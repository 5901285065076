import React, { useState, useEffect, useRef } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { addWorkticketTask } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketTask = (props) => {
  const classes = useStyles();
  const inputTaskRef = useRef();
  const permissions = [];
  const [stateContext] = useWorkticketView();
  const [addTask, setAddTask] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      setTaskList(workticket.tasks);
    }
  }, [isLoading, workticket.tasks]);

  const handleSaveItem = async () => {
    if (!hasPermission(permissionWorkticket.TASKS, permissions)) {
      return;
    }
    const value = inputTaskRef.current.value;
    if (value !== "") {
      const data = {
        name: value,
        description: "",
      };
      try {
        const result = await addWorkticketTask(workticket.id, data);
        setTaskList(result.data.data.workticket.tasks);
        inputTaskRef.current.value = "";
      } catch (e) {
        logException(e, "Cannot create task");
      }
    }
    setAddTask(false);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyTaskList}>
      <Typography
        variant="h4"
        className={classes.titleSectionBody}
        gutterBottom
      >
        Task List
      </Typography>
      <FormControl component="fieldset" className={classes.taskItemFieldset}>
        <FormGroup
          aria-label="position"
          className={classes.taskItemContainer}
          row
        >
          {taskList.map((task) => (
            <FormControlLabel
              value={task.id}
              key={task.id}
              checked={task.completed_at ? true : false}
              control={
                <Checkbox className={classes.tableCheckbox} color="secondary" />
              }
              label={<Box className={classes.taskLabel}>{task.name}</Box>}
              labelPlacement="end"
              className={classes.taskItem}
            />
          ))}
          {taskList.length === 0 && !addTask && (
            <Box className={classes.containerEmpty}>
              <Typography variant="h5" gutterBottom>
                No tasks have been created.
              </Typography>
            </Box>
          )}

          <FormControlLabel
            value={"new"}
            style={{ display: addTask ? "inline-flex" : "none" }}
            control={
              <Checkbox className={classes.tableCheckbox} color="secondary" />
            }
            label={
              <Input
                defaultValue=""
                className={classes.inputNewTask}
                disableUnderline={true}
                inputProps={{ "aria-label": "description" }}
                onBlur={handleSaveItem}
                inputRef={inputTaskRef}
              />
            }
            labelPlacement="end"
            className={classNames(classes.taskItem, classes.taskItemAdd)}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default WorkticketTask;
