import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useWorkticketView } from "contexts/workticketViewContext";

import MaintenanceAlert from "components/common/maintenanceAlert";

const WorkticketBreadcrum = () => {
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  if (isLoading && workticket !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <PageHeader pageTitle={workticket.number ? workticket.number : "New"}>
        <Link to="/worktickets">Worktickets</Link>
      </PageHeader>
      <MaintenanceAlert spacing />
    </>
  );
};

export default WorkticketBreadcrum;
