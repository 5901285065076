import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Counter from "components/common/TableList/counter";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CheckDetail from "./CheckDetail";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { getFinancialAPCheckSub } from "services/financialService";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const columns = [
  {
    id: "check_number",
    label: "Check Number",
    checkbox: {
      active: (row) => false,
    },
  },
  { id: "date", label: "Date", format: "date" },
  {
    id: "cleared",
    label: "Cleared",
    content: (row) => (row.cleared ? "Yes" : "No"),
    style: { paddingLeft: 20 },
  },
  {
    id: "amount",
    label: "Amount",
    format: "money",
    style: { width: 150 },
  },
  {
    id: "detail",
    content: (row) => <CheckDetail check={row} />,
    style: { textAlign: "center" },
    disableSort: true,
  },
];

const AccountPayablesSubTable = (props) => {
  const classes = useStyles();
  const [tablePointer, setTablePointer] = useState(30);
  const [loadingMore, setLoadingMore] = useState(false);
  const { listLoading, list, count } = useListState();
  const { hasRowsClickable, selected, rows } = useTableState();
  const {
    searchString,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };

      const result = await getFinancialAPCheckSub(filterData);
      dispatchList({
        type: "SET_LIST",
        list: result.data.data.records,
      });
      dispatchList({
        type: "SET_COUNT",
        count: result.data.data.records.length,
      });
      return dispatchList({
        type: "SET_LOADING",
        listLoading: false,
      });
    };
    fetchData();
  }, [
    dispatchList,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  ]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list.slice(0, tablePointer),
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, listLoading, tablePointer]);

  const handleScrollClick = useCallback(async () => {
    if (searchString) {
      // If search activated not scroll
      return;
    }
    if (list.length <= tablePointer) {
      return;
    }

    try {
      setLoadingMore(true);
      const updateTablePointer = tablePointer + 30;
      setTablePointer(updateTablePointer);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [tablePointer, list.length, searchString]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const resultSearch = list.filter((elementItem) =>
          elementItem.check_number.toString().includes(query)
        );
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: resultSearch,
        });
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list.slice(0, tablePointer),
    });
  };

  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "1") {
      history.push(`/financial/report/account-payable-work-sub`);
    }
  };

  return (
    <Box className={classes.root}>
      {listLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleModuleChange} value="0">
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Checks</Box>}
                key="0"
                value="0"
              />
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Worktickets</Box>}
                key="1"
                value="1"
              />
            </TableTabs>
            <Box className={classes.contentCounter}>
              {!searchString ? (
                <Counter>
                  {count > tablePointer ? tablePointer : count} / {count}
                </Counter>
              ) : (
                <Counter>{rows.length}</Counter>
              )}
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AccountPayablesSubTable);
