import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RingBellIcon from "@material-ui/icons/NotificationsActive";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logException } from "components/util/logUtil";
import FormInput from "components/ui/FormContent/formInput";
import { ringBell, getTeam } from "services/ringBellService";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import { useTableState } from "contexts/tableContext";
import {
  useRingBellState,
  useRingBellDispatch,
} from "contexts/ringBellContext";
import { hasPermission, permissionTalent } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, getFilterData } from "contexts/filterContext";

import useStyles from "./styles";

const InitialData = {
  assignedTo: [],
  note: "",
};

const schemaNormal = {
  assignedTo: Joi.array()
    .required()
    .min(1)
    .label("Team")
    .error(() => {
      return {
        message: "Select at least one team member.",
      };
    }),
  note: Joi.string()
    .min(1)
    .required()
    .label("Note")
    .error(() => {
      return {
        message: "Note is required.",
      };
    }),
};

const schemaIndividual = {
  note: Joi.string()
    .min(1)
    .required()
    .label("Note")
    .error(() => {
      return {
        message: "Note is required.",
      };
    }),
};

const RingBellActionDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [schema, setSchema] = useState(schemaNormal);
  const [error, setError] = useState([]);
  const [data, setData] = useState(InitialData);
  const [users, setUsers] = useState(null);
  const {
    openRingBell,
    openRingBellSingle,
    ringBellSelected,
    ringBellUserId,
  } = useRingBellState();
  const dispatchRingBell = useRingBellDispatch();
  const tableUi = useTableState();
  const { selected } = tableUi;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const filterState = useFilterState();

  useEffect(() => {
    if (!ringBellUserId) {
      if (!openRingBell && !openRingBellSingle) {
        setData(InitialData);
      } else {
        let selectedUsers = [];
        if (selected.length > 0 && openRingBell) {
          selectedUsers = users.filter((item) => selected.includes(item.id));
        } else {
          selectedUsers = users.filter(
            (item) => ringBellSelected.id === item.id
          );
        }
        const newData = {
          ...data,
          assignedTo: selectedUsers ? selectedUsers : [],
        };
        setData(newData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRingBell, openRingBellSingle, ringBellUserId]);

  useEffect(() => {
    if (!ringBellUserId) {
      setSchema(schemaNormal);
    } else {
      setSchema(schemaIndividual);
    }
  }, [ringBellUserId]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setIsLoadingUsers(true);
        const filterData = getFilterData(filterState);
        if (hasPermission(permissionTalent.VIEW_DEPARTMENT, permissions)) {
          filterData.filters = [
            ...filterData.filters,
            {
              filter: "skipPositionConditions",
              group: "skipPositionConditions",
              values: [{ label: "", value: 1 }],
            },
          ];
        }
        const result = await getTeam(filterData);
        setUsers(result.data.data.list);
        setIsLoadingUsers(false);
      } catch (e) {
        logException(e, "Cannot load team data");
      }
    };
    if (!ringBellUserId) {
      loadUsers();
    }
  }, [ringBellUserId, filterState]);

  const handleClose = () => {
    if (openRingBell) {
      dispatchRingBell({
        type: "TOGGLE_RINGBELL",
        openRingBell: false,
      });
    } else {
      dispatchRingBell({
        type: "TOGGLE_RINGBELL_SINGLE",
        openRingBellSingle: false,
      });
    }
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data);
      const assignedToId = [];
      if (!Boolean(errors)) {
        let dataSent = {};
        setIsLoadingDialog(true);
        if (!ringBellUserId) {
          data.assignedTo.map((item) => {
            return assignedToId.push(item.id);
          });
          dataSent = {
            user_ids: assignedToId,
            note: data.note,
          };
        } else {
          dataSent = {
            user_ids: [ringBellUserId],
            note: data.note,
          };
        }
        await ringBell(dataSent);
        if (ringBellUserId) {
          dispatchRingBell({
            type: "TOGGLE_UPDATE_RECOGNITIONS",
            updateRecognitions: true,
          });
        }
        setOpenSuccess(true);
        handleClose();
        setIsLoadingDialog(false);
      }
    } catch (e) {
      console.log("Cannot ring the bell");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    const newData = {
      ...data,
      assignedTo: value ? value : [],
    };
    setData(newData);
    validateProperty("assignedTo", value, schema);
  };

  const validateProperty = (name, value, schemaAnalized) => {
    const obj = { [name]: value };
    const schema = { [name]: schemaAnalized[name] };
    const { error: errors } = Joi.validate(obj, schema);
    if (Boolean(errors)) {
      setError([...error, { key: name, message: errors.details[0].message }]);
    } else {
      setError(error.filter((err) => err.key !== name));
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};
    switch (event.name) {
      case "note":
        newData = {
          ...data,
          note: event.value,
        };
        validateProperty("note", value, schema);
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const validate = (schema, analizeData) => {
    const options = { abortEarly: false };
    const keyNames = Object.keys(schema);
    let data = {};
    for (let key of keyNames) {
      data[key] = analizeData[key];
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (Boolean(errors)) {
      const keyNames = Object.keys(errors);
      const errorArray = [];
      for (let key of keyNames) {
        errorArray.push({ key, message: errors[key] });
      }
      setError(errorArray);
    } else {
      setError([]);
    }
    return errors;
  };

  return (
    <>
      <Dialog
        open={openRingBell || openRingBellSingle}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <RingBellIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Ring the Bell
              </Typography>
              <Box className={classes.formContainer}>
                {!ringBellUserId ? (
                  !isLoadingUsers ? (
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users}
                      name="assignedTo"
                      error={error}
                      label="Team Member(s)"
                      internal={true}
                      handleChange={handleChangeAssignedTo}
                      multiple={true}
                      value={data.assignedTo}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )
                ) : (
                  ""
                )}
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="note"
                  label="Note"
                  error={error}
                  internal={false}
                  value={data.note}
                  handleBlur={handleBlur}
                  multiline={true}
                  rows={8}
                />
              </Box>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Ring Bell
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Bell has been rung!`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default RingBellActionDialog;
