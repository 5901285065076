import React, { useState, useContext } from "react";
import * as classNames from "classnames";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormInput from "components/ui/FormContent/formInput";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import QuestionIcon from "@material-ui/icons/Help";
import AssetsIcon from "@material-ui/icons/Toys";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import {
  updateAssetCategory,
  deleteAssetCategory,
} from "services/assetService";
import { permissionAsset, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const options = [
  {
    id: 1,
    label: "Edit",
    value: "EDIT",
    permission: permissionAsset.MANAGE_CATEGORIES,
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    permission: permissionAsset.MANAGE_CATEGORIES,
  },
];

const AssetsCategoriesTableActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const { rows } = useTableState();
  const dispatchTable = useTableDispatch();
  const { count } = useListState();
  const dispatchList = useListDispatch();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [category, setCategory] = useState(null);
  const [error, setError] = useState("");
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleUpdateBlur = async (event) => {
    setError("");
    if (category[event.name] !== event.value) {
      const data = {
        ...category,
        [event.name]: event.value,
      };
      setCategory(data);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");

    if (action === "EDIT") {
      setOpenUpdate(true);
      setCategory(row);
    }
    if (action === "DELETE") {
      setOpenDelete(true);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleConfirmUpdate = async () => {
    if (category.name === "") {
      setError("Enter name for the category.");
      return;
    }

    try {
      const dataUpdate = { name: category.name };
      await updateAssetCategory(row.id, dataUpdate);
      const indexUpdated = rows.findIndex((category) => category.id === row.id);
      const updateRow = rows[indexUpdated];
      updateRow.name = category.name;
      rows[indexUpdated] = updateRow;
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: rows,
      });
      handleCloseUpdate();
    } catch (e) {
      console.log("Cannot update category");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteAssetCategory(row.id);
      const updatedRows = rows.filter((category) => category.id !== row.id);
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: updatedRows,
      });
      dispatchList({
        type: "SET_COUNT",
        count: count - 1,
      });
      setOpenDelete(false);
    } catch (e) {
      console.log("Cannot delete category");
    }
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter((option) => hasPermission(option.permission, permissions))
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDelete(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <QuestionIcon className={classes.iconQuestionDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Delete Category
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete the category?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDelete}
            className={classes.button}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="secondary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseUpdate}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Grid container spacing={2} className={classes.dialogHeader}>
            <Grid item md={12}>
              <Box className={classes.formIconDialog}>
                <AssetsIcon className={classes.iconDialog} />
              </Box>
              <Typography
                variant="h4"
                className={classes.formTitleDialog}
                gutterBottom
              >
                Update Category
              </Typography>
            </Grid>
          </Grid>

          <Box className={classes.formContainerDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="name"
              label="Name"
              internal={true}
              value={category?.name}
              handleBlur={handleUpdateBlur}
            />
          </Box>
        </DialogContent>

        {Boolean(error) && (
          <Box className={classes.containerError}>{error}</Box>
        )}
        <DialogActions className={classes.actionsDialogWrapper}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleCloseUpdate}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonPrimary)}
            onClick={handleConfirmUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(AssetsCategoriesTableActions);
