import React, { useState, useEffect } from "react";
import QuoteSurface from "./quoteSurface";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuoteSurfacePage = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <LoadingStateHorizontal isVisible />
        </div>
      ) : (
        <QuoteSurface
          quoteId={props.match.params.id}
          idProject={props.match.params.id_project}
        />
      )}
    </>
  );
};

export default QuoteSurfacePage;
