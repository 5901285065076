import React, { useContext } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import { Link } from "react-router-dom";
import { usePartnerState } from "contexts/partnerContext";
import { hasPermission, permissionPartner } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

export function PartnerBreadcrumb() {
  const { partnerDetails } = usePartnerState();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return hasPermission(permissionPartner.PARTNER_OWNER, permissions) ? (
    <PageHeader pageTitle={partnerDetails?.company_name ?? ""} />
  ) : (
    <PageHeader pageTitle={partnerDetails?.company_name ?? ""}>
      <Link to="/partners">Partners</Link>
    </PageHeader>
  );
}
