import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "components/ui/core/button";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import GlobalUiContext from "contexts/globalUiContext";
import { useNoteState, useNoteDispatch } from "contexts/noteContext";
import MessageDialog from "components/ui/dialog/messageDialog";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import NoteIcon from "@material-ui/icons/Info";
import { logException } from "components/util/logUtil";
import { getFilterJobs } from "components/util/filterUtil";
import { saveNote, updateNote, deleteNote } from "services/noteService";

const useStyles = makeStyles((theme) => ({
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  formContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  formContainer: {
    "& > div": {
      marginBottom: theme.spacing(2),
    },
  },
  chipJob: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerError: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    textAlign: "center",
  },
  titleLabel: {
    fontSize: 14,
  },
  contentDialog: {
    fontSize: 14,
    textAlign: "center",
  },
  checkbox: {
    color: "#ececec",
    border: "none",
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
}));

const initNewData = {
  type: 0,
  summary: "",
  description: "",
};

const typeOptions = [
  { value: 1, label: "Team" },
  { value: 2, label: "Private" },
];

const NotesManage = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { noteDetails, noteDetailsAction } = useNoteState();
  const dispatchNotes = useNoteDispatch();
  const { users } = globalUi;
  const [sharedUsers, setSharedUsers] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [newData, setNewData] = useState(initNewData);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [error, setError] = useState("");

  const open = noteDetailsAction ? true : false;
  const readonly = noteDetailsAction === "View" ? true : false;

  useEffect(() => {
    if (jobsData.length) {
      setError("");
      if (!open) {
        setNewData(initNewData);
        setJobsList([]);
        setSharedUsers([]);
      } else {
        if (noteDetails) {
          setNewData({
            type: noteDetails.type,
            summary: noteDetails.summary,
            description: noteDetails.description,
          });
          if (noteDetails.jobs) {
            const jobsSelected = noteDetails.jobs.map((job) => job.id);
            setJobsList(
              jobsData.filter((job) => jobsSelected.includes(parseInt(job.id)))
            );
          }

          if (noteDetails.users_share) {
            const usersSelected = noteDetails.users_share.map(
              (user) => user.id
            );
            setSharedUsers(
              users.filter((user) => usersSelected.includes(user.id))
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, noteDetails, jobsData]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobsData(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs");
      }
    };
    if (open && jobsData && !jobsData.length) {
      loadJobs();
    }
  }, [open, jobsData]);

  const handleCreateBlur = (event) => {
    setError("");
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleTypeChange = (type) => {
    if (readonly) {
      return false;
    }
    setError("");
    const data = {
      ...newData,
      type,
    };
    setNewData(data);
  };

  const handleCreate = async () => {
    if (newData.type === 0) {
      setError("Select type for the note.");
      return;
    }

    if (newData.summary === "") {
      setError("Enter summary for the note.");
      return;
    }
    if (newData.description === "") {
      setError("Enter detail for the note.");
      return;
    }
    if (!jobsList.length) {
      setError("Select at least one job for the note.");
      return;
    }
    try {
      const dataSave = {
        ...newData,
        job_ids: jobsList.map((job) => job.id),
        user_ids: newData.type === 1 ? sharedUsers.map((user) => user.id) : [],
      };

      await saveNote(dataSave);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
      setMsgSuccess("Note has been added.");
      setOpenSuccess(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot create note");
    }
  };

  const handleEdit = async () => {
    if (newData.type === 0) {
      setError("Select type for the note.");
      return;
    }

    if (newData.summary === "") {
      setError("Enter summary for the note.");
      return;
    }
    if (newData.description === "") {
      setError("Enter detail for the note.");
      return;
    }
    if (!jobsList.length) {
      setError("Select at least one job for the note.");
      return;
    }

    try {
      const dataSave = {
        ...newData,
        job_ids: jobsList.map((job) => job.id),
        user_ids: newData.type === 1 ? sharedUsers.map((user) => user.id) : [],
      };

      await updateNote(noteDetails.id, dataSave);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
      setMsgSuccess("Note has been updated.");
      setOpenSuccess(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot update note");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteNote(noteDetails.id);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
      setMsgSuccess("Note has been delete.");
      setOpenSuccess(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot delete note");
    }
  };

  const handleChangeJob = (event, value) => {
    setError("");
    if (value) {
      setJobsList(value);
    }
  };

  const handleChangeSharedUsers = (event, value) => {
    setError("");
    if (value) {
      setSharedUsers(value);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleClose = () => {
    dispatchNotes({
      type: "SET_NOTE_DETAILS_ACTION",
      noteDetailsAction: null,
    });
    dispatchNotes({
      type: "SET_NOTE_DETAILS",
      noteDetails: null,
    });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {noteDetailsAction ? (
          <DialogContent>
            <DialogCloseIcon handleClose={handleClose} />
            <DialogTitle
              title={`${noteDetailsAction} Note`}
              icon={<NoteIcon />}
            />
            {noteDetailsAction === "Delete" ? (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to delete the note?
              </Typography>
            ) : (
              <Box className={classes.formContainerDialog}>
                <Typography
                  variant="h4"
                  className={classes.titleLabel}
                  gutterBottom
                >
                  Note Type
                </Typography>
                {typeOptions.map((type) => (
                  <FormControlLabel
                    key={type.value}
                    control={
                      <Checkbox
                        checked={newData.type === type.value}
                        onChange={() => handleTypeChange(type.value)}
                        className={classes.checkbox}
                      />
                    }
                    label={type.label}
                    value={type.value}
                  />
                ))}
                <Box className={classes.formContainer}>
                  {!readonly ? (
                    <>
                      <FormSelectJobsAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={jobsData}
                        name="job_number"
                        label="Location"
                        multiple={true}
                        handleChange={handleChangeJob}
                        value={jobsList}
                        disableCloseOnSelect={true}
                      />
                      {newData.type === 1 ? (
                        <FormSelectChipsAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={users}
                          name="users"
                          label="Shared With"
                          handleChange={handleChangeSharedUsers}
                          multiple={true}
                          value={sharedUsers}
                        />
                      ) : null}
                    </>
                  ) : null}
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="summary"
                    label="Note Summary"
                    value={newData.summary}
                    handleBlur={handleCreateBlur}
                    readonly={readonly}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="description"
                    label="Note Details"
                    value={newData.description}
                    handleBlur={handleCreateBlur}
                    multiline={true}
                    rows={6}
                    readonly={readonly}
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
        ) : (
          <LoadingIndicator />
        )}
        {Boolean(error) && (
          <Box className={classes.containerError}>{error}</Box>
        )}
        <DialogActions className={classes.actionsDialogWrapper}>
          <ButtonBase
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </ButtonBase>
          {noteDetailsAction === "Add" ? (
            <ButtonBase color="secondary" onClick={handleCreate}>
              Add Note
            </ButtonBase>
          ) : null}
          {noteDetailsAction === "Edit" ? (
            <ButtonBase color="secondary" onClick={handleEdit}>
              Save
            </ButtonBase>
          ) : null}
          {noteDetailsAction === "Delete" ? (
            <ButtonBase color="secondary" onClick={handleDelete}>
              Delete
            </ButtonBase>
          ) : null}
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={msgSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default NotesManage;
