import React from "react";
import Box from "@material-ui/core/Box";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import {
  useQuestionDispatch,
  useQuestionState,
} from "contexts/surveyQuestionContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
}));

const QuestionsListActions = (props) => {
  const classes = useStyles();
  const dispatchQuestions = useQuestionDispatch();
  const { questionList } = useQuestionState();

  const handleClick = () => {
    dispatchQuestions({
      type: "TOGGLE_ADD",
      openQuestionAdd: true,
    });
    dispatchQuestions({
      type: "SET_ASSIGNED_IDS",
      assignedIds: questionList.map((q) => {
        return parseInt(q.qa_question_id);
      }),
    });
  };

  return (
    <Box className={classes.root}>
      <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
        Add Question
      </ButtonBase>
    </Box>
  );
};

export default QuestionsListActions;
