import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { makeStyles } from "@material-ui/core/styles";
import { useProjectView } from "contexts/projectViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
}));

const WorkticketsListTabActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;

  const handleClick = () => {
    const { history } = props;
    history.push(`/workticket/new/${project.id}`);
  };

  return (
    <div className={classes.root}>
      {hasPermission(permissionWorkticket.CREATE, permissions) && (
        <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
          Create Ticket
        </ButtonBase>
      )}
    </div>
  );
};

export default withRouter(WorkticketsListTabActions);
