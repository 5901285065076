import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import React from "react";
import useStyles from "./styles";

export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();

  const response = selectedModalViewResponse?.data?.form_equipment
    ? selectedModalViewResponse?.data?.form_equipment
    : "";
  const items = response?.items ? JSON.parse(response?.items) : [];
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                }}
              >
                <Typography className={classes.modalTitle}>
                  Equipment Repair Request
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      First Name
                    </Typography>
                    <TextField
                      name="name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Account
                    </Typography>
                    <TextField
                      name="account"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.job?.job_description}
                    />
                  </Grid>
                </Grid>
                {items.map((v) => {
                  return (
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <Typography className={classes.subSubTitle}>
                            Item Description
                          </Typography>
                          <TextField
                            name="desc"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.description}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Serial No/Asset Tag Number
                          </Typography>
                          <TextField
                            name="serial_no"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.serial_number}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Make
                          </Typography>
                          <TextField
                            name="make"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.make}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Model
                          </Typography>
                          <TextField
                            name="model"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.model}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <Typography className={classes.subSubTitle}>
                            Issue of Problem with Equipment
                          </Typography>
                          <TextField
                            name="issue"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.issue}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Fix At:
                    </Typography>
                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="fix_at"
                        name="fix_at"
                        value={response?.fix_at ? response.fix_at : ""}
                      >
                        <FormControlLabel
                          value="account site"
                          control={<Radio color="primary" />}
                          label="Account Site"
                          name="fix_at"
                        />
                        <FormControlLabel
                          value="corporate office"
                          control={<Radio color="primary" />}
                          label="Corporate Office"
                          name="fix_at"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
