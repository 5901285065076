import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;
export async function getPartnerRatings(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/ratings`);
}
export async function ratePartner(data) {
  return await http.post(`${apiEndpoint}/${data.partner_id}/rate`, data.data);
}
export async function deleteUserRate(data) {
  return await http.delete(`${apiEndpoint}/${data.partner_id}/ratings`, {
    data: data.data,
  });
}
export async function updatePartnerRate(data) {
  return await http.put(`${apiEndpoint}/${data.partner_id}/ratings`, data.data);
}
