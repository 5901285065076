import React from "react";
import Box from "@material-ui/core/Box";
import LocationsSurfaceTable from "./locationsSurfaceTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import LocationsListSurfaceDialog from "./dialog/locationsListSurfaceDialog";
import { LocationsProvider } from "contexts/surveyLocationsSurfaceContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import useStyles from "./styles";

const LocationsSurfaceList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <LocationsProvider>
        <LocationsSurfaceTable />
        <SurveyFillProvider>
          <LocationsListSurfaceDialog />
        </SurveyFillProvider>
      </LocationsProvider>
    </Box>
  );
};

export default withTableFilterContext(LocationsSurfaceList);
