import React, { useContext, useEffect, useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as classNames from "classnames";
import FormInput from "components/ui/FormContent/formInput";
import PageHeader from "components/common/PageHeader/pageHeader";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import {
  getFilterCustomers,
  getFilterJobsCustomer,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import {
  anyUpdateById,
  getOpenPositionById,
  getPositionTitle,
  updateOpenPositionById,
} from "services/openPositionsService";
import OpenPositionsChip from "./openPositionChip";
import OpenPositionsFile from "./openPositionsFile";
import useStyles from "./styles";
import ViewPositionsLocation from "./viewPositionsLocation";
import WeeklyScheduleModal from "./weeklyScheduleModalFixed";
import { permissionOpenPositions, hasPermission } from "lib/permissions";
import OpenPositionsActivity from "./openPositionsActivity";
import UpdateScheduleDialog from "./updateScheduleDialog";

const mapping = {
  1: {
    label: "Opened",
    value: 1,
  },
  5: {
    label: "Onboarding",
    value: 5,
  },
  6: {
    label: "Filled",
    value: 6,
  },
  7: {
    label: "Closed",
    value: 7,
  },
  10: {
    label: "Archived",
    value: 10,
  },
};

const requestTypeOptions = [
  { label: "New", value: "New" },
  { label: "Replacement", value: "Replacement" },
];

const selectedHourlyOrMonthlyOptions = [
  { label: "Hourly", value: "Hourly" },
  { label: "Monthly", value: "Monthly" },
];

const statusTypeOptions = [
  {
    label: "Opened",
    value: 1,
  },
  {
    label: "Onboarding",
    value: 5,
  },
  {
    label: "Filled",
    value: 6,
  },
  {
    label: "Closed",
    value: 7,
  },
];

export default function ViewPosition() {
  const classes = useStyles();
  const [openPositionByIdData, setOpenPositionByIdData] = useState([]);
  const [siteReq, setSiteReq] = useState("");
  const [candidate, setCandidate] = useState(null);
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const { globalUi } = useContext(GlobalUiContext);
  const { users, permissions } = globalUi;
  const [open, setOpen] = useState(false);

  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  const [selectedRequestType, handleRequestType] = useState("");
  const [selectedStatusTypeOptions, setSelectedStatusTypeOptions] =
    useState("");
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState("");
  const [jobTitleOptions, setJobTitleOptions] = useState("");
  const [jobTitleOptionsSelected, setJobTitleOptionsSelected] = useState("");
  const [selectedHourlyOrMonthly, handleSelectedHourlyOrMonthly] = useState("");
  const [positionRate, setPositionRate] = useState("");
  const [dateSubmitted, setDateSubmitted] = useState("");
  const [dateRequested, setDateRequested] = useState("");
  const [dateFilled, setDateFilled] = useState("");

  useEffect(() => {
    setJobs([]);
    getOpenPositionById(id).then((res) => {
      let openPositionRes = res?.data?.data?.open_positions;
      setOpenPositionByIdData(openPositionRes);
      setSiteReq(openPositionRes?.site_requirement);
      setCandidate(openPositionRes?.candidate);
      handleRequestType(
        openPositionRes?.type === 1
          ? { label: "New", value: "New" }
          : { label: "Replacement", value: "Replacement" }
      );
      setSelectedStatusTypeOptions(
        openPositionRes?.archived === 1
          ? { ...mapping[10] }
          : { ...mapping[res?.data?.data?.open_positions?.status] }
      );
      setPositionRate(openPositionRes?.position_rate);
      getFilterCustomers().then((resultForRender) => {
        setCustomers(resultForRender);
        if (openPositionRes.customer_id) {
          setCustomerSelected({
            ...resultForRender.find(
              (v) => parseInt(v.value) === openPositionRes?.customer?.id
            ),
          });
        }
      });
      getPositionTitle().then((resTitle) => {
        const transformed = resTitle?.data?.data?.open_positions_titles?.map(
          (v) => ({ label: v?.name, value: v?.number })
        );
        setJobTitleOptions(transformed);
        setJobTitleOptionsSelected({
          ...transformed.find(
            (v) => v.label === openPositionRes.position_title
          ),
        });
        handleSelectedHourlyOrMonthly(
          openPositionRes?.position_type === "Hourly"
            ? selectedHourlyOrMonthlyOptions[0]
            : selectedHourlyOrMonthlyOptions[1]
        );
      });

      setTeamMember(
        userOptions.find(
          (v) => parseInt(v.value) === openPositionRes.requestor_user_id
        )
      );
      setDateSubmitted(
        openPositionRes.created_at ? moment(openPositionRes.created_at) : ""
      );
      setDateRequested(
        openPositionRes.date_requested
          ? moment(openPositionRes.date_requested)
          : ""
      );
      setDateFilled(
        openPositionRes.date_filled ? moment(openPositionRes.date_filled) : ""
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (refresh) {
      getOpenPositionById(id).then((res) => {
        let openPositionRes = res?.data?.data?.open_positions;
        setOpenPositionByIdData(openPositionRes);
        setSiteReq(openPositionRes?.site_requirement);
        setComment("");
        setRefresh(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // const CommentItem = (props) => (
  //   <ListItem>
  //     <ListItemAvatar>
  //       <Avatar className={classes.avatarComment}>
  //         {props.comment.user[0].first_name.charAt(0)}
  //         {props.comment.user[0].last_name.charAt(0)}
  //       </Avatar>
  //     </ListItemAvatar>
  //     <ListItemText
  //       className={classes.listText}
  //       secondary={
  //         <>
  //           <Typography
  //             component="span"
  //             variant="body2"
  //             className={classes.listName}
  //             color="textPrimary"
  //           >
  //             {props.comment.user[0].first_name}{" "}
  //             {props.comment.user[0].last_name}
  //           </Typography>
  //           {props.comment.body}
  //         </>
  //       }
  //     />
  //   </ListItem>
  // );

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobsCustomer(
          customerSelected?.value,
          true
        );
        setJobs(resultForRender);
        setJobSelected(
          resultForRender.find(
            (v) => parseInt(v.id) === parseInt(openPositionByIdData.job_id)
          )
        );
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs?.length && customerSelected?.value) {
      loadJob();
    }
  }, [customerSelected?.value]);

  const handleChangeCandidate = async (event) => {
    try {
      setCandidate(event.value);
      await anyUpdateById(openPositionByIdData?.id, {
        candidate: event.value,
      });
    } catch (e) {
      logException(e, "Cannot update candidate");
    }
  };

  return (
    <Box className={classes.overflowXhidden}>
      <UpdateScheduleDialog setOpen={setOpen} open={open} />
      <Box>
        <PageHeader pageTitle={`OPS - ${id}`} link="">
          <Link to="/open-position">Open Positions</Link>
        </PageHeader>
      </Box>
      <Box className={classNames(classes.root)}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box
              className={classNames(
                classes.Box,
                classes.columnContainerLeft,
                classes.grid1
              )}
            >
              <Typography className={classes.labelLeft}>
                Open Position Number
              </Typography>
              <TextField
                name="email"
                className={classes.openPosView}
                value={openPositionByIdData?.id}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                }}
                fullWidth
              />
              <Box />
              <Typography className={classes.labelLeft}>Requestor</Typography>

              <FormSelectAuto
                inputContainer={classes.inputContainer}
                options={userOptions}
                name="user"
                handleChange={(e, valueInput) => {
                  let val = userOptions.find(
                    (v) => v.value === valueInput?.value
                  );
                  setTeamMember(val);
                  anyUpdateById(openPositionByIdData?.id, {
                    requestor_user_id: val?.value,
                  });
                }}
                value={teamMember}
                readonly={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              <Typography className={classes.labelLeft}>
                Request Type
              </Typography>

              <FormSelectAuto
                inputContainer={classes.inputContainer}
                options={requestTypeOptions}
                readonly
                value={selectedRequestType}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="candidate"
                label="Job Candidate"
                value={candidate}
                handleBlur={handleChangeCandidate}
                readonly={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              <Typography className={classes.labelLeft}>Status</Typography>

              <FormSelectAuto
                inputContainer={classes.inputContainer}
                options={statusTypeOptions}
                handleChange={(e, v) => {
                  setSelectedStatusTypeOptions(v);
                  updateOpenPositionById({ status: v?.value }, id);
                }}
                value={selectedStatusTypeOptions}
                readonly={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              <Typography className={classes.labelLeft}>Customer</Typography>

              {Boolean(customers.length) ? (
                <FormSelectAuto
                  inputContainer={classes.inputContainer}
                  options={customers}
                  name="customer"
                  readonly
                  value={customerSelected}
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
              <Typography className={classes.labelLeft}>Job</Typography>

              {Boolean(jobs && customers?.length) ? (
                <FormSelectJobsAuto
                  inputContainer={classes.inputContainer}
                  options={jobs}
                  name="job_number"
                  handleChange={(e, v) => {
                    setJobSelected(v);
                    anyUpdateById(openPositionByIdData?.id, {
                      job_id: jobSelected?.id,
                    });
                  }}
                  value={jobSelected}
                  readonly={
                    !hasPermission(permissionOpenPositions.UPDATE, permissions)
                  }
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
              <Typography className={classes.labelLeft}>
                Position Title
              </Typography>

              {Boolean(jobTitleOptions.length) ? (
                <FormSelectAuto
                  inputContainer={classes.inputContainer}
                  options={jobTitleOptions}
                  handleChange={(e, v) => {
                    setJobTitleOptionsSelected(v);
                    anyUpdateById(openPositionByIdData?.id, {
                      position_title: jobTitleOptionsSelected?.label,
                    });
                  }}
                  value={jobTitleOptionsSelected}
                  readonly={
                    !hasPermission(permissionOpenPositions.UPDATE, permissions)
                  }
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
              <Typography className={classes.labelLeft}>
                Position Type
              </Typography>

              <FormSelectAuto
                inputContainer={classes.inputContainer}
                options={selectedHourlyOrMonthlyOptions}
                handleChange={(e, v) => {
                  handleSelectedHourlyOrMonthly(v);
                  anyUpdateById(openPositionByIdData?.id, {
                    position_type: v?.value,
                  });
                }}
                value={selectedHourlyOrMonthly}
                readonly={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              <Typography className={classes.labelLeft}>
                Compensation
              </Typography>
              <TextField
                name="comp"
                className={classes.field}
                fullWidth
                value={positionRate}
                onChange={(e) => {
                  setPositionRate(e.target.value);
                  anyUpdateById(openPositionByIdData?.id, {
                    position_rate: e.target.value,
                  });
                }}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                disabled={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              <Typography className={classes.inputViewSubmit}>
                Date Submitted
              </Typography>

              <FormInputDateAdo
                name="created_at"
                value={dateSubmitted}
                handleChange={(e) => {
                  setDateSubmitted(e);
                }}
                disabled={true}
              />
              <Typography className={classes.inputView}>
                Date Requested
              </Typography>

              <FormInputDateAdo
                name="date_requested"
                //label="In Date"
                value={dateRequested}
                handleChange={(e) => {
                  if (moment(e, "YYYY-MM-DD", true).isValid()) {
                    setDateRequested(e);
                    anyUpdateById(openPositionByIdData?.id, {
                      date_requested: moment(e).format("YYYY-MM-DD"),
                    });
                  }
                }}
                disabled={
                  !hasPermission(permissionOpenPositions.UPDATE, permissions)
                }
              />
              {selectedStatusTypeOptions?.value === 6 && (
                <Typography className={classes.inputView}>
                  Date Filled
                </Typography>
              )}

              {selectedStatusTypeOptions?.value === 6 && (
                <FormInputDateAdo
                  name="date_filled"
                  value={dateFilled}
                  handleChange={(e) => {
                    if (moment(e, "YYYY-MM-DD", true).isValid()) {
                      setDateFilled(e);
                      anyUpdateById(openPositionByIdData?.id, {
                        date_filled: moment(e).format("YYYY-MM-DD"),
                      });
                    }
                  }}
                  disabled={
                    !hasPermission(permissionOpenPositions.UPDATE, permissions)
                  }
                />
              )}
              {openPositionByIdData?.status !== 6 &&
                hasPermission(permissionOpenPositions.UPDATE, permissions) && (
                  <Box className={classes.columnLeftButton}>
                    <Grid
                      container
                      className={classNames(classes.centerAlignBtn)}
                    >
                      <Button
                        className={classes.markFilledButton}
                        onClick={() => {
                          updateOpenPositionById({ status: 6 }, id);
                        }}
                      >
                        Mark Filled
                      </Button>
                    </Grid>
                  </Box>
                )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className={classNames(classes.grid1, classes.Box)}>
              <Box className={classes.flex}>
                <Box className={classes.flex2}>
                  <Typography
                    display="inline"
                    className={classes.label}
                    style={{ fontSize: "24px" }}
                  >
                    OPS - {openPositionByIdData?.id}
                  </Typography>
                  <span className={classes.viewChip}>
                    <OpenPositionsChip
                      status={
                        selectedStatusTypeOptions
                          ? selectedStatusTypeOptions?.value
                          : openPositionByIdData?.status
                      }
                      archived={openPositionByIdData?.archived}
                    />
                  </span>
                </Box>
              </Box>
              <Typography
                className={classes.label}
                style={{ fontSize: "12px" }}
              >
                {dateSubmitted
                  ? `Date Submitted ${moment(dateSubmitted).format(
                      "MM/DD/YY"
                    )} `
                  : null}
                {dateRequested
                  ? `| Date Requested ${moment(dateRequested).format(
                      "MM/DD/YY"
                    )} `
                  : null}
                {dateFilled
                  ? `| Date Filled ${moment(dateFilled).format("MM/DD/YY")}`
                  : null}
              </Typography>
              <Box className={classes.marginTop10}></Box>
              <Typography className={classes.label}>
                Site Requirements
              </Typography>
              <Grid container>
                <TextField
                  id="op-site-requirements"
                  label=""
                  multiline
                  fullWidth
                  className={classes.field}
                  rows={4}
                  defaultValue=""
                  onChange={(e) => setSiteReq(e.target.value)}
                  value={siteReq}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Box className={classes.marginTop10}></Box>
              <Grid container className={classes.rightAlignBtn}>
                {hasPermission(permissionOpenPositions.UPDATE, permissions) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary
                    )}
                    onClick={() => {
                      anyUpdateById(openPositionByIdData?.id, {
                        site_requirement: siteReq,
                      });
                    }}
                  >
                    Edit
                  </Button>
                ) : null}
              </Grid>
              <Box className={classes.weeklyScheduleView}>
                <WeeklyScheduleModal
                  setOpen={setOpen}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  scheduleData={
                    openPositionByIdData?.position_schedule &&
                    JSON.parse(openPositionByIdData?.position_schedule)
                      ? JSON.parse(openPositionByIdData?.position_schedule)
                      : null
                  }
                  id={openPositionByIdData?.id}
                  anyUpdateById={anyUpdateById}
                />
              </Box>
              {openPositionByIdData?.comments && (
                <OpenPositionsActivity
                  workticket={openPositionByIdData}
                  comment={comment}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              className={classNames(
                classes.Box,
                classes.columnRightBg,
                classes.grid3
              )}
            >
              <ViewPositionsLocation
                openPositionByIdData={openPositionByIdData}
              />
              <Button
                className={classes.viewLocationButton}
                onClick={() => {
                  window.open(
                    `http://maps.google.com/maps?q=${openPositionByIdData?.job?.latitude},${openPositionByIdData?.job?.longitude}`,
                    "_blank"
                  );
                }}
              >
                View Location
              </Button>
            </Box>
            <Box className={classes.docsContainer}>
              <OpenPositionsFile
                id={openPositionByIdData?.id}
                filesRender={openPositionByIdData?.files}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
