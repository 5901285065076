import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  logoContainer: {
    paddingTop: 30,
    paddingBottom: 10,
  },
  eosLogo: {
    width: 45,
    height: 45,
    marginRight: 5,
  },
  appMessage: {
    color: "#17457A",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: "center",
    paddingBottom: 10,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    background: "var(--Background-Light-Blue, #EDF5F8)",
    overflow: "auto",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    marginRight: 10,
  },
  buttonContainer: {
    marginTop: 32,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iOSButton: {
    borderRadius: 4,
    background: "var(--Background-Accent-Middle-Blue, #4F98BC)",
    textTransform: "none",
    fontSize: 14,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    "&:hover": {
      background: "#4F98BC",
      color: "#FFF",
    },
  },
  androidButton: {
    marginTop: 10,
    borderRadius: 4,
    border: "1px solid var(--Body-text-Light-Gray, #D9D9D9)",
    background: "var(--Body-text-White, #FFF)",
    textTransform: "none",
    color: "#4F98BC",
    fontSize: 14,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    "&:hover": {
      background: "#FFF",
      color: "#4F98BC",
    },
  },

  bannerContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 9,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    background: "#17457A",
  },
  bannerImageContainer: {
    marginRight: theme.spacing(1),
    background: "#295B95",
    padding: theme.spacing(1),
  },
  bannerIcon: {
    width: 25,
    height: 25,
  },
  bannerAppMessage: {
    color: "var(--Background-Light-Blue, #EDF5F8)",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    marginRight: 40,
  },
  bannerCloseButton: {
    position: "absolute",
    color: "#FFF",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default useStyles;
