import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useUserIdFromUrl = () => {
  const location = useLocation();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const extractUserId = () => {
      const pathSegments = location.pathname.split("/").filter(Boolean); // Filter to remove empty segments

      const id = pathSegments.find((segment) => /^[0-9]+$/.test(segment));
      return id || null; // Return null if no ID is found
    };

    // Update user ID when the location changes
    const newUserId = extractUserId();
    if (newUserId !== userId) {
      setUserId(newUserId);
    }
  }, [location, userId]);

  return userId;
};

export default useUserIdFromUrl;
