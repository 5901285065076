import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "auto",
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    height: "auto",
    maxWidth: "100%",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    justifyContent: "center",
  },
  contentContainer: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: 10,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
    textTransform: "capitalize",
  },
  iconQuestionDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  popover: {
    pointerEvents: "none",
  },
  wrapperName: {
    paddingLeft: 60,
    position: "relative",
    minWidth: 140,
    minHeight: 52,
  },
  avatar: {
    width: 40,
    height: 40,
    fontSize: 22,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
    top: 6,
  },
  avatarContainer: {
    minHeight: 52,
    lineHeight: "52px",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 22,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimaryTable: {
    color: "#4f98bc",
    background: "#ffffff",
    fontSize: 12,
    height: 22,
  },
  buttonTable: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 22,
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  labelEmpty: {
    textAlign: "center",
  },
  listActionContainer: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  colorFieldContainer: { display: "flex" },
  colorField: {
    width: 24,
    height: 24,
  },
  listActionContainerSingle: {
    marginTop: 16,
    marginBottom: 8,
    textAlign: "right",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
}));

export default useStyles;
