import React, { useState, useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MessageDialog from "./dialog/messageDialog";
import Input from "@material-ui/core/Input";
import FileComment from "components/ui/Files/FileComment";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import { assign } from "lodash";
import {
  addComment,
  updateComment,
  deleteComment,
} from "services/partners/commentsService";
import { getCommentByFileId } from "services/fileService";
import useStyles from "./styles";

const CommentDialog = ({ open, partnerId, fileId, handleDialogClose }) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const [commentList, setCommentList] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoadingComment, setIsLoadingComment] = useState(false);
  const [comment, setComment] = useState("");
  const [subTab, setSubTab] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchComments = async () => {
      try {
        setIsLoadingDialog(true);

        const result = await getCommentByFileId(fileId);
        setCommentList(assign(result.data.data, { isEditable: false }));

        setIsLoadingDialog(false);
      } catch (e) {
        logException(e, "Cannot fetch comments");
      }
    };

    if (open) {
      fetchComments();
    }
  }, [open, fileId]);

  useEffect(() => {
    if (commentList && subTab === null) {
      const firstKey = Object.keys(commentList)[0];
      if (firstKey) {
        setSubTab(firstKey);
      }
    }
  }, [commentList, subTab]);

  useEffect(() => {
    if (!open) {
      setSubTab(null);
    }
  }, [open]);

  const handleClose = () => {
    handleDialogClose();
    setErrorMsg(null);
    setComment("");
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleAddComment = async () => {
    if (comment !== "") {
      const data = { body: comment, tab: subTab, file_id: fileId };

      try {
        setIsLoadingComment(true);
        setIsLoadingDialog(true);
        await addComment(partnerId, data);
        const refreshData = await getCommentByFileId(fileId);
        setCommentList(assign(refreshData.data.data, { isEditable: false }));
        setOpenSuccess(true);
        setIsLoadingDialog(false);
        setComment("");
        setIsLoadingComment(false);
        setSuccessMessage("Comment has been added");
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleUpdateComment = async (commentId, newBody) => {
    const data = { body: newBody, tab: subTab };

    try {
      setIsLoadingComment(true);
      await updateComment(partnerId, commentId, data);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].map((c) =>
          c.id === commentId ? { ...c, body: newBody } : c
        );
        return updatedCommentList;
      });

      setOpenSuccess(true);
      setIsLoadingDialog(false);
      setIsLoadingComment(false);
      setSuccessMessage("This comment has been updated.");
    } catch (e) {
      logException(e, "Cannot update comment");
    }
  };

  const handleDeleteComment = async () => {
    if (!selectedComment) return;

    try {
      await deleteComment(partnerId, selectedComment.id);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].filter(
          (c) => c.id !== selectedComment.id
        );
        return updatedCommentList;
      });

      setOpenConfirm(false);
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const openDeleteConfirm = (comment) => {
    setSelectedComment(comment);
    setOpenConfirm(true);
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const renderComments = () => {
    const data = commentList[subTab] || [];
    return data.map((comment) => (
      <FileComment
        key={comment.id}
        id={comment.id}
        externalId={comment.external_id}
        user={{
          id: comment.user_id,
          name: comment.user_name,
          avatar: comment.profile_url,
        }}
        time={comment.video_metadata}
        date={comment.created_at}
        message={comment.body}
        fileId={comment.files_id}
        fileUrl={comment.file_url}
        fileName={comment.display_name}
        fileOwner={comment.user_name}
        fileType={comment.mime_type}
        fileSize={comment.file_size}
        fileCreateAt={comment.files_created_at}
        onUpdateComment={handleUpdateComment}
        onDeleteComment={() => openDeleteConfirm(comment)}
      />
    ));
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        {isLoadingDialog || isLoadingComment ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainerComment}>
                <TicketIcon className={classes.iconDialog2} />
                <Typography className={classes.titleDialog}>
                  File Comments
                </Typography>
              </Box>
              {errorMsg ? (
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.contentDialog}
                >
                  {errorMsg}
                </Typography>
              ) : (
                <>
                  <Box className={classes.formContainer2}>
                    <Box className={classes.containerComments}>
                      <Box style={{ paddingBottom: 10 }}>
                        {role?.category !== "subcontractor" && (
                          <Box className={classes.containerSubTabs}>
                            <Tabs
                              value={subTab}
                              onChange={handleSubTabChange}
                              className={classes.activityTabRoot}
                            >
                              {hasPermission(
                                permissionWorkticket.COMMENTS_INTERNAL,
                                permissions
                              ) && (
                                <Tab
                                  label="Internal"
                                  value="internal"
                                  className={classes.activityTab}
                                />
                              )}
                              {hasPermission(
                                permissionWorkticket.COMMENTS_PARTNER,
                                permissions
                              ) && (
                                <Tab
                                  label="Partners"
                                  value="partner"
                                  className={classes.activityTab}
                                />
                              )}
                            </Tabs>
                          </Box>
                        )}
                      </Box>
                      {renderComments()}
                    </Box>
                  </Box>

                  <ConfirmDialog
                    open={openConfirm}
                    handleConfirm={handleDeleteComment}
                    handleClose={closeConfirm}
                    message={"Are you sure you want to delete this comment?"}
                    title={"File Comment"}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.containerFooter}>
              {!errorMsg ? (
                <>
                  <Box className={classes.containerInputComment}>
                    <Input
                      className={classes.inputNewComment}
                      disableUnderline={true}
                      rows={3}
                      multiline={true}
                      fullWidth={true}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Box>

                  <Box className={classes.buttonContainer}>
                    <Button
                      onClick={handleClose}
                      className={classes.buttonOutlined}
                      variant="outlined"
                      size="large"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleAddComment}
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              ) : (
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="large"
                  color="secondary"
                  className={classes.buttonOutlined}
                  style={{ marginRight: 0 }}
                >
                  Okay
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={successMessage}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default CommentDialog;
