import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";

const apiEndpoint = `${apiUrl}/triggers`;

export function getTriggersListT(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getTriggersListTSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getTriggersListTOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function createTrigger(data) {
  http.setFormEncode();
  return http.post(`${apiEndpoint}`, data);
}

export function updateTrigger(id, data) {
  http.setFormEncode();
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function deleteTrigger(id) {
  return http.delete(`${apiEndpoint}/${id}`, {});
}

export function getTriggersSources() {
  return http.get(`${apiEndpoint}/sources`, {});
}
