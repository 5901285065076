import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelect from "components/ui/FormContent/formSelect";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import RedirectDialog from "components/ui/dialog/redirectDialog";
import ErrorMessageDialog from "pages/quotePage/dialog/errorMessageDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TicketIcon from "@material-ui/icons/Receipt";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import { DialogMessage } from "components/ui/core/dialogMessage";
import WorkticketBillingPartner from "./workticketBillingPartner";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import WorkticketComplianceChip from "components/ui/Worktickets/WorkticketComplianceChip";
import {
  convertDateFormatField,
  billingLimitDate,
} from "components/util/timeFormat";
import { getGeoLocation } from "components/util/geoUtil";
import {
  updateWorkticketDetails,
  updateWorkticketStatus,
  duplicateWorkticket,
  deleteWorkticket,
  assignUserWorkticket,
  removeUserWorkticket,
  createWorkticket,
} from "services/workticketService";

import { getFilterJobs } from "components/util/filterUtil";
import { getProjectDetails } from "services/projectService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { permissionWorkticket, hasPermission } from "lib/permissions";
import {
  workticketsStatus,
  workticketsRestrictStatus,
  workticketsManagedBy,
} from "constants.js";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const initNewData = {
  job_number: 0,
  woid: "",
  subject: "",
  description: "",
  managed_by: 2,
  start_date: moment(),
  priority: 1,
};

const WorkticketInformation = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { permissions, users } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openRedirect, setOpenRedirect] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgRedirect, setMsgRedirect] = useState("");
  const [linkRedirect, setLinkRedirect] = useState("");
  const [labelRedirect, setLabelRedirect] = useState("");
  const [hasCloseRedirect, setHasCloseRedirect] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [project, setProject] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [newData, setNewData] = useState(initNewData);
  const [openDone, setOpenDone] = useState(false);
  const [dateDone, setDateDone] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [confirmationDate, setConfirmationDate] = useState(false);
  const [confirmationIsLoading, setConfirmationIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const [openMessageError, setOpenMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const { workticket, isLoading } = stateContext ?? null;
  const { users: usersAssigned } = workticket;
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();
  const { refreshWorkticketData } = props;

  useEffect(() => {
    if (!isLoading && isLoadingUsers) {
      let assigned = [];
      let assignedId = [];
      usersAssigned.map((user) => {
        const userFound = users.find((uItem) => uItem.id === user.id);
        if (userFound) {
          assigned.push(userFound);
        }
        assignedId.push(user.id);
        return true;
      });
      setAssignedTo(assigned);
      let usersListInsert = [];
      if (
        workticket.recurrence_instance_id &&
        workticket.preventative_service
      ) {
        if (workticket.preventative_service[0].work_type === 2) {
          usersListInsert = users.filter(
            (user) =>
              user.tier_classification === "subcontractor" ||
              user?.role?.tier_classification === "subcontractor"
          );
        } else {
          usersListInsert = users.filter(
            (user) =>
              user.tier_classification !== "subcontractor" &&
              user?.role?.tier_classification !== "subcontractor"
          );
        }
      } else {
        usersListInsert = users;
      }
      setUsersList(usersListInsert);
      setIsLoadingUsers(false);
    }
  }, [workticket, isLoading, isLoadingUsers, usersAssigned, assignedTo, users]);

  useEffect(() => {
    if (workticket.id) {
      setIsLoadingUsers(true);
    }
  }, [workticket]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };

    const loadProject = async () => {
      if (props.id_project) {
        const resultProject = await getProjectDetails(props.id_project);
        setProject(resultProject.data.data.project);
      }
    };

    if (workticket === "new") {
      if (props.id_project === "0") {
        loadJob();
      } else {
        loadProject();
      }
    }
  }, [isLoading, props.id_project, workticket]);

  const handleBlur = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket[event.name] !== event.value) {
      try {
        const data = {
          [event.name]: event.value,
        };
        await updateWorkticketDetails(workticket.id, data);
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: { ...workticket, ...data },
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
        }
        logException(e, "Cannot update workticket");
      }
    }
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  const handleChangeStatus = async (event) => {
    if (!hasPermission(permissionWorkticket.STATUS_UPDATE, permissions)) {
      return;
    }
    if (workticket.status !== event.value) {
      if (event.value === 2) {
        setOpenDone(true);
      } else {
        try {
          let data = {
            status: event.value,
          };
          const position = await getGeoLocation();
          if (position) {
            data = {
              ...data,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
          }
          workticket.status = event.value;
          if (event.value === 3) {
            workticket.cancellation_reviewed = hasPermission(
              permissionWorkticket.UPDATE_CANCELLATION_STATUS,
              permissions
            )
              ? 1
              : 0;
          }
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: workticket,
          });
          await updateWorkticketStatus(workticket.id, data);
        } catch (e) {
          logException(e, "Cannot update workticket status");
        }
      }
    }
  };

  const handleChangeDueDate = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket.start_date !== event.value) {
      try {
        const data = {
          start_date: moment(event).format("YYYY-MM-DD"),
        };
        workticket.start_date = moment(event).format("YYYY-MM-DD");
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket due date");
      }
    }
  };

  const handleChangeAssignedTo = (event, value) => {
    const removeAssigned = [];
    const addAssigned = [];

    const assignedToId = assignedTo.map((item) => item.id);
    const editAssignedToId = value.map((item) => item.id);

    assignedTo.forEach((item) => {
      if (!editAssignedToId.includes(item.id)) {
        removeAssigned.push(item.id);
      }
    });

    value.forEach((item) => {
      if (!assignedToId.includes(item.id)) {
        addAssigned.push(item.id);
      }
    });

    addAssigned.forEach(async (user) => {
      try {
        const resultAssign = await assignUserWorkticket(workticket.id, {
          user_id: user,
        });
        if (resultAssign) {
          await refreshWorkticketData();
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });

    removeAssigned.forEach(async (user) => {
      try {
        await removeUserWorkticket(workticket.id, user);
        await refreshWorkticketData();
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });
    setAssignedTo(value);
  };

  const handleDuplicate = () => {
    setOpenDuplicate(true);
    setIsLoadingDuplicate(false);
  };

  const handleConvertToBillableProject = () => {
    dispatchGlobalUi({
      type: "SET_FLOW",
      createFlow: null,
    });
    dispatchGlobalUi({
      type: "SET_QUICKTICKET",
      quickTicket: workticket,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "project",
    });
  };

  const handleDuplicateConfirm = async () => {
    if (!hasPermission(permissionWorkticket.CLONE, permissions)) {
      return;
    }
    try {
      setIsLoadingDuplicate(true);
      const resultDuplicate = await duplicateWorkticket(workticket.id);
      setHasCloseRedirect(true);
      setOpenRedirect(true);
      setOpenDuplicate(false);
      setMsgRedirect(
        `Workticket ${resultDuplicate.data.data.workticket.number} created.`
      );
      setLinkRedirect(`/workticket/${resultDuplicate.data.data.workticket.id}`);
      setLabelRedirect("Open Workticket");
    } catch (e) {
      logException(e, "Cannot duplicate workticket");
    }
  };

  const handleMarkDoneConfirmation = () => {
    setConfirmationDate(true);
  };

  const handleMarkDone = async () => {
    const doneStatus = 2;
    const completedStatus = 4;
    try {
      setConfirmationIsLoading(true);
      let data = { status: doneStatus };
      if (hasPermission(permissionWorkticket.SET_COMPLETED_DATE, permissions)) {
        data = {
          ...data,
          completed_date: moment(dateDone).format("YYYY-MM-DD"),
        };
      }
      const position = await getGeoLocation();
      if (position) {
        data = {
          ...data,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      await updateWorkticketStatus(workticket.id, data);
      workticket.status = hasPermission(
        permissionWorkticket.MARK_DONE,
        permissions
      )
        ? doneStatus
        : completedStatus;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setConfirmationIsLoading(false);
      setOpenDone(false);
      setMsgSuccess(`Workticket has been mark verified.`);
      setOpenSuccess(true);
      setDateDone(null);
      setConfirmationDate(false);
    } catch (e) {
      logException(e, "Cannot mark workticket as verified");
    }
  };

  const handleDelete = () => {
    setOpenDelete(true);
    setIsLoadingDelete(false);
  };

  const handleDeleteConfirm = async () => {
    if (!hasPermission(permissionWorkticket.DELETE, permissions)) {
      return;
    }
    try {
      setIsLoadingDelete(true);
      await deleteWorkticket(workticket.id);
      setOpenDelete(false);
      setHasCloseRedirect(false);
      setOpenRedirect(true);
      setMsgRedirect(`Workticket has been deleted.`);
      setLinkRedirect(`/worktickets`);
      setLabelRedirect("Okay");
    } catch (e) {
      logException(e, "Cannot delete workticket");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseRedirect = () => {
    setOpenRedirect(false);
  };

  const handleCancelCreate = () => {
    const { history } = props;
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [
        ...pageHeaderTabs.filter(
          (page) => page.link !== window.location.pathname
        ),
      ],
    });
    history.push(`/worktickets`);
  };

  const handleCreateBlur = async (event) => {
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
      setError([]);
    }
  };

  // const handleCreatePriority = async (priority) => {
  //   if (newData.priority !== priority) {
  //     const data = {
  //       ...newData,
  //       priority: priority,
  //     };
  //     setNewData(data);
  //   }
  // };

  const handleCreateDueDate = async (event) => {
    const data = {
      ...newData,
      start_date: event,
    };
    setNewData(data);
  };

  const handleCreateJob = (event, value) => {
    const data = {
      ...newData,
      job_number: value.job_number,
    };
    setNewData(data);
    setError([]);
  };

  const handleCreateManagedBy = (event) => {
    const data = {
      ...newData,
      managed_by: event.value,
    };
    setNewData(data);
    setError([]);
  };

  const handleCreate = async () => {
    if (!hasPermission(permissionWorkticket.CREATE, permissions)) {
      return;
    }

    setIsLoadingCreate(true);

    const projectParam =
      props.id_project === "0"
        ? {}
        : { project_id: props.id_project, job_number: project.job.job_number };

    // Validate  require fields
    let errorValidate = [];
    if (!newData.job_number && props.id_project === "0") {
      errorValidate.push({ key: "job_number", message: "Please select a job" });
    }

    if (!newData.subject) {
      errorValidate.push({ key: "subject", message: "Please enter a summary" });
    }

    if (!newData.managed_by) {
      errorValidate.push({
        key: "managed_by",
        message: "Please select managed by",
      });
    }

    if (errorValidate.length) {
      setError([...errorValidate]);
      setIsLoadingCreate(false);
      return;
    }

    const data = {
      ...newData,
      start_date: newData.start_date.format("YYYY-MM-DD"),
      workticket_category: 0,
      subject: newData.subject,
      item_subject: newData.subject,
      is_strict_scheduling: 1,
      manual: 1,
      ...projectParam,
    };
    try {
      const result = await createWorkticket(data);
      setMsgRedirect("Workticket has been created.");
      setLabelRedirect("Okay");
      setLinkRedirect(`/workticket/${result.data.data.workticket.id}`);
      setOpenRedirect(true);
      setIsLoadingCreate(false);
    } catch (e) {
      logException(e, "Cannot create workticket");
    }
  };

  const handleDoneOpen = () => {
    setOpenDone(true);
  };

  const handleDoneClose = () => {
    setOpenDone(false);
    setDateDone(null);
    setConfirmationDate(false);
  };

  const handleDuplicateClose = () => {
    setOpenDuplicate(false);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleAddCompleteDate = () => {
    setDateDone(workticket.sub_completed_date);
  };

  if ((isLoading || isLoadingUsers) && workticket !== "new") {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  if (
    workticket === "new" &&
    !hasPermission(permissionWorkticket.CREATE, permissions)
  ) {
    return "Access Denied";
  }

  const backDatePerm = hasPermission(
    permissionWorkticket.BACK_DATE,
    permissions
  );

  let minLimitDate = undefined;
  let minDateMessage = undefined;
  if (!backDatePerm) {
    minLimitDate = billingLimitDate(5);
    minDateMessage = "Date not allowed, contact financial/admin team.";
  }

  const markDonePerm = hasPermission(
    permissionWorkticket.MARK_DONE,
    permissions
  );

  const readOnlyWorkticket =
    (workticket?.recurrence_instance_id && !workticket?.instance_override) ||
    [2, 3, 5].includes(workticket?.status);

  // Get status dropdown option depending on permission and status
  const workticketsDisplayStatus = markDonePerm
    ? workticketsStatus.filter(
        (item) =>
          workticket.status === 4 ||
          (workticket.status !== 4 && item.value !== 4)
      )
    : workticketsRestrictStatus.filter(
        (item) =>
          (workticket.status === 4 && item.value !== 2) ||
          (workticket.status !== 4 && item.value !== 4)
      );

  return workticket !== "new" ? (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="workticket_number"
            label="Workticket Number"
            value={workticket.number}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          <WorkticketBillingPartner />
          {![2, 3, 5].includes(workticket.status) &&
          hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions) ? (
            <FormSelectChipsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={usersList}
              name="users"
              label="Assigned To"
              handleChange={handleChangeAssignedTo}
              multiple={true}
              value={assignedTo}
              readonly={readOnlyWorkticket}
            />
          ) : (
            <>
              {Boolean(assignedTo.length) && (
                <InputLabel className={classes.inputLabel}>
                  Assigned To
                </InputLabel>
              )}
              {assignedTo.map((option, index) => (
                <Chip
                  disabled={false}
                  key={index}
                  data-tag-index={index}
                  tabIndex={-1}
                  avatar={<Avatar>{option.first_name[0]}</Avatar>}
                  label={`${option.first_name} ${option.last_name}`}
                  className={classes.chipAuto}
                />
              ))}
            </>
          )}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            value={workticket.subject}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            value={workticket?.description ?? ""}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          {workticket.workticket_category === 4 && (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="external_woid"
              label="WOID"
              value={workticket.external_woid}
              handleBlur={handleBlur}
              readonly={!hasPermission(permissionWorkticket.EDIT, permissions)}
            />
          )}
          {hasPermission(permissionWorkticket.STATUS_UPDATE, permissions) &&
          ![5].includes(workticket.status) ? (
            <>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="status"
                label="Status"
                options={workticketsDisplayStatus}
                value={workticket.status}
                handleBlur={handleChangeStatus}
                changeValueControlled={true}
                readonly={[2].includes(workticket.status)}
              />
              {workticket.sub_completed_date ? (
                <Alert severity="info" className={classes.alertContainer}>
                  Marked as completed on{" "}
                  {convertDateFormatField(workticket.sub_completed_date)}
                </Alert>
              ) : null}
            </>
          ) : (
            <Box className={classes.containerPriority}>
              <WorkticketSoloStatusChip
                status={workticket.status}
                type={workticket.type}
                category={workticket.workticket_category}
                cancellation_reviewed={workticket.cancellation_reviewed}
              />
              {([2, 4, 5].includes(workticket.status) &&
                workticket.compliance_checked) ||
              workticket.recalled ? (
                <WorkticketComplianceChip
                  onSite={workticket.compliance_onsite}
                  onTime={workticket.compliance_ontime}
                  hasDocuments={workticket.compliance_document}
                />
              ) : null}
            </Box>
          )}

          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Job"
            value={`${workticket.job.job_number} - ${workticket.job.job_description}`}
            readonly={true}
          />
          {workticket.job_zone ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="job_zone"
              label="Campus Zone"
              value={workticket.job_zone.name}
              readonly={true}
            />
          ) : null}
          {workticket.area ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="area"
              label="Area"
              value={workticket.area}
              readonly={true}
            />
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="created_at"
            label="Created Date"
            value={convertDateFormatField(workticket.created_at)}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          {workticket?.external_start_date ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="external_start_date"
              label="Start Date"
              value={convertDateFormatField(workticket.external_start_date)}
              styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
              readonly={true}
            />
          ) : null}
          <FormInputDateAdo
            name="start_date"
            label="Due Date"
            value={workticket.start_date}
            handleChange={handleChangeDueDate}
            disabled={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
        </Box>
        <Box className={classes.containerInformationActions}>
          <Grid container spacing={1} justifyContent="flex-end">
            {workticket?.project ? (
              <>
                {workticket?.project.type === 0 &&
                hasPermission(permissionWorkticket.DELETE, permissions) ? (
                  <Grid item md={4} xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      className={classNames(
                        classes.button,
                        classes.buttonOutlined
                      )}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Grid>
                ) : null}
                {workticket?.project.type === 0 &&
                hasPermission(permissionWorkticket.CLONE, permissions) ? (
                  <Grid item md={4} xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      className={classNames(
                        classes.button,
                        classes.buttonOutlined
                      )}
                      onClick={handleDuplicate}
                    >
                      Duplicate
                    </Button>
                  </Grid>
                ) : null}
              </>
            ) : null}
            {![2, 3, 5].includes(workticket.status) && markDonePerm ? (
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonOutlined)}
                  onClick={handleDoneOpen}
                >
                  Mark Verified
                </Button>
              </Grid>
            ) : null}
            {([0, 4].includes(workticket.workticket_category) ||
              workticket.external_woid) &&
              hasPermission(
                permissionWorkticket.CONVERT_TO_PROJECT,
                permissions
              ) && (
                <Grid
                  item
                  md={workticket.workticket_category === 4 ? 8 : 12}
                  xs={workticket.workticket_category === 4 ? 8 : 12}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth={true}
                    className={classNames(
                      classes.button,
                      classes.buttonOutlined
                    )}
                    onClick={handleConvertToBillableProject}
                  >
                    Convert to Billable Project
                  </Button>
                </Grid>
              )}
            {![2, 4, 5].includes(workticket.status) &&
            !markDonePerm &&
            hasPermission(permissionWorkticket.STATUS_UPDATE, permissions) ? (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonOutlined)}
                  onClick={handleDoneOpen}
                >
                  Mark Completed
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <ErrorMessageDialog
            title={""}
            open={openMessageError}
            handleClose={closeMessageError}
            message={message}
          />
        </Box>
      </Box>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={msgSuccess}
      />
      <RedirectDialog
        open={openRedirect}
        hasClose={hasCloseRedirect}
        handleClose={handleCloseRedirect}
        title="Success"
        message={msgRedirect}
        redirectLabel={labelRedirect}
        redirect={linkRedirect}
      />
      <Dialog
        open={openDone}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <DialogCloseIcon handleClose={handleDoneClose} />
          <DialogTitle title="Workticket" icon={<TicketIcon />} />
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <>
              {confirmationDate && dateDone ? (
                !confirmationIsLoading ? (
                  <DialogMessage>
                    Are you sure, the date{" "}
                    {moment(dateDone).format("MM/DD/YYYY")} is the date and
                    month that billing will be allocated to?
                  </DialogMessage>
                ) : (
                  <Box className={classes.formContainer}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                )
              ) : (
                <>
                  <DialogMessage>
                    Are you sure you want to mark workticket as{" "}
                    {markDonePerm ? `verified` : `completed`}?
                  </DialogMessage>
                  {workticket.sub_completed_date && (
                    <Alert
                      severity="info"
                      className={classes.alertContainer}
                      action={
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={handleAddCompleteDate}
                        >
                          Use Date +
                        </Button>
                      }
                    >
                      Marked completed on{" "}
                      {convertDateFormatField(workticket.sub_completed_date)}
                    </Alert>
                  )}

                  <Box className={classes.formContainer}>
                    <FormInputDateAdo
                      name="date"
                      label="Verified date*"
                      value={dateDone}
                      minDate={minLimitDate}
                      minDateMessage={minDateMessage}
                      handleChange={setDateDone}
                      disableFuture={true}
                      errorMessage={"Please select a valid work verify date"}
                    />
                    <Typography
                      variant="caption"
                      className={classes.formInfoText}
                    >
                      *If applicable, billing will be tied to this time frame.
                    </Typography>
                  </Box>
                </>
              )}
            </>
          ) : (
            <DialogMessage>
              Are you sure you want to mark workticket as{" "}
              {markDonePerm ? `verified` : `completed`}?
            </DialogMessage>
          )}
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleDoneClose}
            className={classes.buttonOutlinedDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <Button
              onClick={
                confirmationDate ? handleMarkDone : handleMarkDoneConfirmation
              }
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={!dateDone || confirmationIsLoading}
            >
              {confirmationDate ? `Yes` : `Verify`}
            </Button>
          ) : (
            <Button
              onClick={handleMarkDone}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDuplicate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <DialogCloseIcon handleClose={handleDuplicateClose} />
          <DialogTitle title="Workticket" icon={<TicketIcon />} />
          <DialogMessage>
            Are you sure you want to duplicate the workticket?
          </DialogMessage>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleDuplicateClose}
            className={classes.buttonOutlinedDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDuplicateConfirm}
            className={classes.buttonDialog}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isLoadingDuplicate}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <DialogCloseIcon handleClose={handleDeleteClose} />
          <DialogTitle title="Workticket" icon={<TicketIcon />} />
          <DialogMessage>
            Are you sure you want to delete the workticket?
          </DialogMessage>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleDeleteClose}
            className={classes.buttonOutlinedDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            className={classes.buttonDialog}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isLoadingDelete}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          {props.id_project === "0" ? (
            <FormSelectJobsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={jobs}
              name="job_number"
              label="Job"
              handleChange={handleCreateJob}
              value={jobs.find((job) => job.job_number === newData.job_number)}
              error={error}
            />
          ) : (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="job_number"
              label="Job"
              value={
                project
                  ? `${project.job.job_number} - ${project.job.job_description} `
                  : ``
              }
              readonly={true}
            />
          )}
          {hasPermission(permissionWorkticket.WORKTICKET_WOID, permissions) ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="woid"
              label="WOID"
              error={error}
              internal={false}
              value={""}
              handleBlur={handleCreateBlur}
            />
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            value={""}
            handleBlur={handleCreateBlur}
            error={error}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            value={""}
            multiline={true}
            rows={4}
            handleBlur={handleCreateBlur}
          />
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="managed_by"
            label="Managed By"
            value={newData.managed_by}
            options={workticketsManagedBy}
            handleBlur={handleCreateManagedBy}
            error={error}
          />
          <FormInputDateAdo
            name="start_date"
            label="Due Date"
            handleChange={handleCreateDueDate}
            value={newData.start_date}
          />
        </Box>
        <Box className={classes.containerInformationActions}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={6} sm={6} md={4}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={true}
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={handleCancelCreate}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4} className={classes.buttonWrapper}>
              {isLoadingCreate ? (
                <Box className={classes.centerLoadingSideBar}>
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonPrimary)}
                  onClick={handleCreate}
                  disabled={isLoadingCreate}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <RedirectDialog
        open={openRedirect}
        hasClose={false}
        handleClose={handleCloseRedirect}
        title="Success"
        message={msgRedirect}
        redirectLabel={labelRedirect}
        redirect={linkRedirect}
      />
    </>
  );
};

export default withRouter(WorkticketInformation);
