import Box from "@material-ui/core/Box";
import { Timeline } from "../icons/Timeline";
import Typography from "@material-ui/core/Typography";
import { dateFormatField } from "../../../components/util/timeFormat";

export function ActivityItem({ index, classes, activity }) {
  return (
    <Box key={index} className={classes.activityContainer}>
      <Box className={classes.timelineIconContainer}>
        <Timeline fill={"#747474"} />
      </Box>
      <Box className={classes.activitiesTextContainer}>
        <Typography className={classes.activitiesText}>
          {activity.activity_message}
        </Typography>
        <Typography className={classes.activitiesDate}>
          {dateFormatField(activity.created_at)}
        </Typography>
      </Box>
    </Box>
  );
}
