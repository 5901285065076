import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import BudgetBreadcrumb from "./budgetBreadcrumb";
import BudgetData from "./budgetData";
import { getBudgetDetails } from "services/budgetService";
import { useBudgetView } from "contexts/budgetViewContext";

import useStyles from "./styles";

const BudgetLayout = (props) => {
  const classes = useStyles();

  const context = useBudgetView();
  const dispatchContext = context[1];

  const { id, type } = props;

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        const result = await getBudgetDetails(id);
        dispatchContext({
          type: "SET_BUDGET",
          budget: result.data.data,
        });

        dispatchContext({
          type: "SET_LOADING",
          isLoading: false,
        });
      } catch (e) {
        console.log("Cannot load budget data");
      }
    };
    loadWorkticket();
  }, [id, dispatchContext]);

  return (
    <>
      <BudgetBreadcrumb type={type} />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          <BudgetData type={type} />
        </Grid>
      </Grid>
    </>
  );
};

export default BudgetLayout;
