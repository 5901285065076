import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import InputLabel from "@material-ui/core/InputLabel";

import { convertDateFormatField } from "../../components/util/timeFormat";

import useStyles from "./styles";

const ProjectBillingHistory = props => {
  const classes = useStyles();
  const { historyList, text } = props;

  const HistoryItem = props => (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={classes.avatarHistory}>
          {props.history.user.first_name.charAt(0)}
          {props.history.user.last_name.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listText}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.listName}
              color="textPrimary"
            >
              {props.history.user.first_name} {props.history.user.last_name}
            </Typography>
            {`${
              props.history.notes ? props.history.notes : text
            } - $${parseFloat(props.history.total_amount).toFixed(2)}`}
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(props.history.created_at)}
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <>
      <InputLabel htmlFor="historyList" className={classes.inputLabelP}>
        History
      </InputLabel>
      <Box name="historyList" className={classes.containerhistory}>
        <List className={classes.containerhistoryList}>
          {historyList.map(history => (
            <HistoryItem key={history.id} history={history} />
          ))}
          {!historyList.length && "N/A"}
        </List>
      </Box>
    </>
  );
};

export default ProjectBillingHistory;
