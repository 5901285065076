import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ProjectIcon from "@material-ui/icons/Work";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import FormSelectCheckboxJobsAuto from "components/ui/FormContent/formSelectCheckboxJobsAuto";
import { useProjectView } from "contexts/projectViewContext";
import { useJobsDispatch } from "contexts/projectJobsContext";
import { getJobsCustomer } from "services/jobService";
import {
  addJobsProjectPreventative,
  getPreventativeServices,
} from "services/projectService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  wrapperDialog: {
    textAlign: "center",
  },
  actionsDialogWrapper: {
    justifyContent: "center",
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 11,
    height: 11,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
}));

const JobsListActions = (props) => {
  const classes = useStyles();
  const [jobs, setJobs] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stateContextProject] = useProjectView();
  const dispatchJobs = useJobsDispatch();
  const { project } = stateContextProject ?? null;

  useEffect(() => {
    const loadJobs = async () => {
      setIsLoadingJob(true);
      const responseService = await getPreventativeServices(project.id);
      const activeJobs = responseService.data.data.jobs.map((job) => job.id);

      const resultJob = await getJobsCustomer(project.customer.id);
      const resultForRender = resultJob.data.data.jobs
        .filter((job) => !activeJobs.includes(job.id))
        .map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
          };
        });
      setJobs(resultForRender);
      setIsLoadingJob(false);
    };
    if (open && !jobs.length) {
      loadJobs();
    }
    if (!open) {
      setJobsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const dataInsert = {
        job_ids: jobsList.map((job) => job.id),
      };
      const result = await addJobsProjectPreventative(project.id, dataInsert);

      const projectUpdate = result.data.data.project;
      dispatchJobs({
        type: "SET_JOBS_LIST",
        jobList: projectUpdate?.preventative_jobs ?? [],
      });
      dispatchJobs({
        type: "SET_JOBS_COUNT",
        jobCount: projectUpdate?.preventative_jobs.length ?? 0,
      });

      setJobsList([]);
      setOpen(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const changeJobList = (event, value) => {
    setJobsList(value ?? []);
  };

  return (
    <Box className={classes.root}>
      <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
        Add Job
      </ButtonBase>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <ProjectIcon className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Add jobs
          </Typography>
          {!isLoadingJob ? (
            jobs.length ? (
              <FormSelectCheckboxJobsAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={jobs}
                name="job_list"
                value={jobsList}
                handleChange={changeJobList}
                multiple={true}
              />
            ) : null
          ) : (
            <LinearProgress color="secondary" />
          )}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}>
          <ButtonBase
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            onClick={handleConfirm}
            color="secondary"
            disabled={isLoading}
          >
            Save
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobsListActions;
