import React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";

import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";

const EnhancedTableCollapsibleToolbar = (props) => {
  const classes = useStyles();
  const { selected } = useTableCollapsibleState();

  return (
    <Toolbar
      className={clsx(classes.toolBar, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      {props.children}
    </Toolbar>
  );
};

export default EnhancedTableCollapsibleToolbar;
