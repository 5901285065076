import React, { useState, useEffect, useMemo } from "react";
import TableCollapsible from "components/common/Table/tableCollapsible/tableCollapsible";
import TableCollapsibleLoading from "components/common/Table/tableCollapsible/tableCollapsibleLoading";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import {
  getCustomerParents,
  getCustomerChildrenJob,
  getCustomerParentsSearch,
} from "services/customerService";
import EnhancedTableToolbar from "components/common/Table/tableCollapsible/tableCollapsibleToolbar";
import {
  useCustomersState,
  useCustomersDispatch,
  getCustomersList,
} from "contexts/adminCustomersContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import {
  useTableCollapsibleDispatch,
  useTableCollapsibleState,
} from "contexts/tableCollapsibleContext";
import ToolbarDefault from "components/common/Table/tableCollapsible/tableCollapsibleToolbarDefault";
import JobsTableActions from "./jobsTableActions";
import { useCustomerView } from "contexts/customerViewContext";
import { getCustomerMetadata } from "services/customerService";
import AlertDialog from "pages/adminCustomers/dialog/alertDialog";
import Typography from "@material-ui/core/Typography";
import TableCollapsiblePagination from "components/common/Table/tableCollapsible/tableCollapsiblePagination";
import JobsFilters from "./jobsFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const header = [
  [
    {
      label: "Name",
      colSpan: 2,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Number",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Primary Director",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Primary Manager",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Labor Budget",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Supplies Budget",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Delivery Type",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Industry",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Last Updated",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
  ],
];

const GeneralJobsTable = (props) => {
  const classes = useStyles();
  const dispatchTable = useTableCollapsibleDispatch();
  const { rows } = useTableCollapsibleState();
  const [stateContext] = useCustomerView();
  const { customer } = stateContext ?? null;
  const { customersListLoading, customersList } = useCustomersState();
  const filterState = useFilterState();
  const dispatchCustomers = useCustomersDispatch();
  const dispatchFilters = useFilterDispatch();
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      const result = await getCustomerMetadata(1);
      const { delivery_types, industries } = result.data.data;
      if (delivery_types?.length) {
        const deliveryForRender = delivery_types.map((value) => {
          return { value: value.id, label: value.name };
        });
        setDeliveryTypes(deliveryForRender);
      }
      if (industries?.length) {
        const industriesForRender = industries.map((value) => {
          return { value: value.id, label: value.name };
        });
        setIndustries(industriesForRender);
      }
    };
    loadOptions();
  }, []);

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const columns = useMemo(
    () => [
      [
        {
          id: "name",
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
        },
        {
          id: "customer_number",
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_director",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_director ? (
                <Typography variant="body2" noWrap>
                  {row.primary_director}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this customer." />
              )}
              {row.primary_director &&
              (row.no_director_alert > 0 || row.inactive_director_alert > 0) ? (
                <AlertDialog message="There are missing assigments for the jobs for this customer." />
              ) : null}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_manager",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_manager ? (
                <Typography variant="body2" noWrap>
                  {row.primary_manager}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this customer." />
              )}
              {row.primary_manager &&
              (row.no_manager_alert > 0 || row.inactive_manager_alert > 0) ? (
                <AlertDialog message="There are missing assigments for the jobs for this customer." />
              ) : null}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "labor_budget",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.labor_budget && row.labor_budget >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`${formatNumber(
                    parseFloat(row.labor_budget).toFixed(2)
                  )} hrs/week`}
                </Typography>
              ) : row.no_labor_alert > 0 ? (
                <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
              ) : (
                <AlertDialog message="There are missing labor budget for this customer." />
              )}
              {row.labor_budget &&
              row.labor_budget >= 0 &&
              row.no_labor_alert > 0 ? (
                <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
              ) : null}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "supplies_budget",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.supplies_budget && row.supplies_budget >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`$ ${formatNumber(
                    parseFloat(row.supplies_budget).toFixed(2)
                  )}
                  `}
                </Typography>
              ) : row.no_supplies_alert > 0 ? (
                <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
              ) : (
                <AlertDialog message="There are missing labor budget for this customer." />
              )}
              {row.supplies_budget &&
              row.supplies_budget >= 0 &&
              row.no_supplies_alert > 0 ? (
                <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
              ) : null}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "delivery",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery_id ? (
                <Typography variant="body2" noWrap>
                  {
                    deliveryTypes.find((item) => item.value === row.delivery_id)
                      ?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this customer." />
              )}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "industry",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry_id ? (
                <Typography variant="body2" noWrap>
                  {
                    industries.find((item) => item.value === row.industry_id)
                      ?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this customer." />
              )}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        { id: "updated_at", format: "date", style: { paddingLeft: 8 } },
        {
          id: "actions",
        },
      ],
      [
        {
          name: "_expander",
          style: {
            backgroundColor: "#F9F9F9",
          },
        },
        {
          id: "job_description",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
          content: (row) => (
            <Box className={classes.jobNameContainer}>
              <Box className={classes.jobIndicatorParent}></Box>
              {row.job_description}
            </Box>
          ),
        },
        {
          id: "job_number",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "director",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_director ? (
                <Typography variant="body2" noWrap>
                  {row.primary_director}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this parent job." />
              )}
              {(row.no_director_alert > 0 || row.inactive_director_alert > 0) &&
              row.primary_director ? (
                <AlertDialog message="There are missing assigments for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "manager",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_manager ? (
                <Typography variant="body2" noWrap>
                  {row.primary_manager}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this parent job." />
              )}
              {(row.no_manager_alert > 0 || row.inactive_manager_alert > 0) &&
              row.primary_manager ? (
                <AlertDialog message="There are missing assigments for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "labor_budget_all",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.labor_budget_all && row.labor_budget_all >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`${formatNumber(
                    parseFloat(row.labor_budget_all).toFixed(2)
                  )} hrs/week`}
                </Typography>
              ) : (
                <AlertDialog message="There are missing labor budget for this parent job." />
              )}
              {row.no_labor_alert > 0 &&
              row.labor_budget_all &&
              row.labor_budget_all >= 0 ? (
                <AlertDialog message="There are missing labor budgets for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "supplies_budget_all",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.supplies_budget_all && row.supplies_budget_all >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`$ ${formatNumber(
                    parseFloat(row.supplies_budget_all).toFixed(2)
                  )}`}
                </Typography>
              ) : (
                <AlertDialog message="There are missing supplies budget for this parent job." />
              )}
              {row.no_supplies_alert > 0 &&
              row.supplies_budget_all &&
              row.supplies_budget_all >= 0 ? (
                <AlertDialog message="There are missing supplies budgets for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "delivery",
          conntent: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery ? (
                <Typography variant="body2" noWrap>
                  {row.delivery}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "industry",
          conntent: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry ? (
                <Typography variant="body2" noWrap>
                  {row.industry}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "not_an_existing_date",
          format: "date",
          style: {
            backgroundColor: "#F9F9F9",
            paddingLeft: 8,
          },
        },
        {
          id: "actions",
          content: (row) => <JobsTableActions row={row} isForJob />,
          style: {
            backgroundColor: "#F9F9F9",
          },
        },
      ],
      [
        {
          name: "_expander",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
        },
        {
          id: "job_description",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
          content: (row) => (
            <Box className={classes.jobNameContainer}>
              <Box className={classes.jobIndicatorChild}></Box>
              {row.job_description}
            </Box>
          ),
        },
        {
          id: "job_number",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_director",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_director ? (
                <Typography variant="body2" noWrap>
                  {row.primary_director}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
              {row.primary_director && row.inactive_director_alert > 0 ? (
                <AlertDialog message="Director is inactive for this job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "primary_manager",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_manager ? (
                <Typography variant="body2" noWrap>
                  {row.primary_manager}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
              {row.primary_manager && row.inactive_manager_alert > 0 ? (
                <AlertDialog message="Manager is inactive for this job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "labor_budget",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.labor_budget && row.labor_budget >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`${formatNumber(
                    parseFloat(row.labor_budget).toFixed(2)
                  )} hrs/week`}
                </Typography>
              ) : (
                <AlertDialog message="There are missing labor budget for this job." />
              )}
            </Box>
          ),
        },
        {
          id: "supplies_budget",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.supplies_budget && row.supplies_budget >= 0 ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`$ ${formatNumber(
                    parseFloat(row.supplies_budget).toFixed(2)
                  )}`}
                </Typography>
              ) : (
                <AlertDialog message="There are missing labor budget for this job." />
              )}
            </Box>
          ),
        },
        {
          id: "delivery",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery ? (
                <Typography variant="body2" noWrap>
                  {
                    deliveryTypes.find(
                      (item) => item.value === parseInt(row.delivery)
                    )?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
            </Box>
          ),
        },
        {
          id: "industry",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry ? (
                <Typography variant="body2" noWrap>
                  {
                    industries.find(
                      (item) => item.value === parseInt(row.industry)
                    )?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assigments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "not_an_existing_date",
          format: "date",
          style: {
            backgroundColor: "#EFEFEF",
            paddingLeft: 8,
          },
        },
        {
          id: "actions",
          content: (row) => <JobsTableActions row={row} isForJob />,
          style: {
            backgroundColor: "#EFEFEF",
          },
        },
      ],
    ],
    [deliveryTypes, industries]
  );

  const getChildrenJobs = async (row) => {
    const response = await getCustomerChildrenJob(row.job_id);
    const { jobs } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: false,
        columnIndex: 2,
      };
    });
    return listForRender;
  };

  const parentJobPageChange = async (row, rows, newPage, countIni) => {
    const index = rows.findIndex((r) => r.customer_id === row.customer_id);
    rows[index].childRows = [
      {
        hasExpander: false,
        columnIndex: 0,
        _content: <TableCollapsibleLoading />,
      },
      {
        hasExpander: false,
        columnIndex: 0,
        _content: (
          <TableCollapsiblePagination
            count={countIni}
            page={newPage}
            onChangePage={(event, newPage) =>
              parentJobPageChange(row, rows, newPage, countIni)
            }
          />
        ),
      },
    ];
    rows[index]._page = newPage;
    dispatchTable({ type: "UPDATE_ROWS", rows });
    const filterData = getFilterData(filterState);
    const response = await getCustomerParents(
      row.customer_id,
      newPage,
      filterData
    );
    const { jobs, count } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: j.children_count ? true : false,
        isOpen: false,
        childRows: [],
        getChildren: (row) => getChildrenJobs(row),
        columnIndex: 1,
      };
    });
    listForRender.push({
      hasExpander: false,
      columnIndex: 0,
      _content: (
        <TableCollapsiblePagination
          count={count}
          page={newPage}
          onChangePage={(event, newPage) =>
            parentJobPageChange(row, rows, newPage, count)
          }
        />
      ),
    });
    rows[index].childRows = listForRender;
    dispatchTable({ type: "UPDATE_ROWS", rows });
  };

  const getParentJobs = async (row, rows) => {
    const filterData = getFilterData(filterState);
    const response = await getCustomerParents(row.customer_id, 0, filterData);
    const { jobs, count } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: j.has_children ? true : false,
        isOpen: false,
        childRows: [],
        getChildren: (row) => getChildrenJobs(row),
        columnIndex: 1,
      };
    });
    if (listForRender.length > 0) {
      listForRender.push({
        hasExpander: false,
        columnIndex: 0,
        _content: (
          <TableCollapsiblePagination
            count={count}
            page={row._page}
            onChangePage={(event, newPage) =>
              parentJobPageChange(row, rows, newPage, count)
            }
          />
        ),
      });
    }
    return listForRender;
  };

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [...filterData.filters];
      filterData.filters.push({
        filter: "customer",
        group: "Customer",
        values: [{ label: "", value: customer.id }],
      });
      await getCustomersList(dispatchCustomers, filterData, getParentJobs);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchCustomers, filterState]);

  useEffect(() => {
    const loadData = async () => {
      if (customersList.length) {
        customersList[0].isOpen = true;
        customersList[0].childRows = [
          {
            hasExpander: false,
            columnIndex: 0,
            _content: <TableCollapsibleLoading />,
          },
        ];
        dispatchTable({ type: "UPDATE_ROWS", rows: customersList });
        customersList[0].childRows = await getParentJobs(
          customersList[0],
          customersList
        );
      }
      dispatchTable({ type: "UPDATE_ROWS", rows: customersList });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      dispatchTable({ type: "UPDATE_HEADER", header });
    };
    if (customersList) loadData();
  }, [dispatchTable, customersList]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          rows[0].childRows = [
            {
              hasExpander: false,
              columnIndex: 0,
              _content: <TableCollapsibleLoading />,
            },
          ];
          dispatchTable({ type: "UPDATE_ROWS", rows });
          const filterData = getFilterData(filterState);
          const response = await getCustomerParentsSearch(
            customer.id,
            query,
            filterData
          );
          const { jobs } = response.data.data;
          const listForRender = jobs.map((j) => {
            return {
              ...j,
              hasExpander: true,
              isOpen: false,
              childRows: [],
              getChildren: (row) => getChildrenJobs(row),
              columnIndex: 1,
            };
          });
          rows[0].childRows = listForRender;
          dispatchTable({ type: "UPDATE_ROWS", rows });
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    // <Box>
    <Box className={classes.root}>
      {customersListLoading ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <Box className={classes.contentContainer}>
          <Box className={classes.rootContainer}>
            <Box className={classes.tableContainer}>
              <Box className={classes.fullHeightTable}>
                <TableCollapsible>
                  <EnhancedTableToolbar>
                    <ToolbarDefault
                      handleSearch={handleSearch}
                      placeholderSearch={"All Jobs"}
                      handleSearchClear={handleSearchClear}
                    >
                      <JobsFilters />
                    </ToolbarDefault>
                  </EnhancedTableToolbar>
                  {filterState.filters && <FiltersAppliedList />}
                </TableCollapsible>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    // </Box>
  );
};

export default GeneralJobsTable;
