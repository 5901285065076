import React from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import WTIcon from "@material-ui/icons/Receipt";
import Button from "@material-ui/core/Button";

import { useQuoteState } from "contexts/quoteSurfaceContext";

import useStyles from "./styles";

const QuoteSurfaceWorktickets = props => {
  const classes = useStyles();
  const stateContext = useQuoteState();

  const { quoteDetails, selectedQuote, isLoading } = stateContext
    ? stateContext
    : null;

  const handleView = link => {
    const { history } = props;
    history.push(link);
  };

  if (isLoading || !selectedQuote || selectedQuote === "new") {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyWTList}>
      <Typography variant="h4" className={classes.titleSection} gutterBottom>
        Related Worktickets
      </Typography>
      <Grid container className={classes.itemsContainer}>
        {quoteDetails.worktickets.map(wt => (
          <>
            <Grid item xs={1} md={1} lg={1}>
              <WTIcon />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className={classes.titleSectionBody} gutterBottom>
                {wt.number}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button
                variant="outlined"
                color="primary"
                className={classNames(
                  classes.buttonView,
                  classes.buttonOutlined
                )}
                onClick={() => handleView(`/workticket/surface/${wt.id}`)}
              >
                View
              </Button>
            </Grid>
          </>
        ))}
        {quoteDetails.worktickets.length === 0 && (
          <Box className={classes.containerEmpty}>
            <Typography variant="h5" gutterBottom>
              No Worktickets have been created.
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default withRouter(QuoteSurfaceWorktickets);
