import * as React from "react";
import * as classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});

const Container = ({ classes, className, ...props }) => (
  <div className={classnames(classes.container, className)} {...props} />
);
export default withStyles(styles)(Container);
