import React, { useEffect, useState } from "react";
import { ButtonBase } from "components/ui/core/button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import QuestionIcon from "@material-ui/icons/Help";
import { deleteCustomerContact } from "services/customerService";
import { useCustomerView } from "contexts/customerViewContext";
import { useListState, useListDispatch } from "contexts/listContext";

import useStyles from "../styles";

const ContactsDelete = (props) => {
  const classes = useStyles();
  const { selected, selectedAction } = useListState();
  const [stateContext] = useCustomerView();
  const dispatchList = useListDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const { customer } = stateContext ?? null;

  useEffect(() => {
    if (selectedAction === "DELETE") {
      setOpenDelete(true);
    }
  }, [selectedAction]);

  const handleCloseDelete = () => {
    dispatchList({
      type: "SET_SELECTED",
      selected: null,
    });
    dispatchList({
      type: "SET_SELECTED_ACTION",
      selectedAction: null,
    });
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingDelete(true);
      await deleteCustomerContact(customer.id, selected.id, selected.type);
      dispatchList({
        type: "SET_SELECTED",
        selected: null,
      });
      dispatchList({
        type: "SET_SELECTED_ACTION",
        selectedAction: null,
      });
      dispatchList({
        type: "SET_DUMMY_REFRESH",
        dummyRefresh: Date.now(),
      });
      setOpenDelete(false);
      setIsLoadingDelete(false);
    } catch (e) {
      console.log("Cannot delete contact");
    }
  };

  return (
    <Dialog
      open={openDelete}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperDialog}>
        <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <QuestionIcon className={classes.iconDialog} />
        <Typography variant="h6" gutterBottom className={classes.titleDialog}>
          Delete Contact
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.contentDialog}
        >
          Are you sure you want to delete the contact?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.wrapperDialogAction}>
        <ButtonBase
          onClick={handleCloseDelete}
          className={classes.button}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          onClick={handleConfirmDelete}
          className={classes.button}
          color="secondary"
          disabled={isLoadingDelete}
        >
          Confirm
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
};
export default ContactsDelete;
