import React, { useContext } from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useRingBellDispatch } from "contexts/ringBellContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionTalent, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const RingBellBulkActions = (props) => {
  const classes = useStyles();
  const dispatchRingBell = useRingBellDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleOpenRingBell = () => {
    dispatchRingBell({
      type: "TOGGLE_RINGBELL",
      openRingBell: true,
    });
  };

  const handleOpenAnnouncement = () => {
    dispatchRingBell({
      type: "TOGGLE_ANNOUNCEMENT",
      openAnnouncement: true,
    });
  };

  return (
    <div className={classes.root}>
      {hasPermission(permissionTalent.ANNOUNCEMENTS, permissions) ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.button, classes.buttonSecondary)}
          onClick={handleOpenAnnouncement}
        >
          Message
        </Button>
      ) : null}
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classNames(classes.button, classes.buttonSecondary)}
        onClick={handleOpenRingBell}
      >
        Ring Bell
      </Button>
    </div>
  );
};

export default RingBellBulkActions;
