export function NotepadIcon() {
  return (
    <svg
      width="49"
      height="72"
      viewBox="0 0 49 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_595_7662)">
        <path
          d="M45.0377 6.41992H3.96235C2.05015 6.41992 0.5 7.96385 0.5 9.86838V68.5516C0.5 70.4561 2.05015 72 3.96235 72H45.0377C46.9499 72 48.5 70.4561 48.5 68.5516V9.86838C48.5 7.96385 46.9499 6.41992 45.0377 6.41992Z"
          fill="#D6ECF0"
        />
        <path
          d="M44.3455 9.06299H4.83789V66.2224H44.3455V9.06299Z"
          fill="#F8F8F8"
        />
        <path
          d="M44.346 9.06348C44.3581 31.3527 45.0807 58.2342 42.5669 64.1859C31.4945 65.2605 18.5303 65.6053 4.83789 65.6693L10.0426 9.23562L44.346 9.06348Z"
          fill="#EDF5F8"
        />
        <path
          d="M40.7972 62.3845C23.5395 65.1356 12.0055 65.2803 2.65039 64.5885C3.86258 51.7357 4.34849 30.3833 4.83765 9.06567L25.6336 9.11161L44.4548 10.3491C44.7292 32.475 44.759 52.9129 40.7972 62.3845Z"
          fill="white"
        />
        <path
          d="M34.6983 14.8228L13.915 14.335L13.8404 17.4879L34.6237 17.9757L34.6983 14.8228Z"
          fill="#EDF5F8"
        />
        <path
          d="M38.7045 22.5389L19.958 22.0989L19.9239 23.542L38.6704 23.982L38.7045 22.5389Z"
          fill="#4F98BC"
        />
        <path
          d="M32.626 25.5449L19.8838 25.2458L19.8496 26.689L32.5918 26.988L32.626 25.5449Z"
          fill="#EDF5F8"
        />
        <path
          d="M14.0513 22.0453L9.61328 21.9412L9.50869 26.3614L13.9467 26.4656L14.0513 22.0453Z"
          fill="white"
          stroke="#EDF5F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M10.3375 23.1034C10.4744 23.6259 10.7274 25.8141 11.8278 25.2026C12.0817 25.0615 12.2965 24.6885 12.4861 24.4736L13.5096 23.3159L15.5571 21.001C15.7318 20.8034 14.9598 20.3074 14.7404 20.5556C14.1338 21.2414 13.5273 21.9277 12.9207 22.6135C12.6174 22.9563 12.3142 23.2992 12.0109 23.6421C11.8003 23.8802 11.3135 24.6476 11.0013 24.7158C11.1714 24.7706 11.341 24.8249 11.511 24.8796C11.6312 24.9775 11.408 24.2825 11.4029 24.2634C11.3181 23.9405 11.2338 23.618 11.149 23.2951C11.0791 23.0283 10.234 22.7076 10.3379 23.103L10.3375 23.1034Z"
          fill="#4F98BC"
        />
        <path
          d="M35.205 30.4971L19.7676 30.1348L19.7334 31.5779L35.1709 31.9402L35.205 30.4971Z"
          fill="#4F98BC"
        />
        <path
          d="M38.4399 33.7213L19.6934 33.2812L19.6592 34.7244L38.4057 35.1644L38.4399 33.7213Z"
          fill="#EDF5F8"
        />
        <path
          d="M13.8609 30.0812L9.42285 29.9771L9.31826 34.3973L13.7563 34.5015L13.8609 30.0812Z"
          fill="white"
          stroke="#EDF5F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M10.1441 31.2643C10.2811 31.7868 10.534 33.975 11.6344 33.3635C11.8883 33.2224 12.1031 32.8494 12.2927 32.6345C12.6337 32.2485 12.9752 31.8629 13.3162 31.4768L15.3637 29.1619C15.5384 28.9643 14.7665 28.4683 14.5471 28.7165C13.9405 29.4023 13.3339 30.0885 12.7274 30.7743C12.4241 31.1172 12.1208 31.4601 11.8175 31.803C11.6069 32.0411 11.1201 32.8085 10.808 32.8767C10.978 32.9315 11.1476 32.9858 11.3176 33.0405C11.4378 33.1384 11.2147 32.4434 11.2096 32.4243C11.1248 32.1014 11.0404 31.7789 10.9557 31.456C10.8858 31.1892 10.0407 30.8685 10.1446 31.2639L10.1441 31.2643Z"
          fill="#4F98BC"
        />
        <path
          d="M38.3256 38.6109L19.5791 38.1709L19.545 39.614L38.2915 40.054L38.3256 38.6109Z"
          fill="#4F98BC"
        />
        <path
          d="M27.8877 41.5144L19.5039 41.3176L19.4698 42.7607L27.8536 42.9575L27.8877 41.5144Z"
          fill="#EDF5F8"
        />
        <path
          d="M13.6714 38.1169L9.2334 38.0127L9.12881 42.4329L13.5669 42.5371L13.6714 38.1169Z"
          fill="white"
          stroke="#EDF5F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M10.0152 39.3573C10.1522 39.8798 10.4051 42.068 11.5055 41.4565C11.7594 41.3154 11.9742 40.9424 12.1638 40.7275C12.5048 40.3415 12.8463 39.9559 13.1873 39.5699C13.8698 38.7982 14.5523 38.0266 15.2348 37.255C15.4095 37.0573 14.6376 36.5613 14.4181 36.8095C13.8116 37.4953 13.205 38.1816 12.5985 38.8674C12.2952 39.2103 11.9919 39.5532 11.6886 39.8961C11.478 40.1341 10.9912 40.9015 10.6791 40.9697C10.8491 41.0245 11.0187 41.0788 11.1887 41.1335C11.3089 41.2314 11.0858 40.5364 11.0807 40.5173C10.9959 40.1944 10.9115 39.8719 10.8268 39.549C10.7569 39.2822 9.91178 38.9616 10.0157 39.3569L10.0152 39.3573Z"
          fill="#4F98BC"
        />
        <path
          d="M38.1352 46.6473L19.3887 46.2073L19.3545 47.6504L38.1011 48.0904L38.1352 46.6473Z"
          fill="#4F98BC"
        />
        <path
          d="M34.1236 49.7014L19.3145 49.3538L19.2803 50.7969L34.0895 51.1445L34.1236 49.7014Z"
          fill="#EDF5F8"
        />
        <path
          d="M13.481 46.1532L9.04297 46.0491L8.93838 50.4693L13.3764 50.5735L13.481 46.1532Z"
          fill="white"
          stroke="#EDF5F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M9.82673 47.341C9.9637 47.8635 10.2167 50.0517 11.317 49.4401C11.5709 49.2991 11.7857 48.926 11.9753 48.7112L12.9988 47.5535C13.6813 46.7819 14.3638 46.0102 15.0463 45.2386C15.221 45.0409 14.4491 44.5449 14.2297 44.7932C13.6231 45.4789 13.0165 46.1652 12.41 46.851C12.1067 47.1939 11.8034 47.5368 11.5001 47.8797C11.2896 48.1177 10.8027 48.8852 10.4906 48.9534C10.6606 49.0081 10.8302 49.0624 11.0003 49.1172C11.1205 49.2151 10.8973 48.52 10.8922 48.501C10.8074 48.178 10.7231 47.8556 10.6383 47.5326C10.5684 47.2658 9.72331 46.9452 9.8272 47.3405L9.82673 47.341Z"
          fill="#4F98BC"
        />
        <path
          d="M26.6301 54.3568L19.1992 54.1824L19.1651 55.6255L26.596 55.7999L26.6301 54.3568Z"
          fill="#4F98BC"
        />
        <path
          d="M37.8734 57.768L19.1268 57.3286L19.0928 58.7717C26.3454 58.941 35.5804 59.2797 37.4648 58.9916C37.7509 58.6677 37.9051 58.2701 37.8734 57.768Z"
          fill="#EDF5F8"
        />
        <path
          d="M13.3541 54.1298L8.91602 54.0256L8.81143 58.4459L13.2495 58.55L13.3541 54.1298Z"
          fill="white"
          stroke="#EDF5F8"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M9.63872 55.2546C9.77568 55.777 10.0287 57.9653 11.129 57.3537C11.3829 57.2126 11.5977 56.8396 11.7873 56.6248L12.8108 55.4671C13.4933 54.6954 14.1758 53.9238 14.8583 53.1522C15.033 52.9545 14.2611 52.4585 14.0412 52.7067C13.4346 53.3925 12.8281 54.0788 12.2215 54.7646C11.9182 55.1075 11.6149 55.4504 11.3117 55.7937C11.1011 56.0318 10.6143 56.7992 10.3021 56.8674C10.4722 56.9222 10.6417 56.9765 10.8118 57.0312C10.932 57.1291 10.7088 56.434 10.7037 56.415C10.6189 56.0921 10.5346 55.7696 10.4498 55.4467C10.3799 55.1799 9.53483 54.8592 9.63872 55.2546Z"
          fill="#4F98BC"
        />
        <path
          d="M33.3978 6.81711V11.2288H15.6016V6.81711C15.6016 6.11368 15.8885 5.47707 16.3507 5.01493C16.4084 4.95739 16.4681 4.90357 16.5314 4.85345C16.9731 4.48782 17.5414 4.26881 18.1601 4.26881C18.6241 4.26881 19.0695 4.16302 19.4683 3.97185C19.6416 3.88833 19.8056 3.78996 19.9602 3.67675C20.469 3.30183 20.8584 2.76916 21.0522 2.13441C21.2498 1.48666 21.6541 0.944707 22.1778 0.567938C22.6809 0.207873 23.294 0 23.9444 0H25.055C25.7054 0 26.3184 0.207873 26.8216 0.567938C27.3452 0.944707 27.7496 1.48666 27.9471 2.13441C28.1409 2.76916 28.5304 3.30183 29.0391 3.67675C29.1938 3.78996 29.3578 3.88833 29.5311 3.97185C29.9299 4.16302 30.3752 4.26881 30.8392 4.26881C31.4579 4.26881 32.0263 4.48782 32.4679 4.85345C33.0363 5.31931 33.3978 6.02645 33.3978 6.81711Z"
          fill="#4F98BC"
        />
        <path
          d="M34.8419 9.06299V12.3425H14.1572V9.06299H15.6014V11.2289H33.3977V9.06299H34.8419Z"
          fill="#E4F3F5"
        />
        <path
          d="M24.5002 4.13831C25.372 4.13831 26.0786 3.46939 26.0786 2.64423C26.0786 1.81907 25.372 1.15015 24.5002 1.15015C23.6285 1.15015 22.9219 1.81907 22.9219 2.64423C22.9219 3.46939 23.6285 4.13831 24.5002 4.13831Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_595_7662">
          <rect
            width="48"
            height="72"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
