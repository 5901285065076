import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    zIndex: 2,
    background: "#ffffff",
  },
  pageHeader: {
    fontSize: 16,
    margin: 0,
    display: "inline-block",
    verticalAlign: "top",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
    padding: "8px 20px",
  },
  separator: {
    margin: 0,
  },
  breadcrumb: {
    fontSize: 14,
  },
  breadcrumbLink: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#4f98bc",
    cursor: "pointer",
    "& a": {
      color: "#4f98bc",
    },
  },
  breadcrumbActive: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#747474",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
