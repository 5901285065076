import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useRoleView } from "contexts/roleViewContext";

const RoleBreadcrumb = () => {
  const [stateContext] = useRoleView();
  const { role, isLoading } = stateContext ? stateContext : null;

  if (isLoading && role !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }
  
  return (
    <PageHeader pageTitle={role.id ? `${role.name}`: "New"}>
      <Link to="/roles">Roles</Link>
    </PageHeader>
  );
};

export default RoleBreadcrumb;
