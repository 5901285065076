import React from "react";
import {
  getQuestionsList,
  getQuestionsListSearch,
  getQuestionsListOffset,
} from "services/surveysQuestionsService";
import { merge } from "lodash";

const defaultState = {
  surveysQuestionsLoading: true,
  surveysQuestionSelected: null,
  surveysQuestionList: [],
  surveysQuestionsCount: [],
  openSurveysQuestionDelete: false,
  openManageQuestion: false,
};

const SurveysQuestionsStateContext = React.createContext();
const SurveysQuestionsDispatchContext = React.createContext();

function surveysQuestionsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        surveysQuestionsLoading: action.surveysQuestionsLoading,
      };
    case "SET_QUESTIONS_LIST":
      return {
        ...state,
        surveysQuestionList: action.surveysQuestionList,
      };
    case "SET_QUESTIONS_COUNT":
      return {
        ...state,
        surveysQuestionsCount: action.surveysQuestionsCount,
      };
    case "SET_QUESTIONS_SELECTED":
      return {
        ...state,
        surveysQuestionSelected: action.surveysQuestionSelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openSurveysQuestionDelete: action.openSurveysQuestionDelete,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageQuestion: action.openManageQuestion,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveysQuestionsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(
    surveysQuestionsReducer,
    mergedState
  );
  return (
    <SurveysQuestionsStateContext.Provider value={state}>
      <SurveysQuestionsDispatchContext.Provider value={dispatch}>
        {children}
      </SurveysQuestionsDispatchContext.Provider>
    </SurveysQuestionsStateContext.Provider>
  );
}

function useSurveysQuestionsState() {
  const context = React.useContext(SurveysQuestionsStateContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysQuestionsState must be used within a SurveysQuestionsProvider"
    );
  }
  return context;
}

function useSurveysQuestionsDispatch() {
  const context = React.useContext(SurveysQuestionsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysQuestionsDispatch must be used within a SurveysQuestionsProvider"
    );
  }
  return context;
}

async function getSurveysQuestionsList(dispatch, filterData, noLoading) {
  try {
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        surveysQuestionsLoading: true,
      });
    }
    const result = await getQuestionsList(filterData);
    dispatch({
      type: "SET_QUESTIONS_LIST",
      surveysQuestionList: [...result.data.data.list],
    });
    dispatch({
      type: "SET_QUESTIONS_COUNT",
      surveysQuestionsCount: result.data.data.filter_count,
    });
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        surveysQuestionsLoading: false,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function getSurveysQuestionsListDataSet(
  surveysQuestionsList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getQuestionsListOffset(offset, filterData);
    const updateData = surveysQuestionsList.concat(result.data.data.list);
    console.log(updateData);
    dispatch({
      type: "SET_QUESTIONS_LIST",
      surveysQuestionList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchSurveysQuestionsList(search, filterData) {
  try {
    const result = await getQuestionsListSearch(search, filterData);
    return result.data.data.list;
  } catch (error) {
    console.log(error);
  }
}

export {
  SurveysQuestionsProvider,
  useSurveysQuestionsState,
  useSurveysQuestionsDispatch,
  getSurveysQuestionsListDataSet,
  getSurveysQuestionsList,
  searchSurveysQuestionsList,
};
