import React, { useState, useEffect } from "react";
import eosLove from "../../assets/icons/encompass-love.png";
import auth from "services/authService";
import MainLayoutLogin from "components/common/MainLayoutLogin";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { errorArray } from "components/util/error";
import useStyles from "./loginForm.styles";
import Joi from "joi-browser";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const schema = Joi.object({
  password: Joi.string().min(12).required().label("Password"),
  confirmPassword: Joi.string().min(12).required().label("Confirm Password"),
});

const path = "/dashboard";

const CreatePasswordForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasPageError, setHasPageError] = useState("");
  const [hasPageErrorStatus, setHasPageErrorStatus] = useState(0);

  const handleChange = ({ currentTarget: input }) => {
    const dataInput = { ...data };
    dataInput[input.name] = input.value;
    setData(dataInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCreatePassword(event);
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) {
      window.location = path;
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (hasError) {
      const errorResult = errorArray(schema, data);
      if (errorResult && Object.keys(errorResult).length) {
        setErrors(errorResult);
        setHasError(true);
      } else {
        setErrors([]);
        setHasError(false);
      }
    }
  }, [data, hasError]);

  const handleCreatePassword = async (event) => {
    setHasPageError("");
    const errorResult = errorArray(schema, data);
    if (errorResult && Object.keys(errorResult).length) {
      setErrors(errorResult);
      setHasError(true);
      return false;
    }
    if (data.password !== data.confirmPassword) {
      setData({ password: "", confirmPassword: "" });
      setHasPageError("The passwords you entered do not match.");
      setTimeout(() => {
        setHasPageError("");
      }, 3000);
      return false;
    }

    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
    const isValidPassword = regex.test(data.password);
    if (!isValidPassword) {
      setData({ password: "", confirmPassword: "" });
      setHasPageError("Your password must contain one letter, and one number ");
      setTimeout(() => {
        setHasPageError("");
      }, 3000);
      return false;
    }

    setIsWorking(true);
    try {
      const { email, token } = props.match.params;
      const dataRequest = {
        email,
        token,
        password: data.password,
      };
      await auth.passwordCreateSubmit(dataRequest);
      await auth.login(email, data.password);
      //window.location = path;
      setIsWorking(false);
      setSuccess(true);
      handleGoLogin();
    } catch (ex) {
      setIsWorking(false);
      setData({ password: "", confirmPassword: "" });
      setHasPageErrorStatus(1);
      if (
        ex.response &&
        (ex.response.status === 400 || ex.response.status === 401)
      ) {
        setHasPageError(ex.response.data.message);
      } else if (ex.response && ex.response.status === 422) {
        setHasPageError("The password reset token is invalid.");
      }
    }
  };

  const handleGoLogin = () => {
    setTimeout(() => {
      window.location = path;
    }, 2000);
  };

  if (isLoading) {
    return <LoadingStateHorizontal isVisible style={classes.centerLoading} />;
  }

  return (
    <MainLayoutLogin>
      <Container maxWidth="sm">
        <Grid container className={classes.root}>
          <CardContent className={classes.card}>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Create your password.
            </Typography>
            {success && (
              <Alert severity="success" className={classes.alert}>
                Your new password has been successfully created.
              </Alert>
            )}
            {hasPageError && (
              <Alert severity="error" className={classes.alert}>
                {hasPageError}
              </Alert>
            )}

            {hasPageError && hasPageErrorStatus === 1 ? (
              <CardActions className={classes.cardActions}>
                {/* <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.subTitle}
                >
                  {hasPageError}
                </Typography> */}
                {isWorking && (
                  <Box className={classes.centerLoadingLogin}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                )}
              </CardActions>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.subTitle}
                >
                  Make it something memorable, but not too{" "}
                  <nobr>
                    memorable. <img src={eosLove} alt="EOS Love" />
                  </nobr>
                </Typography>
                <TextField
                  type="password"
                  name="password"
                  value={data.password}
                  disabled={isWorking || success}
                  className={classes.fieldWithFooter}
                  InputProps={{
                    className: classes.input,
                    minLength: 10,
                    disableUnderline: true,
                  }}
                  fullWidth
                  error={errors && errors["password"] ? true : false}
                  helperText={
                    errors && errors["password"] ? errors["password"] : ""
                  }
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your password"
                />
                <Box className={classes.cardInfoText}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.textFooter}
                  >
                    Your password must contain at least 12 characters, including
                    at least one letter and one number
                  </Typography>
                </Box>
                <TextField
                  type="password"
                  name="confirmPassword"
                  value={data.confirmPassword}
                  disabled={isWorking || success}
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    minLength: 10,
                    disableUnderline: true,
                  }}
                  fullWidth
                  error={errors && errors["confirmPassword"] ? true : false}
                  helperText={
                    errors && errors["confirmPassword"]
                      ? errors["confirmPassword"]
                      : ""
                  }
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Confirm your password"
                />
                <CardActions className={classes.cardActions}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    disabled={isWorking || success}
                    className={classes.loginButton}
                    onClick={handleCreatePassword}
                    size="large"
                  >
                    Create my account and login
                  </Button>
                </CardActions>
                <Box className={classes.cardFooter}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.textFooter}
                  >
                    By logging in, you agree to our{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.encompassonsite.com/terms"
                      className={classes.linkLabel}
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.encompassonsite.com/privacy"
                      className={classes.linkLabel}
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </Box>
                {isWorking && (
                  <Box className={classes.centerLoadingLogin}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                )}
              </>
            )}
          </CardContent>
        </Grid>
      </Container>
    </MainLayoutLogin>
  );
};

export default CreatePasswordForm;
