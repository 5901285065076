import React, { useContext } from "react";
import EvaluationsDeleteDialog from "./evaluationsDeleteDialog";
import GlobalUiContext from "contexts/globalUiContext";

const EvaluationsListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <EvaluationsDeleteDialog />
    </>
  );
};
export default EvaluationsListDialog;
