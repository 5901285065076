import { useAddContactModal } from "../../context/AddContactDialogContext";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddContactIcon from "../../icons/AddContactIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import FormInput from "components/ui/FormContent/formInput";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { Fragment } from "react";
import useStyles from "../../styles";
import Box from "@material-ui/core/Box";
import { useContacts } from "./useContacts";
import MessageDialog from "../../../partnersList/dialog/messageDialog";

export function AddContactDialog() {
  const classes = useStyles();

  const [contactAction] = useAddContactModal();
  const { isOpenAddDialog } = contactAction;
  const {
    isSubmitting,
    handleDispatchAddContact,
    handleBlur,
    data,
    handleSubmit,
    handleCloseSuccess,
    openSuccess,
    isEditing,
    isLabelEditing,
    handleBlurEmail,
    emailErrors,
    handleBlurPhoneNumber,
    phoneErrors,
  } = useContacts();

  return (
    <Fragment>
      <Dialog open={isOpenAddDialog} aria-labelledby="form-dialog-title">
        <DialogTitle
          className={classes.dialogTitleWrapper}
          id="form-dialog-title"
        >
          <Box className={classes.dialogTitleInnerWrapper}>
            <AddContactIcon />
            <h3 className={classes.dialogTitle}>
              {isEditing ? "Edit" : "Add New"} Contact
            </h3>
          </Box>
          <IconButton
            disabled={isSubmitting}
            onClick={() => handleDispatchAddContact(false)}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.addContactFormWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="first_name"
                label="First Name"
                value={data.first_name}
                handleBlur={handleBlur}
                placeholder="Write First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="last_name"
                label="Last Name"
                value={data.last_name}
                handleBlur={handleBlur}
                placeholder="Write Last Name"
              />
            </Grid>
          </Grid>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="phone_number"
            label="Phone Number"
            value={data.phone_number}
            handleBlur={handleBlurPhoneNumber}
            error={phoneErrors}
            type="text"
            placeholder="Write Phone Number"
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="email"
            label="Email"
            error={emailErrors}
            type="email"
            value={data.email}
            handleBlur={handleBlurEmail}
            placeholder="Write Email"
          />
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            className={classes.addContactCancelButton}
            onClick={() => handleDispatchAddContact(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            className={classes.secondAddContactButton}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isEditing ? "Edit" : "Add"} Contact
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={
          isLabelEditing
            ? "Contact updated successfully"
            : "Contact added successfully"
        }
      />
    </Fragment>
  );
}
