import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import moment from "moment";
import React from "react";
import useStyles from "./styles";

export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();
  const response = selectedModalViewResponse?.data?.form_accident
    ? selectedModalViewResponse.data.form_accident
    : "";
  let data = response?.data ? JSON.parse(response?.data) : {};
  let witnesses = data?.witnesses ? JSON.parse(data.witnesses) : [];

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                }}
              >
                <Typography className={classes.modalTitle}>
                  First Report of Injury or Illness
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Manager
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Name
                    </Typography>
                    <TextField
                      name="name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="last name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Employee
                    </Typography>
                    <TextField
                      name="employee"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={
                        response?.user?.first_name +
                        " " +
                        response?.user?.last_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5}>
                    <Typography className={classes.subSubTitle}>
                      Date of Birth
                    </Typography>
                    <TextField
                      name="dob"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.dob).format("MM/DD/YYYY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Sex</Typography>
                    <RadioGroup
                      aria-label="sex"
                      name="sex"
                      value={data?.gender ? String(data.gender) : ""}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label="M"
                        name="sex"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label="F"
                        name="sex"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography className={classes.subSubTitle}>
                      Hire Date
                    </Typography>
                    <TextField
                      name="hire_date"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.hire_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}></Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Home Phone # (Area code)
                    </Typography>
                    <TextField
                      name="home_phone"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Cell Phone # (Area code)
                    </Typography>
                    <TextField
                      name="cell_phone"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.cell_phone}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Home Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Street
                    </Typography>
                    <TextField
                      name="home_address"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_street}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Apt. #
                    </Typography>
                    <TextField
                      name="apt"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_apt}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      City
                    </Typography>
                    <TextField
                      name="city"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_city}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      State
                    </Typography>
                    <TextField
                      name="state"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_state}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Zip</Typography>
                    <TextField
                      name="zip"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_zip}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Accident
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Employee Descrption of Accident (include cause of injury)
                    </Typography>
                    <TextField
                      name="employee_desc_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_description}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subSubTitle}>
                      Date of Accident
                    </Typography>
                    <TextField
                      name="date_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.accident_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Typography className={classes.subSubTitle}>
                      Injure / Illness that occurred
                    </Typography>
                    <TextField
                      name="injury Occured"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_injure_illness}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Part of the body Affected
                    </Typography>
                    <TextField
                      name="part_affected"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_part_affected}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Typography>
                    Place a (x) mark on the body frame bellow indicating what
                    particular area(s) on your body that is injured. Specify
                    whether it is in the front or back as well. Make sure it
                    matches the description you have written down describing the
                    accident.
                  </Typography>
                </Grid>
                <Box
                  style={{
                    justifyContent: "center",
                    marginTop: "50px",
                    display: "flex",
                  }}
                >
                  <img src={data?.accident_image} />
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Was the employee transported to Emergency Room?{" "}
                    </Typography>
                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="was_employee_transferred_emergency_room"
                        name="was_employee_transferred_emergency_room"
                        value={
                          data?.to_emergency_room
                            ? String(data.to_emergency_room)
                            : ""
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="was_employee_transferred_emergency_room"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="No"
                          name="was_employee_transferred_emergency_room"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Was Employee sent to assigned Occupational Clinic?{" "}
                    </Typography>

                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="was_employee_assigned_clininc"
                        name="was_employee_assigned_clininc"
                        value={
                          data?.to_occupational_clinic
                            ? String(data.to_occupational_clinic)
                            : ""
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="was_employee_assigned_clininc"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          name="was_employee_assigned_clininc"
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Place of Accident
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Street
                    </Typography>
                    <TextField
                      name="acc_street"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_street}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Apt. #
                    </Typography>
                    <TextField
                      name="acc_apt"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_apt}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      City
                    </Typography>
                    <TextField
                      name="acc_city"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_city}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      State
                    </Typography>
                    <TextField
                      name="acc_state"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_state}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Zip</Typography>
                    <TextField
                      name="acc_zip"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_zip}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Please list any witnesses
                    </Typography>

                    {witnesses?.map((v) => {
                      return (
                        <span>
                          <Typography className={classes.subSubTitle}>
                            Name
                          </Typography>

                          <TextField
                            name="witness_name"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v}
                          />
                        </span>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid container spacing={3}></Grid>
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center", marginTop: "50px" }}
                >
                  <Typography className={classes.subTitle}>
                    Signature
                  </Typography>
                </Grid>
                <br />
                <br />
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  <img src={response?.signature_image} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
