import React from "react";
import {
  getSurveys,
  getSurveysListSearch,
  getSurveysListOffset,
} from "services/surveyService";
import { merge } from "lodash";

const defaultState = {
  surveysLoading: true,
  surveySelected: null,
  surveysList: [],
  surveysCount: [],
  openSurveyDelete: false,
  openSurveyChangeStatus: false,
};

const SurveysStateContext = React.createContext();
const SurveysDispatchContext = React.createContext();

function surveysReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        surveysLoading: action.surveysLoading,
      };
    case "SET_SURVEYS_LIST":
      return {
        ...state,
        surveysList: action.surveysList,
      };
    case "SET_SURVEYS_COUNT":
      return {
        ...state,
        surveysCount: action.surveysCount,
      };
    case "SET_SURVEY_SELECTED":
      return {
        ...state,
        surveySelected: action.surveySelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openSurveyDelete: action.openSurveyDelete,
      };
    case "TOGGLE_CHANGE_STATUS":
      return {
        ...state,
        openSurveyChangeStatus: action.openSurveyChangeStatus,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveysProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(surveysReducer, mergedState);
  return (
    <SurveysStateContext.Provider value={state}>
      <SurveysDispatchContext.Provider value={dispatch}>
        {children}
      </SurveysDispatchContext.Provider>
    </SurveysStateContext.Provider>
  );
}

function useSurveysState() {
  const context = React.useContext(SurveysStateContext);
  if (context === undefined) {
    throw new Error("useSurveysState must be used within a SurveysProvider");
  }
  return context;
}

function useSurveysDispatch() {
  const context = React.useContext(SurveysDispatchContext);
  if (context === undefined) {
    throw new Error("useSurveysDispatch must be used within a SurveysProvider");
  }
  return context;
}

async function getSurveysList(dispatch, filterData, noLoading) {
  try {
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        surveysLoading: true,
      });
    }
    const result = await getSurveys(filterData);
    dispatch({
      type: "SET_SURVEYS_LIST",
      surveysList: [...result.data.data.list],
    });
    dispatch({
      type: "SET_SURVEYS_COUNT",
      surveysCount: result.data.data.filter_count,
    });
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        surveysLoading: false,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function getSurveysListDataSet(
  surveysList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getSurveysListOffset(offset, filterData);
    const updateData = surveysList.concat(result.data.data.list);
    console.log(updateData);
    dispatch({
      type: "SET_SURVEYS_LIST",
      surveysList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchSurveysList(search, filterData) {
  try {
    const result = await getSurveysListSearch(search, filterData);
    return result.data.data.list;
  } catch (error) {
    console.log(error);
  }
}

export {
  SurveysProvider,
  useSurveysState,
  useSurveysDispatch,
  getSurveysListDataSet,
  getSurveysList,
  searchSurveysList,
};
