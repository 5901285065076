import React, { lazy, Suspense, useContext } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { WorkticketProvider } from "contexts/workticketContext";
import { WorkticketProvider as WorkticketSurfaceProvider } from "contexts/workticketSurfaceContext";
import { WorkticketActionProvider } from "contexts/workticketActionContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorkticketsTable = lazy(() => import("./workticketsTable"));
const WorkticketsSurfaceTable = lazy(() => import("./workticketsSurfaceTable"));
const WorkticketsSurfaceDialog = lazy(() =>
  import("./dialog/workticketsSurfaceDialog")
);

const WorkticketsList = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      <PageHeader pageTitle="Worktickets" link="/worktickets" />
      <Box className={classes.pageBodyWrapper}>
        {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
          <WorkticketSurfaceProvider>
            <WorkticketActionProvider>
              <Suspense
                fallback={
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                }
              >
                <WorkticketsSurfaceTable history={props.history} />
                <WorkticketsSurfaceDialog />
              </Suspense>
            </WorkticketActionProvider>
          </WorkticketSurfaceProvider>
        ) : (
          <WorkticketProvider>
            <WorkticketActionProvider>
              <Suspense
                fallback={
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                }
              >
                <WorkticketsTable history={props.history} />
              </Suspense>
            </WorkticketActionProvider>
          </WorkticketProvider>
        )}
      </Box>
    </>
  );
};

export default withTableFilterContext(WorkticketsList);
