import React, { useState, useEffect, useCallback } from "react";
import TableTabs from "../../components/common/Table/tableTabs";
import { StyledTab } from "../../components/common/Table/stylesTabs";
import Table from "../../components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "../../components/common/Table/tableToolbar";
import ToolbarDefault from "../../components/common/Table/tableToolbarDefault";
import CalendarFilters from "../../components/common/Filters/calendarFilters";
import FiltersAppliedList from "../../components/common/Filters/filtersAppliedList";
import MessageDialog from "./dialog/messageDialog";
import {
  getServicesAdmin,
  updateService,
  createService,
  deleteService,
} from "../../services/serviceService";
import {
  getCategoriesAdmin,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../../services/categoryService";
import {
  getTrades,
  createTrade,
  updateTrade,
  deleteTrade,
} from "../../services/tradesService";
import { getCommoditiesAdmin } from "../../services/commodityService";
import { getGoodsAdmin } from "../../services/goodService";
import { ButtonBase } from "../../components/ui/core/button";
import QuoteAdminActions from "./quoteAdminActions";
import TradeAdminActions from "./tradeAdminActions";
import QuoteAdminActionsService from "./QuoteAdminActionsService";
import CategoryDialog from "./dialog/categoryDialog";
import TradeDialog from "./dialog/tradeDialog";
import ServiceDialog from "./dialog/serviceDialog";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "../../contexts/tableContext";
import { useListState, useListDispatch } from "../../contexts/listContext";
import {
  useFilterState,
  useFilterDispatch,
} from "../../contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuoteAdminTable = (props) => {
  const classes = useStyles();
  const [tablePointer, setTablePointer] = useState(25);
  const [loadingMore, setLoadingMore] = useState(false);
  const [item, setItem] = useState(null);
  const [activeTab, setActiveTab] = useState(4);
  const [openCreate, setOpenCreate] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [listTrades, setListTrades] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listServices, setListServices] = useState([]);
  const [listCommodities, setListCommodities] = useState([]);
  const [listGoods, setListGoods] = useState([]);
  const { listLoading, list, count, dummyRefresh } = useListState();
  const { hasRowsClickable, rows } = useTableState();
  const {
    searchString,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  const viewItem = (item) => {
    setItem(item);
    setOpenCreate(true);
  };

  const columnsCategory = [
    {
      id: "name",
      label: "Category",
      checkbox: {
        active: (row) => false,
      },
    },
    { id: "created_at", label: "Creation Date", format: "date" },
    { id: "updated_at", label: "Last Modified", format: "date" },
    {
      id: "actions",
      content: (row) => (
        <QuoteAdminActions
          row={row}
          handleView={viewItem}
          handleDelete={handleCategoryDelete}
        />
      ),
      contentMobile: (row) => (
        <QuoteAdminActions
          row={row}
          mobile={true}
          handleView={viewItem}
          handleDelete={handleCategoryDelete}
        />
      ),
    },
  ];

  const columnsTrade = [
    {
      id: "trade_name",
      label: "Trade",
      checkbox: {
        active: (row) => false,
      },
    },
    { id: "created_at", label: "Creation Date", format: "date" },
    { id: "updated_at", label: "Last Modified", format: "date" },
    {
      id: "actions",
      content: (row) => (
        <TradeAdminActions
          row={row}
          handleView={viewItem}
          handleDelete={handleTradeDelete}
        />
      ),
      contentMobile: (row) => (
        <TradeAdminActions
          row={row}
          mobile={true}
          handleView={viewItem}
          handleDelete={handleTradeDelete}
        />
      ),
    },
  ];

  const columnsService = [
    {
      id: "name",
      label: "Category",
      checkbox: {
        active: (row) => false,
      },
    },
    { id: "created_at", label: "Creation Date", format: "date" },
    { id: "updated_at", label: "Last Modified", format: "date" },
    {
      id: "actions",
      content: (row) => (
        <QuoteAdminActionsService
          row={row}
          handleView={viewItem}
          handleDelete={handleServiceDelete}
        />
      ),
      contentMobile: (row) => (
        <QuoteAdminActionsService
          row={row}
          mobile={true}
          handleView={viewItem}
          handleDelete={handleServiceDelete}
        />
      ),
    },
  ];

  const columnsCommodity = [
    {
      id: "name",
      label: "Commodity",
      checkbox: {
        active: (row) => false,
      },
    },
    { id: "created_at", label: "Creation Date", format: "date" },
    { id: "updated_at", label: "Last Modified", format: "date" },
  ];

  const columnsGood = [
    {
      id: "name",
      label: "Item Name",
      checkbox: {
        active: (row) => false,
      },
    },
    { id: "created_at", label: "Creation Date", format: "date" },
    { id: "updated_at", label: "Last Modified", format: "date" },
  ];

  useEffect(() => {
    dispatchList({
      type: "SET_LOADING",
      listLoading: true,
    });
    const fetchData = async () => {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };

      const [
        resultCategories,
        resultServices,
        resultCommodities,
        resultGoods,
        resultTrades,
      ] = await Promise.all([
        getCategoriesAdmin(filterData),
        getServicesAdmin(filterData),
        getCommoditiesAdmin(filterData),
        getGoodsAdmin(filterData),
        getTrades(filterData),
      ]);

      setListCategories(resultCategories.data.data.categories);
      setListServices(resultServices.data.data.services);
      setListCommodities(resultCommodities.data.data.commodities);
      setListGoods(resultGoods.data.data.goods);
      setListTrades(resultTrades.data.data);
      dispatchList({
        type: "SET_LIST",
        list:
          activeTab === 0
            ? resultCategories.data.data.categories
            : activeTab === 1
            ? resultServices.data.data.services
            : activeTab === 2
            ? resultCommodities.data.data.commodities
            : activeTab === 3
            ? resultGoods.data.data.goods
            : resultTrades.data.data,
      });
      dispatchList({
        type: "SET_COUNT",
        count:
          activeTab === 0
            ? resultCategories.data.data.categories.length
            : activeTab === 1
            ? resultServices.data.data.services.length
            : activeTab === 2
            ? resultCommodities.data.data.commodities.length
            : activeTab === 3
            ? resultGoods.data.data.goods.length
            : resultTrades.data.data.length,
      });
      return dispatchList({
        type: "SET_LOADING",
        listLoading: false,
      });
    };
    fetchData();
  }, [
    dispatchList,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
    dummyRefresh,
  ]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list.slice(0, tablePointer),
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns:
          activeTab === 0
            ? columnsCategory
            : activeTab === 1
            ? columnsService
            : activeTab === 2
            ? columnsCommodity
            : activeTab === 3
            ? columnsGood
            : columnsTrade,
      });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, listLoading, tablePointer]);

  const handleScrollClick = useCallback(async () => {
    if (searchString) {
      // If search activated not scroll
      return;
    }
    if (list.length <= tablePointer) {
      return;
    }
    try {
      setLoadingMore(true);
      const updateTablePointer = tablePointer + 25;
      setTablePointer(updateTablePointer);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [tablePointer, list.length, searchString]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        if (activeTab === 0) {
          const resultSearch = list.filter((elementItem) =>
            String(elementItem.name).toUpperCase().includes(query.toUpperCase())
          );
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: resultSearch,
          });
        } else {
          const resultSearch = list.filter((elementItem) =>
            activeTab === 4
              ? String(elementItem.trade_name)
                  .toUpperCase()
                  .includes(query.toUpperCase())
              : String(elementItem.name)
                  .toUpperCase()
                  .includes(query.toUpperCase())
          );
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: resultSearch,
          });
        }
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list.slice(0, tablePointer),
    });
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    if (newTab === 0) {
      dispatchList({
        type: "SET_LIST",
        list: listCategories,
      });
      dispatchList({
        type: "SET_COUNT",
        count: listCategories.length,
      });
    } else if (newTab === 1) {
      dispatchList({
        type: "SET_LIST",
        list: listServices,
      });
      dispatchList({
        type: "SET_COUNT",
        count: listServices.length,
      });
    } else if (newTab === 2) {
      dispatchList({
        type: "SET_LIST",
        list: listCommodities,
      });
      dispatchList({
        type: "SET_COUNT",
        count: listCommodities.length,
      });
    } else if (newTab === 3) {
      dispatchList({
        type: "SET_LIST",
        list: listGoods,
      });
      dispatchList({
        type: "SET_COUNT",
        count: listGoods.length,
      });
    } else if (newTab === 4) {
      dispatchList({
        type: "SET_LIST",
        list: listTrades,
      });
      dispatchList({
        type: "SET_COUNT",
        count: listTrades.length,
      });
    }
  };
  const closeCreate = (refresh) => {
    if (refresh) {
      dispatchList({
        type: "SET_DUMMY_REFRESH",
        dummyRefresh: !dummyRefresh,
      });
    }
    setItem(null);
    setOpenCreate(false);
  };

  const handleNew = () => {
    setItem(null);
    setOpenCreate(true);
  };

  const addNewCategory = async (data) => {
    if (data.id) {
      try {
        await updateCategory(data.id, data);
        setOpenCreate(false);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        setOpenMessage(true);
        setMessage("Category updated successfully!");
      } catch (e) {
        console.log("Cannot update Category");
        console.log(e);
      }
    } else {
      try {
        await createCategory(data);
        setOpenCreate(false);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        setOpenMessage(true);
        setMessage("Category created successfully!");
      } catch (e) {
        console.log("Cannot create Category");
        console.log(e);
      }
    }
    setItem(null);
    handleSearchClear();
  };

  const addNewTrade = async (data) => {
    if (data.id) {
      try {
        await updateTrade(data.id, data);
        setOpenCreate(false);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        setOpenMessage(true);
        setMessage("Trade updated successfully!");
      } catch (e) {
        console.log("Cannot update Category");
        console.log(e);
      }
    } else {
      try {
        await createTrade(data);
        setOpenCreate(false);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        setOpenMessage(true);
        setMessage("Trade created successfully!");
      } catch (e) {
        console.log("Cannot create Trade");
        console.log(e);
      }
    }
    setItem(null);
    handleSearchClear();
  };

  const addNewService = async (data, isAddingExceptions) => {
    if (data.id) {
      try {
        await updateService(data.id, data);
        if (!isAddingExceptions) {
          setOpenCreate(false);
          dispatchList({
            type: "SET_DUMMY_REFRESH",
            dummyRefresh: !dummyRefresh,
          });
          setOpenMessage(true);
          setMessage("Service updated successfully!");
        }
      } catch (e) {
        console.log("Cannot update Service");
        console.log(e);
      }
    } else {
      try {
        await createService(data);
        setOpenCreate(false);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        setOpenMessage(true);
        setMessage("Service created successfully!");
      } catch (e) {
        console.log("Cannot create Service");
        console.log(e);
      }
    }
    if (!isAddingExceptions) {
      setItem(null);
      handleSearchClear();
    }
  };

  const handleCategoryDelete = async (id) => {
    if (id) {
      try {
        await deleteCategory(id);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
      } catch (e) {
        console.log("Cannot delete category");
      }
    }
    handleSearchClear();
  };

  const handleTradeDelete = async (id) => {
    if (id) {
      try {
        await deleteTrade(id);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
      } catch (e) {
        console.log("Cannot delete trade");
      }
    }
    handleSearchClear();
  };

  const handleServiceDelete = async (id) => {
    if (id) {
      try {
        await deleteService(id);
        dispatchList({
          type: "SET_DUMMY_REFRESH",
          dummyRefresh: !dummyRefresh,
        });
        handleSearchClear();
      } catch (e) {
        console.log("Cannot delete service");
      }
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  return (
    <Box className={classes.root}>
      {listLoading ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <MessageDialog
              title={"Success!"}
              open={openMessage}
              handleClose={closeMessage}
              message={message}
            />
            {activeTab === 0 && (
              <CategoryDialog
                open={openCreate}
                handleConfirm={addNewCategory}
                handleClose={closeCreate}
                title={item ? "Edit Category" : "New Category"}
                item={item}
              />
            )}
            {activeTab === 1 && (
              <ServiceDialog
                open={openCreate}
                handleConfirm={addNewService}
                handleClose={closeCreate}
                title={item ? "Edit Service" : "New Service"}
                item={item}
              />
            )}
            {activeTab === 4 && (
              <TradeDialog
                open={openCreate}
                handleConfirm={addNewTrade}
                handleClose={closeCreate}
                title={item ? "Edit Trade" : "New Trade"}
                item={item}
              />
            )}
            <Box className={classes.contentCounter}>
              {!searchString ? (
                <Chip
                  label={`${
                    count > tablePointer ? tablePointer : count
                  } / ${count}`}
                  color="secondary"
                  variant="outlined"
                />
              ) : (
                <Chip
                  label={`${rows.length}`}
                  color="secondary"
                  variant="outlined"
                />
              )}
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={activeTab}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Trades</Box>}
                key={4}
                value={4}
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Service Categories</Box>
                }
                key={0}
                value={0}
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Service Management</Box>
                }
                key={1}
                value={1}
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Supplies Commodities</Box>
                }
                key={2}
                value={2}
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Supplies Management</Box>
                }
                key={3}
                value={3}
              />
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        {(activeTab < 2 || activeTab > 3) && (
                          <div className={classes.rootButton}>
                            <ButtonBase
                              variant="outlined"
                              color="secondary"
                              onClick={handleNew}
                            >
                              {activeTab === 0
                                ? "New Category"
                                : activeTab === 1
                                ? "New Service"
                                : "New Trade"}
                            </ButtonBase>
                          </div>
                        )}
                        <CalendarFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuoteAdminTable;
