import React from "react";
import RecognitionDeleteDialog from "./recognitionDeleteDialog";
import RecognitionViewDialog from "./recognitionViewDialog";

const RecognitionListDialog = (props) => {
  return (
    <>
      <RecognitionDeleteDialog />
      <RecognitionViewDialog />
    </>
  );
};
export default RecognitionListDialog;
