import React, { lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import { WorknovaProvider } from "contexts/worknovaTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { WorknovaActionProvider } from "contexts/worknovaActionContext";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorknovaTable = lazy(() => import("./worknovaTable"));
const WorknovaDialog = lazy(() => import("./dialog/worknovaDialog"));

const WorknovaList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Workorders" link="/worknova" />
      <Box className={classes.pageBodyWrapper}>
        <WorknovaProvider>
          <WorknovaActionProvider>
            <Suspense
              fallback={
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              }
            >
              <WorknovaTable />
              <WorknovaDialog />
            </Suspense>
          </WorknovaActionProvider>
        </WorknovaProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(WorknovaList);
