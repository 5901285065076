import React from "react";
import Box from "@material-ui/core/Box";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import { useServiceDispatch } from "contexts/projectServiceContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
}));

const ServicesListActions = (props) => {
  const classes = useStyles();
  const dispatchServices = useServiceDispatch();

  const handleClick = () => {
    dispatchServices({
      type: "SET_SERVICE_MANAGE_MODE",
      serviceManageMode: "NEW",
    });
    dispatchServices({
      type: "SET_SERVICE_MANAGE_OPEN",
      serviceManageOpen: true,
    });
  };

  return (
    <Box className={classes.root}>
      <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
        Add Service
      </ButtonBase>
    </Box>
  );
};

export default ServicesListActions;
