import React, { useContext } from "react";
import ResultsDeleteDialog from "./resultsDeleteDialog";
import GlobalUiContext from "contexts/globalUiContext";
import ResultsBaselineDialog from "./resultsBaselineDialog";

const ResultsListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <ResultsDeleteDialog />
      <ResultsBaselineDialog />
    </>
  );
};
export default ResultsListDialog;
