import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { XIcon } from "../../../icons/XIcon";
import Typography from "@material-ui/core/Typography";
import FormSelect from "../../../../../components/ui/FormContent/formSelect";
import Button from "@material-ui/core/Button";
import { ArrowBack } from "../../../icons/ArrowBack";
import useStyles from "../../../styles";
import { useFilterDialog } from "../../../context/FilterDialogContext";
import { useGeographicalCoverage } from "../../../context/GeographicalCoverageContext";
import { useGeoCoverage } from "./useGeoCoverage";
import { CityStateFilterPage } from "../../../Trades/CityStateFilterPage";
import LoadingIndicator from "../../../../../components/common/LoadingIndicator/loadingIndicator";

export function GeographicalCoverageDrawer() {
  const classes = useStyles();
  const [geoAction, dispatchGeoDrawer] = useGeographicalCoverage();
  const { isEditing } = geoAction;
  const toggleDrawer = () => {
    dispatchGeoDrawer({ type: "TOGGLE_DRAWER", isDrawerOpen: false });
  };
  const handleBlur = (event) => {
    // setData({ ...data, [event.name]: event.value });
  };
  const { handleDialogueOpen, setIsEditingVariant, variantsLocations } =
    useFilterDialog();
  const [filterType, setFilterType] = React.useState("");
  const {
    data,
    statesList,
    zipCodesList,
    citiesList,
    setZipCodesList,
    setStatesList,
    setCitiesList,
    filterCities,
    setFilterCities,
    filterStates,
    setFilterStates,
    filterZipCodes,
    setFilterZipCodes,
    filtersOpen,
    setFiltersOpen,
    setDeletedStates,
    submitEditing,
    submitAdd,
    loading,
    geoMutation,
  } = useGeoCoverage();
  return (
    <Fragment>
      <Drawer anchor={"right"} open={true} onClose={toggleDrawer}>
        <Box className={classes.drawerOuterContainer}>
          <Box className={classes.drawerContainer}>
            <Box>
              <Box className={classes.drawerTitleContainer}>
                <Box onClick={toggleDrawer} className={classes.drawerCloseIcon}>
                  <XIcon className={classes.drawerCloseIcon} />
                </Box>
                <Typography className={classes.drawerTitle}>
                  Geographical Coverage
                </Typography>
                <Typography className={classes.drawerInfoText}>
                  Tell us more about the geographical coverage of this partner.
                </Typography>
              </Box>
              <Box className={classes.drawerFormContainer}>
                {geoMutation.isLoading && <LoadingIndicator />}
                {!geoMutation.isLoading && (
                  <Fragment>
                    <Box
                      //disabled onClick when editing
                      onClick={() => {
                        if (!isEditing) {
                          setFiltersOpen(true);
                          setIsEditingVariant(true);
                          setFilterType("states");
                        }
                      }}
                    >
                      <FormSelect
                        name="states_serviced"
                        redirectIcon={true}
                        disabled={isEditing}
                        readonly={true} // Set to false if you want to allow editing
                        label="States Serviced"
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={[
                          {
                            value: 1,
                            label:
                              statesList.length > 0
                                ? `
                    ${statesList?.map((state) => state).join(", ")}
                    `
                                : "States Serviced",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.states_serviced || 1}
                        styleOverride={{ minWidth: "321px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>

                    <Box
                      onClick={() => {
                        setFiltersOpen(true);
                        setIsEditingVariant(true);
                        setFilterType("cities");
                      }}
                    >
                      <FormSelect
                        name="city_serviced"
                        label="Cities Serviced"
                        redirectIcon={true}
                        readonly={true} // Set to false if you want to allow editing
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={[
                          {
                            value: 1,
                            label:
                              citiesList.length > 0
                                ? ` 
                            ${citiesList.join(", ")} 
                            `
                                : "Cities Serviced",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.city_serviced || 1}
                        styleOverride={{ minWidth: "321px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                    <Box
                      onClick={() => {
                        setFiltersOpen(true);
                        setIsEditingVariant(true);
                        setFilterType("zip_codes");
                      }}
                    >
                      <FormSelect
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="zip_codes"
                        label="Zip Codes"
                        redirectIcon={true}
                        readonly={true} // Set to false if you want to allow editing
                        options={[
                          {
                            value: 1,
                            label:
                              zipCodesList.length > 0
                                ? `${zipCodesList.join(", ")}`
                                : "Zip Codes",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.zip_codes || 1}
                        styleOverride={{ width: "100%" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.drawerButtonsContainer}>
            <Button
              onClick={toggleDrawer}
              disabled={loading}
              startIcon={<ArrowBack />}
              className={classes.goBackButton}
            >
              Go Back
            </Button>
            <Button
              disabled={loading}
              className={classes.saveChangesButton}
              onClick={isEditing ? submitEditing : submitAdd}
            >
              {isEditing ? "Save Changes" : "Save"}
            </Button>
          </Box>
        </Box>
      </Drawer>
      {filtersOpen ? (
        <CityStateFilterPage
          filterCities={filterCities}
          setFilterCities={setFilterCities}
          filterStates={filterStates}
          setFilterStates={setFilterStates}
          filterZipCodes={filterZipCodes}
          setFilterZipCodes={setFilterZipCodes}
          setCitiesList={setCitiesList}
          setStatesList={setStatesList}
          setZipCodesList={setZipCodesList}
          statesList={statesList}
          citiesList={citiesList}
          zipCodesList={zipCodesList}
          setOpen={setFiltersOpen}
          setRemovedStates={setDeletedStates}
          editingGeoLocation={isEditing ? true : false}
          filterType={filterType}
        />
      ) : null}
    </Fragment>
  );
}
