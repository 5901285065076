import React, { useEffect, useState } from "react";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillDispatch,
  surveyStart,
  surveyStartPublic,
} from "contexts/surveyFillContext";

const SurveysFrontlineSubmitPage = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatchSurveyFill = useSurveyFillDispatch();
  const { params } = props;

  useEffect(() => {
    const loadSurvey = async () => {
      setLoading(true);

      await surveyStart(
        dispatchSurveyFill,
        params.job_id,
        params.survey_id,
        params?.is_randomize ?? 0,
        params?.random_number
      );
      dispatchSurveyFill({
        type: "SET_LINK_MODE",
        linkMode: true,
      });
      setLoading(false);
    };
    const loadPublicSurvey = async () => {
      setLoading(true);

      await surveyStartPublic(dispatchSurveyFill, params.token);
      dispatchSurveyFill({
        type: "SET_LINK_MODE",
        linkMode: true,
      });
      setLoading(false);
    };

    if (params.job_id && params.survey_id) {
      loadSurvey();
    }
    if (params.token) {
      loadPublicSurvey();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{loading ? <LoadingIndicator /> : null}</>;
};

export default SurveysFrontlineSubmitPage;
