import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import IconLink from "@material-ui/icons/ArrowForward";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilled";
import LinkDocumentIcon from "@material-ui/icons/Link";
import LearnPageTableActions from "./learnPageTableActions";
import ContentField from "./contentField";
import HeaderField from "./headerField";
import { useLearnPageDispatch } from "contexts/learnPageContext";

import useStyles from "./styles";

const LearnPageItem = (props) => {
  const classes = useStyles();
  const { item, readonly } = props;
  const dispatchLearnPage = useLearnPageDispatch();

  const openVideo = () => {
    dispatchLearnPage({
      type: "SET_URL",
      viewURL: item.url,
    });
    dispatchLearnPage({
      type: "TOGGLE_VIEW_VIDEO",
      openViewVideo: true,
    });
  };

  return (
    <Box className={classes.itemContainer}>
      <Box className={classes.imageItemContainer}>
        <Box
          className={classes.categoryNameContainer}
          style={{ backgroundColor: item.color }}
        >
          <Typography className={classes.categoryName}>
            {item.category_name}
          </Typography>
        </Box>
        <img className={classes.tileImage} src={item.image_url} />
      </Box>
      <Box className={classes.itemContentContainer}>
        <Box className={classes.categoryIndicatorContainer}>
          <Box
            className={classes.categoryIndicator}
            style={{ backgroundColor: item.color }}
          ></Box>
        </Box>
        <Box className={classes.itemContentDetail}>
          <Box className={classes.itemContentHeader}>
            <HeaderField field={item.title} fieldLength={15} />
            {!readonly ? <LearnPageTableActions item={item} /> : null}
          </Box>
          <ContentField field={item.description} fieldLength={90} />
          <Box className={classes.itemActions}>
            {item.type !== 2 ||
            (!item.url.startsWith("https://www.youtube.com/embed") &&
              !item.url.startsWith("https://player.vimeo.com/video/")) ? (
              <Typography className={classes.linkTipo}>
                <Link
                  to={{ pathname: item.url }}
                  style={{ textDecoration: "none" }}
                  className={classes.linkLabel}
                  target="_blank"
                >
                  {item.cta_text}
                  <IconLink className={classes.linkIcon} />
                </Link>
              </Typography>
            ) : (
              <Box onClick={openVideo}>
                <Typography className={classes.linkTipoVideo}>
                  {item.cta_text}
                  <IconLink className={classes.linkIcon} />
                </Typography>
              </Box>
            )}
            {item.type === 1 ? (
              <LinkDocumentIcon className={classes.itemActionIcon} />
            ) : (
              <PlayCircleIcon className={classes.itemActionIcon} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LearnPageItem;
