import http from "./httpService";
import { apiUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";
import {
  getFilterString,
  filtersDashboardGeo,
} from "components/util/filterUtil";

const apiEndpoint = `${apiUrl}/notes`;
const apiEndpointSingular = `${apiUrl}/note`;

export function getNotes(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}?${filterString}`, {});
}

export function getNotesSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getNotesOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getDashboardNotes(filterData = undefined, dashboardState) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}`, payload);
}

export function getDashboardNotesSearch(search, filterData, dashboardState) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}?search=${search}`, payload);
}

export function getDashboardNotesOffset(offset, filterData, dashboardState) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}?offset=${offset}`, payload);
}

export function saveNote(data) {
  http.setFormEncode();
  return http.post(apiEndpointSingular, setFormData(data));
}

export function updateNote(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function deleteNote(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
