import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    flexGrow: 1,
    margin: "25px auto 40px auto",
    zIndex: 100,
  },
  headerWrapper: {
    position: "absolute",
    paddingLeft: 25,
    paddingTop: 25,
    paddingBottom: 150,
    width: "100%",
    backgroundColor: "#17457a",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  logoWrapper: {
    width: 220,
  },
  logo: {
    width: "100%",
  },
}));

export default useStyles;
