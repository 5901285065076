import React from "react";
import Button from "@material-ui/core/Button";
import { useLocationsDispatch } from "contexts/surveyLocationsContext";

import useStyles from "./styles";

const LocationsTableActions = (props) => {
  const classes = useStyles();
  const { row } = props;
  const dispatchLocations = useLocationsDispatch();

  const handleClick = (event) => {
    dispatchLocations({
      type: "SET_LOCATIONS_SELECTED",
      locationsSelected: row,
    });
    dispatchLocations({
      type: "TOGGLE_OPEN_SURVEYS",
      openLocationSurveys: true,
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.buttonSurvey}
        onClick={handleClick}
        size="small"
        disabled={!row.has_surveys}
      >
        {row.has_surveys ? "Complete Survey" : "No survey available"}
      </Button>
    </div>
  );
};

export default LocationsTableActions;
