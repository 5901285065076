import React from "react";
import merge from "lodash/merge";

const defaultState = {
  jobListLoading: true,
  jobSelected: null,
  jobList: [],
  jobCount: [],
};

const JobsStateContext = React.createContext();
const JobsDispatchContext = React.createContext();

function jobsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        jobListLoading: action.jobListLoading,
      };
    case "SET_JOBS_LIST":
      return {
        ...state,
        jobList: action.jobList,
      };
    case "SET_JOBS_COUNT":
      return {
        ...state,
        jobCount: action.jobCount,
      };
    case "SET_JOBS_SELECTED":
      return {
        ...state,
        jobSelected: action.jobSelected,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function JobsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(jobsReducer, mergedState);
  return (
    <JobsStateContext.Provider value={state}>
      <JobsDispatchContext.Provider value={dispatch}>
        {children}
      </JobsDispatchContext.Provider>
    </JobsStateContext.Provider>
  );
}

function useJobsState() {
  const context = React.useContext(JobsStateContext);
  if (context === undefined) {
    throw new Error("useJobsState must be used within a JobsProvider");
  }
  return context;
}

function useJobsDispatch() {
  const context = React.useContext(JobsDispatchContext);
  if (context === undefined) {
    throw new Error("useJobsDispatch must be used within a JobsProvider");
  }
  return context;
}

export { JobsProvider, useJobsState, useJobsDispatch };
