import http from "./httpService";
import { apiUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";
import { getFilterString } from "components/util/filterUtil";

const apiEndpoint = apiUrl + "/assets";
const apiEndpointSingular = apiUrl + "/asset";

export function getAssets(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getAssetsAll(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiUrl}/assetsFilter?${filterString}`, {});
}

export function getAssetsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getAssetsOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getAssetDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {});
}

export function updateAssetDetails(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function saveAssetFile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function deleteAsset(id) {
  return http.delete(`${apiEndpoint}/${id}`, {});
}

export function getAssetCategories() {
  return http.get(`${apiEndpointSingular}/categories`, {});
}

export function saveAssetCategory(data) {
  return http.post(`${apiEndpointSingular}/categories`, data);
}

export function updateAssetCategory(id, data) {
  return http.put(`${apiEndpointSingular}/categories/${id}`, data);
}

export function deleteAssetCategory(id) {
  return http.delete(`${apiEndpointSingular}/categories/${id}`, {});
}

export function getAssetJobs(customer_id = undefined) {
  return http.get(
    `${apiEndpointSingular}${customer_id ? `?customer_id=${customer_id}` : ``}`,
    {}
  );
}

export function saveAsset(data) {
  http.setFormEncode();
  return http.post(apiEndpointSingular, setFormData(data));
}

export function getAssetMaintenanceUpcoming(assetId = 0) {
  return http.get(
    `${apiEndpoint}${assetId ? `/${assetId}` : ``}/maintenance/upcoming`
  );
}

export function getAssetMaintenanceHistory(assetId = 0) {
  return http.get(
    `${apiEndpoint}${assetId ? `/${assetId}` : ``}/maintenance/history`
  );
}

export function getAssetMaintenancePlan(assetId = 0) {
  return http.get(`${apiEndpoint}/${assetId}/maintenance/plan`, {});
}

export function getAssetProjectMaintenanceUpcoming(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/upcoming`
  );
}

export function getAssetProjectMaintenanceHistory(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/history`
  );
}

export function getAssetProjectMaintenancePlan(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/plan`
  );
}
