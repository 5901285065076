import React from "react";
import { merge } from "lodash";

const defaultState = {
  formsListLoading: true,
  formsSelected: null,
  formsList: [],
  formsCount: [],
  openForms: false,
  openFormsSingle: false,
  formsManager: null,
  currentSelectedTab: 1,
};

const FormsStateContext = React.createContext();
const FormsDispatchContext = React.createContext();

function formsReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentSelectedTab: action.currentSelectedTab,
      };
    case "SET_LOADING":
      return {
        ...state,
        formsListLoading: action.formsListLoading,
      };
    case "SET_FORMS_LIST":
      return {
        ...state,
        formsList: action.formsList,
      };
    case "SET_FORMS_COUNT":
      return {
        ...state,
        formsCount: action.formsCount,
      };
    case "SET_FORMS_SELECTED":
      return {
        ...state,
        formsSelected: action.formsSelected,
      };

    case "TOGGLE_FORMS":
      return {
        ...state,
        openForms: action.openForms,
      };

    case "TOGGLE_FORMS_SINGLE":
      return {
        ...state,
        openFormsSingle: action.openFormsSingle,
      };

    case "SET_MANAGER":
      return {
        ...state,
        formsManager: action.formsManager,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FormsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(formsReducer, mergedState);
  return (
    <FormsStateContext.Provider value={state}>
      <FormsDispatchContext.Provider value={dispatch}>
        {children}
      </FormsDispatchContext.Provider>
    </FormsStateContext.Provider>
  );
}

function useFormsState() {
  const context = React.useContext(FormsStateContext);
  if (context === undefined) {
    throw new Error("useFormsState must be used within a FormsProvider");
  }
  return context;
}

function useFormsDispatch() {
  const context = React.useContext(FormsDispatchContext);
  if (context === undefined) {
    throw new Error("useFormsDispatch must be used within a FormsProvider");
  }
  return context;
}

async function getFormsList(dispatch) {
  try {
    dispatch({
      type: "SET_LOADING",
      formsListLoading: true,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getFormsListDataSet() {
  try {
  } catch (error) {
    console.log(error);
  }
}

async function searchFormsList() {
  try {
  } catch (error) {
    console.log(error);
  }
}

export {
  FormsProvider,
  useFormsState,
  useFormsDispatch,
  getFormsListDataSet,
  getFormsList,
  searchFormsList,
};
