import React from "react";
import { TableCollapsibleProvider } from "contexts/tableCollapsibleContext";
import { FilterProvider } from "contexts/filterContext";

const withTableCollapsibleFilterContext = (BaseComponent) => (props) =>
  (
    <FilterProvider>
      <TableCollapsibleProvider>
        <BaseComponent {...props} />
      </TableCollapsibleProvider>
    </FilterProvider>
  );

export default withTableCollapsibleFilterContext;
