import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import { useJobView } from "contexts/jobViewContext";

const JobBreadcrum = () => {
  const [stateContext] = useJobView();
  const { job, isLoading } = stateContext ?? null;

  if (isLoading && job !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return job.customer_id ? (
    <PageHeader
      pageTitle={job.id ? `${job.job_number} -  ${job.job_description}` : "New"}
    >
      <Link to="/customers">Customers</Link>
      <Link to={`/customer/${job.customer_id}`}>{`${job.customer_name}`}</Link>
    </PageHeader>
  ) : (
    <PageHeader
      pageTitle={job.id ? `${job.job_number} - ${job.job_description}` : "New"}
    >
      <Link to="/customers">Customers</Link>
      <Link to="/unassigned-jobs">Unassigned Jobs</Link>
    </PageHeader>
  );
};

export default JobBreadcrum;
