import React from "react";
import * as timekeepingService from "../services/timekeepingService";
import { merge } from "lodash";

const defaultState = {
  timekeepingListLoading: true,
  timekeepingSelected: null,
  timekeepingList: [],
  timekeepingCount: [],
};

const TimekeepingStateContext = React.createContext();
const TimekeepingDispatchContext = React.createContext();

function assetReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        timekeepingListLoading: action.timekeepingListLoading,
      };
    case "SET_TIMEKEEPING_LIST":
      return {
        ...state,
        timekeepingList: action.timekeepingList,
      };
    case "SET_TIMEKEEPING_COUNT":
      return {
        ...state,
        timekeepingCount: action.timekeepingCount,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TimekeepingProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(assetReducer, mergedState);
  return (
    <TimekeepingStateContext.Provider value={state}>
      <TimekeepingDispatchContext.Provider value={dispatch}>
        {children}
      </TimekeepingDispatchContext.Provider>
    </TimekeepingStateContext.Provider>
  );
}

function useTimekeepingState() {
  const context = React.useContext(TimekeepingStateContext);
  if (context === undefined) {
    throw new Error(
      "useTimekeepingState must be used within a TimekeepingProvider"
    );
  }
  return context;
}

function useTimekeepingDispatch() {
  const context = React.useContext(TimekeepingDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useTimekeepingDispatch must be used within a TimekeepingProvider"
    );
  }
  return context;
}

async function getTimekeepingList(dispatch, filterData) {
  try {
    const timekeepingListResult = await timekeepingService.getTimekeeping(
      filterData
    );
    const timekeepingListExport = await timekeepingService.timekeepingExportHistory();
    const timekeepingList = timekeepingListResult.data.data.entries;
    timekeepingList["8888"] = timekeepingListExport.data.data.files;

    dispatch({
      type: "SET_TIMEKEEPING_LIST",
      timekeepingList: { ...timekeepingList },
    });

    const timekeepingCount = timekeepingListResult.data.data.filter_counts;
    timekeepingCount["8888"] = timekeepingListExport.data.data.files.length;

    dispatch({
      type: "SET_TIMEKEEPING_COUNT",
      timekeepingCount: { ...timekeepingCount },
    });
    dispatch({
      type: "SET_LOADING",
      timekeepingListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getTimekeepingListDataSet(
  timekeepingList,
  dispatch,
  tab,
  offset,
  filterData
) {
  try {
    const result = await timekeepingService.getTimekeepingOffset(
      tab,
      offset,
      filterData
    );
    timekeepingList[tab] = timekeepingList[tab].concat(
      result.data.data.entries
    );
    dispatch({
      type: "SET_TIMEKEEPING_LIST",
      timekeepingList: timekeepingList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchTimekeeping(search, filterData) {
  try {
    const timekeepingListResult = await timekeepingService.getTimekeepingSearch(
      search,
      filterData
    );
    return timekeepingListResult.data.data.entries;
  } catch (error) {
    console.log(error);
  }
}

export {
  TimekeepingProvider,
  useTimekeepingState,
  useTimekeepingDispatch,
  getTimekeepingList,
  searchTimekeeping,
  getTimekeepingListDataSet,
};
