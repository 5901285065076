import React, { useState, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import WorkticketQuoteFMPilot from "pages/workticketPage/workticketQuoteFMPilot";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const WorkticketQuote = (props) => {
  const { refreshWorkticketData } = props;
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [isOpenQuote, setIsOpenQuote] = useState(false);
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  const handleOpenQuote = () => {
    setIsOpenQuote(true);
  };

  const handleCloseQuote = () => {
    setIsOpenQuote(false);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return hasPermission(permissionWorkticket.REQUESTS_QUOTE, permissions) ? (
    <>
      <Box className={classes.containerRating}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenQuote}
              className={classNames(classes.button)}
              disableElevation
            >
              Quote
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      <WorkticketQuoteFMPilot
        workticketId={workticket.id}
        woNumber={workticket.number}
        woStatus={workticket.external?.status_external}
        dne={workticket.external.nte}
        handleClose={handleCloseQuote}
        open={isOpenQuote}
        workticketNumber={workticket.workticket_number}
        handleUpdate={refreshWorkticketData}
      />
    </>
  ) : null;
};

export default WorkticketQuote;
