import React, { useContext, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Counter from "components/common/TableList/counter";
import EvaluationsFilters from "./evaluationsFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import EvaluationsTableActions from "./evaluationsTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import EvaluationsBulkActions from "./evaluationsBulkActions";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import { StyledTabs } from "components/common/Table/stylesTabs";
import { useHistory } from "react-router-dom";
import {
  permissionOpenPositions,
  permissionTalent,
  hasPermission,
} from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useEvaluationsState,
  useEvaluationsDispatch,
  getEvaluationsList,
  getEvaluationsListDataSet,
  searchEvaluationsList,
} from "contexts/evaluationsContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const EvaluationsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { evaluationsListLoading, evaluationsList, evaluationsCount } =
    useEvaluationsState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchEvaluations = useEvaluationsDispatch();
  const dispatchFilters = useFilterDispatch();
  const history = useHistory();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [selectedTab, setSelectedTab] = useState(
    hasPermission(permissionTalent.VIEW_MY_EVALUATIONS, permissions) ? 1 : 2
  );

  const columns = [
    {
      id: "submitted_for",
      label: "Submitted For",
      path: "submitted_for",
    },
    {
      id: "job_description",
      label: "Location",
      path: "job_description",
    },
  ];
  if (selectedTab === 3) {
    columns.push({
      id: "start_date",
      label: "Date Created",
      path: "start_date",
      format: "date",
    });
  } else {
    columns.push({
      id: "completed_date",
      label: "Date Submitted",
      path: "completed_date",
      format: "date",
    });
  }
  columns.push(
    {
      id: "submitted_by",
      label: "Submitted By",
      path: "submitted_by",
    },
    {
      id: "survey_name",
      label: "Survey",
      path: "survey_name",
    },
    {
      id: "score",
      label: "Score",
      content: (row) =>
        !row.total_score && parseInt(row.total_score) !== 0
          ? "-"
          : `${(parseFloat(row.total_score) * 100).toFixed(1)} %`,
    },
    {
      id: "actions",
      content: (row) => <EvaluationsTableActions row={row} tab={selectedTab} />,
      contentMobile: (row) => (
        <EvaluationsTableActions row={row} mobile={true} tab={selectedTab} />
      ),
      style: { width: 55 },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      const user = JSON.parse(localStorage.getItem("user"));
      if (selectedTab === 1) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "submittedFor",
            group: "Submitted For",
            values: [{ label: "", value: user.id }],
          },
          {
            filter: "skipJobConditions",
            group: "skipJobConditions",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 2 }],
          },
        ];
      } else if (selectedTab === 3) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "surveyor",
            group: "Surveyor",
            values: [{ label: "", value: user.id }],
          },
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "skipJobConditions",
            group: "skipJobConditions",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "date_column",
            group: "date_column",
            values: [{ label: "", value: "ss1.created_at" }],
          },
        ];
      } else {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 2 }],
          },
        ];
      }
      await getEvaluationsList(dispatchEvaluations, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchEvaluations, filterState, selectedTab]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: evaluationsList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, evaluationsList]);

  const handleScrollClick = useCallback(async () => {
    if (evaluationsCount <= evaluationsList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      const user = JSON.parse(localStorage.getItem("user"));
      if (selectedTab === 1) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "submittedFor",
            group: "Submitted For",
            values: [{ label: "", value: user.id }],
          },
          {
            filter: "skipJobConditions",
            group: "skipJobConditions",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 2 }],
          },
        ];
      } else if (selectedTab === 3) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "surveyor",
            group: "Surveyor",
            values: [{ label: "", value: user.id }],
          },
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "skipJobConditions",
            group: "skipJobConditions",
            values: [{ label: "", value: 1 }],
          },
          {
            filter: "date_column",
            group: "date_column",
            values: { label: "", value: "ss1.created_at" },
          },
        ];
      } else {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "status",
            group: "Status",
            values: [{ label: "", value: 2 }],
          },
        ];
      }
      await getEvaluationsListDataSet(
        evaluationsList,
        dispatchEvaluations,
        evaluationsList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: evaluationsList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    evaluationsList,
    evaluationsCount,
    filterState,
    dispatchEvaluations,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const user = JSON.parse(localStorage.getItem("user"));
          if (selectedTab === 1) {
            filterData.filters = [
              ...filterData.filters,
              {
                filter: "submittedFor",
                group: "Submitted For",
                values: [{ label: "", value: user.id }],
              },
              {
                filter: "skipJobConditions",
                group: "skipJobConditions",
                values: [{ label: "", value: 1 }],
              },
              {
                filter: "status",
                group: "Status",
                values: [{ label: "", value: 2 }],
              },
            ];
          } else if (selectedTab === 3) {
            filterData.filters = [
              ...filterData.filters,
              {
                filter: "surveyor",
                group: "Surveyor",
                values: [{ label: "", value: user.id }],
              },
              {
                filter: "status",
                group: "Status",
                values: [{ label: "", value: 1 }],
              },
              {
                filter: "skipJobConditions",
                group: "skipJobConditions",
                values: [{ label: "", value: 1 }],
              },
              {
                filter: "date_column",
                group: "date_column",
                values: { label: "", value: "ss1.created_at" },
              },
            ];
          } else {
            filterData.filters = [
              ...filterData.filters,
              {
                filter: "status",
                group: "Status",
                values: [{ label: "", value: 2 }],
              },
            ];
          }
          const results = await searchEvaluationsList(query, filterData);

          dispatchEvaluations({
            type: "SET_EVALUATIONS_LIST",
            evaluationsList: results,
          });

          dispatchEvaluations({
            type: "SET_EVALUATIONS_COUNT",
            evaluationsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterEvaluations: [],
    });
  };

  const handleChangeSubTab = (e, value) => {
    const filterData = getFilterData(filterState);
    if (value === 1) {
      filterData.filters = filterData.filters.filter(
        (f) => f.filter !== "submittedFor"
      );
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: filterData.filters,
      });
    } else if (value === 3) {
      filterData.filters = filterData.filters.filter(
        (f) => f.filter !== "surveyor"
      );
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: filterData.filters,
      });
    }
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterEvaluations: [],
    });
    setSelectedTab(value);
  };

  return (
    <Box className={classes.root}>
      {evaluationsListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs
              handleTabChange={(e, value) => {
                history.push(`/${value}`);
              }}
            >
              <StyledTabs
                value={1}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Team</Box>}
                  key={"team"}
                  value={"team"}
                />
                {hasPermission(permissionTalent.ORG_CHART, permissions) ? (
                  <StyledTab
                    disableRipple={true}
                    label={<Box className={classes.tabItem}>Org Chart</Box>}
                    key="org-chart"
                    value="team/org-chart"
                  />
                ) : null}
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Evaluations</Box>}
                  key={1}
                  value={1}
                />
                {hasPermission(
                  permissionOpenPositions.LIST_AND_VIEW,
                  permissions
                ) ? (
                  <StyledTab
                    disableRipple={true}
                    label={
                      <Box className={classes.tabItem}>Open Positions</Box>
                    }
                    key={"open-position"}
                    value={"open-position"}
                  />
                ) : null}
              </StyledTabs>
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${evaluationsList.length} / ${evaluationsCount}`}</Counter>
            </Box>

            <TableTabs handleTabChange={handleChangeSubTab}>
              <StyledTabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                {hasPermission(
                  permissionTalent.VIEW_MY_EVALUATIONS,
                  permissions
                ) ? (
                  <StyledTab
                    disableRipple={true}
                    label={
                      <Box className={classes.tabItem}>My Evaluations</Box>
                    }
                    key={1}
                    value={1}
                  />
                ) : null}
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Submitted</Box>}
                  key={2}
                  value={2}
                />
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Drafts</Box>}
                  key={3}
                  value={3}
                />
              </StyledTabs>
            </TableTabs>

            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <EvaluationsBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                          <EvaluationsFilters tab={selectedTab} />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(EvaluationsTable);
