import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/MoreVert";
import * as classNames from "classnames";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import { logException } from "components/util/logUtil";
import { convertDateFormatField } from "components/util/timeFormat";
import useStyles from "./styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { fillQuestion } from "services/surveyFrontlineService";
import { surveysLabelsLang } from "constants.js";

const SurveyComment = ({ workticket = {} }) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [pastComment, setPastComment] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [commentType, setCommentType] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingUpdate, setIsUploadingUpdate] = useState(false);
  const {
    selectedQuestions,
    correlative,
    selectedComments,
    lang,
    isPreview,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const handleAddComment = async () => {
    if (comment !== "" && !isPreview && !readonlySurvey) {
      const data = {
        newComments: [
          {
            body: comment,
            type: commentType,
          },
        ],
      };
      try {
        setIsUploading(true);
        const result = await fillQuestion(
          selectedQuestions[correlative].id,
          data
        );
        dispatchSurveyFill({
          type: "SET_SELECTED_COMMENTS",
          selectedComments: result.data.data.question.comments,
        });
        setComment("");
        setIsUploading(false);
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleClickItem = (event, comment) => {
    setAnchorEl(event.currentTarget);
    setPastComment(selectedComment);
    setSelectedComment(comment);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsEdit = () => {
    if (pastComment) {
      pastComment.isEditable = false;
    }
    selectedComment.isEditable = true;
    setEditComment(selectedComment.body);
    setAnchorEl(null);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const handleCancelEdit = () => {
    selectedComment.isEditable = false;
    setPastComment(null);
    setSelectedComment(null);
  };

  const handleUpdateComment = async () => {
    const data = {
      updatedComments: [
        {
          body: editComment,
          type: commentType,
          id: selectedComment.id,
        },
      ],
    };
    try {
      setIsUploadingUpdate(true);
      const result = await fillQuestion(
        selectedQuestions[correlative].id,
        data
      );
      dispatchSurveyFill({
        type: "SET_SELECTED_COMMENTS",
        selectedComments: result.data.data.question.comments,
      });
      setPastComment(null);
      setSelectedComment(null);
      setIsUploadingUpdate(false);
    } catch (e) {
      logException(e, "Cannot update comment");
    }
  };

  const deleteComment = () => {
    const data = {
      deleteComments: [
        {
          id: selectedComment.id,
        },
      ],
    };
    try {
      fillQuestion(selectedQuestions[correlative].id, data);
      const comments = selectedComments.filter(
        (c) => c.id !== selectedComment.id
      );
      setOpenConfirm(false);
      dispatchSurveyFill({
        type: "SET_SELECTED_COMMENTS",
        selectedComments: comments,
      });
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  return (
    <Box className={classes.containerBodyActivity}>
      <Box className={classes.containerHeaderComments}>
        <Typography className={classes.docCommentsTitle}>
          {surveysLabelsLang[lang].feedbackCommentsLabel}
        </Typography>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={commentType === 1}
              onChange={() => setCommentType(1)}
            />
          }
          label={
            surveysLabelsLang[lang].feedbackCommentsTeamLabe
          }
          value={1}
          className={classes.docCommentsType}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={commentType === 2}
              onChange={() => setCommentType(2)}
            />
          }
          label={
            surveysLabelsLang[lang].feedbackCommentsPrivateLabel
          }
          value={2}
          className={classes.docCommentsType}
        /> */}
      </Box>
      <Box className={classes.containerComments}>
        <Box className={classes.containerCommentsForm}>
          <Input
            className={classes.inputNewComment}
            disableUnderline={true}
            rows={8}
            multiline={true}
            fullWidth={true}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box className={classes.boxActions}>
            {!readonlySurvey ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(
                  classes.button,
                  classes.buttonSecondary,
                  classes.marginTop10
                )}
                onClick={handleAddComment}
                disabled={isUploading}
              >
                {surveysLabelsLang[lang].feedbackCommentsAddLabel}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {isUploading && <LinearProgress color="secondary" />}
      </Box>
      <List className={classes.containerCommentsList}>
        {selectedComments.map((comment) => (
          <ListItem
            key={comment.id}
            className={
              comment.isEditable
                ? classes.listItemComment
                : classes.listContainer
            }
          >
            {comment?.user[0]?.first_name ? (
              <ListItemAvatar>
                <Avatar className={classes.avatarComment}>
                  {comment.user[0]?.first_name.charAt(0)}
                  {comment.user[0]?.last_name.charAt(0)}
                </Avatar>
              </ListItemAvatar>
            ) : null}

            {comment.isEditable ? (
              <ListItemText
                disableTypography={true}
                className={classes.listText}
                secondary={
                  <Box className={classes.containerCommentsForm}>
                    <Input
                      className={classes.inputEditComment}
                      disableUnderline={true}
                      rows={6}
                      multiline={true}
                      fullWidth={true}
                      autoFocus={true}
                      value={editComment}
                      onChange={(e) => setEditComment(e.target.value)}
                    />
                    <Box className={classes.boxActionsComment}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        disableElevation
                        className={classNames(
                          classes.button,
                          classes.buttonOutlinedComment
                        )}
                        onClick={handleCancelEdit}
                      >
                        {surveysLabelsLang[lang].feedbackCommentsCancelLabel}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        className={classNames(
                          classes.button,
                          classes.buttonSecondary
                        )}
                        onClick={handleUpdateComment}
                        disabled={isUploadingUpdate}
                      >
                        {surveysLabelsLang[lang].feedbackCommentsUpdateLabel}
                      </Button>
                    </Box>
                    {isUploadingUpdate && <LinearProgress color="secondary" />}
                  </Box>
                }
              />
            ) : (
              <ListItemText
                className={classes.listText}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.listName}
                      color="textPrimary"
                    >
                      {comment.user[0]?.first_name} {comment.user[0]?.last_name}
                    </Typography>
                    {comment.body}
                  </React.Fragment>
                }
              />
            )}
            {!comment.isEditable && (
              <ListItemSecondaryAction className={classes.listAction}>
                {convertDateFormatField(comment.created_at)}
                <IconButton
                  aria-controls="wt-comment-menu"
                  aria-label="comment-edit"
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleClickItem(event, comment);
                  }}
                  className={classes.menuItemIconComment}
                  disabled={readonlySurvey}
                >
                  <MenuIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>

      <Menu
        id="comment-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          className: classes.menu,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            markAsEdit();
          }}
          className={classes.menuItem}
        >
          {surveysLabelsLang[lang].feedbackCommentsEditLabel}
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDelete();
          }}
          className={classes.menuItem}
        >
          {surveysLabelsLang[lang].feedbackCommentsDeleteLabel}
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={openConfirm}
        handleConfirm={deleteComment}
        handleClose={closeConfirm}
        message={surveysLabelsLang[lang].feedbackCommentsDeleteMsgLabel}
      />
    </Box>
  );
};

export default SurveyComment;
