import React, { useState, useEffect } from "react";
import auth from "services/authService";
import MainLayoutLogin from "components/common/MainLayoutLogin";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { errorArray } from "components/util/error";
import useStyles from "./loginForm.styles";
import Joi from "joi-browser";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const schema = Joi.object({
  password: Joi.string().min(5).required().label("Password"),
  email: Joi.string().min(2).required().label("Email Address or User ID"),
});

const path = "/dashboard";

const LoginForm = (props) => {
  const classes = useStyles();
  const [deviceType, setDeviceType] = useState("pc");
  const [data, setData] = useState({ password: "", email: "" });
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [hasLoginError, setHasLoginError] = useState("");

  useEffect(() => {
    function checkUserAgent() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        )
      ) {
        setDeviceType("mobile");
      }
    }
    checkUserAgent();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const dataInput = { ...data };
    dataInput[input.name] = input.value;
    setData(dataInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      doSubmit(event);
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) {
      window.location = path;
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (hasError) {
      const errorResult = errorArray(schema, data);
      if (errorResult && Object.keys(errorResult).length) {
        setErrors(errorResult);
        setHasError(true);
      } else {
        setErrors([]);
        setHasError(false);
      }
    }
  }, [data, hasError]);

  const doSubmit = async (event) => {
    setHasLoginError("");
    const errorResult = errorArray(schema, data);
    if (errorResult && Object.keys(errorResult).length) {
      setErrors(errorResult);
      setHasError(true);
      return false;
    }
    setIsWorking(true);
    try {
      const { email, password } = data;
      await auth.login(email, password, deviceType);
      window.location = path;
    } catch (ex) {
      setIsWorking(false);
      if (
        ex.response &&
        (ex.response.status === 400 || ex.response.status === 401)
      ) {
        setHasLoginError(ex.response.data.message);
      }
    }
  };

  const handleForgotPassword = () => {
    const { history } = props;
    history.push("/forgot-password");
  };

  if (isLoading) {
    return <LoadingStateHorizontal isVisible style={classes.centerLoading} />;
  }

  return (
    <MainLayoutLogin>
      <Container maxWidth="sm">
        <Grid container className={classes.root}>
          <CardContent className={classes.card}>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Sign In to your Workspace
            </Typography>
            {hasLoginError && (
              <Alert severity="error" className={classes.alert}>
                {hasLoginError}
              </Alert>
            )}
            <TextField
              name="email"
              className={classes.field}
              value={data.email}
              disabled={isWorking}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
              }}
              fullWidth
              error={errors && errors["email"] ? true : false}
              helperText={errors && errors["email"] ? errors["email"] : ""}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Email Address or User ID"
            />
            <TextField
              type="password"
              name="password"
              value={data.password}
              disabled={isWorking}
              className={classes.field}
              InputProps={{
                className: classes.input,
                minLength: 5,
                disableUnderline: true,
              }}
              fullWidth
              error={errors && errors["password"] ? true : false}
              helperText={
                errors && errors["password"] ? errors["password"] : ""
              }
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Password"
            />
            <Box className={classes.cardInfo}>
              <Link
                color="secondary"
                className={classes.forgotPasswordLink}
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Link>
            </Box>
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth={true}
                disabled={isWorking}
                className={classes.loginButton}
                onClick={doSubmit}
                size="large"
              >
                Sign In
              </Button>
            </CardActions>
            <Box className={classes.cardFooter}>
              <Typography
                variant="caption"
                gutterBottom
                className={classes.textFooter}
              >
                By logging in, you agree to our{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.encompassonsite.com/terms"
                  className={classes.linkLabel}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.encompassonsite.com/privacy"
                  className={classes.linkLabel}
                >
                  Privacy Policy
                </a>
              </Typography>
            </Box>
            {isWorking && (
              <Box className={classes.centerLoadingLogin}>
                <LoadingStateHorizontal isVisible />
              </Box>
            )}
          </CardContent>
        </Grid>
      </Container>
    </MainLayoutLogin>
  );
};

export default LoginForm;
