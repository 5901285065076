import React from "react";
import Dialog from "./dialog";

const Survey = () => {
  return (
    <>
      <Dialog />
    </>
  );
};

export default Survey;
