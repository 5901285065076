import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TimerIcon from "@material-ui/icons/Timer";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import useStyles from "./styles";

const complianceOptions = {
  1: { label: "Non Compliant", icon: <RemoveCircleIcon />, color: "#F07A8C" },
  2: { label: "On-Time", icon: <TimerIcon />, color: "#77C8EB" },
  3: {
    label: "On-Time and On-Site",
    icon: <LocationOnIcon />,
    color: "#A67EC8",
  },
  4: {
    label: "Fully Compliant",
    icon: <PlaylistAddCheckIcon />,
    color: "#6973C6",
  },
};

const typeOption = (onTime, onSite, hasDocuments) => {
  if (onTime && onSite && hasDocuments) {
    return 4;
  } else if (onTime && onSite) {
    return 3;
  } else if (onTime) {
    return 2;
  } else {
    return 1;
  }
};

const WorkticketComplianceChip = (props) => {
  const classes = useStyles();
  const { onTime, onSite, hasDocuments } = props;

  const option = typeOption(
    Number(onTime),
    Number(onSite),
    Number(hasDocuments)
  );

  if (!option) return null;

  return (
    <Chip
      icon={complianceOptions[option].icon}
      label={complianceOptions[option].label}
      className={classNames(classes.chip)}
      style={{ backgroundColor: complianceOptions[option].color }}
    />
  );
};

export default WorkticketComplianceChip;
