import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import Fab from "@material-ui/core/Fab";
import StartIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import {
  getWorkticketSchedule,
  clockInWorkticketStrict,
  clockInWorkticketNonStrict,
  clockOutWorkticket,
} from "services/workticketService";
import { getUserSchedules } from "services/userService";
import { useWorkticketState } from "contexts/workticketContext";
import GlobalUiContext from "contexts/globalUiContext";
import ErrorDialog from "../../dialog/errorDialog";
import { MinutesFromNowStandard } from "components/util/timeFormat";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketTimekeeping = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { workticketLocation } = useWorkticketState();
  const [msgError, setMsgError] = useState("");
  const [openError, setOpenError] = useState(false);
  const [timekeeping, setTimekeeping] = useState(null);
  const { permissions, timekeeping: timekeepingGlobal } = globalUi;

  const { workticket } = props;
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (
      timekeepingGlobal &&
      timekeepingGlobal.workticket &&
      workticket &&
      workticket.id
    ) {
      if (timekeepingGlobal.workticket.id === workticket.id) {
        setTimekeeping(timekeepingGlobal);
      }
    }
  }, [timekeepingGlobal, workticket]);

  const positionData = (drive) => {
    return {
      drive,
      latitude: workticketLocation ? workticketLocation.coords.longitude : null,
      longitude: workticketLocation ? workticketLocation.coords.latitude : null,
    };
  };

  const handleStart = async () => {
    const resultClockActive = await getUserSchedules(user.id);
    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }

    const resultSchedule = await getWorkticketSchedule(workticket.id);
    let validSchedule = false;
    let scheduleId = 0;
    if (!workticket.is_strict_scheduling) {
      validSchedule = true;
    }
    if (workticket.is_strict_scheduling && resultSchedule.data.data.schedules) {
      resultSchedule.data.data.schedules.map((schedule) => {
        const minutesStart = MinutesFromNowStandard(schedule.start_date);
        const minutesEnd = MinutesFromNowStandard(schedule.end_date);
        const userSchedule = schedule.users.find(
          (uSchedule) => uSchedule.id === user.id
        );
        if (minutesStart >= -7 && minutesEnd <= 0 && userSchedule) {
          validSchedule = true;
          scheduleId = schedule.id;
        }
        return true;
      });
    }

    if (!validSchedule) {
      setMsgError("User does not have a schedule.");
      setOpenError(true);
      return false;
    }

    if (workticket.is_strict_scheduling) {
      const data = positionData(0);
      const result = await clockInWorkticketStrict(
        workticket.id,
        scheduleId,
        data
      );
      const entry = result.data.data.entry;
      setTimekeeping(entry);

      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
    } else {
      try {
        const data = positionData(0);
        const result = await clockInWorkticketNonStrict(workticket.id, data);

        const schedule = result.data.data.schedule;
        const entry = schedule.entries.find(
          (item) => !item.clock_hours && item.user.id === user.id
        );
        if (entry.user.id === user.id) {
          setTimekeeping(entry);
          const entryInput = { ...entry, workticket: { ...workticket } };
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: entryInput,
          });
        }
      } catch (e) {
        setMsgError(
          "Cannot clock In, this user is not assigned to this workticket."
        );
        setOpenError(true);
      }
    }
  };

  const handleStop = async () => {
    try {
      if (!timekeeping) {
        console.log("No clock is running");
        return;
      }
      const data = positionData(timekeepingGlobal.drive_in ? 1 : 0);
      await clockOutWorkticket(
        workticket.id,
        timekeeping.workticket_schedule_id,
        data
      );
      setTimekeeping(null);
      if (
        globalUi.timekeeping &&
        globalUi.timekeeping.workticket.id === workticket.id
      ) {
        dispatchGlobalUi({
          type: "SET_TIMEKEEPING",
          timekeeping: null,
        });
      }
    } catch (e) {
      logException(e, "Cannot stop clock");
    }
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  if (!hasPermission(permissionWorkticket.CLOCK, permissions)) {
    return null;
  }

  return (
    <>
      {!timekeeping ? (
        <Fab
          size="small"
          color="primary"
          aria-label="start"
          className={classNames(classes.button, classes.buttonStart)}
          disabled={
            [2, 3, 4].includes(workticket.status) || workticket.archived
          }
          onClick={handleStart}
        >
          <StartIcon className={classes.icon} />
        </Fab>
      ) : (
        <Fab
          size="small"
          color="primary"
          aria-label="stop"
          className={classNames(classes.button, classes.buttonStop)}
          onClick={handleStop}
        >
          <StopIcon className={classes.icon} />
        </Fab>
      )}
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default WorkticketTimekeeping;
