import React from "react";
import workOrderLogo from "../../assets/icons/icon-work-order.png";
import questionLogo from "../../assets/icons/icon-questions-and-comments.png";
import emergencyLogo from "../../assets/icons/icon-emergency.png";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const options = [
  {
    type: 1,
    icon: workOrderLogo,
    title: "Work Order",
    description: "Work to be completed within 2-5 days",
  },
  {
    type: 2,
    icon: questionLogo,
    title: "Questions and Comments",
    description: "General Inquiry",
  },
  {
    type: 3,
    icon: emergencyLogo,
    title: "Emergency",
    description: "Needs to be completed within 24 hours",
  },
];

const RequestOption = (props) => {
  const classes = useStyles();

  const { type, handleClick } = props;

  return (
    <Grid container className={classes.optionContainer} spacing={2}>
      {options.map((item) => (
        <Grid item xs={4} key={item.type}>
          <Paper
            className={
              type === item.type
                ? classes.optionPaperActive
                : classes.optionPaper
            }
            square={true}
            elevation={0}
          >
            <Box
              className={classes.optionBox}
              onClick={() => handleClick(item.type)}
            >
              <img src={item.icon} alt={item.title} />
              <Typography
                variant="body1"
                className={classes.optionBoxTitle}
                gutterBottom
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                className={classes.optionBoxContent}
                gutterBottom
              >
                {item.description}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default RequestOption;
