import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import { createTask } from "services/surveysTasksService";
import { validate, validateProperty } from "components/util/validationUtil";
import {
  useSurveysTasksState,
  useSurveysTasksDispatch,
} from "contexts/surveysTasksContext";

import useStyles from "./styles";

const taskSchema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
};

const taskBiSchema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  name_es: Joi.string()
    .min(1)
    .required()
    .label("Nombre")
    .error(() => {
      return {
        message: "Nombre es requerido.",
      };
    }),
};

const initialData = {
  name: "",
  name_es: "",
  tooltip: "",
  tooltip_es: "",
  has_es: false,
};

const ManageTaskDialog = (props) => {
  const classes = useStyles();
  const [schema, setSchema] = useState(taskSchema);
  const [data, setData] = useState(initialData);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const { openManageTask } = useSurveysTasksState();
  const dispatchSurveysTasks = useSurveysTasksDispatch();

  useEffect(() => {
    if (openManageTask) {
      setError([]);
      setSchema(taskSchema);
      setData(initialData);
    }
  }, [openManageTask]);

  const handleClose = () => {
    dispatchSurveysTasks({
      type: "TOGGLE_MANAGE",
      openManageTask: false,
    });
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);
        if (!data.has_es) {
          data.name_es = "";
          data.tooltip_es = "";
        }
        const task = await createTask(data);
        dispatchSurveysTasks({
          type: "TOGGLE_SET_RECENT_TASK",
          recentlyTaskCreated: task.data.data.question,
        });
        setIsLoadingDialog(false);
        handleClose();
      }
    } catch (e) {
      console.log("Cannot create Task");
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};
    switch (event.name) {
      case "name":
        newData = {
          ...data,
          name: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;

      case "tooltip":
        newData = {
          ...data,
          tooltip: event.value,
        };
        break;
      case "name_es":
        newData = {
          ...data,
          name_es: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;

      case "tooltip_es":
        newData = {
          ...data,
          tooltip_es: event.value,
        };
        break;
      default:
        break;
    }
    setData(newData);
  };

  const handleOnChange = (event) => {
    if (event.target.checked) {
      setSchema(taskBiSchema);
    } else {
      setSchema(taskSchema);
    }
    const newData = {
      ...data,
      has_es: event.target.checked,
    };
    setData(newData);
  };

  return (
    <>
      <Dialog
        open={openManageTask}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={data.has_es ? "md" : "xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog2}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Grid container>
                <Grid item md={data.has_es ? 6 : 12}>
                  <Box className={classes.titleContainer}>
                    <FormatListBulletedIcon className={classes.titleIcon} />
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.titleDialog}
                    >
                      New Task
                    </Typography>
                  </Box>
                  <Box
                    className={
                      data.has_es
                        ? classes.formFieldsContainer2
                        : classes.formFieldsContainer
                    }
                  >
                    <Box className={classes.formToggleContainer}>
                      {!data.has_es ? (
                        <FormControlLabel
                          className={classes.spanishToggle}
                          control={
                            <Checkbox
                              checked={data.has_es}
                              onChange={handleOnChange}
                              color="secondary"
                              className={classes.spanishToggleCheck}
                            />
                          }
                          label="Spanish"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="name"
                      label="Task Name"
                      error={error}
                      value={data.name}
                      handleBlur={handleBlur}
                    />
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="tooltip"
                      label="Tool Tip"
                      error={error}
                      value={data.tooltip}
                      handleBlur={handleBlur}
                      multiline={true}
                      rows={3}
                    />
                  </Box>
                </Grid>
                {data.has_es ? (
                  <Grid item md={6}>
                    <Box className={classes.titleContainer}>
                      <FormatListBulletedIcon className={classes.titleIcon} />
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleDialog}
                      >
                        Nueva Tarea
                      </Typography>
                    </Box>
                    <Box className={classes.formFieldsContainer}>
                      <Box className={classes.formToggleContainer}>
                        <FormControlLabel
                          className={classes.spanishToggle}
                          control={
                            <Checkbox
                              checked={data.has_es}
                              onChange={handleOnChange}
                              color="secondary"
                              className={classes.spanishToggleCheck}
                            />
                          }
                          label="Spanish"
                        />
                      </Box>
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="name_es"
                        label="Nombre"
                        error={error}
                        value={data.name_es}
                        handleBlur={handleBlur}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="tooltip_es"
                        label="Etiqueta"
                        error={error}
                        value={data.tooltip_es}
                        handleBlur={handleBlur}
                        multiline={true}
                        rows={3}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              <ButtonBase color="secondary" onClick={handleConfirm}>
                Create Task
              </ButtonBase>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default ManageTaskDialog;
