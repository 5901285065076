import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import TimekeepingDownload from "./timekeepingDownload";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import TimekeepingFilters from "./timekeepingFilters";
import TimekeepingBulkActions from "./timekeepingBulkActions";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import TimekeepingIconSynch from "components/ui/Worktickets/timekeepingIconSynch";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { timekeepingTabOptions } from "constants.js";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useTimekeepingState,
  useTimekeepingDispatch,
  getTimekeepingList,
  searchTimekeeping,
  getTimekeepingListDataSet,
} from "contexts/timekeepingContext";

import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "ID",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => !row.is_exported,
    },
  },
  {
    id: "user.employee_number",
    label: "Employee Number",
  },
  {
    id: "user_name",
    label: "Employee Name",
    content: (row) => `${row.user.first_name} ${row.user.last_name}`,
    disableSort: true,
  },
  {
    id: "workticket.number",
    label: "Workticket",
    format: "link",
    path: (row) => (row.workticket ? `/workticket/${row.workticket.id}` : ``),
  },
  {
    id: "work_date",
    label: "Date",
    format: "date",
  },
  {
    id: "clock_hours",
    label: "Hours",
  },
  {
    id: "in_clock",
    label: "In",
    content: (row) =>
      row.clock_in ? `${moment(row.clock_in).format("LT")}` : `-`,
    disableSort: true,
  },
  {
    id: "out_clock",
    label: "Out",
    content: (row) =>
      row.clock_out ? `${moment(row.clock_out).format("LT")}` : `-`,
    disableSort: true,
  },
  {
    id: "drive_hours",
    label: "Drive",
    content: (row) => (row.drive_hours > 0 ? `${row.drive_hours}` : `-`),
    disableSort: true,
  },
  {
    id: "drive_in",
    label: "In",
    content: (row) =>
      row.drive_in ? `${moment(row.drive_in).format("LT")}` : `-`,
    disableSort: true,
  },
  {
    id: "drive_out",
    label: "Out",
    content: (row) =>
      row.drive_out ? `${moment(row.drive_out).format("LT")}` : `-`,
    disableSort: true,
  },
  {
    id: "manual",
    label: "Manual",
    style: { textAlign: "center" },
    content: (row) =>
      row.clock_is_manual_entry || row.drive_is_manual_entry ? (
        <CheckCircleIcon />
      ) : null,
    disableSort: true,
  },
  {
    id: "sync",
    label: "",
    content: (row) => <TimekeepingIconSynch row={row} />,
    disableSort: true,
  },
];

const columnsExport = [
  {
    id: "id",
    label: "ID",
    format: "id",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "file_name",
    label: "File Name",
  },
  {
    id: "created_at",
    label: "Date",
    format: "date",
  },
  {
    id: "created_by",
    label: "Created By",
    content: (row) =>
      `${row.export_file[0].user.first_name} ${row.export_file[0].user.last_name}`,
    disableSort: true,
  },
  {
    id: "file",
    label: "",
    content: (row) => <TimekeepingDownload fileId={row.id} />,
    style: { width: 150 },
    disableSort: true,
  },
];

const SEARCH_INDEX = "9999";
const FILE_INDEX = "8888";

const TimekeepingTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { timekeepingListLoading, timekeepingList, timekeepingCount } =
    useTimekeepingState();
  const { selected, currentTab } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchTimekeeping = useTimekeepingDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const moduleName = window.location.pathname.split("/")[1];
    if (localStorage.getItem(`filter_${moduleName}`)) {
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: JSON.parse(
          localStorage.getItem(
            `filter_${window.location.pathname.split("/")[1]}`
          )
        ),
      });
    }
    if (localStorage.getItem(`filter_date_${moduleName}`)) {
      const dataDate = JSON.parse(
        localStorage.getItem(`filter_date_${moduleName}`)
      );
      dispatchFilters({
        type: "SET_DATE",
        ...dataDate,
      });
    }
    dispatchFilters({
      type: "SET_LOADING",
      isLoadingFilters: false,
    });
  }, [dispatchFilters]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getTimekeepingList(dispatchTimekeeping, filterData);
      dispatchTable({ type: "SET_SELECTED", selected: [] });
    };

    if (filterState.searchString === "" && !filterState.isLoadingFilters) {
      fetchData();
    }
  }, [dispatchTimekeeping, dispatchTable, filterState]);

  useEffect(() => {
    if (currentTab !== FILE_INDEX) {
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    } else {
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columnsExport });
    }
    const timekeepingData = timekeepingList[currentTab] ?? [];
    dispatchTable({ type: "UPDATE_ROWS", rows: timekeepingData });
  }, [dispatchTable, timekeepingList, currentTab]);

  useEffect(() => {
    if (!timekeepingListLoading) {
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "active" });
    }
  }, [dispatchTable, timekeepingListLoading]);

  const handleTabChange = (event, newTab) => {
    const fetchData = async () => {
      if (currentTab === SEARCH_INDEX) {
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: "",
        });
      }
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: newTab });
    };
    fetchData();
    setTimeout(() => {
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: null,
      });
    }, 3000);
  };

  const handleScrollClick = useCallback(async () => {
    const timekeepingData = timekeepingList[currentTab] ?? [];

    if (timekeepingCount[currentTab] <= timekeepingData.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getTimekeepingListDataSet(
        timekeepingList,
        dispatchTimekeeping,
        currentTab,
        timekeepingData.length,
        filterData
      );
      const timekeepingDataUpdate = timekeepingList[currentTab]
        ? timekeepingList[currentTab]
        : [];
      dispatchTable({ type: "UPDATE_ROWS", rows: timekeepingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    currentTab,
    timekeepingList,
    timekeepingCount,
    filterState,
    dispatchTimekeeping,
    dispatchTable,
    loadingMore,
  ]);

  const handleSearch = (ev, query) => {
    const searchStatus = SEARCH_INDEX;
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchTimekeeping(query, filterData);

          // Send result to search tab
          timekeepingList[searchStatus] = results;
          dispatchTimekeeping({
            type: "SET_TIMEKEEPING_LIST",
            timekeepingList: { ...timekeepingList },
          });

          timekeepingCount[searchStatus] = results.length;
          dispatchTimekeeping({
            type: "SET_TIMEKEEPING_COUNT",
            timekeepingCount: timekeepingCount,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          dispatchTable({ type: "SET_CURRENT_TAB", currentTab: searchStatus });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });

    dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "active" });
  };

  return (
    <Box className={classes.root}>
      {timekeepingListLoading || loadingSearch || currentTab === -1 ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${timekeepingList[currentTab].length} / ${timekeepingCount[currentTab]}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={currentTab}>
              {timekeepingTabOptions
                .filter((item) => item.onTabing)
                .map((option) => {
                  return (
                    <StyledTab
                      disableRipple={true}
                      label={
                        <Box className={classes.tabItem}>{option.text}</Box>
                      }
                      value={option.tab}
                      key={option.id}
                    />
                  );
                })}
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Synch History</Box>}
                key={FILE_INDEX}
                value={FILE_INDEX}
              />
              {Boolean(filterState.searchString) && (
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Search</Box>}
                  key={SEARCH_INDEX}
                  value={SEARCH_INDEX}
                />
              )}
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <TimekeepingBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                          <TimekeepingFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(TimekeepingTable);
