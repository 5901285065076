import React, { useContext } from "react";
import PublishSurveyDialog from "./publishSurveyDialog";
import GlobalUiContext from "contexts/globalUiContext";

const SurveyAdminDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <PublishSurveyDialog />
    </>
  );
};
export default SurveyAdminDialog;
