import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import useStyles from "./styles";

const ChipList = ({ items, maxItems, isArray = false, nameProp }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let itemsArray;
  if (isArray) {
    itemsArray = items;
  } else {
    itemsArray = items ? items.split("|") : [];
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const displayedItems = itemsArray.slice(0, maxItems);

  //classes.whiteChips if array
  return (
    <>
      {displayedItems.map((item, index) => (
        <Chip
          label={isArray ? item[nameProp] : item}
          key={index}
          size="small"
          variant="outlined"
          className={isArray ? classes.whiteChips : classes.infoChip}
        />
      ))}
      {itemsArray.length > maxItems && (
        <>
          <Chip
            label="..."
            key="more"
            size="small"
            variant="outlined"
            onClick={handleClick}
            className={classes.chips}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              className: classes.popoverPaper,
            }}
          >
            {itemsArray.slice(maxItems).map((item, index) => (
              <Chip
                label={isArray ? item[nameProp] : item}
                key={index + maxItems}
                size="small"
                variant="outlined"
                className={classes.chips}
              />
            ))}
          </Popover>
        </>
      )}
    </>
  );
};

export default ChipList;
