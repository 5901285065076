import React from "react";
import moment from "moment";
import MainLayoutGuest from "components/common/MainLayoutGuest";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import RequestStart from "./requestStart";
import RequestStep1 from "./requestStep1";
import RequestStep2 from "./requestStep2";
import RequestStep3 from "./requestStep3";
import RequestStep4 from "./requestStep4";
import RequestCompletedDialog from "./requestCompletedDialog";
import RequestAddDialog from "./requestAddDialog";

import { StateProvider } from "components/util/state";

import useStyles from "./styles";

const initialState = {
  company: null,
  list: [],
  data: {
    type: 0,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    job_department_id: "",
    job_zone_id: "",
    job_number: "",
    nearest_location: "",
    summary: "",
    description: "",
    due_date: moment().add(30, "days"),
    due_notes: "",
    files: undefined,
    status: 1,
  },
  step: 0,
  completed: false,
  addDialog: false,
  add: false,
  submitted: false,
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...initialState, list: [] };
    case "SET_COMPANY":
      return {
        ...state,
        company: action.company,
      };
    case "CHANGE_STEP":
      return {
        ...state,
        step: action.newStep,
      };
    case "UPDATE_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.updateData,
        },
        step: action.newStep ?? state.step,
      };
    case "COMPLETED":
      const listSubmit = state.list;
      listSubmit.push(state.data);
      return {
        ...initialState,
        list: listSubmit,
        completed: true,
        isLoading: state.isLoading,
        step: state.step,
        company: state.company,
      };
    case "ADD_REQUEST":
      return {
        ...state,
        add: action.status,
      };
    case "NEW_REQUEST":
      const list = state.list;
      list.push(state.data);
      return {
        list: list,
        data: {
          ...state.data,
          job_number: "",
          building_zone: "",
          summary: "",
          description: "",
          zone: "",
          due_date: moment().add(30, "days"),
          due_notes: "",
          files: undefined,
        },
        step: 2,
        add: false,
        completed: false,
        company: state.company,
      };
    case "LOADING":
      return {
        ...state,
        isLoading: action.loading,
      };
    default:
      return state;
  }
};

const RequestNonFacility = (props) => {
  const classes = useStyles();

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <MainLayoutGuest>
        <Container maxWidth="sm">
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Paper className={classes.paper} square={true}>
                <RequestStart subdomain={props.match.params.subdomain} />
                <RequestStep1 />
                <RequestStep2 />
                <RequestStep3 />
                <RequestStep4 />
                <RequestCompletedDialog />
                <RequestAddDialog />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </MainLayoutGuest>
    </StateProvider>
  );
};

export default RequestNonFacility;
