import React, { useState } from "react";
import useStyles from "./styles";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInfromation from "components/ui/FormContent/formInformation";
import Box from "@material-ui/core/Box";
import CheckCircle from "@material-ui/icons/CheckCircle";
import FormInput from "components/ui/FormContent/formInput";
import {
  useQuoteItemUserState,
  useQuoteItemUserDispatch,
} from "contexts/quoteItemUserContext";
import { useQuoteState } from "contexts/quoteContext";
import {
  userAssignmentType,
  inHousePaymentTypes,
  subcontractorPaymentTypes,
  userUOMTypes,
} from "constants.js";
import { validateProperty } from "components/util/validationUtil";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const QuoteUserItem = (props) => {
  const classes = useStyles();
  const {
    quoteItemUserSchema,
    data,
    options,
    hours,
    hideUserType,
    isDetail,
    index,
    quoteApproved,
    readonly,
    fromCustom,
  } = props;
  const { quoteItemUsers, deletedQuoteItemUsers, changedQuoteItemUsers } =
    useQuoteItemUserState();
  const { quoteDetails } = useQuoteState();
  const dispatchQuoteItemUser = useQuoteItemUserDispatch();

  const handleChangeUser = (event, value) => {
    if (value) {
      quoteItemUsers[index] = {
        ...data,
        user: value,
        user_id: value.value,
        unit_cost: value.hour_rate
          ? Math.round(parseFloat(value.hour_rate) * 100) / 100
          : 0,
        total_cost: value.hour_rate
          ? Math.round(parseFloat(value.hour_rate) * data.quantity * 100) / 100
          : 0,
      };
      if (!quoteItemUsers[index].isNew) {
        const sel = changedQuoteItemUsers.filter(
          (item) => item.index === index
        );
        if (sel.length > 0) {
          sel[0] = {
            ...sel[0],
            user_id: value.value,
            unit_cost: value.hour_rate
              ? Math.round(parseFloat(value.hour_rate) * 100) / 100
              : 0,
            total_cost: value.hour_rate
              ? Math.round(parseFloat(value.hour_rate) * data.quantity * 100) /
                100
              : 0,
          };
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers.filter((item) => item.index !== index),
              sel[0],
            ],
          });
        } else {
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers,
              {
                index,
                id: quoteItemUsers[index].id,
                user_id: value.value,
                unit_cost: value.hour_rate
                  ? Math.round(parseFloat(value.hour_rate) * 100) / 100
                  : 0,
                total_cost: value.hour_rate
                  ? Math.round(
                      parseFloat(value.hour_rate) * data.quantity * 100
                    ) / 100
                  : 0,
              },
            ],
          });
        }
      }
    } else {
      quoteItemUsers[index] = {
        ...data,
        user: value,
        user_id: -1,
        unit_cost: 0,
        total_cost: 0,
      };
    }
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
    validateProperty("user", value, quoteItemUserSchema, data.error, setError);
  };

  const setError = (error) => {
    quoteItemUsers[index].error = error;
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
  };

  const handleChangePayment = (event, value) => {
    if (value) {
      quoteItemUsers[index] = {
        ...data,
        payment: value,
        payment_type: value.value,
      };
      if (!quoteItemUsers[index].isNew) {
        const sel = changedQuoteItemUsers.filter(
          (item) => item.index === index
        );
        if (sel.length > 0) {
          sel[0] = {
            ...sel[0],
            payment_type: value.value,
          };
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers.filter((item) => item.index !== index),
              sel[0],
            ],
          });
        } else {
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers,
              {
                index,
                id: quoteItemUsers[index].id,
                payment_type: value.value,
              },
            ],
          });
        }
      }
    } else {
      quoteItemUsers[index] = {
        ...data,
        payment_type: null,
        payment: null,
      };
    }
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
    validateProperty(
      "payment",
      value,
      quoteItemUserSchema,
      data.error,
      setError
    );
    if (value && value.value === 0 && data.total_cost === 0) {
      setError([
        {
          key: "payment",
          message: "Up Front Payment should be greater than 0.",
        },
      ]);
    }
  };

  const handleChangeUOM = (event, value) => {
    if (value) {
      quoteItemUsers[index] = {
        ...data,
        uomObject: value,
        uom: value.value,
        quantity: value.value === 3 ? 1 : data.quantity,
        total_cost:
          value.value === 3
            ? Math.round(data.unit_cost * 100) / 100
            : Math.round(data.unit_cost * parseFloat(data.quantity) * 100) /
              100,
      };
      if (!quoteItemUsers[index].isNew) {
        const sel = changedQuoteItemUsers.filter(
          (item) => item.index === index
        );
        if (sel.length > 0) {
          sel[0] = {
            ...sel[0],
            uom: value.value,
          };
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers.filter((item) => item.index !== index),
              sel[0],
            ],
          });
        } else {
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers,
              {
                index,
                id: quoteItemUsers[index].id,
                uom: value.value,
              },
            ],
          });
        }
      }
      dispatchQuoteItemUser({
        type: "SET_QUOTE_ITEM_USERS",
        quoteItemUsers: quoteItemUsers,
      });
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let total = 0;
    switch (event.name) {
      case "quantity":
        total =
          Math.round(data.unit_cost * parseFloat(event.value) * 100) / 100;
        quoteItemUsers[index] = {
          ...data,
          total_cost: total,
          quantity: Math.round(parseFloat(event.value) * 100) / 100,
        };
        if (!quoteItemUsers[index].isNew) {
          const sel = changedQuoteItemUsers.filter(
            (item) => item.index === index
          );
          if (sel.length > 0) {
            sel[0] = {
              ...sel[0],
              total_cost: total,
              quantity: Math.round(parseFloat(event.value) * 100) / 100,
              hours: Math.round(parseFloat(event.value) * 100) / 100,
            };
            dispatchQuoteItemUser({
              type: "SET_CHANGED_QUOTE_ITEM_USERS",
              changedQuoteItemUsers: [
                ...changedQuoteItemUsers.filter((item) => item.index !== index),
                sel[0],
              ],
            });
          } else {
            dispatchQuoteItemUser({
              type: "SET_CHANGED_QUOTE_ITEM_USERS",
              changedQuoteItemUsers: [
                ...changedQuoteItemUsers,
                {
                  index,
                  id: quoteItemUsers[index].id,
                  total_cost: total,
                  hours: Math.round(parseFloat(event.value) * 100) / 100,
                  quantity: Math.round(parseFloat(event.value) * 100) / 100,
                },
              ],
            });
          }
        }
        validateProperty(
          "quantity",
          event.value,
          quoteItemUserSchema,
          data.error,
          setError
        );
        break;
      case "unit_cost":
        total = Math.round(data.quantity * parseFloat(event.value) * 100) / 100;
        quoteItemUsers[index] = {
          ...data,
          total_cost: total,
          unit_cost: Math.round(parseFloat(event.value) * 100) / 100,
        };
        if (!quoteItemUsers[index].isNew) {
          const sel = changedQuoteItemUsers.filter(
            (item) => item.index === index
          );
          if (sel.length > 0) {
            sel[0] = {
              ...sel[0],
              total_cost: total,
              unit_cost: Math.round(parseFloat(event.value) * 100) / 100,
            };
            dispatchQuoteItemUser({
              type: "SET_CHANGED_QUOTE_ITEM_USERS",
              changedQuoteItemUsers: [
                ...changedQuoteItemUsers.filter((item) => item.index !== index),
                sel[0],
              ],
            });
          } else {
            dispatchQuoteItemUser({
              type: "SET_CHANGED_QUOTE_ITEM_USERS",
              changedQuoteItemUsers: [
                ...changedQuoteItemUsers,
                {
                  index,
                  id: quoteItemUsers[index].id,
                  total_cost: total,
                  unit_cost: Math.round(parseFloat(event.value) * 100) / 100,
                },
              ],
            });
          }
        }
        validateProperty(
          "unit_cost",
          event.value,
          quoteItemUserSchema,
          data.error,
          setError
        );

        break;
    }
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
  };

  const handleChangeUserType = (event, value) => {
    if (value) {
      quoteItemUsers[index] = {
        ...data,
        type: value.value,
        user_type: value,
        user: null,
        user_id: -1,
        unit_cost: 0,
        total_cost: 0,
        payment: null,
        payment_type: null,
      };
      if (!quoteItemUsers[index].isNew) {
        const sel = changedQuoteItemUsers.filter(
          (item) => item.index === index
        );
        if (sel.length > 0) {
          sel[0] = {
            ...sel[0],
            type: value.value,
            user: null,
            user_id: -1,
            unit_cost: 0,
            total_cost: 0,
            payment_type: null,
          };
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers.filter((item) => item.index !== index),
              sel[0],
            ],
          });
        } else {
          dispatchQuoteItemUser({
            type: "SET_CHANGED_QUOTE_ITEM_USERS",
            changedQuoteItemUsers: [
              ...changedQuoteItemUsers,
              {
                index,
                id: quoteItemUsers[index].id,
                type: value.value,
                user: null,
                user_id: -1,
                unit_cost: 0,
                total_cost: 0,
              },
            ],
          });
        }
      }
    } else {
      quoteItemUsers[index] = {
        ...data,
        type: null,
        user_type: null,
        user: null,
        user_id: -1,
        unit_cost: 0,
        total_cost: 0,
      };
    }
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: quoteItemUsers,
    });
    validateProperty(
      "user_type",
      value,
      quoteItemUserSchema,
      data.error,
      setError
    );
  };

  const deleteUser = () => {
    const newItems = quoteItemUsers.filter(
      (item, indexItem) => indexItem !== index
    );
    const selectedItem = quoteItemUsers.filter(
      (item, indexItem) => indexItem === index
    );
    if (!selectedItem[0].isNew) {
      dispatchQuoteItemUser({
        type: "SET_DELETED_QUOTE_ITEM_USERS",
        deletedQuoteItemUsers: [...deletedQuoteItemUsers, selectedItem[0].id],
      });
    }
    dispatchQuoteItemUser({
      type: "SET_QUOTE_ITEM_USERS",
      quoteItemUsers: newItems,
    });
  };

  return (
    <Box className={isDetail ? classes.userDataContainer : ""}>
      {isDetail ? (
        data.amount_paid && parseFloat(data.amount_paid) > 0 ? (
          <Tooltip title="Item has already been paid or partial paid.">
            <Box className={classes.buttonDeteleQuoteUserTooltip}>
              <IconButton
                className={classes.buttonDeteleQuoteUser}
                aria-label="Delete"
                disabled={true}
              >
                <DeleteIcon className={classes.iconUserDelete} />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <IconButton
            className={classes.buttonDeteleQuoteUser}
            aria-label="Delete"
            onClick={deleteUser}
            disabled={readonly}
          >
            <DeleteIcon className={classes.iconUserDelete} />
          </IconButton>
        )
      ) : (
        ""
      )}
      {!hideUserType ? (
        quoteApproved && !data.isNew ? (
          <Tooltip title="This field can't be changed after quote has been approved.">
            <Box>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={userAssignmentType}
                readonly={true}
                name="user_type"
                internal={false}
                error={data.error}
                label="Who is performing the service"
                value={data.user_type}
              />
            </Box>
          </Tooltip>
        ) : (
          <FormSelectAuto
            gridSizes={[{ size: "md", val: 12 }]}
            options={userAssignmentType}
            name="user_type"
            internal={false}
            error={data.error}
            handleChange={handleChangeUserType}
            label="Who is performing the service"
            value={data.user_type}
            readonly={readonly}
          />
        )
      ) : (
        ""
      )}
      {(quoteApproved && !data.isNew) ||
      data.is_grouped ||
      data.amount_paid > 0 ? (
        <Tooltip
          title={
            quoteApproved
              ? "This field can't be changed after quote has been approved."
              : data.is_grouped
              ? "This cost has been grouped with another one."
              : "Item has already been paid or partial paid."
          }
        >
          <Box>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={options}
              name="user"
              internal={false}
              readonly={true}
              label={
                data.type ? "Partner Responsible" : "Team Member Responsible"
              }
              error={data.error}
              value={data.user}
              endText={
                <FormInfromation
                  icon={
                    <CheckCircle className={classes.informationIconStandard} />
                  }
                  text="Worktickets will be assigned to member."
                />
              }
              endTextSize={6}
            />
          </Box>
        </Tooltip>
      ) : (
        <FormSelectAuto
          gridSizes={[{ size: "md", val: 12 }]}
          options={options}
          name="user"
          internal={false}
          handleChange={handleChangeUser}
          label={data.type ? "Partner Responsible" : "Team Member Responsible"}
          error={data.error}
          value={data.user}
          endText={
            <FormInfromation
              icon={<CheckCircle className={classes.informationIconStandard} />}
              text="Worktickets will be assigned to member."
            />
          }
          endTextSize={6}
          readonly={readonly}
        />
      )}
      {quoteApproved &&
      parseFloat(data.amount_paid) === parseFloat(data.total_cost) &&
      parseFloat(data.total_cost) > 0 ? (
        <Tooltip title="Payment has been already sent to finance.">
          <Box>
            <FormInput
              gridSizes={[{ size: "md", val: 6 }]}
              name="quantity"
              label={`Est ${
                data.uom === 1
                  ? "Time"
                  : data.uom === 0
                  ? "Units"
                  : data.uom === 2
                  ? "Square footage"
                  : "Service"
              } Allotment`}
              error={data.error}
              readonly={true}
              internal={false}
              value={data.quantity !== null ? data.quantity : ""}
              endText={
                fromCustom ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={
                      quoteDetails?.project
                        ? userUOMTypes.filter(
                            (t) =>
                              !t.disabledForProjects.includes(
                                quoteDetails.project.type
                              )
                          )
                        : userUOMTypes
                    }
                    name="uom"
                    noLabel={true}
                    value={data.uomObject}
                    readonly={readonly}
                  />
                ) : (
                  "hrs"
                )
              }
              isComponentEndText={fromCustom}
              alignText="right"
              endAdornment={
                <>
                  /{hours.toFixed(2)}
                  <CheckCircle className={classes.informationIconTextBox} />
                </>
              }
              isCustomObjectEndText={true}
            />
          </Box>
        </Tooltip>
      ) : (
        <FormInput
          gridSizes={[{ size: "md", val: 6 }]}
          name="quantity"
          label={`Est ${
            data.uom === 1
              ? "Time"
              : data.uom === 0
              ? "Units"
              : data.uom === 2
              ? "Square footage"
              : "Service"
          } Allotment`}
          error={data.error}
          handleBlur={handleBlur}
          internal={false}
          value={data.quantity !== null ? data.quantity : ""}
          endText={
            fromCustom ? (
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={
                  quoteDetails?.project
                    ? userUOMTypes.filter(
                        (t) =>
                          !t.disabledForProjects?.includes(
                            quoteDetails.project.type
                          )
                      )
                    : userUOMTypes
                }
                name="uom"
                noLabel={true}
                value={data.uomObject}
                handleChange={handleChangeUOM}
                readonly={readonly}
              />
            ) : (
              "hrs"
            )
          }
          isComponentEndText={fromCustom}
          alignText="right"
          endAdornment={
            quoteDetails?.type !== 1 ? (
              <>
                /{hours.toFixed(2)}
                <CheckCircle className={classes.informationIconTextBox} />
              </>
            ) : (
              <CheckCircle className={classes.informationIconTextBox} />
            )
          }
          isCustomObjectEndText={true}
          readonly={readonly || data.uom === 3}
        />
      )}
      {quoteApproved && !data.isNew ? (
        <Tooltip title="This field can't be changed after quote has been approved.">
          <Box>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={
                data.type === 0
                  ? inHousePaymentTypes
                  : subcontractorPaymentTypes
              }
              readonly={true}
              name="payment"
              error={data.error}
              internal={false}
              label="Payments"
              value={data.payment}
            />
          </Box>
        </Tooltip>
      ) : (
        <FormSelectAuto
          gridSizes={[{ size: "md", val: 12 }]}
          options={
            data.type === 0 ? inHousePaymentTypes : subcontractorPaymentTypes
          }
          name="payment"
          error={data.error}
          internal={false}
          handleChange={handleChangePayment}
          label="Payments"
          value={data.payment}
          readonly={readonly}
        />
      )}
      {quoteApproved &&
      parseFloat(data.amount_paid) === parseFloat(data.total_cost) &&
      parseFloat(data.total_cost) > 0 ? (
        <Tooltip title="Payment has been already sent to finance.">
          <Box>
            <FormInput
              gridSizes={[{ size: "md", val: 6 }]}
              name="unit_cost"
              label="Unit Cost"
              subLabel="(Hourly Pay Rate)"
              error={data.error}
              internal={false}
              value={data.unit_cost}
              readonly={Boolean(data.user?.hour_rate)}
              readonly={true}
              endText="hr"
              startAdornment="$"
              alignText="right"
              noMargin={true}
            />
          </Box>
        </Tooltip>
      ) : (
        <FormInput
          gridSizes={[{ size: "md", val: 6 }]}
          name="unit_cost"
          label="Unit Cost"
          subLabel={data.uom === 3 ? "" : "(Hourly Pay Rate)"}
          error={data.error}
          internal={false}
          value={data.unit_cost}
          readonly={Boolean(data.user?.hour_rate)}
          handleBlur={handleBlur}
          endText={data.uom === 3 ? "per service" : "hr"}
          startAdornment="$"
          alignText="right"
          noMargin={true}
          readonly={readonly}
        />
      )}
    </Box>
  );
};

export default QuoteUserItem;
