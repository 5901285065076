import React from "react";
const Context = React.createContext(null);
export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const CommentDialogueContext = React.createContext();
const initialState = {
  isOpenAddDialog: false,
  commentToEdit: null,
  isEditing: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_COMMENT_DIALOG":
      return {
        ...state,
        isOpenAddDialog: action.open,
      };
    case "TOGGLE_EDIT_COMMENT":
      return {
        ...state,
        commentToEdit: action.comment,
        isOpenAddDialog: action.open,
        isEditing: action.isEditing,
      };
    default:
      return state;
  }
};
const CommentDialogueProvider = ({ children }) => (
  <CommentDialogueContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </CommentDialogueContext.Provider>
);
const useCommentDialogue = () => {
  const context = React.useContext(CommentDialogueContext);
  if (context === undefined) {
    throw new Error(
      "useCommentDialogue must be used within a CommentDialogueProvider"
    );
  }
  return context;
};
export { CommentDialogueProvider, useCommentDialogue };
