import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Joi from "joi-browser";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ButtonBase } from "components/ui/core/button";
import { getNoticeTypes, getInfractionTypes } from "services/noticeService";
import { logException } from "components/util/logUtil";
import { validate, validateProperty } from "components/util/validationUtil";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import BackIcon from "@material-ui/icons/ArrowBack";
import FilesDisplayOnlyUploaded from "components/ui/FilesDisplayUploaded/filesDisplayOnlyUploaded";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormInput from "components/ui/FormContent/formInput";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import SignatureCanvas from "react-signature-canvas";
import FileUpload from "components/common/FileUpload/fileUpload";
import { storeNotice } from "services/noticeService";
import { getDetailForUser } from "services/teamService";
import { dataURLtoFile } from "components/util/fileUtil";
import MessageDialog from "../dialog/messageDialog";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const InitialData = {
  submmittedId: 0,
  submmittedName: "",
  submmittedEmail: "",
  forId: 0,
  forName: "",
  forEmail: "",
  notice: null,
  infraction: null,
  warningReason: "",
  comments: "",
  files: [],
  filesDisplay: [],
  signature: null,
  witnessSignature: null,
  witnessName: "",
  infractionCount: 1,
};
const noticeStep0Schema = {
  submmittedName: Joi.string().required().label("Your Name"),
  submmittedEmail: Joi.string().required().label("Your Email"),
  forName: Joi.string().required().label("Employee Name"),
  forEmail: Joi.string().required().label("Employee Email"),
  notice: Joi.object()
    .required()
    .label("Notice")
    .error(() => {
      return {
        message: "Notice Type is required.",
      };
    }),
};

const noticeStep1SchemaVerbal = {
  warningReason: Joi.string().required().label("Warning Reason"),
  infraction: Joi.object()
    .required()
    .label("Infraction")
    .error(() => {
      return {
        message: "Infraction is required.",
      };
    }),
};

const noticeStep1SchemaVerbalAT = {
  infraction: Joi.object()
    .required()
    .label("Infraction")
    .error(() => {
      return {
        message: "Infraction is required.",
      };
    }),
};

const SideDrawer = (props) => {
  const classes = useStyles();
  const {
    isOpen,
    closeDrawer,
    forUserId,
    readonly,
    handleUpdate,
    updateList,
    noticesData,
  } = props;
  const [step, setStep] = useState(0);
  const [isLoadingNotice, setIsLoadingNotice] = useState(false);
  const [data, setData] = useState(InitialData);
  const [noticeTypes, setNoticeTypes] = useState([]);
  const [infractionTypes, setInfractionTypes] = useState([]);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
  const [isLoadingInfractionsInfo, setIsLoadingInfractionsInfo] =
    useState(false);
  const [schema, setSchema] = useState(noticeStep0Schema);
  const [error, setError] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [update, setUpdate] = useState(0);
  const signRef = useRef();
  const witnessSignRef = useRef();

  useEffect(() => {
    const loadInfractions = async () => {
      try {
        setIsLoadingInfractionsInfo(true);
        const resultInfraction = await getInfractionTypes(data.notice.value);
        const { infractions } = resultInfraction.data.data;
        const resultForRender = infractions.map((infraction) => {
          return { value: infraction.id, label: infraction.name };
        });
        setInfractionTypes(resultForRender);
        setIsLoadingInfractionsInfo(false);
      } catch (e) {
        logException(e, "Cannot load infractions data");
      }
    };
    if (step === 1 && !data.infraction && !noticesData) {
      loadInfractions();
    } else if (step === 0) {
      const newData = {
        ...data,
        infraction: null,
      };
      setSchema(noticeStep0Schema);
      setData(newData);
    }
  }, [step]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoadingUserInfo(true);
        let newData = {};
        const resultNotice = await getNoticeTypes();
        const { notice_types } = resultNotice.data.data;
        const resultForRender = notice_types.map((notice) => {
          return { value: notice.id, label: notice.name };
        });
        setNoticeTypes(resultForRender);
        if (!noticesData) {
          const user = JSON.parse(localStorage.getItem("user"));
          const resultUser = await getDetailForUser(user.id);
          const { user: userSubmitted } = resultUser.data.data;
          const resultUserFor = await getDetailForUser(forUserId);
          const { user: userFor } = resultUserFor.data.data;
          newData = {
            ...InitialData,
            submmittedId: userSubmitted.id,
            submmittedName: `${userSubmitted.first_name} ${userSubmitted.last_name}`,
            submmittedEmail: userSubmitted.email,
            forId: userFor.id,
            forName: `${userFor.first_name} ${userFor.last_name}`,
            forEmail: userFor.email,
            files: [],
            filesDisplay: [],
          };
        } else {
          const selectedType = resultForRender.filter(
            (item) => item.value === parseInt(noticesData.type)
          )[0];
          const content = JSON.parse(JSON.parse(noticesData.content));
          const resultInfraction = await getInfractionTypes(selectedType.value);
          const { infractions } = resultInfraction.data.data;
          const resultForRenderInfraction = infractions.map((infraction) => {
            return { value: parseInt(infraction.id), label: infraction.name };
          });
          const selectedInfraction = resultForRenderInfraction.filter(
            (item) => item.value === parseInt(content.infraction_type)
          )[0];
          setInfractionTypes(resultForRenderInfraction);
          let warningReason = "";
          let infractionCount = 1;
          if (noticesData.type === 5) {
            infractionCount = parseInt(content.infraction_count);
          } else {
            warningReason = content.warning_reason;
          }
          const filesDisplay = content.files
            ? content.files.map((file, index) => {
                return {
                  id: index,
                  file_name: file.split("/").pop(),
                  file_url: file,
                };
              })
            : [];
          newData = {
            ...InitialData,
            submmittedId: noticesData.submitted_for_id,
            submmittedName: noticesData.submitted_by,
            submmittedEmail: noticesData.submitted_by_email,
            forId: noticesData.submitted_for_id,
            forName: noticesData.submitted_for,
            forEmail: noticesData.submitted_for_email,
            notice: selectedType,
            infraction: selectedInfraction,
            warningReason,
            comments: content.comments,
            filesDisplay: filesDisplay,
            signature: content.signature,
            witnessSignature: content.witness,
            witnessName: content.witness_name,
            infractionCount,
          };
        }
        setData(newData);
        setIsLoadingUserInfo(false);
      } catch (e) {
        logException(e, "Cannot load users data");
      }
    };
    if (isOpen) {
      loadUserData();
    } else {
      setStep(0);
      setData(InitialData);
    }
  }, [isOpen, forUserId]);

  const changeStep = async (n) => {
    if (n === 0) {
      setSchema(noticeStep0Schema);
      setStep(n);
    } else {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors) || n < step) {
        if (n === 1) {
          if (![5].includes(data.notice.value))
            setSchema(noticeStep1SchemaVerbal);
          else setSchema(noticeStep1SchemaVerbalAT);
          setStep(n);
        } else if (n === 2) {
          setSchema({});
          setStep(n);
        }
      }
    }
  };

  const submitNotice = async () => {
    let createData = {};
    setIsLoadingNotice(true);
    if (data?.notice.value !== 5) {
      createData = {
        submitted_by_id: data.submmittedId,
        submitted_for_id: data.forId,
        type: data.notice.value,
        infraction_type: data.infraction.value,
        reason: data.warningReason,
        comments: data.comments,
        witness_name: data.witnessName,
        files: data.files,
        signature_file: dataURLtoFile(signRef.current.toDataURL(), "file"),
        witness_file: dataURLtoFile(witnessSignRef.current.toDataURL(), "file"),
      };
    } else {
      createData = {
        submitted_by_id: data.submmittedId,
        submitted_for_id: data.forId,
        type: data.notice.value,
        infraction_type: data.infraction.value,
        infraction_count: data.infractionCount,
        comments: data.comments,
        witness_name: data.witnessName,
        files: data.files,
        signature_file: dataURLtoFile(signRef.current.toDataURL(), "file"),
        witness_file: dataURLtoFile(witnessSignRef.current.toDataURL(), "file"),
      };
    }
    try {
      const result = await storeNotice(createData);
      if (handleUpdate) {
        await updateList();
      }
      setOpenSuccess(true);
      closeDrawer();
    } catch (e) {
      logException(e, "Cannot store notice");
    }
    setIsLoadingNotice(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeNoticeType = (event, value) => {
    if (value) {
      const newData = {
        ...data,
        notice: value,
      };
      setData(newData);
      validateProperty("notice", value, schema, error, setError);
    }
  };

  const handleChangeInfractionType = (event, value) => {
    if (value) {
      const newData = {
        ...data,
        infraction: value,
      };
      setData(newData);
      validateProperty("infraction", value, schema, error, setError);
    }
  };

  const changeInfractionCount = (value) => {
    if (value) {
      const newData = {
        ...data,
        infractionCount: value,
      };
      setData(newData);
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};

    switch (event.name) {
      case "warningReason":
        newData = {
          ...data,
          warningReason: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "comments":
        newData = {
          ...data,
          comments: event.value,
        };
        break;
      case "witnessName":
        newData = {
          ...data,
          witnessName: event.value,
        };
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const setFiles = (files) => {
    const newData = {
      ...data,
      files,
    };
    setData(newData);
  };

  const handleRemove = (indexInput) => {
    const newFileList = data.files.filter(
      (item, index) => index !== indexInput
    );
    setFiles(newFileList);
  };

  return (
    <>
      <Drawer anchor="right" open={isOpen} className={classes.drawerWrapper}>
        <IconButton
          className={classes.buttonClose}
          aria-label="Close"
          onClick={() => closeDrawer()}
        >
          <CloseIcon className={classes.iconClose} />
        </IconButton>
        <Box className={classes.currentStepContainer}>
          <Typography className={classes.currentStep}>
            Step {step + 1}/3
          </Typography>
        </Box>
        {step === 0 ? (
          <>
            <Box className={classes.headerContainer}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom={true}
                className={classes.headerContainerTitle}
              >
                Submit Notice
              </Typography>
              <Typography
                variant="h6"
                gutterBottom={true}
                className={classes.headerContainerSubTitle}
              >
                Tell us more about the notice your are submitting.
              </Typography>
            </Box>
            <Box className={classes.optionWrapper}>
              <Box className={classes.containerInformationCreate}>
                {!isLoadingUserInfo ? (
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="submmittedName"
                    label="Your Name"
                    error={error}
                    value={data.submmittedName}
                    readonly={true}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                {!isLoadingUserInfo ? (
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="submmittedEmail"
                    label="Your Email"
                    error={error}
                    value={data.submmittedEmail}
                    readonly={true}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                {!isLoadingUserInfo ? (
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="forName"
                    label="Employee Name"
                    error={error}
                    value={data.forName}
                    readonly={true}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                {!isLoadingUserInfo ? (
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="forEmail"
                    label="Employee Email"
                    error={error}
                    value={data.forEmail}
                    readonly={true}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={noticeTypes}
                  name="notice"
                  error={error}
                  handleChange={handleChangeNoticeType}
                  label="Notice Type"
                  value={data.notice}
                  readonly={readonly}
                />
                <Box className={classes.actionBox}>
                  <ButtonBase
                    color="secondary"
                    disabled={data.type < 1 || isLoadingUserInfo}
                    onClick={() => changeStep(1)}
                    disableElevation
                  >
                    {readonly ? "Next" : "Begin"}
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </>
        ) : step === 1 ? (
          <>
            <Box className={classes.headerContainer}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom={true}
                className={classes.headerContainerTitle}
              >
                {`Submit ${data.notice.label} Notice`}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom={true}
                className={classes.headerContainerSubTitle}
              >
                Tell us more about the notice your are submitting.
              </Typography>
            </Box>
            <Box className={classes.optionWrapper}>
              <Box className={classes.containerInformationCreate}>
                {data.notice.value === 5 ? (
                  <Box
                    className={
                      data.notice.value === 5
                        ? classes.containerOptions3
                        : classes.containerOptions2
                    }
                  >
                    <InputLabel className={classes.inputLabelP}>
                      Infraction
                    </InputLabel>
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="primary button group"
                      className={classes.groupOption2}
                      fullWidth={true}
                    >
                      <Button
                        className={
                          data.infractionCount === 1
                            ? classes.groupOptionActive
                            : null
                        }
                        onClick={() => changeInfractionCount(1)}
                        disabled={readonly && data.infractionCount !== 1}
                      >
                        First
                      </Button>
                      <Button
                        className={
                          data.infractionCount === 2
                            ? classes.groupOptionActive
                            : null
                        }
                        onClick={() => changeInfractionCount(2)}
                        disabled={readonly && data.infractionCount !== 2}
                      >
                        Second
                      </Button>
                      <Button
                        className={
                          data.infractionCount === 3
                            ? classes.groupOptionActive
                            : null
                        }
                        onClick={() => changeInfractionCount(3)}
                        disabled={readonly && data.infractionCount !== 3}
                      >
                        Third
                      </Button>
                    </ButtonGroup>
                  </Box>
                ) : (
                  ""
                )}
                {!isLoadingInfractionsInfo ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={infractionTypes}
                    name="infraction"
                    error={error}
                    handleChange={handleChangeInfractionType}
                    label="Infraction Type"
                    value={data.infraction}
                    readonly={readonly}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                {![5].includes(data.notice.value) ? (
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="warningReason"
                    label="Warning Reason"
                    error={error}
                    multiline={true}
                    rows={3}
                    value={data.warningReason}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                ) : null}
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="comments"
                  label="Comments"
                  error={error}
                  multiline={true}
                  rows={3}
                  value={data.comments}
                  handleBlur={handleBlur}
                  readonly={readonly}
                />
                <Box className={classes.actionBox}>
                  <ButtonBase
                    color="secondary"
                    startIcon={<BackIcon />}
                    variant="text"
                    onClick={() => changeStep(0)}
                    className={classes.buttonBackDrawer}
                  >
                    Back
                  </ButtonBase>
                  <ButtonBase color="secondary" onClick={() => changeStep(2)}>
                    Next
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </>
        ) : step === 2 ? (
          <>
            <Box className={classes.headerContainer}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom={true}
                className={classes.headerContainerTitle}
              >
                {`Submit ${data.notice?.label} Notice`}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom={true}
                className={classes.headerContainerSubTitle}
              >
                Tell us more about the notice your are submitting.
              </Typography>
            </Box>
            <Box className={classes.optionWrapper}>
              <Box className={classes.containerInformationCreate}>
                {!readonly ? (
                  <Box className={classes.containerFile}>
                    {!Boolean(data.files.length) && (
                      <Typography
                        className={classes.titleSectionFile}
                        gutterBottom
                      >
                        Documents
                      </Typography>
                    )}
                    <FileUpload
                      value={data.files}
                      handleChange={setFiles}
                      handleUpdate={setUpdate}
                      filesDisplay={
                        Boolean(update) && (
                          <FilesDisplay
                            files={data.files}
                            handleRemove={handleRemove}
                          />
                        )
                      }
                    />
                  </Box>
                ) : (
                  <Typography className={classes.titleSectionFile} gutterBottom>
                    Documents
                  </Typography>
                )}
                <Box className={classes.containerDisplayFilesQS}>
                  {data.filesDisplay.length > 0 ? (
                    <FilesDisplayOnlyUploaded
                      files={data.filesDisplay}
                      handleRemoveDisplay={() => {}}
                    />
                  ) : noticesData ? (
                    "N/A"
                  ) : (
                    ""
                  )}
                </Box>
                <Typography className={classes.titleSectionFile} gutterBottom>
                  Signature
                </Typography>
                {readonly ? (
                  <img
                    src={data.signature}
                    alt="signature"
                    className={classes.imgSignature}
                  />
                ) : (
                  <SignatureCanvas
                    ref={signRef}
                    canvasProps={{ className: classes.sigCanvas }}
                  />
                )}
                <Typography className={classes.titleSectionFile} gutterBottom>
                  Witness Signature
                </Typography>
                {readonly ? (
                  <img
                    src={data.witnessSignature}
                    alt="signature"
                    className={classes.imgSignature}
                  />
                ) : (
                  <SignatureCanvas
                    ref={witnessSignRef}
                    canvasProps={{ className: classes.sigCanvas }}
                  />
                )}
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="witnessName"
                  label="Witness Name"
                  error={error}
                  value={data.witnessName}
                  handleBlur={handleBlur}
                  readonly={readonly}
                />
                <Box className={classes.actionBox}>
                  <ButtonBase
                    color="secondary"
                    startIcon={<BackIcon />}
                    variant="text"
                    onClick={() => changeStep(1)}
                    className={classes.buttonBackDrawer}
                  >
                    Back
                  </ButtonBase>
                  {!readonly ? (
                    isLoadingNotice ? (
                      <Box className={classes.centerLoadingSideBar}>
                        <LoadingStateHorizontal isVisible />
                      </Box>
                    ) : (
                      <ButtonBase
                        color="secondary"
                        onClick={() => submitNotice()}
                      >
                        Submit
                      </ButtonBase>
                    )
                  ) : null}
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
      </Drawer>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Notice has been submitted!`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default withRouter(SideDrawer);
