import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { truncateString } from "components/util/stringFormat";

import useStyles from "./styles";

const FieldDescription = (props) => {
  const classes = useStyles();
  const { field, fieldLength } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.preventDefault();
    if (field.length > fieldLength) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event) => {
    console.log("Leave");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!field || field.length <= fieldLength) {
    return field;
  }

  return (
    <>
      <Typography
        variant="body2"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.label}
      >
        {truncateString(field, fieldLength)}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={2}
      >
        {field}
      </Popover>
    </>
  );
};

export default FieldDescription;
