import React from "react";
import Box from "@material-ui/core/Box";
import UserLocationsTable from "./userLocationsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { UserLocationsProvider } from "contexts/userLocationsContext";
import useStyles from "./styles";

const UserLocationsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <UserLocationsProvider>
          <UserLocationsTable />
        </UserLocationsProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(UserLocationsList);
