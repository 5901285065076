import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "./styles";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  questionFill,
} from "contexts/surveyFillContext";
import {
  deleteAnswer,
  getTasksForSubmit,
  getFeedbackQuestion,
} from "services/surveyFrontlineService";
import { surveysLabelsLang } from "constants.js";

const SurveyActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    selectedQuestions,
    correlative,
    updateAnswers,
    lang,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const options = [
    {
      id: 1,
      label: surveysLabelsLang[lang].answerDeleteLabel,
      value: "DELETE",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");
    setAnchorEl(null);
    switch (action) {
      case "DELETE":
        dispatchSurveyFill({
          type: "TOGGLE_ISLOADING",
          isLoading: true,
        });
        const resultQuestion = await deleteAnswer(
          selectedQuestions[correlative].id
        );
        const { questions: selQuestions } = resultQuestion.data.data;
        if (selQuestions[0].type === 1) {
          const resultTasks = await getTasksForSubmit(selQuestions[0].id);
          const { tasks: selTasks } = resultTasks.data.data;
          dispatchSurveyFill({
            type: "SET_SELECTED_TASKS",
            selectedTasks: selTasks,
          });
          if (selQuestions[0].status === 1 && selTasks.length > 0) {
            const noAnswerTasks = selTasks.filter((t) => t.status !== 2);
            if (noAnswerTasks.length === 0) {
              selQuestions[0].filled = true;
            }
          }
        }
        const resultQuestionDetail = await getFeedbackQuestion(
          selQuestions[0].id
        );
        const { question: selectedQuestion } = resultQuestionDetail.data.data;
        dispatchSurveyFill({
          type: "SET_SELECTED_FILES",
          selectedFiles: selectedQuestion.files,
        });
        dispatchSurveyFill({
          type: "SET_SELECTED_COMMENTS",
          selectedComments: selectedQuestion.comments,
        });
        dispatchSurveyFill({
          type: "SET_CORRELATIVE",
          correlative: 0,
        });
        dispatchSurveyFill({
          type: "SET_SELECTED_QUESTIONS",
          selectedQuestions: selQuestions,
        });
        const data = {
          current_question_id: selQuestions[0].id,
        };
        questionFill(
          dispatchSurveyFill,
          selQuestions[0].id,
          data,
          true,
          !updateAnswers
        );
        dispatchSurveyFill({
          type: "TOGGLE_ISLOADING",
          isLoading: false,
        });
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.navigationMenuButton}
        disabled={selectedQuestions.length === 1}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
        MenuListProps={{
          className: classes.menu,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={handleSelect}
            data={option.value}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SurveyActions;
