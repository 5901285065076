import { createContext, useContext, useMemo } from "react";
import useUserIdFromUrl from "../useUserIdFromUrl";

const UserIdContext = createContext();

export const UserIdProvider = ({ children }) => {
  const userId = useUserIdFromUrl();
  const value = useMemo(
    () => ({
      userId,
    }),
    [userId]
  );
  return (
    <UserIdContext.Provider value={value}>{children}</UserIdContext.Provider>
  );
};

export const useGetUserId = () => useContext(UserIdContext);
