import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    flexGrow: 1,
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    height: 42,
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: 8,
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: 160,
      maxWidth: 160,
      minWidth: 160,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      clear: "both",
      marginTop: 10,
      zIndex: 100,
      width: "100%",
      maxWidth: "100%",
    },
  },
  searchIcon: {
    color: "#ececec",
    cursor: "pointer",
  },
  clearSearch: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    cursor: "pointer",
  },
  clearSearchButton: {
    padding: 0,
  },
  inputFieldSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "700px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
}));

export default useStyles;
