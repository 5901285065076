import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import MessageDialog from "components/ui/dialog/messageDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AssetIcon from "@material-ui/icons/Toys";
import CloseIcon from "@material-ui/icons/Close";

import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, getFilterData } from "contexts/filterContext";

import { useAssetDispatch, getAssetList } from "contexts/assetContext";
import { permissionAsset, hasPermission } from "lib/permissions";
import { deleteAsset } from "services/assetService";

import useStyles from "./styles";

const options = [
  { id: 1, label: "Edit", value: "EDIT", permission: permissionAsset.EDIT },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    permission: permissionAsset.DELETE,
  },
];

const AssetTableActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatchAssets = useAssetDispatch();
  const filterState = useFilterState();
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");

    if (action === "EDIT") {
      dispatchAssets({
        type: "SET_ASSET_ACTION",
        assetAction: row,
      });
    }
    if (action === "DELETE") {
      setOpenDelete(true);
    }
    setAnchorEl(null);
  };

  const handleConfirmDelete = async () => {
    if (!hasPermission(permissionAsset.DELETE, permissions)) {
      return;
    }
    try {
      await deleteAsset(row.id);
      setOpenDelete(false);
      setOpenSuccess(true);
      const filterData = getFilterData(filterState);
      await getAssetList(dispatchAssets, filterData);
    } catch (e) {
      console.log("Cannot delete asset");
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  if (
    !hasPermission(permissionAsset.EDIT, permissions) &&
    !hasPermission(permissionAsset.DELETE, permissions)
  ) {
    return "";
  }

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter((opt) => hasPermission(opt.permission, permissions))
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDelete}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <AssetIcon className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Assets
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete the asset?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDelete}
            className={classes.buttonOutlined}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Asset has been deleted.`}
        handleClose={handleCloseSuccess}
      />
    </div>
  );
};

export default withRouter(AssetTableActions);
