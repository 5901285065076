import React from "react";
import {
  getBudgetJobs,
  getBudgetJobsTabOffset,
  getBudgetJobsSearch,
  getBudgetCampus,
  getBudgetCampusTabOffset,
  getBudgetPortfolio,
  getBudgetPortfolioTabOffset,
} from "../services/budgetService";
import { merge } from "lodash";

const defaultState = {
  budgetListLoading: true,
  budgetSelected: null,
  budgetList: [],
  budgetCount: [],
};

const BudgetStateContext = React.createContext();
const BudgetDispatchContext = React.createContext();

function budgetReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        budgetListLoading: action.budgetListLoading,
      };
    case "SET_BUDGET_LIST":
      return {
        ...state,
        budgetList: action.budgetList,
      };
    case "SET_BUDGET_COUNT":
      return {
        ...state,
        budgetCount: action.budgetCount,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BudgetProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(budgetReducer, mergedState);
  return (
    <BudgetStateContext.Provider value={state}>
      <BudgetDispatchContext.Provider value={dispatch}>
        {children}
      </BudgetDispatchContext.Provider>
    </BudgetStateContext.Provider>
  );
}

function useBudgetState() {
  const context = React.useContext(BudgetStateContext);
  if (context === undefined) {
    throw new Error("useBudgetState must be used within a BudgetProvider");
  }
  return context;
}

function useBudgetDispatch() {
  const context = React.useContext(BudgetDispatchContext);
  if (context === undefined) {
    throw new Error("useBudgetDispatch must be used within a BudgetProvider");
  }
  return context;
}

async function getBudgetJobsList(dispatch, filterData) {
  try {
    const budgetListResult = await getBudgetJobs(filterData);
    const budgetList = budgetListResult.data.data.records;

    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: [...budgetList],
    });

    const budgetCount = budgetListResult.data.data.filter_count;

    dispatch({
      type: "SET_BUDGET_COUNT",
      budgetCount: budgetCount,
    });
    dispatch({
      type: "SET_LOADING",
      budgetListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBudgetJobsListDataSet(
  budgetList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getBudgetJobsTabOffset(offset, filterData);
    budgetList = budgetList.concat(result.data.data.records);
    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: budgetList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBudgetCampusList(dispatch, filterData) {
  try {
    const budgetListResult = await getBudgetCampus(filterData);
    const budgetList = budgetListResult.data.data.records;

    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: [...budgetList],
    });

    const budgetCount = budgetListResult.data.data.filter_count;

    dispatch({
      type: "SET_BUDGET_COUNT",
      budgetCount: budgetCount,
    });
    dispatch({
      type: "SET_LOADING",
      budgetListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBudgetCampusListDataSet(
  budgetList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getBudgetCampusTabOffset(offset, filterData);
    budgetList = budgetList.concat(result.data.data.records);
    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: budgetList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBudgetPortfolioList(dispatch, filterData) {
  try {
    const budgetListResult = await getBudgetPortfolio(filterData);
    const budgetList = budgetListResult.data.data.records;

    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: [...budgetList],
    });

    const budgetCount = budgetListResult.data.data.filter_count;

    dispatch({
      type: "SET_BUDGET_COUNT",
      budgetCount: budgetCount,
    });
    dispatch({
      type: "SET_LOADING",
      budgetListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBudgetPortfolioListDataSet(
  budgetList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getBudgetPortfolioTabOffset(offset, filterData);
    budgetList = budgetList.concat(result.data.data.records);
    dispatch({
      type: "SET_BUDGET_LIST",
      budgetList: budgetList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchBudgetsJobs(search, filterData) {
  try {
    const budgetListResult = await getBudgetJobsSearch(search, filterData);
    return budgetListResult.data.data.records;
  } catch (error) {
    console.log(error);
  }
}

export {
  BudgetProvider,
  useBudgetState,
  useBudgetDispatch,
  getBudgetJobsList,
  searchBudgetsJobs,
  getBudgetJobsListDataSet,
  getBudgetCampusList,
  getBudgetCampusListDataSet,
  getBudgetPortfolioList,
  getBudgetPortfolioListDataSet,
};
