import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as classNames from "classnames";
import DateRangePickerComponent from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import TableTabs from "components/common/Table/tableTabs";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import Counter from "components/common/TableList/counter";
import {
  getFilterData,
  useFilterDispatch,
  useFilterState,
} from "contexts/filterContext";
import { useFormsDispatch, useFormsState } from "contexts/formsContext";
import GlobalUiContext from "contexts/globalUiContext";
import { useTableDispatch, useTableState } from "contexts/tableContext";
import { hasPermission, permissionForms } from "lib/permissions";
import moment from "moment";
import AccidentFollowupModal from "pages/forms/AccidentFollowupModal";
import AccidentInvestigationModal from "pages/forms/AccidentInvestigationModal";
import EquipmentTransferRequestModal from "pages/forms/EquipmentTransferRequestModal";
import InjuryIllnessModal from "pages/forms/InjuryIllnessModal";
import RepairRequestFormModal from "pages/forms/RepairRequestFormModal";
import TalentChangeRequestModal from "pages/forms/TalentChangeRequestModal";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { getFormsTabData } from "../../services/formsService";
import WeeklyScheduleModal from "../openPositions/weeklyScheduleModal";
import FieldLocation from "./fieldLocation";
import FormsRequest from "./formsRequest";
import FormsBulkActions from "./formsTabBulkActions";
import FormsFilters from "./formsTabFilters";
import FormsTabTableActions from "./formsTabTableActions";
import useStyles from "./styles";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormIcon from "@material-ui/icons/ListAlt";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const formTypeOptions = [
  { label: "Talent Change Request", value: 1 },
  // { label: "Equipment Repair Request", value: 2.1 },
  { label: "Asset Transfer/Repair Request", value: 2.2 },
  { label: "First Report of Injury", value: 3.1 },
  { label: "Investigation", value: 3.2 },
  { label: "Accident Follow up", value: 3.3 },
];

const FormsSurfaceTable = (props) => {
  const history = useHistory();
  const [firstLoad, setFirstLoad] = useState(true);
  const [topTab, setTopTab] = useState(`forms${history.location.search}`);
  const [formDropdown, setFormDropdown] = useState("");
  const columnsEquipment = [
    {
      id: "Name",
      label: "Name",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_first_name} ${row?.created_by_last_name}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Email",
      label: "Email",
      content: (row) =>
        row?.created_by_email ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_email}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Job Name",
      label: "Job Name",
      content: (row) => (
        <FieldLocation job={{ job_description: row?.job_description }} />
      ),
      style: { width: 250 },
    },
    {
      id: "Job To",
      label: "Job To",
      content: (row) =>
        row?.to_job_id ? (
          <FieldLocation job={{ job_description: row?.job_to_description }} />
        ) : (
          <Typography>-</Typography>
        ),
      style: { width: 250 },
    },

    {
      id: "Fix at",
      label: "Fix at",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.fix_at ? row.fix_at : "-"}
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "created_at",
      label: "Created At",
      format: "date",
      style: { width: 50 },
    },
    {
      id: "Type",
      label: "Type",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.to_job_id ? "Transfer/Repair" : "Repair"}
        </Typography>
      ),
    },
    {
      id: "Actions",
      label: "",
      content: (row) => (
        <FormsTabTableActions
          row={row}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          setRefresh={setRefresh}
          refresh={refresh}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      contentMobile: (row) => (
        <FormsTabTableActions
          row={row}
          setRefresh={setRefresh}
          refresh={refresh}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      style: { width: 58 },
      disableSort: true,
    },
  ];
  const columns = [
    {
      id: "Name",
      label: "Name",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_first_name} ${row?.created_by_last_name}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Email",
      label: "Email",
      content: (row) =>
        row?.created_by_email ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_email}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Job Number",
      label: "Job Number",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.job_number}
        </Typography>
      ),
      style: { width: 150 },
    },

    {
      id: "Job Name",
      label: "Job Name",
      content: (row) => (
        <FieldLocation job={{ job_description: row?.job_description }} />
      ),
      style: { width: 250 },
    },

    {
      id: "Employee Number",
      label: "Employee Number",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.user_employee_number}
        </Typography>
      ),
      style: { width: 250 },
      disableSort: true,
    },
    {
      id: "Employee Name",
      label: "Employee Name",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.user_first_name} ${row?.user_last_name}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
      disableSort: true,
    },

    {
      id: "created_at",
      label: "Created At",
      format: "date",
      style: { width: 50 },
    },
    {
      id: "Type",
      label: "Type",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          Talent Change Request
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "Actions",
      label: "",
      content: (row) => (
        <FormsTabTableActions
          row={row}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          setRefresh={setRefresh}
          refresh={refresh}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      contentMobile: (row) => (
        <FormsTabTableActions
          row={row}
          refresh={refresh}
          setRefresh={setRefresh}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      style: { width: 58 },
      disableSort: true,
    },
  ];

  const columnsAccident = [
    {
      id: "Name",
      label: "Name",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_first_name} ${row?.created_by_last_name}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Email",
      label: "Email",
      content: (row) =>
        row?.created_by_email ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.created_by_email}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
    },
    {
      id: "Incident Date",
      label: "Incident Date",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.data
            ? moment(JSON.parse(row.data)?.accident_date).format("MM/DD/YYYY")
            : "-"}
        </Typography>
      ),
      style: { width: 50 },
    },
    {
      id: "Employee Number",
      label: "Employee Number",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.user_employee_number}
        </Typography>
      ),
      style: { width: 150 },
      disableSort: true,
    },
    {
      id: "Employee Name",
      label: "Employee Name",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.user_first_name} ${row?.user_last_name}`}</Typography>
        ) : (
          "-"
        ),
      style: { width: 250 },
      disableSort: true,
    },
    {
      id: "created_at",
      label: "Created At",
      format: "date",
      style: { width: 50 },
    },
    {
      id: "Type",
      label: "Type",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {parseInt(row?.type) === 1
            ? "Accident Follow up"
            : parseInt(row?.type) === 2
            ? "Injury Illness Request"
            : "Investigation Request"}
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "Actions",
      label: "",
      content: (row) => (
        <FormsTabTableActions
          row={row}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          setRefresh={setRefresh}
          refresh={refresh}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      contentMobile: (row) => (
        <FormsTabTableActions
          row={row}
          refresh={refresh}
          setRefresh={setRefresh}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          topTab={topTab}
          setFormSelected={setFormSelected}
          setModal={setModal}
          setSelectedModalViewResponse={setSelectedModalViewResponse}
        />
      ),
      style: { width: 58 },
      disableSort: true,
    },
  ];

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFormsRequest, setOpenFormsRequest] = useState(false);
  const [openWeeklySchedule, setOpenWeeklySchedule] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { formsList } = useFormsState();
  const [refresh, setRefresh] = useState(null);
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchForms = useFormsDispatch();
  const dispatchFilters = useFilterDispatch();
  const [openStatusModal, setStatusModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [weeklyScheduleData, setWeeklyScheduleData] = useState("");
  const [filteredRes, setFilteredRes] = useState("");
  const [modal, setModal] = useState(0);
  const [formSelection, setFormSelection] = useState(false);
  const [formSelected, setFormSelected] = useState(false);
  const [selectedModalViewResponse, setSelectedModalViewResponse] =
    useState(null);

  const [tab, setTab] = useState(0);

  const [tabUpdated, setTabUpdated] = useState({
    1: 0,
    2: 0,
    3: 0,
  });

  const getColumns = () => {
    if (topTab === "forms?talent") {
      return [...columns];
    } else if (topTab === "forms?assets") {
      return [...columnsEquipment];
    } else if (topTab === "forms?accident") {
      return [...columnsAccident];
    }
  };

  useEffect(() => {
    if (firstLoad || refresh === null) {
      return;
    }
    setFormDropdown("");
    if (topTab === "forms?talent" && tabUpdated[1] > 0) {
      setTimeout(() => {
        setTabUpdated({ ...tabUpdated, 1: 0 });
      }, 5000);
    } else if (topTab === "forms?assets" && tabUpdated[2] > 0) {
      setTimeout(() => {
        setTabUpdated({ ...tabUpdated, 2: 0 });
      }, 5000);
    } else if (topTab === "forms?accident" && tabUpdated[3] > 0) {
      setTimeout(() => {
        setTabUpdated({ ...tabUpdated, 3: 0 });
      }, 5000);
    }
    const filterData = getFilterData(filterState);
    setWeeklyScheduleData("");
    dispatchForms({
      type: "SET_FORMS_LIST",
      formsList: null,
    });
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: [],
    });

    getFormsTabData(filterData, "", "", topTab).then((res) => {
      dispatchForms({
        type: "SET_FORMS_LIST",
        formsList: res?.data,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: res?.data?.data?.forms,
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
    });
  }, [refresh]);
  useEffect(() => {
    if (
      (filterState.startDateActive && filterState.endDateActive) ||
      (filterState.filters.length >= 0 && !firstLoad)
    ) {
      const filterData = getFilterData(filterState);
      dispatchForms({
        type: "SET_FORMS_LIST",
        formsList: null,
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: [],
      });
      getFormsTabData(filterData, filterState.searchString, "", topTab).then(
        (res) => {
          dispatchForms({
            type: "SET_FORMS_LIST",
            formsList: res?.data,
          });
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: res?.data?.data?.forms,
          });
          dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
        }
      );
    }
  }, [
    filterState.filters.length,
    filterState.startDateActive,
    filterState.endDateActive,
  ]);

  const handleScrollClick = () => {
    if (firstLoad) {
      return;
    }
    if (
      formsList?.data?.forms.length >= formsList?.data?.filter_counts ||
      loadingMore
    ) {
      return;
    }
    let offsetLength = formsList?.data?.forms.length;
    // if (
    //   offsetLength >
    //   formsList?.data?.filter_counts -
    //     formsList?.data?.forms.length
    // ) {
    //   offsetLength =
    //     formsList?.data?.filter_counts -
    //     formsList?.data?.forms.length;
    // }

    const filterData = getFilterData(filterState);

    setLoadingMore(true);
    getFormsTabData(
      filterData,
      filterState.searchString,
      offsetLength,
      topTab
    ).then((res) => {
      formsList.data.forms = [...formsList.data.forms, ...res.data.data.forms];

      dispatchForms({
        type: "SET_FORMS_LIST",
        formsList: { ...formsList },
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [...formsList?.data?.forms, ...res.data.data.forms],
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
      setLoadingMore(false);
    });
  };

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      //ck on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        setLoadingSearch(true);
        setTab(11);
        const filterData = getFilterData(filterState);
        const formsListCopy = formsList;
        dispatchForms({
          type: "SET_FORMS_LIST",
          formsList: null,
        });
        dispatchTable({
          type: "UPDATE_COLUMNS",
          columns: [],
        });
        getFormsTabData(filterData, query, "", topTab).then((res) => {
          dispatchForms({
            type: "SET_FORMS_LIST",
            formsList: formsListCopy,
          });

          dispatchTable({
            type: "UPDATE_ROWS",
            rows: res?.data?.data?.forms,
          });
          setFilteredRes(res?.data?.data);
          dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
        });

        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });

        setLoadingSearch(false);

        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchForms({
      type: "SET_FORMS_LIST",
      formsList: null,
    });
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: [],
    });
    const filterData = getFilterData(filterState);
    setTab(0);
    getFormsTabData(filterData, "", "", topTab).then((res) => {
      dispatchForms({
        type: "SET_FORMS_LIST",
        formsList: res?.data,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: res?.data?.data?.forms,
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
    });
  };

  const closeAndRefreshSidebar = () => {
    setOpenFormsRequest(false);
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
    dispatchForms({
      type: "SET_FORMS_LIST",
      formsList: null,
    });
    setTab(0);
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: [],
    });
    const filterData = getFilterData(filterState);
    getFormsTabData(filterData, "", "", topTab).then((res) => {
      dispatchForms({
        type: "SET_FORMS_LIST",
        formsList: res?.data,
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: getColumns(),
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: res?.data?.data?.forms,
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
    });
  }, [topTab]);
  const handleTabChange = (event, value) => {
    let topTab =
      value === 1
        ? "forms?talent"
        : value === 2
        ? "forms?assets"
        : "forms?accident";
    const { history } = props;
    history.push(`/${topTab}`);
    setTopTab(topTab);
    setTimeout(() => {
      setTabUpdated({ ...tabUpdated, [value]: 0 });
    }, 5000);
  };

  return (
    <Box className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        open={formSelection}
        onClose={() => {
          setOpenFormsRequest(false);
          setFormDropdown("");
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography className={classes.createNewForm}>
            <FormIcon className={classes.permissionIcon} /> Create New Form
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.formContainerDialog}>
            <Grid container>
              <Box className={classes.formSelector}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subSubTitle}>
                    Please select the Form type
                  </Typography>
                  <FormSelectAuto
                    options={formTypeOptions}
                    name="form_select"
                    handleChange={(e, v) => {
                      setFormDropdown(v);
                      setFormSelected(v?.value);
                    }}
                    value={formDropdown}
                    inputContainerProps={classes.inputContainer}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.delDialogBtnCenter}>
          <Button
            autoFocus
            onClick={() => {
              setFormSelection(false);
              setFormDropdown("");
            }}
            //className={classNames(classes.button, classes.buttonSecondary)}
            className={classes.markFilledButton}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            autoFocus
            onClick={() => {
              setOpenFormsRequest(true);
              setFormSelection(false);
              setFormDropdown("");
            }}
            className={classNames(classes.button, classes.buttonSecondary)}
            // className={classes.markFilledButton}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {openWeeklySchedule && (
        <WeeklyScheduleModal
          setOpenWeeklySchedule={setOpenWeeklySchedule}
          setWeeklyScheduleData={setWeeklyScheduleData}
          weeklyScheduleData={weeklyScheduleData}
          openWeeklySchedule={openWeeklySchedule}
          fullScreen={fullScreen}
        />
      )}

      {openFormsRequest && (
        <Drawer open={openFormsRequest}>
          <Box
            className={classNames(classes.trSidebar, classes.overflowScroll)}
          >
            <FormsRequest
              setOpenFormsRequest={setOpenFormsRequest}
              setOpenWeeklySchedule={setOpenWeeklySchedule}
              weeklyScheduleData={weeklyScheduleData}
              setReload={setRefresh}
              setTabUpdated={setTabUpdated}
              tabUpdated={tabUpdated}
              setWeeklyScheduleData={setWeeklyScheduleData}
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
            />
          </Box>
        </Drawer>
      )}
      {!formsList?.data ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <Box className={classes.root}>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleTabChange}>
              <StyledTabs
                value={
                  topTab === "forms?talent"
                    ? 1
                    : topTab === "forms?assets"
                    ? 2
                    : 3
                }
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Talent{" "}
                      {tabUpdated[1] > 0 && (
                        <span className={classes.animationText}>
                          <DotIcon className={classes.newIndicator} />+
                          {tabUpdated[1]}
                        </span>
                      )}
                    </Box>
                  }
                  key={1}
                  value={1}
                />
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Assets{" "}
                      {tabUpdated[2] > 0 && (
                        <span className={classes.animationText}>
                          <DotIcon className={classes.newIndicator} />+
                          {tabUpdated[2]}
                        </span>
                      )}
                    </Box>
                  }
                  key={2}
                  value={2}
                />
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Accident{" "}
                      {tabUpdated[3] > 0 && (
                        <span className={classes.animationText}>
                          <DotIcon className={classes.newIndicator} />+
                          {tabUpdated[3]}
                        </span>
                      )}
                    </Box>
                  }
                  key={3}
                  value={3}
                />
              </StyledTabs>
            </TableTabs>

            <Box className={classes.contentCounter}>
              {tab === 11 ? (
                <Counter>{`${filteredRes.forms?.length || 0} / ${
                  filteredRes.filter_counts || 0
                }`}</Counter>
              ) : (
                <Counter>{`${formsList?.data?.forms?.length || 0} / ${
                  formsList?.data?.forms?.length || 0
                }`}</Counter>
              )}
            </Box>

            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  {formsList?.data && (
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={getColumns()}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                      hasSelect={false}
                    >
                      <EnhancedTableToolbar>
                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "block" : "none",
                          }}
                        >
                          <ToolbarSelected>
                            <FormsBulkActions
                              selected={selected}
                              setStatusModal={setStatusModal}
                              setSelectedRowData={setSelectedRowData}
                            />
                          </ToolbarSelected>
                        </Grid>

                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "none" : "flex",
                          }}
                        >
                          <Grid
                            item
                            sm={2}
                            xs={12}
                            md={2}
                            style={{ minWidth: "290px" }}
                          >
                            <ToolbarDefault
                              handleSearch={handleSearch}
                              placeholderSearch={"Search Forms"}
                              handleSearchClear={handleSearchClear}
                            ></ToolbarDefault>
                          </Grid>

                          <Grid item sm={2} xs={12} md={1}>
                            {hasPermission(
                              permissionForms.FORMS_MODULE,
                              permissions
                            ) && (
                              <Button
                                className={classNames(
                                  classes.button,
                                  classes.marginLeft10,
                                  classes.talReqButton
                                )}
                                onClick={() => {
                                  setFormSelection(true);
                                  //setOpenFormsRequest(true)
                                }}
                              >
                                New Form
                              </Button>
                            )}
                          </Grid>
                          <Grid item sm={4} xs={12} md={5} />
                          <Grid
                            item
                            sm={4}
                            xs={12}
                            md={3}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <DateRangePickerComponent />
                            <FormsFilters topTab={topTab} />
                          </Grid>
                        </Grid>
                      </EnhancedTableToolbar>
                      {(filterState.filters ||
                        filterState.startDateActive ||
                        filterState.endDateActive) && <FiltersAppliedList />}
                    </Table>
                  )}
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Box>
      )}

      {modal === 2.1 && (
        <RepairRequestFormModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
      {modal === 2.2 && (
        <EquipmentTransferRequestModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
      {modal === 3.1 && (
        <AccidentFollowupModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
      {modal === 3.2 && (
        <InjuryIllnessModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
      {modal === 3.3 && (
        <AccidentInvestigationModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
      {modal === 1 && (
        <TalentChangeRequestModal
          setModal={setModal}
          selectedModalViewResponse={selectedModalViewResponse}
        />
      )}
    </Box>
  );
};

export default withRouter(FormsSurfaceTable);
