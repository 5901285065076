import React from "react";
import merge from "lodash/merge";
import {
  getEvaluations,
  getEvaluationsSearch,
  getEvaluationsOffset,
} from "services/surveyEvaluationsService";

const defaultState = {
  evaluationsListLoading: true,
  evaluationsSelected: null,
  evaluationsList: [],
  evaluationsCount: [],
  openEvaluationsDelete: false,
  openView: false,
  readonlyView: true,
  openSurveyDialog: false,
};

const EvaluationsStateContext = React.createContext();
const EvaluationsDispatchContext = React.createContext();

function evaluationsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        evaluationsListLoading: action.evaluationsListLoading,
      };
    case "SET_EVALUATIONS_LIST":
      return {
        ...state,
        evaluationsList: action.evaluationsList,
      };
    case "SET_EVALUATIONS_COUNT":
      return {
        ...state,
        evaluationsCount: action.evaluationsCount,
      };
    case "SET_EVALUATIONS_SELECTED":
      return {
        ...state,
        evaluationsSelected: action.evaluationsSelected,
      };
    case "SET_READONLY_VIEW":
      return {
        ...state,
        readonlyView: action.readonlyView,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openEvaluationsDelete: action.openEvaluationsDelete,
      };
    case "TOGGLE_OPENSURVEY":
      return {
        ...state,
        openSurveyDialog: action.openSurveyDialog,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function EvaluationsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(evaluationsReducer, mergedState);
  return (
    <EvaluationsStateContext.Provider value={state}>
      <EvaluationsDispatchContext.Provider value={dispatch}>
        {children}
      </EvaluationsDispatchContext.Provider>
    </EvaluationsStateContext.Provider>
  );
}

function useEvaluationsState() {
  const context = React.useContext(EvaluationsStateContext);
  if (context === undefined) {
    throw new Error(
      "useEvaluationsState must be used within a LocationProvider"
    );
  }
  return context;
}

function useEvaluationsDispatch() {
  const context = React.useContext(EvaluationsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getEvaluationsList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      evaluationsListLoading: true,
    });
    const result = await getEvaluations(filterData);
    dispatch({
      type: "SET_EVALUATIONS_LIST",
      evaluationsList: [...result.data.data.evaluations],
    });
    dispatch({
      type: "SET_EVALUATIONS_COUNT",
      evaluationsCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      evaluationsListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getEvaluationsListDataSet(
  evaluationsList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getEvaluationsOffset(offset, filterData);
    const updateData = evaluationsList.concat(result.data.data.evaluations);
    dispatch({
      type: "SET_EVALUATIONS_LIST",
      evaluationsList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchEvaluationsList(search, filterData) {
  try {
    const result = await getEvaluationsSearch(search, filterData);
    return result.data.data.evaluations;
  } catch (error) {
    console.log(error);
  }
}

export {
  EvaluationsProvider,
  useEvaluationsState,
  useEvaluationsDispatch,
  getEvaluationsList,
  getEvaluationsListDataSet,
  searchEvaluationsList,
};
