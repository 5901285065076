import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const QuoteActionContext = React.createContext();

const initialState = {
  openDueDate: false,
  openApproveSingle: false,
  openApprove: false,
  openReject: false,
  openRejectSingle: false,
  openCloneSingle: false,
  openSnooze: false,
  openSnoozeSingle: false,
  openCompleteSingle: false,
  openDeleteSingle: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DUEDATE":
      return {
        ...state,
        openDueDate: action.open,
      };
    case "TOGGLE_APPROVE_SINGLE":
      return {
        ...state,
        openApproveSingle: action.open,
      };
    case "TOGGLE_APPROVE":
      return {
        ...state,
        openApprove: action.open,
      };
    case "TOGGLE_REJECT":
      return {
        ...state,
        openReject: action.open,
      };
    case "TOGGLE_REJECT_SINGLE":
      return {
        ...state,
        openRejectSingle: action.open,
      };
    case "TOGGLE_CLONE_SINGLE":
      return {
        ...state,
        openCloneSingle: action.open,
      };
    case "TOGGLE_SNOOZE_SINGLE":
      return {
        ...state,
        openSnoozeSingle: action.open,
      };
    case "TOGGLE_SNOOZE":
      return {
        ...state,
        openSnooze: action.open,
      };
    case "TOGGLE_COMPLETE_SINGLE":
      return {
        ...state,
        openCompleteSingle: action.open,
      };
    case "TOGGLE_DELETE_SINGLE":
      return {
        ...state,
        openDeleteSingle: action.open,
      };
    default:
      return initialState;
  }
};

const QuoteActionProvider = ({ children }) => (
  <QuoteActionContext.Provider value={React.useReducer(reducer, initialState)}>
    {children}
  </QuoteActionContext.Provider>
);

function useQuoteAction() {
  const context = React.useContext(QuoteActionContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketAction must be used within a QuoteActionProvider"
    );
  }
  return context;
}

export { QuoteActionProvider, useQuoteAction };
