export function AttachFileIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_416_4878)">
        <path
          d="M14.7727 7.09091L14.7727 16.5C14.7727 18.3082 13.3082 19.7727 11.5 19.7727C9.69182 19.7727 8.22727 18.3082 8.22727 16.5L8.22727 6.27273C8.22727 5.14364 9.14364 4.22727 10.2727 4.22727C11.4018 4.22727 12.3182 5.14364 12.3182 6.27273L12.3182 14.8636C12.3182 15.3136 11.95 15.6818 11.5 15.6818C11.05 15.6818 10.6818 15.3136 10.6818 14.8636L10.6818 7.09091L9.45455 7.09091L9.45455 14.8636C9.45455 15.9927 10.3709 16.9091 11.5 16.9091C12.6291 16.9091 13.5455 15.9927 13.5455 14.8636L13.5455 6.27273C13.5455 4.46455 12.0809 3 10.2727 3C8.46455 3 7 4.46455 7 6.27273L7 16.5C7 18.9873 9.01273 21 11.5 21C13.9873 21 16 18.9873 16 16.5V7.09091L14.7727 7.09091Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_416_4878">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
