import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Rating } from "@material-ui/lab";
import Integer from "lodash";
import { dateFormatField } from "../../../components/util/timeFormat";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { Fragment, useState } from "react";
import { useRateDialog } from "./useRateDialog";
import { useGetUserId } from "../context/UserIdContext";

export function RatingsList({ rating, classes, index }) {
  const { userId } = useGetUserId();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    isLoading: submitIsLoading,
    setIsLoading,
    deleteRating,
    handleEditTrade,
  } = useRateDialog();
  return (
    <Fragment key={index}>
      <Box key={index} className={classes.nonTopRatingCommentContainer}>
        <Box className={classes.topRatingPictureContainer}>
          <img
            className={classes.topRatingPicture}
            src={rating.user.profile.picture.file_url}
            alt="avatar"
          />
        </Box>
        <Box className={classes.topRatingCommenterInfo}>
          <Box className={classes.nameStarsContainer}>
            <Typography className={classes.topRatingCommenterName}>
              {rating.user.first_name + " " + rating.user.last_name}
            </Typography>

            <Rating
              className={classes.ratingRootStyling}
              name="simple-controlled"
              value={Integer.parseInt(rating.rate)}
              disabled={true}
            />
          </Box>
          <Box className={classes.menuDateContainer}>
            <Typography className={classes.topRatingDate}>
              {dateFormatField(rating.created_at)}
            </Typography>
            {Number(rating.user_id) === Number(user.id) && (
              <Box onClick={handleClick}>
                <MoreHorizIcon />
              </Box>
            )}
          </Box>
        </Box>
        <Typography className={classes.topRatingReviewComment}>
          {rating.comment}
        </Typography>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleEditTrade(rating);
            handleClose();
          }}
          disabled={submitIsLoading}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={submitIsLoading}
          onClick={() => {
            setIsLoading(true);
            const data = {
              rating_id: rating.id,
            };
            deleteRating.mutate({
              partner_id: userId,
              data: data,
            });
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
