import React from "react";
import useStyles from "./styles";
import TablePagination from "@material-ui/core/TablePagination";
import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

const TableCollapsiblePagination = (props) => {
  const classes = useStyles();
  const { count, page, onChangePage, rowsPerPage = 100 } = props;
  const { header } = useTableCollapsibleState();
  const colSpan = header[0].reduce(
    (partialSum, a) => partialSum + a.colSpan ?? 1,
    0
  );
  return (
    <TableRow className={classes.tablerow}>
      <TableCell colSpan={colSpan} className={classes.tablecellLevel}>
        <Box className={classes.tablecellPagination}>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={onChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableCollapsiblePagination;
