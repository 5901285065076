import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import * as classNames from "classnames";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { dataURLtoFile } from "components/util/fileUtil";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import diagram from "./diagram.png";
import useStyles from "./styles";
import BackIcon from "@material-ui/icons/NavigateBefore";
const isnum = (val) => /^\d+$/.test(val);

export default function AutoGrid({
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
  currentStep,
  setCurrentStep,
  totalSteps,
}) {
  const [teamMember, setTeamMember] = useState("");

  const { globalUi } = useContext(GlobalUiContext);
  const handleClearForBody = () => {
    let canvas = document.getElementsByTagName("canvas")[0];
    if (canvas.getContext) {
      let ctx = canvas.getContext("2d");
      loadImage(diagram).then((img) => {
        ctx.drawImage(img, 0, 0, 340, 400);
      });
    }
  };
  const loadImage = (url) => {
    return new Promise((r) => {
      let i = new Image();
      i.onload = () => r(i);
      i.src = url;
    });
  };

  useEffect(() => {
    let canvas = document.getElementsByTagName("canvas")[0];
    if (canvas.getContext) {
      let ctx = canvas.getContext("2d");
      loadImage(diagram).then((imgRes) => {
        ctx.drawImage(imgRes, 0, 0, 340, 400);
      });
    }
  }, []);
  const [addMultiple, setAddMultiple] = useState([
    {
      witness_name: "",
    },
  ]);
  const addMultipleObj = {
    witness_name: "",
  };

  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  const classes = useStyles();
  const [form, setForm] = useState({ home_phone: "", cell_phone: "" });
  let validAddMultiple = true;
  addMultiple.map((v) => {
    if (!v.witness_name) {
      validAddMultiple = false;
    }
  });
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let sigPad = useRef(null);
  let sigPadBody = useRef(null);
  return (
    <Box>
      <Grid
        container
        onChange={(e) => {
          if (e.target.id) {
            if (e.target.id.length === 1) {
              addMultiple[e.target.id][e.target.name] = e.target.value;
              setAddMultiple([...addMultiple]);
              return;
            }
          }
          if (
            e.target.name === "home_phone" ||
            e.target.name === "cell_phone"
          ) {
            if (isnum(e.target.value.trim())) {
              setForm({ ...form, [e.target.name]: e.target.value });
            }
            return;
          }

          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid item xs={12} md={12} className={classes.transferAdj}>
          <Box
            style={{
              overflow: "auto !important",
            }}
          >
            <span style={{ display: currentStep === 1 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Team Member(*)
                  </Typography>

                  <FormSelectAuto
                    options={userOptions}
                    name="user"
                    handleChange={(e, v) => setTeamMember(v)}
                    value={teamMember}
                    inputContainer={classes.inputContainer}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of Birth
                  </Typography>

                  <FormInputDateAdo
                    name="dob"
                    value={form?.dob}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        dob: e,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>Sex</Typography>
                  <RadioGroup
                    aria-label="radio"
                    name="radio"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio color="primary" />}
                      label="M"
                      name="sex"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio color="primary" />}
                      label="F"
                      name="sex"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Hire Date
                  </Typography>

                  <FormInputDateAdo
                    name="hire_date"
                    value={form?.hire_date}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        hire_date: e,
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12}></Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Home Phone # (Area code)
                  </Typography>
                  <TextField
                    name="home_phone"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    value={form.home_phone}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Cell Phone # (Area code)
                  </Typography>
                  <TextField
                    name="cell_phone"
                    value={form.cell_phone}
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    {" "}
                    Home Address
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Street(*)
                  </Typography>
                  <TextField
                    name="home_address"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Apt. #(*)
                  </Typography>
                  <TextField
                    name="apt"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    City(*)
                  </Typography>
                  <TextField
                    name="city"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    State(*)
                  </Typography>
                  <TextField
                    name="state"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Zip(*)
                  </Typography>
                  <TextField
                    name="zip"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 2 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Employee Description of Accident (include cause of injury)
                    (*)
                  </Typography>
                  <TextField
                    name="employee_desc_of_accident"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of Accident(*)
                  </Typography>

                  <FormInputDateAdo
                    name="date_of_accident"
                    value={form?.date_of_accident}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        date_of_accident: e,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Injure / Illness that occurred(*)
                  </Typography>
                  <TextField
                    name="injury_occured"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Part of the body Affected(*)
                  </Typography>
                  <TextField
                    name="part_affected"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.inputContainerDateAdj}>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.inputContainerDateAdj}>
                    Place a (x) mark on the body frame bellow indicating what
                    particular area(s) on your body that is injured. Specify
                    whether it is in the front or back as well. Make sure it
                    matches the description you have written down describing the
                    accident.
                  </Typography>
                  <Box className={classes.inputContainerDateAdj}>
                    <Box className={classes.bodyImageCentered}>
                      <SignatureCanvas
                        penColor="red"
                        canvasProps={{
                          width: 340,
                          height: 400,
                          className: classes.sigCanvas,
                        }}
                        ref={(ref) => {
                          sigPadBody = ref;
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid container className={classes.centerAlignBtn}>
                  <Button
                    autoFocus
                    className={classes.markFilledButton}
                    onClick={() => {
                      handleClearForBody();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>

              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Was the employee transported to Emergency Room?{" "}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.was_employee_transferred_emergency_room ===
                            "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_transferred_emergency_room"
                          value="yes"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_transferred_emergency_room: "yes",
                            })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.was_employee_transferred_emergency_room ===
                            "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_transferred_emergency_room"
                          value="no"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_transferred_emergency_room: "no",
                            })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Was Employee sent to assigned Occupational Clinic?{" "}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.was_employee_assigned_clinic === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_assigned_clinic"
                          value="yes"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_assigned_clinic: "yes",
                            })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.was_employee_assigned_clinic === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_assigned_clinic"
                          value="no"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_assigned_clinic: "no",
                            })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 3 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    {" "}
                    Place of Accident
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Street(*)
                  </Typography>
                  <TextField
                    name="acc_street"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Apt. #(*)
                  </Typography>
                  <TextField
                    name="acc_apt"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    City(*)
                  </Typography>
                  <TextField
                    name="acc_city"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    State(*)
                  </Typography>
                  <TextField
                    name="acc_state"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Zip(*)
                  </Typography>
                  <TextField
                    name="acc_zip"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    Please list any witnesses
                  </Typography>
                  {addMultiple?.map((v, i) => {
                    return (
                      <Box className={classes.inputContainer}>
                        <Typography className={classes.subSubTitle}>
                          Name(*)
                        </Typography>
                        <TextField
                          name="witness_name"
                          className={classes.field}
                          InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                          }}
                          fullWidth
                          id={i}
                        />
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Box className={classes.plusRightEnd}>
                    {addMultiple.length > 1 && (
                      <IndeterminateCheckBoxIcon
                        className={classes.iconMultiRequest}
                        onClick={() => {
                          addMultiple.pop();
                          setAddMultiple([...addMultiple]);
                        }}
                      />
                    )}
                    &nbsp;
                    <AddCircleIcon
                      className={classes.iconMultiRequest}
                      onClick={() => {
                        addMultiple.push({ ...addMultipleObj });
                        setAddMultiple([...addMultiple]);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <Typography className={classes.subTitle}>Signature</Typography>
              </Grid>
              <br />
              <br />
              <Grid container style={{ justifyContent: "center" }}>
                <SignatureCanvas
                  penColor="green"
                  canvasProps={{
                    width: 300,
                    height: 100,
                    className: classes.sigCanvas,
                  }}
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                />
              </Grid>
              <Box className={classes.inputContainerDateAdj}>
                <br />
                <Grid container className={classes.centerAlignBtn}>
                  <Button
                    autoFocus
                    className={classes.markFilledButton}
                    onClick={() => {
                      sigPad.clear();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Box>
            </span>
            <Box className={classes.trContainer}>
              <Box className={classes.trSubContainer}>
                <Box className={classes.flex}>
                  {currentStep === 1 ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className={classes.buttonBack}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                      startIcon={<BackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                    disabled={
                      currentStep === 1
                        ? !teamMember?.value ||
                          !form?.home_address ||
                          !form?.apt ||
                          !form?.city ||
                          !form?.state ||
                          !form?.zip
                        : currentStep === 2
                        ? !form?.date_of_accident ||
                          !form?.injury_occured ||
                          !form?.part_affected ||
                          !form?.employee_desc_of_accident
                        : currentStep === 3
                        ? !form?.acc_street ||
                          !form?.acc_apt ||
                          !form?.acc_city ||
                          !form?.acc_state ||
                          !form?.acc_zip ||
                          !validAddMultiple
                        : false
                    }
                    onClick={() => {
                      if (currentStep === totalSteps) {
                        const arrWitness = addMultiple.map(
                          (v) => v.witness_name
                        );
                        const templatePayload = {
                          user_id: teamMember.value,
                          dob: form.dob,
                          gender: form.sex,
                          hire_date: form.hire_date,
                          home_phone: form.home_phone,
                          cell_phone: form.cell_phone,
                          home_street: form.home_address,
                          home_apt: form.apt,
                          home_city: form.city,
                          home_state: form.state,
                          home_zip: form.zip,
                          accident_description: form.employee_desc_of_accident,
                          accident_date: form.date_of_accident,
                          accident_injure_illness: form.injury_occured,
                          accident_part_affected: form.part_affected,
                          accident_image: "",
                          to_emergency_room:
                            form.was_employee_transferred_emergency_room ===
                            "yes"
                              ? 1
                              : 0,
                          to_occupational_clinic:
                            form.was_employee_assigned_clininc,
                          accident_street: form.acc_street,
                          accident_apt: form.acc_apt,
                          accident_city: form.acc_city,
                          accident_state: form.acc_state,
                          accident_zip: form.acc_zip,
                          witnesses: arrWitness,
                          signature_image: "",
                        };
                        templatePayload.accident_image = dataURLtoFile(
                          document
                            .getElementsByTagName("canvas")[0]
                            .toDataURL(),
                          "accident_image"
                        );
                        templatePayload.signature_image = dataURLtoFile(
                          document
                            .getElementsByTagName("canvas")[1]
                            .toDataURL(),
                          "signature_image"
                        );
                        Object.keys(templatePayload).map((v) => {
                          if (!templatePayload[v]) {
                            templatePayload[v] = "";
                          }
                        });
                        submitFormDataBasedOnCurrentTab(
                          templatePayload,
                          topTab,
                          formSelected
                        ).then((res) => {
                          if (res.status === 201) {
                            closeAndRefreshSidebar();
                          }
                        });
                      }
                      if (currentStep < totalSteps) {
                        setCurrentStep(++currentStep);
                      }
                    }}
                  >
                    {" "}
                    {currentStep === totalSteps ? "Submit" : "Next"}
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
