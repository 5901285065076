import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    marginTop: 25,
    marginBottom: 40,
    overflow: "hidden",
    zIndex: 100,
  },
  leftSection: {
    backgroundColor: "#EDF5F8",
    paddingLeft: 50,
    paddingRight: 50,
    height: "100%",
  },
  logoWrapper: {
    width: 260,
    paddingTop: 45,
    paddingBottom: 60,
  },
  logo: {
    width: "100%",
  },
  sliderWrapper: {
    marginTop: 115,
    height: 170,
  },
  sliderTitle: {
    color: "#0B417E",
    fontSize: 30,
  },
  sliderSubTitle: {
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    color: "#0B417E",
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "140%",
  },
  linkLabel: {
    color: "#0B417E",
    marginRight: 20,
    fontSize: 12,
    whiteSpace: "nowrap",
    cursor: "pointer",
    textDecoration: "underline",
  },
  textLabel: {
    color: "#0B417E",
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  linkWrapper: {
    textAlign: "center",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  linkWrapperFooter: {
    marginTop: theme.spacing(1),
  },
  tabWrapper: {
    width: "40%",
    marginBottom: 50,
  },
  tabItem: {
    background: "#0B417E",
    height: 7,
    width: "100%",
    borderRadius: 5,
    opacity: 0.5,
    cursor: "pointer",
  },
  tabItemActive: {
    opacity: 1,
  },
  socialWrapper: {
    marginBottom: 80,
    "& > a": {
      marginRight: 20,
    },
  },
  socialWrapperFooter: {
    "& > a": {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  termsContent: {
    padding: 20,
    paddingTop: 0,
    "&  p": {
      marginBottom: 20,
    },
  },
  termsClose: {
    textAlign: "right",
    display: "block",
    marginTop: 5,
  },
  footerMobile: {
    backgroundColor: "#EDF5F8",
    position: "fixed",
    bottom: 0,
    padding: 15,
    textAlign: "center",
    width: "100%",
  },
}));

export default useStyles;
