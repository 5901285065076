import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import JobTableActions from "./jobTableActions";
import JobsListActions from "./jobsListActions";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { getJobs } from "services/jobService";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const columns = [
  {
    id: "id",
    label: "Job ID",
    format: "id",
    path: "job",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "job_number",
    label: "Job Number",
  },
  {
    id: "job_description",
    label: "Job Name",
  },
  {
    id: "site_number",
    label: "Site Number",
  },
  {
    id: "type",
    label: "Type",
    content: (row) => (row.location_parent_id ? "Child" : "Parent"),
  },
  {
    id: "city",
    label: "City",
  },
  {
    id: "state",
    label: "State",
  },
  {
    id: "actions",
    content: (row) => <JobTableActions row={row} />,
    contentMobile: (row) => <JobTableActions row={row} mobile={true} />,
    disableSort: true,
  },
];

const JobsTable = (props) => {
  const classes = useStyles();
  const [tablePointer, setTablePointer] = useState(50);
  const [loadingMore, setLoadingMore] = useState(false);
  const { listLoading, list, count } = useListState();
  const { hasRowsClickable, selected, rows } = useTableState();
  const { searchString, filters } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getJobs();
      dispatchList({
        type: "SET_LIST",
        list: result.data.data.jobs,
      });
      dispatchList({
        type: "SET_COUNT",
        count: result.data.data.jobs.length,
      });
      return dispatchList({
        type: "SET_LOADING",
        listLoading: false,
      });
    };
    fetchData();
  }, [dispatchList]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list.slice(0, tablePointer),
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, listLoading, tablePointer]);

  const handleScrollClick = useCallback(async () => {
    if (searchString) {
      // If search activated not scroll
      return;
    }
    if (list.length <= tablePointer) {
      return;
    }

    try {
      setLoadingMore(true);
      const updateTablePointer = tablePointer + 50;
      setTablePointer(updateTablePointer);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [tablePointer, list.length, searchString]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const resultSearch = list.filter(
          (jobItem) =>
            jobItem.job_description &&
            (jobItem.job_description.includes(query) ||
              jobItem.job_number.toString().startsWith(query))
        );
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: resultSearch,
        });
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list.slice(0, tablePointer),
    });
  };

  const handleTabChange = (event, value) => {
    const { history } = props;
    history.push(`/${value}`);
  };

  return (
    <Box className={classes.root}>
      {listLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              {!searchString ? (
                <Chip
                  label={`${
                    count > tablePointer ? tablePointer : count
                  } / ${count}`}
                  color="secondary"
                  variant="outlined"
                />
              ) : (
                <Chip
                  label={`${rows.length}`}
                  color="secondary"
                  variant="outlined"
                />
              )}
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={"jobs"}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Customers</Box>}
                key={"customers"}
                value={"customers"}
              />
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Jobs</Box>}
                key={"jobs"}
                value={"jobs"}
              />
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected />
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                          placeholderSearch={"All Jobs"}
                        >
                          <JobsListActions />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(JobsTable);
