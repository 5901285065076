import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { ArrowBack } from "../icons/ArrowBack";
import useStyles from "../styles";
import { useUserDrawerContext } from "../context/UserDrawerContext";
import { usePartnerUsers } from "./useUsers";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { StepOneComponent } from "./StepOneComponent";
import { StepTwoComponent } from "./StepTwoComponent";
import Typography from "@material-ui/core/Typography";

export function AddUserForm() {
  const classes = useStyles();
  const {
    transformJobData,
    jobs,
    jobsValue,
    isJobsLoading,
    handleJobChange,
    handleCustomerChange,
    transFormedCustomers,
    customersValue,
    handleFileChange,
    selectedFile,
    data,
    handleBlur,
    submitData,
    errors,
    isUserLoading,
    transformedRoles,
    originalImage,
    isSubmitting,
    handleBlurConfirmPassword,
    confirmPasswordErrors,
    currentStep,
    setCurrentStep,
    resetData,
    submitPassword,
    addUserMutation,
    updatePartnerUserMutation,
    areThereErrors,
  } = usePartnerUsers();
  const {
    isEditing,
    toggleDrawer,
    drawerState,
    handleToggleDrawer,
    isViewOnly,
  } = useUserDrawerContext();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  return (
    <Fragment>
      <Drawer
        anchor={"right"}
        open={drawerState["right"]}
        onClose={() => {
          handleToggleDrawer("right", false);
          resetData();
        }}
      >
        <Box className={classes.drawerOuterContainer}>
          <Box className={classes.drawerContainer}>
            {(addUserMutation.isLoading ||
              updatePartnerUserMutation.isLoading) && <LoadingIndicator />}
            {!addUserMutation.isLoading &&
              !updatePartnerUserMutation.isLoading && (
                <Fragment>
                  {currentStep === 1 && (
                    <StepOneComponent
                      data={data}
                      handleBlur={handleBlur}
                      classes={classes}
                      toggleDrawer={toggleDrawer}
                      resetData={resetData}
                      isEditing={isEditing}
                      isUserLoading={isUserLoading}
                      selectedFile={selectedFile}
                      handleFileChange={handleFileChange}
                      originalImage={originalImage}
                      isSubmitting={isSubmitting}
                      handleBlurConfirmPassword={handleBlurConfirmPassword}
                      confirmPasswordErrors={confirmPasswordErrors}
                      submitPassword={submitPassword}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      errors={errors}
                      isViewOnly={isViewOnly}
                    />
                  )}
                  {currentStep === 2 && (
                    <StepTwoComponent
                      data={data}
                      errors={errors}
                      handleBlur={handleBlur}
                      transformedRoles={transformedRoles}
                      jobs={jobs}
                      jobsValue={jobsValue}
                      isJobsLoading={isJobsLoading}
                      toggleDrawer={toggleDrawer}
                      handleJobChange={handleJobChange}
                      handleCustomerChange={handleCustomerChange}
                      transFormedCustomers={transFormedCustomers}
                      customersValue={customersValue}
                      classes={classes}
                      transformJobData={transformJobData}
                      isViewOnly={isViewOnly}
                      resetData={resetData}
                    />
                  )}
                </Fragment>
              )}
          </Box>
          <Box className={classes.errorsButtonContainer}>
            {areThereErrors && (
              <Typography className={classes.errorMissingFieldsText}>
                Invalid or missing fields. Please fill them out.
              </Typography>
            )}
            <Box className={classes.drawerButtonsContainer}>
              {currentStep === 1 ? (
                <Button
                  onClick={() => {
                    toggleDrawer("right", false);
                    resetData();
                  }}
                  startIcon={<ArrowBack />}
                  className={classes.goBackButton}
                >
                  Go Back
                </Button>
              ) : (
                <Button
                  onClick={handleBack}
                  startIcon={<ArrowBack />}
                  className={classes.goBackButton}
                  disableRipple={true}
                >
                  Go Back
                </Button>
              )}
              {currentStep === 1 ? (
                <Button
                  disableRipple={true}
                  onClick={handleNext}
                  className={classes.saveChangesButton}
                >
                  Next
                </Button>
              ) : (
                <>
                  {isViewOnly === false && (
                    <Button
                      disableRipple={true}
                      disabled={isSubmitting}
                      onClick={submitData}
                      className={classes.saveChangesButton}
                    >
                      {isEditing ? "Save Changes" : "Create User"}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
}
