import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
const apiEndpoint = `${apiUrl}/evaluations`;

export function getEvaluationsForUser(userId) {
  return http.get(`${apiEndpoint}/${userId}`, {});
}

export function getEvaluations(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`);
}

export function getEvaluationsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getEvaluationsOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getEvaluationsFilter() {
  return http.get(`${apiEndpoint}/filter`);
}

export function exportEvaluations(data) {
  return http.post(`${apiEndpoint}/export`, data);
}

export function getDepartments() {
  return http.get(`${apiEndpoint}/departments`);
}

export function getPositions(departments_ids) {
  return http.get(`${apiEndpoint}/positions`, {
    params: { departments_ids },
  });
}
