import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import MessageDialog from "./dialog/messageDialog";
import { logException } from "components/util/logUtil";
import {
  getWorkticketComments,
  addWorkticketComment,
} from "services/workticketService";
import { getCommentByFileId } from "services/fileService";
import { useWorkticketView } from "contexts/workticketViewContext";
import useStyles from "./styles";

const AddCommentButton = ({
  file,
  workTicketId,
  activeTab,
  onCommentChange,
  commentStartTime,
}) => {
  const classes = useStyles();
  const [newCommentText, setNewCommentText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const context = useWorkticketView();
  const dispatchContext = context[1];

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleNewCommentChange = (event) => {
    const newComment = event.target.value;
    setNewCommentText(newComment);
    onCommentChange(newComment);
  };

  const handleAddComment = async () => {
    if (newCommentText.trim() !== "") {
      const metadataObj = {
        metadata: {
          comment_time: commentStartTime || "",
        },
      };
      const metadataString = JSON.stringify(metadataObj);
      const commentData = {
        file_id: file.id,
        body: newCommentText,
        video_metadata: metadataString,
        tab: activeTab,
      };

      try {
        setIsLoadingData(true);
        setOpenMessage(true);
        handleNewCommentChange({ target: { value: "" } });
        await addWorkticketComment(workTicketId, commentData);
        fetchData();
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };
  const fetchData = async () => {
    try {
      const response = await getCommentByFileId(file.id);
      const comments = response.data.data;
      checkHasComments(comments);

      dispatchContext({
        type: "SET_WORKTICKET_FILE_COMMENTS",
        workticketFileComments: comments,
      });
      const responseComment = await getWorkticketComments(workTicketId);
      dispatchContext({
        type: "SET_WORKTICKET_COMMENTS",
        workticketComments: responseComment.data.data,
      });

      setOpenMessageContent("Comment has been added successfully.");
      setIsLoadingData(false);
    } catch (e) {
      logException(e, "Cannot add comment");
    }
  };

  const checkHasComments = (dataComment) => {
    const hasTooManyComments =
      dataComment?.internal?.length > 1 ||
      dataComment?.partner?.length > 1 ||
      dataComment?.customer?.length > 1;

    if (!hasTooManyComments) {
      dispatchContext({
        type: "SET_REFRESH_TOGGLE_COMMENTS",
        refreshToggleComments: true,
      });
    }
  };

  return (
    <Box className={classes.containerAddButton}>
      <Fab
        className={classes.addCommentButton}
        aria-label="add"
        size="small"
        disableRipple
        disableFocusRipple
        elevation={0}
      >
        <AddIcon />
      </Fab>
      <Input
        className={classes.commentInputText}
        type="text"
        placeholder="Add a Comment"
        multiline
        disableUnderline
        value={newCommentText}
        onChange={handleNewCommentChange}
      />

      <IconButton
        size="small"
        style={{ color: "#4F98BC" }}
        onClick={handleAddComment}
      >
        <SendIcon />
      </IconButton>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default AddCommentButton;
