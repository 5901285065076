import React, { useEffect, useContext } from "react";
import * as classNames from "classnames";
import { NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { default as useStyles, HtmlTooltip } from "./styles";
import { hasPermission } from "lib/permissions";

import GlobalUiContext from "contexts/globalUiContext";

const NavItem = (props) => {
  const classes = useStyles();
  const page = window.location.pathname;
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);

  const { navItems, isNavBarOpen, navItemOpen, currentPage, permissions } =
    globalUi;

  let hasSelectedChild = null;

  useEffect(() => {
    dispatchGlobalUi({ type: "UPDATE_CURRENTPAGE", currentPage: page });
  }, [page, dispatchGlobalUi]);

  const pageItems = navItems.map((page, index) => {
    const isOpen = Boolean(navItemOpen === index);
    const isSelected = Boolean(
      currentPage && page.link.split("/")[1].includes(currentPage.split("/")[1])
    );
    if (
      !page.childs &&
      !page.external &&
      hasPermission(page.permission, permissions)
    ) {
      return (
        <NavLink to={page.link} style={{ textDecoration: "none" }} key={index}>
          <ListItem
            button
            className={classNames(
              classes.listItem,
              isSelected && classes.selected
            )}
          >
            {!isNavBarOpen ? (
              <Tooltip title={page.name} placement="right" arrow>
                <ListItemIcon className={classes.listItemIcon}>
                  {page.icon}
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon className={classes.listItemIcon}>
                {page.icon}
              </ListItemIcon>
            )}

            <ListItemText
              primary={page.name}
              className={classes.listItemText}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    } else if (
      !page.childs &&
      page.external &&
      hasPermission(page.permission, permissions)
    ) {
      return (
        <a
          href={`${page.link}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          key={index}
        >
          <ListItem
            button
            className={classNames(
              classes.listItem,
              isSelected && classes.selected
            )}
          >
            {!isNavBarOpen ? (
              <Tooltip title={page.name} placement="right" arrow>
                <ListItemIcon className={classes.listItemIcon}>
                  {page.icon}
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon className={classes.listItemIcon}>
                {page.icon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={page.name}
              className={classes.listItemText}
              disableTypography={true}
            />
          </ListItem>
        </a>
      );
    } else if (page.childs) {
      let hasChildren = false;
      let elemChildren = [];
      const childsItems = page.childs.map((pageChild, childIndex) => {
        if (hasPermission(pageChild.permission, permissions)) {
          hasChildren = true;
          elemChildren = [...elemChildren, pageChild];

          const isSelected = Boolean(
            currentPage &&
              pageChild.link.split("/")[1].includes(currentPage.split("/")[1])
          );

          if (!pageChild.external) {
            return (
              <NavLink
                to={pageChild.link}
                style={{ textDecoration: "none" }}
                key={pageChild.name}
                parentid={pageChild.parentId}
              >
                <ListItem
                  button
                  className={classNames(classes.nested, {
                    [classes.nestedClosed]: !isNavBarOpen,
                    [classes.selectedSub]: isSelected,
                  })}
                >
                  {isOpen && isNavBarOpen && (
                    <ListItemIcon
                      className={classNames(classes.listItemIconSmall, {
                        [classes.listItemIconTooltip]: !isNavBarOpen,
                      })}
                    >
                      {pageChild.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    secondary={`${pageChild.name}`}
                    className={classes.listItemChildText}
                  />
                </ListItem>
              </NavLink>
            );
          } else {
            return (
              <a
                href={`${pageChild.link}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                key={pageChild.name}
              >
                <ListItem
                  button
                  className={classNames(classes.nested, {
                    [classes.nestedClosed]: !isNavBarOpen,
                    [classes.selectedSub]: isSelected,
                  })}
                >
                  {isOpen && isNavBarOpen && (
                    <ListItemIcon
                      className={classNames(classes.listItemIconSmall, {
                        [classes.listItemIconTooltip]: !isNavBarOpen,
                      })}
                    >
                      {pageChild.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText
                    secondary={pageChild.name}
                    className={classes.listItemChildText}
                  />
                </ListItem>
              </a>
            );
          }
        } else {
          return "";
        }
      });
      if (hasChildren) {
        childsItems.forEach((element) => {
          if (element !== "" && element.props.to === currentPage) {
            hasSelectedChild = element.props.parentid;
          }
        });

        return elemChildren?.length === 1 && !elemChildren[0].external ? (
          <NavLink
            to={elemChildren[0].link}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <ListItem
              button
              className={classNames(
                classes.listItem,
                isSelected && classes.selected
              )}
            >
              {!isNavBarOpen ? (
                <Tooltip title={page.name} placement="right" arrow>
                  <ListItemIcon className={classes.listItemIcon}>
                    {page.icon}
                  </ListItemIcon>
                </Tooltip>
              ) : (
                <ListItemIcon className={classes.listItemIcon}>
                  {page.icon}
                </ListItemIcon>
              )}

              <ListItemText
                primary={page.name}
                className={classes.listItemText}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        ) : (
          <Box key={index}>
            <HtmlTooltip
              interactive
              placement="right-start"
              disableHoverListener={isNavBarOpen}
              title={childsItems}
              enterTouchDelay={100}
              leaveTouchDelay={3000}
            >
              <ListItem
                button
                key={index}
                onClick={() =>
                  dispatchGlobalUi({
                    type: "CLICK_SUBNAV",
                    navItemOpen: index,
                  })
                }
                className={classNames(classes.listItem, {
                  [classes.selected]: hasSelectedChild === page.id && !isOpen,
                })}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {page.icon}
                </ListItemIcon>
                <ListItemText
                  primary={page.name}
                  className={classes.listItemText}
                  disableTypography={true}
                />
                {isOpen ? (
                  <ExpandLess className={classes.listItemExpand} />
                ) : (
                  <ExpandMore className={classes.listItemExpand} />
                )}
              </ListItem>
            </HtmlTooltip>

            <Collapse in={isOpen && isNavBarOpen} timeout="auto" unmountOnExit>
              <React.Fragment>{childsItems}</React.Fragment>
            </Collapse>
          </Box>
        );
      }
    }
    return null;
  });

  return <Box>{pageItems}</Box>;
};

export default NavItem;
