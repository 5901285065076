import React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./styles";

const FormTotal = (props) => {
  const classes = useStyles();
  const { label, subLabel, value, icon, text, variant, error, name } = props;

  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  return (
    <Box className={classes.informationContainer}>
      <Box className={classes.labelContainerTotal}>
        <InputLabel className={classes.inputInternalLabel}>{label}</InputLabel>
        {subLabel ? (
          <InputLabel className={classes.inputInternalSubLabel}>
            {subLabel}
          </InputLabel>
        ) : (
          ""
        )}
      </Box>
      <Box
        className={
          variant === 2 ? classes.valuesContainer2 : classes.valuesContainer
        }
      >
        <Grid container>
          <Grid item md={5} className={classes.valueContainer}>
            {variant === 2 ? (
              <ArrowForwardIosIcon className={classes.arrowIcon} />
            ) : (
              ""
            )}
            {value}
          </Grid>
          <Grid item md={7}>
            <Box className={classes.textInformationContainer}>
              {icon}
              <Typography className={classes.textInformation}>
                {text}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {errorInput && Boolean(errorInput.length) && (
        <FormHelperText error>{errorInput[0].message}</FormHelperText>
      )}
    </Box>
  );
};

export default FormTotal;
