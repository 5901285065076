import React from "react";
import * as classNames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useStyles from "./styles";

import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";

const TableCollapsibleHead = (props) => {
  const classes = useStyles();
  const { header } = useTableCollapsibleState();
  return (
    <TableHead className={classes.header}>
      {header.map((headerRow, index) => (
        <TableRow key={index}>
          {headerRow.map((headerCell, indexCell) =>
            headerCell.type === "repeat" ? (
              [...Array(headerCell.n).keys()].map(() =>
                headerCell.labels.map(
                  (headerMultiCell, headerMultiCellIndex) => (
                    <TableCell
                      className={classNames(
                        classes.headLabel,
                        classes.tableheadcell
                      )}
                      key={headerMultiCellIndex}
                      style={
                        headerMultiCellIndex === headerCell.labels.length - 1
                          ? { ...headerCell?.style, ...headerCell.endStyle }
                          : headerCell?.style ?? {}
                      }
                      colSpan={headerCell.colSpan ?? 1}
                    >
                      {headerMultiCell}
                    </TableCell>
                  )
                )
              )
            ) : (
              <TableCell
                className={classNames(classes.headLabel, classes.tableheadcell)}
                key={indexCell}
                style={headerCell?.style ?? {}}
                colSpan={headerCell.colSpan ?? 1}
              >
                {headerCell.label}
              </TableCell>
            )
          )}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default TableCollapsibleHead;
