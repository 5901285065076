import React, { useContext } from "react";
import SurveysDeleteDialog from "./surveysDeleteDialog";
import SurveysChangeStatusDialog from "./surveysChangeStatusDialog";
import GlobalUiContext from "contexts/globalUiContext";

const SurveysListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <SurveysDeleteDialog />
      <SurveysChangeStatusDialog />
    </>
  );
};
export default SurveysListDialog;
