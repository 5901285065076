import React from "react";
import * as quoteService from "../services/quoteService";
import * as projectService from "../services/projectService";
import { quotesStatusOptions } from "../constants";
import { merge } from "lodash";

const defaultState = {
  quoteListLoading: true,
  quoteSelected: null,
  quoteList: [],
  quoteCount: [],
  quoteDetails: [],
  editMode: false,
  permissions: [],
};

const QuoteStateContext = React.createContext();
const QuoteDispatchContext = React.createContext();

function quoteReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        quoteListLoading: action.quoteListLoading,
      };
    case "SET_QUOTE_LIST":
      return {
        ...state,
        quoteList: action.quoteList,
      };
    case "SET_QUOTE_COUNT":
      return {
        ...state,
        quoteCount: action.quoteCount,
      };
    case "SET_QUOTE_SELECTED":
      return {
        ...state,
        quoteSelected: action.quoteSelected,
      };
    case "SET_QUOTE_DETAILS":
      return {
        ...state,
        quoteDetails: action.quoteDetails,
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(quoteReducer, mergedState);
  return (
    <QuoteStateContext.Provider value={state}>
      <QuoteDispatchContext.Provider value={dispatch}>
        {children}
      </QuoteDispatchContext.Provider>
    </QuoteStateContext.Provider>
  );
}

function useQuoteState() {
  const context = React.useContext(QuoteStateContext);
  if (context === undefined) {
    throw new Error("useQuoteState must be used within a QuoteProvider");
  }
  return context;
}

function useQuoteDispatch() {
  const context = React.useContext(QuoteDispatchContext);
  if (context === undefined) {
    throw new Error("useQuoteDispatch must be used within a QuoteProvider");
  }
  return context;
}

async function getQuote(dispatch, id) {
  try {
    const quoteDetailsResult = await quoteService.getQuoteDetails(id);
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuoteList(dispatch, filterData) {
  try {
    const quoteListResult = await quoteService.getQuotes(filterData);
    //const resultAll = await quoteService.getQuotesTab("all");
    const quoteList = {
      ...quoteListResult.data.data.quotes,
    };
    dispatch({
      type: "SET_QUOTE_LIST",
      quoteList: quoteList,
    });
    const quoteCount = {
      ...quoteListResult.data.data.filter_counts,
    };
    dispatch({
      type: "SET_QUOTE_COUNT",
      quoteCount: quoteCount,
    });
    dispatch({
      type: "SET_LOADING",
      quoteListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuoteListDataSet(
  quoteList,
  dispatch,
  tab,
  offset,
  filterData
) {
  try {
    const findElement = quotesStatusOptions.find((item) => item.id === tab);
    const result = await quoteService.getQuotesTabOffset(
      findElement.tab,
      offset,
      filterData
    );
    quoteList[tab] = quoteList[tab].concat(result.data.data.quotes);
    dispatch({
      type: "SET_QUOTE_LIST",
      quoteList: quoteList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchQuotes(search, filterData) {
  try {
    const quoteListResult = await quoteService.getQuotesSearch(
      search,
      filterData
    );
    return quoteListResult.data.data.quotes;
  } catch (error) {
    console.log(error);
  }
}

async function filterQuotes(search, filters) {
  try {
    const quoteListResult = await quoteService.filter(search, filters);
    return quoteListResult.data.data.quotes;
  } catch (error) {
    console.log(error);
  }
}

async function getQuoteTabList(dispatch, id, filterData) {
  try {
    const quoteListResult = await projectService.getQuotes(id, filterData);
    const quoteList = [...quoteListResult.data.data.quotes];
    dispatch({
      type: "SET_QUOTE_LIST",
      quoteList: quoteList,
    });
    const quoteCount = quoteListResult.data.data.count;
    dispatch({
      type: "SET_QUOTE_COUNT",
      quoteCount: quoteCount,
    });
    dispatch({
      type: "SET_LOADING",
      quoteListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuoteTabListDataSet(
  quoteList,
  dispatch,
  id,
  offset,
  filterData
) {
  try {
    const result = await projectService.getQuotesOffset(id, offset, filterData);
    quoteList = quoteList.concat(result.data.data.quotes);
    dispatch({
      type: "SET_QUOTE_LIST",
      quoteList: quoteList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchQuotesTab(dispatch, id, search, filterData) {
  try {
    const quoteListResult = await projectService.getQuotesSearch(
      id,
      search,
      filterData
    );
    const quoteList = [...quoteListResult.data.data.quotes];
    dispatch({
      type: "SET_QUOTE_LIST",
      quoteList: quoteList,
    });
    const quoteCount = quoteListResult.data.data.count;
    dispatch({
      type: "SET_QUOTE_COUNT",
      quoteCount: quoteCount,
    });
    dispatch({
      type: "SET_LOADING",
      quoteListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

export {
  QuoteProvider,
  useQuoteState,
  useQuoteDispatch,
  getQuote,
  getQuoteList,
  getQuoteListDataSet,
  filterQuotes,
  searchQuotes,
  getQuoteTabList,
  getQuoteTabListDataSet,
  searchQuotesTab,
};
