import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormInput from "components/ui/FormContent/formInput";
import { useSurveyFillState } from "contexts/surveyFillContext";
import { updateSurvey } from "services/surveyFrontlineService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const initialData = {
  first_name: "",
  last_name: "",
  email: "",
  company_name: "",
};

const BasicInfoDialog = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState([]);
  const { completeSurvey, history } = props;
  const { publicMode, selectedQuestions } = useSurveyFillState();

  useEffect(() => {
    const query = new URLSearchParams(history?.location?.search);
    const email = query.get("email");
    let updateData = { ...data };
    if (email) {
      updateData = { ...updateData, email };
      const first_name = query.get("first_name");
      if (first_name) {
        updateData = { ...updateData, first_name };
      }
      const last_name = query.get("last_name");
      if (last_name) {
        updateData = { ...updateData, last_name };
      }
      setData({ ...updateData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = (event) => {
    setError([]);
    setData({ ...data, [event.name]: event.value });
  };

  const handleStart = async () => {
    try {
      setIsLoading(true);
      const query = new URLSearchParams(history?.location?.search);
      const email = query.get("email");

      let errorData = [];
      if (data.first_name === "" && email !== data.email) {
        errorData = [
          ...errorData,
          { key: "first_name", message: "First name is required" },
        ];
      }
      if (data.last_name === "" && email !== data.email) {
        errorData = [
          ...errorData,
          { key: "last_name", message: "Last name is required" },
        ];
      }
      if (data.email === "") {
        errorData = [
          ...errorData,
          { key: "email", message: "Email is required" },
        ];
      }
      if (data.company_name === "" && email !== data.email) {
        errorData = [
          ...errorData,
          { key: "company_name", message: "Company is required" },
        ];
      }

      if (errorData.length) {
        setError(errorData);
        setIsLoading(false);
        return null;
      }
      await updateSurvey(selectedQuestions[0].qa_survey_submit_id, data);
      completeSurvey();
      setOpenDialog(false);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot save basic information for survey");
    }
  };

  if (!publicMode) {
    return null;
  }

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <Box>
        <DialogContent className={classes.wrapperDialog}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="first_name"
                label="First Name"
                value={data.first_name}
                handleBlur={handleBlur}
                error={error}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="last_name"
                label="Last Name"
                value={data.last_name}
                handleBlur={handleBlur}
                error={error}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="email"
                label="Email"
                value={data.email}
                handleBlur={handleBlur}
                error={error}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="company_name"
                label="Company"
                value={data.company_name}
                handleBlur={handleBlur}
                error={error}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleStart}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
            disabled={isLoading}
          >
            Submit
          </Button>
          {isLoading ? <LoadingStateHorizontal isVisible /> : null}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default BasicInfoDialog;
