import React from "react";
import * as assetService from "../services/assetSurfaceService";
import { merge } from "lodash";

const defaultState = {
  assetListLoading: true,
  assetSelected: null,
  assetList: [],
  assetCount: [],
  assetDetails: [],
  assetAction: null,
  assetRowId: null
};

const AssetStateContext = React.createContext();
const AssetDispatchContext = React.createContext();

function assetReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        assetListLoading: action.assetListLoading
      };
    case "SET_ASSET_LIST":
      return {
        ...state,
        assetList: action.assetList
      };
    case "SET_ASSET_COUNT":
      return {
        ...state,
        assetCount: action.assetCount
      };
    case "SET_ASSET_SELECTED":
      return {
        ...state,
        assetSelected: action.assetSelected
      };
    case "SET_ASSET_DETAILS":
      return {
        ...state,
        assetDetails: action.assetDetails
      };
    case "SET_ASSET_ACTION":
      return {
        ...state,
        assetAction: action.assetAction
      };
    case "SET_ASSET_ROW_ID":
      return {
        ...state,
        assetRowId: action.assetRowId
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AssetProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(assetReducer, mergedState);
  return (
    <AssetStateContext.Provider value={state}>
      <AssetDispatchContext.Provider value={dispatch}>
        {children}
      </AssetDispatchContext.Provider>
    </AssetStateContext.Provider>
  );
}

function useAssetState() {
  const context = React.useContext(AssetStateContext);
  if (context === undefined) {
    throw new Error("useAssetState must be used within a AssetProvider");
  }
  return context;
}

function useAssetDispatch() {
  const context = React.useContext(AssetDispatchContext);
  if (context === undefined) {
    throw new Error("useAssetDispatch must be used within a AssetProvider");
  }
  return context;
}

async function getProject(dispatch, id) {
  try {
    const assetDetailsResult = await assetService.getAssetDetails(id);
    dispatch({
      type: "SET_ASSET_DETAILS",
      assettDetails: assetDetailsResult.data.data.asset
    });
  } catch (error) {
    console.log(error);
  }
}

async function getAssetList(dispatch, filterData) {
  try {
    const assetListResult = await assetService.getAssets(filterData);
    dispatch({
      type: "SET_ASSET_LIST",
      assetList: assetListResult.data.data.assets
    });
    dispatch({
      type: "SET_ASSET_COUNT",
      assetCount: assetListResult.data.data.filter_count
    });
    dispatch({
      type: "SET_LOADING",
      assetListLoading: false
    });
  } catch (error) {
    console.log(error);
  }
}

async function getAssetListDataSet(assetList, dispatch, offset, filterData) {
  try {
    const result = await assetService.getAssetsOffset(offset, filterData);
    const updateData = assetList.concat(result.data.data.assets);
    dispatch({
      type: "SET_ASSET_LIST",
      assetList: updateData
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchAssets(search, filterData) {
  try {
    const assetListResult = await assetService.getAssetsSearch(
      search,
      filterData
    );
    return assetListResult.data.data.assets;
  } catch (error) {
    console.log(error);
  }
}

export {
  AssetProvider,
  useAssetState,
  useAssetDispatch,
  getProject,
  getAssetList,
  searchAssets,
  getAssetListDataSet
};
