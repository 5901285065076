import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const [numSelected, setNumSelected] = useState(0);
  const [numNotActive, setNumNotActive] = useState(0);
  const {
    rows,
    columns,
    order,
    orderBy,
    selected,
    hasSelect,
    tableKpi,
  } = useTableState();
  const dispatch = useTableDispatch();

  useEffect(() => {
    if (selected) {
      setNumSelected(selected.length);
    }
  }, [selected]);

  const createSortHandler = (property) => (event) => {
    const isDesc = orderBy === property && order === "desc";
    const setOrder = isDesc ? "asc" : "desc";
    dispatch({ type: "SET_ORDER", order: setOrder });
    dispatch({ type: "SET_ORDERBY", orderBy: property });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [];
      let notSelecteds = 0;
      rows.map((n) => {
        if (columns[0].checkbox && !columns[0].checkbox.active(n)) {
          notSelecteds += 1;
          return false;
        }
        newSelecteds.push(n.id);
        return true;
      });
      if (rows.length === notSelecteds) {
        return;
      }
      setNumNotActive(notSelecteds);
      dispatch({ type: "SET_SELECTED", selected: newSelecteds });
      return;
    }
    dispatch({ type: "SET_SELECTED", selected: [] });
  };

  return (
    <TableHead className={classes.header}>
      {rows && rows.length > 0 ? (
        <TableRow>
          {hasSelect ? (
            <TableCell padding="checkbox" className={classes.headCheckbox}>
              <Checkbox
                indeterminate={
                  numSelected > 0 && numSelected < rows.length - numNotActive
                }
                checked={numSelected === rows.length - numNotActive}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
                className={classes.tableCheckbox}
              />
            </TableCell>
          ) : null}
          {columns.map((headCell, index) => (
            <TableCell
              className={
                tableKpi
                  ? classNames(
                      classes.headLabel,
                      classes.tablecell,
                      classes.tableHeadErrorKpi
                    )
                  : classNames(classes.headLabel, classes.tablecell)
              }
              key={headCell.id ? headCell.id : index}
              sortDirection={orderBy === headCell.id ? order : false}
              style={headCell?.style ?? {}}
            >
              {!headCell.disableHeader ? (
                headCell.disableSort ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <TableRow></TableRow>
      )}
    </TableHead>
  );
};

export default EnhancedTableHead;
