import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    color: "#747474",
    backgroundColor: "#f5f5f5",
    boxShadow: "none",
    fontSize: "10px",
    padding: "1px 6px",
    fontWeight: "700",
    borderColor: "#78c1ce",
    minWidth: 0,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#eee"
    }
  }
});

export default withStyles(styles)(props => (
  <Button variant="contained" {...props}>
    <Typography
      style={{
        color: "#747474",
        fontSize: "10px",
        fontWeight: "700",
        lineHeight: "normal"
      }}
    >
      Clear
    </Typography>
  </Button>
));
