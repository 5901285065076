import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useCustomerView } from "contexts/customerViewContext";

const CustomerBreadcrum = () => {
  const [stateContext] = useCustomerView();
  const { customer, isLoading } = stateContext ?? null;

  if (isLoading && customer !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <PageHeader pageTitle={customer.id ? `${customer.common_name}` : "New"}>
      <Link to="/customers">Customers</Link>
    </PageHeader>
  );
};

export default CustomerBreadcrum;
