import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/CheckCircle";
import ArchivedIcon from "@material-ui/icons/Archive";
import useStyles from "./styles";

const BudgetStatusChip = (props) => {
  const classes = useStyles();
  const { status } = props;

  let classChip;
  let iconChip;
  let labelChip;

  switch (status) {
    case 1:
      labelChip = "Active";
      classChip = classes.chipActive;
      iconChip = <DoneIcon />;
      break;
    case 2:
      labelChip = "Archived";
      classChip = classes.chipArchived;
      iconChip = <ArchivedIcon />;
      break;
    default:
      break;
  }

  return (
    <Chip
      icon={iconChip}
      label={labelChip}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default BudgetStatusChip;
