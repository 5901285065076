import React from "react";
import LocationsSurveysDialog from "./locationsSurveysDialog";
import SurveyContent from "pages/survey/surveyContent";

const LocationsListDialog = (props) => {
  return (
    <>
      <LocationsSurveysDialog />
      <SurveyContent />
    </>
  );
};
export default LocationsListDialog;
