import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {
  imageIcon,
  documentIcon,
  videoIcon,
  checkCircle,
  radioButtonUnchecked,
  infoIcon,
  linkIcon,
} from "./icons";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import MessageDialogResumeFiles from "./dialog/messageDialogResumeFiles";
import ErrorIcon from "@material-ui/icons/Error";
import { logException } from "components/util/logUtil";
import {
  useUploadFileState,
  useUploadFileDispatch,
} from "contexts/uploadFileContext";
import classNames from "classnames";
import useStyles from "./styles";

const COMPLETED = "Completed";
const FAILED = "Failed";
const IN_PROGRESS = "In Progress";

const iconMap = {
  1: documentIcon(),
  2: imageIcon(),
  3: videoIcon(),
  4: linkIcon(),
};

const changeUploadFileStatus = (dispatch, isUploadFile) => {
  dispatch({ type: "SET_LOADING", isUploadFile: isUploadFile });
};

const UploadPartnerProgress = ({ partnerId, activeTab, refreshData }) => {
  const classes = useStyles();

  const [finishUpload, setFinishUpload] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileDispatch = useUploadFileDispatch();
  const { uploadFiles } = useUploadFileState();

  const [messageCompletedFiles, setMessageCompletedFiles] = useState("");
  const [messageFailedFiles, setMessageFailedFiles] = useState("");
  const [messageFailedFilesList, setMessageFailedFilesList] = useState("");

  useEffect(() => {
    const inProgressFiles = uploadFiles.all?.filter(
      (item) => item.status === IN_PROGRESS
    );
    setFinishUpload(inProgressFiles?.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFiles.all, activeTab]);

  useEffect(() => {
    if (finishUpload) {
      handleFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishUpload]);

  const handleFinish = async () => {
    try {
      setIsLoading(true);
      setOpenMessage(true);

      if (refreshData) {
        refreshData();
      }

      const allFiles = uploadFiles.all || [];
      const totalCount = allFiles.length;
      const completedCount = allFiles.filter(
        (file) => file.status === COMPLETED
      ).length;
      const failedCount = allFiles.filter(
        (file) => file.status === FAILED
      ).length;

      setMessageCompletedFiles(
        completedCount > 0 ? ` ${completedCount}/${totalCount}` : ""
      );
      setMessageFailedFiles(
        failedCount > 0 ? ` ${failedCount}/${totalCount}` : ""
      );
      setMessageFailedFilesList(
        failedCount > 0
          ? allFiles
              .filter((file) => file.status === FAILED)
              .map((file) => file.display_name)
              .join("|")
          : ""
      );
    } catch (error) {
      logException(error, "Error finishing upload");
    } finally {
      setIsLoading(false);
    }
  };

  const closeMessageDialog = () => {
    changeUploadFileStatus(uploadFileDispatch, false);
    setOpenMessage(false);
  };

  if (!uploadFiles || !Array.isArray(uploadFiles.all))
    return <LoadingStateHorizontal isVisible />;

  return (
    <Box width="100%" style={{ marginTop: 15, marginBottom: 40 }}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {uploadFiles[activeTab].map((item, index) => (
              <TableRow
                key={item.id}
                className={classNames(
                  {
                    [classes.stripedRow]: index % 2 === 0,
                  },
                  classes.borderRow
                )}
              >
                <TableCell className={classes.imageCell}>
                  <Box className={classes.progressIconBox}>
                    {iconMap[item.mime_type]}
                  </Box>
                </TableCell>
                <TableCell className={classes.fullWidthCell}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      className={classes.progressBarFileName}
                      align="left"
                    >
                      {item.display_name}
                    </Typography>
                    {item.status !== COMPLETED && (
                      <Box>
                        <LinearProgress
                          className={classNames(classes.progressBar, {
                            [classes.progressBarFailed]: item.status === FAILED,
                          })}
                          variant="determinate"
                          color="secondary"
                          value={
                            typeof item.progress === "number" &&
                            item.progress >= 0 &&
                            item.progress <= 100
                              ? item.progress
                              : 0
                          }
                        />
                        {item.status === FAILED && (
                          <Box display="flex" alignItems="center" mt={1}>
                            <Box className={classes.centeredIconButton}>
                              {infoIcon()}
                            </Box>
                            <Box ml={1}>
                              <Typography
                                className={classes.progressBarMessage}
                              >
                                Upload failed: We're sorry, but your file could
                                not be uploaded.
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell className={classes.iconCell} pr={0}>
                  <Box display="flex" alignItems="center">
                    <Box>
                      {item.status === COMPLETED ? (
                        <IconButton>{checkCircle()}</IconButton>
                      ) : item.status === FAILED ? (
                        <IconButton>
                          <ErrorIcon className={classes.iconError} />
                        </IconButton>
                      ) : (
                        <IconButton>{radioButtonUnchecked()}</IconButton>
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MessageDialogResumeFiles
        open={openMessage}
        title={"File Upload completed"}
        successFiles={messageCompletedFiles}
        failedFiles={messageFailedFiles}
        listFailed={messageFailedFilesList}
        handleClose={closeMessageDialog}
        isLoadingData={isLoading}
      />
    </Box>
  );
};

export default UploadPartnerProgress;
