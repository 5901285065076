import Resizer from "react-image-file-resizer";

const resizeFile = (
  file,
  maxWidth = 800,
  maxHeight = 800,
  quality = 85,
  output = "file"
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      output
    );
  });

export default resizeFile;
