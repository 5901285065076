import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    maxWidth: "100%",
    maxHeight: "75%",
    "& table": {
      minWidth: 800,
    },
  },
  tableSchedule: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  scheduleBox: {
    height: 500,
    minWidth: 600,
    paddingLeft: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: "10px",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    maxHeight: "85%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 1200,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      marginRight: 70,
    },
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: 12,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      // left: 20,
      // top: 185,
      // position: "relative",
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
  },
  newIndicator1: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#0b417e",
  },
  newIndicator2: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#9cbb65",
  },
  newLabel1: {
    marginLeft: 5,
    fontSize: 16,
    color: "#0b417e",
  },
  newLabel2: {
    marginLeft: 5,
    fontSize: 16,
    color: "#9cbb65",
  },
  alertChip: {
    backgroundColor: "#dc0505",
    fontSize: 10,
    marginLeft: 3,
    position: "relative",
    "& span": {
      padding: 6,
    },
  },
  calendarToolbarHeader: {
    marginBottom: 5,
    "& .MuiGrid-container > div:first-child": {
      display: "none",
    },
    "& .MuiGrid-container .MuiSelect-select": {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 10,
      marginLeft: 40,
    },
  },
  calendarToolbarTitle: {
    textAlign: "center",
  },
  wrapperScheduleClose: {
    textAlign: "right",
  },
  wrapperScheduleProgress: {
    height: 18,
    "& > .MuiLinearProgress-root": {
      margin: 0,
    },
  },
  buttonPrimaryAction: {
    background: "#78c1ce !important",
    textTransform: "Capitalize",
    marginRight: 20,
  },
  eventSchedule: {
    backgroundColor: "#78c1ce !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleNotStarted: {
    backgroundColor: "#78c1ce !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleInProgress: {
    backgroundColor: "#efc945 !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleDone: {
    backgroundColor: "#9cbb65 !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleCancelled: {
    backgroundColor: "#f39a3e !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  scheduleViewContainer: {
    "&  .MuiListItem-root": {
      marginLeft: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 35,
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      fontSize: 14,
    },
  },
  scheduleViewTitle: {
    fontSize: 18,
  },
  scheduleViewDateTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  scheduleBadge: {
    width: 11,
    height: 11,
    backgroundColor: "#78c1ce",
    borderRadius: 2,
    position: "absolute",
    top: 12,
    left: 20,
  },
  scheduleViewOptions: {
    textAlign: "right",
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  viewAction: {
    textDecoration: "none",
  },
  viewButtonAction: {
    textTransform: "capitalize",
    marginBottom: 15,
  },
  buttonSchedule: {
    textTransform: "capitalize",
    marginRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  table: {
    minWidth: 650,
    "& th, & td": {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th:last-child, & td:last-child": {
      borderRight: 0,
    },
  },
  headerCellTitle: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
    textAlign: "center",
    color: "#4D4D4D",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  subHeaderCell: {
    color: "#747474",
    textAlign: "left",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  checkboxCell: {
    borderBottom: "none",
  },

  cellBorder: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  //New
  cellContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  goodIcon: {
    color: "#9CBB65",
    fontSize: 20,
  },
  badIcon: {
    color: "#F07A8C",
    fontSize: 20,
  },
  chips: {
    color: "#747474",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    marginRight: 4,
    marginTop: 2,
  },
  whiteChips: {
    backgroundColor: "#FFFFFF",
    color: "#747474",
    border: "1px solid #ECECEC",
  },
  selectStatus: {
    paddingTop: 2,
    paddingLeft: 10,
    fontSize: 12,
    borderRadius: 4,
    width: "auto",
    cursor: "pointer",
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  starColor: {
    color: "#EFC945",
  },
  popoverPaper: {
    maxWidth: 300,
    width: "auto",
    padding: theme.spacing(1),
  },
  rating: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ratingTable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  starsContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiRating-icon": {
      marginRight: (props) => props.spacing,
    },
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,
    marginTop: theme.spacing(1),
    padding: "0 55px",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
  labelText: {
    textAlign: "center",
    flex: 1,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  filterContainer: {
    padding: 20,
    backgroundColor: "white",
    width: "100%",
    minWidth: 400,
    maxWidth: 400,
    height: "auto",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: 20,
      width: "100%",
      minWidth: 375,
      maxWidth: 375,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      width: "100%",
      minWidth: 350,
      maxWidth: 350,
    },
  },
  subContainer: {
    overflowY: "auto",
    maxHeight: "60vh",
  },
  filterHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  headerTitle: {
    color: "#4D4D4D",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    margin: 0,
  },
  filterTitle: {},
  filterBtn: {
    textTransform: "none",
    color: "#4F98BC",
    backgroundColor: "#EDF5F8",
    border: "1px solid #EDF5F8",
    width: 100,
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 400,
    "& svg": {
      marginRight: 8,
      width: 16,
      top: -2,
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 5,
    },
  },
  badgeFilters: {
    backgroundColor: "#4F98BC",
    color: "#FFF",
    borderRadius: 10,
    padding: "0 5px",
    marginLeft: 8,
    fontSize: 11,
    width: 25,
    fontWeight: 600,
  },
  accordionContainer: {
    overflowY: "auto",
    maxHeight: "50vh",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  accordionRoot: {
    boxShadow: "none",
    border: "0px solid blue",
    position: "unset",
    borderWidth: 0,
    backgroundColor: "#F8F8F8",
    borderRadius: 0,
    marginBottom: 15,
    "&.MuiExpansionPanel-root:before": {
      display: "none",
      backgroundColor: "transparent",
    },
    "&.MuiIconButton-root": {
      position: "absolute",
      right: "0",
      top: "0",
    },
  },
  accordionSummary: {
    borderBottom: "none",
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    minHeight: 40,
    height: 40,
    padding: "0 16px",
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
  },
  accordionSummaryExpanded: {
    height: "30px !important",
    minHeight: "30px !important",
  },
  accordionDetails: {
    flexDirection: "column",
    background: "#F8F8F8",
    padding: "5px 16px 16px",
  },
  iconDown: {
    transform: "rotate(180deg)",
    transition: "transform 0.3s ease",
  },
  buttonCancel: {
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    textTransform: "capitalize",
    marginRight: 10,
    width: "100",
  },
  buttonApply: {
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    width: "100",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  containerButtonPartner: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
      marginTop: 10,
    },
  },
  buttonPartner: {
    color: "#fff",
    backgroundColor: "#4F98BC !important",
    border: "1px solid #4F98BC",
    fontSize: 14,
    fontStyle: "normal",
    textTransform: "Capitalize",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4F98BC",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      paddingLeft: 5,
      paddingRight: 5,
      "&:hover": {
        backgroundColor: "#4F98BC",
      },
    },
  },
  textField: {
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    background: "#FFF",
    marginBottom: 10,
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9",
      },
    },
  },
  inputField: {
    borderRadius: 4,
    border: "solid 1px #d6dde1 !important",
    fontSize: 14,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-inputMarginDense": {
      margin: 0,
    },
  },

  hideBorder: {},
  filterButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  toggleButton: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  activeButton: {
    color: "#4F98BC",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    background: "#EDF5F8",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  inactiveButton: {
    color: "#747474",
    borderRadius: 4,
    border: "1px solid #D9D9D9",
    background: "#FFF",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
  checkListContainer: {
    display: "flex",
    flexDirection: "column",
  },
  filterCheckbox: {
    color: "#D9D9D9",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
      marginLeft: 0,
      color: "#747474",
    },
  },
  checkbox: {
    marginLeft: 0,
    marginRight: 0,
  },
  //aca van los del drawer,
  drawerContent: {
    width: 400,
    display: "flex",
    flexDirection: "column",
  },
  headerSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
    padding: theme.spacing(2),
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  stepLabel: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
  },
  headerTextContainer: {
    marginTop: theme.spacing(2),
  },
  contentsContainer: {
    flex: 1,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: "#747474",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 400,
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
  },
  labelInputs: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    color: "#D9D9D9",
    border: "none",
    padding: 0,
    paddingRight: 5,
    paddingLeft: 10,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
      marginLeft: 0,
      color: "#747474",
    },
  },
  checkboxListContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 8,
  },
  fileUploadContainer: {
    border: "1px solid #D9D9D9",
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 50,
    marginRight: 50,
    maxWidth: 100,
    backgroundColor: "white",
    color: "#4F98BC",
    border: `1px solid #4F98BC`,
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: 600,
    fontStyle: "normal",
    "&:hover": {
      backgroundColor: "#e0f0f6",
    },
  },
  uploadIcon: {
    color: "#4F98BC",
  },
  checkboxLabel: {
    color: "#747474",
  },
  backButton: {
    border: "none",
    backgroundColor: "white",
    color: "#4F98BC",
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: "normal",
    paddingLeft: 0,
    "&:hover": {
      border: "none",
      backgroundColor: "white",
    },
  },
  formHelperText: {
    color: theme.palette.error.main,
  },
  formControl: {
    paddingBottom: theme.spacing(2),
  },
  labelStepTwo: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  customTooltip: {
    borderRadius: 4,
    border: "1px solid #ECECEC",
    backgroundColor: "#FFF",
    boxShadow: "1px 0px 15px 0px rgba(96, 96, 96, 0.16)",
    color: "#747474",
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "normal",
    padding: 12,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  largeIcon: {
    fontSize: 16,
  },
}));

export default useStyles;
