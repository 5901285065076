import React, { useState } from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import { updateQuotesBulk } from "../../../services/quoteService";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { useQuoteAction } from "../../../contexts/quoteActionContext";
import {
  useTableState,
  useTableDispatch
} from "../../../contexts/tableContext";
import FormInputDateAdo from "../../../components/ui/FormContent/formInputDateAdo";
import { numberToWord } from "../../../components/util/stringFormat";

import useStyles from "./styles";

const DueDateDialog = props => {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState(moment().add(30, "days"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [quoteAction, dispatchQuoteAction] = useQuoteAction();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openDueDate } = quoteAction;
  const { selected, rows } = tableUi;

  const handleClose = () => {
    dispatchQuoteAction({
      type: "TOGGLE_DUEDATE",
      open: false
    });
  };

  const handleConfirm = async () => {
    if (selected.length) {
      const data = {
        quotes_ids: selected,
        due_date: moment(dueDate).format("YYYY-MM-DD")
      };

      try {
        setIsLoadingDialog(true);
        const result = await updateQuotesBulk(data);
        if (result) {
          dispatchQuoteAction({
            type: "TOGGLE_DUEDATE",
            open: false
          });

          selected.map(item => {
            const foundIndex = rows.findIndex(row => row.id === item);
            if (foundIndex !== undefined) {
              rows[foundIndex].due_date = moment(dueDate).format("YYYY-MM-DD");
            }
            dispatchTable({ type: "UPDATE_ROWS", rows });
            dispatchTable({ type: "SET_SELECTED", selected: [] });
            return true;
          });

          dispatchTable({
            type: "SET_UPDATED_ROWS",
            rowsUpdated: selected
          });
          setTimeout(() => {
            dispatchTable({
              type: "SET_UPDATED_ROWS",
              rowsUpdated: []
            });
          }, 5000);
          setIsLoadingDialog(true);
          setOpenSuccess(true);
        }
      } catch (e) {
        console.log("Cannot update quotes due date.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openDueDate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuoteIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Quotes
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                When do you want to change the Due Date for these{" "}
                {numberToWord(selected.length)} ({selected.length}) quote
                {selected.length > 1 ? "s" : ""}?
              </Typography>
              <Box className={classes.formContainer}>
                <FormInputDateAdo
                  name="dueDate"
                  label="Due Date"
                  value={dueDate}
                  handleChange={setDueDate}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Change
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These quote${
          selected.length > 1 ? "s" : ""
        } due date have changed.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default DueDateDialog;
