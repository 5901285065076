import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const colorMargin = (value) => {
  let color = "#747474";
  if (!value) {
    return color;
  }
  if (value > 0) {
    color = "#9cbb65";
  } else if (value < 0) {
    color = "#F07A8C";
  }
  return color;
};

const colorMarginPct = (value) => {
  let color = "#747474";
  if (!value) {
    return color;
  }
  if (value >= 20) {
    color = "#9cbb65";
  } else if (value >= 1 && value < 20) {
    color = "#efc945";
  } else if (value < 0) {
    color = "#F07A8C";
  }
  return color;
};

const WorkticketFinancialField = (props) => {
  const classes = useStyles();
  const { field, type } = props;

  return (
    <Typography
      variant="caption"
      className={classes.label}
      style={{
        color:
          type === 1 ? `${colorMargin(field)}` : `${colorMarginPct(field)}`,
      }}
    >
      {field ? `${field}${type === 2 ? "%" : ""}` : "-"}
    </Typography>
  );
};

export default WorkticketFinancialField;
