import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";

const apiEndpoint = `${apiUrl}/qa/task`;

export function getTasksList(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}`, {});
}

export function getTasksListSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}search=${search}`, {});
}

export function getTasksListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}offset=${offset}`, {});
}

export function createTask(data) {
  http.setFormEncode();
  return http.post(`${apiEndpoint}`, data);
}

export function updateTask(id, data) {
  http.setFormEncode();
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function deleteTask(id) {
  return http.delete(`${apiEndpoint}/${id}`, {});
}

export function getTasks() {
  return http.get(`${apiEndpoint}s/all`, {});
}
