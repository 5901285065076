import React from "react";
import Box from "@material-ui/core/Box";
import ServiceTable from "./servicesTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ServiceProvider } from "contexts/projectServiceContext";
import useStyles from "./styles";

const ServicesList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <ServiceProvider>
        <ServiceTable />
      </ServiceProvider>
    </Box>
  );
};

export default withTableFilterContext(ServicesList);
