import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import ThreeDots from "../icons/ThreeDots";
import { ArrowUp } from "../icons/ArrowUp";
import { ArrowDown } from "../icons/ArrowDown";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Typography from "@material-ui/core/Typography";
import { AddLocationDialog } from "./AddLocationDialog";
import DeleteLocationDialogue from "./DeleteLocationDialogue";
import {
  getCatalogJobs,
  getCatalogCustomers,
  getPartnerJobs,
  removePartnerJobs,
} from "services/partners/locationsService";
import { logException } from "components/util/logUtil";
import useStyles from "../styles";

export function LocationPage({ id }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [anchorEls, setAnchorEls] = useState([]);
  const [catalogJobs, setCatalogJobs] = useState(null);
  const [catalogCustomers, setCatalogCustomers] = useState(null);
  const [partnerJobs, setPartnerJobs] = useState([]);
  const [partnerCustomers, setPartnerCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [openNewLocationDialog, setOpenNewLocationDialog] = useState(false);
  const [openDeleteLocationDialog, setOpenDeleteLocationDialog] =
    useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadFileData = async () => {
      setIsLoading(true);
      try {
        const [resultCatalogJobs, resultCatalogCustomers, result] =
          await Promise.all([
            getCatalogJobs(),
            getCatalogCustomers(),
            getPartnerJobs(id),
          ]);

        setCatalogJobs(resultCatalogJobs.data.data.jobs);
        setCatalogCustomers(resultCatalogCustomers.data.data.customers);
        setPartnerJobs(result.data.data.jobs);
        setPartnerCustomers(result.data.data.customers);
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load file data");
      }

      setIsLoading(false);
    };
    if (id) {
      loadFileData();
    }
  }, [id]);

  const refreshData = async () => {
    try {
      const result = await getPartnerJobs(id);
      setPartnerJobs(result.data.data.jobs);
      setPartnerCustomers(result.data.data.customers);
    } catch (e) {
      logException(e, "Cannot load file data");
    }
  };

  const handleNew = () => {
    setOpenNewLocationDialog(true);
  };

  const handleChange = (index) => () => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleClose = (index) => () => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleDeleteCustomer = async () => {
    try {
      setIsLoadingDelete(true);
      await removePartnerJobs(id, {
        customer_ids: [selectedCustomers.id],
      });
      refreshData();
      setIsLoadingDelete(false);
      setIsSuccessDelete(true);
    } catch (e) {
      logException(e, "Cannot delete customer");
    }
  };

  const handleDeleteJob = async (job_id) => {
    try {
      setPartnerJobs(partnerJobs.filter((job) => job.id !== job_id));
      await removePartnerJobs(id, {
        job_ids: [job_id],
      });
    } catch (e) {
      logException(e, "Cannot delete job");
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.zeroContainer}>
        <Typography className={classes.generalInformationSubHeader}>
          Locations
        </Typography>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Fragment>
      <Box className={classes.locationsTitleContainer}>
        <Typography className={classes.locationsSubHeader}>
          Locations
        </Typography>
        <Button onClick={handleNew} className={classes.addContactButton}>
          Add New
        </Button>
      </Box>
      <Box>
        {partnerCustomers?.map((customer, index) => (
          <Fragment key={index}>
            <Accordion
              elevation={0}
              key={index}
              classes={{ root: classes.locationAccordionRoot }}
              expanded={expanded[index]}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  className={`${classes.locationListMenu} ${
                    expanded[index] ? classes.locationListMenuExpanded : ""
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, index);
                  }}
                  disableRipple={true}
                  onFocus={(event) => event.stopPropagation()}
                >
                  <ThreeDots />
                </IconButton>
                {expanded[index] ? (
                  <Box className={classes.arrowDownLocation}>
                    <ArrowUp />
                  </Box>
                ) : (
                  <Box className={classes.arrowDownLocation}>
                    <ArrowDown />
                  </Box>
                )}

                <Box
                  style={{
                    overflow: "scroll",
                    textOverflow: "ellipsis",
                    width: "90%",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <Typography noWrap className={classes.locationBadge}>
                    {customer.name}
                  </Typography>
                </Box>
              </AccordionSummary>
              <Menu
                id="simple-menu"
                anchorEl={anchorEls[index]}
                keepMounted
                open={Boolean(anchorEls[index])}
                onClose={handleClose(index)}
              >
                <MenuItem
                  onClick={(event) => {
                    setOpenDeleteLocationDialog(true);
                    setSelectedCustomers(customer);
                    setAnchorEls(
                      anchorEls.map((el, i) => (i === index ? null : el))
                    );
                  }}
                >
                  <Box className={classes.menuIcon}>
                    {" "}
                    <DeleteBin style={{ marginRight: 8 }} />
                  </Box>
                  <Typography variant="caption" className={classes.menuText}>
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
              <AccordionDetails
                className={classes.accordionInnerDetailsContainer}
              >
                {partnerJobs
                  .filter((item) => item.customer_id === customer.id)
                  .map((job, index) => (
                    <Box key={index} className={classes.locationJobBadge}>
                      {job.job_number} - {job.job_description}
                      <IconButton onClick={() => handleDeleteJob(job.id)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))}
      </Box>
      <DeleteLocationDialogue
        open={openDeleteLocationDialog}
        isLoadingOpen={isLoadingDelete}
        isSuccessOpen={isSuccessDelete}
        handleDelete={() => {
          handleDeleteCustomer();
        }}
        handleDeleteClose={() => {
          setOpenDeleteLocationDialog(false);
        }}
        handleSuccessClose={() => {
          setIsSuccessDelete(false);
        }}
      />
      <AddLocationDialog
        id={id}
        open={openNewLocationDialog}
        handleClose={() => setOpenNewLocationDialog(false)}
        catalogJobs={catalogJobs}
        catalogCustomers={catalogCustomers}
        refreshData={refreshData}
      />
    </Fragment>
  );
}
