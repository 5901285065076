import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ButtonBase } from "components/ui/core/button";
import Skeleton from "@material-ui/lab/Skeleton";
import WorkticketTimekeepingClockFM from "./workticketTimekeepingClockFM";
import WorkticketTimekeepingHistoryInline from "./workticketTimekeepingHistoryInline";
import { useWorkticketView } from "contexts/workticketViewContext";
import GlobalUiContext from "contexts/globalUiContext";
import WorkticketTimekeepingHistory from "./workticketTimekeepingHistory";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const WorkticketTimekeepingFM = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [openTimekeepingReport, setOpenTimekeepingReport] = useState(false);

  const { isLoading } = stateContext ?? null;

  const handleOpenTimekeepingReport = () => {
    setOpenTimekeepingReport(true);
  };

  const handleCloseTimekeepingReport = () => {
    setOpenTimekeepingReport(false);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={100}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
    );
  }

  return (
    <Box className={classes.containerTimekeeping}>
      <Typography
        variant="h4"
        className={classes.titleSectionBody}
        gutterBottom
      >
        Timekeeping
      </Typography>
      <Box className={classes.containerTimekeepingClock}>
        <WorkticketTimekeepingClockFM />
      </Box>
      {hasPermission(permissionWorkticket.VIEW_TIMEKEEPING, permissions) ? (
        <WorkticketTimekeepingHistoryInline />
      ) : null}
      <Grid container spacing={2}>
        {hasPermission(permissionWorkticket.VIEW_TIMEKEEPING, permissions) && (
          <Grid item md={6} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenTimekeepingReport}
            >
              History
            </ButtonBase>
          </Grid>
        )}
      </Grid>
      <WorkticketTimekeepingHistory
        open={openTimekeepingReport}
        handleClose={handleCloseTimekeepingReport}
        restrictModify={false}
      />
    </Box>
  );
};

export default WorkticketTimekeepingFM;
