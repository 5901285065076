import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory, withRouter } from "react-router-dom";
import { deleteOpenPositionById } from "services/openPositionsService";
import DeleteDialog from "./deleteConfirmationDialog";
import { permissionOpenPositions, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

const options = [
  { id: 1, label: "View", value: "VIEW", user: false },
  { id: 2, label: "Change Status", value: "Change Status", user: true },
  { id: 3, label: "Delete", value: "DELETE", user: true },
];

const OpenPositionsTableActions = (props) => {
  const { row, mobile } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const optionsWithPermissions = [];

  optionsWithPermissions.push(options[0]);

  if (hasPermission(permissionOpenPositions.UPDATE, permissions)) {
    optionsWithPermissions.push(options[1]);
  }

  if (hasPermission(permissionOpenPositions.DELETE, permissions)) {
    optionsWithPermissions.push(options[2]);
  }

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDel, setOpen] = React.useState(false);
  const handleCloseDelDialog = () => {
    setOpen(false);
    deleteOpenPositionById(row?.id).then((res) => {
      if (res?.status === 201) {
        props.setRefresh(!props.refresh);
      }
    });
  };

  return (
    <div>
      <DeleteDialog
        openDeleteDialog={openDel}
        handleClose={handleCloseDelDialog}
        setOpen={setOpen}
      />
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {optionsWithPermissions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              if (option.id === 1) {
                history.push(`/open-position/${row?.id}`);
              }
              if (option.id === 2) {
                props.setSelectedRowData(row);
                props.setStatusModal(true);
              }
              if (option.id === 3) {
                setOpen(true);
              }
            }}
            data={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withRouter(OpenPositionsTableActions);
