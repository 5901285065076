import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = `${apiUrl}/services/categories`;

export function getCategories() {
  return http.get(`${apiEndpoint}/`);
}

export function getCategoriesCustomers(customer_id) {
  return http.get(`${apiEndpoint}?customer_id=${customer_id}`);
}

export function getCategoriesAdmin(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/admin?${filterString}`);
}

export function updateCategory(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function createCategory(data) {
  return http.post(`${apiEndpoint}`, data);
}

export function deleteCategory(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
