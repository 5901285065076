import React, { useEffect, useContext } from "react";
import * as classNames from "classnames";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { quotesStatus, quoteTypeOptions } from "../../constants";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import IconLink from "@material-ui/icons/ArrowForward";
import FormInput from "../../components/ui/FormContent/formInput";
import FormSelect from "../../components/ui/FormContent/formSelect";
import Skeleton from "@material-ui/lab/Skeleton";
import * as serviceQuote from "../../services/quoteService";
import ConfirmDialog from "./dialog/confirmDialog";
import { Link } from "react-router-dom";
import FormInputDateAdo from "../../components/ui/FormContent/formInputDateAdo";
import {
  getProjectsUser,
  getProjectDetails,
} from "../../services/projectService";

import RedirectDialog from "../../components/ui/dialog/redirectDialog";
import FormSelectAuto from "../../components/ui/FormContent/formSelectAuto";
import GlobalUiContext from "../../contexts/globalUiContext";
import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "../../contexts/pageHeaderContext";

import {
  useQuoteState,
  useQuoteDispatch,
  getQuote,
  getQuotePlan,
  updateQuote,
} from "../../contexts/quoteContext";
import QuoteDescriptionHelp from "./quoteDescriptionHelp";
import { convertDateFormatField } from "components/util/timeFormat";

import { permissionQuote, hasPermission } from "../../lib/permissions";

import useStyles from "./styles";

const InitialNewData = {
  customer_id: -1,
  job_number: null,
  project_id: null,
  type: 0,
  subject: "",
  description: "",
  due_date: moment().add(30, "days"),
};

const QuoteDetail = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const { pendingPlanning, cloned } = props;
  const { selectedQuote, quoteDetails, isLoading } = useQuoteState();
  const dispatch = useQuoteDispatch();
  const [newData, setNewData] = React.useState(InitialNewData);
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();
  const [msgRedirect, setMsgRedirect] = React.useState("");
  const [linkRedirect, setLinkRedirect] = React.useState("");
  const [labelRedirect, setLabelRedirect] = React.useState("");
  const [openRedirect, setOpenRedirect] = React.useState(false);
  const [projectsOptExisting, setProjectsOptExisting] = React.useState([]);
  const [project, setProject] = React.useState(null);
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const hasViewProject = hasPermission(
    permissionQuote.VIEW_PROJECT,
    permissions
  );
  const [messageCreate, setMessageCreate] = React.useState("");
  const [openCreate, setOpenCreate] = React.useState(false);
  const [projectExistingSelected, setProjectExistingSelected] = React.useState(
    []
  );
  const { quoteId } = props;

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_QUOTE",
      selectedQuote: quoteId,
    });
  }, [dispatch, quoteId]);

  useEffect(() => {
    const loadProjects = async () => {
      const result = await getProjectsUser();
      const { projects } = result.data.data;
      const filterProject = projects.filter(
        (pro) => pro.type !== 0 && !pro.has_completed_worktickets
      );
      const resultForRender = filterProject.map((project) => {
        return {
          value: project.id,
          label: `${project.title}`,
        };
      });
      setProjectsOptExisting([...resultForRender]);
    };
    if (!projectsOptExisting.length) {
      loadProjects();
    }
  }, [projectsOptExisting]);

  useEffect(() => {
    if ([2, 5, 6].includes(project?.type) && newData.type === 0)
      newData.type = 1;
    setNewData(newData);
  }, [project, newData.type]);

  const handleChangeProjectExisting = (event, value) => {
    const updateProject = async (projectId) => {
      const data = { project_id: projectId };
      try {
        const result = await serviceQuote.changeQuoteProject(
          quoteDetails.id,
          data
        );
        quoteDetails.job = result.data.data.quote.job;
        quoteDetails.customer = result.data.data.quote.customer;
        dispatch({
          type: "SET_QUOTE_DETAILS",
          quoteDetails,
        });
      } catch (e) {
        console.log("Cannot update project quote");
      }
    };
    setProjectExistingSelected(value);
    if (value) {
      updateProject(value.value);
    }
  };

  useEffect(() => {
    if (quoteDetails.project) {
      setProjectExistingSelected({
        value: quoteDetails.project.id,
        label: `${quoteDetails.project.title}`,
      });
    }
  }, [quoteDetails.project]);

  useEffect(() => {
    if (selectedQuote) {
      if (selectedQuote !== "new") {
        const fetchData = async () => {
          await getQuote(dispatch, selectedQuote);
          return dispatch({ type: "SET_LOADING", isLoading: false });
        };
        const fetchDataPlan = async () => {
          await getQuotePlan(dispatch, selectedQuote);
          return dispatch({ type: "SET_LOADING", isLoading: false });
        };

        if (!pendingPlanning) fetchData();
        else fetchDataPlan();
      } else {
        const loadProject = async () => {
          const resultProject = await getProjectDetails(props.idProject);
          setProject(resultProject.data.data.project);
        };
        if (props.idProject) {
          loadProject();
        }
        return dispatch({ type: "SET_LOADING", isLoading: true });
      }
    }
  }, [dispatch, selectedQuote]);

  const handleChangeStatus = async (event) => {
    if (quoteDetails.status !== event.value) {
      try {
        quoteDetails.status = event.value;
        dispatch({
          type: "SET_QUOTE_DETAILS",
          quoteDetails,
        });
        updateQuote(dispatch, selectedQuote, quoteDetails);
      } catch (e) {
        console.log("Cannot update quote status");
      }
    }
  };

  const handleBlur = async (event) => {
    if (quoteDetails[event.name] !== event.value) {
      try {
        quoteDetails[event.name] = event.value;
        dispatch({
          type: "SET_QUOTE_DETAILS",
          quoteDetails,
        });
        updateQuote(dispatch, selectedQuote, quoteDetails);
      } catch (e) {
        console.log("Cannot update quote");
      }
    }
  };

  const handleCreateBlur = async (event) => {
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleCreateDueDate = async (event) => {
    const data = {
      ...newData,
      due_date: event,
    };
    setNewData(data);
  };

  const closeCreate = () => {
    setOpenCreate(false);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
    if (newData.type === 0) {
      setMessageCreate(
        "This is a one time quote that will bill the customer when approved, and all the work tickets associated are marked as verified. Do you want to continue?"
      );
    } else if (newData.type === 1) {
      setMessageCreate(
        "This is a one-time quote that will bill the customer when the quote is approved, and worktickets are created. Do you want to continue?"
      );
    } else if (newData.type === 2) {
      setMessageCreate(
        "This is a recurring quote, in which you can set one automated recurrence for the entire quote. Do you want to continue?"
      );
    } else if (newData.type === 3) {
      setMessageCreate(
        "This is a recurring quote, in which you can set automated and different recurrences for each quote item. Do you want to continue?"
      );
    } else if (newData.type === 5) {
      setMessageCreate(
        "This is a one time quote that will bill the customer when approved, and all the work tickets associated are marked as verified. Do you want to continue?"
      );
    }
  };

  const handleCloseRedirect = () => {
    setOpenRedirect(false);
  };

  const handleCreate = async () => {
    setOpenCreate(false);
    const sendData = {
      ...newData,
      start_date: newData.due_date.format("YYYY-MM-DD"),
      summary: newData.subject,
      project_id: project.id,
      job_number: project.job.job_number,
      customer_id: project.cutomer_id,
      is_draft: true,
    };
    try {
      const result = await serviceQuote.createQuote(sendData);
      setMsgRedirect("Quote has been started as Draft.");
      setLabelRedirect("Okay");
      setLinkRedirect(`/quote/${result.data.data.quote.id}`);
      setOpenRedirect(true);
    } catch (e) {
      console.log("Cannot create quote", e);
    }
  };

  if ((isLoading && selectedQuote !== "new") || !selectedQuote) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  const handleSaveDraft = () => {
    const { history } = props;
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [
        ...pageHeaderTabs.filter(
          (page) => page.link !== window.location.pathname
        ),
      ],
    });
    history.push(`/quotes`);
  };

  return selectedQuote === "new" ? (
    <Box className={classes.containerMainInformation}>
      <Box className={classes.containerInformation}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="create_job_number"
          label="Job"
          internal={false}
          value={
            project
              ? `${project.job.job_number} - ${project.job.job_description} `
              : ``
          }
          readonly={true}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="subject"
          label="Summary"
          internal={false}
          value={""}
          handleBlur={handleCreateBlur}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="description"
          label="Description"
          internal={false}
          value={""}
          multiline={true}
          rows={4}
          handleBlur={handleCreateBlur}
        />
        <FormInputDateAdo
          name="due_date"
          label="Due Date"
          handleChange={handleCreateDueDate}
          value={newData.due_date}
        />
        <FormSelect
          gridSizes={[{ size: "md", val: 12 }]}
          name="type"
          label="Type"
          options={quoteTypeOptions.filter((item) =>
            item.projectType.includes(project?.type)
          )}
          internal={true}
          value={newData.type}
          handleBlur={handleCreateBlur}
        />
        <QuoteDescriptionHelp quoteType={newData.type} />
      </Box>
      <Box className={classes.containerInformationActions}>
        <Grid container spacing={1} justify="space-around" alignItems="center">
          <Grid xs={12} sm={12} md={5}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={true}
              className={classNames(classes.button, classes.buttonOutlined)}
              onClick={handleSaveDraft}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={
                [1, 5].includes(newData.type) ? handleCreate : handleOpenCreate
              }
              disabled={!project}
              fullWidth={true}
              className={classNames(classes.button, classes.buttonAdd)}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <RedirectDialog
        open={openRedirect}
        hasClose={false}
        handleClose={handleCloseRedirect}
        title="Success"
        message={msgRedirect}
        redirectLabel={labelRedirect}
        redirect={linkRedirect}
      />
      <ConfirmDialog
        open={openCreate}
        handleConfirm={handleCreate}
        handleClose={closeCreate}
        message={messageCreate}
        quoteId={"Quote Creation"}
      />
    </Box>
  ) : (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="id"
            label="Quote Number"
            internal={true}
            readonly={true}
            value={`${quoteDetails.number}`}
          />
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="status"
            label="Status"
            options={quotesStatus}
            internal={true}
            readonly={true}
            value={quoteDetails.status}
            handleBlur={handleChangeStatus}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="customer"
            label="Customer"
            internal={true}
            readonly={true}
            value={quoteDetails.customer ? quoteDetails.customer.name : ""}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            readonly={!hasEdit}
            internal={true}
            value={quoteDetails.subject}
            handleBlur={handleBlur}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            internal={true}
            readonly={!hasEdit}
            value={quoteDetails.description}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
          />
          {quoteDetails.is_completed ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="completed_date"
              label="Completed Date"
              value={convertDateFormatField(quoteDetails.completed_date)}
              readonly={true}
            />
          ) : null}
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="type"
            label="Type"
            options={quoteTypeOptions}
            internal={true}
            readonly={true}
            value={quoteDetails.type}
            handleBlur={handleCreateBlur}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job"
            label="Job"
            internal={true}
            value={quoteDetails.job ? quoteDetails.job.job_description : ""}
            readonly={true}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Job Number"
            internal={true}
            value={quoteDetails.job ? quoteDetails.job.job_number : ""}
            readonly={true}
          />
          {quoteDetails.project && quoteDetails.project.external_woid && (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="external_woid"
              label="WOID"
              internal={true}
              value={quoteDetails.project.external_woid}
              readonly={true}
            />
          )}
          {cloned ? (
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={projectsOptExisting}
              name="project_existing"
              value={projectExistingSelected}
              label="Project"
              internal={true}
              handleChange={handleChangeProjectExisting}
            />
          ) : quoteDetails.project && hasViewProject ? (
            quoteDetails.project ? (
              <>
                <InputLabel className={classes.inputLabel}>Project</InputLabel>
                <Typography className={classes.linkTipo}>
                  <Link
                    to={`/project/${quoteDetails.project.id}`}
                    className={classes.linkLabel}
                  >
                    {quoteDetails.project.title}
                    <IconLink className={classes.linkIcon} />
                  </Link>
                </Typography>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="created"
            label="Created By"
            internal={true}
            value={`${quoteDetails.user.first_name} ${quoteDetails.user.last_name}`}
            readonly={true}
          />
        </Box>
      </Box>
    </>
  );
};
export default withRouter(QuoteDetail);
