import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { XIcon } from "../icons/XIcon";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { UserCircle } from "../icons/UserCircle";
import FormInput from "../../../components/ui/FormContent/formInput";
import Button from "@material-ui/core/Button";

export function StepOneComponent({
  toggleDrawer,
  resetData,
  classes,
  isEditing,
  isUserLoading,
  data,
  handleBlur,
  handleBlurConfirmPassword,
  confirmPasswordErrors,
  submitPassword,
  handleFileChange,
  selectedFile,
  originalImage,
  errors,
  isViewOnly,
}) {
  return (
    <Fragment>
      <Box className={classes.drawerTitleContainer}>
        <Box
          onClick={() => {
            toggleDrawer("right", false, false, null);
            resetData();
          }}
          className={classes.drawerCloseIcon}
        >
          <XIcon className={classes.drawerCloseIcon} />
        </Box>
        <Box className={classes.userDrawerStepCount}>
          <Typography className={classes.userDrawerStepCountText}>
            Step 1 of 2
          </Typography>
        </Box>
        <Typography className={classes.drawerTitle}>
          General Information
        </Typography>
        {isViewOnly === false && (
          <Typography className={classes.drawerInfoText}>
            Tell us more about the user you’re{" "}
            {isEditing ? "editing" : "creating"}.
          </Typography>
        )}
      </Box>
      {isUserLoading && <LoadingIndicator />}
      {!isUserLoading && (
        <Box className={classes.drawerFormContainer}>
          {isEditing ? (
            <>
              <input
                hidden
                type={"file"}
                onChange={handleFileChange}
                readOnly={isViewOnly}
                accept="image/*"
                disabled={isViewOnly}
                style={{ display: "none" }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Box className={classes.changePhotoContainer}>
                  {originalImage && (
                    <Fragment>
                      <img
                        className={classes.uploadPhotoImage}
                        src={originalImage}
                        alt={"profile"}
                      />
                    </Fragment>
                  )}
                  {!originalImage && !selectedFile && <UserCircle />}
                  {selectedFile && (
                    <Fragment>
                      <img
                        className={classes.uploadPhotoImage}
                        src={URL.createObjectURL(selectedFile)}
                        alt={"profile"}
                      />
                    </Fragment>
                  )}
                  {isViewOnly === false && (
                    <Typography className={classes.uploadPhotoText}>
                      Change Photo
                    </Typography>
                  )}
                </Box>
              </label>
            </>
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="first_name"
            label="First Name *"
            value={data.first_name}
            error={errors}
            data={data.first_name}
            handleBlur={handleBlur}
            placeholder="Write a Name"
            readOnly={isViewOnly}
            styleOverride={{ minWidth: "114px" }}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="last_name"
            label="Last Name *"
            error={errors}
            data={data.last_name}
            value={data.last_name}
            handleBlur={handleBlur}
            placeholder="Write a Last Name"
            readOnly={isViewOnly}
            styleOverride={{ minWidth: "114px" }}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="email"
            type="email"
            label="Email *"
            error={errors}
            data={data.email}
            value={data.email}
            handleBlur={handleBlur}
            placeholder="Write an Email"
            readOnly={!!isEditing}
            styleOverride={{ minWidth: "114px" }}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="phone"
            type="text"
            error={errors}
            label="Phone Number *"
            data={data.phone}
            value={data.phone}
            handleBlur={handleBlur}
            placeholder="Write a Phone Number"
            readOnly={isViewOnly}
            styleOverride={{ minWidth: "114px" }}
          />
          {!isEditing && (
            <>
              <input
                hidden
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Box className={classes.uploadPhotoContainer}>
                  {selectedFile ? (
                    <img
                      className={classes.uploadPhotoImage}
                      src={URL.createObjectURL(selectedFile)}
                      alt="profile"
                    />
                  ) : (
                    <UserCircle />
                  )}
                  <Typography className={classes.uploadPhotoText}>
                    Upload Photo
                  </Typography>
                </Box>
              </label>
            </>
          )}
          {isEditing ? (
            <Box className={classes.changePassContainer}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="password"
                handleBlur={handleBlur}
                data={data.password}
                value={data.password}
                readOnly={isViewOnly}
                label="Password"
                type="password"
                placeholder="********"
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="confirm_password"
                label="Confirm Password"
                handleBlur={handleBlurConfirmPassword}
                type="password"
                data={data.confirm_password}
                readOnly={isViewOnly}
                value={data.confirm_password}
                error={confirmPasswordErrors}
                placeholder="********"
              />
              {isViewOnly === false && (
                <Box className={classes.addNewCoverageButtonWrapper}>
                  <Button
                    disabled={confirmPasswordErrors.length > 0}
                    onClick={submitPassword}
                    className={classes.changePassWordButton}
                  >
                    Change Password
                  </Button>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      )}
    </Fragment>
  );
}
