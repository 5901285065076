import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootLayout: {
    height: "100%",
  },
  gridItemLayout: {
    height: "100%",
  },
  gridDividerLayout: {
    borderRight: "1px solid #f8f8f8",
    paddingRight: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    height: "90%",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: "10px",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    height: "93vh",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: "100%",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      height: "85vh",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  pageBodyWrapper: {
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    position: "relative",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "auto",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 20,
    position: "absolute",
    zIndex: 2,
    marginTop: 8,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      left: 20,
      top: 75,
    },
  },
  tabItem: {
    display: "block",
  },
  iconQuestionDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  rootUpcoming: {
    height: "30%",
    padding: "0 0 0 20px",
    display: "flex",
    width: "100%",
  },
  containerUpcoming: {
    width: "100%",
    height: "100%",
  },
  containerUpcomingHeader: {
    height: 30,
  },
  containerUpcomingBody: {
    height: "90%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
  },
  listUpcoming: {
    overflowY: "auto",
  },
  itemUpcomingDateLabel: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "100%",
    textAlign: "center",
  },
  itemUpcomingText: {
    border: "solid 1px #f8f8f8",
    padding: "8px 5px 8px 5px",
    borderRadius: 2,
    "& span": {
      fontSize: 14,
    },
    "& .MuiListItemText-secondary": {
      fontSize: 11,
      color: "#747474",
    },
  },
  titleSectionBody: {
    fontSize: "18px",
  },
  containerInformation: {
    minHeight: 200,
    maxHeight: 240,
    overflow: "auto",
    marginTop: 10,
  },
  containerInformationList: {
    padding: 5,
    marginBottom: 3,
    border: "solid 1px #f8f8f8",
  },
  containerInformationItemHeader: {
    padding: 0,
    minWidth: "25px",
    margin: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
    marginBottom: "6px",
  },
  containerInformationItem: {
    padding: 0,
    minWidth: "25px",
    margin: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
    marginBottom: "16px",
  },
  containerInformationItem2: {
    padding: 0,
    minWidth: "25px",
    margin: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
    background: "#f6f6f6",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  containerInformationItem3: {
    padding: 0,
    minWidth: "25px",
    margin: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  containerInformationItemIcon: {
    minWidth: "30px",
  },
  containerInformationItemText: {
    fontSize: "14px",
  },
  containerInformationItemTextBold: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  containerInformationItemTextC: {
    minWidth: "300px",
    maxWidth: "300px",
  },
  containerInformationItemTextA: {
    minWidth: "100px",
  },
  containerInformationItemButton: {
    padding: 0,
    margin: 0,
    right: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
  },
  containerInformationItemTextHeader: {
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  containerInformationAction: {
    width: "100%",
    textAlign: "center",
  },
  buttonViewAll: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    color: "#4f98bc",
    width: "100%",
    fontSize: 14,
  },
  buttonView: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    color: "#4f98bc",
    fontSize: 14,
    height: 20,
    background: "#ffffff",
    padding: 0,
    "& .MuiButton-label": {
      lineHeight: "100%",
      position: "relative",
    },
  },
  dialogHeader: {
    marginTop: 10,
    marginBottom: 20,
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitleBody: {
    fontSize: 18,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  iconHeader: {
    marginRight: 5,
    position: "relative",
    top: 3,
  },
  containerBodyList: {
    marginTop: 30,
  },
  wrapperAssetInformationClose: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  activityTabRoot: {
    marginBottom: 10,
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerBodyWTList: {
    paddingLeft: 50,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOption2: {
    color: "#4f98bc",
    width: "200px",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  centerLoadingSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    margin: 0,
    padding: 0,
    height: "80vh",
    overflowY: "hidden",
  },
}));

export default useStyles;
