import React, { useState, useContext, useMemo } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import RatingDialog from "./dialog/ratingDialog";
import WorkticketRatingDetail from "./workticketRatingDetail";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const WorkticketRating = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [isOpenRating, setIsOpenRating] = useState(false);
  const [stateContext, dispatchContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  const canEdit = useMemo(
    () => hasPermission(permissionWorkticket.EDIT_RATING, permissions),
    [permissionWorkticket.EDIT_RATING, permissions]
  );

  const canCreate = useMemo(
    () => hasPermission(permissionWorkticket.RATE, permissions),
    [permissionWorkticket.RATE, permissions]
  );

  const handleOpenRating = () => {
    setIsOpenRating(true);
  };

  const handleCloseRating = (wt) => {
    setIsOpenRating(false);
    if (wt) {
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: {
          ...workticket,
          rated_at: wt.rated_at,
          rated_by: wt.rated_by,
          rated_score: wt.rated_score,
          rated_comment: wt.rated_comment,
          rated_obo_first_name: wt.rated_obo_first_name,
          rated_obo_last_name: wt.rated_obo_last_name,
          rated_obo_title: wt.rated_obo_title,
        },
      });
    }
  };

  const manageEdit = () => {
    setIsOpenRating(true);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return (
    <>
      {workticket?.rated_score ? (
        <WorkticketRatingDetail
          ratedScore={parseInt(workticket.rated_score)}
          fileUrl={
            workticket?.rated_obo_first_name
              ? ""
              : workticket?.rated_by?.profile?.picture?.file_url
          }
          firstName={
            workticket?.rated_obo_first_name ?? workticket?.rated_by?.first_name
          }
          lastName={
            workticket?.rated_obo_first_name
              ? workticket?.rated_obo_last_name ?? ""
              : workticket?.rated_by?.last_name
          }
          ratedAt={workticket?.rated_at}
          ratedComment={workticket?.rated_comment}
          manageEdit={manageEdit}
          canEdit={canEdit}
        />
      ) : canCreate || canEdit ? (
        <Box className={classes.containerRating}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <ButtonBase
                color="primary"
                variant="contained"
                fullWidth={true}
                onClick={handleOpenRating}
                className={classNames(classes.button, classes.buttonRating)}
                disableElevation
              >
                Rate Service
              </ButtonBase>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <RatingDialog
        open={isOpenRating}
        handleClose={handleCloseRating}
        id={workticket?.id}
        initialScore={workticket?.rated_score}
        initialComment={workticket?.rated_comment}
        oboFields={{
          first_name: workticket?.rated_obo_first_name,
          last_name: workticket?.rated_obo_last_name,
          title: workticket?.rated_obo_title,
        }}
      />
    </>
  );
};

export default WorkticketRating;
