import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import FormContent from "components/ui/FormContent/formBody";
import { Typography } from "@material-ui/core";
import RequestActionNav from "./requestActionNav";
import StepNav from "./stepNav";
import Joi from "joi-browser";

import { useStateValue } from "components/util/state";
import { errorList } from "components/util/error";

import useStyles from "./styles";

const schema = Joi.object({
  job_number: Joi.alternatives(Joi.string(), Joi.number())
    .required()
    .label("Location"),
  job_department_id: Joi.alternatives(Joi.string(), Joi.number())
    .allow("")
    .optional()
    .label("Department"),
  job_zone_id: Joi.alternatives(Joi.string(), Joi.number())
    .allow("")
    .optional()
    .label("Zone"),
  nearest_location: Joi.string().allow("").optional().label("Area"),
  first_name: Joi.string().required().label("First Name"),
  last_name: Joi.string().required().label("Last Name"),
  email: Joi.string().email().required().label("Email"),
});

const RequestStep1 = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({});
  const [stepComplete, setStepComplete] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [error, setError] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [{ add, completed, step, list, company }, dispatch] = useStateValue();

  useEffect(() => {
    const loadData = () => {
      setJobList(
        company.jobs.map((job) => {
          return { value: job.job_number, label: job.job_description };
        })
      );
      // setDepartmentList(
      //   company.departments.map((department) => {
      //     return { value: department.id, label: department.name };
      //   })
      // );

      setZoneList(
        company.zones.map((zone) => {
          return { value: zone.id, label: zone.name };
        })
      );
    };
    if (company) {
      loadData();
    }
  }, [company]);

  useEffect(() => {
    if (add || completed) {
      setFormState({});
      setError([]);
      setHasError(false);
    }
  }, [add, completed]);

  useEffect(() => {
    const errorResult = errorList(schema, formState);
    if (hasError) {
      if (errorResult.length > 0) {
        setError(errorResult);
      } else {
        setError([]);
      }
    }
    if (errorResult.length === 0) {
      setStepComplete(true);
    }
  }, [formState, hasError]);

  const handleOnClick = () => {
    const errorResult = errorList(schema, formState);
    if (errorResult.length > 0) {
      setError(errorResult);
      setHasError(true);
      return false;
    }

    dispatch({
      type: "UPDATE_DATA",
      updateData: formState,
      newStep: 2,
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 0,
    });
  };

  const handleBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  const handleJobBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
    // setDepartmentList(
    //   company.departments
    //     .filter((item) => Number(item.job_number) === Number(event.value))
    //     .map((department) => {
    //       return { value: department.id, label: department.name };
    //     })
    // );
    setZoneList(
      company.zones
        .filter((item) => Number(item.job_number) === Number(event.value))
        .map((zone) => {
          return { value: zone.id, label: zone.name };
        })
    );
  };

  const validateOnType = (event) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  if (step !== 1) return null;
  return (
    <>
      <div className={classes.headerContainer}>
        <StepNav step={step} list={list} />
        <Typography
          variant="h3"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Request Details
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Tell us more about the request you are submitting.
        </Typography>
      </div>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.stepContainer}>
          <Box className={classes.formContainer}>
            {jobList.length ? (
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="job_number"
                label="Location*"
                options={jobList}
                value={formState.job_number ?? ""}
                handleBlur={handleJobBlur}
                error={error}
              />
            ) : null}
            {departmentList.length && formState?.job_number ? (
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="job_department_id"
                label="Department"
                options={departmentList}
                value={formState.job_department_id ?? ""}
                handleBlur={handleBlur}
              />
            ) : null}
            {zoneList.length && formState?.job_number ? (
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="job_zone_id"
                label="Zone"
                options={zoneList}
                value={formState.job_zone_id ?? ""}
                handleBlur={handleBlur}
              />
            ) : null}
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="nearest_location"
              label="Area"
              value={formState.nearest_location ?? ""}
              validateOnType={validateOnType}
              error={error}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="first_name"
                  label="First Name"
                  value={formState.first_name}
                  validateOnType={validateOnType}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="last_name"
                  label="Last Name"
                  value={formState.last_name}
                  validateOnType={validateOnType}
                  error={error}
                />
              </Grid>
            </Grid>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="email"
              label="Email *"
              value={formState.email}
              validateOnType={validateOnType}
              error={error}
            />
            <FormContent>
              <i>* The contact person will be updated on progress via email.</i>
            </FormContent>
          </Box>
          <RequestActionNav
            handleOnClickBack={handleOnClickBack}
            handleOnClick={handleOnClick}
            stepComplete={stepComplete}
          />
        </div>
      </Container>
    </>
  );
};

export default RequestStep1;
