import React, { forwardRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import BackIcon from "@material-ui/icons/NavigateBefore";
import * as classNames from "classnames";
import Dropdown from "./dropdown";
import useStyles from "./styles";
import SurveyChip from "./surveyChip";
import SurveyComment from "./surveyComment";
import SurveyFile from "./surveyFile";

const QuesArray = [
  {
    type: "type 1",
    quesNo: 1,
    surveyName: "Survey Name Goes Here",
    status: "In Progress",
    lang: "eng",
    quesName: "Question Name goes here - Rating with No Tasks",
    toolTip: "Sub tool tip description",
  },
  {
    type: "type 2",
    quesNo: 2,
    surveyName: "Survey Name Goes Here",
    status: "In Progress",
    lang: "eng",
    quesName: "Question Name goes here - Rating with No Tasks",
    toolTip: "Sub tool tip description",
  },
  {
    type: "type 3",
    quesNo: 3,
    surveyName: "Survey Name Goes Here",
    status: "In Progress",
    lang: "eng",
    quesName: "Question Name goes here - Rating with No Tasks",
    toolTip: "Sub tool tip description",
  },
  {
    type: "type 4",
    quesNo: 4,
    surveyName: "Survey Name Goes Here",
    status: "In Progress",
    lang: "eng",
    quesName: "Question Name goes here - Rating with No Tasks",
    toolTip: "Sub tool tip description",
    mcq: [],
  },
];

const GetQuesType4 = () => {
  const classes = useStyles();
  const [selectedIndexArr, setSelectedIndexArr] = useState([]);

  const addOrRemoveIndex = (ind) => {
    //check if index already present
    if (selectedIndexArr.indexOf(ind) >= 0) {
      //remove
      selectedIndexArr.splice(selectedIndexArr.indexOf(ind), 1);
      setSelectedIndexArr([...selectedIndexArr]);
      return;
    }

    selectedIndexArr.push(ind);
    setSelectedIndexArr([...selectedIndexArr]);
  };

  return (
    <Box>
      {/* Survey Tooltip [conditional] */}
      <Grid container spacing={3}>
        <Grid item xs={2} md={1} className={classes.quesAdj}></Grid>
        <Grid item xs={10} md={8} className={classes.textLeft}>
          <Typography className={classes.quesTextSub}>
            Sub tool tip description
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}></Grid>
      </Grid>

      <List>
        <ListItem className={classes.listMinHeight}>
          {/* Survey Question Options */}
          <Grid container spacing={3}>
            <Grid container className={classes.alignMultipleQuesOption}>
              {["Option 1", "Option 2", "Option 3"].map((v, i) => {
                let isSelected = selectedIndexArr.indexOf(i) >= 0;
                return (
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={
                        isSelected
                          ? classes.mcqSelected
                          : classes.multipleOptionItemWrapper
                      }
                      onClick={() => {
                        addOrRemoveIndex(i);
                      }}
                    >
                      <span className={classes.alphabetOptionsBox}>
                        <span className={classes.alphabetOptionsBoxText}>
                          {alphabets[i]}
                        </span>
                      </span>
                      <Typography
                        className={
                          isSelected
                            ? classes.mcqSelectedText
                            : classes.alphabetOptionText
                        }
                      >
                        {v}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

const GetQuesType3 = () => {
  const classes = useStyles();

  const [ans, setAns] = useState("");

  return (
    <Box>
      {/* Survey Tooltip [conditional] */}
      <Grid container spacing={3}>
        <Grid item xs={2} md={1} className={classes.quesAdj}></Grid>

        <Grid item xs={10} md={8} className={classes.textLeft}>
          <Typography className={classes.quesTextSub}>
            Sub tool tip description
          </Typography>
        </Grid>
      </Grid>

      <List>
        <ListItem className={classes.listMinHeight}>
          {/* Survey Question Options */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} className={classes.modalBody}>
              <Input
                className={classes.inputNewAns}
                disableUnderline={true}
                rows={6}
                multiline={true}
                fullWidth={true}
                value={ans}
                placeholder={"Input 2-3 sentence answer here ..."}
                onChange={(e) => setAns(e.target.value)}
              />
            </Grid>

            <Grid container className={classes.alignMultipleQuesOption}></Grid>

            <Grid container spacing={3}></Grid>

            <Box></Box>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

const handleParentMouseOver = (e) => {
  // setCurrentHover(-1);
  e.preventDefault();
  e.stopPropagation();
};

const GetQuesType1 = () => {
  const classes = useStyles();

  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const optionsText = ["Very Poor", "Average", "Excellent"];

  const [currentHover, setCurrentHover] = useState(-1);
  const [selectedHover, setSelectedHover] = useState(-1);
  const resetHover = (e) => {
    setCurrentHover(-1);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleMouseOver = (e) => {
    let num = Number(e.target.getAttribute("data-value"));
    if (num) {
      setCurrentHover(num);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box onMouseOver={(e) => handleParentMouseOver(e)}>
      {/* Survey Tooltip [conditional] */}
      <Grid container spacing={3}>
        <Grid item xs={2} md={1} className={classes.quesAdj}></Grid>

        <Grid item xs={12} md={3}></Grid>
      </Grid>

      <List>
        {<Divider className={classes.divider} />}
        <ListItem
          className={classes.listMinHeight}
          onMouseOver={(e) => resetHover(e)}
        >
          {/* Survey Question Options */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className={classes.alignQuesOption}>
              <Typography className={classes.quesOption}>
                {" "}
                Question Option 1
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              className={classes.alignRating}
              onMouseOver={(e) => handleMouseOver(e)}
            >
              {options.map((v) => {
                return (
                  <Box
                    className={classes.ratingBox}
                    data-value={v}
                    onClick={() => setSelectedHover(v)}
                  >
                    <Typography
                      data-value={v}
                      className={
                        v <= selectedHover
                          ? classes.ratingFontSelected
                          : v <= currentHover
                          ? classes.ratingFontSelected
                          : classes.ratingFont
                      }
                    >
                      {v}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>

            <Grid container spacing={3}>
              {<Grid item xs={12} md={6}></Grid>}

              {
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.ratingTextContainer}
                >
                  {optionsText.map((v) => (
                    <Typography className={classes.ratingTextBelow}>
                      {v}
                    </Typography>
                  ))}
                </Grid>
              }
            </Grid>

            <Box></Box>
          </Grid>
        </ListItem>
        <Divider className={classes.divider} />
      </List>
    </Box>
  );
};

const GetQuesType2 = () => {
  const classes = useStyles();

  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const optionsText = ["Very Poor", "Average", "Excellent"];

  const [currentHover, setCurrentHover] = useState(-1);
  const [selectedHover, setSelectedHover] = useState(-1);
  const resetHover = (e) => {
    setCurrentHover(-1);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleMouseOver = (e) => {
    let num = Number(e.target.getAttribute("data-value"));
    if (num) {
      setCurrentHover(num);
    }

    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Box onMouseOver={(e) => handleParentMouseOver(e)}>
      <Grid container spacing={3}>
        <Grid item xs={2} md={1} className={classes.quesAdj}></Grid>
        {
          <Grid item xs={10} md={8} className={classes.textLeft}>
            <Typography className={classes.quesTextSub}>
              Sub tool tip description
            </Typography>
          </Grid>
        }
        <Grid item xs={12} md={3}></Grid>
      </Grid>

      <List>
        {<Divider className={classes.divider} />}
        <ListItem
          className={classes.listMinHeight}
          onMouseOver={(e) => resetHover(e)}
        >
          {/* Survey Question Options */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={1} className={classes.maxWidth} />

            <Grid
              item
              xs={12}
              md={11}
              className={classes.alignRating}
              onMouseOver={(e) => handleMouseOver(e)}
            >
              {options.map((v) => {
                return (
                  <Box
                    className={classes.ratingBox}
                    data-value={v}
                    onClick={() => setSelectedHover(v)}
                  >
                    <Typography
                      data-value={v}
                      className={
                        v <= selectedHover
                          ? classes.ratingFontSelected
                          : v <= currentHover
                          ? classes.ratingFontSelected
                          : classes.ratingFont
                      }
                    >
                      {v}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={1} className={classes.maxWidth} />

              <Grid
                item
                xs={12}
                md={11}
                className={classes.ratingTextContainer}
              >
                {optionsText.map((v) => {
                  return (
                    <Typography
                      className={classes.ratingTextBelowAdj}
                      onClick={() => setSelectedHover(v)}
                    >
                      {v}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>

            <Box></Box>
          </Grid>
        </ListItem>
        <Divider className={classes.divider} />
      </List>
    </Box>
  );
};

const DescriptionIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8021 36.0712V13.9291C13.8021 9.43644 17.4352 5.79443 21.917 5.79443H16.9759C12.4814 5.79443 8.83789 9.43791 8.83789 13.9325V36.0679C8.83789 40.5623 12.4814 44.2059 16.9759 44.2059H21.917C17.4352 44.2059 13.8021 40.5639 13.8021 36.0712Z"
      fill="#17457A"
      fill-opacity="0.1"
    />
    <path
      d="M32.0311 45.8334H17.0571C11.6724 45.8334 7.2915 41.4526 7.2915 36.0678V13.9324C7.2915 8.54761 11.6724 4.16675 17.0571 4.16675H32.0311C37.4159 4.16675 41.7967 8.54761 41.7967 13.9324V36.0678C41.7967 41.4526 37.4159 45.8334 32.0311 45.8334ZM17.0571 7.42196C13.4673 7.42196 10.5467 10.3425 10.5467 13.9324V36.0678C10.5467 39.6576 13.4673 42.5782 17.0571 42.5782H32.0311C35.6209 42.5782 38.5415 39.6576 38.5415 36.0678V13.9324C38.5415 10.3425 35.6209 7.42196 32.0311 7.42196H17.0571ZM33.3332 12.142C33.3332 11.2431 32.6045 10.5144 31.7056 10.5144C30.8066 10.5144 30.078 11.2431 30.078 12.142C30.078 13.4882 28.9827 14.5834 27.6366 14.5834H21.4517C20.1055 14.5834 19.0103 13.4882 19.0103 12.142C19.0103 11.2431 18.2816 10.5144 17.3826 10.5144C16.4837 10.5144 15.755 11.2431 15.755 12.142C15.755 15.2831 18.3105 17.8386 21.4517 17.8386H27.6366C30.7777 17.8386 33.3332 15.2831 33.3332 12.142Z"
      fill="#747474"
    />
    <path
      d="M32.1124 36.3119H18.3592C17.4603 36.3119 16.7316 35.5833 16.7316 34.6843C16.7316 33.7854 17.4603 33.0567 18.3592 33.0567H32.1124C33.0114 33.0567 33.74 33.7854 33.74 34.6843C33.74 35.5833 33.0114 36.3119 32.1124 36.3119ZM33.74 28.4181C33.74 27.5191 33.0114 26.7905 32.1124 26.7905H28.9386C28.0397 26.7905 27.311 27.5191 27.311 28.4181C27.311 29.317 28.0397 30.0457 28.9386 30.0457H32.1124C33.0114 30.0457 33.74 29.317 33.74 28.4181ZM22.9002 29.071L26.0254 25.9188C26.6583 25.2804 26.6539 24.2499 26.0154 23.617C25.3772 22.9842 24.3467 22.9885 23.7137 23.627L20.5884 26.7791C20.5735 26.7941 20.5551 26.794 20.5403 26.7791L19.0266 25.2524C18.3937 24.6139 17.3632 24.6095 16.7248 25.2424C16.0865 25.8753 16.0821 26.9058 16.7149 27.5442L18.2286 29.071C18.8517 29.6995 19.6812 30.0457 20.5643 30.0457C21.4476 30.0457 22.2772 29.6995 22.9002 29.071Z"
      fill="#747474"
    />
  </svg>
);
const alphabets = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
];
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getType = (type) => {
    if (type === "type 4") {
      return <GetQuesType4 />;
    }
    if (type === "type 1") {
      return <GetQuesType1 />;
    } else if (type === "type 2") {
      return <GetQuesType2 />;
    } else if (type === "type 3") {
      return <GetQuesType3 />;
    }
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              className={classes.closeAdj}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.modalBody}>
          {/* Survey Name */}
          <Grid container spacing={3} className={classes.marginHeader}>
            <Grid item xs={2} md={1} className={classes.maxWidth}>
              <DescriptionIcon className={classes.iconColor} />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textLeft}>
              <Typography className={classes.surveyTitle}>
                Survey Name Goes Here <SurveyChip />
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
            <Grid item xs={10} md={2} className={classes.textRight}>
              {/* <Typography>English</Typography> */}
              <Dropdown />
            </Grid>
          </Grid>
          {/* Survey Question */}
          <Grid container spacing={3} className={classes.quesContainer}>
            <Grid item xs={2} md={1} className={classes.quesAdj}>
              <Typography className={classes.ques}>
                {selectedIndex + 1}
              </Typography>
            </Grid>
            <Grid item xs={10} md={6} className={classes.textLeft}>
              <Typography className={classes.quesText}>
                Question Name goes here - Rating with No Tasks{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} className={classes.adjRight}></Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.quesCounter}>
                Questions {selectedIndex + 1}/{QuesArray.length}
              </Typography>
            </Grid>
          </Grid>
          {getType(QuesArray[selectedIndex].type)}
          <Typography className={classes.additionalFeedbackTitle}>
            Additional Feedback
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.docCommentsTitle}>
                Documents
              </Typography>
              <SurveyFile />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.docCommentsTitle}>
                Comments
              </Typography>
              <SurveyComment
                workticket={[
                  {
                    comments: [],
                  },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button
            color="primary"
            className={classes.buttonBack}
            disabled={selectedIndex === 0}
            onClick={() => {
              if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
              }
            }}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex < 3) {
                setSelectedIndex(selectedIndex + 1);
              }
            }}
            variant="contained"
            color="primary"
            size="large"
            disabled={selectedIndex + 1 === QuesArray.length}
            className={classNames(
              classes.button,
              classes.buttonSecondary,
              classes.btnDisabled
            )}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
