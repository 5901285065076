import Box from "@material-ui/core/Box";
import React, { Fragment } from "react";
import useStyles from "../styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ThreeDots from "../icons/ThreeDots";
import { PinIcon } from "../icons/PinIcon";
import { AddTradeDrawer } from "./AddTradeDrawer";
import { NoTrades } from "./NoTrades";
import { TradeAccordion } from "./TradeAccordion";
import usePartnerTrades from "./hooks/usePartnerTrades";
import usePartnerData from "./hooks/usePartnerData";
import useTradeManagement from "./hooks/useTradeManagement";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function TradesOffering() {
  const classes = useStyles();
  const { data: trades, isLoading: tradesLoading } = usePartnerTrades();
  const { data: partnerData, isLoading: partnerDataLoading } = usePartnerData();

  const {
    expanded,
    anchorEl,
    selectedAccordion,
    loadingState,
    isEditingTrade,
    tradeToEdit,
    workItemId,
    addingVariant,
    partnerTradeId,
    drawerState,
    handleEditTrade,
    handleDeleteTrade,
    handleAddVariant,
    handleClick,
    handleClose,
    handleChange,
    toggleDrawer,
    handleToggleDrawer,
    setIsEditingTrade,
    setAddingVariant,
    setWorkItemId,
  } = useTradeManagement();
  return (
    <Box className={classes.generalInformationWrapper}>
      {tradesLoading && <LoadingIndicator />}
      {!tradesLoading && trades.length === 0 && (
        <NoTrades classes={classes} toggleDrawer={toggleDrawer} />
      )}
      {!tradesLoading && trades.length !== 0 && (
        <>
          <Box className={classes.tradesTitleContainer}>
            <Typography className={classes.tradesOfferingSubheader}>
              Trades Offered & Pricing
            </Typography>
            <Button
              onClick={toggleDrawer("right", true)}
              className={classes.addContactButton}
            >
              Add New Trade
            </Button>
          </Box>
          <Box className={classes.tradesDataContainer}>
            <Box className={classes.dummyThreedotContainer}>
              <ThreeDots />
              <PinIcon />
            </Box>
            {trades?.map((item, accordionIndex) => (
              <TradeAccordion
                key={accordionIndex}
                item={item}
                accordionIndex={accordionIndex}
                expanded={expanded}
                handleChange={handleChange}
                classes={classes}
                handleClick={handleClick}
                anchorEl={anchorEl}
                selectedAccordion={selectedAccordion}
                handleClose={handleClose}
                handleAddVariant={handleAddVariant}
                handleEditTrade={handleEditTrade}
                handleDeleteTrade={handleDeleteTrade}
                loadingState={loadingState}
              />
            ))}
          </Box>
        </>
      )}
      <AddTradeDrawer
        drawerState={drawerState}
        toggleDrawer={() => toggleDrawer("right", false)}
        handleToggleDrawer={handleToggleDrawer}
        isEditingTrade={isEditingTrade}
        setIsEditingTrade={setIsEditingTrade}
        tradeToEdit={tradeToEdit}
        workItemId={workItemId}
        addingVariant={addingVariant}
        setAddingVariant={setAddingVariant}
        partnerTradeId={partnerTradeId}
        setWorkItemId={setWorkItemId}
      />
    </Box>
  );
}
