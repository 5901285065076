import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "./styles";

const RingBellManager = (props) => {
  const classes = useStyles();
  const { manager } = props;

  if (!manager) {
    return null;
  }

  return (
    <Grid container className={classes.managerContainer}>
      <Grid item md={12}>
        <Typography variant="h5" className={classes.tabSubTitle}>
          Manager
        </Typography>
      </Grid>
      <Grid item md={4} className={classes.managerDetailPic}>
        {manager.profile?.picture ? (
          <Avatar
            src={manager.profile.picture.file_url}
            className={classes.avatarManager}
          />
        ) : (
          <Avatar className={classes.avatarManager}>
            {manager.first_name[0]}
            {manager.last_name[0]}
          </Avatar>
        )}
      </Grid>
      <Grid item md={8} className={classes.managerDetail}>
        <Typography
          className={classes.managerName}
        >{`${manager.first_name} ${manager.last_name}`}</Typography>
        <Typography className={classes.managerDetailInfo}>
          {manager.job_title}
        </Typography>
        <Typography className={classes.managerDetailInfo}>Location</Typography>
        <Typography className={classes.managerDetailInfoSpaced}>
          {manager.email}
        </Typography>
        <Typography className={classes.managerDetailInfo}>
          mobile: {manager.phone}
        </Typography>
        <Typography className={classes.managerDetailInfo}>
          date of hire:{" "}
          {moment(manager.original_hire_date).format("MM/DD/YYYY")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RingBellManager;
