import React from "react";
import * as classNames from "classnames";
import GoogleMapReact from "google-map-react";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import LocationIcon from "@material-ui/icons/Apartment";

import { useWorkticketView } from "contexts/workticketViewContext";

import useStyles from "./styles";

import { mapKey } from "lib/config";

const WorkticketLocation = (props) => {
  const classes = useStyles();
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  if (!workticket.job.latitude) {
    return <>Location not available</>;
  }

  const PinComponent = () => (
    <Fab
      color="primary"
      size="small"
      aria-label="location"
      className={classes.buttonPin}
    >
      <LocationIcon />
    </Fab>
  );
  if (!isLoading) {
    const latitude = parseFloat(workticket.job.latitude);
    const longitude = parseFloat(workticket.job.longitude);
    const mapProps = {
      center: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      },
      zoom: 10,
    };

    return (
      <Box className={classes.containerLocation}>
        <div style={{ height: "300px", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapKey }}
            defaultCenter={mapProps.center}
            defaultZoom={mapProps.zoom}
          >
            <PinComponent
              lat={workticket.job.latitude}
              lng={workticket.job.longitude}
              classesProps={classes.buttonPin}
            />
          </GoogleMapReact>
        </div>
        <Box className={classes.containerLocationBtn}>
          <Link
            href={`http://maps.google.com/maps?q=${workticket.job.latitude},${workticket.job.longitude}`}
            rel="noreferrer"
            target="_blank"
            className={classNames(classes.buttonLink, classes.buttonOutlined)}
          >
            Go to Location
          </Link>
        </Box>
      </Box>
    );
  }
};

export default WorkticketLocation;
