import React from "react";
import UserLayout from "./userLayout";
import { UserViewProvider } from "contexts/userViewContext";

const User = (props) => {
  return (
    <UserViewProvider>
      <UserLayout id={props.match.params.id} {...props} />
    </UserViewProvider>
  );
};

export default User;
