import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { previewPreventativeServices } from "services/projectService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServicesPreview = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { active, data, jobsList } = props;

  useEffect(() => {
    const loadPreview = async () => {
      const jobsListIds = jobsList.map((job) => job.id);
      let dataPreview = {};

      let user_assign = [];
      if (data.perform_service_type === 1 && data.team_member_id) {
        user_assign.push(data.team_member_id);
      } else if (data.perform_service_type === 2 && data.subcontractor_id) {
        user_assign.push(data.subcontractor_id);
      }

      if (data.frequency_type === 1) {
        dataPreview = {
          job_ids: jobsListIds,
          summary: data?.summary ?? "Summary",
          start_date: moment(data.start_date).format("YYYY-MM-DD"),
          user_ids: user_assign,
        };
      } else if (data.frequency_type === 2) {
        let intervalDays = [];

        if (data.interval_type.id === "weeks") {
          intervalDays = data.interval_week_days;
        }

        if (data.interval_type.id === "months") {
          intervalDays = data.interval_month_days.map((day) => day.value);
        }

        dataPreview = {
          is_recurring: 1,
          job_ids: jobsListIds,
          summary: data?.summary ?? "Summary",
          start_date: moment(data.start_date).format("YYYY-MM-DD"),
          user_ids: user_assign,
          interval_type: data.interval_type.id,
          interval_size: data.interval_size,
          interval_days: intervalDays,
          skip_weekends:
            data.interval_type_id === 3
              ? data.interval_skip_weekdays
                ? 1
                : 0
              : 0,
          stop_date: data.interval_no_end_date
            ? ""
            : moment(data.interval_end_date).format("YYYY-MM-DD"),
        };
      }
      try {
        setIsLoading(true);
        const result = await previewPreventativeServices(dataPreview);
        setList(result.data.data.worktickets);
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load workticket preview");
      }
    };
    if (open && jobsList.length) {
      loadPreview();
    }
  }, [open, jobsList, data]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.actionPreviewWorkticket}>
      <Button
        variant="outlined"
        color={active ? "secondary" : "default"}
        className={classes.btnPreview}
        startIcon={<TicketIcon />}
        onClick={handleOpen}
        disabled={!active}
      >
        Preview Worktickets
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBarWorkticketPreview}>
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {!isLoading ? (
              <Typography variant="h6" className={classes.appBarTitle}>
                Showing the first {list.length} tickets to be generated, pattern
                will be apply to all jobs
              </Typography>
            ) : null}
          </Toolbar>
        </AppBar>
        <Box className={classes.previewContent}>
          <Box>
            <Typography variant="h6" className={classes.previewTableTitle}>
              <TicketIcon fontSize="large" /> Workticket Preview
            </Typography>
          </Box>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <Box>
              <TableContainer component={Box}>
                <Table className={classes.table} aria-label="table">
                  <TableHead className={classes.previewTableHeader}>
                    <TableRow>
                      <TableCell
                        className={classes.previewTableTicketCol}
                      ></TableCell>
                      <TableCell>WT Summary</TableCell>
                      <TableCell>Job Number</TableCell>
                      <TableCell>Job Description</TableCell>
                      <TableCell>Assigned To</TableCell>
                      {data.frequency_type === 2 ? (
                        <TableCell>Frequency</TableCell>
                      ) : null}
                      <TableCell>Creation Date</TableCell>
                      <TableCell>Due Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TicketIcon />
                        </TableCell>
                        <TableCell>{row.summary}</TableCell>
                        <TableCell>{row.job_number}</TableCell>
                        <TableCell>{row.job_description}</TableCell>
                        <TableCell>
                          {row.users[0]?.full_name ?? "N/A"}
                        </TableCell>
                        {data.frequency_type === 2 ? (
                          <TableCell>{`${data.interval_type.labelPlural} Recurrence`}</TableCell>
                        ) : null}
                        <TableCell>{row.created_date}</TableCell>
                        <TableCell>{row.due_date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default ServicesPreview;
