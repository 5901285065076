import React from "react";
import Typography from "@material-ui/core/Typography";

const PageNotFound = () => {
  return (
    <>
      <Typography variant="h2" style={{ textAlign: "center", marginTop: 120 }}>
        Oops!
      </Typography>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        404 - Page Not Found
      </Typography>
    </>
  );
};

export default PageNotFound;
