import React from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  highlight: {
    color: "#F07A8C",
    cursor: "pointer",
  },
  paper: {
    padding: 8,
  },
  label: {
    marginRight: 8,
    cursor: "pointer",
  },
}));

const ScoreCA = ({ row, history }) => {
  const classes = useStyles();
  const { total_score, workticket_id, workticket_number } = row;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (!workticket_id) {
      return;
    }
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const handleOpenWorkticket = () => {
    history.push(`/workticket/${workticket_id}`);
  };

  const open = Boolean(anchorEl);

  return total_score ? (
    <>
      <Box
        className={workticket_id ? classes.highlight : null}
        onClick={handlePopoverOpen}
      >{`${(parseFloat(total_score) * 100).toFixed(1)} %`}</Box>
      {workticket_id ? (
        <Popover
          id={`mouse-over-ca-${workticket_id}`}
          open={open}
          anchorEl={anchorEl}
          className={classes.poper}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          elevation={2}
        >
          <Typography
            variant="caption"
            className={classes.label}
            onClick={handleOpenWorkticket}
          >
            {workticket_number}
          </Typography>{" "}
          <WorkticketSoloStatusChip status={1} type={4} />
        </Popover>
      ) : null}
    </>
  ) : (
    "-"
  );
};

export default withRouter(ScoreCA);
