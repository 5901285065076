import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";

const apiEndpoint = apiSurfaceUrl + "/workticket/emergency";

const apiSurfaceEndpoint = apiSurfaceUrl + "/zones";

export function getCampus() {
  return http.get(`${apiEndpoint}?customer=nova`, {
    params: {},
  });
}

export function getLocations(campus) {
  return http.get(`${apiEndpoint}?customer=nova&location=${campus}`, {
    params: {},
  });
}

export function getZones(job) {
  return http.get(`${apiSurfaceEndpoint}?job_numbers[]=${job}`, {
    params: {},
  });
}
