import React from "react";
import PageHeader from "../../components/common/PageHeader/pageHeader";
import QuoteAdminTable from "./quoteAdminTable";
import Box from "@material-ui/core/Box";

import { TableProvider } from "../../contexts/tableContext";
import { DrawerProvider } from "../../contexts/detailsDrawerContext";
import { ListProvider } from "../../contexts/listContext";
import { FilterProvider } from "../../contexts/filterContext";

import useStyles from "./styles";

const QuoteAdmin = (props) => {
  const classes = useStyles();

  const initialStateFilters = {};

  return (
    <>
      <PageHeader
        pageTitle="Services Admin"
        link="/services-admin "
      ></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <DrawerProvider>
            <FilterProvider initialState={initialStateFilters}>
              <TableProvider>
                <QuoteAdminTable />
              </TableProvider>
            </FilterProvider>
          </DrawerProvider>
        </ListProvider>
      </Box>
    </>
  );
};

export default QuoteAdmin;
