import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AccountReceivableTable from "./accountReceivableTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { BillingProvider } from "contexts/billingArContext";

import useStyles from "./styles";

const AccountReceivableList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Account Receivable"
        link="/financial/report/account-receivable "
      />
      <Box className={classes.pageBodyWrapper}>
        <BillingProvider>
          <AccountReceivableTable />
        </BillingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountReceivableList);
