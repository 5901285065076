import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootLayout: {
    height: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
  },
  fullHeightTable: {
    flex: "1 1",
    height: "80vh",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: "100%",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  contentCounter: {
    textAlign: "right",
    marginTop: theme.spacing(1),
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      left: 20,
      top: 75,
    },
  },
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: 455,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#979797",
  },
  iconClose: {
    fontSize: 16,
  },
  centerLoadingSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    margin: 0,
    padding: 0,
    height: "80vh",
    overflowY: "hidden",
  },
}));

export default useStyles;
