import React from "react";
import {
  getTasksList,
  getTasksListSearch,
  getTasksListOffset,
} from "services/surveysTasksService";
import { merge } from "lodash";

const defaultState = {
  surveysTasksLoading: true,
  surveysTaskSelected: null,
  surveysTaskList: [],
  surveysTasksCount: [],
  openSurveysTaskDelete: false,
  openManageTask: false,
};

const SurveysTasksStateContext = React.createContext();
const SurveysTasksDispatchContext = React.createContext();

function surveysTasksReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        surveysTasksLoading: action.surveysTasksLoading,
      };
    case "SET_TASKS_LIST":
      return {
        ...state,
        surveysTaskList: action.surveysTaskList,
      };
    case "SET_TASKS_COUNT":
      return {
        ...state,
        surveysTasksCount: action.surveysTasksCount,
      };
    case "SET_TASKS_SELECTED":
      return {
        ...state,
        surveysTaskSelected: action.surveysTaskSelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openSurveysTaskDelete: action.openSurveysTaskDelete,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageTask: action.openManageTask,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveysTasksProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(surveysTasksReducer, mergedState);
  return (
    <SurveysTasksStateContext.Provider value={state}>
      <SurveysTasksDispatchContext.Provider value={dispatch}>
        {children}
      </SurveysTasksDispatchContext.Provider>
    </SurveysTasksStateContext.Provider>
  );
}

function useSurveysTasksState() {
  const context = React.useContext(SurveysTasksStateContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysTasksState must be used within a SurveysTasksProvider"
    );
  }
  return context;
}

function useSurveysTasksDispatch() {
  const context = React.useContext(SurveysTasksDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysTasksDispatch must be used within a SurveysTasksProvider"
    );
  }
  return context;
}

async function getSurveysTasksList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      surveysTasksLoading: true,
    });
    const result = await getTasksList(filterData);
    dispatch({
      type: "SET_TASKS_LIST",
      surveysTaskList: [...result.data.data.list],
    });
    dispatch({
      type: "SET_TASKS_COUNT",
      surveysTasksCount: result.data.data.filter_count,
    });
    dispatch({
      type: "SET_LOADING",
      surveysTasksLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getSurveysTasksListDataSet(
  surveysTasksList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getTasksListOffset(offset, filterData);
    const updateData = surveysTasksList.concat(result.data.data.list);
    console.log(updateData);
    dispatch({
      type: "SET_TASKS_LIST",
      surveysTaskList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchSurveysTasksList(search, filterData) {
  try {
    const result = await getTasksListSearch(search, filterData);
    return result.data.data.list;
  } catch (error) {
    console.log(error);
  }
}

export {
  SurveysTasksProvider,
  useSurveysTasksState,
  useSurveysTasksDispatch,
  getSurveysTasksListDataSet,
  getSurveysTasksList,
  searchSurveysTasksList,
};
