export function MiniChartBar() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8114 2C14.9663 2 14.2812 2.67157 14.2812 3.5L14.2812 16.5C14.2812 17.3284 14.9663 18 15.8114 18H16.8315C17.6766 18 18.3617 17.3284 18.3617 16.5L18.3617 3.5C18.3617 2.67157 17.6766 2 16.8315 2L15.8114 2Z"
        fill="#4D4D4D"
      />
      <path
        d="M9.69079 6C8.84572 6 8.16064 6.67157 8.16064 7.5L8.16064 16.5C8.16064 17.3284 8.84572 18 9.69079 18H10.7109C11.556 18 12.241 17.3284 12.241 16.5L12.241 7.5C12.241 6.67157 11.556 6 10.7109 6H9.69079Z"
        fill="#4D4D4D"
      />
      <path
        d="M3.57019 10C2.72511 10 2.04004 10.6716 2.04004 11.5L2.04004 16.5C2.04004 17.3284 2.72511 18 3.57019 18H4.59029C5.43537 18 6.12044 17.3284 6.12044 16.5L6.12044 11.5C6.12044 10.6716 5.43537 10 4.59029 10H3.57019Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
