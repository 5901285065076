import React from "react";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

const TimekeepingIconSynch = (props) => {
  const { row } = props;
  const { hr_entry, hr_entry_clock_rel, hr_entry_drive_rel } = row;

  if ((hr_entry_clock_rel || hr_entry_drive_rel) && !hr_entry) {
    return <SyncProblemIcon />;
  }

  if ((hr_entry_clock_rel || hr_entry_drive_rel) && hr_entry) {
    return <SyncIcon />;
  }

  return null;
};

export default TimekeepingIconSynch;
