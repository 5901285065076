import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { minimizeIcon, chatBubbleIcon, expandIcon } from "./icons";
import { convertTimeToSeconds } from "../../util/timeFormat";
import CommentList from "./commentList";
import TimestampComment from "./timestampComment";
import TabComment from "./tabComment";
import useStyles from "./styles";

const FloatingComments = ({
  data,
  file,
  workTicketId,
  playerRef,
  activeTab,
  isLoadingComments,
  videoDuration,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [currentTab, setCurrentTab] = useState(activeTab);
  const [filteredData, setFilteredData] = useState([]);

  // Synchronize currentTab with activeTab prop
  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (data) {
      let filtered = [];
      if (currentTab === "internal") {
        filtered = data.internal || [];
      } else if (currentTab === "partner") {
        filtered = data.partner || [];
      } else if (currentTab === "customer") {
        filtered = data.customer || [];
      }
      setFilteredData(filtered);
    }
  }, [data, currentTab]);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTimeClick = (time) => {
    const seconds = convertTimeToSeconds(time);

    if (playerRef.current && seconds !== null) {
      playerRef.current.seekTo(seconds, "seconds");
    } else {
      console.error("Player reference is null or undefined.");
    }
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const minimizeOrExpandButton = (
    <IconButton onClick={toggleMinimize} size="small">
      {isMinimized ? expandIcon() : minimizeIcon()}
    </IconButton>
  );

  const closeButton = (
    <IconButton onClick={handleClose} size="small">
      <CloseIcon />
    </IconButton>
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Box className={classes.floatingCommentsContainer}>
      <Box className={classes.floatingCommentsTitleContainer}>
        <Box onClick={toggleMinimize} className={classes.commentTitleContainer}>
          <IconButton>{chatBubbleIcon()}</IconButton>
          <Typography className={classes.boldBodyTypography} variant="body">
            Comments
          </Typography>
        </Box>
        <Box>
          {!isMinimized ? (
            <>
              {minimizeOrExpandButton}
              {closeButton}
            </>
          ) : (
            <>
              {minimizeOrExpandButton}
              {closeButton}
            </>
          )}
        </Box>
      </Box>
      {!isMinimized && (
        <Box
          className={classes.floatingCommentsContainerList}
          style={{ scroll: "auto" }}
        >
          <Box style={{ marginLeft: 5, marginRight: 5, marginBottom: 10 }}>
            <TabComment activeTab={currentTab} onChangeTab={handleTabChange} />
          </Box>
          <Box className={classes.commentListContainer}>
            <Box style={{ padding: 16 }}>
              <CommentList
                data={filteredData}
                file={file}
                workTicketId={workTicketId}
                activeTab={currentTab}
                onTimeClick={handleTimeClick}
                embed
                isLoadingComments={isLoadingComments}
              />
            </Box>
          </Box>
          <Box className={classes.timestampCommentContainer}>
            <TimestampComment
              file={file}
              workTicketId={workTicketId}
              playerRef={playerRef}
              videoDuration={videoDuration}
              activeTab={currentTab}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FloatingComments;
