import { useQuery } from "react-query";
import { getPartnerMainInfo } from "services/partners/partnerService";
import Integer from "lodash";
import { useGetUserId } from "../context/UserIdContext";
import { logException } from "components/util/logUtil";
import { changePartnerStatus } from "services/partnerService";
import { usePartnerAction } from "contexts/partnerActionContext";
import { usePartnerDispatch } from "contexts/partnerContext";
import { useState } from "react";

export function usePartnerMainInfo() {
  const partnerAction = usePartnerAction();
  const dispatchPartnerAction = partnerAction[1];
  const dispatchPartners = usePartnerDispatch();
  const [isRefetchingNewStatus, setIsRefetchingNewStatus] = useState(false);

  const handleStatusChange = async (partnerSelected) => {
    try {
      setIsRefetchingNewStatus(true);
      const inactiveStatus = 4;
      if (partnerSelected.status === inactiveStatus) {
        openChangeStatusModal(partnerSelected);
      } else {
        await updatePartnerStatus(partnerSelected);
      }
    } catch (error) {
      setIsRefetchingNewStatus(false);
      logException(error, "Error updating partner status");
    }
  };

  const openChangeStatusModal = (partnerSelected) => {
    dispatchPartnerAction({
      type: "TOGGLE_CHANGE_STATUS",
      open: true,
    });
    dispatchPartners({
      type: "SET_PARTNER_SELECTED",
      partnerSelected,
    });
  };

  const updatePartnerStatus = async (partnerSelected) => {
    const data = { status: partnerSelected.status };
    await changePartnerStatus(partnerSelected.id, data);
    refetch();
    setIsRefetchingNewStatus(false);
  };

  const { userId } = useGetUserId();

  const {
    data: partnerMainData,
    isLoading: isPartnerMainInfoLoading,
    refetch,
  } = useQuery(
    ["partnerGeo", userId],
    async () => {
      const { data } = await getPartnerMainInfo(userId);
      return data.data.partner;
    },
    {
      enabled: !!userId,
    }
  );

  let partnerStates = partnerMainData?.states;

  let stateNameSet = new Set();
  let uniqueStates = [];
  partnerStates?.forEach((state) => {
    if (!stateNameSet.has(state.name)) {
      stateNameSet.add(state.name);
      uniqueStates.push(state);
    }
  });
  partnerStates = uniqueStates;
  partnerStates?.forEach((state) => {
    let cityName = new Set();
    let uniqueCities = [];
    partnerMainData?.cities?.forEach((city) => {
      if (city.state_abbreviation === state.abbreviation) {
        if (!cityName.has(city.city_name)) {
          cityName.add(city.city_name);
          uniqueCities.push(city);
        }
      }
    });
    state.cities = uniqueCities;
    let stateZips = [];
    state.cities?.forEach((city) => {
      let uniqueZipCodes = [];
      let zipCodeSet = new Set();
      partnerMainData?.zip_codes?.forEach((zip) => {
        if (Integer.toInteger(zip.city_id) === city.id) {
          if (!zipCodeSet.has(zip.id)) {
            zipCodeSet.add(zip.id);
            uniqueZipCodes.push(zip);
          }
        }
      });
      city.zip_codes = uniqueZipCodes;
      stateZips.push(...uniqueZipCodes);
    });
    state.zip_codes = stateZips;
  });

  return {
    partnerMainData,
    isPartnerMainInfoLoading,
    partnerStates,
    userId,
    handleStatusChange,
    isRefetchingNewStatus,
    refetch,
  };
}
