import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";

import GlobalUiContext from "contexts/globalUiContext";
import { permissionAdmin, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
}));

const UsersListActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleClick = () => {
    const { history } = props;
    history.push(`/user/new`);
  };

  return (
    <div className={classes.root}>
      {hasPermission(permissionAdmin.USER_CREATE, permissions) && (
        <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
          Create User
        </ButtonBase>
      )}
    </div>
  );
};

export default withRouter(UsersListActions);
