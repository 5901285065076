import React, { useState, useEffect } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormSelect from "../../ui/FormContent/formSelect";
import FormInput from "../../ui/FormContent/formInput";
import FormInputDateAdo from "../../ui/FormContent/formInputDateAdo";
import FormSelectAuto from "../../ui/FormContent/formSelectAuto";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionIcon from "@material-ui/icons/Help";

import useStyles from "./styles";

const optionsScheduleInterval = [
  { value: 1, label: "Day", id: "days" },
  { value: 2, label: "Week", id: "weeks" },
  { value: 3, label: "Month", id: "months" },
];

const optionsDays = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const optionsDaysMonth = [...Array(31).keys()].map((item) => {
  return { value: item + 1, label: String(item + 1) };
});

const Recurrence = (props) => {
  const classes = useStyles();
  const [scheduleInterval, setScheduleInterval] = useState(1);
  const [scheduleData, setScheduleData] = useState({
    startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    stopDate: moment().add("year", 1).format("YYYY-MM-DD HH:mm:ss"),
    skipWeekdays: 0,
  });
  const [noEndDate, setNoEndDate] = useState(false);
  const [every, setEvery] = useState(1);
  const [days, setDays] = React.useState([1]);
  const [daysMonth, setDaysMonth] = useState([]);
  const [selItems, setSelItems] = useState([]);
  const [openModify, setOpenModify] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState([]);

  const {
    open,
    mode,
    data,
    handleClose,
    handleSubmit,
    handleGeneratorDelete,
    title,
    items,
    next,
    back,
    showBack,
    handleBack,
    subTitle,
    message,
    confirm,
    disableDelete,
  } = props;

  useEffect(() => {
    if (data) {
      const findScheduleInterval = optionsScheduleInterval.find(
        (item) => item.id === data.schedule_interval
      );
      if (findScheduleInterval) {
        setScheduleInterval(findScheduleInterval.value);
      }
      setEvery(data.schedule_data.value);
      setScheduleData({
        startDate: data.schedule_data.start_date,
        stopDate: !data.schedule_data.stop_date
          ? moment().add("month", 1).format("YYYY-MM-DD HH:mm:ss")
          : data.schedule_data.stop_date,
        skipWeekdays: data.schedule_data.skip_weekends,
      });

      setNoEndDate(!data.schedule_data.stop_date ? true : false);
      if (data.schedule_interval === "weeks") {
        setDays(data.schedule_data.days);
      }
      if (data.schedule_interval === "months") {
        const daysMonthUpdate = data.schedule_data.days.map((item) => {
          return optionsDaysMonth.find((i) => i.value === item);
        });
        setDaysMonth(daysMonthUpdate);
      }
    }
  }, [data]);

  const selectedOption = optionsScheduleInterval.find(
    (item) => item.value === scheduleInterval
  );

  const handleChangeInterval = async (event) => {
    setScheduleInterval(event.value);
    setHasChanged(true);
  };

  const handleChangeItems = (event, value) => {
    setSelItems(value);
    setHasChanged(true);
  };

  const handleChangeStartDate = async (event) => {
    const updateScheduleData = {
      ...scheduleData,
      startDate: moment(event).format("YYYY-MM-DD HH:mm:ss"),
    };
    setScheduleData(updateScheduleData);
    setHasChanged(true);
  };

  const handleChangeStopDate = async (event) => {
    const updateScheduleData = {
      ...scheduleData,
      stopDate: moment(event).format("YYYY-MM-DD HH:mm:ss"),
    };
    setScheduleData(updateScheduleData);
    setHasChanged(true);
  };

  const handleChangeSkipWeekends = (event) => {
    const updateScheduleData = {
      ...scheduleData,
      skipWeekdays: event.target.checked ? 1 : 0,
    };
    setScheduleData(updateScheduleData);
    setHasChanged(true);
  };

  const handleBlurEvery = (event) => {
    setEvery(event.value);
    setHasChanged(true);
  };

  const handleChangeDays = (e, option) => {
    if (e.target.checked) {
      setDays([...days, option]);
    } else {
      days.splice(days.indexOf(option), 1);
      if (days.length) {
        setDays([...days]);
      } else {
        setDays([]);
      }
    }
    setHasChanged(true);
  };

  const handleChangeDaysMonth = (event, value) => {
    setHasChanged(true);
    setDaysMonth(value);
    if (value.length > 0) {
      setError([]);
    } else {
      setError([{ key: "daysMonths", message: "On Days is Required" }]);
    }
  };

  const handleModify = () => {
    if (daysMonth.length <= 0 && scheduleInterval === 3) {
      setError([{ key: "daysMonths", message: "On Days is Required" }]);
    } else {
      setOpenModify(true);
    }
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };

  const handleSave = () => {
    if (daysMonth.length <= 0 && scheduleInterval === 3) {
      setError([{ key: "daysMonths", message: "On Days is Required" }]);
    } else {
      setOpenModify(false);
      let daysInput = null;
      let quoteItems = null;
      if (selectedOption.id === "weeks") {
        daysInput = days;
      }
      if (selectedOption.id === "months") {
        daysInput = daysMonth.map((item) => {
          return item.value;
        });
      }

      if (selItems.length > 0) {
        quoteItems = selItems.map((item) => {
          return item.value;
        });
      }

      const dataSubmit = {
        quoteItems: quoteItems,
        schedule_interval: selectedOption.id,
        schedule_data: {
          value: every,
          days: daysInput,
          start_date: scheduleData.startDate,
          stop_date: !noEndDate ? scheduleData.stopDate : null,
          skip_weekends: scheduleData.skipWeekdays,
          mode: mode,
        },
      };
      const dataId = data ? data.id : null;
      handleSubmit(dataId, dataSubmit);
      if (!handleBack) handleClose();
    }
  };

  const handleDelete = () => {
    handleGeneratorDelete();
    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classes.wrapperDialogClose}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          {data && !disableDelete && (
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              className={classes.wrapperDialogDelete}
            >
              <DeleteIcon className={classes.iconOptions} />
            </IconButton>
          )}

          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            {`Recurrence ${title ? title : ""}`}
          </Typography>
          {subTitle ? (
            <Typography
              variant="caption"
              gutterBottom
              className={classes.subTitleDialog}
            >
              {subTitle}
            </Typography>
          ) : (
            ""
          )}
          {items && items.length > 0 ? (
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={items}
              name="selItems"
              label="Quote Items"
              internal={true}
              handleChange={handleChangeItems}
              multiple={true}
              readOnly={data && data.tickets_in_progress}
              value={selItems}
            />
          ) : (
            ""
          )}
          <FormSelect
            gridSizes={[{ size: "md", val: 6 }]}
            name="schedule_interval"
            label="Schedule Interval"
            options={optionsScheduleInterval}
            internal={true}
            readonly={data && data.tickets_in_progress}
            value={scheduleInterval}
            handleBlur={handleChangeInterval}
          />
          <Box>
            <Grid container spacing={4}>
              <Grid item md={3} xs={6}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="every"
                  label="Every"
                  internal={true}
                  readonly={data && data.tickets_in_progress}
                  value={every}
                  handleBlur={handleBlurEvery}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleOption}
                >
                  {selectedOption && selectedOption.label}
                  {every > 1 ? "s" : ""}
                </Typography>
              </Grid>
              {(scheduleInterval === 1 || scheduleInterval === 3) && (
                <Grid item md={6}>
                  <Box className={classes.skipContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(scheduleData.skipWeekdays)}
                          onChange={handleChangeSkipWeekends}
                          disabled={data && data.tickets_in_progress}
                          className={classes.filterCheckbox}
                        />
                      }
                      label={"Skip weekends."}
                      value={1}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            {scheduleInterval === 2 && (
              <Box className={classes.daysOptionsContainer}>
                <Grid container spacing={1}>
                  {optionsDays.map((option) => {
                    return (
                      <Grid item xs={6} md={3} key={option.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days.includes(option.value)}
                              onChange={(e) =>
                                handleChangeDays(e, option.value)
                              }
                              className={classes.filterCheckbox}
                              disabled={data && data.tickets_in_progress}
                            />
                          }
                          label={option.label}
                          value={option.value}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
            {scheduleInterval === 3 && (
              <Box className={classes.monthsOptionsContainer}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={optionsDaysMonth}
                  name="daysMonths"
                  error={error}
                  label="On Days"
                  internal={true}
                  handleChange={handleChangeDaysMonth}
                  multiple={true}
                  value={daysMonth}
                  readonly={data && data.tickets_in_progress}
                />
              </Box>
            )}
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <FormInputDateAdo
                  name="startDate"
                  label="Start Date"
                  value={scheduleData.startDate}
                  handleChange={handleChangeStartDate}
                  disabled={data && data.tickets_in_progress}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.gridContainer}>
                <FormInputDateAdo
                  name="stopDate"
                  label="End Date"
                  value={scheduleData.stopDate}
                  handleChange={handleChangeStopDate}
                  disabled={noEndDate || (data && data.tickets_in_progress)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noEndDate}
                      onChange={() => setNoEndDate(!noEndDate)}
                      className={classes.filterCheckbox}
                      disabled={data && data.tickets_in_progress}
                    />
                  }
                  label={"Don't define end date."}
                  value={1}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          {(showBack === true || showBack === null) && (
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              size="large"
              onClick={handleBack ? handleBack : handleClose}
            >
              {back ? back : "Cancel"}
            </Button>
          )}
          {((hasChanged && data && !data.tickets_in_progress) || !data) && (
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              size="large"
              onClick={data && confirm ? handleModify : handleSave}
            >
              {next ? next : data ? "Update" : "Create"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModify}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => handleCloseModify(false)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <QuestionIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Modify Recurrence
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction2}>
            <Button
              onClick={handleCloseModify}
              className={classes.buttonOutlined}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className={classes.button2}
              color="primary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default Recurrence;
