import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AssetsIcon from "@material-ui/icons/Toys";
import WorkIcon from "@material-ui/icons/Work";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button from "@material-ui/core/Button";
import { getAssetDetails } from "services/assetService";

import useStyles from "./styles";

const AssetMaintenance = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const { id } = props;

  useEffect(() => {
    const loadData = async () => {
      try {
        const assetDetail = await getAssetDetails(id);
        setAsset(assetDetail.data.data.asset);
        // const assetPlan = await getAssetMaintenancePlan(id);
        // const resultPlan = assetPlan.data.data.generators;
        // setPlanDay(
        //   resultPlan.filter((plan) => plan.schedule_interval === "days")
        // );
        // setPlanWeek(
        //   resultPlan.filter((plan) => plan.schedule_interval === "weeks")
        // );
        // setPlanMonth(
        //   resultPlan.filter((plan) => plan.schedule_interval === "months")
        // );
      } catch (e) {
        console.log("Cannot get the asset data");
      }
    };
    if (open) {
      loadData();
    }
  }, [id, open]);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDisable = (e) => {
    e.stopPropagation();
  };

  // const handleTabChange = (e, newValue) => {
  //   e.stopPropagation();
  //   setTab(newValue);
  // };

  const taskList = asset?.tasks ? JSON.parse(asset.tasks) : null;

  return (
    <>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        disableElevation={true}
        size={"small"}
        onClick={(e) => handleOpen(e)}
      >
        View
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={(e) => handleClose(e)}
        aria-labelledby="max-width-dialog-title"
        onClick={(e) => handleDisable(e)}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={(e) => handleClose(e)}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Grid container spacing={2} className={classes.dialogHeader}>
            <Grid item sm={12}>
              <Typography
                variant="h3"
                className={classes.titleBody}
                gutterBottom
              >
                Maintenance Plan
              </Typography>
              {asset && (
                <>
                  <Typography
                    variant="h4"
                    className={classes.subtitleBody}
                    gutterBottom
                  >
                    <AssetsIcon
                      fontSize="small"
                      className={classes.iconHeader}
                    />{" "}
                    {asset.name}
                  </Typography>
                  {asset.jobs.length && (
                    <>
                      {asset.jobs.map((job) => (
                        <Typography
                          variant="h4"
                          className={classes.subtitleBody}
                          gutterBottom
                          key={job.id}
                        >
                          <WorkIcon
                            fontSize="small"
                            className={classes.iconHeader}
                          />
                          {`${job.job_number} - ${job.job_description}`}
                        </Typography>
                      ))}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Box className={classes.containerDialog}>
            {taskList &&
              taskList.map((task) => (
                <FormControlLabel
                  value={task.name}
                  key={task.id}
                  checked={false}
                  control={
                    <Checkbox
                      className={classes.tableCheckbox}
                      color="secondary"
                    />
                  }
                  label={<Box className={classes.taskLabel}>{task.name}</Box>}
                  labelPlacement="end"
                  className={classes.taskItem}
                />
              ))}
            {(!taskList || taskList.length === 0) && (
              <Box className={classes.taskContainerEmpty}>
                <Typography variant="h5" gutterBottom>
                  No tasks
                </Typography>
              </Box>
            )}
          </Box>
          {/* <Box className={classes.containerDialog}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              className={classes.activityTabRoot}
            >
              {planTab.map((plan) => (
                <Tab
                  key={plan.value}
                  label={`${plan.label} (${
                    plan.value === 0
                      ? planDay.length
                      : plan.value === 1
                      ? planWeek.length
                      : planMonth.length
                  })`}
                  value={plan.value}
                  className={classes.activityTab}
                />
              ))}
            </Tabs>
            {tab === 0 && (
              <Box className={classes.containerPlan}>
                {planDay.length ? (
                  planDay.map((plan, index) => (
                    <List className={classes.containerPlanList} key={index}>
                      <ListItem className={classes.containerPlanItem}>
                        <ListItemIcon>
                          <TimelineIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Every ${
                            plan.schedule_data.value < 2
                              ? `Day`
                              : `${plan.schedule_data.value} days`
                          }`}
                          secondary={`Skip weekends: ${
                            plan.schedule_data.skip_weekends ? `Yes` : `No`
                          }`}
                        />
                        {plan.schedule_data.end_date && (
                          <ListItemSecondaryAction
                            className={classes.containerPlanDate}
                          >
                            End Date:{" "}
                            {moment(plan.schedule_data.end_date).format("LL")}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    </List>
                  ))
                ) : (
                  <List className={classes.containerPlanList}>
                    <ListItem>
                      <ListItemText primary={"No daily maintenance plan"} />
                    </ListItem>
                  </List>
                )}
              </Box>
            )}
            {tab === 1 && (
              <Box className={classes.containerPlan}>
                {planWeek.length ? (
                  planWeek.map((plan, index) => (
                    <List className={classes.containerPlanList} key={index}>
                      <ListItem className={classes.containerPlanItem}>
                        <ListItemIcon>
                          <TimelineIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Every ${
                            plan.schedule_data.value < 2
                              ? `Week`
                              : `${plan.schedule_data.value} weeks`
                          }`}
                          secondary={`On ${plan.schedule_data.days.map(
                            (day) => `${optionsDays[day]} `
                          )}`}
                        />
                        {plan.schedule_data.end_date && (
                          <ListItemSecondaryAction
                            className={classes.containerPlanDate}
                          >
                            End Date:{" "}
                            {moment(plan.schedule_data.end_date).format("LL")}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    </List>
                  ))
                ) : (
                  <List className={classes.containerPlanList}>
                    <ListItem>
                      <ListItemText primary={"No weekly maintenance plan"} />
                    </ListItem>
                  </List>
                )}
              </Box>
            )}
            {tab === 2 && (
              <Box className={classes.containerPlan}>
                {planMonth.length ? (
                  planMonth.map((plan, index) => (
                    <List className={classes.containerPlanList} key={index}>
                      <ListItem className={classes.containerPlanItem}>
                        <ListItemIcon>
                          <TimelineIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Every ${
                            plan.schedule_data.value < 2
                              ? `Month`
                              : `${plan.schedule_data.value} months`
                          }  on ${plan.schedule_data.days.map(
                            (day) => `${day}`
                          )}`}
                          secondary={`Skip weekends: ${
                            plan.schedule_data.skip_weekends ? `Yes` : `No`
                          }`}
                        />
                        {plan.schedule_data.end_date && (
                          <ListItemSecondaryAction
                            className={classes.containerPlanDate}
                          >
                            End Date:{" "}
                            {moment(plan.schedule_data.end_date).format("LL")}
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    </List>
                  ))
                ) : (
                  <List className={classes.containerPlanList}>
                    <ListItem>
                      <ListItemText primary={"No monthly maintenance plan"} />
                    </ListItem>
                  </List>
                )}
              </Box>
            )}
          </Box> */}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}></DialogActions>
      </Dialog>
    </>
  );
};

export default AssetMaintenance;
