export function RedQuestionMark() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_489_6935"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="32"
      >
        <rect x="0.5" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_489_6935)">
        <path
          d="M16.4327 23.9998C16.8993 23.9998 17.2938 23.8387 17.616 23.5165C17.9382 23.1943 18.0993 22.7998 18.0993 22.3332C18.0993 21.8665 17.9382 21.4721 17.616 21.1498C17.2938 20.8276 16.8993 20.6665 16.4327 20.6665C15.966 20.6665 15.5716 20.8276 15.2493 21.1498C14.9271 21.4721 14.766 21.8665 14.766 22.3332C14.766 22.7998 14.9271 23.1943 15.2493 23.5165C15.5716 23.8387 15.966 23.9998 16.4327 23.9998ZM15.2327 18.8665H17.6993C17.6993 18.1332 17.7827 17.5554 17.9493 17.1332C18.116 16.7109 18.5882 16.1332 19.366 15.3998C19.9438 14.8221 20.3993 14.2721 20.7327 13.7498C21.066 13.2276 21.2327 12.5998 21.2327 11.8665C21.2327 10.6221 20.7771 9.6665 19.866 8.99984C18.9549 8.33317 17.8771 7.99984 16.6327 7.99984C15.366 7.99984 14.3382 8.33317 13.5493 8.99984C12.7605 9.6665 12.2105 10.4665 11.8993 11.3998L14.0993 12.2665C14.2105 11.8665 14.4605 11.4332 14.8493 10.9665C15.2382 10.4998 15.8327 10.2665 16.6327 10.2665C17.3438 10.2665 17.8771 10.4609 18.2327 10.8498C18.5882 11.2387 18.766 11.6665 18.766 12.1332C18.766 12.5776 18.6327 12.9943 18.366 13.3832C18.0993 13.7721 17.766 14.1332 17.366 14.4665C16.3882 15.3332 15.7882 15.9887 15.566 16.4332C15.3438 16.8776 15.2327 17.6887 15.2327 18.8665ZM16.4993 29.3332C14.6549 29.3332 12.9216 28.9832 11.2993 28.2832C9.67713 27.5832 8.26602 26.6332 7.06602 25.4332C5.86602 24.2332 4.91602 22.8221 4.21602 21.1998C3.51602 19.5776 3.16602 17.8443 3.16602 15.9998C3.16602 14.1554 3.51602 12.4221 4.21602 10.7998C4.91602 9.17761 5.86602 7.7665 7.06602 6.5665C8.26602 5.3665 9.67713 4.4165 11.2993 3.7165C12.9216 3.0165 14.6549 2.6665 16.4993 2.6665C18.3438 2.6665 20.0771 3.0165 21.6993 3.7165C23.3216 4.4165 24.7327 5.3665 25.9327 6.5665C27.1327 7.7665 28.0827 9.17761 28.7827 10.7998C29.4827 12.4221 29.8327 14.1554 29.8327 15.9998C29.8327 17.8443 29.4827 19.5776 28.7827 21.1998C28.0827 22.8221 27.1327 24.2332 25.9327 25.4332C24.7327 26.6332 23.3216 27.5832 21.6993 28.2832C20.0771 28.9832 18.3438 29.3332 16.4993 29.3332Z"
          fill="#9A1010"
        />
      </g>
    </svg>
  );
}
