import React from "react";
import WorkticketIcon from "@material-ui/icons/Receipt";
import useStyles from "./styles";

const FieldWTSummary = (props) => {
  const classes = useStyles();
  const { field } = props;

  if (!field) {
    return null;
  }

  return (
    <div className={classes.wrapperSummary}>
      <WorkticketIcon className={classes.avatarSummary} /> {field}
    </div>
  );
};

export default FieldWTSummary;
