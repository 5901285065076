import React, { useState, useEffect, useRef } from "react";
import VideoPlayer from "components/common/File/videoPlayer";
import { Box, Typography, Button } from "@material-ui/core/index";

const vimeo = "https://vimeo.com/channels/41579/67609665";
const yt = "https://www.youtube.com/results?search_query=naturaleza+4k";
const mp4 =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

export const FileComponentsPage = () => {
  const playerRef = useRef(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);

  // Función para actualizar el estado del tiempo transcurrido
  const handleGetCurrentTime = (state) => {
    setCurrentTime(state.playedSeconds);
  };

  // Función para actualizar el estado de la duración total
  const handleGetDuration = (duration) => {
    setTotalTime(duration);
  };

  // Función para obtener el tiempo transcurrido cuando se hace clic en el botón
  const handleFetchCurrentTime = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      setCurrentTime(currentTime);
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      setIsReady(true);
    }
  }, [playerRef]);

  return (
    <Box mt={5} mb={2}>
      <Box>
        <Typography>Reproductor de Video</Typography>
        <VideoPlayer
          url={vimeo}
          playerRef={playerRef}
          isFullScreen={false}
          getCurrentTime={handleGetCurrentTime}
          getDuration={handleGetDuration}
        />
        <Box>
          <p>Tiempo total del video: {isReady ? totalTime : "Cargando..."}</p>
          <p>Tiempo transcurrido: {currentTime}</p>
          <Button
            onClick={handleFetchCurrentTime}
            variant="contained"
            color="primary"
          >
            Obtener Tiempo Transcurrido
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
