import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 80,
    paddingRight: 40,
    paddingLeft: 40,
  },
  optionContainer: {
    textAlign: "right",
  },
  settingButton: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
