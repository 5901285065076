import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import GlobalUiContext from "contexts/globalUiContext";
import { useCustomersDispatch } from "contexts/adminCustomersContext";
import CreateCustomerDialog from "./dialog/createCustomerDialog";
import { permissionCustomer, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
}));

const CustomersListActions = (props) => {
  const classes = useStyles();
  const dispatchContext = useCustomersDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleClick = () => {
    dispatchContext({
      type: "SET_OPEN_CREATE",
      openCreate: true,
    });
  };

  return (
    <Box className={classes.root}>
      {hasPermission(permissionCustomer.CREATE_EDIT, permissions) ? (
        <>
          <ButtonBase
            variant="outlined"
            color="secondary"
            onClick={handleClick}
          >
            Create Customer
          </ButtonBase>
          <CreateCustomerDialog />
        </>
      ) : null}
    </Box>
  );
};

export default withRouter(CustomersListActions);
