import React, { Fragment, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddContactIcon from "../icons/AddContactIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useStyles from "../styles";
import Box from "@material-ui/core/Box";
import { useUserDrawerContext } from "../context/UserDrawerContext";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { usePartnerUsers } from "./useUsers";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import { LocationItem } from "./LocationItem";
import { useLocation } from "./useLocation";
import { useGetUserId } from "../context/UserIdContext";
import Integer from "lodash";

export function AddLocationDialog() {
  const classes = useStyles();
  const [selectedLocations, setSelectedLocations] = useState({});
  const { userId } = useGetUserId();
  const { jobs, transformJobData } = usePartnerUsers();
  const { jobsValue, assignUserLocationMutation, handleSingleJobChange } =
    useLocation();
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);

  const [assignSameLocation, setAssignSameLocation] = React.useState(true);
  const { handleLocationDialogueClose, isLocationDialogOpen, selectedUsers } =
    useUserDrawerContext();
  const [, setArrayOfDataLocations] = useState([]);
  useEffect(() => {
    const arrayOfDataLocations = selectedUsers.map((user) => {
      return {
        id: user.id,
        location: 1,
      };
    });
    setArrayOfDataLocations(arrayOfDataLocations);
  }, [selectedUsers]);

  const handleSubmitLocations = () => {
    if (assignSameLocation) {
      let user_ids = [];
      selectedUsers.forEach((user) => {
        user_ids.push(Integer.parseInt(user.id));
      });
      const dummyData = {
        data: {
          user_ids: user_ids,
          job_ids: jobsValue.map((item) => Integer.parseInt(item.value)),
        },
        partner_id: userId,
      };
      assignUserLocationMutation.mutate(dummyData);
    } else {
      Object.keys(selectedLocations).forEach((userToAddId) => {
        const dummyData = {
          data: {
            user_ids: [Integer.parseInt(userToAddId)],
            job_ids: selectedLocations[userToAddId],
          },
          partner_id: userId,
          userId: userToAddId,
        };
        assignUserLocationMutation.mutate(dummyData);
      });
    }
  };

  const SuccessForm = () => {
    return (
      <Fragment>
        <DialogTitle
          className={classes.dialogTitleWrapper}
          id="form-dialog-title"
        >
          <IconButton
            onClick={handleLocationDialogueClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.userCreatedSuccessMainContainer}>
          <Box className={classes.userSuccessIconTextContainer}>
            <SuccessIcon />
            <Typography className={classes.userCreatedSuccessText}>
              Success!
            </Typography>
          </Box>
          <Typography className={classes.userCreatedInfoText}>
            These locations assignments are being processed and will be complete
            in a few minutes.{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.okaySuccessButton}
            onClick={() => {
              handleLocationDialogueClose();
              setSuccessDialogOpen(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Fragment>
    );
  };
  return (
    <Dialog open={isLocationDialogOpen} aria-labelledby="form-dialog-title">
      {!isSuccessDialogOpen ? (
        <Fragment>
          <DialogTitle
            className={classes.dialogTitleWrapper}
            id="form-dialog-title"
          >
            <Box className={classes.dialogTitleInnerWrapper}>
              <AddContactIcon />
              <h3 className={classes.dialogTitle}>Assign Location</h3>
            </Box>
            <IconButton
              onClick={handleLocationDialogueClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.addLocationFormWrapper}>
            {selectedUsers.length > 1 && (
              <Typography>
                Do you want to assign these ({selectedUsers.length}) users
                locations?
              </Typography>
            )}
            {selectedUsers.length === 1 && (
              <Typography>
                Do you want to assign this user a location?
              </Typography>
            )}
            {assignSameLocation ? (
              <FormSelectAuto
                label={
                  selectedUsers.length > 1
                    ? "Select locations for selected users"
                    : "Select locations for selected user"
                }
                options={jobs?.length ? transformJobData(jobs) : []}
                gridSizes={[{ size: "md", val: 12 }]}
                value={jobsValue}
                handleChange={(event, value) => handleSingleJobChange(value)}
                name="name"
                multiple={true}
              />
            ) : (
              selectedUsers.map((user, index) => (
                <LocationItem
                  index={index}
                  userId={user.id}
                  user={user}
                  setSelectedLocations={setSelectedLocations}
                  selectedLocations={selectedLocations}
                />
              ))
            )}
            {selectedUsers.length > 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={assignSameLocation}
                    onChange={() => setAssignSameLocation(!assignSameLocation)}
                    name="assignSameLocation"
                    color="primary"
                  />
                }
                label="Same location for all selected users"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.addContactCancelButton}
              onClick={handleLocationDialogueClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.secondAddContactButton}
              onClick={() => {
                handleSubmitLocations();
                setSuccessDialogOpen(true);
              }}
            >
              Assign
            </Button>
          </DialogActions>
        </Fragment>
      ) : (
        <SuccessForm />
      )}
    </Dialog>
  );
}
