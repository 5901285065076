import http from "./httpService";
import { apiUrl } from "../lib/config";
const apiEndpoint = apiUrl + "/generators";

export function getGenerator(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function saveGenerator(data) {
  return http.post(`${apiUrl}/generator`, data);
}

export function updateGenerator(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function deleteGenerator(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function assignUsersGenerator(id, data) {
  return http.post(`${apiEndpoint}/${id}/bulk-assign-users`, data);
}

export function removeUsersGenerator(id, data) {
  return http.post(`${apiEndpoint}/${id}/bulk-remove-users`, data);
}
