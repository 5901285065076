import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import TriggersListTable from "./triggersListTable";
import TriggersListDialog from "./dialog/triggersListDialog";
import { TriggersProvider } from "contexts/triggersTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const TriggersList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Surveys" link="/surveys/admin/triggers" />
      <Box className={classes.pageBodyWrapper}>
        <TriggersProvider>
          <TriggersListTable />
          <TriggersListDialog />
        </TriggersProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(TriggersList);
