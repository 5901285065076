import React from "react";
import Chip from "@material-ui/core/Chip";
import useStyles from "./styles";

const RequestSubmittedBy = (props) => {
  const classes = useStyles();
  const { user_id, env } = props;

  if (!user_id) {
    return (
      <Chip
        variant="outlined"
        label={"Occupant"}
        className={classes.chipInternal}
      />
    );
  }

  return (
    <Chip
      variant="outlined"
      label={env === "surface" ? "Internal" : "Customer"}
      className={classes.chipInternal}
    />
  );
};

export default RequestSubmittedBy;
