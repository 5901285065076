import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/learn/tiles`;
const apiEndpointCat = `${apiUrl}/learn/category`;
const apiEndpointSingular = `${apiUrl}/learn/tile`;
const apiEndpointCatPlural = `${apiUrl}/learn/categories`;

export function getTilesList(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getTilesListSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getTilesListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getLearnData() {
  return http.get(`${apiEndpoint}/create`, {});
}

export function createCategory(data) {
  http.setFormEncode();
  return http.post(`${apiEndpointCat}`, data);
}

export function createTile(data) {
  http.setMultiPart();
  return http.post(`${apiEndpointSingular}`, setFormData(data));
}

export function deleteTile(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function updateTile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}`, setFormData(data));
}

export function updateCategory(id, data) {
  http.setFormEncode();
  return http.put(`${apiEndpointCatPlural}/${id}`, data);
}

export function deleteCategory(id) {
  http.setFormEncode();
  return http.delete(`${apiEndpointCatPlural}/${id}`);
}
