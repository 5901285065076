import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";

import {
  getFilterJobs,
  getFilterCustomers,
  getFilterIndustries,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import useStyles from "components/common/Filters/styles";

const LocationsFilters = (props) => {
  const classes = useStyles();
  const [jobFilters, setJobFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [allJobs, setAllJobs] = useState(false);
  const [includeChild] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { removeJobs, removeCustomers } = props;

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length && !removeJobs) {
      loadJob();
    }
  }, [anchorEl, jobs, removeJobs]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (anchorEl && !customers) {
      loadCustomer();
    }
  }, [anchorEl, customers]);

  useEffect(() => {
    const loadIndustries = async () => {
      try {
        const resultForRender = await getFilterIndustries();
        setIndustries(resultForRender);
      } catch (e) {
        logException(e, "Cannot load industry data");
      }
    };
    if (anchorEl && !industries) {
      loadIndustries();
    }
  }, [anchorEl, industries]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
      if (customers?.length) {
        const selectedCustomersFilter = filters.find(
          (filter) => filter.filter === "customer"
        );
        if (selectedCustomersFilter) {
          setCustomerFilters(selectedCustomersFilter.values);
        } else {
          setCustomerFilters([]);
        }
      }
      if (industries?.length) {
        const selectedIndustriesFilter = filters.find(
          (filter) => filter.filter === "industry"
        );
        if (selectedIndustriesFilter) {
          setIndustryFilters(selectedIndustriesFilter.values);
        } else {
          setIndustryFilters([]);
        }
      }

      const selectedAllJobsFilter = filters.find(
        (filter) => filter.filter === "all_jobs"
      );
      setAllJobs(selectedAllJobsFilter ? true : false);
    }
  }, [anchorEl, filters, jobs, customers, industries]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (customerFilters.length) {
      filterData.push({
        filter: "customer",
        group: "Customer",
        values: [...customerFilters],
      });
    }

    if (industryFilters.length) {
      filterData.push({
        filter: "industry",
        group: "Industry",
        values: [...industryFilters],
      });
    }

    if (jobFilters.length) {
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
          id: job.id,
          job_type: job?.job_type,
          parent_job_id: job?.parent_job_id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    if (allJobs) {
      filterData.push({
        filter: "all_jobs",
        group: "All Jobs",
        values: [{ label: "Yes", value: 1 }],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    if (!includeChild) {
      setJobFilters(value);
      return;
    }

    let mode = "add";
    if (value.length < jobFilters.length) {
      mode = "remove";
    }

    if (mode === "add") {
      const jobCurrent = jobFilters.map((job) => job.id);
      const jobNew = value.find((job) => !jobCurrent.includes(job.id));
      let children = [];
      if (jobNew && Number(jobNew?.job_type) === 1) {
        children = jobs.filter(
          (job) => Number(job?.parent_job_id) === Number(jobNew?.id)
        );
      }
      setJobFilters([...value, ...children]);
      return;
    }

    if (mode === "remove") {
      const jobCurrent = value.map((job) => job.id);
      const jobRemove = jobFilters.find((job) => !jobCurrent.includes(job.id));
      let valueNew = value;
      if (jobRemove && Number(jobRemove?.job_type) === 1) {
        valueNew = value.filter(
          (job) => Number(job?.parent_job_id) !== Number(jobRemove?.id)
        );
      }
      setJobFilters(valueNew);
      return;
    }
  };

  const handleChangeCustomer = (event, value) => {
    setCustomerFilters(value);
  };

  const handleChangeIndustry = (event, value) => {
    setIndustryFilters(value);
  };

  const handleChangeJobAll = () => {
    setAllJobs(!allJobs);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Industry
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
              {industries && Boolean(industries.length) ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={industries}
                  name="customer"
                  handleChange={handleChangeIndustry}
                  multiple={true}
                  value={industryFilters}
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
            </Grid>
          </Grid>
          {!removeCustomers && !Boolean(jobFilters.length) && (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {customers && Boolean(customers.length) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={customers}
                    name="customer"
                    handleChange={handleChangeCustomer}
                    multiple={true}
                    value={customerFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          )}
          {!removeJobs && !Boolean(customerFilters.length) ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Job
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.autoContainerFilter}>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    label="Job"
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={jobFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allJobs}
                    onChange={handleChangeJobAll}
                    className={classes.filterCheckbox}
                  />
                }
                label="Show all my jobs"
              />
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default LocationsFilters;
