import React, { Fragment } from "react";
import { ContactList } from "./ContactList";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "../../styles";
import { useAddContactModal } from "../../context/AddContactDialogContext";
import { AddContactDialog } from "./AddContactDialog";
import { useContacts } from "./useContacts";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";

export function ContactPage() {
  const classes = useStyles();
  const { contactsList, contactsIsLoading } = useContacts();
  const [contactAction, dispatchContactAction] = useAddContactModal();

  return (
    <Fragment>
      {contactsIsLoading ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <Typography className={classes.companyContactsSubheader}>
            Company Contacts
          </Typography>
          {contactsList &&
            contactsList.length > 0 &&
            contactsList.map((contact, index) => (
              <ContactList
                classes={classes}
                contact={contact}
                index={index}
                key={index}
              />
            ))}
          <Box className={classes.addContactButtonWrapper}>
            <Button
              className={classes.addContactButton}
              onClick={() => {
                dispatchContactAction({
                  type: "TOGGLE_CONTACT_DIALOG",
                  open: true,
                });
              }}
            >
              Add New Contact
            </Button>
          </Box>
          <AddContactDialog />
        </Fragment>
      )}
    </Fragment>
  );
}
