import React, { useState, useEffect } from "react";
import moment from "moment";
import * as classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AssetTask from "./assetTask";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import MessageDialog from "components/ui/dialog/messageDialog";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import AssetsIcon from "@material-ui/icons/Toys";
import { logException } from "components/util/logUtil";
import {
  saveAsset,
  getAssetCategories,
  getAssetJobs,
} from "services/assetService";

const useStyles = makeStyles((theme) => ({
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  formContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  chipJob: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerJobInput: {
    "& > div": {
      marginBottom: 10,
    },
    "& input": {
      fontSize: 14,
    },
  },
  containerError: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    textAlign: "center",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
}));

const initNewData = {
  name: "",
  description: "",
  zone: "",
  warrantyIniDate: moment(),
  warrantyEndDate: moment(),
  warrantyOther: "",
  brand: "",
  serial: "",
  model: "",
  tag: "",
  tasks: null,
};

const AssetsManage = (props) => {
  const classes = useStyles();
  const { open, handleClose, handleUpdateDate } = props;
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [categoriesUsed, setCategoriesUsed] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [customer, setCustomer] = useState(0);
  const [step, setStep] = useState(1);
  const [newData, setNewData] = useState(initNewData);
  const [files, setFiles] = useState([]);
  const [updateFile, setUpdateFile] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setNewData(initNewData);
      setJobsList([]);
      setFiles([]);
      setCategoriesSelected([]);
      setCategoriesUsed([]);
    }
  }, [open]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await getAssetCategories();
        const renderCategories = result.data.data.categories.map((category) => {
          return { value: category.id, label: category.name };
        });
        setCategories(renderCategories);
      } catch (e) {
        logException(e, "Cannot load categories");
      }
    };
    if (open && categories && !categories.length) {
      loadCategories();
    }
  }, [open, categories]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const resultJob = await getAssetJobs();
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer ? job.customer.id : 0,
          };
        });
        setJobsData(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs");
      }
    };
    if (open && jobsData && !jobsData.length) {
      loadJobs();
    }
  }, [open, jobsData]);

  useEffect(() => {
    const loadJobsCustomer = async () => {
      try {
        const findJob = jobsData.find((job) => job.id === jobsList[0].id);
        const resultJob = await getAssetJobs(findJob.customer_id);
        setCustomer(findJob.customer_id);
        const updateJobList = Object.keys(resultJob.data.data.jobs).map(
          (i) => resultJob.data.data.jobs[i]
        );
        const resultForRender = updateJobList.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer ? job.customer.id : 0,
          };
        });
        setJobsData(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer jobs");
      }
    };
    if (jobsList.length === 1 && customer === 0) {
      loadJobsCustomer();
    }
  }, [jobsList, jobsData, customer]);

  const handleCreateBlur = async (event) => {
    setError("");
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleWarrantyIniDate = async (event) => {
    const data = {
      ...newData,
      warrantyIniDate: event,
    };
    setNewData(data);
  };

  const handleWarrantyEndDate = async (event) => {
    const data = {
      ...newData,
      warrantyEndDate: event,
    };
    setNewData(data);
  };

  const handleCreate = async () => {
    if (newData.name === "") {
      setError("Enter name for the asset.");
      return;
    }
    if (newData.description === "") {
      setError("Enter description for the asset.");
      return;
    }
    if (!categoriesUsed.length) {
      setError("Select at least one category for the asset.");
      return;
    }
    if (!jobsList.length) {
      setError("Select at least one job for the asset.");
      return;
    }
    try {
      const dataSave = {
        ...newData,
        warrantyIniDate: moment(newData.warrantyIniDate).format("YYYY-MM-DD"),
        warrantyEndDate: moment(newData.warrantyEndDate).format("YYYY-MM-DD"),
        category_ids: categoriesUsed,
        job_ids: jobsList.map((job) => job.id),
        files: files,
        tasks: newData.tasks ? JSON.stringify(newData.tasks) : null,
      };
      await saveAsset(dataSave);
      handleUpdateDate();
      setOpenSuccess(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot create asset");
    }
  };

  const handleChangeCategories = (event, value) => {
    setError("");
    setCategoriesUsed(value.map((val) => val.value));
    setCategoriesSelected(value ?? []);
  };

  const handleChangeJob = (event, value) => {
    setError("");
    if (value) {
      jobsList.push(value);
      setJobsList([...jobsList]);
    }
  };

  const handleJobDelete = (id) => {
    const updateJobsList = jobsList.filter((job) => job.id !== id);
    setJobsList(updateJobsList);
    if (!updateJobsList.length) {
      setJobsData([]);
      setCustomer(0);
    }
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleStepChange = (step) => {
    setError("");
    setStep(step);
  };

  const handleChangeTask = (task) => {
    const data = {
      ...newData,
      tasks: task,
    };
    setNewData(data);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DialogCloseIcon handleClose={handleClose} />
          <DialogTitle title="Create New Asset" icon={<AssetsIcon />} />
          <Box className={classes.formContainerDialog}>
            <ButtonGroup
              variant="outlined"
              color="default"
              aria-label="primary button group"
              className={classes.groupOption}
              fullWidth={true}
            >
              <Button
                key={`step-1`}
                className={step === 1 ? classes.groupOptionActive : null}
                onClick={() => handleStepChange(1)}
              >
                Information
              </Button>
              <Button
                key={`step-3`}
                className={step === 3 ? classes.groupOptionActive : null}
                onClick={() => handleStepChange(3)}
              >
                Tasks
              </Button>
              <Button
                key={`step-2`}
                className={step === 2 ? classes.groupOptionActive : null}
                onClick={() => handleStepChange(2)}
              >
                Documents
              </Button>
            </ButtonGroup>
          </Box>
          {step === 1 ? (
            <Box className={classes.formContainerDialog}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                label="Name"
                value={newData.name}
                handleBlur={handleCreateBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="description"
                label="Description"
                value={newData.description}
                handleBlur={handleCreateBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="zone"
                label="Zone"
                value={newData.zone}
                handleBlur={handleCreateBlur}
              />
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={categories}
                name="categories"
                label="Categories"
                handleChange={handleChangeCategories}
                multiple={true}
                value={categoriesSelected}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="brand"
                label="Brand Name"
                value={newData.brand}
                handleBlur={handleCreateBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="serial"
                label="Serial Number"
                value={newData.serial}
                handleBlur={handleCreateBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="model"
                label="Model #"
                value={newData.model}
                handleBlur={handleCreateBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="tag"
                label="Asset Tag #"
                value={newData.tag}
                handleBlur={handleCreateBlur}
              />
              <FormInputDateAdo
                name="warrantyIniDate"
                label="Warranty Begin Date"
                handleChange={handleWarrantyIniDate}
                value={newData.warrantyIniDate}
              />
              <FormInputDateAdo
                name="warrantyEndDate"
                label="Warranty End Date "
                handleChange={handleWarrantyEndDate}
                value={newData.warrantyEndDate}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="warrantyOther"
                label="Warranty Notes"
                value={newData.warrantyOther}
                handleBlur={handleCreateBlur}
              />

              <Box className={classes.containerJobInput}>
                <FormSelectJobsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={jobsData}
                  name="job_number"
                  label="Jobs"
                  multiple={false}
                  handleChange={handleChangeJob}
                />
              </Box>
              <Box className={classes.containerSelectedElements}>
                {jobsList.map((job) => (
                  <Chip
                    key={job.id}
                    size="small"
                    label={`${job.job_number} - ${job.job_description}`}
                    onDelete={() => handleJobDelete(job.id)}
                    className={classes.chipJob}
                  />
                ))}
              </Box>
            </Box>
          ) : null}
          {step === 2 ? (
            <Box className={classes.formContainerDialog}>
              {!Boolean(files.length) && (
                <Typography
                  variant="h4"
                  className={classes.titleSectionBody}
                  gutterBottom
                >
                  Documents
                </Typography>
              )}
              <FileUpload
                value={files}
                handleChange={setFiles}
                handleUpdate={setUpdateFile}
                filesDisplay={
                  Boolean(updateFile) && (
                    <FilesDisplay files={files} handleRemove={handleRemove} />
                  )
                }
              />
            </Box>
          ) : null}
          {step === 3 ? (
            <Box className={classes.formContainerDialog}>
              <AssetTask taskList={newData.tasks} onChange={handleChangeTask} />
            </Box>
          ) : null}
        </DialogContent>

        {Boolean(error) && (
          <Box className={classes.containerError}>{error}</Box>
        )}
        <DialogActions className={classes.actionsDialogWrapper}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonPrimary)}
            onClick={handleCreate}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Asset has been created.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default AssetsManage;
