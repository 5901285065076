import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useIndividualView } from "contexts/individualViewContext";

const IndividualViewBreadcrum = () => {
  const [stateContext] = useIndividualView();
  const { individual, isLoading } = stateContext ? stateContext : null;

  if (isLoading && individual !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <PageHeader
      pageTitle={
        individual ? `${individual.first_name} ${individual.last_name}` : ""
      }
    >
      <Link to="/team">Team</Link>
    </PageHeader>
  );
};

export default IndividualViewBreadcrum;
