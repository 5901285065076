import Box from "@material-ui/core/Box";
import PageHeader from "components/common/PageHeader/pageHeader";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { FrontlineSurveyProvider } from "contexts/surveyFrontlineContext";
import React from "react";
import { Link } from "react-router-dom";
import SurveyTabTable from "./TabTable";
import useStyles from "./styles";

const SurveyTab = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Survey Frontline"
        link="/survey-frontline?location"
      >
        <Link to="/dashboard">Dashboard</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <FrontlineSurveyProvider>
          <SurveyTabTable />
        </FrontlineSurveyProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(SurveyTab);
