import React, { useState, useRef } from "react";
import { ButtonBase } from "components/ui/core/button";
import SignatureCanvas from "react-signature-canvas";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { storeSurveyFiles } from "services/surveyFrontlineService";
import { logException } from "components/util/logUtil";
import { dataURLtoFile } from "components/util/fileUtil";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const SignatureDialog = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const signRef = useRef();
  const {
    openSignature,
    signature,
    selectedQuestions,
    correlative,
    lang,
    isPreview,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const handleClose = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SIGNATURE",
      openSignature: false,
    });
    if (edit) {
      setEdit(false);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleSaveSign = async () => {
    if (!isPreview && !readonlySurvey) {
      try {
        setIsLoadingSignature(true);
        const data = {
          is_signature: 1,
          "files[]": dataURLtoFile(signRef.current.toDataURL(), "file"),
        };
        const result = await storeSurveyFiles(
          selectedQuestions[correlative].qa_survey_submit_id,
          data
        );
        const signatureFile = result.data.data.survey.files.filter(
          (file) => file.pivot.is_signature
        );
        signRef.current.clear();
        if (signatureFile.length > 0) {
          dispatchSurveyFill({
            type: "SET_SIGNATURE",
            signature: signatureFile[0],
          });
        }
        handleClose();
        setOpenSuccess(true);
        setIsLoadingSignature(false);
      } catch (e) {
        logException(e, "Cannot save signature");
      }
    } else {
      handleClose();
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleUpdateSign = async () => {
    try {
      setIsLoadingSignature(true);
      const data = {
        is_signature: 1,
        "files[]": dataURLtoFile(signRef.current.toDataURL(), "file"),
        "deleteFiles[]": signature.id,
      };
      const result = await storeSurveyFiles(
        selectedQuestions[correlative].qa_survey_submit_id,
        data
      );
      const signatureFile = result.data.data.survey.files.filter(
        (file) => file.pivot.is_signature
      );
      signRef.current.clear();
      if (signatureFile.length > 0) {
        dispatchSurveyFill({
          type: "SET_SIGNATURE",
          signature: signatureFile[0],
        });
      } else {
        dispatchSurveyFill({
          type: "SET_SIGNATURE",
          signature: null,
        });
      }
      handleClose();
      setOpenSuccess(true);
      setIsLoadingSignature(false);
    } catch (e) {
      logException(e, "Cannot save signature");
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openSignature}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          {signature && !edit && !readonlySurvey ? (
            <IconButton
              aria-label="close"
              onClick={handleEdit}
              className={classes.wrapperEdit}
            >
              <EditIcon className={classes.iconClose} />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Typography variant="h4" className={classes.titleDialogSign}>
            {signature
              ? surveysLabelsLang[lang].signatureLabel
              : surveysLabelsLang[lang].signatureCreateLabel}
          </Typography>
          {signature && !edit ? (
            <img
              src={signature.file_url}
              alt="signature"
              className={classes.imgSignature}
            />
          ) : (
            <SignatureCanvas
              ref={signRef}
              canvasProps={{ className: classes.sigCanvas }}
            />
          )}
        </DialogContent>
        {!isLoadingSignature ? (
          <DialogActions className={classes.actionsDialogWrapperSign}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              {signature
                ? surveysLabelsLang[lang].signatureCloseLabel
                : surveysLabelsLang[lang].signatureCancelLabel}
            </ButtonBase>
            {!signature && !readonlySurvey && (
              <ButtonBase
                color="secondary"
                disableElevation={true}
                onClick={handleSaveSign}
              >
                {surveysLabelsLang[lang].signatureSignLabel}
              </ButtonBase>
            )}
            {edit && !readonlySurvey && (
              <ButtonBase
                color="secondary"
                disableElevation={true}
                onClick={handleUpdateSign}
              >
                {surveysLabelsLang[lang].signatureUpdateLabel}
              </ButtonBase>
            )}
          </DialogActions>
        ) : (
          <DialogActions className={classes.actionsDialogWrapperSign}>
            <LoadingStateHorizontal isVisible />
          </DialogActions>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title={surveysLabelsLang[lang].sucessMsgHeaderLabel}
        message={surveysLabelsLang[lang].signatureMsgLabel}
      />
    </>
  );
};
export default SignatureDialog;
