import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const FieldName = (props) => {
  const classes = useStyles();
  const { row } = props;

  if (!row) {
    return null;
  }

  const firstName = row.user ? row.user.first_name : row.first_name;
  const lastName = row.user ? row.user.last_name : row.last_name;

  return (
    <Box className={classes.wrapperName}>
      {row.user?.profile?.picture ? (
        <Avatar
          src={row.user.profile.picture.file_url}
          className={classes.avatar}
        />
      ) : row.photo ? (
        <Avatar src={row.photo} className={classes.avatar} />
      ) : (
        <Avatar className={classes.avatar}>
          {firstName[0]}
          {lastName[0]}
        </Avatar>
      )}
      <Box className={classes.avatarContainer}>
        {firstName} {lastName}
      </Box>
    </Box>
  );
};

export default FieldName;
