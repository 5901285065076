import React from "react";
import Popover from "@material-ui/core/Popover";
import CommentIcon from "@material-ui/icons/Comment";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const Note = (props) => {
  const classes = useStyles();
  const { notes, icon } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!notes || notes === "") {
    return null;
  }

  const handlePopoverOpen = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        variant="body2"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.label}
      >
        {icon ? icon : <CommentIcon />}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        className={classes.poper}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={2}
      >
        {notes}
      </Popover>
    </>
  );
};

export default Note;
