import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import SendIcon from "@material-ui/icons/Send";
import Skeleton from "@material-ui/lab/Skeleton";
import CommonDialog from "../../components/common/CommonDialog";
import FormInput from "../../components/ui/FormContent/formInput";
import { getContacts, sendQuote } from "../../services/quoteService";
import { useQuoteState, useQuoteDispatch } from "../../contexts/quoteContext";
import { permissionQuote, hasPermission } from "../../lib/permissions";
import GlobalUiContext from "../../contexts/globalUiContext";
import MessageDialog from "./dialog/messageDialog";
import PrintQuoteDialog from "./dialog/printQuoteDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";

import useStyles from "./styles";

const initialData = {
  contact: [],
  subject: "",
  message: "",
};

const QuoteActions = (props) => {
  const classes = useStyles();
  const stateContext = useQuoteState();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const readOnlyStatus = [5, 20];
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const hasCreate = hasPermission(permissionQuote.CREATE, permissions);

  const { quoteDetails, isLoading } = stateContext ? stateContext : null;

  const [data, setData] = React.useState(initialData);

  const dispatch = useQuoteDispatch();

  const handleBlur = async (event) => {
    if (data[event.name] !== event.value) {
      const newData = {
        ...data,
        [event.name]: event.value,
      };
      setData(newData);
    }
  };

  const handleChangeContact = (event, value) => {
    const newData = {
      ...data,
      contact: value,
    };
    setData(newData);
  };

  const handleOpenPdf = async () => {
    dispatch({
      type: "TOGGLE_OPEN_PRINT",
      openPrint: true,
    });
  };

  const closeDialog = () => {
    setIsOpenDialog(false);
    setData(initialData);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const openDialog = async () => {
    setIsOpenDialog(true);
    setIsLoadingDialog(true);
    const result = await getContacts(quoteDetails.id);
    const resultForRender = result.data.data.contacts.map((item) => {
      return {
        value: item.id,
        type: item?.role_id ? 1 : 2,
        label: `${item.first_name} ${item.last_name}`,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
      };
    });
    setContacts(resultForRender);
    setIsLoadingDialog(false);
  };

  const handleSend = async () => {
    setIsLoadingDialog(true);
    const result = await sendQuote(quoteDetails.id, data);
    if (!readOnlyStatus.includes(quoteDetails.status)) {
      quoteDetails["status"] = 1;
      dispatch({
        type: "SET_QUOTE_DETAILS",
        quoteDetails,
      });
    }
    closeDialog();
    setIsLoadingDialog(false);
    setOpenMessage(true);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return quoteDetails.type !== 1 ? (
    <Box className={classes.containerActionButtons}>
      <Grid container spacing={2}>
        {(hasEdit || hasCreate) && (
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={true}
              onClick={openDialog}
              startIcon={<SendIcon />}
              className={classNames(classes.button, classes.buttonOutlined)}
            >
              Send
            </Button>
          </Grid>
        )}
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={true}
            startIcon={<PdfIcon />}
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleOpenPdf}
            disabled={loadingPdf}
          >
            Print PDF
          </Button>
        </Grid>
      </Grid>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={"Quote has been sent successfully."}
      />

      <PrintQuoteDialog />

      <CommonDialog
        open={isOpenDialog}
        cancelMessage="Cancel"
        confirmMessage="Send"
        handleCancel={closeDialog}
        handleConfirm={handleSend}
        maxWidth="sm"
        variant={true}
        icon={<SendIcon />}
        noButtons={isLoadingDialog}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12} md={12} className={classes.itemDialog3}>
              <FormSelectAuto
                label="Customer Contact"
                gridSizes={[{ size: "xs", val: 12 }]}
                options={contacts}
                name="contact"
                multiple={true}
                handleChange={handleChangeContact}
                value={data.contact}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.itemDialog4}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                className={classes.field}
                name="subject"
                label="Subject"
                internal={true}
                value={data.subject}
                sameLine={true}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.itemDialog4}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                className={classes.field}
                name="message"
                label="Message"
                value={data.message}
                handleBlur={handleBlur}
                internal={true}
                multiline={true}
                sameLine={true}
                rows={4}
                alignText="center"
              />
            </Grid>
          </Grid>
        )}
      </CommonDialog>
    </Box>
  ) : (
    ""
  );
};

export default QuoteActions;
