import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {
  getFilterCustomers,
  getFilterJobsCustomer,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import AccidentFollowup from "pages/forms/AccidentFollowup";
import AccidentInvestigation from "pages/forms/AccidentInvestigation";
import EquipmentTransferRequest from "pages/forms/EquipmentTransferRequest";
import InjuryIllness from "pages/forms/InjuryIllness";
import RepairRequestForm from "pages/forms/RepairRequestForm";
import TalentChangeRequest from "pages/forms/TalentChangeRequest";
import React, { useContext, useEffect, useState } from "react";
import {
  getPositionTitle,
  submitFormDataBasedOnCurrentTab,
} from "services/formsService";
import useStyles from "./styles";

const TalentRequest = ({
  setOpenWeeklySchedule,
  weeklyScheduleData,
  setOpenFormsRequest,
  setReload,
  tabUpdated,
  setTabUpdated,
  setWeeklyScheduleData,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
}) => {
  const submitFormDataBasedOnCurrentTabNew = (...args) => {
    return submitFormDataBasedOnCurrentTab(...args).then((res) => {
      if (res.status === 201) {
        setTabUpdated({
          ...tabUpdated,
          [Math.floor(formSelected)]: ++tabUpdated[Math.floor(formSelected)],
        });
      }
      return res;
    });
  };
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState("");
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const userOptions = [];
  users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  const totalSteps = {
    1: 3,
    2.1: 1,
    2.2: 3,
    3.1: 3,
    3.2: 4,
    3.3: 2,
  };
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    getPositionTitle().then((res) => {
      let transformed = [];
      const response = res?.data?.data?.open_positions_titles?.map((v) => {
        transformed.push({ label: v?.name, value: v?.number });
      });
      setJobTitleOptions(transformed);
    });
  }, []);
  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers.length) {
      loadCustomer();
    }
  }, [customers]);
  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobsCustomer(
          customerSelected?.value
        );
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, [customerSelected?.value]);
  return (
    <Box>
      <Box className={classes.step}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenFormsRequest(false);
            setWeeklyScheduleData("");
          }}
          className={classes.closeIcon}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>

        <Typography className={classes.currentTab}>
          Step {currentStep}/{totalSteps[formSelected]}
        </Typography>
        <Typography className={classes.talentRequest}>
          {formSelected === 1 ? "Talent Change Request" : ""}
          {formSelected === 2.1 ? "Equipment Repair Request" : ""}
          {formSelected === 2.2 ? "Asset Transfer/Repair Request" : ""}
          {formSelected === 3.1 ? "First Report of Injury" : ""}
          {formSelected === 3.2 ? "Accident Investigation" : ""}
          {formSelected === 3.3 ? "Accident Follow up" : ""}
        </Typography>
        <Typography className={classes.tellMore}>
          {formSelected === 1
            ? "Input additional details about your change request."
            : ""}
          {formSelected === 2.1
            ? "Input additional details about your equipment repair."
            : ""}
          {formSelected === 2.2
            ? "Input details about the asset you need to transfer/repair."
            : ""}
          {formSelected === 3.1
            ? "Input details about the injury that occurred."
            : ""}
          {formSelected === 3.2
            ? "Input details about the accident investigation."
            : ""}
          {formSelected === 3.3
            ? "Input follow up information about the accident."
            : ""}
        </Typography>
      </Box>
      {currentTab === 1 && (
        <Box className={classes.newFormAdj}>
          {formSelected === 1 && (
            <TalentChangeRequest
              setOpenWeeklySchedule={setOpenWeeklySchedule}
              weeklyScheduleData={weeklyScheduleData}
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}

          {formSelected === 2.1 && (
            <RepairRequestForm
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}
          {formSelected === 2.2 && (
            <EquipmentTransferRequest
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}

          {formSelected === 3.1 && (
            <InjuryIllness
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}
          {formSelected === 3.2 && (
            <AccidentInvestigation
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}
          {formSelected === 3.3 && (
            <AccidentFollowup
              submitFormDataBasedOnCurrentTab={
                submitFormDataBasedOnCurrentTabNew
              }
              topTab={topTab}
              formSelected={formSelected}
              closeAndRefreshSidebar={closeAndRefreshSidebar}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={totalSteps[formSelected]}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
export default TalentRequest;
