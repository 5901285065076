import React from "react";

const ThreeDots = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_455_3825)">
      <path
        d="M6.6084 10C5.5084 10 4.6084 10.9 4.6084 12C4.6084 13.1 5.5084 14 6.6084 14C7.7084 14 8.6084 13.1 8.6084 12C8.6084 10.9 7.7084 10 6.6084 10ZM18.6084 10C17.5084 10 16.6084 10.9 16.6084 12C16.6084 13.1 17.5084 14 18.6084 14C19.7084 14 20.6084 13.1 20.6084 12C20.6084 10.9 19.7084 10 18.6084 10ZM12.6084 10C11.5084 10 10.6084 10.9 10.6084 12C10.6084 13.1 11.5084 14 12.6084 14C13.7084 14 14.6084 13.1 14.6084 12C14.6084 10.9 13.7084 10 12.6084 10Z"
        fill="#747474"
      />
    </g>
    <defs>
      <clipPath id="clip0_455_3825">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.608398)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ThreeDots;
