import React from "react";
import * as classnames from "classnames";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { default as useStyles, StyledTabs } from "./stylesTabs";

import { useTableState } from "contexts/tableContext";

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TableTabs = (props) => {
  const classes = useStyles();
  const { currentTab } = useTableState();
  const { value, className } = props;

  return (
    <AppBar position="static" color="default" className={classes.tabBarAppBar}>
      <StyledTabs
        value={value || value === 0 ? value : currentTab}
        onChange={props.handleTabChange}
        indicatorColor="primary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        className={classnames(classes.root, className)}
      >
        {props.children}
      </StyledTabs>
    </AppBar>
  );
};

export default TableTabs;
