import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  surveyStart,
} from "contexts/surveyFillContext";
import { submitSurvey } from "services/surveyFrontlineService";
import ReplayIcon from "@material-ui/icons/Replay";
import BasicInfoDialog from "./basicInfoDialog";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";

const SubmitDialog = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isOpenPublic, setIsOpenPublic] = useState(false);
  const {
    openSubmit,
    selectedSurvey,
    selectedJobId,
    selectedQuestions,
    correlative,
    lang,
    isRandomize,
    randomNumber,
    selectedUserId,
    calledFrom,
    linkMode,
    publicMode,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  useEffect(() => {
    const surveySubmit = async () => {
      setIsLoadingDialog(true);
      await submitSurvey(selectedQuestions[correlative].qa_survey_submit_id);
      setIsLoadingDialog(false);
      setIsDone(true);
    };
    if (openSubmit && !publicMode) {
      surveySubmit();
    } else if (openSubmit) {
      setIsOpenPublic(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSubmit]);

  const handleSubmitControlled = async () => {
    try {
      setIsLoadingDialog(true);
      await submitSurvey(selectedQuestions[correlative].qa_survey_submit_id);
      setIsLoadingDialog(false);
      setIsDone(true);
    } catch (e) {
      console.log("Cannot save survey");
    }
  };

  useEffect(() => {
    if (isDone && publicMode) {
      setTimeout(() => {
        history.push("/");
      }, 5000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone, publicMode]);

  const handleClose = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SUBMIT",
      openSubmit: false,
    });
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SHOW_SURVEY",
      openShowSurvey: false,
    });
    if (calledFrom === "invididual-evaluations") {
      dispatchSurveyFill({
        type: "TOGGLE_UPDATE_LIST_DATA",
        updateListData: true,
      });
    } else if (linkMode) {
      history.push(`/surveys`);
    }
  };

  const handleSeeResults = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SUBMIT",
      openSubmit: false,
    });
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SHOW_SURVEY",
      openShowSurvey: false,
    });
    if (calledFrom === "invididual-evaluations") {
      dispatchSurveyFill({
        type: "TOGGLE_UPDATE_LIST_DATA",
        updateListData: true,
      });
    } else if (calledFrom === "locations-surveys" || linkMode) {
      history.push(`/surveys?completed`);
    }
  };

  const handleTakeAgain = async () => {
    try {
      setIsLoadingDialog(true);
      await surveyStart(
        dispatchSurveyFill,
        selectedJobId,
        selectedSurvey.id,
        isRandomize,
        randomNumber,
        selectedUserId
      );
      setIsLoadingDialog(false);
      dispatchSurveyFill({
        type: "TOGGLE_OPEN_SUBMIT",
        openSubmit: false,
      });
    } catch (e) {
      console.log("Cannot re-enter survey " + e);
    }
  };

  return (
    <>
      <Dialog
        open={openSubmit}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              {!publicMode ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  className={classes.wrapperDialogClose}
                >
                  <CloseIcon className={classes.iconDialogClose} />
                </IconButton>
              ) : null}

              <SuccessIcon className={classes.iconSuccessDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                {surveysLabelsLang[lang].surveySubmittedMsgHeaderLabel}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                {surveysLabelsLang[lang].surveySubmittedMsgBodyLabel}
              </Typography>
            </DialogContent>
            {!publicMode ? (
              <DialogActions className={classes.actionsDialogWrapperSubmit}>
                <ButtonBase
                  color="secondary"
                  variant="outlined"
                  onClick={handleTakeAgain}
                  startIcon={<ReplayIcon />}
                >
                  {surveysLabelsLang[lang].surveySubmittedTakeAgainLabel}
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  disableElevation={true}
                  onClick={handleSeeResults}
                >
                  {surveysLabelsLang[lang].surveySubmittedSeeResultsLabel}
                </ButtonBase>
              </DialogActions>
            ) : null}
          </>
        )}
      </Dialog>
      {publicMode && isOpenPublic ? (
        <BasicInfoDialog
          completeSurvey={handleSubmitControlled}
          isOpen={isOpenPublic}
          history={history}
        />
      ) : null}
    </>
  );
};
export default withRouter(SubmitDialog);
