import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SideSettings from "./components/settings/sideSettings";
import useStyles from "./styles";

const PerformanceBoardPage = (props) => {
  const [openSettings, setOpenSettings] = useState(false);
  const classes = useStyles();

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  return (
    <Box className={classes.root}>
      <Box>Performance Board</Box>
      <Box className={classes.optionContainer}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.settingButton}
          onClick={handleOpenSettings}
        >
          Settings
        </Button>
      </Box>
      <SideSettings isOpen={openSettings} handleClose={handleCloseSettings} />
    </Box>
  );
};

export default PerformanceBoardPage;
