import React, { useContext, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ProjectIcon from "@material-ui/icons/Work";
import { ButtonBase } from "components/ui/core/button";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import GlobalUiContext from "contexts/globalUiContext";
import { useServiceDispatch } from "contexts/projectServiceContext";
import {
  deletePreventativeServices,
  stopPreventativeServices,
  restartPreventativeServices,
} from "services/projectService";
import { statusLoadingTitles } from "./serviceOptions.js";
import { logException } from "components/util/logUtil";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  actionsDialogWrapper: {
    justifyContent: "center",
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 11,
    height: 11,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  dateContainer: {
    textAlign: "left",
  },
  loadingText: {
    fontSize: 14,
    textAlign: "center",
    padding: 20,
  },
}));

export const statusService = [
  { value: 1, label: "Active" },
  { value: 0, label: "Stopped" },
];

const options = [
  { id: 1, label: "Edit", value: "EDIT" },
  { id: 2, label: "Change Status", value: "CHANGE_STATUS" },
  { id: 3, label: "Delete", value: "DELETE" },
];

const ServicesTableActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [dateStatus, setDateStatus] = useState(null);
  const [dateMinStatus, setDateMinStatus] = useState(null);
  const [dateMinStop, setDateMinStop] = useState(null);
  const [changedStatus, setChangedStatus] = useState(false);
  const { dispatchGlobalUi } = useContext(GlobalUiContext);
  const dispatchServices = useServiceDispatch();

  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    if (action === "EDIT") {
      dispatchServices({
        type: "SET_SERVICE_ROW_ID",
        serviceRowId: row.id,
      });
      dispatchServices({
        type: "SET_SERVICE_MANAGE_MODE",
        serviceManageMode: "EDIT",
      });
      dispatchServices({
        type: "SET_SERVICE_MANAGE_OPEN",
        serviceManageOpen: true,
      });
    } else if (action === "CHANGE_STATUS") {
      const findStatus = statusService.find(
        (itemStatus) => itemStatus.value === row.status
      );
      setStatus(findStatus ?? "");
      setChangedStatus(false);
      setDateMinStatus(moment(row.start_date));
      setDateStatus(
        row?.recurrence?.stop_date && row.status === 0
          ? moment(row?.recurrence?.stop_date).add(1, "days")
          : moment(row.start_date)
      );
      setDateMinStop(
        row?.recurrence?.stop_date && row.status === 0
          ? moment(row?.recurrence?.stop_date).add(1, "days")
          : null
      );
      setOpenStatus(true);
    } else if (action === "DELETE") {
      setOpenDelete(true);
    }
    setAnchorEl(null);
  };

  const handleChangeStatusService = (event, selected) => {
    setChangedStatus(selected?.value === row.status ? false : true);
    setStatus(selected ?? "");
  };

  const handleChangeDate = async (event) => {
    setDateStatus(event);
  };

  const handleStatusConfirm = async () => {
    if (row.status !== status.value) {
      try {
        setIsLoadingStatus(true);
        if (status.value === 0) {
          await stopPreventativeServices(row.id, {
            stop_date: moment(dateStatus).format("YYYY-MM-DD"),
          });
        } else if (status.value === 1) {
          await restartPreventativeServices(row.id, {
            restart_date: moment(dateStatus).format("YYYY-MM-DD"),
          });
        }
        dispatchGlobalUi({
          type: "SET_REFRESH_DATA",
          refreshData: true,
        });
        setOpenStatus(false);
        setIsLoadingStatus(false);
      } catch (e) {
        logException(e, "Cannot update project service status");
      }
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoadingDelete(true);
      await deletePreventativeServices(row.id);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
      setOpenDelete(false);
      setIsLoadingDelete(false);
    } catch (e) {
      console.log(e);
      logException(e, "Cannot delete project service");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusClose = () => {
    setOpenStatus(false);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  return (
    <Box>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter(
            (option) =>
              option.value !== "CHANGE_STATUS" ||
              (option.value === "CHANGE_STATUS" && row.recurrence)
          )
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
      {openStatus ? (
        <Dialog
          open={openStatus}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogContent className={classes.wrapperDialog}>
            {isLoadingStatus ? (
              <Box>
                <LoadingIndicator />
                <Typography variant="h6" className={classes.loadingText}>
                  {statusLoadingTitles[2]}
                </Typography>
              </Box>
            ) : (
              <>
                <IconButton
                  aria-label="close"
                  onClick={handleStatusClose}
                  className={classes.wrapperClose}
                >
                  <CloseIcon className={classes.iconClose} />
                </IconButton>
                <ProjectIcon className={classes.iconDialog} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Change Status Service
                </Typography>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={statusService}
                  name="status"
                  value={status}
                  handleChange={handleChangeStatusService}
                />
                {changedStatus && status ? (
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12} className={classes.dateContainer}>
                      <FormInputDateAdo
                        name="status_date"
                        label={
                          status.value === 0 ? "Stop Date" : "Restart Date"
                        }
                        value={dateStatus}
                        handleChange={handleChangeDate}
                        minDate={dateMinStop ?? dateMinStatus}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapper}>
            <ButtonBase
              onClick={handleStatusClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              onClick={handleStatusConfirm}
              color="secondary"
              disabled={isLoadingStatus || !(changedStatus && status)}
            >
              Save
            </ButtonBase>
          </DialogActions>
        </Dialog>
      ) : null}
      {openDelete ? (
        <Dialog
          open={openDelete}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogContent className={classes.wrapperDialog}>
            {isLoadingDelete ? (
              <Box>
                <LoadingIndicator />
                <Typography variant="h6" className={classes.loadingText}>
                  {statusLoadingTitles[4]}
                </Typography>
              </Box>
            ) : (
              <>
                <IconButton
                  aria-label="close"
                  onClick={handleDeleteClose}
                  className={classes.wrapperClose}
                >
                  <CloseIcon className={classes.iconClose} />
                </IconButton>
                <ProjectIcon className={classes.iconDialog} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Delete Service
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Please confirm if you want to delete the service.
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapper}>
            <ButtonBase
              onClick={handleDeleteClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              onClick={handleDeleteConfirm}
              color="secondary"
              disabled={isLoadingDelete}
            >
              Confirm
            </ButtonBase>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default withRouter(ServicesTableActions);
