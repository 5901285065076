import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";

import useStyles from "./styles";

const LoadingDialog = (props) => {
  const classes = useStyles();
  const { open } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-loading"
      aria-describedby="dialog-loading"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperDialog}>
        <LoadingIndicator />
      </DialogContent>
    </Dialog>
  );
};
export default LoadingDialog;
