import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 21,
    borderRadius: 4,
    fontSize: 10,
    minWidth: 80,
    color: "#fff",
    fontWeight: "bold",
    paddingLeft: 4,
    marginRight: 4,
    "& svg": {
      fontSize: 14,
      color: "#fff",
      marginTop: -1,
      position: "relative",
    },
    "& span": {
      paddingLeft: 8,
    },
  },
}));

export default useStyles;
