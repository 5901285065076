import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import NotesManage from "./notesManage";
import NoteTableActions from "./noteTableActions";
import NotesListActions from "./notesListActions";
import NoteFilters from "./noteFilters";
import FieldName from "./fieldName";
import FieldLocation from "./fieldLocation";
import FieldDescription from "./fieldDescription";
import InfoIcon from "@material-ui/icons/Info";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import EmptyScreen from "components/ui/core/emptyScreen";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useNoteState,
  useNoteDispatch,
  getNoteList,
  searchNotes,
  getNoteListDataSet,
} from "contexts/noteContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { is } from "date-fns/locale";

const columns = [
  {
    id: "user",
    label: "Name",
    content: (row) => <FieldName field={row.user} />,
  },
  {
    id: "jobs",
    label: "Locations",
    content: (row) => <FieldLocation jobs={row.jobs} />,
    disableSort: true,
  },
  { id: "created_at", label: "Date", format: "date" },
  {
    id: "type",
    label: "Type",
    content: (row) => (row.type === 1 ? "Team" : "Private"),
  },
  {
    id: "summary",
    label: "Note Summary",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "description",
    label: "Note Detail",
    content: (row) => (
      <FieldDescription field={row.description} fieldLength={80} />
    ),
    disableSort: true,
  },
  {
    id: "actions",
    content: (row) => <NoteTableActions row={row} />,
    contentMobile: (row) => <NoteTableActions row={row} mobile={true} />,
    style: { width: 58 },
    disableSort: true,
  },
];

const NotesTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { noteListLoading, noteList, noteCount } = useNoteState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchNotes = useNoteDispatch();
  const dispatchFilters = useFilterDispatch();

  const { refreshData } = globalUi;
  const { isDashboard, dashboardState } = props;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getNoteList(dispatchNotes, filterData, isDashboard, dashboardState);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dashboardState, dispatchNotes, filterState, isDashboard]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getNoteList(dispatchNotes, filterData, isDashboard, dashboardState);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: noteList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, noteList]);

  const handleScrollClick = useCallback(async () => {
    if (noteCount <= noteList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getNoteListDataSet(
        noteList,
        dispatchNotes,
        noteList.length,
        filterData,
        isDashboard,
        dashboardState
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: noteList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    noteCount,
    noteList,
    loadingMore,
    filterState,
    dispatchNotes,
    isDashboard,
    dashboardState,
    dispatchTable,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchNotes(
            query,
            filterData,
            isDashboard,
            dashboardState
          );
          // Send result to search tab
          dispatchNotes({
            type: "SET_NOTE_LIST",
            noteList: results,
          });

          dispatchNotes({
            type: "SET_NOTE_COUNT",
            noteCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  if (
    !noteListLoading &&
    isDashboard &&
    !noteList.length &&
    filterState.searchString === ""
  ) {
    return <EmptyScreen icon={<InfoIcon />} type={3} actionText="Add A Note" />;
  }

  return (
    <Box className={classes.root}>
      {noteListLoading || loadingSearch ? (
        isDashboard ? (
          <LoadingStateHorizontal isVisible style={classes.centerLoadingTab} />
        ) : (
          <LoadingStateHorizontal isVisible style={classes.centerLoading} />
        )
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>{`${noteList.length} / ${noteCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <Box>
                      <EnhancedTableToolbar>
                        {selected.length > 0 ? (
                          <ToolbarSelected />
                        ) : (
                          <ToolbarDefault
                            handleSearch={handleSearch}
                            placeholderSearch={"All Notes"}
                            handleSearchClear={handleSearchClear}
                          >
                            <NotesListActions />
                            <NoteFilters isDashboard={isDashboard} />
                          </ToolbarDefault>
                        )}
                      </EnhancedTableToolbar>
                    </Box>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
            <NotesManage />
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(NotesTable);
