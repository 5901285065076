import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";

import { useQuoteState } from "contexts/quoteSurfaceContext";

import useStyles from "./styles";

const QuoteSurfaceFile = () => {
  const classes = useStyles();
  const [filesDisplay, setFilesDisplay] = useState([]);
  const stateContext = useQuoteState();

  const { quoteDetails, isLoading } = stateContext ? stateContext : null;

  useEffect(() => {
    if (!isLoading) {
      setFilesDisplay(quoteDetails.files);
    }
  }, [quoteDetails, isLoading]);

  const handleRemoveDisplay = id => {
    const filesDisplayRemove = filesDisplay.filter(file => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={200} />;
  }

  return (
    <Box className={classes.containerFile}>
      <Typography variant="h4" className={classes.titleSection} gutterBottom>
        Documents
      </Typography>
      <Box className={classes.containerDisplayFiles}>
        <FilesDisplayUploaded
          files={filesDisplay}
          handleRemoveDisplay={handleRemoveDisplay}
          restrictRemove={true}
        />
      </Box>
    </Box>
  );
};

export default QuoteSurfaceFile;
