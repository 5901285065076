import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useDraftsDispatch } from "contexts/surveyDraftsContext";

const DraftsTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchDrafts = useDraftsDispatch();
  const open = Boolean(anchorEl);
  const { row } = props;

  const options = [
    {
      id: 1,
      label: "Resume",
      value: "VIEW",
    },
    {
      id: 2,
      label: "Delete",
      value: "DELETE",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";
    switch (action) {
      case "DELETE":
        dispatchDrafts({
          type: "TOGGLE_OPEN_DELETE",
          openDelete: true,
        });
        break;
      case "VIEW":
        dispatchDrafts({
          type: "TOGGLE_OPEN_RESUME",
          openResume: true,
        });
        break;
      default:
        break;
    }
    dispatchDrafts({
      type: "SET_DRAFTS_SELECTED",
      draftsSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DraftsTableActions;
