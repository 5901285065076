import React, { useState, useContext, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useResultsDispatch } from "contexts/surveyResultsContext";
import { exportResumePdf } from "services/surveyFrontlineService";
import { hasPermission, permissionQuality } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

const ResultsTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchResult = useResultsDispatch();
  const open = Boolean(anchorEl);
  const { row } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasManage = useMemo(() =>
    hasPermission(permissionQuality.MANAGE, permissions)
  );

  const options = [
    {
      id: 1,
      label: "View",
      value: "VIEW",
    },
    {
      id: 2,
      label: "Export to PDF",
      value: "DOWNLOAD",
    },
    {
      id: 3,
      label: "Delete",
      value: "DELETE",
    },
    {
      id: 4,
      label: "Set Baseline",
      value: "BASELINE",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const downloadResult = async () => {
    try {
      const result = await exportResumePdf(row.id);
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (e) {
      console.log("Cannot download pdf file.");
    }
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    switch (action) {
      case "DELETE":
        dispatchResult({
          type: "TOGGLE_DELETE",
          openResultDelete: true,
        });
        break;
      case "VIEW":
        dispatchResult({
          type: "TOGGLE_OPEN_VIEW",
          openView: true,
        });
        break;
      case "DOWNLOAD":
        downloadResult();
        break;
      case "BASELINE":
        dispatchResult({
          type: "TOGGLE_BASELINE",
          openResultBaseline: true,
        });
        break;
      default:
        break;
    }
    dispatchResult({
      type: "SET_RESULTS_SELECTED",
      resultsSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={!hasManage}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ResultsTableActions;
