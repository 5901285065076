import React, { useState, useEffect, useRef, useContext } from "react";
import { ButtonBase } from "components/ui/core/button";
import SignatureCanvas from "react-signature-canvas";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SignatureIcon from "@material-ui/icons/AssignmentTurnedIn";
import MessageDialog from "components/ui/dialog/messageDialog";
import Skeleton from "@material-ui/lab/Skeleton";
import { saveWorkticketSignature } from "services/workticketService";
import { deleteFile } from "services/fileService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

function dataURLtoFile(dataUrl, filename) {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const WorkticketSignature = () => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const [hasSignature, setHasSignature] = useState(null);
  const signRef = useRef();
  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      const signatureFile = workticket.files.filter(
        (file) => parseInt(file.pivot.is_signature) === 1 && !file.is_thumbnail
      );
      if (signatureFile && Boolean(signatureFile.length)) {
        setHasSignature(signatureFile);
      }
    }
  }, [isLoading, workticket.files]);

  useEffect(() => {
    if (!open) {
      setEdit(false);
    }
  }, [open]);

  const handleOpenSignature = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSaveSign = async () => {
    try {
      setIsLoadingSignature(true);
      const data = {
        is_signature: 1,
        is_paper_ticket: 0,
        "files[]": dataURLtoFile(signRef.current.toDataURL(), "file"),
      };
      const result = await saveWorkticketSignature(workticket.id, data);
      const signatureFile = result.data.data.workticket.files.filter(
        (file) => parseInt(file.pivot.is_signature) === 1
      );
      signRef.current.clear();
      setHasSignature(signatureFile);
      setOpen(false);
      setOpenSuccess(true);
      setIsLoadingSignature(false);
    } catch (e) {
      logException(e, "Cannot save signature");
    }
  };

  const handleUpdateSign = async () => {
    try {
      setIsLoadingSignature(true);
      await deleteFile(hasSignature[0].id);
      const data = {
        is_signature: 1,
        is_paper_ticket: 0,
        "files[]": dataURLtoFile(signRef.current.toDataURL(), "file"),
      };
      const result = await saveWorkticketSignature(workticket.id, data);
      const signatureFile = result.data.data.workticket.files.filter(
        (file) => parseInt(file.pivot.is_signature) === 1
      );
      signRef.current.clear();
      setHasSignature(signatureFile);
      setOpen(false);
      setOpenSuccess(true);
      setIsLoadingSignature(false);
    } catch (e) {
      logException(e, "Cannot save signature");
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={30}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      {(hasPermission(permissionWorkticket.SIGNATURE, permissions) ||
        hasSignature) && (
        <Box className={classes.containerSignature}>
          <ButtonBase
            color="secondary"
            fullWidth={true}
            disableElevation={true}
            onClick={handleOpenSignature}
          >
            {hasSignature ? "View" : "Get"} Signature
          </ButtonBase>
        </Box>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          {hasPermission(permissionWorkticket.SIGNATURE, permissions) &&
            hasSignature &&
            !edit && (
              <IconButton
                aria-label="close"
                onClick={handleEdit}
                className={classes.wrapperEdit}
              >
                <EditIcon className={classes.iconClose} />
              </IconButton>
            )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Typography variant="h4" className={classes.titleDialog}>
            <SignatureIcon /> {hasSignature ? "" : "Create"} Signature
          </Typography>
          {hasSignature && !edit ? (
            <img
              src={hasSignature[0].file_url}
              alt="signature"
              className={classes.imgSignature}
            />
          ) : (
            <SignatureCanvas
              ref={signRef}
              canvasProps={{ className: classes.sigCanvas }}
            />
          )}
        </DialogContent>
        {!isLoadingSignature ? (
          <DialogActions className={classes.actionsDialogWrapper}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              {hasSignature ? "Close" : "Cancel"}
            </ButtonBase>
            {!hasSignature && (
              <ButtonBase
                color="secondary"
                disableElevation={true}
                onClick={handleSaveSign}
              >
                Sign
              </ButtonBase>
            )}
            {edit && (
              <ButtonBase
                color="secondary"
                disableElevation={true}
                onClick={handleUpdateSign}
              >
                Update Signature
              </ButtonBase>
            )}
          </DialogActions>
        ) : (
          <DialogActions className={classes.actionsDialogWrapper}>
            <LoadingStateHorizontal isVisible />
          </DialogActions>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message="Signature has been saved."
      />
    </>
  );
};

export default WorkticketSignature;
