import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AssetCategory from "./assetCategory";
import AssetMaintenance from "./assetMaintenance";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { useProjectView } from "contexts/projectViewContext";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useAssetState,
  useAssetDispatch,
  getAssetListProject,
  searchAssetsProject,
  getAssetListDataSetProject,
} from "contexts/assetContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "ID",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "name",
    label: "Asset Name",
  },
  {
    id: "category",
    label: "Category",
    content: (row) => <AssetCategory categories={row.categories} />,
  },
  {
    id: "zone",
    label: "Zone",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "serial",
    label: "Serial",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "tag",
    label: "Tag",
  },
  {
    id: "maintenance_plan",
    label: "Maintenance Plan",
    content: (row) => <AssetMaintenance id={row.id} />,
  },
];

const AssetsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { assetListLoading, assetList, assetCount } = useAssetState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchAssets = useAssetDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ?? null;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getAssetListProject(project.id, dispatchAssets, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchAssets, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: assetList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, assetList, project]);

  const handleScrollClick = useCallback(async () => {
    if (assetCount <= assetList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getAssetListDataSetProject(
        project.id,
        assetList,
        dispatchAssets,
        assetList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: assetList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    assetList,
    assetCount,
    filterState,
    dispatchAssets,
    dispatchTable,
    project,
  ]);

  const handleRowClick = (event, rowId) => {
    dispatchAssets({
      type: "SET_ASSET_ROW_ID",
      assetRowId: rowId,
    });
    dispatchTable({ type: "SET_UPDATED_ROWS", rowsUpdated: [rowId] });
    console.log("Click on row event", rowId);
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchAssetsProject(
            project.id,
            query,
            filterData
          );
          // Send result to search tab
          dispatchAssets({
            type: "SET_ASSET_LIST",
            assetList: results,
          });

          dispatchAssets({
            type: "SET_ASSET_COUNT",
            assetCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 caracters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {assetListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${assetList.length} / ${assetCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <TableTabs value={"assets"}></TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <CalendarFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AssetsTable);
