import moment from "moment-timezone";
import { appTimezone } from "../../constants";

moment.tz.setDefault(appTimezone);

export const getDateStringNow = () => {
  return moment().format("MMMM D, YYYY");
};

export const timeFormat = (minutesInput) => {
  if (minutesInput === undefined) {
    return "00:00:00";
  }
  if (minutesInput > 1440) {
    minutesInput = minutesInput - parseFloat(1440);
  }
  let hours = Math.floor(minutesInput / 60);
  let minutes = minutesInput - hours * 60;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (minutes === 0) minutes = "00";
  const format = `${hours}:${minutes}:00`;
  return format;
};

export const timeFormat24toAMPM = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[3] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const convertTimeToMinutes = (time) => {
  return (
    parseFloat(moment(time).format("mm")) +
    parseFloat(moment(time).format("HH")) * 60
  );
};

export const convertTimeToHours = (time) => {
  return (
    parseFloat(moment(time).format("mm")) / 60 +
    parseFloat(moment(time).format("HH"))
  );
};

export const convertMinutesToHours = (minutesEnd, minutesStart) => {
  return ((minutesEnd - minutesStart) / 60).toFixed(2);
};

export const convertDateTimeToUnix = (date, time) => {
  return moment(`${date}T${time}`, moment.defaultFormat).format("x");
};

export const convertDateToUnix = (date) => {
  return moment(`${date}`, moment.defaultFormat).format("x");
};

export const formatDateToUnix = (date) => {
  return moment(date).unix();
};

export const convertDateToFormat = (date, time) => {
  return moment(`${date}T${time}`, moment.defaultFormat);
};

export const hoursFromNow = (date, time) => {
  const dateIni = convertDateToFormat(date, time);
  const dateEnd = convertDateToFormat(getDateNow(), getTimeNow());
  const duration = moment.duration(dateEnd.diff(dateIni));
  return duration.asHours();
};

export const hoursFromRange = (date, timeIni, timeEnd) => {
  const dateIni = convertDateToFormat(date, timeIni);
  const dateEnd = convertDateToFormat(date, timeEnd);
  const duration = moment.duration(dateEnd.diff(dateIni));
  return duration.asHours();
};

export const MinutesFromNow = (date, time) => {
  const dateIni = convertDateToFormat(date, time);
  const dateEnd = convertDateToFormat(getDateNow(), getTimeNow());
  const duration = moment.duration(dateEnd.diff(dateIni));
  return duration.asMinutes();
};

export const MinutesFromNowStandard = (date) => {
  const dateIni = moment(`${date}`, moment.defaultFormat);
  const dateEnd = moment();
  const duration = moment.duration(dateEnd.diff(dateIni));
  return duration.asMinutes();
};

export const DaysFromNowStandard = (date) => {
  const dateIni = moment(`${date}`, moment.defaultFormat);
  const dateEnd = moment();
  const duration = moment.duration(dateEnd.diff(dateIni));
  return duration.asDays();
};

export const getDateNow = () => {
  return moment().format("YYYY-MM-DD");
};

export const getYearNow = () => {
  return moment().format("YY");
};

export const getTimeNow = () => {
  return moment().format("HH:mm:ss");
};

export const convertDateFormat = (date) => {
  let dateOutput = moment().format("YYYY-MM-DD");
  date = date.replace(/_/g, "0");
  date = date.toString().match(/^([0-2]\d)(\/)([0-3]\d)(\/)([1-2]\d)$/) || [
    date,
  ];
  if (date.length > 1) {
    dateOutput = `20${date[5]}-${date[1].padStart(2, "0")}-${date[3].padStart(
      2,
      "0"
    )}`;
  } else {
    return false;
  }
  return dateOutput;
};

export const convertDateFormatField = (date, timezone) => {
  if (timezone) {
    return moment(`${date}`, moment.defaultFormat)
      .tz(timezone)
      .format("MM/DD/YY");
  } else {
    return moment(`${date}`, moment.defaultFormat).format("MM/DD/YY");
  }
};

export const dateFormatField = (date) => {
  return moment(`${date}`, moment.defaultFormat).format("MM/DD/YYYY");
};

export const dateFormatTimeField = (date) => {
  return moment(`${date}`, moment.defaultFormat).format("h:mm a");
};

export const convertDateToMoment = (date, timezone) => {
  if (timezone) {
    return new Date(formatDateTZ(date, "lll", timezone));
  } else {
    return new Date(formatDateTZ(date, "lll"));
  }
};

export const convertDateToCalendar = (date, timezone) => {
  const localOffset = moment
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .utcOffset();
  const appOffset = moment().utcOffset();
  const offsetHours = (localOffset - appOffset) / 60;

  if (timezone) {
    return new Date(
      moment(date).tz(timezone).add(offsetHours, "hours").format("lll")
    );
  } else {
    return new Date(moment(date).add(offsetHours, "hours").format("lll"));
  }
};

export const convertDateTimeDBFormat = (date, time) => {
  return `${moment(date).format("YYYY-MM-DD")} ${moment(time).format(
    "HH:mm:ss"
  )}`;
};

export const getDiffDateFormatField = (date, metric = "days") => {
  return moment(`${date}`, moment.defaultFormat).diff(moment(), metric);
};

export const getDiffDateFormatMinutes = (
  dateIni,
  timeIni,
  dateEnd,
  timeEnd
) => {
  const formatIni = `${moment(dateIni).format("YYYY-MM-DD")}T${moment(
    timeIni
  ).format("HH:mm")}`;
  const formatEnd = `${moment(dateEnd).format("YYYY-MM-DD")}T${moment(
    timeEnd
  ).format("HH:mm")}`;
  return (
    moment(formatEnd, moment.defaultFormat).diff(
      moment(formatIni, moment.defaultFormat),
      "minutes"
    ) / 60
  );
};

export const getCurrentIni = (metric) => {
  return moment().startOf(metric).format("YYYY-MM-DD HH:mm");
};

export const getCurrentEnd = (metric) => {
  return moment().endOf(metric).format("YYYY-MM-DD HH:mm");
};

export const getNextIni = (metric, quantity) => {
  return moment()
    .add(quantity, metric)
    .startOf(metric)
    .format("YYYY-MM-DD HH:mm");
};

export const getNextEnd = (metric, quantity) => {
  return moment()
    .add(quantity, metric)
    .endOf(metric)
    .format("YYYY-MM-DD HH:mm");
};

export const getLastIni = (metric, quantity) => {
  return moment()
    .subtract(quantity, metric)
    .startOf(metric)
    .format("YYYY-MM-DD HH:mm");
};

export const getLastEnd = (metric, quantity) => {
  return moment()
    .subtract(quantity, metric)
    .endOf(metric)
    .format("YYYY-MM-DD HH:mm");
};

export const businessDays = (date, days = 0) => {
  let dateBase = date;
  while (days > 0) {
    dateBase = dateBase.add(1, "days");
    if (dateBase.isoWeekday() !== 6 && dateBase.isoWeekday() !== 7) {
      days--;
    }
  }
  return dateBase;
};

export const billingLimitDate = (days) => {
  const businessDayMonth = businessDays(moment().startOf("month"), days);
  const daysCompare = DaysFromNowStandard(
    businessDayMonth.format("YYYY-MM-DD")
  );

  if (daysCompare > 0) {
    return moment().startOf("month");
  } else {
    return moment().subtract(1, "months").startOf("month");
  }
};

export const convertTzCtEt = (hours) => {
  return moment(
    `${moment().format("YYYY-MM-DD")} ${hours}`,
    "YYYY-MM-DD HH:mm:ss"
  )
    .subtract(4, "hours")
    .format("hh:mm A");
};

export const getDateFormatDatabase = "YYYY-MM-DD HH:mm:ss";

export const formatDateTZ = (dateTime, format, timezone) => {
  if (timezone) {
    return moment(dateTime).tz(timezone).format(format);
  } else {
    return moment(dateTime).format(format);
  }
};

export const getDateTZ = (dateTime, timezone) => {
  if (timezone) {
    return moment(dateTime).tz(timezone);
  } else {
    return moment(dateTime);
  }
};

export const formatAppDateTZ = (dateTime, format, timezone) => {
  if (timezone) {
    return moment
      .tz(dateTime, moment.defaultFormat, timezone)
      .tz(appTimezone)
      .format(format);
  } else {
    return moment(dateTime, moment.defaultFormat).format(format);
  }
};

export const timeFormatSeconds = (secondsInput) => {
  if (secondsInput === undefined) {
    return "00:00";
  }
  if (secondsInput > 86400) {
    secondsInput = secondsInput - parseFloat(86400);
  }
  let minutes = Math.floor((secondsInput % 3600) / 60);
  let seconds = Math.floor(secondsInput % 60);

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  const format = `${minutes}:${seconds}`;
  return format;
};

export const convertTimeToSeconds = (time) => {
  const isValidTimeFormat = /^\d{2}:\d{2}$/.test(time);
  if (!isValidTimeFormat) {
    console.error("Invalid time format:", time);
    return null;
  }

  const [minutes, seconds] = time.split(":").map(Number);
  return minutes * 60 + seconds;
};
