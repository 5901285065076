import React from "react";
import JobsTable from "./jobsTable";
import Box from "@material-ui/core/Box";
import withTableCollapsibleFilterContext from "components/hoc/withTableCollapsibleFilterContext";
import { CustomersProvider } from "contexts/adminCustomersContext";
import useStyles from "./styles";

const JobsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <CustomersProvider>
          <JobsTable />
        </CustomersProvider>
      </Box>
    </>
  );
};

export default withTableCollapsibleFilterContext(JobsList);
