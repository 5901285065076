import React from "react";
import merge from "lodash/merge";
import {
  getNotices,
  getNoticesSearch,
  getNoticesOffset,
} from "services/noticeService";

const defaultState = {
  noticesListLoading: true,
  noticesSelected: null,
  noticesList: [],
  noticesCount: [],
  openNoticesDelete: false,
  openNoticesDrawer: false,
  openNoticesReadOnly: false,
  noticesData: null,
  openView: false,
};

const NoticesStateContext = React.createContext();
const NoticesDispatchContext = React.createContext();

function noticesReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        noticesListLoading: action.noticesListLoading,
      };
    case "SET_NOTICES_LIST":
      return {
        ...state,
        noticesList: action.noticesList,
      };
    case "SET_NOTICES_COUNT":
      return {
        ...state,
        noticesCount: action.noticesCount,
      };
    case "SET_NOTICES_SELECTED":
      return {
        ...state,
        noticesSelected: action.noticesSelected,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openNoticesDelete: action.openNoticesDelete,
      };
    case "TOGGLE_OPENNOTICE":
      return {
        ...state,
        openNoticesDrawer: action.openNoticesDrawer,
      };
    case "TOGGLE_OPENNOTICE_READONLY":
      return {
        ...state,
        openNoticesReadOnly: action.openNoticesReadOnly,
      };
    case "SET_NOTICES_DATA":
      return {
        ...state,
        noticesData: action.noticesData,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NoticesProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(noticesReducer, mergedState);
  return (
    <NoticesStateContext.Provider value={state}>
      <NoticesDispatchContext.Provider value={dispatch}>
        {children}
      </NoticesDispatchContext.Provider>
    </NoticesStateContext.Provider>
  );
}

function useNoticesState() {
  const context = React.useContext(NoticesStateContext);
  if (context === undefined) {
    throw new Error("useNoticesState must be used within a LocationProvider");
  }
  return context;
}

function useNoticesDispatch() {
  const context = React.useContext(NoticesDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getNoticesList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      noticesListLoading: true,
    });
    const result = await getNotices(filterData);
    dispatch({
      type: "SET_NOTICES_LIST",
      noticesList: [...result.data.data.notices],
    });
    dispatch({
      type: "SET_NOTICES_COUNT",
      noticesCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      noticesListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getNoticesListDataSet(
  noticesList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getNoticesOffset(offset, filterData);
    const updateData = noticesList.concat(result.data.data.notices);
    dispatch({
      type: "SET_NOTICES_LIST",
      noticesList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchNoticesList(search, filterData) {
  try {
    const result = await getNoticesSearch(search, filterData);
    return result.data.data.notices;
  } catch (error) {
    console.log(error);
  }
}

export {
  NoticesProvider,
  useNoticesState,
  useNoticesDispatch,
  getNoticesList,
  getNoticesListDataSet,
  searchNoticesList,
};
