import React from "react";
import Box from "@material-ui/core/Box";
import ActivityTable from "./activityTable";
import { ActivityProvider } from "contexts/activityContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const AcitivyList = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.pageBodyWrapper}>
      <ActivityProvider>
        <ActivityTable />
      </ActivityProvider>
    </Box>
  );
};

export default withTableFilterContext(AcitivyList);
