import React, { useState, useEffect, useContext, useRef } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  addWorkticketTask,
  completeWorkticketTask,
  deleteWorkticketTask,
} from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketTask = (props) => {
  const classes = useStyles();
  const inputTaskRef = useRef();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [addTask, setAddTask] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      setTaskList(workticket.tasks);
    }
  }, [isLoading, workticket.tasks]);

  const handleTaskAdd = () => {
    if (!hasPermission(permissionWorkticket.TASKS, permissions)) {
      return;
    }
    setAddTask(true);
    setTimeout(() => {
      inputTaskRef.current.focus();
    }, 200);
  };

  const handleSaveItem = async () => {
    if (!hasPermission(permissionWorkticket.TASKS, permissions)) {
      return;
    }
    const value = inputTaskRef.current.value;
    if (value !== "") {
      const data = {
        name: value,
        description: "",
      };
      try {
        const result = await addWorkticketTask(workticket.id, data);
        setTaskList(result.data.data.workticket.tasks);
        inputTaskRef.current.value = "";
      } catch (e) {
        logException(e, "Cannot create task");
      }
    }
    setAddTask(false);
  };

  const handleChange = async (event) => {
    const taskId = parseInt(event.target.value);
    const taskChecked = event.target.checked;
    try {
      const taskListUpdate = [...taskList];
      await completeWorkticketTask(workticket.id, event.target.value);

      const indexKey = taskListUpdate.findIndex(
        ({ id }) => parseInt(id) === taskId
      );
      taskListUpdate[indexKey] = {
        ...taskListUpdate[indexKey],
        completed_at: taskChecked ? Date() : null,
      };
      setTaskList(taskListUpdate);
    } catch (e) {
      logException(e, "Cannot mark task as completed");
    }
  };

  const handleDeleteTask = async (taskId) => {
    if (!hasPermission(permissionWorkticket.TASKS, permissions)) {
      return;
    }
    try {
      await deleteWorkticketTask(workticket.id, taskId);
      const taskListUpdate = taskList.filter((task) => task.id !== taskId);
      setTaskList(taskListUpdate);
    } catch (e) {
      logException(e, "Cannot delete workticket");
    }
  };

  const handleTaskAddSubmit = () => {};

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  const readOnlyWorkticket =
    workticket?.recurrence_instance_id && !workticket?.instance_override;

  return (
    <Box className={classes.containerBodyTaskList}>
      <Typography
        variant="h4"
        className={classes.titleSectionBody}
        gutterBottom
      >
        Task List
      </Typography>
      <FormControl component="fieldset" className={classes.taskItemFieldset}>
        <FormGroup
          aria-label="position"
          className={classes.taskItemContainer}
          row
        >
          {taskList.map((task) => (
            <FormControlLabel
              value={task.id}
              key={task.id}
              checked={task.completed_at ? true : false}
              control={
                <Checkbox className={classes.tableCheckbox} color="secondary" />
              }
              label={
                <Box className={classes.taskLabel}>
                  {task.name}
                  {hasPermission(permissionWorkticket.TASKS, permissions) &&
                    (!readOnlyWorkticket ? (
                      <IconButton
                        aria-label="delete"
                        className={classes.deleteIcon}
                        onClick={() => handleDeleteTask(task.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : null)}
                </Box>
              }
              labelPlacement="end"
              className={classes.taskItem}
              onChange={handleChange}
            />
          ))}
          {taskList.length === 0 && !addTask && (
            <Box className={classes.containerEmpty}>
              <Typography variant="h5" gutterBottom>
                No tasks have been created.
              </Typography>
            </Box>
          )}

          <FormControlLabel
            value={"new"}
            style={{ display: addTask ? "inline-flex" : "none" }}
            control={
              <Checkbox className={classes.tableCheckbox} color="secondary" />
            }
            label={
              <Input
                defaultValue=""
                className={classes.inputNewTask}
                disableUnderline={true}
                inputProps={{ "aria-label": "description" }}
                onBlur={handleSaveItem}
                inputRef={inputTaskRef}
              />
            }
            labelPlacement="end"
            className={classNames(classes.taskItem, classes.taskItemAdd)}
          />
        </FormGroup>
      </FormControl>
      {hasPermission(permissionWorkticket.TASKS, permissions) &&
        !readOnlyWorkticket &&
        (!addTask ? (
          <Box className={classes.boxActions}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonAdd)}
              onClick={handleTaskAdd}
            >
              Add Task
            </Button>
          </Box>
        ) : (
          <Box className={classes.boxActions}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonAdd)}
              onClick={handleTaskAddSubmit}
            >
              Save Task
            </Button>
          </Box>
        ))}
    </Box>
  );
};

export default WorkticketTask;
