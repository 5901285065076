const configData = {
  apiUrl: process.env.REACT_APP_API_URL,
  apiSurfaceUrl: process.env.REACT_APP_API_SURFACE_URL,
  appUrl: process.env.REACT_APP_URL,
  appLegacy: process.env.REACT_APP_LEGACY,
  tokenKey: process.env.REACT_APP_TOKEN_KEY,
  mapKey: process.env.REACT_APP_MAP_KEY
};

module.exports = configData;
