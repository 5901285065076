import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";

import { getFilterEvaluations } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";

import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import useStyles from "components/common/Filters/styles";

const EvaluationsFilters = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [surveyorFilters, setSurveyorFilters] = useState([]);
  const [surveyFilters, setSurveyFilters] = useState([]);
  const [surveys, setSurveys] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const usersList = useMemo(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return [{ id: user.id, first_name: "Me", last_name: "" }, ...users];
  }, [users]);

  useEffect(() => {
    const loadSurveys = async () => {
      try {
        const resultForRender = await getFilterEvaluations();
        setSurveys(resultForRender);
      } catch (e) {
        logException(e, "Cannot load survey data");
      }
    };
    if (anchorEl && !surveys) {
      loadSurveys();
    }
  }, [anchorEl, surveys]);

  useEffect(() => {
    if (anchorEl) {
      if (users.length) {
        const selectedSurveyorFilter = filters.find(
          (filter) => filter.filter === "surveyor"
        );
        if (selectedSurveyorFilter) {
          const selectedValue = selectedSurveyorFilter.values.map(
            (assigned) => assigned.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setSurveyorFilters(findUsers);
        } else {
          setSurveyorFilters([]);
        }
      }
      if (surveys?.length) {
        const selectedSurveyFilter = filters.find(
          (filter) => filter.filter === "survey"
        );
        if (selectedSurveyFilter) {
          const selectedValue = selectedSurveyFilter.values.map(
            (survey) => survey.value
          );
          const findSurvey = surveys.filter((survey) =>
            selectedValue.includes(survey.value)
          );
          setSurveyFilters(findSurvey);
        } else {
          setSurveyFilters([]);
        }
      }
    }
  }, [anchorEl, filters, users, surveys]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (surveyorFilters.length) {
      const surveyorFilterConvert = surveyorFilters.map((assigned) => {
        return {
          label: `${assigned.first_name} ${assigned.last_name}`,
          value: assigned.id,
        };
      });
      filterData.push({
        filter: "surveyor",
        group: "Submitted By",
        values: [...surveyorFilterConvert],
      });
    }

    if (surveyFilters.length) {
      filterData.push({
        filter: "survey",
        group: "Survey",
        values: [...surveyFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeSurveyor = (event, value) => {
    if (value) {
      setSurveyorFilters(value);
    } else {
      setSurveyorFilters([]);
    }
  };

  const handleChangeSurvey = (event, value) => {
    if (value) {
      setSurveyFilters(value);
    } else {
      setSurveyFilters([]);
    }
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Submitted By
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={usersList}
                    name="assigned"
                    handleChange={handleChangeSurveyor}
                    multiple={true}
                    value={surveyorFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Survey Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  {surveys && Boolean(surveys.length) ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={surveys}
                      name="survey"
                      handleChange={handleChangeSurvey}
                      multiple={true}
                      value={surveyFilters}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default EvaluationsFilters;
