import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GlobalUiContext from "contexts/globalUiContext";
// import { hasPermission, permissionSurvey } from "lib/permissions";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import useStyles from "./styles";

const options = [
  { id: 1, label: "View", value: "VIEW", user: false },
  { id: 2, label: "Download", value: "DOWNLOAD", user: false },
  { id: 3, label: "Delete", value: "DELETE", user: true },
];

const SurveyTabTableActions = (props) => {
  const { row, mobile } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      props.setRefresh(!props.refresh);
      setRefresh(false);
    }
  }, [refresh]);

  const optionsWithPermissions = [];

  optionsWithPermissions.push(options[0]);
  optionsWithPermissions.push(options[1]);

  // if (hasPermission(permissionSurvey.DELETE_SURVEY, permissions)) {
  //   optionsWithPermissions.push(options[1]);
  // }

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDel, setOpen] = React.useState(false);
  const handleCloseDelDialog = () => {
    setOpen(false);
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {optionsWithPermissions.map((option) => (
          <MenuItem key={option.id} onClick={() => {}} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withRouter(SurveyTabTableActions);
