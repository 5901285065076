import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BudgetFileDownload from "./budgetFileDownload";

import { getBudgetHistory } from "services/budgetService";
import { useBudgetView } from "contexts/budgetViewContext";
import { dateFormatField } from "components/util/timeFormat";

import useStyles from "./styles";

const headerColumns = [
  "Created By",
  "Created Date",
  "Effective Date",
  "Finalize Date",
  "Active",
  "File",
];

const BudgetSummaryTable = (props) => {
  const classes = useStyles();
  const [history, setHistory] = useState(null);

  const [stateContext] = useBudgetView();
  const { budget, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadHistory = async () => {
      try {
        const result = await getBudgetHistory(budget.id);
        if (result) {
          setHistory(result.data.data.records);
        }
      } catch (e) {
        console.log("Cannot load budget history data");
      }
    };
    if (!isLoading && !history) {
      loadHistory();
    }
  }, [history, budget, isLoading]);

  if (!history) {
    return null;
  }

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell className={classes.rowHeader} key={column}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((item) => (
              <TableRow tabIndex={-1} key={item.id}>
                <TableCell>{`${item.user.first_name} ${item.user.last_name}`}</TableCell>
                <TableCell>{dateFormatField(item.created_at)}</TableCell>
                <TableCell>{dateFormatField(item.effective_date)}</TableCell>
                <TableCell>
                  {!item.active ? dateFormatField(item.finalize_date) : ""}
                </TableCell>
                <TableCell>{item.active ? "Active" : ""}</TableCell>
                <TableCell>
                  <BudgetFileDownload fileId={item?.files[0].id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BudgetSummaryTable;
