import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";

import useStyles from "./styles";

const FileUpload = (props) => {
  const classes = useStyles();
  const [errorSize, setErrorSize] = useState(null);
  const { title, value, handleChange, handleUpdate, filesDisplay } = props;

  const onDrop = (acceptedFiles) => {
    const filesList = value;
    acceptedFiles.forEach((fileI) => {
      const fileSizeMB = fileI.size / 1024 ** 2;
      if (fileSizeMB > 20) {
        setErrorSize(true);
        setTimeout(() => {
          setErrorSize(false);
        }, 3000);
      } else {
        filesList.push(fileI);
      }
    });
    handleChange(filesList);
    handleUpdate(filesList.length);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...props.settings,
    onDrop,
  });

  return (
    <>
      {errorSize ? (
        <Alert severity="error" className={classes.containerAlert}>
          File size exceeds the 20 MB supported, reduce the file size.
        </Alert>
      ) : null}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box className={classes.dropZone}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleFileUpload}
            >
              Drop the files here
            </Typography>
          </Box>
        ) : filesDisplay ? (
          <Box className={classes.fileDisplayWrapper}>
            <Typography variant="h6" gutterBottom className={classes.titleFile}>
              {title ?? `Documents`}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.uploadLink}
            >
              Upload {title ?? `Documents`}
            </Typography>
            <Box className={classes.fileDisplayContainer}>{filesDisplay}</Box>
          </Box>
        ) : (
          <Box className={classes.dropZone}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleFileUpload}
            >
              Drag documents here
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.description}
            >
              or&nbsp;
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              className={classes.linkFormat}
            >
              browse your computer
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

export default FileUpload;
