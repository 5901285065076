import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;

export function getComments(id) {
  return http.get(`${apiEndpoint}/${id}/comments`);
}

export function addComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comments`, data);
}

export function updateComment(id, id_comment, data) {
  return http.put(`${apiEndpoint}/${id}/comments/${id_comment}`, data);
}

export function deleteComment(id, id_comment) {
  return http.delete(`${apiEndpoint}/${id}/comments/${id_comment}`);
}
export async function getPartnerComments(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/comments`);
}
export async function addNewPartnerComment(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/comments`,
    data.data
  );
}
export async function deletePartnerComment(data) {
  return await http.delete(
    `${apiEndpoint}/${data.partner_id}/comments/${data.id}`
  );
}
export async function updatePartnerComment(data) {
  return await http.put(
    `${apiEndpoint}/${data.partner_id}/comments/${data.id}`,
    data.data
  );
}
