import React from "react";
import * as ringBellService from "../services/ringBellService";
import { merge } from "lodash";

const defaultState = {
  ringBellListLoading: true,
  ringBellSelected: null,
  ringBellList: [],
  ringBellCount: [],
  openRingBell: false,
  openRingBellSingle: false,
  ringBellManager: null,
  openAnnouncement: false,
  openSurveyDialog: false,
  openNoticeDrawer: false,
  ringBellUserId: null,
  updateRecognitions: false,
};

const RingBellStateContext = React.createContext();
const RingBellDispatchContext = React.createContext();

function ringBellReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        ringBellListLoading: action.ringBellListLoading,
      };
    case "SET_RINGBELL_LIST":
      return {
        ...state,
        ringBellList: action.ringBellList,
      };
    case "SET_RINGBELL_COUNT":
      return {
        ...state,
        ringBellCount: action.ringBellCount,
      };
    case "SET_RINGBELL_SELECTED":
      return {
        ...state,
        ringBellSelected: action.ringBellSelected,
      };

    case "TOGGLE_RINGBELL":
      return {
        ...state,
        openRingBell: action.openRingBell,
      };

    case "TOGGLE_RINGBELL_SINGLE":
      return {
        ...state,
        openRingBellSingle: action.openRingBellSingle,
      };

    case "SET_MANAGER":
      return {
        ...state,
        ringBellManager: action.ringBellManager,
      };

    case "TOGGLE_ANNOUNCEMENT":
      return {
        ...state,
        openAnnouncement: action.openAnnouncement,
      };

    case "TOGGLE_OPENSURVEY":
      return {
        ...state,
        openSurveyDialog: action.openSurveyDialog,
      };

    case "TOGGLE_OPENNOTICE":
      return {
        ...state,
        openNoticeDrawer: action.openNoticeDrawer,
      };
    case "SET_RINGBELL_USERID":
      return {
        ...state,
        ringBellUserId: action.ringBellUserId,
      };

    case "TOGGLE_UPDATE_RECOGNITIONS":
      return {
        ...state,
        updateRecognitions: action.updateRecognitions,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function RingBellProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(ringBellReducer, mergedState);
  return (
    <RingBellStateContext.Provider value={state}>
      <RingBellDispatchContext.Provider value={dispatch}>
        {children}
      </RingBellDispatchContext.Provider>
    </RingBellStateContext.Provider>
  );
}

function useRingBellState() {
  const context = React.useContext(RingBellStateContext);
  if (context === undefined) {
    throw new Error("useRingBellState must be used within a RingBellProvider");
  }
  return context;
}

function useRingBellDispatch() {
  const context = React.useContext(RingBellDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useRingBellDispatch must be used within a RingBellProvider"
    );
  }
  return context;
}

async function getRingBellList(
  dispatch,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    dispatch({
      type: "SET_LOADING",
      ringBellListLoading: true,
    });
    const ringBellListResult = isDashboard
      ? await ringBellService.getRingBellListDashboard(
          filterData,
          dashboardState
        )
      : await ringBellService.getRingBellList(filterData);
    dispatch({
      type: "SET_RINGBELL_LIST",
      ringBellList: [...ringBellListResult.data.data.list],
    });
    dispatch({
      type: "SET_MANAGER",
      ringBellManager: ringBellListResult.data.data.manager
        ? { ...ringBellListResult.data.data.manager }
        : null,
    });
    dispatch({
      type: "SET_RINGBELL_COUNT",
      ringBellCount: ringBellListResult.data.data.filter_count,
    });
    dispatch({
      type: "SET_LOADING",
      ringBellListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getRingBellListDataSet(
  ringBellList,
  dispatch,
  offset,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    const result = isDashboard
      ? await ringBellService.getRingBellListOffsetDashboard(
          offset,
          filterData,
          dashboardState
        )
      : await ringBellService.getRingBellListOffset(offset, filterData);
    const updateData = ringBellList.concat(result.data.data.list);
    dispatch({
      type: "SET_RINGBELL_LIST",
      ringBellList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchRingBellList(
  search,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    const ringBellListResult = isDashboard
      ? await ringBellService.getRingBellListSearchDashboard(
          search,
          filterData,
          dashboardState
        )
      : await ringBellService.getRingBellListSearch(search, filterData);
    return ringBellListResult.data.data.list;
  } catch (error) {
    console.log(error);
  }
}

export {
  RingBellProvider,
  useRingBellState,
  useRingBellDispatch,
  getRingBellListDataSet,
  getRingBellList,
  searchRingBellList,
};
