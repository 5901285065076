import React, { useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useRingBellDispatch } from "contexts/ringBellContext";
import { permissionTalent, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

import useStyles from "./styles";

const RingBellTableActions = (props) => {
  const classes = useStyles();
  const { row } = props;
  const dispatchRingBell = useRingBellDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleClick = (event) => {
    dispatchRingBell({
      type: "SET_RINGBELL_SELECTED",
      ringBellSelected: row,
    });
    dispatchRingBell({
      type: "TOGGLE_RINGBELL_SINGLE",
      openRingBellSingle: true,
    });
  };

  const handleEvaluate = (event) => {
    dispatchRingBell({
      type: "SET_RINGBELL_SELECTED",
      ringBellSelected: row,
    });
    dispatchRingBell({
      type: "TOGGLE_OPENSURVEY",
      openSurveyDialog: true,
    });
  };

  const handleNotice = (event) => {
    dispatchRingBell({
      type: "SET_RINGBELL_SELECTED",
      ringBellSelected: row,
    });
    dispatchRingBell({
      type: "TOGGLE_OPENNOTICE",
      openNoticeDrawer: true,
    });
  };

  return (
    <Box className={classes.actionsContainer}>
      {hasPermission(permissionTalent.EVALUATE, permissions) ? (
        <Button
          variant="contained"
          aria-label="more"
          aria-haspopup="true"
          className={classNames(
            classes.button,
            classes.buttonSecondary,
            classes.teamActionButton
          )}
          onClick={handleEvaluate}
          size="small"
        >
          Evaluate
        </Button>
      ) : null}
      {hasPermission(permissionTalent.NOTICE, permissions) ? (
        <Button
          variant="contained"
          aria-label="more"
          aria-haspopup="true"
          className={classNames(
            classes.button,
            classes.buttonSecondary,
            classes.teamActionButton
          )}
          onClick={handleNotice}
          size="small"
        >
          Notice
        </Button>
      ) : null}
      <Button
        variant="contained"
        aria-label="more"
        aria-haspopup="true"
        className={classNames(
          classes.button,
          classes.buttonSecondary,
          classes.teamActionButton
        )}
        onClick={handleClick}
        size="small"
      >
        Ring Bell
      </Button>
    </Box>
  );
};

export default withRouter(RingBellTableActions);
