import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";

const CommonDialog = props => {
  const classes = useStyles();
  const { open, maxWidth, icon, variant, noButtons } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperSuccess}>
        <IconButton
          aria-label="close"
          onClick={props.handleCancel}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        {props.children}
      </DialogContent>
      {!variant && !noButtons && (
        <DialogActions className={classes.wrapperActionSuccess}>
          <Button
            onClick={props.handleCancel}
            className={classes.buttonCancel}
            variant="outlined"
            size="large"
            color="primary"
          >
            {props.cancelMessage}
          </Button>

          <Button
            onClick={props.handleConfirm}
            className={classes.buttonConfirm}
            color="primary"
            variant="contained"
            size="large"
          >
            {props.confirmMessage}
          </Button>
        </DialogActions>
      )}
      {variant && !noButtons && (
        <DialogActions className={classes.wrapperActionSuccess}>
          <Button
            onClick={props.handleCancel}
            className={classes.buttonCancelVariant}
            variant="outlined"
            size="large"
            color="primary"
          >
            {props.cancelMessage}
          </Button>

          <Button
            onClick={props.handleConfirm}
            className={classes.buttonConfirmVariant}
            color="primary"
            variant="contained"
            size="large"
            endIcon={icon}
          >
            {props.confirmMessage}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default CommonDialog;
