import React, { useState, useContext, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import MessageDialog from "components/ui/dialog/messageDialog";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";

import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { uploadWorkorders } from "services/worknovaService";
import GlobalUiContext from "contexts/globalUiContext";

import { worknovaLocations, worknovaTypes } from "constants.js";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorknovaUpload = (props) => {
  const classes = useStyles();
  const [type, setType] = useState([]);
  const [location, setLocation] = useState(null);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [extensionError, setExtensionError] = useState(false);

  const { dispatchGlobalUi } = useContext(GlobalUiContext);

  const closeMessageDialog = () => {
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: false,
    });
    setOpenMessage(false);
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
    setUpdate(!update);
  };

  const handleChangeLocation = (event, value) => {
    setLocation(value);
  };

  const handleChangeType = (event, value) => {
    setType(value);
  };

  const handleSubmit = async () => {
    try {
      const typeValue = type.map((typeItem) => typeItem.value);

      const data = {
        location: location.value,
        type: typeValue,
        files,
      };
      setIsLoading(true);
      await uploadWorkorders(data);
      setOpenMessage(true);
      setIsLoading(false);
      setIsCompleted(true);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
    } catch (e) {
      logException(e, "Cannot upload workorders");
    }
  };

  const handleChangeFiles = (selectedFiles) => {
    const acceptedFile = [];
    selectedFiles.forEach((fileI) => {
      const extension = fileI.name.split(".").pop();
      if (extension === "xls" || extension === "xlsx") {
        acceptedFile.push(fileI);
      } else {
        setExtensionError(true);
      }
    });
    setFiles(acceptedFile);
  };

  useEffect(() => {
    setUpdate(files.length);
  }, [files]);

  useEffect(() => {
    if (extensionError) {
      setTimeout(() => {
        setExtensionError(false);
      }, 5000);
    }
  }, [extensionError]);

  const activeSubmit = type && location && files.length === 1;

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Upload Workorders
        </Typography>
        <Typography
          variant="h6"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Upload Excel file matching the template format.
        </Typography>
      </div>
      {isLoading ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <Box className={classes.optionWrapper}>
          {!isCompleted ? (
            <>
              <Box>
                <Grid container spacing={4}>
                  <Grid item md={12} sm={12}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={worknovaLocations}
                      name="location"
                      label="Location"
                      handleChange={handleChangeLocation}
                      value={location}
                    />
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={worknovaTypes}
                      name="type"
                      label="Type"
                      handleChange={handleChangeType}
                      value={type}
                      multiple={true}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <FileUpload
                  title={"File"}
                  value={files}
                  handleChange={handleChangeFiles}
                  handleUpdate={setUpdate}
                  filesDisplay={
                    Boolean(update) && (
                      <FilesDisplay files={files} handleRemove={handleRemove} />
                    )
                  }
                />
                {extensionError ? (
                  <Alert severity="error" className={classes.infoAlert}>
                    Only file extension xlx and xlsx are accepted.
                  </Alert>
                ) : null}
              </Box>
              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!activeSubmit}
                >
                  Submit
                </ButtonBase>
              </Box>
            </>
          ) : null}
        </Box>
      )}
      <MessageDialog
        open={openMessage}
        title={"Success!"}
        message={
          "Workorders file have been submitted - This Process will be completed in approximately 5-15 minutes. You will get an email notification when the upload synch process is completed."
        }
        handleClose={closeMessageDialog}
      />
    </>
  );
};

export default withRouter(WorknovaUpload);
