import { isValidFormatCommentTimestamp } from "components/util/stringFormat";

export const extractCommentTime = (videoMetadata) => {
  try {
    if (typeof videoMetadata !== "string") {
      return null;
    }

    const metadataObj = JSON.parse(
      videoMetadata.replace(/\\\"/g, '"').replace(/^"|"$/g, "")
    );
    const commentTime = metadataObj?.metadata?.comment_time?.trim();

    return commentTime && isValidFormatCommentTimestamp(commentTime)
      ? commentTime
      : null;
  } catch (error) {
    console.error("Error in the extractCommentTime function:", error);
    return null;
  }
};
