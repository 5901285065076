import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import { logException } from "components/util/logUtil";
import useStyles from "components/common/Filters/styles";

import { getFilterJobs, getFilterCustomers } from "components/util/filterUtil";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const TimekeepingFilters = (props) => {
  const classes = useStyles();
  const [jobFilters, setJobFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length) {
      loadJob();
    }
  }, [anchorEl, jobs]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (anchorEl && !customers.length) {
      loadCustomer();
    }
  }, [anchorEl, customers]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
      if (customers.length) {
        const selectedCustomersFilter = filters.find(
          (filter) => filter.filter === "customer"
        );
        if (selectedCustomersFilter) {
          setCustomerFilters(selectedCustomersFilter.values);
        } else {
          setCustomerFilters([]);
        }
      }
    }
  }, [anchorEl, filters, jobs, customers.length]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (customerFilters.length) {
      filterData.push({
        filter: "customer",
        group: "Customer",
        values: [...customerFilters],
      });
    }

    if (jobFilters.length) {
      // Map label and value with job data
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    setJobFilters(value);
  };

  const handleChangeCustomer = (event, value) => {
    setCustomerFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          {!Boolean(jobFilters.length) && (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
                {Boolean(customers.length) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={customers}
                    name="customer"
                    internal={true}
                    handleChange={handleChangeCustomer}
                    multiple={true}
                    value={customerFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          )}
          {!Boolean(customerFilters.length) && (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Job
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
                {Boolean(jobs.length) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    label="Job"
                    internal={true}
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={jobFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          )}

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default TimekeepingFilters;
