import React from "react";
import Box from "@material-ui/core/Box";
import SurveysTabTable from "./surveysTabTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { SurveysTabProvider } from "contexts/surveysTabContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import SurveysTabListDialog from "./dialog/surveysTabListDialog";
import useStyles from "./styles";

const SurveysTabList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <SurveysTabProvider>
        <SurveysTabTable />
        <SurveyFillProvider>
          <SurveysTabListDialog />
        </SurveyFillProvider>
      </SurveysTabProvider>
    </Box>
  );
};

export default withTableFilterContext(SurveysTabList);
