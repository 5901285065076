import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import React from "react";
import WeeklyScheduleModal from "../openPositions/weeklyScheduleModalViewOnly";
import useStyles from "./styles";
import { logException } from "components/util/logUtil";
import moment from "moment";
const downloadFile = async (fileURL) => {
  try {
    window.open(fileURL);
  } catch (e) {
    logException(e, "Cannot download file");
  }
};
export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();

  const response = selectedModalViewResponse;

  const res = response?.data?.form_change_request
    ? response.data.form_change_request
    : "";
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                }}
              >
                <Typography className={classes.modalTitle}>
                  Talent Change Request
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      First Name
                    </Typography>
                    <TextField
                      name="name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      value={res?.created_by?.first_name}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Site Name
                    </Typography>
                    <TextField
                      name="site_name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.job_new?.job_description}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Employee Name
                    </Typography>
                    <TextField
                      name="employee_name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={
                        res?.user
                          ? (res?.user?.first_name
                              ? res?.user?.first_name
                              : "") +
                            " " +
                            (res?.user?.last_name ? res?.user?.last_name : "")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Employee Number
                    </Typography>
                    <TextField
                      name="employee_number"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.user?.employee_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Effective date
                    </Typography>
                    <TextField
                      name="effective_date"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(res?.effective_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Change Requested
                    </Typography>
                    <TextField
                      name="change_requested"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.change_request}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Explaination for change
                    </Typography>
                    <TextField
                      name="explaination_for_change"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.change_explanation}
                    />
                  </Grid>
                </Grid>

                {res?.file && (
                  <Grid constainer spacing={3}>
                    <Typography
                      className={classes.downloadFile}
                      onClick={() => downloadFile(res?.file)}
                    >
                      Download attached file
                    </Typography>
                  </Grid>
                )}

                <Grid container spacing={3}>
                  {JSON.parse(res?.schedule ? res.schedule : '""') === "" ? (
                    <Typography className={classes.modalTitle}>
                      No Schedule Available
                    </Typography>
                  ) : (
                    <Box className={classes.weeklyScheduleView}>
                      <WeeklyScheduleModal
                        scheduleData={
                          res?.schedule && JSON.parse(res?.schedule)
                            ? JSON.parse(res?.schedule)
                            : null
                        }
                        viewOnly={true}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subTitle}>
                      Current Rate
                    </Typography>
                    <TextField
                      name="current_rate"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.current_rate}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subTitle}>
                      Options Current Rate
                    </Typography>
                    <TextField
                      name="options_current_rate"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.current_rate_type}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subTitle}>
                      New Rate
                    </Typography>
                    <TextField
                      name="new_rate"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.new_rate}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subTitle}>
                      Options New Rate
                    </Typography>
                    <TextField
                      name="options_new_rate"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.new_rate_type}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subTitle}>
                      Change in Job Title
                    </Typography>
                    <TextField
                      name="change_in_job_title"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.change_position_title}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Change in Position Job Code
                    </Typography>
                    <TextField
                      name="change_in_job_code"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.change_position_job_code}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      New Account Number
                    </Typography>
                    <TextField
                      name="new_ac"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.job_new?.job_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Describe any additional changes needed(technology,
                      training, other)
                    </Typography>
                    <TextField
                      name="additional_changes_needed"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={res?.additional_changes}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
