import React, { useContext } from "react";
import eosLogo from "../../../assets/logo-eos-dark.svg";
import * as classNames from "classnames";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import GlobalUiContext from "contexts/globalUiContext";
import NavItem from "./navItem";
import useStyles from "./styles";

const SideNav = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);

  const { isNavBarOpen, isNavBarShow } = globalUi;

  return isNavBarShow ? (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isNavBarOpen,
        [classes.drawerClose]: !isNavBarOpen,
      })}
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerOpen]: isNavBarOpen,
          [classes.drawerClose]: !isNavBarOpen,
        }),
      }}
      open={isNavBarOpen}
    >
      <Box className={classes.navWrapper}>
        <Box className={classes.toolbar}>
          <Box className={classes.logoWrapper}>
            <Link to="/">
              <img
                src={eosLogo}
                alt="Encompass Onsite"
                className={classes.logo}
              />
            </Link>
          </Box>
        </Box>
        <List className={classes.listMenu}>
          <NavItem />
        </List>
      </Box>
      <IconButton
        aria-label="Open Close sidebar"
        onClick={() => dispatchGlobalUi({ type: "TOGGLE_NAV" })}
        className={classes.menuOpen}
      >
        <MenuIcon />
      </IconButton>
    </Drawer>
  ) : null;
};

export default SideNav;
