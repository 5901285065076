import React from "react";

const CommentsIconBlack = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.09423 2.21519C5.82308 1.99073 7.5858 1.875 9.37524 1.875C11.1645 1.875 12.927 1.99071 14.6557 2.21513C16.2576 2.42308 17.3986 3.76614 17.4936 5.32079C17.2221 5.23012 16.9343 5.16997 16.6334 5.14499C15.4762 5.04895 14.3061 5 13.125 5C11.9439 5 10.7738 5.04895 9.61665 5.14498C7.65142 5.30809 6.25 6.97056 6.25 8.84018V12.4114C6.25 13.8203 7.04557 15.1105 8.27734 15.7315L6.06694 17.9419C5.88819 18.1207 5.61937 18.1742 5.38582 18.0774C5.15228 17.9807 5 17.7528 5 17.5V14.1421C4.69708 14.1097 4.39514 14.0739 4.09424 14.0348C2.42055 13.8175 1.25 12.3611 1.25 10.7189V5.53115C1.25 3.88893 2.42055 2.43249 4.09423 2.21519Z"
      fill="#747474"
    />
    <path
      d="M13.125 6.25C11.9784 6.25 10.8428 6.29751 9.72004 6.3907C8.4373 6.49717 7.5 7.58568 7.5 8.84018V12.4114C7.5 13.6678 8.43997 14.7569 9.72434 14.8618C10.7609 14.9464 11.8084 14.9927 12.8653 14.9992L15.1831 17.3169C15.3618 17.4957 15.6306 17.5492 15.8642 17.4524C16.0977 17.3557 16.25 17.1278 16.25 16.875V14.8834C16.342 14.8765 16.4338 14.8693 16.5256 14.8619C17.81 14.757 18.75 13.6679 18.75 12.4115V8.84018C18.75 7.58569 17.8127 6.49717 16.53 6.39071C15.4072 6.29752 14.2716 6.25 13.125 6.25Z"
      fill="#747474"
    />
  </svg>
);

export default CommentsIconBlack;
