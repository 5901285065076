import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import * as classNames from "classnames";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "./styles";

const requestedForOptions = [
  { label: "Dispatch", value: "Dispatch" },
  { label: "Manager", value: "Manager" },
  { label: "Site Supervisor", value: "Site Supervisor" },
  { label: "Repair Technician", value: "Repair Technician" },
];

export default function AutoGrid({
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
}) {
  const templatePayload = {
    job_id: "",
    fix_at: "",
    transfer_by: "",
    items: [],
  };

  const whoWillTransferOptions = [
    { label: "Team Member", value: 2 },
    { label: "Me", value: 1 },
  ];
  const [requestFor, setRequestFor] = useState("");

  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");

  const { globalUi } = useContext(GlobalUiContext);
  const [addMultiple, setAddMultiple] = useState([
    {
      description: "",
      serial_number: "",
      make: "",
      model: "",
      issue: "",
    },
  ]);
  const addMultipleObj = {
    description: "",
    serial_number: "",
    make: "",
    model: "",
    issue: "",
  };

  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });
  const handleChangeCustomer = (event, value) => {
    setCustomerSelected(value);
  };

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, [customerSelected?.value]);
  const classes = useStyles();
  const [form, setForm] = useState({});

  let validAddMultiple = true;
  addMultiple.map((v) => {
    if (!v.description || !v.make || !v.model) {
      validAddMultiple = false;
    }
  });

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        onChange={(e) => {
          if (e.target.id) {
            if (e.target.id.length === 1) {
              addMultiple[e.target.id][e.target.name] = e.target.value;
              setAddMultiple([...addMultiple]);
              return;
            }
          }

          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid item xs={12} md={12}>
          <Box
            className={classes.Box}
            style={{
              overflow: "auto !important",
            }}
          >
            <Box className={classes.padding23}>
              <Typography className={classes.subTitle}>Job Name(*)</Typography>

              <span>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    options={jobs}
                    name="job_number"
                    handleChange={(e, v) => setJobSelected(v)}
                    value={jobSelected}
                    inputContainerProps={classes.inputContainer}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </span>
            </Box>
            <Grid container className={classes.inputContainer}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.subTitle}>Fix At:(*)</Typography>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <RadioGroup
                      aria-label="radio"
                      name="radio"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="account site"
                        control={<Radio color="primary" />}
                        label="Account Site"
                        name="fix_at"
                      />
                      <FormControlLabel
                        value="corporate office"
                        control={<Radio color="primary" />}
                        label="Corporate Office"
                        name="fix_at"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {form.fix_at === "corporate office" && (
              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subSubTitle}>
                    Who will be transferring?
                  </Typography>

                  <FormSelectAuto
                    options={requestedForOptions}
                    name="user"
                    handleChange={(e, v) => setRequestFor(v)}
                    value={requestFor}
                    inputContainer={classes.inputContainer}
                  />
                </Grid>
              </Grid>
            )}

            {addMultiple?.map((v, i) => (
              <Box>
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Typography className={classes.subSubTitle}>
                      Item Description(*)
                    </Typography>
                    <TextField
                      name="description"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      id={i}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Typography className={classes.subSubTitle}>
                      Serial No
                    </Typography>
                    <TextField
                      name="serial_number"
                      data-index={1}
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      id={i}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Typography className={classes.subSubTitle}>
                      Make(*)
                    </Typography>
                    <TextField
                      name="make"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      id={i}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Typography className={classes.subSubTitle}>
                      Model(*)
                    </Typography>
                    <TextField
                      name="model"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      id={i}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Typography className={classes.subSubTitle}>
                      Issue of Problem with Equipment
                    </Typography>
                    <TextField
                      name="issue"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      id={i}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box className={classes.plusRightEnd}>
                  {addMultiple.length > 1 && (
                    <IndeterminateCheckBoxIcon
                      className={classes.iconMultiRequest}
                      onClick={() => {
                        addMultiple.pop();
                        setAddMultiple([...addMultiple]);
                      }}
                    />
                  )}
                  &nbsp;
                  <AddCircleIcon
                    className={classes.iconMultiRequest}
                    onClick={() => {
                      addMultiple.push({ ...addMultipleObj });
                      setAddMultiple([...addMultiple]);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box>
              <br />
              <Grid container className={classes.rightAlignBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classNames(
                    classes.button,
                    classes.buttonSecondary
                  )}
                  disabled={
                    !jobSelected?.id || !form?.fix_at || !validAddMultiple
                  }
                  onClick={() => {
                    templatePayload.job_id = jobSelected.id;
                    templatePayload.fix_at = form.fix_at;
                    templatePayload.transfer_by = requestFor;
                    templatePayload.items = JSON.stringify(addMultiple);

                    Object.keys(templatePayload).map((v) => {
                      if (!templatePayload[v]) {
                        templatePayload[v] = "";
                      }
                    });
                    submitFormDataBasedOnCurrentTab(
                      templatePayload,
                      topTab,
                      formSelected
                    ).then((res) => {
                      if (res.status === 201) {
                        closeAndRefreshSidebar();
                      }
                    });
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
