import React, { useState, useEffect, useCallback, useContext } from "react";
import { useQuery } from "react-query";
import WorkticketTableActions from "../workticketsList/workticketTableActions";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import WorkticketsBulkActions from "components/ui/Worktickets/workticketsBulkActions";
import WorkticketFilters from "../workticketsList/workticketFilters";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import WorkticketPriority from "components/ui/Worktickets/WorkticketPriority";
import WorkticketAssigned from "components/ui/Worktickets/WorkticketAssigned";
import WorkticketTimekeeping from "components/ui/Worktickets/WorkticketTimekeeping";
import WorkticketsListTabActions from "components/ui/Worktickets/workticketsListTabActions";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import { workticketsStatusOptions } from "constants.js";
import { isEqual } from "lodash";
import useStyles from "../workticketsList/styles";
import Snackbar from "@material-ui/core/Snackbar";
import WorkticketField from "components/ui/Worktickets/WorkticketField";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useWorkticketState,
  useWorkticketDispatch,
  searchWorktickets,
  geoWorktickets,
  setWorkticketList,
  getWorkticketListDataSet,
} from "contexts/workticketContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { useProjectView } from "contexts/projectViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { getWorkticketsTabData } from "services/workticketService";
import { logException } from "components/util/logUtil";
import WorkticketsDialog from "./dialogWorktickets/workticketsDialog";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import WorkticketTableRequestActions from "pages/workticketsList/workticketTableRequestActions";
import WorkticketAssign from "components/ui/Worktickets/WorkticketAssign";
import WorkticketAccept from "components/ui/Worktickets/WorkticketAccept";
import WorkticketFinancialEdit from "components/ui/Worktickets/WorkticketFinancialEdit";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import EmergencyIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import WorkticketRequestStatus from "components/ui/Worktickets/WorkticketRequestStatus";
import WorkticketComments from "components/ui/Worktickets/WorkticketComments";
import WorkticketQuote from "components/ui/Worktickets/WorkticketQuote";

function useWorktickets(tab, filterData, filterAddon = undefined) {
  return useQuery(
    ["project-worktickets", { tab: tab, filters: { ...filterData } }],
    async () => {
      const findTabElement = workticketsStatusOptions.find(
        (item) => item.id === tab
      );
      if (!findTabElement) {
        return [];
      }
      const { data } = await getWorkticketsTabData(
        findTabElement.tab,
        0,
        filterData,
        filterAddon
      );
      return data;
    }
  );
}

const columns = [
  {
    id: "number",
    label: "Number",
    format: "id",
    path: "workticket",
    checkbox: {
      active: (row) => ![2, 3].includes(row.status),
    },
  },
  {
    id: "subject",
    label: "Name",
    style: { minWidth: 180 },
  },
  { id: "job_number", label: "Job No." },
  { id: "job_description", label: "Job" },
  {
    id: "users",
    label: "Assigned To",
    content: (row) =>
      row.usersAssign ? (
        <WorkticketField field={`${row.usersAssign}`} fieldLength={10} />
      ) : (
        "N/A"
      ),
    disableSort: true,
  },
  { id: "start_date", label: "Due Date", format: "date" },
  {
    id: "status",
    label: "Status",
    content: (row) => (
      <WorkticketStatusChip
        status={row.status}
        archived={row.archived}
        dueDate={row.start_date}
      />
    ),
    disableSort: true,
  },
  {
    id: "timekeeping",
    content: (row) => <WorkticketTimekeeping workticket={row} />,
    style: { textAlign: "center" },
    disableSort: true,
  },
  {
    id: "actions",
    content: (row) => <WorkticketTableActions row={row} />,
    contentMobile: (row) => <WorkticketTableActions row={row} mobile={true} />,
    disableSort: true,
  },
];

export const columnsRequests = [
  {
    id: "indicator",
    label: "",
    disableHeader: true,
    content: (row) =>
      row.workticket_category === 4 ? (
        <EmergencyIcon />
      ) : row.external_woid ? (
        <AddIcon />
      ) : null,
  },
  {
    id: "workticket_number",
    label: "Number",
    format: "id",
    path: "workticket",
  },
  { id: "created_at", label: "Date Sent", format: "date" },
  {
    id: "subject",
    label: "Name",
    style: { minWidth: 180 },
    content: (row) => <WorkticketField field={row.subject} fieldLength={35} />,
    disableSort: true,
  },
  { id: "customer_name", label: "Customer" },
  { id: "job_description", label: "Job", style: { minWidth: 150 } },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip Code" },
  { id: "nte", label: "NTE", format: "money" },
  { id: "site_number", label: "Site Number" },
  {
    id: "ar",
    label: "AR",
    style: { minWidth: 100 },
    content: (row) => <WorkticketFinancialEdit row={row} type={1} />,
  },
  {
    id: "ap",
    label: "AP",
    style: { minWidth: 100 },
    content: (row) => <WorkticketFinancialEdit row={row} type={2} />,
  },
  { id: "start_date", label: "Due Date", format: "date" },
  {
    id: "assigned",
    label: "Assigned To",
    content: (row) => (
      <WorkticketAssign
        id={row.id}
        state={row.state}
        user_id={parseInt(row.partner_assigned)}
      />
    ),
  },
  {
    id: "partner_coverage",
    label: "Partner Coverage",
    content: (row) => (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        {row.partner_coverage ? (
          <>
            <CheckCircleIcon style={{ marginRight: 10, color: "#328914" }} />
            <Typography variant="body2" noWrap>
              Yes
            </Typography>
          </>
        ) : (
          <>
            <CancelIcon style={{ marginRight: 10, color: "#D9D9D9" }} />
            <Typography variant="body2" noWrap>
              No
            </Typography>
          </>
        )}
      </Box>
    ),
  },
  {
    id: "trade",
    label: "Trade Needed",
  },
  {
    id: "source",
    label: "Source",
    content: (row) =>
      parseInt(row.workticket_category) === 7 ? "Service Channel" : "FM Pilot",
  },
  {
    id: "comments",
    label: "Comments",
    content: (row) => <WorkticketComments hasComments={row.has_comments} />,
  },
  {
    id: "response_time",
    label: "Response Time",
    content: (row) => (
      <WorkticketRequestStatus
        status={row.status}
        dateCreated={row.call_date}
        timezone={row.timezone}
        isApproved={parseInt(row.approval) === 1 ? true : false}
        category={row.workticket_category}
      />
    ),
  },
  {
    id: "quick_actions",
    style: { minWidth: 250 },
    content: (row) =>
      parseInt(row.status) !== 3 ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <WorkticketAccept
            id={row.id}
            status={row.status}
            approval={parseInt(row.approval) === 1 ? true : false}
            category={row.workticket_category}
          />
          <WorkticketQuote row={row} />
        </Box>
      ) : null,
  },
  {
    id: "actions",
    content: (row) => <WorkticketTableRequestActions row={row} />,
    contentMobile: (row) => (
      <WorkticketTableRequestActions row={row} mobile={true} />
    ),
    disableSort: true,
  },
];

export const columnsRequestsWithNoSource = columnsRequests.filter(
  (column) => column.id !== "source"
);

const columnsSchedule = [
  {
    id: "number",
    label: "Number",
    format: "id",
    path: "workticket",
    checkbox: {
      active: (row) => ![2, 3].includes(row.status),
    },
  },
  {
    id: "subject",
    label: "Name",
  },
  {
    id: "actions",
    content: (row) => <WorkticketTableActions row={row} />,
    contentMobile: (row) => <WorkticketTableActions row={row} mobile={true} />,
  },
  { id: "job_number", label: "Job Number" },
  { id: "job_description", label: "Job" },
  {
    id: "users",
    label: "Assigned To",
    content: (row) => <WorkticketAssigned users={row.users} />,
  },
  {
    id: "priority",
    label: "Priority",
    content: (row) => <WorkticketPriority priority={row.priority} />,
  },
  { id: "start_date", label: "Due Date", format: "date" },
  {
    id: "status",
    label: "Status",
    content: (row) => (
      <WorkticketStatusChip
        status={row.status}
        archived={row.archived}
        dueDate={row.start_date}
      />
    ),
  },
];

const SEARCH_INDEX = 9999;
const GEO_INDEX = 8888;

const WorkticketsTable = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useProjectView();
  const { project } = stateContext ?? null;
  const {
    workticketList,
    workticketCount,
    workticketLocation,
    scheduleMode,
    geoMode,
  } = useWorkticketState();
  const { currentTab, selected, tabUpdated } = useTableState();
  const filterState = useFilterState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingGeo, setLoadingGeo] = useState(false);
  const dispatchTable = useTableDispatch();
  const dispatchWorktickets = useWorkticketDispatch();
  const dispatchFilters = useFilterDispatch();
  const filterData = getFilterData(filterState);
  filterData.filters = [
    ...filterData.filters,
    {
      filter: "project",
      group: "project",
      values: [{ label: "", value: project.id }],
    },
  ];

  const { data, isLoading, isFetching, refetch } = useWorktickets(
    currentTab,
    filterData
  );

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          return dispatchWorktickets({
            type: "SET_WORKTICKET_LOCATION",
            workticketLocation: position,
          });
        }
      }
    };
    executeGeo();
  }, [dispatchWorktickets]);

  useEffect(() => {
    const moduleName = window.location.pathname.split("/")[1];
    if (localStorage.getItem(`filter_${moduleName}`)) {
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: JSON.parse(
          localStorage.getItem(
            `filter_${window.location.pathname.split("/")[1]}`
          )
        ),
      });
    }
    if (localStorage.getItem(`filter_date_${moduleName}`)) {
      const dataDate = JSON.parse(
        localStorage.getItem(`filter_date_${moduleName}`)
      );
      dispatchFilters({
        type: "SET_DATE",
        ...dataDate,
      });
    }
    dispatchFilters({
      type: "SET_LOADING",
      isLoadingFilters: false,
    });
  }, [dispatchFilters]);

  useEffect(() => {
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: scheduleMode ? columnsSchedule : columns,
    });
  }, [dispatchTable, scheduleMode]);

  useEffect(() => {
    if (!isLoading && !geoMode && filterState.searchString === "") {
      const local_tab = localStorage.getItem("local_worktickets_tab");
      if (!local_tab) {
        dispatchTable({ type: "SET_CURRENT_TAB", currentTab: 0 });
        localStorage.setItem("local_worktickets_tab", 0);
        return;
      }
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: Number(local_tab) });
    }
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, isLoading, geoMode, filterState.searchString]);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  useEffect(() => {
    if (filterState.searchString === "" && !isLoading && data) {
      setWorkticketList(dispatchWorktickets, data);
    }
  }, [filterState.searchString, dispatchWorktickets, data, isLoading]);

  useEffect(() => {
    if (!loadingGeo && !loadingSearch) {
      const workticketData = workticketList ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: workticketData });

      const tableColumns = [41].includes(Number(currentTab))
        ? hasPermission(permissionWorkticket.REQUESTS_SOURCE, permissions)
          ? columnsRequests
          : columnsRequestsWithNoSource
        : columns;
      dispatchTable({ type: "UPDATE_COLUMNS", columns: tableColumns });
    }
  }, [
    dispatchTable,
    workticketList,
    currentTab,
    loadingGeo,
    loadingSearch,
    permissions,
  ]);

  useEffect(() => {
    const loadGeoWorktickets = async () => {
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: GEO_INDEX });
      setLoadingGeo(true);
      const results = await geoWorktickets(workticketLocation);
      if (!isEqual(workticketList, results)) {
        dispatchWorktickets({
          type: "SET_WORKTICKET_LIST",
          workticketList: results,
        });

        dispatchWorktickets({
          type: "SET_WORKTICKET_COUNT",
          workticketCount: results.length,
        });
      }
      setLoadingGeo(false);
    };
    if (geoMode) {
      loadGeoWorktickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoMode, workticketLocation]);

  const handleTabChange = (event, newTab) => {
    const fetchData = async () => {
      if (currentTab === SEARCH_INDEX) {
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: "",
        });
      }
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: newTab });
      localStorage.setItem("local_worktickets_tab", newTab);
    };
    fetchData();
    dispatchWorktickets({
      type: "SET_GEO_MODE",
      geoMode: false,
    });
    setTimeout(() => {
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: null,
      });
    }, 3000);
  };

  const handleScrollClick = useCallback(async () => {
    if (workticketCount <= workticketList.length || geoMode || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "project",
          group: "project",
          values: [{ label: "", value: project.id }],
        },
      ];
      await getWorkticketListDataSet(
        workticketList,
        dispatchWorktickets,
        currentTab,
        workticketList.length,
        filterData
      );
      const workticketDataUpdate = workticketList ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: workticketDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      logException(e, "Cannot load more worktickets data");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    workticketList,
    workticketCount,
    filterState,
    dispatchWorktickets,
    dispatchTable,
    geoMode,
    loadingMore,
  ]);

  const handleSearch = (ev, query) => {
    const searchStatus = SEARCH_INDEX;
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = [
            ...filterData.filters,
            {
              filter: "project",
              group: "project",
              values: [{ label: "", value: project.id }],
            },
          ];
          const results = await searchWorktickets(query, filterData);
          console.log("rr", results);
          // Send result to search tab
          dispatchWorktickets({
            type: "SET_WORKTICKET_LIST",
            workticketList: results?.worktickets ?? [],
          });
          dispatchWorktickets({
            type: "SET_WORKTICKET_COUNT",
            workticketCount: results?.filter_counts ?? 0,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          dispatchTable({ type: "SET_CURRENT_TAB", currentTab: searchStatus });
          dispatchWorktickets({
            type: "SET_GEO_MODE",
            geoMode: false,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    setLoadingSearch(true);
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    dispatchWorktickets({
      type: "SET_GEO_MODE",
      geoMode: false,
    });
    const local_tab = localStorage.getItem("local_worktickets_tab");
    if (!local_tab) {
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: 0 });
      localStorage.setItem("local_worktickets_tab", 0);
      return;
    }
    dispatchTable({ type: "SET_CURRENT_TAB", currentTab: Number(local_tab) });
    setLoadingSearch(false);
  };

  const IndicatorUpdateTab = ({ tab }) => {
    if (tabUpdated?.tab === tab) {
      return <DotIcon className={classes[`newIndicator${tab}`]} />;
    }
    return null;
  };

  const LabelTab = ({ tab, label }) => {
    if (tabUpdated?.tab === tab) {
      return (
        <>
          {label}
          <span className={classes[`newLabel${tab}`]}>
            {`${tabUpdated?.signal ?? "+"}${tabUpdated.count}`}
          </span>
        </>
      );
    }
    return <>{label}</>;
  };

  const isLoadingData =
    isLoading || loadingSearch || loadingGeo || currentTab === -1;

  const hasFilters =
    filterState.filters ||
    filterState.startDateActive ||
    filterState.endDateActive;

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.contentCounter}>
          {!isLoadingData ? (
            <Counter>{`${workticketList?.length} / ${workticketCount}`}</Counter>
          ) : null}
        </Box>
        {currentTab !== -1 ? (
          <TableTabs handleTabChange={handleTabChange}>
            {workticketsStatusOptions
              .filter(
                (item) =>
                  item.onTabing &&
                  (item.permission === -1 ||
                    hasPermission(item.permission, permissions))
              )
              .map((option) => {
                return (
                  <StyledTab
                    disableRipple={true}
                    label={
                      <Box className={classes.tabItem}>
                        <IndicatorUpdateTab tab={option.id} />
                        <LabelTab label={option.text} tab={option.id} />
                      </Box>
                    }
                    value={option.id}
                    key={option.id}
                  />
                );
              })}
            {Boolean(geoMode) ? (
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Current Location</Box>}
                key={GEO_INDEX}
                value={GEO_INDEX}
              />
            ) : null}
            {Boolean(filterState.searchString) ? (
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Search</Box>}
                key={SEARCH_INDEX}
                value={SEARCH_INDEX}
              />
            ) : null}
          </TableTabs>
        ) : null}
        {!isLoadingData ? (
          <Box className={classes.rootContainer}>
            <Box className={classes.tableContainer}>
              <Box className={classes.fullHeightTableProject}>
                <Table
                  handleScrollClick={handleScrollClick}
                  activeMobile={true}
                >
                  <EnhancedTableToolbar>
                    {selected.length > 0 ? (
                      <ToolbarSelected>
                        <WorkticketsBulkActions />
                      </ToolbarSelected>
                    ) : (
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        placeholderSearch={"All Project Worktickets"}
                        handleSearchClear={handleSearchClear}
                      >
                        {project.type === 0 ? (
                          <WorkticketsListTabActions />
                        ) : null}

                        <CalendarFilters />
                        <WorkticketFilters
                          removeJobs={true}
                          removeProjects={true}
                          removeCustomers={true}
                        />
                      </ToolbarDefault>
                    )}
                  </EnhancedTableToolbar>
                  {hasFilters ? <FiltersAppliedList /> : null}
                </Table>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.centerLoadingSideBar}>
            <LoadingStateHorizontal isVisible />
          </Box>
        )}
        {loadingMore ? (
          <Box className={classes.loadingTable}>
            <LinearProgress color="secondary" />
          </Box>
        ) : null}
        {isFetching && !isLoadingData ? (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={true}
            message="Loading..."
            className={classes.snackItem}
          />
        ) : null}
      </Box>
      <WorkticketsDialog refetchData={refetch} />
    </Box>
  );
};

export default WorkticketsTable;
