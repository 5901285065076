import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import QuestionIcon from "@material-ui/icons/Help";

import GlobalUiContext from "contexts/globalUiContext";

import useStyles from "./styles";

const optionsScheduleInterval = [
  { value: 1, label: "Day", id: "days" },
  { value: 2, label: "Week", id: "weeks" },
  { value: 3, label: "Month", id: "months" },
];

const optionsDays = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
];

const optionsDaysMonth = [...Array(31).keys()].map((item) => {
  return { value: item + 1, label: String(item + 1) };
});

const scheduleInit = {
  startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  stopDate: moment().add(1, "year").format("YYYY-MM-DD HH:mm:ss"),
  skipWeekdays: 0,
};

const RecurrencePortfolioGenerator = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [scheduleInterval, setScheduleInterval] = useState(1);
  const [scheduleData, setScheduleData] = useState(scheduleInit);
  const [noEndDate, setNoEndDate] = useState(false);
  const [every, setEvery] = useState(1);
  const [days, setDays] = useState([1]);
  const [daysMonth, setDaysMonth] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  const {
    open,
    data,
    serviceData,
    handleClose,
    handleRecurrenceUnlink,
    subTitle,
  } = props;

  useEffect(() => {
    if (data) {
      const findScheduleInterval = optionsScheduleInterval.find(
        (item) => item.id === data.interval_type
      );
      if (findScheduleInterval) {
        setScheduleInterval(findScheduleInterval.value);
      }
      setEvery(data.interval_size);
      setScheduleData({
        startDate: data.start_date,
        stopDate: !data.stop_date
          ? moment().add(1, "month").format("YYYY-MM-DD HH:mm:ss")
          : data.stop_date,
        skipWeekdays: data.skip_weekends,
      });

      setNoEndDate(!data.stop_date ? true : false);
      if (data.interval_type === "weeks") {
        setDays(data.interval_days);
      }
      if (data.interval_type === "months") {
        const daysMonthUpdate = data.interval_days.map((item) => {
          return optionsDaysMonth.find((i) => i.value === Number(item));
        });
        setDaysMonth(daysMonthUpdate);
      }
    }
  }, [data, users]);

  const selectedOption = optionsScheduleInterval.find(
    (item) => item.value === scheduleInterval
  );

  const handleDelete = () => {
    setOpenDelete(true);
  };

  const handleConfirmDelete = () => {
    if (handleRecurrenceUnlink) {
      handleRecurrenceUnlink();
    }
    handleClose();
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classes.wrapperDialogClose}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Recurrence
          </Typography>
          {subTitle ? (
            <Typography
              variant="caption"
              gutterBottom
              className={classes.subTitleDialog}
            >
              {subTitle}
            </Typography>
          ) : null}

          <Box>
            <List className={classes.listRoot} disablePadding={true}>
              <ListItem>
                <ListItemText
                  primary="Project Number"
                  secondary={serviceData?.project_number}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Service Name"
                  secondary={serviceData?.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Schedule Interval"
                  secondary={selectedOption && selectedOption.label}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Every"
                  secondary={`${every} ${
                    selectedOption && selectedOption.label
                  }${every > 1 ? "s" : ""}`}
                />
              </ListItem>
              {scheduleInterval === 2 && (
                <ListItem>
                  <ListItemText
                    primary="On Week Days"
                    secondary={days.map(
                      (day) =>
                        `${
                          optionsDays.find((opt) => opt.value === Number(day))
                            ?.label
                        }, `
                    )}
                  />
                </ListItem>
              )}
              {scheduleInterval === 3 ? (
                <ListItem>
                  <ListItemText
                    primary="On Month Days"
                    secondary={daysMonth.map((day) => `${day.label}, `)}
                  />
                </ListItem>
              ) : null}
              <ListItem>
                <ListItemText
                  primary="Start Date -  End Date"
                  secondary={`${moment(scheduleData.startDate).format(
                    "MM/DD/YYYY"
                  )} -  ${
                    noEndDate
                      ? `Not Defined`
                      : `${moment(scheduleData.stopDate).format("MM/DD/YYYY")}`
                  }`}
                />
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            className={classes.buttonUnlink}
            color="secondary"
            variant="contained"
            size="large"
            onClick={handleDelete}
          >
            Unlink workticket
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialogConfirmation}>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDelete(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <QuestionIcon className={classes.iconQuestionDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Unlink Workticket
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to unlink workticket from service recurrence,
            this action cannot be undo?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDelete}
            className={classes.button}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="secondary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecurrencePortfolioGenerator;
