import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import SurveysQuestionsTableActions from "./surveysQuestionsTableActions";
import SurveysQuestionsListFilters from "./surveysQuestionsListFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { questionQATypes, questionQACategory } from "constants.js";
import { ButtonBase } from "components/ui/core/button";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableDispatch } from "contexts/tableContext";
import {
  useSurveysQuestionsState,
  useSurveysQuestionsDispatch,
  getSurveysQuestionsList,
  searchSurveysQuestionsList,
  getSurveysQuestionsListDataSet,
} from "contexts/surveysQuestionsTableContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { useHistory } from "react-router-dom";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "Question Number",
    style: { width: 200, paddingLeft: 8 },
    content: (row) => `QUES-${row.id}`,
  },
  {
    id: "type",
    label: "Question Type",
    content: (row) =>
      questionQATypes.find((item) => item.value === row.type).label,
    style: { width: 200 },
  },
  {
    id: "name",
    label: "Question Name",
    style: { width: 800 },
  },
  {
    id: "updated_at",
    label: "Last Updated",
    format: "date",
    style: { width: 200 },
  },
  {
    id: "category",
    label: "Category",
    content: (row) =>
      questionQACategory.find((item) => item.value === row.category).label,
    style: { width: 200 },
  },
  {
    id: "actions",
    content: (row) => <SurveysQuestionsTableActions row={row} />,
    contentMobile: (row) => (
      <SurveysQuestionsTableActions row={row} mobile={true} />
    ),
  },
];

const SurveysQuestionsListTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    surveysQuestionsLoading,
    surveysQuestionList,
    surveysQuestionsCount,
  } = useSurveysQuestionsState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchSurveysQuestons = useSurveysQuestionsDispatch();
  const dispatchFilters = useFilterDispatch();
  const history = useHistory();

  const { refreshData } = globalUi;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getSurveysQuestionsList(dispatchSurveysQuestons, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchSurveysQuestons, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getSurveysQuestionsList(dispatchSurveysQuestons, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: surveysQuestionList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, surveysQuestionList]);

  const handleScrollClick = useCallback(async () => {
    if (surveysQuestionsCount <= surveysQuestionList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getSurveysQuestionsListDataSet(
        surveysQuestionList,
        dispatchSurveysQuestons,
        surveysQuestionList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: surveysQuestionList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    surveysQuestionList,
    surveysQuestionsCount,
    filterState,
    dispatchSurveysQuestons,
    dispatchTable,
    loadingMore,
  ]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchSurveysQuestionsList(query, filterData);

          dispatchSurveysQuestons({
            type: "SET_QUESTIONS_LIST",
            surveysQuestionList: results,
          });

          dispatchSurveysQuestons({
            type: "SET_QUESTIONS_COUNT",
            surveysQuestionsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  const openManageQuestion = () => {
    dispatchSurveysQuestons({
      type: "SET_QUESTIONS_SELECTED",
      surveysQuestionSelected: null,
    });
    dispatchSurveysQuestons({
      type: "TOGGLE_MANAGE",
      openManageQuestion: true,
    });
  };

  return (
    <Box className={classes.root}>
      {surveysQuestionsLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs
              handleTabChange={(e, value) => {
                history.push(`/surveys/admin/${value}`);
              }}
              value={"questions"}
            >
              {["surveys", "questions", "tasks", "triggers"].map((tab) => (
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>{tab}</Box>}
                  key={tab}
                  value={tab}
                />
              ))}
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${surveysQuestionList.length} / ${surveysQuestionsCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={() => {}}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        placeholderSearch={"All Questions"}
                        handleSearchClear={handleSearchClear}
                      >
                        <Box className={classes.listActionContainer}>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            onClick={openManageQuestion}
                          >
                            New Question
                          </ButtonBase>
                        </Box>
                        <SurveysQuestionsListFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filterState.filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(SurveysQuestionsListTable);
