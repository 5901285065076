import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiSurfaceUrl}/requests`;

export function getRequestData() {
  return http.get(`${apiSurfaceUrl}/request`);
}

export function getRequests(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`);
}

export function getRequestsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getRequestsTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function getRequestDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {
    params: {},
  });
}

export function updateBulkRequest(data) {
  return http.post(`${apiEndpoint}/update`, data);
}

export function uploadFileRequest(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function saveRequest(data, customer_id) {
  http.setMultiPart();
  data.customer_id = customer_id;
  return http.post(`${apiSurfaceUrl}/request`, setFormData(data));
}
