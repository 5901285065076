import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import ServicesFilters from "./servicesFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import ServicesListActions from "./servicesListActions";
import ServicesManageDrawer from "./servicesManageDrawer";
import ServiceStatusChip from "./servicesStatusChip";
import ServicesTableActions from "./servicesTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import WorkticketAssigned from "components/ui/Worktickets/WorkticketAssigned";
import { intervalDisplay } from "./serviceOptions";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useProjectView } from "contexts/projectViewContext";
import {
  useServiceState,
  useServiceDispatch,
  getServiceList,
  searchServices,
  getServiceListDataSet,
} from "contexts/projectServiceContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const ServicesTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { serviceListLoading, serviceList, serviceCount, serviceManageOpen } =
    useServiceState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchServices = useServiceDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ?? null;
  const { refreshData } = globalUi;

  const columns = useMemo(
    () => [
      {
        id: "id",
        label: "ID",
        path: "",
        checkbox: {
          active: (row) => false,
        },
      },
      {
        id: "name",
        label: "Service",
      },
      {
        id: "jobs",
        label: "Job",
        content: (row) =>
          row?.jobs && row.jobs.length
            ? `${row.jobs[0].job_number} - ${row.jobs[0].job_description} ${
                row.jobs.length > 1 ? `...+${row.jobs.length - 1}` : ""
              }`
            : null,
        disableSort: true,
      },
      {
        id: "bill_per_instance",
        label: "Price",
        format: "money",
      },
      {
        id: "payment_per_instance",
        label: "Cost",
        format: "money",
      },
      {
        id: "users",
        label: "Assigned To",
        content: (row) => <WorkticketAssigned users={row.users} />,
        disableSort: true,
      },
      {
        id: "recurrence",
        label: "Frequency",
        content: (row) =>
          row.recurrence?.interval_type
            ? intervalDisplay[row.recurrence.interval_type]
            : project?.is_external && project.type_external === 1
            ? "Auto"
            : "One-time",
      },
      {
        id: "status",
        label: "Status",
        content: (row) => <ServiceStatusChip status={row.status} />,
        disableSort: true,
      },
      {
        id: "actions",
        content: (row) => <ServicesTableActions row={row} />,
        contentMobile: (row) => (
          <ServicesTableActions row={row} mobile={true} />
        ),
        style: { width: 55 },
        disableSort: true,
      },
    ],
    [project]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterData = getFilterData(filterState);
        await getServiceList(project.id, dispatchServices, filterData);
      } catch (e) {
        console.log(e);
      }
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [project, dispatchServices, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getServiceList(project.id, dispatchServices, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: serviceList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, serviceList, columns]);

  const handleScrollClick = useCallback(async () => {
    if (serviceCount <= serviceList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getServiceListDataSet(
        project.id,
        serviceList,
        dispatchServices,
        serviceList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: serviceList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    project.id,
    serviceList,
    serviceCount,
    filterState,
    dispatchServices,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchServices(project.id, query, filterData);
          // Send result to search tab
          dispatchServices({
            type: "SET_SERVICE_LIST",
            serviceList: results,
          });

          dispatchServices({
            type: "SET_SERVICE_COUNT",
            serviceCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {serviceListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${serviceList.length} / ${serviceCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <ServicesListActions />
                          <CalendarFilters />
                          <ServicesFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
      {serviceManageOpen ? <ServicesManageDrawer /> : null}
    </Box>
  );
};

export default withRouter(ServicesTable);
