import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import Button from "@material-ui/core/Button";
import { useUserDrawerContext } from "../context/UserDrawerContext";
import { AddLocationDialog } from "./AddLocationDialog";
import { UserRowItem } from "./UserRowItem";
import DeleteUserDialogue from "./DeleteUserDialogue";
import { usePartnerUsers } from "./useUsers";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionPartner } from "lib/permissions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "role_category",
    numeric: false,
    disablePadding: true,
    label: "Role Category",
  },
  {
    id: "role_name",
    numeric: false,
    disablePadding: true,
    label: "Role Name",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, permissionManage } = props;
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#F8F8F8",
      color: "#747474",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  return (
    <TableHead>
      <TableRow>
        {permissionManage ? (
          <StyledTableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "Select all users" }}
            />
          </StyledTableCell>
        ) : null}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "none",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#747474",
          backgroundColor: "#F8F8F8",
          display: "flex",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
          display: "flex",
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const customStyles = useStyles();
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { handleLocationDialogueOpen } = useUserDrawerContext();
  const { handleDeleteUserDialogueOpen } = usePartnerUsers();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Box className={customStyles.deleteAssignButtonContainer}>
          {numSelected <= 1 && numSelected > 0 && (
            <Button
              onClick={() => {
                handleDeleteUserDialogueOpen();
              }}
              className={customStyles.deleteUserButton}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={handleLocationDialogueOpen}
            className={customStyles.assignLocationButton}
          >
            Assign
          </Button>
        </Box>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesTable = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 800,
    "@media (max-width: 768px)": {
      minWidth: 0,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStylesTable();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [objectsUserSelected, setObjectsUserSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rows = props.rows;
  const { toggleDrawer, handleToggleDrawer } = useUserDrawerContext();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const { selectUser, handleLocationDialogueOpen } = useUserDrawerContext();
  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    const selectedObjectIndex = objectsUserSelected.indexOf(row);
    let newSelected = [];
    let newObjectsSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      newObjectsSelected = newObjectsSelected.concat(objectsUserSelected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newObjectsSelected = newObjectsSelected.concat(
        objectsUserSelected.slice(1)
      );
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newObjectsSelected = newObjectsSelected.concat(
        objectsUserSelected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newObjectsSelected = newObjectsSelected.concat(
        objectsUserSelected.slice(0, selectedObjectIndex),
        objectsUserSelected.slice(selectedObjectIndex + 1)
      );
    }
    setSelected(newSelected);
    // selectUser(newSelected);
    setObjectsUserSelected(newObjectsSelected);
    console.log(newObjectsSelected);
    selectUser(newObjectsSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3f51b5",
      color: "red",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const {
    handleDeleteUser,
    isDeleteUserDialogOpen,
    handleDeleteUserDialogueClose,
  } = usePartnerUsers();

  const permissionManage =
    hasPermission(permissionPartner.PARTNER_MANAGE, permissions) ||
    hasPermission(permissionPartner.PARTNER_OWNER, permissions);

  return (
    <>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                permissionManage={permissionManage}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <UserRowItem
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        selectedUsers={objectsUserSelected}
                        StyledTableCell={StyledTableCell}
                        toggleDrawer={toggleDrawer}
                        handleLocationDialogueOpen={handleLocationDialogueOpen}
                        handleToggleDrawer={handleToggleDrawer}
                        permissionManage={permissionManage}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 50 * emptyRows,
                    }}
                  ></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <AddLocationDialog />
      </Box>
      <DeleteUserDialogue
        userToDelete={objectsUserSelected[0]?.id}
        handleDeleteUser={handleDeleteUser}
        handleDeleteUserDialogueClose={handleDeleteUserDialogueClose}
        isDeleteUserDialogOpen={isDeleteUserDialogOpen}
      />
    </>
  );
}
