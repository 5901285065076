import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: "1px dashed #b4b4b4",
    borderRadius: 5,
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 60,
    cursor: "pointer",
  },
  dropZoneFiles: {
    border: "1px dashed #b4b4b4",
    borderRadius: 5,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: "pointer",
  },
  titleFileUpload: {
    fontSize: 20,
    color: "#c4c4c4",
    marginBottom: 0,
  },
  titleFile: {
    fontSize: 16,
    color: "#747474",
    marginBottom: 5,
    marginTop: 5,
    fontWeight: "bold",
  },
  uploadLink: {
    position: "absolute",
    right: 25,
    top: 10,
    color: "#359be0",
    textDecoration: "underline",
    fontSize: 12,
    cursor: "pointer",
  },
  fileDisplayWrapper: {
    border: "1px dashed #b4b4b4",
    borderRadius: 5,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
    position: "relative",
  },
  fileDisplayContainer: {
    textAlign: "left",
    padding: 10,
    border: "1px dashed #b4b4b4",
    minHeight: 200,
    borderRadius: 5,
    cursor: "pointer",
    overflow: "hidden",
  },
  fileThumbsDisplay: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "& > div": {
      width: 140,
      padding: 10,
      textAlign: "center",
      marginBottom: 4,
    },
    "& img": {
      maxWidth: "100%",
    },
    "& svg": {
      fontSize: 60,
    },
  },
  cardThumb: {
    height: 85,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f8f8f8",
    padding: 4,
  },
  cardLabel: {
    marginTop: 8,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 12,
  },
  cardInProgress: {
    padding: 0,
    margin: 0,
  },
}));

export default useStyles;
