import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SurveyIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { logException } from "components/util/logUtil";
import {
  useLocationsState,
  useLocationsDispatch,
} from "contexts/surveyLocationsSurfaceContext";
import { getFrontlineLocationsSurvey } from "services/surveyFrontlineSurfaceService";
import { useSurveyFillDispatch, surveyStart } from "contexts/surveyFillContext";

import useStyles from "./styles";

const LocationsSurveysSurfaceDialog = (props) => {
  const classes = useStyles();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState(null);
  const { openLocationSurveys, locationsSelected } = useLocationsState();
  const dispatchLocations = useLocationsDispatch();

  const dispatchSurveyFill = useSurveyFillDispatch();

  useEffect(() => {
    const loadSurveys = async () => {
      try {
        setIsLoadingDialog(true);
        const result = await getFrontlineLocationsSurvey(locationsSelected.id);
        const resultForRender = result.data.data.surveys.reduce(
          (array, item) => {
            if (parseInt(item.has_randomize) === 1) {
              array = [
                ...array,
                {
                  value: item.id,
                  label: item.name,
                  has_randomize: 0,
                  random_number: null,
                },
                {
                  value: item.id,
                  label: `${item.name} (Express)`,
                  has_randomize: 1,
                  random_number: item.random_number,
                },
              ];
            } else {
              array = [
                ...array,
                {
                  value: item.id,
                  label: item.name,
                  has_randomize: 0,
                  random_number: null,
                },
              ];
            }
            return array;
          },
          []
        );

        setSurveys(resultForRender);
        setSurvey(null);
        setIsLoadingDialog(false);
      } catch (e) {
        logException(e, "Cannot load surveys data");
      }
    };
    if (locationsSelected?.id) {
      loadSurveys();
    }
  }, [locationsSelected]);

  const handleClose = () => {
    dispatchLocations({
      type: "TOGGLE_OPEN_SURVEYS",
      openLocationSurveys: false,
    });
  };

  const handleChangeSurveys = async (event, value) => {
    if (value) {
      setSurvey(value);
    } else {
      setSurvey(null);
    }
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      dispatchSurveyFill({
        type: "SET_CALLED_FROM",
        calledFrom: "locations-surveys",
      });
      await surveyStart(
        dispatchSurveyFill,
        locationsSelected.id,
        survey.value,
        survey.has_randomize,
        survey.random_number
      );
      setIsLoadingDialog(false);
      handleClose();
    } catch (e) {
      console.log(`Cannot start survey: ${e}`);
    }
  };

  return (
    <>
      <Dialog
        open={openLocationSurveys}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainer}>
                <SurveyIcon className={classes.headerIcon} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Select Survey
                </Typography>
              </Box>
              <Box className={classes.formContainer}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={surveys ?? []}
                  name="surveys"
                  handleChange={handleChangeSurveys}
                  label="Survey"
                  value={survey}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction2}>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
                disabled={!Boolean(survey)}
              >
                Start
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default LocationsSurveysSurfaceDialog;
