import React, { useContext, useEffect } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import { getWorkticketPdf } from "services/workticketService";
import { getWorkticketPdf as getWorkticketSurfacePdf } from "services/workticketSurfaceService";
import { permissionSurface, hasPermission } from "lib/permissions";

const WorkticketPdf = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const workticketId = props.match.params.id;
  useEffect(() => {
    const loadPdf = async () => {
      const surfaceUser = hasPermission(
        permissionSurface.WORKTICKET,
        permissions
      );
      const showTimekeeping = true;
      if (surfaceUser) {
        const result = await getWorkticketSurfacePdf(
          workticketId,
          showTimekeeping
        );
        const file = new Blob([result.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        document.querySelector("iframe").src = fileURL;
        return;
      }
      const result = await getWorkticketPdf(workticketId, showTimekeeping);
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      document.querySelector("iframe").src = fileURL;
    };

    loadPdf();
  }, [workticketId, permissions]);

  return (
    <iframe
      src=""
      type="application/pdf"
      width="93%"
      height="100%"
      title="Pdf"
      style={{ border: "none" }}
    ></iframe>
  );
};

export default WorkticketPdf;
