import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import * as classNames from "classnames";
// import { useFormsDispatch } from "contexts/formsContext";
import React from "react";
// import { downloadFile } from "../../services/formsService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const FormsBulkActions = (props) => {
  const classes = useStyles();
  // const dispatchForms = useFormsDispatch();

  // const downloadFileFromUrl = (url) => {
  //   window.open(url);
  // };

  // const handleOpenForms = () => {
  //   dispatchForms({
  //     type: "TOGGLE_",
  //     openForms: true,
  //   });
  // };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classNames(classes.button, classes.buttonSecondary)}
        onClick={() => {
          props.setStatusModal(true);
        }}
      >
        Delete
      </Button>
      {/* <Button
        variant="contained"
        color="secondary"
        size="large"
        className={classNames(classes.button, classes.buttonSecondary)}
        onClick={() => {
          downloadFile(props?.selected).then((res) => {
            downloadFileFromUrl(res?.data?.data?.file?.presigned_url);
          });
        }}
      >
        Download
      </Button> */}
    </div>
  );
};

export default FormsBulkActions;
