import http from "./httpService";
import { apiUrl } from "../lib/config";

const apiEndpoint = `${apiUrl}/services/trades`;

export function getTrades() {
  return http.get(`${apiEndpoint}/`);
}

export function updateTrade(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function createTrade(data) {
  return http.post(`${apiEndpoint}`, data);
}

export function deleteTrade(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
