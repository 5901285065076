import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import * as classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import TravelIcon from "@material-ui/icons/DirectionsCar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TicketIcon from "@material-ui/icons/Receipt";
import TimekeepingIconSynch from "components/ui/Worktickets/timekeepingIconSynch";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import {
  updateTimekeepingManual,
  deleteTimekeepingManual,
  getTimekeepingHistory,
  getWorkticketPdf,
} from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import {
  formatDateTZ,
  formatAppDateTZ,
  getDateTZ,
} from "components/util/timeFormat";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const customersTKOvertime = ["31300"];

const WorkticketTimekeepingHistory = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions, users } = globalUi;
  const [loadingList, setLoadingList] = useState(true);
  const [timekeepingList, setTimekeepingList] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [stateContext] = useWorkticketView();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [row, setRow] = useState({});
  const [date, setDate] = useState(moment());
  const [dateOut, setDateOut] = useState(moment());
  const [timeIn, setTimeIn] = useState(moment());
  const [timeOut, setTimeOut] = useState(moment());
  const [user, setUser] = useState(undefined);
  const [drive, setDrive] = useState(false);
  const [overtime, setOvertime] = useState(false);
  const [onPayroll, setOnPayroll] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [errorSubmit, setErrorSubmit] = useState("");
  const { restrictModify } = props;

  const { workticket, isLoading } = stateContext ?? null;

  const handleOpenPdf = async () => {
    setLoadingPdf(true);
    const result = await getWorkticketPdf(workticket.id, true);
    const file = new Blob([result.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    setLoadingPdf(false);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingList(true);
        const result = await getTimekeepingHistory(workticket.id);
        setTimekeepingList(result.data.data.entries);
        setLoadingList(false);
      } catch (e) {
        logException(e, "Cannot load timekeeping list");
      }
    };
    if (!isLoading && props.open) {
      loadData();
    }
  }, [isLoading, workticket.id, props.open]);

  useEffect(() => {
    if (timekeepingList.length) {
      const total = timekeepingList.reduce(
        (total, option) => {
          if (option.clock_in) {
            total = parseFloat(total) + parseFloat(option.clock_hours);
          } else {
            total = parseFloat(total) + parseFloat(option.drive_hours);
          }
          return total;
        },
        [0]
      );
      setTotalHours(total);
    } else {
      setTotalHours(0);
    }
  }, [timekeepingList]);

  useEffect(() => {
    if (errorSubmit) {
      setTimeout(() => {
        setErrorSubmit("");
      }, 5000);
    }
  }, [errorSubmit]);

  const handleUpdate = (row) => {
    setOpenUpdate(true);
    setDeleteRow(false);
    setRow(row);
    const findUser = users.find((user) => user.id === row.user.id);
    if (findUser) {
      setUser(findUser);
    } else {
      setUser(undefined);
    }
    if (row.hr_entry) {
      setOnPayroll(true);
    } else {
      setOnPayroll(false);
    }

    const tz = workticket?.job?.timezone;
    if (row.clock_in) {
      setDate(getDateTZ(row.clock_in, tz));
      setTimeIn(getDateTZ(row.clock_in, tz));
      setDateOut(getDateTZ(row.clock_out, tz));
      setTimeOut(getDateTZ(row.clock_out, tz));
      setDrive(false);
    } else {
      setDate(getDateTZ(row.drive_in, tz));
      setTimeIn(getDateTZ(row.drive_in, tz));
      setDateOut(getDateTZ(row.drive_out, tz));
      setTimeOut(getDateTZ(row.drive_out, tz));
      setDrive(true);
    }
    setOvertime(row?.overtime && Number(row.overtime) ? true : false);
  };

  const handleUpdateTimekeeping = async () => {
    try {
      const dateFormat = "YYYY-MM-DD HH:mm:ss";
      const dateFormatOnly = "YYYY-MM-DD";
      const timeFormat = "HH:mm:00";

      let data = {};
      const tz = workticket?.job?.timezone;

      const inDate = `${formatAppDateTZ(
        date.format(dateFormatOnly),
        dateFormatOnly,
        tz
      )} ${formatAppDateTZ(timeIn.format(dateFormat), timeFormat, tz)}`;

      const outDate = `${formatAppDateTZ(
        dateOut.format(dateFormatOnly),
        dateFormatOnly,
        tz
      )} ${formatAppDateTZ(timeOut.format(dateFormat), timeFormat, tz)}`;

      if (inDate.includes("Invalid date") || outDate.includes("Invalid date")) {
        setErrorSubmit("Invalid date or time");
        return;
      }

      if (!drive) {
        data = {
          clock_in: inDate,
          clock_out: outDate,
          overtime: overtime ? 1 : 0,
          is_closed_out: true,
        };
      } else {
        data = {
          drive_in: inDate,
          drive_out: outDate,
          overtime: overtime ? 1 : 0,
          is_closed_out: true,
        };
      }
      await updateTimekeepingManual(workticket.id, row.id, data);
      setOpenUpdate(false);
      setLoadingList(true);
      const resultUpdate = await getTimekeepingHistory(workticket.id);
      setTimekeepingList(resultUpdate.data.data.entries);
      setLoadingList(false);
    } catch (e) {
      logException(e, "Cannot update timekeeping");
    }
  };

  const handleDeleteTimekeeping = async () => {
    try {
      await deleteTimekeepingManual(workticket.id, row.id);
      setOpenUpdate(false);
      setLoadingList(true);
      const resultUpdate = await getTimekeepingHistory(workticket.id);
      setTimekeepingList(resultUpdate.data.data.entries);
      setLoadingList(false);
    } catch (e) {
      logException(e, "Cannot delete timekeeping");
    }
  };

  const handleDelete = (row) => {
    setOpenUpdate(true);
    setDeleteRow(true);
    setRow(row);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    if (value) {
      setUser(value);
    } else {
      setUser(undefined);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Grid
            container
            spacing={2}
            className={classes.dialogHeaderTimekeeping}
          >
            <Grid item sm={9}>
              <Typography
                variant="h3"
                className={classes.titleBody}
                gutterBottom
              >
                Timekeeping History
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={true}
                startIcon={<PdfIcon />}
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleOpenPdf}
                disabled={loadingPdf}
              >
                Print PDF
              </Button>
            </Grid>
          </Grid>
          {!isLoading && (
            <>
              {loadingList ? (
                <Box className={classes.loading}>
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoadingHistDialog}
                  />
                </Box>
              ) : (
                <>
                  <Typography
                    variant="h4"
                    className={classes.subtitleBody}
                    gutterBottom
                  >
                    {workticket.number}
                  </Typography>
                  <Typography
                    variant="h4"
                    className={classes.subtitleBody}
                    gutterBottom
                  >
                    {workticket.job.job_description}
                  </Typography>
                  <Box className={classes.containerDialog}>
                    <TableContainer component={Box}>
                      {Boolean(timekeepingList.length) ? (
                        <Table
                          className={classes.tableDialog}
                          aria-label="timekeeping table"
                          size="small"
                        >
                          <TableHead className={classes.tableHeadDialog}>
                            <TableRow>
                              <TableCell>Employee Number</TableCell>
                              <TableCell>Employee Name</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>In Time</TableCell>
                              <TableCell>Out Time</TableCell>
                              <TableCell>Hours</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.tableBodyDialog}>
                            {timekeepingList.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>
                                  {row.user.employee_number}
                                </TableCell>
                                <TableCell>{`${row.user.first_name} ${row.user.last_name}`}</TableCell>
                                <TableCell>
                                  {formatDateTZ(
                                    row.clock_in,
                                    "L",
                                    workticket?.job?.timezone
                                  )}
                                </TableCell>
                                {row.clock_in ? (
                                  <>
                                    <TableCell>
                                      <nobr>
                                        {formatDateTZ(
                                          row.clock_in,
                                          "LT",
                                          workticket?.job?.timezone
                                        )}
                                      </nobr>
                                    </TableCell>
                                    <TableCell>
                                      <nobr>
                                        {row.clock_out
                                          ? formatDateTZ(
                                              row.clock_out,
                                              "LT",
                                              workticket?.job?.timezone
                                            )
                                          : "Running"}
                                      </nobr>
                                    </TableCell>
                                    <TableCell>{row.clock_hours}</TableCell>
                                    <TableCell>
                                      {row?.overtime && Number(row.overtime)
                                        ? "OT"
                                        : ""}
                                    </TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell>
                                      <nobr>
                                        {formatDateTZ(
                                          row.drive_in,
                                          "LT",
                                          workticket?.job?.timezone
                                        )}
                                      </nobr>
                                    </TableCell>
                                    <TableCell>
                                      <nobr>
                                        {row.drive_out
                                          ? formatDateTZ(
                                              row.drive_out,
                                              "LT",
                                              workticket?.job?.timezone
                                            )
                                          : "Running"}
                                      </nobr>
                                    </TableCell>
                                    <TableCell>{row.drive_hours}</TableCell>
                                    <TableCell>
                                      <TravelIcon />
                                      {row?.overtime && Number(row.overtime)
                                        ? " OT"
                                        : ""}
                                    </TableCell>
                                  </>
                                )}
                                <TableCell style={{ width: 100 }}>
                                  {!row.workticket_external_id &&
                                  (!row.is_exported ||
                                    (row.is_exported &&
                                      row.hr_entry &&
                                      row.hr_entry.period_status !== "LOCK")) &&
                                  hasPermission(
                                    permissionWorkticket.MANAGE_TIMEKEEPING,
                                    permissions
                                  ) &&
                                  !restrictModify ? (
                                    <IconButton
                                      aria-label="close"
                                      className={classes.wrapperIcon}
                                      onClick={() => handleUpdate(row)}
                                    >
                                      <EditIcon
                                        className={classes.iconOptions}
                                      />
                                    </IconButton>
                                  ) : null}
                                  {!row.is_exported &&
                                  !row.workticket_external_id &&
                                  hasPermission(
                                    permissionWorkticket.DELETE_TIMEKEEPING,
                                    permissions
                                  ) &&
                                  !restrictModify ? (
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => handleDelete(row)}
                                      className={classes.wrapperIcon}
                                    >
                                      <DeleteIcon
                                        className={classes.iconOptions}
                                      />
                                    </IconButton>
                                  ) : null}
                                </TableCell>
                                <TableCell>
                                  <TimekeepingIconSynch row={row} />
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell
                                variant="footer"
                                align="right"
                                colSpan="5"
                              >
                                Total Hours:
                              </TableCell>
                              <TableCell variant="footer">
                                {totalHours.toFixed(2)}
                              </TableCell>
                              <TableCell variant="footer"></TableCell>
                              <TableCell
                                variant="footer"
                                colSpan="3"
                              ></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography
                          variant="h4"
                          className={classes.loading}
                          gutterBottom
                        >
                          No timekeeping records found.
                        </Typography>
                      )}
                    </TableContainer>
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}></DialogActions>
      </Dialog>
      <>
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={openUpdate}
          onClose={handleCloseUpdate}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseUpdate}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            {!isLoading && (
              <>
                <Grid container spacing={2} className={classes.dialogHeader}>
                  <Grid item sm={12}>
                    <Box className={classes.formIconDialog}>
                      <TicketIcon className={classes.iconDialog} />
                    </Box>
                    <Typography
                      variant="h4"
                      className={classes.formTitleDialog}
                      gutterBottom
                    >
                      Workticket {workticket.number}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.formSubtitleDialog}
                      gutterBottom
                    >
                      {deleteRow
                        ? "Are you sure you want to delete the timekeeping?"
                        : "Update timekeeping"}
                    </Typography>
                    {errorSubmit ? (
                      <Alert
                        severity="error"
                        className={classes.alertContainer}
                      >
                        {errorSubmit}
                      </Alert>
                    ) : null}
                    {onPayroll ? (
                      <Alert severity="info" className={classes.alertContainer}>
                        Entry Synch - Any update will reflect on ADP(payroll
                        system).
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
                {!deleteRow && (
                  <Box className={classes.formContainerDialog}>
                    <FormInputDateAdo
                      name="date"
                      label="In Date"
                      value={date}
                      handleChange={setDate}
                    />
                    <FormInputTimeAdo
                      name="time_in"
                      label="In Time"
                      value={timeIn}
                      handleChange={setTimeIn}
                    />
                    <FormInputDateAdo
                      name="date_out"
                      label="Out Date"
                      value={dateOut}
                      handleChange={setDateOut}
                    />
                    <FormInputTimeAdo
                      name="time_out"
                      label="Out Time"
                      value={timeOut}
                      handleChange={setTimeOut}
                    />
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users}
                      name="users"
                      label="Assigned To"
                      handleChange={handleChangeAssignedTo}
                      value={user}
                      readonly={true}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={drive}
                          value={true}
                          color="secondary"
                        />
                      }
                      label="Travel Time"
                      className={classes.containerOptions}
                    />
                    {customersTKOvertime.includes(
                      workticket?.job?.customer?.customer_number
                    ) ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={overtime}
                            onChange={(event) =>
                              setOvertime(event.target.checked)
                            }
                            value={true}
                            color="secondary"
                          />
                        }
                        label="Overtime"
                        className={classes.containerOptions}
                      />
                    ) : null}
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapper}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonOutlined)}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
            {!deleteRow ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleUpdateTimekeeping}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleDeleteTimekeeping}
              >
                Okay
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default WorkticketTimekeepingHistory;
