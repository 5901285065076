import Bugsnag from "@bugsnag/js";
function init() {}

function log(error) {
  if (process.env.REACT_APP_ENV === "production") {
    Bugsnag.notify(error);
  }

  console.error(error);
}

const logService = {
  init,
  log,
};

export default logService;
