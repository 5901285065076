import React from "react";
import JobLayout from "./jobLayout";
import { JobViewProvider } from "contexts/jobViewContext";

const Job = (props) => {
  return (
    <JobViewProvider>
      <JobLayout id={props.match.params.id} />
    </JobViewProvider>
  );
};

export default Job;
