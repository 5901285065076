import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import FormSubTitle from "components/ui/FormContent/formSubTitle";
import RequestOption from "./requestOption";
import { getRequestData } from "services/requestSurfaceService";
import { useStateValue } from "components/util/state";

import useStyles from "./styles";

const RequestStep1 = (props) => {
  const classes = useStyles();
  const [requestType, setRequestType] = useState(0);
  const [{ step, completed, company }, dispatch] = useStateValue();

  useEffect(() => {
    const loadCompany = async () => {
      try {
        const resultData = await getRequestData();
        dispatch({
          type: "SET_COMPANY",
          company: { ...resultData.data.data },
        });
      } catch (e) {
        console.log("Data cannot load.");
      }
    };
    if (!company) {
      loadCompany();
    }
  }, [company, dispatch]);

  useEffect(() => {
    if (completed) {
      setRequestType(0);
    }
  }, [completed]);

  const handleOnClick = () => {
    dispatch({
      type: "UPDATE_DATA",
      updateData: { type: requestType },
    });
    dispatch({
      type: "CHANGE_STEP",
      newStep: 1,
    });
  };

  if (step !== 0) return null;
  return (
    <>
      <div className={classes.headerContainer}>
        <Typography
          variant="h3"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Create New Request
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Tell us more about the request you're submitting
        </Typography>
      </div>
      <Container className={classes.container}>
        <div className={classes.stepContainer}>
          <FormSubTitle>
            <span style={{ marginLeft: 13 }}>
              What kind of request are you submitting?
            </span>
          </FormSubTitle>
          <RequestOption type={requestType} handleClick={setRequestType} />
          <Box className={classes.actionBox}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonBegin}
              onClick={handleOnClick}
              disabled={requestType ? false : true}
              size="large"
            >
              Begin
            </Button>
          </Box>
        </div>
      </Container>
    </>
  );
};

export default RequestStep1;
