import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "../container";
import useStyles from "./styles";

import theme from "theme";

const MainLayoutPublic = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <main className={classes.content}>{children}</main>
      </Container>
    </ThemeProvider>
  );
};

export default MainLayoutPublic;
