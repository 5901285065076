import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillDispatch,
  surveyResume,
  useSurveyFillState,
} from "contexts/surveyFillContext";
import {
  useEvaluationsState,
  useEvaluationsDispatch,
  getEvaluationsList,
} from "contexts/evaluationsContext";
import { logException } from "components/util/logUtil";
import { useFilterState, getFilterData } from "contexts/filterContext";

const ViewDialog = (props) => {
  const { openView, readonlyView, evaluationsSelected } = useEvaluationsState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const dispatchEvaluations = useEvaluationsDispatch();
  const { openShowSurvey } = useSurveyFillState();
  const filterState = useFilterState();

  useEffect(() => {
    const loadSurvey = async () => {
      try {
        await surveyResume(
          dispatchSurveyFill,
          evaluationsSelected.id,
          readonlyView
        );
        dispatchEvaluations({
          type: "TOGGLE_OPEN_VIEW",
          openView: false,
        });
      } catch (e) {
        logException(e, "Cannot view survey");
      }
    };
    if (openView) {
      loadSurvey();
    }
  }, [openView]);

  useEffect(() => {
    const loadDrafts = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "surveyor",
          group: "Surveyor",
          values: [{ label: "", value: user.id }],
        },
        {
          filter: "status",
          group: "Status",
          values: [{ label: "", value: 1 }],
        },
        {
          filter: "skipJobConditions",
          group: "skipJobConditions",
          values: [{ label: "", value: 1 }],
        },
        {
          filter: "date_column",
          group: "date_column",
          values: [{ label: "", value: "ss1.created_at" }],
        },
      ];
      await getEvaluationsList(dispatchEvaluations, filterData);
    };
    if (!openShowSurvey && !readonlyView) {
      loadDrafts();
    }
  }, [openShowSurvey, readonlyView]);

  return (
    <Dialog
      open={openView}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <LoadingIndicator />
    </Dialog>
  );
};
export default ViewDialog;
