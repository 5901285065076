import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "components/ui/core/button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import { DialogMessage } from "components/ui/core/dialogMessage";
import AlertIcon from "@material-ui/icons/NewReleases";
import { financialPaymentExport } from "services/financialService";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
} from "contexts/billingApContext";
import { useTableDispatch } from "contexts/tableContext";
import { useFilterState, getFilterData } from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { logException } from "components/util/logUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: 20,
    "& > button": {
      marginLeft: 20,
      marginRight: 20,
    },
  },
}));

const AccountPayablesListActions = (props) => {
  const classes = useStyles();
  const { tab } = props;
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const { billingCount } = useBillingState();
  const dispatchBilling = useBillingDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setOpenConfirm(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleExportConfirm = async () => {
    try {
      setIsLoading(true);
      const filterData = getFilterData(filterState);
      const result = await financialPaymentExport(
        { export_all: 1 },
        filterData
      );
      window.open(result.data.data.file.presigned_url);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });

      await getBillingList(dispatchBilling, filterData);
      setOpenConfirm(false);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot export all file");
    }
  };

  return (
    <div className={classes.root}>
      {tab === "1" ? (
        <ButtonBase
          variant="outlined"
          color="secondary"
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? `Loading...` : `Export All`}
        </ButtonBase>
      ) : null}
      {openConfirm ? (
        <Dialog
          open={openConfirm}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogContent className={classes.wrapperDialog}>
            {!isLoading ? (
              <DialogCloseIcon handleClose={handleConfirmClose} />
            ) : null}
            <DialogTitle
              title="Financial"
              icon={<AlertIcon fontSize="large" />}
            />
            {!isLoading ? (
              <>
                <DialogMessage align="center">
                  You are about to export ALL {billingCount["1"] ?? null}{" "}
                  records ready.
                </DialogMessage>
                <DialogMessage align="center">
                  <strong>This cannot be undone!</strong>
                </DialogMessage>
              </>
            ) : (
              <>
                <DialogMessage align="center">
                  <strong>Export in progress...</strong>
                </DialogMessage>
                <DialogMessage align="center">
                  Please wait until the export is completed. Don't close or
                  refresh the page.
                </DialogMessage>
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            {!isLoading ? (
              <>
                <ButtonBase
                  variant="outlined"
                  color="secondary"
                  onClick={handleConfirmClose}
                >
                  Cancel
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  variant="contained"
                  onClick={handleExportConfirm}
                >
                  Okay
                </ButtonBase>
              </>
            ) : (
              <Box className={classes.containerDialogLoader}>
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              </Box>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default AccountPayablesListActions;
