import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
  },
  poper: {
    pointerEvents: "none",
    padding: 20,
    marginTop: 5,
  },
  paper: {
    padding: 10,
    fontSize: 13,
    maxWidth: 320,
  },
}));

export default useStyles;
