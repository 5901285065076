import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  saveWorkticketFollow,
  getWorkticketPdf,
} from "services/workticketSurfaceService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketActions = (props) => {
  const classes = useStyles();
  const [stateContext] = useWorkticketView();
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const { workticket, isLoading } = stateContext ?? null;

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (workticket && workticket.id && loadingInitial) {
      const findFollow = workticket.followers.find(
        (userFollow) => userFollow.user_id === user.id
      );
      if (findFollow) {
        setIsFollow(true);
      } else {
        setIsFollow(false);
      }
      setLoadingInitial(false);
    }
  }, [workticket, user, loadingInitial]);

  useEffect(() => {
    if (workticket.id) {
      setLoadingInitial(true);
    }
  }, [workticket]);

  const handleFollow = async () => {
    try {
      setLoadingFollow(true);
      const data = {
        workticket_ids: [workticket.id],
        is_unfollow: isFollow ? 1 : 0,
      };
      await saveWorkticketFollow(data);
      setLoadingFollow(false);
      setIsFollow(isFollow ? false : true);
    } catch (e) {
      logException(e, "Cannot follow workticket");
    }
  };

  const handleOpenPdf = async () => {
    setLoadingPdf(true);
    const result = await getWorkticketPdf(workticket.id, 1, 1, 1);
    const file = new Blob([result.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    setLoadingPdf(false);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return (
    <Box className={classes.containerActionButtons}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <ButtonBase
            color="secondary"
            variant={isFollow ? `contained` : `outlined`}
            fullWidth={true}
            onClick={handleFollow}
            disabled={loadingFollow}
          >
            {isFollow ? `Unfollow` : `Follow`}
          </ButtonBase>
        </Grid>
        <Grid item sm={6} xs={12}>
          <ButtonBase
            color="secondary"
            variant="outlined"
            fullWidth={true}
            onClick={handleOpenPdf}
            startIcon={<PdfIcon />}
            disabled={loadingPdf}
          >
            Print PDF
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(WorkticketActions);
