import React, { useState, useEffect, useContext } from "react";
import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet-async";
import GlobalUiContext from "contexts/globalUiContext";
import {
  getUserPermissions,
  getUsersList,
  getUserMeta,
} from "services/userService";
import LoadingData from "../LoadingData/loadingData";

const stripSingleColon = (str) => {
  return str.replace(/'/g, "");
};

const DataProvider = (props) => {
  const { children } = props;
  const [meta, setMeta] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { dispatchGlobalUi } = useContext(GlobalUiContext);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const loadData = async () => {
      const resultPermissions = await getUserPermissions();
      const permissions = resultPermissions.data.data.role.permissions;
      const role = resultPermissions.data.data.role;
      dispatchGlobalUi({
        type: "SET_PERMISSIONS",
        permissions: permissions,
      });
      dispatchGlobalUi({
        type: "SET_ROLE",
        role: role,
      });

      const userMeta = await getUserMeta();
      const resultMeta = userMeta.data.data;
      setMeta(resultMeta);

      const resultUsers = await getUsersList();
      const resultUsersData = resultUsers.data.data.users.map((u) => {
        return { ...u, id: parseInt(u.id) };
      });

      dispatchGlobalUi({
        type: "SET_USERS",
        users: resultUsersData,
      });

      if (process.env.REACT_APP_ENV === "production") {
        LogRocket.identify(user.id, {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          employeeNumber: user.employee_number,
          company: resultMeta?.company,
          userGroup: resultMeta?.user_group,
          userRole: resultMeta?.user_role,
        });
        LogRocket.getSessionURL(function (sessionURL) {
          mixpanel.track("LogRocket", { sessionURL: sessionURL });
        });
      }
      setIsLoadingData(false);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchGlobalUi]);
  if (isLoadingData) {
    return <LoadingData />;
  }

  // prettier-ignore
  return (
    <>
      <Helmet>
        {process.env.REACT_APP_ENV === "production" ? (
          <>
            <script
              src={`https://insights-secure-storage.nyc3.cdn.digitaloceanspaces.com/scripts/freshpaint.${process.env.REACT_APP_ENV}.js`}
            ></script>
            <script>{`setInterval(function(){freshpaint.ready(function(){freshpaint.identify(${user.id}, {email: '${user.email}',name:'${stripSingleColon(user.first_name)} ${stripSingleColon(user.last_name)}', employeeNumber:'${user.employee_number}', company:'${stripSingleColon(meta?.company)}' ,Company:'${stripSingleColon(meta?.company)}' ,userGroup:'${stripSingleColon(meta?.user_group)}', userRole:'${stripSingleColon(meta?.user_role)}'})})},5000);`}</script>
          </>
        ) : null}
      </Helmet>
      {children}
    </>
  );
};

export default DataProvider;
