import React from "react";
import BudgetLayout from "./budgetLayout";
import { BudgetViewProvider } from "contexts/budgetViewContext";

const Budget = (props) => {
  return (
    <BudgetViewProvider>
      <BudgetLayout id={props.match.params.id} type={props.match.params.type} />
    </BudgetViewProvider>
  );
};

export default Budget;
