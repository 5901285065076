import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SuccessIcon from "assets/icons/success";
import GlobalUiContext from "contexts/globalUiContext";
import * as classNames from "classnames";
import useStyles from "./styles";

const MessageDialog = (props) => {
  const { dispatchGlobalUi } = useContext(GlobalUiContext);
  const classes = useStyles();
  const {
    open,
    title,
    message,
    hasClose,
    handleClose,
    redirect,
    redirectLabel,
  } = props;

  const handleRedirect = () => {
    const { history } = props;
    history.push(redirect);
    handleClose();
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleListView = () => {
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
    dispatchGlobalUi({
      type: "SET_REFRESH_DATA",
      refreshData: true,
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperDialog}>
        {hasClose && (
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
        )}
        <SuccessIcon className={classes.iconSuccessDialog} />
        <Typography variant="h6" gutterBottom className={classes.titleDialog}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.contentDialog}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.wrapperDialogAction}>
        <Button
          onClick={handleListView}
          className={classNames(classes.buttonLink, classes.buttonRedirect)}
          variant="contained"
          size="large"
        >
          Back to List View
        </Button>
        {redirect && (
          <Button
            onClick={handleRedirect}
            className={classNames(classes.buttonLink, classes.buttonConfirm)}
            variant="contained"
            size="large"
          >
            {redirectLabel}
          </Button>
        )}
        {hasClose && (
          <Button
            onClick={handleClose}
            className={classes.buttonOutlined}
            color="primary"
            variant="outlined"
            size="large"
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(MessageDialog);
