import React, { Fragment, useEffect } from "react";
import { useFilterDialog } from "../context/FilterDialogContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import useStyles from "../../projectPage/styles";
import { useQuery, useQueryClient } from "react-query";
import {
  getCities,
  getStates,
  getZipCodes,
} from "../../../services/partners/tradesService";
import Slide from "@material-ui/core/Slide";
import { CityFilter, CItyFilter } from "./CItyFilter";
import { StateFilter } from "./StateFilter";
import { ZipCodeFilter } from "./ZipCodeFilter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function CityStateFilterPage({
  filterCities,
  setFilterCities,
  filterStates,
  setFilterStates,
  filterZipCodes,
  setFilterZipCodes,
  citiesList,
  setCitiesList,
  statesList,
  setStatesList,
  zipCodesList,
  setZipCodesList,
  setOpen,
  editingGeoLocation,
  filterType,
}) {
  const classes = useStyles();
  const { handleDialogueClose, isEditingVariant, setIsEditingVariant } =
    useFilterDialog();
  const queryClient = useQueryClient();

  const [jobList, setJobList] = React.useState([]);
  const [stateValues, setStateValues] = React.useState([]);
  const [filterStateValue, setFilterStateValue] = React.useState([]);
  const [filterZipValue, setFilterZipValue] = React.useState([]);
  const [filterStateSelected, setFilterStateSelected] = React.useState([]);
  const [citiesStates, setCitiesStates] = React.useState({
    cities: [],
    states: [],
  });
  const [originalCities, setOriginalCities] = React.useState(filterCities);
  const [originalStates, setOriginalStates] = React.useState(filterStates);
  const [originalZipCodes, setOriginalZipCodes] =
    React.useState(filterZipCodes);
  const { data: statesData, isLoading: statesLoading } = useQuery(
    "states",
    async () => {
      const { data } = await getStates();
      return data.data.states;
    }
  );
  const [originalCityList, setOriginalCityList] = React.useState(citiesList);
  const [originalStateList, setOriginalStateList] = React.useState(statesList);
  const [originalZipList, setOriginalZipList] = React.useState(zipCodesList);
  const zipQuery = useQuery(
    ["zipCodes", citiesStates],
    async () => {
      const { data } = await getZipCodes({ citiesStates });
      return data.data.zip_codes; // Ensure this matches the structure
    },
    {
      // enabled: citiesStates.cities.length > 0 && citiesStates.states.length > 0,
      enabled: true,
      onSuccess: (data) => {},
      onError: (error) => {
        console.error("Error fetching zip data:", error);
      },
    }
  );
  useEffect(() => {
    if (isEditingVariant) {
      const dummyStates = transformData(statesData);
      let arrayOfStates = [];
      dummyStates?.forEach((state) => {
        if (filterStates.includes(state.value)) {
          arrayOfStates.push(state);
        }
      });

      setFilterStateValue(arrayOfStates);
      setStateValues(arrayOfStates);
      setFilterStateSelected(filterCities);
      setCitiesStates((prev) => ({
        ...prev,
        cities: filterCities,
        states: filterStates,
      }));
      const dummyZipCodes = transformDataZip(zipQuery?.data);
      let arrayOfZipCodes = [];
      dummyZipCodes?.forEach((zip) => {
        if (filterZipCodes.includes(zip.value)) {
          arrayOfZipCodes.push(zip);
        }
      });
      setFilterZipValue(arrayOfZipCodes);
    }
  }, [
    filterCities,
    filterStates,
    filterZipCodes,
    isEditingVariant,
    statesData,
    zipQuery?.data,
  ]);

  const cityQuery = useQuery(
    ["cities"],
    async () => {
      const { data } = await getCities({ states: filterStates });
      return data.data.cities;
    },
    {
      onSuccess: (data) => {
        setJobList(data);
      },
    }
  );

  const handleChangeState = async (event, value) => {
    const selectedStateValues = value.map((item) => item.value);
    const selectedStateLabels = value.map((item) => item.label);
    setFilterStateValue(value ?? []);
    setStateValues(selectedStateValues);
    setStatesList(selectedStateLabels);
    setCitiesStates((prev) => ({
      ...prev,
      states: selectedStateValues,
    }));
    setFilterStates(selectedStateValues);
    if (selectedStateValues.length) {
      await cityQuery.refetch();
    } else {
      setJobList([]);
    }
  };

  const handleClickStatesRow = async (event, id, stateLabel) => {
    const updatedFilterStateSelected = [...filterStates];
    if (updatedFilterStateSelected.includes(id)) {
      updatedFilterStateSelected.splice(
        updatedFilterStateSelected.indexOf(id),
        1
      );
      setStatesList((prev) => prev.filter((state) => state !== stateLabel));
    } else {
      updatedFilterStateSelected.push(id);
      setStatesList((prev) => [...prev, stateLabel]);
    }
    setStateValues(updatedFilterStateSelected);
    setCitiesStates((prev) => ({
      ...prev,
      states: updatedFilterStateSelected,
    }));

    if (updatedFilterStateSelected.length > 0 && stateValues.length > 0) {
      // await zipQuery.refetch();
    } else {
      setJobList([]);
    }
    setFilterStates(updatedFilterStateSelected);
  };
  const clickRow = async (event, id, cityLabel) => {
    const updatedFilterStateSelected = [...filterStateSelected];
    if (updatedFilterStateSelected.includes(id)) {
      updatedFilterStateSelected.splice(
        updatedFilterStateSelected.indexOf(id),
        1
      );
      setCitiesList((prev) => prev.filter((city) => city !== cityLabel));
    } else {
      updatedFilterStateSelected.push(id);
      setCitiesList((prev) => [...prev, cityLabel]);
    }
    setFilterStateSelected(updatedFilterStateSelected);

    setCitiesStates((prev) => ({
      ...prev,
      cities: updatedFilterStateSelected,
    }));
    setFilterCities(updatedFilterStateSelected);
    if (updatedFilterStateSelected.length > 0 && stateValues.length > 0) {
      // await zipQuery.refetch();
    }
  };
  const handleChangeZipRow = async (event, id, zipLabel) => {
    const updatedFilterZipSelected = [...filterZipCodes];
    if (updatedFilterZipSelected.includes(id)) {
      updatedFilterZipSelected.splice(updatedFilterZipSelected.indexOf(id), 1);
      setZipCodesList((prev) => prev.filter((zip) => zip !== zipLabel));
    } else {
      updatedFilterZipSelected.push(id);
      setZipCodesList((prev) => [...prev, zipLabel]);
    }
    setFilterZipCodes(updatedFilterZipSelected);
  };
  const handleChangeStateZip = async (event, value) => {
    const selectedZipValues = value.map((item) => item.value);
    const selectedZipLabels = value.map((item) => item.label);
    setZipCodesList(selectedZipLabels);
    setFilterZipValue(value ?? []);
    setFilterZipCodes(selectedZipValues);
  };

  const transformData = (arr) => {
    return arr?.map((item) => ({
      value: item.abbreviation,
      label: item.name,
    }));
  };

  const transformDataZip = (arr) => {
    return arr?.map((item) => ({
      value: item.id,
      label: `${item?.zip}, ${item.city?.city_name}-${item.city?.state_abbreviation}`,
    }));
  };

  return (
    <div>
      <Dialog open={true} fullScreen TransitionComponent={Transition}>
        <AppBar color="secondary" className={classes.appBarWorkticketPreview}>
          <Toolbar>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              className={classes.btnJobApply}
              onClick={() => {
                setOpen(false);
                setIsEditingVariant(false);
                setOriginalCities(filterCities);
                setOriginalStates(filterStates);
                setOriginalZipCodes(filterZipCodes);
                setOriginalCityList(citiesList);
                setOriginalStateList(statesList);
                setOriginalZipList(zipCodesList);
                if (filterType === "states") {
                  cityQuery.refetch();
                  if (filterCities.length > 0 && filterStates.length > 0) {
                    zipQuery.refetch();
                  }
                }
                if (filterType === "cities") {
                  if (filterCities.length > 0 && filterStates.length > 0) {
                    zipQuery.refetch();
                  }
                }
                if (filterType === "zip_codes") {
                }
              }}
            >
              Apply
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setOpen(false);
                setIsEditingVariant(false);
                setFilterCities(originalCities);
                setFilterStates(originalStates);
                setFilterZipCodes(originalZipCodes);
                setCitiesList(originalCityList);
                setStatesList(originalStateList);
                setZipCodesList(originalZipList);
                setStateValues([]);
                setFilterStateValue([]);
                setFilterZipValue([]);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.appBarTitle}>
              {filterType === "zip_codes" &&
                `${filterZipCodes?.length}/ ${
                  zipQuery?.data?.length ? zipQuery?.data?.length : 0
                } Zip Codes Selected`}
              {filterType === "cities" &&
                `${citiesList?.length}/ ${
                  cityQuery?.data?.length ? cityQuery?.data?.length : 0
                } Cities Selected`}
              {filterType === "states" &&
                `${statesList?.length}/ ${
                  statesData?.length ? statesData?.length : 0
                } States Selected`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box className={classes.filterJobContent}>
          <Box>
            {filterType === "zip_codes" && (
              <Fragment>
                <ZipCodeFilter
                  filterStateSelected={filterZipCodes}
                  clickRow={handleChangeZipRow}
                  zipQuery={zipQuery?.data}
                  classes={classes}
                  filterStateValue={filterStateValue}
                  filterCityValue={citiesList}
                  zipQueryLoading={zipQuery?.isLoading}
                />
                {zipQuery?.data?.length === 0 && (
                  <Typography
                    variant="h6"
                    className={classes.previewTableTitle}
                  >
                    No zip codes found
                  </Typography>
                )}
              </Fragment>
            )}
            {filterType === "cities" && (
              <Fragment>
                <CityFilter
                  classes={classes}
                  cityQuery={cityQuery}
                  cityLoading={cityQuery.isLoading}
                  clickRow={clickRow}
                  filterStateSelected={filterStateSelected}
                  stateData={transformData(statesData)}
                  filterStateValue={filterStateValue}
                />
                {cityQuery.data?.length === 0 && (
                  <Typography
                    variant="h6"
                    className={classes.previewTableTitle}
                  >
                    No cities found
                  </Typography>
                )}
              </Fragment>
            )}
            {filterType === "states" && (
              <Fragment>
                <StateFilter
                  classes={classes}
                  statesLoading={statesLoading}
                  stateQuery={statesData}
                  clickRow={handleClickStatesRow}
                  filterStateSelected={filterStates}
                />
                {statesData?.length === 0 && (
                  <Typography
                    variant="h6"
                    className={classes.previewTableTitle}
                  >
                    No states found
                  </Typography>
                )}
              </Fragment>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
