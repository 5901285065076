import React, { useState } from "react";
import { ButtonBase } from "components/ui/core/button";
import BuildIcon from "@material-ui/icons/Build";
import EmptyScreen from "components/ui/core/emptyScreen";
import { useDashboardState } from "contexts/dashboardContext";
import QuotesTable from "../quotes/QuotesTable";
import RequestTable from "../requests/RequestTable";
import WorkTicketsTable from "./WorkTicketsTable";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  workText: {
    flex: "1",
    marginLeft: "24px",
    fontWeight: "bolder",
    fontSize: "21px",
  },
  workTicketContainer: {
    flex: "5",
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
    maxHeight: "35px",
    minHeight: "30px",
    marginTop: "10px",
  },
  workTicketsTab: {
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "14px",
    cursor: "pointer",
  },
  flexContainer: { flex: "1", marginLeft: "20px" },
  flex1: { flex: "1" },
  loader: { display: "flex", marginTop: "80px" },
  centerLoadingTab: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
}));

const redirectPath = {
  1: "/worktickets",
  2: "/quotes",
  3: "/requests",
};

const WorkComponent = ({ history, selectedLocation, isSurfaceUser }) => {
  const [selected, setSelected] = useState(1);
  const workTableData = useDashboardState();
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container} id="mobile-tab-container">
        <Box className={classes.workText} id="mobile-tab">
          Work
        </Box>
        <Box className={classes.workTicketContainer}>
          {["Worktickets", "Quotes", "Requests"].map((v, i) => {
            return (
              <Box
                key={i}
                className={classes.workTicketsTab}
                style={{
                  borderBottom: selected !== i + 1 ? "" : "3px solid #16457A",
                  color: selected !== i + 1 ? "#C4C4C4" : "#16457A",
                }}
                onClick={() => setSelected(i + 1)}
              >
                {v}
              </Box>
            );
          })}
        </Box>
        <Box className={classes.flexContainer} id="view-all-container">
          <ButtonBase
            variant="contained"
            color="secondary"
            onClick={() => history.push(redirectPath[selected])}
            disableElevation
          >
            View All
          </ButtonBase>
        </Box>
      </Box>
      <br />
      {workTableData.sliderWorkListLoading ? (
        <Box className={classes.loader}>
          <LoadingStateHorizontal isVisible style={classes.centerLoadingTab} />
        </Box>
      ) : (
        <Box>
          {selected === 1 ? (
            !workTableData?.sliderWorkList?.data?.worktickets.length ? (
              <EmptyScreen
                icon={<BuildIcon />}
                type={3}
                actionText="Add A Workticket"
              />
            ) : (
              <WorkTicketsTable
                history={history}
                workTableData={workTableData?.sliderWorkList?.data}
                selectedLocation={selectedLocation}
                isSurfaceUser={isSurfaceUser}
              />
            )
          ) : null}
          {selected === 2 ? (
            !workTableData?.sliderWorkList?.data?.quotes.length ? (
              <EmptyScreen
                icon={<BuildIcon />}
                type={3}
                actionText="Add A Quote"
              />
            ) : (
              <QuotesTable
                history={history}
                workTableData={workTableData?.sliderWorkList?.data}
                isSurfaceUser={isSurfaceUser}
              />
            )
          ) : null}
          {selected === 3 ? (
            !workTableData?.sliderWorkList?.data?.requests.length ? (
              <EmptyScreen
                icon={<BuildIcon />}
                type={3}
                actionText="Add A Request"
              />
            ) : (
              <RequestTable
                history={history}
                workTableData={workTableData?.sliderWorkList?.data}
              />
            )
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default WorkComponent;
