import React from "react";
import { FilterProvider } from "contexts/filterContext";

const withFilterContext = (BaseComponent) => (props) => (
  <FilterProvider>
    <BaseComponent {...props} />
  </FilterProvider>
);

export default withFilterContext;
