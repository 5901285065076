import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FieldLocation = (props) => {
  const classes = useStyles();
  const { jobs } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  if (!jobs) {
    return "Error jobs";
  }
  if (!jobs.length) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const handlePopoverOpen = (event) => {
    event.preventDefault();
    if (jobs.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const listJobs = jobs.map((job) => {
    return job.job_description;
  });

  return (
    <>
      <Typography
        variant="body2"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.label}
      >
        {listJobs[0]} {listJobs[1] ? "..." : ""}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={2}
      >
        {listJobs.join("; ")}
      </Popover>
    </>
  );
};

export default FieldLocation;
