import React from "react";
import SurveysTabLocationsDialog from "./surveysTabLocationsDialog";
import SurveyContent from "pages/survey/surveyContent";

const LocationsListDialog = (props) => {
  return (
    <>
      <SurveysTabLocationsDialog />
      <SurveyContent />
    </>
  );
};
export default LocationsListDialog;
