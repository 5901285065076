import useStyles from "../styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import FormInput from "../../../components/ui/FormContent/formInput";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useAddQuestionModal } from "../context/AddQuestionDialogContext";
import { QuestionMarkCircle } from "../icons/QuestionMarkCircle";
import Typography from "@material-ui/core/Typography";
import { useMutation, useQueryClient } from "react-query";
import { createQuestion } from "../../../services/partners/questionsService";
import { useGetUserId } from "../context/UserIdContext";

export function AddQuestionDialog() {
  const { userId } = useGetUserId();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const { openQuestionDialogue, handleDialogueClose } = useAddQuestionModal();
  const initialState = {
    question: "",
  };
  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };
  const queryClient = useQueryClient();

  const mutation = useMutation(createQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      setData(initialState);
      handleDialogueClose();
      setIsSubmitting(false);
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });
  const onSubmit = () => {
    setIsSubmitting(true);
    mutation.mutate({
      data: data,
      partner_id: userId,
    });
  };
  const [data, setData] = useState(initialState);
  return (
    <Dialog open={openQuestionDialogue} aria-labelledby="form-dialog-title">
      <DialogTitle
        className={classes.dialogTitleWrapper}
        id="form-dialog-title"
      >
        <Box className={classes.dialogTitleInnerWrapper}>
          <QuestionMarkCircle />
          <Typography className={classes.dialogTitle}>
            Add New Question
          </Typography>
        </Box>
        <IconButton
          onClick={handleDialogueClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.addContactFormWrapper}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="question"
          label="Question Title"
          value={data.question}
          styleOverride={{ minWidth: "425px" }}
          handleBlur={handleBlur}
        />
      </DialogContent>
      <DialogActions>
        <Box className={classes.addQuestionButtonActionContainer}>
          <Button
            className={classes.addContactCancelButton}
            onClick={handleDialogueClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            className={classes.secondAddContactButton}
            onClick={onSubmit}
          >
            Add Question
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
