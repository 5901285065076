import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Skeleton from "@material-ui/lab/Skeleton";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import ProjectTypeChip from "components/ui/Projects/ProjectTypeChip";
import { convertDateFormatField } from "components/util/timeFormat";
import { updateProjectDetails } from "services/projectService";
import GlobalUiContext from "contexts/globalUiContext";
import { useProjectView } from "contexts/projectViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { projectOptions, switchOptions } from "constants.js";
import { permissionQuote, hasPermission } from "lib/permissions";

const ProjectInformation = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext, dispatchContext] = useProjectView();

  const { project, isLoading } = stateContext ?? null;

  const handleBlur = async (event) => {
    if (!hasPermission(permissionQuote.EDIT, permissions)) {
      return;
    }
    if (project[event.name] !== event.value) {
      try {
        await updateProjectDetails(project.id, { [event.name]: event.value });
      } catch (e) {
        logException(e, "Cannot update project");
      }
    }
  };

  const handleBillable = async (is_billable) => {
    if (!hasPermission(permissionQuote.EDIT, permissions)) {
      return;
    }
  };

  const handleChangeStatus = async (event) => {
    if (!hasPermission(permissionQuote.EDIT, permissions)) {
      return;
    }
    if (project.status !== event.value) {
      try {
        const data = {
          status: event.value,
        };
        project.status = event.value;
        dispatchContext({
          type: "SET_PROJECT",
          project: project,
        });
        await updateProjectDetails(project.id, data);
      } catch (e) {
        logException(e, "Cannot update project status");
      }
    }
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <>
      <Box className={classes.containerInformation}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="id"
          label="Project Number"
          value={`${project.number}`}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />

        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="title"
          label="Title"
          value={project.title}
          handleBlur={handleBlur}
          readonly={!hasPermission(permissionQuote.EDIT, permissions)}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="description"
          label="Description"
          value={project.description ? project.description : ""}
          multiline={true}
          rows={4}
          handleBlur={handleBlur}
          readonly={!hasPermission(permissionQuote.EDIT, permissions)}
        />

        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="customer_name"
          label="Customer"
          value={project.customer.name}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />
        {project?.job ? (
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job"
            label="Job"
            value={`${project.job.job_number} - ${project.job.job_description}`}
            styleOverride={{ cursor: "not-allowed" }}
            readonly={true}
          />
        ) : null}

        {project.external_woid && (
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="external_woid"
            label="WOID"
            value={project.external_woid}
            readonly={true}
          />
        )}
        <Box className={classes.containerPriority}>
          <InputLabel className={classes.inputLabel}>Type</InputLabel>
          <ProjectTypeChip type={project.type} />
        </Box>
        <FormSelect
          gridSizes={[{ size: "md", val: 12 }]}
          name="status"
          label="Status"
          options={projectOptions.status}
          value={project.status}
          handleBlur={handleChangeStatus}
        />

        {[-1].includes(project.type) && (
          <Grid container spacing={4} className={classes.containerOptions}>
            <Grid item md={6} sm={12}>
              <InputLabel className={classes.inputLabel}>Billable</InputLabel>
              <ButtonGroup
                variant="outlined"
                color="default"
                aria-label="primary button group"
                className={classes.groupOption}
                fullWidth={true}
              >
                {switchOptions.map((opt) => (
                  <Button
                    key={`billable-${opt.value}`}
                    className={
                      project.is_billable === opt.value
                        ? classes.groupOptionActive
                        : null
                    }
                    onClick={() => handleBillable(opt.value)}
                  >
                    {opt.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
          </Grid>
        )}
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="created_at"
          label="Created Date"
          value={convertDateFormatField(project.created_at)}
          styleOverride={{
            fontWeight: "bold",
            cursor: "not-allowed",
          }}
          readonly={true}
        />
      </Box>
    </>
  );
};

export default ProjectInformation;
