import React, { useMemo } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import { Quality } from "components/common/Icons/navIcons";
import EmptyScreen from "components/ui/core/emptyScreen";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import useStyles from "./styles";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  questionFill,
} from "contexts/surveyFillContext";
import { surveysLabelsLang } from "constants.js";

const defaultLabels = {
  English: {
    left: "Very Poor",
    center: "Average",
    right: "Excellent",
  },
  Spanish: {
    left: "Pobre",
    center: "Regular",
    right: "Excelente",
  },
};

const SurveyQuestionRating = (props) => {
  const classes = useStyles();
  const {
    selectedQuestions,
    selectedTasks,
    correlative,
    lang,
    updateAnswers,
    isPreview,
    readonlySurvey,
    showOnlyAnsweredTasks,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const isMobile = useMemo(
    () => "ontouchstart" in document.documentElement,
    []
  );

  const options = useMemo(
    () =>
      Array.from(
        { length: selectedQuestions[correlative].scale },
        (_, i) => i + 1
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [correlative, selectedQuestions]
  );
  const labels = useMemo(
    () => JSON.parse(selectedQuestions[correlative].labels),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [correlative, selectedQuestions]
  );
  const labels_es = useMemo(
    () => JSON.parse(selectedQuestions[correlative].labels_es),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [correlative, selectedQuestions]
  );

  const handleMouseOver = (task, e) => {
    const num = Number(e.target.getAttribute("data-value"));
    if (num) {
      task.hover = num;
    }
    task.hovering = true;
    dispatchSurveyFill({
      type: "SET_SELECTED_TASKS",
      selectedTasks: selectedTasks,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseLeave = (task, e) => {
    task.hover = -1;
    task.hovering = false;
    dispatchSurveyFill({
      type: "SET_SELECTED_TASKS",
      selectedTasks: selectedTasks,
    });

    e.preventDefault();
    e.stopPropagation();
  };

  const handleSetScore = (task, v) => {
    if (!readonlySurvey) {
      let data = {};
      let filled = false;
      task.score = v;
      task.hover = v;
      task.status = 2;
      task.total_score =
        parseFloat(v) / parseFloat(selectedQuestions[correlative].scale);

      const answeredTasks = selectedTasks.filter((t) => t.status === 2);
      if (answeredTasks.length > 0) {
        selectedQuestions[correlative].filled = true;
        const totalSum = answeredTasks.reduce(
          (a, b) => a + (parseFloat(b["total_score"]) || 0),
          0
        );
        const totalTasks = answeredTasks.length;
        filled = true;
        data = {
          total_score: totalSum / totalTasks,
          status: 2,
          completed_using_es: lang !== "English",
          tasks: [
            {
              id: task.id,
              score: task.score,
              total_score: task.total_score,
              status: 2,
            },
          ],
        };
      }

      if (!isPreview) {
        questionFill(
          dispatchSurveyFill,
          selectedQuestions[correlative].id,
          data,
          filled && selectedQuestions[correlative].status === 1,
          !updateAnswers
        );
      }
      if (filled) {
        selectedQuestions[correlative].status = 2;
        dispatchSurveyFill({
          type: "SET_SELECTED_QUESTIONS",
          selectedQuestions: selectedQuestions,
        });
      }
      dispatchSurveyFill({
        type: "SET_SELECTED_TASKS",
        selectedTasks: selectedTasks,
      });
    }
  };

  const labelDisplay = lang === "Spanish" ? labels_es : labels;

  return (
    <Box>
      <Typography className={classes.quesTextSub}>
        {lang === "Spanish" && selectedQuestions[correlative].tooltip_es?.length
          ? selectedQuestions[correlative].tooltip_es
          : selectedQuestions[correlative].tooltip}
      </Typography>

      <List className={classes.questionList}>
        {showOnlyAnsweredTasks ? (
          selectedTasks.filter((t) => parseInt(t.status) === 2).length > 0 ? (
            selectedTasks
              .filter((t) => parseInt(t.status) === 2)
              .map((task) => (
                <ListItem className={classes.listMinHeight} key={task.id}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.alignQuesOption}
                    >
                      <Typography className={classes.quesOption}>
                        {lang === "Spanish" && task.name_es?.length
                          ? task.name_es
                          : task.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={options.length > 4 ? 6 : options.length}
                      className={classes.alignRating}
                      onMouseOver={
                        isMobile
                          ? null
                          : (e) =>
                              !readonlySurvey ? handleMouseOver(task, e) : ""
                      }
                      onMouseLeave={
                        isMobile
                          ? null
                          : (e) =>
                              !readonlySurvey ? handleMouseLeave(task, e) : ""
                      }
                    >
                      {options.map((v) => {
                        return parseInt(
                          selectedQuestions[correlative].rating_type
                        ) === 1 ? (
                          <Box
                            className={classes.ratingBox}
                            data-value={v}
                            onClick={() => handleSetScore(task, v)}
                            key={`Box${task.id}-${v}`}
                          >
                            <Typography
                              data-value={v}
                              className={classNames(classes.ratingFont, {
                                [classes.ratingFontSelected]:
                                  (v <= task.score && !task.hovering) ||
                                  v <= task.hover,
                                [classes.ratingFontSelectedActive]:
                                  v === task.score,
                              })}
                            >
                              {v}
                            </Typography>
                          </Box>
                        ) : (v <= task.score && !task.hovering) ||
                          v <= task.hover ? (
                          <StarIcon
                            key={`Star${task.id}-${v}`}
                            className={classNames(classes.ratingStarSelected, {
                              [classes.ratingStarSelectedActive]:
                                v === task.score,
                            })}
                            data-value={v}
                            onClick={() => handleSetScore(task, v)}
                          />
                        ) : (
                          <StarOutlineIcon
                            key={`StarO${task.id}-${v}`}
                            className={classes.ratingStar}
                            data-value={v}
                            onClick={() => handleSetScore(task, v)}
                          />
                        );
                      })}
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={6}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={options.length > 4 ? 6 : options.length}
                        className={classes.ratingTextContainer}
                      >
                        <Typography className={classes.ratingTextBelow}>
                          {labelDisplay?.left?.length
                            ? labelDisplay.left
                            : defaultLabels[lang].left}
                        </Typography>
                        {options.length >= 3 ? (
                          <Typography className={classes.ratingTextBelow}>
                            {labelDisplay?.center?.length
                              ? labelDisplay.center
                              : defaultLabels[lang].center}
                          </Typography>
                        ) : null}
                        <Typography className={classes.ratingTextBelow}>
                          {labelDisplay?.right?.length
                            ? labelDisplay.right
                            : defaultLabels[lang].right}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
          ) : (
            <EmptyScreen
              detailText={surveysLabelsLang[lang].noAnswerMessage}
              icon={<Quality fill="#17457a" width="48px" />}
              type={3}
              actionText={surveysLabelsLang[lang].noAnswerMessageAction}
              detailActionText={surveysLabelsLang[lang].noAnswerMessageDetail}
            />
          )
        ) : (
          selectedTasks.map((task) => (
            <ListItem className={classes.listMinHeight} key={task.id}>
              <Grid container>
                <Grid item xs={12} md={6} className={classes.alignQuesOption}>
                  <Typography className={classes.quesOption}>
                    {lang === "Spanish" && task.name_es?.length
                      ? task.name_es
                      : task.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={options.length > 4 ? 6 : options.length}
                  className={classes.alignRating}
                  onMouseOver={(e) =>
                    isMobile
                      ? null
                      : !readonlySurvey
                      ? handleMouseOver(task, e)
                      : ""
                  }
                  onMouseLeave={(e) =>
                    isMobile
                      ? null
                      : !readonlySurvey
                      ? handleMouseLeave(task, e)
                      : ""
                  }
                >
                  {options.map((v) => {
                    return selectedQuestions[correlative].rating_type === 1 ? (
                      <Box
                        className={classes.ratingBox}
                        data-value={v}
                        onClick={() => handleSetScore(task, v)}
                        key={`Box${task.id}-${v}`}
                      >
                        <Typography
                          data-value={v}
                          className={classNames(classes.ratingFont, {
                            [classes.ratingFontSelected]:
                              (v <= task.score && !task.hovering) ||
                              v <= task.hover,
                            [classes.ratingFontSelectedActive]:
                              v === task.score,
                          })}
                        >
                          {v}
                        </Typography>
                      </Box>
                    ) : (v <= task.score && !task.hovering) ||
                      v <= task.hover ? (
                      <StarIcon
                        key={`Star${task.id}-${v}`}
                        className={classNames(classes.ratingStarSelected, {
                          [classes.ratingStarSelectedActive]: v === task.score,
                        })}
                        data-value={v}
                        onClick={() => handleSetScore(task, v)}
                      />
                    ) : (
                      <StarOutlineIcon
                        key={`StarO${task.id}-${v}`}
                        className={classes.ratingStar}
                        data-value={v}
                        onClick={() => handleSetScore(task, v)}
                      />
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid
                    item
                    xs={12}
                    md={options.length > 4 ? 6 : options.length}
                    className={classes.ratingTextContainer}
                  >
                    <Typography className={classes.ratingTextBelow}>
                      {labelDisplay?.left?.length
                        ? labelDisplay.left
                        : defaultLabels[lang].left}
                    </Typography>
                    {options.length >= 3 ? (
                      <Typography className={classes.ratingTextBelow}>
                        {labelDisplay?.center?.length
                          ? labelDisplay.center
                          : defaultLabels[lang].center}
                      </Typography>
                    ) : null}
                    <Typography className={classes.ratingTextBelow}>
                      {labelDisplay?.right?.length
                        ? labelDisplay.right
                        : defaultLabels[lang].right}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default SurveyQuestionRating;
