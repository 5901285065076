import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5
  },
  wrapperDialogDelete: {
    position: "absolute",
    right: 50,
    top: 5
  },
  filterCheckbox: {
    color: "#ececec",
    border: "none",
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14
    }
  },
  gridContainer: {
    "& .MuiInputBase-root": {
      marginBottom: 0
    }
  },
  titleOption: {
    marginTop: 28,
    fontSize: 16
  },
  skipContainer: {
    marginTop: 20
  },
  daysOptionsContainer: {
    marginTop: 10,
    marginBottom: 10,
    "& .MuiIconButton-root": {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  wrapperDialogAction: {
    marginBottom: 10
  },
  button: {
    textTransform: "capitalize"
  },
  subTitleDialog: {
    position: "relative",
    top: "-12px"
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5
  },
  contentDialog: {
    fontSize: 14
  },
  wrapperDialogAction2: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40
  },
  button2: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc"
    }
  },
  wrapperDialog: {
    textAlign: "center"
  }
}));

export default useStyles;
