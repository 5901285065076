import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { duplicateWorkticket } from "services/workticketService";
import {
  useWorkticketState,
  useWorkticketDispatch,
  getWorkticketListDataSet,
} from "contexts/workticketContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useFilterState } from "contexts/filterContext";

import useStyles from "./styles";

const DuplicateDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [duplicateSuccess, setDuplicateSuccess] = useState("");
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const { workticketList, workticketCount } = useWorkticketState();
  const { workticketSelected } = useWorkticketState();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const dispatchWorktickets = useWorkticketDispatch();
  const { currentTab } = tableUi;
  const { openDuplicate } = workticketAction;
  const {
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_DUPLICATE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);

      // Duplicate workticket
      const responseDuplicate = await duplicateWorkticket(
        workticketSelected.id
      );
      setDuplicateSuccess(responseDuplicate.data.data.workticket.number);

      // Add the notification to not schedule task
      const notScheduleStatus = 10;
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: { tab: notScheduleStatus, count: 1 },
      });

      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };

      workticketList[notScheduleStatus] = [];
      await getWorkticketListDataSet(
        workticketList,
        dispatchWorktickets,
        notScheduleStatus,
        0,
        filterData
      );
      workticketCount[notScheduleStatus] += 1;

      // Mark updated fields
      dispatchTable({
        type: "SET_UPDATED_ROWS",
        rowsUpdated: [responseDuplicate.data.data.workticket.id],
      });

      // Current tab update table data
      if (currentTab === notScheduleStatus) {
        const workticketDataUpdate = workticketList[currentTab]
          ? workticketList[currentTab]
          : [];
        dispatchTable({ type: "UPDATE_ROWS", rows: workticketDataUpdate });
      }

      dispatchWorktickets({
        type: "SET_WORKTICKET_COUNT",
        workticketCount: workticketCount,
      });

      setIsLoadingDialog(false);
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      console.log("Cannot duplicate workticket");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openDuplicate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Worktickets
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to duplicate these workticket?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket have been duplicated, ${duplicateSuccess} created.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default DuplicateDialog;
