import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import useStyles from "../styles";
import Button from "@material-ui/core/Button";
import ToysIcon from "@material-ui/icons/Toys";
import { Typography } from "@material-ui/core";
import { getAssetDetailsById } from "services/mapService";
import MapIcon from "@material-ui/icons/Map";
import HistoryIcon from "@material-ui/icons/History";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import TimelineIcon from "@material-ui/icons/Timeline";
// import moment from "moment";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CloseIcon from "@material-ui/icons/Close";

// const optionsDays = {
//   1: "Monday",
//   2: "Tuesday",
//   3: "Wednesday",
//   4: "Thursday",
//   5: "Friday",
//   6: "Saturday",
//   7: "Sunday",
// };

const AssetDetails = ({
  history,
  isSurfaceUser,
  assetModalDataVisibility,
  setAssetModalDataVisibility,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [assetDetailsData, setAssetDetailsData] = useState(null);
  const [tabArray, setTabArray] = useState(["Daily", "Weekly", "Monthly"]);
  const [tabArrayData, setTabarrayData] = useState([[], [], []]);
  const [counts, setCounts] = useState({
    Daily: 0,
    Weekly: 0,
    Monthly: 0,
  });

  useEffect(() => {
    getAssetDetailsById(
      assetModalDataVisibility?.selectedRowData?.id,
      isSurfaceUser
    ).then((res) => {
      setAssetDetailsData(res?.data?.data);
      // let monthsCount = 0;
      // let daysCount = 0;
      // let weeksCount = 0;
      // let data = res?.data?.data?.maintenance_plan?.map((v, i) => {
      //   if (v.schedule_interval === "months") {
      //     ++monthsCount;
      //     tabArrayData[2] = tabArrayData[2].push(v?.schedule_data);
      //   } else if (v.schedule_interval === "days") {
      //     ++daysCount;
      //     tabArrayData[0] = tabArrayData[0].push(v?.schedule_data);
      //   } else if (v.schedule_interval === "weeks") {
      //     ++weeksCount;
      //     tabArrayData[1] = tabArrayData[1].push(v?.schedule_data);
      //   }
      // });
      // tabArray[0] = tabArray[0] + `(${daysCount})`;
      // tabArray[1] = tabArray[1] + `(${weeksCount})`;
      // tabArray[2] = tabArray[2] + `(${monthsCount})`;
      // setCounts({
      //   Daily: daysCount,
      //   Weekly: weeksCount,
      //   Monthly: monthsCount,
      // });
      setTabArray([...tabArray]);
      setTabarrayData([...tabArrayData]);
    });
  }, []);

  const taskList = assetModalDataVisibility?.selectedRowData?.tasks
    ? JSON.parse(assetModalDataVisibility.selectedRowData.tasks)
    : null;

  return (
    <Box className={classes.assetRoot} id="asset-details-modal">
      <Box>
        <Box className={classes.assetDetailsText}>Asset Details</Box>
        <Box className={classes.close}>
          <CloseIcon onClick={() => setAssetModalDataVisibility(false)} />
        </Box>
        <Box className={classes.flex}>
          <ToysIcon />
          <Typography className={classes.paddingLeft}>
            {assetModalDataVisibility?.selectedRowData?.name}
          </Typography>
        </Box>

        <Box className={classes.flex}>
          <MapIcon />
          <Typography className={classes.paddingLeft}>
            {" "}
            {assetModalDataVisibility?.selectedRowData?.locations[0]}
          </Typography>
        </Box>
        <Box className={classes.lineBreak} />
        <Box className={classes.containerDialog}>
          {taskList &&
            taskList.map((task) => (
              <FormControlLabel
                value={task.name}
                key={task.id}
                checked={false}
                control={
                  <Checkbox
                    className={classes.tableCheckbox}
                    color="secondary"
                  />
                }
                label={<Box className={classes.taskLabel}>{task.name}</Box>}
                labelPlacement="end"
                className={classes.taskItem}
              />
            ))}
        </Box>
        {/* <Box>
          <Box className={classes.workTicketContainer}>
            {tabArray.map((v, i) => {
              return (
                <Box
                  className={classes.assetTab}
                  key={i}
                  style={{
                    borderBottom: selected !== i + 1 ? "" : "3px solid #16457A",
                    color: selected !== i + 1 ? "#C4C4C4" : "#16457A",
                  }}
                  onClick={() => setSelected(i + 1)}
                >
                  {v}
                </Box>
              );
            })}
          </Box>
        </Box> */}
      </Box>

      {/* {selected === 1 && (
        <Box className={classes.containerPlan}>
          {counts["Daily"] ? (
            assetDetailsData?.maintenance_plan?.map(
              (plan, index) =>
                plan.schedule_interval === "days" && (
                  <List className={classes.containerPlanList} key={index}>
                    <ListItem className={classes.containerPlanItem}>
                      <ListItemIcon>
                        <TimelineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Every ${
                          plan.schedule_data.value < 2
                            ? `Day`
                            : `${plan.schedule_data.value} days`
                        }`}
                        secondary={`Skip weekends: ${
                          plan.schedule_data.skip_weekends ? `Yes` : `No`
                        }`}
                      />
                      {plan.schedule_data.end_date && (
                        <ListItemSecondaryAction
                          className={classes.containerPlanDate}
                        >
                          End Date:{" "}
                          {moment(plan.schedule_data.end_date).format("LL")}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </List>
                )
            )
          ) : (
            <List className={classes.containerPlanList}>
              <ListItem>
                <ListItemText primary={"No daily maintenance plan"} />
              </ListItem>
            </List>
          )}
        </Box>
      )}
      {selected === 2 && (
        <Box className={classes.containerPlan}>
          {counts["Weekly"] ? (
            assetDetailsData?.maintenance_plan?.map(
              (plan, index) =>
                plan.schedule_interval === "weeks" && (
                  <List className={classes.containerPlanList} key={index}>
                    <ListItem className={classes.containerPlanItem}>
                      <ListItemIcon>
                        <TimelineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Every ${
                          plan.schedule_data.value < 2
                            ? `Week`
                            : `${plan.schedule_data.value} weeks`
                        }`}
                        secondary={`On ${plan.schedule_data.days.map(
                          (day) => `${optionsDays[day]} `
                        )}`}
                      />
                      {plan.schedule_data.end_date && (
                        <ListItemSecondaryAction
                          className={classes.containerPlanDate}
                        >
                          End Date:{" "}
                          {moment(plan.schedule_data.end_date).format("LL")}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </List>
                )
            )
          ) : (
            <List className={classes.containerPlanList}>
              <ListItem>
                <ListItemText primary={"No weekly maintenance plan"} />
              </ListItem>
            </List>
          )}
        </Box>
      )}
      {selected === 3 && (
        <Box className={classes.containerPlan}>
          {counts["Monthly"] ? (
            assetDetailsData?.maintenance_plan?.map(
              (plan, index) =>
                plan.schedule_interval === "months" && (
                  <List className={classes.containerPlanList} key={index}>
                    <ListItem className={classes.containerPlanItem}>
                      <ListItemIcon>
                        <TimelineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Every ${
                          plan.schedule_data.value < 2
                            ? `Month`
                            : `${plan.schedule_data.value} months`
                        }  on ${plan.schedule_data.days.map(
                          (day) => `${day}`
                        )}`}
                        secondary={`Skip weekends: ${
                          plan.schedule_data.skip_weekends ? `Yes` : `No`
                        }`}
                      />
                      {plan.schedule_data.end_date && (
                        <ListItemSecondaryAction
                          className={classes.containerPlanDate}
                        >
                          End Date:{" "}
                          {moment(plan.schedule_data.end_date).format("LL")}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </List>
                )
            )
          ) : (
            <List className={classes.containerPlanList}>
              <ListItem>
                <ListItemText primary={"No monthly maintenance plan"} />
              </ListItem>
            </List>
          )}
        </Box>
      )} */}

      <Box className={classes.flex}>
        <Button
          className={selectedTab === 1 ? classes.dark : classes.light}
          variant={selectedTab === 1 ? "contained" : "text"}
          onClick={() => setSelectedTab(1)}
        >
          Upcoming
        </Button>
        <Button
          className={selectedTab === 2 ? classes.dark : classes.light}
          variant={selectedTab === 2 ? "contained" : "text"}
          onClick={() => setSelectedTab(2)}
        >
          History
        </Button>
        <Box className={classes.lineBreak} />
      </Box>
      {assetDetailsData === null ? <LinearProgress color="secondary" /> : ""}
      {selectedTab === 1 ? (
        <Box id="modal-body">
          <Box className={classes.lineBreak} />
          {assetDetailsData?.upcoming_maintenance.map((v, i) => {
            return (
              <Box key={i} className={classes.flex}>
                <Box className={classes.flex1}>
                  <HistoryIcon className={classes.marginRight8} />
                  <Typography>{v?.number}</Typography>
                </Box>
                <Box className={classes.flex1Right}>
                  <Button
                    variant="text"
                    className={classes.followingBtn}
                    onClick={() => history.push(`/workticket/${v.id}`)}
                  >
                    View
                  </Button>
                </Box>
                <Box className={classes.lineBreak} />
              </Box>
            );
          })}
        </Box>
      ) : (
        ""
      )}
      {selectedTab === 2 ? (
        <Box>
          <Box className={classes.lineBreak} />
          {assetDetailsData?.historical_maintenance.map((v, i) => {
            return (
              <Box key={i} className={classes.flex}>
                <Box className={classes.flex1}>
                  <HistoryIcon className={classes.marginRight8} />
                  <Typography>{v?.number}</Typography>
                </Box>
                <Box className={classes.flex1Right}>
                  <Button
                    variant="text"
                    className={classes.followingBtn}
                    onClick={() => history.push(`/workticket/${v.id}`)}
                  >
                    View
                  </Button>
                </Box>
                <Box className={classes.lineBreak} />
              </Box>
            );
          })}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default withRouter(AssetDetails);
