import React, { useContext, useReducer } from "react";

const JobViewContext = React.createContext();

const initialState = {
  job: {},
  files: [],
  notes: "",
  isLoading: true,
  openCreateContact: false,
  refreshContactsOptions: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_JOB":
      return {
        ...state,
        job: action.job,
      };
    case "SET_FILES":
      return {
        ...state,
        files: action.files,
      };
    case "SET_NOTES":
      return {
        ...state,
        notes: action.notes,
      };
    case "SET_OPEN_CREATE_CONTACT":
      return {
        ...state,
        openCreateContact: action.openCreateContact,
      };
    case "SET_REFRESH_CONTACTS":
      return {
        ...state,
        refreshContactsOptions: action.refreshContactsOptions,
      };
    default:
      return state;
  }
};

const JobViewProvider = ({ children }) => (
  <JobViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </JobViewContext.Provider>
);

const useJobView = () => {
  const context = useContext(JobViewContext);
  if (context === undefined) {
    throw new Error("useJobView must be used within a JobViewProvider");
  }
  return context;
};

export { JobViewProvider, useJobView };
