import { Fragment, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { PinIcon } from "../icons/PinIcon";
import ThreeDots from "../icons/ThreeDots";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import { TradesVariants } from "./TradesVariants";
import ChipList from "../../partnersList/chipList";
import DeleteTradeDialogue from "./DeleteTradeDialogue";

export const TradeAccordion = ({
  item,
  accordionIndex,
  expanded,
  handleChange,
  classes,
  handleClick,
  anchorEl,
  selectedAccordion,
  handleClose,
  handleAddVariant,
  handleEditTrade,
  handleDeleteTrade,
  loadingState,
}) => {
  const [isTradeDialogOpen, setIsTradeDialogOpen] = useState(false);
  const menuOptions = [
    {
      icon: <AddIcon fontSize="inherit" />,
      label: "Add New Variant",
      action: () => {
        handleAddVariant(item);
        handleClose();
      },
      disabled: loadingState,
    },
    {
      icon: <EditIcon fontSize="inherit" />,
      label: "Edit",
      action: () => {
        handleEditTrade(item);
        handleClose();
      },
      disabled: loadingState,
    },
    {
      icon: <DeleteBin fontSize="inherit" />,
      label: "Delete",
      action: () => {
        setIsTradeDialogOpen(true);
        handleClose();
      },
      disabled: loadingState,
    },
  ];

  return (
    <Fragment key={accordionIndex}>
      <Accordion
        classes={{ root: classes.accordionRoot }}
        expanded={expanded.includes(accordionIndex)}
        onChange={handleChange(accordionIndex)}
        defaultExpanded={false}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={`${classes.expandIcon} ${
                expanded.includes(accordionIndex)
                  ? classes.expandIconExpanded
                  : ""
              }`}
            />
          }
        >
          <Chip
            className={
              expanded.includes(accordionIndex)
                ? classes.chipExpanded
                : classes.chipNotExpanded
            }
            label={item?.trade?.trade_name}
            variant="outlined"
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsContainer}>
          <Box className={classes.accordionVariantStandard}>
            {item?.states?.length > 1 ? (
              <Box className={classes.accordionCityTitleContainer}>
                <Box className={classes.pinTitleContainer}>
                  <PinIcon />
                  <Typography className={classes.accordionDetailsSubtitle}>
                    Standard
                  </Typography>
                </Box>
                <Box className={classes.tradesMultipleStatesContainer}>
                  <ChipList
                    isArray={true}
                    items={item?.states}
                    maxItems={3}
                    nameProp={"name"}
                  />
                  <IconButton
                    onClick={(event) => handleClick(event, accordionIndex)}
                    disableRipple
                  >
                    <ThreeDots />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box className={classes.accordionCityTitleContainer}>
                <Box className={classes.tradesOneCityContainer}>
                  <Box className={classes.pinTitleContainer}>
                    <PinIcon />
                    <Typography className={classes.accordionDetailsSubtitle}>
                      Standard
                    </Typography>
                  </Box>
                  <ChipList
                    isArray={true}
                    items={item?.states}
                    maxItems={3}
                    nameProp={"name"}
                  />
                </Box>
                <Box
                  onClick={(event) => handleClick(event, accordionIndex)}
                  disableRipple
                  className={classes.threeDotButtonAccordion}
                >
                  <ThreeDots />
                </Box>
              </Box>
            )}
            <Box className={classes.ratesContainer}>
              <Typography className={classes.ratesText}>
                Hourly Rate:{" "}
                <span className={classes.spanRatesText}>
                  {item.hourly_rate}
                </span>
              </Typography>
              {item.fixed_rate > 0 && (
                <>
                  <Box className={classes.verticalSeparatorRates} />
                  <Typography className={classes.ratesText}>
                    Fixed Rate:{" "}
                    <span className={classes.spanRatesText}>
                      ${item.fixed_rate}
                    </span>
                  </Typography>
                </>
              )}
              {item.sqf_rate > 0 && (
                <>
                  <Box className={classes.verticalSeparatorRates} />
                  <Typography className={classes.ratesText}>
                    Sq Footage Rate:{" "}
                    <span className={classes.spanRatesText}>
                      {item.sqf_rate}
                    </span>
                  </Typography>
                </>
              )}
            </Box>
            <Box className={classes.infoChipsContainer}>
              <ChipList
                isArray={true}
                items={item?.cities}
                maxItems={3}
                nameProp={"city_name"}
              />
            </Box>
          </Box>
          {expanded.includes(accordionIndex) && (
            <TradesVariants tradeId={item.id} />
          )}
        </AccordionDetails>
      </Accordion>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && selectedAccordion === accordionIndex}
        onClose={handleClose}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            disabled={option.disabled}
            onClick={option.action}
          >
            <Box className={classes.menuIcon}>{option.icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <DeleteTradeDialogue
        isDeleteTradeDialogueOpen={isTradeDialogOpen}
        handleDeleteUserDialogueClose={() => setIsTradeDialogOpen(false)}
        handleDeleteUser={() => handleDeleteTrade(item.id)}
      />
    </Fragment>
  );
};
