import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapperDialog: {
    textAlign: "center"
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5
  },
  contentDialog: {
    fontSize: 14
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc"
    }
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    }
  },
  checkbox: {
    color: "#ececec"
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12
    }
  }
}));

export default useStyles;
