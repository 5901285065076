import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import UploadPartnerFile from "components/common/File/uploadPartnerFileButton";
import { TabPanelPartners } from "components/common/File/tabPanelPartners";
import { useUploadFileState } from "contexts/uploadFileContext";
import {
  getPartnersFiles,
  getPartnersFilesZip,
} from "services/partners/filesService";
import { NotepadIcon } from "../icons/NotepadIcon";
import { DownloadCloud } from "../icons/DownloadCloud";
import { logException } from "components/util/logUtil";
import useStyles from "../styles";

const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default function FilesTable({ id }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [files, setFiles] = useState(null);
  const { isUploadFile, uploadFiles } = useUploadFileState();

  const refreshData = async () => {
    try {
      const result = await getPartnersFiles(id);
      setFiles(result.data.data);
    } catch (e) {
      console.log(e);
      logException(e, "Cannot load file data");
    }
  };

  useEffect(() => {
    const loadFileData = async () => {
      setIsLoading(true);
      try {
        const result = await getPartnersFiles(id);
        setFiles(result.data.data);
      } catch (e) {
        logException(e, "Cannot load file data");
      }

      setIsLoading(false);
    };
    if (id) {
      loadFileData();
    }
  }, [id]);

  const handleDownloadAll = async () => {
    setIsLoadingZip(true);
    const result = await getPartnersFilesZip(id);
    const blob = new Blob([result.data], { type: "application/zip" });
    const downloadUrl = window.URL.createObjectURL(blob);
    downloadFile(downloadUrl, `partner_files_${id}.zip`);
    setIsLoadingZip(false);
  };

  if (isLoading || !files) {
    return <LoadingIndicator />;
  }

  if (files?.all?.length === 0 && !isUploadFile) {
    return (
      <Box className={classes.containerUpload} style={{ marginBottom: 90 }}>
        <Box className={classes.noFilesContainer}>
          <NotepadIcon />
          <Box className={classes.noFilesText}>No Documents available yet.</Box>
          <UploadPartnerFile partnerId={id} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.containerUpload} style={{ marginBottom: 90 }}>
      <Box className={classes.tabPanelBoxUpload}>
        <Box>
          <TabPanelPartners
            data={isUploadFile ? uploadFiles : files}
            partnerId={id}
            refreshData={refreshData}
          />
        </Box>

        {!isUploadFile && (
          <Box className={classes.downloadAllUploadFileButtonContainer}>
            <Button
              startIcon={<DownloadCloud fill="#4F98BC" />}
              className={classes.downloadAllButton}
              onClick={handleDownloadAll}
              disabled={isLoadingZip || files.length === 0}
            >
              {isLoadingZip ? "Downloading..." : "Download All"}
            </Button>
            <UploadPartnerFile partnerId={id} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
