import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;
export async function getPartnerContacts(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/contacts`);
}
export async function addNewPartnerContact(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/contacts`,
    data.data
  );
}
export async function deletePartnerContact(data) {
  return await http.delete(`${apiEndpoint}/${data.partner_id}/contacts`, {
    data: data.data,
  });
}
export async function updatePartnerContact(data) {
  return await http.put(
    `${apiEndpoint}/${data.partner_id}/contacts`,
    data.data
  );
}
