export function PinIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="#E8F8CB" />
      <g clipPath="url(#clip0_589_7109)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4083 13.0919C12.5929 12.9072 12.6086 12.6244 12.479 12.3926C12.1176 11.7562 12.2079 10.9352 12.7501 10.3931L14.7142 8.4289L15.1071 8.82174C15.3231 9.0378 15.6767 9.0378 15.8928 8.82174C16.1088 8.60568 16.1088 8.25213 15.8928 8.03607L11.9644 4.1077C11.7483 3.89164 11.3948 3.89164 11.1787 4.1077C10.9627 4.32376 10.9627 4.67731 11.1787 4.89337L11.5715 5.28621L9.60736 7.25039C9.06525 7.79251 8.24422 7.88286 7.60782 7.52145C7.37605 7.39181 7.09321 7.40753 6.90857 7.59216L6.8575 7.64323C6.64144 7.85929 6.64144 8.21284 6.8575 8.4289L8.81383 10.3852L6.07183 13.1429C5.85577 13.359 5.85577 13.7126 6.07183 13.9286C6.28789 14.1447 6.64144 14.1447 6.8575 13.9286L9.59951 11.1709L11.5715 13.1429C11.7876 13.359 12.1412 13.359 12.3572 13.1429L12.4083 13.0919Z"
          fill="#328914"
        />
      </g>
      <defs>
        <clipPath id="clip0_589_7109">
          <rect
            width="13.3333"
            height="13.3333"
            fill="white"
            transform="translate(3.33398 3.3335)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
