import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import * as classNames from "classnames";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useState } from "react";
import { getPositionTitle } from "services/formsService";
import useStyles from "./styles";
import FormsTabFile from "../formsTab/formsTabFile";
import BackIcon from "@material-ui/icons/NavigateBefore";

export default function AutoGrid({
  setOpenWeeklySchedule,
  weeklyScheduleData,
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
  currentStep,
  setCurrentStep,
  totalSteps,
}) {
  const changeRequestOptions = [
    {
      label: "Rehire(Less than 6 months break)",
      value: "Rehire(Less than 6 months break)",
    },
    { label: "Transfer", value: "Transfer" },
    { label: "Promotion", value: "Promotion" },
    { label: "Unpaid Leave", value: "Unpaid Leave" },
    { label: "FMLA (HR Approved)", value: "FMLA (HR Approved)" },
    { label: "Pay Adjustment", value: "Pay Adjustment" },
  ];
  const [selectedHourlyOrMonthly, handleSelectedHourlyOrMonthly] = useState("");
  const [
    selectedHourlyOrMonthlyNew,
    handleSelectedHourlyOrMonthlyNew,
  ] = useState("");
  const selectedHourlyOrMonthlyOptions = [
    { label: "Hourly", value: "Hourly" },
    { label: "Monthly", value: "Monthly" },
  ];
  const [selectedChangeRequest, setSelectedChangeRequest] = useState("");
  const [teamMember, setTeamMember] = useState("");

  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");
  const [jobSelectedNew, setJobSelectedNew] = useState("");
  const [jobTitleOptions, setJobTitleOptions] = useState("");
  const [jobTitleOptionsSelected, setJobTitleOptionsSelected] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const { globalUi } = useContext(GlobalUiContext);

  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });
  const handleChangeCustomer = (event, value) => {
    setCustomerSelected(value);
  };
  useEffect(() => {
    getPositionTitle().then((res) => {
      let transformed = [];
      const response = res?.data?.data?.open_positions_titles?.map((v) => {
        transformed.push({ label: v?.name, value: v?.number });
      });
      setJobTitleOptions(transformed);
    });
  }, []);

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, [customerSelected?.value]);
  const classes = useStyles();
  const [form, setForm] = useState({ new_rate: "", current_rate: "" });

  return (
    <Box className={classes.root}>
      <Grid
        container
        onChange={(e) => {
          if (
            e.target.name === "new_rate" ||
            e.target.name === "current_rate"
          ) {
            if (
              (e.target.value >= 0 &&
                Number(e.target.value) &&
                Number(e.target.value) >= 0) ||
              e.target.value.trim() === "" ||
              e.target.value === "." ||
              e.target.value === "0" ||
              e.target.value === "0."
            ) {
              setForm({ ...form, [e.target.name]: e.target.value });
              return;
            }
            return;
          }
          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={classes.Box}
          style={{
            overflow: "auto !important",
          }}
        >
          <Box className={classes.padding23}>
            <span style={{ display: currentStep === 1 ? "block" : "none" }}>
              <Typography className={classes.subTitle}>
                Team Member(*)
              </Typography>

              <FormSelectAuto
                options={userOptions}
                name="user"
                handleChange={(e, v) => setTeamMember(v)}
                value={teamMember}
                inputContainerProps={classes.inputContainer}
              />

              <Typography className={classes.subTitle}>Job Name(*)</Typography>

              <span>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    options={jobs}
                    name="job_number"
                    handleChange={(e, v) => setJobSelected(v)}
                    value={jobSelected}
                    inputContainerProps={classes.inputContainer}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </span>

              <Box className={classes.inputContainerDateAdj}>
                <Typography className={classes.subTitle}>
                  Effective date(*)
                </Typography>

                <FormInputDateAdo
                  name="effective_date"
                  value={form?.effective_date}
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      effective_date: e,
                    });
                  }}
                />
              </Box>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Change Requested(*)
                </Typography>

                <FormSelectAuto
                  options={changeRequestOptions}
                  name="user"
                  handleChange={(e, v) => setSelectedChangeRequest(v)}
                  value={selectedChangeRequest}
                  inputContainerProps={classes.inputContainer}
                />
              </Grid>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Explaination for change(*)
                </Typography>
                <TextField
                  name="explaination_for_change"
                  multiline
                  rows={4}
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </span>
            <span style={{ display: currentStep === 2 ? "block" : "none" }}>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Attach Files (Optional)
                </Typography>

                <FormsTabFile setUploadedFile={setUploadedFile} />
              </Grid>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Change Work Schedule{" "}
                </Typography>
                <TextField
                  name="email"
                  className={classes.field}
                  onClick={() => setOpenWeeklySchedule(true)}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRangeIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  value={weeklyScheduleData ? "Updated" : "Please Set"}
                />
              </Grid>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Current Rate
                </Typography>
                <TextField
                  name="current_rate"
                  value={form.current_rate}
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Current Type
                </Typography>
                <FormSelectAuto
                  options={selectedHourlyOrMonthlyOptions}
                  handleChange={(e, v) => handleSelectedHourlyOrMonthly(v)}
                  value={selectedHourlyOrMonthly}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>New Rate</Typography>
                <TextField
                  name="new_rate"
                  value={form.new_rate}
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>New Type</Typography>
                <FormSelectAuto
                  options={selectedHourlyOrMonthlyOptions}
                  handleChange={(e, v) => handleSelectedHourlyOrMonthlyNew(v)}
                  value={selectedHourlyOrMonthlyNew}
                />
              </Grid>
            </span>
            <span style={{ display: currentStep === 3 ? "block" : "none" }}>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Change in Job Title
                </Typography>
                <TextField
                  name="change_in_job_title"
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Change in Position Job Code
                </Typography>

                {Boolean(jobTitleOptions.length) ? (
                  <FormSelectAuto
                    options={jobTitleOptions}
                    handleChange={(e, v) => setJobTitleOptionsSelected(v)}
                    value={jobTitleOptionsSelected}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  New Account
                </Typography>

                <span>
                  {Boolean(jobs) ? (
                    <FormSelectJobsAuto
                      options={jobs}
                      name="job_number_new"
                      handleChange={(e, v) => setJobSelectedNew(v)}
                      value={jobSelectedNew}
                      inputContainerProps={classes.inputContainer}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </span>
              </Grid>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Describe any additional changes needed(technology, training,
                  other)
                </Typography>
                <TextField
                  name="additional_changes_needed"
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </span>
            <Box className={classes.trContainer}>
              <Box className={classes.trSubContainer}>
                <Box className={classes.flex}>
                  {currentStep === 1 ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className={classes.buttonBack}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                      startIcon={<BackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                    disabled={
                      !jobSelected?.id ||
                      !form?.effective_date ||
                      !selectedChangeRequest?.value ||
                      !teamMember?.value ||
                      !form?.explaination_for_change
                    }
                    onClick={() => {
                      if (currentStep === totalSteps) {
                        let templatePayload = {
                          job_id: jobSelected.id,
                          new_job_id: jobSelectedNew.id,
                          user_id: teamMember.value,
                          effective_date: form.effective_date,
                          change_request: selectedChangeRequest.value,
                          change_explanation: form.explaination_for_change,
                          file: uploadedFile[0],
                          schedule: JSON.stringify(weeklyScheduleData),
                          current_rate: form.current_rate,
                          current_rate_type: selectedHourlyOrMonthly.value,
                          new_rate: form.new_rate,
                          new_rate_type: selectedHourlyOrMonthlyNew.value,
                          change_position_title: form.change_in_job_title,
                          change_position_job_code:
                            jobTitleOptionsSelected.value,
                          additional_changes: form.additional_changes_needed,
                        };
                        Object.keys(templatePayload).map((v) => {
                          if (!templatePayload[v]) {
                            templatePayload[v] = "";
                          }
                        });
                        submitFormDataBasedOnCurrentTab(
                          templatePayload,
                          topTab,
                          formSelected
                        ).then((res) => {
                          if (res.status === 201) {
                            closeAndRefreshSidebar();
                          }
                        });
                      }
                      if (currentStep < totalSteps) {
                        setCurrentStep(++currentStep);
                      }
                    }}
                  >
                    {" "}
                    {currentStep === totalSteps ? "Submit" : "Next"}
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}
