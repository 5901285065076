import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "components/ui/core/button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import { DialogMessage } from "components/ui/core/dialogMessage";
import AlertIcon from "@material-ui/icons/NewReleases";
import {
  financialPaymentExport,
  financialPaymentDownload,
} from "services/financialService";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useFilterState, getFilterData } from "contexts/filterContext";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
} from "contexts/billingApContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { logException } from "components/util/logUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: 20,
    "& > button": {
      marginLeft: 20,
      marginRight: 20,
    },
  },
}));

const AccountPayablesBulkActions = () => {
  const classes = useStyles();
  const filterState = useFilterState();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { selected, rows, currentTab } = tableUi;
  const { billingCount } = useBillingState();
  const dispatchBilling = useBillingDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleExportConfirm = async () => {
    try {
      setIsLoading(true);
      const paymentsIds = rows
        .filter((row) => selected.includes(row.id))
        .map((row) => row.id);
      const result = await financialPaymentExport({ payment_ids: paymentsIds });
      window.open(result.data.data.file.presigned_url);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      const filterData = getFilterData(filterState);
      await getBillingList(dispatchBilling, filterData);
      setOpenConfirm(false);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot export file");
    }
  };

  const handleExport = () => {
    setOpenConfirm(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const paymentsIds = rows
        .filter((row) => selected.includes(row.id))
        .map((row) => row.id);

      const result = await financialPaymentDownload({
        payment_ids: paymentsIds,
      });
      window.open(result.data.data.file.presigned_url);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });

      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot download file");
    }
  };

  return (
    <Box className={classes.root}>
      {["0", "1"].includes(currentTab) ? (
        <ButtonBase
          color="secondary"
          onClick={handleDownload}
          disabled={isLoading}
          variant="outlined"
        >
          {isLoading ? `Loading...` : `Download`}
        </ButtonBase>
      ) : null}
      {currentTab === "1" ? (
        <ButtonBase
          color="secondary"
          onClick={handleExport}
          disabled={isLoading}
        >
          {isLoading ? `Loading...` : `Export & Mark`}
        </ButtonBase>
      ) : null}

      <Dialog
        open={openConfirm}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoading ? (
            <DialogCloseIcon handleClose={handleConfirmClose} />
          ) : null}

          <DialogTitle
            title="Financial"
            icon={<AlertIcon fontSize="large" />}
          />
          {!isLoading ? (
            <>
              <DialogMessage align="center">
                You are about to export {selected.length} of{" "}
                {billingCount["1"] ?? null} records ready.
              </DialogMessage>
              <DialogMessage align="center">
                <strong>This cannot be undone!</strong>
              </DialogMessage>
            </>
          ) : (
            <>
              <DialogMessage align="center">
                <strong>Export in progress...</strong>
              </DialogMessage>
              <DialogMessage align="center">
                Please wait until the export is completed. Don't close or
                refresh the page.
              </DialogMessage>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          {!isLoading ? (
            <>
              <ButtonBase
                variant="outlined"
                color="secondary"
                onClick={handleConfirmClose}
              >
                Cancel
              </ButtonBase>
              <ButtonBase
                color="secondary"
                variant="contained"
                onClick={handleExportConfirm}
              >
                Okay
              </ButtonBase>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountPayablesBulkActions;
