import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "../../components/common/PageHeader/pageHeader";

import { useQuoteState } from "../../contexts/quoteContext";

import MaintenanceAlert from "components/common/maintenanceAlert";

const QuoteBreadcrum = (props) => {
  const stateContext = useQuoteState();
  const { selectedQuote, isLoading, quoteDetails } = stateContext
    ? stateContext
    : null;

  if ((isLoading && selectedQuote !== "new") || !selectedQuote) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  if (props.pendingPlanning) {
    return (
      <PageHeader pageTitle="Quote Creation">
        <Link key="1" to="/quotes">
          Quotes
        </Link>
        <Link key="2" to={`/quote/${selectedQuote}`}>
          {quoteDetails.number ? quoteDetails.number : `QUO-${selectedQuote}`}
        </Link>
      </PageHeader>
    );
  }

  if (!isLoading && selectedQuote !== "new") {
    return (
      <>
        <PageHeader
          pageTitle={
            quoteDetails.number ? quoteDetails.number : `QUO-${selectedQuote}`
          }
        >
          <Link to="/quotes">Quotes</Link>
        </PageHeader>
        <MaintenanceAlert spacing />
      </>
    );
  } else {
    return (
      <>
        <PageHeader pageTitle={`New`}>
          <Link to="/quotes">Quotes</Link>
        </PageHeader>
        <MaintenanceAlert spacing />
      </>
    );
  }
};

export default QuoteBreadcrum;
