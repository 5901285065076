import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { projectHelpOptions } from "constants.js";

const ProjectDescriptionHelp = (props) => {
  const classes = useStyles();
  const { projectType } = props;

  return (
    <Box className={classes.containerTabs}>
      <List dense={true}>
        {projectHelpOptions
          .filter((option) => option.required.includes(projectType))
          .map((option, index) => {
            return (
              <ListItem key={index}>
                <ListItemIcon
                  className={
                    option.required.includes(projectType)
                      ? classes.checkListIconAvailable
                      : option.optional.includes(projectType)
                      ? classes.checkListIconOptional
                      : classes.checkListIconNotAvailable
                  }
                >
                  {option.required.includes(projectType) ? (
                    <Tooltip title="Required">
                      <CheckIcon />
                    </Tooltip>
                  ) : option.optional.includes(projectType) ? (
                    <Tooltip title="Optional">
                      <CallSplitIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Not Available">
                      <CloseIcon />
                    </Tooltip>
                  )}
                </ListItemIcon>
                <ListItemText
                  className={
                    option.required.includes(projectType)
                      ? classes.checkListIconAvailableText
                      : option.optional.includes(projectType)
                      ? classes.checkListIconOptionalText
                      : classes.checkListIconNotAvailableText
                  }
                  primary={option.text}
                />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default ProjectDescriptionHelp;
