import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";

import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import useStyles from "components/common/Filters/styles";

const RecognitionFilters = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [submittedByFilters, setSubmittedByFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const usersList = useMemo(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return [{ id: user.id, first_name: "Me", last_name: "" }, ...users];
  }, [users]);

  useEffect(() => {
    if (anchorEl) {
      if (users.length) {
        const selectedSubmittedByFilter = filters.find(
          (filter) => filter.filter === "submittedBy"
        );
        if (selectedSubmittedByFilter) {
          const selectedValue = selectedSubmittedByFilter.values.map(
            (assigned) => assigned.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setSubmittedByFilters(findUsers);
        } else {
          setSubmittedByFilters([]);
        }
      }
    }
  }, [anchorEl, filters, users]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (submittedByFilters.length) {
      const submittedByFilterConvert = submittedByFilters.map((assigned) => {
        return {
          label: `${assigned.first_name} ${assigned.last_name}`,
          value: assigned.id,
        };
      });
      filterData.push({
        filter: "submittedBy",
        group: "Submitted By",
        values: [...submittedByFilterConvert],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeSubmittedBy = (event, value) => {
    if (value) {
      setSubmittedByFilters(value);
    } else {
      setSubmittedByFilters([]);
    }
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Submitted By
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={usersList}
                    name="assigned"
                    handleChange={handleChangeSubmittedBy}
                    multiple={true}
                    value={submittedByFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default RecognitionFilters;
