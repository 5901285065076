import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillDispatch,
  surveyResume,
  useSurveyFillState,
} from "contexts/surveyFillContext";
import {
  useDraftsState,
  useDraftsDispatch,
  getDraftsList,
} from "contexts/surveyDraftsContext";
import { useFilterState, getFilterData } from "contexts/filterContext";

const DeleteDialog = (props) => {
  const { openResume, draftsSelected } = useDraftsState();
  const dispatchDrafts = useDraftsDispatch();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const { openShowSurvey } = useSurveyFillState();
  const filterState = useFilterState();

  useEffect(() => {
    const loadSurvey = async () => {
      await surveyResume(dispatchSurveyFill, draftsSelected.id);
      dispatchDrafts({
        type: "TOGGLE_OPEN_RESUME",
        openResume: false,
      });
    };
    if (openResume) {
      loadSurvey();
    }
  }, [openResume]);

  useEffect(() => {
    const loadDrafts = async () => {
      const filterData = getFilterData(filterState);
      await getDraftsList(dispatchDrafts, filterData);
    };
    if (!openShowSurvey) {
      loadDrafts();
    }
  }, [openShowSurvey]);

  return (
    <Dialog
      open={openResume}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <LoadingIndicator />
    </Dialog>
  );
};
export default DeleteDialog;
