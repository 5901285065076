import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const GeographicalCoverageContext = React.createContext();
const initialState = {
  isDrawerOpen: false,
  isEditing: false,
  isAdding: false,
  locationToEdit: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        isDrawerOpen: action.open,
      };
    case "TOGGLE_EDIT":
      return {
        ...state,
        isDrawerOpen: action.open,
        isEditing: action.isEditing,
        locationToEdit: action.location,
      };
    case "TOGGLE_ADD":
      return {
        ...state,
        isDrawerOpen: action.open,
        isAdding: action.isAdding,
        isEditing: false,
        locationToEdit: null,
      };
    default:
      return state;
  }
};
const GeographicalCoverageProvider = ({ children }) => (
  <GeographicalCoverageContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </GeographicalCoverageContext.Provider>
);
const useGeographicalCoverage = () => {
  const context = React.useContext(GeographicalCoverageContext);
  if (context === undefined) {
    throw new Error(
      "useGeographicalCoverage must be used within a GeographicalCoverageProvider"
    );
  }
  return context;
};
export { GeographicalCoverageProvider, useGeographicalCoverage };
