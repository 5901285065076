import React, { useState } from "react";
import { ButtonBase } from "components/ui/core/button";
import MessageDialog from "components/ui/dialog/messageDialog";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useFilterState, getFilterData } from "contexts/filterContext";
import {
  useTimekeepingDispatch,
  getTimekeepingList,
} from "contexts/timekeepingContext";
import {
  timekeepingExport,
  timekeepingExportAll,
} from "services/timekeepingService";

import { logException } from "components/util/logUtil";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const TimekeepingsBulkActions = () => {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatchTimekeeping = useTimekeepingDispatch();
  const filterState = useFilterState();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { selected } = tableUi;

  const handleExport = async () => {
    try {
      setIsLoading(true);
      await timekeepingExport({ entry_ids: selected });
      dispatchTable({ type: "SET_SELECTED", selected: [] });
      setConfirmDialog(true);
      setIsLoading(false);
      // window.open(result.data.data.file.presigned_url);
    } catch (e) {
      logException(e, "Cannot export timekeeping entries");
    }
  };

  const handleExportAll = async () => {
    try {
      setIsLoading(true);
      await timekeepingExportAll();
      setConfirmDialog(true);
      setIsLoading(false);
      // window.open(result.data.data.file.presigned_url);
    } catch (e) {
      logException(e, "Cannot export timekeeping file");
    }
  };

  const handleCloseConfirm = async () => {
    setConfirmDialog(false);
    const filterData = getFilterData(filterState);
    await getTimekeepingList(dispatchTimekeeping, filterData);
    dispatchTable({
      type: "SET_SELECTED",
      selected: [],
    });
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        color="secondary"
        variant="outlined"
        onClick={handleExportAll}
        disabled={isLoading}
      >
        Synch All
      </ButtonBase>
      <ButtonBase color="secondary" onClick={handleExport} disabled={isLoading}>
        Synch Selected
      </ButtonBase>
      <MessageDialog
        open={confirmDialog}
        title="Synch in progress"
        message="Timekeeping records have been sent to ADP - This Process will be completed in approximately 5 minutes. You will get an email notification when the synch process is completed."
        handleClose={handleCloseConfirm}
      />
    </div>
  );
};

export default TimekeepingsBulkActions;
