import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import FieldContainerFilter from "components/common/Filters/fieldContainerFilter";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import { logException } from "components/util/logUtil";
import useStyles from "components/common/Filters/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import {
  getFilterIndustries,
  getFilterManagers,
  getFilterDirectors,
} from "components/util/filterUtil";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import { customerAlertsFilters } from "constants.js";

const JobsFilters = (props) => {
  const classes = useStyles();
  const [alertFilters, setAlertFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    let valuesAlert = [];
    let values = [];
    alertFilters.map((item) => {
      const foundOption = customerAlertsFilters.find(
        (option) => option.value === item
      );
      if (foundOption && item !== -1) {
        values.push(foundOption.value);
        valuesAlert.push(foundOption);
      }
      return true;
    });

    if (valuesAlert.length) {
      if (values.includes("manager_alert")) {
        filterData.push({
          filter: "manager_alert",
          group: "Alert",
          values: [valuesAlert.find((a) => a.value === "manager_alert")],
        });
      }
      if (values.includes("director_alert")) {
        filterData.push({
          filter: "director_alert",
          group: "Alert",
          values: [valuesAlert.find((a) => a.value === "director_alert")],
        });
      }
    }
    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  useEffect(() => {
    const foundManager = filters.find(
      (element) => element.filter === "manager_alert"
    );
    const foundDirector = filters.find(
      (element) => element.filter === "director_alert"
    );
    let resultFilter = [];

    if (foundManager || foundDirector) {
      if (foundManager) {
        const selectedItem = foundManager.values.map((item) => item.value);
        if (selectedItem.length) {
          resultFilter.push(selectedItem[0]);
        }
      }
      if (foundDirector) {
        const selectedItem = foundDirector.values.map((item) => item.value);
        if (selectedItem.length) {
          resultFilter.push(selectedItem[0]);
        }
      }
      setAlertFilters([...resultFilter]);
    } else {
      setAlertFilters([-1]);
    }
  }, [filters]);

  const handleChangeAlertFilter = (e, option) => {
    if (e.target.checked) {
      if (option === -1) {
        setAlertFilters([-1]);
      } else {
        setAlertFilters([option]);
      }
    } else {
      setAlertFilters([-1]);
    }
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Alerts
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                {customerAlertsFilters.map((option) => {
                  return (
                    <Grid item xs={12} md={12} key={option.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={alertFilters.includes(option.value)}
                            onChange={(e) =>
                              handleChangeAlertFilter(e, option.value)
                            }
                            className={classes.filterCheckbox}
                          />
                        }
                        label={option.label}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default JobsFilters;
