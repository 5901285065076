import http from "./httpService";
import { apiUrl } from "../lib/config";
import { setFormData } from "../components/util/serviceUtil";
const apiEndpoint = apiUrl + "/users";
const apiEndpointCustomers = apiUrl + "/customers";
const apiLaborFilter = apiUrl + "/filters/labor-report";

export function getUserSchedules(id) {
  return http.get(`${apiEndpoint}/${id}/schedules/active`);
}

export function getUserSchedulesAll(id) {
  return http.get(`${apiEndpoint}/${id}/schedules/active/all`);
}

export function getUserJobSchedules(id, job_number) {
  return http.get(`${apiEndpoint}/${id}/schedules/job/${job_number}/active`);
}

export function getUsersList(options, operator, show) {
  return http.get(
    `${apiEndpoint}/dropdown-list?${options ? options : ``}${
      operator ? operator : ``
    }${show ? show : ``}`
  );
}

export function getRoleTierUsersList(tier) {
  return http.get(
    `${apiEndpoint}/dropdown-list/roles/?tier_classification=${tier}`
  );
}

export function getJobTierUsersList(tier) {
  return http.get(`${apiEndpoint}/dropdown-list/job-tier/?tier=${tier}`);
}

export function getUser(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getPreferences() {
  return http.get(`${apiEndpoint}/preferences`);
}

export function getUserDisablePreferences() {
  return http.get(`${apiEndpoint}/user-disable-preferences`);
}

export function saveUserDisablePreferences(data) {
  return http.post(`${apiEndpoint}/user-disable-preferences`, data);
}

export function deleteUserDisablePreferences(id) {
  return http.delete(`${apiEndpoint}/user-disable-preferences/${id}`);
}

export function getCustomers() {
  return http.get(`${apiEndpointCustomers}`);
}

export function getCustomersLabor() {
  return http.get(`${apiLaborFilter}`);
}

export function getZones() {
  return http.get(`${apiEndpoint}/job-zones`);
}

export function updatePassword(data) {
  return http.post(`${apiUrl}/user/change-password`, data);
}

export function updateProfilePicture(data) {
  http.setMultiPart();
  return http.post(`${apiUrl}/user/profile-picture`, setFormData(data));
}

export function resetPassword(data) {
  return http.post(`${apiUrl}/user/reset-password`, data);
}

export function getUserPermissions() {
  return http.get(`${apiUrl}/user/permissions`);
}

export function getUserSurvey(id, survey) {
  return http.get(`${apiEndpoint}/${id}/survey/${survey}`);
}

export function saveUserSurvey(id, data) {
  http.setFormEncode();
  return http.post(`${apiEndpoint}/${id}/survey`, setFormData(data));
}

export function reportsToList(id, department) {
  return http.get(
    `${apiEndpoint}/reports-to/list/${id}?department=${department}`,
    {}
  );
}

export function getUserMeta() {
  return http.get(`${apiEndpoint}/user-meta`);
}
