import React, { useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import { assignUserWorkticket } from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketState } from "contexts/workticketContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableDispatch } from "contexts/tableContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const AssignDialog = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [assignedTo, setAssignedTo] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const { workticketSelected } = useWorkticketState();
  const dispatchTable = useTableDispatch();
  const { openAssign } = workticketAction;

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_ASSIGN",
      open: false,
    });
    setErrorMsg(null);
  };

  const handleConfirm = async () => {
    const assignedUser = assignedTo.map((item) => {
      return item.id;
    });

    if (!assignedUser.length) {
      setError([
        {
          key: "users",
          message: "Please select at least one user to assign.",
        },
      ]);
      return;
    }
    try {
      setIsLoadingDialog(true);
      for (const user of assignedUser) {
        await assignUserWorkticket(workticketSelected.id, {
          user_id: user,
        });
      }

      props.refetchData();
      dispatchTable({
        type: "SET_UPDATED_ROWS",
        rowsUpdated: [workticketSelected.id],
      });
      setAssignedTo([]);
      setIsLoadingDialog(false);
      handleClose();
      setOpenSuccess(true);
      setErrorMsg(null);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setIsLoadingDialog(false);
        setAssignedTo([]);
        setErrorMsg(e.response.data.message);
      }
      logException(e, "Cannot assign users");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    setAssignedTo(value);
    setError([]);
  };

  return (
    <>
      <Dialog
        open={openAssign}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              {errorMsg ? (
                <>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.contentDialog}
                  >
                    {errorMsg}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.titleDialog}
                  >
                    Worktickets
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.contentDialog}
                  >
                    Assign workticket?
                  </Typography>
                  <Box className={classes.formContainer}>
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users}
                      name="users"
                      label=""
                      handleChange={handleChangeAssignedTo}
                      value={assignedTo}
                      multiple={true}
                      error={error}
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              {!errorMsg ? (
                <>
                  <Button
                    onClick={handleClose}
                    className={classes.buttonOutlined}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="large"
                  color="secondary"
                  className={classes.buttonOutlined}
                  style={{ marginRight: 0 }}
                >
                  Okay
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket has been assigned.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default AssignDialog;
