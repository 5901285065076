import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import WorkticketLayout from "./workticketLayout";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";

import { WorkticketViewProvider } from "contexts/workticketViewContext";

const Workticket = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  useEffect(() => {
    if (hasPermission(permissionSurface.WORKTICKET, permissions)) {
      props.history.push(`/workticket/surface/${props.match.params.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <WorkticketViewProvider>
      <WorkticketLayout
        id={props.match.params.id}
        id_project={props.match.params.id_project}
      />
    </WorkticketViewProvider>
  );
};

export default withRouter(Workticket);
