import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import Skeleton from "@material-ui/lab/Skeleton";
import { getQuotePdf, saveQuoteFollow } from "services/quoteSurfaceService";
import { useQuoteState } from "contexts/quoteSurfaceContext";
import { logException } from "components/util/logUtil";
import MessageDialog from "./dialog/messageDialog";

import useStyles from "./styles";

const QuoteSurfaceActions = props => {
  const classes = useStyles();
  const stateContext = useQuoteState();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [isFollow, setIsFollow] = useState(false);

  const { quoteDetails, isLoading } = stateContext ? stateContext : null;

  const handleOpenPdf = async () => {
    setLoadingPdf(true);
    const result = await getQuotePdf(quoteDetails.id);
    const file = new Blob([result.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    setLoadingPdf(false);
  };

  useEffect(() => {
    if (quoteDetails && quoteDetails.id && loadingInitial) {
      const findFollow = quoteDetails.followers.find(
        userFollow => userFollow.user_id === user.id
      );
      if (findFollow) {
        setIsFollow(true);
      } else {
        setIsFollow(false);
      }
      setLoadingInitial(false);
    }
  }, [quoteDetails, user, loadingInitial]);

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleFollow = async () => {
    try {
      setLoadingFollow(true);
      const data = {
        quotes_ids: [quoteDetails.id],
        is_unfollow: isFollow ? 1 : 0
      };
      await saveQuoteFollow(data);
      setLoadingFollow(false);
      setIsFollow(isFollow ? false : true);
    } catch (e) {
      logException(e, "Cannot follow workticket");
    }
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return (
    <Box className={classes.containerActionButtons}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={true}
            onClick={handleFollow}
            className={classNames(classes.button, classes.buttonOutlined)}
            disabled={loadingFollow}
          >
            {isFollow ? `Unfollow` : `Follow`}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth={true}
            startIcon={<PdfIcon />}
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleOpenPdf}
            disabled={loadingPdf}
          >
            Print PDF
          </Button>
        </Grid>
      </Grid>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={"Quote has been sent successfully."}
      />
    </Box>
  );
};

export default QuoteSurfaceActions;
