import React from "react";
import * as quoteService from "../services/quoteSurfaceService";
import { merge } from "lodash";

const defaultState = {
  quoteDetails: [],
  quoteLoading: true,
  isLoading: true,
  uniqueJobs: [],
  worktickets: [],
  activities: [],
  selectedQuote: null,
  selectedQuoteItem: { position: -1, is_editable: true, is_deletable: true },
  originalQuoteItem: {},
  permissions: []
};

const QuoteStateContext = React.createContext();
const QuoteDispatchContext = React.createContext();

function quoteReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading
      };
    case "SET_QUOTE_DETAILS":
      return {
        ...state,
        quoteDetails: action.quoteDetails
      };
    case "SET_SELECTED_QUOTE":
      return {
        ...state,
        selectedQuote: action.selectedQuote
      };
    case "SET_SELECTED_QUOTE_ITEM":
      return {
        ...state,
        selectedQuoteItem: action.selectedQuoteItem
      };
    case "SET_ORIGINAL_QUOTE_ITEM":
      return {
        ...state,
        originalQuoteItem: action.originalQuoteItem
      };
    case "SET_WORKTICKETS":
      return {
        ...state,
        worktickets: action.worktickets
      };
    case "SET_ACTIVITIES":
      return {
        ...state,
        activities: action.activities
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  console.log(initialState);
  const [state, dispatch] = React.useReducer(quoteReducer, mergedState);
  return (
    <QuoteStateContext.Provider value={state}>
      <QuoteDispatchContext.Provider value={dispatch}>
        {" "}
        {children}{" "}
      </QuoteDispatchContext.Provider>{" "}
    </QuoteStateContext.Provider>
  );
}

function useQuoteState() {
  const context = React.useContext(QuoteStateContext);
  if (context === undefined) {
    throw new Error("useQuoteState must be used within a QuoteProvider");
  }
  return context;
}

function useQuoteDispatch() {
  const context = React.useContext(QuoteDispatchContext);
  if (context === undefined) {
    throw new Error("useQuoteDispatch must be used within a QuoteProvider");
  }
  return context;
}

async function getQuote(dispatch, id) {
  try {
    const quoteDetailsResult = await quoteService.getQuoteDetails(id);
    if (!quoteDetailsResult.data.data.quote.items) {
      quoteDetailsResult.data.data.quote.items = [];
    } else {
      quoteDetailsResult.data.data.quote.items = JSON.parse(
        quoteDetailsResult.data.data.quote.items
      );
    }
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote
    });
    dispatch({
      type: "SET_ACTIVITIES",
      activities: quoteDetailsResult.data.data.quote.activity
    });
  } catch (error) {
    console.log(error);
  }
}

export { QuoteProvider, useQuoteState, useQuoteDispatch, getQuote };
