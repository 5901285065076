import React from "react";
import Typography from "@material-ui/core/Typography";

const WorkticketPriority = (props) => {
  const { priority } = props;

  if (!priority) {
    return "Error priority";
  }
  return (
    <>
      {priority === 1 && <Typography variant="body2">Low</Typography>}
      {priority === 2 && <Typography variant="body2">Medium</Typography>}
      {priority === 3 && <Typography variant="body2">High</Typography>}
    </>
  );
};

export default WorkticketPriority;
