import React, { lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import { ProjectProvider } from "contexts/projectContext";
import { ProjectActionProvider } from "contexts/projectActionContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const ProjectsTable = lazy(() => import("./projectsTable"));

const ProjectsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Projects" link="/projects" />
      <Box className={classes.pageBodyWrapper}>
        <ProjectProvider>
          <ProjectActionProvider>
            <Suspense
              fallback={
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              }
            >
              <ProjectsTable />
            </Suspense>
          </ProjectActionProvider>
        </ProjectProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(ProjectsList);
