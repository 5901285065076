import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import RecognitionFilters from "./recognitionFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import RecognitionTableActions from "./recognitionTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { useIndividualView } from "contexts/individualViewContext";
import { ButtonBase } from "components/ui/core/button";
import useStyles from "./styles";

import { useTableDispatch } from "contexts/tableContext";
import {
  useRecognitionState,
  useRecognitionDispatch,
  getRecognitionList,
  getRecognitionListDataSet,
  searchRecognitionList,
} from "contexts/recognitionContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import {
  useRingBellState,
  useRingBellDispatch,
} from "contexts/ringBellContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "submitted_for",
    label: "Submitted For",
    path: "submitted_for",
  },
  {
    id: "submitted_date",
    label: "Date Submitted",
    path: "submitted_date",
    format: "date",
  },
  {
    id: "submitted_by",
    label: "Submitted By",
    path: "submitted_by",
  },
  {
    id: "note",
    label: "Content",
    path: "note",
  },
  {
    id: "actions",
    content: (row) => <RecognitionTableActions row={row} />,
    contentMobile: (row) => <RecognitionTableActions row={row} mobile={true} />,
    style: { width: 55 },
  },
];

const RecognitionTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;
  const dispatchRingBell = useRingBellDispatch();

  const { recognitionListLoading, recognitionList, recognitionCount } =
    useRecognitionState();
  const { updateRecognitions } = useRingBellState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchRecognition = useRecognitionDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getRecognitionList(dispatchRecognition, filterData);
      if (updateRecognitions) {
        dispatchRingBell({
          type: "TOGGLE_UPDATE_RECOGNITIONS",
          updateRecognitions: false,
        });
      }
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchRecognition, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getRecognitionList(dispatchRecognition, filterData);
      dispatchRingBell({
        type: "TOGGLE_UPDATE_RECOGNITIONS",
        updateRecognitions: false,
      });
    };
    if (updateRecognitions) {
      fetchData();
    }
  }, [updateRecognitions]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: recognitionList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, recognitionList]);

  const handleScrollClick = useCallback(async () => {
    if (recognitionCount <= recognitionList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getRecognitionListDataSet(
        recognitionList,
        dispatchRecognition,
        recognitionList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: recognitionList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    recognitionList,
    recognitionCount,
    filterState,
    dispatchRecognition,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = [
            ...filterData.filters,
            {
              filter: "submittedFor",
              group: "Submitted For",
              values: [{ label: "", value: individual.id }],
            },
          ];
          const results = await searchRecognitionList(query, filterData);

          dispatchRecognition({
            type: "SET_RECOGNITION_LIST",
            recognitionList: results,
          });

          dispatchRecognition({
            type: "SET_RECOGNITION_COUNT",
            recognitionCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterRecognition: [],
    });
  };

  const handleRingTheBell = (event) => {
    dispatchRingBell({
      type: "SET_RINGBELL_SELECTED",
      ringBellSelected: null,
    });
    dispatchRingBell({
      type: "SET_RINGBELL_USERID",
      ringBellUserId: individual.id,
    });
    dispatchRingBell({
      type: "TOGGLE_RINGBELL_SINGLE",
      openRingBellSingle: true,
    });
  };

  return (
    <Box className={classes.root}>
      {recognitionListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${recognitionList.length} / ${recognitionCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        <Box className={classes.listActionContainer}>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            onClick={handleRingTheBell}
                          >
                            Ring the Bell
                          </ButtonBase>
                        </Box>
                        <CalendarFilters />
                        <RecognitionFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(RecognitionTable);
