export function LunaLogo() {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1" clipPath="url(#clip0_1723_11231)">
        <g id="Group">
          <path
            id="Vector"
            d="M13.3734 21.4599C13.3664 21.6586 13.3442 21.8559 13.3033 22.0508C13.1502 22.7799 12.6979 23.393 12.0657 23.7298C11.7314 23.9074 11.3586 24 10.9846 24C9.94329 24 8.96275 23.2524 8.68109 22.1841C8.5724 21.7696 8.59578 21.3391 8.53968 20.9184C8.49527 20.589 8.42982 20.2621 8.34334 19.9426C8.1727 19.3172 7.92026 18.7164 7.59536 18.1625C6.95258 17.067 6.0293 16.1628 4.94474 15.5707C4.55439 15.3572 4.14535 15.1845 3.72344 15.0562C3.50607 14.9896 3.28518 14.9353 3.06313 14.8922C2.85627 14.8527 2.59448 14.87 2.39813 14.7997C2.39229 14.7972 2.38411 14.7972 2.37827 14.7972H2.37242C2.27893 14.7885 2.18426 14.7824 2.09076 14.7787C0.91271 14.6319 0.00228882 13.5747 0.00228882 12.2942C0.00228882 11.0137 0.964133 9.89973 2.18894 9.7998C2.41216 9.80597 2.63187 9.82078 2.85159 9.84298C5.16329 10.0626 7.28215 10.9989 9.00248 12.441C10.0765 13.3415 10.9986 14.4394 11.7127 15.6755C12.4432 16.94 12.9539 18.3451 13.2087 19.802C13.3033 20.3435 13.3956 20.9061 13.3758 21.4612L13.3734 21.4599Z"
            fill="url(#paint0_linear_1723_11231)"
          />
          <path
            id="Vector_2"
            d="M12.7834 7.09614C12.7191 7.28241 12.6478 7.46622 12.5718 7.65003C12.5227 7.77462 12.4702 7.89799 12.4152 8.02011C11.6076 9.82983 10.4202 11.3225 9.00027 12.4402C7.27994 10.9981 5.16224 10.0617 2.84938 9.84217C2.74536 9.8323 2.64018 9.82119 2.53616 9.81503C3.49567 9.87177 4.61295 9.77062 5.45559 9.25126C6.6091 8.5407 7.55108 7.18249 8.21023 5.98588C8.58655 5.30246 8.8273 4.6141 9.5519 4.25142C10.1561 3.94918 10.8795 3.92574 11.5317 4.25142C12.5625 4.76213 13.0697 5.97231 12.7834 7.0949V7.09614Z"
            fill="url(#paint1_linear_1723_11231)"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_3"
            d="M13.627 2.66091C13.6352 2.45366 13.6574 2.24641 13.7007 2.04163C13.8608 1.27679 14.3341 0.635312 14.9968 0.282498C15.3474 0.096222 15.7365 0 16.1292 0C17.2196 0 18.2481 0.783346 18.5426 1.90224C18.6571 2.33647 18.6326 2.78797 18.691 3.22837C18.7366 3.57379 18.8056 3.9155 18.8967 4.25104C19.0755 4.90732 19.3397 5.53647 19.6809 6.11627C20.3541 7.26353 21.3218 8.21095 22.4578 8.83146C22.8668 9.05474 23.2957 9.23608 23.7375 9.37055C23.9654 9.43963 24.1968 9.49761 24.4294 9.54202C24.6468 9.58396 24.9202 9.56546 25.1259 9.63824C25.1318 9.64071 25.1399 9.64071 25.1458 9.64071H25.1516C25.2498 9.64934 25.3491 9.65551 25.4473 9.66045C26.6815 9.81465 27.6351 10.9212 27.6351 12.2621C27.6351 13.6031 26.6277 14.7713 25.3445 14.8749C25.1107 14.8688 24.8805 14.8527 24.6503 14.8293C22.2275 14.5998 20.0093 13.6179 18.2072 12.1079C17.0817 11.1655 16.1164 10.0145 15.3672 8.72043C14.6017 7.39553 14.0676 5.92382 13.8 4.39784C13.7007 3.83038 13.6037 3.24194 13.6259 2.65968L13.627 2.66091Z"
            fill="url(#paint2_linear_1723_11231)"
          />
          <path
            id="Vector_4"
            d="M14.2453 17.7085C14.3131 17.5136 14.3867 17.3211 14.4674 17.1275C14.5188 16.9979 14.5737 16.8672 14.631 16.7401C15.4771 14.844 16.7207 13.2798 18.2084 12.1104C20.0106 13.6203 22.2299 14.6023 24.6515 14.8317C24.7602 14.8428 24.8712 14.8539 24.9799 14.8601C23.9748 14.7996 22.8049 14.9057 21.9214 15.4498C20.7129 16.1949 19.7266 17.6172 19.0359 18.8706C18.6408 19.5861 18.3896 20.3077 17.6299 20.6877C16.9965 21.0035 16.2391 21.0294 15.5555 20.6877C14.4756 20.1523 13.9438 18.8854 14.2442 17.7085H14.2453Z"
            fill="url(#paint3_linear_1723_11231)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1723_11231"
          x1="13.6259"
          y1="25.3705"
          x2="3.18477"
          y2="12.0863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17447F" />
          <stop offset="0.07" stop-color="#195590" />
          <stop offset="0.3" stop-color="#2187C2" />
          <stop offset="0.41" stop-color="#259BD6" />
          <stop offset="0.59" stop-color="#38A5DB" />
          <stop offset="0.94" stop-color="#6AC1EA" />
          <stop offset="1" stop-color="#74C7ED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1723_11231"
          x1="11.5223"
          y1="3.7444"
          x2="4.33379"
          y2="12.3879"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stop-color="#D5DD28" />
          <stop offset="0.17" stop-color="#D0D928" />
          <stop offset="0.26" stop-color="#C2CF28" />
          <stop offset="0.36" stop-color="#AABE28" />
          <stop offset="0.46" stop-color="#8AA729" />
          <stop offset="0.57" stop-color="#60892A" />
          <stop offset="0.67" stop-color="#2D652B" />
          <stop offset="0.72" stop-color="#17562C" />
          <stop offset="0.87" stop-color="#172F17" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1723_11231"
          x1="12.4244"
          y1="-2.76453"
          x2="23.3635"
          y2="11.1526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17447F" />
          <stop offset="0.16" stop-color="#1C639E" />
          <stop offset="0.41" stop-color="#259BD6" />
          <stop offset="0.59" stop-color="#38A5DB" />
          <stop offset="0.94" stop-color="#6AC1EA" />
          <stop offset="1" stop-color="#74C7ED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1723_11231"
          x1="15.566"
          y1="21.2206"
          x2="23.0975"
          y2="12.1659"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stop-color="#D5DD28" />
          <stop offset="0.17" stop-color="#D0D928" />
          <stop offset="0.26" stop-color="#C2CF28" />
          <stop offset="0.36" stop-color="#AABE28" />
          <stop offset="0.46" stop-color="#8AA729" />
          <stop offset="0.57" stop-color="#60892A" />
          <stop offset="0.67" stop-color="#2D652B" />
          <stop offset="0.72" stop-color="#17562C" />
          <stop offset="0.87" stop-color="#172F17" />
        </linearGradient>
        <clipPath id="clip0_1723_11231">
          <rect width="27.6364" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
