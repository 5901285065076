import React from "react";
import {
  getFinancialPayments,
  getFinancialPaymentsSearch,
  getFinancialPaymentsOffset,
  financialExportHistory,
} from "services/financialService";
import { merge } from "lodash";

const defaultState = {
  billingListLoading: true,
  billingSelected: null,
  billingList: [],
  billingCount: [],
  billingAfter: [],
};

const BillingStateContext = React.createContext();
const BillingDispatchContext = React.createContext();

function assetReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        billingListLoading: action.billingListLoading,
      };
    case "SET_BILLING_LIST":
      return {
        ...state,
        billingList: action.billingList,
      };
    case "SET_BILLING_COUNT":
      return {
        ...state,
        billingCount: action.billingCount,
      };
    case "SET_BILLING_AFTER":
      return {
        ...state,
        billingAfter: action.billingAfter,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BillingProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(assetReducer, mergedState);
  return (
    <BillingStateContext.Provider value={state}>
      <BillingDispatchContext.Provider value={dispatch}>
        {children}
      </BillingDispatchContext.Provider>
    </BillingStateContext.Provider>
  );
}

function useBillingState() {
  const context = React.useContext(BillingStateContext);
  if (context === undefined) {
    throw new Error("useBillingState must be used within a BillingProvider");
  }
  return context;
}

function useBillingDispatch() {
  const context = React.useContext(BillingDispatchContext);
  if (context === undefined) {
    throw new Error("useBillingDispatch must be used within a BillingProvider");
  }
  return context;
}

async function getBillingList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      billingListLoading: true,
    });

    const billingListResult = await getFinancialPayments(filterData);
    const billingRecords = billingListResult.data.data.records;

    let billingList = [];
    billingList["0"] = billingRecords["0"].list.map((item) => {
      return { ...item };
    });
    billingList["1"] = billingRecords["1"].list.map((item) => {
      return { ...item };
    });

    dispatch({
      type: "SET_BILLING_LIST",
      billingList: billingList,
    });

    let billingCount = [];
    billingCount["0"] = billingRecords["0"].count;
    billingCount["1"] = billingRecords["1"].count;

    dispatch({
      type: "SET_BILLING_COUNT",
      billingCount,
    });

    dispatch({
      type: "SET_LOADING",
      billingListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBillingExportList(dispatch, list) {
  try {
    dispatch({
      type: "SET_LOADING",
      billingListLoading: true,
    });
    const billingListExport = await financialExportHistory(1);
    const result = billingListExport.data.data.files;

    list["8888"] = result;
    dispatch({
      type: "SET_BILLING_LIST",
      billingList: { ...list },
    });

    const billingCount = {
      8888: billingListExport.data.data.count,
    };

    dispatch({
      type: "SET_BILLING_COUNT",
      billingCount,
    });

    dispatch({
      type: "SET_LOADING",
      billingListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBillingListDataSet(
  billingList,
  offset,
  dispatch,
  tab,
  filterData
) {
  try {
    const result = await getFinancialPaymentsOffset(tab, offset, filterData);
    billingList[tab] = billingList[tab].concat(
      result.data.data.records[tab].list.map((item) => {
        return { ...item };
      })
    );
    dispatch({
      type: "SET_BILLING_LIST",
      billingList: billingList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getBillingExportListDataSet(billingList, offset, dispatch, tab) {
  try {
    const result = await financialExportHistory(1, offset);
    billingList[tab] = billingList[tab].concat(result.data.data.files);
    dispatch({
      type: "SET_BILLING_LIST",
      billingList: billingList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchBilling(search, filterData) {
  try {
    const billingListResult = await getFinancialPaymentsSearch(
      search,
      filterData
    );
    return billingListResult.data.data.records;
  } catch (error) {
    console.log(error);
  }
}

export {
  BillingProvider,
  useBillingState,
  useBillingDispatch,
  getBillingList,
  getBillingExportList,
  searchBilling,
  getBillingListDataSet,
  getBillingExportListDataSet,
};
