import React, { useContext } from "react";
import SurveysTasksDeleteDialog from "./surveysTasksDeleteDialog";
import ManageTaskDialog from "./manageTaskDialog";
import GlobalUiContext from "contexts/globalUiContext";

const SurveysTasksListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <ManageTaskDialog />
      <SurveysTasksDeleteDialog />
    </>
  );
};
export default SurveysTasksListDialog;
