import React, { useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import QuoteItemService from "pages/quotePage/quoteItemService";
import QuoteItemCustom from "pages/quotePage/quoteItemCustom";
import QuoteItemGood from "pages/quotePage/quoteItemGood";
import { QuoteItemUserProvider } from "contexts/quoteItemUserContext";
import {
  useQuoteItemDrawerState,
  useQuoteItemDrawerDispatch,
} from "contexts/quoteItemDrawerContext";
import useStyles from "./styles";

const SideDrawer = (props) => {
  const classes = useStyles();
  const { isOpen, drawerContent } = useQuoteItemDrawerState();
  const dispatchQuoteItemDrawer = useQuoteItemDrawerDispatch();

  const closeDrawer = () => {
    dispatchQuoteItemDrawer({
      type: "SET_IS_OPEN",
      isOpen: false,
    });
  };

  return (
    <Drawer anchor="right" open={isOpen} className={classes.drawerWrapper}>
      <>
        <IconButton
          className={classes.buttonClose}
          aria-label="Close"
          onClick={() => closeDrawer()}
        >
          <CloseIcon className={classes.iconClose} />
        </IconButton>
        <QuoteItemUserProvider>
          {drawerContent === "service" ? (
            <QuoteItemService />
          ) : drawerContent === "unit" ? (
            <QuoteItemCustom />
          ) : drawerContent === "good" ? (
            <QuoteItemGood />
          ) : (
            ""
          )}
        </QuoteItemUserProvider>
      </>
    </Drawer>
  );
};

export default SideDrawer;
