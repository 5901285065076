import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import DoneIcon from "@material-ui/icons/ThumbUp";
import CancelIcon from "@material-ui/icons/ThumbDown";

import { APStatus } from "../../../../constants";
import useStyles from "./styles";

const BillingStatusChip = (props) => {
  const classes = useStyles();

  const { status, archived } = props;
  const statusSelected = APStatus.find(
    (statusItem) => statusItem.id === status
  );

  if (!statusSelected) {
    return "Error status";
  }

  let classChip;
  let iconChip;

  switch (statusSelected.text) {
    case "Work in Progress":
      classChip = classes.chipInProgress;
      iconChip = <PauseIcon className={classes.icon} />;
      break;
    case "Pending Payment":
      classChip = classes.chipNotStarted;
      iconChip = <PauseIcon className={classes.icon} />;
      break;
    case "Partial Pending Payment":
      classChip = classes.chipPartial;
      iconChip = <PauseIcon className={classes.icon} />;
      break;
    case "Partial Parent":
      classChip = classes.chipPartial;
      iconChip = <PauseIcon className={classes.icon} />;
      break;
    case "Exported":
      classChip = classes.chipDone;
      iconChip = <DoneIcon className={classes.icon} />;
      break;
    case "Rejected":
      classChip = classes.chipCancelled;
      iconChip = <CancelIcon className={classes.icon} />;
      break;
    default:
      break;
  }

  return (
    <>
      <Chip
        icon={iconChip}
        label={archived ? "Archived" : statusSelected.text}
        className={classNames(classes.chip, classChip)}
      />
    </>
  );
};

export default BillingStatusChip;
