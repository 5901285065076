import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
// import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/qa/surveys`;
const apiEndpointSingular = `${apiUrl}/qa/survey`;

export function getSurveys(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getSurveysListSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getSurveysListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getSurveyDetails(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getSurveyQuestions(survey_id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${survey_id}/questions?${filterString}`);
}

export function getSurveyQuestionsOffset(survey_id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${survey_id}/questions?${filterString}&offset=${offset}`
  );
}

export function getSurveyQuestionsSearch(survey_id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${survey_id}/questions?${filterString}search=${search}`
  );
}

export function getSurveyShares(survey_id) {
  return http.get(`${apiEndpoint}/${survey_id}/shares`);
}

export function updateSurvey(survey_id, data) {
  return http.put(`${apiEndpoint}/${survey_id}`, data);
}

export function createSurvey(data) {
  return http.post(`${apiEndpointSingular}`, data);
}

export function createSurveyShare(data) {
  return http.post(`${apiEndpointSingular}/share`, data);
}

export function getSurveyPreview(id) {
  return http.get(`${apiEndpoint}/${id}?preview=1`);
}

export function deleteSurvey(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function deleteSurveyShare(id) {
  return http.delete(`${apiEndpoint}/shares/${id}`);
}

export function getSurveyResults(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/submitted?${filterString}`);
}

export function getSurveyResultsSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/submitted?${filterString}search=${search}`,
    {}
  );
}

export function getSurveyResultsOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/submitted?${filterString}offset=${offset}`,
    {}
  );
}

export function exportResult(id, data) {
  return http.post(`${apiEndpoint}/${id}/submitted/export`, data);
}

export function setBaseline(survey, submit) {
  return http.post(
    `${apiEndpoint}/${survey}/results/${submit}/toggle-baseline`,
    {}
  );
}
