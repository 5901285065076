import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "components/common/Table/table";
import {
  getAllSliderAssetList,
  useDashboardDispatch,
} from "contexts/dashboardContext";
import { useTableDispatch } from "contexts/tableContext";
import { withRouter } from "react-router-dom";
import useStyles from "../styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logException } from "components/util/logUtil";

const AssetsTable = ({
  assetTableData,
  selectedLocation,
  isSurfaceUser,
  setAssetModalDataVisibility,
}) => {
  const dispatch = useDashboardDispatch();
  const classes = useStyles();
  const [loadingMore, setLoadingMore] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);

  const columns = [
    {
      checkbox: false,
    },
    {
      id: "name_asset",
      label: "Name",
      content: (row) => row?.name,
    },
    {
      id: "number_asset",
      label: "Number",
      content: (row) => <span>{row.number}</span>,
    },
    {
      id: "jobs_asset",
      label: "Location",
      content: (row) => row?.locations[0],
    },
    {
      id: "category_asset",
      label: "Category",
      content: (row) => row?.categories[0]?.name,
    },
    {
      id: "zone_asset",
      label: "Zone",
      content: (row) => row?.zone || "-",
    },
    { id: "last_maintenance", label: "Last Maintenance", format: "date" },
    { id: "next_maintenance", label: "Upcoming Maintenance", format: "date" },

    {
      id: "actionItem",
      label: "",
      content: (row, i) => (
        <Button
          key={i}
          className={classes.followingBtn}
          onClick={() => {
            setAssetModalDataVisibility({
              ...{ selectedRowData: row, visibility: true },
            });
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  const dispatchTable = useTableDispatch();

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: assetTableData?.assets });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    let lastScrollTop = 0;
    document.getElementById("table-wrapper").addEventListener(
      "scroll",
      function () {
        let st =
          document.getElementById("table-wrapper").pageYOffset ||
          document.getElementById("table-wrapper").scrollTop;
        if (st > lastScrollTop) {
          setScrollDirection("down");
        } else {
          setScrollDirection("up");
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
    return () =>
      document
        .getElementById("table-wrapper")
        .removeEventListener("scroll", () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, assetTableData?.assets]);

  const handleScrollClick = () => {
    if (!loadingMore && scrollDirection === "down") {
      try {
        setLoadingMore(true);
        getAllSliderAssetList(
          dispatch,
          selectedLocation.job_number,
          isSurfaceUser,
          true,
          assetTableData?.assets?.length
        ).then((res) => {
          setLoadingMore(false);
        });
      } catch (e) {
        logException(e, "Cannot load more data");
      }
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.rootContainer}>
          <Box className={classes.tableContainer}>
            <Box
              className="sliderHeightTable"
              idscrollwrapper={"asset-table-wrapper"}
            >
              <Table
                newColumns={columns}
                activeMobile={true}
                handleScrollClick={() => handleScrollClick()}
              ></Table>
              {loadingMore && (
                <Box className={classes.loadingTable}>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AssetsTable);
