import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import AssetsCategoryCreate from "./assetsCategoryCreate";
import { makeStyles } from "@material-ui/core/styles";

import GlobalUiContext from "contexts/globalUiContext";
import { permissionAsset, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
}));

const AssetsCategoriesListActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [openAdd, setOpenAdd] = useState(false);

  const handleClick = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  return (
    <div className={classes.root}>
      {hasPermission(permissionAsset.MANAGE_CATEGORIES, permissions) && (
        <>
          <ButtonBase
            variant="outlined"
            color="secondary"
            onClick={handleClick}
          >
            Create Category
          </ButtonBase>
          <AssetsCategoryCreate
            open={openAdd}
            handleClose={handleCloseAdd}
            handleUpdateDate={props.handleUpdateDate}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(AssetsCategoriesListActions);
