import React, { useContext } from "react";
import QuestionsDeleteDialog from "./questionsDeleteDialog";
import ManageQuestionDialog from "./manageQuestionDialog";
import AddQuestionDialog from "./addQuestionDialog";
import GlobalUiContext from "contexts/globalUiContext";
import QuestionsRequiredDialog from "./questionsRequiredDialog";

const QuestionsListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <ManageQuestionDialog />
      <QuestionsDeleteDialog />
      <AddQuestionDialog />
      <QuestionsRequiredDialog />
    </>
  );
};
export default QuestionsListDialog;
