import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateSurvey } from "services/surveyService";
import { useSurveyView } from "contexts/surveyViewContext";

import useStyles from "./styles";

const PublishSurveyDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [isFailure, setIsFailure] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [stateContextSurvey, dispatchContext] = useSurveyView();
  const { survey, openPublishDialog } = stateContextSurvey ?? null;

  const handleClose = () => {
    dispatchContext({
      type: "TOGGLE_PUBLISH",
      openPublishDialog: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      await updateSurvey(survey.id, {
        status: 2,
      });
      survey.status = 2;
      dispatchContext({
        type: "SET_SURVEY",
        survey: survey,
      });
      setIsLoadingDialog(false);
      handleClose();
      setMessage("This survey has been published.");
      setTitle("Success!");
      setIsFailure(false);
      setOpenSuccess(true);
    } catch (e) {
      if (e.message.includes("400")) {
        setMessage(
          "Please add questions to this survey so it can be published."
        );
        setIsFailure(true);
        setTitle("Error!");
        setOpenSuccess(true);
        setIsLoadingDialog(false);
        handleClose();
      }
      console.log(e.message);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openPublishDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Surveys
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to publish this survey?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={title}
        message={message}
        handleClose={handleCloseSuccess}
        isFailure={isFailure}
      />
    </>
  );
};
export default PublishSurveyDialog;
