import React, { useMemo } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  questionFill,
} from "contexts/surveyFillContext";

const defaultLabels = {
  English: {
    left: "Very Poor",
    center: "Average",
    right: "Excellent",
  },
  Spanish: {
    left: "Pobre",
    center: "Regular",
    right: "Excelente",
  },
};

const SurveyQuestionRatingNoTasks = (props) => {
  const classes = useStyles();
  const {
    selectedQuestions,
    correlative,
    lang,
    updateAnswers,
    isPreview,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const options = Array.from(
    { length: selectedQuestions[correlative].scale },
    (_, i) => i + 1
  );
  const labels = JSON.parse(selectedQuestions[correlative].labels);
  const labels_es = JSON.parse(selectedQuestions[correlative].labels_es);
  const isMobile = useMemo(
    () => "ontouchstart" in document.documentElement,
    []
  );

  const handleMouseOver = (e) => {
    const num = Number(e.target.getAttribute("data-value"));
    if (num) {
      selectedQuestions[correlative].hover = num;
    }
    selectedQuestions[correlative].hovering = true;
    dispatchSurveyFill({
      type: "SET_SELECTED_QUESTIONS",
      selectedQuestions: selectedQuestions,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseLeave = (e) => {
    selectedQuestions[correlative].hover = -1;
    selectedQuestions[correlative].hovering = false;
    dispatchSurveyFill({
      type: "SET_SELECTED_QUESTIONS",
      selectedQuestions: selectedQuestions,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSetScore = (v) => {
    if (!readonlySurvey) {
      const totalScore =
        parseFloat(v) / parseFloat(selectedQuestions[correlative].scale);
      const data = {
        score: v,
        status: 2,
        total_score: totalScore,
      };
      if (!isPreview) {
        questionFill(
          dispatchSurveyFill,
          selectedQuestions[correlative].id,
          data,
          selectedQuestions[correlative].status === 1,
          !updateAnswers
        );
      }
      selectedQuestions[correlative].score = v;
      selectedQuestions[correlative].status = 2;
      selectedQuestions[correlative].total_score = totalScore;
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: selectedQuestions,
      });
    }
  };

  const labelDisplay = lang === "Spanish" ? labels_es : labels;

  return (
    <Box>
      <Typography className={classes.quesTextSub}>
        {lang === "Spanish" && selectedQuestions[correlative].tooltip_es?.length
          ? selectedQuestions[correlative].tooltip_es
          : selectedQuestions[correlative].tooltip}
      </Typography>
      <List className={classes.questionList}>
        <ListItem className={classes.listMinHeight}>
          <Grid container>
            <Grid item xs={12} md={options.length <= 3 ? 4 : 1} />
            <Grid
              item
              xs={12}
              md={options.length <= 3 ? 4 : 11}
              className={classes.alignRating}
              onMouseOver={
                isMobile
                  ? null
                  : (e) => (!readonlySurvey ? handleMouseOver(e) : "")
              }
              onMouseLeave={
                isMobile
                  ? null
                  : (e) => (!readonlySurvey ? handleMouseLeave(e) : "")
              }
            >
              {options.map((v) => {
                return parseInt(selectedQuestions[correlative].rating_type) ===
                  1 ? (
                  <Box
                    className={classes.ratingBox}
                    data-value={v}
                    onClick={() => handleSetScore(v)}
                    key={`Box${v}`}
                  >
                    <Typography
                      data-value={v}
                      className={classNames(classes.ratingFont, {
                        [classes.ratingFontSelected]:
                          (v <= selectedQuestions[correlative].score &&
                            !selectedQuestions[correlative].hovering) ||
                          v <= selectedQuestions[correlative].hover,
                        [classes.ratingFontSelectedActive]:
                          v === selectedQuestions[correlative].score,
                      })}
                    >
                      {v}
                    </Typography>
                  </Box>
                ) : (v <= selectedQuestions[correlative].score &&
                    !selectedQuestions[correlative].hovering) ||
                  v <= selectedQuestions[correlative].hover ? (
                  <StarIcon
                    key={`Star${v}`}
                    className={classNames(classes.ratingStarSelected, {
                      [classes.ratingStarSelectedActive]:
                        v === selectedQuestions[correlative].score,
                    })}
                    data-value={v}
                    onClick={() => handleSetScore(v)}
                  />
                ) : (
                  <StarOutlineIcon
                    key={`StarO${v}`}
                    className={classes.ratingStar}
                    data-value={v}
                    onClick={() => handleSetScore(v)}
                  />
                );
              })}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                md={options.length <= 3 ? 4 : 1}
                className={classes.maxWidth}
              />
              <Grid
                item
                xs={12}
                md={options.length <= 3 ? 4 : 11}
                className={classes.ratingTextContainer}
              >
                <Typography className={classes.ratingTextBelow}>
                  {labelDisplay?.left?.length
                    ? labelDisplay.left
                    : defaultLabels[lang].left}
                </Typography>
                {options.length >= 3 ? (
                  <Typography className={classes.ratingTextBelow}>
                    {labelDisplay?.center?.length
                      ? labelDisplay.center
                      : defaultLabels[lang].center}
                  </Typography>
                ) : null}
                <Typography className={classes.ratingTextBelow}>
                  {labelDisplay?.right?.length
                    ? labelDisplay.right
                    : defaultLabels[lang].right}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

export default SurveyQuestionRatingNoTasks;
