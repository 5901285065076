export const errorList = (schema, data) => {
  const errorList = [];
  const { error } = schema.validate(data, { abortEarly: false });

  if (error) {
    error.details.map(errorItem => {
      return errorList.push({
        key: errorItem.path[0],
        message: errorItem.message
      });
    });
  }
  return errorList;
};

export const errorArray = (schema, data) => {
  let errorList = {};
  const { error } = schema.validate(data, { abortEarly: false });

  if (error) {
    error.details.map(errorItem => {
      return errorList[errorItem.path[0]]=errorItem.message;
    });
  }
  return errorList;
};
