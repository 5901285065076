import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import * as classNames from "classnames";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "./styles";
import BackIcon from "@material-ui/icons/NavigateBefore";

const whoWillTransferOptions = [
  { label: "Dispatch", value: "Dispatch" },
  { label: "Manager", value: "Manager" },
  { label: "Site Supervisor", value: "Site Supervisor" },
  { label: "Repair Technician", value: "Repair Technician" },
];

export default function AutoGrid({
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
  currentStep,
  setCurrentStep,
  totalSteps,
}) {
  let templatePayload = {
    job_id: "",
    to_job_id: "",
    transfer_by: "",
    items: [],
    location: "",
  };

  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");
  const [toJobSelected, setToJobSelected] = useState("");

  const { globalUi } = useContext(GlobalUiContext);
  const [whoWillTransfer, setWhoWillTransfer] = useState("");
  const [addMultiple, setAddMultiple] = useState([
    {
      description: "",
      serial_number: "",
      make: "",
      model: "",
      comment: "",
    },
  ]);
  const addMultipleObj = {
    description: "",
    serial_number: "",
    make: "",
    model: "",
    comment: "",
  };

  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, [customerSelected?.value]);

  const classes = useStyles();
  const [form, setForm] = useState({});
  let validAddMultiple = true;
  addMultiple.map((v) => {
    if (!v.description || !v.make || !v.model || !v.comment) {
      validAddMultiple = false;
    }
  });
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box>
      <Grid
        container
        onChange={(e) => {
          if (e.target.id) {
            if (e.target.id.length === 1) {
              addMultiple[e.target.id][e.target.name] = e.target.value;
              setAddMultiple([...addMultiple]);
              return;
            }
          }

          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid item xs={12} md={12} className={classes.transferAdj}>
          <Box
            className={classes.Box}
            style={{
              overflow: "auto !important",
              height: "100%",
            }}
          >
            <span style={{ display: currentStep === 1 ? "block" : "none" }}>
              <Box className={classes.padding23}>
                <Typography className={classes.subTitle}>
                  Job Name(*)
                </Typography>

                <span>
                  {Boolean(jobs) ? (
                    <FormSelectJobsAuto
                      options={jobs}
                      name="job_number"
                      handleChange={(e, v) => setJobSelected(v)}
                      value={jobSelected}
                      inputContainerProps={classes.inputContainer}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </span>

                <Typography className={classes.subTitle}>
                  To Job Name(*)
                </Typography>

                <span>
                  {Boolean(jobs) ? (
                    <FormSelectJobsAuto
                      options={jobs}
                      name="job_number"
                      handleChange={(e, v) => setToJobSelected(v)}
                      value={toJobSelected}
                      inputContainerProps={classes.inputContainer}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </span>
              </Box>

              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subTitle}>
                  Specific location of equipment:
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="location_of_equip"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      multiline={true}
                      minRows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 2 ? "block" : "none" }}>
              {addMultiple?.map((v, i) => (
                <Box key={i}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.inputContainer}
                    >
                      <Typography className={classes.subSubTitle}>
                        Item Description(*)
                      </Typography>
                      <TextField
                        name="description"
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        id={i}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.inputContainer}
                    >
                      <Typography className={classes.subSubTitle}>
                        Serial No
                      </Typography>
                      <TextField
                        name="serial_number"
                        data-index={1}
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        id={i}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.inputContainer}
                    >
                      <Typography className={classes.subSubTitle}>
                        Make(*)
                      </Typography>
                      <TextField
                        name="make"
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        id={i}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.inputContainer}
                    >
                      <Typography className={classes.subSubTitle}>
                        Model(*)
                      </Typography>
                      <TextField
                        name="model"
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        id={i}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.inputContainer}
                    >
                      <Typography className={classes.subSubTitle}>
                        Describe reason for request(*)
                      </Typography>
                      <TextField
                        name="comment"
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        id={i}
                        multiline={true}
                        minRows={3}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Grid container>
                <Grid item xs={12} md={12}>
                  <Box className={classes.plusRightEnd}>
                    {addMultiple.length > 1 && (
                      <IndeterminateCheckBoxIcon
                        className={classes.iconMultiRequest}
                        onClick={() => {
                          addMultiple.pop();
                          setAddMultiple([...addMultiple]);
                        }}
                      />
                    )}
                    &nbsp;
                    <AddCircleIcon
                      className={classes.iconMultiRequest}
                      onClick={() => {
                        addMultiple.push({ ...addMultipleObj });
                        setAddMultiple([...addMultiple]);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 3 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Who will be transferring?
                  </Typography>
                  <FormSelectAuto
                    options={whoWillTransferOptions}
                    name="who_will_transfer"
                    handleChange={(e, v) => setWhoWillTransfer(v)}
                    value={whoWillTransfer}
                    inputContainer={classes.inputContainer}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subSubTitle}>
                  Contact Name
                </Typography>
                <TextField
                  name="onsite_name"
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subSubTitle}>
                  Contact Phone
                </Typography>
                <TextField
                  name="onsite_phone"
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Grid>
            </span>
            <Box className={classes.trContainer}>
              <Box className={classes.trSubContainer}>
                <Box className={classes.flex}>
                  {currentStep === 1 ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className={classes.buttonBack}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                      startIcon={<BackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      currentStep === 1
                        ? !jobSelected?.id || !toJobSelected?.id
                        : currentStep === 2
                        ? !validAddMultiple
                        : false
                    }
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                    onClick={() => {
                      if (currentStep === totalSteps) {
                        templatePayload.job_id = jobSelected.id;
                        templatePayload.to_job_id = toJobSelected.id;
                        templatePayload.fix_at = form.fix_at;
                        templatePayload.transfer_by = whoWillTransfer.value;
                        templatePayload.items = JSON.stringify(addMultiple);
                        templatePayload.location = form.location_of_equip;
                        templatePayload.contact_name = form?.onsite_name ?? "";
                        templatePayload.contact_phone =
                          form?.onsite_phone ?? "";
                        Object.keys(templatePayload).map((v) => {
                          if (!templatePayload[v]) {
                            templatePayload[v] = "";
                          }
                        });
                        submitFormDataBasedOnCurrentTab(
                          templatePayload,
                          topTab,
                          formSelected
                        ).then((res) => {
                          if (res.status === 201) {
                            closeAndRefreshSidebar();
                          }
                        });
                      }
                      if (currentStep < totalSteps) {
                        setCurrentStep(++currentStep);
                      }
                    }}
                  >
                    {" "}
                    {currentStep === totalSteps ? "Submit" : "Next"}
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
