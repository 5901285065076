import React, { useState, useEffect, lazy, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";

import { QuoteProvider } from "contexts/quoteContext";
import QuoteBreadcrum from "./quoteBreadcrum";
import QuoteHeader from "./quoteHeader";
import { QuoteItemDrawerProvider } from "contexts/quoteItemDrawerContext";
import { TableProvider } from "contexts/tableContext";
import { DrawerProvider } from "contexts/detailsDrawerContext";
import { QuoteActionPlanningProvider } from "contexts/quoteActionPlanningContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuotePage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const QuotesDialog = lazy(() => import("./dialog/quotesPlanningDialog"));
  const QuotePlanningItems = lazy(() => import("./quoteItemsPlanning"));

  const classes = useStyles();

  const initialStateQuote = {
    selectedQuote: "new",
    selectedQuoteItem: {},
    quoteDetails: [],
    uniqueJobs: [],
    editMode: false,
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <LoadingStateHorizontal isVisible />
        </div>
      ) : (
        <QuoteProvider initialState={initialStateQuote}>
          <QuoteBreadcrum pendingPlanning={true} />
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
              <Box p={3} className={classes.containerBody}>
                <QuoteHeader pendingPlanning={true} />
                <DrawerProvider>
                  <TableProvider>
                    <QuoteItemDrawerProvider>
                      <QuoteActionPlanningProvider>
                        <Suspense fallback={<LoadingIndicator />}>
                          <QuotePlanningItems
                            quoteId={props.match.params.id}
                            pendingPlanning={true}
                          />
                          <QuotesDialog />
                        </Suspense>
                      </QuoteActionPlanningProvider>
                    </QuoteItemDrawerProvider>
                  </TableProvider>
                </DrawerProvider>
              </Box>
            </Grid>
          </Grid>
        </QuoteProvider>
      )}
    </>
  );
};

export default QuotePage;
