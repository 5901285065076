import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import * as classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TicketIcon from "@material-ui/icons/Receipt";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LocationOn from "@material-ui/icons/LocationOn";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import WorkticketGeoMap from "components/ui/Worktickets/WorkticketGeoMap";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import {
  updateTimekeepingManual,
  deleteTimekeepingManual,
  getTimekeepingHistory,
} from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import {
  formatDateTZ,
  formatAppDateTZ,
  getDateTZ,
} from "components/util/timeFormat";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const customersTKOvertime = ["31300"];

const optionsMenu = [
  {
    id: 1,
    label: "Edit",
    value: "EDIT",
    icon: <EditIcon fontSize="inherit" />,
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    icon: <DeleteIcon fontSize="inherit" />,
  },
];

const WorkticketTimekeepingHistoryInline = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions, users } = globalUi;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [timekeepingList, setTimekeepingList] = useState([]);
  const [stateContext] = useWorkticketView();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openGeoLocation, setOpenGeoLocation] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [row, setRow] = useState({});
  const [date, setDate] = useState(moment());
  const [dateOut, setDateOut] = useState(moment());
  const [timeIn, setTimeIn] = useState(moment());
  const [timeOut, setTimeOut] = useState(moment());
  const [user, setUser] = useState(undefined);
  const [drive, setDrive] = useState(false);
  const [overtime, setOvertime] = useState(false);
  const [onPayroll, setOnPayroll] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  const { restrictModify } = props;

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingList(true);
        const result = await getTimekeepingHistory(workticket.id);
        setTimekeepingList(result.data.data.entries);
        setLoadingList(false);
      } catch (e) {
        logException(e, "Cannot load timekeeping list");
      }
    };
    if (!isLoading) {
      loadData();
    }
  }, [isLoading, workticket.id]);

  useEffect(() => {
    if (errorSubmit) {
      setTimeout(() => {
        setErrorSubmit("");
      }, 5000);
    }
  }, [errorSubmit]);

  const handleUpdate = (row) => {
    setOpenUpdate(true);
    setDeleteRow(false);
    setRow(row);
    const findUser = users.find((user) => user.id === row.user.id);
    if (findUser) {
      setUser(findUser);
    } else {
      setUser(undefined);
    }
    if (row.hr_entry) {
      setOnPayroll(true);
    } else {
      setOnPayroll(false);
    }

    const tz = workticket?.job?.timezone;
    if (row.clock_in) {
      setDate(getDateTZ(row.clock_in, tz));
      setTimeIn(getDateTZ(row.clock_in, tz));
      setDateOut(getDateTZ(row.clock_out, tz));
      setTimeOut(getDateTZ(row.clock_out, tz));
      setDrive(false);
    } else {
      setDate(getDateTZ(row.drive_in, tz));
      setTimeIn(getDateTZ(row.drive_in, tz));
      setDateOut(getDateTZ(row.drive_out, tz));
      setTimeOut(getDateTZ(row.drive_out, tz));
      setDrive(true);
    }
    setOvertime(row?.overtime && Number(row.overtime) ? true : false);
  };

  const handleUpdateTimekeeping = async () => {
    try {
      const dateFormat = "YYYY-MM-DD HH:mm:ss";
      const dateFormatOnly = "YYYY-MM-DD";
      const timeFormat = "HH:mm:00";

      let data = {};
      const tz = workticket?.job?.timezone;

      const inDate = `${formatAppDateTZ(
        date.format(dateFormatOnly),
        dateFormatOnly,
        tz
      )} ${formatAppDateTZ(timeIn.format(dateFormat), timeFormat, tz)}`;

      const outDate = `${formatAppDateTZ(
        dateOut.format(dateFormatOnly),
        dateFormatOnly,
        tz
      )} ${formatAppDateTZ(timeOut.format(dateFormat), timeFormat, tz)}`;

      if (inDate.includes("Invalid date") || outDate.includes("Invalid date")) {
        setErrorSubmit("Invalid date or time");
        return;
      }

      if (!drive) {
        data = {
          clock_in: inDate,
          clock_out: outDate,
          overtime: overtime ? 1 : 0,
          is_closed_out: true,
        };
      } else {
        data = {
          drive_in: inDate,
          drive_out: outDate,
          overtime: overtime ? 1 : 0,
          is_closed_out: true,
        };
      }
      await updateTimekeepingManual(workticket.id, row.id, data);
      setOpenUpdate(false);
      setLoadingList(true);
      const resultUpdate = await getTimekeepingHistory(workticket.id);
      setTimekeepingList(resultUpdate.data.data.entries);
      setLoadingList(false);
    } catch (e) {
      logException(e, "Cannot update timekeeping");
    }
  };

  const handleDeleteTimekeeping = async () => {
    try {
      await deleteTimekeepingManual(workticket.id, row.id);
      setOpenUpdate(false);
      setLoadingList(true);
      const resultUpdate = await getTimekeepingHistory(workticket.id);
      setTimekeepingList(resultUpdate.data.data.entries);
      setLoadingList(false);
    } catch (e) {
      logException(e, "Cannot delete timekeeping");
    }
  };

  const handleDelete = (row) => {
    setOpenUpdate(true);
    setDeleteRow(true);
    setRow(row);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseLocation = () => {
    setOpenGeoLocation(false);
  };

  const handleClickLocation = (event, row) => {
    console.log("Location", row);
    setSelectedRow(row);
    setOpenGeoLocation(true);
  };

  const handleClickMenu = (event, row) => {
    const inputMenuOption = [];
    if (
      !row.workticket_external_id &&
      !row.is_exported &&
      hasPermission(permissionWorkticket.MANAGE_TIMEKEEPING, permissions) &&
      !restrictModify
    ) {
      inputMenuOption.push(optionsMenu[0]);
    }

    if (
      !row.is_exported &&
      !row.workticket_external_id &&
      hasPermission(permissionWorkticket.DELETE_TIMEKEEPING, permissions) &&
      !restrictModify
    ) {
      inputMenuOption.push(optionsMenu[1]);
    }
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
    setMenuOptions(inputMenuOption);
  };

  const handleSelectMenu = (event) => {
    const action = event.currentTarget.getAttribute("data");
    const actionHandlers = {
      EDIT: handleUpdate,
      DELETE: handleDelete,
    };

    const selectedAction = actionHandlers[action];
    if (selectedAction) {
      selectedAction(selectedRow);
    }
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeAssignedTo = (event, value) => {
    if (value) {
      setUser(value);
    } else {
      setUser(undefined);
    }
  };

  const radiusRange = workticket.workticket_category === 7 ? 800 : 800;

  return (
    <>
      <Box>
        <Box>
          {!isLoading && (
            <>
              {loadingList ? (
                <Box className={classes.timekeepingBoxLoading}>
                  <LoadingStateHorizontal isVisible />
                </Box>
              ) : (
                <>
                  {Boolean(timekeepingList.length) ? (
                    <Box className={classes.timekeepingBoxSection}>
                      {timekeepingList.map((row) => (
                        <Box key={row.id} className={classes.timekeepingBox}>
                          <Box className={classes.tkFieldRow}>
                            <Box className={classes.tkField}>
                              <span>Name:</span>
                              {`${row.user.first_name} ${row.user.last_name}`}
                            </Box>
                            <Box>
                              <IconButton
                                className={classes.tkActionBtn}
                                onClick={(event) =>
                                  handleClickLocation(event, row)
                                }
                                disabled={
                                  !row.clock_in_lat &&
                                  !row.clock_in_long &&
                                  !row.clock_out_lat &&
                                  !row.clock_out_long
                                }
                              >
                                <LocationOn />
                              </IconButton>
                              {!row.workticket_external_id &&
                              !row.is_exported &&
                              hasPermission(
                                permissionWorkticket.MANAGE_TIMEKEEPING,
                                permissions
                              ) &&
                              !restrictModify ? (
                                <IconButton
                                  className={classes.tkActionBtn}
                                  onClick={(event) =>
                                    handleClickMenu(event, row)
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </Box>
                          {row.clock_in ? (
                            <>
                              <Box className={classes.tkFieldRow}>
                                <Box className={classes.tkField}>
                                  <span>Date:</span>
                                  {formatDateTZ(
                                    row.clock_in,
                                    "L",
                                    workticket?.job?.timezone
                                  )}
                                </Box>
                                <Box className={classes.tkFieldRight}>
                                  <span>Hours:</span> {row.clock_hours}
                                  {row?.overtime && Number(row.overtime)
                                    ? " OT"
                                    : ""}
                                </Box>
                              </Box>
                              <Box className={classes.tkFieldRow}>
                                <Box className={classes.tkField}>
                                  <span>In Time:</span>
                                  <nobr>
                                    {formatDateTZ(
                                      row.clock_in,
                                      "LT",
                                      workticket?.job?.timezone
                                    )}
                                  </nobr>
                                </Box>
                                <Box className={classes.tkFieldRight}>
                                  <nobr>
                                    <span>Out Time:</span>
                                    {row.clock_out
                                      ? formatDateTZ(
                                          row.clock_out,
                                          "LT",
                                          workticket?.job?.timezone
                                        )
                                      : "Running"}
                                  </nobr>
                                </Box>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box className={classes.tkFieldRow}>
                                <Box className={classes.tkField}>
                                  <span>Date:</span>
                                  {formatDateTZ(
                                    row.drive_in,
                                    "L",
                                    workticket?.job?.timezone
                                  )}
                                </Box>
                                <Box className={classes.tkFieldRight}>
                                  <span>Hours:</span>
                                  {row.drive_hours}
                                  {row?.overtime && Number(row.overtime)
                                    ? " OT"
                                    : ""}
                                </Box>
                              </Box>
                              <Box className={classes.tkFieldRow}>
                                <Box className={classes.tkField}>
                                  <span>Drive In:</span>
                                  <nobr>
                                    {formatDateTZ(
                                      row.drive_in,
                                      "LT",
                                      workticket?.job?.timezone
                                    )}
                                  </nobr>
                                </Box>
                                <Box className={classes.tkFieldRight}>
                                  <span>Drive Out:</span>
                                  <nobr>
                                    {row.drive_out
                                      ? formatDateTZ(
                                          row.drive_out,
                                          "LT",
                                          workticket?.job?.timezone
                                        )
                                      : "Running"}
                                  </nobr>
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={handleSelectMenu}
              data={option.value}
            >
              <Box className={classes.menuIcon}>{option.icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={openUpdate}
          onClose={handleCloseUpdate}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseUpdate}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            {!isLoading && (
              <>
                <Grid container spacing={2} className={classes.dialogHeader}>
                  <Grid item sm={12}>
                    <Box className={classes.formIconDialog}>
                      <TicketIcon className={classes.iconDialog} />
                    </Box>
                    <Typography
                      variant="h4"
                      className={classes.formTitleDialog}
                      gutterBottom
                    >
                      Workticket {workticket.number}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.formSubtitleDialog}
                      gutterBottom
                    >
                      {deleteRow
                        ? "Are you sure you want to delete the timekeeping?"
                        : "Update timekeeping"}
                    </Typography>
                    {errorSubmit ? (
                      <Alert
                        severity="error"
                        className={classes.alertContainer}
                      >
                        {errorSubmit}
                      </Alert>
                    ) : null}
                    {onPayroll ? (
                      <Alert severity="info" className={classes.alertContainer}>
                        Entry Synch - Any update will reflect on ADP(payroll
                        system).
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
                {!deleteRow && (
                  <Box className={classes.formContainerDialog}>
                    <FormInputDateAdo
                      name="date"
                      label="In Date"
                      value={date}
                      handleChange={setDate}
                    />
                    <FormInputTimeAdo
                      name="time_in"
                      label="In Time"
                      value={timeIn}
                      handleChange={setTimeIn}
                    />
                    <FormInputDateAdo
                      name="date_out"
                      label="Out Date"
                      value={dateOut}
                      handleChange={setDateOut}
                    />
                    <FormInputTimeAdo
                      name="time_out"
                      label="Out Time"
                      value={timeOut}
                      handleChange={setTimeOut}
                    />
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users}
                      name="users"
                      label="Assigned To"
                      handleChange={handleChangeAssignedTo}
                      value={user}
                      readonly={true}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={drive}
                          value={true}
                          color="secondary"
                        />
                      }
                      label="Travel Time"
                      className={classes.containerOptions}
                    />
                    {customersTKOvertime.includes(
                      workticket?.job?.customer?.customer_number
                    ) ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={overtime}
                            onChange={(event) =>
                              setOvertime(event.target.checked)
                            }
                            value={true}
                            color="secondary"
                          />
                        }
                        label="Overtime"
                        className={classes.containerOptions}
                      />
                    ) : null}
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapper}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonOutlined)}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
            {!deleteRow ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleUpdateTimekeeping}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleDeleteTimekeeping}
              >
                Okay
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openGeoLocation}
          onClose={handleCloseLocation}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseLocation}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            <Box>
              <Box className={classes.formHeaderDialog}>
                <Box className={classes.formIconDialog}>
                  <LocationOn className={classes.iconDialog} />
                </Box>
                <Typography
                  variant="h4"
                  className={classes.formTitleDialog}
                  gutterBottom
                >
                  Clock Locations
                </Typography>
              </Box>
              <Typography
                variant="h5"
                className={classes.formSubtitleDialog}
                gutterBottom
                style={{ marginBottom: 16, marginTop: 8 }}
              >
                Please note this map that shows the location of the job, the
                area that is acceptable to clock, and the actual clock location.
              </Typography>
              <Box>
                <WorkticketGeoMap
                  workticket={workticket}
                  row={selectedRow}
                  radius={radiusRange}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapperRight}>
            <Link
              href={`http://maps.google.com/maps?q=${workticket.job.latitude},${workticket.job.longitude}`}
              rel="noreferrer"
              target="_blank"
              className={classNames(classes.button, classes.buttonOutlinedLink)}
            >
              Go to Location
            </Link>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonPrimary)}
              onClick={handleCloseLocation}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default WorkticketTimekeepingHistoryInline;
