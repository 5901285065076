import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  card: {
    padding: 20,
    marginTop: 60,
    marginBottom: 40,
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 20,
    },
  },
  title: {
    fontWeight: "bold",
    color: "#4f98bc",
    fontSize: 30,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  subTitle: {
    color: "#747474",
    fontSize: 16,
    marginBottom: 30,
    lineHeight: "140%",
    "& span": {
      fontWeight: 700,
      fontSize: 14,
    },
  },
  labelTitle: {
    color: "#747474",
    fontWeight: "bold",
    fontSize: 16,
  },
  tab: {
    fontWeight: "bold",
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  fieldWithFooter: {
    marginBottom: theme.spacing(0),
  },
  input: {
    color: "#747474",
    fontWeight: 700,
    border: "1px solid #DFDFDF",
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: 16,
  },
  cardActions: {
    marginTop: 10,
    padding: 0,
    width: "100%",
  },
  rightIcon: {
    marginLeft: 3,
  },
  logo: {
    display: "inline-block",
    maxWidth: 220,
  },
  logoWrapper: {
    textAlign: "center",
  },
  loginButton: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: "bold",
  },
  alert: {
    marginBottom: theme.spacing(1),
    border: "none",
  },
  cardFooter: {
    marginTop: theme.spacing(2),
  },
  textFooter: {
    color: "#979797",
    fontSize: 12,
  },
  linkLabel: {
    color: "#979797",
    fontSize: 12,
  },
  cardInfo: {
    textAlign: "right",
    marginBottom: theme.spacing(3),
  },
  cardInfoText: {
    textAlign: "left",
    marginBottom: theme.spacing(3),
  },
  forgotPasswordLink: {
    fontWeight: 700,
    cursor: "pointer",
  },
  centerLoading: {
    background: "#f8f8f8",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  centerLoadingLogin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    marginTop: 35,
  },
}));

export default useStyles;
