import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "components/common/Filters/styles";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const ActivityFilters = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [usersFilters, setUsersFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (anchorEl) {
      if (users.length) {
        const selectedUsersFilter = filters.find(
          (filter) => filter.filter === "managed"
        );
        if (selectedUsersFilter) {
          const selectedValue = selectedUsersFilter.values.map(
            (managed) => managed.value
          );
          const findUsers = users.filter((user) =>
            selectedValue.includes(user.id)
          );
          setUsersFilters(findUsers);
        } else {
          setUsersFilters([]);
        }
      }
    }
  }, [anchorEl, filters, users]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    if (usersFilters.length) {
      const managedFilterConvert = usersFilters.map((us) => {
        return {
          label: `${us.first_name} ${us.last_name}`,
          value: us.id,
        };
      });
      filterData.push({
        filter: "user",
        group: "User",
        values: [...managedFilterConvert],
      });
    }
    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    handleClose();
  };

  const handleChangeUsers = (event, value) => {
    setUsersFilters(value ?? []);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                User
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.wrapperTeamMember}
                >
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={users}
                    name="users"
                    handleChange={handleChangeUsers}
                    multiple={true}
                    value={usersFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default ActivityFilters;
