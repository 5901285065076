import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/notices`;
const apiEndpointSingular = `${apiUrl}/notice  `;

export function getNoticeTypes() {
  return http.get(`${apiEndpoint}/types`, {});
}

export function getInfractionTypes(noticeType) {
  return http.get(
    `${apiEndpoint}/infractions?notice_type_id=${noticeType}`,
    {}
  );
}

export function storeNotice(data) {
  return http.post(`${apiEndpointSingular}`, setFormData(data));
}

export function getNotices(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`);
}

export function getNoticesSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getNoticesOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function deleteNotice(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
