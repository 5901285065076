import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import JobsTable from "./jobsTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";

import useStyles from "./styles";

const JobsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Unassigned Jobs" link="/unassigned-jobs" />
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <JobsTable />
        </ListProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(JobsList);
