import React from "react";
import * as qualityTabService from "../services/qualityTabService";
import { merge } from "lodash";

const defaultState = {
  qualityTabListLoading: true,
  qualityTabSelected: null,
  qualityTabList: [],
  qualityTabCount: [],
  qualityTabAvg: null,
};

const QualityTabStateContext = React.createContext();
const QualityTabDispatchContext = React.createContext();

function qualityTabReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        qualityTabListLoading: action.qualityTabListLoading,
      };
    case "SET_QUALITYTAB_LIST":
      return {
        ...state,
        qualityTabList: action.qualityTabList,
      };
    case "SET_QUALITYTAB_COUNT":
      return {
        ...state,
        qualityTabCount: action.qualityTabCount,
      };
    case "SET_QUALITYTAB_AVG":
      return {
        ...state,
        qualityTabAvg: action.qualityTabAvg,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QualityTabProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(qualityTabReducer, mergedState);
  return (
    <QualityTabStateContext.Provider value={state}>
      <QualityTabDispatchContext.Provider value={dispatch}>
        {children}
      </QualityTabDispatchContext.Provider>
    </QualityTabStateContext.Provider>
  );
}

function useQualityTabState() {
  const context = React.useContext(QualityTabStateContext);
  if (context === undefined) {
    throw new Error(
      "useQualityTabState must be used within a QualityTabProvider"
    );
  }
  return context;
}

function useQualityTabDispatch() {
  const context = React.useContext(QualityTabDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useQualityTabDispatch must be used within a QualityTabProvider"
    );
  }
  return context;
}

async function getQualityTabList(
  dispatch,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    dispatch({
      type: "SET_LOADING",
      qualityTabListLoading: true,
    });
    const qualityTabListResult = isDashboard
      ? await qualityTabService.getQualityTabListDashboard(
          filterData,
          dashboardState
        )
      : await qualityTabService.getQualityTabList(filterData);
    dispatch({
      type: "SET_QUALITYTAB_AVG",
      qualityTabAvg: qualityTabListResult.data.data.average.avg_score,
    });
    dispatch({
      type: "SET_QUALITYTAB_LIST",
      qualityTabList: [...qualityTabListResult.data.data.list],
    });
    dispatch({
      type: "SET_QUALITYTAB_COUNT",
      qualityTabCount: qualityTabListResult.data.data.filter_count
        ? qualityTabListResult.data.data.filter_count.count
        : 0,
    });
    dispatch({
      type: "SET_LOADING",
      qualityTabListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQualityTabListDataSet(
  qualityTabList,
  dispatch,
  offset,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    const result = isDashboard
      ? await qualityTabService.getQualityTabListOffsetDashboard(
          offset,
          filterData,
          dashboardState
        )
      : await qualityTabService.getQualityTabListOffset(offset, filterData);
    const updateData = qualityTabList.concat(result.data.data.list);
    dispatch({
      type: "SET_QUALITYTAB_LIST",
      qualityTabList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

export {
  QualityTabProvider,
  useQualityTabState,
  useQualityTabDispatch,
  getQualityTabListDataSet,
  getQualityTabList,
};
