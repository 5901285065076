import React from "react";

const EmailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_492_8879)">
      <path
        d="M14.9329 3.73324C14.9329 2.9999 14.3329 2.3999 13.5996 2.3999H2.93294C2.19961 2.3999 1.59961 2.9999 1.59961 3.73324V11.7332C1.59961 12.4666 2.19961 13.0666 2.93294 13.0666H13.5996C14.3329 13.0666 14.9329 12.4666 14.9329 11.7332V3.73324ZM13.5996 3.73324L8.26628 7.06657L2.93294 3.73324H13.5996ZM13.5996 11.7332H2.93294V5.06657L8.26628 8.3999L13.5996 5.06657V11.7332Z"
        fill="#747474"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_8879">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIcon;
