import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import TicketIcon from "@material-ui/icons/Receipt";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const Workticket = (props) => {
  const classes = useStyles();
  const { worktickets: listWorktickets, workticketsId } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  if (!listWorktickets || !listWorktickets.length) {
    return null;
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Typography
        variant="body2"
        aria-describedby={id}
        onClick={handlePopoverOpen}
        className={classes.label}
      >
        <TicketIcon />
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={2}
      >
        {Array.isArray(listWorktickets)
          ? listWorktickets.map((item, index) => (
              <Box key={`list-workticket-${index}`}>
                <Link
                  to={
                    workticketsId && workticketsId[index]
                      ? `/workticket/${workticketsId[index]}`
                      : "#"
                  }
                >
                  {item}
                </Link>
              </Box>
            ))
          : listWorktickets}
      </Popover>
    </>
  );
};

export default Workticket;
