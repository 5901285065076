import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormSelect from "components/ui/FormContent/formSelect";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NextIcon from "@material-ui/icons/NavigateNext";
import BackIcon from "@material-ui/icons/NavigateBefore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  calendarToolbarHeader: {
    marginBottom: 5,
    "& .MuiGrid-container > div:first-child": {
      display: "none",
    },
    "& .MuiGrid-container .MuiSelect-select": {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 10,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      "& .MuiGrid-container .MuiSelect-select": {
        paddingLeft: 0,
        marginLeft: 0,
      },
    },
  },
  buttonPrimaryAction: {
    background: "#78c1ce !important",
    textTransform: "Capitalize",
    marginRight: 20,
  },
  calendarToolbarTitle: {
    textAlign: "center",
  },
}));

const CalendarToolbar = (toolbar) => {
  const classes = useStyles();
  const [toolbarView] = useState(toolbar.view);

  const viewOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];

  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };
  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };
  const goToCurrent = () => {
    toolbar.onNavigate("TODAY");
  };

  const handleChangeView = (e) => {
    toolbar.onView(e.value);
  };

  return (
    <Grid container spacing={2} className={classes.calendarToolbarHeader}>
      <Grid item sm={3} xs={12}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={goToCurrent}
          className={classes.buttonPrimaryAction}
          disableElevation
        >
          Today
        </Button>
        <IconButton aria-label="go-back" size="small" onClick={goToBack}>
          <BackIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="go-next" size="small" onClick={goToNext}>
          <NextIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography
          variant="h4"
          className={classes.calendarToolbarTitle}
          gutterBottom
        >
          {toolbar.label}
        </Typography>
      </Grid>
      <Grid item sm={3} xs={12}>
        <FormSelect
          gridSizes={[{ size: "md", val: 12 }]}
          name="calendar-view"
          label="View"
          options={viewOptions}
          value={toolbarView}
          handleBlur={handleChangeView}
        />
      </Grid>
    </Grid>
  );
};

export default CalendarToolbar;
