import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormContent from "components/ui/FormContent/formBody";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import Typography from "@material-ui/core/Typography";
import StepNav from "./stepNav";
import RequestConfirmationDialog from "./requestConfirmationDialog";
import RequestCompletedDialog from "./requestCompletedDialog";
import BackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/AddCircle";

import { useStateValue } from "components/util/state";
import { saveRequest } from "services/requestSurfaceService";
import { logException } from "components/util/logUtil";

import useStyles from "./styles";

const RequestStep3 = (props) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [
    { completed, list, data, step, requests, company },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    async function submitData() {
      list.forEach(async (item, index) => {
        try {
          const job = company.jobs.find(
            (job) => job.job_number === item.job_number
          );
          await saveRequest(item, job.customer_id);
          if (list.length === index + 1) {
            dispatch({
              type: "LOADING",
              loading: false,
            });
          }
        } catch (e) {
          logException(e, "Cannot create request from customer");
        }
      });
    }
    if ((company, completed)) {
      submitData();
    }
  }, [company, completed, data, list, dispatch]);

  useEffect(() => {
    if (requests) {
      setUpdate(0);
      setFiles([]);
    }
  }, [requests]);

  const handleOnClick = () => {
    if (!Boolean(list.length)) {
      dispatch({
        type: "UPDATE_DATA",
        updateData: {
          files: files,
        },
        newStep: 3,
      });
      dispatch({
        type: "ADD_REQUEST",
        status: true,
      });
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmit = () => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    dispatch({
      type: "UPDATE_DATA",
      updateData: {
        files: files,
      },
    });
    dispatch({
      type: "COMPLETED",
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 2,
    });
  };

  const handleAddRequest = () => {
    dispatch({
      type: "UPDATE_DATA",
      updateData: {
        files: files,
      },
      newStep: 3,
    });
    dispatch({
      type: "NEW_REQUEST",
    });
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
    setUpdate(newFileList.length);
  };

  if (step !== 3) return null;
  return (
    <>
      <div className={classes.headerContainer}>
        <StepNav step={step} list={list} />
        <Typography
          variant="h3"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Upload Documents & Photos
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Upload photos and documents of the request here.
        </Typography>
      </div>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.stepContainer}>
          <div className={classes.stepInnerContainer}>
            <FormContent>Documents</FormContent>
            <FileUpload
              value={files}
              handleChange={setFiles}
              handleUpdate={setUpdate}
              filesDisplay={
                Boolean(update) && (
                  <FilesDisplay files={files} handleRemove={handleRemove} />
                )
              }
            />
          </div>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.titleMultiRequest}
            onClick={handleAddRequest}
          >
            <AddIcon className={classes.iconMultiRequest} /> Add Request
          </Typography>
          <Box className={classes.actionBox}>
            <Button
              color="primary"
              className={classes.buttonBack}
              onClick={handleOnClickBack}
              startIcon={<BackIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonBegin}
              onClick={handleOnClick}
            >
              Submit
            </Button>
          </Box>
          <RequestConfirmationDialog
            openDialog={openDialog}
            handleCancel={setOpenDialog}
            handleSubmit={handleSubmit}
            name={`${data.first_name} ${data.last_name}`}
          />
          <RequestCompletedDialog />
        </div>
      </Container>
    </>
  );
};

export default RequestStep3;
