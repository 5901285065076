import React, { useContext } from "react";
import RingBellTabSurface from "./ringBellTabSurface";
import RingBellTab from "./ringBellTab";
import RingBellDialog from "./dialog/ringBellDialog";
import { RingBellSurfaceProvider } from "contexts/ringBellSurfaceContext";
import { RingBellProvider } from "contexts/ringBellContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";

const RingBellTabContainer = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      {hasPermission(permissionSurface.MAP, permissions) ? (
        <RingBellSurfaceProvider>
          <RingBellTabSurface selectedLocation={props.selectedLocation} />
          <RingBellDialog />
        </RingBellSurfaceProvider>
      ) : (
        <RingBellProvider>
          <RingBellTab selectedLocation={props.selectedLocation} />
          <RingBellDialog />
        </RingBellProvider>
      )}
    </>
  );
};

export default withTableFilterContext(RingBellTabContainer);
