import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import * as classNames from "classnames";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { dataURLtoFile } from "components/util/fileUtil";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import diagram from "./diagram.png";
import useStyles from "./styles";
import BackIcon from "@material-ui/icons/NavigateBefore";

const isnum = (val) => /^\d+$/.test(val);
export default function AutoGrid({
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
  currentStep,
  setCurrentStep,
  totalSteps,
}) {
  const [teamMember, setTeamMember] = useState("");
  const [teamMemberNew, setTeamMemberNew] = useState("");

  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");

  const { globalUi } = useContext(GlobalUiContext);
  const [addMultiple, setAddMultiple] = useState([
    {
      witness_name: "",
    },
  ]);
  const addMultipleObj = {
    witness_name: "",
  };
  const loadImage = (url) => {
    return new Promise((r) => {
      let i = new Image();
      i.onload = () => r(i);
      i.src = url;
    });
  };
  const handleClearForBody = () => {
    let canvas = document.getElementsByTagName("canvas")[0];
    if (canvas.getContext) {
      let ctx = canvas.getContext("2d");
      loadImage(diagram).then((img) => {
        ctx.drawImage(img, 0, 0, 340, 400);
      });
    }
  };

  useEffect(() => {
    let canvas = document.getElementsByTagName("canvas")[0];
    if (canvas.getContext) {
      let ctx = canvas.getContext("2d");
      loadImage(diagram).then((img) => {
        ctx.drawImage(img, 0, 0, 340, 400);
      });
    }
  }, []);

  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, [customerSelected?.value]);
  const classes = useStyles();
  const [form, setForm] = useState({ home_phone: "", cell_phone: "" });
  let sigPad = useRef(null);
  let sigPadBody = useRef(null);
  let validAddMultiple = true;
  addMultiple.map((v) => {
    if (!v.witness_name) {
      validAddMultiple = false;
    }
  });
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        onChange={(e) => {
          if (e.target.id) {
            if (e.target.id.length === 1) {
              addMultiple[e.target.id][e.target.name] = e.target.value;
              setAddMultiple([...addMultiple]);
              return;
            }
          }
          if (
            e.target.name === "home_phone" ||
            e.target.name === "cell_phone"
          ) {
            if (isnum(e.target.value.trim())) {
              setForm({ ...form, [e.target.name]: e.target.value });
            }
            return;
          }

          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid item xs={12} md={12} className={classes.inputContainer}>
          <Box
            style={{
              overflow: "auto !important",
              overflowX: "hidden",
            }}
          >
            <span style={{ display: currentStep === 1 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.subTitle}>
                        Person Conducting Investigation
                      </Typography>

                      <FormSelectAuto
                        options={userOptions}
                        name="user"
                        handleChange={(e, v) => setTeamMemberNew(v)}
                        value={teamMemberNew}
                        inputContainerProps={classes.inputContainer}
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.subTitle}>
                    Job Name(*)
                  </Typography>

                  <span>
                    {Boolean(jobs) ? (
                      <FormSelectJobsAuto
                        options={jobs}
                        name="job_number"
                        handleChange={(e, v) => setJobSelected(v)}
                        value={jobSelected}
                        inputContainerProps={classes.inputContainer}
                      />
                    ) : (
                      <LinearProgress color="secondary" />
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Workers compensation carrier
                  </Typography>
                  <TextField
                    name="person_conducting_investigation_workers_comp_carrier"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Employee of Accident / Incident(*)
                  </Typography>
                  <FormSelectAuto
                    options={userOptions}
                    name="user"
                    handleChange={(e, v) => setTeamMember(v)}
                    value={teamMember}
                    inputContainerProps={classes.inputContainer}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className={classes.inputContainerDateAdj}
              >
                <Typography className={classes.subSubTitle}>
                  Date of Birth
                </Typography>

                <FormInputDateAdo
                  name="dob"
                  value={form?.dob}
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      dob: e,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.inputContainer}>
                <Typography className={classes.subSubTitle}>Sex</Typography>
                <Grid item xs={12} md={12}>
                  <RadioGroup
                    aria-label="radio"
                    name="radio"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      name="sex"
                      value="male"
                      control={<Radio color="primary" />}
                      label="M"
                    />
                    <FormControlLabel
                      name="sex"
                      value="female"
                      control={<Radio color="primary" />}
                      label="F"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.inputContainerDateAdj}
              >
                <Typography className={classes.subSubTitle}>
                  Hire Date
                </Typography>

                <FormInputDateAdo
                  name="hire_date"
                  value={form?.hire_date}
                  handleChange={(e) => {
                    setForm({
                      ...form,
                      hire_date: e,
                    });
                  }}
                />
              </Grid>
            </span>
            <span style={{ display: currentStep === 2 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Home Phone # (Area code)
                  </Typography>
                  <TextField
                    name="home_phone"
                    value={form.home_phone}
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Cell Phone # (Area code)
                  </Typography>
                  <TextField
                    name="cell_phone"
                    value={form.cell_phone}
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    {" "}
                    Home Address
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Street(*)
                  </Typography>
                  <TextField
                    name="street"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Apt. #(*)
                  </Typography>
                  <TextField
                    name="apt"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    City(*)
                  </Typography>
                  <TextField
                    name="city"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    State(*)
                  </Typography>
                  <TextField
                    name="state"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Zip(*)
                  </Typography>
                  <TextField
                    name="zip"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 3 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Description of Accident/Incident (Be specific)(*)
                  </Typography>
                  <TextField
                    name="description_of_accident"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of Accident/Incident(*)
                  </Typography>

                  <FormInputDateAdo
                    name="date_of_accident"
                    value={form?.date_of_accident}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        date_of_accident: e,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Time of Accident/Incident(*)
                  </Typography>
                  <TextField
                    name="time_of_accident"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Day first reported
                  </Typography>
                  <FormInputDateAdo
                    name="day_first_reported"
                    value={form?.day_first_reported}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        day_first_reported: e,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Job being performed(*)
                  </Typography>
                  <TextField
                    name="job_being_performed"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Part of the body Affected(*)
                  </Typography>
                  <TextField
                    name="part_of_the_body_affected"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    Was the person performing his/her regular duties?
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.part_of_regular_duty === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="part_of_regular_duty"
                          value="yes"
                          onClick={() =>
                            setForm({ ...form, part_of_regular_duty: "yes" })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.part_of_regular_duty === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="part_of_regular_duty"
                          value="no"
                          onClick={() =>
                            setForm({ ...form, part_of_regular_duty: "no" })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.inputContainerDateAdj}>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography>
                    Place a (x) mark on the body frame bellow indicating what
                    particular area(s) on your body that is injured. Specify
                    whether it is in the front or back as well. Make sure it
                    matches the description you have written down describing the
                    accident.
                  </Typography>
                </Grid>
                <Box className={classes.inputContainerDateAdj}>
                  <Box className={classes.bodyImageCentered}>
                    <SignatureCanvas
                      penColor="red"
                      canvasProps={{
                        width: 340,
                        height: 400,
                        className: classes.sigCanvas,
                      }}
                      ref={(ref) => {
                        sigPadBody = ref;
                      }}
                    />
                  </Box>
                </Box>

                <Grid container className={classes.centerAlignBtn}>
                  <Button
                    autoFocus
                    className={classes.markFilledButton}
                    onClick={() => {
                      handleClearForBody();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 4 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    {" "}
                    Location of Accident/Incident
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Street(*)
                  </Typography>
                  <TextField
                    name="accident_street"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Apt. #(*)
                  </Typography>
                  <TextField
                    name="accident_apt"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    City(*)
                  </Typography>
                  <TextField
                    name="accident_city"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    State(*)
                  </Typography>
                  <TextField
                    name="accident_state"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Zip(*)
                  </Typography>
                  <TextField
                    name="accident_zip"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    Please list any witnesses
                  </Typography>
                  {addMultiple?.map((v, i) => {
                    return (
                      <Box className={classes.inputContainer} key={i}>
                        <Typography className={classes.subSubTitle}>
                          Name(*)
                        </Typography>
                        <TextField
                          name="witness_name"
                          className={classes.field}
                          InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                          }}
                          fullWidth
                          id={i}
                        />
                      </Box>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.inputContainer}>
                    <Box className={classes.plusRightEnd}>
                      {addMultiple.length > 1 && (
                        <IndeterminateCheckBoxIcon
                          className={classes.iconMultiRequest}
                          onClick={() => {
                            addMultiple.pop();
                            setAddMultiple([...addMultiple]);
                          }}
                        />
                      )}
                      &nbsp;
                      <AddCircleIcon
                        className={classes.iconMultiRequest}
                        onClick={() => {
                          addMultiple.push({ ...addMultipleObj });
                          setAddMultiple([...addMultiple]);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Cause of incident (Be specific)(*)
                  </Typography>
                  <TextField
                    name="cause_of_incident"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subTitle}>
                    Was medical treatment requested?
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.med_treatment_requested === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="med_treatment_requested"
                          value="yes"
                          onClick={() =>
                            setForm({ ...form, med_treatment_requested: "yes" })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.med_treatment_requested === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="med_treatment_requested"
                          value="no"
                          onClick={() =>
                            setForm({ ...form, med_treatment_requested: "no" })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
                {form?.med_treatment_requested &&
                  form?.med_treatment_requested === "yes" && (
                    <Box className={classes.boxYes}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.inputContainer}
                      >
                        <Typography className={classes.subSubTitle}>
                          If "YES" WHERE (Name of hospital or clinic)
                        </Typography>
                        <TextField
                          name="name_of_hospital"
                          className={classes.field}
                          InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.inputContainer}
                      >
                        <Typography className={classes.subSubTitle}>
                          Address of hospital or clinic
                        </Typography>
                        <TextField
                          name="address_of_hospital"
                          className={classes.field}
                          InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Box>
                  )}
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    What actions have been taken to prevent the recurrence of
                    the incident?
                  </Typography>
                  <TextField
                    name="actions_taken"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of corrective action
                  </Typography>
                  <FormInputDateAdo
                    name="date_of_corrective_action"
                    value={form?.date_of_corrective_action}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        date_of_corrective_action: e,
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ justifyContent: "center" }}>
                <Typography className={classes.subTitle}>Signature</Typography>
              </Grid>
              <br />
              <br />
              <Grid
                container
                style={{ justifyContent: "center" }}
                className={classes.inputContainerDateAdj}
              >
                <SignatureCanvas
                  penColor="green"
                  canvasProps={{
                    width: 340,
                    height: 100,
                    className: classes.sigCanvas,
                  }}
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                />
              </Grid>
              <Box className={classes.inputContainerDateAdj}>
                <br />
                <Grid container className={classes.centerAlignBtn}>
                  <Button
                    autoFocus
                    className={classes.markFilledButton}
                    onClick={() => {
                      sigPad.clear();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Box>
            </span>
            <Box className={classes.trContainer}>
              <Box className={classes.trSubContainer}>
                <Box className={classes.flex}>
                  {currentStep === 1 ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className={classes.buttonBack}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                      startIcon={<BackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                    disabled={
                      currentStep === 1
                        ? !jobSelected?.id || !teamMember?.value
                        : currentStep === 2
                        ? !form?.street ||
                          !form?.apt ||
                          !form?.city ||
                          !form?.street ||
                          !form?.zip
                        : currentStep === 3
                        ? !form?.date_of_accident ||
                          !form?.time_of_accident ||
                          !form?.job_being_performed ||
                          !form?.part_of_the_body_affected ||
                          !form?.description_of_accident
                        : currentStep === 4
                        ? !form?.accident_street ||
                          !form?.accident_apt ||
                          !form?.accident_city ||
                          !form?.accident_state ||
                          !form?.accident_zip ||
                          !form?.cause_of_incident ||
                          !validAddMultiple
                        : false
                    }
                    onClick={() => {
                      if (currentStep === totalSteps) {
                        const arrWitness = addMultiple.map(
                          (v) => v.witness_name
                        );
                        const templatePayload = {
                          dob: form.dob,
                          requestor_type: teamMemberNew.value ? 2 : 1,
                          requestor_user_id: teamMemberNew.value,
                          user_id: teamMember.value,
                          job_id: jobSelected.id,
                          worker_compensation_carrier:
                            form.person_conducting_investigation_workers_comp_carrier,
                          gender: form.sex,
                          hire_date: form.hire_date,
                          home_phone: form.home_phone,
                          cell_phone: form.cell_phone,
                          home_street: form.street,
                          home_apt: form.apt,
                          home_city: form.city,
                          home_state: form.state,
                          home_zip: form.zip,
                          accident_description: form.description_of_accident,
                          accident_date: form.date_of_accident,
                          accident_time: form.time_of_accident,
                          accident_date_first_report: form.day_first_reported,
                          accident_job_performed: form.job_being_performed,
                          accident_part_affected:
                            form.part_of_the_body_affected,
                          regular_duties:
                            form.part_of_regular_duty === "yes" ? 1 : 0,
                          accident_image: "",
                          accident_street: form.accident_street,
                          accident_apt: form.accident_apt,
                          accident_city: form.accident_city,
                          accident_state: form.accident_state,
                          accident_zip: form.accident_zip,
                          witnesses: arrWitness,
                          signature_image: "",
                          accident_cause: form.cause_of_incident,
                          medical_treatment:
                            form.med_treatment_requested === "yes" ? 1 : 0,
                          name_hospital_clinic: form.name_of_hospital,
                          address_hospital_clinic: form.address_of_hospital,
                          taken_action_prevent: form.actions_taken,
                          corrective_action_date:
                            form.date_of_corrective_action,
                        };

                        templatePayload.accident_image = dataURLtoFile(
                          document
                            .getElementsByTagName("canvas")[0]
                            .toDataURL(),
                          "accident_image"
                        );
                        templatePayload.signature_image = dataURLtoFile(
                          document
                            .getElementsByTagName("canvas")[1]
                            .toDataURL(),
                          "signature_image"
                        );

                        Object.keys(templatePayload).map((v) => {
                          if (!templatePayload[v]) {
                            templatePayload[v] = "";
                          }
                        });
                        submitFormDataBasedOnCurrentTab(
                          templatePayload,
                          topTab,
                          formSelected
                        ).then((res) => {
                          if (res.status === 201) {
                            closeAndRefreshSidebar();
                          }
                        });
                      }
                      if (currentStep < totalSteps) {
                        setCurrentStep(++currentStep);
                      }
                    }}
                  >
                    {" "}
                    {currentStep === totalSteps ? "Submit" : "Next"}
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
