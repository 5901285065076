import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import MessageDialog from "components/ui/dialog/messageDialog";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import AssetsIcon from "@material-ui/icons/Toys";
import { saveAssetCategory } from "services/assetService";
import { logException } from "components/util/logUtil";

const useStyles = makeStyles((theme) => ({
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  formContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  chipJob: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerJobInput: {
    "& > div": {
      marginBottom: 10,
    },
    "& input": {
      fontSize: 14,
    },
  },
  containerError: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    textAlign: "center",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
}));

const initNewData = {
  name: "",
};

const AssetsCategoryManage = (props) => {
  const classes = useStyles();
  const { open, handleClose, handleUpdateDate } = props;
  const [newData, setNewData] = useState(initNewData);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      setNewData(initNewData);
    }
  }, [open]);

  const handleCreateBlur = async (event) => {
    setError("");
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleCreate = async () => {
    if (newData.name === "") {
      setError("Enter name for the category.");
      return;
    }

    try {
      const dataSave = { ...newData };
      await saveAssetCategory(dataSave);
      handleUpdateDate();
      setOpenSuccess(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot create category");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DialogCloseIcon handleClose={handleClose} />
          <DialogTitle title="Create Category" icon={<AssetsIcon />} />

          <Box className={classes.formContainerDialog}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="name"
              label="Name"
              value={newData.name}
              handleBlur={handleCreateBlur}
            />
          </Box>
        </DialogContent>

        {Boolean(error) && (
          <Box className={classes.containerError}>{error}</Box>
        )}
        <DialogActions className={classes.actionsDialogWrapper}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonPrimary)}
            onClick={handleCreate}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Category has been created.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default AssetsCategoryManage;
