import React, { useState, useEffect } from "react";
import moment from "moment";
import * as classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AssetTask from "./assetTask";
import Grid from "@material-ui/core/Grid";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import RedirectDialog from "components/ui/dialog/redirectDialog";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import IconButton from "@material-ui/core/IconButton";
import AssetsIcon from "@material-ui/icons/Toys";
import CloseIcon from "@material-ui/icons/Close";
import { logException } from "components/util/logUtil";
import {
  updateAssetDetails,
  saveAssetFile,
  getAssetCategories,
  getAssetJobs,
  getAssetDetails,
} from "services/assetService";
import { useAssetState } from "contexts/assetContext";

const useStyles = makeStyles((theme) => ({
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  dialogHeader: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        margin: "0 auto",
      },
    },
  },
  formIconDialog: {
    textAlign: "center",
  },
  formTitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  formContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  chipJob: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerJobInput: {
    "& > div": {
      marginBottom: 10,
    },
    "& input": {
      fontSize: 14,
    },
  },
  containerError: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    textAlign: "center",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
}));

const initNewData = {
  name: "",
  description: "",
  zone: "",
  warrantyIniDate: moment(),
  warrantyEndDate: moment(),
  warrantyOther: "",
  brand: "",
  serial: "",
  model: "",
  tag: "",
  tasks: null,
};

const AssetsUpdate = (props) => {
  const classes = useStyles();
  const { assetAction } = useAssetState();
  const { open, handleClose, handleUpdateDate } = props;
  const [asset, setAsset] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesUsed, setCategoriesUsed] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  // const [customer, setCustomer] = useState(0);
  const [step, setStep] = useState(1);
  const [newData, setNewData] = useState(initNewData);
  const [files, setFiles] = useState([]);
  const [updateFile, setUpdateFile] = useState(0);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [openRedirect, setOpenRedirect] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadAsset = async () => {
      try {
        const result = await getAssetDetails(assetAction.id);
        setAsset(result.data.data.asset);
        setFilesDisplay(result.data.data.asset.files);
        setLoadingCategories(true);
        setCategories([]);
      } catch (e) {
        logException(e, "Cannot load asset");
      }
    };
    if (open && assetAction) {
      loadAsset();
    }
  }, [open, assetAction]);

  useEffect(() => {
    if (asset) {
      setNewData({
        name: asset.name,
        description: asset.description,
        zone: asset.zone ?? "",
        warrantyIniDate: moment(asset.warrantyIniDate),
        warrantyEndDate: moment(asset.warrantyEndDate),
        warrantyOther: asset.warrantyOther ?? "",
        brand: asset.brand ?? "",
        serial: asset.serial ?? "",
        model: asset.model ?? "",
        tag: asset.tag ?? "",
        tasks: asset.tasks ? JSON.parse(asset.tasks) : null,
      });
    }
  }, [asset]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await getAssetCategories();
        const renderCategories = result.data.data.categories.map((category) => {
          return { value: category.id, label: category.name };
        });
        setCategories(renderCategories);
        const catSelected = asset.categories.map((cat) => cat.id);
        setCategoriesSelected(
          renderCategories.filter((cat) => catSelected.includes(cat.value))
        );
        setCategoriesUsed(catSelected);
        setLoadingCategories(false);
      } catch (e) {
        logException(e, "Cannot load categories");
      }
    };

    if (open && categories && !categories.length && asset) {
      loadCategories();
    }
  }, [open, categories, asset]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const resultJob = await getAssetJobs();
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer ? job.customer.id : 0,
          };
        });
        setJobsData(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs");
      }
    };
    if (open && jobsData && !jobsData.length && asset) {
      loadJobs();
    }
  }, [open, jobsData, asset]);

  useEffect(() => {
    if (open && jobsData && asset) {
      setJobsList([...asset.jobs]);
    }
  }, [open, jobsData, asset]);

  // useEffect(() => {
  //   const loadJobsCustomer = async () => {
  //     try {
  //       const findJob = jobsData.find((job) => job.id === jobsList[0].id);
  //       if (findJob) {
  //         const resultJob = await getAssetJobs(findJob.customer_id);
  //         setCustomer(findJob.customer_id);
  //         const updateJobList = Object.keys(resultJob.data.data.jobs).map(
  //           (i) => resultJob.data.data.jobs[i]
  //         );
  //         const resultForRender = updateJobList.map((job) => {
  //           return {
  //             id: job.id,
  //             job_description: job.job_description,
  //             job_number: job.job_number,
  //             customer_id: job.customer ? job.customer.id : 0,
  //           };
  //         });
  //         setJobsData(resultForRender);
  //       }
  //     } catch (e) {
  //       logException(e, "Cannot load customer jobs");
  //     }
  //   };
  //   if (jobsList.length === 1 && customer === 0) {
  //     loadJobsCustomer();
  //   }
  // }, [jobsList, jobsData, customer]);

  const handleCreateBlur = async (event) => {
    setError("");
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleWarrantyIniDate = async (event) => {
    const data = {
      ...newData,
      warrantyIniDate: event,
    };
    setNewData(data);
  };

  const handleWarrantyEndDate = async (event) => {
    const data = {
      ...newData,
      warrantyEndDate: event,
    };
    setNewData(data);
  };

  const handleUpdate = async () => {
    if (newData.name === "") {
      setError("Enter name for the asset.");
      return;
    }
    if (newData.description === "") {
      setError("Enter description for the asset.");
      return;
    }
    if (!categoriesUsed.length) {
      setError("Select at least one category for the asset.");
      return;
    }
    if (!jobsList.length) {
      setError("Select at least one job for the asset.");
      return;
    }
    try {
      // Asset current customer and jobs
      const addCategories = [];
      const removeCategories = [];
      const addJobs = [];
      const removeJobs = [];

      const currentCategories = asset.categories.map((cat) => cat.id);
      const currentJobs = asset.jobs.map((job) => job.id);
      const jobsUsed = jobsList.map((job) => job.id);

      categoriesUsed.map((id) => {
        if (!currentCategories.includes(id)) {
          addCategories.push(id);
        }
        return true;
      });

      currentCategories.map((id) => {
        if (!categoriesUsed.includes(id)) {
          removeCategories.push(id);
        }
        return true;
      });

      jobsUsed.map((id) => {
        if (!currentJobs.includes(id)) {
          addJobs.push(id);
        }
        return true;
      });

      currentJobs.map((id) => {
        if (!jobsUsed.includes(id)) {
          removeJobs.push(id);
        }
        return true;
      });

      const dataSave = {
        ...newData,
        warrantyIniDate: moment(newData.warrantyIniDate).format("YYYY-MM-DD"),
        warrantyEndDate: moment(newData.warrantyEndDate).format("YYYY-MM-DD"),
        tasks: newData.tasks ? JSON.stringify(newData.tasks) : null,
        add_job_ids: addJobs,
        remove_job_ids: removeJobs,
        add_category_ids: addCategories,
        remove_category_ids: removeCategories,
      };
      await updateAssetDetails(asset.id, dataSave);

      // Files Upload
      if (files.length) {
        await saveAssetFile(asset.id, { files: files });
      }
      handleUpdateDate();
      setOpenRedirect(true);
      handleClose();
    } catch (e) {
      logException(e, "Cannot update asset");
    }
  };

  const handleChangeCategories = (event, value) => {
    setError("");
    setCategoriesUsed(value.map((val) => val.value));
    if (value) {
      setCategoriesSelected(value);
    } else {
      setCategoriesSelected([]);
    }
  };

  const handleChangeJob = (event, value) => {
    setError("");
    if (value) {
      jobsList.push(value);
      setJobsList([...jobsList]);
    }
  };

  const handleJobDelete = (id) => {
    const updateJobsList = jobsList.filter((job) => job.id !== id);
    setJobsList(updateJobsList);
    if (!updateJobsList.length) {
      setJobsData([]);
    }
  };

  const handleChangeTask = (task) => {
    const data = {
      ...newData,
      tasks: task,
    };
    setNewData(data);
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = filesDisplay.filter((file) => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  const handleStepChange = (step) => {
    setError("");
    setStep(step);
  };

  const handleRedirectClose = () => {
    setOpenRedirect(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {loadingCategories ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperClose}
              >
                <CloseIcon className={classes.iconClose} />
              </IconButton>
              <Grid container spacing={2} className={classes.dialogHeader}>
                <Grid item md={12}>
                  <Box className={classes.formIconDialog}>
                    <AssetsIcon className={classes.iconDialog} />
                  </Box>
                  <Typography
                    variant="h4"
                    className={classes.formTitleDialog}
                    gutterBottom
                  >
                    Update Asset
                  </Typography>
                </Grid>
              </Grid>
              <Box className={classes.formContainerDialog}>
                <ButtonGroup
                  variant="outlined"
                  color="default"
                  aria-label="primary button group"
                  className={classes.groupOption}
                  fullWidth={true}
                >
                  <Button
                    key={`step-1`}
                    className={step === 1 ? classes.groupOptionActive : null}
                    onClick={() => handleStepChange(1)}
                  >
                    Information
                  </Button>
                  <Button
                    key={`step-3`}
                    className={step === 3 ? classes.groupOptionActive : null}
                    onClick={() => handleStepChange(3)}
                  >
                    Tasks
                  </Button>
                  <Button
                    key={`step-2`}
                    className={step === 2 ? classes.groupOptionActive : null}
                    onClick={() => handleStepChange(2)}
                  >
                    Documents
                  </Button>
                </ButtonGroup>
              </Box>
              {step === 1 ? (
                <Box className={classes.formContainerDialog}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="name"
                    label="Name"
                    value={newData.name}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="description"
                    label="Description"
                    value={newData.description}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="zone"
                    label="Zone"
                    value={newData.zone}
                    handleBlur={handleCreateBlur}
                  />
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={categories}
                    name="categories"
                    label="Categories"
                    handleChange={handleChangeCategories}
                    multiple={true}
                    value={categoriesSelected}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="brand"
                    label="Brand Name"
                    value={newData.brand}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="serial"
                    label="Serial Number"
                    value={newData.serial}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="model"
                    label="Model #"
                    value={newData.model}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="tag"
                    label="Asset Tag #"
                    value={newData.tag}
                    handleBlur={handleCreateBlur}
                  />
                  <FormInputDateAdo
                    name="warrantyIniDate"
                    label="Warranty Begin Date"
                    handleChange={handleWarrantyIniDate}
                    value={newData.warrantyIniDate}
                  />
                  <FormInputDateAdo
                    name="warrantyEndDate"
                    label="Warranty End Date "
                    handleChange={handleWarrantyEndDate}
                    value={newData.warrantyEndDate}
                  />
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="warrantyOther"
                    label="Warranty Notes"
                    value={newData.warrantyOther}
                    handleBlur={handleCreateBlur}
                  />
                  <Box className={classes.containerJobInput}>
                    <FormSelectJobsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={jobsData}
                      name="job_number"
                      label="Jobs"
                      multiple={false}
                      handleChange={handleChangeJob}
                    />
                  </Box>
                  <Box className={classes.containerSelectedElements}>
                    {jobsList.map((job) => (
                      <Chip
                        key={job.id}
                        size="small"
                        label={`${job.job_number} - ${job.job_description}`}
                        onDelete={() => handleJobDelete(job.id)}
                        className={classes.chipJob}
                      />
                    ))}
                  </Box>
                </Box>
              ) : null}
              {step === 2 ? (
                <Box className={classes.formContainerDialog}>
                  {!Boolean(files.length) && (
                    <Typography
                      variant="h4"
                      className={classes.titleSectionBody}
                      gutterBottom
                    >
                      Documents
                    </Typography>
                  )}
                  <FileUpload
                    value={files}
                    handleChange={setFiles}
                    handleUpdate={setUpdateFile}
                    filesDisplay={
                      Boolean(updateFile) && (
                        <FilesDisplay
                          files={files}
                          handleRemove={handleRemove}
                        />
                      )
                    }
                  />
                  <Box className={classes.containerDisplayFiles}>
                    <FilesDisplayUploaded
                      files={filesDisplay}
                      handleRemoveDisplay={handleRemoveDisplay}
                    />
                  </Box>
                </Box>
              ) : null}
              {step === 3 ? (
                <Box className={classes.formContainerDialog}>
                  <AssetTask
                    taskList={newData.tasks}
                    onChange={handleChangeTask}
                  />
                </Box>
              ) : null}
            </DialogContent>
            {Boolean(error) && (
              <Box className={classes.containerError}>{error}</Box>
            )}

            <DialogActions className={classes.actionsDialogWrapper}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <RedirectDialog
        open={openRedirect}
        hasClose={false}
        handleClose={handleRedirectClose}
        title="Success"
        message={"Asset has been updated"}
        redirectLabel={"Okay"}
        redirect={"/assets/admin"}
      />
    </>
  );
};

export default AssetsUpdate;
