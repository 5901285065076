import React, { useState, useEffect, useContext } from "react";
import { useQueryClient } from "react-query";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateWorkticketBulkStatus } from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { numberToWord } from "components/util/stringFormat";
import {
  billingLimitDate,
  convertDateFormatField,
} from "components/util/timeFormat";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const DoneDialog = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [date, setDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [confirmationDate, setConfirmationDate] = useState(false);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openDone } = workticketAction;
  const { selected, rows } = tableUi;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (openDone) {
      setDate(null);
      if (selected.length === 1) {
        const foundRow = rows.find((row) => row.id === selected[0]);
        setSelectedRow(foundRow);
      } else {
        setSelectedRow(null);
      }
    }
  }, [openDone, rows, selected]);

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_DONE",
      open: false,
    });
    setConfirmationDate(false);
    setDate(null);
  };

  const handleConfirmDate = () => {
    setConfirmationDate(true);
  };

  const handleConfirm = async () => {
    if (selected.length) {
      setIsLoadingDialog(true);
      const doneStatus = 2;
      let data = {
        workticket_ids: selected,
        status: doneStatus,
      };
      if (hasPermission(permissionWorkticket.SET_COMPLETED_DATE, permissions)) {
        data = { ...data, completed_date: moment(date).format("YYYY-MM-DD") };
      }
      try {
        const result = await updateWorkticketBulkStatus(data);
        if (result) {
          dispatchTable({
            type: "SET_TAB_UPDATED",
            tabUpdated: { tab: doneStatus, count: selected.length },
          });
          // Update content on current tab and done status
          queryClient.invalidateQueries(["worktickets", doneStatus]);
          // Mark updated fields
          dispatchTable({ type: "SET_UPDATED_ROWS", rowsUpdated: selected });
          // Clear selections on table
          dispatchTable({ type: "SET_SELECTED", selected: [] });
          props.refetchData();
          handleClose();
          setIsLoadingDialog(false);
          setOpenSuccess(true);
        }
      } catch (e) {
        logException(e, "Cannot mark worktickets as verified.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleAddCompleteDate = () => {
    if (!selectedRow) {
      return;
    }
    setDate(selectedRow.sub_completed_date);
  };

  const backDatePerm = hasPermission(
    permissionWorkticket.BACK_DATE,
    permissions
  );

  let minLimitDate = undefined;
  let minDateMessage = undefined;
  if (!backDatePerm) {
    minLimitDate = billingLimitDate(5);
    minDateMessage = "Date not allowed, contact financial/admin team.";
  }

  return (
    <>
      <Dialog
        open={openDone}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Worktickets
              </Typography>

              {hasPermission(
                permissionWorkticket.SET_COMPLETED_DATE,
                permissions
              ) ? (
                <>
                  {confirmationDate && date ? (
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.contentDialog}
                    >
                      Are you sure, the date {moment(date).format("MM/DD/YYYY")}{" "}
                      is the date and month that billing will be allocated to?
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.contentDialog}
                      >
                        Are you sure you want to mark ({selected.length})
                        workticket
                        {selected.length > 1 ? "s" : ""} as{" "}
                        {hasPermission(
                          permissionWorkticket.MARK_DONE,
                          permissions
                        )
                          ? `verified`
                          : `completed`}
                        ?
                      </Typography>
                      {selectedRow?.sub_completed_date && (
                        <Alert
                          severity="info"
                          className={classes.alertContainer}
                          action={
                            <Button
                              color="primary"
                              size="small"
                              variant="contained"
                              onClick={handleAddCompleteDate}
                            >
                              Use Date +
                            </Button>
                          }
                        >
                          Marked completed on{" "}
                          {convertDateFormatField(
                            selectedRow.sub_completed_date
                          )}
                        </Alert>
                      )}
                      <Box className={classes.formContainer}>
                        <FormInputDateAdo
                          name="date"
                          label="Verified date*"
                          value={date}
                          minDate={minLimitDate}
                          minDateMessage={minDateMessage}
                          handleChange={setDate}
                          internal={true}
                          disableFuture={true}
                          errorMessage={
                            "Please select a valid work verify date"
                          }
                        />
                        <Typography variant="caption">
                          *If applicable, billing will be tied to this time
                          frame.
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.contentDialog}
                >
                  Are you sure you want to mark these{" "}
                  {numberToWord(selected.length)} ({selected.length}) workticket
                  {selected.length > 1 ? "s" : ""} as{" "}
                  {hasPermission(permissionWorkticket.MARK_DONE, permissions)
                    ? `verified`
                    : `completed`}
                  ?
                </Typography>
              )}
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              {hasPermission(
                permissionWorkticket.SET_COMPLETED_DATE,
                permissions
              ) ? (
                <Button
                  onClick={confirmationDate ? handleConfirm : handleConfirmDate}
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={!date}
                >
                  {confirmationDate ? `Yes` : `Verify`}
                </Button>
              ) : (
                <Button
                  onClick={handleConfirm}
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Okay
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket${
          selected.length > 1 ? "s" : ""
        } have been marked done.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default DoneDialog;
