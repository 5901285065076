import React, { Fragment, useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import UserTables from "./UserTables";
import { AddUserForm } from "./AddUserForm";
import { usePartnerState } from "contexts/partnerContext";
import { useUserDrawerContext } from "../context/UserDrawerContext";
import { usePartnerUsers } from "./useUsers";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import MessageDialog from "../../partnersList/dialog/messageDialog";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionPartner } from "lib/permissions";

export function UserManagement() {
  const classes = useStyles();
  const {
    handleDialogueClose,
    drawerState,
    toggleDrawer,
    isSuccessDialogOpen,
    successMessage,
  } = useUserDrawerContext();
  const { partnerDetails } = usePartnerState();
  const [searched, setSearched] = useState("");
  const { userPartnersList, areUsersLoading } = usePartnerUsers();
  const [tableData, setTableData] = useState(userPartnersList);
  const [rows, setRows] = useState(userPartnersList);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const requestSearch = (searchedVal) => {
    if (searchedVal === "") {
      setRows(tableData);
    } else {
      const filteredRows = tableData.filter((row) => {
        return (
          row.first_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.email.toLowerCase().includes(searchedVal.toLowerCase())
        );
      });
      setRows(filteredRows);
    }
  };

  const handleChange = (event) => {
    setSearched(event.target.value);
  };

  useEffect(() => {
    if (!areUsersLoading) {
      setTableData(userPartnersList);
      setRows(userPartnersList);
    }
  }, [areUsersLoading, userPartnersList]);

  useEffect(() => {
    requestSearch(searched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched]);

  return (
    <Fragment>
      {areUsersLoading && <LoadingIndicator />}
      {!areUsersLoading && (
        <Fragment>
          <Box className={classes.limitUserWrapperMobile}>
            <h3 className={classes.generalInformationSubHeader}>
              User Management
            </h3>
            <Box className={classes.userManagementMainContainer}>
              <Box className={classes.buttonsContainer}>
                <Box className={classes.createSearchContainer}>
                  <TextField
                    value={searched}
                    onChange={handleChange}
                    variant="standard"
                    placeholder={"Search for user"}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className={classes.findUserField}
                  />
                  {Number(partnerDetails.status) === 1 &&
                  hasPermission(
                    permissionPartner.PARTNER_MANAGE,
                    permissions
                  ) ? (
                    <Button
                      onClick={toggleDrawer("right", true, false)}
                      className={classes.createUserButton}
                    >
                      Create User
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <UserTables rows={rows} />
            </Box>
          </Box>
        </Fragment>
      )}
      <AddUserForm
        toggleDrawer={toggleDrawer("right", false)}
        drawerState={drawerState}
      />
      <MessageDialog
        open={isSuccessDialogOpen}
        handleClose={handleDialogueClose}
        title="Success"
        message={successMessage}
      />
    </Fragment>
  );
}
