import React from "react";
import moment from "moment";
import RequestStart from "./requestStart";
import RequestStep1 from "./requestStep1";
import RequestStep2 from "./requestStep2";
import RequestStep3 from "./requestStep3";
import RequestAddDialog from "./requestAddDialog";
import { StateProvider } from "components/util/state";

const RequestFacility = (props) => {
  const initialState = {
    company: null,
    list: [],
    data: {
      type: 0,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      job_department_id: "",
      job_zone_id: "",
      job_number: "",
      nearest_location: "",
      summary: "",
      description: "",
      due_date: moment().add(30, "days"),
      due_notes: "",
      files: undefined,
      status: 1,
    },
    step: 0,
    completed: false,
    addDialog: false,
    add: false,
    requests: 0,
    submitted: false,
    isLoading: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "START":
        return initialState;
      case "SET_COMPANY":
        return {
          ...state,
          company: action.company,
        };
      case "CHANGE_STEP":
        return {
          ...state,
          step: action.newStep,
        };
      case "UPDATE_DATA":
        return {
          ...state,
          data: {
            ...state.data,
            ...action.updateData,
          },
          isLoading: state.isLoading,
          step: action.newStep ?? state.step,
        };
      case "COMPLETED":
        const listSubmit = state.list;
        listSubmit.push(state.data);
        return {
          ...initialState,
          list: listSubmit,
          completed: true,
          isLoading: state.isLoading,
          step: state.step,
          company: state.company,
        };
      case "ADD_REQUEST":
        return {
          ...state,
          add: action.status,
        };
      case "NEW_REQUEST":
        const list = state.list;
        list.push(state.data);
        return {
          data: {
            ...state.data,
            job_number: "",
            building_zone: "",
            summary: "",
            description: "",
            due_date: moment().add(30, "days").format("YYYY-MM-DD"),
            due_notes: "",
            files: undefined,
          },
          list: list,
          step: 1,
          add: false,
          completed: false,
          requests: list.length,
          company: state.company,
        };
      case "LOADING":
        return {
          ...state,
          isLoading: action.loading,
        };
      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <RequestStart />
      <RequestStep1 />
      <RequestStep2 />
      <RequestStep3 />
      <RequestAddDialog />
    </StateProvider>
  );
};

export default RequestFacility;
