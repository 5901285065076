import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: 455,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#979797",
  },
  iconClose: {
    fontSize: 16,
  },
}));

export default useStyles;
