import React, { lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import { WorknovaDetailProvider } from "contexts/worknovaDetailTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { WorknovaDetailActionProvider } from "contexts/worknovaDetailActionContext";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorknovaDetailTable = lazy(() => import("./worknovaDetailTable"));
const WorknovaDetailDialog = lazy(() =>
  import("./dialog/worknovaDetailDialog")
);

const WorknovaDetailList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle={`Upload ${props.match.params.id}`}>
        <Link to="/worknova">Workorders</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <WorknovaDetailProvider>
          <WorknovaDetailActionProvider>
            <Suspense
              fallback={
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              }
            >
              <WorknovaDetailTable worknovaId={props.match.params.id} />
              <WorknovaDetailDialog />
            </Suspense>
          </WorknovaDetailActionProvider>
        </WorknovaDetailProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(WorknovaDetailList);
