import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    width: 37,
    height: 35,
    marginLeft: 20,
    marginRight: 20,
  },
  buttonStart: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4d90b2",
    }
  },
  buttonStop: {
    backgroundColor: "#328914",
    "&:hover": {
      backgroundColor: "#369117",
    }
  },
}));

export default useStyles;
