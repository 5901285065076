import React, { useState, useEffect, useCallback, useContext } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import NoticesFilters from "./noticesFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import NoticesTableActions from "./noticesTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { useIndividualView } from "contexts/individualViewContext";
import { ButtonBase } from "components/ui/core/button";
import { noticeTypes } from "constants.js";
import SideDrawer from "pages/ringBellList/drawer/sideDrawer";
import { permissionTalent, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";

import { useTableDispatch } from "contexts/tableContext";
import {
  useNoticesState,
  useNoticesDispatch,
  getNoticesList,
  getNoticesListDataSet,
  searchNoticesList,
} from "contexts/noticesContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "submitted_for",
    label: "Submitted For",
    path: "submitted_for",
  },
  {
    id: "submitted_date",
    label: "Date Submitted",
    path: "submitted_date",
    format: "date",
  },
  {
    id: "submitted_by",
    label: "Submitted By",
    path: "submitted_by",
  },
  {
    id: "type",
    label: "Notice Type",
    content: (row) =>
      noticeTypes.find((item) => parseInt(item.value) === parseInt(row.type))
        ?.label,
  },
  {
    id: "actions",
    content: (row) => <NoticesTableActions row={row} />,
    contentMobile: (row) => <NoticesTableActions row={row} mobile={true} />,
    style: { width: 55 },
  },
];

const NoticesTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;

  const {
    noticesListLoading,
    noticesList,
    noticesCount,
    openNoticesDrawer,
    openNoticesReadOnly,
    noticesData,
  } = useNoticesState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchNotices = useNoticesDispatch();
  const dispatchFilters = useFilterDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getNoticesList(dispatchNotices, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchNotices, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: noticesList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, noticesList]);

  const handleScrollClick = useCallback(async () => {
    if (noticesCount <= noticesList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getNoticesListDataSet(
        noticesList,
        dispatchNotices,
        noticesList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: noticesList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [noticesList, noticesCount, filterState, dispatchNotices, dispatchTable]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = [
            ...filterData.filters,
            {
              filter: "submittedFor",
              group: "Submitted For",
              values: [{ label: "", value: individual.id }],
            },
          ];
          const results = await searchNoticesList(query, filterData);

          dispatchNotices({
            type: "SET_NOTICES_LIST",
            noticesList: results,
          });

          dispatchNotices({
            type: "SET_NOTICES_COUNT",
            noticesCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterNotices: [],
    });
  };

  const handleNotice = (event) => {
    dispatchNotices({
      type: "TOGGLE_OPENNOTICE",
      openNoticesDrawer: true,
    });
  };

  const handleCloseNotice = () => {
    dispatchNotices({
      type: "TOGGLE_OPENNOTICE",
      openNoticesDrawer: false,
    });
    dispatchNotices({
      type: "TOGGLE_OPENNOTICE_READONLY",
      openNoticesReadOnly: false,
    });
    dispatchNotices({
      type: "SET_NOTICES_DATA",
      noticesData: null,
    });
  };

  const updateList = async () => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
      ];
      await getNoticesList(dispatchNotices, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  };

  return (
    <Box className={classes.root}>
      <SideDrawer
        isOpen={openNoticesDrawer}
        forUserId={individual?.id}
        closeDrawer={handleCloseNotice}
        handleUpdate={true}
        updateList={updateList}
        readonly={openNoticesReadOnly}
        noticesData={noticesData}
      />
      {noticesListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${noticesList.length} / ${noticesCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        {hasPermission(permissionTalent.NOTICE, permissions) ? (
                          <Box className={classes.listActionContainer}>
                            <ButtonBase
                              variant="outlined"
                              color="secondary"
                              onClick={handleNotice}
                            >
                              Notice
                            </ButtonBase>
                          </Box>
                        ) : (
                          ""
                        )}
                        <CalendarFilters />
                        <NoticesFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(NoticesTable);
