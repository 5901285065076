import React from "react";
import { Link } from "react-router-dom";

const FieldEmail = (props) => {
  const { field } = props;

  return (
    <Link
      to="#"
      style={{ color: "#4f97bb" }}
      onClick={(e) => {
        window.location = `mailto:${field}`;
        e.preventDefault();
      }}
    >
      {field}
    </Link>
  );
};

export default FieldEmail;
