import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import MessageDialog from "./dialog/messageDialog";
import { logException } from "components/util/logUtil";
import { addComment } from "services/partners/commentsService";
import useStyles from "./styles";

const AddCommentPartnerButton = ({
  file,
  partnerId,
  activeTab,
  commentStartTime,
  refreshData,
}) => {
  const classes = useStyles();
  const [newCommentText, setNewCommentText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleNewCommentChange = (event) => {
    const newComment = event.target.value;
    setNewCommentText(newComment);
  };

  const handleAddComment = async () => {
    if (newCommentText.trim() !== "") {
      const metadataObj = {
        metadata: {
          comment_time: commentStartTime || "",
        },
      };
      const metadataString = JSON.stringify(metadataObj);
      const commentData = {
        file_id: file.id,
        body: newCommentText,
        video_metadata: metadataString,
        tab: activeTab,
      };

      try {
        setIsLoadingData(true);
        setOpenMessage(true);
        handleNewCommentChange({ target: { value: "" } });
        await addComment(partnerId, commentData);
        setOpenMessageContent("Comment added successfully");
        setIsLoadingData(false);
        refreshData();
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  return (
    <Box className={classes.containerAddButton}>
      <Fab
        className={classes.addCommentButton}
        aria-label="add"
        size="small"
        disableRipple
        disableFocusRipple
        elevation={0}
      >
        <AddIcon />
      </Fab>
      <Input
        className={classes.commentInputText}
        type="text"
        placeholder="Add a Comment"
        multiline
        disableUnderline
        value={newCommentText}
        onChange={handleNewCommentChange}
      />

      <IconButton
        size="small"
        style={{ color: "#4F98BC" }}
        onClick={handleAddComment}
      >
        <SendIcon />
      </IconButton>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default AddCommentPartnerButton;
