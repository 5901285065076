import React, { useState, useEffect, useCallback } from "react";

import Select from "react-select";
import { FixedSizeList as List } from "react-window";

const ReactSelect = ({
  locations,
  onSelectChange,
  selectedLocation,
  selectedCustomer,
}) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const options = [];
    if (selectedCustomer?.id) {
      locations
        .filter((lItem) => lItem.customer_id === selectedCustomer.id)
        .map(
          (o, i) =>
            o &&
            o.job_description &&
            options.push({
              value: o.job_description,
              label: o.job_description,
              labelLowerCase: String(o.job_description).toLowerCase(),
              originalObject: o,
              key: i,
            })
        );
    } else {
      locations.map(
        (o, i) =>
          o &&
          o.job_description &&
          options.push({
            value: o.job_description,
            label: o.job_description,
            labelLowerCase: String(o.job_description).toLowerCase(),
            originalObject: o,
            key: i,
          })
      );
    }

    setOptions([...options]);
    setFilteredOptions([...options]);
  }, [locations, selectedCustomer]);

  const [selected, setSelected] = useState(
    selectedLocation?.job_description
      ? options.find(
          (option) =>
            selectedLocation.job_description === option.job_description
        )
      : ""
  );
  useEffect(() => {
    if (selectedLocation?.job_description) {
      setSelected({
        value: selectedLocation.job_description,
        label: selectedLocation.job_description,
        originalObject: selectedLocation,
      });
    } else {
      setSelected("");
    }
  }, [selectedLocation]);

  const customStyle = {
    option: (base, { isDisabled, isSelected }) => ({
      ...base,
    }),
    menu: (styles) => ({ ...styles, zIndex: "999 !important", padding: "5px" }),
  };

  const handleFilter = (candidate, input) => {
    if (input) {
      const listOfWords = input.trim().split(" ");
      let flag = [];

      for (let i = 0; i < listOfWords.length; i++) {
        if (
          candidate.label.toLowerCase().includes(input.trim().toLowerCase()) ||
          candidate.label.toLowerCase().includes(listOfWords[i].trim())
        ) {
          flag.push(true);
        }
      }
      if (flag.length === listOfWords.length && flag.indexOf(false) < 0) {
        return true;
      }
    }
    return candidate.label.includes(input) || candidate?.data?.__isNew__;
  };

  const height = 50;

  const MenuList = ({ options, children, maxHeight, getValue }) => {
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  return (
    <Select
      components={MenuList}
      options={filteredOptions}
      placeholder={"All Locations"}
      styles={customStyle}
      onChange={(e) => onSelectChange(e)}
      value={selected}
      menuPosition={"fixed"}
      filterOption={handleFilter}
      isClearable
    />
  );
};

export default ReactSelect;
