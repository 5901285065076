import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseIcon from "@material-ui/icons/Close";
import ShapeIcon from "assets/icons/shapeIcon";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { logException } from "components/util/logUtil";
import { usePartnerState, usePartnerDispatch } from "contexts/partnerContext";
import { usePartnerAction } from "contexts/partnerActionContext";
import { useTableDispatch } from "contexts/tableContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionPartner, hasPermission } from "lib/permissions";
import { partnerStatus } from "constants.js";
import {
  getInactiveReasons,
  changePartnerStatus,
} from "services/partnerService";
import useStyles from "./styles";

const inactiveStatusId = 4;

const validateFields = (status, reasonIds, note, inactiveStatusId) => {
  const errors = {};
  if (!status) errors.status = "Status is required.";
  if (status?.value === inactiveStatusId) {
    if (reasonIds.length === 0)
      errors.reason = "At least one reason is required.";
    if (!note) errors.note = "Note is required.";
  }
  return errors;
};

const ChangePartnerStatusDialog = (props) => {
  const classes = useStyles();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);
  const [noteContent, setNoteContent] = useState("");
  const [availableReasons, setAvailableReasons] = useState([]);
  const [errors, setErrors] = useState({});
  const [partnerOptions, setPartnerOptions] = useState([]);

  const [partnerAction, dispatchPartnerAction] = usePartnerAction();
  const { partnerSelected } = usePartnerState();
  const dispatchTable = useTableDispatch();
  const dispatchPartners = usePartnerDispatch();
  const { openChangeStatus } = partnerAction;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  useEffect(() => {
    const loadData = async () => {
      if (!partnerSelected) return;

      // previousStatus is used only when changing status to inactive from the select in the table
      const excludeStatus = partnerSelected.previousStatus
        ? parseInt(partnerSelected.previousStatus)
        : parseInt(partnerSelected.status);

      // Check permissions for "Inactive"
      const hasInactivatePermission = hasPermission(
        permissionPartner.PARTNER_INACTIVATE,
        permissions
      );

      const filteredOptions = partnerStatus.filter((option) => {
        // Do not show the "Inactive" option if the permission is not granted
        if (option.value === inactiveStatusId && !hasInactivatePermission) {
          return false;
        }
        return option.value !== excludeStatus;
      });

      setPartnerOptions(filteredOptions);

      if (partnerSelected.previousStatus) {
        const defaultStatusOption = partnerStatus.find(
          (option) => option.value === parseInt(partnerSelected.status)
        );
        setSelectedStatus(defaultStatusOption || null);
      }

      try {
        const response = await getInactiveReasons();
        setAvailableReasons(response.data.data.partner_inactive_reasons);
      } catch (error) {
        logException(error, "Failed to fetch partner data");
      }
    };

    loadData();
  }, [partnerSelected, permissions]);

  const handleConfirm = async () => {
    const validationErrors = validateFields(
      selectedStatus,
      selectedReasonIds,
      noteContent,
      inactiveStatusId
    );

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const data = {
        status: selectedStatus?.value || null,
        inactive_reason_ids: selectedReasonIds,
        note: noteContent,
      };

      setIsLoading(true);
      await changePartnerStatus(partnerSelected.id, data);

      props.refetchData();
      dispatchPartners({
        type: "UPDATE_PARTNER_STATUS",
        payload: {
          partnerId: partnerSelected.id,
          statusId: selectedStatus?.value,
        },
      });

      dispatchTable({
        type: "SET_UPDATED_ROWS",
        rowsUpdated: [partnerSelected.id],
      });

      setIsLoading(false);
      handleClose();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Cannot update status");
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    dispatchPartnerAction({
      type: "TOGGLE_CHANGE_STATUS",
      open: false,
    });

    resetStates();
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    resetStates();
  };

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue || null);
    if (newValue?.value === inactiveStatusId) {
      setSelectedReasonIds(selectedReasonIds);
    } else {
      setSelectedReasonIds([]);
    }
    setErrors((prev) => ({ ...prev, status: false }));
  };

  const handleReasonChange = (event, newValue) => {
    setSelectedReasonIds(newValue.map((r) => r.value) || []);
    setErrors((prev) => ({ ...prev, reason: false }));
  };

  const resetStates = () => {
    setSelectedStatus(null);
    setSelectedReasonIds([]);
    setNoteContent("");
    setErrors({});
  };

  return (
    <>
      <Dialog
        open={isLoading}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openChangeStatus && !isLoading}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <DialogTitle className={classes.wrapperDialog}>
          <Box className={classes.titleWithIcon}>
            <ShapeIcon />
            <Typography
              className={classes.titleDialog}
              variant="caption"
              gutterBottom
            >
              Change Partner Status
            </Typography>
          </Box>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Change the Partner Status by choosing one of the options below.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.selectContainer}>
            <FormSelectAuto
              multiple={false}
              name="status"
              label="Status*"
              options={partnerOptions}
              value={selectedStatus}
              handleChange={handleStatusChange}
              error={
                errors.status ? [{ key: "status", message: errors.status }] : []
              }
              placeholder="Select Status"
            />
          </Box>
          {selectedStatus?.value === inactiveStatusId && (
            <>
              {availableReasons.length === 0 ? (
                <LinearProgress color="secondary" />
              ) : (
                <Box className={classes.selectContainer}>
                  <FormSelectAuto
                    multiple
                    name="reasons"
                    label="Inactive Reason*"
                    placeholder="Select Reasons"
                    options={availableReasons.map((reason) => ({
                      value: reason.id,
                      label: reason.reason_name,
                    }))}
                    value={availableReasons
                      .filter((r) => selectedReasonIds.includes(r.id))
                      .map((reason) => ({
                        value: reason.id,
                        label: reason.reason_name,
                      }))}
                    handleChange={handleReasonChange}
                    error={
                      errors.reason
                        ? [
                            {
                              key: "reasons",
                              message: errors.reason,
                            },
                          ]
                        : []
                    }
                  />
                </Box>
              )}
              <Box className={classes.selectContainer}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel htmlFor="note" className={classes.labelText}>
                    Note*
                  </FormLabel>
                  <TextField
                    fullWidth
                    multiline
                    id="note"
                    size="small"
                    variant="standard"
                    placeholder="Type note here"
                    minRows={3}
                    value={noteContent}
                    onChange={(e) => {
                      setNoteContent(e.target.value);
                      setErrors((prev) => ({ ...prev, note: false }));
                    }}
                    InputProps={{ disableUnderline: true }}
                    error={Boolean(errors.note)}
                    className={classes.textArea}
                  />
                  {errors.note && (
                    <FormHelperText error>{errors.note}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button onClick={handleClose} className={classes.buttonCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            className={classes.buttonConfirm}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        handleClose={closeSuccessDialog}
        title="Success"
        message="Partner status has been successfully updated."
      />
    </>
  );
};

export default ChangePartnerStatusDialog;
