import React, { useState, useEffect, useContext } from "react";
import eosLogo from "../../../assets/icons/encompass-logo.png";
import { withRouter } from "react-router-dom";
import auth from "services/authService";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SettingsIcon from "@material-ui/icons/Settings";
import LogOutIcon from "@material-ui/icons/PowerSettingsNew";
import CloseIcon from "@material-ui/icons/Close";
import TimerIcon from "@material-ui/icons/Timer";
import EditIcon from "@material-ui/icons/Edit";
import DownArrowIcon from "@material-ui/icons/ArrowDropDown";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getUser,
  updatePassword,
  updateProfilePicture,
  getPreferences,
  getUserDisablePreferences,
  saveUserDisablePreferences,
  deleteUserDisablePreferences,
} from "services/userService";
import MessageDialog from "components/ui/dialog/messageDialog";
import { getUserSchedules } from "services/userService";
import GlobalUiContext from "contexts/globalUiContext";
import { withStyles } from "@material-ui/core/styles";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { timekeepingTimerInterval } from "constants.js";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    height: 22,
    width: 22,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.6)",
      opacity: 0,
    },
  },
}))(Badge);

const ProfileNav = (props) => {
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState({ password: "", password_confirmation: "" });
  const [error, setError] = useState("");
  const [profileUser, setProfileUser] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [disablePreferences, setDisablePreferences] = useState([]);
  const [openPreference, setOpenPreference] = useState(true);
  const [loadingPreference, setLoadingPreference] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const { timekeeping: globalTimekeeping, isNavBarShow } = globalUi;
  const classes = useStyles();

  const profilePicture = localStorage.getItem("profilePicture");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("open") === "preferences") {
      handleProfile();
    }
  }, []);

  useEffect(() => {
    const loadPreference = async () => {
      const [resultResponse, resultDisable] = await Promise.all([
        getPreferences(),
        getUserDisablePreferences(),
      ]);
      setPreferences(resultResponse.data.data);
      setDisablePreferences(resultDisable.data.data);
    };
    if (openDrawer) {
      loadPreference();
    }
  }, [openDrawer]);

  useEffect(() => {
    const userTimer = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return;
      }
      const result = await getUserSchedules(user.id);
      if (
        Boolean(result.data.data.entries.length) &&
        (!globalTimekeeping ||
          globalTimekeeping.id !== result.data.data.entries[0].id)
      ) {
        dispatchGlobalUi({
          type: "SET_TIMEKEEPING",
          timekeeping: result.data.data.entries[0],
        });
      } else if (
        !Boolean(result.data.data.entries.length) &&
        globalTimekeeping
      ) {
        dispatchGlobalUi({
          type: "SET_TIMEKEEPING",
          timekeeping: null,
        });
      }
    };
    const interval = setInterval(() => {
      userTimer();
    }, timekeepingTimerInterval);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTimekeeping]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const validateTimekeeping = async () => {
      if (!globalTimekeeping) {
        const result = await getUserSchedules(user.id);
        if (
          result.data.data.entries.length &&
          (!globalTimekeeping ||
            globalTimekeeping.id !== result.data.data.entries[0].id)
        ) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: result.data.data.entries[0],
          });
        }
      }
    };
    if (user && user.id) {
      validateTimekeeping();
    }
  }, [dispatchGlobalUi, globalTimekeeping]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = async () => {
    setOpenDrawer(true);
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const resultUser = await getUser(user.id);
    setProfileUser(resultUser.data.data.user);
    if (resultUser.data.data.user.profile.picture) {
      setProfilePic(resultUser.data.data.user.profile.picture.file_url);
    }
    setIsLoading(false);
  };

  const handleCloseProfile = () => {
    setOpenDrawer(false);
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await auth.logout();
      window.location = "/";
    } catch (e) {
      console.log("Error logout from server");
      window.location = "/";
    }
  };

  const handleClock = () => {
    if (globalUi.timekeeping) {
      const { history } = props;
      history.push(`/workticket/${globalUi.timekeeping.workticket.id}`);
    }
  };

  const handleBlur = (event) => {
    try {
      data[event.name] = event.value;
      setData(data);
    } catch (e) {
      logException(e, "Cannot set new value to field");
    }
  };

  const handleChangePassword = async () => {
    setError("");
    if (data.password !== "") {
      if (data.password !== data.password_confirmation) {
        setError("Password confirmation does not match");
        setTimeout(() => {
          setError("");
        }, 2500);
        return;
      }

      try {
        await updatePassword(data);
        setOpenDrawer(false);
        setOpen(false);
        setOpenDialog(true);
      } catch (ex) {
        if (
          ex.response &&
          (ex.response.status === 400 || ex.response.status === 401)
        ) {
          setError(ex.response.data.message.password);
        } else {
          logException(ex, "Cannot change password.");
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpen(false);
  };

  const handleChangeProfilePic = async (event) => {
    if (event.target.files[0]) {
      setProfilePic(URL.createObjectURL(event.target.files[0]));
      try {
        const pictureResult = await updateProfilePicture({
          files: [...event.target.files],
        });

        if (pictureResult.data.data.user.profile.picture) {
          localStorage.setItem(
            "profilePicture",
            pictureResult.data.data.user.profile.picture.file_url
          );
          setProfilePic(pictureResult.data.data.user.profile.picture.file_url);
        }
      } catch (e) {
        logException(e, "Cannot upload profile picture");
      }
    }
  };

  const handleChangePreference = async (event, value) => {
    try {
      setLoadingPreference(true);

      if (event.target.checked) {
        await deleteUserDisablePreferences(value);
        setDisablePreferences(
          disablePreferences.filter((val) => val !== value)
        );
      } else {
        await saveUserDisablePreferences({ preference_id: value });
        setDisablePreferences([...disablePreferences, value]);
      }
      setLoadingPreference(false);
    } catch (e) {
      logException(e, "Cannot save user preference");
    }
  };

  const handleOpenPreferences = () => {
    setOpenChangePassword(false);
    setOpenPreference(true);
  };

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
    setOpenPreference(false);
  };

  const AvatarDisplay = () =>
    globalUi.timekeeping ? (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={<TimerIcon className={classes.iconTimer} />}
      >
        <Avatar
          alt="Profile"
          src={profilePicture ?? eosLogo}
          className={classes.large}
        />
      </StyledBadge>
    ) : (
      <Avatar
        alt="Profile"
        src={profilePicture ?? eosLogo}
        className={classes.large}
      />
    );

  if (!isNavBarShow) {
    return null;
  }

  return (
    <>
      <Box className={classes.root}>
        <SpeedDial
          ariaLabel="Profile Icon"
          className={classes.speedDial}
          icon={<AvatarDisplay />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={"down"}
        >
          {globalUi.timekeeping && (
            <SpeedDialAction
              key={"Clock"}
              icon={<TimerIcon />}
              tooltipTitle={"Clock"}
              className={classes.speedDialActionActive}
              onClick={handleClock}
            />
          )}
          <SpeedDialAction
            key={"Settings"}
            icon={<SettingsIcon />}
            tooltipTitle={"Settings"}
            className={classes.speedDialAction}
            onClick={handleProfile}
          />
          <SpeedDialAction
            key={"Support"}
            icon={<ContactSupportIcon />}
            tooltipTitle={"Support Center"}
            className={classes.speedDialAction}
            onClick={() =>
              window.open("http://help.encompassonsite.com/", "_blank")
            }
          />
          <SpeedDialAction
            key={"Logout"}
            icon={<LogOutIcon />}
            tooltipTitle={"Logout"}
            className={classes.speedDialAction}
            onClick={handleLogout}
          />
        </SpeedDial>
      </Box>
      <Drawer anchor="right" open={openDrawer}>
        <Box className={classes.containerProfile}>
          {isLoading ? (
            <Box className={classes.wrapperLoading}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          ) : (
            <Box className={classes.wrapperProfile}>
              <Box className={classes.wrapperProfileClose}>
                <IconButton aria-label="close" onClick={handleCloseProfile}>
                  <CloseIcon className={classes.iconDrawerClose} />
                </IconButton>
              </Box>
              <Box className={classes.infoProfile}>
                <input
                  type="file"
                  accept="image/*"
                  className={classes.inputImage}
                  id="icon-button-file-user"
                  onChange={handleChangeProfilePic}
                />
                <label htmlFor="icon-button-file-user">
                  <Avatar
                    alt="Profile"
                    src={profilePic ?? eosLogo}
                    className={classes.imageProfile}
                  />
                  <Avatar className={classes.profileImageEdit}>
                    <EditIcon className={classes.profileIconEdit} />
                  </Avatar>
                </label>
                <Typography variant="body1" className={classes.titleProfile}>
                  {profileUser && profileUser.first_name}{" "}
                  {profileUser && profileUser.last_name}
                </Typography>
                <Typography variant="body1" className={classes.subtitleProfile}>
                  {profileUser && profileUser.email}
                </Typography>
              </Box>
              {preferences && preferences.length ? (
                <Box className={classes.passwordProfile}>
                  <Typography
                    variant="body1"
                    className={classes.labelProfile}
                    onClick={handleOpenPreferences}
                  >
                    Notification Preferences
                    {!openPreference ? <DownArrowIcon /> : null}
                  </Typography>
                  {openPreference ? (
                    <Box className={classes.passwordFieldsProfile}>
                      {preferences.map((itemPreference) => (
                        <FormControlLabel
                          key={itemPreference.id}
                          className={classes.preferenceFormLabel}
                          control={
                            <Checkbox
                              checked={
                                !disablePreferences.includes(itemPreference.id)
                              }
                              onChange={(e) =>
                                handleChangePreference(e, itemPreference.id)
                              }
                              className={classes.preferenceCheckbox}
                              disabled={loadingPreference}
                            />
                          }
                          label={itemPreference.display_name}
                        />
                      ))}
                    </Box>
                  ) : null}
                </Box>
              ) : null}
              <Box className={classes.passwordProfile}>
                <Typography
                  variant="body1"
                  className={classes.labelProfile}
                  onClick={handleOpenChangePassword}
                >
                  Change Password
                  {!openChangePassword ? <DownArrowIcon /> : null}
                </Typography>
                {Boolean(error) && (
                  <Box className={classes.passwordErrorProfile}>{error}</Box>
                )}
                {openChangePassword ? (
                  <Box className={classes.passwordFieldsProfile}>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="password"
                      label="New Password"
                      value={data.password}
                      type={"password"}
                      handleBlur={handleBlur}
                    />
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="password_confirmation"
                      label="Confirm Password"
                      value={data.password_confirmation}
                      type={"password"}
                      handleBlur={handleBlur}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disableElevation
                      fullWidth={true}
                      className={classes.passwordChangeButton}
                      onClick={handleChangePassword}
                    >
                      Change
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
      <MessageDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        title="Success"
        message={"Password has been changed."}
      />
    </>
  );
};

export default withRouter(ProfileNav);
