import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import * as classNames from "classnames";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
// import { saveWorkticketFile } from "services/workticketService";
import { uploadFileOpenPosition } from "services/openPositionsService";
import useStyles from "./styles";

const OpenPositionsFile = ({ id, filesRender }) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [files, setFiles] = useState([]);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [update, setUpdate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { workticket, isLoading } = {};
  useEffect(() => {
    if (filesRender) {
      setFilesDisplay(filesRender);
    }
  }, [filesRender]);
  useEffect(() => {
    if (!files.length) {
      setUpdate(0);
    }
  }, [files]);

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);
      const data = {
        is_signature: 0,
        files: files,
      };
      const fileResult = await uploadFileOpenPosition(id, data);
      setFilesDisplay([...filesDisplay, ...fileResult.data.data.files]);
      setFiles([]);
      setIsUploading(false);
    } catch (e) {
      logException(e, "Cannot save files");
    }
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = filesDisplay.filter((file) => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={200} />;
  }

  // if (!hasPermission(permissionWorkticket.UPLOAD_FILES, permissions)) {
  //   return null;
  // }

  return (
    <Box className={classes.containerFile}>
      {!Boolean(files.length) && (
        <Typography
          variant="h4"
          className={classes.titleSectionBody}
          gutterBottom
        >
          Documents
        </Typography>
      )}

      <FileUpload
        value={files}
        handleChange={setFiles}
        handleUpdate={setUpdate}
        filesDisplay={
          Boolean(update) && (
            <FilesDisplay files={files} handleRemove={handleRemove} />
          )
        }
      />
      {Boolean(update) && (
        <>
          <Box className={classes.containerUploadFile}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonUpload)}
              onClick={handleUploadFiles}
              disabled={isUploading}
            >
              Finish Upload
            </Button>
          </Box>
          {isUploading && <LinearProgress color="secondary" />}
        </>
      )}
      <Box className={classes.containerDisplayFiles}>
        <FilesDisplayUploaded
          files={filesDisplay ? filesDisplay : []}
          handleRemoveDisplay={handleRemoveDisplay}
        />
      </Box>
    </Box>
  );
};

export default OpenPositionsFile;
