import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";
import {
  getFilterString,
  filtersDashboardGeo,
} from "components/util/filterUtil";

const apiEndpoint = `${apiSurfaceUrl}/qualityDashboard`;

export function getQualityTabList(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/list?${filterString}`, {});
}

export function getQualityTabListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/list?${filterString}offset=${offset}`, {});
}

export function getQualityTabListDashboard(
  filterData = undefined,
  dashboardState
) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}/list`, payload);
}

export function getQualityTabListOffsetDashboard(
  offset,
  filterData,
  dashboardState
) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}/list?offset=${offset}`, payload);
}
