import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionForms } from "lib/permissions";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  deleteFormDataBasedOnCurrentTab,
  viewFormDataBasedOnCurrentTab,
} from "services/formsService";
import DeleteDialog from "./deleteConfirmationDialog";
// import useStyles from "./styles";

const options = [
  { id: 1, label: "View", value: "VIEW", user: false },
  { id: 3, label: "Delete", value: "DELETE", user: true },
];

const FormsTabTableActions = (props) => {
  const { row, mobile } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      props.setRefresh(!props.refresh);
      setRefresh(false);
    }
  }, [refresh]);

  const optionsWithPermissions = [];

  optionsWithPermissions.push(options[0]);

  if (hasPermission(permissionForms.DELETE_FORMS, permissions)) {
    optionsWithPermissions.push(options[1]);
  }

  // const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  // const user = JSON.parse(localStorage.getItem("user"));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDel, setOpen] = React.useState(false);
  const handleCloseDelDialog = () => {
    setOpen(false);

    deleteFormDataBasedOnCurrentTab(row?.id, props.topTab).then((res) => {
      if (res.status === 201) {
        setRefresh(true);
      }
    });
  };

  return (
    <div>
      <DeleteDialog
        openDeleteDialog={openDel}
        handleClose={handleCloseDelDialog}
        setOpen={setOpen}
      />
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {optionsWithPermissions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              if (option.id === 1) {
                props.setSelectedModalViewResponse(null);
                if (props.topTab === "forms?talent") {
                  props.setFormSelected(1);
                  props.setModal(1);
                } else if (props.topTab === "forms?assets") {
                  if (row.to_job_id) {
                    props.setFormSelected(2.2);
                    props.setModal(2.2);
                  } else {
                    props.setFormSelected(2.1);
                    props.setModal(2.1);
                  }
                } else {
                  const typeRow = Number(row?.type);
                  if (typeRow === 1) {
                    props.setFormSelected(3.1);
                    props.setModal(3.1);
                  } else if (typeRow === 2) {
                    props.setFormSelected(3.2);
                    props.setModal(3.2);
                  } else if (typeRow === 3) {
                    props.setFormSelected(3.3);
                    props.setModal(3.3);
                  }
                }
                viewFormDataBasedOnCurrentTab(row.id, props.topTab).then(
                  (res) =>
                    props.setSelectedModalViewResponse(
                      res?.data ? res?.data : null
                    )
                );
              }
              if (option.id === 2) {
                props.setSelectedRowData(row);
                props.setStatusModal(true);
              }
              if (option.id === 3) {
                setOpen(true);
              }
            }}
            data={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withRouter(FormsTabTableActions);
