import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;

export async function getPartnerTrades(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/trades`);
}
export async function getPartnerTradesVariants(data) {
  return await http.get(
    `${apiEndpoint}/${data.partner_id}/trades/${data.tradeId}`
  );
}
export async function createTrade(data) {
  return await http.post(`${apiEndpoint}/${data.partner_id}/trades`, data.data);
}
export async function deletePartnerTrade(data) {
  return await http.delete(`${apiEndpoint}/${data.partner_id}/trades`, {
    data: data.data,
  });
}
export async function deletePartnerTradeVariant(data) {
  return await http.delete(`${apiEndpoint}/${data.userId}/trades/${data.id}`, {
    data: { partner_trade_variation_id: data.partner_trade_id },
  });
}
export async function updatePartnerTrade(data) {
  return await http.put(
    `${apiEndpoint}/${data.partner_id}/trades`,
    data.data.data
  );
}
export async function updatePartnerTradeVariant(data) {
  return await http.put(
    `${apiEndpoint}/${data.userId}/trades/${data.partner_id}`,
    data.data
  );
}
export async function getTradesList() {
  return await http.get(`${apiEndpoint}/trades`);
}
export async function addPartnerTradeVariant(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/trades/${data.partner_trade_id}`,
    data.data
  );
}
export async function getStates() {
  return await http.get(`${apiEndpoint}/states`);
}
export async function getCities(data) {
  return await http.get(`${apiEndpoint}/cities`, {
    params: {
      "filters[states][]": data.states,
    },
  });
}
export async function getZipCodes(data) {
  return await http.get(`${apiEndpoint}/zip-codes`, {
    params: {
      "filters[states][]": data.citiesStates.states,
      "filters[cities][]": data.citiesStates.cities,
    },
  });
}
export async function getPartnerData(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}`);
}
