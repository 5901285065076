import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "bold",
  },
  tabBarAppBar: {
    borderColor: "#ececec",
    marginBottom: 10,
    zIndex: 1,
    height: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
}));

export const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: 14,
    textTransform: "none",
    minWidth: "auto",
    padding: "2px 0px",
    marginRight: 36,
    fontWeight: "bold",
    color: "#16457A !important",
    borderColor: "#16457A !important",
    border: "0 !important",

    "&:hover": {
      color: theme.palette.text.primary,
      border: "0 !important",
    },
  },
}))((props) => <Tab {...props} />);

export const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: "100%",
      width: "100%",
      backgroundColor: "#16457A !important",
    },
  },
  scrollButtons: {
    fontSize: "20px !important",
    "& > svg": {
      fontSize: "1.5rem",
      color: "#747474",
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default useStyles;
