import React, { useState, useEffect, useMemo } from "react";
import * as classNames from "classnames";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "assets/icons/deleteIcon";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import {
  getRatesFMPilot,
  getTaxesFMPilot,
  submitInvoiceFMPilot,
  getInvoiceFMPilot,
  saveInvoiceFileFMPilot,
  getInvoiceAttachmentsFMPilot,
} from "services/workticketService";
import { logException } from "components/util/logUtil";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./styles";

const initialRow = {
  chargeType: null,
  rateType: null,
  option: null,
  description: "",
  unitCost: 0,
  quantity: 0,
  total: 0,
  error: [],
  selectedItem: null,
  isLocked: false,
  chargeTypes: [],
  rateTypes: [],
  options: [],
};

const WorkticketInvoiceFMPilot = (props) => {
  const classes = useStyles();
  const {
    workticketNumber,
    workticketId,
    woNumber,
    woStatus,
    dne,
    handleClose,
    open,
    refreshWorkticketData,
  } = props;
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [rows, setRows] = useState([{ ...initialRow }]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [taxObject, setTaxObject] = useState({});
  const [isFistLoad, setIsFistLoad] = useState(true);
  const [allRates, setAllRates] = useState([]);
  const [description, setDescription] = useState("");
  const [totalDollars, setTotalDollars] = useState(0);
  const [ust, setUst] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [submitDate, setSubmitDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(moment().format("MM/DD/YYYY"));
  const [files, setFiles] = useState([]);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(`ENC${workticketId}`);
  const [successMessage, setSuccessMessage] = useState("");

  const disabledSubmit = useMemo(
    () =>
      !["PVINV", "CRINV"].includes(woStatus) ||
      filesDisplay.length === 0 ||
      !rows.length ||
      parseFloat(parseFloat(totalDollars).toFixed(2)) +
        parseFloat(parseFloat(ust).toFixed(2)) +
        parseFloat(parseFloat(totalTax).toFixed(2)) >
        parseFloat(dne)
        ? true
        : false,
    [filesDisplay, rows, totalDollars, ust, totalTax, dne, woStatus]
  );

  useEffect(() => {
    const loadInvoice = async () => {
      setIsLoadingProcess(true);
      const [resultRates, resultTaxes, resultInvoice] = await Promise.all([
        getRatesFMPilot(workticketId),
        getTaxesFMPilot(workticketId),
        getInvoiceFMPilot(workticketId),
      ]);
      setTaxObject(resultTaxes.data.data[0]);
      const dataRates = resultRates.data.data;
      const dataInvoice = resultInvoice.data.data;
      const dataChargeTypes = Object.values(
        dataRates.reduce((acc, { ChargeTypeId, LineItemType }) => {
          const key = `${ChargeTypeId}-${LineItemType}`;
          if (!acc[key]) {
            acc[key] = { value: ChargeTypeId, label: LineItemType };
          }
          return acc;
        }, {})
      );
      setAllRates(dataRates);
      if (dataInvoice) {
        const resultAttachments = await getInvoiceAttachmentsFMPilot(
          workticketId,
          dataInvoice.Id
        );
        if (resultAttachments?.data?.data) {
          setFilesDisplay(
            resultAttachments.data.data.map((item) => {
              return { file_name: item.Name, file_url: item.URL };
            })
          );
        }
        setInvoiceDate(moment(dataInvoice.InvoiceDate).format("MM/DD/YYYY"));
        setSubmitDate(dataInvoice.SubmittedDate);
        setInvoiceNumber(dataInvoice.Number);
        setDescription(dataInvoice.Description);
        setUst(parseFloat(dataInvoice.Tax1Value).toFixed(2));
        setTotalTax(parseFloat(dataInvoice.VendorTotalTax).toFixed(2));
        setInvoiceId(dataInvoice.Id);
        const dataLineItems = dataInvoice.LineItems;
        const dataRows = dataLineItems.map((item) => {
          const selItem = dataRates.find(
            (i) =>
              i.ChargeTypeId === item.ChargeTypeId &&
              i.RateType === item.Item &&
              i.Option === item.Option
          );
          return {
            chargeType: item.ChargeTypeId,
            rateType: item.Item,
            option: item.Option,
            description: item.Description,
            unitCost: parseFloat(item.UnitCost).toFixed(2),
            quantity: parseFloat(item.Quantity).toFixed(2),
            total: parseFloat(item.UnitCost * item.Quantity).toFixed(2),
            error: [],
            selectedItem: selItem,
            isLocked: selItem?.IsLocked || selItem?.LineItemType === "Labor",
            chargeTypes: dataChargeTypes,
            rateTypes: dataRates
              .filter((i) => i.ChargeTypeId === item.ChargeTypeId)
              .map((i) => ({
                value: i.RateType,
                label: i.RateType,
              })),
            options: dataRates
              .filter(
                (i) =>
                  i.ChargeTypeId === item.ChargeTypeId &&
                  i.RateType === item.Item
              )
              .map((i) => ({
                value: i.Option,
                label: i.Option,
              })),
          };
        });
        setRows(dataRows);
        const sumDollars = dataRows.reduce(
          (partialSum, a) => partialSum + parseFloat(a.total),
          0
        );
        setTotalDollars(sumDollars);
      } else {
        rows[0].chargeTypes = dataChargeTypes;
        rows[0].rateTypes = [];
        rows[0].options = [];
        setRows([...rows]);
      }
      setIsLoadingProcess(false);
    };
    if (workticketId && rows.length === 1 && isFistLoad && open) {
      loadInvoice();
      setIsFistLoad(false);
    }
  }, [workticketId, rows, isFistLoad, open]);

  useEffect(() => {
    if (!open) {
      setRows([{ ...initialRow }]);
      setTaxObject({});
      setTotalDollars(0);
      setUst(0);
      setTotalTax(0);
      setDescription("");
      setIsFistLoad(true);
      setInvoiceId(null);
      setFiles([]);
      setFilesDisplay([]);
      setUpdate(0);
      setIsUploading(false);
    }
  }, [open]);

  const handleChangeChargeType = (n, value) => {
    if (value) {
      rows[n].chargeType = value.value;

      const dataRateTypes = Object.values(
        allRates
          .filter((i) => i.ChargeTypeId === value.value)
          .reduce((acc, { RateType }) => {
            const key = `${RateType}`;
            if (!acc[key]) {
              acc[key] = { value: RateType, label: RateType };
            }
            return acc;
          }, {})
      );
      rows[n].option = null;
      rows[n].rateType = null;

      rows[n].rateTypes = dataRateTypes;
    } else {
      rows[n].chargeType = null;
      rows[n].option = null;
      rows[n].rateType = null;
      rows[n].rateTypes = [];
    }
    rows[n].unitCost = 0;
    rows[n].quantity = 0;
    rows[n].total = 0;
    rows[n].isLocked = false;
    rows[n].selectedItem = null;
    rows[n].options = [];
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    rows[n].error = [];
    setRows([...rows]);
  };

  const handleChangeOption = (n, value) => {
    if (value) {
      const selItem = allRates.find(
        (i) =>
          i.ChargeTypeId === rows[n].chargeType &&
          i.RateType === rows[n].rateType &&
          i.Option === value.value
      );
      rows[n].option = value.value;
      rows[n].unitCost = parseFloat(selItem.RateValue).toFixed(2);
      rows[n].isLocked = selItem.IsLocked || selItem.LineItemType === "Labor";
      rows[n].total = rows[n].unitCost * rows[n].quantity;
    } else {
      rows[n].option = null;
      rows[n].selectedItem = null;
      rows[n].unitCost = 0;
      rows[n].quantity = 0;
      rows[n].total = 0;
    }
    rows[n].error = [];
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const handleChangeRateType = (n, value) => {
    if (value) {
      rows[n].rateType = value.value;

      const dataOptions = Object.values(
        allRates
          .filter(
            (i) =>
              i.ChargeTypeId === rows[n].chargeType &&
              i.RateType === value.value
          )
          .reduce((acc, { Option }) => {
            const key = `${Option}`;
            if (!acc[key]) {
              acc[key] = { value: Option, label: Option };
            }
            return acc;
          }, {})
      );
      rows[n].options = dataOptions;
    } else {
      rows[n].rateType = null;
      rows[n].options = [];
    }
    rows[n].option = null;
    rows[n].unitCost = 0;
    rows[n].quantity = 0;
    rows[n].total = 0;
    rows[n].error = [];
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const handleSendInvoice = async (submitInvoice) => {
    let isGood = true;
    for (let i = 0; i < rows.length; i++) {
      const error = [];
      if (!rows[i].chargeType) {
        error.push({
          key: "chargeType",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].rateType) {
        error.push({
          key: "rateType",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].option) {
        error.push({
          key: "option",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].description) {
        error.push({
          key: "description",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].unitCost) {
        error.push({
          key: "unitCost",
          message: "Required",
        });
        isGood = false;
      }
      if (!rows[i].quantity) {
        error.push({
          key: "quantity",
          message: "Required",
        });
        isGood = false;
      }
      rows[i].error = error;
    }
    if (!isGood) {
      setRows([...rows]);
      return;
    }
    try {
      let data = {};
      setIsLoadingProcess(true);
      data = {
        InvoiceDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        Number: `ENC${workticketId}`,
        Tax1ID: taxObject.TaxTypeID,
        Tax1Name: taxObject.TaxTypeName,
        Tax1Value: parseFloat(parseFloat(ust).toFixed(2)),
        Description: description,
      };
      const lineItems = [];
      for (let i = 0; i < rows.length; i++) {
        lineItems.push({
          IsIncurred: false,
          Option: rows[i].option,
          ChargeTypeId: rows[i].chargeType,
          Quantity: parseFloat(parseFloat(rows[i].quantity).toFixed(2)),
          Item: rows[i].rateType,
          UnitCost: parseFloat(parseFloat(rows[i].unitCost).toFixed(2)),
          ChargeType: rows[i].chargeTypes.find(
            (item) => item.value === rows[i].chargeType
          ).label,
          Description: rows[i].description,
        });
      }
      data.LineItems = lineItems;
      const result = await submitInvoiceFMPilot(workticketId, {
        invoiceData: data,
        submitInvoice: submitInvoice ? 1 : 0,
      });
      setInvoiceId(result.data.data.Id);
      if (submitInvoice) {
        await refreshWorkticketData();
        setIsLoadingProcess(false);
        handleClose();
      } else {
        setIsLoadingProcess(false);
      }
      if (submitInvoice) {
        setSuccessMessage("Invoice has been submitted");
      } else {
        setSuccessMessage("Invoice has been saved");
      }
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot submit invoice");
    }
  };

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
  };

  const handleBlur = async (event) => {
    if (event.name === "general_description") {
      setDescription(event.value);
    } else if (event.name === "ust" && !isNaN(event.value)) {
      setUst(event.value);
    } else {
      setUst(event.value);
      await delay(50);
      setUst(0);
    }
  };

  const handleRowBlur = (n, event) => {
    if (["unitCost", "quantity"].includes(event.name) && !isNaN(event.value)) {
      rows[n][event.name] = event.value;
      rows[n].total = rows[n].unitCost * rows[n].quantity;
      rows[n].error = [];
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    } else if (event.name === "description") {
      if (!event.value) {
        rows[n].error = [
          {
            key: event.name,
            message: "Required",
          },
        ];
      } else {
        rows[n][event.name] = event.value;
        rows[n].error = [];
      }
    } else {
      rows[n][event.name] = 0;
      rows[n].error = [
        {
          key: event.name,
          message: "Required",
        },
      ];
      rows[n].total = 0;
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    }
    setRows([...rows]);
  };

  const handleAddClick = () => {
    const newRow = { ...initialRow };
    newRow.chargeTypes = [...rows[0].chargeTypes];
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (n) => {
    rows.splice(n, 1);
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);
      const data = {
        invoiceId,
        files: files,
      };
      await saveInvoiceFileFMPilot(workticketId, data);
      const resultAttachments = await getInvoiceAttachmentsFMPilot(
        workticketId,
        invoiceId
      );
      if (resultAttachments?.data?.data) {
        setFilesDisplay(
          resultAttachments.data.data.map((item) => {
            return { file_name: item.Name, file_url: item.URL };
          })
        );
      }
      setFiles([]);
      setUpdate(false);
      setIsUploading(false);
    } catch (e) {
      logException(e, "Cannot save files");
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {isLoadingProcess ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                className={classes.wrapperClose}
              >
                <CloseIcon className={classes.iconClose} />
              </IconButton>
              {!isLoadingProcess && (
                <>
                  <Grid container spacing={2} className={classes.dialogHeader}>
                    <Grid item sm={12}>
                      <Box className={classes.formHeaderContainer}>
                        <Box className={classes.formIconDialog}>
                          <TicketIcon className={classes.iconDialog} />
                        </Box>
                        <Typography
                          variant="h4"
                          className={classes.formTitleDialogInvoice}
                          gutterBottom
                        >
                          {workticketNumber}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.formContainerDialogMultiple}>
                    <Grid container spacing={0}>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Number:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {woNumber}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Number:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {invoiceNumber}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Date:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {invoiceDate}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {woStatus}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate ? "Submitted" : "Pending"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Submitted Date:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate ?? "N/A"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            DNE:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {`$${parseFloat(dne).toFixed(2)} USD`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12}>
                        <Box className={classes.descriptionFieldContainer}>
                          <FormInput
                            name={"general_description"}
                            gridSizes={[{ size: "md", val: 12 }]}
                            label="Description of work performed*"
                            value={description}
                            sameLine={true}
                            handleBlur={handleBlur}
                            rows={3}
                            multiline
                            noMargin
                          />
                        </Box>
                      </Grid>
                      {rows.map((row, n) => (
                        <React.Fragment key={n}>
                          <Grid item md={2} sm={6}>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={row.chargeTypes}
                              name="chargeType"
                              label="Charge Type"
                              handleChange={(event, value) =>
                                handleChangeChargeType(n, value)
                              }
                              value={row.chargeTypes.find(
                                (item) => item.value === row.chargeType
                              )}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={row.rateTypes}
                              name="rateType"
                              label="Item"
                              handleChange={(event, value) =>
                                handleChangeRateType(n, value)
                              }
                              value={row.rateTypes.find(
                                (item) => item.value === row.rateType
                              )}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={row.options}
                              name="option"
                              label="Option"
                              handleChange={(event, value) =>
                                handleChangeOption(n, value)
                              }
                              value={row.options.find(
                                (item) => item.value === row.option
                              )}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormInput
                              name={"description"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Description"
                              value={row.description}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={1} sm={6}>
                            <FormInput
                              name={"unitCost"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Rate"
                              value={row.unitCost}
                              readonly={row.isLocked}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={1} sm={6}>
                            <FormInput
                              name={"quantity"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Qty"
                              value={row.quantity}
                              sameLine={true}
                              handleBlur={(event) => handleRowBlur(n, event)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={1} sm={6}>
                            <FormInput
                              name={"total"}
                              gridSizes={[{ size: "md", val: 12 }]}
                              label="Total"
                              value={row.total}
                              sameLine={true}
                              readonly={true}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={1} sm={6}>
                            <Box className={classes.iconContainer}>
                              <IconButton
                                aria-label="delete"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={() => handleDeleteRow(n)}
                                disabled={rows.length === 1}
                                className={classes.iconButtonAction}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Box
                      className={classes.addContainerInvoice}
                      onClick={handleAddClick}
                    >
                      <AddIcon className={classes.iconAddLineInvoice} /> Add
                      Item
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item md={4} sm={6}></Grid>
                      <Grid item md={2} sm={6}>
                        <Box className={classes.fieldComposedInvoice}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice2}
                            gutterBottom
                          >
                            UST
                          </Typography>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="ust"
                            withoutLabel
                            value={ust}
                            handleBlur={handleBlur}
                            noMargin
                          />
                        </Box>
                      </Grid>
                      <Grid item md={2} sm={6}>
                        <Box className={classes.fieldComposedInvoice2}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Vendor Total Tax
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={1} sm={6}>
                        <FormInput
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="ust"
                          withoutLabel
                          readonly={true}
                          value={totalTax}
                          noMargin
                        />
                      </Grid>
                      <Grid item md={3} sm={6}>
                        <Box className={classes.resumeInvoiceFieldsContainer}>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoice}
                              gutterBottom
                            >
                              Subtotal:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderValueInvoice2}
                              gutterBottom
                            >
                              {parseFloat(totalDollars).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume2}
                            >
                              Total Cost:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume}
                            >
                              {parseFloat(
                                parseFloat(
                                  parseFloat(totalDollars).toFixed(2)
                                ) +
                                  parseFloat(parseFloat(ust).toFixed(2)) +
                                  parseFloat(parseFloat(totalTax).toFixed(2))
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {invoiceId ? (
                    <>
                      <Box className={classes.containerFileLabel}>
                        <Typography
                          variant="h4"
                          className={classes.labelHeaderAttachments}
                        >
                          Attachments
                        </Typography>
                      </Box>
                      <Box className={classes.containerFile}>
                        <FileUpload
                          title={"Invoice Attachments"}
                          value={files}
                          handleChange={setFiles}
                          handleUpdate={setUpdate}
                          filesDisplay={
                            Boolean(update) && (
                              <FilesDisplay
                                files={files}
                                handleRemove={handleRemove}
                              />
                            )
                          }
                        />
                        {Boolean(update) && (
                          <>
                            <Box className={classes.containerUploadFile}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                className={classNames(
                                  classes.button,
                                  classes.buttonUpload
                                )}
                                onClick={handleUploadFiles}
                                disabled={isUploading}
                              >
                                Finish Upload
                              </Button>
                            </Box>
                            {isUploading && (
                              <LinearProgress color="secondary" />
                            )}
                          </>
                        )}
                        <Box className={classes.containerDisplayFiles}>
                          <FilesDisplayUploaded
                            files={filesDisplay}
                            handleRemoveDisplay={() => {}}
                            restrictRemove
                          />
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.actionsDialogWrapperInvoice}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                disabled={
                  !rows.length ||
                  parseFloat(parseFloat(totalDollars).toFixed(2)) +
                    parseFloat(parseFloat(ust).toFixed(2)) +
                    parseFloat(parseFloat(totalTax).toFixed(2)) >
                    parseFloat(dne) ||
                  woStatus !== "PVINV"
                }
                onClick={() => handleSendInvoice(false)}
              >
                Edit Invoice
              </Button>
              <Button
                variant={disabledSubmit ? "outlined" : "contained"}
                color="primary"
                size="large"
                disableElevation
                className={
                  disabledSubmit
                    ? classNames(classes.button, classes.buttonOutlined)
                    : classNames(classes.button, classes.buttonPrimary)
                }
                onClick={() => handleSendInvoice(true)}
                disabled={disabledSubmit}
              >
                Submit Invoice
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={successMessage}
      />
    </>
  );
};

export default WorkticketInvoiceFMPilot;
