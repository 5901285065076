import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState } from "contexts/tableContext";
import { numberToWord } from "components/util/stringFormat";

import useStyles from "./styles";

const ArchiveDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const tableUi = useTableState();
  const { openArchive } = workticketAction;
  const { selected } = tableUi;

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_ARCHIVE",
      open: false,
    });
  };

  const handleConfirm = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_ARCHIVE",
      open: false,
    });
    setOpenSuccess(true);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openArchive}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <TicketIcon className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Worktickets
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to archive these{" "}
            {numberToWord(selected.length)} ({selected.length}) workticket
            {selected.length > 1 ? "s" : ""}?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleClose}
            className={classes.buttonOutlined}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket${
          selected.length > 1 ? "s" : ""
        } have been archived.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ArchiveDialog;
