import React from "react";
import Table from "@material-ui/core/Table";
import TableCollapsibleHead from "./tableCollapsibleHead";
import TableCollapsibleBody from "./tableCollapsibleBody";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";

// import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";

const TableCollapsible = (props) => {
  const classes = useStyles();
  const { rows, stickyHeader } = useTableCollapsibleState();
  return (
    <Box className={classes.root}>
      {props.children}
      <Box
        className={classes.tableWrapper}
        id={props.idscrollwrapper ? props.idscrollwrapper : "table-wrapper"}
      >
        <Table
          stickyHeader={stickyHeader}
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableCollapsibleHead />
          <TableBody>
            <TableCollapsibleBody localRows={rows} level={0} />
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default TableCollapsible;
