import React, { useState, useEffect, useCallback, useContext } from "react";
import LoadingIndicator from "../../components/common/LoadingIndicator/loadingIndicator";
import Table from "../../components/common/Table/table";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ConfirmDialog from "./dialog/confirmDialog";
import {
  useQuoteState,
  updateQuote,
  useQuoteDispatch,
} from "../../contexts/quoteContext";
import QuoteRecurrenceActions from "./quoteRecurrenceActions";
import Recurrence from "../../components/common/Recurrence";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "../../contexts/tableContext";
import { useListState, useListDispatch } from "../../contexts/listContext";
import {
  quoteGenerator,
  deleteQuoteGenerator,
  updateQuoteGenerator,
} from "../../services/quoteService";
import { permissionQuote, hasPermission } from "../../lib/permissions";
import GlobalUiContext from "../../contexts/globalUiContext";

const QuoteRecurrenceTable = (props) => {
  const classes = useStyles();
  const [tablePointer, setTablePointer] = useState(50);
  const dispatch = useQuoteDispatch();
  const [loadingMore, setLoadingMore] = useState(false);
  const { listLoading, list } = useListState();
  const { hasRowsClickable } = useTableState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const { quoteDetails, isLoading, selectedQuote } = useQuoteState();
  const [openRecurrence, setOpenRecurrence] = useState(false);
  const [generator, setGenerator] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [subTitle, setSubTitle] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasRecurrence = hasPermission(permissionQuote.RECURRENCE, permissions);
  const [isLoadingDeleteGenerator, setIsLoadingDeleteGenerator] = useState(
    false
  );
  const [isLoadingChangeGenerator, setIsLoadingChangeGenerator] = useState(
    false
  );

  const viewItem = (row) => {
    if (quoteDetails.type === 2) {
      setSubTitle(quoteDetails.number);
    } else {
      setSubTitle(
        `${quoteDetails.number} - ${row.name} - Hours: ${parseFloat(
          row.hours
        ).toFixed(2)} - Amount: $ ${parseFloat(row.amount).toFixed(2)}`
      );
    }
    setGenerator(row.item);
    setOpenRecurrence(true);
  };

  const columns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "hours",
      label: "Hours",
      format: "number",
    },
    {
      id: "amount",
      label: "Amount",
      format: "money",
    },
    {
      id: "every",
      label: "Every",
    },
    { id: "interval", label: "Interval" },
    {
      id: "actions",
      content: (row) =>
        hasRecurrence ? (
          <QuoteRecurrenceActions
            row={row}
            handleView={viewItem}
            handleDelete={handleGeneratorDelete}
            quoteId={quoteDetails.number}
            isOldStructure={quoteDetails.is_old_structure}
          />
        ) : (
          ""
        ),
      contentMobile: (row) =>
        hasRecurrence ? (
          <QuoteRecurrenceActions
            row={row}
            mobile={true}
            handleView={viewItem}
            handleDelete={handleGeneratorDelete}
            quoteId={quoteDetails.number}
            isOldStructure={quoteDetails.is_old_structure}
          />
        ) : (
          ""
        ),
    },
  ];

  useEffect(() => {
    const list = [];
    if (quoteDetails.type === 2) {
      if (quoteDetails.generator) {
        for (let i = 0; i < quoteDetails.generator.length; i++) {
          list.push({
            name: quoteDetails.subject,
            hours: quoteDetails.hours,
            amount: quoteDetails.total,
            item: quoteDetails.generator[i],
            interval: quoteDetails.generator[i].schedule_interval,
            every: quoteDetails.generator[i].schedule_data.value,
          });
        }
      }
    } else if (quoteDetails.type === 3) {
      for (let i = 0; i < quoteDetails.quote_items.length; i++) {
        if (quoteDetails.quote_items[i].generator) {
          for (
            let j = 0;
            j < quoteDetails.quote_items[i].generator.length;
            j++
          ) {
            list.push({
              name: quoteDetails.quote_items[i].item_subject,
              hours: parseFloat(quoteDetails.quote_items[i].hours).toFixed(2),
              amount: quoteDetails.quote_items[i].total,
              item: quoteDetails.quote_items[i].generator[j],
              interval:
                quoteDetails.quote_items[i].generator[j].schedule_interval,
              every:
                quoteDetails.quote_items[i].generator[j].schedule_data.value,
            });
          }
        }
      }
    }
    dispatchList({
      type: "SET_LIST",
      list: list,
    });
    dispatchList({
      type: "SET_COUNT",
      count: list.length,
    });
    dispatchList({
      type: "SET_LOADING",
      listLoading: false,
    });
  }, [
    dispatchList,
    quoteDetails.type,
    quoteDetails.generator,
    quoteDetails.quote_items,
  ]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list.slice(0, tablePointer),
      });
      dispatchTable({
        type: "SET_HAS_SELECT",
        hasSelect: false,
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: columns,
      });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, listLoading, tablePointer]);

  const handleScrollClick = useCallback(async () => {
    if (list.length <= tablePointer) {
      return;
    }
    try {
      setLoadingMore(true);
      const updateTablePointer = tablePointer + 50;
      setTablePointer(updateTablePointer);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [tablePointer, list.length]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleRecurrence = () => {
    setOpenRecurrence(!openRecurrence);
  };

  const handleRecurrenceSubmit = async (id, data) => {
    setIsLoadingChangeGenerator(true);
    if (!id) {
      try {
        const resultGenerator = await quoteGenerator({
          ...data,
          type: 1,
          source_ids: [quoteDetails.id],
        });
        setGenerator(resultGenerator.data.data.generators[0]);
      } catch (e) {
        console.log("Cannot create generator", e);
      }
    } else {
      await updateQuoteGenerator(id, {
        ...data,
      });
      updateQuote(dispatch, selectedQuote, {}, true, true);
      console.log("Update");
    }
    setIsLoadingChangeGenerator(false);
  };

  const handleGeneratorDelete = async (id) => {
    if (id) {
      try {
        setIsLoadingDeleteGenerator(true);
        await deleteQuoteGenerator(id);
        setGenerator(null);
        setIsLoadingDeleteGenerator(false);
        updateQuote(dispatch, selectedQuote, {}, true, true);
      } catch (e) {
        console.log("Cannot delete generator");
      }
    }
  };

  const openDeleteDialog = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return isLoading ? (
    <Skeleton
      animation="wave"
      variant="rect"
      height={80}
      style={{ marginBottom: 20 }}
    />
  ) : (
    <Box className={classes.rootRecurrence}>
      {listLoading ? (
        <LoadingIndicator />
      ) : (
        list.length > 0 && (
          <>
            <ConfirmDialog
              open={isLoadingDeleteGenerator || isLoadingChangeGenerator}
              handleConfirm={() => {}}
              isLoading={isLoadingDeleteGenerator || isLoadingChangeGenerator}
              handleClose={() => {}}
              message={""}
              quoteId={quoteDetails.number}
            />
            <Box className={classes.contentContainer}>
              <Box className={classes.contentCounter}>
                <Typography
                  variant="header"
                  color="inherit"
                  className={classes.itemHeaderRecurrence}
                  noWrap
                >
                  Recurrence
                </Typography>
              </Box>
              <Box className={classes.rootContainer}>
                <Box className={classes.tableContainer}>
                  <Box className={classes.fullHeightTable}>
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={columns}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                    ></Table>
                  </Box>
                </Box>
              </Box>
              {loadingMore && (
                <Box className={classes.loadingTable}>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Box>
          </>
        )
      )}
      <Recurrence
        open={openRecurrence}
        mode={"workticket"}
        subTitle={subTitle}
        data={generator}
        handleClose={handleRecurrence}
        handleSubmit={handleRecurrenceSubmit}
        handleGeneratorDelete={openDeleteDialog}
        disableDelete={true}
        message={
          "This will edit the recurrence for all associated work tickets that have not already been “started”. Do you want to continue?"
        }
        confirm={true}
      />
      <ConfirmDialog
        open={openDelete}
        handleConfirm={handleGeneratorDelete}
        handleClose={handleCloseDelete}
        message={
          "Deleting this will stop the auto generation of work tickets moving forward and existing tickets which have not been “started” yet are going to be deleted. Do you want to continue?"
        }
        quoteId={quoteDetails.number}
      />
    </Box>
  );
};

export default QuoteRecurrenceTable;
