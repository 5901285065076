import React from "react";
import Chip from "@material-ui/core/Chip";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import PhotoIcon from "@material-ui/icons/Photo";
import useStyles from "./styles";

const imagesTypes = [
  "image/gif",
  "image/png",
  "image/jpeg",
  "image/bmp",
  "image/webp",
];

const FilesDisplay = (props) => {
  const classes = useStyles();
  const { files, handleRemove } = props;

  const handleDelete = (index) => {
    handleRemove(index);
  };

  return files.map((fileE, index) => {
    return (
      <Chip
        icon={imagesTypes.includes(fileE.type) ? <PhotoIcon /> : <FileIcon />}
        label={
          fileE.name.length > 18
            ? `${fileE.name.substring(0, 18)}...`
            : fileE.name
        }
        className={classes.fileIcon}
        onDelete={() => handleDelete(index)}
        key={index}
      />
    );
  });
};

export default FilesDisplay;
