import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import JobBreadcrum from "./jobBreadcrum";
import JobInformation from "./jobInformation";
import NotesList from "./notesList/notesList";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import { getJobDetail } from "services/jobService";
import { useJobView } from "contexts/jobViewContext";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionCustomer, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const JobLayout = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [tab, setTab] = useState(-1);
  const context = useJobView();
  const [stateContext, dispatchContext] = context;
  const { job, isLoading } = stateContext ?? null;

  const TabOptions = [
    { id: 1, text: "General", tab: "general", permission: null },
  ];

  if (job.customer_id) {
    if (parseInt(job.job_type) === 1) {
      TabOptions.push({
        id: 2,
        text: "Budgets",
        tab: "budgets",
        permission: permissionCustomer.VIEW_BUDGET,
      });
    }

    TabOptions.push({
      id: 3,
      text: "Notes",
      tab: "notes",
      permission: permissionCustomer.NOTE_MANAGE,
    });
  }

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        if (props.id !== "new") {
          const result = await getJobDetail(props.id);
          dispatchContext({
            type: "SET_JOB",
            job: result.data.data.job,
          });
          dispatchContext({
            type: "SET_FILES",
            files: result.data.data.job?.files,
          });
        } else {
          dispatchContext({
            type: "SET_JOB",
            job: "new",
          });
        }
        dispatchContext({
          type: "SET_LOADING",
          isLoading: false,
        });
      } catch (e) {
        logException(e, "Cannot load job data");
      }
    };
    loadWorkticket();
  }, [props.id, dispatchContext]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("tab")) {
      setTab(Number(query.get("tab")));
    } else {
      setTab(1);
    }
  }, []);

  const handleTabChange = (event, newTab) => {
    const { history } = props;
    setTab(newTab);
    history.push(`/job/${job.id}?tab=${newTab}`);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  if (tab === 2) {
    const { history } = props;
    history.push(`/job/budgets/${job.id}`);
  }

  return (
    <>
      <JobBreadcrum />
      {job !== "new" ? (
        <StyledTabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          className={classes.rootTabs}
        >
          {TabOptions.filter(
            (item) =>
              !item.permission || hasPermission(item.permission, permissions)
          ).map((option) => {
            return (
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>{option.text}</Box>}
                value={option.id}
                key={option.id}
              />
            );
          })}
        </StyledTabs>
      ) : null}
      {tab === 1 ? (
        <Box className={classes.root}>
          <Box className={classes.containerBody}>
            <JobInformation {...props} />
          </Box>
        </Box>
      ) : null}
      {tab === 3 ? <NotesList /> : null}
    </>
  );
};

export default withRouter(JobLayout);
