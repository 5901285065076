import React from "react";
import Box from "@material-ui/core/Box";
import { learnColorWheel } from "constants.js";
import useStyles from "./styles";

const LearnCategoryColorField = (props) => {
  const classes = useStyles();
  const { color } = props;

  return (
    <Box className={classes.colorFieldContainer}>
      <Box
        className={classes.colorField}
        style={{ backgroundColor: color }}
      ></Box>
    </Box>
  );
};

export default LearnCategoryColorField;
