import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";

import { getFilterNotices } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import useStyles from "components/common/Filters/styles";

const NoticesFilters = (props) => {
  const classes = useStyles();
  const [noticeFilters, setNoticeFilters] = useState([]);
  const [notices, setNotices] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const loadNotices = async () => {
      try {
        const resultForRender = await getFilterNotices();
        setNotices(resultForRender);
      } catch (e) {
        logException(e, "Cannot load survey data");
      }
    };
    if (anchorEl && !notices) {
      loadNotices();
    }
  }, [anchorEl, notices]);

  useEffect(() => {
    if (anchorEl) {
      if (notices?.length) {
        const selectedNoticeFilter = filters.find(
          (filter) => filter.filter === "type"
        );
        if (selectedNoticeFilter) {
          const selectedValue = selectedNoticeFilter.values.map(
            (notice) => notice.value
          );
          const findNotice = notices.filter((notice) =>
            selectedValue.includes(notice.value)
          );
          setNoticeFilters(findNotice);
        } else {
          setNoticeFilters([]);
        }
      }
    }
  }, [anchorEl, filters, notices]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];

    if (noticeFilters.length) {
      filterData.push({
        filter: "type",
        group: "Type",
        values: [...noticeFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeNotice = (event, value) => {
    if (value) {
      setNoticeFilters(value);
    } else {
      setNoticeFilters([]);
    }
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item md={12} className={classes.wrapperTeamMember}>
                  {notices && Boolean(notices.length) ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={notices}
                      name="type"
                      handleChange={handleChangeNotice}
                      multiple={true}
                      value={noticeFilters}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default NoticesFilters;
