import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Joi from "joi-browser";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ButtonBase } from "components/ui/core/button";
import { getCustomers } from "services/userService";
import { logException } from "components/util/logUtil";
import { validate, validateProperty } from "components/util/validationUtil";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import BackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import HideIcon from "@material-ui/icons/UnfoldLess";
import { getJobsCustomers } from "services/jobService";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormInput from "components/ui/FormContent/formInput";
import { getRoles } from "services/superService";
import GlobalUiContext from "contexts/globalUiContext";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import {
  triggerActions,
  triggerOperators,
  triggerDeliveryChannel,
  triggerDeliveryEmailChannel,
} from "constants.js";
import {
  useTriggersState,
  useTriggersDispatch,
} from "contexts/triggersTableContext";
import {
  createTrigger,
  updateTrigger,
  getTriggersSources,
} from "services/triggersService";
import { getRoleTierUsersList } from "services/userService";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const triggerActionsOptions = [
  { value: 1, label: "Surveys" },
  { value: 2, label: "Roles" },
  { value: 3, label: "Users" },
  { value: 4, label: "Customers" },
  { value: 5, label: "Jobs" },
];

const triggerActionsOptionsRating = [{ value: 4, label: "Customers" }];

const triggerNotificationOptions = [
  { value: 1, label: "Roles" },
  { value: 2, label: "Users" },
  { value: 3, label: "Manual" },
];

const InitialData = {
  name: "",
  subject: "",
  category: 1,
  action: null,
  condition_operator: null,
  threshold: 0,
  users: [],
  roles: [],
  customers: [],
  jobs: [],
  sources: [],
  delivery_channel: null,
  recipe_users: [],
  recipe_roles: [],
  recipe_custom: "",
  corrective_action: 0,
  existing_jobs: [],
};

const triggerStep0Schema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  delivery_channel: Joi.object()
    .required()
    .label("Notification Type")
    .error(() => {
      return {
        message: "Notification is required.",
      };
    }),
  action: Joi.object()
    .required()
    .label("Action")
    .error(() => {
      return {
        message: "Action is required.",
      };
    }),
  condition_operator: Joi.object()
    .required()
    .label("Operator")
    .error(() => {
      return {
        message: "Operator is required.",
      };
    }),
  threshold: Joi.number().required().min(0).label("Threshold"),
};

const triggerStep0CompletedSchema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  action: Joi.object()
    .required()
    .label("Action")
    .error(() => {
      return {
        message: "Action is required.",
      };
    }),
  delivery_channel: Joi.object()
    .required()
    .label("Notification Type")
    .error(() => {
      return {
        message: "Notification is required.",
      };
    }),
};

const triggerStep0CompletedSchemaNoDelivery = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  action: Joi.object()
    .required()
    .label("Action")
    .error(() => {
      return {
        message: "Action is required.",
      };
    }),
  condition_operator: Joi.object()
    .required()
    .label("Operator")
    .error(() => {
      return {
        message: "Operator is required.",
      };
    }),
  threshold: Joi.number().required().min(0).label("Threshold"),
};

const triggerStep1Schema = {};

const triggerStep2Schema = {
  recipe_custom: Joi.string()
    .trim()
    .allow("")
    .regex(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/)
    .label("Custom Recipes")
    .error(() => {
      return {
        message: "Enter a comma separated emails.",
      };
    }),
};

const SideDrawer = (props) => {
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(InitialData);
  const [customers, setCustomers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [isLoadingTrigger, setIsLoadingTrigger] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [sources, setSources] = useState([]);
  const [sourcesType, setSourcesType] = useState([]);
  const [schema, setSchema] = useState(triggerStep0Schema);
  const [error, setError] = useState([]);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [deliveryChannel, setDeliveryChannel] = useState(
    triggerDeliveryChannel
  );
  const [actionRuleSelected, setActionRuleSelected] = useState(null);
  const [actionRule, setActionRule] = useState(0);
  const [notificationRuleSelected, setNotificationRuleSelected] =
    useState(null);
  const [notificationRule, setNotificationRule] = useState(0);
  const { users } = globalUi;
  const dispatchTriggers = useTriggersDispatch();
  const { openManageTriggers, triggersSelected } = useTriggersState();

  useEffect(() => {
    const loadUsers = async () => {
      const resultClients = await getRoleTierUsersList("client");
      const renderClients = resultClients.data.data.users.map((user) => {
        return {
          id: Number(user.id),
          first_name: user.first_name,
          last_name: `${user.last_name} (Client)`,
        };
      });
      const user = JSON.parse(localStorage.getItem("user"));
      const usersList = [
        { id: user.id, first_name: "Me", last_name: "" },
        ...users,
        ...renderClients,
      ];
      setUsersList(usersList);
    };

    loadUsers();
  }, [users]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultUser = await getCustomers();
        const { customers } = resultUser.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers.length) {
      loadCustomer();
    }
  }, [customers]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const roles = await getRoles();
        const resultForRender = roles.data.data.roles.map((role) => {
          return { value: role.id, label: role.name };
        });
        setRoles(resultForRender);
      } catch (e) {
        logException(e, "Cannot load roles data");
      }
    };
    if (!roles.length) {
      loadRoles();
    }
  }, [roles]);

  useEffect(() => {
    const loadSources = async () => {
      try {
        const sources = await getTriggersSources();
        const resultForRender = sources.data.data.map((source) => {
          return { value: source.id, label: source.name, type: source.type };
        });
        setSources(resultForRender);
      } catch (e) {
        logException(e, "Cannot load sources data");
      }
    };
    if (!sources.length) {
      loadSources();
    }
  }, [sources]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        setIsLoadingJob(true);
        const customerIds = data.customers.map((c) => {
          return c.value;
        });
        const resultJob = await getJobsCustomers(customerIds);
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer_id,
            city: job.city,
            state: job.state,
            zip: job.zip,
          };
        });
        setJobs([
          { id: -1, job_number: "-1", job_description: "All" },
          ...resultForRender,
        ]);

        if (data.jobs.length > 0) {
          const jobIds = data.jobs.map((j) => {
            return j.id;
          });
          const jobsSelected = resultForRender.filter((c) =>
            jobIds.includes(c.id)
          );
          const newData = {
            ...data,
            jobs: jobsSelected,
          };
          setData(newData);
        } else if (data.existing_jobs.length > 0) {
          const jobsSelected = resultForRender.filter((c) =>
            data.existing_jobs.includes(c.id)
          );
          const newData = {
            ...data,
            jobs: jobsSelected,
            existing_jobs: [],
          };
          setData(newData);
        }
        setIsLoadingJob(false);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (data.customers?.length > 0 || jobs.length === 0) {
      loadJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customers]);

  useEffect(() => {
    const loadTriggerData = () => {
      const selectedAction = triggerActions.filter(
        (item) => item.value === triggersSelected.action
      )[0];
      const selectedOperator = triggerOperators.filter(
        (item) => item.value === triggersSelected.condition_operator
      )[0];
      const selectedSources = sources.filter((item) =>
        triggersSelected.sources.includes(item.value)
      );
      const selectedChannel = triggerDeliveryChannel.filter(
        (item) => item.value === triggersSelected.delivery_channel
      )[0];
      const selectedRoles = roles.filter((item) =>
        triggersSelected.roles.includes(item.value)
      );
      const selectedUsers = usersList.filter((item) =>
        triggersSelected.users.includes(item.id)
      );
      const selectedCustomers = customers.filter((item) =>
        triggersSelected.customers.includes(item.value)
      );
      const selectedJobs = jobs.filter((item) =>
        triggersSelected.jobs.includes(item.id)
      );
      const selectedRecipeRoles = roles.filter((item) =>
        triggersSelected.recipe_roles.includes(item.value)
      );
      const selectedRecipeUsers = usersList.filter((item) =>
        triggersSelected.recipe_users.includes(item.id)
      );
      if (selectedAction?.value === "1" || selectedAction?.value === "5") {
        setSchema(triggerStep0CompletedSchema);
      } else {
        setSchema(triggerStep0Schema);
      }
      const newData = {
        name: triggersSelected.name,
        subject: triggersSelected.subject,
        category: 1,
        action: selectedAction,
        condition_operator: selectedOperator,
        threshold: triggersSelected.threshold,
        users: selectedUsers,
        roles: selectedRoles,
        customers: selectedCustomers,
        jobs: selectedJobs,
        sources: selectedSources,
        delivery_channel: selectedChannel,
        recipe_users: selectedRecipeUsers,
        recipe_roles: selectedRecipeRoles,
        recipe_custom: triggersSelected.recipe_custom ?? "",
        corrective_action: triggersSelected.corrective_action,
        existing_jobs: triggersSelected.jobs ?? [],
      };
      setData(newData);
    };
    if (openManageTriggers && triggersSelected) {
      loadTriggerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openManageTriggers, triggersSelected]);

  const changeStep = async (n) => {
    if (n === 0) {
      if (data.action?.value === "1" || data.action?.value === "5") {
        setSchema(triggerStep0CompletedSchema);
      } else if (data.action?.value === "7") {
        setSchema(triggerStep0CompletedSchemaNoDelivery);
      } else {
        setSchema(triggerStep0Schema);
      }
      setStep(n);
    } else {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors) || n < step) {
        if (n === 1) {
          setSchema(triggerStep1Schema);
          setStep(n);
        } else if (
          n === 2 &&
          data.delivery_channel?.value !== "sms" &&
          data.action?.value !== "7"
        ) {
          setSchema(triggerStep2Schema);
          setStep(n);
        } else {
          try {
            setIsLoadingTrigger(true);
            const createData = {
              name: data.name,
              subject: data.subject,
              category: data.action?.value === "7" ? 2 : 1,
              action: data.action.value,
              condition_operator: data.condition_operator
                ? data.condition_operator.value
                : null,
              threshold: data.threshold,
              users: data.users.map((u) => {
                return u.id;
              }),
              roles: data.roles.map((r) => {
                return r.value;
              }),
              customers: data.customers.map((c) => {
                return c.value;
              }),
              jobs: data.jobs.map((j) => {
                return j.id;
              }),
              sources: data.sources.map((s) => {
                return s.value;
              }),
              delivery_channel:
                data.action?.value === "7"
                  ? "recall"
                  : data.delivery_channel.value,
              recipe_users: data.recipe_users.map((u) => {
                return u.id;
              }),
              recipe_roles: data.recipe_roles.map((r) => {
                return r.value;
              }),
              recipe_custom: data.recipe_custom,
              corrective_action: data.corrective_action,
            };
            if (triggersSelected) {
              await updateTrigger(triggersSelected.id, createData);
            } else {
              await createTrigger(createData);
            }
            setIsLoadingTrigger(false);
            dispatchGlobalUi({
              type: "SET_REFRESH_DATA",
              refreshData: true,
            });
            closeDrawer();
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
    if (n === 1) {
      if (data.action?.value === "5" || data.action?.value === "6") {
        setSourcesType(sources.filter((item) => item.type === 1));
      } else {
        setSourcesType(sources.filter((item) => [2, 3].includes(item.type)));
      }
    }
  };

  const handleChangeCustomer = (event, value) => {
    let newData = {};
    if (value.length > 0) {
      if (value.length < data.customers.length) {
        const difference = data.customers.filter((c) => !value.includes(c));
        const newJobs = data.jobs.filter(
          (j) => j.customer_id !== difference[0].value
        );
        newData = {
          ...data,
          customers: value,
          jobs: newJobs,
        };
      } else {
        newData = {
          ...data,
          customers: value,
        };
      }
    } else {
      newData = { ...data, customers: value, jobs: [] };
    }
    setData(newData);
  };

  const changeJob = (event, value) => {
    let newData = {};
    const jobsIds = data.jobs.map((j) => {
      return j.id;
    });
    if (jobsIds.includes(-1)) {
      newData = {
        ...data,
        jobs: value.filter((j) => j.id !== -1),
      };
    } else {
      if (value.length > 1) {
        const jobsIdsValue = value.map((j) => {
          return j.id;
        });
        if (jobsIdsValue.includes(-1)) {
          newData = {
            ...data,
            jobs: value.filter((j) => j.id === -1),
          };
        } else {
          newData = {
            ...data,
            jobs: value,
          };
        }
      } else {
        newData = {
          ...data,
          jobs: value,
        };
      }
    }
    setData(newData);
  };

  const handleChangeOperator = (event, value) => {
    const newData = {
      ...data,
      condition_operator: value,
    };
    setData(newData);
    validateProperty("condition_operator", value, schema, error, setError);
  };

  const handleChangeAction = (event, value) => {
    let newData = {};
    if (value?.value === "1" || value?.value === "5") {
      newData = {
        ...data,
        action: value,
        condition_operator: null,
        threshold: 0,
        delivery_channel: null,
      };
      setSchema(triggerStep0CompletedSchema);
    } else {
      newData = {
        ...data,
        action: value,
        delivery_channel: null,
      };
      setSchema(
        value?.value === "7"
          ? triggerStep0CompletedSchemaNoDelivery
          : triggerStep0Schema
      );
    }

    if (value?.value === "5" || value?.value === "6") {
      setDeliveryChannel(triggerDeliveryChannel.filter((t) => t.showInList));
    } else {
      setDeliveryChannel(triggerDeliveryEmailChannel);
    }
    setData(newData);
    validateProperty("action", value, schema, error, setError);
  };

  const handleChangeSource = (event, value) => {
    const newData = {
      ...data,
      sources: value,
    };
    setData(newData);
  };

  const handleChangeDelivery = (event, value) => {
    let newData = {};
    if (value.value === "sms") {
      newData = {
        ...data,
        recipe_roles: [],
        recipe_users: [],
        recipe_custom: "",
        corrective_action: 0,
        delivery_channel: value,
      };
    } else {
      newData = {
        ...data,
        delivery_channel: value,
      };
    }
    setData(newData);
    validateProperty("delivery_channel", value, schema, error, setError);
  };

  const handleChangeRoles = (event, value) => {
    const newData = {
      ...data,
      roles: value,
    };
    setData(newData);
  };

  const handleChangeUsers = (event, value) => {
    const newData = {
      ...data,
      users: value,
    };
    setData(newData);
  };

  const handleChangeRecipeRoles = (event, value) => {
    const newData = {
      ...data,
      recipe_roles: value,
    };
    setData(newData);
  };

  const handleChangeRecipeUsers = (event, value) => {
    const newData = {
      ...data,
      recipe_users: value,
    };
    setData(newData);
  };

  const handleBlur = (event, value) => {
    let newData = {};

    switch (event.name) {
      case "name":
        newData = {
          ...data,
          name: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "subject":
        newData = {
          ...data,
          subject: event.value,
        };
        break;
      case "threshold":
        newData = {
          ...data,
          threshold: event.value,
        };
        break;
      case "recipe_custom":
        newData = {
          ...data,
          recipe_custom: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const closeDrawer = () => {
    dispatchTriggers({
      type: "TOGGLE_MANAGE",
      openManageTriggers: false,
    });
    dispatchTriggers({
      type: "SET_TRIGGERS_SELECTED",
      triggersSelected: null,
    });
    setData(InitialData);
    setStep(0);
    setSchema(triggerStep0Schema);
  };

  const changeCorrectiveAction = (option) => {
    const newData = {
      ...data,
      corrective_action: option,
    };
    setData(newData);
  };

  const handleTriggerActions = (event, value) => {
    setActionRuleSelected(value);
    setActionRule(value?.value ?? 0);
  };

  const handleActionOnClose = () => {
    setActionRuleSelected(null);
    setActionRule(0);
  };

  const handleActionRule = (value) => {
    setActionRule(value);
    setActionRuleSelected(
      triggerActionsOptions.find((item) => item.value === value)
    );
  };

  const handleTriggerNotification = (event, value) => {
    setNotificationRuleSelected(value);
    setNotificationRule(value?.value ?? 0);
  };

  const handleNotificationOnClose = () => {
    setNotificationRuleSelected(null);
    setNotificationRule(0);
  };

  const handleNotificationRule = (value) => {
    setNotificationRule(value);
    setNotificationRuleSelected(
      triggerNotificationOptions.find((item) => item.value === value)
    );
  };

  const hasActionRules =
    data.sources.length ||
    data.users.length ||
    data.roles.length ||
    data.customers.length ||
    data.jobs.length;

  const hasNotificationRules =
    data.recipe_roles.length ||
    data.recipe_users.length ||
    data.recipe_custom !== "";

  return (
    <Drawer
      anchor="right"
      open={openManageTriggers}
      className={classes.drawerWrapper}
    >
      <IconButton
        className={classes.buttonClose}
        aria-label="Close"
        onClick={closeDrawer}
      >
        <CloseIcon className={classes.iconClose} />
      </IconButton>
      <Box className={classes.currentStepContainer}>
        <Typography className={classes.currentStep}>
          Step {step + 1}/{data.delivery_channel?.value === "sms" ? 2 : 3}
        </Typography>
      </Box>
      {step === 0 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              General Information
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the trigger you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                label="Trigger Name(*)"
                error={error}
                value={data.name}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="subject"
                label="Trigger Subject"
                error={error}
                value={data.subject}
                handleBlur={handleBlur}
              />

              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={triggerActions}
                name="action"
                error={error}
                handleChange={handleChangeAction}
                label="Action(*)"
                value={data.action}
              />
              {data.action &&
              data.action?.value !== "1" &&
              data.action?.value !== "5" ? (
                <Grid container spacing={2}>
                  <Grid item xs={7} sm={7} md={7}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={triggerOperators}
                      name="condition_operator"
                      error={error}
                      handleChange={handleChangeOperator}
                      label="Condition(*)"
                      value={data.condition_operator}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <FormInput
                      gridSizes={[{ size: "md", val: 9 }]}
                      name="threshold"
                      label="Threshold(*)"
                      error={error}
                      value={data.threshold}
                      handleBlur={handleBlur}
                      isObjectEndText={true}
                      endText={data.action?.value === "7" ? "" : "%"}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {data.action && data.action?.value !== "7" ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={deliveryChannel}
                  name="delivery_channel"
                  error={error}
                  handleChange={handleChangeDelivery}
                  label="Notification Type(*)"
                  value={data.delivery_channel}
                />
              ) : null}

              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  disabled={data.type < 1}
                  onClick={() => changeStep(1)}
                  disableElevation
                >
                  {"Next"}
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </>
      ) : step === 1 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Trigger Actions
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the trigger actions rules
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              {actionRule === 0 ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={
                    data.action?.value === "7"
                      ? triggerActionsOptionsRating
                      : triggerActionsOptions
                  }
                  name="trigger_action"
                  error={error}
                  handleChange={handleTriggerActions}
                  label="Trigger Action"
                  value={actionRuleSelected}
                />
              ) : null}
              {actionRule > 0 ? (
                <Box className={classes.actionItemWrapper}>
                  <Box className={classes.actionNavWrapper}>
                    <IconButton
                      aria-label="close-action"
                      onClick={handleActionOnClose}
                    >
                      <HideIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  {actionRule === 1 ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={sourcesType}
                      name="sources"
                      multiple={true}
                      error={error}
                      handleChange={handleChangeSource}
                      label="Submit Survey"
                      value={data.sources}
                      onClose={handleActionOnClose}
                    />
                  ) : null}
                  {actionRule === 2 ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={roles}
                      name="roles"
                      multiple={true}
                      error={error}
                      handleChange={handleChangeRoles}
                      label="Submit survey by specific user role"
                      value={data.roles}
                      onClose={handleActionOnClose}
                    />
                  ) : null}
                  {actionRule === 3 ? (
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={usersList}
                      label="Submit survey by specific user"
                      name="users"
                      error={error}
                      handleChange={handleChangeUsers}
                      multiple={true}
                      value={data.users}
                      onClose={handleActionOnClose}
                    />
                  ) : null}
                  {actionRule === 4 ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={customers}
                      name="customers"
                      multiple={true}
                      error={error}
                      handleChange={handleChangeCustomer}
                      label={
                        data.action?.value !== "7"
                          ? "Submit survey customer"
                          : "Workticket customer"
                      }
                      value={data.customers}
                      onClose={handleActionOnClose}
                    />
                  ) : null}
                  {actionRule === 5 ? (
                    !isLoadingJob ? (
                      <FormSelectJobsAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={jobs}
                        name="jobs"
                        multiple={true}
                        label="Submit survey job"
                        error={error}
                        value={data.jobs}
                        handleChange={changeJob}
                        onClose={handleActionOnClose}
                      />
                    ) : (
                      <LinearProgress color="secondary" />
                    )
                  ) : null}
                </Box>
              ) : null}
              {!hasActionRules ? (
                <Box className={classes.actionEmptyWrapper}>
                  <Typography
                    variant="h6"
                    gutterBottom={true}
                    className={classes.headerContainerSubTitle}
                  >
                    No actions rules added yet
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.actionDetailWrapper}>
                  {data.sources.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">Submit surveys</Typography>
                      {actionRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleActionRule(1)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}

                      {data.sources.map((item) => (
                        <Chip
                          key={`survey-${item.value}`}
                          variant="outlined"
                          size="small"
                          label={item.label}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.roles.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">
                        Submit survey by specific user roles
                      </Typography>
                      {actionRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleActionRule(2)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {data.roles.map((item) => (
                        <Chip
                          key={`role-${item.value}`}
                          variant="outlined"
                          size="small"
                          label={item.label}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.users.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">
                        Submit survey by specific users
                      </Typography>
                      {actionRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleActionRule(3)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {data.users.map((item) => (
                        <Chip
                          key={`user-${item.id}`}
                          variant="outlined"
                          size="small"
                          label={`${item.first_name} ${item.last_name}`}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.customers.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">
                        {data.action?.value !== "7"
                          ? "Submit survey customers"
                          : "Workticket customers"}
                      </Typography>
                      {actionRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleActionRule(4)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {data.customers.map((item) => (
                        <Chip
                          key={`customer-${item.value}`}
                          variant="outlined"
                          size="small"
                          label={item.label}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.jobs.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">
                        Submit survey jobs
                      </Typography>
                      {actionRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleActionRule(5)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}

                      {data.jobs.map((item) => (
                        <Chip
                          key={`job-${item.value}`}
                          variant="outlined"
                          size="small"
                          label={item.job_description}
                        />
                      ))}
                    </Box>
                  ) : null}
                </Box>
              )}

              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(0)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                {isLoadingTrigger ? (
                  <Box className={classes.loadingPrevContainer}>
                    <Box className={classes.centerLoadingSideBar}>
                      <LoadingStateHorizontal isVisible />
                    </Box>
                  </Box>
                ) : (
                  <ButtonBase color="secondary" onClick={() => changeStep(2)}>
                    {data.delivery_channel?.value === "sms" ||
                    data.action?.value === "7"
                      ? triggersSelected
                        ? "Update"
                        : "Create"
                      : "Next"}
                  </ButtonBase>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : step === 2 && data.delivery_channel?.value !== "sms" ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Trigger Notification
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the trigger notification.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              {notificationRule === 0 ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={triggerNotificationOptions}
                  name="trigger_notification"
                  error={error}
                  handleChange={handleTriggerNotification}
                  label="Notification Receivers"
                  value={notificationRuleSelected}
                />
              ) : null}

              {notificationRule > 0 ? (
                <Box className={classes.actionItemWrapper}>
                  <Box className={classes.actionNavWrapper}>
                    <IconButton
                      aria-label="close-action"
                      onClick={handleNotificationOnClose}
                    >
                      <HideIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  {notificationRule === 1 ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={roles}
                      name="recipe_roles"
                      multiple={true}
                      error={error}
                      handleChange={handleChangeRecipeRoles}
                      label="Roles"
                      value={data.recipe_roles}
                      onClose={handleNotificationOnClose}
                    />
                  ) : null}
                  {notificationRule === 2 ? (
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={usersList}
                      error={error}
                      label="Users"
                      name="recipe_users"
                      handleChange={handleChangeRecipeUsers}
                      multiple={true}
                      value={data.recipe_users}
                      onClose={handleNotificationOnClose}
                    />
                  ) : null}
                  {notificationRule === 3 ? (
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="recipe_custom"
                      label="Manual Emails (Comma separated)"
                      error={error}
                      value={data.recipe_custom}
                      handleBlur={handleBlur}
                    />
                  ) : null}
                </Box>
              ) : null}

              {!hasNotificationRules ? (
                <Box className={classes.actionEmptyWrapper}>
                  <Typography
                    variant="h6"
                    gutterBottom={true}
                    className={classes.headerContainerSubTitle}
                  >
                    No notification receivers added yet(*)
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.actionDetailWrapper}>
                  {data.recipe_roles.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">Roles</Typography>
                      {notificationRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleNotificationRule(1)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}

                      {data.recipe_roles.map((item) => (
                        <Chip
                          key={`recipe-role-${item.value}`}
                          variant="outlined"
                          size="small"
                          label={item.label}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.recipe_users.length ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">Users</Typography>
                      {notificationRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleNotificationRule(2)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {data.recipe_users.map((item) => (
                        <Chip
                          key={`recipe-user-${item.id}`}
                          variant="outlined"
                          size="small"
                          label={`${item.first_name} ${item.last_name}`}
                        />
                      ))}
                    </Box>
                  ) : null}
                  {data.recipe_custom !== "" ? (
                    <Box className={classes.actionDetailItem}>
                      <Typography variant="body2">Manual Emails</Typography>
                      {notificationRule === 0 ? (
                        <IconButton
                          aria-label="update-action"
                          onClick={() => handleNotificationRule(3)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      <Chip
                        variant="outlined"
                        size="small"
                        label={data.recipe_custom}
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
              <Box
                className={
                  data.has_randomize
                    ? classes.containerOptions3
                    : classes.containerOptions2
                }
              >
                <InputLabel className={classes.inputLabelP}>
                  This survey will create a corrective action?
                </InputLabel>
                <Typography
                  variant="body2"
                  className={classes.inputLabelTooltip}
                >
                  Job manager will receive a notification and assign
                </Typography>
                <ButtonGroup
                  variant="outlined"
                  color="default"
                  aria-label="primary button group"
                  className={classes.groupOption2}
                  fullWidth={true}
                >
                  <Button
                    className={
                      data.corrective_action === 1
                        ? classes.groupOptionActive
                        : null
                    }
                    onClick={() => changeCorrectiveAction(1)}
                  >
                    Yes
                  </Button>
                  <Button
                    className={
                      data.corrective_action !== 1
                        ? classes.groupOptionActive
                        : null
                    }
                    onClick={() => changeCorrectiveAction(0)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(1)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                {data.recipe_custom.length > 0 ||
                data.recipe_roles.length > 0 ||
                data.recipe_users.length > 0 ? (
                  isLoadingTrigger ? (
                    <Box className={classes.centerLoadingSideBar}>
                      <LoadingStateHorizontal isVisible />
                    </Box>
                  ) : (
                    <ButtonBase color="secondary" onClick={() => changeStep(3)}>
                      {triggersSelected ? "Update" : "Create"}
                    </ButtonBase>
                  )
                ) : (
                  <Tooltip title="Add at least one notification receiver.">
                    <div>
                      <ButtonBase
                        color="secondary"
                        onClick={() => changeStep(3)}
                        disabled={true}
                      >
                        {triggersSelected ? "Update" : "Create"}
                      </ButtonBase>
                    </div>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </Drawer>
  );
};

export default withRouter(SideDrawer);
