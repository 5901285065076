import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { default as useStyles, InputStyled } from "./styles";
import clsx from "clsx";

const FormSelect = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    fieldValue,
    fieldText,
    fieldKey,
    error,
    options,
    handleBlur,
    disabled,
    blurType,
    gridSizes,
    redirectIcon,
    internal,
    readonly,
    noMargin,
    sameLine,
    withoutLabel,
    changeValueControlled,
  } = props;
  const [stateValue, setStateValue] = useState(value);
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);
  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  useEffect(() => {
    if (value !== null) {
      setStateValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    if (!changeValueControlled) {
      setStateValue(event.target.value);
    }

    if (handleBlur) {
      handleBlur(event.target, blurType ?? "normal");
    }
  };

  return (
    <div
      className={
        noMargin
          ? classes.noMargin
          : internal && sameLine
          ? classes.inputSameRow
          : internal
          ? classes.inputInternalContainer
          : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        {!withoutLabel && (
          <Grid item xs={12} md={12}>
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {label}
            </InputLabel>
          </Grid>
        )}
        <Grid item xs={gridSizeXs} md={gridSizeMd}>
          <Select
            className={classNames({
              [classes.selectField]: redirectIcon,
            })}
            name={name}
            labelId="simple-select-label"
            id="simple-select"
            value={stateValue}
            fullWidth={true}
            readOnly={readonly ?? false}
            sx={{
              "& .MuiSvgIcon-root": {
                rotate: "180deg",
              },
            }}
            onChange={handleChange}
            input={
              <InputStyled
                className={classNames({
                  [classes.disabled]: readonly,
                })}
              />
            }
            disabled={disabled ? true : false}
          >
            {options.length > 0 &&
              options.map((option) => {
                return (
                  <MenuItem
                    value={fieldValue ? option[fieldValue] : option.value}
                    key={fieldKey ? option[fieldKey] : option.value}
                  >
                    {fieldText ? option[fieldText] : option.label}
                  </MenuItem>
                );
              })}
          </Select>
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormSelect;
