import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import NotesTable from "./notesTable";
import NotesSurfaceTable from "./notesSurfaceTable";
import { NoteProvider } from "contexts/noteContext";
import { NoteProvider as NoteSurfaceProvider } from "contexts/noteSurfaceContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const NotesList = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const { isDashboard, dashboardState } = props;

  return (
    <>
      {!isDashboard ? (
        <Box>
          <PageHeader pageTitle="Notes" link="/notes">
            <Link to="/dashboard">Dashboard</Link>
          </PageHeader>
        </Box>
      ) : null}
      <>
        {hasPermission(permissionSurface.NOTES, permissions) ? (
          <NoteSurfaceProvider>
            <NotesSurfaceTable
              dashboardState={dashboardState}
              isDashboard={isDashboard}
            />
          </NoteSurfaceProvider>
        ) : (
          <NoteProvider>
            <NotesTable
              dashboardState={dashboardState}
              isDashboard={isDashboard}
            />
          </NoteProvider>
        )}
      </>
    </>
  );
};

export default withTableFilterContext(NotesList);
