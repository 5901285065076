import React from "react";
import CommentsIcon from "assets/icons/commentsIcon";
import CommentsIconBlack from "assets/icons/commentsIconBlack";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useWorkticketDispatch } from "contexts/workticketContext";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const WorkticketComments = (props) => {
  const classes = useStyles();
  const workticketAction = useWorkticketAction();
  const dispatchWorkticketAction = workticketAction[1];
  const dispatchWorktickets = useWorkticketDispatch();
  const { hasComments, row } = props;

  const handleClick = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_COMMENT",
      open: true,
    });
    dispatchWorktickets({
      type: "SET_WORKTICKET_SELECTED",
      workticketSelected: row,
    });
  };

  return (
    <Box className={classes.root} onClick={handleClick}>
      {hasComments ? <CommentsIconBlack /> : <CommentsIcon />}
    </Box>
  );
};

export default WorkticketComments;
