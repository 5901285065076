import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    fontFamily: "Lato",
    textTransform: "none",
    boxShadow: "none",
    fontSize: 14,
    width: 60,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    borderColor: "#78c1ce",
    padding: "6px 6px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

export default withStyles(styles)((props) => (
  <Button variant="contained" {...props}>
    View
  </Button>
));
