import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const mapping = {
  1: {
    text: "Opened",
    color: "#78C1CE",
  },
  2: {
    text: "Identified",
    color: "#4F98BC",
  },
  3: {
    text: "Interviewing",
    color: "#EFC945",
  },
  4: {
    text: "Offered",
    color: "#F39A3E",
  },
  5: {
    text: "Onboarding",
    color: "#9CBB65",
  },
  6: {
    text: "Filled",
    color: "#03A550",
  },
  7: {
    text: "Closed",
    color: " #CF7F7F",
  },
  10: {
    text: "Archived",
    color: "#747474",
  },
};
const OpenPositionChip = ({ status, archived }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.radiusChip}
      style={
        archived === 1
          ? { backgroundColor: mapping[10]?.color }
          : { backgroundColor: mapping[status]?.color }
      }
    >
      <Typography className={classes.chipFontColor}>
        {archived === 1 ? "Archived" : mapping[status]?.text}
      </Typography>
    </Box>
  );
};
export default OpenPositionChip;
