import React from "react";
import Box from "@material-ui/core/Box";
import CompletedTable from "./completedTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { CompletedProvider } from "contexts/surveyCompletedContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import ViewDialog from "./dialog/viewDialog";
import SurveyContent from "pages/survey/surveyContent";
import useStyles from "./styles";

const CompletedList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <CompletedProvider>
        <CompletedTable />
        <SurveyFillProvider>
          <ViewDialog />
          <SurveyContent />
        </SurveyFillProvider>
      </CompletedProvider>
    </Box>
  );
};

export default withTableFilterContext(CompletedList);
