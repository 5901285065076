import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/open-positions`;

export function getOpenPositionsList(filterData, query, offset = "", tabName) {
  const filterString = getFilterString(filterData);
  if (query) {
    return http.get(
      `${apiEndpoint}?${filterString}search=${query}${
        offset ? `filters[tab]=${tabName}&offset=` : ""
      }${offset ?? ""}`
    );
  } else {
    return http.get(
      `${apiEndpoint}?${filterString}${
        offset ? `filters[tab]=${tabName}&offset=` : ""
      }${offset ?? ""}`
    );
  }
}

export function getOpenPositionById(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function deleteOpenPositionById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function updateOpenPositionById(obj, id) {
  return http.put(`${apiEndpoint}/${id}`, obj);
}

export function updateOpenPositionByIdBulk(idArr, id) {
  let payload = new FormData();
  idArr.forEach((item) => {
    payload.append("open_positions_ids[]", item);
  });
  return http.post(`${apiEndpoint}/update/bulk`, id);
}

export function getPositionTitle() {
  return http.get(`${apiUrl}/open-positions-titles`);
}

export function downloadFile(idArr) {
  let payload = new FormData();
  idArr.forEach((v) => {
    payload.append("open_positions_ids[]", v);
  });
  return http.post(`${apiEndpoint}/export-ids`, payload);
}

export function createOpenPosition(formData) {
  return http.post(apiEndpoint, formData);
}

export function anyUpdateById(id, obj) {
  return http.put(`${apiEndpoint}/${id}`, obj);
}

export function uploadFileOpenPosition(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function addOpenPositionsComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comment`, data);
}

export function updateOpenPositionsComment(id, id_comment, data) {
  return http.put(`${apiEndpoint}/${id}/comments/${id_comment}`, data);
}

export function deleteOpenPositionsComment(id, id_comment) {
  return http.delete(`${apiEndpoint}/${id}/comments/${id_comment}`);
}
