import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { permissionQuote, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import { useProjectView } from "contexts/projectViewContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    fontWeight: "bold",
  },
  buttonCreate: {
    color: "#4f98bc",
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonCreateActive: {
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {},
  },
}));

const ProjectTabQuoteActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const hasCreate = hasPermission(permissionQuote.CREATE, permissions);

  const handleLink = () => {
    const { history } = props;
    history.push(`/quote/new/${project.id}`);
  };
  return (
    hasCreate && (
      <div className={classes.root}>
        {!project.has_completed_worktickets && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonCreate)}
            onClick={handleLink}
          >
            Create Quote
          </Button>
        )}
      </div>
    )
  );
};

export default withRouter(ProjectTabQuoteActions);
