import React from "react";
import * as activityService from "../services/customerService";
import { merge } from "lodash";

const defaultState = {
  activityListLoading: true,
  activityList: [],
  activityCount: [],
};

const ActivityStateContext = React.createContext();
const ActivityDispatchContext = React.createContext();

function activityReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        activityListLoading: action.activityListLoading,
      };
    case "SET_ACTIVITY_LIST":
      return {
        ...state,
        activityList: action.activityList,
      };
    case "SET_ACTIVITY_COUNT":
      return {
        ...state,
        activityCount: action.activityCount,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ActivityProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(activityReducer, mergedState);
  return (
    <ActivityStateContext.Provider value={state}>
      <ActivityDispatchContext.Provider value={dispatch}>
        {children}
      </ActivityDispatchContext.Provider>
    </ActivityStateContext.Provider>
  );
}

function useActivityState() {
  const context = React.useContext(ActivityStateContext);
  if (context === undefined) {
    throw new Error("useActivityState must be used within a ActivityProvider");
  }
  return context;
}

function useActivityDispatch() {
  const context = React.useContext(ActivityDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useActivityDispatch must be used within a ActivityProvider"
    );
  }
  return context;
}

async function getActivityList(customer, dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      activityListLoading: true,
    });
    const activityListResult = await activityService.getActivities(
      customer,
      filterData
    );

    dispatch({
      type: "SET_ACTIVITY_LIST",
      activityList: [...activityListResult.data.data.activity_feed],
    });
    dispatch({
      type: "SET_ACTIVITY_COUNT",
      activityCount: activityListResult.data.data.count,
    });
    dispatch({
      type: "SET_LOADING",
      activityListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getActivityListDataSet(
  customer,
  activityList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await activityService.getActivitiesOffset(
      customer,
      offset,
      filterData
    );
    const updateData = activityList.concat(result.data.data.activity_feed);
    dispatch({
      type: "SET_ACTIVITY_LIST",
      activityList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchActivities(customer, search, filterData) {
  try {
    const activityListResult = await activityService.getActivitiesSearch(
      customer,
      search,
      filterData
    );
    return activityListResult.data.data.activity_feed;
  } catch (error) {
    console.log(error);
  }
}

export {
  ActivityProvider,
  useActivityState,
  useActivityDispatch,
  getActivityList,
  searchActivities,
  getActivityListDataSet,
};
