import React from "react";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const AssetCategory = (props) => {
  const classes = useStyles();
  const { categories } = props;

  if (!categories) {
    return "Error categories";
  }
  if (!categories.length) {
    return <Typography variant="body2">None</Typography>;
  }

  return (
    <>
      {categories.map((category) => {
        return (
          <Chip
            key={category.id}
            label={category.name}
            className={classes.chipCat}
          />
        );
      })}
    </>
  );
};

export default AssetCategory;
