import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewPartnerContact,
  deletePartnerContact,
  getPartnerContacts,
  updatePartnerContact,
} from "../../../../services/partners/contactsService";
import { logException } from "../../../../components/util/logUtil";
import { useAddContactModal } from "../../context/AddContactDialogContext";
import { useEffect, useState } from "react";
import { useGetUserId } from "../../context/UserIdContext";
export function useContacts() {
  const { userId } = useGetUserId();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLabelEditing, setIsLabelEditing] = useState(false);
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    isLabelEditing && setIsLabelEditing(false);
  };
  const queryClient = useQueryClient();
  const [contactAction, dispatchContactAction] = useAddContactModal();
  const { contactToEdit, isEditing } = contactAction;
  const [emailErrors, setEmailErrors] = useState([]);
  const [phoneErrors, setPhoneErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDispatchEditContact = (contact, isEditing, open) => {
    dispatchContactAction({
      type: "TOGGLE_EDIT_CONTACT",
      contact,
      isEditing,
      open,
    });
  };
  const handleDispatchAddContact = (open) => {
    dispatchContactAction({
      type: "TOGGLE_CONTACT_DIALOG",
      open,
    });
  };
  const { data: contactsList, isLoading: contactsIsLoading } = useQuery(
    ["contacts", userId],
    async () => {
      const { data } = await getPartnerContacts(userId);
      return data.data.partner_contacts;
    },
    {
      enabled: !!userId,
    }
  );
  const updateContact = useMutation(updatePartnerContact, {
    onSuccess: () => {
      queryClient.invalidateQueries("contacts");
      handleDispatchEditContact(null, false, false);
      setIsSubmitting(false);
      setIsLabelEditing(true);
      setOpenSuccess(true);
      setData(initialState);
    },
    onError: (error) => {
      logException(error, "Failed to update contact");
      setIsSubmitting(false);
    },
  });
  const addNewContact = useMutation(addNewPartnerContact, {
    onSuccess: () => {
      queryClient.invalidateQueries("contacts");
      handleDispatchAddContact(false);
      setIsSubmitting(false);
      setOpenSuccess(true);
      setData(initialState);
    },
    onError: (error) => {
      logException(error, "Failed to add new contact");
      setIsSubmitting(false);
    },
  });

  const deleteContact = useMutation(deletePartnerContact, {
    onSuccess: () => {
      queryClient.invalidateQueries("contacts");
      setIsSubmitting(false);
    },
    onError: (error) => {
      logException(error, "Failed to delete contact");
      setIsSubmitting(false);
    },
  });
  const initialState = {
    first_name: "",
    phone_number: "",
    email: "",
    last_name: "",
  };
  const [data, setData] = useState(initialState);
  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };
  const handleBlurEmail = (event) => {
    if (!isValidEmail(event.value)) {
      setEmailErrors([
        {
          key: "email",
          message: "Please enter a valid email address",
        },
      ]);
    } else {
      setData({ ...data, email: event.value });
      setEmailErrors([]);
    }
  };
  const handleBlurPhoneNumber = (event) => {
    console.log(event.value);
    if (!isValidatePhoneNumber(event.value)) {
      setPhoneErrors([
        {
          key: "phone_number",
          message: "Please enter a valid phone number",
        },
      ]);
    } else {
      setData({ ...data, phone_number: event.value });
      setPhoneErrors([]);
    }
  };
  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  function isValidatePhoneNumber(phoneNumber) {
    const phoneNumberRegex =
      /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneNumberRegex.test(phoneNumber);
  }
  const handleChangeNumberInput = (event) => {
    const { value } = event.target;
    if (value.match(/^[0-9]*$/)) {
      setData({ ...data, [event.name]: value });
    }
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    if (!isValidEmail(data.email)) {
      setEmailErrors([
        {
          key: "email",
          message: "Please enter a valid email address",
        },
      ]);
      setIsSubmitting(false);
      return;
    }
    //validate phone number
    if (!isValidatePhoneNumber(data.phone_number)) {
      setPhoneErrors([
        {
          key: "phone_number",
          message: "Please enter a valid phone number",
        },
      ]);
      setIsSubmitting(false);
      return;
    }
    if (isEditing) {
      updateContact.mutate({
        partner_id: userId,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          contact_id: contactToEdit.id,
        },
      });
    } else {
      addNewContact.mutate({
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          is_primary: 1,
        },
        partner_id: userId,
      });
    }
  };
  useEffect(() => {
    if (contactToEdit) {
      setData({
        first_name: contactToEdit.first_name,
        last_name: contactToEdit.last_name,
        phone_number: contactToEdit.phone_number,
        email: contactToEdit.email,
      });
    }
  }, [contactToEdit]);
  return {
    contactsList,
    contactsIsLoading,
    updateContact,
    setIsSubmitting,
    isSubmitting,
    addNewContact,
    handleDispatchAddContact,
    initialState,
    data,
    setData,
    handleBlur,
    handleSubmit,
    deleteContact,
    handleCloseSuccess,
    openSuccess,
    isEditing,
    isLabelEditing,
    handleChangeNumberInput,
    handleBlurEmail,
    emailErrors,
    phoneErrors,
    handleBlurPhoneNumber,
  };
}
