import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflowY: "scroll",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10,
  },
  containerMain: {
    backgroundColor: "#f8f8f8",
    height: "100%",
    display: "flex",
    position: "relative",
  },
  containerInformation: {
    padding: 20,
    width: "100%",
  },
  containerInformationActions: {
    backgroundColor: "#ececec",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    position: "absolute",
    width: "100%",
    bottom: 0,
    "& button": {
      padding: "7px 5px 7px 5px",
    },
  },
  containerBody: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  containerSidebar: {
    backgroundColor: "#f8f8f8",
    padding: 20,
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitleBody: {
    fontSize: 18,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  containerSubtitle: {
    "& > span:last-child": {
      borderRight: "0 !important",
    },
  },
  containerSubtitleItem: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
    paddingRight: 5,
    borderRight: "1px solid #747474",
  },
  containerTimekeeping: {
    backgroundColor: "#ffffff",
    padding: 10,
    marginBottom: 30,
  },
  containerTimekeepingClock: {
    paddingTop: 40,
    paddingBottom: 40,
    position: "relative",
  },
  containerBodyTaskList: {
    marginTop: 40,
  },
  containerActionButtons: {
    marginTop: 10,
    marginBottom: 30,
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonLink: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    width: "100%",
    display: "block",
    borderRadius: 4,
    minWidth: 64,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
      backgroundColor: "rgba(23, 69, 122, 0.08)",
    },
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  buttonPrimaryAction: {
    background: "#78c1ce !important",
    textTransform: "Capitalize",
    marginRight: 20,
  },
  buttonAdd: {
    background: "#4f98bc !important",
    width: 160,
    marginLeft: 10,
  },
  containerSignature: {
    padding: 10,
    marginBottom: 30,
  },
  containerLocation: {
    marginTop: 20,
  },
  containerLocationBtn: {
    marginTop: 30,
  },
  iconLarge: {
    fontSize: 36,
  },
  textTimer: {
    fontSize: 18,
    fontWeight: "normal",
    marginTop: 18,
    marginLeft: 5,
  },
  taskItemFieldset: {
    width: "100%",
  },
  taskItemContainer: {
    marginTop: "10px !important",
    "& > label:last-child": {
      borderBottom: "1px solid #f8f8f8",
    },
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
  },
  taskItemAdd: {
    backgroundColor: "#ececec",
    "& > .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  boxActions: {
    marginTop: 10,
    textAlign: "right",
  },
  inputNewTask: {
    width: "100%",
    "& > input": {
      backgroundColor: "#ececec",
      width: "100%",
    },
  },
  activityTabRoot: {
    marginBottom: 10,
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerComments: {
    marginTop: 20,
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
  },
  containerActivity: {
    marginTop: 20,
    maxHeight: 500,
    overflowY: "auto",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  iconOptions: {
    width: 18,
    height: 18,
  },
  titleDialog: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: 18,
    "& > svg": {
      position: "relative",
      bottom: "-5px",
    },
  },
  sigCanvas: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    height: 85,
  },
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
  },
  containerStatus: {
    display: "inline-block",
    top: "-3px",
    position: "relative",
    marginLeft: 10,
    "& > .MuiChip-root": {
      height: 21,
      borderRadius: 3,
      fontSize: 10,
      minWidth: 110,
      marginLeft: 5,
      fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: -5,
    },
  },
  chipPrimary: {
    backgroundColor: "#78c1ce !important",
    color: "#fff !important",
  },
  chipDark: {
    backgroundColor: "#747474 !important",
    color: "#fff !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "#dfdfdf !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  containerPriority: {
    marginTop: 10,
    marginBottom: 15,
  },
  dialogHeader: {
    marginTop: 20,
  },
  containerDialog: {
    minHeight: 400,
  },
  tableDialog: {
    marginTop: 30,
    "& td": {
      border: "none",
    },
    "& th": {
      border: "none",
      background: "#ffffff",
    },
    "& tr:nth-child(odd)": {
      background: "#f8f8f8",
    },
  },
  tableHeadDialog: {
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
  },
  formContainerDialog: {
    padding: "0 75px 0 75px",
    marginTop: 20,
  },
  formIconDialog: {
    textAlign: "center",
  },
  formTitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  formSubtitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 10,
  },
  avatarOptions: {
    marginRight: 3,
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
  },
  contentTitle: {
    fontSize: 14,
  },
  optionBox: {
    textAlign: "center",
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px",
  },
  optionBoxContent: {
    fontSize: 10,
  },
  avatarBox: {
    margin: "0 auto",
    marginBottom: 10,
  },
  avatarType1: {
    backgroundColor: "#ffffff",
    "& svg": {
      color: "#4f98bc",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  buttonBegin: {
    textTransform: "Capitalize",
    backgroundColor: "#4f98bc",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#4d90b2",
    },
  },
  buttonBeginLink: {
    textTransform: "Capitalize",
    fontWeight: "normal",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    textDecoration: "none",
    color: "#ffffff",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
    },
  },
  rootBottomNav: {
    width: "80%",
    marginRight: "15%",
    borderTop: "1px solid #d4d4d4",
  },
  rootForm: {
    "& .MuiGrid-item > div": {
      marginBottom: 5,
    },
  },
  titleLabel: {
    color: "#747474",
    fontSize: 14,
    fontWeight: "bold",
  },
  titleContent: {
    fontSize: 18,
    "& svg": {
      top: 5,
      position: "relative",
    },
  },
  sidebarContainer: {
    marginLeft: 10,
    marginRight: 10,
  },
  chipDepartment: {
    marginRight: 10,
    marginBottom: 5,
    background: "#77c1ce",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  chipZone: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  sidebarContainerJob: {
    marginTop: 20,
    "&  .MuiGrid-item > div": {
      marginBottom: 10,
    },
  },
  containerUploadFile: {
    textAlign: "right",
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10,
  },
  containerDisplayFiles: {
    marginTop: 10,
  },
  buttonPin: {
    background: "#8f0aa1 !important",
    "&::after": {
      top: "97%",
      left: 20,
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderTopColor: "#8f0aa1",
      borderWidth: "8px",
      marginLeft: "-8px",
      zIndex: 5,
    },
  },
  infoAlert: {
    marginBottom: theme.spacing(1),
    border: 0,
  },
  sidebarWrapper: {
    padding: theme.spacing(1),
  },
  sidebarWrapperAction: {
    textAlign: "right",
  },
  sectionContainer: {
    padding: 20,
    minHeight: 250,
  },
  contentWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  rootTabs: {
    paddingLeft: 25,
    paddingRight: 25,
    borderBottom: "1px solid #f8f8f8",
    "& .MuiTab-root": {
      minWidth: 80,
    },
  },
  buttonContainer: {
    paddingTop: "38px !important",
  },
}));

export default useStyles;
