import React from "react";
import WorkticketLayout from "./workticketLayout";

import { WorkticketViewProvider } from "contexts/workticketViewContext";

const Workticket = (props) => {
  return (
    <WorkticketViewProvider>
      <WorkticketLayout
        id={props.match.params.id}
        id_project={props.match.params.id_project}
      />
    </WorkticketViewProvider>
  );
};

export default Workticket;
