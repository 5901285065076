import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { quotesStatus, quoteTypeOptions } from "../../constants";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  usePageHeaderState,
  usePageHeaderDispatch
} from "contexts/pageHeaderContext";

import {
  useQuoteState,
  useQuoteDispatch,
  getQuote
} from "contexts/quoteSurfaceContext";

import useStyles from "./styles";

const InitialNewData = {
  customer_id: -1,
  job_number: null,
  project_id: null,
  type: 0,
  subject: "",
  description: "",
  due_date: moment().add(30, "days")
};

const QuoteSurfaceDetail = props => {
  const classes = useStyles();
  const { selectedQuote, quoteDetails, isLoading } = useQuoteState();
  const dispatch = useQuoteDispatch();
  const [newData, setNewData] = React.useState(InitialNewData);

  const { quoteId } = props;

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_QUOTE",
      selectedQuote: quoteId
    });
  }, [dispatch, quoteId]);

  useEffect(() => {
    if (selectedQuote) {
      if (selectedQuote !== "new") {
        const fetchData = async () => {
          await getQuote(dispatch, selectedQuote);
          return dispatch({ type: "SET_LOADING", isLoading: false });
        };
        fetchData();
      }
    }
  }, [dispatch, selectedQuote]);

  const handleBlur = async event => {};

  const handleCreateBlur = async event => {
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value
      };
      setNewData(data);
    }
  };

  if ((isLoading && selectedQuote !== "new") || !selectedQuote) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="id"
            label="Quote Number"
            internal={true}
            readonly={true}
            value={`${quoteDetails.number}`}
          />
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="status"
            label="Status"
            options={quotesStatus}
            internal={true}
            readonly={true}
            value={quoteDetails.status}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="customer"
            label="Customer"
            internal={true}
            readonly={true}
            value={quoteDetails.customer ? quoteDetails.customer.name : ""}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            readonly={true}
            internal={true}
            value={quoteDetails.subject}
            handleBlur={handleBlur}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            internal={true}
            readonly={true}
            value={quoteDetails.description}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
          />
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="type"
            label="Type"
            options={quoteTypeOptions}
            internal={true}
            readonly={true}
            value={quoteDetails.type}
            handleBlur={handleCreateBlur}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job"
            label="Location"
            internal={true}
            value={quoteDetails.job ? quoteDetails.job.job_description : ""}
            readonly={true}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="created"
            label="Created By"
            internal={true}
            value={`${quoteDetails.user.first_name} ${quoteDetails.user.last_name}`}
            readonly={true}
          />
        </Box>
      </Box>
    </>
  );
};
export default withRouter(QuoteSurfaceDetail);
