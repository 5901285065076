import React, { useContext, useReducer } from "react";

const IndividualViewContext = React.createContext();

const initialState = {
  individual: {},
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_INDIVIDUAL":
      return {
        ...state,
        individual: action.individual,
      };
    default:
      return state;
  }
};

const IndividualViewProvider = ({ children }) => (
  <IndividualViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </IndividualViewContext.Provider>
);

const useIndividualView = () => {
  const context = useContext(IndividualViewContext);
  if (context === undefined) {
    throw new Error(
      "useIndividualView must be used within a IndividualViewProvider"
    );
  }
  return context;
};

export { IndividualViewProvider, useIndividualView };
