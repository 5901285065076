import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateQuotesBulk } from "services/quoteService";
import {
  useQuoteState,
  useQuoteDispatch,
  getQuoteTabList,
} from "contexts/quoteTableContext";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import { useFilterState } from "contexts/filterContext";
import { useQuoteAction } from "contexts/quoteActionContext";
import { useTableDispatch } from "contexts/tableContext";
import { useProjectView } from "contexts/projectViewContext";

import useStyles from "./styles";

const SnoozeSingleDialog = (props) => {
  const classes = useStyles();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ?? null;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dueDate, setDueDate] = useState(moment().add(30, "days"));
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [quoteAction, dispatchQuoteAction] = useQuoteAction();
  const { quoteSelected } = useQuoteState();
  const dispatchTable = useTableDispatch();
  const dispatchQuotes = useQuoteDispatch();
  const { openSnoozeSingle } = quoteAction;
  const {
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();

  const handleClose = () => {
    dispatchQuoteAction({
      type: "TOGGLE_SNOOZE_SINGLE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      const snoozeStatus = 30;
      const data = {
        quotes_ids: [quoteSelected.id],
        snooze_date: moment(dueDate).format("YYYY-MM-DD"),
        status: snoozeStatus,
      };
      await updateQuotesBulk(data);

      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };
      await getQuoteTabList(dispatchQuotes, project.id, filterData);

      dispatchTable({
        type: "SET_UPDATED_ROWS",
        rowsUpdated: [quoteSelected.id],
      });

      setTimeout(() => {
        dispatchTable({
          type: "SET_UPDATED_ROWS",
          rowsUpdated: [],
        });
      }, 5000);

      handleClose();
      setIsLoadingDialog(false);
      setOpenSuccess(true);
    } catch (e) {
      console.log("Cannot mark quote as snoozed");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openSnoozeSingle}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuoteIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Quotes
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to snooze this quote?
              </Typography>
              <Box className={classes.formContainer}>
                <FormInputDateAdo
                  name="dueDate"
                  label="Date"
                  value={dueDate}
                  handleChange={setDueDate}
                />
              </Box>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Snooze
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These quote has been snoozed.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default SnoozeSingleDialog;
