export const convertArrayToString = (array, column, delimeter = ", ") => {
  return array
    .map((item) => {
      return item[column];
    })
    .join(delimeter);
};

export const formatPhone = (phone) => {
  if (phone) {
    let C = phone.replace(/[^0-9xX]/g, "");
    C = C.replace(/[xX]/g, "x");
    let B = "";
    if (C.indexOf("x") > -1) {
      B = " " + C.substr(C.indexOf("x"));
      C = C.substr(0, C.indexOf("x"));
    }
    switch (C.length) {
      case 10:
        return C.replace(/(...)(...)(....)/g, "($1) $2-$3") + B;
      case 11:
        if (C.substr(0, 1) === "1") {
          return C.substr(1).replace(/(...)(...)(....)/g, "($1) $2-$3") + B;
        }
        break;
      default:
    }
    return phone;
  } else {
    return "";
  }
};

export const numberToWord = (n, custom_join_character) => {
  if (!n) return false;
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words;

  var and = custom_join_character || "and";

  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user arguemnt into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2]) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
};

export const truncateString = (input, long) => {
  return input.length > long ? `${input.substring(0, long)}...` : input;
};

export const formatNumber = (num) => {
  return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatStringToNumber = (input) => {
  return formatNumber(parseFloat(input).toFixed(2));
};

export const displayFieldBudget = (measure, value) => {
  if (!value) {
    return "";
  }
  if (measure === "$") {
    return `$${formatStringToNumber(value)}`;
  }

  if (measure === "SF") {
    return `${formatStringToNumber(value)} SF`;
  }

  if (measure === "%") {
    return `${formatStringToNumber(value * 100)} %`;
  }
  return `${value} ${measure}`;
};

export const isValidFormatCommentTimestamp = (value) => {
  const regex = /^(?:[0-5]\d):(?:[0-5]\d)$/;
  return regex.test(value) && value.length === 5;
};
