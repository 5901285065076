import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import { createTile, updateTile } from "services/learnService";
import {
  useLearnPageState,
  useLearnPageDispatch,
  getLearnPageList,
} from "contexts/learnPageContext";
import SchoolIcon from "@material-ui/icons/School";
import { useFilterState } from "contexts/filterContext";
import { validate, validateProperty } from "components/util/validationUtil";
import { learnTilesTypes } from "constants.js";
import { getLearnData } from "services/learnService";
import { logException } from "components/util/logUtil";
import LinearProgress from "@material-ui/core/LinearProgress";

import useStyles from "./styles";

const tileSchema = {
  categoryObject: Joi.object()
    .required()
    .label("Type")
    .error(() => {
      return {
        message: "Category is required.",
      };
    }),
  title: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Title is required.",
      };
    }),
  description: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Description is required.",
      };
    }),
  cta_text: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "CTA text is required.",
      };
    }),
  typeObject: Joi.object()
    .required()
    .label("Type")
    .error(() => {
      return {
        message: "Type is required.",
      };
    }),
  url: Joi.string().min(1).required().label("Link"),
};

const initialData = {
  title: "",
  description: "",
  cta_text: "",
  typeObject: null,
  categoryObject: null,
  url: "",
  image_url: null,
  file: null,
};

const ManageTaskDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [data, setData] = useState(initialData);
  const [updatedData, setUpdatedData] = useState({});
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const [categories, setCategories] = useState(null);
  const { tileSelected, openTileManage, reloadCategories } =
    useLearnPageState();
  const dispatchLearnPage = useLearnPageDispatch();
  const { filters } = useFilterState();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await getLearnData();
        const resultForRender = result.data.data.categories.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCategories(resultForRender);
        if (reloadCategories) {
          const newData = {
            ...data,
            categoryObject: null,
          };

          setData(newData);
          dispatchLearnPage({
            type: "TOGGLE_RELOAD_CATEGORIES",
            reloadCategories: false,
          });
        }
      } catch (e) {
        logException(e, "Cannot load categories data");
      }
    };
    if (!categories || reloadCategories) {
      loadCategories();
    }
  }, [categories, reloadCategories]);

  useEffect(() => {
    if (openTileManage) {
      setError([]);
      setUpdatedData({});
      if (tileSelected) {
        const selectedType = learnTilesTypes.filter(
          (item) => item.value === parseInt(tileSelected.type)
        );
        const selectedCategory = categories.filter(
          (item) => item.value === parseInt(tileSelected.category_id)
        );
        setData({
          title: tileSelected.title,
          description: tileSelected.description,
          cta_text: tileSelected.cta_text,
          typeObject: selectedType[0],
          categoryObject: selectedCategory[0],
          url: tileSelected.url,
          image_url: tileSelected.image_url,
          file: null,
        });
      } else {
        setData(initialData);
      }
    }
  }, [openTileManage]);

  const handleClose = () => {
    dispatchLearnPage({
      type: "TOGGLE_MANAGE",
      openTileManage: false,
    });
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(tileSchema, data, setError);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);

        if (tileSelected) {
          await updateTile(tileSelected.id, updatedData);
          setMessageSuccess("Tile updated successfully!");
        } else {
          const createData = {
            title: data.title,
            description: data.description,
            category_id: data.categoryObject.value,
            cta_text: data.cta_text,
            type: data.typeObject.value,
            url: data.url,
            file: data.file,
          };
          await createTile(createData);
          setMessageSuccess("Tile created successfully!");
        }

        const filterData = {
          filters: filters,
        };
        await getLearnPageList(dispatchLearnPage, filterData);
        setIsLoadingDialog(false);
        handleClose();
        setOpenSuccess(true);
      }
    } catch (e) {
      console.log("Cannot update/create Tile");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let updateData = {};
    let total = 0;
    switch (event.name) {
      case "title":
        newData = {
          ...data,
          title: event.value,
        };
        if (tileSelected) {
          updateData = {
            ...updatedData,
            title: event.value,
          };
        }
        break;

      case "description":
        newData = {
          ...data,
          description: event.value,
        };
        if (tileSelected) {
          updateData = {
            ...updatedData,
            description: event.value,
          };
        }
        break;
      case "cta_text":
        newData = {
          ...data,
          cta_text: event.value,
        };
        if (tileSelected) {
          updateData = {
            ...updatedData,
            cta_text: event.value,
          };
        }
        break;

      case "url":
        newData = {
          ...data,
          url: event.value,
        };
        if (tileSelected) {
          updateData = {
            ...updatedData,
            url: event.value,
          };
        }
        break;
      default:
        break;
    }
    validateProperty(event.name, event.value, tileSchema, error, setError);
    setData(newData);
    if (tileSelected) {
      setUpdatedData(updateData);
    }
  };

  const handleChangeType = (event, value) => {
    const newData = {
      ...data,
      typeObject: value,
    };
    setData(newData);
    if (tileSelected && value) {
      setUpdatedData({
        ...updatedData,
        type: value.value,
      });
    }
    validateProperty("typeObject", value, tileSchema, error, setError);
  };

  const handleChangeCategory = (event, value) => {
    const newData = {
      ...data,
      categoryObject: value,
    };
    setData(newData);
    if (tileSelected && value) {
      setUpdatedData({
        ...updatedData,
        category_id: value.value,
      });
    }
    validateProperty("categoryObject", value, tileSchema, error, setError);
  };

  const handleAddCategory = () => {
    dispatchLearnPage({
      type: "TOGGLE_MANAGE_CATEGORY",
      openTileManageCategory: true,
    });
  };

  const handleChangePicture = async (event) => {
    if (event.target.files[0]) {
      let image_url = URL.createObjectURL(event.target.files[0]);
      setData({ ...data, image_url, file: event.target.files[0] });
      if (tileSelected) {
        setUpdatedData({
          ...updatedData,
          file: event.target.files[0],
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={openTileManage}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog2}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.titleContainer}>
                <SchoolIcon className={classes.titleIcon} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  {tileSelected ? "Update Item" : "New Item"}
                </Typography>
              </Box>
              <Box
                className={
                  data.has_es
                    ? classes.formFieldsContainer2
                    : classes.formFieldsContainer
                }
              >
                <Box className={classes.tasksActionsContainer}>
                  <Typography className={classes.titleSection} gutterBottom>
                    Category
                  </Typography>
                  <ButtonBase
                    variant="outlined"
                    color="secondary"
                    className={classes.addOptionsButton}
                    onClick={handleAddCategory}
                  >
                    View Categories
                  </ButtonBase>
                </Box>
                {!reloadCategories ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={categories}
                    name="categoryObject"
                    error={error}
                    handleChange={handleChangeCategory}
                    label="Category"
                    value={data.categoryObject}
                    noLabel={true}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="title"
                  label="Title"
                  error={error}
                  value={data.title}
                  handleBlur={handleBlur}
                />
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="description"
                  label="Description"
                  error={error}
                  value={data.description}
                  handleBlur={handleBlur}
                  multiline={true}
                  rows={2}
                />
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="cta_text"
                      label="CTA Text"
                      error={error}
                      value={data.cta_text}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={learnTilesTypes}
                      name="typeObject"
                      error={error}
                      handleChange={handleChangeType}
                      label="Item Type"
                      value={data.typeObject}
                    />
                  </Grid>
                </Grid>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="url"
                  label="Insert Link"
                  error={error}
                  value={data.url}
                  handleBlur={handleBlur}
                />
                <Typography className={classes.titleSection} gutterBottom>
                  Upload Photo
                </Typography>
                <Box className={classes.infoImage}>
                  <input
                    type="file"
                    accept="image/*"
                    className={classes.inputImage}
                    id="icon-button-file-tile"
                    onChange={handleChangePicture}
                  />
                  <label htmlFor="icon-button-file-tile">
                    <Box className={classes.imageContainer}>
                      {data.image_url ? (
                        <img
                          className={classes.tileImage}
                          src={data.image_url}
                        />
                      ) : (
                        <Box className={classes.messageContainer}>
                          <Typography className={classes.messageUpload}>
                            Upload 300 x 185 Photo Here
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </label>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              <ButtonBase color="secondary" onClick={handleConfirm}>
                {tileSelected ? "Update Item" : "Add Item"}
              </ButtonBase>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={messageSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ManageTaskDialog;
