import { createContext, useReducer, useContext } from "react";

const UserDrawerContext = createContext();

const initialState = {
  drawerState: { right: false },
  isEditing: false,
  isSuccessDialogOpen: false,
  isViewOnly: false,
  isDeleteUserDialogOpen: false,
  isLocationDialogOpen: false,
  isViewDetailsDialogOpen: false,
  selectedUsers: [],
  userToEdit: null,
  successMessage: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        drawerState: { ...state.drawerState, [action.anchor]: action.open },
        isEditing: action.isEditing,
        userToEdit: action.userToEdit,
        isViewOnly: action.isViewOnly,
      };
    case "OPEN_SUCCESS_DIALOG":
      return {
        ...state,
        isSuccessDialogOpen: true,
        successMessage: action.message,
      };
    case "CLOSE_SUCCESS_DIALOG":
      return { ...state, isSuccessDialogOpen: false, successMessage: "" };
    case "OPEN_LOCATION_DIALOG":
      return { ...state, isLocationDialogOpen: true };
    case "CLOSE_LOCATION_DIALOG":
      return { ...state, isLocationDialogOpen: false };
    case "OPEN_VIEW_DETAILS_DIALOG":
      return { ...state, isViewDetailsDialogOpen: true };
    case "CLOSE_VIEW_DETAILS_DIALOG":
      return { ...state, isViewDetailsDialogOpen: false };
    case "OPEN_DELETE_USER_DIALOG":
      return { ...state, isDeleteUserDialogOpen: true };
    case "CLOSE_DELETE_USER_DIALOG":
      return { ...state, isDeleteUserDialogOpen: false };
    case "SELECT_USER":
      return {
        ...state,
        selectedUsers: action.selected,
      };
    default:
      return state;
  }
};

export const UserDrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleDrawer =
    (anchor, open, isEditing, userToEdit = null, isViewOnly = false) =>
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      dispatch({
        type: "TOGGLE_DRAWER",
        anchor,
        open,
        isEditing,
        userToEdit,
        isViewOnly,
      });
    };
  const handleToggleDrawer = (
    anchor,
    open,
    isEditing = false,
    userToEdit = null,
    isViewOnly = false
  ) => {
    dispatch({
      type: "TOGGLE_DRAWER",
      anchor,
      open,
      isEditing,
      userToEdit,
      isViewOnly,
    });
  };

  const handleDialogueOpen = (message) => {
    dispatch({ type: "OPEN_SUCCESS_DIALOG", message });
  };

  const handleDialogueClose = () => {
    dispatch({ type: "CLOSE_SUCCESS_DIALOG" });
  };
  const handleDeleteUserDialogueOpen = () => {
    dispatch({ type: "OPEN_DELETE_USER_DIALOG" });
  };
  const handleDeleteUserDialogueClose = () => {
    dispatch({ type: "CLOSE_DELETE_USER_DIALOG" });
  };
  const handleLocationDialogueOpen = () => {
    dispatch({ type: "OPEN_LOCATION_DIALOG" });
  };

  const handleLocationDialogueClose = () => {
    dispatch({ type: "CLOSE_LOCATION_DIALOG" });
  };

  const handleViewDetailsDialogueOpen = () => {
    dispatch({ type: "OPEN_VIEW_DETAILS_DIALOG" });
  };

  const handleViewDetailsDialogueClose = () => {
    dispatch({ type: "CLOSE_VIEW_DETAILS_DIALOG" });
  };

  const selectUser = (selected) => {
    dispatch({ type: "SELECT_USER", selected });
  };

  return (
    <UserDrawerContext.Provider
      value={{
        ...state,
        toggleDrawer,
        handleDialogueOpen,
        handleDialogueClose,
        handleLocationDialogueOpen,
        handleLocationDialogueClose,
        handleViewDetailsDialogueOpen,
        handleViewDetailsDialogueClose,
        selectUser,
        handleToggleDrawer,
        handleDeleteUserDialogueClose,
        handleDeleteUserDialogueOpen,
      }}
    >
      {children}
    </UserDrawerContext.Provider>
  );
};

export const useUserDrawerContext = () => useContext(UserDrawerContext);
