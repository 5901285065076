import React from "react";
import ManageTileDialog from "./manageTileDialog";
import LearnPageDeleteDialog from "./learnPageDeleteDialog";
import LearnPageCategoriesDialog from "./learnPageCategoriesDialog";

const LearnPageDialog = (props) => {
  return (
    <>
      <ManageTileDialog />
      <LearnPageCategoriesDialog />
      <LearnPageDeleteDialog />
    </>
  );
};
export default LearnPageDialog;
