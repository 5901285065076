import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { logException } from "components/util/logUtil";
import { deleteFile } from "services/fileService";
import useStyles from "./styles";

const imagesTypes = ["gif", "png", "jpg", "jpeg", "bmp", "webp"];

const FilesDisplaySurveyUploaded = (props) => {
  const classes = useStyles();
  const { files, handleRemoveDisplay, restrictRemove, controlled } = props;

  const handleClick = (file) => {
    window.open(file.file_url, "_blank");
  };

  const handleDelete = async (id) => {
    try {
      handleRemoveDisplay(id);
      if (!controlled) {
        await deleteFile(id);
      }
    } catch (e) {
      logException(e, "Cannot remove file");
    }
  };

  const getExtension = (fileName) => {
    return fileName.substr(fileName.lastIndexOf(".") + 1);
  };

  const filesContent = files
    .filter(
      (itemF) =>
        !itemF.is_thumbnail &&
        ((itemF.pivot && !itemF.pivot.is_signature) || !itemF.pivot)
    )
    .map((fileE) => {
      return (
        <Card className={classes.cardRoot} key={fileE.file_name}>
          <Box className={classes.cardThumb} onClick={() => handleClick(fileE)}>
            {imagesTypes.includes(getExtension(fileE.file_name)) ? (
              <img src={fileE.file_url} alt="Survey" />
            ) : (
              <FileIcon />
            )}
          </Box>
          <Typography
            variant="body2"
            className={classes.cardLabel}
            gutterBottom
          >
            {fileE.file_name}
          </Typography>
          {!restrictRemove ? (
            <Box className={classes.wrapperRemove}>
              <IconButton
                aria-label="remove-file"
                size="small"
                onClick={() => handleDelete(fileE.id)}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          ) : null}
        </Card>
      );
    });

  return <Box className={classes.wrapperSurveyRoot}>{filesContent}</Box>;
};

export default FilesDisplaySurveyUploaded;
