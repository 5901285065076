import http from "./httpService";
import jwtDecode from "jwt-decode";
import { apiUrl, tokenKey } from "lib/config";

const apiEndpoint = apiUrl;

http.setJwt(getJwt());

export const login = async (email, password, os = "pc") => {
  const { data } = await http.post(`${apiEndpoint}/login-user`, {
    email_or_id: email,
    password: password,
    os,
  });
  localStorage.setItem(tokenKey, data.data.token);
  localStorage.setItem("user", JSON.stringify(data.data.user));
  http.setJwt(getJwt());
  if (data.data.user.profile.picture) {
    localStorage.setItem(
      "profilePicture",
      data.data.user.profile.picture.file_url
    );
  }
};

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export const logout = async () => {
  localStorage.removeItem(tokenKey);
  localStorage.clear();
  return http.post(`${apiUrl}/logout-user`);
};

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getCurrentUser() {
  if (!localStorage.getItem(tokenKey)) {
    return;
  }
  try {
    const jwt = localStorage.getItem(tokenKey);
    const jwtUserDecode = jwtDecode(jwt);
    if (Date.now() / 1000 > jwtUserDecode.exp) {
      return false;
    }
    return jwtUserDecode;
  } catch (ex) {
    return console.log("ex", ex);
  }
}

export const passwordReset = (email) => {
  return http.post(`${apiEndpoint}/forgot-password-email`, {
    email: email,
  });
};

export const passwordResetSubmit = (data) => {
  return http.post(`${apiEndpoint}/reset-password-email`, data);
};

export const passwordCreateSubmit = (data) => {
  return http.post(`${apiEndpoint}/create-password-email`, data);
};

const authService = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  passwordReset,
  passwordResetSubmit,
  passwordCreateSubmit,
};

export default authService;
