export function ArrowBack() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_575_12078)">
        <path
          d="M15.8337 9.1666H6.52539L10.5921 5.09993C10.9171 4.77494 10.9171 4.2416 10.5921 3.9166C10.2671 3.5916 9.74206 3.5916 9.41706 3.9166L3.92539 9.40827C3.60039 9.73327 3.60039 10.2583 3.92539 10.5833L9.41706 16.0749C9.74206 16.3999 10.2671 16.3999 10.5921 16.0749C10.9171 15.7499 10.9171 15.2249 10.5921 14.8999L6.52539 10.8333H15.8337C16.2921 10.8333 16.6671 10.4583 16.6671 9.99994C16.6671 9.5416 16.2921 9.1666 15.8337 9.1666Z"
          fill="#4F98BC"
        />
      </g>
      <defs>
        <clipPath id="clip0_575_12078">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
