import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = apiUrl + "/super/financial";
const apiEndpointOP = apiUrl + "/financial";

export function getFinancialAP(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/ap?${filterString}`, {
    params: {},
  });
}

export function getFinancialAPSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/ap?${filterString}search=${search}`);
}

export function getFinancialAPOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/ap?${filterString}&filters[tab]=${tab}&offset=${offset}`
  );
}

export function getFinancialPayments(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ap?${filterString}`);
}

export function getFinancialSuppliesList() {
  return http.get(`${apiEndpointOP}/ap/supplies`);
}

export function getFinancialSuppliesItemList(id) {
  return http.get(`${apiEndpointOP}/ap/supplies/${id}`);
}

export function getFinancialSuppliesItemDownload(id) {
  return http.get(`${apiEndpointOP}/ap/supplies-download/${id}`);
}

export function getFinancialPaymentsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ap?${filterString}search=${search}`);
}

export function getFinancialPaymentsOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointOP}/ap?${filterString}tab=${tab}&offset=${offset}}`
  );
}

export function financialAPExport(data) {
  return http.post(`${apiEndpoint}/ap/export-ids`, data);
}

export function financialPaymentExport(data, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/payments/export?${filterString}`, data);
}

export function financialPaymentSuppliesExport(data) {
  return http.post(`${apiEndpointOP}/ap/supplies/export`, data);
}

export function financialPaymentDownload(data, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(
    `${apiEndpoint}/payments/export?download=1&${filterString}`,
    data
  );
}

export function getFinancialAR(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/ar?${filterString}`, {
    params: {},
  });
}

export function getFinancialARSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/ar?${filterString}search=${search}`, {});
}

export function getFinancialAROffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/ar?${filterString}&filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function getFinancialBills(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ar?${filterString}`);
}

export function getFinancialBillsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ar?${filterString}search=${search}`);
}

export function getFinancialBillsOffset(tab, searchAfter, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointOP}/ar?${filterString}tab=${tab}&filters[search_after][]=${searchAfter[0]}&filters[search_after][]=${searchAfter[1]}`
  );
}

export function financialARExport(data) {
  return http.post(`${apiEndpoint}/ar/export-ids`, data);
}

export function financialBillExport(data, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/bills/export?${filterString}`, data);
}

export function financialBillDownload(data, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(
    `${apiEndpoint}/bills/export?download=1&${filterString}`,
    data
  );
}

export function financialExportHistory(type, offset = 0) {
  return http.get(
    `${apiEndpoint}/export-history?filters[types][]=${type}&offset=${offset}`
  );
}

export function getFinancialARDetail(id) {
  return http.get(`${apiEndpointOP}/ar/${id}`, {
    params: {},
  });
}

export function getFinancialAPDetail(id) {
  return http.get(`${apiEndpointOP}/ap/${id}`, {
    params: {},
  });
}

export function postFinancialAP(id, data) {
  return http.post(`${apiEndpointOP}/ap/${id}`, data);
}

export function postFinancialAR(id, data) {
  return http.post(`${apiEndpointOP}/ar/${id}`, data);
}

// export function getFinancialAPSub(filterData) {
//   const filterString = getFilterString(filterData);
//   return http.get(`${apiEndpointOP}/ap/subcontractor/paid?${filterString}`, {
//     params: {},
//   });
// }

// export function getFinancialAPSubSearch(search, filterData) {
//   const filterString = getFilterString(filterData);
//   return http.get(
//     `${apiEndpointOP}/ap/subcontractor/paid?${filterString}search=${search}`,
//     {}
//   );
// }

// export function getFinancialAPSubOffset(offset, filterData) {
//   const filterString = getFilterString(filterData);
//   return http.get(
//     `${apiEndpointOP}/ap/subcontractor/paid?${filterString}&offset=${offset}`,
//     {}
//   );
// }

export function getFinancialAPSub(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ap?${filterString}`);
}

export function getFinancialAPSubSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpointOP}/ap?${filterString}search=${search}`);
}

export function getFinancialAPSubOffset(tab, offset, filterData, searchString) {
  const filterString = getFilterString(filterData);
  return http.get(
    searchString
      ? `${apiEndpointOP}/ap?${filterString}filters[tab]=${tab}&offset=${offset}&search=${searchString}`
      : `${apiEndpointOP}/ap?${filterString}filters[tab]=${tab}&offset=${offset}`
  );
}

export function getFinancialAPCheckSub(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointOP}/ap/subcontractor/check-paid?${filterString}`
  );
}

export function getFinancialAPCheckDetailSub(check_id) {
  return http.get(`${apiEndpointOP}/ap/subcontractor/check-paid/${check_id}`);
}
