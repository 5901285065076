import React, { useContext, useState, useEffect } from "react";
import eosLogo from "../../assets/icons/encompass-logo.png";
import moment from "moment";
import * as classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import RedirectDialog from "components/ui/dialog/redirectDialog";
import ErrorDialog from "components/ui/dialog/errorDialog";
import { ButtonBase } from "components/ui/core/button";
import Button from "@material-ui/core/Button";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FilesDisplaySecureUploaded from "components/ui/FilesDisplayUploaded/filesDisplaySecureUploaded";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { pick } from "lodash";
import GlobalUiContext from "contexts/globalUiContext";
import { useCustomerView } from "contexts/customerViewContext";
import { permissionCustomer, hasPermission } from "lib/permissions";
import { getCustomerContact } from "services/customerService";
import ContactsManage from "./contactsManage";
import { taxEmemptOptions } from "constants.js";

import {
  getCustomerMetadata,
  createCustomerDetail,
  saveCustomerFile,
  updateCustomerDetail,
} from "services/customerService";

import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const activeOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const initData = {
  customer_number: "",
  name: "",
  legal_name: "",
  subdomain: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  delivery_id: 0,
  industry_id: 0,
  start_date: moment(),
  estimated_acv: 0,
  receive_emails: 0,
  encompass_entity_id: 0,
  billing_contact_id: -1,
  billing_email: "",
  is_taxable: false,
  notes: "",
  contact_type: 2,
  request_form_email_address: "",
  internal_support_email: "",
  is_active: 1,
};

const CustomerInformation = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext, dispatchContext] = useCustomerView();
  const [data, setData] = useState(initData);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [entities, setEntities] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [superUser, setSuperUser] = useState(false);
  const [logo, setLogo] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState([]);
  const [update, setUpdate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const {
    customer,
    files: filesUploaded,
    notes,
    jobsSelected,
    isLoading,
    refreshContactsOptions,
  } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading && customer !== "new") {
      setData({
        ...customer,
        name: customer.common_name,
        encompass_entity_id: customer.encompass_entity,
      });
      setIsLoadingCustomer(false);
    } else if (!isLoading) {
      setIsLoadingCustomer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, customer]);

  useEffect(() => {
    const loadOptions = async () => {
      const result = await getCustomerMetadata(1);
      const { delivery_types, industries, is_super, entities } =
        result.data.data;
      if (delivery_types?.length) {
        const deliveryForRender = delivery_types.map((value) => {
          return { value: value.id, label: value.name };
        });
        setDeliveryTypes(deliveryForRender);
      }
      if (industries?.length) {
        const industriesForRender = industries.map((value) => {
          return { value: value.id, label: value.name };
        });
        setIndustries(industriesForRender);
      }
      if (entities?.length) {
        const entitiesForRender = entities.map((value) => {
          return { value: value.id, label: value.name };
        });
        setEntities(entitiesForRender);
      }
      const resultContacts = await getCustomerContact(customer.id);
      const contactsForRender = resultContacts.data.data.contacts.map(
        (value) => {
          return {
            value: value.id,
            label: `${value.first_name} ${value.last_name}`,
            email: value.email,
            contact_type: value.type ?? 1,
          };
        }
      );

      setContacts(contactsForRender);

      setSuperUser(is_super);
      setIsLoadingOptions(false);
    };
    loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.billing_contact_id > 0 && contacts && !data.billing_email) {
      const selectedContact = contacts.filter(
        (c) => c.value === data.billing_contact_id
      );
      if (selectedContact.length)
        setData({ ...data, billing_email: selectedContact[0].email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, data.billing_contact_id, contacts, data.billing_email]);

  useEffect(() => {
    const loadOptions = async () => {
      const resultContacts = await getCustomerContact(customer.id);
      const contactsForRender = resultContacts.data.data.contacts.map(
        (value) => {
          return {
            value: value.id,
            label: `${value.first_name} ${value.last_name}`,
            email: value.email,
            contact_type: value.type ?? 1,
          };
        }
      );
      setContacts(contactsForRender);
      dispatchContext({
        type: "SET_REFRESH_CONTACTS",
        refreshContactsOptions: false,
      });
    };
    if (refreshContactsOptions) loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContactsOptions]);

  useEffect(() => {
    if (!isLoading && customer !== "new") {
      if (customer.logo) {
        setLogo(customer.logo?.file_url);
      }
      if (filesUploaded) {
        setFilesDisplay(filesUploaded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, customer]);

  const handleBlurSubdomain = (event) => {
    if (!fieldsUpdate.includes(event.name)) {
      setFieldsUpdate([...fieldsUpdate, event.name]);
    }
    setData({ ...data, [event.name]: event.value !== "" ? event.value : null });
  };

  const handleBlur = (event) => {
    if (!fieldsUpdate.includes(event.name)) {
      setFieldsUpdate([...fieldsUpdate, event.name]);
    }
    setData({ ...data, [event.name]: event.value });
  };

  const handleSave = async () => {
    const fields = [
      "customer_number",
      "name",
      "legal_name",
      "encompass_entity_id",
      "subdomain",
      "address1",
      "address2",
      "city",
      "state",
      "zip",
      "delivery_id",
      "industry_id",
      "start_date",
      "estimated_acv",
      "billing_contact_id",
      "is_taxable",
      "notes",
      "request_form_email_address",
      "internal_support_email",
      "is_active",
    ];

    if (customer === "new") {
      const dataCreate = pick(data, fields);

      try {
        await createCustomerDetail({
          ...dataCreate,
          notes: notes,
          job_ids: jobsSelected,
        });
        setMsgSuccess("Customer has been created");
        setOpenSuccess(true);
      } catch (e) {
        let displayError = "Please enter the required fields: ";
        const errorMessage = e.response.data.message.replace("Errors: ", "");
        const errorObj = JSON.parse(errorMessage);
        for (let key in errorObj) {
          displayError = displayError.concat(
            errorObj[key].map((item) => item).join(","),
            " "
          );
        }
        setOpenError(true);
        setMsgError(displayError);
      }
    } else {
      console.log(fieldsUpdate);
      const dataUpdate = pick(data, fieldsUpdate);
      try {
        await updateCustomerDetail(customer.id, {
          ...dataUpdate,
        });

        setMsgSuccess("Customer has been updated");
        setOpenSuccess(true);
      } catch (e) {
        logException(e, "Cannot update customer");
        if (
          e.response &&
          (e.response.status === 400 || e.response.status === 401)
        ) {
          setOpenError(true);
          setMsgError("Cannot update customer");
        }
      }
    }
  };

  const handleCancel = () => {
    props.history.push(`/customers`);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeSelectIndustry = (event, item) => {
    if (!fieldsUpdate.includes("industry_id")) {
      setFieldsUpdate([...fieldsUpdate, "industry_id"]);
    }
    setData({ ...data, industry_id: item ? item.value : null });
  };

  const handleChangeSelectDelivery = (event, item) => {
    if (!fieldsUpdate.includes("delivery_id")) {
      setFieldsUpdate([...fieldsUpdate, "delivery_id"]);
    }
    setData({ ...data, delivery_id: item ? item.value : null });
  };

  const handleChangeSelectEntity = (event, item) => {
    if (!fieldsUpdate.includes("encompass_entity_id")) {
      setFieldsUpdate([...fieldsUpdate, "encompass_entity_id"]);
    }
    setData({ ...data, encompass_entity_id: item ? item.value : null });
  };

  const handleChangeSelectTax = (event, item) => {
    if (!fieldsUpdate.includes("is_taxable")) {
      setFieldsUpdate([...fieldsUpdate, "is_taxable"]);
    }
    setData({ ...data, is_taxable: item ? item.value : null });
  };
  const handleChangeSelectContact = (event, item) => {
    if (!fieldsUpdate.includes("billing_contact_id")) {
      setFieldsUpdate([...fieldsUpdate, "billing_contact_id", "contact_type"]);
    }
    setData({
      ...data,
      billing_contact_id: item ? item.value : null,
      billing_email: item ? item.email : "",
      contact_type: item ? item.contact_type : "",
    });
  };

  const handleChangeSelectActive = (item) => {
    if (!fieldsUpdate.includes("is_active")) {
      setFieldsUpdate([...fieldsUpdate, "is_active"]);
    }
    setData({ ...data, is_active: item ? item.value : null });
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleChangeEmails = async () => {
    const newValue = !data.receive_emails;
    try {
      await updateCustomerDetail(customer.id, {
        receive_emails: newValue,
      });
      setData({ ...data, receive_emails: !data.receive_emails });
    } catch (e) {
      logException(e, "Cannot update customer");
      if (
        e.response &&
        (e.response.status === 400 || e.response.status === 401)
      ) {
        setOpenError(true);
        setMsgError("Cannot update customer");
      }
    }
  };

  const handleChangeLogo = async (event) => {
    if (event.target.files[0]) {
      try {
        setLogo(URL.createObjectURL(event.target.files[0]));
        const dataFile = { files: [...event.target.files], is_logo: 1 };
        await saveCustomerFile(customer.id, dataFile);
      } catch (e) {
        logException(e, "Cannot upload logo");
      }
    }
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);
      const data = {
        files: files,
        is_logo: 0,
      };
      const fileResult = await saveCustomerFile(customer.id, data);
      setFilesDisplay([...filesDisplay, ...fileResult.data.data.files]);
      setFiles([]);
      setIsUploading(false);
    } catch (e) {
      logException(e, "Cannot save files");
    }
  };

  const handleFileRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = filesDisplay.filter((file) => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  const handleClick = () => {
    dispatchContext({
      type: "SET_OPEN_CREATE_CONTACT",
      openCreateContact: true,
    });
  };

  const readonly = !hasPermission(permissionCustomer.CREATE_EDIT, permissions);

  if (isLoading || isLoadingCustomer || isLoadingOptions) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <Box className={classes.rootForm}>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <Card className={classes.contentWrapper}>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <FormSelect
                    label="Status"
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={activeOptions}
                    name="is_active"
                    multiple={false}
                    handleBlur={handleChangeSelectActive}
                    value={data.is_active}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="customer_number"
                    label="Customer Number"
                    value={data.customer_number}
                    handleBlur={handleBlur}
                    readonly={customer.id ? true : false}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="legal_name"
                    label="Customer Legal Name"
                    value={data.legal_name}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="name"
                    label="Customer Common Name"
                    value={data.name}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Encompass Entity"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={entities}
                    name="encompass_entity_id"
                    multiple={false}
                    handleChange={handleChangeSelectEntity}
                    value={entities.find(
                      (encompass_entity) =>
                        encompass_entity.value === data.encompass_entity_id
                    )}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Industry"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={industries}
                    name="industry_id"
                    multiple={false}
                    handleChange={handleChangeSelectIndustry}
                    value={industries.find(
                      (industry) => industry.value === data.industry_id
                    )}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Delivery Model"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={deliveryTypes}
                    name="delivery_id"
                    multiple={false}
                    handleChange={handleChangeSelectDelivery}
                    value={deliveryTypes.find(
                      (delivery) => delivery.value === data.delivery_id
                    )}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="address1"
                    label="Billing Address 1"
                    value={data.address1}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="address2"
                    label="Billing Address 2"
                    value={data.address2 ?? ""}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="city"
                    label="City"
                    value={data.city}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="state"
                    label="State"
                    value={data.state}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="zip"
                    label="Zip"
                    value={data.zip}
                    handleBlur={handleBlur}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Billing Contact Name"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={contacts}
                    name="billing_contact_id"
                    multiple={false}
                    handleChange={handleChangeSelectContact}
                    value={contacts.find(
                      (contact) => contact.value === data.billing_contact_id
                    )}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="billing_email"
                    label="Billing Email"
                    value={data.billing_email}
                    readonly={true}
                  />
                </Grid>
                <Grid item md={4} xs={12} className={classes.buttonContainer}>
                  {hasPermission(
                    permissionCustomer.CREATE_EDIT_CONTACT,
                    permissions
                  ) ? (
                    <ButtonBase
                      variant="outlined"
                      color="secondary"
                      onClick={handleClick}
                    >
                      Create Contact
                    </ButtonBase>
                  ) : null}
                  <ContactsManage />
                </Grid>
                <Grid item md={4} xs={6}>
                  <FormSelectAuto
                    label={"Tax Emempt"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={taxEmemptOptions}
                    name="is_taxable"
                    multiple={false}
                    handleChange={handleChangeSelectTax}
                    value={taxEmemptOptions.find(
                      (tax) => tax.value === data.is_taxable
                    )}
                    readonly={readonly}
                  />
                </Grid>
                <Grid item md={8} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="notes"
                    label="Special Tax Considerations"
                    handleBlur={handleBlur}
                    value={data.notes}
                    readonly={readonly}
                  />
                </Grid>
              </Grid>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="internal_support_email"
                label="Internal Support Email"
                value={data?.internal_support_email ?? ""}
                handleBlur={handleBlur}
                readonly={readonly}
              />
              {superUser ? (
                <Grid container spacing={4}>
                  <Grid item md={12} xs={12}>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="subdomain"
                      label="Subdomain"
                      value={data.subdomain ?? ""}
                      handleBlur={handleBlurSubdomain}
                      readonly={readonly}
                    />
                    <Alert severity="info" className={classes.infoAlert}>
                      Entering the subdomain field will enable the Request form
                      for the customer.
                      {data.subdomain ? (
                        <Box className={classes.requestContainer}>
                          Requests Public Page:{" "}
                          <Typography variant="h5">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={`${window.location.protocol}//${window.location.hostname}/request/${data.subdomain}`}
                            >
                              {`${window.location.protocol}//${window.location.hostname}/request/${data.subdomain}`}
                            </a>
                          </Typography>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="request_form_email_address"
                            label="Request Email:"
                            value={data?.request_form_email_address ?? ""}
                            handleBlur={handleBlur}
                            readonly={readonly}
                          />
                        </Box>
                      ) : null}
                    </Alert>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container spacing={4}>
                {!readonly ? (
                  <Grid item md={12} xs={12}>
                    <Box className={classes.boxActions}>
                      <ButtonBase
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </ButtonBase>
                      <ButtonBase
                        color="secondary"
                        className={classNames(
                          classes.button,
                          classes.buttonAdd
                        )}
                        onClick={handleSave}
                      >
                        Save Changes
                      </ButtonBase>
                    </Box>
                  </Grid>
                ) : (
                  <ButtonBase
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Close
                  </ButtonBase>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            {customer !== "new" ? (
              <Box className={classes.sectionContainer}>
                <Typography
                  variant="h4"
                  className={classes.titleContract}
                  gutterBottom
                >
                  Logo
                </Typography>
                <Box className={classes.sidebarContainerLogo}>
                  <input
                    type="file"
                    accept="image/*"
                    className={classes.inputImage}
                    id="icon-button-file"
                    onChange={handleChangeLogo}
                  />
                  <label htmlFor="icon-button-file">
                    <img
                      src={logo ?? eosLogo}
                      alt="logoImage"
                      className={classes.logoImage}
                    />
                    {!readonly ? (
                      <Avatar className={classes.logoImageEdit}>
                        <EditIcon className={classes.logoIconEdit} />
                      </Avatar>
                    ) : null}
                  </label>
                </Box>
                <Box className={classes.sectionContainerFile}>
                  {files && !Boolean(files.length) ? (
                    <Typography
                      variant="h4"
                      className={classes.titleContract}
                      gutterBottom
                    >
                      Documents
                    </Typography>
                  ) : null}
                  {!readonly ? (
                    <Box className={classes.sidebarContainerFile}>
                      <FileUpload
                        value={files}
                        handleChange={setFiles}
                        handleUpdate={setUpdate}
                        filesDisplay={
                          Boolean(update) && (
                            <FilesDisplay
                              files={files}
                              handleRemove={handleFileRemove}
                            />
                          )
                        }
                      />
                    </Box>
                  ) : null}
                  {Boolean(update) ? (
                    <>
                      <Box className={classes.containerUploadFile}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          disableElevation
                          className={classNames(
                            classes.button,
                            classes.buttonUpload
                          )}
                          onClick={handleUploadFiles}
                          disabled={isUploading}
                        >
                          Finish Upload
                        </Button>
                      </Box>
                      {isUploading ? (
                        <LinearProgress color="secondary" />
                      ) : null}
                    </>
                  ) : null}
                  <Box className={classes.containerDisplayFiles}>
                    <FilesDisplaySecureUploaded
                      files={filesDisplay}
                      handleRemoveDisplay={handleRemoveDisplay}
                      restrictRemove={readonly}
                    />
                  </Box>

                  <FormControlLabel
                    className={classes.preferenceFormLabel}
                    control={
                      <Checkbox
                        checked={Boolean(data.receive_emails)}
                        onChange={handleChangeEmails}
                      />
                    }
                    label="Receive Email Notifications"
                  />
                </Box>
              </Box>
            ) : (
              <Skeleton
                animation="wave"
                variant="rect"
                height={300}
                style={{ marginBottom: 20 }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <RedirectDialog
        open={openSuccess}
        hasClose={false}
        title="Success"
        message={msgSuccess}
        redirectLabel={"Okay"}
        redirect={"/customers"}
        handleClose={handleCloseSuccess}
      />
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default CustomerInformation;
