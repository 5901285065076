import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import ContactsManage from "./contactsManage";
import { ButtonBase } from "components/ui/core/button";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import MessageDialog from "components/ui/dialog/messageDialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import pick from "lodash/pick";
import GlobalUiContext from "contexts/globalUiContext";
import { useJobView } from "contexts/jobViewContext";
import { updateJobDetail } from "services/jobService";
import { logException } from "components/util/logUtil";
import { getCustomers } from "services/userService";
import { getFilterJobsCustomer } from "components/util/filterUtil";
import { jobTypesOptions, industryOptionsCore } from "constants.js";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getCustomerContact } from "services/customerService";
import useStyles from "./styles";

const deliveryTypes = {
  0: "None",
  1: "Campus",
  2: "Portfolio",
  3: "Staffing",
  4: "Construction Cleaning",
};

const activeOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const initState = {
  job_number: "",
  job_description: "",
  delivery_id: "",
  total_budget: 0,
  internal_labor_budget: 0,
  external_labor_budget: 0,
  supplies_budget: 0,
  start_date: moment(),
  end_date: moment(),
  primary_senior_director_id: null,
  primary_director_id: null,
  primary_manager_id: null,
  has_labor_budget: 0,
  has_supplies_budget: 0,
  in_report: 0,
};

const JobInformation = (props) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useJobView();
  const [data, setData] = useState(initState);
  const [usersList, setUsersList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const { job, notes, isLoading, refreshContactsOptions } =
    stateContext ?? null;
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (!isLoading && job !== "new") {
      setData({ ...job });
    }
  }, [isLoading, job]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobsCustomer(
          job.customer_id || data.customer_id
        );
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length && (job.customer_id || data.customer_id)) {
      loadJob();
    }
  }, [jobs, job, data.customer_id]);

  useEffect(() => {
    const loadContacts = async () => {
      const resultContacts = await getCustomerContact(job.customer_id);
      const contactsForRender = resultContacts.data.data.contacts.map(
        (value) => {
          return {
            value: value.id,
            label: `${value.first_name} ${value.last_name}`,
            email: value.email,
            contact_type: value.type ?? 1,
          };
        }
      );
      if (contactsForRender.length > 0) {
        setContacts(contactsForRender);
      }
    };
    if (!contacts.length && job.customer_id) {
      loadContacts();
    }
  }, [contacts, job]);

  useEffect(() => {
    if (users && users.length) {
      const uList = users
        .filter((u) => u.tier_classification !== "subcontractor")
        .map((user) => {
          return {
            value: Number(user.id),
            label: `${
              user.employee_number ? `${user.employee_number} - ` : ""
            } ${user.first_name} ${user.last_name}`,
          };
        });
      setUsersList(uList);
    }
  }, [users]);

  useEffect(() => {
    const loadCustomers = async () => {
      const result = await getCustomers();
      const cList = result.data.data.customers.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      setCustomersList(cList);
    };
    if (!customersList.length) {
      loadCustomers();
    }
  }, [customersList]);

  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };

  const handleChangeJob = (event, item) => {
    setData({ ...data, parent_job_id: item ? item.id : null });
  };

  useEffect(() => {
    if (data.main_contact_id > 0 && contacts && !data.main_contact_email) {
      const selectedContact = contacts.filter(
        (c) => c.value === data.main_contact_id
      );
      if (selectedContact.length)
        setData({ ...data, main_contact_email: selectedContact[0].email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.main_contact_id, contacts, data.main_contact_email]);

  useEffect(() => {
    const loadOptions = async () => {
      const resultContacts = await getCustomerContact(job.customer_id);
      const contactsForRender = resultContacts.data.data.contacts.map(
        (value) => {
          return {
            value: value.id,
            label: `${value.first_name} ${value.last_name}`,
            email: value.email,
            contact_type: value.type ?? 1,
          };
        }
      );
      setContacts(contactsForRender);
      dispatchContext({
        type: "SET_REFRESH_CONTACTS",
        refreshContactsOptions: false,
      });
    };
    if (refreshContactsOptions) loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshContactsOptions]);

  const handleSave = async () => {
    try {
      const dataPick = pick(data, [
        "customer_",
        "primary_contact_id",
        "primary_senior_director_id",
        "primary_director_id",
        "job_type",
        "parent_job_id",
        "primary_manager_id",
        "main_contact_id",
        "longitude",
        "latitude",
        "customer_id",
        "has_labor_budget",
        "has_supply_budget",
        "in_report",
      ]);

      const dataUpdate = {
        ...dataPick,
        notes: notes,
      };
      await updateJobDetail(job.id, dataUpdate);
      setMsgSuccess("Job has been updated");
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot update jobs details");
    }
  };

  const handleCancel = () => {
    if (job.customer_id) {
      props.history.push(`/customer/${job.customer_id}`);
    } else {
      props.history.push(`/unassigned-jobs`);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  // const handleChangePrimarySeniorDirector = async (event, user) => {
  //   setData({ ...data, primary_senior_director_id: user?.value ?? null });
  // };

  const handleChangePrimaryDirector = async (event, user) => {
    setData({ ...data, primary_director_id: user?.value ?? null });
  };

  const handleChangePrimaryManager = async (event, user) => {
    setData({ ...data, primary_manager_id: user?.value ?? null });
  };

  const handleChangeSelectContact = (event, item) => {
    setData({
      ...data,
      main_contact_id: item ? item.value : null,
      main_contact_email: item ? item.email : "",
      contact_type: item ? item.contact_type : 1,
    });
  };

  const handleChangeCustomer = (event, item) => {
    setData({
      ...data,
      customer_id: item ? item.value : null,
    });
  };

  const handleJobType = (event, item) => {
    setData({
      ...data,
      job_type: item ? item.value : null,
    });
  };

  const handleChangeInReport = (item) => {
    setData({ ...data, in_report: item ? item.value : 0 });
  };

  const handleChangeLaborActive = (item) => {
    setData({ ...data, has_labor_budget: item ? item.value : 0 });
  };

  const handleChangeSupplyActive = (item) => {
    setData({ ...data, has_supply_budget: item ? item.value : 0 });
  };

  const handleClick = () => {
    dispatchContext({
      type: "SET_OPEN_CREATE_CONTACT",
      openCreateContact: true,
    });
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <Box className={classes.rootForm}>
        <Card className={classes.contentWrapper}>
          <Grid container spacing={4}>
            <Grid item md={4} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="job_number"
                label="Job Number"
                value={data.job_number}
                handleBlur={handleBlur}
                readonly={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="job_description"
                label="Job Name"
                value={data.job_description}
                handleBlur={handleBlur}
                readonly={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormSelectAuto
                label={"Customer/Major Account"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={customersList}
                name="customer"
                multiple={false}
                handleChange={handleChangeCustomer}
                value={customersList.find((c) => c.value === data.customer_id)}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormSelectAuto
                label={"Job Type"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={jobTypesOptions}
                name="job_type"
                multiple={false}
                handleChange={handleJobType}
                value={jobTypesOptions.find(
                  (t) => t.value === Number(data.job_type)
                )}
              />
            </Grid>
            {!Boolean(data.customer_id) ? null : (
              <Grid item md={4} xs={12}>
                {Boolean(jobs.length) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    label="Parent Job"
                    handleChange={handleChangeJob}
                    multiple={false}
                    value={jobs.find(
                      (j) =>
                        j.id ===
                        (data.job_type === 1
                          ? Number(data.id)
                          : Number(data.parent_job_id))
                    )}
                    readonly={data.job_type === 1}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            )}
            <Grid item md={4} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="delivery_id"
                label="Delivery Model"
                value={deliveryTypes[data.delivery_id]}
                readonly={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="industry"
                label="Industry"
                value={
                  industryOptionsCore.find(
                    (i) => Number(i.value) === Number(data.industry_id)
                  )?.label
                }
                readonly={true}
              />
            </Grid>
            {/* <Grid item md={3} xs={12}>
              <FormSelectAuto
                label={"Primary Senior Director"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={usersList}
                name="primary_senior_director"
                multiple={false}
                handleChange={handleChangePrimarySeniorDirector}
                value={usersList.find(
                  (user) =>
                    Number(user.value) ===
                    Number(data.primary_senior_director_id)
                )}
              />
            </Grid> */}
            <Grid item md={4} xs={12}>
              <FormSelectAuto
                label={"Primary Director"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={usersList}
                name="primary_director"
                multiple={false}
                handleChange={handleChangePrimaryDirector}
                value={usersList.find(
                  (user) =>
                    Number(user.value) === Number(data.primary_director_id)
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormSelectAuto
                label={"Primary Manager"}
                gridSizes={[{ size: "md", val: 12 }]}
                options={usersList}
                name="primary_manager"
                multiple={false}
                handleChange={handleChangePrimaryManager}
                value={usersList.find(
                  (user) =>
                    Number(user.value) === Number(data.primary_manager_id)
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="address1"
                label="Address 1"
                value={data.address1}
                readonly={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="address2"
                label="Address 2"
                value={data.address2}
                readonly={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="city"
                label="City"
                value={data.city}
                readonly={true}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="state"
                label="State"
                value={data.state}
                readonly={true}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="zip"
                label="Zip"
                value={data.zip}
                readonly={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={3} xs={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="latitude"
                label="Latitude"
                value={data.latitude}
                readonly={true}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="longitude"
                label="Longitude"
                value={data.longitude}
                readonly={true}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="in_report"
                label="Show In Performance Board"
                value={job.in_report}
                options={activeOptions}
                handleBlur={handleChangeInReport}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="has_labor_budget"
                label="Labor Budget"
                value={job.has_labor_budget}
                options={activeOptions}
                handleBlur={handleChangeLaborActive}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="has_supply_budget"
                label="Supply Budget"
                value={job.has_supply_budget}
                options={activeOptions}
                handleBlur={handleChangeSupplyActive}
              />
            </Grid>
          </Grid>
          <Alert severity="info" className={classes.infoAlert}>
            Read only fields must be updated on Winteam.
          </Alert>
        </Card>
      </Box>
      <Card className={classes.contentWrapper}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <FormSelectAuto
              label={"Main Contact Name"}
              gridSizes={[{ size: "md", val: 12 }]}
              options={contacts}
              name="main_contact_id"
              multiple={false}
              handleChange={handleChangeSelectContact}
              value={contacts.find(
                (contact) => contact.value === data.main_contact_id
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="main_contact_email"
              label="Main Contact Email"
              value={data.main_contact_email}
              readonly={true}
            />
          </Grid>
          <Grid item md={4} xs={12} className={classes.buttonContainer}>
            <ButtonBase
              variant="outlined"
              color="secondary"
              onClick={handleClick}
            >
              Create Contact
            </ButtonBase>
            <ContactsManage />
          </Grid>
        </Grid>

        <Box className={classes.boxActions}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonAdd)}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Box>
      </Card>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={msgSuccess}
      />
    </>
  );
};

export default withRouter(JobInformation);
