import React from "react";
import * as requestService from "services/requestSurfaceService";
import { statusRequestsSurface } from "../constants";
import { merge } from "lodash";

const defaultState = {
  requestListLoading: true,
  requestSelected: null,
  requestList: [],
  requestCount: [],
  requestDetails: null,
};

const RequestStateContext = React.createContext();
const RequestDispatchContext = React.createContext();

function requestReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        requestListLoading: action.requestListLoading,
      };
    case "SET_REQUEST_LIST":
      return {
        ...state,
        requestList: action.requestList,
      };
    case "SET_REQUEST_COUNT":
      return {
        ...state,
        requestCount: action.requestCount,
      };
    case "SET_REQUEST_SELECTED":
      return {
        ...state,
        requestSelected: action.requestSelected,
      };
    case "SET_REQUEST_DETAILS":
      return {
        ...state,
        requestDetails: action.requestDetails,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function RequestProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(requestReducer, mergedState);
  return (
    <RequestStateContext.Provider value={state}>
      <RequestDispatchContext.Provider value={dispatch}>
        {children}
      </RequestDispatchContext.Provider>
    </RequestStateContext.Provider>
  );
}

function useRequestState() {
  const context = React.useContext(RequestStateContext);
  if (context === undefined) {
    throw new Error("useRequestState must be used within a RequestProvider");
  }
  return context;
}

function useRequestDispatch() {
  const context = React.useContext(RequestDispatchContext);
  if (context === undefined) {
    throw new Error("useRequestDispatch must be used within a RequestProvider");
  }
  return context;
}

async function getRequest(dispatch, id) {
  try {
    const requestDetailsResult = await requestService.getRequestDetails(id);
    dispatch({
      type: "SET_REQUEST_DETAILS",
      requestDetails: requestDetailsResult.data.data.request,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getRequestList(dispatch, filterData) {
  try {
    const requestListResult = await requestService.getRequests(filterData);
    dispatch({
      type: "SET_REQUEST_LIST",
      requestList: requestListResult.data.data.requests,
    });
    const requestCount = {
      ...requestListResult.data.data.filter_counts,
    };
    dispatch({
      type: "SET_REQUEST_COUNT",
      requestCount: requestCount,
    });
    dispatch({
      type: "SET_LOADING",
      requestListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getRequestListDataSet(
  requestList,
  dispatch,
  tab,
  offset,
  filterData
) {
  try {
    const findElement = statusRequestsSurface.find((item) => item.id === tab);
    const result = await requestService.getRequestsTabOffset(
      findElement.tab,
      offset,
      filterData
    );
    requestList[tab] = requestList[tab].concat(result.data.data.requests);
    dispatch({
      type: "SET_REQUEST_LIST",
      requestList: { ...requestList },
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchRequests(search, filterData) {
  try {
    const requestListResult = await requestService.getRequestsSearch(
      search,
      filterData
    );
    return requestListResult.data.data.requests;
  } catch (error) {
    console.log(error);
  }
}

export {
  RequestProvider,
  useRequestState,
  useRequestDispatch,
  getRequest,
  getRequestList,
  searchRequests,
  getRequestListDataSet,
};
