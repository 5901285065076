import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionAdmin } from "lib/permissions";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";

const tabs = [
  {
    value: "customers",
    label: "Customers",
    permissionAccess: [permissionAdmin.CUSTOMER_LIST],
  },
  {
    value: "unassigned-jobs",
    label: "Unassigned Jobs",
    permissionAccess: [permissionAdmin.JOB_LIST],
  },
];

const CustomerAdminTabs = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const { value, unassignedJobCount } = props;

  const handleChangeTab = (e, value) => {
    const { history } = props;
    history.push(`/${value}`);
  };

  return (
    <Box className={classes.tabsContainer}>
      <Tabs
        aria-label="tabs"
        value={value}
        className={classes.tabContainer}
        onChange={handleChangeTab}
      >
        {tabs.map((tab) => {
          return tab.permissionAccess.map((permission) => {
            return hasPermission(permission, permissions) ? (
              <Tab
                disableRipple={true}
                label={
                  <Box className={classes.tabItemLabel}>
                    {unassignedJobCount && tab.value !== value ? (
                      <>
                        {`${tab.label} (${unassignedJobCount})`}
                        <DotIcon className={classes.dotIcon} />
                      </>
                    ) : (
                      tab.label
                    )}
                  </Box>
                }
                key={tab.value}
                value={tab.value}
                className={classes.tabItem}
              />
            ) : null;
          });
        })}
      </Tabs>
    </Box>
  );
};

export default withRouter(CustomerAdminTabs);
