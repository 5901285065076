import { useQuery } from "react-query";
import { getPartnerTrades } from "../../../../services/partners/tradesService";
import { useGetUserId } from "../../context/UserIdContext";

const usePartnerTrades = () => {
  const { userId } = useGetUserId();
  const { data, isLoading } = useQuery(
    ["trades", userId],
    async () => {
      const response = await getPartnerTrades(userId);
      return response.data.data.partner_trades;
    },
    {
      enabled: !!userId,
    }
  );

  return { data, isLoading };
};

export default usePartnerTrades;
