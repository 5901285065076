import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuestionIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

import useStyles from "./styles";

const ConfirmDialog = props => {
  const classes = useStyles();

  const {
    open,
    handleConfirm,
    handleClose,
    message,
    quoteId,
    isLoading
  } = props;

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuestionIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                {quoteId}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                {message}
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default ConfirmDialog;
