import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
    paddingBottom: 0,
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 10,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    paddingLeft: 10,
  },
  titleDelete: {
    color: "#747474",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    paddingLeft: 10,
  },
  contentDialog: {
    fontSize: 14,
    paddingTop: 10,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4f98bc",
      boxShadow: "none",
    },
  },
  buttonOutlined: {
    border: "1px solid #4F98BC",
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
      border: "1px solid #4F98BC",
    },
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 0,
    paddingRight: 35,
    paddingBottom: 28,
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  labelText: {
    color: "#747474",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 8,
    paddingTop: 10,
  },
  labelFormText: {
    color: "#747474",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 400,
  },
  containerButton: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonCancel: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    textTransform: "capitalize",
    marginRight: 10,
    color: "#4F98BC",
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#4F98BC",
      boxShadow: "none",
    },
  },
  buttonRedirect: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#4F98BC",
    fontSize: 14,
    textTransform: "none",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },

  titleWithIcon: {
    color: "#4D4D4D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 33,
  },
  inputText: {
    fontSize: 12,
  },
  selectContainer: {
    padding: 10,
  },
  feedbackLabel: {
    color: "#4D4D4D",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 35,
  },
  commentLabel: {
    color: "#747474",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 7,
  },
  textArea: {
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    border: "1px solid #d6dde1",
    borderRadius: 4,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  modalContent: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#78C1CE",
    color: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 1201,
  },
  modalCloseButton: {
    color: "#fff",
  },
  modalTitle: {
    flex: 1,
    textAlign: "center",
    fontSize: "1.25rem",
  },
  selectedText: {
    color: "#fff",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    paddingRight: 20,
  },
  modalBody: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
    position: "relative",
  },
  modalFooter: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  selectedItems: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedItem: {
    margin: theme.spacing(0.5),
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  itemCount: {
    color: "#4D4D4D",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
  },
  stateSelectContainer: {
    display: "flex",
    alignItems: "center",
  },
  stateLabel: {
    color: "#747474",
    marginRight: theme.spacing(2),
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
  },
  stateSelect: {
    width: 200,
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  checkboxItem: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  selectedCheckbox: {
    backgroundColor: "#EDF5F8",
  },

  textField: {
    minWidth: 500,
    borderRadius: 4,
    border: "1px solid  #D9D9D9",
    background: "#FFF",
    marginBottom: 10,
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9",
      },
    },
  },
  formHelperText: {
    color: theme.palette.error.main,
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  selectStates: {
    minWidth: 300,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    overflowY: "auto",
  },
}));

export default useStyles;
