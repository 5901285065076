import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RingBellIcon from "@material-ui/icons/NotificationsActive";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormInput from "components/ui/FormContent/formInput";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useRecognitionState,
  useRecognitionDispatch,
} from "contexts/recognitionContext";
import useStyles from "./styles";

const RecognitionViewDialog = (props) => {
  const classes = useStyles();
  const dispatchRecognition = useRecognitionDispatch();
  const { recognitionSelected, openView } = useRecognitionState();

  const handleClose = () => {
    dispatchRecognition({
      type: "TOGGLE_OPEN_VIEW",
      openView: false,
    });
  };

  return (
    <>
      <Dialog
        open={openView}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {!recognitionSelected ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <RingBellIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Ring the Bell
              </Typography>
              <Box className={classes.formContainer}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="submittedFor"
                  label="Submitted For"
                  value={recognitionSelected.submitted_for}
                  readonly={true}
                />
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="submittedBy"
                  label="Submitted By"
                  value={recognitionSelected.submitted_by}
                  readonly={true}
                />
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="note"
                  label="Note"
                  value={recognitionSelected.note}
                  readonly={true}
                  multiline={true}
                  rows={8}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default RecognitionViewDialog;
