import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Box from "@material-ui/core/Box";
import Table from "components/common/Table/table";
import WorkticketIcon from "@material-ui/icons/Receipt";

import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewDialog = (props) => {
  const { open, handleClose, isLoading } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        position="relative"
        classes={{
          root: classes.rootAppBar,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box className={classes.previewWTContainer}>
          <WorkticketIcon className={classes.previewWTIcon} />
        </Box>
        <Box className={classes.rootWrapper}>
          <Box className={classes.root}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Box className={classes.fullHeightTable}>
                  <Table
                    idscrollwrapper="table-wrapper-preview"
                    handleRowClick={() => {}}
                    handleScrollClick={() => {}}
                    activeMobile={true}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PreviewDialog;
