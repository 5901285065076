import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import DoneIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import ArchivedIcon from "@material-ui/icons/Archive";
import ClockIcon from "@material-ui/icons/WatchLater";

import { workticketsStatusOptions } from "constants.js";
import useStyles from "./styles";

const WorkticketSoloStatusChip = (props) => {
  const classes = useStyles();
  const { category, status, type, overdue, cancellation_reviewed } = props;

  let classChip;
  let iconChip;
  let labelChip;

  const statusSelected = workticketsStatusOptions.find(
    (statusItem) => statusItem.id === parseInt(status)
  );

  if (!statusSelected) {
    return "Error status";
  }

  if (type === 4) {
    classChip = classes.chipCorrectiveAction;
    iconChip = <ErrorIcon />;
    labelChip = "Corrective Action";
  } else if (category === 4) {
    classChip = classes.chipEmergency;
    iconChip = <ErrorIcon />;
    labelChip = "Emergency";
  } else if (parseInt(overdue) === 1) {
    classChip = classes.chipOverdue;
    iconChip = <ErrorIcon />;
    labelChip = "Overdue";
  } else if (Number(status) === 3 && Number(cancellation_reviewed) === 0) {
    classChip = classes.chipCancelled;
    iconChip = <CancelIcon />;
    labelChip = "Pending Cancellation";
  } else {
    labelChip = statusSelected.text;
    switch (statusSelected.text) {
      case "Not Scheduled":
        classChip = classes.chipNotScheduled;
        iconChip = <PauseIcon />;
        break;
      case "Not Started":
        classChip = classes.chipNotStarted;
        iconChip = <PauseIcon />;
        break;
      case "In Progress":
        classChip = classes.chipInProgress;
        iconChip = <PlayIcon />;
        break;
      case "Verified":
        classChip = classes.chipDone;
        iconChip = <DoneIcon />;
        break;
      case "Completed":
        classChip = classes.chipCompleted;
        iconChip = <DoneIcon />;
        break;
      case "Cancelled":
        classChip = classes.chipCancelled;
        iconChip = <CancelIcon />;
        break;
      case "Postpone":
        classChip = classes.chipPostpone;
        iconChip = <CancelIcon />;
        break;
      case "Overdue":
        classChip = classes.chipOverdue;
        iconChip = <ErrorIcon />;
        break;
      case "Archived":
        classChip = classes.chipArchived;
        iconChip = <ArchivedIcon />;
        break;
      case "Request":
        classChip = classes.chipRequest;
        iconChip = <ClockIcon />;
        break;
      default:
        break;
    }
  }

  return (
    <Chip
      icon={iconChip}
      label={labelChip}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default WorkticketSoloStatusChip;
