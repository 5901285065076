import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorknovaDetailActionContext = React.createContext();

const initialState = {
  openUpdate: false,
  openUpdateSingle: false,
  openResolve: false,
  openResolveSingle: false,
  openResolveDuplicateSingle: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_UPDATE":
      return {
        ...state,
        openUpdate: action.open,
      };
    case "TOGGLE_UPDATE_SINGLE":
      return {
        ...state,
        openUpdateSingle: action.open,
      };
    case "TOGGLE_RESOLVE":
      return {
        ...state,
        openResolve: action.open,
      };
    case "TOGGLE_RESOLVE_SINGLE":
      return {
        ...state,
        openResolveSingle: action.open,
      };
    case "TOGGLE_RESOLVE_DUPLICATE_SINGLE":
      return {
        ...state,
        openResolveDuplicateSingle: action.open,
      };
    default:
      return initialState;
  }
};

const WorknovaDetailActionProvider = ({ children }) => (
  <WorknovaDetailActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </WorknovaDetailActionContext.Provider>
);

function useWorknovaDetailAction() {
  const context = React.useContext(WorknovaDetailActionContext);
  if (context === undefined) {
    throw new Error(
      "useWorknovaDetailAction must be used within a WorknovaDetailActionProvider"
    );
  }
  return context;
}

export { WorknovaDetailActionProvider, useWorknovaDetailAction };
