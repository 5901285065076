import { useState, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deletePartnerTrade } from "../../../../services/partners/tradesService";
import { useGetUserId } from "../../context/UserIdContext";

const useTradeManagement = () => {
  const queryClient = useQueryClient();
  const { userId } = useGetUserId();
  const [expanded, setExpanded] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [isEditingTrade, setIsEditingTrade] = useState(false);
  const [tradeToEdit, setTradeToEdit] = useState(null);
  const [workItemId, setWorkItemId] = useState(null);
  const [addingVariant, setAddingVariant] = useState(false);
  const [partnerTradeId, setPartnerTradeId] = useState(null);
  const [drawerState, setDrawerState] = useState({ right: false });

  const deleteTrade = useMutation(deletePartnerTrade, {
    onSuccess: () => {
      queryClient.invalidateQueries("trades");
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });

  const handleEditTrade = useCallback((trade) => {
    setIsEditingTrade(true);
    setTradeToEdit(trade);
    handleToggleDrawer("right", true);
    handleClose();
  }, []);

  const handleDeleteTrade = useCallback(
    (partner_trade_id) => {
      setLoadingState(true);
      deleteTrade.mutate({
        data: {
          partner_trade_id,
        },
        partner_id: userId,
      });
    },
    [deleteTrade]
  );

  const handleAddVariant = useCallback((trade) => {
    setWorkItemId(trade.trade_id);
    setPartnerTradeId(trade.id);
    setAddingVariant(true);
    handleToggleDrawer("right", true);
  }, []);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccordion(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAccordion(null);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const handleToggleDrawer = (anchor, open) => {
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  return {
    expanded,
    anchorEl,
    selectedAccordion,
    loadingState,
    isEditingTrade,
    tradeToEdit,
    workItemId,
    addingVariant,
    partnerTradeId,
    drawerState,
    handleEditTrade,
    handleDeleteTrade,
    handleAddVariant,
    handleClick,
    handleClose,
    handleChange,
    toggleDrawer,
    handleToggleDrawer,
    setIsEditingTrade,
    setAddingVariant,
    setWorkItemId,
  };
};

export default useTradeManagement;
