import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import IconButton from "@material-ui/core/IconButton";
import FormInput from "components/ui/FormContent/formInput";
import CloseIcon from "@material-ui/icons/Close";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "./messageDialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import Grid from "@material-ui/core/Grid";
import { validate, validateProperty } from "components/util/validationUtil";
import { pick } from "lodash";
import {
  useCustomersState,
  useCustomersDispatch,
} from "contexts/adminCustomersContext";
import { taxEmemptOptions } from "constants.js";

import {
  getCustomerMetadata,
  createCustomerDetail,
} from "services/customerService";
import useStyles from "./styles";

const customerSchema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  customer_number: Joi.string()
    .min(1)
    .required()
    .label("customer_number")
    .error(() => {
      return {
        message: "Customer Number is required.",
      };
    }),
  address1: Joi.string()
    .min(1)
    .required()
    .label("address1")
    .error(() => {
      return {
        message: "Billing Address 1 is required.",
      };
    }),
  city: Joi.string()
    .min(1)
    .required()
    .label("city")
    .error(() => {
      return {
        message: "City is required.",
      };
    }),
  state: Joi.string()
    .min(1)
    .required()
    .label("state")
    .error(() => {
      return {
        message: "State is required.",
      };
    }),
  zip: Joi.string()
    .min(1)
    .required()
    .label("zip")
    .error(() => {
      return {
        message: "Zip is required.",
      };
    }),
};

const initData = {
  customer_number: "",
  name: "",
  legal_name: "",
  subdomain: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  delivery_id: null,
  industry_id: null,
  encompass_entity_id: null,
  is_taxable: 0,
  notes: "",
  internal_support_email: "",
};

const CreateCustomerDialog = (props) => {
  const classes = useStyles();
  const dispatchContext = useCustomersDispatch();
  const [data, setData] = useState(initData);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState([]);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [entities, setEntities] = useState([]);
  const { openCreate } = useCustomersState();

  useEffect(() => {
    const loadOptions = async () => {
      const result = await getCustomerMetadata(1);
      const { delivery_types, industries, entities } = result.data.data;
      if (delivery_types?.length) {
        const deliveryForRender = delivery_types.map((value) => {
          return { value: value.id, label: value.name };
        });
        setDeliveryTypes(deliveryForRender);
      }
      if (industries?.length) {
        const industriesForRender = industries.map((value) => {
          return { value: value.id, label: value.name };
        });
        setIndustries(industriesForRender);
      }
      if (entities?.length) {
        const entitiesForRender = entities.map((value) => {
          return { value: value.id, label: value.name };
        });
        setEntities(entitiesForRender);
      }
      setIsLoadingOptions(false);
    };
    loadOptions();
  }, []);

  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
    if (
      ["name", "city", "customer_number", "state", "zip", "address1"].includes(
        event.name
      )
    ) {
      validateProperty(
        event.name,
        event.value,
        customerSchema,
        error,
        setError
      );
    }
  };

  const handleSave = async () => {
    const fields = [
      "customer_number",
      "name",
      "legal_name",
      "encompass_entity_id",
      "address1",
      "address2",
      "city",
      "state",
      "zip",
      "delivery_id",
      "industry_id",
      "is_taxable",
      "notes",
      "internal_support_email",
    ];

    const dataCreate = pick(data, fields);

    try {
      const errors = validate(customerSchema, data, setError);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);
        await createCustomerDetail({
          ...dataCreate,
        });
        setIsLoadingDialog(false);
        setMessageSuccess("Customer has been created");
        setOpenSuccess(true);
        handleClose();
        dispatchContext({
          type: "SET_REFRESH",
          refreshList: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeSelectIndustry = (event, item) => {
    setData({ ...data, industry_id: item ? item.value : null });
  };

  const handleChangeSelectDelivery = (event, item) => {
    setData({ ...data, delivery_id: item ? item.value : null });
  };

  const handleChangeSelectEntity = (event, item) => {
    setData({ ...data, encompass_entity_id: item ? item.value : null });
  };

  const handleChangeSelectTax = (event, item) => {
    setData({ ...data, is_taxable: item ? item.value : null });
  };

  const handleClose = () => {
    dispatchContext({
      type: "SET_OPEN_CREATE",
      openCreate: false,
    });
    setData(initData);
  };

  return (
    <>
      <Dialog
        open={openCreate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        {isLoadingDialog || isLoadingOptions ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog2}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.titleContainer}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  New Customer
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="customer_number"
                    label="Customer Number"
                    error={error}
                    value={data.customer_number}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="legal_name"
                    label="Customer Legal Name"
                    value={data.legal_name}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="name"
                    error={error}
                    label="Customer Common Name"
                    value={data.name}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Encompass Entity"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={entities}
                    name="encompass_entity"
                    multiple={false}
                    handleChange={handleChangeSelectEntity}
                    value={entities.find(
                      (encompass_entity) =>
                        encompass_entity.value === data.encompass_entity_id
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Industry"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={industries}
                    name="industry_id"
                    multiple={false}
                    handleChange={handleChangeSelectIndustry}
                    value={industries.find(
                      (industry) => industry.value === data.industry_id
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormSelectAuto
                    label={"Delivery Model"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={deliveryTypes}
                    name="delivery_id"
                    multiple={false}
                    handleChange={handleChangeSelectDelivery}
                    value={deliveryTypes.find(
                      (delivery) => delivery.value === data.delivery_id
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="address1"
                    label="Billing Address 1"
                    error={error}
                    value={data.address1}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="address2"
                    label="Billing Address 2"
                    value={data.address2 ?? ""}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="city"
                    label="City"
                    error={error}
                    value={data.city}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="state"
                    label="State"
                    error={error}
                    value={data.state}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="zip"
                    label="Zip"
                    error={error}
                    value={data.zip}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <FormSelectAuto
                    label={"Tax Emempt"}
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={taxEmemptOptions}
                    name="is_taxable"
                    multiple={false}
                    handleChange={handleChangeSelectTax}
                    value={taxEmemptOptions.find(
                      (tax) => tax.value === data.is_taxable
                    )}
                  />
                </Grid>
                <Grid item md={8} xs={6}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="notes"
                    label="Special Tax Considerations"
                    handleBlur={handleBlur}
                    value={data.notes}
                  />
                </Grid>
              </Grid>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="internal_support_email"
                label="Internal Support Email"
                value={data?.internal_support_email ?? ""}
                handleBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              <ButtonBase
                color="secondary"
                variant={"outlined"}
                onClick={handleClose}
              >
                Cancel
              </ButtonBase>
              <ButtonBase color="secondary" onClick={handleSave}>
                Create
              </ButtonBase>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title="Success!"
        message={messageSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default CreateCustomerDialog;
