import React from "react";
import QuoteRecurrenceTable from "./quoteRecurrenceTable";
import Box from "@material-ui/core/Box";

import { TableProvider } from "../../contexts/tableContext";
import { DrawerProvider } from "../../contexts/detailsDrawerContext";
import { ListProvider } from "../../contexts/listContext";

import useStyles from "./styles";

const QuoteRecurrence = props => {
  const classes = useStyles();

  return (
    <Box className={classes.containerTabWorkticket}>
      <ListProvider>
        <DrawerProvider>
          <TableProvider>
            <QuoteRecurrenceTable />
          </TableProvider>
        </DrawerProvider>
      </ListProvider>
    </Box>
  );
};

export default QuoteRecurrence;
