import React, { useContext } from "react";
import QualityTabSurface from "./qualityTabSurface";
import QualityTab from "./qualityTab";
import { QualityTabSurfaceProvider } from "contexts/qualityTabSurfaceContext";
import { QualityTabProvider } from "contexts/qualityTabContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";

const QualityTabContainer = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      {hasPermission(permissionSurface.MAP, permissions) ? (
        <QualityTabSurfaceProvider>
          <QualityTabSurface selectedLocation={props.selectedLocation} />
        </QualityTabSurfaceProvider>
      ) : (
        <QualityTabProvider>
          <QualityTab selectedLocation={props.selectedLocation} />
        </QualityTabProvider>
      )}
    </>
  );
};

export default withTableFilterContext(QualityTabContainer);
