import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  dotContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dot: {
    width: "16px",
    height: "8px",
    borderRadius: "4px",
    transition: "transform 300ms",
    marginRight: "4px",
    zIndex: 0,
  },
}));
export const Style = () => {
  return (
    <style>
      {`
          .light-blue {
            background-color: #78C1CE;
          }
          .green {
            background-color: #9CBB65;
          }
          .orange {
            background-color: #F39A3E;
          }
          .pink {
            background-color: #F07A8C;
          }
          
          @keyframes animateDots1 {
            0% {
              transform: translateX(0);
              z-index: 1;
            }
            25% {
              transform: translateX(20px);
              z-index: 1;
            }
            50% {
              transform: translateX(40px);
              z-index: 1;
            }
            75% {
              transform: translateX(60px);
              z-index: 1;
            }
            100% {
              transform: translateX(0);
              z-index: 1;
            }
          }
          
          @keyframes animateDots2 {
            0%,
            100% {
              transform: translateX(0px);
            }
            25% {
              transform: translateX(-20px);
            }
            50% {
              transform: translateX(-20px);
            }
            75% {
              transform: translateX(-20px);
            }
          }
          
          @keyframes animateDots3 {
            0%,
            100% {
              transform: translateX(0px);
            }
            25% {
              transform: translateX(0px);
            }
            50% {
              transform: translateX(-20px);
            }
            75% {
              transform: translateX(-20px);
            }
          }
          
          @keyframes animateDots4 {
            0%,
            100% {
              transform: translateX(0px);
            }
            25% {
              transform: translateX(0px);
            }
            50% {
              transform: translateX(0px);
            }
            75% {
              transform: translateX(-20px);
            }
          }
          
          .light-blue.active {
            animation: animateDots1 2s infinite 0s;
          }
          .green.active {
            animation: animateDots2 2s infinite 0s;
          }
          .orange.active {
            animation: animateDots3 2s infinite 0s;
          }
          .pink.active {
            animation: animateDots4 2s infinite 0s;
          }
          
          /* Start the animation at load */
          .light-blue.active,
          .green.active,
          .orange.active,
          .pink.active {
            animation-play-state: running;
          }
        `}
    </style>
  );
};
export default useStyles;
