import React from "react";
import useStyles from "./styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

const TableCollapsibleLoading = (props) => {
  const classes = useStyles();
  const { header } = useTableCollapsibleState();
  const colSpan = header[0].reduce(
    (partialSum, a) => partialSum + a.colSpan ?? 1,
    0
  );

  return (
    <TableRow className={classes.tablerow}>
      <TableCell colSpan={colSpan} className={classes.tablecell}>
        <Box>
          <LinearProgress color="secondary" />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableCollapsibleLoading;
