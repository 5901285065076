export function SolidDocument() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21875 1.125C3.4421 1.125 2.8125 1.7546 2.8125 2.53125V15.4688C2.8125 16.2454 3.4421 16.875 4.21875 16.875H13.7812C14.5579 16.875 15.1875 16.2454 15.1875 15.4688V9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75H10.9688C10.1921 6.75 9.5625 6.1204 9.5625 5.34375V3.9375C9.5625 2.3842 8.3033 1.125 6.75 1.125H4.21875ZM5.625 11.25C5.625 10.9393 5.87684 10.6875 6.1875 10.6875H11.8125C12.1232 10.6875 12.375 10.9393 12.375 11.25C12.375 11.5607 12.1232 11.8125 11.8125 11.8125H6.1875C5.87684 11.8125 5.625 11.5607 5.625 11.25ZM6.1875 12.9375C5.87684 12.9375 5.625 13.1893 5.625 13.5C5.625 13.8107 5.87684 14.0625 6.1875 14.0625H9C9.31066 14.0625 9.5625 13.8107 9.5625 13.5C9.5625 13.1893 9.31066 12.9375 9 12.9375H6.1875Z"
        fill="#D9D9D9"
      />
      <path
        d="M9.72839 1.36193C10.326 2.05236 10.6875 2.95271 10.6875 3.9375V5.34375C10.6875 5.49908 10.8134 5.625 10.9688 5.625H12.375C13.3598 5.625 14.2601 5.98653 14.9506 6.58411C14.2847 4.03491 12.2776 2.02783 9.72839 1.36193Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}
