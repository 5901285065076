import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconPin: {
    backgroundColor: "#17457A",
    color: "#fff",
    borderRadius: 8,
    padding: theme.spacing(1),
    width: 40,
    height: 40,
    marginTop: -20,
    marginLeft: -20,
  },
  mapContainer: {
    width: "100%",
    height: 320,
  },
  mapContainerInfo: {
    display: "flex",
    gap: 8,
    marginTop: 8,
  },
  mapContainerInfoItem: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  dotLocation: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#17457A",
  },
  dotLocationAccepted: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#EDF5F8",
    border: "1px solid #17457A",
  },
  dotLocationClockIn: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#DE5000",
  },
  dotLocationClockOut: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#E3B100",
  },
}));

export default useStyles;
