import http from "./httpService";
import { apiUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";
const apiEndpoint = apiUrl + "/files";

export function getFileView(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function deleteFile(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function updateFile(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function getCommentByFileId(id) {
  return http.get(`${apiEndpoint}/${id}/comments`);
}

export function updateFileComment(id, comment_id, data) {
  return http.post(`${apiEndpoint}/${id}/comments/${comment_id}`, data);
}

export function deleteFileComment(id, comment_id) {
  return http.delete(`${apiEndpoint}/${id}/comments/${comment_id}`);
}

export function addFileComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comment`, data);
}

export function zipDownload(data) {
  return http.post(`${apiEndpoint}/zip-download`, setFormData(data), {
    responseType: "blob",
  });
}
