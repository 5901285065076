import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import { convertDateFormatField } from "components/util/timeFormat";
import { updateWorkticketDetails } from "services/workticketService";

import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { permissionWorkticket, hasPermission } from "lib/permissions";

const WorkticketInformation = (props) => {
  const classes = useStyles();
  const users = [];
  const permissions = [];
  const [stateContext, dispatchContext] = useWorkticketView();
  const [assignedTo, setAssignedTo] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const { workticket, isLoading } = stateContext ?? null;
  const { users: usersAssigned } = workticket;

  useEffect(() => {
    if (!isLoading && isLoadingUsers) {
      let assigned = [];
      let assignedId = [];
      usersAssigned.map((user) => {
        const userFound = users.find((uItem) => uItem.id === user.id);
        if (userFound) {
          assigned.push(userFound);
        }
        assignedId.push(user.id);
        return true;
      });
      setAssignedTo(assigned);
      setIsLoadingUsers(false);
    }
  }, [isLoading, isLoadingUsers, usersAssigned, assignedTo, users]);

  useEffect(() => {
    if (workticket.id) {
      setIsLoadingUsers(true);
    }
  }, [workticket]);

  const handleBlur = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket[event.name] !== event.value) {
      try {
        const data = {
          [event.name]: event.value,
        };
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket");
      }
    }
  };

  const handleChangeDueDate = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket.start_date !== event.value) {
      try {
        const data = {
          start_date: moment(event).format("YYYY-MM-DD"),
        };
        workticket.start_date = moment(event).format("YYYY-MM-DD");
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket due date");
      }
    }
  };

  if ((isLoading || isLoadingUsers) && workticket !== "new") {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="workticket_number"
            label="Workticket Number"
            internal={true}
            value={workticket.number}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          {Boolean(workticket?.users.length) && (
            <InputLabel className={classes.inputLabel}>Assigned To</InputLabel>
          )}
          {workticket?.users
            .filter((user) => user.active === 1)
            .map((option, index) => (
              <Chip
                disabled={false}
                key={index}
                data-tag-index={index}
                tabIndex={-1}
                avatar={<Avatar>{option.first_name[0]}</Avatar>}
                label={`${option.first_name} ${option.last_name}`}
                className={classes.chipAuto}
              />
            ))}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            internal={true}
            value={workticket.subject}
            handleBlur={handleBlur}
            readonly={!hasPermission(permissionWorkticket.EDIT, permissions)}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            internal={true}
            value={workticket.description}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
            readonly={!hasPermission(permissionWorkticket.EDIT, permissions)}
          />

          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Location"
            internal={true}
            value={`${workticket.job.job_description}`}
            readonly={true}
          />
          {workticket.job_zone ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="job_zone"
              label="Campus Zone"
              internal={true}
              value={workticket.job_zone.name}
              readonly={true}
            />
          ) : null}
          {workticket.area ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="area"
              label="Area"
              internal={true}
              value={workticket.area}
              readonly={true}
            />
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="created_at"
            label="Created Date"
            internal={true}
            value={convertDateFormatField(workticket.created_at)}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          <FormInputDateAdo
            name="start_date"
            label="Due Date"
            internal={true}
            value={workticket.start_date}
            handleChange={handleChangeDueDate}
            disabled={!hasPermission(permissionWorkticket.EDIT, permissions)}
          />
        </Box>
      </Box>
    </>
  );
};

export default withRouter(WorkticketInformation);
