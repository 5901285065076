import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import SchoolIcon from "@material-ui/icons/School";
import {
  useLearnPageState,
  useLearnPageDispatch,
} from "contexts/learnPageContext";
import LearnCategoriesList from "./learnCategoriesList/learnCategoriesList";

import useStyles from "./styles";

const LearnPageCategoriesDialog = (props) => {
  const classes = useStyles();
  const { openTileManageCategory } = useLearnPageState();
  const dispatchLearnPage = useLearnPageDispatch();

  const handleClose = () => {
    dispatchLearnPage({
      type: "TOGGLE_MANAGE_CATEGORY",
      openTileManageCategory: false,
    });
  };

  return (
    <Dialog
      open={openTileManageCategory}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperDialog}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <Box className={classes.titleContainer}>
          <SchoolIcon className={classes.titleIcon} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Categories
          </Typography>
        </Box>
        <LearnCategoriesList />
      </DialogContent>
    </Dialog>
  );
};
export default LearnPageCategoriesDialog;
