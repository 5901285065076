import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { getWorkticketPdf } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  permissionQuote,
  permissionProject,
  hasPermission,
} from "lib/permissions";

import useStyles from "./styles";

const WorkticketActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const { workticket, isLoading } = stateContext ?? null;

  const handleOpenPdf = async () => {
    setLoadingPdf(true);
    const result = await getWorkticketPdf(workticket.id);
    const file = new Blob([result.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    setLoadingPdf(false);
  };

  const handleOpenQuote = () => {
    const { history } = props;
    history.push(`/quote/${workticket.quote_id}`);
  };

  const handleOpenProject = () => {
    const { history } = props;
    history.push(`/project/${workticket.preventative_service[0].project_id}`);
  };

  const handleOpenSurvey = () => {
    const { history } = props;
    history.push(`/surveys/completed/${workticket.survey_id}`);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  return (
    <Box className={classes.containerActionButtons}>
      <Grid container spacing={2}>
        {Boolean(workticket.quote_id) &&
        hasPermission(permissionQuote.DETAIL, permissions) ? (
          <Grid item sm={6} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenQuote}
            >
              View Quote
            </ButtonBase>
          </Grid>
        ) : null}
        {workticket.preventative_service &&
        hasPermission(permissionProject.DETAIL, permissions) ? (
          <Grid item sm={6} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenProject}
            >
              View Project
            </ButtonBase>
          </Grid>
        ) : null}
        {workticket.survey_id && workticket.taskitem === 0 ? (
          <Grid item sm={6} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenSurvey}
            >
              View Survey
            </ButtonBase>
          </Grid>
        ) : null}
        <Grid item sm={6} xs={12}>
          <ButtonBase
            color="secondary"
            variant="outlined"
            fullWidth={true}
            onClick={handleOpenPdf}
            startIcon={<PdfIcon />}
            disabled={loadingPdf}
          >
            Print PDF
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(WorkticketActions);
