import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";

const Overlay = ({ setAssetModalDataVisibility }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.overlay}
      onClick={() =>
        setAssetModalDataVisibility({
          ...{ selectedRowData: null, visibility: false },
        })
      }
    />
  );
};
export default Overlay;
