import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  colorSwitchBase: {},
  track: {
    backgroundColor: "#747474"
  },
  colorChecked: {
    color: "#fafafa",
    "&$colorChecked": {
      color: "#fafafa",
      "& + $colorBar": {
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      },
      "& + $track": {
        backgroundColor: "#747474"
      }
    }
  },
  colorBar: {},
  labelText: {
    color: "rgb(91, 92, 94)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "0.875rem",
    marginRight: "10px",
    paddingTop: "4px"
  },
  fieldHorizontal: {
    marginTop: "0px"
  },
  switch: {
    paddingLeft: "12px",
    marginTop: "6px"
  },
  inputInternalLabel: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    marginBottom: "10px"
  },
  customInputInternalLabel: {
    fontSize: 14,
    color: "#16457a",
    fontWeight: "bold",
    marginBottom: 44
  },
  container: {
    paddingTop: "2px"
  },
  input: {
    marginTop: "10px",
    paddingRight: "8px"
  }
}));

export default useStyles;
