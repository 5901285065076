import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import EvaluationsTable from "./evaluationsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { EvaluationsProvider } from "contexts/evaluationsContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import ViewDialog from "./dialog/viewDialog";
import SurveyContent from "pages/survey/surveyContent";
import PageHeader from "components/common/PageHeader/pageHeader";
import EvaluationsListDialog from "./dialog/evaluationsListDialog";
import useStyles from "./styles";

const EvaluationsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Evaluations" link="/evaluations">
        <Link to="/evaluations">Talent</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <EvaluationsProvider>
          <EvaluationsTable />
          <EvaluationsListDialog />
          <SurveyFillProvider>
            <ViewDialog />
            <SurveyContent />
          </SurveyFillProvider>
        </EvaluationsProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(EvaluationsList);
