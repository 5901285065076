import React from "react";
import useStyles, { Style } from "./styles";

export const LoadingStateHorizontal = ({ isVisible, style, propStyle }) => {
  const classes = useStyles();
  if (!isVisible) {
    return null;
  }

  return (
    <div style={propStyle}>
      <div className={`${classes.dotContainer} ${style && style} `}>
        <Style />
        <div className={classes.dot + " light-blue active"}></div>
        <div className={classes.dot + " green active"}></div>
        <div className={classes.dot + " orange active"}></div>
        <div className={classes.dot + " pink active"}></div>
      </div>
    </div>
  );
};
export default LoadingStateHorizontal;
