import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

const UpdateCommentComponent = ({ comment, onUpdateComment, onCancel }) => {
  const classes = useStyles();
  const [updatedComment, setUpdatedComment] = useState(comment.body);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setUpdatedComment(newValue);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleUpdateComment = () => {
    onUpdateComment(updatedComment);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.updateCommentContainer}>
        <Input
          disableUnderline
          multiline
          fullWidth
          value={updatedComment}
          onChange={handleChange}
          className={classes.updateCommentInput}
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button
            className={classes.cancelCommentButton}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
        <Box ml={1}>
          <Button
            className={classes.updateCommentButton}
            onClick={handleUpdateComment}
          >
            Update Comment
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateCommentComponent;
