import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { BrowserRouter } from "react-router-dom";
import { unregister } from "./serviceWorker";
import "./index.css";
import App from "./App";

let ErrorBoundary = null;

if (process.env.REACT_APP_ENV === "production") {
  Bugsnag.start({
    apiKey: "864a12728dffc21e6a994f6e861e35ff",
    plugins: [new BugsnagPluginReact()],
  });

  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
  mixpanel.init("2cfdd707ccca669c4f7c10c9017151ab");
  LogRocket.init("tuixku/encompass-one");
}

ReactDOM.render(
  <BrowserRouter>
    {process.env.REACT_APP_ENV === "production" ? (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    )}
  </BrowserRouter>,
  document.getElementById("root")
);
unregister();
