import React from "react";
import Box from "@material-ui/core/Box";
import EvaluationsTable from "./evaluationsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { EvaluationsProvider } from "contexts/evaluationsContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import ViewDialog from "./dialog/viewDialog";
import SurveyContent from "pages/survey/surveyContent";
import useStyles from "./styles";
import EvaluationsListDialog from "./dialog/evaluationsListDialog";
import TeamSurveysDialog from "./dialog/teamSurveysDialog";

const EvaluationsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <EvaluationsProvider>
          <EvaluationsListDialog />
          <SurveyFillProvider>
            <EvaluationsTable />
            <ViewDialog />
            <SurveyContent />
            <TeamSurveysDialog />
          </SurveyFillProvider>
        </EvaluationsProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(EvaluationsList);
