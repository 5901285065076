import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  containerEmptyScreen: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    height: 370,
    paddingBottom: 25,
  },
  containerEmptyScreenCustom: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: 25,
  },
  iconEmptyScreen: {
    "& svg": {
      fontSize: 48,
      color: theme.palette.primary.main,
    },
  },
  containerTitle: {
    marginBottom: 16,
  },
  titleWrapper: {
    fontSize: 30,
    color: "#C4C4C4",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  subTitleWrapper: {
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  contactTitleWrapper: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}));

const EmptyScreen = (props) => {
  const classes = useStyles();
  const {
    icon,
    type,
    actionText,
    detailText,
    detailText2,
    action,
    detailActionText,
    withOutCustomHeight,
  } = props;
  return (
    <Box
      className={
        !withOutCustomHeight
          ? classes.containerEmptyScreen
          : classes.containerEmptyScreenCustom
      }
    >
      <Box className={classes.iconEmptyScreen}>{icon}</Box>
      <Box className={classes.containerTitle}>
        {detailText ? (
          <Typography variant="h1" className={classes.titleWrapper}>
            {detailText}
          </Typography>
        ) : (
          <>
            <Typography variant="h1" className={classes.titleWrapper}>
              There is no data to show
            </Typography>
            <Typography
              variant="h1"
              className={classes.titleWrapper}
              gutterBottom
            >
              here.
            </Typography>
          </>
        )}
        {detailText && detailText2 ? (
          <Typography
            variant="h1"
            className={classes.titleWrapper}
            gutterBottom
          >
            {detailText2}
          </Typography>
        ) : null}
      </Box>
      {type === 1 ? (
        <Box>
          <Typography
            variant="h4"
            className={classes.subTitleWrapper}
            gutterBottom
          >
            Ok. It’s your move.{" "}
            <a
              href={`http://help.encompassonsite.com/`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              className={classes.contactTitleWrapper}
            >
              Contact Support
            </a>{" "}
            if
          </Typography>
          <Typography
            variant="h4"
            className={classes.subTitleWrapper}
            gutterBottom
          >
            something’s not right.
          </Typography>
        </Box>
      ) : null}
      {type === 2 ? (
        <Box>
          <Typography
            variant="h4"
            className={classes.subTitleWrapper}
            gutterBottom
          >
            Something wrong?
          </Typography>
          <Typography variant="h4" gutterBottom>
            <a
              href={`http://help.encompassonsite.com/`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              className={classes.contactTitleWrapper}
            >
              Contact Support
            </a>
          </Typography>
        </Box>
      ) : null}
      {type === 3 ? (
        <Box>
          <Typography
            variant="h4"
            className={classes.subTitleWrapper}
            gutterBottom
          >
            {detailActionText ? detailActionText : "Do something awesome like:"}
          </Typography>
          {action ? (
            <Typography
              variant="h4"
              className={classes.subTitleWrapperLink}
              gutterBottom
            >
              <Link component="button" onClick={action}>
                {actionText}
              </Link>
            </Typography>
          ) : (
            <Typography
              variant="h4"
              className={classes.subTitleWrapper}
              gutterBottom
            >
              {actionText}
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default EmptyScreen;
