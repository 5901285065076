import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import VerticalTabs from "./VerticalTabs";
import useStyles from "./styles";
import {
  useDashboardDispatch,
  setDashboardPropsData,
  useDashboardState,
} from "contexts/dashboardContext";
import RefreshIcon from "@material-ui/icons/Refresh";

const DetailsSlider = ({
  history,
  selectedLocation,
  isSurfaceUser,
  setAssetModalDataVisibility,
  setCurrentSelectedTab,
}) => {
  const dispatch = useDashboardDispatch();
  const dashboardState = useDashboardState();
  const [containerMapMoved, setContainerMapMoved] = useState(false);

  useEffect(() => {
    setDashboardPropsData(dispatch, { expanded: false });
  }, [selectedLocation]);

  useEffect(() => {
    setContainerMapMoved(true);
  }, [dashboardState?.dasboardPropsData?.boundsChanged]);

  useEffect(() => {
    setContainerMapMoved(
      dashboardState?.dasboardPropsData?.expanded ? false : containerMapMoved
    );
  }, [dashboardState?.dasboardPropsData?.expanded]);

  const classes = useStyles();

  return (
    <Box>
      <Box className="hide-on-mobile-only">
        <Box
          className={
            containerMapMoved ? classes.containerMapMoved : classes.container
          }
          style={
            dashboardState?.dasboardPropsData?.expanded
              ? { borderRadius: "4px 4px 0px 0px" }
              : { borderRadius: "4px 4px 4px 4px" }
          }
          onClick={() => {
            setDashboardPropsData(dispatch, {
              expanded: !dashboardState?.dasboardPropsData?.expanded,
            });
          }}
        >
          <Box className={classes.flexDetails} id="slider-details-top-btn">
            <Box className={classes.details}>
              {dashboardState?.dasboardPropsData?.expanded ? null : (
                <RefreshIcon
                  style={{
                    color: containerMapMoved ? "#ffffff" : "#9B9B9B",
                    marginRight: "20px",
                    position: "absolute",
                    left: "10px",
                    fontSize: "30px",
                  }}
                />
              )}
              <Typography
                className={
                  containerMapMoved ? classes.centerMapMoved : classes.center
                }
              >
                Details
              </Typography>
            </Box>
            <Box>
              {!dashboardState?.dasboardPropsData?.expanded ? (
                <ExpandLessIcon className={classes.detailsArrowBtn} />
              ) : (
                <ExpandMoreIcon className={classes.detailsArrowBtn} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {dashboardState?.dasboardPropsData?.expanded ? (
        <Box className="hide-on-mobile-only">
          <Box className="verticalTabs">
            <VerticalTabs
              history={history}
              selectedLocation={selectedLocation}
              isDashboard={true}
              isSurfaceUser={isSurfaceUser}
              setAssetModalDataVisibility={setAssetModalDataVisibility}
              setCurrentSelectedTab={setCurrentSelectedTab}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
export default DetailsSlider;
