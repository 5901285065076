import React from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const SurveyLanguageDropDown = (props) => {
  const classes = useStyles();
  const { options, lang, handleChange } = props;

  return (
    <FormControl>
      <Select
        disableUnderline={true}
        value={lang}
        onChange={(event) => handleChange(event)}
        label="Change Language"
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} className={classes.menuItem}>
            <Typography className={classes.menuItemLabel}>{option}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SurveyLanguageDropDown;
