import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-block",
    float: "left",
    [theme.breakpoints.down("sm")]: {
      float: "right"
    }
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    fontWeight: "bold"
  },
  buttonAdd: {
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20
    }
  }
}));

const JobsListActions = props => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

export default withRouter(JobsListActions);
