import React from "react";
import { merge } from "lodash";

const defaultState = {
  openWidth: 0,
  isOpen: false,
  hasActions: true,
  hasClose: true
};

const DrawerStateContext = React.createContext();
const DrawerDispatchContext = React.createContext();

function drawerReducer(state, action) {
  //console.log(action);
  switch (action.type) {
    case "TOGGLE_DRAWER":
      return {
        ...state,
        isOpen: action.isOpen
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DrawerProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(drawerReducer, mergedState);
  return (
    <DrawerStateContext.Provider value={state}>
      <DrawerDispatchContext.Provider value={dispatch}>
        {children}
      </DrawerDispatchContext.Provider>
    </DrawerStateContext.Provider>
  );
}

function useDrawerState() {
  const context = React.useContext(DrawerStateContext);
  if (context === undefined) {
    throw new Error("useDrawerState must be used within a DrawerProvider");
  }
  return context;
}

function useDrawerDispatch() {
  const context = React.useContext(DrawerDispatchContext);
  if (context === undefined) {
    throw new Error("useDrawerDispatch must be used within a DrawerProvider");
  }
  return context;
}

export { DrawerProvider, useDrawerState, useDrawerDispatch };
