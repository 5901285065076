import React, { useContext, useReducer } from "react";

const SurveyViewContext = React.createContext();

const initialState = {
  survey: {},
  isLoading: true,
  openPublishDialog: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_SURVEY":
      return {
        ...state,
        survey: action.survey,
      };
    case "TOGGLE_PUBLISH":
      return {
        ...state,
        openPublishDialog: action.openPublishDialog,
      };
    default:
      return state;
  }
};

const SurveyViewProvider = ({ children }) => (
  <SurveyViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </SurveyViewContext.Provider>
);

const useSurveyView = () => {
  const context = useContext(SurveyViewContext);
  if (context === undefined) {
    throw new Error("useSurveyView must be used within a SurveyViewProvider");
  }
  return context;
};

export { SurveyViewProvider, useSurveyView };
