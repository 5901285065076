import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { displayFieldBudget as displayField } from "components/util/stringFormat";
import { useBudgetView } from "contexts/budgetViewContext";

import useStyles from "./styles";

const BudgetPortfolioTable = (props) => {
  const classes = useStyles();
  const [stateContext] = useBudgetView();
  const { budget, isLoading } = stateContext ?? null;

  if (isLoading) {
    return null;
  }

  const budgetChildren = budget.budget_children;

  if (!budget.budget_parent_id) {
    // Parent Budget
    return (
      <Box>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rowHeader} rowSpan={2}>
                  Job Number
                </TableCell>
                <TableCell className={classes.rowHeader} rowSpan={2}>
                  Site Number
                </TableCell>
                {budgetChildren[0].budget_portfolio[0].budget_portfolio_item.map(
                  (item) => (
                    <TableCell
                      colSpan={3}
                      className={classes.rowHeader}
                      align="center"
                      key={item.id}
                    >
                      {item.name}
                    </TableCell>
                  )
                )}
                <TableCell className={classes.rowHeader} rowSpan={2}>
                  Total Revenue
                </TableCell>
                <TableCell className={classes.rowHeader} rowSpan={2}>
                  Total COGS
                </TableCell>
                <TableCell className={classes.rowHeader} rowSpan={2}>
                  Total CM
                </TableCell>
              </TableRow>
              <TableRow>
                {budgetChildren[0].budget_portfolio[0].budget_portfolio_item.map(
                  (item) => (
                    <React.Fragment key={item.id}>
                      <TableCell className={classes.rowHeader}>Qty</TableCell>
                      <TableCell className={classes.rowHeader}>Bill</TableCell>
                      <TableCell className={classes.rowHeader}>Cost</TableCell>
                    </React.Fragment>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetChildren.map((itemChild) => {
                return itemChild.budget_portfolio.map((itemPortfolio) => {
                  return (
                    <TableRow tabIndex={-1} key={itemPortfolio.id}>
                      <TableCell>{itemPortfolio.job_number}</TableCell>
                      <TableCell>{itemPortfolio.site_number}</TableCell>
                      {itemPortfolio.budget_portfolio_item.map((item) => (
                        <React.Fragment key={item.id}>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>
                            {displayField("$", item.billing)}
                          </TableCell>
                          <TableCell>{displayField("$", item.cost)}</TableCell>
                        </React.Fragment>
                      ))}
                      <TableCell>
                        {displayField("$", itemPortfolio.total_revenue)}
                      </TableCell>
                      <TableCell>
                        {displayField("$", itemPortfolio.total_cogs)}
                      </TableCell>
                      <TableCell>
                        {displayField("$", itemPortfolio.total_cm)}
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return null;
};

export default BudgetPortfolioTable;
