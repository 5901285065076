import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "../styles";
import { useAllRatingsModal } from "../context/AllRatingsDialogueContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Rating } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import { RatingTableBody } from "./RatingTableBody";
import Button from "@material-ui/core/Button";
import { useRateAction } from "../context/PartnerRateContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStylesTable = makeStyles({
  table: {
    // minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8F8F8",
    fontWeight: "700",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
export default function BasicTable(ratings) {
  const classes = useStylesTable();
  const originalClasses = useStyles();
  const partner_id = "1";
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Review</StyledTableCell>
            <StyledTableCell align="left">Reviewed Date</StyledTableCell>
            <StyledTableCell align="left">Stars</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ratings?.ratings?.map((row, index) => (
            <RatingTableBody
              key={index}
              index={index}
              partner_id={partner_id}
              originalClasses={originalClasses}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function AllRatingsModal({ ratings, averageRating }) {
  const [rateAction, dispatchRateAction] = useRateAction();
  const { openAllRatingsDialogue, handleDialogueClose } = useAllRatingsModal();
  const classes = useStyles();
  return (
    <Dialog open={true} fullScreen TransitionComponent={Transition}>
      <AppBar color="secondary" className={classes.appBarWorkticketPreview}>
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              handleDialogueClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appBarTitle}>
            Partner Ratings
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box className={classes.allRatingsHeader}>
          <Typography className={classes.allRatingsHeaderText}>
            {ratings?.length} Ratings
          </Typography>
          <Box className={classes.allRatingsAverageText}>
            <Button
              onClick={() => {
                dispatchRateAction({
                  type: "TOGGLE_RATE_PARTNER",
                  open: true,
                });
              }}
              className={classes.ratePartnerButton}
              style={{ marginTop: "0", width: "240px" }}
            >
              Rate Partner
            </Button>
            <Box className={classes.allRatingsAverageText}>
              <Typography className={classes.allRatingsHeaderText}>
                {averageRating}
              </Typography>
              <Rating
                className={classes.ratingRootStylingLg}
                name="simple-controlled"
                value={averageRating}
                disabled={true}
              />
            </Box>
          </Box>
        </Box>
        <BasicTable ratings={ratings} />
      </DialogContent>
    </Dialog>
  );
}
