import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: 3,
  },
  chipExpired: {
    backgroundColor: "#FFDEE3",
    color: "#EA5168",
  },
  chipJustReceived: {
    backgroundColor: "#EDF5F8",
    color: "#4F98BC",
  },
  chipMinsLeft: {
    backgroundColor: "#FFF5D2",
    color: "#E3B100",
  },
  chipAccepted: {
    backgroundColor: "#E8F8CB",
    color: "#328914",
  },
  pendingApproval: {
    backgroundColor: "#FFEAD5",
    color: "#DB6F00",
  },
}));

export default useStyles;
