import React, { useEffect } from "react";
import { getWorkticketPdf } from "services/workticketSurfaceService";

const WorkticketPdf = (props) => {
  const workticketId = props.match.params.id;
  useEffect(() => {
    const loadPdf = async () => {
      const result = await getWorkticketPdf(workticketId, 1, 1, 1);
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      document.querySelector("iframe").src = fileURL;
    };

    loadPdf();
  }, [workticketId]);

  return (
    <iframe
      src=""
      type="application/pdf"
      width="93%"
      height="100%"
      title="Pdf"
      style={{ border: "none" }}
    ></iframe>
  );
};

export default WorkticketPdf;
