import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;
export async function getPartnerUsers(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/users?offset=0`);
}
export async function createPartnerUser(data) {
  return await http.post(`${apiEndpoint}/${data.partner_id}/users`, data.data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export async function getPartnerUser(partner_id, user_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/users/${user_id}`);
}
export async function deletePartnerUser(data) {
  return await http.delete(
    `${apiEndpoint}/${data.partner_id}/users/${data.user_id}`
  );
}
export async function getPartnerRoles() {
  return await http.get(`${apiEndpoint}/roles`);
}
export async function updatePartnerUser(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/users/${data.user_id}/update`,
    data.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
export async function addUserLocations(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/users/add-jobs`,
    data.data
  );
}
export async function removeUserLocations(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/users/remove-jobs`,
    data.data
  );
}
export async function addUserCustomers(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/users/add-customers`,
    data.data
  );
}
export async function removeUserCustomers(data) {
  return await http.post(
    `${apiEndpoint}/${data.partner_id}/users/remove-customers`,
    data.data
  );
}
