import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import RingBellSurfaceTableActions from "./ringBellSurfaceTableActions";
import RingBellFilters from "./ringBellFilters";
import RingBellSurfaceBulkActions from "./ringBellSurfaceBulkActions";
import FieldName from "./fieldName";
import FieldLocation from "./fieldLocation";
import FieldAbout from "./fieldAbout";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { ringBellPositionOptions } from "constants.js";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useRingBellSurfaceState,
  useRingBellSurfaceDispatch,
  getRingBellSurfaceList,
  searchRingBellSurfaceList,
  getRingBellSurfaceListDataSet,
} from "contexts/ringBellSurfaceContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "user",
    label: "Name",
    content: (row) => <FieldName field={row} />,
    style: { width: 250 },
  },
  {
    id: "position",
    label: "Position",
    content: (row) =>
      ringBellPositionOptions.find(
        (positionItem) => positionItem.value === row.tier_classification
      ).label,
    style: { width: 150 },
  },
  {
    id: "job_description",
    label: "Location",
    style: { width: 250 },
  },
  {
    id: "original_hire_date",
    label: "Date of Hire",
    format: "date",
    style: { width: 50 },
  },
  {
    id: "about",
    label: "About",
    content: (row) => (
      <FieldAbout
        field={row.note?.length > 0 ? row.note : "-"}
        fieldLength={50}
      />
    ),
    disableSort: true,
  },
  {
    id: "actions",
    label: "Recognize",
    content: (row) => <RingBellSurfaceTableActions row={row} />,
    contentMobile: (row) => <RingBellSurfaceTableActions row={row} />,
    style: { width: 58 },
    disableSort: true,
  },
];

const RingBellSurfaceTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { ringBellListLoading, ringBellList, ringBellCount } =
    useRingBellSurfaceState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchRingBell = useRingBellSurfaceDispatch();
  const dispatchFilters = useFilterDispatch();

  const { refreshData } = globalUi;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getRingBellSurfaceList(dispatchRingBell, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchRingBell, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getRingBellSurfaceList(dispatchRingBell, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, ringBellList]);

  const handleScrollClick = useCallback(async () => {
    if (ringBellCount <= ringBellList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getRingBellSurfaceListDataSet(
        ringBellList,
        dispatchRingBell,
        ringBellList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    ringBellList,
    ringBellCount,
    filterState,
    dispatchRingBell,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchRingBellSurfaceList(query, filterData);

          dispatchRingBell({
            type: "SET_RINGBELL_LIST",
            ringBellList: results,
          });

          dispatchRingBell({
            type: "SET_RINGBELL_COUNT",
            ringBellCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {ringBellListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={() => {}}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Team</Box>}
                key={-1}
                value={-1}
              />
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${ringBellList.length} / ${ringBellCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <RingBellSurfaceBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          placeholderSearch={"All Team Members"}
                          handleSearchClear={handleSearchClear}
                        >
                          <RingBellFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(RingBellSurfaceTable);
