import React from "react";
import IndividualViewLayout from "./individualViewLayout";
import { IndividualViewProvider } from "contexts/individualViewContext";

const IndividualViewPage = (props) => {
  return (
    <IndividualViewProvider>
      <IndividualViewLayout id={props.match.params.id} />
    </IndividualViewProvider>
  );
};

export default IndividualViewPage;
