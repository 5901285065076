import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ThreeDots from "../icons/ThreeDots";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { Location } from "../icons/Location";
import { DeleteBin } from "../icons/DeleteBin";
import { usePartnerUsers } from "./useUsers";
import DeleteUserDialogue from "./DeleteUserDialogue";
import useStyles from "../styles";

export function UserRowItem({
  StyledTableCell,
  row,
  handleClick,
  isItemSelected,
  labelId,
  handleLocationDialogueOpen,
  handleToggleDrawer,
  selectedUsers,
  permissionManage,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const {
    handleDeleteUser,
    isSubmitting,
    isDeleteUserDialogOpen,
    handleDeleteUserDialogueClose,
    handleDeleteUserDialogueOpen,
    selectUser,
  } = usePartnerUsers();

  const menuItems = [
    {
      label: "View Details",
      icon: <RemoveRedEyeOutlinedIcon fontSize="inherit" />,
      onClick: () => {
        handleToggleDrawer("right", true, true, row, true);
        handleMenuClose();
      },
      disabled: isSubmitting,
    },
    {
      label: "Edit",
      icon: <EditIcon fontSize="inherit" />,
      onClick: () => {
        handleToggleDrawer("right", true, true, row);
        handleMenuClose();
      },
    },
    {
      label: "Assign Location",
      icon: <Location fontSize="inherit" />,
      onClick: () => {
        handleLocationDialogueOpen();
        selectUser([row]);
        handleMenuClose();
      },
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        handleDeleteUserDialogueOpen();
        handleMenuClose();
      },
      disabled: isSubmitting,
    },
  ];
  const multipleUsersMenuItems = [
    {
      label: "Assign Location",
      icon: <Location fontSize="inherit" />,
      onClick: () => {
        handleLocationDialogueOpen();
        selectUser([row]);
        handleMenuClose();
      },
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        handleDeleteUserDialogueOpen();
        handleMenuClose();
      },
      disabled: isSubmitting,
    },
  ];

  return (
    <>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row.id, row)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        {permissionManage ? (
          <StyledTableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </StyledTableCell>
        ) : null}

        <StyledTableCell align="left">{row.first_name}</StyledTableCell>
        <StyledTableCell padding={"none"} align="left">
          {row.last_name}
        </StyledTableCell>
        <StyledTableCell align="left">{row.email}</StyledTableCell>
        <StyledTableCell align="left">{row.role_category}</StyledTableCell>
        <TableCell align="left">{row.role_name}</TableCell>

        <TableCell align="center">
          {permissionManage ? (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleMenuClick(event);
              }}
              onFocus={(event) => event.stopPropagation()}
              aria-label="options"
            >
              <ThreeDots />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {selectedUsers.length > 1 &&
          multipleUsersMenuItems.map(({ label, icon, onClick, disabled }) => (
            <MenuItem key={label} onClick={onClick} disabled={disabled}>
              <Box className={classes.menuIcon}>{icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {label}
              </Typography>
            </MenuItem>
          ))}
        {(selectedUsers.length === 1 || selectedUsers.length === 0) &&
          menuItems.map(({ label, icon, onClick, disabled }) => (
            <MenuItem key={label} onClick={onClick} disabled={disabled}>
              <Box className={classes.menuIcon}>{icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {label}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
      <DeleteUserDialogue
        userToDelete={row.id}
        handleDeleteUser={handleDeleteUser}
        handleDeleteUserDialogueClose={handleDeleteUserDialogueClose}
        isDeleteUserDialogOpen={isDeleteUserDialogOpen}
      />
    </>
  );
}
