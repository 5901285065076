import React from "react";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { WorkticketProvider } from "contexts/workticketContext";
import { WorkticketActionProvider } from "contexts/workticketActionContext";
import WorkticketsTablePreventive from "./projectTabWorkticketTablePreventive";
import useStyles from "./styles";

const ProjectTabWorkticketPreventive = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerTabWorkticketProject}>
      <WorkticketProvider>
        <WorkticketActionProvider>
          <WorkticketsTablePreventive />
        </WorkticketActionProvider>
      </WorkticketProvider>
    </Box>
  );
};

export default withTableFilterContext(ProjectTabWorkticketPreventive);
