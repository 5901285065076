import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  // root: {
  //   flexGrow: 1,
  //   height: "100%",
  //   display: "flex",
  //   maxWidth: "100%",
  // },
  rootContainer: {
    height: "100%",
  },
  tableContainer: {
    height: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    height: 336,
    maxWidth: "100%",
    overflowX: "hidden",
    // "& table": {
    //   minWidth: 1200,
    // },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  // pageBodyWrapper: {
  //   display: "flex",
  //   maxHeight: "100%",
  //   height: "100%",
  //   marginLeft: 24,
  //   alignItems: "stretch",
  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: 70,
  //   },
  // },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "100%",
    bottom: 0,
  },
  tabItem: {
    display: "block",
  },
  iconQuestionDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  popover: {
    pointerEvents: "none",
  },
  wrapperName: {
    paddingLeft: theme.spacing(4),
    position: "relative",
    minWidth: 140,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
  },
  avatarManager: {
    width: 120,
    height: 120,
    fontSize: 60,
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 22,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
    borderColor: "#4f98bc",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  sliderHeightTable: {
    height: 336,
    // minWidth: 1109,
  },
  tabSubTitle: {
    marginTop: 16,
    fontWeight: "bolder",
    fontSize: 18,
    color: "#a7a7a7",
    borderBottom: "#f3f3f3 solid",
  },
  tabContainer: { position: "relative" },
  actionContainer: {
    position: "fixed",
    left: 1228,
    marginTop: -28,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      marginTop: -28,
    },
  },
  searchContainer: {
    position: "fixed",
    left: 940,
    marginTop: -30,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      marginTop: -30,
    },
  },
  tabTitle: {
    marginLeft: 24,
    fontWeight: "bolder",
    fontSize: 21,
  },
  managerContainer: {
    paddingRight: 8,
  },
  managerDetail: {
    paddingTop: 45,
    paddingLeft: theme.spacing(3),
  },
  managerDetailPic: {
    paddingTop: 45,
  },
  managerName: {
    fontWeight: "bolder",
    fontSize: 16,
    color: "#747474",
    wordWrap: "break-word",
  },
  managerDetailInfo: {
    fontSize: 16,
    color: "#747474",
    wordWrap: "break-word",
  },
  managerDetailInfoSpaced: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    color: "#747474",
    wordWrap: "break-word",
  },
  headerTab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginLeft: 0,
      "& > div": {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
    },
    "& h4": {
      textAlign: "left",
    },
  },
  filterContainer: {
    display: "inline-block",
    margin: "0 30px 0 10px",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  talentWrapper: {
    display: "flex",
    flex: 1,
  },
  talentContent: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      overflowY: "scroll",
      height: "70vh",
    },
  },
  tableBody: {
    width: "100%",
    overflow: "hidden",
    flex: 1,
    height: 370,
    "& > div": {
      margin: 0,
      height: "100%",
    },
    "& > div > div > div > div > div": {
      padding: 0,
    },
    "& > div > div > div > div > div > div:first-child": {
      right: -75,
    },
    "& > div > div > div > div > div > div > div > div": {
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 160px)",
    },
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      marginRight: 70,
    },
  },
  talentRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 30,
    },
  },
  contentCounter: {
    right: 164,
    position: "absolute",
    zIndex: 2,
    marginTop: 8,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      // left: 20,
      // top: 210
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  labelEmpty: {
    textAlign: "center",
  },
  talentTab: {
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "14px",
    cursor: "pointer",
  },
  talentTabContainer: {
    flex: "5",
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
    maxHeight: "35px",
    minHeight: "30px",
    marginTop: "10px",
    maxWidth: "400px",
  },
  buttonCap: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  modalLabel: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
  },
  modalValue: {
    fontSize: 16,
    marginBottom: 16,
  },
  modalContainer: {
    padding: 10,
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  centerLoadingTab: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
