import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AssetsTable from "./assetsTable";
import Box from "@material-ui/core/Box";
import { AssetProvider } from "contexts/assetContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const AssetsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Assets" link="/assets"></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <AssetProvider>
          <AssetsTable />
        </AssetProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AssetsList);
