import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/requests`;

export function getRequests(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`);
}

export function getRequestsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getRequestsTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function getRequestDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {
    params: {},
  });
}

export function updateRequest(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function updateBulkRequest(data) {
  return http.post(`${apiEndpoint}/update`, data);
}

export function uploadFileRequest(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}
