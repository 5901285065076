import { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "../styles";
import { Timeline } from "../icons/Timeline";
import { CommentsActive } from "../icons/CommentsActive";
import { Comments } from "./Comments";
import { Activities } from "../Activities/Activities";

const activeBlue = "#4F98BC";
const white = "#FFFFFF";

export function CommentsActivities() {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState("comments");
  const [countObject, setCountObject] = useState({
    activity: 0,
    comments: 0,
  });

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <Fragment>
      <Box className={classes.commentsActivitiesMainContainer}>
        <Box className={classes.commentActivityButtonContainer}>
          <Button
            startIcon={
              <CommentsActive
                fill={activeButton === "comments" ? white : activeBlue}
              />
            }
            value="comments"
            onClick={() => handleButtonClick("comments")}
            className={
              activeButton === "comments"
                ? classes.commentsButtonActive
                : classes.commentsButtonInactive
            }
          >
            {/*Comments ({countObject.comments ? countObject.comments : 0})*/}

            {!countObject.comments && <span>Comments</span>}
            {countObject?.comments > 0 && (
              <span>Comments ({countObject.comments})</span>
            )}
          </Button>
          <Button
            value="activity"
            onClick={() => handleButtonClick("activity")}
            startIcon={
              <Timeline
                fill={activeButton === "activity" ? white : activeBlue}
              />
            }
            className={
              activeButton === "activity"
                ? classes.activityButtonActive
                : classes.activityButtonInactive
            }
          >
            {!countObject.activity && <span>Activity</span>}
            {countObject?.activity > 0 && (
              <span>Activity ({countObject.activity})</span>
            )}
          </Button>
        </Box>
        {activeButton === "activity" && (
          <Activities
            setActivityCount={(count) =>
              setCountObject({ ...countObject, activity: count })
            }
          />
        )}
        {activeButton === "comments" && (
          <Comments
            setCommentCount={(count) =>
              setCountObject({ ...countObject, comments: count })
            }
          />
        )}
      </Box>
    </Fragment>
  );
}
