import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  wrapperDialogDelete: {
    position: "absolute",
    right: 50,
    top: 5,
  },
  filterCheckbox: {
    color: "#ececec",
    border: "none",
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  gridContainer: {
    "& .MuiInputBase-root": {
      marginBottom: 0,
    },
  },
  titleOption: {
    marginTop: 28,
    fontSize: 16,
  },
  skipContainer: {
    marginTop: 20,
  },
  daysOptionsContainer: {
    marginTop: 10,
    marginBottom: 10,
    "& .MuiIconButton-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  wrapperDialogAction: {
    marginBottom: 10,
  },
  button: {
    textTransform: "capitalize",
  },
  buttonUnlink: {
    textTransform: "capitalize",
    backgroundColor: "#f07a8c",
    "&:hover": {
      backgroundColor: "#d46b7b",
    },
  },
  subTitleDialog: {
    position: "relative",
    top: "-12px",
  },
  iconQuestionDialog: {
    color: theme.palette.success.main,
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  wrapperDialogConfirmation: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  listRoot: {
    marginBottom: 20,
    "& .MuiListItem-root": {
      padding: 0,
    },
  },
}));

export default useStyles;
