import React from "react";
import Box from "@material-ui/core/Box";
import NoticesTable from "./noticesTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { NoticesProvider } from "contexts/noticesContext";
import useStyles from "./styles";
import NoticesListDialog from "./dialog/noticesListDialog";

const NoticesList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <NoticesProvider>
          <NoticesTable />
          <NoticesListDialog />
        </NoticesProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(NoticesList);
