import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import SurveysTasksListTable from "./surveysTasksListTable";
import SurveysTasksListDialog from "./dialog/surveysTasksListDialog";
import { SurveysTasksProvider } from "contexts/surveysTasksTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const SurveysTasksList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Surveys" link="/surveys/admin/tasks" />
      <Box className={classes.pageBodyWrapper}>
        <SurveysTasksProvider>
          <SurveysTasksListTable />
          <SurveysTasksListDialog />
        </SurveysTasksProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(SurveysTasksList);
