import React, { useState } from "react";
import { linkIcon, shapeIcon } from "./icons";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MessageDialog from "./dialog/messageDialog";
import { logException } from "components/util/logUtil";
import { validateVideoLink } from "components/util/youtubeUtil";
import { saveWorkticketVideoLink } from "services/workticketService";
import { workticketFiles } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import useStyles from "./styles";

const VideoLinkDialog = ({ workTicketId, workTicketNumber }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [videoType, setVideoType] = useState("");
  const [description, setDescription] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [linkInvalidError, setLinkInvalidError] = useState(false);
  const [videoTypeError, setVideoTypeError] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [stateContext, dispatchContext] = useWorkticketView();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setDescription("");
    setLink("");
    setLinkError(false);
    setTitleError(false);
    setLinkInvalidError(false);
    setVideoType("");
    setVideoTypeError(false);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleAddVideo = async () => {
    if (!link || !title || !videoType) {
      setLinkError(!link);
      setTitleError(!title);
      setVideoTypeError(!videoType);
      return;
    }
    if (!validateVideoLink(link)) {
      setLinkInvalidError(true);
      return;
    }

    setIsLoadingData(true);
    setOpenMessage(true);

    handleClose();
    const isVideo_360 = videoType === "360" ? 1 : 0;
    const data = {
      type: "link",
      videoType: videoType,
      url: link,
      file_name: title,
      file_description: description,
      vid_360: isVideo_360,
    };
    try {
      await saveWorkticketVideoLink(workTicketId, data);
      const response = await workticketFiles(workTicketId);
      dispatchContext({
        type: "SET_WORKTICKET_FILES",
        workticketFiles: response.data.data,
      });
      setOpenMessageContent("Video link has been added successfully");
      setIsLoadingData(false);
    } catch (e) {
      logException(e, "Cannot save files");
    }
  };

  return (
    <Box>
      <Button
        className={classes.buttonCancel}
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={linkIcon()}
      >
        <Typography variant="body1" style={{ color: "#4F98BC" }}>
          Add Video Link
        </Typography>
      </Button>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.wrapperDialogClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>

        <DialogTitle className={classes.wrapperDialog}>
          <Box
            display="flex"
            verticalAlignItems="center"
            justifyContent="center"
            mt={2}
          >
            {shapeIcon()}
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
              style={{ paddingLeft: 10 }}
            >
              {workTicketNumber}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
            style={{ paddingTop: 10 }}
          >
            Share media from YouTube, Vimeo, or any other platform where you
            upload your videos.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel
              htmlFor="form-link-input"
              className={classes.videoLinkLabelText}
              style={{ paddingTop: 0 }}
            >
              Paste Link
            </FormLabel>
            <TextField
              className={classes.videoLinkFormText}
              id="form-link-input"
              variant="outlined"
              size="small"
              value={link}
              error={linkError || linkInvalidError}
              helperText={
                linkError
                  ? "Link is required"
                  : linkInvalidError
                  ? "Link not valid"
                  : ""
              }
              onChange={(e) => {
                setLink(e.target.value);
                setLinkError(false);
                setLinkInvalidError(false);
              }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <FormLabel
              htmlFor="video-type-select"
              className={classes.videoLinkLabelText}
              style={{ color: "#747474" }}
            >
              Video Type
            </FormLabel>
            <Select
              className={classes.videoLinkFormText}
              id="video-type-select"
              size="small"
              variant="outlined"
              value={videoType}
              error={videoTypeError}
              inputProps={{ style: { fontSize: 12 } }}
              displayEmpty={true}
              renderValue={(value) =>
                value?.length
                  ? Array.isArray(value)
                    ? value.join(", ")
                    : value
                  : "Select Video Type"
              }
              onChange={(e) => {
                setVideoType(e.target.value);
                setVideoTypeError(false);
              }}
            >
              <MenuItem value="360">360 Video</MenuItem>
              <MenuItem value="standard">Standard Video</MenuItem>
            </Select>

            {videoTypeError && (
              <FormHelperText error={videoTypeError}>
                Video type is required
              </FormHelperText>
            )}
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <FormLabel
              htmlFor="form-title-input"
              className={classes.videoLinkLabelText}
            >
              Title
            </FormLabel>
            <TextField
              className={classes.videoLinkFormText}
              id="form-title-input"
              size="small"
              variant="outlined"
              value={title}
              error={titleError}
              helperText={titleError ? "Title is required" : ""}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <FormLabel
              htmlFor="form-description-input"
              className={classes.videoLinkLabelText}
            >
              Description
            </FormLabel>
            <TextField
              className={classes.videoLinkFormText}
              id="form-description-input"
              size="small"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              minRows={4}
              inputProps={{ style: { fontSize: 14 } }}
            />
          </FormControl>

          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ paddingTop: 8 }}
          >
            <Button
              onClick={handleClose}
              className={classes.buttonCancel}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button onClick={handleAddVideo} className={classes.buttonConfirm}>
              Add Video Link
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default VideoLinkDialog;
