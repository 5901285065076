import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import RateReviewIcon from "@material-ui/icons/RateReview";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import StarRating from "../starRating";
import { usePartnerState } from "contexts/partnerContext";
import { usePartnerAction } from "contexts/partnerActionContext";
import { useTableDispatch } from "contexts/tableContext";
import { logException } from "components/util/logUtil";
import { ratePartner } from "services/partnerService";
import useStyles from "./styles";

export const RatingDialog = (props) => {
  const classes = useStyles();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [partnerAction, dispatchPartnerAction] = usePartnerAction();
  const { partnerSelected } = usePartnerState();
  const dispatchTable = useTableDispatch();
  const { openRatePartner } = partnerAction;

  useEffect(() => {
    setRating(0);
    setComment("");
  }, [partnerSelected]);

  const closeRatePartnerDialog = () => {
    dispatchPartnerAction({
      type: "TOGGLE_RATE_PARTNER",
      open: false,
    });
    setRating(0);
    setComment("");
  };

  const confirmRating = async () => {
    setIsLoadingDialogOpen(true);
    try {
      const ratingData = {
        rate: rating,
        comment: comment,
      };

      await ratePartner(partnerSelected.id, ratingData);
      props.refetchData();
      dispatchTable({
        type: "SET_UPDATED_ROWS",
        rowsUpdated: [partnerSelected.id],
      });

      setIsLoadingDialogOpen(false);
      closeRatePartnerDialog();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to rate partner");
      setIsLoadingDialogOpen(false);
    }
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={isLoadingDialogOpen}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRatePartner && !isLoadingDialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={closeRatePartnerDialog}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <DialogTitle className={classes.wrapperDialog}>
          <Box className={classes.titleWithIcon}>
            <RateReviewIcon className={classes.rateIcon} />
            <Typography
              className={classes.titleDialog}
              variant="caption"
              gutterBottom
            >
              Rate this Partner
            </Typography>
          </Box>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Please rate this partner by selecting stars and provide additional
            feedback in the text box to help us know more about them.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <StarRating
            stars={rating}
            maxStars={5}
            readOnly={false}
            showLabels={true}
            iconSize={40}
            spacing={7}
            onRate={(newRating) => setRating(newRating)}
          />
          <Box className={classes.selectContainer}>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="feedback" className={classes.feedbackLabel}>
                Additional Feedback
              </FormLabel>
              <FormLabel htmlFor="comments" className={classes.commentLabel}>
                Comments
              </FormLabel>
              <TextField
                id="feedback"
                size="small"
                variant="standard"
                multiline
                minRows={5}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{ disableUnderline: true }}
                className={classes.textArea}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            onClick={closeRatePartnerDialog}
            className={classes.buttonCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={confirmRating}
            className={classes.buttonConfirm}
          >
            Rate Partner
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        handleClose={closeSuccessDialog}
        title="Success"
        message="Rating has been successfully submitted."
      />
    </>
  );
};

export default RatingDialog;
