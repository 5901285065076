import React from "react";
import { UserIdProvider } from "./context/UserIdContext";
import { TabIdProvider } from "./context/TabsContext";
import { PartnerActionProvider } from "../../contexts/partnerActionContext";
import { PartnerProvider } from "../../contexts/partnerContext";
import { TableProvider } from "../../contexts/tableContext";
import PartnerLayout from "./partnerLayout";

const PartnerPage = (props) => {
  const id = props.match.params.id;
  const mode = props.match.params.mode ?? "view";

  return (
    <>
      <TabIdProvider>
        <TableProvider>
          <PartnerProvider>
            <PartnerActionProvider>
              <UserIdProvider>
                <PartnerLayout id={id} mode={mode} />
              </UserIdProvider>
            </PartnerActionProvider>
          </PartnerProvider>
        </TableProvider>
      </TabIdProvider>
    </>
  );
};

export default PartnerPage;
