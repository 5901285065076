import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import useStyles from "components/common/Filters/styles";
import { useLearnPageState } from "contexts/learnPageContext";
import { getLearnData } from "services/learnService";
import { logException } from "components/util/logUtil";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const LearnPageFilters = (props) => {
  const classes = useStyles();
  const [categories, setCategories] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const { reloadCategories } = useLearnPageState();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await getLearnData();
        const resultForRender = result.data.data.categories.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCategories(resultForRender);
      } catch (e) {
        logException(e, "Cannot load categories data");
      }
    };
    if (!categories || reloadCategories) {
      loadCategories();
    }
  }, [categories, reloadCategories]);

  useEffect(() => {
    if (anchorEl) {
      const selectedCategoryFilter = filters.find(
        (filter) => filter.filter === "category"
      );
      setCategoryFilters(selectedCategoryFilter?.values ?? []);
    }
  }, [anchorEl, filters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    if (categoryFilters.length) {
      filterData.push({
        filter: "category",
        group: "category",
        values: [...categoryFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    handleClose();
  };

  const handleChangeCategory = (event, value) => {
    setCategoryFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={categories ?? []}
                name="category"
                handleChange={handleChangeCategory}
                multiple={true}
                value={categoryFilters}
              />
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default LearnPageFilters;
