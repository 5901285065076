import React, { useContext, useState, useEffect } from "react";
import * as classNames from "classnames";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import { saveTimekeepingManual } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import { formatAppDateTZ } from "components/util/timeFormat";
import WorkticketTimekeepingManualActions from "./workticketTimekeepingManualActions";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import AddIcon from "@material-ui/icons/AddCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TravelIcon from "@material-ui/icons/DirectionsCar";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const initialRow = {
  date: moment(),
  timeIn: moment(),
  timeOut: moment(),
  payRate: 0,
  user: 0,
  hourlyRate: 0,
  totalHours: 0,
  totalDollars: 0,
  error: [],
  hasHourRate: true,
  drive: false,
  driveEnabled: false,
  overtime: false,
};

const subcontratorTiers = [
  "subcontractor_crew",
  "subcontractor",
  "subcontractor_admin",
];
const HOURLY_RATE = 3;

const customersTKOvertime = ["31300"];

const WorkticketTimekeepingManualMultiple = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [rows, setRows] = useState([{ ...initialRow }]);
  const [totalDollars, setTotalDollars] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [users, setUsers] = useState([]);
  const { handleClose, open } = props;

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (workticket?.users) {
      setUsers(workticket.users);
    } else {
      setUsers([]);
    }
  }, [workticket?.users]);

  useEffect(() => {
    if (!open) {
      setRows([{ ...initialRow }]);
      setTotalDollars(0);
      setTotalHours(0);
    }
  }, [open]);

  const handleChangeAssignedTo = (n, value) => {
    if (value) {
      rows[n].user = value.id;
      if (subcontratorTiers.includes(value.tier_classification)) {
        if (workticket?.quote_item_user) {
          if (workticket.quote_item_user.length > 0) {
            const sum = workticket.quote_item_user.reduce(
              (partialSum, a) => partialSum + parseFloat(a.unit_cost ?? 0),
              0
            );
            const avg = sum / workticket.quote_item_user.length;
            rows[n].hourlyRate = avg;
            rows[n].hasHourRate = true;
          } else {
            rows[n].hourlyRate = 0;
            rows[n].hasHourRate = true;
          }
        } else if (workticket?.preventative_service) {
          if (
            workticket.preventative_service[0]?.payment_type === HOURLY_RATE
          ) {
            rows[n].hourlyRate = parseFloat(
              workticket.preventative_service[0].unit_cost
            );
            rows[n].hasHourRate = true;
          } else {
            rows[n].hourlyRate = 0;
            rows[n].hasHourRate = false;
          }
        } else {
          rows[n].hourlyRate = 0;
          rows[n].hasHourRate = true;
        }
      } else {
        rows[n].hourlyRate = parseFloat(value.hour_rate ?? 0);
        rows[n].hasHourRate = true;
      }
      if (value.role?.is_dispatch) {
        rows[n].driveEnabled = true;
      } else {
        rows[n].driveEnabled = false;
        rows[n].drive = false;
      }
    } else {
      rows[n].user = 0;
      rows[n].hourlyRate = 0;
      rows[n].hasHourRate = true;
      rows[n].driveEnabled = false;
      rows[n].drive = false;
    }
    rows[n].totalDollars = rows[n].hourlyRate * rows[n].totalHours;
    rows[n].error = [];
    const sumHours = rows.reduce(
      (partialSum, a) => partialSum + a.totalHours,
      0
    );
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setTotalHours(sumHours);
    setRows([...rows]);
  };

  const handleSaveTimekeeping = async () => {
    let isGood = true;
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].user) {
        rows[i].error = [
          {
            key: "users",
            message: "Please select at least one user to assign.",
          },
        ];
        isGood = false;
      }
    }
    if (!isGood) {
      setRows([...rows]);
      return;
    }
    try {
      let data = {};
      setIsLoadingProcess(true);
      const tz = workticket?.job?.timezone;
      for (let i = 0; i < rows.length; i++) {
        const inDate = `${formatAppDateTZ(
          `${rows[i].date.format("YYYY-MM-DD")} ${rows[i].timeIn.format(
            "HH:mm:ss"
          )}`,
          "YYYY-MM-DD HH:mm:00",
          tz
        )}`;
        let outDate = "";
        const diffHours = rows[i].timeOut.diff(rows[i].timeIn, "hours", true);
        if (diffHours < 0) {
          outDate = `${formatAppDateTZ(
            `${moment(rows[i].date).add("day", 1).format("YYYY-MM-DD")} ${rows[
              i
            ].timeOut.format("HH:mm:ss")}`,
            "YYYY-MM-DD HH:mm:00",
            tz
          )}`;
        } else {
          outDate = `${formatAppDateTZ(
            `${moment(rows[i].date).format("YYYY-MM-DD")} ${rows[
              i
            ].timeOut.format("HH:mm:ss")}`,
            "YYYY-MM-DD HH:mm:00",
            tz
          )}`;
        }
        if (!rows[i].drive) {
          data = {
            user_id: rows[i].user,
            clock_in: inDate,
            clock_out: outDate,
            overtime: rows[i].overtime,
          };
        } else {
          data = {
            user_id: rows[i].user,
            drive_in: inDate,
            drive_out: outDate,
            overtime: rows[i].overtime,
          };
        }

        await saveTimekeepingManual(workticket.id, data);
      }
      setIsLoadingProcess(false);
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot manual timekeeping");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleSetDate = (value, n) => {
    rows[n].date = value;
    setRows([...rows]);
  };

  const handleOvertimeChange = (event, n) => {
    rows[n].overtime = event.target.checked;
    setRows([...rows]);
  };

  const handleDriveChange = (event, n) => {
    rows[n].drive = event.target.checked;
    setRows([...rows]);
  };

  const handleSetTimeIn = (value, n) => {
    rows[n].timeIn = value;
    rows[n].totalHours = rows[n].timeOut.diff(value, "hours", true);
    rows[n].totalDollars = rows[n].hourlyRate * rows[n].totalHours;
    const sumHours = rows.reduce(
      (partialSum, a) => partialSum + a.totalHours,
      0
    );
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setTotalHours(sumHours);
    setRows([...rows]);
  };

  const handleSetTimeOut = (value, n) => {
    rows[n].timeOut = value;
    const diffHours = value.diff(rows[n].timeIn, "hours", true);
    if (diffHours < 0) {
      rows[n].totalHours = moment(value)
        .add("day", 1)
        .diff(rows[n].timeIn, "hours", true);
    } else {
      rows[n].totalHours = diffHours;
    }
    rows[n].totalDollars = rows[n].hourlyRate * rows[n].totalHours;
    const sumHours = rows.reduce(
      (partialSum, a) => partialSum + a.totalHours,
      0
    );
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setTotalHours(sumHours);
    setRows([...rows]);
  };

  const handleHourlyRateChange = (event, n) => {
    let val = 0;
    if (event.value && parseFloat(event.value) >= 0) {
      val = parseFloat(event.value);
    }
    rows[n].hourlyRate = val;
    rows[n].totalDollars = rows[n].hourlyRate * rows[n].totalHours;
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const handleAddClick = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const handleDeleteRow = (n) => {
    rows.splice(n, 1);
    const sumHours = rows.reduce(
      (partialSum, a) => partialSum + a.totalHours,
      0
    );
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setTotalHours(sumHours);
    setRows([...rows]);
  };

  const handleCopyToAll = (n) => {
    const newRows = rows.map((r) => {
      return { ...rows[n] };
    });
    const sumHours = newRows.reduce(
      (partialSum, a) => partialSum + a.totalHours,
      0
    );
    const sumDollars = newRows.reduce(
      (partialSum, a) => partialSum + a.totalDollars,
      0
    );
    setTotalDollars(sumDollars);
    setTotalHours(sumHours);
    setRows([...newRows]);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {isLoadingProcess ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                className={classes.wrapperClose}
              >
                <CloseIcon className={classes.iconClose} />
              </IconButton>
              {!isLoading && (
                <>
                  <Grid container spacing={2} className={classes.dialogHeader}>
                    <Grid item sm={12}>
                      <Box className={classes.formIconDialog}>
                        <TicketIcon className={classes.iconDialog} />
                      </Box>
                      <Typography
                        variant="h4"
                        className={classes.formTitleDialog}
                        gutterBottom
                      >
                        Workticket {workticket.number}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.formSubtitleDialog}
                        gutterBottom
                      >
                        Enter timekeeping manually
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box className={classes.formContainerDialogMultiple}>
                    <Grid container spacing={2}>
                      {rows.map((row, n) => (
                        <React.Fragment key={n}>
                          <Grid item md={2} sm={6}>
                            <FormSelectChipsAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={users.filter(
                                (u) =>
                                  u.tier_classification !==
                                  "subcontractor_admin"
                              )}
                              name="users"
                              label="Team Member"
                              handleChange={(event, value) =>
                                handleChangeAssignedTo(n, value)
                              }
                              value={users.find((usr) => usr.id === row.user)}
                              error={row.error}
                              noMargin
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormInputDateAdo
                              name="date"
                              label="Date"
                              value={row.date}
                              handleChange={(value) => handleSetDate(value, n)}
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormInputTimeAdo
                              name="time_in"
                              label="In Time"
                              value={row.timeIn}
                              handleChange={(value) =>
                                handleSetTimeIn(value, n)
                              }
                              noMargin
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <FormInputTimeAdo
                              name="time_out"
                              label="Out Time"
                              value={row.timeOut}
                              handleChange={(value) =>
                                handleSetTimeOut(value, n)
                              }
                              noMargin
                            />
                          </Grid>
                          {hasPermission(
                            permissionWorkticket.MANUAL_RATE_TIMEKEEPING,
                            permissions
                          ) ? (
                            <Grid item md={1} sm={6}>
                              <FormInput
                                gridSizes={[{ size: "md", val: 12 }]}
                                name="hourly_rate"
                                label="Hourly Rate"
                                value={row.hourlyRate.toFixed(2)}
                                readonly={row.hasHourRate}
                                handleBlur={(event, value) =>
                                  handleHourlyRateChange(event, n)
                                }
                                noMargin
                                alignText="right"
                              />
                            </Grid>
                          ) : null}

                          <Grid item md={1} sm={6}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="total_hours"
                              label="T. Hours"
                              value={row.totalHours.toFixed(2)}
                              readonly={true}
                              noMargin
                              alignText="right"
                            />
                          </Grid>
                          <Grid item md={2} sm={6}>
                            <Box className={classes.actionContainer}>
                              {hasPermission(
                                permissionWorkticket.MANUAL_RATE_TIMEKEEPING,
                                permissions
                              ) ? (
                                <FormInput
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  name="total_dollars"
                                  label="T. Dollars"
                                  value={row.totalDollars.toFixed(2)}
                                  readonly={true}
                                  noMargin
                                  alignText="right"
                                />
                              ) : null}
                              <Box className={classes.travelContainer}>
                                {customersTKOvertime.includes(
                                  workticket?.job?.customer?.customer_number
                                ) ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={row.overtime}
                                        onChange={(event) =>
                                          handleOvertimeChange(event, n)
                                        }
                                        value={true}
                                        color="secondary"
                                      />
                                    }
                                    label="Overtime"
                                    className={classes.containerOptionsOvertime}
                                  />
                                ) : null}

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={row.drive}
                                      onChange={(event) =>
                                        handleDriveChange(event, n)
                                      }
                                      value={true}
                                      color="secondary"
                                      disabled={!row.driveEnabled}
                                    />
                                  }
                                  label={<TravelIcon />}
                                  className={classes.containerOptionsTravel}
                                />
                              </Box>
                              <WorkticketTimekeepingManualActions
                                handleDeleteRow={() => handleDeleteRow(n)}
                                handleCopyToAll={() => handleCopyToAll(n)}
                              />
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Box
                      className={classes.addContainer}
                      onClick={handleAddClick}
                    >
                      <AddIcon className={classes.iconAddLine} /> Add Line Item
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item md={8} sm={6}></Grid>
                      <Grid item md={2} sm={6}>
                        <FormInput
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="total_hours"
                          label="Total Hours"
                          value={totalHours.toFixed(2)}
                          readonly={true}
                          noMargin
                          alignText="right"
                        />
                      </Grid>
                      <Grid item md={2} sm={6}>
                        {hasPermission(
                          permissionWorkticket.MANUAL_RATE_TIMEKEEPING,
                          permissions
                        ) ? (
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="total_dollars"
                            label="Total Dollars"
                            value={totalDollars.toFixed(2)}
                            readonly={true}
                            noMargin
                            alignText="right"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.actionsDialogWrapper}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleSaveTimekeeping}
                disabled={!rows.length}
              >
                Enter Timekeeping
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message="Timekeeping manual entry has been saved."
      />
    </>
  );
};

export default WorkticketTimekeepingManualMultiple;
