import React, { useEffect, useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Table from "components/common/Table/table";
import RequestTypeChip from "components/ui/Requests/RequestTypeChip";
import { useTableDispatch } from "contexts/tableContext";
import RequestDashboardSurfaceDialog from "../dialog/requestDashboardSurfaceDialog";
import RequestDashboardDialog from "../dialog/requestDashboardDialog";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "../styles";

const RequestTable = ({ workTableData }) => {
  const classes = useStyles();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const dispatchTable = useTableDispatch();
  const columns = [
    {
      checkbox: false,
    },
    {
      id: "number",
      label: "Number",
      content: (row) => (
        <span className="underline" onClick={() => openDetail(row.id)}>
          {row.number}
        </span>
      ),
    },
    {
      id: "summary",
      label: "Summary",
      content: (row) => row.summary,
    },
    {
      id: "jobs",
      label: "Locations",
      content: (row) => row.job_description,
    },
    {
      id: "status",
      label: "Status",
      content: (row) => row.status_string,
    },
    {
      id: "type",
      label: "Type",
      content: (row) => <RequestTypeChip type={row.type} />,
    },
    {
      id: "submittedBy",
      label: "Submitted By",
      content: (row) => row.first_name + " " + row.last_name,
    },
    { id: "created_at", label: "Submitted Date", format: "date" },
  ];

  const openDetail = (id) => {
    setIsOpenDialog(true);
    setSelectedRequest(id);
  };

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: workTableData?.requests });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable]);

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.rootContainer}>
          <Box className={classes.tableContainer}>
            <Box className="sliderHeightTable">
              <Table newColumns={columns} activeMobile={true}></Table>
            </Box>
          </Box>
        </Box>
      </Box>
      {hasPermission(permissionSurface.REQUEST, permissions) ? (
        <RequestDashboardSurfaceDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedRequest}
        />
      ) : (
        <RequestDashboardDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedRequest}
        />
      )}
    </Box>
  );
};

export default RequestTable;
