import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AccountPayablesSubsTable from "./accountPayablesSubTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";

import useStyles from "./styles";

const AccountPayablesSubList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Account Payable Subcontractor"
        link="/financial/report/account-payable-sub"
      ></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <AccountPayablesSubsTable />
        </ListProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AccountPayablesSubList);
