import React, { useMemo, useState } from "react";
import withTableContext from "components/hoc/withTableContext";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import WTIcon from "@material-ui/icons/Receipt";
import Button from "@material-ui/core/Button";
import { ButtonBase } from "components/ui/core/button";
import WorkticketIcon from "@material-ui/icons/Receipt";
import Tooltip from "@material-ui/core/Tooltip";
import FieldWTSummary from "./fieldWTSummary";
import { logException } from "components/util/logUtil";
import PreviewDialog from "./dialog/previewDialog";
import { getQuotePrivew } from "services/quoteService";
import { dateFormatField } from "components/util/timeFormat";
import { useTableDispatch } from "contexts/tableContext";

import { useQuoteState } from "contexts/quoteContext";

import useStyles from "./styles";

const QuoteWorktickets = (props) => {
  const columns = useMemo(
    () => [
      {
        id: "summary",
        label: "WT Summary",
        content: (row) => <FieldWTSummary field={row.summary} />,
      },
      {
        id: "job",
        label: "Job",
      },
      {
        id: "job_number",
        label: "Job Number",
      },
      {
        id: "assigned",
        label: "Assigned To",
      },
      {
        id: "payments",
        label: "Payments",
        format: "money",
      },
      {
        id: "creation_date",
        label: "Creation Date",
        content: (row) => dateFormatField(row.creation_date),
        style: { width: 110 },
      },
      {
        id: "due_date",
        label: "Due Date",
        content: (row) => dateFormatField(row.due_date),
        style: { width: 110 },
      },
    ],
    []
  );
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoadingPreview, setIsloadingPreview] = useState(false);
  const dispatchTable = useTableDispatch();
  const classes = useStyles();
  const stateContext = useQuoteState();

  const { quoteDetails, selectedQuote, isLoading } = stateContext
    ? stateContext
    : null;

  const handleView = (link) => {
    const { history } = props;
    history.push(link);
  };

  if (isLoading || !selectedQuote || selectedQuote === "new") {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  const previewWorktickets = async () => {
    try {
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
      setIsloadingPreview(true);
      setOpenPreview(true);
      const response = await getQuotePrivew(quoteDetails.id);
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: response.data.data.preview,
      });
      setIsloadingPreview(false);
    } catch (e) {
      logException(e, "Cannot generate preview");
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  return (
    <Box className={classes.containerBodyWTList}>
      <Typography variant="h4" className={classes.titleSection} gutterBottom>
        Related Worktickets ({quoteDetails.worktickets.length})
      </Typography>
      <Grid container className={classes.itemsContainer}>
        {quoteDetails.worktickets.map((wt) => (
          <React.Fragment key={wt.id}>
            <Grid item xs={1} md={1} lg={1}>
              <WTIcon />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className={classes.titleSectionBody} gutterBottom>
                {wt.number}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button
                variant="outlined"
                color="primary"
                className={classNames(
                  classes.buttonView,
                  classes.buttonOutlined
                )}
                onClick={() => handleView(`/workticket/${wt.id}`)}
              >
                View
              </Button>
            </Grid>
          </React.Fragment>
        ))}
        {quoteDetails.worktickets.length === 0 && (
          <Box className={classes.previewContainer}>
            <PreviewDialog
              open={openPreview}
              handleClose={handleClosePreview}
              isLoading={isLoadingPreview}
            ></PreviewDialog>
            <Box className={classes.previewQuoteAction}>
              {quoteDetails.quote_items.filter(
                (item) => !["task", "good"].includes(item.item_type)
              ).length > 0 ? (
                <ButtonBase
                  startIcon={<WorkticketIcon />}
                  variant="outlined"
                  onClick={previewWorktickets}
                  className={classes.buttonPreviewDrawer}
                >
                  Preview Worktickets
                </ButtonBase>
              ) : (
                <Tooltip title="Please add a service or custom item to prview tickets.">
                  <Box>
                    <ButtonBase
                      startIcon={<WorkticketIcon />}
                      variant="outlined"
                      disabled={true}
                      onClick={() => {}}
                      className={classes.buttonPreviewDrawer}
                    >
                      Preview Worktickets
                    </ButtonBase>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default withRouter(withTableContext(QuoteWorktickets));
