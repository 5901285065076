import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chipInternal: {
    minWidth: 60,
    height: "auto",
    borderRadius: 3,
    padding: 0,
    fontSize: 12,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  logoEOS: {
    height: 26,
  },
}));

export default useStyles;
