import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormSelect from "components/ui/FormContent/formSelect";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionIcon from "@material-ui/icons/Help";
import { difference } from "lodash";

import GlobalUiContext from "contexts/globalUiContext";
import {
  assignUsersGenerator,
  removeUsersGenerator,
} from "services/generatorService";

import useStyles from "./styles";

const optionsScheduleInterval = [
  { value: 1, label: "Day", id: "days" },
  { value: 2, label: "Week", id: "weeks" },
  { value: 3, label: "Month", id: "months" },
];

const optionsDays = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const optionsDaysMonth = [...Array(31).keys()].map((item) => {
  return { value: item + 1, label: String(item + 1) };
});

const scheduleInit = {
  startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  stopDate: moment().add(1, "year").format("YYYY-MM-DD HH:mm:ss"),
  skipWeekdays: 0,
};

const RecurrenceGenerator = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [scheduleInterval, setScheduleInterval] = useState(1);
  const [scheduleData, setScheduleData] = useState(scheduleInit);
  const [noEndDate, setNoEndDate] = useState(true);
  const [every, setEvery] = useState(1);
  const [days, setDays] = useState([1]);
  const [daysMonth, setDaysMonth] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [recurrenceUsers, setRecurrenceUsers] = useState([]);
  const [isDirty, setIsDirty] = useState(0);
  const [error, setError] = useState([]);

  const {
    open,
    type,
    data,
    handleClose,
    handleSubmit,
    handleGeneratorDelete,
    title,
    subTitle,
  } = props;

  useEffect(() => {
    if (data) {
      const findScheduleInterval = optionsScheduleInterval.find(
        (item) => item.id === data.schedule_interval
      );
      if (findScheduleInterval) {
        setScheduleInterval(findScheduleInterval.value);
      }
      setEvery(data.schedule_data.value);
      setScheduleData({
        startDate: data.schedule_data.start_date,
        stopDate: !data.schedule_data.stop_date
          ? moment().add(1, "month").format("YYYY-MM-DD HH:mm:ss")
          : data.schedule_data.stop_date,
        skipWeekdays: data.schedule_data.skip_weekends,
      });

      setNoEndDate(!data.schedule_data.stop_date ? true : false);
      if (data.schedule_interval === "weeks") {
        setDays(data.schedule_data.days);
      }
      if (data.schedule_interval === "months") {
        const daysMonthUpdate = data.schedule_data.days.map((item) => {
          return optionsDaysMonth.find((i) => i.value === item);
        });
        setDaysMonth(daysMonthUpdate);
      }

      if (data.users) {
        const usersAssign = data.users.map((user) => user.id);
        setRecurrenceUsers(
          users.filter((user) => usersAssign.includes(user.id))
        );
      }
    } else {
      setScheduleInterval(1);
      setScheduleData({
        startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        stopDate: moment().add(1, "year").format("YYYY-MM-DD HH:mm:ss"),
        skipWeekdays: 0,
      });
      setNoEndDate(true);
      setEvery(1);
      setDays([1]);
      setDaysMonth([]);
      setOpenDelete(false);
      setRecurrenceUsers([]);
      setIsDirty(0);
    }
  }, [data, users]);

  const selectedOption = optionsScheduleInterval.find(
    (item) => item.value === scheduleInterval
  );

  const handleChangeInterval = async (event) => {
    setIsDirty(1);
    setScheduleInterval(event.value);
  };

  const handleChangeStartDate = async (event) => {
    setIsDirty(1);
    const updateScheduleData = {
      ...scheduleData,
      startDate: moment(event).format("YYYY-MM-DD HH:mm:ss"),
    };
    setScheduleData(updateScheduleData);
  };

  const handleChangeStopDate = async (event) => {
    setIsDirty(1);
    const updateScheduleData = {
      ...scheduleData,
      stopDate: moment(event).format("YYYY-MM-DD HH:mm:ss"),
    };
    setScheduleData(updateScheduleData);
  };

  const handleChangeSkipWeekends = (event) => {
    setIsDirty(1);
    const updateScheduleData = {
      ...scheduleData,
      skipWeekdays: event.target.checked ? 1 : 0,
    };
    setScheduleData(updateScheduleData);
  };

  const handleBlurEvery = (event) => {
    setIsDirty(1);
    setEvery(event.value);
  };

  const handleChangeDays = (e, option) => {
    setIsDirty(1);
    if (e.target.checked) {
      setDays([...days, option]);
    } else {
      days.splice(days.indexOf(option), 1);
      if (days.length) {
        setDays([...days]);
      } else {
        setDays([]);
      }
    }
  };

  const handleChangeDaysMonth = (event, value) => {
    setIsDirty(1);
    setDaysMonth(value);
    setError([]);
  };

  const handleSave = async () => {
    if (selectedOption.id === "months" && !Boolean(daysMonth.length)) {
      setError([
        {
          key: "daysMonths",
          message: "Please select at least one day of the month.",
        },
      ]);
      return;
    }
    let daysInput = null;
    if (selectedOption.id === "weeks") {
      daysInput = days;
    }
    if (selectedOption.id === "months") {
      daysInput = daysMonth.map((item) => {
        return item.value;
      });
    }
    const usersAssign = recurrenceUsers.map((user) => user.id);
    const dataSubmit = {
      schedule_interval: selectedOption.id,
      type: type,
      schedule_data: {
        value: every,
        days: daysInput,
        start_date: scheduleData.startDate,
        stop_date: !noEndDate ? scheduleData.stopDate : null,
        skip_weekends: scheduleData.skipWeekdays,
      },
      user_ids: usersAssign,
    };
    const dataId = data ? data.id : null;
    if (dataId) {
      // Assign or remove users
      const currentAssigned = data.users
        ? data.users.map((user) => user.id)
        : [];
      const selectedAssigned = recurrenceUsers.map((user) => user.id);
      const addAssigned = difference(selectedAssigned, currentAssigned);
      const removeAssigned = difference(currentAssigned, selectedAssigned);
      if (addAssigned.length) {
        await assignUsersGenerator(dataId, { user_ids: addAssigned });
      }
      if (removeAssigned.length) {
        await removeUsersGenerator(dataId, { user_ids: removeAssigned });
      }
    }

    if (dataId === null || (dataId && isDirty)) {
      handleSubmit(dataId, dataSubmit);
    }

    handleClose();
  };

  const handleDelete = () => {
    setOpenDelete(true);
  };

  const handleConfirmDelete = () => {
    const dataId = data ? data.id : null;
    if (handleGeneratorDelete) {
      handleGeneratorDelete(dataId);
    }
    handleClose();
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    if (value) {
      setRecurrenceUsers(value);
    } else {
      setRecurrenceUsers([]);
    }
  };
  const manageGenerator =
    data === null ||
    (data.type === 0 && data?.is_active && !data?.tickets_in_progress);

  const readOnly =
    data && (data.type !== 0 || !data?.is_active || data?.tickets_in_progress);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            className={classes.wrapperDialogClose}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
          {data && data.type === 0 && data?.is_active && (
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              className={classes.wrapperDialogDelete}
            >
              <DeleteIcon className={classes.iconOptions} />
            </IconButton>
          )}

          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            {`Recurrence ${title ? title : ""}`}
          </Typography>
          {subTitle ? (
            <Typography
              variant="caption"
              gutterBottom
              className={classes.subTitleDialog}
            >
              {subTitle}
            </Typography>
          ) : (
            ""
          )}
          {manageGenerator && (
            <>
              <FormSelect
                gridSizes={[{ size: "md", val: 6 }]}
                name="schedule_interval"
                label="Schedule Interval"
                options={optionsScheduleInterval}
                value={scheduleInterval}
                handleBlur={handleChangeInterval}
                readonly={data && data.type !== 0 ? true : false}
              />
              <Box>
                <Grid container spacing={4}>
                  <Grid item md={3} xs={6}>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="every"
                      label="Every"
                      value={every}
                      handleBlur={handleBlurEvery}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.titleOption}
                    >
                      {selectedOption && selectedOption.label}
                      {every > 1 ? "s" : ""}
                    </Typography>
                  </Grid>
                  {(scheduleInterval === 1 || scheduleInterval === 3) && (
                    <Grid item md={6}>
                      <Box className={classes.skipContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(scheduleData.skipWeekdays)}
                              onChange={handleChangeSkipWeekends}
                              className={classes.filterCheckbox}
                            />
                          }
                          label={"Skip weekends."}
                          value={1}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {scheduleInterval === 2 && (
                  <Box className={classes.daysOptionsContainer}>
                    <Grid container spacing={1}>
                      {optionsDays.map((option) => {
                        return (
                          <Grid item xs={6} md={3} key={option.value}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={days.includes(option.value)}
                                  onChange={(e) =>
                                    handleChangeDays(e, option.value)
                                  }
                                  className={classes.filterCheckbox}
                                />
                              }
                              label={option.label}
                              value={option.value}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
                {scheduleInterval === 3 && (
                  <Box className={classes.monthsOptionsContainer}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={optionsDaysMonth}
                      name="daysMonths"
                      label="On Days"
                      handleChange={handleChangeDaysMonth}
                      multiple={true}
                      value={daysMonth}
                      error={error}
                    />
                  </Box>
                )}
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <FormInputDateAdo
                      name="startDate"
                      label="Start Date"
                      value={scheduleData.startDate}
                      handleChange={handleChangeStartDate}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={classes.gridContainer}>
                    <FormInputDateAdo
                      name="stopDate"
                      label="End Date"
                      value={scheduleData.stopDate}
                      handleChange={handleChangeStopDate}
                      disabled={noEndDate ? true : false}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={noEndDate}
                          onChange={() => setNoEndDate(!noEndDate)}
                          className={classes.filterCheckbox}
                        />
                      }
                      label={"Don't define end date."}
                      value={1}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users}
                      name="recurrence_users"
                      label="Assigned To"
                      handleChange={handleChangeAssignedTo}
                      multiple={true}
                      value={recurrenceUsers}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {readOnly && (
            <Box>
              <List className={classes.listRoot} disablePadding={true}>
                <ListItem>
                  <ListItemText
                    primary="Schedule Interval"
                    secondary={selectedOption && selectedOption.label}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Every"
                    secondary={`${every} ${
                      selectedOption && selectedOption.label
                    }${every > 1 ? "s" : ""}`}
                  />
                </ListItem>
                {scheduleInterval === 2 && (
                  <ListItem>
                    <ListItemText
                      primary="On Week Days"
                      secondary={days.map(
                        (day) =>
                          `${
                            optionsDays.find((opt) => opt.value === day)?.label
                          }, `
                      )}
                    />
                  </ListItem>
                )}
                {scheduleInterval === 3 && (
                  <ListItem>
                    <ListItemText
                      primary="On Month Days"
                      secondary={daysMonth.map((day) => `${day.label}, `)}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary="Start Date -  End Date"
                    secondary={`${moment(scheduleData.startDate).format(
                      "MM/DD/YYYY"
                    )} -  ${
                      noEndDate
                        ? `Not Defined`
                        : `${moment(scheduleData.stopDate).format(
                            "MM/DD/YYYY"
                          )}`
                    }`}
                  />
                </ListItem>
                {recurrenceUsers && Boolean(recurrenceUsers.length) && (
                  <ListItem>
                    <ListItemText
                      primary="Assigned To"
                      secondary={recurrenceUsers.map((user) => {
                        return `${user.first_name} ${user.last_name}, `;
                      })}
                    />
                  </ListItem>
                )}
              </List>
            </Box>
          )}
        </DialogContent>
        {manageGenerator && (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              size="large"
              onClick={handleSave}
            >
              {data ? "Update" : "Create"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialogConfirmation}>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDelete(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <QuestionIcon className={classes.iconQuestionDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Delete Recurrence
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete the recurrence?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDelete}
            className={classes.button}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="secondary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecurrenceGenerator;
