import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const WorkticketAssigned = (props) => {
  const classes = useStyles();
  const { users, usingNames } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  if (!users) {
    return "Error users";
  }
  if (!users.length) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const handlePopoverOpen = (event) => {
    event.preventDefault();
    if (users.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const listUsers = usingNames
    ? users
    : users
        .filter((user) => user.active)
        .map((user) => {
          return user.classification_id === 15
            ? user.first_name
            : `${user.first_name} ${user.last_name}`;
        });

  return (
    <>
      <Typography
        variant="body2"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.label}
      >
        {listUsers[0]} {listUsers[1] ? "..." : ""}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        className={classes.poper}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={2}
      >
        {listUsers.slice(0, 5).join("; ")}{" "}
        {listUsers.length > 5 ? "+ More..." : null}
      </Popover>
    </>
  );
};

export default WorkticketAssigned;
