import React, { useContext, useReducer } from "react";

const RoleViewContext = React.createContext();

const initialState = {
  role: {},
  options: [],
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.role,
      };
    case "SET_OPTIONS":
      return {
        ...state,
        options: action.options,
      };
    default:
      return state;
  }
};

const RoleViewProvider = ({ children }) => (
  <RoleViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </RoleViewContext.Provider>
);

const useRoleView = () => {
  const context = useContext(RoleViewContext);
  if (context === undefined) {
    throw new Error("useRoleView must be used within a RoleViewProvider");
  }
  return context;
};

export { RoleViewProvider, useRoleView };
