import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import WorkticketBreadcrum from "./workticketBreadcrum";
import WorkticketHeader from "./workticketHeader";
import WorkticketInformation from "./workticketInformation";
import WorkticketInformationWG from "./workticketInformationWG";
import WorkticketInformationPT from "./workticketInformationPT";
import WorkticketInformationFM from "./workticketInformationFM";
import WorkticketInformationCO from "./workticketInformationCO";
import WorkticketTask from "./workticketTask";
import WorkticketSchedule from "./workticketSchedule";
import WorkticketActivity from "./workticketActivity";
import WorkticketBilling from "./workticketBilling";
import WorkticketBillingPortfolio from "./workticketBillingPortfolio";
import WorkticketTimekeeping from "./workticketTimekeeping";
import WorkticketTimekeepingWG from "./workticketTimekeepingWG";
import WorkticketTimekeepingFM from "./workticketTimekeepingFM";
import WorkticketTimekeepingCO from "./workticketTimekeepingCO";
// import WorkticketFilePaper from "./workticketFilePaper";
// import WorkticketFile from "./workticketFile";
// import WorkticketFilePaperWG from "./workticketFilePaperWG";
// import WorkticketFileWG from "./workticketFileWG";
import WorkticketActions from "./workticketActions";
import WorkticketRating from "./workticketRating";
import WorkticketSignature from "./workticketSignature";
import WorkticketLocation from "./workticketLocation";
import WorkticketInvoice from "./workticketInvoice";
import WorkticketInvoiceWG from "./workticketInvoiceWG";
import WorkticketQuote from "./workticketQuote";
import { WorkticketUpload } from "./workticketUpload";
import { WorkticketGallery } from "./worticketGallery";
import WorkticketQuoteActionCorrigo from "./workticketQuoteActionCorrigo";
import WorkticketInvoiceActionCorrigo from "./workticketInvoiceActionCorrigo";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import WorkIcon from "@material-ui/icons/Storage";
import TimerIcon from "@material-ui/icons/Timer";

import { getWorkticketDetails } from "services/workticketService";
import { getWorkticketComments } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { UploadFileProvider } from "contexts/uploadFileContext";

import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const QUICK_TICKET_CATEGORY = 0;
const ADDON_PROJECT = 1;
const PORTFOLIO_PROJECT = 3;
const TEMP_STAFFING_PROJECT = 5;
const CONTRACT_PERIODIC = 6;
const EXTERNAL_WALGREENS = 7;
const FM_CATEGORY = 8;
const CORRIGO_CATEGORY = 9;

function useWorkticketsItem(workticketId) {
  return useQuery(["workticket", workticketId], async () => {
    if (workticketId === "new") {
      return {};
    }
    const { data } = await getWorkticketDetails(workticketId);
    return data;
  });
}

const WorkticketLayout = (props) => {
  const classes = useStyles();
  const [valueBottomNavigation, setBottomNavigation] = useState(
    props.id_project ? "info" : "work"
  );
  const [isMobile, setIsMobile] = useState(false);
  const context = useWorkticketView();
  const dispatchContext = context[1];

  const { data, isLoading, refetch } = useWorkticketsItem(props.id);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    window.addEventListener("resize", resize.bind(this));
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
  };

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: props.id !== "new" ? data.data.workticket : "new",
        });

        dispatchContext({
          type: "SET_LOADING",
          isLoading: props.id !== "new" ? false : true,
        });
      } catch (e) {
        logException(e, "Cannot load workticket data");
      }
    };
    if (!isLoading) {
      loadWorkticket();
    }
  }, [props.id, dispatchContext, data, isLoading]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  const setWorkTicketComments = (comments) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
  };

  const fetchWorkTicketComments = async () => {
    try {
      if (props.id !== "new") {
        const response = await getWorkticketComments(props.id);
        setWorkTicketComments(response.data.data);
      }
    } catch (e) {
      logException(e, "Cannot load workticket comments data");
    }
  };

  useEffect(() => {
    fetchWorkTicketComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const workticketCategory = data?.data?.workticket?.workticket_category;

  const isPortfolioWorkticket =
    [
      PORTFOLIO_PROJECT,
      ADDON_PROJECT,
      TEMP_STAFFING_PROJECT,
      CONTRACT_PERIODIC,
    ].includes(data?.data?.workticket?.project?.type) ||
    [QUICK_TICKET_CATEGORY].includes(workticketCategory);

  return (
    <>
      <WorkticketBreadcrum />
      <Grid container className={classes.root}>
        {(!isMobile || (isMobile && valueBottomNavigation === "info")) && (
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.containerMain}>
              {workticketCategory === EXTERNAL_WALGREENS ? (
                <WorkticketInformationWG
                  id_project={props.id_project}
                  refreshWorkticketData={refetch}
                />
              ) : workticketCategory === FM_CATEGORY ? (
                <WorkticketInformationFM
                  id_project={props.id_project}
                  refreshWorkticketData={refetch}
                />
              ) : workticketCategory === CORRIGO_CATEGORY ? (
                <WorkticketInformationCO
                  id_project={props.id_project}
                  refreshWorkticketData={refetch}
                />
              ) : isPortfolioWorkticket ? (
                <WorkticketInformationPT
                  id_project={props.id_project}
                  refreshWorkticketData={refetch}
                />
              ) : (
                <WorkticketInformation
                  id_project={props.id_project}
                  refreshWorkticketData={refetch}
                />
              )}
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "work")) && (
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.containerBody}>
              <WorkticketHeader />
              <WorkticketGallery
                workTicketId={props.id}
                workTicketNumber={data?.data?.workticket?.number}
              />
              <WorkticketTask />
              <WorkticketSchedule />
              <UploadFileProvider>
                <WorkticketUpload
                  workTicketId={props.id}
                  workTicketNumber={data?.data?.workticket?.number}
                />
              </UploadFileProvider>
              <WorkticketActivity />
              <WorkticketBilling />
              <WorkticketBillingPortfolio />
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "clock")) && (
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.containerSidebar}>
              <WorkticketActions />
              <WorkticketRating />
              {workticketCategory === EXTERNAL_WALGREENS ? (
                <>
                  <WorkticketTimekeepingWG />
                  <WorkticketInvoiceWG />
                  {/* <WorkticketFilePaperWG />
                  <WorkticketFileWG /> */}
                </>
              ) : workticketCategory === FM_CATEGORY ? (
                <>
                  <WorkticketTimekeepingFM />
                  <WorkticketInvoice refreshWorkticketData={refetch} />
                  <WorkticketQuote refreshWorkticketData={refetch} />
                  {/* <WorkticketFilePaper />
                  <WorkticketFile /> */}
                </>
              ) : workticketCategory === CORRIGO_CATEGORY ? (
                <>
                  <WorkticketTimekeepingCO />
                  <WorkticketQuoteActionCorrigo
                    refreshWorkticketData={refetch}
                  />
                  <WorkticketInvoiceActionCorrigo
                    refreshWorkticketData={refetch}
                  />
                  {/* <WorkticketFilePaper />
                  <WorkticketFile /> */}
                </>
              ) : (
                <>
                  <WorkticketTimekeeping />
                  {/* <WorkticketFilePaper />
                  <WorkticketFile /> */}
                </>
              )}
              <WorkticketSignature />
              <WorkticketLocation />
            </Box>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="work" icon={<WorkIcon />} />
          <BottomNavigationAction value="clock" icon={<TimerIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default WorkticketLayout;
