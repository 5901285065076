import React from "react";
import merge from "lodash/merge";
import {
  getSurveysTab,
  getSurveysTabSearch,
  getSurveysTabOffset,
} from "services/surveyFrontlineService";

const defaultState = {
  surveysTabListLoading: true,
  surveysTabSelected: null,
  surveysTabList: [],
  surveysTabCount: [],
  openView: false,
  openLocations: false,
};

const SurveysTabStateContext = React.createContext();
const SurveysTabDispatchContext = React.createContext();

function surveysTabReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        surveysTabListLoading: action.surveysTabListLoading,
      };
    case "SET_SURVEYS_TAB_LIST":
      return {
        ...state,
        surveysTabList: action.surveysTabList,
      };
    case "SET_SURVEYS_TAB_COUNT":
      return {
        ...state,
        surveysTabCount: action.surveysTabCount,
      };
    case "SET_SURVEYS_TAB_SELECTED":
      return {
        ...state,
        surveysTabSelected: action.surveysTabSelected,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    case "TOGGLE_OPEN_LOCATIONS":
      return {
        ...state,
        openLocations: action.openLocations,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveysTabProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(surveysTabReducer, mergedState);
  return (
    <SurveysTabStateContext.Provider value={state}>
      <SurveysTabDispatchContext.Provider value={dispatch}>
        {children}
      </SurveysTabDispatchContext.Provider>
    </SurveysTabStateContext.Provider>
  );
}

function useSurveysTabState() {
  const context = React.useContext(SurveysTabStateContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysTabState must be used within a LocationProvider"
    );
  }
  return context;
}

function useSurveysTabDispatch() {
  const context = React.useContext(SurveysTabDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getSurveysTabList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      surveysTabListLoading: true,
    });
    const result = await getSurveysTab(filterData);
    dispatch({
      type: "SET_SURVEYS_TAB_LIST",
      surveysTabList: [...result.data.data.surveys],
    });
    dispatch({
      type: "SET_SURVEYS_TAB_COUNT",
      surveysTabCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      surveysTabListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getSurveysTabListDataSet(
  surveysTabList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getSurveysTabOffset(offset, filterData);
    const updateData = surveysTabList.concat(result.data.data.surveys);
    dispatch({
      type: "SET_SURVEYS_TAB_LIST",
      surveysTabList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchSurveysTabList(search, filterData) {
  try {
    const result = await getSurveysTabSearch(search, filterData);
    return result.data.data.surveys;
  } catch (error) {
    console.log(error);
  }
}

export {
  SurveysTabProvider,
  useSurveysTabState,
  useSurveysTabDispatch,
  getSurveysTabList,
  getSurveysTabListDataSet,
  searchSurveysTabList,
};
