import React, { useContext, useReducer } from "react";

const CustomerViewContext = React.createContext();

const initialState = {
  customer: {},
  files: [],
  notes: "",
  jobsSelected: [],
  isLoading: true,
  contacts: [],
  openCreateContact: false,
  refreshContactsOptions: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: action.customer,
      };
    case "SET_FILES":
      return {
        ...state,
        files: action.files,
      };
    case "SET_NOTES":
      return {
        ...state,
        notes: action.notes,
      };
    case "SET_JOBS":
      return {
        ...state,
        jobsSelected: action.jobsSelected,
      };
    case "SET_CONTACTS":
      return {
        ...state,
        contacts: action.contacts,
      };
    case "SET_OPEN_CREATE_CONTACT":
      return {
        ...state,
        openCreateContact: action.openCreateContact,
      };
    case "SET_REFRESH_CONTACTS":
      return {
        ...state,
        refreshContactsOptions: action.refreshContactsOptions,
      };
    default:
      return state;
  }
};

const CustomerViewProvider = ({ children }) => (
  <CustomerViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </CustomerViewContext.Provider>
);

const useCustomerView = () => {
  const context = useContext(CustomerViewContext);
  if (context === undefined) {
    throw new Error(
      "useCustomerView must be used within a CustomerViewProvider"
    );
  }
  return context;
};

export { CustomerViewProvider, useCustomerView };
