import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import SurveysQuestionsListTable from "./surveysQuestionsListTable";
import SurveysQuestionsListDialog from "./dialog/surveysQuestionsListDialog";
import { SurveysQuestionsProvider } from "contexts/surveysQuestionsTableContext";
import { SurveysTasksProvider } from "contexts/surveysTasksContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const SurveysQuestionsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Surveys" link="/surveys/admin/questions" />
      <Box className={classes.pageBodyWrapper}>
        <SurveysQuestionsProvider>
          <SurveysTasksProvider>
            <SurveysQuestionsListTable />
            <SurveysQuestionsListDialog />
          </SurveysTasksProvider>
        </SurveysQuestionsProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(SurveysQuestionsList);
