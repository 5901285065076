import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FileUploadSurvey from "pages/survey/FileUploadSurvey/fileUploadSurvey";
import FilesDisplaySurveyUploaded from "components/ui/FilesDisplayUploaded/filesDisplaySurveyUploaded";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { fillQuestion } from "services/surveyFrontlineService";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";

const SurveyFile = () => {
  const classes = useStyles();
  const {
    selectedQuestions,
    correlative,
    selectedFiles,
    lang,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = selectedFiles.filter((file) => file.id !== id);
    const data = {
      deleteFiles: [id],
    };
    fillQuestion(selectedQuestions[correlative].id, data, false);
    dispatchSurveyFill({
      type: "SET_SELECTED_FILES",
      selectedFiles: filesDisplayRemove,
    });
  };

  return (
    <>
      <Typography className={classes.docCommentsTitle}>
        {surveysLabelsLang[lang].feedbackDocumentsLabel}
      </Typography>
      <Box className={classes.containerFile}>
        <FileUploadSurvey questionId={selectedQuestions[correlative].id} />
        <Box className={classes.containerDisplayFiles}>
          <FilesDisplaySurveyUploaded
            files={selectedFiles ?? []}
            handleRemoveDisplay={handleRemoveDisplay}
            restrictRemove={readonlySurvey}
          />
        </Box>
      </Box>
    </>
  );
};

export default SurveyFile;
