import http from "./httpService";
import { apiUrl } from "lib/config";

import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/forms/`;

export function getFormsTabData(
  filterData,
  query = "",
  offset = "",
  topTab = "talent"
) {
  const filterString = getFilterString(filterData);
  let tabName = topTab.split("?")[1];
  if (query) {
    return http.get(
      `${apiEndpoint}${tabName}?${filterString}search=${query}${
        offset ? `filters[tab]=${tabName}&offset=` : ""
      }${offset ? offset : ""}`,
      {}
    );
  } else {
    return http.get(
      `${apiEndpoint}${tabName}?${filterString}${
        offset ? `filters[tab]=${tabName}&offset=` : ""
      }${offset ? offset : ""}`,
      {}
    );
  }
}

export function submitFormDataBasedOnCurrentTab(
  formData,
  topTab,
  formSelected
) {
  http.setMultiPart();
  let subTabName = "";

  subTabName = "talent/change-request";
  if (formSelected === 2.1) {
    subTabName = "equipment/repair";
  } else if (formSelected === 2.2) {
    subTabName = "equipment/transfer";
  }

  if (formSelected === 3.1) {
    subTabName = "accident/injury-illness";
  } else if (formSelected === 3.2) {
    subTabName = "accident/investigation";
  } else if (formSelected === 3.3) {
    subTabName = "accident/follow-up";
  }

  return http.post(`${apiEndpoint}${subTabName}`, setFormData(formData));
}

export function deleteFormDataBasedOnCurrentTab(id, topTab = "talent") {
  let tabName = topTab.split("?")[1];
  if (tabName === "assets") {
    tabName = "equipment";
  }
  return http.delete(`${apiEndpoint}${tabName}/${id}`, {});
}
export function viewFormDataBasedOnCurrentTab(id, topTab = "talent") {
  let tabName = topTab.split("?")[1];
  if (tabName === "assets") {
    tabName = "equipment";
  }
  return http.get(`${apiEndpoint}${tabName}/${id}`, {});
}

export function getPositionTitle() {
  return http.get(`${apiEndpoint}positions-titles`);
}

export function downloadFile(idArr) {
  let payload = new FormData();
  // idArr.map((v) => {
  //   payload.append("open_positions_ids[]", v);
  // });
  return http.post(`${apiEndpoint}/export-ids`, payload);
}
