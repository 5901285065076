import React from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useQuoteState } from "contexts/quoteSurfaceContext";

const QuoteSufaceItems = props => {
  const classes = useStyles();

  const { quoteDetails, isLoading } = useQuoteState();

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <Box>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rect"
          height={80}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <div>
          <Box mt={3}>
            {quoteDetails.quote_items && quoteDetails.quote_items.length > 0 && (
              <Grid container className={classes.itemsContainer}>
                <Grid item xs={6} md={5} lg={5}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemHeader}
                    noWrap
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemHeaderCenter}
                    noWrap
                  >
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemHeaderCenter}
                    noWrap
                  >
                    Hours
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemHeaderCenter}
                    noWrap
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            )}
            {quoteDetails.quote_items &&
              quoteDetails.quote_items.map((item, index) => {
                return (
                  <Grid
                    container
                    className={classes.itemsContainerDetail}
                    key={item.id}
                  >
                    <Grid item xs={6} md={5} lg={5}>
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.itemDetail}
                        noWrap
                      >
                        {item.item_subject}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      className={classes.alignRight}
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.itemDetail}
                        noWrap
                      >
                        {item.quantity
                          ? `${formatNumber(item.quantity)} ${
                              item.uom ? item.uom : ""
                            }`
                          : "-----"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      className={classes.alignRight}
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.itemDetail}
                        noWrap
                      >
                        {item.hours
                          ? formatNumber(parseFloat(item.hours).toFixed(2))
                          : "-----"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      className={classes.alignRight}
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.itemDetail}
                        noWrap
                      >
                        {item.total && item.item_type !== "task"
                          ? `$ ${formatNumber(
                              parseFloat(item.total).toFixed(2)
                            )}`
                          : "-----"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            {quoteDetails.quote_items && quoteDetails.quote_items.length > 0 && (
              <Grid container className={classes.itemsContainerDetail}>
                <Grid item xs={6} md={5} lg={5}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  >
                    Total
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  ></Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2} className={classes.alignRight}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  >
                    {formatNumber(parseFloat(quoteDetails.hours).toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3} lg={3} className={classes.alignRight}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  >
                    {`$ ${formatNumber(
                      parseFloat(quoteDetails.total).toFixed(2)
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {(!quoteDetails.quote_items ||
              quoteDetails.quote_items.length === 0) && (
              <Typography>No items added.</Typography>
            )}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default QuoteSufaceItems;
