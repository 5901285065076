import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const PartnerActionContext = React.createContext();

const initialState = {
  openPartnerProfile: false,
  openEditPartner: false,
  openChangeStatus: false,
  openRatePartner: false,
  openDelete: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_PARTNER_PROFILE":
      return {
        ...state,
        openPartnerProfile: action.open,
      };
    case "TOGGLE_EDIT_PARTNER":
      return {
        ...state,
        openEditPartner: action.open,
      };
    case "TOGGLE_CHANGE_STATUS":
      return {
        ...state,
        openChangeStatus: action.open,
      };
    case "TOGGLE_RATE_PARTNER":
      return {
        ...state,
        openRatePartner: action.open,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openDelete: action.open,
      };
    default:
      return initialState;
  }
};

const PartnerActionProvider = ({ children }) => (
  <PartnerActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </PartnerActionContext.Provider>
);

function usePartnerAction() {
  const context = React.useContext(PartnerActionContext);
  if (context === undefined) {
    throw new Error(
      "usePartnerAction must be used within a PartnerActionProvider"
    );
  }
  return context;
}

export { PartnerActionProvider, usePartnerAction };
