import React from "react";
import Box from "@material-ui/core/Box";
import LocationsTable from "./locationsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import LocationsListDialog from "./dialog/locationsListDialog";
import { LocationsProvider } from "contexts/surveyLocationsContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import useStyles from "./styles";

const LocationsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <LocationsProvider>
        <LocationsTable />
        <SurveyFillProvider>
          <LocationsListDialog />
        </SurveyFillProvider>
      </LocationsProvider>
    </Box>
  );
};

export default withTableFilterContext(LocationsList);
