import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
  },
  drawerPaper: {
    width: 240,
    backgroundColor: "#EDF5F8",
    border: 0,
    overflow: "hidden",
  },
  logoWrapper: {
    padding: "14px 15px 5px 15px",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 16px 10px 20px",
    },
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  logo: {
    width: 200,
    [theme.breakpoints.up("sm")]: {
      width: 250,
    },
    position: "relative",
    display: "block",
  },
  navWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  listItem: {
    color: "inherit",
    padding: "12px 12px 12px 0",
    "&:hover": {
      background: "rgba(0,0,0, 0.1)",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px 12px 16px 0",
    },
  },
  htmlTooltip: {
    backgroundColor: "#EDF5F8",
    maxWidth: 220,
    fontSize: 14,
    lineHeight: 14,
    padding: "0",
    position: "relative",
    "&:after": {
      right: "100%",
      top: "50%",
      border: "solid transparent",
      content: " ",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderRightColor: "#75ccf8",
      borderWidth: 10,
      marginTop: -10,
    },
  },
  listItemText: {
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    padding: "0",
    fontWeight: "bold",
  },
  listItemChildText: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
  },
  listItemIcon: {
    minWidth: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(9),
    },
    background: "transparent",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: "0",
    justifyContent: "center",
    "& svg": {
      fontSize: 28,
    },
  },
  listItemIconSmall: {
    background: "transparent",
    color: theme.palette.primary.main,
    width: 40,
    height: "auto",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: "0",
    justifyContent: "center",
    "& > svg": {
      fontSize: 20,
    },
  },
  listItemExpand: {
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
  selected: {
    opacity: 1,
    color: theme.palette.primary.contrastText,
    background: "#FFFFFF",
  },
  selectedSub: {
    backgroundColor: "#FFFFFF",
  },
  listMenu: {
    paddingTop: 0,
  },
  nested: {
    padding: "2px 10px 2px 8px",
    [theme.breakpoints.up("sm")]: {
      padding: "5px 10px 5px 16px",
    },
  },
  nestedClosed: {
    padding: "9px 12px",
    borderRadius: "5px",
    "& > div": {
      margin: "0",
    },
  },
  listItemIconTooltip: {
    minWidth: 20,
    width: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 290,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 9,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
    },
  },
  drawerOpen: {
    width: 290,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
  },
  menuOpen: {
    color: theme.palette.primary.main,
    width: theme.spacing(7),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: "15px 17px",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
      padding: "15px 12px",
    },
  },
}));

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#EDF5F8",
    border: "3px solid #EDF5F8",
    color: "#ffffff",
    maxWidth: 220,
    fontSize: 14,
    lineHeight: 14,
    padding: "0",
    position: "relative",
    borderRadius: 5,
    marginTop: 10,
    "&::after": {
      right: "100%",
      top: "30px",
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderRightColor: "#EDF5F8",
      borderWidth: "10px",
      marginTop: "-20px",
    },
  },
}))(Tooltip);

export default useStyles;
