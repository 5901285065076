import React from "react";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { WorkticketProvider } from "contexts/workticketContext";
import { WorkticketActionProvider } from "contexts/workticketActionContext";
import WorkticketsTable from "./projectTabWorkticketTable";
import useStyles from "./styles";

const ProjectTabWorkticket = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerTabWorkticketProject}>
      <WorkticketProvider>
        <WorkticketActionProvider>
          <WorkticketsTable />
        </WorkticketActionProvider>
      </WorkticketProvider>
    </Box>
  );
};

export default withTableFilterContext(ProjectTabWorkticket);
