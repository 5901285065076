import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import AlertIcon from "@material-ui/icons/Error";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";

const AlertDialog = (props) => {
  const classes = useStyles();
  const { message } = props;

  return (
    <Tooltip
      title={
        <Box className={classes.wrapperDialog}>
          <List className={classes.messageContainer}>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <AlertIcon className={classes.alertIcon} />
              </ListItemIcon>
              <Typography className={classes.alertMessage}>
                {message}
              </Typography>
            </ListItem>
          </List>
        </Box>
      }
      classes={{
        tooltip: classes.tooltipCustom,
      }}
    >
      <AlertIcon className={classes.alertIcon} />
    </Tooltip>
  );
};

export default AlertDialog;
