import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import FormInputScale from "components/ui/FormContent/formInputScale";
import CloseIcon from "@material-ui/icons/Close";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import DeleteIcon from "@material-ui/icons/Delete";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MessageDialog from "./messageDialog";
import { logException } from "components/util/logUtil";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import { getTasks } from "services/surveysTasksService";
import { createQuestion } from "services/surveysQuestionsService";
import { updateSurvey } from "services/surveyService";

import {
  useSurveysQuestionsState,
  useSurveysQuestionsDispatch,
} from "contexts/surveysQuestionsTableContext";
import { validate, validateProperty } from "components/util/validationUtil";
import {
  questionQATypes,
  questionQACategory,
  questionQATypesES,
  questionQACategoryES,
  questionQARatingTypes,
  questionQARatingTypesES,
} from "constants.js";
import { useSurveyView } from "contexts/surveyViewContext";
import GlobalUiContext from "contexts/globalUiContext";
import {
  useQuestionState,
  useQuestionDispatch,
} from "contexts/surveyQuestionContext";
import {
  useSurveysTasksState,
  useSurveysTasksDispatch,
} from "contexts/surveysTasksContext";

import useStyles from "./styles";

const questionSchema = {
  typeObject: Joi.object()
    .required()
    .label("Type")
    .error(() => {
      return {
        message: "Type is required.",
      };
    }),
  categoryObject: Joi.object()
    .required()
    .label("Category")
    .error(() => {
      return {
        message: "Category is required.",
      };
    }),
  scale: Joi.number().min(1).max(10).required().label("Scale"),
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
};

const QuestionBiSchema = {
  typeObject: Joi.object()
    .required()
    .label("Type")
    .error(() => {
      return {
        message: "Type is required.",
      };
    }),
  categoryObject: Joi.object()
    .required()
    .label("Category")
    .error(() => {
      return {
        message: "Category is required.",
      };
    }),
  typeObjectES: Joi.object()
    .required()
    .label("Type")
    .error(() => {
      return {
        message: "Type is required.",
      };
    }),
  categoryObjectES: Joi.object()
    .required()
    .label("Category")
    .error(() => {
      return {
        message: "Category is required.",
      };
    }),
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  scale: Joi.number().min(1).max(10).required().label("Scale"),
  name_es: Joi.string()
    .min(1)
    .required()
    .label("Nombre")
    .error(() => {
      return {
        message: "Nombre es requerido.",
      };
    }),
};

const initialData = {
  type: null,
  typeObject: null,
  typeObjectES: null,
  category: null,
  categoryObject: null,
  categoryObjectES: null,
  name: "",
  name_es: "",
  tooltip: "",
  tooltip_es: "",
  description: "",
  description_es: "",
  scale: 10,
  labels: {},
  labels_es: {},
  options: [
    { id: 1, name: "" },
    { id: 2, name: "" },
  ],
  options_es: [
    { id: 1, name: "" },
    { id: 2, name: "" },
  ],
  tasks: [],
  tasksES: [],
  is_multiple_options: false,
  has_uploads: true,
  has_comments: true,
  has_es: false,
  rating_type: 1,
  ratingTypeObject: questionQARatingTypes.filter((item) => item.value === 1)[0],
  ratingTypeObjectES: questionQARatingTypesES.filter(
    (item) => item.value === 1
  )[0],
};

const ManageQuestionDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [schema, setSchema] = useState(questionSchema);
  const [data, setData] = useState(initialData);
  const [tasks, setTasks] = useState(null);
  const [tasksES, setTasksES] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [iniData, setIniData] = useState(null);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const { surveysQuestionSelected, openManageQuestion } =
    useSurveysQuestionsState();
  const dispatchSurveysQuestions = useSurveysQuestionsDispatch();
  const [stateContextSurvey] = useSurveyView();
  const { survey } = stateContextSurvey ?? null;
  const { dispatchGlobalUi } = useContext(GlobalUiContext);
  const { assignedIds } = useQuestionState();
  const dispatchQuestions = useQuestionDispatch();
  const { recentlyTaskCreated } = useSurveysTasksState();
  const dispatchSurveysTasks = useSurveysTasksDispatch();

  useEffect(() => {
    if (openManageQuestion) {
      setError([]);
      setUpdatedData(null);
      if (surveysQuestionSelected) {
        const selectedType = questionQATypes.filter(
          (item) => item.value === surveysQuestionSelected.type
        );
        const selectedTypeES = questionQATypesES.filter(
          (item) => item.value === surveysQuestionSelected.type
        );
        const selectedCategory = questionQACategory.filter(
          (item) => item.value === surveysQuestionSelected.category
        );
        const selectedCategoryES = questionQACategoryES.filter(
          (item) => item.value === surveysQuestionSelected.category
        );
        const selectedRatingType = questionQARatingTypes.filter(
          (item) => item.value === surveysQuestionSelected.rating_type
        );
        const selectedRatingTypeES = questionQARatingTypesES.filter(
          (item) => item.value === surveysQuestionSelected.rating_type
        );
        const ids = surveysQuestionSelected.tasks.map((i) => {
          return i.id;
        });
        const resultForRenderT = tasks.filter((item) => ids.includes(item.id));
        const resultForRenderTES = tasksES.filter((item) =>
          ids.includes(item.id)
        );
        const loadedData = {
          typeObject: selectedType[0],
          typeObjectES: selectedTypeES[0],
          type: surveysQuestionSelected.type,
          categoryObject: selectedCategory[0],
          categoryObjectES: selectedCategoryES[0],
          category: surveysQuestionSelected.category,
          name: surveysQuestionSelected.name,
          name_es: surveysQuestionSelected.name_es ?? "",
          tooltip: surveysQuestionSelected.tooltip ?? "",
          tooltip_es: surveysQuestionSelected.tooltip_es ?? "",
          description: surveysQuestionSelected.description ?? "",
          description_es: surveysQuestionSelected.description_es ?? "",
          labels: JSON.parse(surveysQuestionSelected.labels),
          labels_es: JSON.parse(surveysQuestionSelected.labels_es),
          options: JSON.parse(surveysQuestionSelected.options),
          options_es: JSON.parse(surveysQuestionSelected.options_es),
          tasks: resultForRenderT,
          tasksES: resultForRenderTES,
          scale: surveysQuestionSelected.scale,
          has_comments: surveysQuestionSelected.has_comments,
          has_uploads: surveysQuestionSelected.has_uploads,
          has_es: surveysQuestionSelected.has_es,
          rating_type: surveysQuestionSelected.rating_type,
          ratingTypeObject: selectedRatingType[0],
          ratingTypeObjectES: selectedRatingTypeES[0],
        };
        setData(loadedData);
        setIniData(JSON.parse(JSON.stringify(loadedData)));
        if (surveysQuestionSelected.has_es) {
          setSchema(QuestionBiSchema);
        } else {
          setSchema(questionSchema);
        }
      } else {
        setSchema(questionSchema);
        const selectedCategory = questionQACategory.filter(
          (item) => item.value === survey.type
        );
        const selectedCategoryES = questionQACategoryES.filter(
          (item) => item.value === survey.type
        );
        const newData = {
          ...initialData,
          categoryObject: selectedCategory[0],
          categoryObjectES: selectedCategoryES[0],
          category: survey.type,
          options: [
            { id: 1, name: "" },
            { id: 2, name: "" },
          ],
          options_es: [
            { id: 1, name: "" },
            { id: 2, name: "" },
          ],
        };
        setData(newData);
      }
    }
  }, [openManageQuestion]);

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const data = await getTasks();
        const resultForRender = data.data.tasks.map((item) => {
          return {
            value: item.id,
            label: item.name,
            ...item,
          };
        });
        const resultForRenderES = data.data.tasks.map((item) => {
          return {
            value: item.id,
            label: item.has_es ? item.name_es : item.name,
            ...item,
          };
        });
        setTasks(resultForRender);
        setTasksES(resultForRenderES);
      } catch (e) {
        logException(e, "Cannot load tasks data");
      }
    };
    if (!tasks) {
      loadTasks();
    }
  }, [tasks]);

  useEffect(() => {
    const loadNewTask = () => {
      const newTask = {
        value: recentlyTaskCreated.id,
        label: recentlyTaskCreated.name,
        ...recentlyTaskCreated,
      };
      const newTaskES = {
        value: recentlyTaskCreated.id,
        label: recentlyTaskCreated.has_es
          ? recentlyTaskCreated.name_es
          : recentlyTaskCreated.name,
        ...recentlyTaskCreated,
      };
      const newTasks = [newTask, ...tasks];
      const newTasksES = [newTaskES, ...tasksES];

      const ids = data.tasks.map((i) => {
        return i.value;
      });
      ids.push(newTask.value);
      const resultForRenderT = newTasks.filter((item) => ids.includes(item.id));
      const resultForRenderTES = newTasksES.filter((item) =>
        ids.includes(item.id)
      );
      const newData = {
        ...data,
        tasks: resultForRenderT,
        tasksES: resultForRenderTES,
      };
      setTasks(newTasks);
      setTasksES(newTasksES);
      setData(newData);
      dispatchSurveysTasks({
        type: "TOGGLE_SET_RECENT_TASK",
        recentlyTaskCreated: null,
      });
    };
    if (recentlyTaskCreated) {
      loadNewTask();
    }
  }, [recentlyTaskCreated]);

  useEffect(() => {
    if (iniData && JSON.stringify(data) !== JSON.stringify(iniData)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [JSON.stringify(data)]);

  const handleClose = () => {
    dispatchSurveysQuestions({
      type: "TOGGLE_MANAGE",
      openManageQuestion: false,
    });
  };

  const selectDeselectQuestion = async (type) => {
    try {
      setIsLoadingDialog(true);
      if (type === 0) {
        await updateSurvey(survey.id, {
          remove_question_id: surveysQuestionSelected.id,
        });
        const newAssignedIds = assignedIds.filter(
          (idq) => idq !== surveysQuestionSelected.id
        );
        dispatchQuestions({
          type: "SET_ASSIGNED_IDS",
          assignedIds: newAssignedIds,
        });
      } else {
        await updateSurvey(survey.id, {
          add_question_id: surveysQuestionSelected.id,
        });
        assignedIds.push(parseInt(parseInt(surveysQuestionSelected.id)));
        dispatchQuestions({
          type: "SET_ASSIGNED_IDS",
          assignedIds: assignedIds,
        });
      }
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
      setIsLoadingDialog(false);
      handleClose();
    } catch (e) {
      console.log("Cannot create Question");
    }
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);
        if (!data.has_es) {
          data.name_es = "";
          data.tooltip_es = "";
          data.description_es = "";
          data.labels_es = {};
          data.options_es = [];
        }
        const result = await createQuestion(data);
        setMessageSuccess("Question created successfully!");
        await updateSurvey(survey.id, {
          add_question_id: result.data.data.question.id,
        });
        assignedIds.push(parseInt(result.data.data.question.id));
        dispatchQuestions({
          type: "SET_ASSIGNED_IDS",
          assignedIds: assignedIds,
        });
        dispatchGlobalUi({
          type: "SET_REFRESH_DATA",
          refreshData: true,
        });
        setIsLoadingDialog(false);
        setOpenSuccess(true);
      }
      handleClose();
    } catch (e) {
      console.log("Cannot delete Question");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let updateData = {};
    switch (event.name) {
      case "name":
        newData = {
          ...data,
          name: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            name: event.value,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;

      case "tooltip":
        newData = {
          ...data,
          tooltip: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            tooltip: event.value,
          };
        }
        break;
      case "name_es":
        newData = {
          ...data,
          name_es: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            name_es: event.value,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;

      case "tooltip_es":
        newData = {
          ...data,
          tooltip_es: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            tooltip_es: event.value,
          };
        }
        break;
      case "description":
        newData = {
          ...data,
          description: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            description: event.value,
          };
        }
        break;
      case "description_es":
        newData = {
          ...data,
          description_es: event.value,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            description_es: event.value,
          };
        }
        break;
      case "labelLeft":
        const lbls = { ...data.labels };
        lbls.left = event.value;
        newData = {
          ...data,
          labels: lbls,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels: lbls,
          };
        }
        break;
      case "labelLeftES":
        const lblsES = { ...data.labels_es };
        lblsES.left = event.value;
        newData = {
          ...data,
          labels_es: lblsES,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels_es: lblsES,
          };
        }
        break;
      case "labelCenter":
        const lblsc = { ...data.labels };
        lblsc.center = event.value;
        newData = {
          ...data,
          labels: lblsc,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels: lblsc,
          };
        }
        break;
      case "labelCenterES":
        const lblscES = { ...data.labels_es };
        lblscES.center = event.value;
        newData = {
          ...data,
          labels_es: lblscES,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels_es: lblscES,
          };
        }
        break;
      case "labelRight":
        const lblsr = { ...data.labels };
        lblsr.right = event.value;
        newData = {
          ...data,
          labels: lblsr,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels: lblsr,
          };
        }
        break;
      case "labelRightES":
        const lblsrES = { ...data.labels_es };
        lblsrES.right = event.value;
        newData = {
          ...data,
          labels_es: lblsrES,
        };
        if (surveysQuestionSelected) {
          updateData = {
            ...updatedData,
            labels_es: lblsrES,
          };
        }
        break;
      default:
        break;
    }
    setData(newData);
    if (surveysQuestionSelected) {
      setUpdatedData(updateData);
    }
  };

  const handleOnChange = (event) => {
    if (event.target.checked) {
      setSchema(QuestionBiSchema);
    } else {
      setSchema(questionSchema);
    }
    const newData = {
      ...data,
      has_es: event.target.checked,
      options_es:
        event.target.checked && data.type === 3
          ? data.options.map((option) => {
              return { id: option.id, name: "" };
            })
          : [
              { id: 1, name: "" },
              { id: 2, name: "" },
            ],
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        has_es: event.target.checked,
        options_es:
          event.target.checked && data.type === 3
            ? data.options.map((option) => {
                return { id: option.id, name: "" };
              })
            : [
                { id: 1, name: "" },
                { id: 2, name: "" },
              ],
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleChangeType = (event, value) => {
    const selectedOption = questionQATypesES.filter(
      (item) => item.value === value?.value
    );
    const newData = {
      ...data,
      typeObject: value,
      typeObjectES: selectedOption ? selectedOption[0] : null,
      type: value ? value.value : null,
    };
    setData(newData);
    if (surveysQuestionSelected) {
      setUpdatedData({
        ...updatedData,
        type: value ? value.value : null,
      });
    }
    validateProperty("typeObject", value, schema, error, setError);
  };

  const handleChangeTypeES = (event, value) => {
    const selectedOption = questionQATypes.filter(
      (item) => item.value === value?.value
    );
    const newData = {
      ...data,
      typeObjectES: value,
      typeObject: selectedOption ? selectedOption[0] : null,
      type: value ? value.value : null,
    };
    setData(newData);
    if (surveysQuestionSelected) {
      setUpdatedData({
        ...updatedData,
        type: value ? value.value : null,
      });
    }
    validateProperty("typeObjectES", value, schema, error, setError);
  };

  const handleChangeCategory = (event, value) => {
    const selectedOption = questionQACategoryES.filter(
      (item) => item.value === value?.value
    );
    const newData = {
      ...data,
      categoryObject: value,
      categoryObjectES: selectedOption ? selectedOption[0] : null,
      category: value ? value.value : null,
    };
    setData(newData);
    if (surveysQuestionSelected) {
      setUpdatedData({
        ...updatedData,
        category: value ? value.value : null,
      });
    }
    validateProperty("categoryObject", value, schema, error, setError);
  };

  const handleChangeCategoryES = (event, value) => {
    const selectedOption = questionQACategory.filter(
      (item) => item.value === value?.value
    );
    const newData = {
      ...data,
      categoryObjectES: value,
      categoryObject: selectedOption ? selectedOption[0] : null,
      category: value ? value.value : null,
    };
    setData(newData);
    if (surveysQuestionSelected) {
      setUpdatedData({
        ...updatedData,
        category: value ? value.value : null,
      });
    }
    validateProperty("categoryObjectES", value, schema, error, setError);
  };

  const handleChangeRatingType = (event, value) => {
    if (value) {
      const selectedOption = questionQARatingTypesES.filter(
        (item) => item.value === value?.value
      );
      const newData = {
        ...data,
        ratingTypeObject: value,
        ratingTypeObjectES: selectedOption ? selectedOption[0] : null,
        rating_type: value ? value.value : null,
      };
      setData(newData);
      if (surveysQuestionSelected) {
        setUpdatedData({
          ...updatedData,
          rating_type: value ? value.value : null,
        });
      }
    }
  };

  const handleChangeRatingTypeES = (event, value) => {
    if (value) {
      const selectedOption = questionQARatingTypes.filter(
        (item) => item.value === value?.value
      );
      const newData = {
        ...data,
        ratingTypeObjectES: value,
        ratingTypeObject: selectedOption ? selectedOption[0] : null,
        rating_type: value ? value.value : null,
      };
      setData(newData);
      if (surveysQuestionSelected) {
        setUpdatedData({
          ...updatedData,
          rating_type: value ? value.value : null,
        });
      }
    }
  };

  const handleOnChangeScale = (event) => {
    const newData = {
      ...data,
      scale: parseInt(event.target.value),
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        scale: event.target.value,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
    validateProperty("scale", event.target.value, schema, error, setError);
  };

  const handleChangeTasks = async (event, value) => {
    if (value) {
      const ids = value.map((i) => {
        return i.value;
      });
      const newData = {
        ...data,
        tasks: value,
        tasksES: tasksES.filter((item) => ids.includes(item.value)),
      };
      setData(newData);
    }
  };

  const handleChangeTasksES = async (event, value) => {
    if (value) {
      const ids = value.map((i) => {
        return i.value;
      });
      const newData = {
        ...data,
        tasksES: value,
        tasks: tasks.filter((item) => ids.includes(item.value)),
      };
      setData(newData);
    }
  };

  const handleOnChangeUploads = (event) => {
    const newData = {
      ...data,
      has_uploads: event.target.checked,
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        has_uploads: event.target.checked,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleOnChangeComments = (event) => {
    const newData = {
      ...data,
      has_comments: event.target.checked,
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        has_comments: event.target.checked,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleAddOption = () => {
    const newOption = {
      id: data.options.length
        ? data.options[data.options.length - 1].id + 1
        : 1,
      name: "",
    };
    const newData = {
      ...data,
      options: [...data.options, { ...newOption }],
      options_es: data.has_es ? [...data.options_es, { ...newOption }] : [],
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        options: [...data.options, { ...newOption }],
        options_es: data.has_es ? [...data.options_es, { ...newOption }] : [],
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleDeleteOption = (optionId) => {
    const newOptions = data.options.filter((option) => option.id !== optionId);
    const newOptionsES = data.options_es.filter(
      (option) => option.id !== optionId
    );
    const newData = {
      ...data,
      options: newOptions,
      options_es: newOptionsES,
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        options: newOptions,
        options_es: newOptionsES,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleChangeOption = (event, optionId) => {
    const opt = data.options.findIndex((option) => option.id === optionId);
    data.options[opt].name = event.value;
    const newData = {
      ...data,
      options: data.options,
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        options: data.options,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleChangeOptionES = (event, optionId) => {
    const opt = data.options_es.findIndex((option) => option.id === optionId);
    data.options_es[opt].name = event.value;
    const newData = {
      ...data,
      options_es: data.options_es,
    };
    if (surveysQuestionSelected) {
      const updateData = {
        ...updatedData,
        options_es: data.options_es,
      };
      setUpdatedData(updateData);
    }
    setData(newData);
  };

  const handleCreateTask = () => {
    dispatchSurveysTasks({
      type: "TOGGLE_MANAGE",
      openManageTask: true,
    });
  };

  return (
    <>
      <Dialog
        open={openManageQuestion}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={data.has_es ? "md" : "xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog2}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Grid container>
                <Grid item md={data.has_es ? 6 : 12}>
                  <Box className={classes.titleContainer}>
                    <ContactSupportIcon className={classes.titleIcon} />
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.titleDialog}
                    >
                      {surveysQuestionSelected
                        ? "View Question"
                        : "New Question"}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      data.has_es
                        ? classes.formFieldsContainer2
                        : classes.formFieldsContainer
                    }
                  >
                    <Box className={classes.formToggleContainer}>
                      {!data.has_es ? (
                        <FormControlLabel
                          className={classes.spanishToggle}
                          control={
                            <Checkbox
                              checked={data.has_es}
                              onChange={handleOnChange}
                              color="secondary"
                              className={classes.spanishToggleCheck}
                            />
                          }
                          label="Spanish"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={questionQATypes}
                      name="typeObject"
                      error={error}
                      handleChange={handleChangeType}
                      label="Type"
                      value={data.typeObject}
                    />
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={questionQACategory}
                      name="categoryObject"
                      error={error}
                      handleChange={handleChangeCategory}
                      label="Category"
                      value={data.categoryObject}
                      readonly={true}
                    />
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="name"
                      label="Question Name"
                      error={error}
                      value={data.name}
                      handleBlur={handleBlur}
                    />
                    {data.type === 1 ? (
                      <>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={questionQARatingTypes}
                          name="ratingTypeObject"
                          error={error}
                          handleChange={handleChangeRatingType}
                          label="Rating Type"
                          value={data.ratingTypeObject}
                        />
                        <FormInput
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="tooltip"
                          label="Tool Tip"
                          error={error}
                          value={data.tooltip}
                          handleBlur={handleBlur}
                        />
                        <FormInputScale
                          gridSizes={[{ size: "md", val: 4 }]}
                          name="scale_initial"
                          name2="scale"
                          label="Scale"
                          error={error}
                          readonly={true}
                          minNumber={1}
                          maxNumber={10}
                          value={1}
                          value2={data.scale}
                          onChange2={handleOnChangeScale}
                        />
                        <Typography
                          className={classes.titleSection}
                          gutterBottom
                        >
                          Labels
                        </Typography>
                        <FormInput
                          gridSizes={[{ size: "md", val: 8 }]}
                          name="labelLeft"
                          withoutLabel={true}
                          value={data.labels.left}
                          handleBlur={handleBlur}
                          endText={"Left"}
                          placeholder="Very Poor"
                        />
                        <FormInput
                          gridSizes={[{ size: "md", val: 8 }]}
                          name="labelCenter"
                          withoutLabel={true}
                          value={data.labels.center}
                          handleBlur={handleBlur}
                          endText={"Center"}
                          placeholder="Average"
                        />
                        <FormInput
                          gridSizes={[{ size: "md", val: 8 }]}
                          name="labelRight"
                          withoutLabel={true}
                          value={data.labels.right}
                          handleBlur={handleBlur}
                          endText={"Right"}
                          placeholder="Excellent"
                        />
                        <Box className={classes.tasksActionsContainer}>
                          <Typography
                            className={classes.titleSection}
                            gutterBottom
                          >
                            Tasks
                          </Typography>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            className={classes.addOptionsButton}
                            onClick={handleCreateTask}
                          >
                            Add A New Task
                          </ButtonBase>
                        </Box>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={tasks}
                          name="tasks"
                          multiple={true}
                          handleChange={handleChangeTasks}
                          noLabel={true}
                          value={data.tasks}
                          limitTags={10}
                        />
                      </>
                    ) : data.type === 2 ? (
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="description"
                        label="Description"
                        error={error}
                        value={data.description}
                        multiline={true}
                        rows={3}
                        handleBlur={handleBlur}
                      />
                    ) : data.type === 3 ? (
                      <>
                        <Box className={classes.optionsActionsContainer}>
                          <Typography
                            className={classes.titleSection}
                            gutterBottom
                          >
                            Options
                          </Typography>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            className={classes.addOptionsButton}
                            onClick={handleAddOption}
                            disabled={data.options.length >= 10}
                          >
                            Add Option
                          </ButtonBase>
                        </Box>
                        <Box className={classes.containerEmpty}>
                          {data.options.length === 0 ? (
                            <Typography
                              className={classes.titleNoOption}
                              gutterBottom
                            >
                              {"No options have been added."}
                            </Typography>
                          ) : (
                            data.options.map((option) => (
                              <FormInput
                                key={option.id}
                                gridSizes={[{ size: "md", val: 8 }]}
                                withoutLabel={true}
                                value={option.name}
                                isObjectEndText={true}
                                handleBlur={(event) =>
                                  handleChangeOption(event, option.id)
                                }
                                endText={
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.deleteIcon}
                                    onClick={() =>
                                      handleDeleteOption(option.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                }
                              />
                            ))
                          )}
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                    <Typography className={classes.titleSection2} gutterBottom>
                      Other
                    </Typography>
                    <Box>
                      <FormControlLabel
                        className={classes.optionToggle}
                        control={
                          <Checkbox
                            checked={data.has_uploads}
                            onChange={handleOnChangeUploads}
                            color="secondary"
                            className={classes.optionToggleCheck}
                          />
                        }
                        label="Document Upload"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        className={classes.optionToggle}
                        control={
                          <Checkbox
                            checked={data.has_comments}
                            onChange={handleOnChangeComments}
                            color="secondary"
                            className={classes.optionToggleCheck}
                          />
                        }
                        label="Comments"
                      />
                    </Box>
                  </Box>
                </Grid>
                {data.has_es ? (
                  <Grid item md={6}>
                    <Box className={classes.titleContainer}>
                      <ContactSupportIcon className={classes.titleIcon} />
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleDialog}
                      >
                        {surveysQuestionSelected
                          ? "Ver Pregunta"
                          : "Nueva Pregunta"}
                      </Typography>
                    </Box>
                    <Box className={classes.formFieldsContainer}>
                      <Box className={classes.formToggleContainer}>
                        <FormControlLabel
                          className={classes.spanishToggle}
                          control={
                            <Checkbox
                              checked={data.has_es}
                              onChange={handleOnChange}
                              color="secondary"
                              className={classes.spanishToggleCheck}
                            />
                          }
                          label="Spanish"
                        />
                      </Box>
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={questionQATypesES}
                        name="typeObjectES"
                        error={error}
                        handleChange={handleChangeTypeES}
                        label="Tipo"
                        value={data.typeObjectES}
                      />
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={questionQACategoryES}
                        name="categoryObjectES"
                        error={error}
                        handleChange={handleChangeCategoryES}
                        label="Categoría"
                        value={data.categoryObjectES}
                        readonly={true}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="name_es"
                        label="Nombre de la Pregunta"
                        error={error}
                        value={data.name_es}
                        handleBlur={handleBlur}
                      />
                      {data.type === 1 ? (
                        <>
                          <FormSelectAuto
                            gridSizes={[{ size: "md", val: 12 }]}
                            options={questionQARatingTypesES}
                            name="ratingTypeObjectES"
                            error={error}
                            handleChange={handleChangeRatingTypeES}
                            label="Tipo de Punteo"
                            value={data.ratingTypeObjectES}
                          />
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="tooltip_es"
                            label="Etiqueta"
                            error={error}
                            value={data.tooltip_es}
                            handleBlur={handleBlur}
                          />
                          <FormInputScale
                            gridSizes={[{ size: "md", val: 4 }]}
                            name="scale_initial"
                            name2="scale"
                            label="Escala"
                            labelTo="A"
                            error={error}
                            readonly={true}
                            minNumber={1}
                            maxNumber={10}
                            value={1}
                            value2={data.scale}
                            onChange2={handleOnChangeScale}
                          />
                          <Typography
                            className={classes.titleSection}
                            gutterBottom
                          >
                            Etiquetas
                          </Typography>
                          <FormInput
                            gridSizes={[{ size: "md", val: 8 }]}
                            name="labelLeftES"
                            withoutLabel={true}
                            value={data.labels_es.left}
                            handleBlur={handleBlur}
                            endText={"Izquierda"}
                            placeholder="Pobre"
                          />
                          <FormInput
                            gridSizes={[{ size: "md", val: 8 }]}
                            name="labelCenterES"
                            withoutLabel={true}
                            value={data.labels_es.center}
                            handleBlur={handleBlur}
                            endText={"Centro"}
                            placeholder="Regular"
                          />
                          <FormInput
                            gridSizes={[{ size: "md", val: 8 }]}
                            name="labelRightES"
                            withoutLabel={true}
                            value={data.labels_es.right}
                            handleBlur={handleBlur}
                            endText={"Derecha"}
                            placeholder="Excelente"
                          />
                          <Box className={classes.tasksActionsContainer}>
                            <Typography
                              className={classes.titleSection}
                              gutterBottom
                            >
                              Tareas
                            </Typography>

                            <ButtonBase
                              variant="outlined"
                              color="secondary"
                              className={classes.addOptionsButton}
                              onClick={handleCreateTask}
                            >
                              Agregar Una Tarea Nueva
                            </ButtonBase>
                          </Box>
                          <FormSelectAuto
                            gridSizes={[{ size: "md", val: 12 }]}
                            options={tasksES}
                            name="tasks"
                            multiple={true}
                            handleChange={handleChangeTasksES}
                            noLabel={true}
                            value={data.tasksES}
                            limitTags={10}
                          />
                        </>
                      ) : data.type === 2 ? (
                        <FormInput
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="description_es"
                          label="Descripción"
                          error={error}
                          value={data.description_es}
                          multiline={true}
                          rows={3}
                          handleBlur={handleBlur}
                        />
                      ) : data.type === 3 ? (
                        <>
                          <Box className={classes.optionsActionsContainer}>
                            <Typography
                              className={classes.titleSection}
                              gutterBottom
                            >
                              Opciones
                            </Typography>
                            <ButtonBase
                              variant="outlined"
                              color="secondary"
                              className={classes.addOptionsButton}
                              onClick={handleAddOption}
                              disabled={data.options_es.length >= 10}
                            >
                              Agregar Opción
                            </ButtonBase>
                          </Box>
                          <Box className={classes.containerEmpty}>
                            {data.options_es.length === 0 ? (
                              <Typography
                                className={classes.titleNoOption}
                                gutterBottom
                              >
                                {"No se han agregado opciones."}
                              </Typography>
                            ) : (
                              data.options_es.map((option) => (
                                <FormInput
                                  key={option.id}
                                  gridSizes={[{ size: "md", val: 8 }]}
                                  withoutLabel={true}
                                  value={option.name}
                                  isObjectEndText={true}
                                  handleBlur={(event) =>
                                    handleChangeOptionES(event, option.id)
                                  }
                                  endText={
                                    <IconButton
                                      aria-label="delete"
                                      className={classes.deleteIcon}
                                      onClick={() =>
                                        handleDeleteOption(option.id)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                />
                              ))
                            )}
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                      <Typography
                        className={classes.titleSection2}
                        gutterBottom
                      >
                        Otros
                      </Typography>
                      <Box>
                        <FormControlLabel
                          className={classes.optionToggle}
                          control={
                            <Checkbox
                              checked={data.has_uploads}
                              onChange={handleOnChangeUploads}
                              color="secondary"
                              className={classes.optionToggleCheck}
                            />
                          }
                          label="Adjuntar Documentos"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          className={classes.optionToggle}
                          control={
                            <Checkbox
                              checked={data.has_comments}
                              onChange={handleOnChangeComments}
                              color="secondary"
                              className={classes.optionToggleCheck}
                            />
                          }
                          label="Comentarios"
                        />
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              {!surveysQuestionSelected || (iniData && hasChanged) ? (
                <ButtonBase color="secondary" onClick={handleConfirm}>
                  Create and Add Question
                </ButtonBase>
              ) : assignedIds.includes(surveysQuestionSelected.id) ? (
                <ButtonBase
                  color="secondary"
                  variant={"outlined"}
                  onClick={() => selectDeselectQuestion(0)}
                >
                  Remove
                </ButtonBase>
              ) : (
                <ButtonBase
                  color="secondary"
                  onClick={() => selectDeselectQuestion(1)}
                >
                  Add
                </ButtonBase>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={messageSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ManageQuestionDialog;
