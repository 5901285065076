import React from "react";
import * as workticketService from "../services/workticketSurfaceService";
import { workticketsSurfaceStatusOptions } from "../constants";
import { merge } from "lodash";

const defaultState = {
  workticketListLoading: true,
  workticketSelected: null,
  workticketList: [],
  workticketCount: [],
  workticketDetails: [],
  workticketLocation: null,
  scheduleMode: false,
  workticketSchedule: [],
  workticketScheduleCalendarRange: null,
};

const WorkticketStateContext = React.createContext();
const WorkticketDispatchContext = React.createContext();

function workticketReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        workticketListLoading: action.workticketListLoading,
      };
    case "SET_WORKTICKET_LIST":
      return {
        ...state,
        workticketList: action.workticketList,
      };
    case "SET_WORKTICKET_COUNT":
      return {
        ...state,
        workticketCount: action.workticketCount,
      };
    case "SET_WORKTICKET_SELECTED":
      return {
        ...state,
        workticketSelected: action.workticketSelected,
      };
    case "SET_WORKTICKET_LOCATION":
      return {
        ...state,
        workticketLocation: action.workticketLocation,
      };
    case "SET_WORKTICKET_DETAILS":
      return {
        ...state,
        workticketDetails: action.workticketDetails,
      };
    case "SET_SCHEDULE_MODE":
      return {
        ...state,
        scheduleMode: action.scheduleMode,
      };
    case "SET_SCHEDULE_LIST":
      return {
        ...state,
        workticketSchedule: action.workticketSchedule,
      };
    case "SET_SCHEDULE_CALENDAR_RANGE":
      return {
        ...state,
        workticketScheduleCalendarRange: action.ScheduleCalendarRange,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WorkticketProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(workticketReducer, mergedState);
  return (
    <WorkticketStateContext.Provider value={state}>
      <WorkticketDispatchContext.Provider value={dispatch}>
        {children}
      </WorkticketDispatchContext.Provider>
    </WorkticketStateContext.Provider>
  );
}

function useWorkticketState() {
  const context = React.useContext(WorkticketStateContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketState must be used within a WorkticketProvider"
    );
  }
  return context;
}

function useWorkticketDispatch() {
  const context = React.useContext(WorkticketDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketDispatch must be used within a WorkticketProvider"
    );
  }
  return context;
}

async function getWorkticket(dispatch, id) {
  try {
    const workticketDetailsResult =
      await workticketService.getWorkticketDetails(id);
    dispatch({
      type: "SET_WORKTICKET_DETAILS",
      workticketDetails: workticketDetailsResult.data.data.workticket,
    });
  } catch (error) {
    console.log(error);
  }
}

async function setWorkticketList(dispatch, data) {
  try {
    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: data?.data?.worktickets ?? [],
    });
    dispatch({
      type: "SET_WORKTICKET_COUNT",
      workticketCount: data?.data?.filter_counts ?? 0,
    });
    dispatch({
      type: "SET_LOADING",
      workticketListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketList(dispatch, filterData) {
  try {
    const workticketListResult = await workticketService.getWorktickets(
      filterData
    );

    const workticketList = {
      ...workticketListResult.data.data.worktickets,
    };

    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: workticketList,
    });
    const workticketCount = {
      ...workticketListResult.data.data.filter_counts,
    };
    dispatch({
      type: "SET_WORKTICKET_COUNT",
      workticketCount: workticketCount,
    });
    dispatch({
      type: "SET_LOADING",
      workticketListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketListDataSet(
  workticketList,
  dispatch,
  tab,
  offset,
  filterData,
  isClear
) {
  try {
    const findElement = workticketsSurfaceStatusOptions.find(
      (item) => item.id === tab
    );
    const result = await workticketService.getWorkticketsTabOffset(
      findElement.tab,
      offset,
      filterData
    );
    const updateWorkticketList = isClear
      ? result.data.data.worktickets
      : [...workticketList, ...result.data.data.worktickets];
    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: updateWorkticketList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchWorktickets(search, filterData) {
  try {
    const workticketListResult = await workticketService.getWorkticketsSearch(
      search,
      filterData
    );
    return workticketListResult.data.data;
  } catch (error) {
    console.log(error);
  }
}

export {
  WorkticketProvider,
  useWorkticketState,
  useWorkticketDispatch,
  getWorkticket,
  getWorkticketList,
  getWorkticketListDataSet,
  searchWorktickets,
  setWorkticketList,
};
