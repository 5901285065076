import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as classNames from "classnames";
import DateRangePickerComponent from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import TableTabs from "components/common/Table/tableTabs";
import React, { useContext, useEffect, useState, useMemo } from "react";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import Counter from "components/common/TableList/counter";
import {
  getFilterData,
  useFilterDispatch,
  useFilterState,
} from "contexts/filterContext";
import {
  useFrontlineSurveyDispatch,
  useFrontlineSurveyState,
} from "contexts/surveyFrontlineContext";
import GlobalUiContext from "contexts/globalUiContext";
import { useTableDispatch, useTableState } from "contexts/tableContext";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";
import FieldLocation from "./fieldLocation";
import AssignmentIcon from "@material-ui/icons/Assignment";

import SurveyBulkActions from "./surveyTabBulkActions";
import SurveyFilters from "./surveyTabFilters";
import SurveyTabTableActions from "./surveyTabTableActions";
import useStyles from "./styles";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { getFrontlineSurvey } from "services/surveyFrontlineService";
import FormIcon from "@material-ui/icons/ListAlt";
const surveyList = {};

const SurveySurfaceTable = (props) => {
  const history = useHistory();
  const [firstLoad, setFirstLoad] = useState(true);
  const [topTab, setTopTab] = useState(
    `survey-frontline${
      history.location.search.trim() === ""
        ? "?location"
        : history.location.search
    }`
  );
  const [surveyDropdown, setSurveyDropdown] = useState("");
  const [surveySelection, setSurveySelection] = useState(false);
  const columnsLocation = useMemo(
    () => [
      {
        id: "Name",
        label: "Survey Name",
        content: (row) =>
          row?.id ? (
            <Typography
              variant="body2"
              aria-haspopup="true"
            >{`${row?.created_by?.first_name} ${row?.created_by?.last_name}`}</Typography>
          ) : (
            "-"
          ),
        // style: { width: 250 },
      },
      {
        id: "Job Number",
        label: "Job Number",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            121
          </Typography>
        ),
        // style: { width: 250 },
      },
      {
        id: "Job Name",
        label: "Job Name",
        content: (row) => <FieldLocation job={row.job} />,
        // style: { width: 250 },
      },

      {
        id: "created_at",
        label: "Last Survey Date",
        format: "date",
        // style: { width: 50 },
      },
      {
        id: "Industry",
        label: "Industry",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            Draft
          </Typography>
        ),
      },
      {
        id: "Actions",
        label: "",
        content: (row) => (
          <Button
            className={classes.surveyButton}
            onClick={() => setSurveySelection(true)}
          >
            Complete Survey
          </Button>
        ),
        contentMobile: (row) => (
          <Button
            className={classes.surveyButton}
            onClick={() => setSurveySelection(true)}
          >
            Complete Survey
          </Button>
        ),
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        id: "Name",
        label: "Survey Name",
        content: (row) =>
          row?.id ? (
            <Typography
              variant="body2"
              aria-haspopup="true"
            >{`${row?.created_by?.first_name} ${row?.created_by?.last_name}`}</Typography>
          ) : (
            "-"
          ),
        // style: { width: 250 },
      },
      {
        id: "Job Name",
        label: "Job Name",
        content: (row) => <FieldLocation job={row.job} />,
        // style: { width: 250 },
      },
      {
        id: "Completed By",
        label: "Completed By",
        content: (row) =>
          row?.id ? (
            <Typography
              variant="body2"
              aria-haspopup="true"
            >{`${row?.created_by?.first_name} ${row?.created_by?.last_name}`}</Typography>
          ) : (
            "-"
          ),
        // style: { width: 250 },
      },

      {
        id: "start_date",
        label: "Start Date",
        format: "date",
      },
      {
        id: "created_at",
        label: "End Date",
        format: "date",
        // style: { width: 50 },
      },
      {
        id: "Score",
        label: "Score",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            99%
          </Typography>
        ),
      },
      {
        id: "Actions",
        label: "",
        content: (row) => (
          <SurveyTabTableActions
            row={row}
            setStatusModal={setStatusModal}
            setSelectedRowData={setSelectedRowData}
            setRefresh={setRefresh}
            refresh={refresh}
            topTab={topTab}
            setSurveySelected={setSurveySelected}
            setModal={setModal}
          />
        ),
        contentMobile: (row) => (
          <SurveyTabTableActions
            row={row}
            setRefresh={setRefresh}
            refresh={refresh}
            setStatusModal={setStatusModal}
            setSelectedRowData={setSelectedRowData}
            topTab={topTab}
            setSurveySelected={setSurveySelected}
            setModal={setModal}
          />
        ),
        style: { width: 58 },
      },
    ],
    []
  );

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { surveyList } = useFrontlineSurveyState();
  const [refresh, setRefresh] = useState(null);
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchSurveys = useFrontlineSurveyDispatch();
  const dispatchFilters = useFilterDispatch();
  const [openStatusModal, setStatusModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [filteredRes, setFilteredRes] = useState("");
  const [modal, setModal] = useState(0);
  const [surveySelected, setSurveySelected] = useState(false);

  const [tab, setTab] = useState(0);

  const [tabUpdated, setTabUpdated] = useState({
    1: 0,
    2: 0,
  });

  const getColumns = () => {
    if (topTab === "survey-frontline?location") {
      return [...columnsLocation];
    } else if (topTab === "survey-frontline?completed") {
      return [...columns];
    }
  };

  useEffect(() => {
    getFrontlineSurvey().then((res) => console.log("res", res));
  }, []);

  // useEffect(() => {
  //   if (firstLoad || refresh === null) {
  //     return;
  //   }
  //   setSurveyDropdown("");
  //   if (topTab === "survey-frontline?location" && tabUpdated[1] > 0) {
  //     setTimeout(() => {
  //       setTabUpdated({ ...tabUpdated, 1: 0 });
  //     }, 5000);
  //   } else if (topTab === "survey-frontline?completed" && tabUpdated[2] > 0) {
  //     setTimeout(() => {
  //       setTabUpdated({ ...tabUpdated, 2: 0 });
  //     }, 5000);
  //   }
  //   const filterData = getFilterData(filterState);
  //   setWeeklyScheduleData("");
  //   dispatchSurveys({
  //     type: "SET_FRONTLINE_SURVEY_LIST",
  //     surveyList: null,
  //   });
  //   dispatchTable({
  //     type: "UPDATE_COLUMNS",
  //     columns: [],
  //   });

  // }, [refresh]);
  // useEffect(() => {
  //   if (
  //     (filterState.startDateActive && filterState.endDateActive) ||
  //     (filterState.filters.length >= 0 && !firstLoad)
  //   ) {
  //     const filterData = getFilterData(filterState);
  //     dispatchSurveys({
  //       type: "SET_FRONTLINE_SURVEY_LIST",
  //       surveyList: null,
  //     });
  //     dispatchTable({
  //       type: "UPDATE_COLUMNS",
  //       columns: [],
  //     });

  //   }
  // }, [
  //   filterState.filters.length,
  //   filterState.startDateActive,
  //   filterState.endDateActive,
  // ]);

  const handleScrollClick = () => {
    if (firstLoad) {
      return;
    }
    if (
      surveyList?.data?.length >= surveyList?.data?.filter_counts ||
      loadingMore
    ) {
      return;
    }
    let offsetLength = surveyList?.data?.length;
    // if (
    //   offsetLength >
    //   surveyList?.data?.filter_counts -
    //     surveyList?.data?.length
    // ) {
    //   offsetLength =
    //     surveyList?.data?.filter_counts -
    //     surveyList?.data?.length;
    // }

    const filterData = getFilterData(filterState);

    setLoadingMore(true);
  };

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      //ck on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        setLoadingSearch(true);
        setTab(11);
        const filterData = getFilterData(filterState);
        const surveyListCopy = surveyList;
        dispatchSurveys({
          type: "SET_FRONTLINE_SURVEY_LIST",
          surveyList: null,
        });
        dispatchTable({
          type: "UPDATE_COLUMNS",
          columns: [],
        });

        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });

        setLoadingSearch(false);

        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchSurveys({
      type: "SET_FRONTLINE_SURVEY_LIST",
      surveyList: null,
    });
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: [],
    });
    const filterData = getFilterData(filterState);
    setTab(0);
  };

  useEffect(() => {
    // if (firstLoad) {
    //   setFirstLoad(false);
    // }
    // dispatchSurveys({
    //   type: "SET_FRONTLINE_SURVEY_LIST",
    //   surveyList: null,
    // });
    // setTab(0);
    // dispatchTable({
    //   type: "UPDATE_COLUMNS",
    //   columns: [],
    // });
    // const filterData = getFilterData(filterState);
    console.log("topTab", topTab);
    dispatchTable({
      type: "UPDATE_COLUMNS",
      columns: [],
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: [],
    });
    if (topTab === "survey-frontline?completed") {
      dispatchSurveys({
        type: "SET_FRONTLINE_SURVEY_LIST",
        surveyList: [
          {
            additional_changes: null,
            change_explanation:
              "Winston, Effective 7/27/21 he is back to work. According with Occupational Health Dept in CCF he is not required to bring a negative test.  if employee is feeling good with no symptoms. ",
            change_position_job_code: null,
            change_position_title: null,
            change_request: "Unpaid Leave",
            created_at: "2021-08-05 10:54:35",
            created_by: {
              id: 4185,
              first_name: "Yocasta",
              middle_name: "",
              last_name: "Perez",
              nickname: "",
            },
            created_user_id: 4185,
            current_rate: null,
            current_rate_type: null,
            deleted_at: null,
            effective_date: "2021-07-27",
            file: "",
            id: 5,
            job: {
              id: 752,
              job_number: 50581,
              job_description: "Cleveland Clinic - Hospital",
              site_number: null,
            },
            job_id: 752,
            job_new: null,
            new_job_id: null,
            new_rate: null,
            new_rate_type: null,
            requestor_type: 1,
            requestor_user_id: 4185,
            schedule: '""',
            updated_at: "2021-08-05 10:54:35",
            user: {
              id: 4193,
              first_name: "Winston",
              middle_name: "R",
              last_name: "Williams",
              nickname: null,
            },
            user_id: 4193,
          },
        ],
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [
          {
            additional_changes: null,
            change_explanation:
              "Winston, Effective 7/27/21 he is back to work. According with Occupational Health Dept in CCF he is not required to bring a negative test.  if employee is feeling good with no symptoms. ",
            change_position_job_code: null,
            change_position_title: null,
            change_request: "Unpaid Leave",
            created_at: "2021-08-05 10:54:35",
            created_by: {
              id: 4185,
              first_name: "Yocasta",
              middle_name: "",
              last_name: "Perez",
              nickname: "",
            },
            created_user_id: 4185,
            current_rate: null,
            current_rate_type: null,
            deleted_at: null,
            effective_date: "2021-07-27",
            file: "",
            id: 5,
            job: {
              id: 752,
              job_number: 50581,
              job_description: "Cleveland Clinic - Hospital",
              site_number: null,
            },
            job_id: 752,
            job_new: null,
            new_job_id: null,
            new_rate: null,
            new_rate_type: null,
            requestor_type: 1,
            requestor_user_id: 4185,
            schedule: '""',
            updated_at: "2021-08-05 10:54:35",
            user: {
              id: 4193,
              first_name: "Winston",
              middle_name: "R",
              last_name: "Williams",
              nickname: null,
            },
            user_id: 4193,
          },
        ],
      });
      dispatchTable({ type: "SET_HAS_SELECT", hasSelect: true });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
    } else {
      dispatchSurveys({
        type: "SET_FRONTLINE_SURVEY_LIST",
        surveyList: [
          {
            additional_changes: null,
            change_explanation:
              "Winston, Effective 7/27/21 he is back to work. According with Occupational Health Dept in CCF he is not required to bring a negative test.  if employee is feeling good with no symptoms. ",
            change_position_job_code: null,
            change_position_title: null,
            change_request: "Unpaid Leave",
            created_at: "2021-08-05 10:54:35",
            created_by: {
              id: 4185,
              first_name: "Yocasta",
              middle_name: "",
              last_name: "Perez",
              nickname: "",
            },
            created_user_id: 4185,
            current_rate: null,
            current_rate_type: null,
            deleted_at: null,
            effective_date: "2021-07-27",
            file: "",
            id: 5,
            job: {
              id: 752,
              job_number: 50581,
              job_description: "Cleveland Clinic - Hospital",
              site_number: null,
            },
            job_id: 752,
            job_new: null,
            new_job_id: null,
            new_rate: null,
            new_rate_type: null,
            requestor_type: 1,
            requestor_user_id: 4185,
            schedule: '""',
            updated_at: "2021-08-05 10:54:35",
            user: {
              id: 4193,
              first_name: "Winston",
              middle_name: "R",
              last_name: "Williams",
              nickname: null,
            },
            user_id: 4193,
          },
        ],
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [
          {
            additional_changes: null,
            change_explanation:
              "Winston, Effective 7/27/21 he is back to work. According with Occupational Health Dept in CCF he is not required to bring a negative test.  if employee is feeling good with no symptoms. ",
            change_position_job_code: null,
            change_position_title: null,
            change_request: "Unpaid Leave",
            created_at: "2021-08-05 10:54:35",
            created_by: {
              id: 4185,
              first_name: "Yocasta",
              middle_name: "",
              last_name: "Perez",
              nickname: "",
            },
            created_user_id: 4185,
            current_rate: null,
            current_rate_type: null,
            deleted_at: null,
            effective_date: "2021-07-27",
            file: "",
            id: 5,
            job: {
              id: 752,
              job_number: 50581,
              job_description: "Cleveland Clinic - Hospital",
              site_number: null,
            },
            job_id: 752,
            job_new: null,
            new_job_id: null,
            new_rate: null,
            new_rate_type: null,
            requestor_type: 1,
            requestor_user_id: 4185,
            schedule: '""',
            updated_at: "2021-08-05 10:54:35",
            user: {
              id: 4193,
              first_name: "Winston",
              middle_name: "R",
              last_name: "Williams",
              nickname: null,
            },
            user_id: 4193,
          },
        ],
      });
      dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns() });
    }
  }, [topTab]);
  const handleTabChange = (event, value) => {
    let topTab =
      value === 1
        ? "survey-frontline?location"
        : value === 2
        ? "survey-frontline?completed"
        : "";
    const { history } = props;

    if (topTab === "survey-frontline?location") {
      dispatchTable({ type: "SET_SELECTED", selected: [] });
    }
    setTopTab(topTab);
    history.push(`/${topTab}`);
    setTimeout(() => {
      setTabUpdated({ ...tabUpdated, [value]: 0 });
    }, 5000);
  };

  return (
    <Box className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        open={surveySelection}
        onClose={() => {
          setSurveyDropdown("");
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography className={classes.createNewSurvey}>
            <span className={classes.permissionIconSurvey}>
              <AssignmentIcon />
            </span>
            Select Survey
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.surveyContainerDialog}>
            <Grid container>
              <Box className={classes.formSelector}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subSubTitle}>
                    Survey
                  </Typography>
                  <FormSelectAuto
                    options={[]}
                    name="form_select"
                    handleChange={(e, v) => {
                      setSurveyDropdown(v);
                      setSurveySelected(v?.value);
                    }}
                    value={surveyDropdown}
                    inputContainerProps={classes.inputContainer}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.delDialogBtnCenter}>
          <Button
            // autoFocus
            onClick={() => {
              setSurveySelection(false);
              setSurveyDropdown("");
            }}
            //className={classNames(classes.button, classes.buttonSecondary)}
            className={classes.markFilledButton}
          >
            Cancel
          </Button>

          <Button
            // autoFocus
            onClick={() => {
              setSurveySelection(false);
              setSurveyDropdown("");
            }}
            className={classNames(classes.button, classes.buttonSecondary)}
            // className={classes.markFilledButton}
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>

      {!surveyList?.data && false ? (
        <LoadingIndicator />
      ) : (
        <Box className={classes.root}>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleTabChange}>
              <StyledTabs
                value={
                  topTab === "survey-frontline?location"
                    ? 1
                    : topTab === "survey-frontline?completed"
                    ? 2
                    : 3
                }
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Location
                      {tabUpdated[1] > 0 && (
                        <span className={classes.animationText}>
                          <DotIcon className={classes.newIndicator} />+
                          {tabUpdated[1]}
                        </span>
                      )}
                    </Box>
                  }
                  key={1}
                  value={1}
                />
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Completed
                      {tabUpdated[2] > 0 && (
                        <span className={classes.animationText}>
                          <DotIcon className={classes.newIndicator} />+
                          {tabUpdated[2]}
                        </span>
                      )}
                    </Box>
                  }
                  key={2}
                  value={2}
                />
              </StyledTabs>
            </TableTabs>

            <Box className={classes.contentCounter}>
              {tab === 11 ? (
                <Counter>{`${filteredRes.survey?.length || 0} / ${
                  filteredRes.filter_counts || 0
                }`}</Counter>
              ) : (
                <Counter>{`${surveyList?.data?.length || 1} / ${
                  surveyList?.data?.length || 1
                }`}</Counter>
              )}
            </Box>

            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  {
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={getColumns()}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                      hasSelect={false}
                    >
                      <EnhancedTableToolbar>
                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "block" : "none",
                          }}
                        >
                          <ToolbarSelected>
                            <SurveyBulkActions
                              selected={selected}
                              setStatusModal={setStatusModal}
                              setSelectedRowData={setSelectedRowData}
                            />
                          </ToolbarSelected>
                        </Grid>

                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "none" : "flex",
                          }}
                        >
                          <Grid
                            item
                            sm={2}
                            xs={12}
                            md={2}
                            style={{ minWidth: "290px" }}
                          >
                            <ToolbarDefault
                              handleSearch={handleSearch}
                              placeholderSearch={"Search"}
                              handleSearchClear={handleSearchClear}
                            ></ToolbarDefault>
                          </Grid>

                          <Grid item sm={2} xs={12} md={1}></Grid>
                          <Grid item sm={4} xs={12} md={5} />
                          <Grid
                            item
                            sm={4}
                            xs={12}
                            md={3}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <DateRangePickerComponent />
                            <SurveyFilters topTab={topTab} />
                          </Grid>
                        </Grid>
                      </EnhancedTableToolbar>
                      {(filterState.filters ||
                        filterState.startDateActive ||
                        filterState.endDateActive) && <FiltersAppliedList />}
                    </Table>
                  }
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withRouter(SurveySurfaceTable);
