import React, { lazy, Suspense } from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "components/common/PageHeader/pageHeader";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { PartnerProvider } from "contexts/partnerContext";
import { PartnerActionProvider } from "contexts/partnerActionContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const PartnersTable = lazy(() => import("./partnersTable"));

const PartnersList = (props) => {
  const classes = useStyles();

  return (
    <>
      {<PageHeader pageTitle="Partners" link="/partners" />}
      <Box className={classes.pageBodyWrapper}>
        <PartnerProvider>
          <PartnerActionProvider>
            <Suspense
              fallback={
                <LoadingStateHorizontal
                  isVisible
                  style={classes.centerLoading}
                />
              }
            >
              <PartnersTable />
            </Suspense>
          </PartnerActionProvider>
        </PartnerProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(PartnersList);
