import React, { useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import { saveWorkticketBulkAssign } from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useWorkticketState,
  useWorkticketDispatch,
  getWorkticketListDataSet,
} from "contexts/workticketContext";
import { useFilterState, getFilterData } from "contexts/filterContext";

import { numberToWord } from "components/util/stringFormat";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const AssignMultipleDialog = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [assignedTo, setAssignedTo] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const { workticketList, workticketCount } = useWorkticketState();
  const dispatchWorktickets = useWorkticketDispatch();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openAssignMultiple } = workticketAction;
  const { currentTab, selected } = tableUi;
  const filterState = useFilterState();

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_ASSIGN_MULTIPLE",
      open: false,
    });
    setErrorMsg(null);
  };

  const handleConfirm = async () => {
    const assignedUser = [];
    assignedTo.map((item) => {
      return assignedUser.push(item.id);
    });

    if (!assignedUser.length) {
      setError([
        {
          key: "users",
          message: "Please select at least one user to assign.",
        },
      ]);
      return;
    }

    const data = {
      user_ids: assignedUser,
      workticket_ids: selected,
    };

    if (selected.length) {
      try {
        setIsLoadingDialog(true);
        // Replace with bulk assign
        const result = await saveWorkticketBulkAssign(data);

        if (result) {
          if (currentTab === 50) {
            const notScheduleStatus = 10;
            // On Not Assigned tab make the transition
            dispatchTable({
              type: "SET_TAB_UPDATED",
              tabUpdated: { tab: notScheduleStatus, count: selected.length },
            });
            // Update content on current tab and schedule tab
            const filterData = getFilterData(filterState);

            // Not Schedule tab
            workticketList[notScheduleStatus] = [];
            await getWorkticketListDataSet(
              workticketList,
              dispatchWorktickets,
              notScheduleStatus,
              0,
              filterData
            );
            workticketCount[notScheduleStatus] += selected.length;

            // Current Tab
            workticketList[currentTab] = [];
            await getWorkticketListDataSet(
              workticketList,
              dispatchWorktickets,
              currentTab,
              0,
              filterData
            );
            workticketCount[currentTab] -= selected.length;

            const workticketDataUpdate = workticketList[currentTab]
              ? workticketList[currentTab]
              : [];
            dispatchTable({ type: "UPDATE_ROWS", rows: workticketDataUpdate });
            // Mark updated fields
            dispatchTable({ type: "SET_UPDATED_ROWS", rowsUpdated: selected });
            // Clear selections on table
            dispatchTable({ type: "SET_SELECTED", selected: [] });

            dispatchWorktickets({
              type: "SET_WORKTICKET_COUNT",
              workticketCount: workticketCount,
            });
          }
          setAssignedTo([]);
          handleClose();
          setIsLoadingDialog(false);
          setOpenSuccess(true);
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setIsLoadingDialog(false);
          setAssignedTo([]);
          setErrorMsg(e.response.data.message);
        }
        logException(e, "Cannot assign worktickets.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    setAssignedTo(value);
    setError([]);
  };

  return (
    <>
      <Dialog
        open={openAssignMultiple}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              {errorMsg ? (
                <>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.contentDialog}
                  >
                    {errorMsg}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.titleDialog}
                  >
                    Worktickets
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.contentDialog}
                  >
                    Do you want to assign these {numberToWord(selected.length)}{" "}
                    ({selected.length}) workticket
                    {selected.length > 1 ? "s" : ""}?
                  </Typography>
                  <Box className={classes.formContainer}>
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users.filter(
                        (u) => u.tier_classification !== "subcontractor_admin"
                      )}
                      name="users"
                      label=""
                      handleChange={handleChangeAssignedTo}
                      value={assignedTo}
                      multiple={true}
                      error={error}
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              {!errorMsg ? (
                <>
                  <Button
                    onClick={handleClose}
                    className={classes.buttonOutlined}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="large"
                  color="secondary"
                  className={classes.buttonOutlined}
                  style={{ marginRight: 0 }}
                >
                  Okay
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket${
          selected.length > 1 ? "s" : ""
        } assignments are being processed and will be complete in a few minutes.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default AssignMultipleDialog;
