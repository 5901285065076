import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import RolesTable from "./rolesTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";

import useStyles from "./styles";

const RolesList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Roles" link="/roles"></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <RolesTable />
        </ListProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(RolesList);
