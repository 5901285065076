import React, { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IndividualViewBreadcrum from "./individualViewBreadcrum";
import IndividualViewInformation from "./individualViewInformation";
import IndividualViewTabs from "./individualViewTabs";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import TabIcon from "@material-ui/icons/Storage";
import CollapseIcon from "@material-ui/icons/FirstPage";
import UnCollapsed from "@material-ui/icons/LastPage";
import { getDetailForUser } from "services/teamService";
import { useIndividualView } from "contexts/individualViewContext";
import EmptyScreen from "components/ui/core/emptyScreen";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import { hasPermission, permissionTalent } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

const IndividualViewLayout = (props) => {
  const classes = useStyles();
  const [valueBottomNavigation, setBottomNavigation] = useState("info");
  const { globalUi } = useContext(GlobalUiContext);
  const [isMobile, setIsMobile] = useState(false);
  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const context = useIndividualView();
  const dispatchContext = context[1];
  const { permissions } = globalUi;

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setCollapseSidebar(false);
    }
    window.addEventListener("resize", resize.bind(this));
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
    if (window.innerWidth <= 960 && collapseSidebar) {
      setCollapseSidebar(false);
    }
  };

  useEffect(() => {
    const loadIndividualView = async () => {
      try {
        if (props.id !== "new") {
          const result = await getDetailForUser(props.id);
          dispatchContext({
            type: "SET_INDIVIDUAL",
            individual: result.data.data.user,
          });
          dispatchContext({
            type: "SET_LOADING",
            isLoading: false,
          });
        }
      } catch (e) {
        logException(e, "Cannot load Individual View data");
      }
    };
    loadIndividualView();
  }, [props.id, dispatchContext]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  const classSidebar = !isMobile
    ? collapseSidebar
      ? classes.collapseInfo
      : classes.unCollapseInfo
    : null;

  const classMainContent = !isMobile
    ? collapseSidebar
      ? classes.collapseSidebar
      : classes.unCollapseSidebar
    : null;

  if (!hasPermission(permissionTalent.INDIVIDUAL_VEW, permissions)) {
    return (
      <>
        <IndividualViewBreadcrum />
        <EmptyScreen type={2} />
      </>
    );
  }

  return (
    <>
      <IndividualViewBreadcrum />
      <Grid container className={classes.root}>
        {(!isMobile || (isMobile && valueBottomNavigation === "info")) && (
          <Grid item xs={12} sm={12} md={3} className={classSidebar}>
            <Box className={classes.containerMain}>
              {!isMobile ? (
                !collapseSidebar ? (
                  <CollapseIcon
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(true)}
                  />
                ) : (
                  <UnCollapsed
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(false)}
                  />
                )
              ) : null}
              {!collapseSidebar ? <IndividualViewInformation /> : null}
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "tabs")) && (
          <Grid item xs={12} sm={12} md={9} className={classMainContent}>
            <IndividualViewTabs />
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="tabs" icon={<TabIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default IndividualViewLayout;
