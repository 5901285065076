import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ProjectIcon from "@material-ui/icons/Work";
import { ButtonBase } from "components/ui/core/button";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useJobsDispatch } from "contexts/projectJobsContext";
import { useProjectView } from "contexts/projectViewContext";
import { removeJobsProjectPreventative } from "services/projectService";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  actionsDialogWrapper: {
    justifyContent: "center",
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 11,
    height: 11,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
}));

const options = [{ id: 1, label: "Delete", value: "DELETE" }];

const JobsTableActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stateContextProject] = useProjectView();
  const dispatchJobs = useJobsDispatch();
  const { project } = stateContextProject ?? null;
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");

    if (action === "DELETE") {
      setOpenDelete(true);
    }
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      const dataRemove = {
        job_ids: [row.id],
      };

      const result = await removeJobsProjectPreventative(
        project.id,
        dataRemove
      );

      const projectUpdate = result.data.data.project;
      console.log("projectUpdate", projectUpdate);
      dispatchJobs({
        type: "SET_JOBS_LIST",
        jobList: projectUpdate?.preventative_jobs ?? [],
      });
      dispatchJobs({
        type: "SET_JOBS_COUNT",
        jobCount: projectUpdate?.preventative_jobs.length ?? 0,
      });

      setOpenDelete(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {openDelete ? (
        <Dialog
          open={openDelete}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleDeleteClose}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            <ProjectIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Delete Job
            </Typography>
            <Typography variant="body2" gutterBottom>
              Deleting the job will also remove all the service related to the
              job, please confirm.
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapper}>
            <ButtonBase
              onClick={handleDeleteClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              onClick={handleDeleteConfirm}
              color="secondary"
              disabled={isLoading}
            >
              Confirm
            </ButtonBase>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default withRouter(JobsTableActions);
