import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import useStyles from "components/common/Filters/styles";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const typeOptions = [
  { value: 1, label: "Team" },
  { value: 2, label: "Private" },
];

const NoteFilters = (props) => {
  const classes = useStyles();
  const [typeFilters, setTypeFilters] = useState([]);
  const [jobFilters, setJobFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [includeChild] = useState(true);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { isDashboard } = props;

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length && !isDashboard) {
      loadJob();
    }
  }, [anchorEl, jobs, isDashboard]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }

      const selectedTypeFilter = filters.find(
        (filter) => filter.filter === "type"
      );
      if (selectedTypeFilter) {
        setTypeFilters(selectedTypeFilter.values);
      } else {
        setTypeFilters([]);
      }
    }
  }, [anchorEl, filters, jobs]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    if (typeFilters.length) {
      filterData.push({
        filter: "type",
        group: "Type",
        values: [...typeFilters],
      });
    }

    if (jobFilters.length) {
      // Map label and value with job data
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
          id: job.id,
          job_type: job?.job_type,
          parent_job_id: job?.parent_job_id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    if (!includeChild) {
      setJobFilters(value);
      return;
    }

    let mode = "add";
    if (value.length < jobFilters.length) {
      mode = "remove";
    }

    if (mode === "add") {
      const jobCurrent = jobFilters.map((job) => job.id);
      const jobNew = value.find((job) => !jobCurrent.includes(job.id));
      let children = [];
      if (jobNew && Number(jobNew?.job_type) === 1) {
        children = jobs.filter(
          (job) => Number(job?.parent_job_id) === Number(jobNew?.id)
        );
      }
      setJobFilters([...value, ...children]);
      return;
    }

    if (mode === "remove") {
      const jobCurrent = value.map((job) => job.id);
      const jobRemove = jobFilters.find((job) => !jobCurrent.includes(job.id));
      let valueNew = value;
      if (jobRemove && Number(jobRemove?.job_type) === 1) {
        valueNew = value.filter(
          (job) => Number(job?.parent_job_id) !== Number(jobRemove?.id)
        );
      }
      setJobFilters(valueNew);
      return;
    }
  };

  const handleChangeType = (event, value) => {
    setTypeFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          {!isDashboard ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Job
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
                {Boolean(jobs.length) ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job_number"
                    label="Job"
                    internal={true}
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={jobFilters}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={typeOptions}
                name="type"
                internal={true}
                handleChange={handleChangeType}
                multiple={true}
                value={typeFilters}
              />
            </Grid>
          </Grid>

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default NoteFilters;
