import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import FieldContainerFilter from "components/common/Filters/fieldContainerFilter";
import useStyles from "components/common/Filters/styles";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import {
  getFilterCustomers,
  getFilterJobs,
  getFilterManagers,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import { useFilterDispatch, useFilterState } from "contexts/filterContext";
import { getPositionTitle } from "services/openPositionsService";

const OpenPositionsFilters = (props) => {
  const classes = useStyles();
  const moduleName = window.location.pathname.split("/")[1];
  const filterPersisted = JSON.parse(
    localStorage.getItem(`filter_${moduleName}`)
  );
  const [jobFilters, setJobFilters] = useState([]);
  const [positionFilters, setPositionFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [managerFilters, setManagerFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState(null);
  const [openPositions, setOpenPositions] = useState(null);

  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!props.hideJobs) {
      let temp = filterPersisted?.map((v) => {
        if (v.filter === "customer") {
          setCustomerFilters([...v.values]);
        }
        if (v.filter === "manager") {
          setManagerFilters([...v.values]);
        }
        if (v.filter === "job") {
          setJobFilters([...v.values]);
        }
        if (v.filter === "position_title") {
          setPositionFilters([...v.values]);
        }
      });
    }
  }, []);

  useEffect(() => {
    getPositionTitle().then((res) => {
      const transformed = res?.data?.data?.open_positions_titles?.map((v) => ({
        label: v?.name,
        value: v?.number,
      }));

      setOpenPositions(transformed);
    });
  }, []);

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs(true);

        const transformed = resultForRender?.map((v) => ({
          label: `${v?.job_number} - ${v?.job_description}`,
          value: v?.id,
        }));
        setJobs(transformed);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length && !props.hideJobs) {
      loadJob();
    }
  }, [anchorEl, jobs]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (anchorEl && !customers.length) {
      loadCustomer();
    }
  }, [anchorEl, customers]);

  useEffect(() => {
    const loadManagers = async () => {
      try {
        const resultForRender = await getFilterManagers();
        setManagers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load managers data");
      }
    };
    if (anchorEl && !managers) {
      loadManagers();
    }
  }, [anchorEl, managers]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length && !props.hideJobs) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "job"
        );
        if (selectedJobFilter) {
          setJobFilters(selectedJobFilter.values);
        } else {
          setJobFilters([]);
        }
      }
      if (customers.length) {
        const selectedCustomersFilter = filters.find(
          (filter) => filter.filter === "customer"
        );
        if (selectedCustomersFilter) {
          setCustomerFilters(selectedCustomersFilter.values);
        } else {
          setCustomerFilters([]);
        }
      }
      if (managers) {
        const selectedManagersFilter = filters.find(
          (filter) => filter.filter === "manager"
        );
        if (selectedManagersFilter) {
          setManagerFilters(selectedManagersFilter.values);
        } else {
          setManagerFilters([]);
        }
      }
    }
  }, [anchorEl, filters, jobs, customers.length, managers]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };
  const handleChangePosition = (event, value) => {
    setPositionFilters(value);
  };

  const handleApply = async () => {
    const filterData = [];
    if (customerFilters.length) {
      filterData.push({
        filter: "customer",
        group: "Customer",
        values: [...customerFilters],
      });
    }

    if (jobFilters.length) {
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilters],
      });
    }

    if (managerFilters.length) {
      filterData.push({
        filter: "manager",
        group: "Manager",
        values: [...managerFilters],
      });
    }

    if (positionFilters.length) {
      filterData.push({
        filter: "position_title",
        group: "Position",
        values: [...positionFilters],
      });
    }

    if (props.hideJobs) {
      const currentFilter = filters.find((v) => v.filter === "job");
      if (currentFilter) {
        filterData.push(currentFilter);
      }
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    if (!props.hideJobs) {
      localStorage.setItem(
        `filter_${window.location.pathname.split("/")[1]}`,
        JSON.stringify(filterData)
      );
    }
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    setJobFilters(value);
  };

  const handleChangeCustomer = (event, value) => {
    setCustomerFilters(value);
  };

  const handleChangeManager = (event, value) => {
    setManagerFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <FieldContainerFilter
            title="Customer"
            isLoading={!Boolean(customers.length)}
          >
            <FormSelectAuto
              options={customers}
              name="customer"
              handleChange={handleChangeCustomer}
              multiple={true}
              value={customerFilters}
            />
          </FieldContainerFilter>
          {!props.hideJobs && (
            <FieldContainerFilter title="Job" isLoading={!Boolean(jobs.length)}>
              <FormSelectAuto
                options={jobs}
                name="id"
                label="Job"
                handleChange={handleChangeJob}
                multiple={true}
                value={jobFilters}
              />
            </FieldContainerFilter>
          )}

          <FieldContainerFilter title="Manager" isLoading={!Boolean(managers)}>
            <FormSelectAuto
              options={managers}
              name="manager"
              handleChange={handleChangeManager}
              multiple={true}
              value={managerFilters}
            />
          </FieldContainerFilter>
          <FieldContainerFilter title="Position Title">
            <FormSelectAuto
              options={openPositions}
              gridSizes={[{ size: "md", val: 12 }]}
              name="tier_classification"
              internal={true}
              handleChange={handleChangePosition}
              multiple={true}
              value={positionFilters}
            />
          </FieldContainerFilter>

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default OpenPositionsFilters;
