import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useCompletedDispatch } from "contexts/surveyCompletedContext";
import { exportResumePdf } from "services/surveyFrontlineService";

const ResultsTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchCompleted = useCompletedDispatch();
  const open = Boolean(anchorEl);
  const { row } = props;

  const options = [
    {
      id: 1,
      label: "View",
      value: "VIEW",
    },
    {
      id: 2,
      label: "Export to PDF",
      value: "PDF",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const exportPdfResult = async () => {
    try {
      const result = await exportResumePdf(row.id);
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (e) {
      console.log("Cannot download pdf file.");
    }
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    switch (action) {
      case "VIEW":
        dispatchCompleted({
          type: "TOGGLE_OPEN_VIEW",
          openView: true,
        });
        break;
      case "PDF":
        exportPdfResult();
        break;
      default:
        break;
    }
    dispatchCompleted({
      type: "SET_COMPLETED_SELECTED",
      completedSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ResultsTableActions;
