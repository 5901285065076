import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import UserTableActions from "./userTableActions";
import UsersListActions from "./usersListActions";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { getUsers } from "services/superService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "User ID",
    format: "id",
    path: "user",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "first_name",
    label: "First Name",
  },
  {
    id: "last_name",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "role_name",
    label: "Role",
  },
  {
    id: "validated",
    label: "Valid",
  },
  {
    id: "role_category",
    label: "Type",
  },
  {
    id: "employee_number",
    label: "Emp/Sub ID",
    style: { textAlign: "center" },
  },
  {
    id: "actions",
    content: (row) => <UserTableActions row={row} />,
    contentMobile: (row) => <UserTableActions row={row} mobile={true} />,
  },
];

const TabOptions = [
  { id: "all", label: "All", tab: "All" },
  { id: "active", label: "Active", tab: "Active" },
  { id: "pending", label: "Pending", tab: "Pending" },
];

const PAGE_ITEMS = 100;

function useUsers() {
  return useQuery("users", async () => {
    const { data } = await getUsers();
    return data;
  });
}

const UsersTable = (props) => {
  const classes = useStyles();
  const [tablePointer, setTablePointer] = useState(PAGE_ITEMS);
  const [loadingMore, setLoadingMore] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedSubTab, setSelectedSubTab] = useState("All");
  const { list } = useListState();
  const { selected, rows } = useTableState();
  const { searchString, filters } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  const { data, isLoading } = useUsers();

  useEffect(() => {
    const fetchData = () => {
      dispatchList({
        type: "SET_LIST_DATA",
        list: data?.data.users,
        count: data?.data?.users?.length ?? 0,
        listLoading: false,
      });
    };

    if (!isLoading) {
      fetchData();
    }
  }, [dispatchList, isLoading, data]);

  useEffect(() => {
    if (!isLoading) {
      if (selectedSubTab === "All") {
        setListData([...list]);
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: list?.slice(0, tablePointer) ?? [],
        });
      } else {
        const filteredList =
          list.filter((user) => user.validated === selectedSubTab) ?? [];
        setListData([...filteredList]);
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: filteredList?.slice(0, tablePointer) ?? [],
        });
      }

      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
  }, [dispatchTable, list, isLoading, tablePointer, selectedSubTab]);

  const handleScrollClick = useCallback(async () => {
    if (searchString || listData.length <= tablePointer) {
      return;
    }

    try {
      setLoadingMore(true);
      const nextPointer = tablePointer + PAGE_ITEMS;
      const updateTablePointer =
        nextPointer < listData?.length ? nextPointer : listData?.length;
      setTablePointer(updateTablePointer);
    } catch (e) {
      logException(e, "Cannot load more data");
    }
  }, [tablePointer, listData?.length, searchString]);

  const handleRowClick = (event, row) => {};

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const queryNormalize = query.toLowerCase().replace(/^\s+/g, "");
        const resultSearch = listData.filter(
          (user) =>
            user.first_name?.toLowerCase().startsWith(queryNormalize) ||
            user.last_name?.toLowerCase().startsWith(queryNormalize) ||
            user.email?.toLowerCase().startsWith(queryNormalize) ||
            user.employee_number?.toLowerCase().startsWith(queryNormalize)
        );
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: resultSearch,
        });
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: listData.slice(0, tablePointer),
    });
  };

  const handleTabChange = (event, value) => {
    const { history } = props;
    history.push(`/${value}`);
  };

  const handleSubTabChange = (event, value) => {
    setSelectedSubTab(value);
    setTablePointer(PAGE_ITEMS);
  };

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>
                {!searchString
                  ? `${rows.length} / ${listData.length}`
                  : rows.length}
              </Counter>
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={"users"}>
              {[
                { value: "users", label: "User Management" },
                { value: "roles", label: "Role Management" },
              ].map((tabItem) => (
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>{tabItem.label}</Box>}
                  key={tabItem.value}
                  value={tabItem.value}
                />
              ))}
            </TableTabs>
            <TableTabs
              handleTabChange={handleSubTabChange}
              value={selectedSubTab}
            >
              {TabOptions.map((tabItem) => (
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>{tabItem.label}</Box>}
                  key={tabItem.id}
                  value={tabItem.label}
                />
              ))}
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected />
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          placeholderSearch={"All Users"}
                          handleSearchClear={handleSearchClear}
                        >
                          <UsersListActions />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {filters ? <FiltersAppliedList /> : null}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(UsersTable);
