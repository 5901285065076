import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: 320,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#979797",
  },
  iconClose: {
    fontSize: 16,
  },
  drawerContent: {
    marginTop: 20,
  },
  sectionSettings: {
    padding: 20,
    borderBottom: "1px solid #D8D8D8",
  },
  titleSettings: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  itemSettings: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  itemTitleSettings: {
    fontSize: 14,
    fontWeight: "normal",
  },
  itemSettingsName: {
    flex: 1,
    textAlign: "left",
  },
  itemInnerImage: {
    width: 40,
  },
  breakdownTitleActions: {
    fontSize: 14,
    fontWeight: "normal",
  },
  breakdownActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  breakdownItem2: {
    paddingLeft: 8,
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& > div": {
      flex: 1,
    },
  },
  breakdownItem3: {
    paddingLeft: 32,
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& > div": {
      flex: 1,
    },
  },
  breakdownItemDate: {
    paddingLeft: 64,
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& > div": {
      flex: 1,
    },
  },
  breakdownButtonActions: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    marginRight: 8,
    top: -2,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8,
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.6,
    },
    "& svg": {
      fontSize: 16,
    },
  },
  breakdownButtonRemove: {
    backgroundColor: "#747474",
    color: "#ffffff",
    marginRight: 8,
    top: -2,
    "&:hover": {
      backgroundColor: "#747474",
      opacity: 0.8,
    },
    "&:focus": {
      backgroundColor: "#747474",
      opacity: 0.6,
    },
    "& svg": {
      fontSize: 16,
    },
  },
}));

export default useStyles;
