import React from "react";
import RoleLayout from "./roleLayout";
import { RoleViewProvider } from "../../contexts/roleViewContext";

const Role = (props) => {
  return (
    <RoleViewProvider>
      <RoleLayout id={props.match.params.id} {...props} />
    </RoleViewProvider>
  );
};

export default Role;
