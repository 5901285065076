import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonStart: {
    backgroundColor: "#4f98bc",
    maxWidth: "100%",
  },
  buttonStop: {
    backgroundColor: "#ff0000",
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: "#dd0000",
    },
  },
  textTimer: {
    fontSize: 18,
    fontWeight: "normal",
    marginTop: 5,
    marginLeft: 5,
  },
  iconLarge: {
    fontSize: 36,
  },
}));

export default useStyles;
