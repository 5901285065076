import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentDialog: {
    fontSize: 14,
  },
}));

export const DialogMessage = (props) => {
  const classes = useStyles();
  const { children, align } = props;

  return (
    <Typography
      variant="body1"
      gutterBottom
      className={classes.contentDialog}
      align={align}
    >
      {children}
    </Typography>
  );
};
