import React, { useContext, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "components/common/Filters/styles";
import { getPreventativeServices } from "services/projectService";
import {
  getCategories,
  getCategoriesCustomers,
} from "services/categoryService";
import { useProjectView } from "contexts/projectViewContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const recurrenceOptionsFilter = [
  { value: "days", label: "Daily" },
  { value: "weeks", label: "Weekly" },
  { value: "months", label: "Monthly" },
];

const frequencyFilter = [
  { value: "one-time", label: "One-Time" },
  { value: "recurring", label: "Recurring" },
];

const ServicesFilters = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [typeFilters, setTypeFilters] = useState([-1]);
  const [frequencyFilters, setFrequencyFilters] = useState("");
  const [stateContextProject] = useProjectView();
  const [usersList, setUsersList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [serviceCategories, setServiceCategory] = useState([]);
  const [jobFilters, setJobFilters] = useState([]);
  const [assignedToFilters, setAssignedToFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const { project } = stateContextProject ?? null;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setUsersList([{ id: -1, first_name: "N/A", last_name: "" }, ...users]);
  }, [users]);

  useEffect(() => {
    const foundType = filters.find((element) => element.filter === "interval");

    if (foundType) {
      const selectedItem = foundType.values
        .filter((item) => item.value !== "one-time")
        .map((item) => item.value);
      if (selectedItem.length) {
        setTypeFilters([...selectedItem]);
      }
    } else {
      setTypeFilters([-1]);
    }
  }, [filters]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const resultService = await getPreventativeServices(project.id);
        setJobs(resultService.data.data.jobs);
        let resultServiceCategories = null;
        if (project?.is_external) {
          resultServiceCategories = await getCategoriesCustomers(
            project.customer.id
          );
        } else {
          resultServiceCategories = await getCategories();
        }

        const resultForRender =
          resultServiceCategories.data.data.categories.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
        setServiceCategory(resultForRender);
      } catch (e) {
        logException(e, "Cannot load project preventative jobs");
      }
    };

    loadData();
  }, [project]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    let valuesType = [];

    typeFilters.forEach((item) => {
      const foundOption = recurrenceOptionsFilter.find(
        (option) => option.value === item
      );
      if (foundOption && item !== -1) {
        valuesType.push(foundOption);
      }
    });

    const filterData = [];
    if (frequencyFilters?.value) {
      if (frequencyFilters.value === "recurring" && valuesType.length) {
        filterData.push({
          filter: "interval",
          group: "Interval",
          values: [...valuesType],
        });
      } else if (frequencyFilters.value === "one-time") {
        filterData.push({
          filter: "interval",
          group: "Interval",
          values: [
            { value: frequencyFilters?.value, label: frequencyFilters?.label },
          ],
        });
      }
    }

    if (jobFilters.length) {
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    if (assignedToFilters.length) {
      const assignedToFilterConvert = assignedToFilters.map((assigned) => ({
        label: `${assigned.first_name} ${assigned.last_name}`,
        value: assigned.id,
      }));
      filterData.push({
        filter: "user",
        group: "Assigned To",
        values: [...assignedToFilterConvert],
      });
    }

    if (categoryFilters.length) {
      filterData.push({
        filter: "category",
        group: "Category",
        values: categoryFilters,
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });

    handleClose();
  };

  const handleChangeType = (e, option) => {
    if (e.target.checked) {
      if (option === -1) {
        setTypeFilters([-1]);
      } else {
        if (typeFilters.includes(-1)) {
          typeFilters.splice(typeFilters.indexOf(-1), 1);
        }
        setTypeFilters([...typeFilters, option]);
      }
    } else {
      typeFilters.splice(typeFilters.indexOf(option), 1);
      if (typeFilters.length) {
        setTypeFilters([...typeFilters]);
      }
    }
  };

  const handleChangeJob = (event, value) => {
    setJobFilters(value);
  };

  const handleChangeAssignedTo = (event, value) => {
    setAssignedToFilters(value ?? []);
  };

  const handleChangeService = (event, value) => {
    setCategoryFilters(value ?? []);
  };

  const handleChangeFrequency = (event, value) => {
    setFrequencyFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Frequency
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.wrapperTeamMember}
                >
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={frequencyFilter}
                    name="frequency"
                    handleChange={handleChangeFrequency}
                    value={frequencyFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {frequencyFilters?.value === "recurring" ? (
            <Grid container spacing={1} className={classes.filterGroup}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h4"
                  className={classes.titleFilter}
                  gutterBottom
                >
                  Type
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  {recurrenceOptionsFilter.map((option) => {
                    return (
                      <Grid item xs={6} md={6} key={option.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={typeFilters.includes(option.value)}
                              onChange={(e) =>
                                handleChangeType(e, option.value)
                              }
                              className={classes.filterCheckbox}
                            />
                          }
                          label={option.label}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Job
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              {Boolean(jobs.length) ? (
                <FormSelectJobsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={jobs}
                  name="job_number"
                  label="Job"
                  internal={true}
                  handleChange={handleChangeJob}
                  multiple={true}
                  value={jobFilters}
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Assigned To
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.wrapperTeamMember}
                >
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={usersList}
                    name="assigned_to"
                    label=""
                    handleChange={handleChangeAssignedTo}
                    multiple={true}
                    value={assignedToFilters}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={serviceCategories}
                name="service_category"
                label="Service Category*"
                value={categoryFilters}
                handleChange={handleChangeService}
                multiple={true}
              />
            </Grid>
          </Grid>

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default ServicesFilters;
