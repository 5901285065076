import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ThreeDots from "../../../icons/ThreeDots";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import EditIcon from "@material-ui/icons/Edit";
import React, { Fragment, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useGeoCoverage } from "./useGeoCoverage";
import ChipList from "../../../../partnersList/chipList";
import DeleteCoverageDialogue from "./DeleteCoverageDialogue";

export function GeographicalAccordion({ classes, accordionIndex, item }) {
  const {
    handleClick,
    handleChange,
    expanded,
    handleEditDrawer,
    anchorEl,
    handleClose,
    submitDelete,
    loading,
  } = useGeoCoverage();

  const [isGeoDialogueOpen, setIsGeoDialogueOpen] = useState(false);

  const menuOptions = [
    {
      label: "Edit",
      icon: <EditIcon fontSize="inherit" />,
      onClick: () => handleEditDrawer(item),
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        setIsGeoDialogueOpen(true);
        handleClose();
      },
    },
  ];

  return (
    <Fragment key={accordionIndex}>
      <Accordion
        classes={{ root: classes.accordionRoot }}
        key={accordionIndex}
        expanded={expanded}
        onChange={handleChange(accordionIndex)}
      >
        <AccordionSummary
          key={accordionIndex}
          expandIcon={
            <ExpandMoreIcon
              className={`${classes.expandIcon} ${
                expanded ? classes.expandIconExpanded : ""
              }`}
            />
          }
        >
          <Chip
            className={
              expanded ? classes.chipExpanded : classes.chipNotExpanded
            }
            label={item?.name}
            variant="outlined"
          />
        </AccordionSummary>
        <AccordionDetails
          key={accordionIndex}
          className={classes.accordionDetailsContainer}
        >
          <Box>
            <Box className={classes.accordionCityTitleContainer}>
              <Typography className={classes.accordionDetailsSubtitle}>
                Cities
              </Typography>
              <Box
                onClick={(event) => handleClick(event, accordionIndex)}
                disableRipple={true}
                className={classes.threeDotButtonAccordion}
              >
                <ThreeDots />
              </Box>
            </Box>
            <Box className={classes.infoChipsContainer}>
              <ChipList
                items={item?.cities}
                maxItems={3}
                isArray={true}
                nameProp={"city_name"}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              style={{ marginBottom: "8px" }}
              className={classes.accordionDetailsSubtitle}
            >
              Zip Codes
            </Typography>
            <Box className={classes.infoChipsContainer}>
              <ChipList
                items={item?.zip_codes}
                maxItems={3}
                isArray={true}
                nameProp={"zip"}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Menu
        id="geo-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={index} disabled={loading} onClick={option.onClick}>
            <Box className={classes.menuIcon}>{option.icon}</Box>
            <Typography variant="caption" className={classes.menuText}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <DeleteCoverageDialogue
        isDeleteCoverageDialogueOpen={isGeoDialogueOpen}
        handleDeleteUserDialogueClose={() => setIsGeoDialogueOpen(false)}
        handleDeleteUser={() => {
          submitDelete(item);
        }}
      />
    </Fragment>
  );
}
