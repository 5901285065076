import React, { useState, useEffect, useMemo } from "react";
import TableCollapsible from "components/common/Table/tableCollapsible/tableCollapsible";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import {
  getCustomerParents,
  getCustomerChildrenJob,
} from "services/customerService";
import CustomersAdminTabs from "./customersAdminTabs";
import EnhancedTableToolbar from "components/common/Table/tableCollapsible/tableCollapsibleToolbar";
import {
  useCustomersState,
  useCustomersDispatch,
  getCustomersList,
  searchCustomersList,
} from "contexts/adminCustomersContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { useTableCollapsibleDispatch } from "contexts/tableCollapsibleContext";
import ToolbarDefault from "components/common/Table/tableCollapsible/tableCollapsibleToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import CustomersFilters from "./customersFilters";
import CustomersListActions from "./customersListActions";
import CustomerTableActions from "./customerTableActions";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import TableCollapsiblePagination from "components/common/Table/tableCollapsible/tableCollapsiblePagination";
import TableCollapsibleLoading from "components/common/Table/tableCollapsible/tableCollapsibleLoading";
import Typography from "@material-ui/core/Typography";
import { getCustomerMetadata } from "services/customerService";
import AlertDialog from "./dialog/alertDialog";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const header = [
  [
    {
      label: "Name",
      colSpan: 2,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Number",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Primary Director",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Primary Manager",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Labor Budget",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Supplies Budget",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Delivery Type",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Industry",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "Last Updated",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
    {
      label: "",
      colSpan: 1,
      style: {
        paddingTop: 16,
        borderBottom: "1px solid #CBCBCB",
        backgroundColor: "#ffffff",
      },
    },
  ],
];

const CustomersTable = (props) => {
  const classes = useStyles();
  const dispatchTable = useTableCollapsibleDispatch();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { customersListLoading, customersList, unassignedJobs, refreshList } =
    useCustomersState();
  const filterState = useFilterState();
  const dispatchCustomers = useCustomersDispatch();
  const dispatchFilters = useFilterDispatch();
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      const result = await getCustomerMetadata(1);
      const { delivery_types, industries } = result.data.data;
      if (delivery_types?.length) {
        const deliveryForRender = delivery_types.map((value) => {
          return { value: value.id, label: value.name };
        });
        setDeliveryTypes(deliveryForRender);
      }
      if (industries?.length) {
        const industriesForRender = industries.map((value) => {
          return { value: value.id, label: value.name };
        });
        setIndustries(industriesForRender);
      }
    };
    loadOptions();
  }, []);

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const columns = useMemo(
    () => [
      [
        {
          id: "name",
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
        },
        {
          id: "customer_number",
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_director",
          content: (row) =>
            parseInt(row.is_active) ? (
              <Box className={classes.rowContainer}>
                {row.primary_director ? (
                  <Typography variant="body2" noWrap>
                    {row.primary_director}
                  </Typography>
                ) : (
                  <AlertDialog message="There are missing assignments for this customer." />
                )}
                {row.primary_director &&
                (parseInt(row.no_director_alert) > 0 ||
                  parseInt(row.inactive_director_alert) > 0) ? (
                  <AlertDialog message="There are missing assignments for the jobs for this customer." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              </Box>
            ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_manager",
          content: (row) =>
            parseInt(row.is_active) ? (
              <Box className={classes.rowContainer}>
                {row.primary_manager ? (
                  <Typography variant="body2" noWrap>
                    {row.primary_manager}
                  </Typography>
                ) : (
                  <AlertDialog message="There are missing assignments for this customer." />
                )}
                {row.primary_manager &&
                (parseInt(row.no_manager_alert) > 0 ||
                  parseInt(row.inactive_manager_alert) > 0) ? (
                  <AlertDialog message="There are missing assignments for the jobs for this customer." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              </Box>
            ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "labor_budget",
          content: (row) =>
            parseInt(row.is_active) ? (
              <Box className={classes.rowContainer}>
                {row.labor_budget && row.labor_budget >= 0 ? (
                  <Typography
                    variant="body2"
                    className={classes.money}
                    color="inherit"
                    noWrap
                  >
                    {`${formatNumber(
                      parseFloat(row.labor_budget).toFixed(2)
                    )} hrs/week`}
                  </Typography>
                ) : parseInt(row.no_labor_alert) > 0 ? (
                  <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
                ) : (
                  <Box className={classes.rowContainer}>
                    <Typography variant="body2" noWrap>
                      N/A
                    </Typography>
                  </Box>
                )}
                {row.labor_budget &&
                row.labor_budget >= 0 &&
                parseInt(row.no_labor_alert) > 0 ? (
                  <AlertDialog message="There are missing labor budgets for the jobs for this customer." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              </Box>
            ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "supplies_budget",
          content: (row) =>
            parseInt(row.is_active) ? (
              <Box className={classes.rowContainer}>
                {row.supplies_budget && row.supplies_budget >= 0 ? (
                  <Typography
                    variant="body2"
                    className={classes.money}
                    color="inherit"
                    noWrap
                  >
                    {`$ ${formatNumber(
                      parseFloat(row.supplies_budget).toFixed(2)
                    )}
                  `}
                  </Typography>
                ) : parseInt(row.no_supplies_alert) > 0 ? (
                  <AlertDialog message="There are missing supplies budgets for the jobs for this customer." />
                ) : (
                  <Box className={classes.rowContainer}>
                    <Typography variant="body2" noWrap>
                      N/A
                    </Typography>
                  </Box>
                )}
                {row.supplies_budget &&
                row.supplies_budget >= 0 &&
                parseInt(row.no_supplies_alert) > 0 ? (
                  <AlertDialog message="There are missing supplies budgets for the jobs for this customer." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              </Box>
            ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "delivery",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery_id ? (
                <Typography variant="body2" noWrap>
                  {
                    deliveryTypes.find((item) => item.value === row.delivery_id)
                      ?.label
                  }
                </Typography>
              ) : parseInt(row.is_active) ? (
                <AlertDialog message="There are missing assignments for this customer." />
              ) : (
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              )}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "industry",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry_id ? (
                <Typography variant="body2" noWrap>
                  {
                    industries.find((item) => item.value === row.industry_id)
                      ?.label
                  }
                </Typography>
              ) : parseInt(row.is_active) ? (
                <AlertDialog message="There are missing assignments for this customer." />
              ) : (
                <Typography variant="body2" noWrap>
                  Inactive
                </Typography>
              )}
            </Box>
          ),
          style: {
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        { id: "updated_at", format: "date", style: { paddingLeft: 8 } },
        {
          id: "actions",
          content: (row) => <CustomerTableActions row={row} />,
        },
      ],
      [
        {
          name: "_expander",
          style: {
            backgroundColor: "#F9F9F9",
          },
        },
        {
          id: "job_description",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
          content: (row) => (
            <Box className={classes.jobNameContainer}>
              <Box className={classes.jobIndicatorParent}></Box>
              {row.job_description}
            </Box>
          ),
        },
        {
          id: "job_number",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "director",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_director ? (
                <Typography variant="body2" noWrap>
                  {row.primary_director}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this parent job." />
              )}
              {(parseInt(row.no_director_alert) > 0 ||
                parseInt(row.inactive_director_alert) > 0) &&
              row.primary_director ? (
                <AlertDialog message="There are missing assignments for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "manager",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_manager ? (
                <Typography variant="body2" noWrap>
                  {row.primary_manager}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this parent job." />
              )}
              {(parseInt(row.no_manager_alert) > 0 ||
                parseInt(row.inactive_manager_alert) > 0) &&
              row.primary_manager ? (
                <AlertDialog message="There are missing assignments for the jobs for this parent job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "labor_budget_all",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) =>
            row.has_labor_budget ? (
              <Box className={classes.rowContainer}>
                {row.labor_budget_all && row.labor_budget_all >= 0 ? (
                  <Typography
                    variant="body2"
                    className={classes.money}
                    color="inherit"
                    noWrap
                  >
                    {`${formatNumber(
                      parseFloat(row.labor_budget_all).toFixed(2)
                    )} hrs/week`}
                  </Typography>
                ) : (
                  <AlertDialog message="There are missing labor budget for this parent job." />
                )}
                {parseInt(row.no_labor_alert) > 0 &&
                row.labor_budget_all &&
                row.labor_budget_all >= 0 ? (
                  <AlertDialog message="There are missing labor budgets for the jobs for this parent job." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" color="inherit" noWrap>
                  N/A
                </Typography>
              </Box>
            ),
        },
        {
          id: "supplies_budget_all",
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) =>
            row.has_supply_budget ? (
              <Box className={classes.rowContainer}>
                {row.supplies_budget_all && row.supplies_budget_all >= 0 ? (
                  <Typography
                    variant="body2"
                    className={classes.money}
                    color="inherit"
                    noWrap
                  >
                    {`$ ${formatNumber(
                      parseFloat(row.supplies_budget_all).toFixed(2)
                    )}`}
                  </Typography>
                ) : (
                  <AlertDialog message="There are missing supplies budget for this parent job." />
                )}
                {parseInt(row.no_supplies_alert) > 0 &&
                row.supplies_budget_all &&
                row.supplies_budget_all >= 0 ? (
                  <AlertDialog message="There are missing supplies budgets for the jobs for this parent job." />
                ) : null}
              </Box>
            ) : (
              <Box className={classes.rowContainer}>
                <Typography variant="body2" color="inherit" noWrap>
                  N/A
                </Typography>
              </Box>
            ),
        },
        {
          id: "delivery",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery ? (
                <Typography variant="body2" noWrap>
                  {row.delivery}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "industry",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry ? (
                <Typography variant="body2" noWrap>
                  {row.industry}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#F9F9F9",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "not_an_existing_date",
          format: "date",
          style: {
            backgroundColor: "#F9F9F9",
            paddingLeft: 8,
          },
        },
        {
          id: "actions",
          content: (row) => <CustomerTableActions row={row} isForJob />,
          style: {
            backgroundColor: "#F9F9F9",
          },
        },
      ],
      [
        {
          name: "_expander",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
        },
        {
          id: "job_description",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
          },
          content: (row) => (
            <Box className={classes.jobNameContainer}>
              <Box className={classes.jobIndicatorChild}></Box>
              {row.job_description}
            </Box>
          ),
        },
        {
          id: "job_number",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "primary_director",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_director ? (
                <Typography variant="body2" noWrap>
                  {row.primary_director}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
              {row.primary_director &&
              parseInt(row.inactive_director_alert) > 0 ? (
                <AlertDialog message="Director is inactive for this job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "primary_manager",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.primary_manager ? (
                <Typography variant="body2" noWrap>
                  {row.primary_manager}
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
              {row.primary_manager &&
              parseInt(row.inactive_manager_alert) > 0 ? (
                <AlertDialog message="Manager is inactive for this job." />
              ) : null}
            </Box>
          ),
        },
        {
          id: "labor_budget",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.labor_budget &&
              row.labor_budget >= 0 &&
              row.has_labor_budget ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`${formatNumber(
                    parseFloat(row.labor_budget).toFixed(2)
                  )} hrs/week`}
                </Typography>
              ) : row.has_labor_budget > 0 ? (
                <AlertDialog message="There are missing labor budgets for this job." />
              ) : (
                <Box className={classes.rowContainer}>
                  <Typography variant="body2" noWrap>
                    N/A
                  </Typography>
                </Box>
              )}
            </Box>
          ),
        },
        {
          id: "supplies_budget",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.supplies_budget &&
              row.supplies_budget >= 0 &&
              row.has_supply_budget ? (
                <Typography
                  variant="body2"
                  className={classes.money}
                  color="inherit"
                  noWrap
                >
                  {`$ ${formatNumber(
                    parseFloat(row.supplies_budget).toFixed(2)
                  )}`}
                </Typography>
              ) : row.has_supply_budget > 0 ? (
                <AlertDialog message="There are missing supplies budgets for for this job." />
              ) : (
                <Box className={classes.rowContainer}>
                  <Typography variant="body2" noWrap>
                    N/A
                  </Typography>
                </Box>
              )}
            </Box>
          ),
        },
        {
          id: "delivery",
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.delivery ? (
                <Typography variant="body2" noWrap>
                  {
                    deliveryTypes.find(
                      (item) => item.value === parseInt(row.delivery)
                    )?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
            </Box>
          ),
        },
        {
          id: "industry",
          content: (row) => (
            <Box className={classes.rowContainer}>
              {row.industry ? (
                <Typography variant="body2" noWrap>
                  {
                    industries.find(
                      (item) => item.value === parseInt(row.industry)
                    )?.label
                  }
                </Typography>
              ) : (
                <AlertDialog message="There are missing assignments for this job." />
              )}
            </Box>
          ),
          style: {
            backgroundColor: "#EFEFEF",
            borderRight: "solid 1px rgba(0, 0, 0, 0.05)",
            paddingLeft: 8,
          },
        },
        {
          id: "not_an_existing_date",
          format: "date",
          style: {
            backgroundColor: "#EFEFEF",
            paddingLeft: 8,
          },
        },
        {
          id: "actions",
          content: (row) => <CustomerTableActions row={row} isForJob />,
          style: {
            backgroundColor: "#EFEFEF",
          },
        },
      ],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryTypes, industries]
  );

  const getChildrenJobs = async (row) => {
    const response = await getCustomerChildrenJob(row.job_id);
    const { jobs } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: false,
        columnIndex: 2,
      };
    });
    return listForRender;
  };

  const parentJobPageChange = async (row, rows, newPage, countIni) => {
    const index = rows.findIndex((r) => r.customer_id === row.customer_id);
    rows[index].childRows = [
      {
        hasExpander: false,
        columnIndex: 0,
        _content: <TableCollapsibleLoading />,
      },
      {
        hasExpander: false,
        columnIndex: 0,
        _content: (
          <TableCollapsiblePagination
            count={countIni}
            page={newPage}
            onChangePage={(event, newPage) =>
              parentJobPageChange(row, rows, newPage, countIni)
            }
          />
        ),
      },
    ];
    rows[index]._page = newPage;
    dispatchTable({ type: "UPDATE_ROWS", rows });
    const filterData = getFilterData(filterState);
    const response = await getCustomerParents(
      row.customer_id,
      newPage,
      filterData
    );
    const { jobs, count } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: j.children_count ? true : false,
        isOpen: false,
        childRows: [],
        getChildren: (row) => getChildrenJobs(row),
        columnIndex: 1,
      };
    });
    listForRender.push({
      hasExpander: false,
      columnIndex: 0,
      _content: (
        <TableCollapsiblePagination
          count={count}
          page={newPage}
          onChangePage={(event, newPage) =>
            parentJobPageChange(row, rows, newPage, count)
          }
        />
      ),
    });
    rows[index].childRows = listForRender;
    dispatchTable({ type: "UPDATE_ROWS", rows });
  };

  const getParentJobs = async (row, rows) => {
    // const filterData = getFilterData(filterState);
    const response = await getCustomerParents(row.customer_id, 0, filterState);
    const { jobs, count } = response.data.data;
    const listForRender = jobs.map((j) => {
      return {
        ...j,
        hasExpander: parseInt(j.has_children) ? true : false,
        isOpen: false,
        childRows: [],
        getChildren: (row) => getChildrenJobs(row),
        columnIndex: 1,
      };
    });
    if (listForRender.length > 0) {
      listForRender.push({
        hasExpander: false,
        columnIndex: 0,
        _content: (
          <TableCollapsiblePagination
            count={count}
            page={row._page}
            onChangePage={(event, newPage) =>
              parentJobPageChange(row, rows, newPage, count)
            }
          />
        ),
      });
    }
    return listForRender;
  };

  useEffect(() => {
    const fetchData = async () => {
      // const selFilters = filterState.filters.filter(
      //   (f) => !["manager_alert", "director_alert"].includes(f.filter)
      // );
      // if (selFilters.length > 0)
      const filterData = getFilterData(filterState);
      await getCustomersList(dispatchCustomers, filterData, getParentJobs);
    };
    if (filterState.searchString === "" && deliveryTypes.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchCustomers, filterState, deliveryTypes]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getCustomersList(dispatchCustomers, filterData, getParentJobs);
    };
    if (refreshList) {
      fetchData();
      dispatchCustomers({
        type: "SET_REFRESH",
        refreshList: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshList]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: customersList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "UPDATE_HEADER", header });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, customersList]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchCustomersList(
            query,
            filterData,
            getParentJobs
          );

          dispatchCustomers({
            type: "SET_CUSTOMERS_LIST",
            customersList: results,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box>
      <CustomersAdminTabs
        value="customers"
        unassignedJobCount={unassignedJobs}
      />
      <Box className={classes.root}>
        {customersListLoading || loadingSearch ? (
          <LoadingStateHorizontal isVisible style={classes.centerLoading} />
        ) : (
          <Box className={classes.contentContainer}>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <TableCollapsible>
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        placeholderSearch={"All Customers"}
                        handleSearchClear={handleSearchClear}
                      >
                        <CustomersListActions />
                        <CalendarFilters />
                        <CustomersFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filterState.filters && <FiltersAppliedList />}
                  </TableCollapsible>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomersTable;
