import useStyles from "./styles";
import React, { Fragment } from "react";
import DetailedAccordion from "./Profile/Contacts/GeographicalCoverage/GeographicalCoverage";
import Box from "@material-ui/core/Box";
import { FilterDialogProvider } from "./context/FilterDialogContext";
import Typography from "@material-ui/core/Typography";
import { ContactPage } from "./Profile/Contacts/ContactPage";
import { AddContactDialogProvider } from "./context/AddContactDialogContext";
import { GeographicalCoverageProvider } from "./context/GeographicalCoverageContext";

export function GeneralInformation() {
  const classes = useStyles();
  return (
    <Fragment>
      <Box className={classes.generalInformationWrapper}>
        <Typography className={classes.generalInformationSubHeader}>
          General Information
        </Typography>
        <AddContactDialogProvider>
          <ContactPage />
        </AddContactDialogProvider>
        <GeographicalCoverageProvider>
          <FilterDialogProvider>
            <GeographicalCoverageProvider>
              <DetailedAccordion />
            </GeographicalCoverageProvider>
          </FilterDialogProvider>
        </GeographicalCoverageProvider>
      </Box>
    </Fragment>
  );
}
