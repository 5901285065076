import { useQuery } from "react-query";
import { getPartnerData } from "../../../../services/partners/tradesService";
import { useGetUserId } from "../../context/UserIdContext";

const usePartnerData = () => {
  const { userId } = useGetUserId();
  const { data, isLoading } = useQuery(
    ["partnerData", userId],
    async () => {
      const response = await getPartnerData(userId);
      return response.data.data.partner;
    },
    {
      enabled: !!userId,
    }
  );

  return { data, isLoading };
};

export default usePartnerData;
