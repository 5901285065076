import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import QuestionsFilters from "./questionsFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import QuestionsListActions from "./questionsListActions";
import QuestionsTableActions from "./questionsTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { questionQATypes } from "constants.js";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useSurveyView } from "contexts/surveyViewContext";
import {
  useQuestionState,
  useQuestionDispatch,
  getQuestionList,
  searchQuestions,
  getQuestionListDataSet,
} from "contexts/surveyQuestionContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { updateSurvey } from "services/surveyService";
import { hasPermission, permissionQuality } from "lib/permissions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuestionsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);

  const { questionListLoading, questionList, questionCount } =
    useQuestionState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchQuestions = useQuestionDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContextSurvey] = useSurveyView();
  const { survey } = stateContextSurvey ?? null;
  const { refreshData, permissions } = globalUi;

  const hasManage = useMemo(() =>
    hasPermission(permissionQuality.MANAGE, permissions)
  );

  const changeQuestionOrder = async (question_id, new_order) => {
    await updateSurvey(survey.id, {
      order_question_id: question_id,
      question_order: new_order,
    });
    const filterData = getFilterData(filterState);
    await getQuestionList(survey.id, dispatchQuestions, filterData);
  };

  const columns = useMemo(
    () => [
      {
        id: "orderAction",
        label: "",
        content: (row) =>
          hasManage ? (
            <>
              {row.order > 1 ? (
                <ArrowUpIcon
                  className={
                    row.order < questionList.length
                      ? classes.arrowUp
                      : classes.arrowUpAlone
                  }
                  onClick={() =>
                    changeQuestionOrder(
                      row.qa_question_id,
                      parseInt(row.order) - 1
                    )
                  }
                />
              ) : null}
              {row.order < questionList.length ? (
                <ArrowDownIcon
                  className={
                    row.order > 1 ? classes.arrowDown : classes.arrowDownAlone
                  }
                  onClick={() =>
                    changeQuestionOrder(
                      row.qa_question_id,
                      parseInt(row.order) + 1
                    )
                  }
                />
              ) : null}
            </>
          ) : null,
      },
      {
        id: "order",
        label: "Order",
        path: "order",
      },
      {
        id: "id",
        label: "Question Number",
        content: (row) => `QUES-${row.qa_question_id}`,
      },
      {
        id: "type",
        label: "Question Type",
        content: (row) =>
          questionQATypes.find((item) => item.value === parseInt(row.type))
            .label,
        style: { width: 200 },
      },
      {
        id: "name",
        label: "Question Name",
        style: { width: 800 },
      },
      {
        id: "last_updated_at",
        label: "Last Updated",
        format: "date",
        style: { width: 100 },
      },
      {
        id: "is_required",
        label: "Required",
        content: (row) => (parseInt(row.is_required) ? "Yes" : "No"),
        style: { width: 75 },
      },
      {
        id: "actions",
        content: (row) => (
          <QuestionsTableActions disabled={!hasManage} row={row} />
        ),
        contentMobile: (row) => (
          <QuestionsTableActions
            disabled={!hasManage}
            row={row}
            mobile={true}
          />
        ),
        style: { width: 25 },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionList]
  );

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getQuestionList(survey.id, dispatchQuestions, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [survey, dispatchQuestions, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getQuestionList(survey.id, dispatchQuestions, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: questionList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, questionList]);

  const handleScrollClick = useCallback(async () => {
    if (questionCount <= questionList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getQuestionListDataSet(
        questionList,
        dispatchQuestions,
        questionList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: questionList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    questionList,
    questionCount,
    filterState,
    dispatchQuestions,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchQuestions(survey.id, query, filterData);
          // Send result to search tab
          dispatchQuestions({
            type: "SET_QUESTION_LIST",
            questionList: results,
          });

          dispatchQuestions({
            type: "SET_QUESTION_COUNT",
            questionCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {questionListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${questionList.length} / ${questionCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          placeholderSearch={"Question Name"}
                          handleSearchClear={handleSearchClear}
                        >
                          {hasManage ? <QuestionsListActions /> : null}
                          <CalendarFilters />
                          <QuestionsFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(QuestionsTable);
