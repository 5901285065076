import React, { useState, useEffect, useContext, useRef } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import useStyles from "./styles";

const QuoteItemTask = (props) => {
  const classes = useStyles();
  const inputTaskRef = useRef();
  const [addTask, setAddTask] = useState(false);

  const {
    taskList,
    handleSaveTask,
    handleDelete,
    noLabel,
    customText,
    readonly,
  } = props;

  const handleTaskAdd = () => {
    setAddTask(true);
    setTimeout(() => {
      inputTaskRef.current.focus();
    }, 200);
  };

  const handleSaveItem = () => {
    const value = inputTaskRef.current.value;
    if (value !== "") {
      const data = {
        name: value,
        description: "",
      };
      inputTaskRef.current.value = "";
      handleSaveTask(data);
    }
    setTimeout(() => {
      setAddTask(false);
    }, 200);
  };

  const handleDeleteTask = (taskId) => {
    handleDelete(taskId);
  };

  const handleTaskAddSubmit = () => {};

  return (
    <Box className={classes.containerBodyTaskList}>
      {!noLabel ? (
        <Typography className={classes.titleSectionTask} gutterBottom>
          Task List
        </Typography>
      ) : (
        ""
      )}
      <FormControl component="fieldset" className={classes.taskItemFieldset}>
        <FormGroup
          aria-label="position"
          className={classes.taskItemContainer}
          row
        >
          {taskList.map((task) => (
            <FormControlLabel
              value={task.id}
              key={task.id}
              control={
                <IconButton className={classes.checkBoxDisabledTask}>
                  <IndeterminateCheckBoxIcon fontSize="small" />
                </IconButton>
              }
              label={
                <Box className={classes.taskLabel}>
                  {task.name}
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon}
                    onClick={() => handleDeleteTask(task.id)}
                    disabled={readonly}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
              labelPlacement="end"
              className={classes.taskItem}
            />
          ))}
          {taskList.length === 0 && !addTask && (
            <Box className={classes.containerEmpty}>
              <Typography className={classes.titleNoTask} gutterBottom>
                {customText ? customText : "No tasks have been created."}
              </Typography>
            </Box>
          )}

          <FormControlLabel
            value={"new"}
            style={{ display: addTask ? "inline-flex" : "none" }}
            control={
              <IconButton className={classes.checkBoxDisabledTask}>
                <IndeterminateCheckBoxIcon fontSize="small" />
              </IconButton>
            }
            label={
              <Input
                defaultValue=""
                className={classes.inputNewTask}
                disableUnderline={true}
                inputProps={{ "aria-label": "description" }}
                onBlur={handleSaveItem}
                inputRef={inputTaskRef}
              />
            }
            labelPlacement="end"
            className={classNames(classes.taskItem, classes.taskItemAdd)}
          />
        </FormGroup>
      </FormControl>
      {!addTask ? (
        <Box className={classes.boxActions}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            disabled={readonly}
            className={classNames(classes.button, classes.buttonAdd)}
            onClick={handleTaskAdd}
          >
            Add Task
          </Button>
        </Box>
      ) : (
        <Box className={classes.boxActions}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonAdd)}
            onClick={handleTaskAddSubmit}
          >
            Save Task
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default QuoteItemTask;
