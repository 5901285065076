import React from "react";
import ProjectLayout from "./projectLayout";
import { ProjectViewProvider } from "contexts/projectViewContext";

const Project = (props) => {
  return (
    <ProjectViewProvider>
      <ProjectLayout id={props.match.params.id} />
    </ProjectViewProvider>
  );
};

export default Project;
