import React from "react";
import ProjectTabBillingTable from "./projectTabBillingTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";

import useStyles from "./styles";

const ProjectTabBilling = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerTabWorkticket}>
      <ListProvider>
        <ProjectTabBillingTable />
      </ListProvider>
    </Box>
  );
};

export default withTableFilterContext(ProjectTabBilling);
