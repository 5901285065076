import React from "react";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FormSubTitle = props => {
  const classes = useStyles();
  return (
    <Typography variant="h6" gutterBottom className={classes.subTitle}>
      {props.children}
    </Typography>
  );
};

export default FormSubTitle;
