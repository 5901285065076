import React, { useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { useAssetState } from "contexts/assetSurfaceContext";
import {
  getAssetDetails,
  getAssetMaintenanceUpcoming,
  getAssetMaintenanceHistory
} from "services/assetSurfaceService";
import CommonDialog from "components/common/CommonDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssetsIcon from "@material-ui/icons/Toys";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import HistoryIcon from "@material-ui/icons/History";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { useAssetDispatch } from "contexts/assetSurfaceContext";

const AssetsSurfaceInformation = props => {
  const classes = useStyles();
  const { assetRowId } = useAssetState();
  const [asset, setAsset] = useState({});
  const [open, setOpen] = useState(false);
  const [historical_maintenance, setHistorical_maintenance] = useState([]);
  const [upcomingList, setUpcomingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const dispatchAssets = useAssetDispatch();

  useEffect(() => {
    const loadInformation = async setIsLoading => {
      try {
        setIsLoading(true);
        const result = await getAssetDetails(assetRowId);
        setAsset(result.data.data.asset);

        const resultUpcoming = await getAssetMaintenanceUpcoming(assetRowId);
        setUpcomingList(resultUpcoming.data.data.worktickets);

        const resultHistory = await getAssetMaintenanceHistory(assetRowId);
        setHistorical_maintenance(resultHistory.data.data.worktickets);

        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load asset information");
      }
    };
    if (assetRowId) {
      loadInformation(setIsLoading);
    }
  }, [assetRowId]);

  const closeViewAll = () => {
    setOpen(false);
  };

  const openViewAll = () => {
    setOpen(true);
  };

  const handleAssetClose = () => {
    dispatchAssets({
      type: "SET_ASSET_ROW_ID",
      assetRowId: null
    });
  };

  const handleTabChange = newValue => {
    setTab(newValue);
  };

  const handleView = link => {
    const { history } = props;
    history.push(link);
  };

  return (
    <>
      <Box className={classes.containerBodyWTList}>
        <Box className={classes.wrapperAssetInformationClose}>
          <IconButton aria-label="close" onClick={handleAssetClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ButtonGroup
          variant="outlined"
          color="default"
          aria-label="primary button group"
          className={classes.groupOption}
          fullWidth={true}
        >
          <Button
            key={`tab-0`}
            className={tab === 0 ? classes.groupOptionActive : null}
            onClick={() => handleTabChange(0)}
          >
            Upcoming
          </Button>
          <Button
            key={`tab-1`}
            className={tab === 1 ? classes.groupOptionActive : null}
            onClick={() => handleTabChange(1)}
          >
            History
          </Button>
        </ButtonGroup>
        {tab === 0 && (
          <Box className={classes.containerUpcoming}>
            <Box className={classes.containerInformation}>
              {isLoading ? (
                <LoadingIndicator />
              ) : (
                <List className={classes.containerInformationList}>
                  {upcomingList.length ? (
                    upcomingList.map(item => {
                      return (
                        <ListItem
                          className={classes.containerInformationItem}
                          key={item.id}
                        >
                          <ListItemIcon
                            className={classes.containerInformationItemIcon}
                          >
                            <TicketIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Tooltip title={item.number}>
                                <Typography
                                  className={
                                    classes.containerInformationItemText
                                  }
                                >
                                  {item.number}
                                </Typography>
                              </Tooltip>
                            }
                          />
                          <ListItemSecondaryAction
                            className={classes.containerInformationItemButton}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classNames(
                                classes.buttonView,
                                classes.buttonOutlined
                              )}
                              onClick={() =>
                                handleView(`/workticket/surface/${item.id}`)
                              }
                            >
                              View
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      <ListItemText primary="No Upcoming Maintenance" />
                    </ListItem>
                  )}
                </List>
              )}
            </Box>
          </Box>
        )}
        {tab === 1 && (
          <Box className={classes.containerHistory}>
            <Box className={classes.containerInformation}>
              {isLoading ? (
                <LoadingIndicator />
              ) : historical_maintenance &&
                historical_maintenance.length > 0 ? (
                <>
                  <CommonDialog
                    open={open}
                    cancelMessage="Cancel"
                    confirmMessage="Ok"
                    handleCancel={closeViewAll}
                    handleConfirm={closeViewAll}
                    maxWidth="sm"
                    variant={true}
                    noButtons={true}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.dialogHeader}
                    >
                      <Grid item sm={12}>
                        <Typography
                          variant="h3"
                          className={classes.titleBody}
                          gutterBottom
                        >
                          Maintenance History
                        </Typography>
                        {asset && (
                          <>
                            <Typography
                              variant="h4"
                              className={classes.subtitleBody}
                              gutterBottom
                            >
                              <AssetsIcon
                                fontSize="small"
                                className={classes.iconHeader}
                              />{" "}
                              {asset.name}
                            </Typography>
                            {asset.jobs.length && (
                              <>
                                {asset.jobs.map(job => (
                                  <Typography
                                    variant="h4"
                                    className={classes.subtitleBody}
                                    gutterBottom
                                    key={job.id}
                                  >
                                    <WorkIcon
                                      fontSize="small"
                                      className={classes.iconHeader}
                                    />
                                    {`${job.job_number} - ${job.job_description}`}
                                  </Typography>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <List className={classes.containerInformationList}>
                      <ListItem
                        className={classes.containerInformationItemHeader}
                      >
                        <ListItemText
                          className={classes.containerInformationItemTextA}
                          primary={
                            <Typography
                              className={
                                classes.containerInformationItemTextHeader
                              }
                            >
                              Date
                            </Typography>
                          }
                        />
                        <ListItemText
                          className={classes.containerInformationItemTextC}
                          primary={
                            <Typography
                              className={
                                classes.containerInformationItemTextHeader
                              }
                            >
                              Maintenance
                            </Typography>
                          }
                        />
                        <ListItemText
                          primary={
                            <Typography
                              className={
                                classes.containerInformationItemTextHeader
                              }
                            >
                              Workticket Number
                            </Typography>
                          }
                        />
                      </ListItem>
                      {historical_maintenance.map((wt, index) => (
                        <ListItem
                          className={
                            index % 2 === 0
                              ? classes.containerInformationItem2
                              : classes.containerInformationItem3
                          }
                          key={index}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                className={
                                  classes.containerInformationItemTextBold
                                }
                              >
                                {wt.completed_date
                                  ? moment(wt.completed_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : moment(wt.start_date).format("MM/DD/YYYY")}
                              </Typography>
                            }
                          />
                          <ListItemText
                            className={classes.containerInformationItemTextC}
                            primary={
                              <Typography
                                className={classes.containerInformationItemText}
                              >
                                {wt.item_subject}
                              </Typography>
                            }
                          />
                          <ListItemText
                            primary={
                              <Typography
                                className={classes.containerInformationItemText}
                              >
                                {wt.number}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CommonDialog>
                  <List className={classes.containerInformationList}>
                    {historical_maintenance.slice(0, 4).map((wt, index) => (
                      <ListItem
                        className={classes.containerInformationItem}
                        key={index}
                      >
                        <ListItemIcon
                          className={classes.containerInformationItemIcon}
                        >
                          <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Tooltip title={wt.number}>
                              <Typography
                                className={classes.containerInformationItemText}
                              >
                                {wt.number}
                              </Typography>
                            </Tooltip>
                          }
                        />
                        <ListItemSecondaryAction
                          className={classes.containerInformationItemButton}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classNames(
                              classes.buttonView,
                              classes.buttonOutlined
                            )}
                            onClick={() =>
                              handleView(`/workticket/surface/${wt.id}`)
                            }
                          >
                            View
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <div className={classes.containerInformationAction}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classNames(
                        classes.buttonViewAll,
                        classes.buttonOutlined
                      )}
                      onClick={openViewAll}
                    >
                      View All
                    </Button>
                  </div>
                </>
              ) : (
                <List className={classes.containerInformationList}>
                  <ListItem>
                    <ListItemText primary={"No Historical Maintenance"} />
                  </ListItem>
                </List>
              )}
            </Box>
          </Box>
        )}
        <Box className={classes.containerBodyList}>
          <Typography
            variant="h4"
            className={classes.titleSectionBody}
            gutterBottom
          >
            Documents
          </Typography>
          <Box className={classes.containerInformation}>
            {isLoading ? (
              <LoadingIndicator />
            ) : asset.files && asset.files.length > 0 ? (
              <List className={classes.containerInformationList}>
                {asset.files.map((file, index) => (
                  <ListItem
                    className={classes.containerInformationItem}
                    key={index}
                  >
                    <ListItemIcon
                      className={classes.containerInformationItemIcon}
                    >
                      {file.file_name.endsWith(".png") ||
                      file.file_name.endsWith(".jpg") ||
                      file.file_name.endsWith(".jpeg") ? (
                        <ImageIcon />
                      ) : (
                        <FileIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title={file.file_name}>
                          <Typography
                            className={classes.containerInformationItemText}
                          >
                            {file.file_name.substring(0, 10)}
                          </Typography>
                        </Tooltip>
                      }
                    />
                    <ListItemSecondaryAction
                      className={classes.containerInformationItemButton}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classNames(
                          classes.buttonView,
                          classes.buttonOutlined
                        )}
                        href={`${file.file_url}`}
                        target="_blank"
                      >
                        View
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <List className={classes.containerInformationList}>
                <ListItem>
                  <ListItemText primary={"No Documents"} />
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(AssetsSurfaceInformation);
