import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialog2: {
    textAlign: "center",
    paddingBottom: 0,
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  infoAlert: {
    border: "none",
    marginBottom: theme.spacing(1),
    top: -15,
    position: "relative",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  titleIcon: {
    marginRight: 8,
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 0,
    paddingRight: 44,
    paddingBottom: 28,
  },
  actionItemButtonDrawer: {
    textTransform: "Capitalize",
    paddingTop: 16,
    paddingBottom: 16,
  },
  formFieldsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textAlign: "left",
  },
  formFieldsContainer2: {
    borderRight: "solid 1px #ECECEC",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textAlign: "left",
  },
  formToggleContainer: {
    textAlign: "right",
    minHeight: 34,
  },
  spanishToggle: {
    marginRight: 0,
    "& > span": {
      fontSize: 12,
    },
    "& > span.MuiButtonBase-root": {
      paddingRight: 4,
    },
  },
  spanishToggleCheck: {
    "& > span > svg": {
      borderRadius: 3,
      width: 16,
      height: 16,
    },
  },
  titleSection: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#747474",
  },
  titleSection2: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#747474",
    marginTop: 8,
  },
  chipTask: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  optionToggle: {
    marginRight: 0,
    "& > span": {
      fontSize: 14,
    },
    "& > span.MuiButtonBase-root": {
      paddingRight: 4,
    },
  },
  optionToggleCheck: {
    "& > span > svg": {
      borderRadius: 3,
      width: 20,
      height: 20,
    },
  },
  optionsActionsContainer: {
    display: "flex",
  },
  addOptionsButton: {
    fontSize: 10,
    marginLeft: 8,
    maxHeight: 24,
  },
  titleNoOption: {
    fontSize: 14,
  },
  containerEmpty: {
    marginTop: 8,
  },
  rootAppBar: {
    backgroundColor: "#78C1CE !important",
    boxShadow: "none",
  },
  rootWrapper: {
    height: "100vh",
    display: "flex",
    position: "relative",
    flexGrow: 1,
    maxHeight: "100%",
    flexDirection: "column",
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
  },
  titleDialogSelect: {
    fontSize: 24,
    fontWeight: "bold",
    paddingLeft: 18,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  headerIcon: {
    marginRight: 8,
  },
  formContainer: {
    textAlign: "left",
    marginTop: 28,
  },
  wrapperDialogAction2: {
    textAlign: "right",
    justifyContent: "right !important",
    marginBottom: 20,
    marginRight: 16,
  },
}));

export default useStyles;
