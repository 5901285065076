import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const SwitchSettings = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 4,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 50,
    opacity: 1,
    backgroundColor: "#747474",
    "$checked + & ": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  checked: {},
}))(Switch);

export default SwitchSettings;
