import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notesRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "110%",
    paddingLeft: 30,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      height: "100%",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 45,
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& > div": {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
      "& h4": {
        textAlign: "left",
      },
    },
  },
  headerActions: {
    "& button": {
      marginLeft: 10,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
  },
  tableBody: {
    width: "100%",
    overflow: "hidden",
    flex: 1,
    height: 370,
    "& > div": {
      margin: 0,
      height: "100%",
    },
    "& > div > div > div": {
      padding: 0,
    },
    "& > div > div > div > div:first-child": {
      right: 0,
    },
    "& > div > div > div > div > div > div": {
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 160px)",
    },
  },
}));

export default useStyles;
