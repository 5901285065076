import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
const apiEndpoint = `${apiUrl}/jobs`;

export function getJobs() {
  return http.get(`${apiEndpoint}`);
}

export function getJobsParents(filterData) {
  let filterString = "";
  const findCustomers = filterData.filters.find(
    (item) => item.filter === "customer_ids"
  );
  if (findCustomers) {
    const filterCustomer = findCustomers.values.map((item) => item.value);
    filterCustomer.forEach((item) => {
      filterString += `customer_ids[]=${item}&`;
    });
  }

  return http.get(`${apiEndpoint}?${filterString}only_parents=1`);
}

export function getJobsFilters(showMain = null) {
  const paramShowMain = showMain ? "?show_main=1" : "";
  return http.get(`${apiEndpoint}/filter${paramShowMain}`);
}

export function getJobsZipFilters() {
  return http.get(`${apiEndpoint}/zip-filter`);
}

export function getJobsCustomer(customer_id, showMain = null) {
  const paramShowMain = showMain ? "?show_main=1" : "";
  return http.get(`${apiEndpoint}${paramShowMain}`, {
    params: { customer_ids: [customer_id] },
  });
}

export function getJobsCustomers(customer_ids) {
  return http.get(`${apiEndpoint}`, {
    params: { customer_ids },
  });
}

export function getLaborJobs(filterData, offset, search) {
  const filterString = getFilterString(filterData);
  if (search)
    return http.get(
      `${apiEndpoint}/labor-report?${filterString}offset=${
        offset ?? 0
      }&search=${search}`
    );
  else
    return http.get(
      `${apiEndpoint}/labor-report?${filterString}offset=${offset ?? 0}`
    );
}

export function getJobBudgets(id) {
  return http.get(`${apiEndpoint}/budgets/${id}`);
}

export function getUnassigedJobs() {
  return http.get(`${apiEndpoint}/unassigned`);
}

export function getActiveLaborBudget(job, search) {
  if (search) {
    return http.get(`${apiEndpoint}/budget/labor/${job}?search=${search}`);
  } else {
    return http.get(`${apiEndpoint}/budget/labor/${job}`);
  }
}

export function getActiveLaborBudgetChildren(job, key) {
  return http.get(`${apiEndpoint}/budget/labor/${job}/children?key=${key}`);
}

export function getPendingLaborBudget(job, search) {
  if (search) {
    return http.get(`${apiEndpoint}/budget/pending/${job}?search=${search}`);
  } else {
    return http.get(`${apiEndpoint}/budget/pending/${job}`);
  }
}

export function getPendingLaborBudgetChildren(job, key) {
  return http.get(`${apiEndpoint}/budget/pending/${job}/children?key=${key}`);
}

export function getJobDetail(job) {
  return http.get(`${apiEndpoint}/${job}`);
}

export function updateJobDetail(id, data) {
  return http.post(`${apiEndpoint}/${id}/update`, data);
}

export function manageJobBudget(id, data) {
  return http.post(`${apiEndpoint}/budget/${id}`, data);
}

export function manageJobBudgetStatus(data) {
  return http.post(`${apiEndpoint}/budget/labor/status`, data);
}

export function getActionDetail(id) {
  return http.get(`${apiEndpoint}/budget/labor/actions/${id}`);
}
export function syncLaborBudget(id) {
  return http.post(`${apiEndpoint}/budget/sync/${id}`);
}
export function getSuppliesBudget(id, startDate, endDate) {
  return http.get(
    `${apiEndpoint}/${id}/budgets/supplies/monthly?start_date=${startDate}&end_date=${endDate}`
  );
}

export function getLaborAlerts(job) {
  return http.get(`${apiEndpoint}/budget/labor/alert/${job}`);
}

export function manageAlertBudget(id, data) {
  return http.post(`${apiEndpoint}/budget/labor/alert/${id}`, data);
}

export function manageAlertClearBudget(id, data) {
  return http.post(`${apiEndpoint}/budget/labor/alert/clear/${id}`, data);
}

export function getJobUsers(job) {
  return http.get(`${apiEndpoint}/budget/users/${job}`);
}

export function getJobPositions(job) {
  return http.get(`${apiEndpoint}/budget/user/positions/${job}`);
}

export function getCities() {
  return http.get(`${apiEndpoint}/cities-filter`);
}
