import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "../../partnersList/dialog/messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import useStyles from "../../partnersList/dialog/styles";
import { RedQuestionMark } from "../icons/RedQuestionMark";

const DeleteLocationDialogue = ({
  open,
  isLoadingOpen,
  isSuccessOpen,
  handleDelete,
  handleDeleteClose,
  handleSuccessClose,
}) => {
  const classes = useStyles();

  const handleCloseDialog = () => {
    handleDeleteClose();
  };

  const handleConfirmDelete = async () => {
    handleDelete();
    handleCloseDialog();
  };

  return (
    <>
      <Dialog
        open={isLoadingOpen}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open && !isLoadingOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <RedQuestionMark className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Delete Customer
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete this customer?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDialog}
            disabled={isLoadingOpen}
            className={classes.buttonOutlined}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoadingOpen}
            onClick={handleConfirmDelete}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessOpen}
        title="Success!"
        message="The customer has been successfully deleted."
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default DeleteLocationDialogue;
