import React from "react";
import AssetsLayout from "./assetsLayout";
import Box from "@material-ui/core/Box";
import { TableProvider } from "contexts/tableContext";
import { AssetProvider } from "contexts/assetContext";
import { FilterProvider } from "contexts/filterContext";
import useStyles from "./styles";

const AssetsList = props => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <AssetProvider>
          <FilterProvider>
            <TableProvider>
              <AssetsLayout />
            </TableProvider>
          </FilterProvider>
      </AssetProvider>
    </Box>
  );
};

export default AssetsList;
