import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useEvaluationsState,
  useEvaluationsDispatch,
  getEvaluationsList,
} from "contexts/evaluationsContext";
import { deleteSurvey } from "services/surveyFrontlineService";
import { useFilterState } from "contexts/filterContext";
import { useIndividualView } from "contexts/individualViewContext";

import useStyles from "./styles";

const EvaluationsDeleteDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const { openEvaluationsDelete, evaluationsSelected } = useEvaluationsState();
  const dispatchSurveysEvaluations = useEvaluationsDispatch();
  const { filters } = useFilterState();
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;

  const handleClose = () => {
    dispatchSurveysEvaluations({
      type: "TOGGLE_DELETE",
      openEvaluationsDelete: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      await deleteSurvey(evaluationsSelected.id);
      const filterData = {
        filters: [
          ...filters,
          {
            filter: "submittedFor",
            group: "Submitted For",
            values: [{ label: "", value: individual.id }],
          },
        ],
      };
      await getEvaluationsList(dispatchSurveysEvaluations, filterData);
      setIsLoadingDialog(false);
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      console.log("Cannot delete evaluation");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openEvaluationsDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Evaluations
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to delete this evaluation?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`This evaluation has been deleted.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default EvaluationsDeleteDialog;
