import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  followBtn: {
    backgroundColor: "#3996C0",
    color: "#ffffff",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
  },
  followingBtn: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
  },
  dark: {
    backgroundColor: "#397290",
    color: "#ffffff",
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: 0,
    flex: 1,
    border: "1px solid #397290",
    "&:hover": {
      backgroundColor: "#397290",
    },
  },
  light: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: 0,
    flex: 1,
  },
  sliderHeightTable: {
    height: "336px",
  },
  assetTab: {
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "16px",
    cursor: "pointer",
  },
  workTicketContainer: {
    flex: "6",
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
    marginTop: 30,
  },
  assetRoot: {
    position: "absolute",
    zIndex: "3",
    height: "700px",
    width: "370px",
    marginTop: "30px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
    padding: "22px",
  },
  assetDetailsText: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "33px",
  },
  assetViewAll: { position: "absolute", bottom: "10px" },
  flex: {
    display: "flex",
    padding: "3px",
  },
  flexDetails: {
    paddingTop: "7px",
    display: "flex",
  },
  flex1: {
    flex: "1",
    display: "flex",
  },
  flex1Right: {
    flex: "1",
    display: "flex",
    justifyContent: "flex-end",
  },
  tableHeight: {
    height: 400,
    overflow: "auto",
  },
  overlay: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(0 0 0 / 48%)",
    marginTop: 41,
  },
  container: {
    //width: 160,
    backgroundColor: "#ffffff",
    borderLeft: "1px solid #9B9B9B",
    borderRight: "1px solid #9B9B9B",
    borderTop: "1px solid #9B9B9B",
    borderRadius: "4px 4px 0px 0px",
    position: "fixed",
    marginTop: -58,
    padding: 6,
    width: 204,
    height: 60,
  },
  containerMapMoved: {
    backgroundColor: "#4f97bb",
    borderRadius: "4px 4px 0px 0px",
    position: "fixed",
    marginTop: -58,
    padding: 6,
    color: "#ffffff",
    width: 204,
    height: 60,
  },
  details: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    alignItems: "center",
    color: "#4A4A4A",
    marginTop: 4,
    cursor: "pointer",
    flex: 2,
    display: "flex",
    justifyContent: "center",
  },
  paddingLeft: {
    paddingLeft: "10px",
  },
  lineBreak: {
    marginTop: "10px",
  },
  center: {
    textAlign: "center",
    fontWeight: "700",
    lineHeight: "22px",
    fontSize: "18.58px",
  },
  centerMapMoved: {
    textAlign: "center",
    color: "#ffffff",

    fontWeight: "700",
    lineHeight: "22px",
    fontSize: "18.58px",
  },
  close: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  detailsArrowBtn: {
    position: "absolute",
    right: "5px",
    top: "16px",
  },
  marginRight8: {
    marginRight: "8px",
  },
  containerDialog: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
    "& .MuiFormControlLabel-label": {
      display: "block",
      width: "100%",
    },
    "& .Mui-checked + span": {
      textDecoration: "line-through",
    },
  },
  rowLink: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default useStyles;
