import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import QuoteSurfaceItems from "./quoteSurfaceItems";
import QuoteSurfaceDetail from "./quoteSurfaceDetail";
import useStyles from "./styles";

import { QuoteProvider } from "../../contexts/quoteSurfaceContext";
import QuoteSurfaceBreadcrum from "./quoteSurfaceBreadcrum";
import QuoteSurfaceHeader from "./quoteSurfaceHeader";
import QuoteSurfaceComments from "./quoteSurfaceComments";
import QuoteSurfaceActions from "./quoteSurfaceActions";
import QuoteSurfaceFile from "./quoteSurfaceFile";
import QuoteSurfaceWorktickets from "./quoteSurfaceWorktickets";

const QuoteSurface = props => {
  const classes = useStyles();

  const initialStateQuote = {
    selectedQuote: null,
    selectedQuoteItem: {},
    quoteDetails: [],
    uniqueJobs: [],
    editMode: false
  };

  return (
    <QuoteProvider initialState={initialStateQuote}>
      <QuoteSurfaceBreadcrum />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.containerMain}>
            <QuoteSurfaceDetail {...props} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box p={3} className={classes.containerBody}>
            <QuoteSurfaceHeader />
            <QuoteSurfaceItems />
            <QuoteSurfaceComments />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.containerSidebar}>
            <QuoteSurfaceActions />
            <QuoteSurfaceFile />
            <QuoteSurfaceWorktickets />
          </Box>
        </Grid>
      </Grid>
    </QuoteProvider>
  );
};

export default QuoteSurface;
