import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import LocationsList from "./locationsTab/locationsList";
import CompletedList from "./completedTab/completedList";
import DraftsList from "./draftTab/draftsList";
import SurveysTabList from "./surveysTab/surveysTabList";
import useStyles from "./styles";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionQuality } from "lib/permissions";

const SurveysFrontlineTabs = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const classes = useStyles();
  const { selectedTab, history } = props;
  const [tab, setTab] = useState(selectedTab ?? 1);

  const TabOptions = [
    { id: 1, text: "Locations", tab: "locations", onTabing: true },
  ];

  if (hasPermission(permissionQuality.SURVEYS_TAB_LIST, permissions)) {
    TabOptions.push({ id: 4, text: "Surveys", tab: "surveys", onTabing: true });
  }
  TabOptions.push({ id: 2, text: "Drafts", tab: "drafts", onTabing: true });
  TabOptions.push({
    id: 3,
    text: "Completed",
    tab: "completed",
    onTabing: true,
  });

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    if (newTab === 1) {
      history.push(`/surveys`);
    }
  };

  useEffect(() => {
    setTab(selectedTab ? (selectedTab === 4 ? 1 : selectedTab) : 1);
    if (selectedTab === 4) {
      history.push(`/surveys`);
    }
  }, [selectedTab]);

  return (
    <Box className={classes.containerTabs}>
      <StyledTabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        className={classes.rootTabs}
      >
        {TabOptions.filter((item) => item.onTabing).map((option) => {
          return (
            <StyledTab
              disableRipple={true}
              label={<Box className={classes.tabItem}>{option.text}</Box>}
              value={option.id}
              key={option.id}
            />
          );
        })}
      </StyledTabs>
      <Box className={classes.containerTabContent}>
        {tab === 1 ? <LocationsList /> : null}
        {tab === 2 ? <DraftsList /> : null}
        {tab === 3 ? <CompletedList /> : null}
        {tab === 4 ? <SurveysTabList /> : null}
      </Box>
    </Box>
  );
};

export default withRouter(SurveysFrontlineTabs);
