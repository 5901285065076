import React from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  convertDateFormatField,
  DaysFromNowStandard,
} from "components/util/timeFormat";
import useStyles from "./styles";

const WorkticketHeader = (props) => {
  const classes = useStyles();
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  const LabelSubtitle = (props) => (
    <Typography
      variant="caption"
      className={classes.containerSubtitleItem}
      gutterBottom
    >
      {props.children}
    </Typography>
  );

  return (
    <>
      <Typography variant="h3" className={classes.titleBody} gutterBottom>
        {workticket.number}
        <Box className={classes.containerStatus}>
          <WorkticketStatusChip
            status={workticket.status}
            dueDate={workticket.start_date}
            archived={0}
          />
          <Chip
            label={`Due: ${convertDateFormatField(workticket.start_date)}`}
            className={classNames(classes.chipPrimary)}
          />
          {(DaysFromNowStandard(workticket.start_date) > 1 ||
            workticket.type === 4) &&
            workticket.status < 2 && (
              <WorkticketSoloStatusChip
                status={workticket.status}
                type={workticket.type}
              />
            )}
        </Box>
      </Typography>
      <Box>
        {workticket.external_woid ? (
          <Chip
            label={`WOID: ${workticket.external_woid}`}
            className={classNames(classes.chipExternalPrimary)}
          />
        ) : null}
      </Box>
      <Box className={classes.containerSubtitle}>
        {workticket.created_at && (
          <LabelSubtitle>
            Date Created: {convertDateFormatField(workticket.created_at)}
          </LabelSubtitle>
        )}
        <LabelSubtitle>
          Scheduled For:{" "}
          {workticket.schedules && workticket.schedules[0]
            ? convertDateFormatField(workticket.schedules[0].schedule_date)
            : "None"}
        </LabelSubtitle>
        {workticket.completed_date && (
          <LabelSubtitle>
            Date Completed: {convertDateFormatField(workticket.completed_date)}
          </LabelSubtitle>
        )}
      </Box>
    </>
  );
};

export default withRouter(WorkticketHeader);
