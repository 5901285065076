import React from "react";
import DeleteDialog from "./deleteDialog";
import ChangePartnerStatusDialog from "./partnerStatusChangeDialog";
import RatingDialog from "./partnerRateDialog";
import RedirectDialog from "./redirectDialog";

const PartnersDialog = (props) => {
  return (
    <>
      <DeleteDialog {...props} />
      <ChangePartnerStatusDialog {...props} />
      <RatingDialog {...props} />
      <RedirectDialog {...props} />
    </>
  );
};
export default PartnersDialog;
