import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { logException } from "components/util/logUtil";
import {
  deleteUserRate,
  ratePartner,
  updatePartnerRate,
} from "../../../services/partners/ratingService";
import { useRateAction } from "../context/PartnerRateContext";
import { useGetUserId } from "../context/UserIdContext";

export const useRateDialog = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { userId } = useGetUserId();
  const [rateAction, dispatchRateAction] = useRateAction();
  const { openRatePartner, openEditPartner } = rateAction;
  const isEditing = openEditPartner?.open || false;
  const rateToEdit = useMemo(() => {
    return (
      openEditPartner?.rateToEdit || { rate: 0, comment: "", rating_id: "" }
    );
  }, [openEditPartner]);

  const [rate, setRate] = useState(rateToEdit.rate);
  const [comment, setComment] = useState(rateToEdit.comment);

  useEffect(() => {
    if (isEditing) {
      setRate(rateToEdit.rate);
      setComment(rateToEdit.comment);
    }
  }, [isEditing, rateToEdit]);

  const handleClose = () => {
    dispatchRateAction({
      type: "TOGGLE_RATE_PARTNER",
      open: false,
    });
    setRate(0);
    setComment("");
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(ratePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries("ratings");
      handleClose();
      setOpenSuccess(true);
      setIsLoadingDialog(false);
      setIsLoading(false);
    },
    onError: (error) => {
      logException(error, "Failed to rate partner");
    },
  });

  const updateRate = useMutation(updatePartnerRate, {
    onSuccess: () => {
      queryClient.invalidateQueries("ratings");
      handleClose();
      setOpenSuccess(true);
      setIsLoadingDialog(false);
      setIsLoading(false);
    },
    onError: (error) => {
      logException(error, "Failed to update rate");
    },
  });

  const handleConfirm = async () => {
    setIsLoading(true);
    const data = {
      rate: rate,
      comment: comment,
    };
    openEditPartner?.open
      ? updateRate.mutate({
          partner_id: userId,
          data: {
            ...data,
            rating_id: rateToEdit.rating_id,
          },
        })
      : mutation.mutate({
          partner_id: userId,
          data: data,
        });
  };
  const deleteRating = useMutation(deleteUserRate, {
    onSuccess: () => {
      queryClient.invalidateQueries("ratings");
      handleClose();
      setIsLoading(false);
    },
    onError: (error) => {
      logException(error, "Failed to delete partner rating");
      setIsLoading(false);
    },
  });
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleEditTrade = (userRating) => {
    dispatchRateAction({
      type: "TOGGLE_EDIT_PARTNER",
      open: true,
      rateToEdit: {
        rate: userRating.rate,
        comment: userRating.comment,
        rating_id: userRating.id,
      },
    });
  };

  return {
    openRatePartner,
    openSuccess,
    isLoadingDialog,
    isEditing,
    rate,
    comment,
    setRate,
    setComment,
    handleClose,
    handleConfirm,
    handleCloseSuccess,
    isLoading,
    setIsLoading,
    deleteRating,
    handleEditTrade,
  };
};
