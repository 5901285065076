import React from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectAdj: {
    right: 72,
    color: "#747474",
    marginTop: "7px",
    position: "relative",
    /* margin-left: -80px; */
    left: "80px",
  },
}));

export default function SimpleSelect() {
  const classes = useStyles();
  const [lang, setLang] = React.useState("English");

  const handleChange = (event) => {
    setLang(event.target.value);
  };

  return (
    <Box className={classes.selectAdj}>
      <FormControl>
        <Select
          disableUnderline={true}
          value={lang}
          onChange={handleChange}
          label="Change Language"
        >
          <MenuItem value={"English"} className={classes.menuItem}>
            <Typography>English</Typography>
          </MenuItem>
          <MenuItem value={"Spanish"} className={classes.menuItem}>
            <Typography>Spanish</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
