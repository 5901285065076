import React from "react";
import GoogleMapReact from "google-map-react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LocationIcon from "@material-ui/icons/Apartment";

import useStyles from "./styles";

import { mapKey } from "lib/config";

const PinComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.iconPin}>
      <LocationIcon />
    </Box>
  );
};

const PinClockIn = () => {
  const classes = useStyles();
  return <Box className={classes.dotLocationClockIn} />;
};

const PinClockOut = () => {
  const classes = useStyles();
  return (
    <Box className={classes.dotLocationClockOut} style={{ marginLeft: -2 }} />
  );
};

const WorkticketGeoMap = (props) => {
  const classes = useStyles();
  const { workticket, row, radius } = props;

  const { clock_in_lat, clock_in_long, clock_out_lat, clock_out_long } = row;

  const handleApiLoaded = (map, maps, center) => {
    const circleOptions = {
      strokeColor: "#17457A",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#EDF5F880",
      fillOpacity: 0.8,
      map,
      center,
      radius: radius ?? 800,
    };
    new maps.Circle(circleOptions);
  };

  const latitude = parseFloat(workticket.job.latitude);
  const longitude = parseFloat(workticket.job.longitude);
  const mapProps = {
    center: {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    },
    zoom: 14,
  };

  console.log("mapProps", mapProps);

  return (
    <Box>
      <Box className={classes.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapKey }}
          defaultCenter={mapProps.center}
          defaultZoom={mapProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            handleApiLoaded(map, maps, mapProps.center)
          }
        >
          <PinComponent
            lat={workticket.job.latitude}
            lng={workticket.job.longitude}
          />
          {clock_in_lat && clock_in_long && (
            <PinClockIn lat={clock_in_lat} lng={clock_in_long} />
          )}
          {clock_out_lat && clock_out_long && (
            <PinClockOut lat={clock_out_lat} lng={clock_out_long} />
          )}
        </GoogleMapReact>
      </Box>
      <Box className={classes.mapContainerInfo}>
        <Box className={classes.mapContainerInfoItem}>
          <Box className={classes.dotLocation} />
          <Typography variant="caption">Location</Typography>
        </Box>
        <Box className={classes.mapContainerInfoItem}>
          <Box className={classes.dotLocationAccepted} />
          <Typography variant="caption">
            Acceptable area for geofence
          </Typography>
        </Box>
        <Box className={classes.mapContainerInfoItem}>
          <Box className={classes.dotLocationClockIn} />
          <Typography variant="caption">Clock in Location</Typography>
        </Box>
        <Box className={classes.mapContainerInfoItem}>
          <Box className={classes.dotLocationClockOut} />
          <Typography variant="caption">Clock out Location</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkticketGeoMap;
