import React, { useState } from "react";
import FormInput from "components/ui/FormContent/formInput";
import { logException } from "components/util/logUtil";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useWorkticketAction } from "contexts/workticketActionContext";
import {
  workticketBillOverride,
  workticketPaymentOverride,
} from "services/workticketService";

const WorkticketFinancialEdit = (props) => {
  const { row, type } = props;
  const { rows } = useTableState();
  const dispatchTable = useTableDispatch();
  const workticketAction = useWorkticketAction();
  const dispatchWorkticketAction = workticketAction[1];
  const [isLoading, setIsLoading] = useState(false);

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleBlur = async (event) => {
    let value = -1;
    if (event.value) {
      setIsLoading(true);
      try {
        if (!isNaN(event.value) && parseFloat(event.value) >= 0) {
          if (type === 1 && parseFloat(event.value) > parseFloat(row.nte)) {
            dispatchWorkticketAction({
              type: "TOGGLE_ERROR",
              open: true,
            });
            dispatchWorkticketAction({
              type: "SET_ERROR_MESSAGE",
              message: `${
                type === 1 ? "AR" : "AP"
              } Total cannot be greater than NTE`,
            });
          } else {
            value = parseFloat(event.value).toFixed(2);
          }
        }
      } catch (e) {
        logException(e, "Cannot change financials");
      }
    }
    if (value > 0) {
      const rowSel = rows.find((r) => r.id === row.id);
      if (type === 1) {
        if (parseFloat(rowSel.ar_total) !== parseFloat(value)) {
          rowSel.ar_total = value;
          await workticketBillOverride(rowSel.id, {
            bill_per_instance: value,
          });
        }
      } else {
        if (parseFloat(rowSel.ap_total) !== parseFloat(value)) {
          rowSel.ap_total = value;
          await workticketPaymentOverride(rowSel.id, {
            payment_per_instance: value,
          });
        }
      }

      dispatchTable({ type: "UPDATE_ROWS", rows: [...rows] });
    } else {
      await delay(1);
    }
    setIsLoading(false);
  };
  return isLoading ? (
    <LinearProgress color="secondary" />
  ) : (
    <FormInput
      name={`${row.id}_${type === 1 ? "ar" : "ap"}`}
      gridSizes={[{ size: "md", val: 12 }]}
      withoutLabel
      value={
        parseFloat(type === 1 ? row.ar_total : row.ap_total).toFixed(2) ?? 0
      }
      sameLine={true}
      handleBlur={(event) =>
        handleBlur(event, type === 1 ? row.ar_total : row.ap_total)
      }
      noMargin
      startAdornment="$"
      alignText="right"
    />
  );
};

export default WorkticketFinancialEdit;
