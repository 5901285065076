import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const RequestActionContext = React.createContext();

const initialState = {
  openReject: false,
  openQuote: false,
  openWorkticket: false,
  openResolve: false,
  openArchive: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_REJECT":
      return {
        ...state,
        openReject: action.open,
      };
    case "TOGGLE_QUOTE":
      return {
        ...state,
        openQuote: action.open,
      };
    case "TOGGLE_WORKTICKET":
      return {
        ...state,
        openWorkticket: action.open,
      };
    case "TOGGLE_RESOLVE":
      return {
        ...state,
        openResolve: action.open,
      };
    case "TOGGLE_ARCHIVE":
      return {
        ...state,
        openArchive: action.open,
      };
    default:
      return initialState;
  }
};

const RequestActionProvider = ({ children }) => (
  <RequestActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </RequestActionContext.Provider>
);

function useRequestAction() {
  const context = React.useContext(RequestActionContext);
  if (context === undefined) {
    throw new Error(
      "useRequestAction must be used within a RequestActionProvider"
    );
  }
  return context;
}

export { RequestActionProvider, useRequestAction };
