import React from "react";
import merge from "lodash/merge";
import {
  getQuestionForSubmit,
  getTasksForSubmit,
  getFeedbackQuestion,
  fillQuestion,
  startSurvey,
  startSurveyPublic,
  resumeSurvey,
  getQuestionsResume,
} from "services/surveyFrontlineService";

const defaultState = {
  selectedSurvey: {},
  selectedJobId: null,
  selectedUserId: null,
  selectedQuestions: [],
  selectedTasks: [],
  answers: [],
  openShowSurvey: false,
  openAnswerAgain: false,
  readonlySurvey: false,
  correlative: 0,
  lang: "English",
  showNewAnswerIndicator: false,
  isLoading: false,
  openSignature: false,
  signature: null,
  openInformation: false,
  selectedFiles: [],
  selectedComments: [],
  updateAnswers: false,
  openSubmit: false,
  openClose: false,
  isRandomize: false,
  randomNumber: null,
  isPreview: false,
  previewQuestions: [],
  surveyStatus: null,
  calledFrom: "",
  updateListData: false,
  linkMode: false,
  publicMode: false,
  showOnlyAnsweredTasks: false,
};

const SurveyFillStateContext = React.createContext();
const SurveyFillDispatchContext = React.createContext();

function surveyFillReducer(state, action) {
  switch (action.type) {
    case "SET_SELECTED_SURVEY":
      return {
        ...state,
        selectedSurvey: action.selectedSurvey,
      };
    case "SET_SELECTED_QUESTIONS":
      return {
        ...state,
        selectedQuestions: action.selectedQuestions,
      };
    case "SET_SELECTED_TASKS":
      return {
        ...state,
        selectedTasks: action.selectedTasks,
      };
    case "SET_ANSWERS":
      return {
        ...state,
        answers: action.answers,
      };
    case "SET_CORRELATIVE":
      return {
        ...state,
        correlative: action.correlative,
      };
    case "SET_LANG":
      return {
        ...state,
        lang: action.lang,
      };
    case "SET_SURVEY_STATUS":
      return {
        ...state,
        surveyStatus: action.surveyStatus,
      };
    case "TOGGLE_OPEN_SHOW_SURVEY":
      return {
        ...state,
        openShowSurvey: action.openShowSurvey,
      };
    case "TOGGLE_OPEN_ANSWER_AGAIN":
      return {
        ...state,
        openAnswerAgain: action.openAnswerAgain,
      };
    case "TOGGLE_NEW_ANSWER_INDICATOR":
      return {
        ...state,
        showNewAnswerIndicator: action.showNewAnswerIndicator,
      };
    case "TOGGLE_READONLY":
      return {
        ...state,
        readonlySurvey: action.readonlySurvey,
      };
    case "TOGGLE_ISLOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "TOGGLE_OPEN_SIGNATURE":
      return {
        ...state,
        openSignature: action.openSignature,
      };
    case "TOGGLE_OPEN_INFORMATION":
      return {
        ...state,
        openInformation: action.openInformation,
      };
    case "TOGGLE_UPDATE_ANSWERS":
      return {
        ...state,
        updateAnswers: action.updateAnswers,
      };
    case "TOGGLE_OPEN_SUBMIT":
      return {
        ...state,
        openSubmit: action.openSubmit,
      };
    case "TOGGLE_OPEN_CLOSE":
      return {
        ...state,
        openClose: action.openClose,
      };
    case "SET_SIGNATURE":
      return {
        ...state,
        signature: action.signature,
      };
    case "SET_SELECTED_FILES":
      return {
        ...state,
        selectedFiles: action.selectedFiles,
      };
    case "SET_SELECTED_COMMENTS":
      return {
        ...state,
        selectedComments: action.selectedComments,
      };
    case "SET_SELECTED_JOB":
      return {
        ...state,
        selectedJobId: action.selectedJobId,
      };
    case "SET_SELECTED_USER_ID":
      return {
        ...state,
        selectedUserId: action.selectedUserId,
      };
    case "SET_IS_RANDOMIZE":
      return {
        ...state,
        isRandomize: action.isRandomize,
      };
    case "SET_RANDOM_NUMBER":
      return {
        ...state,
        randomNumber: action.randomNumber,
      };
    case "SET_IS_PREVIEW":
      return {
        ...state,
        isPreview: action.isPreview,
      };
    case "SET_PREVIEW_QUESTIONS":
      return {
        ...state,
        previewQuestions: action.previewQuestions,
      };
    case "TOGGLE_UPDATE_LIST_DATA":
      return {
        ...state,
        updateListData: action.updateListData,
      };
    case "SET_CALLED_FROM":
      return {
        ...state,
        calledFrom: action.calledFrom,
      };
    case "SET_PUBLIC_MODE":
      return {
        ...state,
        publicMode: action.publicMode,
      };
    case "SET_LINK_MODE":
      return {
        ...state,
        linkMode: action.linkMode,
      };
    case "SET_SHOW_ONLY_ANSWERED_TASKS":
      return {
        ...state,
        showOnlyAnsweredTasks: action.showOnlyAnsweredTasks,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveyFillProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(surveyFillReducer, mergedState);
  return (
    <SurveyFillStateContext.Provider value={state}>
      <SurveyFillDispatchContext.Provider value={dispatch}>
        {children}
      </SurveyFillDispatchContext.Provider>
    </SurveyFillStateContext.Provider>
  );
}

function useSurveyFillState() {
  const context = React.useContext(SurveyFillStateContext);
  if (context === undefined) {
    throw new Error(
      "useSurveyFillState must be used within a SurveyFillProvider"
    );
  }
  return context;
}

function useSurveyFillDispatch() {
  const context = React.useContext(SurveyFillDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSurveyFillDispatch must be used within a SurveyFillProvider"
    );
  }
  return context;
}

async function questionFill(
  dispatch,
  submitQuestionId,
  data,
  updateAns,
  valueAns
) {
  try {
    await fillQuestion(submitQuestionId, data);
    dispatch({
      type: "TOGGLE_UPDATE_ANSWERS",
      updateAnswers: valueAns,
    });
  } catch (error) {
    console.log(error);
  }
}

async function moveToQuestion(dispatch, surveySubmit, question) {
  try {
    dispatch({
      type: "TOGGLE_ISLOADING",
      isLoading: true,
    });
    const resultQuestion = await getQuestionForSubmit(surveySubmit, question);
    const { questions: selectedQues } = resultQuestion.data.data;
    dispatch({
      type: "SET_CORRELATIVE",
      correlative: 0,
    });
    dispatch({
      type: "SET_SELECTED_QUESTIONS",
      selectedQuestions: selectedQues,
    });
    const resultQuestionDetail = await getFeedbackQuestion(selectedQues[0].id);
    const { question: selectedQuestion } = resultQuestionDetail.data.data;
    dispatch({
      type: "SET_SELECTED_FILES",
      selectedFiles: selectedQuestion.files,
    });
    dispatch({
      type: "SET_SELECTED_COMMENTS",
      selectedComments: selectedQuestion.comments,
    });

    if (parseInt(selectedQues[0].type) === 1) {
      const resultTasks = await getTasksForSubmit(selectedQues[0].id);
      const { tasks: selectedTasks } = resultTasks.data.data;
      dispatch({
        type: "SET_SELECTED_TASKS",
        selectedTasks: selectedTasks,
      });
    } else {
      dispatch({
        type: "SET_SELECTED_TASKS",
        selectedTasks: [],
      });
    }
    dispatch({
      type: "TOGGLE_ISLOADING",
      isLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function surveyStart(
  dispatch,
  jobId,
  surveyId,
  hasRandomize,
  randomNumber,
  evaluate_user_id
) {
  const result = await startSurvey(
    jobId,
    surveyId,
    hasRandomize ? 1 : 0,
    randomNumber,
    evaluate_user_id
  );
  const { survey: selSurvey } = result.data.data;
  const resultQuestion = await getQuestionForSubmit(
    selSurvey.current_question.qa_survey_submit_id,
    selSurvey.current_question.qa_question_id
  );
  const { questions: selQuestions } = resultQuestion.data.data;
  dispatch({
    type: "SET_SELECTED_JOB",
    selectedJobId: selSurvey.job_id,
  });
  dispatch({
    type: "SET_SELECTED_USER_ID",
    selectedUserId: selSurvey.evaluate_user_id,
  });
  dispatch({
    type: "SET_IS_RANDOMIZE",
    isRandomize: selSurvey.has_randomize,
  });
  dispatch({
    type: "SET_SURVEY_STATUS",
    surveyStatus: selSurvey.status,
  });
  dispatch({
    type: "SET_RANDOM_NUMBER",
    randomNumber: selSurvey.random_number,
  });
  dispatch({
    type: "SET_SELECTED_SURVEY",
    selectedSurvey: selSurvey.qa_survey,
  });
  const signatureFile = selSurvey.files.filter(
    (file) => file.pivot.is_signature
  );
  if (signatureFile.length > 0) {
    dispatch({
      type: "SET_SIGNATURE",
      signature: signatureFile[0],
    });
  } else {
    dispatch({
      type: "SET_SIGNATURE",
      signature: null,
    });
  }

  if (parseInt(selQuestions[0].type) === 1) {
    const resultTasks = await getTasksForSubmit(selQuestions[0].id);
    const { tasks: selTasks } = resultTasks.data.data;
    dispatch({
      type: "SET_SELECTED_TASKS",
      selectedTasks: selTasks,
    });
    if (selQuestions[0].status === 1 && selTasks.length > 0) {
      const noAnswerTasks = selTasks.filter((t) => t.status !== 2);
      if (noAnswerTasks.length === 0) {
        selQuestions[0].filled = true;
      }
    }
  }
  const resultQuestionDetail = await getFeedbackQuestion(selQuestions[0].id);
  const { question: selectedQuestion } = resultQuestionDetail.data.data;
  dispatch({
    type: "SET_SELECTED_FILES",
    selectedFiles: selectedQuestion.files,
  });
  dispatch({
    type: "SET_SELECTED_COMMENTS",
    selectedComments: selectedQuestion.comments,
  });
  dispatch({
    type: "SET_CORRELATIVE",
    correlative: 0,
  });
  dispatch({
    type: "SET_SELECTED_QUESTIONS",
    selectedQuestions: selQuestions,
  });
  const resultResume = await getQuestionsResume(selSurvey.id);
  const { questions: resumeQuestions } = resultResume.data.data;
  dispatch({
    type: "SET_ANSWERS",
    answers: resumeQuestions,
  });
  dispatch({
    type: "TOGGLE_OPEN_SHOW_SURVEY",
    openShowSurvey: true,
  });
}

async function surveyStartPublic(dispatch, token) {
  const result = await startSurveyPublic(token);
  const { survey: selSurvey } = result.data.data;
  const resultQuestion = await getQuestionForSubmit(
    selSurvey.current_question.qa_survey_submit_id,
    selSurvey.current_question.qa_question_id
  );
  const { questions: selQuestions } = resultQuestion.data.data;
  dispatch({
    type: "SET_SELECTED_JOB",
    selectedJobId: selSurvey.job_id,
  });
  dispatch({
    type: "SET_SELECTED_USER_ID",
    selectedUserId: selSurvey.evaluate_user_id,
  });
  dispatch({
    type: "SET_IS_RANDOMIZE",
    isRandomize: selSurvey.has_randomize,
  });
  dispatch({
    type: "SET_SURVEY_STATUS",
    surveyStatus: selSurvey.status,
  });
  dispatch({
    type: "SET_RANDOM_NUMBER",
    randomNumber: selSurvey.random_number,
  });
  dispatch({
    type: "SET_SELECTED_SURVEY",
    selectedSurvey: selSurvey.qa_survey,
  });
  dispatch({
    type: "SET_PUBLIC_MODE",
    publicMode: true,
  });
  const signatureFile = selSurvey.files.filter(
    (file) => file.pivot.is_signature
  );
  if (signatureFile.length > 0) {
    dispatch({
      type: "SET_SIGNATURE",
      signature: signatureFile[0],
    });
  } else {
    dispatch({
      type: "SET_SIGNATURE",
      signature: null,
    });
  }
  if (parseInt(selQuestions[0].type) === 1) {
    const resultTasks = await getTasksForSubmit(selQuestions[0].id);
    const { tasks: selTasks } = resultTasks.data.data;
    dispatch({
      type: "SET_SELECTED_TASKS",
      selectedTasks: selTasks,
    });
    if (selQuestions[0].status === 1 && selTasks.length > 0) {
      const noAnswerTasks = selTasks.filter((t) => t.status !== 2);
      if (noAnswerTasks.length === 0) {
        selQuestions[0].filled = true;
      }
    }
  }
  const resultQuestionDetail = await getFeedbackQuestion(selQuestions[0].id);
  const { question: selectedQuestion } = resultQuestionDetail.data.data;
  dispatch({
    type: "SET_SELECTED_FILES",
    selectedFiles: selectedQuestion.files,
  });
  dispatch({
    type: "SET_SELECTED_COMMENTS",
    selectedComments: selectedQuestion.comments,
  });
  dispatch({
    type: "SET_CORRELATIVE",
    correlative: 0,
  });
  dispatch({
    type: "SET_SELECTED_QUESTIONS",
    selectedQuestions: selQuestions,
  });
  const resultResume = await getQuestionsResume(selSurvey.id);
  const { questions: resumeQuestions } = resultResume.data.data;
  dispatch({
    type: "SET_ANSWERS",
    answers: resumeQuestions,
  });
  dispatch({
    type: "TOGGLE_OPEN_SHOW_SURVEY",
    openShowSurvey: true,
  });
}

async function surveyResume(dispatch, surveyId, isReadOnly) {
  const result = await resumeSurvey(surveyId);
  const { survey: selSurvey } = result.data.data;
  const resultQuestion = await getQuestionForSubmit(
    selSurvey.current_question.qa_survey_submit_id,
    selSurvey.current_question.qa_question_id
  );
  const { questions: selQuestions } = resultQuestion.data.data;
  if (isReadOnly) {
    dispatch({
      type: "TOGGLE_READONLY",
      readonlySurvey: true,
    });
    dispatch({
      type: "SET_SHOW_ONLY_ANSWERED_TASKS",
      showOnlyAnsweredTasks: true,
    });
  } else {
    dispatch({
      type: "TOGGLE_READONLY",
      readonlySurvey: false,
    });
    dispatch({
      type: "SET_SHOW_ONLY_ANSWERED_TASKS",
      showOnlyAnsweredTasks: false,
    });
  }
  dispatch({
    type: "SET_SELECTED_JOB",
    selectedJobId: selSurvey.job_id,
  });
  dispatch({
    type: "SET_IS_RANDOMIZE",
    isRandomize: selSurvey.has_randomize,
  });
  dispatch({
    type: "SET_SURVEY_STATUS",
    surveyStatus: selSurvey.status,
  });
  dispatch({
    type: "SET_RANDOM_NUMBER",
    randomNumber: selSurvey.random_number,
  });
  dispatch({
    type: "SET_SELECTED_SURVEY",
    selectedSurvey: selSurvey.qa_survey,
  });
  const signatureFile = selSurvey.files.filter(
    (file) => file.pivot.is_signature
  );
  if (signatureFile.length > 0) {
    dispatch({
      type: "SET_SIGNATURE",
      signature: signatureFile[0],
    });
  } else {
    dispatch({
      type: "SET_SIGNATURE",
      signature: null,
    });
  }
  if (parseInt(selQuestions[0].type) === 1) {
    const resultTasks = await getTasksForSubmit(selQuestions[0].id);
    const { tasks: selTasks } = resultTasks.data.data;
    dispatch({
      type: "SET_SELECTED_TASKS",
      selectedTasks: selTasks,
    });
    if (parseInt(selQuestions[0].status) === 1 && selTasks.length > 0) {
      const noAnswerTasks = selTasks.filter((t) => parseInt(t.status) !== 2);
      if (noAnswerTasks.length === 0) {
        selQuestions[0].filled = true;
      }
    }
  }
  const resultQuestionDetail = await getFeedbackQuestion(selQuestions[0].id);
  const { question: selectedQuestion } = resultQuestionDetail.data.data;
  dispatch({
    type: "SET_SELECTED_FILES",
    selectedFiles: selectedQuestion.files,
  });
  dispatch({
    type: "SET_SELECTED_COMMENTS",
    selectedComments: selectedQuestion.comments,
  });
  dispatch({
    type: "SET_CORRELATIVE",
    correlative: 0,
  });
  dispatch({
    type: "SET_SELECTED_QUESTIONS",
    selectedQuestions: selQuestions,
  });
  const resultResume = await getQuestionsResume(selSurvey.id);
  const { questions: resumeQuestions } = resultResume.data.data;
  dispatch({
    type: "SET_ANSWERS",
    answers: resumeQuestions,
  });
  dispatch({
    type: "TOGGLE_OPEN_SHOW_SURVEY",
    openShowSurvey: true,
  });
}

export {
  SurveyFillProvider,
  useSurveyFillState,
  useSurveyFillDispatch,
  moveToQuestion,
  questionFill,
  surveyStart,
  surveyStartPublic,
  surveyResume,
};
