import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";

const InformationDialog = (props) => {
  const classes = useStyles();
  const { openInformation, answers, selectedSurvey, signature, lang } =
    useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const missingRequiredAnswers = answers
    .filter(
      (a) =>
        parseInt(a.sum_status) - parseInt(a.count) <= 0 &&
        parseInt(a.is_required)
    )
    .map((ans) => {
      return `, ${ans.order}`;
    });
  const missingAnswers = answers.filter(
    (a) => parseInt(a.sum_status) - parseInt(a.count) <= 0
  ).length;

  const handleClose = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_INFORMATION",
      openInformation: false,
    });
  };

  return (
    <Dialog
      open={openInformation}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <>
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <AssignmentIcon className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            {surveysLabelsLang[lang].surveySubmitMsgHeaderLabel}
          </Typography>
          {missingAnswers === answers.length ? (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {surveysLabelsLang[lang].noQuestionsAnswered}
            </Typography>
          ) : null}
          {missingRequiredAnswers.length > 0 ? (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {surveysLabelsLang[lang].surveySubmitMsgBodyLabel}{" "}
              <span className={classes.boldText}>{missingRequiredAnswers}</span>
            </Typography>
          ) : null}
          {parseInt(selectedSurvey.signature_confirmation) &&
          parseInt(selectedSurvey.signature_confirmation) === 1 &&
          !signature ? (
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {surveysLabelsLang[lang].signatureLabel}:{" "}
              <span className={classes.boldText}>
                {surveysLabelsLang[lang].questionRequiredLabel}
              </span>
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleClose}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            {surveysLabelsLang[lang].surveySubmitMsgButtonLabel}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
export default InformationDialog;
