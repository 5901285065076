import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { PartnerIcon } from "components/common/Icons/navIconsV2";
import MessageDialog from "./messageDialog";
import ErrorDialog from "components/ui/dialog/errorDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { deletePartner } from "services/partnerService";
import { usePartnerState } from "contexts/partnerContext";
import { usePartnerAction } from "contexts/partnerActionContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const DeletePartnerDialog = (props) => {
  const classes = useStyles();
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [partnerAction, dispatchPartnerAction] = usePartnerAction();
  const { partnerSelected } = usePartnerState();
  const { openDelete } = partnerAction;

  const handleCloseDialog = () => {
    dispatchPartnerAction({ type: "TOGGLE_DELETE", open: false });
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDialogOpen(true);
    try {
      await deletePartner(partnerSelected.id);

      props.refetchData();
      setIsLoadingDialogOpen(false);
      handleCloseDialog();
      setIsSuccessDialogOpen(true);
    } catch (ex) {
      setErrorMessage(
        ex.response &&
          (ex.response.status === 400 || ex.response.status === 401)
          ? ex.response.data.message
          : "Cannot delete partner."
      );
      setIsLoadingDialogOpen(false);
      setIsErrorDialogOpen(true);
      handleCloseDialog();
      logException(ex, "Cannot delete partner.");
    }
  };

  const handleCloseSuccessDialog = () => setIsSuccessDialogOpen(false);

  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);

  return (
    <>
      <Dialog
        open={isLoadingDialogOpen}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelete && !isLoadingDialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <PartnerIcon color="#747474" style={{ width: 35, height: 35 }} />
          <Typography variant="h6" gutterBottom className={classes.titleDelete}>
            Delete Partner
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete this partner?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDialog}
            className={classes.buttonOutlined}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message="The partner has been successfully deleted."
        handleClose={handleCloseSuccessDialog}
      />

      <ErrorDialog
        open={isErrorDialogOpen}
        title="Error!"
        message={errorMessage}
        handleClose={handleCloseErrorDialog}
      />
    </>
  );
};

export default DeletePartnerDialog;
