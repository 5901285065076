import React from "react";
import ManageCategoryDialog from "./manageCategoryDialog";
import LearnCategoriesDeleteDialog from "./learnCategoriesDeleteDialog";

const LearnCategoriesListDialog = (props) => {
  return (
    <>
      <ManageCategoryDialog />
      <LearnCategoriesDeleteDialog />
    </>
  );
};
export default LearnCategoriesListDialog;
