import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const RateActionContext = React.createContext();

const initialState = {
  openEditPartner: false,
  openRatePartner: false,
  rateToEdit: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_EDIT_PARTNER":
      return {
        ...state,
        openEditPartner: {
          open: action.open,
          rateToEdit: action.rateToEdit,
        },
        openRatePartner: action.open,
      };
    case "TOGGLE_RATE_PARTNER":
      return {
        ...state,
        openRatePartner: action.open,
        openEditPartner: { open: false, rateToEdit: null }, // Reset when not editing
      };
    case "SET_RATE_TO_EDIT":
      return {
        ...state,
        openEditPartner: {
          open: true,
          rateToEdit: action.rate,
        },
      };
    default:
      return state;
  }
};
const RateActionProvider = ({ children }) => (
  <RateActionContext.Provider value={React.useReducer(reducer, initialState)}>
    {children}
  </RateActionContext.Provider>
);

function useRateAction() {
  const context = React.useContext(RateActionContext);
  if (context === undefined) {
    throw new Error(
      "usePartnerAction must be used within a PartnerActionProvider"
    );
  }
  return context;
}

export { RateActionProvider, useRateAction };
