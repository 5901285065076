import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ThreeDots from "../icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { dateFormatField } from "../../../components/util/timeFormat";
import { useComments } from "./useComments";

export function CommentItem({ classes, comment, index }) {
  const { deleteComment, loadingState, dispatchEditComment, userId } =
    useComments();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment key={index}>
      <Box
        key={index}
        className={`${classes.commentContainer} ${
          userId === comment.user_id ? classes.commentContainerActive : ""
        }`}
        // onClick={() => setActiveIndex(index)}
      >
        <Box className={classes.commentsTopContainer}>
          {/*<Box className={classes.pictureCommentContainer}> </Box>*/}
          <Typography className={classes.commentNameText}>
            {comment.user_name}
          </Typography>
          <Box className={classes.commentDateMenuContainer}>
            {dateFormatField(comment.updated_at)}
            {userId === comment.user_id && (
              <Box
                className={classes.threeDotsContainerComments}
                onClick={handleClick}
              >
                <ThreeDots />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.commentsContainer}>{comment.body}</Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={loadingState}
          onClick={() => {
            dispatchEditComment(comment);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={loadingState}
          onClick={() => {
            deleteComment.mutate({
              id: comment.id,
              partner_id: userId,
            });
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
