import React, { useContext, useReducer } from "react";

const WorkticketViewContext = React.createContext();

const initialState = {
  workticket: {},
  isLoading: true,
  workticketFiles: [],
  workticketComments: [],
  workticketFileComments: null,
  actionFile: null,
  refreshData: false,
  refreshToggleComments: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_WORKTICKET":
      return {
        ...state,
        workticket: action.workticket,
      };
    case "SET_WORKTICKET_FILES":
      return {
        ...state,
        workticketFiles: action.workticketFiles,
      };
    case "SET_WORKTICKET_COMMENTS":
      return {
        ...state,
        workticketComments: action.workticketComments,
      };
    case "SET_WORKTICKET_FILE_COMMENTS":
      return {
        ...state,
        workticketFileComments: action.workticketFileComments,
      };
    case "SET_ACTION":
      return {
        ...state,
        actionFile: action.actionFile,
      };
    case "SET_REFRESH_DATA":
      return {
        ...state,
        refreshData: action.refreshData,
      };
    case "SET_REFRESH_TOGGLE_COMMENTS":
      return {
        ...state,
        refreshToggleComments: action.refreshToggleComments,
      };
    default:
      return state;
  }
};

const WorkticketViewProvider = ({ children }) => (
  <WorkticketViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </WorkticketViewContext.Provider>
);

const useWorkticketView = () => {
  const context = useContext(WorkticketViewContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketView must be used within a WorkticketViewProvider"
    );
  }
  return context;
};

export { WorkticketViewProvider, useWorkticketView };
