import React from "react";

const CalendarIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_492_8862)">
      <path
        d="M11.3333 8.00008H8V11.3334H11.3333V8.00008ZM10.6667 0.666748V2.00008H5.33333V0.666748H4V2.00008H3.33333C2.59333 2.00008 2.00667 2.60008 2.00667 3.33341L2 12.6667C2 13.4001 2.59333 14.0001 3.33333 14.0001H12.6667C13.4 14.0001 14 13.4001 14 12.6667V3.33341C14 2.60008 13.4 2.00008 12.6667 2.00008H12V0.666748H10.6667ZM12.6667 12.6667H3.33333V5.33341H12.6667V12.6667Z"
        fill="#747474"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_8862">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CalendarIcon;
