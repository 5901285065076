import React, { useState } from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateWorkticketDetails } from "services/workticketService";
import { useWorkticketState } from "contexts/workticketContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";

import useStyles from "./styles";

const DueDateSingleDialog = (props) => {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState(moment().add(30, "days"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const { workticketSelected } = useWorkticketState();
  const dispatchTable = useTableDispatch();
  const { rows } = useTableState();
  const { openDueDateSingle } = workticketAction;

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_DUEDATE_SINGLE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    try {
      const data = {
        start_date: moment(dueDate).format("YYYY-MM-DD"),
      };
      setIsLoadingDialog(true);

      await updateWorkticketDetails(workticketSelected.id, data);
      const foundIndex = rows.findIndex(
        (row) => row.id === workticketSelected.id
      );
      if (foundIndex !== undefined) {
        rows[foundIndex].start_date = moment(dueDate).format("YYYY-MM-DD");
        dispatchTable({ type: "UPDATE_ROWS", rows });
        dispatchTable({
          type: "SET_UPDATED_ROWS",
          rowsUpdated: [workticketSelected.id],
        });
        setTimeout(() => {
          dispatchTable({
            type: "SET_UPDATED_ROWS",
            rowsUpdated: [],
          });
        }, 5000);
      }
      handleClose();
      setIsLoadingDialog(false);
      setOpenSuccess(true);
    } catch (e) {
      console.log("Cannot update workticket due date");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openDueDateSingle}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Worktickets
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                When do you want to change the Due Date for these workticket?
              </Typography>
              <Box className={classes.formContainer}>
                <FormInputDateAdo
                  name="dueDate"
                  label="Due Date"
                  value={dueDate}
                  handleChange={setDueDate}
                />
              </Box>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Change
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket due date have changed.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default DueDateSingleDialog;
