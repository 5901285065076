import React from "react";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FieldLocation = (props) => {
  const classes = useStyles();
  const { jobs } = props;

  if (!jobs) {
    return "Error jobs";
  }
  if (!jobs.length) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const listJobs = jobs.map((job) => {
    return job.job_description;
  });

  return (
    <>
      <Typography
        variant="body2"
        aria-haspopup="true"
        className={classes.label}
      >
        {listJobs[1] ? "Various" : listJobs[0]}
      </Typography>
    </>
  );
};

export default FieldLocation;
