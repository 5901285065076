import React, { useState } from "react";
import { ButtonBase } from "components/ui/core/button";
import DownloadIcon from "@material-ui/icons/GetApp";
import LoadingIcon from "@material-ui/icons/Cached";
import { getFileView } from "services/fileService";
import { logException } from "components/util/logUtil";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
}));

const TimekeepingDownload = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { fileId } = props;

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const response = await getFileView(fileId);
      window.open(response.data.data.file.presigned_url);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot download exported file");
    }
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        color="secondary"
        size="small"
        variant="text"
        disabled={isLoading}
        onClick={handleDownload}
      >
        Download {isLoading ? <LoadingIcon /> : <DownloadIcon />}
      </ButtonBase>
    </div>
  );
};

export default TimekeepingDownload;
