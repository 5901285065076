import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchBar from "components/common/SearchBar/searchBar";

import { useTableCollapsibleState } from "contexts/tableCollapsibleContext";

const TableCollapsibleToolbarDefault = (props) => {
  const classes = useStyles();
  const { hasSearch, hasFilters } = useTableCollapsibleState();

  return (
    <Box className={classes.toolbarControls}>
      {hasSearch ? (
        <SearchBar
          handleSearch={props.handleSearch}
          handleSearchClear={props.handleSearchClear}
          placeholder={props.placeholderSearch}
          controlledSearch={props.controlledSearch}
        />
      ) : null}
      {hasFilters ? (
        <Box className={classes.toolbarFilters}>{props.children}</Box>
      ) : null}
    </Box>
  );
};

export default TableCollapsibleToolbarDefault;
