import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SwitchSettings from "./switchSettings";
import {
  QualityDark,
  QualitySettingsDark,
  TeamDark,
  CSATDark,
  QuoteDark,
  WorkticketDark,
} from "components/common/Icons/navIconsV2";
import useStyles from "./styles";

const metricsList = [
  { value: "labor", label: "Labor", icon: <TeamDark /> },
  { value: "quality", label: "Quality", icon: <QualitySettingsDark /> },
  { value: "worktickets", label: "Worktickets", icon: <WorkticketDark /> },
  { value: "evaluations", label: "Evaluations", icon: <QualityDark /> },
  { value: "add-on-sales", label: "Add-On Projects", icon: <QuoteDark /> },
  {
    value: "customer-satisfaction",
    label: "Customer Satisfaction",
    icon: <CSATDark />,
  },
];

const financialList = [
  { value: "revenue", label: "Revenue" },
  { value: "budget", label: "Budget" },
  { value: "cost", label: "Cost" },
  { value: "contribution-margin", label: "Contribution Margin" },
];

const breakdownOptions = [
  { value: "customer", label: "Customer", level: [1] },
  { value: "delivery-model", label: "Delivery Model", level: [1] },
  { value: "director", label: "Director", level: [1, 2, 3] },
  { value: "industry", label: "Industry", level: [1, 2, 3] },
  { value: "job", label: "Job", level: [1, 2, 3] },
  { value: "manager", label: "Manager", level: [1, 2, 3] },
  { value: "date", label: "Date", level: [1, 2, 3] },
];

const dateOptions = [
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
  { value: "bi-weekly", label: "Bi Weekly" },
];

const SideSettings = ({ isOpen, handleClose }) => {
  const [selectedOptions, setSelectedOptions] = useState(["labor"]);
  const [selectedFinancialOptions, setSelectedFinancialOptions] = useState([]);
  const [breakdownLevel, setBreakdownLevel] = useState(1);
  const [breakdownLevel1, setBreakdownLevel1] = useState(null);
  const [breakdownLevel2, setBreakdownLevel2] = useState(null);
  const [breakdownLevel3, setBreakdownLevel3] = useState(null);
  const [selectedBreakdownOptions, setSelectedBreakdownOptions] = useState([]);
  const [breakdownDate, setBreakdownDate] = useState(null);
  const [hasDateOption, setHasDateOption] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    let optionsSelected = [];
    if (breakdownLevel1?.value) {
      optionsSelected = [...optionsSelected, breakdownLevel1.value];
    }
    if (breakdownLevel2?.value) {
      optionsSelected = [...optionsSelected, breakdownLevel2.value];
    }

    if (breakdownLevel3?.value) {
      optionsSelected = [...optionsSelected, breakdownLevel3.value];
    }
    if (
      breakdownLevel1?.value === "date" ||
      breakdownLevel2?.value === "date" ||
      breakdownLevel3?.value === "date"
    ) {
      setHasDateOption(true);
    } else {
      setHasDateOption(false);
    }
    setSelectedBreakdownOptions(optionsSelected);
  }, [breakdownLevel1, breakdownLevel2, breakdownLevel3]);

  const handleChange = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleFinancialChange = (value) => {
    if (selectedFinancialOptions.includes(value)) {
      setSelectedFinancialOptions(
        selectedFinancialOptions.filter((item) => item !== value)
      );
    } else {
      setSelectedFinancialOptions([...selectedFinancialOptions, value]);
    }
  };

  const handleChangeBreakdown1 = (event, value) => {
    setBreakdownLevel1(value);
    setBreakdownLevel(1);
    setBreakdownLevel2(null);
    setBreakdownLevel3(null);
  };

  const handleChangeBreakdown2 = (event, value) => {
    setBreakdownLevel(2);
    setBreakdownLevel2(value);
    setBreakdownLevel3(null);
  };

  const handleChangeBreakdown3 = (event, value) => {
    setBreakdownLevel3(value);
  };

  const handleChangeBreakdownDate = (event, value) => {
    setBreakdownDate(value);
  };

  const handleAddBreakdown = () => {
    setBreakdownLevel((prev) => prev + 1);
  };

  const handleRemoveBreakdown2 = () => {
    setBreakdownLevel(1);
    setBreakdownLevel2(null);
    setBreakdownLevel3(null);
  };

  const handleRemoveBreakdown3 = () => {
    setBreakdownLevel(2);
    setBreakdownLevel3(null);
  };

  return (
    <Drawer anchor="right" open={isOpen} className={classes.drawerWrapper}>
      <IconButton
        className={classes.buttonClose}
        aria-label="Close"
        onClick={handleClose}
      >
        <CloseIcon className={classes.iconClose} />
      </IconButton>
      <Box className={classes.drawerContent}>
        <Box className={classes.sectionSettings}>
          <Typography variant="h5" className={classes.titleSettings}>
            Breakdown
          </Typography>
          <Box className={classes.breakdownContainer}>
            <Box className={classes.breakdownItem}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={breakdownOptions.filter(
                  (item) =>
                    item.level.includes(1) &&
                    (!selectedBreakdownOptions.includes(item.value) ||
                      selectedBreakdownOptions.includes(breakdownLevel1?.value))
                )}
                name="breakdown_level_1"
                handleChange={handleChangeBreakdown1}
                value={breakdownLevel1}
                placeholder="Select top layer breakdown"
              />
            </Box>
            {breakdownLevel >= 2 ? (
              <Box className={classes.breakdownItem2}>
                <IconButton
                  aria-label="remove-breakdown-2"
                  onClick={handleRemoveBreakdown2}
                  size="small"
                  className={classes.breakdownButtonRemove}
                >
                  <RemoveIcon />
                </IconButton>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={breakdownOptions.filter(
                    (item) =>
                      item.level.includes(2) &&
                      (!selectedBreakdownOptions.includes(item.value) ||
                        selectedBreakdownOptions.includes(
                          breakdownLevel2?.value
                        ))
                  )}
                  name="breakdown_level_2"
                  handleChange={handleChangeBreakdown2}
                  value={breakdownLevel2}
                  placeholder="Then by"
                />
              </Box>
            ) : null}
            {breakdownLevel >= 3 ? (
              <Box className={classes.breakdownItem3}>
                <IconButton
                  aria-label="remove-breakdown-3"
                  onClick={handleRemoveBreakdown3}
                  size="small"
                  className={classes.breakdownButtonRemove}
                >
                  <RemoveIcon />
                </IconButton>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={breakdownOptions.filter(
                    (item) =>
                      item.level.includes(3) &&
                      (!selectedBreakdownOptions.includes(item.value) ||
                        selectedBreakdownOptions.includes(
                          breakdownLevel3?.value
                        ))
                  )}
                  name="breakdown_level_3"
                  handleChange={handleChangeBreakdown3}
                  value={breakdownLevel3}
                  placeholder="Then by"
                />
              </Box>
            ) : null}
            {hasDateOption ? (
              <Box className={classes.breakdownItemDate}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={dateOptions}
                  name="breakdown_date"
                  handleChange={handleChangeBreakdownDate}
                  value={breakdownDate}
                  placeholder="Select Interval"
                />
              </Box>
            ) : null}
            {breakdownLevel < 3 &&
            !hasDateOption &&
            !selectedBreakdownOptions.includes("job") ? (
              <Box className={classes.breakdownActions}>
                <IconButton
                  aria-label="add-breakdown"
                  onClick={handleAddBreakdown}
                  size="small"
                  className={classes.breakdownButtonActions}
                >
                  <AddIcon />
                </IconButton>
                <Typography
                  variant="h5"
                  className={classes.breakdownTitleActions}
                >
                  Add Breakdown
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box className={classes.sectionSettings}>
          <Typography variant="h5" className={classes.titleSettings}>
            Metrics
          </Typography>
          {metricsList.map((metricItem) => (
            <Box className={classes.itemSettings} key={metricItem.value}>
              <Box>
                <Box className={classes.itemInnerImage}>{metricItem.icon}</Box>
              </Box>
              <Box className={classes.itemSettingsName}>
                <Typography variant="h5" className={classes.itemTitleSettings}>
                  {metricItem.label}
                </Typography>
              </Box>
              <Box>
                <SwitchSettings
                  checked={selectedOptions.includes(metricItem.value)}
                  onChange={() => handleChange(metricItem.value)}
                  name={metricItem.value}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={classes.sectionSettings}>
          <Typography variant="h5" className={classes.titleSettings}>
            Financial
          </Typography>
          {financialList.map((financialItem) => (
            <Box className={classes.itemSettings} key={financialItem.value}>
              <Box className={classes.itemSettingsName}>
                <Typography variant="h5" className={classes.itemTitleSettings}>
                  {financialItem.label}
                </Typography>
              </Box>
              <Box>
                <SwitchSettings
                  checked={selectedFinancialOptions.includes(
                    financialItem.value
                  )}
                  onChange={() => handleFinancialChange(financialItem.value)}
                  name={financialItem.value}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default withRouter(SideSettings);
