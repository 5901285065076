import React from "react";
import { merge } from "lodash";

const defaultState = {
  openPositionsListLoading: true,
  openPositionsSelected: null,
  openPositionsList: [],
  openPositionsCount: [],
  openOpenPositions: false,
  openOpenPositionsSingle: false,
  openPositionsManager: null,
  currentSelectedTab: 1,
};

const OpenPositionsStateContext = React.createContext();
const OpenPositionsDispatchContext = React.createContext();

function openPositionsReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentSelectedTab: action.currentSelectedTab,
      };
    case "SET_LOADING":
      return {
        ...state,
        openPositionsListLoading: action.openPositionsListLoading,
      };
    case "SET_OPEN_POSITIONS_LIST":
      return {
        ...state,
        openPositionsList: action.openPositionsList,
      };
    case "SET_OPEN_POSITIONS_COUNT":
      return {
        ...state,
        openPositionsCount: action.openPositionsCount,
      };
    case "SET_OPEN_POSITIONS_SELECTED":
      return {
        ...state,
        openPositionsSelected: action.openPositionsSelected,
      };

    case "TOGGLE_OPEN_POSITIONS":
      return {
        ...state,
        openOpenPositions: action.openOpenPositions,
      };

    case "TOGGLE_OPEN_POSITIONS_SINGLE":
      return {
        ...state,
        openOpenPositionsSingle: action.openOpenPositionsSingle,
      };

    case "SET_MANAGER":
      return {
        ...state,
        openPositionsManager: action.openPositionsManager,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function OpenPositionsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(openPositionsReducer, mergedState);
  return (
    <OpenPositionsStateContext.Provider value={state}>
      <OpenPositionsDispatchContext.Provider value={dispatch}>
        {children}
      </OpenPositionsDispatchContext.Provider>
    </OpenPositionsStateContext.Provider>
  );
}

function useOpenPositionsState() {
  const context = React.useContext(OpenPositionsStateContext);
  if (context === undefined) {
    throw new Error(
      "useOpenPositionsState must be used within a OpenPositionsProvider"
    );
  }
  return context;
}

function useOpenPositionsDispatch() {
  const context = React.useContext(OpenPositionsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useOpenPositionsDispatch must be used within a OpenPositionsProvider"
    );
  }
  return context;
}

async function getOpenPositionsList(dispatch) {
  try {
    dispatch({
      type: "SET_LOADING",
      openPositionsListLoading: true,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getOpenPositionsListDataSet() {
  try {
  } catch (error) {
    console.log(error);
  }
}

async function searchOpenPositionsList() {
  try {
  } catch (error) {
    console.log(error);
  }
}

export {
  OpenPositionsProvider,
  useOpenPositionsState,
  useOpenPositionsDispatch,
  getOpenPositionsListDataSet,
  getOpenPositionsList,
  searchOpenPositionsList,
};
