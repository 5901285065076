import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    marginLeft: theme.spacing(4),
    cursor: "pointer",
  },

  paper: {
    padding: 10,
    fontSize: 14,
    "& a": {
      color: "#747474",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

export default useStyles;
