import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmDialog from "./dialog/confirmDialog";

const QuoteAdminActionsService = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const open = Boolean(anchorEl);
  const { row, mobile, handleDelete, handleView } = props;

  const options = [
    { id: 1, label: "Edit", value: "EDIT" },
    { id: 3, label: "Delete", value: "DELETE" }
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async event => {
    const action = event.currentTarget.getAttribute("data");

    if (action === "EDIT") {
      handleView(row);
    }

    if (action === "DELETE") {
      setOpenDelete(true);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      handleDelete(row.id);
      setOpenDelete(false);
    } catch (e) {
      console.log("Cannot delete item");
    }
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200
          }
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <ConfirmDialog
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleClose={handleCloseDelete}
        message={"Are you sure you want to delete this service?"}
        quoteId={row.name}
      />
    </div>
  );
};

export default QuoteAdminActionsService;
