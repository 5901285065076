import React from "react";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { JobsProvider } from "contexts/projectJobsContext";
import Box from "@material-ui/core/Box";
import JobsTable from "./jobsTable";
import useStyles from "./styles";

const JobsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <JobsProvider>
        <JobsTable />
      </JobsProvider>
    </Box>
  );
};

export default withTableFilterContext(JobsList);
