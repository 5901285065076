import React, { useState, useRef, useEffect } from "react";
import * as classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { ButtonBase } from "components/ui/core/button";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const LIMIT_TASK_NAME = 255;

const ServicesTask = (props) => {
  const classes = useStyles();
  const inputTaskRef = useRef();
  const [addTask, setAddTask] = useState(false);
  const [error, setError] = useState(false);

  const { taskList, onChange } = props;

  const handleTaskAdd = () => {
    setAddTask(true);
    setTimeout(() => {
      inputTaskRef.current.focus();
    }, 200);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  const handleSaveItem = async () => {
    const value = inputTaskRef.current.value;
    if (value !== "") {
      let data = null;
      if (value.length > LIMIT_TASK_NAME) {
        setError(true);
        data = {
          id: new Date().getTime(),
          name: value.substring(0, LIMIT_TASK_NAME),
        };
      } else {
        data = { id: new Date().getTime(), name: value };
      }

      try {
        if (taskList) {
          onChange([...taskList, data]);
        } else {
          onChange([data]);
        }
        inputTaskRef.current.value = "";
      } catch (e) {
        logException(e, "Cannot create task");
      }
    }
    setAddTask(false);
  };

  const handleDeleteTask = async (taskId) => {
    try {
      if (taskList) {
        const data = taskList.filter((task) => task.id !== taskId);
        onChange([...data]);
      }
    } catch (e) {
      logException(e, "Cannot delete workticket");
    }
  };

  const handleTaskAddSubmit = () => {};

  return (
    <Box>
      {error && (
        <Alert severity="error" className={classes.containerAlert}>
          The task name must be less than {LIMIT_TASK_NAME} characters. Task
          name has been cut.
        </Alert>
      )}
      <FormControl component="fieldset" className={classes.taskItemFieldset}>
        <FormGroup
          aria-label="position"
          className={classes.taskItemContainer}
          row
        >
          {taskList &&
            taskList.map((task) => (
              <FormControlLabel
                value={task.name}
                key={task.id}
                checked={false}
                control={
                  <Checkbox
                    className={classes.tableCheckbox}
                    color="secondary"
                  />
                }
                label={
                  <Box className={classes.taskLabel}>
                    {task.name}
                    <IconButton
                      aria-label="delete"
                      className={classes.deleteIcon}
                      onClick={() => handleDeleteTask(task.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
                labelPlacement="end"
                className={classes.taskItem}
              />
            ))}
          {(!taskList || taskList.length === 0) && !addTask && (
            <Box className={classes.taskContainerEmpty}>
              <Typography variant="h5" gutterBottom>
                No tasks have been created.
              </Typography>
            </Box>
          )}

          <FormControlLabel
            value={"new"}
            style={{ display: addTask ? "inline-flex" : "none" }}
            control={
              <Checkbox className={classes.tableCheckbox} color="secondary" />
            }
            label={
              <Input
                defaultValue=""
                className={classes.inputNewTask}
                disableUnderline={true}
                inputProps={{ "aria-label": "description" }}
                onBlur={handleSaveItem}
                inputRef={inputTaskRef}
              />
            }
            labelPlacement="end"
            className={classNames(classes.taskItem, classes.taskItemAdd)}
          />
        </FormGroup>
      </FormControl>
      <Box className={classes.taskBoxActions}>
        {!addTask ? (
          <ButtonBase
            color="secondary"
            size="small"
            disableElevation
            onClick={handleTaskAdd}
          >
            Add Task
          </ButtonBase>
        ) : (
          <ButtonBase
            color="secondary"
            size="small"
            disableElevation
            onClick={handleTaskAddSubmit}
          >
            Save Task
          </ButtonBase>
        )}
      </Box>
    </Box>
  );
};

export default ServicesTask;
