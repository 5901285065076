import React, { useContext, useReducer } from "react";

const ProjectViewContext = React.createContext();

const initialState = {
  project: {},
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading
      };
    case "SET_PROJECT":
      return {
        ...state,
        project: action.project
      };
    default:
      return state;
  }
};

const ProjectViewProvider = ({ children }) => (
  <ProjectViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ProjectViewContext.Provider>
);

const useProjectView = () => {
  const context = useContext(ProjectViewContext);
  if (context === undefined) {
    throw new Error(
      "useProjectView must be used within a ProjectViewProvider"
    );
  }
  return context;
};

export { ProjectViewProvider, useProjectView };
