import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useQuoteState } from "contexts/quoteSurfaceContext";

const QuoteSurfaceBreadcrum = props => {
  const stateContext = useQuoteState();
  const { selectedQuote, isLoading, quoteDetails } = stateContext
    ? stateContext
    : null;

  if ((isLoading && selectedQuote !== "new") || !selectedQuote) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  if (props.pendingPlanning) {
    return (
      <PageHeader pageTitle="Workticket Creation">
        <Link to="/quotes/surface">Quotes</Link>
        <Link to={`/quote/surface/${selectedQuote}`}>
          {quoteDetails.number ? quoteDetails.number : `QUO-${selectedQuote}`}
        </Link>
      </PageHeader>
    );
  }

  if (!isLoading && selectedQuote !== "new") {
    return (
      <PageHeader
        pageTitle={
          quoteDetails.number ? quoteDetails.number : `QUO-${selectedQuote}`
        }
      >
        <Link to="/quotes/surface">Quotes</Link>
      </PageHeader>
    );
  } else {
    return (
      <PageHeader pageTitle={`New`}>
        <Link to="/quotes/surface">Quotes</Link>
      </PageHeader>
    );
  }
};

export default QuoteSurfaceBreadcrum;
