import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useNoticesDispatch } from "contexts/noticesContext";
import { permissionTalent, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

const NoticesTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchNotices = useNoticesDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const options = [
    {
      id: 1,
      label: "View",
      value: "VIEW",
    },
  ];

  if (hasPermission(permissionTalent.DELETE_NOTICE, permissions)) {
    options.push({
      id: 2,
      label: "Delete",
      value: "DELETE",
    });
  }

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";
    switch (action) {
      case "VIEW":
        dispatchNotices({
          type: "TOGGLE_OPENNOTICE_READONLY",
          openNoticesReadOnly: true,
        });
        dispatchNotices({
          type: "SET_NOTICES_DATA",
          noticesData: row,
        });
        dispatchNotices({
          type: "TOGGLE_OPENNOTICE",
          openNoticesDrawer: true,
        });
        break;
      case "DELETE":
        dispatchNotices({
          type: "TOGGLE_DELETE",
          openNoticesDelete: true,
        });
        break;
      default:
        break;
    }
    dispatchNotices({
      type: "SET_NOTICES_SELECTED",
      noticesSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default NoticesTableActions;
