import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "80vh",
  },
  wrapperDetail: {
    marginTop: theme.spacing(10),
    padding: 20,
    height: "90vh",
  },
  rowHeader: {
    fontWeight: "bold",
    backgroundColor: "#ececec",
  },
}));

export default useStyles;
