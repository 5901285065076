import React from "react";

const ContactIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_492_8869)">
      <path
        d="M8.77984 2.66659L11.9998 5.88658V13.3333H3.99984V2.66659H8.77984ZM9.33317 1.33325H3.99984C3.2665 1.33325 2.6665 1.93325 2.6665 2.66659V13.3333C2.6665 14.0666 3.2665 14.6666 3.99984 14.6666H11.9998C12.7332 14.6666 13.3332 14.0666 13.3332 13.3333V5.33325L9.33317 1.33325ZM7.99984 9.33325C8.73317 9.33325 9.33317 8.73325 9.33317 7.99992C9.33317 7.26658 8.73317 6.66658 7.99984 6.66658C7.2665 6.66658 6.6665 7.26658 6.6665 7.99992C6.6665 8.73325 7.2665 9.33325 7.99984 9.33325ZM10.6665 11.6199C10.6665 11.0799 10.3465 10.5999 9.85317 10.3866C9.2865 10.1399 8.65984 9.99992 7.99984 9.99992C7.33984 9.99992 6.71317 10.1399 6.1465 10.3866C5.65317 10.5999 5.33317 11.0799 5.33317 11.6199V11.9999H10.6665V11.6199Z"
        fill="#747474"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_8869">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ContactIcon;
