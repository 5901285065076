import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import JobBudgetsTable from "./jobBudgetsTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { ListProvider } from "contexts/listContext";
import { getJobDetail } from "services/jobService";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles";

const JobBudgetsList = (props) => {
  const [job, setJob] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const loadJobs = async () => {
      const result = await getJobDetail(props.match.params.id);
      setJob(result?.data.data?.job);
    };
    loadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!job) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <PageHeader
        pageTitle={`${job?.job_number} -  ${job?.job_description}`}
        link={`job/${job?.id}/budgets`}
      >
        <Link to="/customers">Customers</Link>
        <Link
          to={`/customer/${job?.customer_id}`}
        >{`${job?.customer_name}`}</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <JobBudgetsTable id={props.match.params.id} />
        </ListProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(JobBudgetsList);
