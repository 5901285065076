import React from "react";

const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_100_1222)">
      <path
        d="M16.0001 2.66663C8.64008 2.66663 2.66675 8.63996 2.66675 16C2.66675 23.36 8.64008 29.3333 16.0001 29.3333C23.3601 29.3333 29.3334 23.36 29.3334 16C29.3334 8.63996 23.3601 2.66663 16.0001 2.66663ZM13.3334 22.6666L6.66675 16L8.54675 14.12L13.3334 18.8933L23.4534 8.77329L25.3334 10.6666L13.3334 22.6666Z"
        fill="url(#paint0_linear_100_1222)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_100_1222"
        x1="14.5966"
        y1="2.66663"
        x2="27.9299"
        y2="31.4386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B1CF33" />
        <stop offset="1" stop-color="#1A6930" />
      </linearGradient>
      <clipPath id="clip0_100_1222">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SuccessIcon;
