import React from "react";
import { merge } from "lodash";

const defaultState = {
  frontlineSurveyListLoading: true,
  frontlineSurveySelected: null,
  frontlineSurveyList: [{}, {}],
  frontlineSurveyCount: [],
  openSurvey: false,
  openSurveySingle: false,
  frontlineSurveyManager: null,
  currentSelectedTab: 1,
};

const SurveyStateContext = React.createContext();
const SurveyDispatchContext = React.createContext();

function frontlineSurveyReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentSelectedTab: action.currentSelectedTab,
      };
    case "SET_LOADING":
      return {
        ...state,
        frontlineSurveyListLoading: action.frontlineSurveyListLoading,
      };
    case "SET_FRONTLINE_SURVEY_LIST":
      return {
        ...state,
        frontlineSurveyList: action.frontlineSurveyList,
      };
    case "SET_FRONTLINE_SURVEY_COUNT":
      return {
        ...state,
        frontlineSurveyCount: action.frontlineSurveyCount,
      };
    case "SET_FRONTLINE_SURVEY_SELECTED":
      return {
        ...state,
        frontlineSurveySelected: action.frontlineSurveySelected,
      };

    case "TOGGLE_SURVEY":
      return {
        ...state,
        openSurvey: action.openSurvey,
      };

    case "TOGGLE_FRONTLINE_SURVEY_SINGLE":
      return {
        ...state,
        openSurveySingle: action.openSurveySingle,
      };

    case "SET_MANAGER":
      return {
        ...state,
        frontlineSurveyManager: action.frontlineSurveyManager,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FrontlineSurveyProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(
    frontlineSurveyReducer,
    mergedState
  );
  return (
    <SurveyStateContext.Provider value={state}>
      <SurveyDispatchContext.Provider value={dispatch}>
        {children}
      </SurveyDispatchContext.Provider>
    </SurveyStateContext.Provider>
  );
}

function useFrontlineSurveyState() {
  const context = React.useContext(SurveyStateContext);
  if (context === undefined) {
    throw new Error(
      "useFrontlineSurveyState must be used within a FrontlineSurveyProvider"
    );
  }
  return context;
}

function useFrontlineSurveyDispatch() {
  const context = React.useContext(SurveyDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useFrontlineSurveyDispatch must be used within a FrontlineSurveyProvider"
    );
  }
  return context;
}

async function getFrontlineSurveyList(dispatch) {
  try {
    dispatch({
      type: "SET_LOADING",
      frontlineSurveyListLoading: true,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getFrontlineSurveyListDataSet() {
  try {
  } catch (error) {
    console.log(error);
  }
}

async function searchFrontlineSurveyList() {
  try {
  } catch (error) {
    console.log(error);
  }
}

export {
  FrontlineSurveyProvider,
  useFrontlineSurveyState,
  useFrontlineSurveyDispatch,
  getFrontlineSurveyListDataSet,
  getFrontlineSurveyList,
  searchFrontlineSurveyList,
};
