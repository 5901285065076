import React from "react";

const LearnIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_388_41551)">
      <path
        d="M10.255 24.7128L12.3748 25.5606V10.9046H10.255L7.48224 13.2326L10.255 24.7128Z"
        fill="#17457A"
        fillOpacity="0.1"
      />
      <path
        d="M30.5469 21.954C29.9701 21.954 29.5045 21.4884 29.5045 20.9116V14.2681C29.5045 13.6913 29.9701 13.2257 30.5469 13.2257C31.1237 13.2257 31.5893 13.6913 31.5893 14.2681V20.9116C31.5893 21.4884 31.1237 21.954 30.5469 21.954Z"
        fill="#78C1CE"
      />
      <path
        d="M17.9965 19.1604C17.8853 19.1604 17.7741 19.1395 17.6629 19.1048L3.70877 14.4279C3.27791 14.282 2.99299 13.8789 2.99994 13.4203C3.00689 12.9686 3.31266 12.5655 3.74351 12.4404L17.6976 8.2917C17.8922 8.2361 18.1007 8.2361 18.2883 8.2917L32.2425 12.4404C32.6803 12.5724 32.9791 12.9686 32.986 13.4203C32.993 13.872 32.7081 14.282 32.2772 14.4279L18.3231 19.1048C18.2258 19.1395 18.1077 19.1604 17.9965 19.1604ZM7.50307 13.4967L18.0034 17.013L28.5038 13.4967L17.9965 10.3834L7.50307 13.4967Z"
        fill="#17457A"
      />
      <path
        d="M18.0035 27.3119C14.7095 27.3119 7.90619 26.4849 7.90619 23.9345V15.2479H9.99097V23.6913C10.6512 24.1847 13.6324 25.234 18.0035 25.234C22.3815 25.234 25.3628 24.1847 26.009 23.6913V15.0811H28.0938V23.9276C28.0938 23.9901 28.0869 24.0457 28.0799 24.1013C27.7742 26.5266 21.214 27.3119 18.0035 27.3119Z"
        fill="#17457A"
      />
    </g>
    <defs>
      <clipPath id="clip0_388_41551">
        <rect
          width="30"
          height="19.0618"
          fill="white"
          transform="translate(3 8.25)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LearnIcon;
