import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import ActivityFilters from "./activityFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import CalendarFilters from "components/common/Filters/calendarFilters";
import TimelineIcon from "@material-ui/icons/Timeline";
import useStyles from "./styles";
import { useCustomerView } from "contexts/customerViewContext";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useActivityState,
  useActivityDispatch,
  getActivityList,
  searchActivities,
  getActivityListDataSet,
} from "contexts/activityContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const ActivitysTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { activityListLoading, activityList, activityCount } =
    useActivityState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchActivity = useActivityDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContext] = useCustomerView();
  const { customer } = stateContext ?? null;

  const columns = [
    {
      id: "activity",
      label: "Activity",
      content: (row) => (
        <Box className={classes.activityContainer}>
          <TimelineIcon className={classes.activityIcon} />
          {row.activity}
        </Box>
      ),
    },
    {
      id: "user_name",
      label: "User",
    },
    {
      id: "date",
      label: "Date",
      format: "date",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getActivityList(customer.id, dispatchActivity, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [customer.id, dispatchActivity, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: activityList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, activityList]);

  const handleScrollClick = useCallback(async () => {
    if (activityCount <= activityList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getActivityListDataSet(
        customer.id,
        activityList,
        dispatchActivity,
        activityList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: activityList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    customer.id,
    activityList,
    activityCount,
    filterState,
    dispatchActivity,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchActivities(customer.id, query, filterData);
          dispatchActivity({
            type: "SET_ACTIVITY_LIST",
            activityList: results,
          });

          dispatchActivity({
            type: "SET_ACTIVITY_COUNT",
            activityCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {activityListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>{`${activityList.length} / ${activityCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <div>
                      <EnhancedTableToolbar>
                        {selected.length > 0 ? (
                          <ToolbarSelected />
                        ) : (
                          <ToolbarDefault
                            handleSearch={handleSearch}
                            placeholderSearch={"All Activities"}
                            handleSearchClear={handleSearchClear}
                          >
                            <ActivityFilters />
                            <CalendarFilters />
                          </ToolbarDefault>
                        )}
                      </EnhancedTableToolbar>
                    </div>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(ActivitysTable);
