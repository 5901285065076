import React, { useEffect, useState } from "react";
import eosOneLogo from "../../../assets/eos-one-logo.svg";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import { getUserPermissions } from "services/userService";

const BannerDownloadApp = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const getRole = async () => {
      const resultPermissions = await getUserPermissions();
      const role = resultPermissions.data.data.role;
      setRole(role);
    };
    getRole();
  }, []);

  const handleClose = (event) => {
    event.stopPropagation();
    setIsVisible(false);
  };

  const handleDownloadApp = () => {
    const universalLinkUrl = `${window.location.protocol}//${window.location.hostname}/onboard/welcome-sign-in`;
    window.location = universalLinkUrl;
  };

  if (role?.category !== "subcontractor") {
    return null;
  }

  return (
    <>
      {isVisible && (
        <Box className={classes.bannerContainer} onClick={handleDownloadApp}>
          <Box className={classes.bannerImageContainer}>
            <img
              className={classes.bannerIcon}
              src={eosOneLogo}
              alt="eos-one"
            />
          </Box>
          <Typography variant="body1" className={classes.bannerAppMessage}>
            Use our mobile app for a better experience on the go!
          </Typography>
          <IconButton
            className={classes.bannerCloseButton}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default BannerDownloadApp;
