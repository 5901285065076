import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;

export async function getCatalogJobs() {
  return await http.get(`${apiEndpoint}/catalog/jobs`);
}

export async function getCatalogCustomers() {
  return await http.get(`${apiEndpoint}/catalog/customers`);
}

export async function getPartnerJobs(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/jobs`);
}

export async function assignPartnerJobs(partner_id, data) {
  return await http.post(`${apiEndpoint}/${partner_id}/assign-jobs`, data);
}

export async function removePartnerJobs(partner_id, data) {
  return await http.post(`${apiEndpoint}/${partner_id}/remove-jobs`, data);
}
