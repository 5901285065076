import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateQuotesBulk } from "services/quoteService";
import {
  useQuoteState,
  useQuoteDispatch,
  getQuoteTabList,
} from "contexts/quoteTableContext";
import { useFilterState } from "contexts/filterContext";
import { useQuoteAction } from "contexts/quoteActionContext";
import { useTableDispatch } from "contexts/tableContext";

import { useProjectView } from "contexts/projectViewContext";

import useStyles from "./styles";

const ApproveSingleDialog = (props) => {
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const classes = useStyles();
  const dispatchTable = useTableDispatch();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [quoteAction, dispatchQuoteAction] = useQuoteAction();
  const { quoteSelected } = useQuoteState();
  const dispatchQuotes = useQuoteDispatch();
  const { openApproveSingle } = quoteAction;
  const {
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();

  const handleClose = () => {
    dispatchQuoteAction({
      type: "TOGGLE_APPROVE_SINGLE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      const approveStatus = 5;
      const data = {
        quotes_ids: [quoteSelected.id],
        status: approveStatus,
      };
      const response = await updateQuotesBulk(data);
      if (response.data.data.successes.length > 0) {
        const filterData = {
          filters: filters,
          dateFilters: {
            startDate,
            startDateActive,
            endDate,
            endDateActive,
          },
        };
        await getQuoteTabList(dispatchQuotes, project.id, filterData);

        dispatchTable({
          type: "SET_UPDATED_ROWS",
          rowsUpdated: [quoteSelected.id],
        });

        setTimeout(() => {
          dispatchTable({
            type: "SET_UPDATED_ROWS",
            rowsUpdated: [],
          });
        }, 5000);
        setOpenSuccess(true);
      } else {
        setMessage(response.data.data.messages[0]);
        setOpenFailure(true);
      }
      handleClose();
      setIsLoadingDialog(false);
    } catch (e) {
      console.log("Cannot mark quote as approved" + e);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  return (
    <>
      <Dialog
        open={openApproveSingle}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuoteIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Quotes
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to mark quote as approved?
              </Typography>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Approve
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These quote has been marked as approved.`}
        handleClose={handleCloseSuccess}
      />
      <MessageDialog
        noSuccess={true}
        open={openFailure}
        title={""}
        message={message}
        handleClose={handleCloseFailure}
      />
    </>
  );
};
export default ApproveSingleDialog;
