import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LearnCategoriesTableActions from "./learnCategoriesTableActions";
import useStyles from "./styles";
import { ButtonBase } from "components/ui/core/button";

import { useTableDispatch } from "contexts/tableContext";
import {
  useLearnCategoriesState,
  useLearnCategoriesDispatch,
  getLearnCategoriesList,
} from "contexts/learnCategoriesTableContext";
import LearnCategoryColorField from "./learnCategoryColorField";
import {
  useLearnPageState,
  useLearnPageDispatch,
} from "contexts/learnPageContext";

const columns = [
  {
    id: "id",
    label: "Category Id",
    style: { paddingLeft: 8 },
  },
  {
    id: "name",
    label: "Name",
    style: { width: 600 },
  },
  {
    id: "color",
    label: "Color",
    content: (row) => <LearnCategoryColorField color={row.color} />,
    style: { width: 100 },
  },
  {
    id: "actions",
    content: (row) => <LearnCategoriesTableActions row={row} />,
    contentMobile: (row) => (
      <LearnCategoriesTableActions row={row} mobile={true} />
    ),
  },
];

const LearnCategoriesListTable = (props) => {
  const classes = useStyles();
  const {
    learnCategoriesLoading,
    learnCategoriesList,
  } = useLearnCategoriesState();
  const { reloadCategories } = useLearnPageState();
  const dispatchLearnPage = useLearnPageDispatch();
  const dispatchTable = useTableDispatch();
  const dispatchLearnCategories = useLearnCategoriesDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await getLearnCategoriesList(dispatchLearnCategories);
    };
    fetchData();
  }, [dispatchLearnCategories]);

  useEffect(() => {
    const fetchData = async () => {
      await getLearnCategoriesList(dispatchLearnCategories);
      dispatchLearnPage({
        type: "TOGGLE_RELOAD_CATEGORIES",
        reloadCategories: false,
      });
    };
    if (reloadCategories) {
      fetchData();
    }
  }, [reloadCategories]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: learnCategoriesList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, learnCategoriesList]);

  const openMangeCategory = () => {
    dispatchLearnCategories({
      type: "SET_CATEGORIES_SELECTED",
      learnCategorySelected: null,
    });
    dispatchLearnCategories({
      type: "TOGGLE_MANAGE",
      openManageCategory: true,
    });
  };

  return (
    <Box className={classes.root}>
      {learnCategoriesLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.listActionContainerSingle}>
            <ButtonBase
              variant="outlined"
              color="secondary"
              onClick={openMangeCategory}
            >
              New Category
            </ButtonBase>
          </Box>
          <Box className={classes.contentContainer}>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={() => {}}
                    newColumns={columns}
                    handleScrollClick={() => {}}
                    activeMobile={true}
                  ></Table>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(LearnCategoriesListTable);
