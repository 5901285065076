import React, { useContext } from "react";
import SurveysQuestionsDeleteDialog from "./surveysQuestionsDeleteDialog";
import ManageQuestionDialog from "./manageQuestionDialog";
import ManageTaskDialog from "./manageTaskDialog";
import GlobalUiContext from "contexts/globalUiContext";

const SurveysQuestionsListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <ManageQuestionDialog />
      <ManageTaskDialog />
      <SurveysQuestionsDeleteDialog />
    </>
  );
};
export default SurveysQuestionsListDialog;
