import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1",
    maxHeight: "100%",
    maxWidth: "100%",
    overflowX: "auto",
  },
  title: {
    flex: "1 1 10%",
    marginTop: 8,
  },
  paper: {
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  header: {
    marginBottom: "10px",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  viewActionWrap: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  viewAction: {
    flexGrow: "1",
    textAlign: "right",
    textDecoration: "none",
    marginRight: "10%",
    visibility: "hidden",
    marginLeft: 3,
  },
  money: {
    textAlign: "right",
    marginRight: 20,
  },
  bold: {
    fontWeight: "bold",
  },
  moneyTotal: {
    textAlign: "right",
    marginRight: 20,
    fontWeight: "bold",
  },
  viewActionMobile: {
    textDecoration: "none",
    marginLeft: 3,
  },
  tableWrapper: {
    maxHeight: "100%",
    maxWidth: "100%",
    // overflowX: "auto",
    minHeight: 200,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 24,
      marginBottom: 50,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 50,
    },

    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar:horizontal": {
      height: "0px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
  },
  noPad: {
    paddingLeft: 0,
  },
  tablerow: {
    height: 40,
    transition: "0.2s ease-in",
    cursor: "auto",
    // "&:hover": {
    //   backgroundColor: "rgba(0,0,0,0.02) !important",
    //   "& $viewAction": {
    //     visibility: "visible",
    //   },
    // },
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  tablerowWhite: {
    height: 43,
    transition: "0.2s ease-in",
    cursor: "auto",
    backgroundColor: "#fff !important",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02) !important",
      "& $viewAction": {
        visibility: "visible",
      },
    },
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  tablerowError: {
    height: 43,
    transition: "0.2s ease-in",
    cursor: "auto",
    backgroundColor: "rgba(255,204,203,0.4) !important",
    "&:hover": {
      backgroundColor: "rgba(255,204,203,0.8) !important",
      "& $viewAction": {
        visibility: "visible",
      },
    },
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  tablerowSection: {
    height: 43,
    transition: "0.2s ease-in",
    backgroundColor: "#EDF5F8",
    cursor: "auto",
    "&:hover": {
      backgroundColor: "rgba(237, 245, 248, 0.8) !important",
      "& $viewAction": {
        visibility: "visible",
      },
    },
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  tableHeadErrorKpi: {
    backgroundColor: "#F9F9F9 !important",
    borderBottomWidth: 4,
  },
  highlightRow: {
    backgroundColor: "rgba(0,0,0,0.04) !important",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.08) !important",
    },
  },
  errorIcon: {
    color: "#f07a8c",
    position: "relative",
    top: "10px",
    left: "8px",
  },
  tablecell: {
    padding: "2px 5px 2px 2px",
  },
  tablecellExpander: {
    padding: "2px 16px 2px 2px",
  },
  tablecellPagination: {
    display: "flex",
  },
  tablecellLevel: {
    padding: "2px 5px 2px 2px",
    background: "rgb(249, 249, 249)",
  },
  tableheadcell: {
    padding: "2px 5px 2px 2px",
    backgroundColor: "#F9F9F9",
    border: "none",
    textAlign: "center",
  },
  status: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    "& > span": {
      padding: "3px 15px !important",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  status1: {
    backgroundColor: "#78C1CE !important",
  },
  status2: {
    backgroundColor: "#9cbb65 !important",
  },
  status3: {
    backgroundColor: "#f39a3e !important",
  },
  toolBar: {
    borderRadius: 4,
    padding: 0,
    minHeight: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 10,
      paddingTop: 10,
      minHeight: "auto",
      borderRadius: 0,
      height: "auto",
      marginTop: 5,
      marginBottom: 5,
      clear: "both",
    },
  },
  toolbarControls: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginTop: 0,
    },
  },
  toolbarFilters: {
    textAlign: "right",
    flexGrow: 4,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  highlight: {
    background: "#dfdfdf",
    color: theme.palette.text.primary,
    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "&::after": {
      top: "100%",
      left: 25,
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderTopColor: "#dfdfdf",
      borderWidth: "10px",
      marginLeft: "-10px",
      zIndex: 5,
    },
  },
  headLabel: {
    fontWeight: "bold",
    paddingBottom: "6px !important",
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCheckbox: {
    color: "#ececec",
    border: "none",
  },
  tableNotCheckbox: {
    marginLeft: 10,
    color: "#ececec",
  },
  headCheckbox: {
    paddingBottom: 5,
  },
  tabs: {
    fontWeight: "bold",
  },
  filterPopper: {
    width: "100%",
    maxWidth: 420,
    padding: theme.spacing(2),
  },
  filterDivider: {
    borderTop: "solid 1px #f8f8f8",
  },
  toolbarActionWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  clearSearchButton: {
    padding: 0,
    opacity: "0.5",
    "&:hover": {
      background: "transparent",
      opacity: "1",
    },
  },
  checkCell: {
    position: "relative",
  },
  "@keyframes newIndicator": {
    "0%": {
      boxShadow: "0 0 0 0 #9ABC66",
    },
    "20%": {
      boxShadow: "0 0 0 5px transparent",
    },
    to: {
      boxShadow: "0 0 0 0 transparent",
    },
  },
  newRowIndicator: {
    position: "absolute",
    left: 0,
    top: "42%",
    marginTop: -2.5,
    width: 6,
    height: 6,
    borderRadius: "50%",
    animation: "$newIndicator 3s infinite",
    background: "#9ABC66",
    [theme.breakpoints.up("sm")]: {
      left: 4,
    },
  },
  rowItem: {
    padding: "10px 0",
    borderBottom: "1px solid #d4d4d4",
    margin: 0,
    width: "95%",
    overflow: "hidden",
  },
  item: {
    fontSize: 12,
    padding: "5px !important",
  },
  itemValue: {
    fontSize: 12,
    padding: "5px !important",
  },
  sortContainer: {
    padding: 5,
  },
  maxHeightTableInSlider: {
    maxHeight: 300,
    width: "100vw",
  },
  linkFormat: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.secondary.main,
    },
  },
  sectionCell: { padding: 0 },
  sectionLevelCell: { background: "rgb(249, 249, 249)" },
}));

export default useStyles;
