import React from "react";
import merge from "lodash/merge";
import {
  getSurveyCompleted,
  getSurveyCompletedSearch,
  getSurveyCompletedOffset,
} from "services/surveyFrontlineService";

const defaultState = {
  completedListLoading: true,
  completedSelected: null,
  completedList: [],
  completedCount: [],
  openView: false,
};

const CompletedStateContext = React.createContext();
const CompletedDispatchContext = React.createContext();

function completedReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        completedListLoading: action.completedListLoading,
      };
    case "SET_COMPLETED_LIST":
      return {
        ...state,
        completedList: action.completedList,
      };
    case "SET_COMPLETED_COUNT":
      return {
        ...state,
        completedCount: action.completedCount,
      };
    case "SET_COMPLETED_SELECTED":
      return {
        ...state,
        completedSelected: action.completedSelected,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CompletedProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(completedReducer, mergedState);
  return (
    <CompletedStateContext.Provider value={state}>
      <CompletedDispatchContext.Provider value={dispatch}>
        {children}
      </CompletedDispatchContext.Provider>
    </CompletedStateContext.Provider>
  );
}

function useCompletedState() {
  const context = React.useContext(CompletedStateContext);
  if (context === undefined) {
    throw new Error("useCompletedState must be used within a LocationProvider");
  }
  return context;
}

function useCompletedDispatch() {
  const context = React.useContext(CompletedDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getCompletedList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      completedListLoading: true,
    });
    const result = await getSurveyCompleted(filterData);
    dispatch({
      type: "SET_COMPLETED_LIST",
      completedList: [...result.data.data.surveys],
    });
    dispatch({
      type: "SET_COMPLETED_COUNT",
      completedCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      completedListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getCompletedListDataSet(
  completedList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getSurveyCompletedOffset(offset, filterData);
    const updateData = completedList.concat(result.data.data.surveys);
    dispatch({
      type: "SET_COMPLETED_LIST",
      completedList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchCompletedList(search, filterData) {
  try {
    const result = await getSurveyCompletedSearch(search, filterData);
    return result.data.data.surveys;
  } catch (error) {
    console.log(error);
  }
}

export {
  CompletedProvider,
  useCompletedState,
  useCompletedDispatch,
  getCompletedList,
  getCompletedListDataSet,
  searchCompletedList,
};
