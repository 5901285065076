import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";
const apiEndpoint = `${apiUrl}/qa/frontline`;

export function getFrontlineSurvey() {
  return http.get(`${apiEndpoint}/surveys`);
}

export function getFrontlineLocations(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/locations?${filterString}`, {});
}

export function getFrontlineLocationsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/locations?${filterString}search=${search}`,
    {}
  );
}

export function getFrontlineLocationsOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/locations?${filterString}offset=${offset}`,
    {}
  );
}

export function getFrontlineLocationsSurvey(jobId) {
  return http.get(`${apiEndpoint}/locations/${jobId}/surveys`, {});
}

export function startSurvey(
  jobId,
  surveyId,
  hasRandomize,
  randomNumber,
  evaluate_user_id
) {
  if (evaluate_user_id) {
    return http.post(
      `${apiEndpoint}/surveys/start?evaluate_user_id=${evaluate_user_id}&survey_id=${surveyId}&is_randomize=${hasRandomize}&random_number=${randomNumber}`
    );
  } else {
    return http.post(
      `${apiEndpoint}/surveys/start?job_id=${jobId}&survey_id=${surveyId}&is_randomize=${hasRandomize}&random_number=${randomNumber}`
    );
  }
}

export function startSurveyPublic(token) {
  return http.post(`${apiEndpoint}/surveys/public/${token}`);
}

export function resumeSurvey(surveyId) {
  return http.get(`${apiEndpoint}/surveys/resume/${surveyId}`, {});
}

export function getQuestionForSubmit(surveySubmit, question) {
  return http.get(
    `${apiEndpoint}/surveys/question-for-submit/${surveySubmit}/${question}`
  );
}

export function getTasksForSubmit(questionSubmit) {
  return http.get(`${apiEndpoint}/surveys/tasks-for-submit/${questionSubmit}`);
}

export function fillQuestion(question, data, hasFiles = false) {
  if (hasFiles) {
    http.setMultiPart();
    return http.post(`${apiEndpoint}/questions/${question}`, setFormData(data));
  }

  return http.post(`${apiEndpoint}/questions/${question}`, data);
}

export function getQuestionsResume(surveySubmit) {
  return http.get(
    `${apiEndpoint}/surveys/${surveySubmit}/get-questions-resume`
  );
}

export function newVersionQuestion(question) {
  return http.post(`${apiEndpoint}/new-version/${question}`, {});
}

export function storeSurveyFiles(survey, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/surveys/files/${survey}`, setFormData(data));
}

export function getFeedbackQuestion(question) {
  return http.get(`${apiEndpoint}/questions/${question}/feedback`, {});
}

export function deleteAnswer(question) {
  return http.delete(`${apiEndpoint}/questions/${question}`, {});
}

export function updateSurvey(survey, data) {
  return http.put(`${apiEndpoint}/surveys/${survey}`, data);
}

export function submitSurvey(survey) {
  return http.post(`${apiEndpoint}/surveys/${survey}/submit`, {});
}

export function deleteSurvey(survey) {
  return http.delete(`${apiEndpoint}/surveys/${survey}`, {});
}

export function getFrontlineDrafts(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/drafts?${filterString}`, {});
}

export function getFrontlineDraftsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/drafts?${filterString}search=${search}`, {});
}

export function getFrontlineDraftsOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/drafts?${filterString}offset=${offset}`, {});
}

export function getSurveyCompleted(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/surveys/submitted?${filterString}`);
}

export function getSurveyCompletedSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/surveys/submitted?${filterString}search=${search}`,
    {}
  );
}

export function getSurveyCompletedOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/surveys/submitted?${filterString}offset=${offset}`
  );
}

export function exportResult(data) {
  return http.post(`${apiEndpoint}/surveys/submitted/export`, data);
}

export function getSurveys() {
  return http.get(`${apiEndpoint}/surveys/filter`);
}

export function exportResumePdf(id) {
  return http.get(`${apiEndpoint}/surveys/pdf/${id}`, {
    responseType: "blob",
  });
}

export function getSurveysTab(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/surveys/list?${filterString}`);
}

export function getSurveysTabSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/surveys/list?${filterString}search=${search}`,
    {}
  );
}

export function getSurveysTabOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/surveys/list?${filterString}offset=${offset}`
  );
}

export function getSurveyLocations(surveyId) {
  return http.get(`${apiEndpoint}/surveys/list/${surveyId}/locations`, {});
}
