import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormTitle from "components/ui/FormContent/formTitle";
import FormSubTitle from "components/ui/FormContent/formSubTitle";
import FormContent from "components/ui/FormContent/formBody";
import RequestOption from "./requestOption";

import { useStateValue } from "components/util/state";
import { getRequestCompany } from "services/requestNonService";
import useStyles from "./styles";

const RequestStep1 = (props) => {
  const classes = useStyles();
  const [requestType, setRequestType] = useState(0);
  const [{ step, completed, company }, dispatch] = useStateValue();

  const { subdomain } = props;

  useEffect(() => {
    const loadCompany = async () => {
      try {
        const resultCompany = await getRequestCompany(subdomain);
        dispatch({
          type: "SET_COMPANY",
          company: { ...resultCompany.data.data },
        });
      } catch (e) {
        console.log("Customer cannot load.");
      }
    };
    if (!company) {
      loadCompany();
    }
  }, [company, subdomain, dispatch]);

  useEffect(() => {
    if (completed) {
      setRequestType(0);
    }
  }, [completed]);

  const handleOnClick = () => {
    dispatch({
      type: "UPDATE_DATA",
      updateData: { type: requestType },
    });
    dispatch({
      type: "CHANGE_STEP",
      newStep: 1,
    });
  };

  if (step !== 0 || !company) return null;
  return (
    <Box className={classes.stepContainer}>
      <FormTitle>Submit a New Request</FormTitle>
      <FormContent>{company?.customer?.request_text}</FormContent>
      <FormContent>
        Once your request is submitted, we will notify you that it has been
        received. Thanks in advance for your patience!
      </FormContent>
      <FormSubTitle>What kind of request are you submitting?</FormSubTitle>
      <RequestOption type={requestType} handleClick={setRequestType} />
      <Box className={classes.actionBox}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonBegin}
          onClick={handleOnClick}
          disabled={!requestType}
          size="large"
        >
          Begin
        </Button>
      </Box>
    </Box>
  );
};

export default RequestStep1;
