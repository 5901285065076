import React from "react";
import * as projectService from "services/projectService";
import merge from "lodash/merge";

const defaultState = {
  serviceListLoading: true,
  serviceSelected: null,
  serviceList: [],
  serviceCount: [],
  serviceDetails: [],
  serviceAction: null,
  serviceRowId: null,
  serviceManageOpen: false,
  serviceManageMode: "NEW",
};

const ServiceStateContext = React.createContext();
const ServiceDispatchContext = React.createContext();

function serviceReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        serviceListLoading: action.serviceListLoading,
      };
    case "SET_SERVICE_LIST":
      return {
        ...state,
        serviceList: action.serviceList,
      };
    case "SET_SERVICE_COUNT":
      return {
        ...state,
        serviceCount: action.serviceCount,
      };
    case "SET_SERVICE_SELECTED":
      return {
        ...state,
        serviceSelected: action.serviceSelected,
      };
    case "SET_SERVICE_DETAILS":
      return {
        ...state,
        serviceDetails: action.serviceDetails,
      };
    case "SET_SERVICE_ACTION":
      return {
        ...state,
        serviceAction: action.serviceAction,
      };
    case "SET_SERVICE_MANAGE_OPEN":
      return {
        ...state,
        serviceManageOpen: action.serviceManageOpen,
      };
    case "SET_SERVICE_MANAGE_MODE":
      return {
        ...state,
        serviceManageMode: action.serviceManageMode,
      };
    case "SET_SERVICE_ROW_ID":
      return {
        ...state,
        serviceRowId: action.serviceRowId,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ServiceProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(serviceReducer, mergedState);
  return (
    <ServiceStateContext.Provider value={state}>
      <ServiceDispatchContext.Provider value={dispatch}>
        {children}
      </ServiceDispatchContext.Provider>
    </ServiceStateContext.Provider>
  );
}

function useServiceState() {
  const context = React.useContext(ServiceStateContext);
  if (context === undefined) {
    throw new Error("useServiceState must be used within a ServiceProvider");
  }
  return context;
}

function useServiceDispatch() {
  const context = React.useContext(ServiceDispatchContext);
  if (context === undefined) {
    throw new Error("useServiceDispatch must be used within a ServiceProvider");
  }
  return context;
}

async function getServiceList(project_id, dispatch, filterData) {
  try {
    const serviceListResult = await projectService.getProjectServices(
      project_id,
      filterData
    );
    dispatch({
      type: "SET_SERVICE_LIST",
      serviceList: serviceListResult.data.data.services,
    });
    dispatch({
      type: "SET_SERVICE_COUNT",
      serviceCount: serviceListResult.data.data.count,
    });
    dispatch({
      type: "SET_LOADING",
      serviceListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getServiceListDataSet(
  project_id,
  serviceList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await projectService.getProjectServicesOffset(
      project_id,
      offset,
      filterData
    );
    const updateData = serviceList.concat(result.data.data.services);
    dispatch({
      type: "SET_SERVICE_LIST",
      serviceList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchServices(project_id, search, filterData) {
  try {
    const serviceListResult = await projectService.getProjectServicesSearch(
      project_id,
      search,
      filterData
    );
    return serviceListResult.data.data.services;
  } catch (error) {
    console.log(error);
  }
}

export {
  ServiceProvider,
  useServiceState,
  useServiceDispatch,
  getServiceList,
  searchServices,
  getServiceListDataSet,
};
