import React, { useContext, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CompletedFilters from "./completedFilters";
import CompletedSurfaceFilters from "./completedSurfaceFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import CompletedTableActions from "./completedTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import ScoreQA from "components/ui/Surveys/ScoreCA";
import EmergencyIcon from "@material-ui/icons/NewReleases";
import Tooltip from "@material-ui/core/Tooltip";
import CompletedBulkActions from "./completedBulkActions";
import { surveysQATypes } from "constants.js";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useCompletedState,
  useCompletedDispatch,
  getCompletedList,
  getCompletedListDataSet,
  searchCompletedList,
} from "contexts/surveyCompletedContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "survey_name",
    label: "Survey Name",
  },
  {
    id: "type",
    label: "Survey Type",
    content: (row) =>
      surveysQATypes.find((typeItem) => typeItem.value === parseInt(row.type))
        .label,
  },
  {
    id: "customer_name",
    label: "Customer",
  },
  {
    id: "job_description",
    label: "Job Name",
    content: (row) =>
      `${row.public && row.public !== "0" ? "-" : row.job_description}`,
  },
  {
    id: "surveyor",
    label: "Surveyor",
    content: (row) => {
      return (
        <Box style={{ whiteSpace: "nowrap" }}>
          {row.surveyor}{" "}
          {!row.submit_user_id ? (
            <Tooltip title="User Not Found" placement="top" arrow>
              <EmergencyIcon
                fontSize="small"
                style={{ top: 5, position: "relative" }}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
      );
    },
  },
  {
    id: "surveyor_role",
    label: "Surveyor Role",
  },
  {
    id: "start_date",
    label: "Start Date",
    format: "date",
  },
  {
    id: "completed_date",
    label: "Completed Date",
    format: "date",
  },
  {
    id: "id",
    label: "Questions",
    content: (row) => `${row.questions_completed}/${row.questions_count}`,
  },
  {
    id: "score",
    label: "Score",
    content: (row) => <ScoreQA row={row} />,
  },
  {
    id: "actions",
    content: (row) => <CompletedTableActions row={row} />,
    contentMobile: (row) => <CompletedTableActions row={row} mobile={true} />,
    style: { width: 55 },
  },
];

const CompletedTable = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { completedListLoading, completedList, completedCount } =
    useCompletedState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchCompleted = useCompletedDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getCompletedList(dispatchCompleted, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchCompleted, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: completedList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, completedList]);

  const handleScrollClick = useCallback(async () => {
    if (completedCount <= completedList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getCompletedListDataSet(
        completedList,
        dispatchCompleted,
        completedList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: completedList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    completedList,
    completedCount,
    filterState,
    dispatchCompleted,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchCompletedList(query, filterData);

          dispatchCompleted({
            type: "SET_COMPLETED_LIST",
            completedList: results,
          });

          dispatchCompleted({
            type: "SET_COMPLETED_COUNT",
            completedCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterCompleted: [],
    });
  };

  return (
    <Box className={classes.root}>
      {completedListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${completedList.length} / ${completedCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <CompletedBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                          placeholderSearch={"Search by survey or job"}
                        >
                          <CalendarFilters />
                          {hasPermission(
                            permissionSurface.QUALITY,
                            permissions
                          ) ? (
                            <CompletedSurfaceFilters />
                          ) : (
                            <CompletedFilters />
                          )}
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(CompletedTable);
