import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { updateQuotesBulk } from "services/quoteService";
import { useQuoteAction } from "contexts/quoteActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useQuoteDispatch, getQuoteTabList } from "contexts/quoteTableContext";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import { numberToWord } from "components/util/stringFormat";
import { useFilterState } from "contexts/filterContext";

import useStyles from "./styles";
import { useProjectView } from "contexts/projectViewContext";

const SnoozeDialog = (props) => {
  const classes = useStyles();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dueDate, setDueDate] = useState(moment().add(30, "days"));
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [quoteAction, dispatchQuoteAction] = useQuoteAction();
  const dispatchQuotes = useQuoteDispatch();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openSnooze } = quoteAction;
  const { selected } = tableUi;
  const {
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();

  const handleClose = () => {
    dispatchQuoteAction({
      type: "TOGGLE_SNOOZE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    if (selected.length) {
      setIsLoadingDialog(true);
      const snoozeStatus = 30;
      const data = {
        quotes_ids: selected,
        snooze_date: moment(dueDate).format("YYYY-MM-DD"),
        status: snoozeStatus,
      };
      try {
        const result = await updateQuotesBulk(data);
        if (result) {
          const filterData = {
            filters: filters,
            dateFilters: {
              startDate,
              startDateActive,
              endDate,
              endDateActive,
            },
          };
          // Done Status
          await getQuoteTabList(dispatchQuotes, project.id, filterData);

          dispatchTable({
            type: "SET_UPDATED_ROWS",
            rowsUpdated: selected,
          });
          setTimeout(() => {
            dispatchTable({
              type: "SET_UPDATED_ROWS",
              rowsUpdated: [],
            });
          }, 5000);

          handleClose();
          setIsLoadingDialog(false);
          setOpenSuccess(true);
        }
      } catch (e) {
        console.log("Cannot mark quotes as snoozed.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openSnooze}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuoteIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Quotes
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to snooze these{" "}
                {numberToWord(selected.length)} ({selected.length}) quote
                {selected.length > 1 ? "s" : ""}?
              </Typography>
              <Box className={classes.formContainer}>
                <FormInputDateAdo
                  name="dueDate"
                  label="Date"
                  value={dueDate}
                  handleChange={setDueDate}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These quote${
          selected.length > 1 ? "s" : ""
        } have been snoozed.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default SnoozeDialog;
