import React, { Fragment } from "react";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import { usePartnerUsers } from "./useUsers";
import { useLocation } from "./useLocation";

export function LocationItem({
  index,
  userId,
  user,
  setSelectedLocations,
  selectedLocations,
}) {
  const { jobs, transformJobData } = usePartnerUsers();
  const { jobsValue, handleJobChange } = useLocation();
  return (
    <Fragment key={index}>
      <FormSelectAuto
        label={`Location for ${user.first_name}`}
        options={jobs?.length ? transformJobData(jobs) : []}
        gridSizes={[{ size: "md", val: 12 }]}
        value={jobsValue}
        handleChange={(event, value) =>
          handleJobChange(
            value,
            userId,
            setSelectedLocations,
            selectedLocations
          )
        }
        name="name"
        multiple={true}
      />
    </Fragment>
  );
}
