import React, { useContext } from "react";

import RingBellActionDialog from "./ringBellActionDialog";
import RingBellActionDialogSurface from "./ringBellActionDialogSurface";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";

const RingBellDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      {hasPermission(permissionSurface.MAP, permissions) ? (
        <RingBellActionDialogSurface />
      ) : (
        <RingBellActionDialog />
      )}
    </>
  );
};
export default RingBellDialog;
