import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Counter from "components/common/TableList/counter";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AssetCategory from "./assetSurfaceCategory";
import AssetMaintenance from "./assetSurfaceMaintenance";
import AssetFilters from "./assetSurfaceFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";

import {
  useAssetState,
  useAssetDispatch,
  getAssetList,
  searchAssets,
  getAssetListDataSet,
} from "contexts/assetSurfaceContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "number",
    label: "Asset Number",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "name",
    label: "Asset Name",
  },
  {
    id: "category",
    label: "Category",
    content: (row) => <AssetCategory categories={row.categories} />,
    disableSort: true,
  },
  {
    id: "zone",
    label: "Zone",
  },
  {
    id: "maintenance_plan",
    label: "Maintenance Plan",
    content: (row) => <AssetMaintenance id={row.id} />,
    disableSort: true,
  },
];

const AssetsSurfaceTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { assetListLoading, assetList, assetCount } = useAssetState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchAssets = useAssetDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const moduleName = window.location.pathname.split("/")[1];
    if (localStorage.getItem(`filter_${moduleName}`)) {
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: JSON.parse(
          localStorage.getItem(
            `filter_${window.location.pathname.split("/")[1]}`
          )
        ),
      });
    }
    if (localStorage.getItem(`filter_date_${moduleName}`)) {
      const dataDate = JSON.parse(
        localStorage.getItem(`filter_date_${moduleName}`)
      );
      dispatchFilters({
        type: "SET_DATE",
        ...dataDate,
      });
    }
    dispatchFilters({
      type: "SET_LOADING",
      isLoadingFilters: false,
    });
  }, [dispatchFilters]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getAssetList(dispatchAssets, filterData);
    };
    if (filterState.searchString === "" && !filterState.isLoadingFilters) {
      fetchData();
    }
  }, [dispatchAssets, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: assetList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, assetList]);

  const handleScrollClick = useCallback(async () => {
    if (assetCount <= assetList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getAssetListDataSet(
        assetList,
        dispatchAssets,
        assetList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: assetList });
      setLoadingMore(false);
    } catch (e) {
      logException(e, "Cannot load more data");
    }
  }, [
    assetList,
    assetCount,
    filterState,
    dispatchAssets,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, rowId) => {
    dispatchAssets({
      type: "SET_ASSET_ROW_ID",
      assetRowId: rowId,
    });
    dispatchTable({ type: "SET_UPDATED_ROWS", rowsUpdated: [rowId] });
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchAssets(query, filterData);
          // Send result to search tab
          dispatchAssets({
            type: "SET_ASSET_LIST",
            assetList: results,
          });

          dispatchAssets({
            type: "SET_ASSET_COUNT",
            assetCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 caracters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {assetListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>{`${assetList.length} / ${assetCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          placeholderSearch={"All Assets"}
                          handleSearchClear={handleSearchClear}
                        >
                          <AssetFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AssetsSurfaceTable);
