import React from "react";
import * as quoteService from "../services/quoteService";
import { merge } from "lodash";

const defaultState = {
  quoteDetails: [],
  quoteLoading: true,
  isLoading: true,
  uniqueJobs: [],
  worktickets: [],
  activities: [],
  selectedQuote: null,
  selectedQuoteItem: null,
  selectedQuoteItemPlanning: null,
  originalQuoteItem: null,
  permissions: [],
  subs: [],
  inHouse: [],
  openPrint: false,
};

const QuoteStateContext = React.createContext();
const QuoteDispatchContext = React.createContext();

function quoteReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_QUOTE_DETAILS":
      return {
        ...state,
        quoteDetails: action.quoteDetails,
      };
    case "SET_SELECTED_QUOTE":
      return {
        ...state,
        selectedQuote: action.selectedQuote,
      };
    case "SET_SELECTED_QUOTE_ITEM":
      return {
        ...state,
        selectedQuoteItem: action.selectedQuoteItem,
      };
    case "SET_SELECTED_QUOTE_ITEM_PLANNING":
      return {
        ...state,
        selectedQuoteItemPlanning: action.selectedQuoteItemPlanning,
      };
    case "SET_ORIGINAL_QUOTE_ITEM":
      return {
        ...state,
        originalQuoteItem: action.originalQuoteItem,
      };
    case "SET_WORKTICKETS":
      return {
        ...state,
        worktickets: action.worktickets,
      };
    case "SET_ACTIVITIES":
      return {
        ...state,
        activities: action.activities,
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
      };
    case "SET_SUBS":
      return {
        ...state,
        subs: action.subs,
      };
    case "SET_INHOUSE":
      return {
        ...state,
        inHouse: action.inHouse,
      };
    case "TOGGLE_OPEN_PRINT":
      return {
        ...state,
        openPrint: action.openPrint,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(quoteReducer, mergedState);
  return (
    <QuoteStateContext.Provider value={state}>
      <QuoteDispatchContext.Provider value={dispatch}>
        {" "}
        {children}{" "}
      </QuoteDispatchContext.Provider>{" "}
    </QuoteStateContext.Provider>
  );
}

function useQuoteState() {
  const context = React.useContext(QuoteStateContext);
  if (context === undefined) {
    throw new Error("useQuoteState must be used within a QuoteProvider");
  }
  return context;
}

function useQuoteDispatch() {
  const context = React.useContext(QuoteDispatchContext);
  if (context === undefined) {
    throw new Error("useQuoteDispatch must be used within a QuoteProvider");
  }
  return context;
}

async function getQuote(dispatch, id) {
  try {
    const quoteDetailsResult = await quoteService.getQuoteDetails(id);
    if (!quoteDetailsResult.data.data.quote.items) {
      quoteDetailsResult.data.data.quote.items = [];
    } else {
      quoteDetailsResult.data.data.quote.items = JSON.parse(
        quoteDetailsResult.data.data.quote.items
      );
    }
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });
    dispatch({
      type: "SET_ACTIVITIES",
      activities: quoteDetailsResult.data.data.quote.activity,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuotePlan(dispatch, id) {
  try {
    const quoteDetailsResult = await quoteService.getQuotePlanning(id);
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });
  } catch (error) {
    console.log(error);
  }
}

async function updateQuote(dispatch, id, data, type, normal) {
  try {
    if (type) {
      dispatch({
        type: "SET_LOADING",
        isLoading: true,
      });
    }
    const quoteUpdateResult = await quoteService.updateQuote(id, data);
    if (type) {
      const quoteDetailsResult = normal
        ? await quoteService.getQuoteDetails(id)
        : await quoteService.getQuotePlanning(id);
      dispatch({
        type: "SET_QUOTE_DETAILS",
        quoteDetails: quoteDetailsResult.data.data.quote,
      });
      dispatch({
        type: "SET_LOADING",
        isLoading: false,
      });
    }
    if (quoteUpdateResult.data.data) {
      dispatch({
        type: "SET_ACTIVITIES",
        activities: quoteUpdateResult.data.data.activity,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function addQuoteItems(dispatch, quoteId, data, normal, isDuplicated) {
  try {
    dispatch({
      type: "SET_LOADING",
      isLoading: true,
    });

    if (data.workItem) {
      delete data.workItem;
    }

    const response = await quoteService.addQuoteItem(quoteId, {
      quote_items: [data],
    });

    if (data.files && data.files.length > 0) {
      const quoteItems = response?.data?.data?.new_quote_items;
      for (let i = 0; i < quoteItems.length; i++) {
        if (!isDuplicated) {
          await quoteService.addFilesQuoteItem(quoteItems[i].id, {
            files: data.files,
          });
        } else {
          await quoteService.addFilesQuoteItemDuplicate(quoteItems[i].id, {
            files: data.files,
          });
        }
      }
    }

    const quoteDetailsResult = normal
      ? await quoteService.getQuoteDetails(quoteId)
      : await quoteService.getQuotePlanning(quoteId);
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });
    dispatch({
      type: "SET_LOADING",
      isLoading: false,
    });

    dispatch({
      type: "SET_ACTIVITIES",
      activities: quoteDetailsResult.data.data.quote.activity,
    });
  } catch (error) {
    console.log(error);
  }
}

async function deleteQuoteItems(dispatch, quoteId, data, normal) {
  try {
    dispatch({
      type: "SET_LOADING",
      isLoading: true,
    });
    await quoteService.deleteQuoteItem(quoteId, data);

    const quoteDetailsResult = normal
      ? await quoteService.getQuoteDetails(quoteId)
      : await quoteService.getQuotePlanning(quoteId);
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });
    dispatch({
      type: "SET_LOADING",
      isLoading: false,
    });

    dispatch({
      type: "SET_ACTIVITIES",
      activities: quoteDetailsResult.data.data.quote.activity,
    });
  } catch (error) {
    console.log(error);
  }
}

async function updateQuoteItems(dispatch, quoteId, data, normal) {
  try {
    dispatch({
      type: "SET_LOADING",
      isLoading: true,
    });

    await quoteService.updateQuoteItem(quoteId, {
      quote_items: [data],
    });

    if (data.newFiles && data.newFiles.length > 0) {
      await quoteService.addFilesQuoteItem(data.id, {
        files: data.newFiles,
      });
    }

    const quoteDetailsResult = normal
      ? await quoteService.getQuoteDetails(quoteId)
      : await quoteService.getQuotePlanning(quoteId);

    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails: quoteDetailsResult.data.data.quote,
    });

    dispatch({
      type: "SET_LOADING",
      isLoading: false,
    });

    dispatch({
      type: "SET_ACTIVITIES",
      activities: quoteDetailsResult.data.data.quote.activity,
    });
  } catch (error) {
    console.log(error);
  }
}

export {
  QuoteProvider,
  useQuoteState,
  useQuoteDispatch,
  getQuote,
  getQuotePlan,
  updateQuote,
  addQuoteItems,
  deleteQuoteItems,
  updateQuoteItems,
};
