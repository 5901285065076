import React from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import SurveysQuestionsListTable from "./surveysQuestionsListTable";
import SurveysQuestionsListDialog from "./dialog/surveysQuestionsListDialog";
import { SurveysQuestionsProvider } from "contexts/surveysQuestionsTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { SurveysTasksProvider } from "contexts/surveysTasksContext";
import useStyles from "./styles";

const SurveysQuestionsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <SurveysQuestionsProvider>
        <SurveysTasksProvider>
          <SurveysQuestionsListTable />
          <SurveysQuestionsListDialog />
        </SurveysTasksProvider>
      </SurveysQuestionsProvider>
    </Box>
  );
};

export default withTableFilterContext(SurveysQuestionsList);
