import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import LearnPageFilters from "./learnPageFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { ButtonBase } from "components/ui/core/button";
import Grid from "@material-ui/core/Grid";
import LearnPageItem from "./learnPageItem";
import EmptyScreen from "components/ui/core/emptyScreen";
import SchoolIcon from "@material-ui/icons/School";

import GlobalUiContext from "contexts/globalUiContext";
import {
  useLearnPageState,
  useLearnPageDispatch,
  getLearnPageList,
  searchLearnPageList,
  getLearnPageListDataSet,
} from "contexts/learnPageContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { hasPermission, permissionLearn } from "lib/permissions";
import ViewVideoDialog from "./dialog/viewVideoDialog";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const LearnPageTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { learnPageLoading, learnPageList, learnPageCount } =
    useLearnPageState();
  const filterState = useFilterState();
  const dispatchLearnPage = useLearnPageDispatch();
  const dispatchFilters = useFilterDispatch();

  const { refreshData, permissions } = globalUi;

  const hasManage = useMemo(() =>
    hasPermission(permissionLearn.ADMIN, permissions)
  );
  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getLearnPageList(dispatchLearnPage, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchLearnPage, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getLearnPageList(dispatchLearnPage, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const handleScrollClick = useCallback(async () => {
    if (learnPageCount <= learnPageList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getLearnPageListDataSet(
        learnPageList,
        dispatchLearnPage,
        learnPageList.length,
        filterData
      );
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    learnPageList,
    learnPageCount,
    filterState,
    dispatchLearnPage,
    loadingMore,
  ]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchLearnPageList(query, filterData);

          dispatchLearnPage({
            type: "SET_LEARN_PAGE_LIST",
            learnPageList: results,
          });

          dispatchLearnPage({
            type: "SET_LEARN_PAGE_COUNT",
            learnPageCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  const openManageDialog = () => {
    dispatchLearnPage({
      type: "SET_TILE_SELECTED",
      tileSelected: null,
    });
    dispatchLearnPage({
      type: "TOGGLE_MANAGE",
      openTileManage: true,
    });
  };

  return (
    <Box className={classes.root}>
      {learnPageLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <ViewVideoDialog />
          <Box className={classes.contentContainer}>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Box className={classes.contentToolbar}>
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        placeholderSearch={"Title"}
                        handleSearchClear={handleSearchClear}
                      >
                        {hasManage ? (
                          <Box className={classes.listActionContainer}>
                            <ButtonBase
                              variant="outlined"
                              color="secondary"
                              onClick={openManageDialog}
                            >
                              Add New
                            </ButtonBase>
                          </Box>
                        ) : null}
                        <LearnPageFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filterState.filters && <FiltersAppliedList />}
                    <Box className={classes.cardsContainer}>
                      {learnPageList.length > 0 ? (
                        <Grid
                          container
                          spacing={4}
                          className={classes.cardsGridContainer}
                        >
                          {learnPageList.map((item) => (
                            <Grid
                              key={item.id}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                            >
                              <LearnPageItem
                                item={item}
                                readonly={!hasManage}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <EmptyScreen
                          detailText={"There is no tiles for this yet"}
                          icon={<SchoolIcon fill="#17457a" width="48px" />}
                          type={3}
                          actionText="Create New Tile"
                          detailActionText="Do something awesome like:"
                          action={openManageDialog}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(LearnPageTable);
