import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { deleteSurvey } from "services/surveyFrontlineService";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";

const CloseDialog = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const {
    openClose,
    selectedQuestions,
    correlative,
    lang,
    linkMode,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const handleClose = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_CLOSE",
      openClose: false,
    });
  };

  const handleSaveResults = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_CLOSE",
      openClose: false,
    });
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SHOW_SURVEY",
      openShowSurvey: false,
    });
    if (linkMode) {
      history.push(`/surveys`);
    }
  };

  const handleDeleteSurvey = async () => {
    setIsLoadingDialog(true);
    await deleteSurvey(selectedQuestions[correlative].qa_survey_submit_id);
    setIsLoadingDialog(false);
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_CLOSE",
      openClose: false,
    });
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SHOW_SURVEY",
      openShowSurvey: false,
    });
    if (linkMode) {
      history.push(`/surveys`);
    }
  };

  return (
    <Dialog
      open={openClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {isLoadingDialog ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <AssignmentIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              {surveysLabelsLang[lang].surveyExitMsgHeaderLabel}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {surveysLabelsLang[lang].surveyExitMsgBodyLabel}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapperSubmit}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleDeleteSurvey}
            >
              {surveysLabelsLang[lang].surveyExitMsgNoLabel}
            </ButtonBase>
            <ButtonBase
              color="secondary"
              disableElevation={true}
              onClick={handleSaveResults}
            >
              {surveysLabelsLang[lang].surveyExitMsgYesLabel}
            </ButtonBase>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default withRouter(CloseDialog);
