import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: "30%",
    maxHeight: 80,
    marginRight: "2%",
    display: "inline-block"
  },
  fileIcon: {
    margin: 5,
  }
}));

export default useStyles;
