import http from "../httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = `${apiUrl}/partners`;

export async function getPartnersFiles(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/files`);
}

export async function getPartnersFilesZip(partner_id) {
  return await http.post(
    `${apiEndpoint}/${partner_id}/files-zip`,
    { partnerId: partner_id },
    {
      responseType: "blob",
    }
  );
}

export async function getPartnersComplianceFiles(partner_id) {
  return await http.get(`${apiEndpoint}/${partner_id}/compliance-files`);
}

export async function uploadFileChunk(id, data, onUploadProgress) {
  return http.post(`${apiEndpoint}/${id}/upload-files-chunk`, data, {
    onUploadProgress,
  });
}

export function updatePartnerFile(partner_id, file_id, data) {
  return http.put(`${apiEndpoint}/${partner_id}/files/${file_id}`, data);
}

export function deletePartnerFileCompliance(partner_id, file_id) {
  return http.delete(
    `${apiEndpoint}/${partner_id}/compliance-files/${file_id}`
  );
}
