import { createContext, useState, useContext } from "react";

const FilterDialogContext = createContext();

export const FilterDialogProvider = ({ children }) => {
  const [zipCodesList, setZipCodesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterCities, setFilterCities] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterZipCodes, setFilterZipCodes] = useState([]);
  const handleDialogueOpen = () => {
    setOpen(true);
  };
  const [isEditingVariant, setIsEditingVariant] = useState(false);
  const handleDialogueClose = () => {
    setOpen(false);
  };
  const [variantsLocations, setVariantsLocations] = useState([]);

  return (
    <FilterDialogContext.Provider
      value={{
        open,
        handleDialogueOpen,
        handleDialogueClose,
        setOpen,
        filterCities,
        setFilterCities,
        filterStates,
        setFilterStates,
        filterZipCodes,
        setFilterZipCodes,
        isEditingVariant,
        setIsEditingVariant,
        zipCodesList,
        setZipCodesList,
        citiesList,
        setCitiesList,
        statesList,
        setStatesList,
        variantsLocations,
        setVariantsLocations,
      }}
    >
      {children}
    </FilterDialogContext.Provider>
  );
};

export const useFilterDialog = () => useContext(FilterDialogContext);
