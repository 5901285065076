export function Location() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.132812"
        y="0.636719"
        width="24"
        height="24"
        rx="4"
        fill="#ECECEC"
      />
      <path
        d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
        strokeLinecap="round"
        stroke="#747474"
        strokeLinejoin="round"
      />
      <path
        d="M17 12C17 16.7614 12 19.5 12 19.5C12 19.5 7 16.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
