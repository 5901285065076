import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
    // overflowX: "hidden",
    padding: "15px",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  input: {
    color: "rgb(118, 119, 123)",
    border: "1px solid rgb(214, 221, 225)",
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
  },
  mainTitle: {
    // textAlign: "center",
    // color: "#2196f3",
    // fontWeight: 300,
    // fontSize: 30,
  },
  subTitle: {
    // textAlign: "left",
    // fontWeight: "bold",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "small",
  },
  subSubTitle: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "small",
  },
  centerAlignBtn: {
    justifyContent: "center",
  },
  rightAlignBtn: {
    justifyContent: "flex-end",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 35,
  },
  plusRightEnd: {
    textAlign: "end",
    marginRight: 15,
  },
  sigCanvas: {
    border: "1px solid black",
    margin: "auto",
    display: "block",
    marginBottom: "10px",
  },
  modalTitle: {
    textAlign: "center",
    fontSize: 20,
    margin: 20,
    fontWeight: "bold",
  },
  modalCross: {
    textAlign: "right",
  },
  closeIcon: {
    width: 50,
    position: "absolute",
    right: 8,
  },
  inputContainer: {
    // marginBottom: "8px !important",
    marginBottom: "30px !important",
  },
  inputContainerDateAdj: {
    // marginBottom: "8px !important",
    marginBottom: "17px !important",
  },
  bodyImageCentered: {
    display: "block",
    margin: "auto",
  },
  talReqButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "41px",
  },
  hidden: {
    visibility: "hidden",
    position: "absolute",
  },
  boxYes: {
    width: "100%",
    padding: 12,
  },
  downloadFile: {
    display: "block",
    marginTop: 10,
    maxWidth: 180,
    marginBottom: 10,
    cursor: "pointer",
    color: "#4f98bb",
  },
  groupOption2: {
    color: "#4f98bc",
    width: "200px",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  iconMultiRequest: {
    width: 38,
    height: 38,
    color: "#0b417e",
  },
  markFilledButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "41px",
  },
  trSubContainer: {
    flex: "1",
    color: "#4F98BC",
    display: "flex",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
  },
  trContainer: {
    // position: "fixed",
    bottom: 200,
    width: 345,
    display: "flex",
    marginBottom: 15,
    marginTop: 30,
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  transferAdj: {
    padding: 12,
  },
}));

export default useStyles;
