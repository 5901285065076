import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import QuoteItems from "./quoteItems";
import QuoteDetail from "./quoteDetail";
import useStyles from "./styles";

import { QuoteProvider } from "contexts/quoteContext";
import { QuoteItemDrawerProvider } from "contexts/quoteItemDrawerContext";

import QuoteBreadcrum from "./quoteBreadcrum";
import QuoteHeader from "./quoteHeader";
import QuoteActivity from "./quoteActivity";
import QuoteActions from "./quoteActions";
import QuoteFile from "./quoteFile";
import QuoteWorktickets from "./quoteWorktickets";
import QuoteRecurrence from "./quoteRecurrence";

const Quote = (props) => {
  const classes = useStyles();

  const initialStateQuote = {
    selectedQuote: null,
    selectedQuoteItem: null,
    quoteDetails: [],
    uniqueJobs: [],
    editMode: false,
  };

  return (
    <QuoteProvider initialState={initialStateQuote}>
      <QuoteBreadcrum />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.containerMain}>
            <QuoteDetail {...props} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box p={3} className={classes.containerBody}>
            <QuoteHeader />
            <QuoteItemDrawerProvider>
              <QuoteItems />
            </QuoteItemDrawerProvider>
            <QuoteActivity />
            <QuoteRecurrence />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.containerSidebar}>
            <QuoteActions />
            <QuoteFile />
            <QuoteWorktickets />
          </Box>
        </Grid>
      </Grid>
    </QuoteProvider>
  );
};

export default Quote;
