import React from "react";
import Box from "@material-ui/core/Box";
import ProjectTabQuoteTable from "./projectTabQuoteTable";
import { DialogProvider } from "contexts/dialogContext";
import { TableProvider } from "contexts/tableContext";
import { DrawerProvider } from "contexts/detailsDrawerContext";
import { QuoteProvider } from "contexts/quoteTableContext";
import { QuoteActionProvider } from "contexts/quoteActionContext";
import { FilterProvider } from "contexts/filterContext";

import QuotesDialog from "./dialogQuotes/quotesDialog";

import useStyles from "./styles";

const ProjectTabQuote = props => {
  const classes = useStyles();

  const initialStateTable = {
    hasRowsClickable: false
  };

  return (
    <Box className={classes.containerTabWorkticket}>
      <QuoteProvider>
        <DialogProvider>
          <DrawerProvider>
            <FilterProvider>
                <TableProvider initialState={initialStateTable}>
                  <QuoteActionProvider>
                    <ProjectTabQuoteTable />
                    <QuotesDialog />
                  </QuoteActionProvider>
                </TableProvider>
            </FilterProvider>
          </DrawerProvider>
        </DialogProvider>
      </QuoteProvider>
    </Box>
  );
};

export default ProjectTabQuote;
