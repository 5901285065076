export function CheckCircleGreen() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99935 0.666748C4.39935 0.666748 0.666016 4.40008 0.666016 9.00008C0.666016 13.6001 4.39935 17.3334 8.99935 17.3334C13.5993 17.3334 17.3327 13.6001 17.3327 9.00008C17.3327 4.40008 13.5993 0.666748 8.99935 0.666748ZM6.74102 12.5751L3.74935 9.58341C3.42435 9.25841 3.42435 8.73341 3.74935 8.40841C4.07435 8.08341 4.59935 8.08341 4.92435 8.40841L7.33268 10.8084L13.066 5.07508C13.391 4.75008 13.916 4.75008 14.241 5.07508C14.566 5.40008 14.566 5.92508 14.241 6.25008L7.91602 12.5751C7.59935 12.9001 7.06602 12.9001 6.74102 12.5751Z"
        fill="#9CBB65"
      />
    </svg>
  );
}
