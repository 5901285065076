import React, { useEffect, useContext, useState } from "react";
import * as classNames from "classnames";
import Joi from "joi-browser";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MoreVert";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ConfirmDialog from "./dialog/confirmDialog";
import MessageDialog from "./dialog/messageDialog";
import ErrorMessageDialog from "./dialog/errorMessageDialog";
import { permissionQuote, hasPermission } from "../../lib/permissions";
import Tooltip from "@material-ui/core/Tooltip";
import EmptyScreen from "components/ui/core/emptyScreen";
import SideDrawer from "./drawer/sideDrawer";
import Link from "@material-ui/core/Link";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Error";
import {
  createBillingItem,
  removeBillingItem,
  changeBillingItem,
} from "services/quoteService";
import { logException } from "components/util/logUtil";
import FormInputBillingItem from "components/ui/FormContent/formInputBillingItem";
import { validateProperty } from "components/util/validationUtil";

import GlobalUiContext from "contexts/globalUiContext";
import {
  useQuoteState,
  useQuoteDispatch,
  updateQuote,
  addQuoteItems,
  deleteQuoteItems,
} from "contexts/quoteContext";
import { useQuoteItemDrawerDispatch } from "contexts/quoteItemDrawerContext";
import quoteDetail from "./quoteDetail";

const billingItemSchema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
  total: Joi.number().required().min(0).label("Total"),
};

const QuoteItems = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorItemEl, setAnchorItemEl] = useState(null);
  const [menuActions, setMenuActions] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageError, setOpenMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [quoteBillingItems, setQuoteBillingItems] = useState([]);
  const [totalQuote, setTotalQuote] = useState(0);
  const dispatchQuoteItemDrawer = useQuoteItemDrawerDispatch();
  const [updateDummy, setUpdateDummy] = useState(false);

  const { selectedQuote, quoteDetails, isLoading, selectedQuoteItem } =
    useQuoteState();
  const dispatch = useQuoteDispatch();
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const readOnlyGlobal =
    [20].includes(quoteDetails.status) ||
    !hasEdit ||
    ([5].includes(quoteDetails.status) && quoteDetails.generator.length > 0) ||
    quoteDetails.is_old_structure;

  useEffect(() => {
    if (quoteDetails.hours === null) {
      let hours = 0;
      for (let i = 0; i < quoteDetails.quote_items.length; i++) {
        hours += parseFloat(
          quoteDetails.quote_items[i].hours
            ? quoteDetails.quote_items[i].hours
            : 0
        );
      }
      quoteDetails["hours"] = hours;
      updateQuote(dispatch, selectedQuote, {
        hours: hours,
      });
    }
  }, [quoteDetails.hours]);

  useEffect(() => {
    if (quoteDetails.quote_billing_item) {
      setQuoteBillingItems(addErrorToArray(quoteDetails.quote_billing_item));
    }
  }, [quoteDetails.quote_billing_item]);

  const addErrorToArray = (list) => {
    const result = list.map((item) => {
      return {
        ...item,
        error: [],
      };
    });
    return result;
  };

  useEffect(() => {
    let total = parseFloat(quoteDetails.sub_total ?? 0);
    for (let i = 0; i < quoteBillingItems.length; i++) {
      const totalItem = parseFloat(quoteBillingItems[i].total);
      if (parseInt(quoteBillingItems[i].type) === 1) {
        total += totalItem;
      } else if (parseInt(quoteBillingItems[i].type) === 2) {
        total -= totalItem;
      } else if (parseInt(quoteBillingItems[i].type) === 3) {
        total += totalItem;
      }
    }
    setTotalQuote(total);
  }, [quoteBillingItems, quoteDetails.total]);

  const handleClick = (event) => {
    dispatch({
      type: "SET_SELECTED_QUOTE_ITEM",
      selectedQuoteItem: null,
    });
    setAnchorEl(event.currentTarget);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const handleClickItem = (event, id) => {
    const selQuoteItem = quoteDetails.quote_items.filter(
      (item) => item.id === id
    )[0];
    dispatch({
      type: "SET_SELECTED_QUOTE_ITEM",
      selectedQuoteItem: selQuoteItem,
    });
    const menuActions = [];
    if (selQuoteItem.item_type) {
      menuActions.push({
        label:
          readOnlyGlobal ||
          !selQuoteItem.is_editable ||
          ([5].includes(quoteDetails.status) &&
            selQuoteItem.generator &&
            selQuoteItem.generator.length > 0)
            ? "View"
            : "Edit",
        action: () => openItem(selQuoteItem.item_type),
      });
    }
    if (
      !readOnlyGlobal &&
      !(
        [5].includes(quoteDetails.status) &&
        selQuoteItem.generator &&
        selQuoteItem.generator.length > 0
      ) &&
      quoteDetails.can_add_items
    ) {
      menuActions.push({
        label: "Duplicate",
        action: () => openDuplicateItem(id),
      });
      if (
        selQuoteItem.is_deletable &&
        quoteDetails.can_delete_items &&
        (!quoteDetails.ar_records?.length > 0 ||
          ([0, 2].includes(quoteDetails.ar_records[0].status) &&
            parseFloat(selQuoteItem.total) <=
              parseFloat(quoteDetails.ar_records[0].net_due)))
      )
        menuActions.push({ label: "Delete", action: () => openDeleteItem(id) });
    }
    setMenuActions(menuActions);
    setAnchorItemEl(event.currentTarget);
  };

  const handleClickBillingItem = (event, id) => {
    const menuActions = [
      {
        label: "Delete",
        action: () => deleteBillingItem(id),
      },
    ];
    setMenuActions(menuActions);
    setAnchorItemEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseItem = () => {
    setAnchorItemEl(null);
  };

  const openDetail = (type) => {
    dispatchQuoteItemDrawer({
      type: "SET_IS_OPEN",
      isOpen: true,
    });
    dispatchQuoteItemDrawer({
      type: "SET_CONTENT",
      drawerContent: type,
    });
    dispatchQuoteItemDrawer({
      type: "SET_FROM_MAIN_QUOTE",
      fromMainQuote: true,
    });
    setAnchorEl(null);
  };

  const openItem = (type) => {
    openDetail(type === "bulk" ? "unit" : type);
    setAnchorItemEl(null);
  };

  const openDeleteItem = (id) => {
    const selectedId = id;
    const selQuoteItem = quoteDetails.quote_items.filter(
      (item) => item.id === selectedId
    )[0];
    setDeleteMessage(
      `Are you sure you want to delete "${selQuoteItem.item_subject}"?`
    );
    setOpenDelete(true);
  };

  const deleteItem = () => {
    const selectedId = selectedQuoteItem.id;
    const newItems = quoteDetails.quote_items.filter(
      (item) => item.id !== selectedId
    );
    const selQuoteItem = quoteDetails.quote_items.filter(
      (item) => item.id === selectedId
    )[0];
    selQuoteItem.isDeleted = true;
    const deleteItem = { ...selQuoteItem };
    quoteDetails.quote_items = newItems;
    deleteQuoteItems(
      dispatch,
      selectedQuote,
      { quote_item_ids: [deleteItem.id] },
      true
    );
    setAnchorItemEl(null);
    setOpenDelete(false);
  };

  const openDuplicateItem = (id) => {
    const selectedId = id;
    const selQuoteItem = quoteDetails.quote_items.filter(
      (item) => item.id === selectedId
    )[0];
    setDuplicateMessage(
      `Are you sure you want to duplicate "${selQuoteItem.item_subject}"?`
    );
    setOpenDuplicate(true);
  };

  const duplicateItem = () => {
    const selectedId = selectedQuoteItem.id;
    const selQuoteItem = quoteDetails.quote_items.filter(
      (item) => item.id === selectedId
    )[0];
    const newItem = JSON.parse(JSON.stringify(selQuoteItem));
    if (newItem.id) {
      delete newItem.id;
    }
    newItem.is_editable = true;
    newItem.is_deletable = true;
    newItem.task_list = newItem.tasks;
    delete newItem.tasks;

    if (quoteDetails.status === 5) {
      quoteDetails["pending_planning"] = 1;
    }

    addQuoteItems(dispatch, selectedQuote, newItem, true, true);

    setOpenDuplicate(false);
    setAnchorItemEl(null);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  const closeDuplicate = () => {
    setOpenDuplicate(false);
    setAnchorItemEl(null);
  };

  const closeDelete = () => {
    setOpenDelete(false);
    setAnchorItemEl(null);
  };

  const addBillingItem = async (type) => {
    try {
      const result = await createBillingItem(quoteDetails.id, {
        name: "",
        type,
        total: 0,
      });
      setQuoteBillingItems(addErrorToArray(result.data.data.items));
    } catch (e) {
      logException(e, "Cannot add new billing item.");
    }
  };

  const deleteBillingItem = async (id) => {
    try {
      const result = await removeBillingItem(quoteDetails.id, {
        id,
      });
      setQuoteBillingItems(addErrorToArray(result.data.data.items));
      setAnchorItemEl(null);
      const { arItem } = result.data.data;
      if (arItem) {
        quoteDetails.ar_records = [arItem];
        dispatch({
          type: "SET_QUOTE_DETAILS",
          quoteDetails: quoteDetails,
        });
      }
    } catch (e) {
      logException(e, "Cannot add new billing item.");
    }
  };

  const updateBillingItem = async (item, data, field) => {
    const err = validateProperty(
      field,
      data[field],
      billingItemSchema,
      item.error,
      () => {}
    );
    if (!err.failed) {
      if (
        quoteDetails.ar_records?.length > 0 &&
        data.type === 2 &&
        data.total - parseFloat(data.originalTotal) >=
          parseFloat(quoteDetails.ar_records[0].net_due)
      ) {
        item.error = [
          {
            key: "total",
            message: "Quantity exceeds pending billing amount for quote.",
          },
        ];
        setUpdateDummy(!updateDummy);
      } else if (
        quoteDetails.ar_records?.length > 0 &&
        [1, 3].includes(parseInt(data.type)) &&
        parseFloat(data.originalTotal) - parseFloat(data.total) >=
          parseFloat(quoteDetails.ar_records[0].net_due)
      ) {
        item.error = [
          {
            key: "total",
            message: "Quantity exceeds pending billing amount for quote.",
          },
        ];
        setUpdateDummy(!updateDummy);
      } else {
        try {
          const result = await changeBillingItem(quoteDetails.id, data);
          setQuoteBillingItems(addErrorToArray(result.data.data.items));
          const { arItem } = result.data.data;
          if (arItem) {
            quoteDetails.ar_records = [arItem];
            dispatch({
              type: "SET_QUOTE_DETAILS",
              quoteDetails: quoteDetails,
            });
          }
        } catch (e) {
          logException(e, "Cannot add new billing item.");
        }
      }
    } else {
      item.error = err.error;
      setUpdateDummy(!updateDummy);
    }
  };

  let addItemsWithoutTicketCreation = true;
  if (
    quoteDetails.quote_items_planning &&
    quoteDetails.quote_items_planning.length > 0
  ) {
    const planningItemsWT = quoteDetails.quote_items_planning.filter(
      (item) =>
        item.has_worktickets === 0 &&
        ["bulk", "unit", "service"].includes(item.quote_item.item_type)
    );
    if (planningItemsWT.length > 0) {
      addItemsWithoutTicketCreation = true;
    } else {
      addItemsWithoutTicketCreation = false;
    }
  }

  return (
    <Box>
      <SideDrawer />
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rect"
          height={80}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <div>
          <ConfirmDialog
            open={openDuplicate}
            handleConfirm={duplicateItem}
            handleClose={closeDuplicate}
            message={duplicateMessage}
            quoteId={quoteDetails.number}
          />
          <ConfirmDialog
            open={openDelete}
            handleConfirm={deleteItem}
            handleClose={closeDelete}
            message={deleteMessage}
            quoteId={quoteDetails.number}
          />
          <MessageDialog
            title={"Success!"}
            open={openMessage}
            handleClose={closeMessage}
            message={message}
          />
          <ErrorMessageDialog
            title={""}
            open={openMessageError}
            handleClose={closeMessageError}
            message={message}
          />
          <Box mt={3}>
            <Grid container className={classes.itemsContainer}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={classes.itemsActionsContainer}
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemHeaderSection}
                  noWrap
                >
                  Items
                </Typography>
                <Box className={classes.containerItemsActions}>
                  {[5].includes(quoteDetails.status) &&
                  quoteDetails.generator.length > 0 ? (
                    <Tooltip title="Delete the recurrence so you can edit this quote">
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={handleClick}
                          className={classNames(
                            classes.button,
                            classes.buttonAdd
                          )}
                          disabled={readOnlyGlobal}
                        >
                          Add Item
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : quoteDetails.can_add_items ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={handleClick}
                      className={classNames(classes.button, classes.buttonAdd)}
                      disabled={readOnlyGlobal}
                    >
                      Add Item
                    </Button>
                  ) : (
                    <Tooltip title="Billing amount has been sent to finance.">
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableElevation
                          className={classNames(
                            classes.button,
                            classes.buttonAdd
                          )}
                          disabled={true}
                        >
                          Add Item
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.itemsContainer}>
              <Grid item xs={6} md={5} lg={5}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemHeader}
                  noWrap
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemHeaderCenter}
                  noWrap
                >
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemHeaderCenter}
                  noWrap
                >
                  {quoteDetails.type !== 1 ? "Hours" : "Unit Cost"}
                </Typography>
              </Grid>
              <Grid item xs={5} md={2} lg={2}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemHeaderCenter}
                  noWrap
                >
                  {quoteDetails.type !== 1 ? "Amount" : "Total Cost"}
                </Typography>
              </Grid>
              <Grid item xs={1} md={1} lg={1}></Grid>
            </Grid>
            {quoteDetails.quote_items?.map((item, index) => {
              return (
                <Grid
                  container
                  className={classes.itemsContainerDetail}
                  key={item.id}
                >
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemDetail}
                      noWrap
                    >
                      {item.item_subject}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemDetail}
                      noWrap
                    >
                      {quoteDetails.type !== 1
                        ? item.quantity
                          ? `${formatNumber(item.quantity)} ${
                              item.uom ? item.uom : ""
                            }`
                          : "-----"
                        : item.quote_items_user?.reduce((tot, arr) => {
                            return tot + arr.quantity;
                          }, 0)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemDetail}
                      noWrap
                    >
                      {quoteDetails.type !== 1
                        ? item.hours
                          ? formatNumber(parseFloat(item.hours).toFixed(2))
                          : "-----"
                        : item.total_cost && item.item_type !== "task"
                        ? `$ ${formatNumber(
                            (
                              parseFloat(item.total_cost) /
                              parseFloat(
                                item.quote_items_user?.reduce((tot, arr) => {
                                  return tot + arr.quantity;
                                }, 0)
                              )
                            ).toFixed(2)
                          )}`
                        : "-----"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemDetail}
                      noWrap
                    >
                      {(item.total ||
                        (item.total_cost && quoteDetails.type === 1)) &&
                      item.item_type !== "task"
                        ? quoteDetails.type !== 1
                          ? `$ ${formatNumber(
                              parseFloat(item.total).toFixed(2)
                            )}`
                          : `$ ${formatNumber(
                              parseFloat(item.total_cost).toFixed(2)
                            )}`
                        : "-----"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    className={classes.alignRight}
                  >
                    {item.item_type !== "task" ? (
                      <IconButton
                        onClick={(event) => {
                          handleClickItem(event, item.id);
                        }}
                        className={classes.menuItemIcon}
                      >
                        <MenuIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {(!quoteDetails.quote_items ||
              quoteDetails.quote_items.length === 0) && (
              <EmptyScreen
                type={3}
                detailText={"You Haven't Added Anything Yet"}
                actionText="Add an Item"
                action={handleClick}
              />
            )}
            <Grid container className={classes.itemsContainerTotal}>
              <Grid
                item
                xs={12}
                md={parseInt(quoteDetails.type) !== 1 ? 7 : 9}
                lg={parseInt(quoteDetails.type) !== 1 ? 7 : 9}
              >
                <Box className={classes.subTotalConatiner}>
                  <Box>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummary}
                      noWrap
                    >
                      Sub Totals
                    </Typography>
                  </Box>
                  <Box className={classes.lineContainer}></Box>
                </Box>
              </Grid>
              {parseInt(quoteDetails.type) !== 1 ? (
                <Grid item xs={6} md={2} lg={2} className={classes.alignRight}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  >
                    {formatNumber(parseFloat(quoteDetails.hours).toFixed(2))}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={5} md={2} lg={2} className={classes.alignRight}>
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.itemSummary}
                  noWrap
                >
                  {parseInt(quoteDetails.is_old_structure)
                    ? `$ ${formatNumber(
                        parseFloat(quoteDetails.total ?? 0).toFixed(2)
                      )}`
                    : parseInt(quoteDetails.type) !== 1
                    ? `$ ${formatNumber(
                        parseFloat(quoteDetails.sub_total ?? 0).toFixed(2)
                      )}`
                    : `$ ${formatNumber(
                        parseFloat(quoteDetails.total_cost ?? 0).toFixed(2)
                      )}`}
                </Typography>
              </Grid>
              <Grid item xs={1} md={1} lg={1}></Grid>
            </Grid>
            {parseInt(quoteDetails.type) !== 1 &&
            !parseInt(quoteDetails.is_old_structure) ? (
              <Grid
                container
                className={classes.itemsContainerBillingActionsButtons}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Box className={classes.subTotalConatiner}>
                    {quoteDetails?.ar_records.length > 0 &&
                    ![0, 2].includes(
                      parseInt(quoteDetails.ar_records[0].status)
                    ) ? (
                      <Tooltip title="Billing Record has been sent to Financial.">
                        <Box>
                          <Link
                            component="button"
                            className={classes.linkBillingLabelDisable}
                            onClick={() => {}}
                            disabled={true}
                          >
                            +Tax
                          </Link>
                          <Link
                            component="button"
                            className={classes.linkBillingLabelDisable}
                            onClick={() => {}}
                            disabled={true}
                          >
                            +Discount
                          </Link>
                          <Link
                            component="button"
                            className={classes.linkBillingLabelDisable}
                            onClick={() => {}}
                            disabled={true}
                          >
                            +Fee
                          </Link>
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box>
                        <Link
                          component="button"
                          className={classes.linkBillingLabel}
                          onClick={() => {
                            addBillingItem(1);
                          }}
                        >
                          +Tax
                        </Link>
                        <Link
                          component="button"
                          className={classes.linkBillingLabel}
                          onClick={() => {
                            addBillingItem(2);
                          }}
                        >
                          +Discount
                        </Link>
                        <Link
                          component="button"
                          className={classes.linkBillingLabel}
                          onClick={() => {
                            addBillingItem(3);
                          }}
                        >
                          +Fee
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {parseInt(quoteDetails.type) !== 1
              ? quoteBillingItems?.map((item) => {
                  return (
                    <Grid
                      container
                      className={classes.itemsContainerDetail}
                      key={item.id}
                    >
                      <Grid item xs={6} md={5} lg={5}>
                        <FormInputBillingItem
                          gridSizes={[{ size: "md", val: 12 }]}
                          name={"name"}
                          noMargin={true}
                          label={
                            parseInt(item.type) === 1
                              ? "Tax"
                              : parseInt(item.type) === 2
                              ? "Discount"
                              : "Fee"
                          }
                          value={item.name}
                          handleBlur={(event) => {
                            updateBillingItem(
                              item,
                              { name: event.value, id: item.id },
                              event.name
                            );
                          }}
                          error={item.error}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        className={classes.totalRight}
                      >
                        <FormInputBillingItem
                          gridSizes={[{ size: "md", val: 12 }]}
                          name={"total"}
                          noMargin={true}
                          startAdornment="$"
                          withoutLabel={true}
                          value={parseFloat(item.total)}
                          handleBlur={(event) => {
                            updateBillingItem(
                              item,
                              {
                                total: parseFloat(event.value),
                                id: item.id,
                                type: parseInt(item.type),
                                originalTotal: parseFloat(item.total),
                              },
                              event.name
                            );
                          }}
                          alignText="right"
                          error={item.error}
                          readonly={
                            quoteDetails.ar_records?.length > 0 &&
                            ![0, 2].includes(
                              parseInt(quoteDetails.ar_records[0].status)
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        className={classes.alignRight}
                      ></Grid>
                      <Grid
                        item
                        xs={5}
                        md={2}
                        lg={2}
                        className={classes.alignRightBilling}
                      >
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemDetail}
                          noWrap
                        >
                          {`$ ${formatNumber(
                            parseFloat(item.total).toFixed(2)
                          )}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        md={1}
                        lg={1}
                        className={classes.alignRightBillingIcon}
                      >
                        {quoteDetails.ar_records?.length > 0 &&
                        (![0, 2].includes(
                          parseInt(quoteDetails.ar_records[0].status)
                        ) ||
                          ([1, 3].includes(parseInt(item.type)) &&
                            parseFloat(item.total) >=
                              parseFloat(
                                quoteDetails.ar_records[0].net_due
                              ))) ? (
                          <Tooltip
                            title={
                              ![0, 2].includes(
                                parseInt(quoteDetails.ar_records[0].status)
                              )
                                ? "Billing amount has already been sent to Finance."
                                : "Pending billing amount could not be less than 0."
                            }
                          >
                            <Box>
                              <IconButton
                                onClick={() => {}}
                                className={classes.menuItemIcon}
                                disabled={true}
                              >
                                <MenuIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        ) : (
                          <IconButton
                            onClick={(event) => {
                              handleClickBillingItem(event, item.id);
                            }}
                            className={classes.menuItemIcon}
                          >
                            <MenuIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })
              : ""}
            {!parseInt(quoteDetails.is_old_structure) ? (
              <Grid container className={classes.itemsContainerTotals}>
                <Grid item xs={12} md={9} lg={9}>
                  <Box className={classes.subTotalConatiner}>
                    <Box>
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.itemSummary}
                        noWrap
                      >
                        {parseInt(quoteDetails.type) !== 1
                          ? "Total"
                          : "Total Costs"}
                      </Typography>
                    </Box>
                    <Box className={classes.lineContainer}></Box>
                  </Box>
                </Grid>
                <Grid item xs={5} md={2} lg={2} className={classes.alignRight}>
                  <Typography
                    variant="body2"
                    color="inherit"
                    className={classes.itemSummary}
                    noWrap
                  >
                    {parseInt(quoteDetails.type) !== 1
                      ? `$ ${formatNumber(
                          parseFloat(totalQuote ?? 0).toFixed(2)
                        )}`
                      : `$ ${formatNumber(
                          parseFloat(quoteDetails.total_cost ?? 0).toFixed(2)
                        )}`}
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1} className={classes.alignRight}>
                  {parseInt(quoteDetails.type) !== 1 ? (
                    <MonetizationOnIcon className={classes.informationIcon} />
                  ) : (
                    <WarningIcon className={classes.warningIcon} />
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {parseInt(quoteDetails.type) !== 1 &&
            !parseInt(quoteDetails.is_old_structure) ? (
              <>
                <Grid
                  container
                  className={classes.itemsContainerBillingActions}
                >
                  <Grid item xs={12} md={9} lg={9}>
                    <Box className={classes.subTotalConatiner}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemSummaryDetail}
                          noWrap
                        >
                          {quoteDetails.is_completed === 1
                            ? "Actual Costs"
                            : "Est. Costs"}
                        </Typography>
                      </Box>
                      <Box className={classes.lineContainerDetail}></Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummaryDetail}
                      noWrap
                    >
                      {quoteDetails.is_completed === 1
                        ? `$ ${formatNumber(
                            (
                              parseFloat(quoteDetails.actual_ap_cost ?? 0) +
                              parseFloat(quoteDetails.actual_inhouse_cost ?? 0)
                            ).toFixed(2)
                          )}`
                        : `$ ${formatNumber(
                            parseFloat(quoteDetails.total_cost ?? 0).toFixed(2)
                          )}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    className={classes.alignRight}
                  >
                    <WarningIcon className={classes.warningIcon} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.itemsContainerBillingActions}
                >
                  <Grid item xs={12} md={9} lg={9}>
                    <Box className={classes.subTotalConatiner}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemSummaryDetail2}
                          noWrap
                        >
                          {quoteDetails.is_completed === 1
                            ? "Actual Profit"
                            : "Est. Profit"}
                        </Typography>
                      </Box>
                      <Box className={classes.lineContainerDetail}></Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummaryDetail2}
                      noWrap
                    >
                      {quoteDetails.is_completed === 1
                        ? `$ ${formatNumber(
                            (
                              parseFloat(totalQuote) -
                              parseFloat(quoteDetails.actual_ap_cost ?? 0) -
                              parseFloat(quoteDetails.actual_inhouse_cost ?? 0)
                            ).toFixed(2)
                          )}`
                        : `$ ${formatNumber(
                            (
                              parseFloat(totalQuote) -
                              parseFloat(quoteDetails.total_cost ?? 0)
                            ).toFixed(2)
                          )}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    className={classes.alignRight}
                  >
                    <CheckCircleIcon className={classes.ckeckIcon} />
                  </Grid>
                </Grid>{" "}
              </>
            ) : (
              ""
            )}
          </Box>
        </div>
      )}
      <Menu
        id="quote-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          className: classes.menu,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            openDetail("service");
          }}
          className={classes.menuItem}
        >
          Services
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDetail("unit");
          }}
          className={classes.menuItem}
        >
          Custom Item
        </MenuItem>
        {addItemsWithoutTicketCreation ? (
          <MenuItem
            onClick={() => {
              openDetail("good");
            }}
            className={classes.menuItem}
          >
            Item
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <Menu
        id="quote-item-menu"
        anchorEl={anchorItemEl}
        open={Boolean(anchorItemEl)}
        onClose={handleCloseItem}
        MenuListProps={{
          className: classes.menu,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuActions.map((itemMenu) => {
          return (
            <MenuItem
              className={classes.menuItem}
              key={itemMenu.label}
              onClick={itemMenu.action}
            >
              {itemMenu.label}
              {itemMenu.sub && (
                <KeyboardArrowDown className={classes.subMenuIcon} />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default QuoteItems;
