import React from "react";

const surveyInternalIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.32812 6.09375H3.51562C2.09175 6.09375 0.9375 4.9395 0.9375 3.51562C0.9375 2.09175 2.09175 0.9375 3.51562 0.9375H6.32812C4.90425 0.9375 3.75 2.09175 3.75 3.51562C3.75 4.9395 4.90425 6.09375 6.32812 6.09375ZM16.7344 14.1562H19.5469C18.123 14.1562 16.9688 13.002 16.9688 11.5781C16.9688 10.1543 18.123 9 19.5469 9H16.7344C15.3105 9 14.1562 10.1543 14.1562 11.5781C14.1562 13.002 15.3105 14.1562 16.7344 14.1562ZM16.9688 20.4844C16.9688 19.0605 18.123 17.9062 19.5469 17.9062H16.7344C15.3105 17.9062 14.1562 19.0605 14.1562 20.4844C14.1562 21.9082 15.3105 23.0625 16.7344 23.0625H19.5469C18.123 23.0625 16.9688 21.9082 16.9688 20.4844Z"
      fill="#17457A"
      fillOpacity="0.1"
    />
    <path
      d="M7.26562 7.03125H3.51562C1.57711 7.03125 0 5.45414 0 3.51562C0 1.57711 1.57711 0 3.51562 0H7.26562C9.20414 0 10.7812 1.57711 10.7812 3.51562C10.7812 5.45414 9.20414 7.03125 7.26562 7.03125ZM3.51562 1.875C2.61098 1.875 1.875 2.61098 1.875 3.51562C1.875 4.42027 2.61098 5.15625 3.51562 5.15625H7.26562C8.17027 5.15625 8.90625 4.42027 8.90625 3.51562C8.90625 2.61098 8.17027 1.875 7.26562 1.875H3.51562ZM20.4844 24H16.7344C14.7959 24 13.2188 22.4229 13.2188 20.4844C13.2188 18.5459 14.7959 16.9688 16.7344 16.9688H20.4844C22.4229 16.9688 24 18.5459 24 20.4844C24 22.4229 22.4229 24 20.4844 24ZM16.7344 18.8438C15.8297 18.8438 15.0938 19.5797 15.0938 20.4844C15.0938 21.389 15.8297 22.125 16.7344 22.125H20.4844C21.389 22.125 22.125 21.389 22.125 20.4844C22.125 19.5797 21.389 18.8438 20.4844 18.8438H16.7344ZM20.4844 15.0938H16.7344C14.7959 15.0938 13.2188 13.5166 13.2188 11.5781C13.2188 9.63961 14.7959 8.0625 16.7344 8.0625H20.4844C22.4229 8.0625 24 9.63961 24 11.5781C24 13.5166 22.4229 15.0938 20.4844 15.0938ZM16.7344 9.9375C15.8297 9.9375 15.0938 10.6735 15.0938 11.5781C15.0938 12.4828 15.8297 13.2188 16.7344 13.2188H20.4844C21.389 13.2188 22.125 12.4828 22.125 11.5781C22.125 10.6735 21.389 9.9375 20.4844 9.9375H16.7344Z"
      fill="white"
    />
    <path
      d="M10.6406 19.5469H7.78125C7.00584 19.5469 6.375 18.916 6.375 18.1406V12.5625H10.4062C10.924 12.5625 11.3438 12.1428 11.3438 11.625C11.3438 11.1072 10.924 10.6875 10.4062 10.6875H6.375V9.84375C6.375 9.32597 5.95528 8.90625 5.4375 8.90625C4.91972 8.90625 4.5 9.32597 4.5 9.84375V18.1406C4.5 19.9499 5.97197 21.4219 7.78125 21.4219H10.6406C11.1584 21.4219 11.5781 21.0022 11.5781 20.4844C11.5781 19.9666 11.1584 19.5469 10.6406 19.5469Z"
      fill="white"
    />
  </svg>
);

export default surveyInternalIcon;
