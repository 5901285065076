import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  detail: {
    marginLeft: 4,
  },
  label: {
    textAlign: "center",
    fontSize: 12,
    color: "#ffffff",
    borderRadius: 4,
    padding: 1,
    fontWeight: "bold",
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

export default useStyles;
