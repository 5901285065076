import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
    marginRight: 3,
    fontWeight: "bold",
    color: "#ffffff",
    "& svg": {
      fontSize: 14,
      color: "#ffffff",
    },
  },
  chipQuick: {
    backgroundColor: "#78c1ce",
  },
  chipAddOn: {
    backgroundColor: "#9cbb65",
  },
  chipPeriodic: {
    backgroundColor: "#efc945",
  },
  chipStaffing: {
    backgroundColor: "#F07A8C",
  },
  chipPortfolio: {
    backgroundColor: "#f39a3e",
  },
  chipPreventative: {
    backgroundColor: "#9d9d9d",
  },
}));

export default useStyles;
