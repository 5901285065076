import React from "react";
import merge from "lodash/merge";
import {
  getSurveyResults,
  getSurveyResultsSearch,
  getSurveyResultsOffset,
} from "services/surveyService";

const defaultState = {
  resultsListLoading: true,
  resultsSelected: null,
  resultsList: [],
  resultsCount: [],
  openResultDelete: false,
  openView: false,
  openResultBaseline: false,
};

const ResultStateContext = React.createContext();
const ResultDispatchContext = React.createContext();

function resultReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        resultsListLoading: action.resultsListLoading,
      };
    case "SET_RESULTS_LIST":
      return {
        ...state,
        resultsList: action.resultsList,
      };
    case "SET_RESULTS_COUNT":
      return {
        ...state,
        resultsCount: action.resultsCount,
      };
    case "SET_RESULTS_SELECTED":
      return {
        ...state,
        resultsSelected: action.resultsSelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openResultDelete: action.openResultDelete,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    case "TOGGLE_BASELINE":
      return {
        ...state,
        openResultBaseline: action.openResultBaseline,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ResultsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(resultReducer, mergedState);
  return (
    <ResultStateContext.Provider value={state}>
      <ResultDispatchContext.Provider value={dispatch}>
        {children}
      </ResultDispatchContext.Provider>
    </ResultStateContext.Provider>
  );
}

function useResultsState() {
  const context = React.useContext(ResultStateContext);
  if (context === undefined) {
    throw new Error("useResultsState must be used within a ResultProvider");
  }
  return context;
}

function useResultsDispatch() {
  const context = React.useContext(ResultDispatchContext);
  if (context === undefined) {
    throw new Error("useResultDispatch must be used within a ResultProvider");
  }
  return context;
}
async function getResultsList(dispatch, id, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      resultsListLoading: true,
    });
    const result = await getSurveyResults(id, filterData);
    dispatch({
      type: "SET_RESULTS_LIST",
      resultsList: [...result.data.data.surveys],
    });
    dispatch({
      type: "SET_RESULTS_COUNT",
      resultsCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      resultsListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getResultsDataSet(
  resultsList,
  dispatch,
  id,
  offset,
  filterData
) {
  try {
    const result = await getSurveyResultsOffset(id, offset, filterData);
    const updateData = resultsList.concat(result.data.data.surveys);
    dispatch({
      type: "SET_RESULTS_LIST",
      resultsList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchResultsList(id, search, filterData) {
  try {
    const result = await getSurveyResultsSearch(id, search, filterData);
    return result.data.data.surveys;
  } catch (error) {
    console.log(error);
  }
}

export {
  ResultsProvider,
  useResultsState,
  useResultsDispatch,
  getResultsList,
  getResultsDataSet,
  searchResultsList,
};
