import React from "react";
import DueDateDialog from "./dueDateDialog";
import ApproveDialog from "./approveDialog";
import ApproveSingleDialog from "./approveSingleDialog";
import RejectSingleDialog from "./rejectSingleDialog";
import CloneSingleDialog from "./cloneSingleDialog";
import SnoozeSingleDialog from "./snoozeSingleDialog";
import SnoozeDialog from "./snoozeDialog";
import CompleteSingleDialog from "./completeSingleDialog";
import DeleteSingleDialog from "./deleteSingleDialog";

const WorkticketsDialog = (props) => {
  return (
    <>
      <ApproveSingleDialog />
      <RejectSingleDialog />
      <DueDateDialog />
      <ApproveDialog />
      <CloneSingleDialog />
      <SnoozeDialog />
      <SnoozeSingleDialog />
      <CompleteSingleDialog />
      <DeleteSingleDialog />
    </>
  );
};
export default WorkticketsDialog;
