import React, { lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import { BudgetProvider } from "contexts/budgetContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const BudgetsTable = lazy(() => import("./budgetsTable"));
const BudgetsDialog = lazy(() => import("./dialog/budgetsDialog"));

const BudgetsList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Budgets" link="/budgets" />
      <Box className={classes.pageBodyWrapper}>
        <BudgetProvider>
          <Suspense
            fallback={
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            }
          >
            <BudgetsTable />
            <BudgetsDialog />
          </Suspense>
        </BudgetProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(BudgetsList);
