import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  tableSchedule: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  scheduleBox: {
    height: 500,
    minWidth: 600,
    paddingLeft: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: "10px",
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    height: "85vh",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 1200,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      marginRight: 70,
    },
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: 10,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
  },
  newIndicator1: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#0b417e",
  },
  newIndicator2: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#9cbb65",
  },
  newLabel1: {
    marginLeft: 5,
    fontSize: 16,
    color: "#0b417e",
  },
  newLabel2: {
    marginLeft: 5,
    fontSize: 16,
    color: "#9cbb65",
  },
  alertChip: {
    backgroundColor: "#dc0505",
    fontSize: 10,
    marginLeft: 3,
    position: "relative",
    "& span": {
      padding: 6,
    },
  },
  calendarToolbarHeader: {
    marginBottom: 5,
    "& .MuiGrid-container > div:first-child": {
      display: "none",
    },
    "& .MuiGrid-container .MuiSelect-select": {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 10,
      marginLeft: 40,
    },
  },
  calendarToolbarTitle: {
    textAlign: "center",
  },
  wrapperScheduleClose: {
    textAlign: "right",
  },
  wrapperScheduleProgress: {
    height: 18,
    "& > .MuiLinearProgress-root": {
      margin: 0,
    },
  },
  buttonPrimaryAction: {
    background: "#78c1ce !important",
    textTransform: "Capitalize",
    marginRight: 20,
  },
  eventSchedule: {
    backgroundColor: "#78c1ce !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleNotStarted: {
    backgroundColor: "#78c1ce !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleInProgress: {
    backgroundColor: "#efc945 !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleDone: {
    backgroundColor: "#9cbb65 !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  eventScheduleCancelled: {
    backgroundColor: "#f39a3e !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "1px solid #fafafa !important",
    borderRadius: "0",
  },
  scheduleViewContainer: {
    "&  .MuiListItem-root": {
      marginLeft: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 35,
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      fontSize: 14,
    },
  },
  scheduleViewTitle: {
    fontSize: 18,
  },
  scheduleViewDateTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  scheduleBadge: {
    width: 11,
    height: 11,
    backgroundColor: "#78c1ce",
    borderRadius: 2,
    position: "absolute",
    top: 12,
    left: 20,
  },
  scheduleViewOptions: {
    textAlign: "right",
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  viewAction: {
    textDecoration: "none",
  },
  viewButtonAction: {
    textTransform: "capitalize",
    marginBottom: 15,
  },
  buttonSchedule: {
    textTransform: "capitalize",
    marginRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
