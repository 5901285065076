import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import WorkIcon from "@material-ui/icons/Work";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import WorkticketIcon from "@material-ui/icons/NoteAdd";
import RequestIcon from "@material-ui/icons/Announcement";
import GlobalUiContext from "contexts/globalUiContext";

import {
  permissionProject,
  permissionQuote,
  permissionWorkticket,
  permissionSurface,
  hasPermission,
} from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: "2",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    "& > button": {
      backgroundColor: theme.palette.primary.main,
      width: 60,
      height: 60,
    },
    [theme.breakpoints.down("sm")]: {
      "& > button": {
        backgroundColor: theme.palette.primary.main,
        width: 47,
        height: 47,
      },
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  speedDialAction: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    width: 48,
    height: 48,
    "&:hover": {
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },
}));

export default function SpeedDialTooltipOpen() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { permissions, isNavBarShow } = globalUi;

  const openCreateQuote = () => {
    dispatchGlobalUi({
      type: "SET_FLOW_OBJECT",
      createObject: null,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "quote",
    });
  };

  const openSideDrawer = () => {
    dispatchGlobalUi({
      type: "SET_FLOW",
      createFlow: null,
    });
    dispatchGlobalUi({
      type: "SET_QUICKTICKET",
      quickTicket: null,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "project",
    });
  };

  const openSideDrawerWorkticket = () => {
    dispatchGlobalUi({
      type: "SET_FLOW_OBJECT",
      createObject: null,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "workticket",
    });
  };

  const openSideDrawerWorkticketSurface = () => {
    dispatchGlobalUi({
      type: "SET_FLOW_OBJECT",
      createObject: null,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "workticketSurface",
    });
  };

  const openSideDrawerRequest = () => {
    dispatchGlobalUi({
      type: "SET_FLOW_OBJECT",
      createObject: null,
    });
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: true,
      drawerContent: "request",
    });
  };

  const actions = [
    {
      icon: <WorkIcon />,
      name: "Add Project",
      callback: openSideDrawer,
      permission: permissionProject.CREATE,
    },
    {
      icon: <QuoteIcon />,
      name: "Add Quote",
      callback: openCreateQuote,
      permission: permissionQuote.CREATE,
    },
    {
      icon: <WorkticketIcon />,
      name: "Add Workticket",
      callback: openSideDrawerWorkticket,
      permission: permissionWorkticket.CREATE,
    },
    {
      icon: <RequestIcon />,
      name: "Add Request",
      callback: openSideDrawerRequest,
      permission: permissionSurface.REQUEST,
    },
    {
      icon: <WorkticketIcon />,
      name: "Urgent WO",
      callback: openSideDrawerWorkticketSurface,
      permission: permissionSurface.CREATE_WORKTICKET,
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!isNavBarShow) {
    return null;
  }

  if (
    !hasPermission(permissionProject.CREATE, permissions) &&
    !hasPermission(permissionQuote.CREATE, permissions) &&
    !hasPermission(permissionWorkticket.CREATE, permissions) &&
    !hasPermission(permissionSurface.REQUEST, permissions)
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Backdrop open={open} className={classes.backDrop} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onClick={handleOpen}
        open={open}
        id={
          window.location.pathname === "/dashboard"
            ? "speed-dial"
            : "speed-dial-non-dashboard"
        }
      >
        {actions.map((action) =>
          hasPermission(action.permission, permissions) ? (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.callback}
              className={classes.speedDialAction}
            />
          ) : null
        )}
      </SpeedDial>
    </div>
  );
}
