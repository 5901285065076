import React from "react";
import { getLearnData } from "services/learnService";
import { merge } from "lodash";

const defaultState = {
  learnCategoriesLoading: true,
  learnCategorySelected: null,
  learnCategoriesList: [],
  learnCategoriesCount: [],
  openLearnCategoriesDelete: false,
  openManageCategory: false,
};

const LearnCategoriesStateContext = React.createContext();
const LearnCategoriesDispatchContext = React.createContext();

function learnCategoriesReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        learnCategoriesLoading: action.learnCategoriesLoading,
      };
    case "SET_CATEGORIES_LIST":
      return {
        ...state,
        learnCategoriesList: action.learnCategoriesList,
      };
    case "SET_CATEGORIES_COUNT":
      return {
        ...state,
        learnCategoriesCount: action.learnCategoriesCount,
      };
    case "SET_CATEGORIES_SELECTED":
      return {
        ...state,
        learnCategorySelected: action.learnCategorySelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openLearnCategoriesDelete: action.openLearnCategoriesDelete,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageCategory: action.openManageCategory,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LearnCategoriesProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(
    learnCategoriesReducer,
    mergedState
  );
  return (
    <LearnCategoriesStateContext.Provider value={state}>
      <LearnCategoriesDispatchContext.Provider value={dispatch}>
        {children}
      </LearnCategoriesDispatchContext.Provider>
    </LearnCategoriesStateContext.Provider>
  );
}

function useLearnCategoriesState() {
  const context = React.useContext(LearnCategoriesStateContext);
  if (context === undefined) {
    throw new Error(
      "useLearnCategoriesState must be used within a LearnCategoriesProvider"
    );
  }
  return context;
}

function useLearnCategoriesDispatch() {
  const context = React.useContext(LearnCategoriesDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLearnCategoriesDispatch must be used within a LearnCategoriesProvider"
    );
  }
  return context;
}

async function getLearnCategoriesList(dispatch) {
  try {
    dispatch({
      type: "SET_LOADING",
      learnCategoriesLoading: true,
    });
    const result = await getLearnData();
    dispatch({
      type: "SET_CATEGORIES_LIST",
      learnCategoriesList: [...result.data.data.categories],
    });
    dispatch({
      type: "SET_CATEGORIES_COUNT",
      learnCategoriesCount: result.data.data.categories.length,
    });
    dispatch({
      type: "SET_LOADING",
      learnCategoriesLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}
export {
  LearnCategoriesProvider,
  useLearnCategoriesState,
  useLearnCategoriesDispatch,
  getLearnCategoriesList,
};
