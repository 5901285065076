import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import UserBreadcrumb from "./roleBreadcrumb";
import RolePermissions from "./rolePermissions";

import { getRoleDetail } from "services/superService";
import { useRoleView } from "contexts/roleViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const RoleLayout = (props) => {
  const classes = useStyles();
  const context = useRoleView();
  const dispatchContext = context[1];

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        if (props.id !== "new") {
          const result = await getRoleDetail(props.id);
          dispatchContext({
            type: "SET_ROLE",
            role: result.data.data.role,
          });

          dispatchContext({
            type: "SET_OPTIONS",
            options: result.data.options,
          });

          dispatchContext({
            type: "SET_LOADING",
            isLoading: false,
          });
        }
      } catch (e) {
        logException(e, "Cannot load role data");
      }
    };
    loadWorkticket();
  }, [props.id, dispatchContext]);

  return (
    <>
      <UserBreadcrumb />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.containerBody}>
            <RolePermissions {...props} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleLayout;
