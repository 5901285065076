import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: "1px dashed #b4b4b4",
    borderRadius: 5,
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 60,
    cursor: "pointer",
  },
  titleFileUpload: {
    fontSize: 20,
    color: "#c4c4c4",
    marginBottom: 0,
  },
  titleFile: {
    fontSize: 16,
    color: "#747474",
    marginBottom: 5,
    marginTop: 5,
    fontWeight: "bold",
  },
  uploadLink: {
    position: "absolute",
    right: 25,
    top: 10,
    color: "#359be0",
    textDecoration: "underline",
    fontSize: 12,
    cursor: "pointer",
  },
  fileDisplayWrapper: {
    border: "1px dashed #b4b4b4",
    borderRadius: 5,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
    position: "relative",
  },
  fileDisplayContainer: {
    textAlign: "left",
    padding: 10,
    border: "1px dashed #b4b4b4",
    minHeight: 200,
    borderRadius: 5,
    cursor: "pointer",
    overflow: "hidden",
  },
  containerAlert: {
    border: 0,
    marginBottom: 4,
  },
}));

export default useStyles;
