import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: "1px solid #d4d4d4",
  },
  chip: {
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
    fontWeight: "bold",
    "& svg": {
      fontSize: 14,
      color: "#fff",
      marginTop: -1,
      position: "relative",
    },
  },
  chipActive: {
    backgroundColor: "#9cbb65",
    color: "#fff",
  },
}));

export default useStyles;
