import React, { useState, useEffect } from "react";
import {
  getDashboardLocationList,
  getAllSliderWorkList,
  useDashboardDispatch,
  useDashboardState,
  getAllSliderAssetList,
  setDashboardPropsData,
} from "contexts/dashboardContext";

import DashboardHeader from "./dashboardHeader";
import DetailsSlider from "./DetailsSlider";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReactGoogleMapComponent from "./common/ReactGoogleMapComponent";
import ReactSelect from "./common/ReactSelect";
import ReactSelectCustomer from "./common/ReactSelectCustomer";
import { makeStyles } from "@material-ui/core/styles";
import AssetDetailsModal from "./assets/AssetDetailsModal";
import Overlay from "./Overlay";
import Box from "@material-ui/core/Box";
import VerticalTabs from "./VerticalTabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  progress: {
    position: "absolute",
    zIndex: 2,
    top: 31,
    width: "100%",
  },
  reactSelect: {
    position: "absolute",
    zIndex: 2,
    top: 50,
    marginLeft: "calc(50% - 250px)",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "calc(50% - 150px)",
      width: 300,
    },
  },
  reactSelectSolo: {
    position: "absolute",
    zIndex: 2,
    top: 50,
    marginLeft: "calc(50% - 150px)",
    width: 300,
  },
  checked: {
    position: "absolute",
    zIndex: 2,
    top: 50,
    marginLeft: "calc(50% + 150px)",
  },
  customToggleDropdown: {
    position: "absolute",
    zIndex: 2,
    top: 50,
    marginLeft: "calc(50% - 10px)",
    backgroundColor: "#ffffff",
    width: 300,
  },
  assetDetailsModalContainer: { display: "flex", justifyContent: "center" },
  filtersContainer: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      flex: 1,
    },
    "& > div:first-child": {
      marginRight: 3,
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& > div:first-child": {
        marginRight: 0,
      },
      "& > div": {
        marginBottom: 2,
      },
    },
  },
}));

const Dashboard = ({ history, isSurfaceUser }) => {
  const classes = useStyles();
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const dispatch = useDashboardDispatch();
  const dashboardState = useDashboardState();
  const isMobile = useMediaQuery("(max-width:500px)");
  const [mobileClickEvent, setClickEvent] = useState(null);
  const [assetModalDataVisibility, setAssetModalDataVisibility] = useState({
    selectedRowData: null,
    visibility: false,
  });
  const [currentSelectedTab, setCurrentSelectedTab] = useState(1);

  useEffect(() => {
    if (isMobile) {
      setDashboardPropsData(dispatch, {
        expanded: true,
        closeButtonForMobile: true,
      });

      setDashboardPropsData(dispatch, {
        currentSelectedTab: currentSelectedTab,
      });
    } else {
      setDashboardPropsData(dispatch, {
        currentSelectedTab: currentSelectedTab,
      });
    }
  }, [mobileClickEvent]);

  useEffect(() => {
    if (isMobile) {
      if (
        currentSelectedTab !==
        dashboardState?.dasboardPropsData?.currentSelectedTab
      ) {
        setDashboardPropsData(dispatch, {
          expanded: true,
          closeButtonForMobile: true,
        });
      }
      setDashboardPropsData(dispatch, {
        currentSelectedTab:
          currentSelectedTab ===
          dashboardState.dasboardPropsData.currentSelectedTab
            ? Boolean(false)
            : currentSelectedTab,
      });
    } else {
      setDashboardPropsData(dispatch, {
        currentSelectedTab: currentSelectedTab,
      });
    }
  }, [dispatch, currentSelectedTab]);

  useEffect(() => {
    if (history?.location?.search && !dashboardState.mapLocationLoading) {
      const query = new URLSearchParams(history?.location?.search);

      const jobNumber = query.get("job_number");
      if (jobNumber) {
        const selectedLoc = dashboardState?.mapLocationList?.data?.jobs.find(
          (v) => String(v.job_number) === jobNumber
        );
        setSelectedLocation({
          ...selectedLoc,
        });
        const item = dashboardState?.mapCustomerList?.data?.customers.find(
          (v) => parseInt(v.id) === parseInt(selectedLoc.customer_id)
        );
        setSelectedCustomer(item);
      }

      const customerId = query.get("customer_id");
      if (customerId) {
        const item = dashboardState?.mapCustomerList?.data?.customers.find(
          (v) => parseInt(v.id) === parseInt(customerId)
        );
        setSelectedCustomer(item);
      }
    } else {
      setSelectedLocation({});
      setDashboardPropsData(dispatch, { selectedLocation: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    getDashboardLocationList(dispatch, isSurfaceUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocation && Object.keys(selectedLocation) !== 0) {
      if (
        currentSelectedTab === 3 &&
        dashboardState?.dasboardPropsData?.expanded
      ) {
        getAllSliderWorkList(
          dispatch,
          selectedLocation.job_number,
          isSurfaceUser,
          dashboardState
        );
      }
      if (
        currentSelectedTab === 5 &&
        dashboardState?.dasboardPropsData?.expanded
      ) {
        getAllSliderAssetList(
          dispatch,
          selectedLocation.job_number,
          isSurfaceUser,
          false,
          0,
          dashboardState
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedLocation?.job_number,
    currentSelectedTab,
    dashboardState?.dasboardPropsData?.expanded,
  ]);

  useEffect(() => {
    setDashboardPropsData(dispatch, {
      jobNumbersInBound: "",
      expanded: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState?.dasboardPropsData?.boundsChanged]);

  useEffect(() => {
    if (!dashboardState.mapLocationLoading && history?.location?.search) {
      const query = new URLSearchParams(history?.location?.search);

      const jobNumber = query.get("job_number");
      if (jobNumber) {
        const selectedLoc = dashboardState?.mapLocationList?.data?.jobs.find(
          (v) => String(v.job_number) === jobNumber
        );
        setSelectedLocation({
          ...selectedLoc,
        });
        const item = dashboardState?.mapCustomerList?.data?.customers.find(
          (v) => parseInt(v.id) === parseInt(selectedLoc.customer_id)
        );
        setSelectedCustomer(item);
      }

      const customerId = query.get("customer_id");
      if (customerId) {
        const item = dashboardState?.mapCustomerList?.data?.customers.find(
          (v) => parseInt(v.id) === parseInt(customerId)
        );
        setSelectedCustomer(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.mapLocationLoading]);

  const onSelectChange = (e) => {
    if (e) {
      history.push(`/dashboard?job_number=${e.originalObject.job_number}`);
    } else {
      if (!dashboardState.mapLocationLoading) {
        history.push(`/dashboard`);
      }
    }

    if (!isSurfaceUser) {
      const customerList = dashboardState?.mapCustomerList?.data?.customers;
      if (e?.originalObject?.customer_id) {
        const findCustomer = customerList.find(
          (customerItem) => customerItem.id === e.originalObject.customer_id
        );

        if (findCustomer) {
          setSelectedCustomer(findCustomer);
        }
      }
    } else {
      setSelectedCustomer({});
    }

    setSelectedLocation(e ? { ...e.originalObject } : {});
    setDashboardPropsData(dispatch, {
      selectedLocation: e ? { ...e.originalObject } : {},
    });
  };

  const onSelectCustomerChange = (e) => {
    if (e) {
      history.push(`/dashboard?customer_id=${e.originalObject.id}`);
    } else {
      if (!dashboardState.mapLocationLoading) {
        history.push(`/dashboard`);
      }
    }

    setSelectedCustomer(e ? { ...e.originalObject } : {});
    setSelectedLocation({});
    setDashboardPropsData(dispatch, {
      selectedCustomer: e ? { ...e.originalObject } : {},
    });
    setDashboardPropsData(dispatch, {
      selectedLocation: {},
    });
  };

  return (
    <Box>
      <DashboardHeader />
      <Box className={classes.assetDetailsModalContainer}>
        {assetModalDataVisibility.visibility ? (
          <AssetDetailsModal
            assetModalDataVisibility={assetModalDataVisibility}
            setAssetModalDataVisibility={setAssetModalDataVisibility}
            selectedLocation={selectedLocation}
            isSurfaceUser={isSurfaceUser}
            history={history}
          />
        ) : null}
      </Box>
      <ReactGoogleMapComponent
        locations={dashboardState?.mapLocationList?.data?.jobs}
        selectedLocation={selectedLocation}
        selectedCustomer={selectedCustomer}
        onSelectChange={onSelectChange}
        isSurfaceUser={isSurfaceUser}
        isMobile={isMobile}
      />

      {!dashboardState?.mapLocationList?.data?.jobs ? (
        <Box className={classes.progress}>
          <LinearProgress color="secondary" />
        </Box>
      ) : null}
      {isSurfaceUser ? (
        <Box className={classes.reactSelectSolo}>
          {dashboardState?.mapLocationList?.data?.jobs && (
            <Box className={classes.filtersContainer}>
              <ReactSelect
                locations={dashboardState?.mapLocationList?.data?.jobs}
                onSelectChange={onSelectChange}
                selectedLocation={selectedLocation}
                selectedCustomer={selectedCustomer}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box className={classes.reactSelect}>
          {dashboardState?.mapLocationList?.data?.jobs && (
            <Box className={classes.filtersContainer}>
              <ReactSelectCustomer
                customers={dashboardState?.mapCustomerList?.data?.customers}
                onSelectChange={onSelectCustomerChange}
                selectedCustomer={selectedCustomer}
              />
              <ReactSelect
                locations={dashboardState?.mapLocationList?.data?.jobs}
                onSelectChange={onSelectChange}
                selectedLocation={selectedLocation}
                selectedCustomer={selectedCustomer}
              />
            </Box>
          )}
        </Box>
      )}

      {!isMobile && (
        <Box
          className="detailsSlider"
          onClick={() =>
            dashboardState.dasboardPropsData.currentSelectedTab
              ? ""
              : setDashboardPropsData(dispatch, { currentSelectedTab: 0 })
          }
        >
          {dashboardState?.mapLocationList?.data?.jobs && (
            <DetailsSlider
              history={history}
              selectedLocation={selectedLocation}
              isSurfaceUser={isSurfaceUser}
              setAssetModalDataVisibility={setAssetModalDataVisibility}
              setCurrentSelectedTab={setCurrentSelectedTab}
            />
          )}
        </Box>
      )}

      {isMobile &&
        dashboardState?.mapLocationList?.data?.jobs &&
        dashboardState.dasboardPropsData.currentSelectedTab > 0 &&
        dashboardState.dasboardPropsData.closeButtonForMobile && (
          <CloseIcon
            id="close-icon"
            onClick={() =>
              setDashboardPropsData(dispatch, {
                closeButtonForMobile:
                  !dashboardState.dasboardPropsData.closeButtonForMobile,
              })
            }
          />
        )}

      <Box className="detailsSlider">
        {dashboardState?.mapLocationList?.data?.jobs && (
          <Box
            className={
              dashboardState.dasboardPropsData.closeButtonForMobile
                ? "verticalTabs"
                : "verticalTabs-closed-mobile"
            }
          >
            {isMobile && (
              <VerticalTabs
                history={history}
                selectedLocation={selectedLocation}
                isSurfaceUser={isSurfaceUser}
                setAssetModalDataVisibility={setAssetModalDataVisibility}
                setCurrentSelectedTab={setCurrentSelectedTab}
                isMobile={isMobile}
                setClickEvent={setClickEvent}
              />
            )}
          </Box>
        )}
      </Box>
      {assetModalDataVisibility.visibility && (
        <Overlay setAssetModalDataVisibility={setAssetModalDataVisibility} />
      )}
    </Box>
  );
};

export default Dashboard;
