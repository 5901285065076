import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const QuoteActionPlanningContext = React.createContext();

const initialState = {
  openEditTaskList: false,
  openAddTask: false,
  openChangeDueDate: false,
  openChangeAssign: false,
  typeAssign: 0,
  selGroups: [],
  openChangeGroup: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_EDIT_TASK_LIST":
      return {
        ...state,
        openEditTaskList: action.openEditTaskList,
      };
    case "TOGGLE_ADD_TASK":
      return {
        ...state,
        openAddTask: action.openAddTask,
      };
    case "CHANGE_DUE_DATE":
      return {
        ...state,
        openChangeDueDate: action.openChangeDueDate,
      };
    case "CHANGE_ASSIGN":
      return {
        ...state,
        openChangeAssign: action.openChangeAssign,
      };
    case "SET_TYPE_ASSIGN":
      return {
        ...state,
        typeAssign: action.typeAssign,
      };
    case "SET_SEL_GROUPS":
      return {
        ...state,
        selGroups: action.selGroups,
      };
    case "CHANGE_GROUP":
      return {
        ...state,
        openChangeGroup: action.openChangeGroup,
      };
    default:
      return initialState;
  }
};

const QuoteActionPlanningProvider = ({ children }) => (
  <QuoteActionPlanningContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </QuoteActionPlanningContext.Provider>
);

function useQuoteActionPlanning() {
  const context = React.useContext(QuoteActionPlanningContext);
  if (context === undefined) {
    throw new Error(
      "useQuoteActionPlanning must be used within a QuoteActionPlanningProvider"
    );
  }
  return context;
}

export { QuoteActionPlanningProvider, useQuoteActionPlanning };
