import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  large: {
    width: 52,
    height: 52,
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 45,
    },
  },
  speedDial: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 2,
    "& > button": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:hover > button": {
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  speedDialAction: {
    background: "#0b417e",
    boxShadow: "none",
    color: "#ffffff",
    width: 40,
    height: 40,
    "&:hover": {
      background: "#0a3c75",
    },
  },
  speedDialActionActive: {
    background: "#44b700",
    boxShadow: "none",
    color: "#ffffff",
    width: 40,
    height: 40,
    "&:hover": {
      background: "#44b700",
    },
  },
  containerProfile: {
    width: 400,
    backgroundColor: "#0b417e",
    height: "100%",
  },
  wrapperProfile: {
    padding: 35,
    paddingTop: 25,
  },
  infoProfile: {
    textAlign: "center",
  },
  imageProfile: {
    margin: "0 auto",
    width: 52,
    height: 52,
    backgroundColor: "#ffffff",
  },
  titleProfile: {
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10,
  },
  subtitleProfile: {
    color: "#ffffff",
    fontSize: 12,
  },
  passwordProfile: {
    marginTop: 30,
    padding: 10,
    background: "#1c4e86",
    borderRadius: 4,
  },
  labelProfile: {
    color: "#ffffff",
    fontSize: 14,
    fontWeight: "bold",
    width: "100%",
    cursor: "pointer",
    position: "relative",
    "& svg": {
      right: 0,
      position: "absolute",
    },
  },
  passwordFieldsProfile: {
    padding: 20,
    "& label": {
      color: "#ffffff",
    },
  },
  passwordChangeButton: {
    textTransform: "capitalize",
  },
  passwordErrorProfile: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    borderRadius: 4,
  },
  wrapperProfileClose: {
    textAlign: "right",
  },
  iconDrawerClose: {
    color: "#d4d4d4",
  },
  iconTimer: {
    color: "#ffffff",
    fontSize: 20,
  },
  inputImage: {
    display: "none",
  },
  profileImageEdit: {
    width: 25,
    height: 25,
    margin: "0 auto",
    top: -20,
    right: -20,
    cursor: "pointer",
    marginBottom: -25,
  },
  profileIconEdit: {
    fontSize: 14,
  },
  wrapperLoading: {
    textAlign: "center",
    marginTop: 80,
  },
  preferenceFormLabel: {
    width: "100%",
    "& span": {
      fontSize: 14,
    },
  },
  preferenceCheckbox: {
    color: "#ececec",
    "& svg": {
      color: "#ececec",
    },
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
