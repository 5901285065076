import React from "react";
import { merge } from "lodash";

const TableCollapsibleStateContext = React.createContext();
const TableCollapsibleDispatchContext = React.createContext();

const defaultState = {
  tableCollapsibleLoading: false,
  header: [],
  rows: [],
  columns: [],
  hasSearch: true,
  hasFilters: true,
  selected: [],
  useEmpty: false,
  stickyHeader: true,
  multipleOpen: true,
};

function tableCollapsibleReducer(state, action) {
  // console.log(action);
  switch (action.type) {
    case "SET_TABLE_LOADING":
      return {
        ...state,
        rows: state.rows,
        tableCollapsibleLoading: action.tableCollapsibleLoading,
      };
    case "UPDATE_ROWS":
      return {
        ...state,
        rows: action.rows,
      };
    case "UPDATE_HEADER":
      return {
        ...state,
        rows: state.rows,
        header: action.header,
      };
    case "UPDATE_COLUMNS":
      return {
        ...state,
        rows: state.rows,
        columns: action.columns,
      };
    case "SET_TABLE_SEARCH":
      return {
        ...state,
        rows: state.rows,
        hasSearch: action.hasSearch,
      };
    case "SET_USE_EMPTY":
      return {
        ...state,
        rows: state.rows,
        useEmpty: action.useEmpty,
      };
    case "SET_STICKY_HEADER":
      return {
        ...state,
        rows: state.rows,
        stickyHeader: action.stickyHeader,
      };
    case "SET_MULTIPLE_OPEN":
      return {
        ...state,
        rows: state.rows,
        multipleOpen: action.multipleOpen,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TableCollapsibleProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);

  const [state, dispatch] = React.useReducer(
    tableCollapsibleReducer,
    mergedState
  );
  return (
    <TableCollapsibleStateContext.Provider value={state}>
      <TableCollapsibleDispatchContext.Provider value={dispatch}>
        {children}
      </TableCollapsibleDispatchContext.Provider>
    </TableCollapsibleStateContext.Provider>
  );
}

function useTableCollapsibleState() {
  const context = React.useContext(TableCollapsibleStateContext);
  if (context === undefined) {
    throw new Error(
      "useTableCollapsibleState must be used within a TableCollapsibleProvider"
    );
  }
  return context;
}

function useTableCollapsibleDispatch() {
  const context = React.useContext(TableCollapsibleDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useTableCollapsibleDispatch must be used within a TableCollapsibleProvider"
    );
  }
  return context;
}

export {
  TableCollapsibleProvider,
  useTableCollapsibleState,
  useTableCollapsibleDispatch,
};
