import { useGetUserId } from "../context/UserIdContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addUserCustomers,
  addUserLocations,
  createPartnerUser,
  deletePartnerUser,
  getPartnerRoles,
  getPartnerUser,
  getPartnerUsers,
  removeUserLocations,
  updatePartnerUser,
} from "../../../services/partners/usersService";
import { getJobsFilters } from "../../../services/jobService";
import { useEffect, useState } from "react";
import { getCustomers } from "../../../services/userService";
import Integer from "lodash";
import { logException } from "components/util/logUtil";
import { useUserDrawerContext } from "../context/UserDrawerContext";

export function usePartnerUsers() {
  const {
    userToEdit,
    isEditing,
    toggleDrawer,
    handleToggleDrawer,
    drawerState,
    handleDialogueOpen,
    handleDialogueClose,
    isDeleteUserDialogOpen,
    handleDeleteUserDialogueClose,
    handleDeleteUserDialogueOpen,
    selectUser,
  } = useUserDrawerContext();
  const dataInitialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role_id: "",
    employee_number: "",
  };
  const editDataInitialState = {
    user_name: "",
    password: "",
    confirm_password: "",
  };
  const [currentStep, setCurrentStep] = useState(1);
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  function isValidatePhoneNumber(phoneNumber) {
    const phoneNumberRegex =
      /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneNumberRegex.test(phoneNumber);
  }
  const [errors, setErrors] = useState([]);
  const [areThereErrors, setAreThereErrors] = useState(false);
  const [data, setData] = useState(dataInitialState);
  const [editData, setEditData] = useState(editDataInitialState);
  const [originalImage, setOriginalImage] = useState(null);
  const [originalJobs, setOriginalJobs] = useState([]);
  const [originalCustomers, setOriginalCustomers] = useState([]);
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState([]);
  const handleBlur = (event) => {
    const { name, value } = event;
    setData({ ...data, [name]: value });
  };
  const handleBlurConfirmPassword = (event) => {
    const { name, value } = event;
    setData({ ...data, [name]: value });
    let error = "";
    if (value !== data.password) {
      error = "Passwords do not match";
      const dummyError = {
        key: name,
        message: error,
      };
      setConfirmPasswordErrors([dummyError]);
    } else {
      setConfirmPasswordErrors([]);
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useGetUserId();
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [jobsValue, setJobsValue] = useState([]);
  const [customersValue, setCustomersValue] = useState([]);
  const [customerJobData, setCustomerJobData] = useState({
    customers: [],
    jobs: [],
  });
  const { isLoading: areUsersLoading, data: userPartnersList } = useQuery(
    ["partnerUsers", userId],
    async () => {
      const { data } = await getPartnerUsers(userId);
      return data.data.users;
    },
    {
      enabled: !!userId,
    }
  );
  const { isLoading: isJobsLoading, data: jobs } = useQuery(
    "jobs",
    async () => {
      const { data } = await getJobsFilters();
      return data.data.jobs;
    },
    {
      enabled: true,
    }
  );
  const { isLoading: isRolesLoading, data: roles } = useQuery(
    "partnerRoles",
    async () => {
      const { data } = await getPartnerRoles();
      return data.data.roles;
    }
  );
  const {
    isLoading: isUserLoading,
    data: fetchedUser,
    refetch: refetchUser,
  } = useQuery(
    "partnerUser",
    async () => {
      const { data } = await getPartnerUser(userId, userToEdit.id);
      return data.data.user;
    },
    {
      enabled: !!userToEdit?.id,
    }
  );
  useEffect(() => {
    if (fetchedUser && isEditing) {
      refetchUser();
      setData({
        first_name: fetchedUser.first_name,
        last_name: fetchedUser.last_name,
        email: fetchedUser.email,
        phone: fetchedUser.phone,
        employee_number: fetchedUser?.employee_number,
        role_id: fetchedUser?.role_id,
      });
      setOriginalImage(fetchedUser?.profile?.picture?.file_url);

      setJobsValue(transformJobData(fetchedUser.jobs));
      setCustomersValue(transformCustomerData(fetchedUser.customers));
      setCustomerJobData({
        customers: fetchedUser.customers,
        jobs: fetchedUser.jobs,
      });
      setOriginalJobs(fetchedUser.jobs);
      setOriginalCustomers(fetchedUser.customers);
    }
  }, [fetchedUser, isEditing, refetchUser]);
  const transformJobData = (arr) => {
    return arr?.map((item) => ({
      value: item.id,
      label: `${item?.job_number}, ${item.job_description}`,
    }));
  };
  const handleJobChange = async (event, value) => {
    setJobsValue(value ?? []);
    const jobs = value.map((item) => Integer.parseInt(item.value));
    setCustomerJobData({ ...customerJobData, jobs });
  };
  const transFormedJobs = transformJobData(jobs);
  const { isLoading: isCustomersLoading, data: customers } = useQuery(
    "customers",
    async () => {
      const { data } = await getCustomers();
      return data.data.customers;
    },
    {
      enabled: true,
    }
  );
  const transformCustomerData = (arr) => {
    return arr?.map((item) => ({
      value: item.id,
      label: `${item?.customer_number ? item?.customer_number : ""} ${
        item.name
      }`,
    }));
  };
  const transformRoleData = (arr) => {
    return arr?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };
  const transFormedCustomers = transformCustomerData(customers);
  const transformedRoles = transformRoleData(roles);
  const handleCustomerChange = async (event, value) => {
    setCustomersValue(value ?? []);
    const customers = value.map((item) => Integer.parseInt(item.value));
    setCustomerJobData({ ...customerJobData, customers });
  };
  const handleFileChange = (e) => {
    if (originalImage) {
      setOriginalImage(null);
    }
    const file = e.target.files[0];
    setSelectedFile(file ? file : null);
  };
  const updatePartnerUserMutation = useMutation(updatePartnerUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerUsers");
    },
    onError: (error) => {
      setIsSubmitting(false);
      logException(error, "Failed to update user");
    },
  });
  const deleteUserMutation = useMutation(deletePartnerUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerUsers");
      setIsSubmitting(false);
    },
    onError: (error) => {
      setIsSubmitting(false);
      logException(error, "Failed to delete user");
    },
  });
  function handleDeleteUser(userToDelete) {
    setIsSubmitting(true);
    deleteUserMutation.mutate({
      partner_id: userId,
      user_id: userToDelete,
    });
  }
  const assignUserLocationMutation = useMutation(addUserLocations, {
    onError: (error) => {
      logException(error, "Failed to assign Location");
    },
  });
  const removeUserLocationMutation = useMutation(removeUserLocations, {
    onError: (error) => {
      logException(error, "Failed to remove Location");
    },
  });
  const assignUserCustomerMutation = useMutation(addUserCustomers, {
    onError: (error) => {
      logException(error, "Failed to assign Customer");
    },
  });
  const removeUserCustomerMutation = useMutation(removeUserLocations, {
    onError: (error) => {
      logException(error, "Failed to remove Customer");
    },
  });
  const addUserMutation = useMutation(createPartnerUser, {
    onSuccess: () => {
      resetData("User added successfully");
      queryClient.invalidateQueries("partnerUsers");
    },
    onError: (error) => {
      setIsSubmitting(false);
      logException(error, "Failed to add user");
    },
  });
  function resetData(message) {
    setIsSubmitting(false);
    setErrors([]);
    setData(dataInitialState);
    setJobsValue([]);
    setCustomersValue([]);
    setCustomerJobData({
      customers: [],
      jobs: [],
    });
    setSelectedFile(null);
    handleToggleDrawer("right", false, false);
    if (message) {
      handleDialogueOpen(message);
    }

    queryClient.invalidateQueries("partnerUser");
    setCurrentStep(1);
  }
  async function submitData() {
    setIsSubmitting(true);
    const formData = {
      ...data,
      job_ids: customerJobData.jobs,
      customer_ids: customerJobData.customers,
      file: selectedFile,
    };

    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "role_id",
      "employee_number",
      "phone",
    ];
    let hasError = false;
    setAreThereErrors(false);
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        const dummyError = {
          key: field,
          message: "This field is required",
        };
        if (errors.length > 0) {
          const index = errors.findIndex((error) => error.key === field);
          if (index === -1) {
            setErrors((prevErrors) => [...prevErrors, dummyError]);
          }
        } else {
          setErrors((prevErrors) => [...prevErrors, dummyError]);
        }

        hasError = true;
        setAreThereErrors(true);
      }
      //delete error if field is filled
      if (formData[field]) {
        const index = errors.findIndex((error) => error.key === field);
        if (index !== -1) {
          setErrors((prevErrors) =>
            prevErrors.filter((error) => error.key !== field)
          );
        }
      }
    });

    if (!validateEmail(formData.email)) {
      const dummyError = {
        key: "email",
        message: "Invalid email address",
      };
      if (errors.length > 0) {
        const index = errors.findIndex((error) => error.key === "email");
        if (index === -1) {
          setErrors((prevErrors) => [...prevErrors, dummyError]);
        }
      }
      hasError = true;
      setAreThereErrors(true);
    }
    if (!isValidatePhoneNumber(formData.phone)) {
      const dummyError = {
        key: "phone",
        message: "Invalid phone number",
      };
      if (errors.length > 0) {
        const index = errors.findIndex((error) => error.key === "phone");
        if (index === -1) {
          setErrors((prevErrors) => [...prevErrors, dummyError]);
        }
      }
      hasError = true;
      setAreThereErrors(true);
    }
    if (hasError || confirmPasswordErrors.length > 0) {
      setIsSubmitting(false);
      setAreThereErrors(true);
      return;
    }
    let trueFormData = new FormData();
    for (let key in formData) {
      if (key === "job_ids" || key === "customer_ids") {
        formData[key].forEach((item, index) => {
          trueFormData.append(`${key}[${index}]`, item);
        });
      } else {
        trueFormData.append(key, formData[key]);
      }
    }
    if (isEditing) {
      if (!originalImage) {
        data.file = selectedFile;
      }
      let deletedJobs = originalJobs.filter(
        (item) => !customerJobData.jobs.includes(item.id)
      );
      let newJobsTAdd = customerJobData.jobs.filter(
        (item) => !originalJobs.map((job) => job.id).includes(item)
      );
      let deletedCustomers = originalCustomers.filter(
        (item) => !customerJobData.customers.includes(item.id)
      );
      let newCustomersToAdd = customerJobData.customers.filter(
        (item) =>
          !originalCustomers.map((customer) => customer.id).includes(item)
      );
      let editingFormData = new FormData();
      for (let key in data) {
        editingFormData.append(key, data[key]);
      }
      const addJobsIds = newJobsTAdd.map((item) => item.id);
      const addCustomersIds = newCustomersToAdd.map((item) => item.id);
      const deletedJobsIds = deletedJobs.map((item) => item.id);
      const deletedCustomersIds = deletedCustomers.map((item) => item.id);
      const customerAddObj = {
        data: {
          customer_ids: addCustomersIds,
          user_ids: [userToEdit.id],
        },
        partner_id: userId,
      };
      const customerRemoveObj = {
        data: {
          customer_ids: deletedCustomersIds,
          user_ids: [userToEdit.id],
        },
        partner_id: userId,
      };
      const jobAddObj = {
        data: {
          job_ids: addJobsIds,
          user_ids: [userToEdit.id],
        },
        partner_id: userId,
      };
      const jobRemoveObj = {
        data: {
          job_ids: deletedJobsIds,
          user_ids: [userToEdit.id],
        },
        partner_id: userId,
      };
      try {
        if (selectedFile) {
          editingFormData.append("file", selectedFile);
        }
        await updatePartnerUserMutation.mutate({
          partner_id: userId,
          user_id: userToEdit.id,
          data: editingFormData,
        });
        if (deletedJobs.length > 0) {
          await removeUserLocationMutation.mutate(jobRemoveObj);
        }
        if (newJobsTAdd.length > 0 && newJobsTAdd[0] !== "") {
          await assignUserLocationMutation.mutate(jobAddObj);
        }
        if (deletedCustomers.length > 0) {
          await removeUserCustomerMutation.mutate(customerRemoveObj);
        }
        if (newCustomersToAdd.length > 0 && newCustomersToAdd[0] !== "") {
          await assignUserCustomerMutation.mutate(customerAddObj);
        }
        resetData("User updated successfully");
      } catch (error) {
        logException(error, "Failed to update user");
      }
    } else {
      addUserMutation.mutate({
        partner_id: userId,
        data: trueFormData,
      });
    }
  }
  const submitPassword = async () => {
    const requiredFields = ["password", "confirm_password"];
    const formData = {
      password: data.password,
      confirm_password: data.confirm_password,
    };
    let hasError = false;
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        const dummyError = {
          key: field,
          message: "This field is required",
        };
        if (confirmPasswordErrors.length > 0) {
          const index = confirmPasswordErrors.findIndex(
            (error) => error.key === field
          );
          if (index === -1) {
            setConfirmPasswordErrors((prevErrors) => [
              ...prevErrors,
              dummyError,
            ]);
          }
        } else {
          setConfirmPasswordErrors((prevErrors) => [...prevErrors, dummyError]);
        }
        hasError = true;
      }
    });
    if (hasError) return;
    if (confirmPasswordErrors.length > 0) return;
    try {
      await updatePartnerUserMutation.mutate({
        partner_id: userId,
        user_id: userToEdit.id,
        data: {
          password: data.password,
        },
      });
      resetData("User password  updated successfully");
    } catch (error) {
      logException(error, "Failed to update user");
    }
  };
  return {
    areUsersLoading,
    userPartnersList,
    jobs,
    isJobsLoading,
    transFormedJobs,
    jobsValue,
    setJobsValue,
    handleJobChange,
    transformJobData,
    isCustomersLoading,
    transFormedCustomers,
    handleCustomerChange,
    customersValue,
    handleFileChange,
    selectedFile,
    data,
    handleBlur,
    submitData,
    errors,
    isUserLoading,
    handleDeleteUser,
    isSubmitting,
    transformedRoles,
    editData,
    originalImage,
    toggleDrawer,
    drawerState,
    assignUserLocationMutation,
    confirmPasswordErrors,
    handleBlurConfirmPassword,
    handleDialogueOpen,
    handleDialogueClose,
    handleToggleDrawer,
    currentStep,
    setCurrentStep,
    isDeleteUserDialogOpen,
    handleDeleteUserDialogueClose,
    handleDeleteUserDialogueOpen,
    resetData,
    submitPassword,
    addUserMutation,
    updatePartnerUserMutation,
    selectUser,
    areThereErrors,
  };
}
