import React from "react";
import merge from "lodash/merge";
import {
  getRecognition,
  getRecognitionSearch,
  getRecognitionOffset,
} from "services/teamService";

const defaultState = {
  recognitionListLoading: true,
  recognitionSelected: null,
  recognitionList: [],
  recognitionCount: [],
  openRecognitionDelete: false,
  openView: false,
};

const RecognitionStateContext = React.createContext();
const RecognitionDispatchContext = React.createContext();

function recognitionReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        recognitionListLoading: action.recognitionListLoading,
      };
    case "SET_RECOGNITION_LIST":
      return {
        ...state,
        recognitionList: action.recognitionList,
      };
    case "SET_RECOGNITION_COUNT":
      return {
        ...state,
        recognitionCount: action.recognitionCount,
      };
    case "SET_RECOGNITION_SELECTED":
      return {
        ...state,
        recognitionSelected: action.recognitionSelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openRecognitionDelete: action.openRecognitionDelete,
      };
    case "TOGGLE_OPEN_VIEW":
      return {
        ...state,
        openView: action.openView,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function RecognitionProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(recognitionReducer, mergedState);
  return (
    <RecognitionStateContext.Provider value={state}>
      <RecognitionDispatchContext.Provider value={dispatch}>
        {children}
      </RecognitionDispatchContext.Provider>
    </RecognitionStateContext.Provider>
  );
}

function useRecognitionState() {
  const context = React.useContext(RecognitionStateContext);
  if (context === undefined) {
    throw new Error(
      "useRecognitionState must be used within a LocationProvider"
    );
  }
  return context;
}

function useRecognitionDispatch() {
  const context = React.useContext(RecognitionDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getRecognitionList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      recognitionListLoading: true,
    });
    const result = await getRecognition(filterData);
    dispatch({
      type: "SET_RECOGNITION_LIST",
      recognitionList: [...result.data.data.recognition],
    });
    dispatch({
      type: "SET_RECOGNITION_COUNT",
      recognitionCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      recognitionListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getRecognitionListDataSet(
  recognitionList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getRecognitionOffset(offset, filterData);
    const updateData = recognitionList.concat(result.data.data.recognition);
    dispatch({
      type: "SET_RECOGNITION_LIST",
      recognitionList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchRecognitionList(search, filterData) {
  try {
    const result = await getRecognitionSearch(search, filterData);
    return result.data.data.recognition;
  } catch (error) {
    console.log(error);
  }
}

export {
  RecognitionProvider,
  useRecognitionState,
  useRecognitionDispatch,
  getRecognitionList,
  getRecognitionListDataSet,
  searchRecognitionList,
};
