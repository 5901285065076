import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import * as classnames from "classnames";
import useStyles from "./styles";

const filterTypesWithStateSelection = ["cities", "zipCodes"];
const filterTypeLabels = {
  zipCodes: "zip codes",
  cities: "cities",
};

const FilterModal = ({
  isOpen,
  onCloseModal,
  availableStates,
  filterType,
  optionsList,
  currentlySelectedItems,
  onFilterChange,
}) => {
  const classes = useStyles();
  const [localSelectedItems, setLocalSelectedItems] = useState([]);
  const [selectState, setSelectState] = useState(null);

  useEffect(() => {
    const selectedIds =
      currentlySelectedItems[filterType]?.map((item) => item.id.toString()) ||
      [];
    setLocalSelectedItems(selectedIds);
  }, [currentlySelectedItems, filterType]);

  const handleSelectStateChange = (event, newValue) => {
    setSelectState(newValue);
  };

  const filteredOptions = selectState
    ? optionsList.filter(
        (item) => item.state_abbreviation === selectState.value
      )
    : optionsList;

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setLocalSelectedItems((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((id) => id !== value)
        : [...prevSelected, value]
    );
  };

  const handleSave = () => {
    const selected = localSelectedItems.map((id) =>
      optionsList.find((item) => item.id.toString() === id)
    );
    onFilterChange(selected);
    onCloseModal();
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <IconButton
            className={classes.modalCloseButton}
            onClick={onCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="caption" className={classes.selectedText}>
            {localSelectedItems.length}/{optionsList.length}{" "}
            {filterTypeLabels[filterType] || filterType} selected for this
            partner
          </Typography>
        </Box>
        <Box className={classes.modalBody}>
          <Box className={classes.bottomRow}>
            <Typography className={classes.itemCount}>
              {filteredOptions.length}{" "}
              {filterTypeLabels[filterType] || filterType}
            </Typography>
            {filterTypesWithStateSelection.includes(filterType) && (
              <Box className={classes.stateSelectContainer}>
                <Typography className={classes.stateLabel}>
                  States Serviced
                </Typography>
                <Box className={classes.selectStates}>
                  <FormSelectAuto
                    name="states"
                    placeholder="Select State"
                    multiple={false}
                    options={availableStates.map((state) => ({
                      value: state.id,
                      label: state.text,
                    }))}
                    value={selectState}
                    handleChange={handleSelectStateChange}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.checkboxContainer}>
            {filteredOptions.map((item) => (
              <Box
                key={item.id}
                className={classnames(classes.checkboxItem, {
                  [classes.selectedCheckbox]: localSelectedItems.includes(
                    item.id.toString()
                  ),
                })}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localSelectedItems.includes(item.id.toString())}
                      value={item.id.toString()}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={item.text}
                  className={classes.checkboxLabel}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            className={classes.buttonConfirm}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModal;
