import React from "react";
import { merge } from "lodash";

const defaultState = {
  openManageTask: false,
  updateTasksList: false,
  recentlyTaskCreated: null,
};
const SurveysTasksStateContext = React.createContext();
const SurveysTasksDispatchContext = React.createContext();

function surveysTasksReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageTask: action.openManageTask,
      };
    case "TOGGLE_UPDATE":
      return {
        ...state,
        updateTasksList: action.updateTasksList,
      };
    case "TOGGLE_SET_RECENT_TASK":
      return {
        ...state,
        recentlyTaskCreated: action.recentlyTaskCreated,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SurveysTasksProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(surveysTasksReducer, mergedState);
  return (
    <SurveysTasksStateContext.Provider value={state}>
      <SurveysTasksDispatchContext.Provider value={dispatch}>
        {children}
      </SurveysTasksDispatchContext.Provider>
    </SurveysTasksStateContext.Provider>
  );
}

function useSurveysTasksState() {
  const context = React.useContext(SurveysTasksStateContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysTasksState must be used within a SurveysTasksProvider"
    );
  }
  return context;
}

function useSurveysTasksDispatch() {
  const context = React.useContext(SurveysTasksDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSurveysTasksDispatch must be used within a SurveysTasksProvider"
    );
  }
  return context;
}

export { SurveysTasksProvider, useSurveysTasksState, useSurveysTasksDispatch };
