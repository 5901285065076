import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  drawerRoot: {
    "& > div.MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  },
  drawerHeader: {
    background: "#f8f8f8"
  },
  headerText: {
    padding: "10px 30px 30px 30px"
  },
  create_project: {
    width: "400px"
  },
  closeButton: {
    margin: "10px 0"
  },
  wrapperSuccess: {
    textAlign: "left",
    padding: "8px 24px"
  },
  wrapperActionSuccess: {
    textAlign: "right !important",
    padding: "8px 24px",
    marginBottom: 20
  },
  titleSuccess: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5
  },
  contentSuccess: {
    fontSize: 14
  },
  iconSuccess: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5
  },
  iconItem: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5
  },
  iconClose: {
    color: "#979797",
    width: 20,
    height: 20
  },
  buttonCancel: {
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  buttonConfirm: {
    textTransform: "Capitalize",
    backgroundColor: "#4f97bb",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5
  },

  buttonCancelVariant: {
    textTransform: "Capitalize",
    color: "#4f98bc",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  buttonConfirmVariant: {
    textTransform: "Capitalize",
    backgroundColor: "#4f98bc",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  }
}));

export default useStyles;
