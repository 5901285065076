import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";

import { useTableState } from "../../../contexts/tableContext";

const TableToolbarSelected = props => {
  const classes = useStyles();
  const { selected } = useTableState();

  return (
    <Box className={classes.toolbarControls}>
      <Typography className={classes.title} color="inherit" variant="subtitle1">
        {selected.length} items selected
      </Typography>
      <Box className={classes.toolbarActionWrapper}>{props.children}</Box>
    </Box>
  );
};

export default TableToolbarSelected;
