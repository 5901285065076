import Box from "@material-ui/core/Box";
import useStyles from "../styles";
import { ActivityItem } from "./ActivityItem";
import { useActivities } from "./useActivities";
import { Fragment, useEffect } from "react";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function Activities({ setActivityCount }) {
  const classes = useStyles();
  const { activities, isActivitiesLoading } = useActivities();

  useEffect(() => {
    setActivityCount(activities?.length);
  }, [activities]);
  return (
    <Fragment>
      {isActivitiesLoading ? (
        <LoadingIndicator />
      ) : (
        <Box className={classes.activitiesList}>
          {activities?.map((activity, index) => (
            <ActivityItem
              index={index}
              key={index}
              activity={activity}
              classes={classes}
            />
          ))}
        </Box>
      )}
    </Fragment>
  );
}
