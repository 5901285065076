import { createContext, useState, useContext } from "react";

const AddQuestionDialogContext = createContext();

export const AddQuestionModalProvider = ({ children }) => {
  const [openQuestionDialogue, setOpen] = useState(false);

  const handleDialogueOpen = () => {
    setOpen(true);
  };

  const handleDialogueClose = () => {
    setOpen(false);
  };

  return (
    <AddQuestionDialogContext.Provider
      value={{ openQuestionDialogue, handleDialogueOpen, handleDialogueClose }}
    >
      {children}
    </AddQuestionDialogContext.Provider>
  );
};

export const useAddQuestionModal = () => useContext(AddQuestionDialogContext);
