import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RateIcon from "@material-ui/icons/RateReview";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import FormInput from "components/ui/FormContent/formInput";
import { ratingWTOptions, ratingWTLabelDisplay } from "constants.js";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { rate } from "services/workticketSurfaceService";
import useStyles from "./styles";

const initialData = {
  first_name: "",
  last_name: "",
  title: "",
  comment: "",
};

const RatingDialog = (props) => {
  const {
    open,
    handleClose,
    id,
    initialScore,
    initialComment,
    oboFields,
  } = props;
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [hover, setHover] = useState(-1);
  const [score, setSore] = useState(0);
  const [data, setData] = useState(initialData);
  const [hovering, setHovering] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  useEffect(() => {
    if (!open) {
      setSore(0);
      setData(initialData);
      setHover(-1);
      setHovering(false);
      setIsLoadingDialog(false);
    } else {
      setSore(initialScore ?? 0);
      setData({
        comment: initialComment ?? "",
        first_name: oboFields?.first_name ?? "",
        last_name: oboFields?.last_name ?? "",
        title: oboFields?.title ?? "",
      });
    }
  }, [open]);

  const handleConfirm = async () => {
    setIsLoadingDialog(true);
    const result = await rate(id, {
      rated_score: score,
      rated_comment: data.comment,
      rated_obo_first_name: data.first_name,
      rated_obo_last_name: data.last_name,
      rated_obo_title: data.title,
    });
    const { workticket } = result.data.data;
    setOpenSuccess(true);
    handleClose(workticket);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleMouseOver = (e) => {
    const num = Number(e.target.getAttribute("data-value"));
    if (num) {
      setHover(num);
    }
    setHovering(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseLeave = (e) => {
    setHover(-1);
    setHovering(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSetScore = (v) => {
    setSore(v);
  };

  const handleBlur = async (event) => {
    data[event.name] = event.value;
    setData({ ...data });
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"md"}
        fullWidth={false}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainer}>
                <RateIcon className={classes.iconDialog} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Rate this Service
                </Typography>
              </Box>
              <Box className={classes.contentDialogRatingWrapper}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.contentDialogRating}
                >
                  Please rate your ticket by selecting stars and provide
                  additional feedback in the text box to help us improve our
                  service.
                </Typography>
              </Box>
              <List className={classes.questionList}>
                <ListItem className={classes.listMinHeight}>
                  <Grid container>
                    <Grid item xs={12} md={3} className={classes.maxWidth} />
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className={classes.alignRating}
                      onMouseOver={(e) => handleMouseOver(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                    >
                      {ratingWTOptions.map((v) => (
                        <Box
                          className={classes.ratingBox}
                          data-value={v}
                          onClick={() => handleSetScore(v)}
                          key={`Box${v}`}
                        >
                          <Typography
                            data-value={v}
                            className={classNames(classes.ratingFont, {
                              [classes.ratingFontSelected]:
                                (v <= score && !hovering) || v <= hover,
                              [classes.ratingFontSelectedActive]: v === score,
                            })}
                          >
                            {v}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={3} className={classes.maxWidth} />
                      <Grid
                        item
                        xs={12}
                        md={5}
                        className={classes.ratingTextContainer}
                      >
                        <Typography className={classes.ratingTextBelow}>
                          {ratingWTLabelDisplay.left}
                        </Typography>
                        {ratingWTOptions.length >= 3 ? (
                          <Typography className={classes.ratingTextBelow}>
                            {ratingWTLabelDisplay.center}
                          </Typography>
                        ) : null}
                        <Typography className={classes.ratingTextBelow}>
                          {ratingWTLabelDisplay.right}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.sectionDialog}
              >
                On behalf of
              </Typography>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="first_name"
                    label="First Name"
                    value={data.first_name}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="last_name"
                    label="Last Name"
                    value={data.last_name}
                    handleBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="title"
                    label="Title"
                    value={data.title}
                    handleBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.sectionDialog}
              >
                Additional Feedback
              </Typography>
              <FormInput
                rows={3}
                multiline={true}
                gridSizes={[{ size: "md", val: 12 }]}
                name="comment"
                label="Comment"
                value={data.comment}
                handleBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions className={classes.wrapperDialogActionRating}>
              <Button
                onClick={() => handleClose()}
                className={classes.buttonOutlinedRating}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
                disabled={score <= 0}
              >
                Rate Service
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Thank you for rating this service.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default RatingDialog;
