import React from "react";
import { TableProvider } from "contexts/tableContext";
import { FilterProvider } from "contexts/filterContext";

const withTableFilterContext = (BaseComponent) => (props) => (
  <FilterProvider>
    <TableProvider>
      <BaseComponent {...props} />
    </TableProvider>
  </FilterProvider>
);

export default withTableFilterContext;
