import React from "react";
import Box from "@material-ui/core/Box";
import DraftsTable from "./draftsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { DraftsProvider } from "contexts/surveyDraftsContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import DeleteDialog from "./dialog/deleteDialog";
import ResumeDialog from "./dialog/resumeDialog";
import SurveyContent from "pages/survey/surveyContent";
import useStyles from "./styles";

const DraftsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <DraftsProvider>
        <DraftsTable />
        <SurveyFillProvider>
          <ResumeDialog />
          <SurveyContent />
        </SurveyFillProvider>
        <DeleteDialog />
      </DraftsProvider>
    </Box>
  );
};

export default withTableFilterContext(DraftsList);
