import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  tabWrapper: {
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  activeTab: {
    color: "#4F98BC",
  },
  pushPinIcon: {
    width: 24,
    height: 24,
    borderRadius: 35,
    background: "#E8F8CB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg path": {
      fill: "#328914",
    },
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  customMenu: {
    borderRadius: 4,
    background: "#FFF",
    boxShadow: "0px 2px 20px 0px rgba(66, 66, 66, 0.16)",
    zIndex: 1500,
  },
  headerTableText: {
    color: "#747474",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1,
    borderBottom: "none",
  },
  containerComments: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    // padding: 20,
    background: "#fff",
    // border: "1px solid #ccc",
    borderRadius: 5,
    border: "none",
  },
  commentBoxStyles: {
    display: "flex",
    borderRadius: 5,
    padding: 10,
    alignItems: "flex-start",
    background: "#F8F8F8",
  },
  nameDateContainerStyles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: 0,
  },
  commentText: {
    margin: 0,
    color: "#747474",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normalize",
  },
  commentClock: {
    margin: 0,
    color: "#fff",
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normalize",
  },
  dateMenuStyles: {
    display: "flex",
    alignItems: "center",
  },
  dateMenuIconStyles: {
    display: "inline-block",
    verticalAlign: "middle",
    paddingLeft: 8,
  },
  commentInputText: {
    color: "#747474",
    fontSize: 12,
    border: "none",
    background: "transparent",
    outline: "none",
    width: "100%",
    padding: 0,
  },
  containerAddButton: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    border: "1px solid var(--Grey-200, #ECECEC)",
    background: "var(--Body-text-White, #FFF)",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  addButton: {
    height: 40,
    width: 40,
    flexShrink: 0,
    fill: "#DFDFDF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#DFDFDF",
    border: "none",
    marginRight: 8,
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
  },
  avatarContainer: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: 10,
    top: 0,
    left: 0,
  },
  avatarImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  avatarText: {
    height: 40,
    width: 40,
    flexShrink: 0,
    fill: "#DFDFDF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#DFDFDF",
  },
  updateCommentContainer: {
    borderRadius: 4,
    border: "1px solid #D9D9D9",
    background: "#FFF",
    padding: 10,
  },
  updateCommentInput: {
    borderRadius: 4,
    border: "none",
    padding: 4,
    background: "transparent",
    color: "#747474",
    fontSize: 12,
    fontStyle: "normal",
  },
  updateCommentButton: {
    display: "flex",
    width: "auto",
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    flexShrink: 0,
    alignSelf: "stretch",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    background: "#4F98BC",
    color: "#ffffff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4F98BC",
    },
    "&:active": {
      background: "#4F98BC",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  cancelCommentButton: {
    display: "flex",
    width: 92,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#FFF",
    color: "#4F98BC",
    textTransform: "capitalize",
  },
  progressBar: {
    "&> .MuiLinearProgress-barColorSecondary": {
      borderRadius: 5,
    },
  },
  progressBarFailed: {
    "& > .MuiLinearProgress-barColorSecondary": {
      borderRadius: 5,
      backgroundColor: "#9A1010 !important",
    },
  },
  progressIconBox: {
    display: "flex",
    alignItems: "center",
  },
  progressBarFileName: {
    color: "#747474",
    fontSize: 14,
  },
  progressBarMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 1,
  },
  progressBarMessage: {
    color: "#747474",
    fontSize: 12,
  },
  stripedRow: {
    backgroundColor: "#f5f5f5",
  },
  borderRow: {
    "& > .MuiTableCell-root": {
      borderBottom: "none",
      borderTop: "none",
    },
  },
  tableHead: {
    backgroundColor: "#f5f5f5",
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  buttonCancel: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid  #4F98BC",
    textTransform: "Capitalize",
    color: "#4F98BC",
  },
  buttonDownload: {
    borderRadius: 4,
    border: "1px solid  #4F98BC",
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      background: "#4F98BC",
      opacity: 0.8,
    },
  },
  finishButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
  },
  tableActionIcon: {
    padding: 0,
    "& svg": {
      fontSize: 18,
    },
  },
  imageCell: {
    paddingRight: 5,
    width: 40,
  },
  pinningCell: {
    paddingLeft: 5,
    paddingRight: 5,
    width: 40,
  },
  ownerCell: {
    paddingRight: theme.spacing(2),
  },
  videoLinkTitle: {
    color: "#4D4D4D",
    textAlign: "center",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginRight: 8,
  },
  videoLinkLabelText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    color: "#747474",
    paddingBottom: 8,
    paddingTop: 20,
  },

  videoLinkFormText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    color: "#747474",
  },
  smallFontSize: {
    fontSize: 14,
  },
  linkStyle: {
    fontSize: 14,
    color: "#4F98BC",
    textDecoration: "underline",
    cursor: "pointer",
  },

  videoPreviewModalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderColor: "#ffffff",
    padding: 24,
    borderRadius: 4,
    maxWidth: 800,
    width: "90%",
    maxHeight: "80%",
    overflow: "auto",
  },
  videoPreviewHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#78C1CE",
    height: 40,
    marginBottom: "10px",
  },

  videoPreviewTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    color: "#fff",
    verticalAlign: "middle",
  },
  videoFrame: {
    borderRadius: "8px 8px 0 0",
    border: "none",
    paddingBottom: 0,
  },
  videoPreviewContainer: {
    paddingTop: 0,
    borderRadius: "8px 8px 4px 4px",
    border: "1px solid",
    borderColor: "#ECECEC",
  },
  videoPreviewDisplayName: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: "#4D4D4D",
  },
  videoPreviewDescription: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    fontSize: 12,
    color: "#747474",
  },
  videoPreviewMetadata: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: 12,
    color: "#4D4D4D",
  },
  videoPreviewCloseButton: {
    padding: 0,
    borderRadius: 0,
  },
  containerDialogLoader: {
    minHeight: 160,
  },
  gallery: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    maxWidth: "100%",
  },
  thumbnails: {
    display: "flex",
    marginTop: 20,
    overflowX: "auto",
    maxWidth: 1000,
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(0, 0, 0, 0.5) transparent",
    overflowY: "hidden",
  },
  thumbnail: {
    margin: "0 5px",
    cursor: "pointer",
    width: 199,
    height: 154,
    border: "2px solid transparent",
    transition: "border-color 0.3s ease",
    borderRadius: 8,
  },
  activeThumbnail: {
    borderColor: "blue",
  },
  thumbnailContainerStyle: {
    position: "relative",
  },
  thumbnailIconSize: {
    width: 25,
    height: 25,
  },
  thumbnailGalleryVideo: {
    background: "#EDF5F8",
    maxWidth: "100%",
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    position: "relative",
  },

  thumbnailGalleryPDF: {
    background: "#FFEAD5",
    maxWidth: "100%",
    height: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    position: "relative",
  },
  centerIconPdFGallery: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumbnailIconSizeGallery: {
    width: 60,
    height: 60,
  },
  thumbnailIconContainer: {
    color: "#fff",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  backgroundRed: {
    background: "var(--Pink-200, #F07A8C) !important",
    boxShadow: "-3px 3px rgba(220, 65, 88, 1)",
  },
  backgroundOrange: {
    background: "#F39A3E !important",
    boxShadow: "-3px 3px rgba(219, 111, 0, 1)",
  },
  backgroundYellow: {
    background: "#EFC945 !important",
    boxShadow: "-3px 3px rgba(207, 163, 10, 1)",
  },
  backgroundBlue: {
    background: "#4F98BC !important",
    boxShadow: "-3px 3px rgba(23, 69, 122, 1)",
  },
  linkIconContainerGallery: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(50%, 50%)",
    color: "#fff",
  },
  pinIconImg: {
    width: 20,
    height: 20,
    borderRadius: 35,
    top: 8,
    right: 8,
    background: "#E8F8CB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    "& svg path": {
      fill: "#328914",
    },
  },
  pinIconImageMiniature: {
    position: "absolute",
    top: 4,
    right: 10,
  },
  previewFileBtnDownload: {
    fontSize: 14,
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      background: "#4F98BC",
    },
    marginInline: 15,
  },
  galleryItem: {
    position: "relative",
  },
  imagePreviewDialog: {
    width: "100%",
    height: "50vh",
    background: "#4D4D4D",
    borderRadius: "0px 0px 4px 4px",
    marginBottom: 8,
    objectFit: "contain",
  },
  imagePreviewPdf: {
    width: "100%",
    height: 200,
    borderRadius: "0px 0px 4px 4px",
    marginBottom: 8,
    objectFit: "contain",
    margin: 20,
  },
  imagePreviewContainerPdf: {
    position: "relative",
    border: "1px solid #D9D9D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#4D4D4D",
    maxHeight: 400,
    width: "100%",
    margin: 20,
  },
  imagePreviewDefaultVideo: {
    maxHeight: 450,
    width: "100%",
    borderRadius: "0px 0px 4px 4px",
    marginBottom: 8,
    objectFit: "contain",
    background: "black",
  },
  imageInfoContainerStyle: {
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
  },
  imageNameStyle: {
    marginRight: "auto",
    color: "#4F98BC",
    paddingLeft: 15,
  },
  thumbnailDocument: {
    width: 82,
    height: 64,
    flexShrink: 0,
    borderRadius: 4,
    background: "#FFEAD5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
  },
  thumbnailVideo: {
    width: 82,
    height: 64,
    flexShrink: 0,
    borderRadius: 4,
    background: "#EDF5F8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
  },
  filePreviewCommentsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  filePreviewCommentsBox: {
    width: "100%",
    borderRadius: 4,
    border: "1px solid var(--Grey-200, #ECECEC)",
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  filePreviewCommentBottomBox: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 10,
  },
  youtubePlayer: {
    "& .ytp-swatch-background-color": {
      backgroundColor: "blue !important",
    },
  },
  floatingBox: {
    position: "absolute",
    bottom: 65,
    right: 0,
    zIndex: 999,
    padding: 16,
  },
  headerBar: {
    // position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px 4px 0 0",
    background: "#0c233d",
    padding: "0 16px",
    width: "auto",
    height: 40,
  },
  bottomBar: {
    display: "flex",
    alignItems: "center",
  },
  roundedButton: {
    height: 24,
    borderRadius: 50,
    background: "#17457A",
    color: "#fff",
    pointerEvents: "none",
  },
  fullScreenIcon: {
    color: "#fff",
    marginRight: 20,
  },
  floatingCommentsContainer: {
    border: "1px solid #ECECEC",
    background: "#FFF",
    padding: 5,
    borderRadius: 8,
  },
  floatingCommentsTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commentTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  roundedButtonClock: {
    height: 16,
    borderColor: "#4F98BC",
    borderRadius: 50,
    background: "#4F98BC",
    color: "#fff",
    pointerEvents: "none",
    marginLeft: 5,
    marginRight: 15,
  },

  labelText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    color: "#747474",
    paddingBottom: 8,
  },
  timestampContainer: {
    display: "flex",
    flexDirection: "column",
    background: "#F8F8F8",
    padding: 12,
    margin: 16,
    borderRadius: 4,
  },
  commentInputContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    background: "#ffff",
  },
  iconButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  timestampInput: {
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  useTimestampButton: {
    border: "none",
    borderRadius: 20,
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5,
    marginRight: 5,
  },
  updateTimestamp: {
    background: "#ffff",
    transform: "scale(0.7)",
    color: "#4F98BC",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#ffff",
    },
  },
  addCommentButton: {
    flexShrink: 0,
    backgroundColor: "#DFDFDF",
    border: "none",
    marginRight: 8,
    transform: "scale(0.7)",
    placeSelf: "flex-start",
    boxShadow: "none",
    pointerEvents: "none",
    cursor: "default",
  },
  commentSectionStyle: {
    flex: "0 1 30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #DFDFDF",
    padding: 5,
    marginLeft: 10,
  },
  scrollableBox: {
    height: "100%",
    flex: "80%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  commentsInputContainer: {
    flex: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingTop: 5,
  },
  thumbnailContainer: {
    flex: "0 1 15%",
    display: "contents",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 5,
  },

  fileInfoTypography: {
    fontSize: 12,
    paddingLeft: 15,
    paddingBottom: 15,
  },
  fileInfoBoxBorder: {
    borderTop: "1px solid #ECECEC",
  },
  flexColumnContainer: {
    flex: "0 0 70%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  previewImageContainer: {
    background: "#4D4D4D",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageGallery: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },

  closeIconButtonDialog: {
    color: "#fff",
    paddingLeft: 25,
    "&:hover": {
      background: "#78C1CE",
    },
  },
  flexColumnChild: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  dialogFullScreen: {
    display: "unset",
    height: "100%",
    padding: "0 !important",
    overflow: "hidden",
  },
  dialogNormal: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
  },
  fileViewerContainer: {
    flex: "0 1 85%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 4,
  },
  fileViewerContainerInner: {
    height: "100%",
    overflow: "hidden",
  },
  fullScreenBorder: {
    border: "none",
  },
  normalBorder: {
    border: "1px solid #ECECEC",
  },
  invalidTime: {
    borderBottom: "2px solid red",
    paddingLeft: 10,
  },
  borderColorBasedOnEditTimestamp: {
    border: "1px solid #4F98BC",
  },
  borderColorBasedOnEdit: {
    border: "1px solid #D9D9D9",
  },
  activeTimestampButton: {
    background: "#4F98BC",
    color: "#FFFFFF",
    "&:hover": {
      background: "#4F98BC",
    },
  },
  inactiveTimestampButton: {
    background: "#EDF5F8",
    color: "#4F98BC",
    "&:hover": {
      background: "#EDF5F8",
    },
  },
  fullWidthCell: {
    paddingLeft: 0,
    width: "100%",
  },
  centeredIconButton: {
    display: "flex",
    alignItems: "center",
  },
  customTab: {
    paddingLeft: 25,
    paddingRight: 25,
    minWidth: 60,
    marginRight: 16,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 8,
      paddingRight: 8,
      minWidth: 40,
      marginRight: 8,
    },
  },
  customTabFiles: {
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: "auto",
    marginRight: 16,
    "&:last-child": {
      marginRight: 0,
    },
  },
  commentTabContainer: {
    borderBottom: "1px solid #ECECEC",
    width: "100%",
  },
  commentToggleSection: {
    position: "relative",
    maxHeight: 250,
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  addCommentToggleSection: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  sizeCell: {
    paddingLeft: 5,
    paddingRight: 5,
    width: 20,
  },
  uploadedCell: {
    paddingLeft: 5,
    width: 20,
  },
  floatingCommentsContainerList: {
    background: "#fff",
    padding: 5,
    borderRadius: 5,
    height: "70vh",
    maxWidth: 370,
    overflow: "hidden",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      maxHeight: "70vh",
      width: "100%",
      maxWidth: "100%",
    },
  },

  commentListContainer: {
    maxHeight: "25vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },

  timestampCommentContainer: {
    paddingTop: 5,
    position: "absolute",
    bottom: 0,
    width: "100%",
    maxHeight: "25vh",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: 10,
      maxHeight: "50vh",
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    },
  },

  boldBodyTypography: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
  },
  circleComments: {
    marginBottom: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  circle: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    border: `2px solid #78C1CE`,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleNoComments: {
    color: "var(--Body-text-Middle-Gray, #747474)",
    textAlign: "center",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 20,
  },
  detailNoCommentsStyle: {
    color: "var(--Body-text-Middle-Gray, #747474)",
    textAlign: "center",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  rootTabs: {
    color: "#4F98BC",
    borderBottom: "2px solid ",
  },
  emptyTableRow: {
    borderBottom: "none",
    paddingBottom: theme.spacing(4),
  },
  emptyTableCell: {
    borderBottom: "none",
    paddingBottom: theme.spacing(4),
    textAlign: "left",
    padding: theme.spacing(2),
  },
  downloadIconColor: {
    color: "#4F98BC",
  },
  downloadButtonFont: {
    color: "#4F98BC",
    fontSize: 14,
    textWrap: "nowrap",
  },
  tabFilesContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
    flexWrap: "wrap",
    "& .Mui-disabled": {
      visibility: "hidden",
      width: 0,
    },
  },
  failedMargin: {
    marginTop: 22,
  },
  iconError: {
    color: "#9A1010",
    width: 20,
    height: 20,
  },
  tableWorkticketFiles: {
    "& td": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& td:first-child": {
      paddingLeft: 16,
    },
  },
  emptyTableCells: {
    paddingRight: theme.spacing(3),
    textAlign: "left",
  },
  noFilesCell: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
  },
  nameCell: {
    width: 250,
    minWidth: 250,
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  playerControlContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: 10,
    marginRight: 10,
  },
  playerProgressContainer: {
    position: "relative",
    width: "100%",
    height: 5,
    background: "rgba(255, 255, 255, 0.50)",
    cursor: "pointer",
  },
  playerProgressBar: {
    height: "100%",
    background: "#4F98BC",
  },
  timeDisplay: {
    position: "absolute",
    transform: "translateX(-50%)",
    background: "black",
    color: "white",
    borderRadius: 3,
    padding: "2px 5px",
    top: -25,
    whiteSpace: "nowrap",
    textAlign: "center",
    minWidth: 20,
    fontSize: 10,
  },
  playerActionsContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
  volumeContainer: {
    display: "flex",
    alignItems: "center",
  },
  volumeSliderContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
  },
  volumeSlider: {
    color: "white",
    width: 40,
    shadow: "0px 0px 3px rgba(0,0,0, 1.5)",
  },
  toggleActions: {
    color: "white",
  },
  time: {
    marginLeft: 5,
    color: "white",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textShadow: "0px 0px 3px rgba(0,0,0, 1.5)",
  },
  formContainer2: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 0,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  containerSubTabs: {
    borderBottom: "2px solid #ECECEC",
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
      borderBottom: "2px solid",
    },
    "&.Mui-flexContainer": {
      borderBottom: "2px solid #ECECEC",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerFooter: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 30,
    paddingRight: 40,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 20,
    },
  },
  containerInputComment: {
    width: "100%",
    marginBottom: theme.spacing(2),
    paddingLeft: 25,
    paddingRight: 14,
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: 8,
    fontSize: 14,
    borderRadius: 4,
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    paddingLeft: 25,
    paddingRight: 18,
    paddingTop: 20,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
  },
}));

export default useStyles;
