import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SurveyLanguageDropDown from "./surveyLanguageDropDown";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import SurveyStatusChip from "./surveyStatusChip";
import SurveyComment from "./surveyComment";
import SurveyFile from "./surveyFile";
import SurveyIcon from "assets/icons/surveyIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/NavigateBefore";
import ForwardIcon from "@material-ui/icons/NavigateNext";
import * as classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import Slide from "@material-ui/core/Slide";
import { surveysLang, surveysLabelsLang } from "constants.js";
import SurveyQuestionRating from "./surveyQuestionRating";
import SurveyQuestionRatingNoTasks from "./surveyQuestionRatingNoTasks";
import SurveyQuestionShortAnswer from "./surveyQuestionShortAnswer";
import SurveyQuestionMultipleChoice from "./surveyQuestionMultipleChoice";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { SignatureIcon } from "components/common/Icons/surveyIcons";
import SurveyContentModal from "./surveyContentModal";
import AnswerAgainDialog from "./dialog/answerAgainDialog";
import InformationDialog from "./dialog/informationDialog";
import {
  getTasksForSubmit,
  getFeedbackQuestion,
  exportResumePdf,
} from "services/surveyFrontlineService";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  moveToQuestion,
  questionFill,
} from "contexts/surveyFillContext";
import SignatureDialog from "./dialog/signatureDialog";
import RequiredIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import SurveyActions from "./surveyActions";
import SubmitDialog from "./dialog/submitDialog";
import CloseDialog from "./dialog/closeDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SurveyContent = (props) => {
  const classes = useStyles();
  const [langOptions] = useState(surveysLang);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    isLoading,
    selectedSurvey,
    selectedQuestions,
    selectedTasks,
    answers,
    openShowSurvey,
    correlative,
    lang,
    showNewAnswerIndicator,
    signature,
    isPreview,
    readonlySurvey,
    previewQuestions,
    surveyStatus,
    publicMode,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const history = useHistory();

  const missingRequiredAnswers = answers.filter(
    (a) =>
      parseInt(a.sum_status) - parseInt(a.count) <= 0 && parseInt(a.is_required)
  ).length;

  const missingAnswers = answers.filter(
    (a) => parseInt(a.sum_status) - parseInt(a.count) <= 0
  ).length;

  useEffect(() => {
    dispatchSurveyFill({
      type: "SET_LANG",
      lang: "English",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openShowSurvey]);

  const handleChangeLang = (event) => {
    dispatchSurveyFill({
      type: "SET_LANG",
      lang: event.target.value,
    });
  };

  const handleClose = () => {
    const currentPage = window.location.pathname;

    if (!isPreview && !readonlySurvey) {
      dispatchSurveyFill({
        type: "TOGGLE_OPEN_CLOSE",
        openClose: true,
      });
    } else if (currentPage.startsWith("/surveys/completed")) {
      history.goBack();
    } else {
      dispatchSurveyFill({
        type: "TOGGLE_OPEN_SHOW_SURVEY",
        openShowSurvey: false,
      });
    }
  };

  const handleClickOpenContents = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenAnswerAgain = () => {
    if (!isPreview && !readonlySurvey) {
      dispatchSurveyFill({
        type: "TOGGLE_OPEN_ANSWER_AGAIN",
        openAnswerAgain: true,
      });
    }
  };

  const handleMoveToAnswer = async (newCorrelative) => {
    dispatchSurveyFill({
      type: "TOGGLE_ISLOADING",
      isLoading: true,
    });
    if (selectedQuestions[newCorrelative].type === 1) {
      const resultTasks = await getTasksForSubmit(
        selectedQuestions[newCorrelative].id
      );
      const { tasks: selectedTasks } = resultTasks.data.data;
      if (
        selectedQuestions[newCorrelative].status === 1 &&
        selectedTasks.length > 0
      ) {
        const noAnswerTasks = selectedTasks.filter((t) => t.status !== 2);
        if (noAnswerTasks.length === 0) {
          selectedQuestions[newCorrelative].filled = true;
        }
      }
      dispatchSurveyFill({
        type: "SET_SELECTED_TASKS",
        selectedTasks: selectedTasks,
      });
    }
    const resultQuestionDetail = await getFeedbackQuestion(
      selectedQuestions[newCorrelative].id
    );
    const { question: selectedQuestion } = resultQuestionDetail.data.data;
    dispatchSurveyFill({
      type: "SET_SELECTED_FILES",
      selectedFiles: selectedQuestion.files,
    });
    dispatchSurveyFill({
      type: "SET_SELECTED_COMMENTS",
      selectedComments: selectedQuestion.comments,
    });
    dispatchSurveyFill({
      type: "SET_CORRELATIVE",
      correlative: newCorrelative,
    });
    dispatchSurveyFill({
      type: "TOGGLE_ISLOADING",
      isLoading: false,
    });
  };

  const handleMoveToOrder = async (newOrder) => {
    if (!isPreview) {
      dispatchSurveyFill({
        type: "TOGGLE_ISLOADING",
        isLoading: true,
      });
      const answer = answers.filter(
        (a) => parseInt(a.order) === parseInt(newOrder)
      )[0];

      if (!readonlySurvey) {
        const data = {
          current_question_id: parseInt(answer.min_id),
        };
        questionFill(
          dispatchSurveyFill,
          selectedQuestions[correlative].id,
          data,
          false,
          false
        );
      }
      await moveToQuestion(
        dispatchSurveyFill,
        selectedQuestions[correlative].qa_survey_submit_id,
        parseInt(answer.qa_question_id)
      );
    } else {
      const selQuestion = previewQuestions.filter(
        (q) => parseInt(q.order) === parseInt(newOrder)
      );
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: selQuestion,
      });
      if (parseInt(selQuestion[0].type) === 1) {
        dispatchSurveyFill({
          type: "SET_SELECTED_TASKS",
          selectedTasks: selQuestion[0].tasks,
        });
      }
    }
  };

  const handleOpenSignature = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SIGNATURE",
      openSignature: true,
    });
  };

  const openInformationDialog = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_INFORMATION",
      openInformation: true,
    });
  };

  const handleOpenSubmit = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_SUBMIT",
      openSubmit: true,
    });
  };

  const handlePrintPdf = async () => {
    try {
      setLoadingPdf(true);
      const result = await exportResumePdf(
        selectedQuestions[correlative].qa_survey_submit_id
      );
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      setLoadingPdf(false);
    } catch (e) {
      console.log("Cannot load pdf");
    }
  };

  const preview = () => {};

  if (!openShowSurvey) {
    return null;
  }

  return (
    <>
      <Dialog
        fullScreen
        open={openShowSurvey}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          position="relative"
          classes={{
            root: classes.rootAppBar,
          }}
        >
          <Toolbar>
            {!publicMode ? (
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.modalBody}>
          <Slide direction="right" timeout={800} in={!isLoading}>
            <Box>
              <Box className={classes.surveyHeader}>
                <SurveyIcon />
                <Typography className={classes.surveyTitle} variant="h1">
                  {selectedSurvey.name}
                </Typography>
                <Box className={classes.surveyStatusWrapper}>
                  <SurveyStatusChip status={surveyStatus} />
                </Box>
                {surveyStatus === 2 ? (
                  <Box className={classes.surveyPdfWrapper}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.buttonPrintPdf}
                      onClick={handlePrintPdf}
                      disabled={loadingPdf}
                    >
                      <PdfIcon /> Print PDF
                    </Button>
                  </Box>
                ) : null}

                {selectedSurvey?.signature_confirmation !== 3 ? (
                  <Box className={classes.surveySignatureWrapper}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      className={classes.buttonSignature}
                      onClick={handleOpenSignature}
                    >
                      {parseInt(selectedSurvey.signature_confirmation) === 1 ? (
                        <Tooltip
                          title={surveysLabelsLang[lang].questionRequiredLabel}
                        >
                          <RequiredIcon className={classes.iconRequiredMain} />
                        </Tooltip>
                      ) : null}
                      <SignatureIcon />
                      {signature || parseInt(surveyStatus) === 2
                        ? surveysLabelsLang[lang].signatureViewLabel
                        : surveysLabelsLang[lang].signatureGetLabel}
                    </Button>
                    <SignatureDialog />
                  </Box>
                ) : null}
                <Box className={classes.surveyLangWrapper}>
                  <SurveyLanguageDropDown
                    options={langOptions}
                    lang={lang}
                    handleChange={handleChangeLang}
                  />
                </Box>
              </Box>
              {answers.length > 0 ? (
                <>
                  <Box className={classes.surveyQuestionHeader}>
                    <Typography className={classes.ques}>
                      {selectedQuestions[correlative].order}
                    </Typography>
                    <Box className={classes.quesTitle}>
                      {parseInt(
                        answers.filter(
                          (a) =>
                            a.order === selectedQuestions[correlative].order
                        )[0].is_required
                      ) ? (
                        <Tooltip
                          title={surveysLabelsLang[lang].questionRequiredLabel}
                        >
                          <RequiredIcon className={classes.iconRequiredMain} />
                        </Tooltip>
                      ) : null}
                      <Typography className={classes.quesText}>
                        {lang === "Spanish" &&
                        selectedQuestions[correlative].name_es?.length
                          ? selectedQuestions[correlative].name_es
                          : selectedQuestions[correlative].name}
                      </Typography>
                    </Box>

                    <IconButton onClick={handleClickOpenContents}>
                      <ExpandMore />
                    </IconButton>
                    <SurveyContentModal
                      anchorEl={anchorEl}
                      handleClose={() => setAnchorEl(null)}
                    />

                    <Box className={classes.quesCounterWrapper}>
                      {isLoading ? null : (
                        <Typography className={classes.quesCounter}>
                          {surveysLabelsLang[lang].questionsLabel}{" "}
                          {selectedQuestions[correlative].order}/
                          {answers.length}
                        </Typography>
                      )}
                      {!isPreview ? (
                        <Box className={classes.answerNavigation}>
                          <IconButton
                            className={classes.navigationButton}
                            disabled={correlative === 0}
                            onClick={() => {
                              handleMoveToAnswer(correlative - 1);
                            }}
                          >
                            <BackIcon />
                          </IconButton>
                          <Typography className={classes.answerText}>
                            {surveysLabelsLang[lang].answerLabel}{" "}
                            {`${correlative + 1}/${selectedQuestions.length}`}
                          </Typography>
                          <IconButton
                            className={classes.navigationButton}
                            disabled={
                              correlative + 1 === selectedQuestions.length
                            }
                            onClick={() => {
                              handleMoveToAnswer(correlative + 1);
                            }}
                          >
                            <ForwardIcon />
                          </IconButton>
                          {showNewAnswerIndicator ? (
                            <Box className={classes.newAnswerIndicator}></Box>
                          ) : null}
                          <SurveyActions />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  {isLoading ? null : parseInt(
                      selectedQuestions[correlative].type
                    ) === 1 && selectedTasks.length > 0 ? (
                    <SurveyQuestionRating lang={lang} />
                  ) : parseInt(selectedQuestions[correlative].type) === 1 ? (
                    <SurveyQuestionRatingNoTasks lang={lang} />
                  ) : parseInt(selectedQuestions[correlative].type) === 2 ? (
                    <SurveyQuestionShortAnswer lang={lang} />
                  ) : parseInt(selectedQuestions[correlative].type) === 3 ? (
                    <SurveyQuestionMultipleChoice lang={lang} />
                  ) : null}
                  {parseInt(selectedQuestions[correlative].has_uploads) ||
                  parseInt(selectedQuestions[correlative].has_comments) ? (
                    <Box className={classes.additionalFeedbackWrapper}>
                      <Typography className={classes.additionalFeedbackTitle}>
                        {surveysLabelsLang[lang].feedbackLabel}
                      </Typography>
                      <Box className={classes.additionalFeedbackContent}>
                        <Grid container spacing={10}>
                          {parseInt(
                            selectedQuestions[correlative].has_uploads
                          ) ? (
                            <Grid item xs={12} md={6}>
                              {isLoading ? null : <SurveyFile />}
                            </Grid>
                          ) : null}
                          {parseInt(
                            selectedQuestions[correlative].has_comments
                          ) ? (
                            <Grid item xs={12} md={6}>
                              {isLoading ? null : <SurveyComment />}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Box>
                    </Box>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Slide>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button
            className={classes.buttonBack}
            disabled={parseInt(selectedQuestions[correlative]?.order) === 1}
            onClick={() => {
              handleMoveToOrder(
                parseInt(selectedQuestions[correlative]?.order) - 1,
                false
              );
            }}
            startIcon={<BackIcon />}
          >
            {surveysLabelsLang[lang].navigationBackLabel}
          </Button>
          <Box className={classes.footerActionButtons}>
            {!readonlySurvey && !isPreview ? (
              <Button
                variant="outlined"
                className={classNames(
                  classes.button,
                  classes.buttonPrimary,
                  classes.buttonReplay
                )}
                size="large"
                startIcon={<ReplayIcon className={classes.againIcon} />}
                onClick={handleOpenAnswerAgain}
                disabled={
                  parseInt(selectedQuestions[correlative]?.status) === 1 &&
                  !selectedQuestions[correlative]?.filled
                }
              >
                {surveysLabelsLang[lang].answerAgainLabel}
              </Button>
            ) : null}
            {parseInt(selectedQuestions[correlative]?.order) ===
              answers.length || answers.length === 0 ? (
              !readonlySurvey && !isPreview ? (
                missingAnswers === answers.length ? (
                  <Tooltip title={surveysLabelsLang[lang].noQuestionsAnswered}>
                    <Button
                      onClick={openInformationDialog}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classNames(
                        classes.button,
                        classes.buttonSecondaryDisabled
                      )}
                    >
                      {surveysLabelsLang[lang].surveySubmitLabel}
                    </Button>
                  </Tooltip>
                ) : parseInt(selectedSurvey.signature_confirmation) === 1 &&
                  !signature ? (
                  <Tooltip title={surveysLabelsLang[lang].signatureMissing}>
                    <Button
                      onClick={() => {
                        !isPreview && !readonlySurvey
                          ? missingRequiredAnswers > 0
                            ? openInformationDialog()
                            : selectedSurvey.signature_confirmation === 1 &&
                              !signature
                            ? handleOpenSignature()
                            : handleOpenSubmit()
                          : preview();
                      }}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classNames(
                        classes.button,
                        missingRequiredAnswers > 0 ||
                          (selectedSurvey.signature_confirmation === 1 &&
                            !signature)
                          ? classes.buttonSecondaryDisabled
                          : classes.buttonSecondary,
                        classes.btnDisabled
                      )}
                    >
                      {surveysLabelsLang[lang].surveySubmitLabel}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={() => {
                      !isPreview && !readonlySurvey
                        ? missingRequiredAnswers > 0
                          ? openInformationDialog()
                          : parseInt(selectedSurvey.signature_confirmation) ===
                              1 && !signature
                          ? handleOpenSignature()
                          : handleOpenSubmit()
                        : preview();
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      missingRequiredAnswers > 0 ||
                        (parseInt(selectedSurvey.signature_confirmation) ===
                          1 &&
                          !signature)
                        ? classes.buttonSecondaryDisabled
                        : classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                  >
                    {surveysLabelsLang[lang].surveySubmitLabel}
                  </Button>
                )
              ) : null
            ) : (
              <Button
                onClick={() => {
                  handleMoveToOrder(
                    parseInt(selectedQuestions[correlative]?.order) + 1,
                    true
                  );
                }}
                variant="contained"
                color="primary"
                size="large"
                className={classNames(
                  classes.button,
                  classes.buttonSecondary,
                  classes.btnDisabled
                )}
              >
                {surveysLabelsLang[lang].navigationNextLabel}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <AnswerAgainDialog />
      <InformationDialog />
      <SubmitDialog />
      <CloseDialog />
    </>
  );
};
export default SurveyContent;
