import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";

import useStyles from "./styles";

const MessageDialog = (props) => {
  const classes = useStyles();
  const { open, title, message, handleClose, isLoading } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose(false)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <SuccessIcon className={classes.iconSuccessDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClose}
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default MessageDialog;
