import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RequestIcon from "@material-ui/icons/SmsFailed";

import useStyles from "./styles";

const RequestConfirmationDialog = (props) => {
  const classes = useStyles();
  const { openDialog, handleCancel, handleSubmit, name } = props;

  const handleClickSubmit = () => {
    handleCancel(false);
    handleSubmit();
  };
  return (
    <Dialog
      open={openDialog}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperAdd}>
        <IconButton
          aria-label="close"
          onClick={() => handleCancel(false)}
          className={classes.wrapperClose}
        >
          <CloseIcon className={classes.iconClose} />
        </IconButton>
        <RequestIcon className={classes.iconRequest} />
        <Typography variant="h6" gutterBottom className={classes.titleAdd}>
          Requests
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.contentAdd}>
          Are you sure you want to submit this request on behalf of {name}?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.wrapperActionAdd}>
        <Button
          onClick={() => handleCancel(false)}
          className={classes.buttonOutlined}
          variant="outlined"
          size="large"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleClickSubmit}
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonBegin}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestConfirmationDialog;
