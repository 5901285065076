import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  isSameDay,
  subMonths,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: startOfDay(endOfWeek(new Date())),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: startOfDay(endOfWeek(addDays(new Date(), -7))),
  startOfToday: startOfDay(new Date()),
  endOfToday: startOfDay(endOfDay(new Date())),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: startOfDay(endOfDay(addDays(new Date(), -1))),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: startOfDay(endOfMonth(new Date())),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: startOfDay(endOfMonth(addMonths(new Date(), -1))),
  startOfYear: startOfYear(new Date()),
  endOfYear: startOfDay(endOfYear(new Date())),
  startOfLast6Months: startOfMonth(addMonths(new Date(), -6)),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: startOfDay(endOfYear(addYears(new Date(), -1))),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "Last 7 Days",
    range: () => ({
      startDate: addDays(defineds.startOfToday, -6),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last 14 Days",
    range: () => ({
      startDate: addDays(defineds.startOfToday, -13),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "This Year",
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: "Last 6 Months",
    range: () => ({
      startDate: defineds.startOfLast6Months,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last 12 Months",
    range: () => ({
      startDate: subMonths(defineds.endOfLastMonth, 12),
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
]);
