import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import FormContent from "components/ui/FormContent/formBody";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import FormTitle from "components/ui/FormContent/formTitle";
import RequestActionNav from "./requestActionNav";
import StepNav from "./stepNav";
import Joi from "joi-browser";
import { useStateValue } from "components/util/state";
import { errorList } from "components/util/error";

import useStyles from "./styles";

const schema = Joi.object({
  job_number: Joi.alternatives(Joi.string(), Joi.number())
    .required()
    .label("Location"),
  job_department_id: Joi.alternatives(Joi.string(), Joi.number())
    .allow("")
    .optional()
    .label("Department"),
  job_zone_id: Joi.alternatives(Joi.string(), Joi.number())
    .allow("")
    .optional()
    .label("Zone"),
  nearest_location: Joi.string().allow("").optional().label("Area"),
  summary: Joi.string().required().label("Summary"),
  description: Joi.string().min(50).required().label("Description"),
});

const RequestStep1 = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({});
  const [stepComplete, setStepComplete] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [error, setError] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [{ add, completed, step, list, company }, dispatch] = useStateValue();

  useEffect(() => {
    const loadData = () => {
      setJobList(
        company.jobs.map((job) => {
          return { value: job.job_number, label: job.job_description };
        })
      );
      // setDepartmentList(
      //   company.departments.map((department) => {
      //     return { value: department.id, label: department.name };
      //   })
      // );
      setDepartmentList([]);

      setZoneList(
        company.zones.map((zone) => {
          return { value: zone.id, label: zone.name };
        })
      );
    };
    if (company) {
      loadData();
    }
  }, [company]);

  useEffect(() => {
    if (add || completed) {
      setFormState({});
      setError([]);
      setHasError(false);
    }
  }, [add, completed]);

  useEffect(() => {
    const errorResult = errorList(schema, formState);
    if (hasError) {
      if (errorResult.length > 0) {
        setError(errorResult);
      } else {
        setError([]);
      }
    }
    if (errorResult.length === 0) {
      setStepComplete(true);
    } else {
      setStepComplete(false);
    }
  }, [formState, hasError]);

  const handleOnClick = () => {
    const errorResult = errorList(schema, formState);
    if (errorResult.length > 0) {
      setError(errorResult);
      setHasError(true);
      return false;
    }

    dispatch({
      type: "UPDATE_DATA",
      updateData: formState,
      newStep: 3,
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 1,
    });
  };

  const handleBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  const handleJobBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
    // setDepartmentList(
    //   company.departments
    //     .filter((item) => Number(item.job_number) === Number(event.value))
    //     .map((department) => {
    //       return { value: department.id, label: department.name };
    //     })
    // );
    setDepartmentList([]);
    setZoneList(
      company.zones
        .filter((item) => Number(item.job_number) === Number(event.value))
        .map((zone) => {
          return { value: zone.id, label: zone.name };
        })
    );
  };

  const validateOnType = (event) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  if (step !== 2) return null;
  return (
    <Box className={classes.stepContainer}>
      <StepNav step={step} list={list} />
      <FormTitle>What is your request?</FormTitle>
      <FormContent>Let us know the details of your request here.</FormContent>
      <Box className={classes.formContainer}>
        {jobList.length ? (
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Location*"
            options={jobList}
            value={formState.job_number ?? ""}
            handleBlur={handleJobBlur}
            error={error}
          />
        ) : null}
        {departmentList.length && formState?.job_number ? (
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_department_id"
            label="Department"
            options={departmentList}
            value={formState.job_department_id ?? ""}
            handleBlur={handleBlur}
          />
        ) : null}
        {zoneList.length && formState?.job_number ? (
          <FormSelect
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_zone_id"
            label="Zone"
            options={zoneList}
            value={formState.job_zone_id ?? ""}
            handleBlur={handleBlur}
          />
        ) : null}
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="nearest_location"
          label="Area"
          value={formState.nearest_location ?? ""}
          validateOnType={validateOnType}
          error={error}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="summary"
          label="Summary*"
          value={formState.summary ?? ""}
          validateOnType={validateOnType}
          error={error}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="description"
          label="Description*"
          multiline={true}
          rows={3}
          value={formState.description ?? ""}
          validateOnType={validateOnType}
          error={error}
        />

        <Box className={classes.minLabel}>
          {!formState.description || formState.description.length < 50 ? (
            <>
              Minimum of {formState?.description?.length ?? 0} / 50 characters
            </>
          ) : null}
        </Box>
      </Box>
      <RequestActionNav
        handleOnClickBack={handleOnClickBack}
        handleOnClick={handleOnClick}
        stepComplete={stepComplete}
      />
    </Box>
  );
};

export default RequestStep1;
