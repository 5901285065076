import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/MoreVert";
import * as classNames from "classnames";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import { logException } from "components/util/logUtil";
import { convertDateFormatField } from "components/util/timeFormat";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionOpenPositions } from "lib/permissions";
import { assign } from "lodash";
import {
  addOpenPositionsComment,
  deleteOpenPositionsComment,
  updateOpenPositionsComment,
} from "services/openPositionsService";
import useStyles from "./styles";

const OpenPositionsActivity = ({ workticket }) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [pastComment, setPastComment] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    setCommentList(assign(workticket.comments, { isEditable: false }));
  }, [workticket.comments]);

  const handleAddComment = async () => {
    if (comment !== "") {
      const data = {
        body: comment,
      };
      try {
        const result = await addOpenPositionsComment(workticket.id, data);
        setCommentList(
          assign(result.data.data.open_positions.comments, {
            isEditable: false,
          })
        );
        setComment("");
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleClickItem = (event, comment) => {
    setAnchorEl(event.currentTarget);
    setPastComment(selectedComment);
    setSelectedComment(comment);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsEdit = () => {
    if (pastComment) {
      pastComment.isEditable = false;
    }
    selectedComment.isEditable = true;
    setEditComment(selectedComment.body);
    setAnchorEl(null);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const handleCancelEdit = () => {
    selectedComment.isEditable = false;
    setPastComment(null);
    setSelectedComment(null);
  };

  const handleUpdateComment = async () => {
    const data = {
      body: editComment,
    };
    try {
      await updateOpenPositionsComment(workticket.id, selectedComment.id, data);
      selectedComment.isEditable = false;
      selectedComment.body = editComment;
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot update comment");
    }
  };

  const deleteComment = async () => {
    try {
      await deleteOpenPositionsComment(workticket.id, selectedComment.id);
      const comments = commentList.filter((c) => c.id !== selectedComment.id);
      setOpenConfirm(false);
      setCommentList(comments);
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  return (
    <Box className={classes.containerBodyActivity}>
      <Typography className={classes.commentsBg}>
        Comments(
        {commentList?.length})
      </Typography>

      <Box className={classes.containerComments}>
        <List className={classes.containerCommentsList}>
          {commentList.map((comment) => (
            <ListItem
              key={comment.id}
              className={
                comment.isEditable
                  ? classes.listItemComment
                  : classes.listContainer
              }
            >
              <ListItemAvatar>
                <Avatar className={classes.avatarComment}>
                  {comment.user[0].first_name.charAt(0)}
                  {comment.user[0].last_name.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              {comment.isEditable ? (
                <ListItemText
                  disableTypography={true}
                  className={classes.listText}
                  secondary={
                    <Box className={classes.containerCommentsForm}>
                      <Input
                        className={classes.inputEditComment}
                        disableUnderline={true}
                        rows={6}
                        multiline={true}
                        fullWidth={true}
                        autoFocus={true}
                        value={editComment}
                        onChange={(e) => setEditComment(e.target.value)}
                      />
                      <Box className={classes.boxActionsComment}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          disableElevation
                          className={classNames(
                            classes.button,
                            classes.buttonOutlinedComment
                          )}
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          disableElevation
                          className={classNames(
                            classes.button,
                            classes.buttonSecondary
                          )}
                          onClick={handleUpdateComment}
                        >
                          Update Comment
                        </Button>
                      </Box>
                    </Box>
                  }
                />
              ) : (
                <ListItemText
                  className={classes.listText}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.listName}
                        color="textPrimary"
                      >
                        {comment.user[0].first_name} {comment.user[0].last_name}
                      </Typography>
                      {comment.body}
                    </React.Fragment>
                  }
                />
              )}
              {!comment.isEditable && (
                <ListItemSecondaryAction className={classes.listAction}>
                  {convertDateFormatField(comment.created_at)}
                  {hasPermission(
                    permissionOpenPositions.UPDATE,
                    permissions
                  ) && (
                    <IconButton
                      aria-controls="wt-comment-menu"
                      aria-label="comment-edit"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleClickItem(event, comment);
                      }}
                      className={classes.menuItemIconComment}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
        <Box className={classes.containerCommentsForm}>
          <Input
            className={classes.inputNewComment}
            disableUnderline={true}
            rows={6}
            multiline={true}
            fullWidth={true}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box className={classes.boxActions}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(
                classes.button,
                classes.buttonSecondary,
                classes.marginTop10
              )}
              onClick={handleAddComment}
            >
              Add Comment
            </Button>
          </Box>
        </Box>
      </Box>

      <Menu
        id="quote-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          className: classes.menu,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            markAsEdit();
          }}
          className={classes.menuItem}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDelete();
          }}
          className={classes.menuItem}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={openConfirm}
        handleConfirm={deleteComment}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
      />
    </Box>
  );
};

export default OpenPositionsActivity;
