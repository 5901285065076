import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const FieldLocation = (props) => {
  const classes = useStyles();
  const { job } = props;

  if (!job) {
    return "Error jobs";
  }
  if (!job?.job_description) {
    return <Typography variant="body2">N/A</Typography>;
  }

  return (
    <Typography variant="body2" aria-haspopup="true">
      {job?.job_description}
    </Typography>
  );
};

export default FieldLocation;
