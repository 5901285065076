import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import FieldAbout from "./fieldAbout";
import QualityIcon from "@material-ui/icons/AssignmentTurnedIn";
import EmptyScreen from "components/ui/core/emptyScreen";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import { ButtonBase } from "components/ui/core/button";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabsDash";
import Grid from "@material-ui/core/Grid";
import QualityTabTableActions from "./qualityTabTableActions";
import ChartPieTab from "components/common/Charts/ChartPieTab";
import CalendarFilters from "components/common/Filters/calendarFilters";
import Counter from "components/common/TableList/counter";
import { useDashboardState } from "contexts/dashboardContext";
import { surveysQATypes } from "constants.js";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useQualityTabState,
  useQualityTabDispatch,
  getQualityTabList,
  getQualityTabListDataSet,
} from "contexts/qualityTabContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "job_description",
    label: "Location",
    content: (row) => (
      <FieldAbout field={row.job_description} fieldLength={200} />
    ),
  },
  {
    id: "type",
    label: "Survey Type",
    content: (row) =>
      row.id.charAt(0) === "L"
        ? row.type
        : surveysQATypes.find((item) => item.value === parseInt(row.type))
            .label,
  },
  {
    id: "completed_by",
    label: "Completed By",
    content: (row) => `${row.first_name} ${row.last_name}`,
  },
  {
    id: "timestamp",
    label: "Date of Survey",
    format: "date",
  },
  {
    id: "score",
    label: "Score",
    format: "percentage",
    style: { width: 110 },
  },
  {
    id: "actions",
    label: "",
    content: (row) => <QualityTabTableActions row={row} />,
    contentMobile: (row) => <QualityTabTableActions row={row} />,
    style: { width: 58 },
  },
];

const QualityTab = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    qualityTabListLoading,
    qualityTabList,
    qualityTabCount,
    qualityTabAvg,
  } = useQualityTabState();
  const { hasRowsClickable } = useTableState();
  const filterState = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const dispatchTable = useTableDispatch();
  const dispatchQualityTab = useQualityTabDispatch();
  const { selectedLocation, history } = props;
  const dashboardState = useDashboardState();

  const { refreshData } = globalUi;
  const isSelectedLocation = Object.keys(selectedLocation).length
    ? true
    : false;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getQualityTabList(
        dispatchQualityTab,
        filterData,
        true,
        dashboardState
      );
    };
    if (filterState.searchString === "") {
      if (isSelectedLocation) {
        if (!filterState.filters.length) {
          // Map label and value with job data
          const filterData = [
            {
              filter: "job",
              group: "Job",
              values: [
                {
                  label: `${selectedLocation.job_number} - ${selectedLocation.job_description}`,
                  value: selectedLocation.id,
                  disableDelete: true,
                },
              ],
            },
          ];
          dispatchFilters({
            type: "SET_FILTERS_CORE",
            filters: filterData,
          });
        } else {
          fetchData();
        }
      } else {
        if (filterState.filters.length > 0) {
          dispatchFilters({
            type: "SET_FILTERS_CORE",
            filters: [],
          });
        } else {
          fetchData();
        }
      }
    }
  }, [dashboardState, dispatchQualityTab, filterState]);

  useEffect(() => {
    if (isSelectedLocation) {
      console.log(filterState.filters);
      if (
        !filterState.filters.length ||
        filterState.filters[0].values[0].value !== selectedLocation.id
      ) {
        // Map label and value with job data
        const filterData = [
          {
            filter: "job",
            group: "Job",
            values: [
              {
                label: `${selectedLocation.job_number} - ${selectedLocation.job_description}`,
                value: selectedLocation.id,
                disableDelete: true,
              },
            ],
          },
        ];
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: filterData,
        });
      }
    } else {
      if (filterState.filters.length > 0) {
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: [],
        });
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getQualityTabList(dispatchQualityTab, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: qualityTabList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, qualityTabList]);

  const handleScrollClick = useCallback(async () => {
    if (qualityTabCount <= qualityTabList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getQualityTabListDataSet(
        qualityTabList,
        dispatchQualityTab,
        qualityTabList.length,
        filterData,
        true,
        dashboardState
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: qualityTabList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    qualityTabList,
    qualityTabCount,
    filterState,
    dispatchQualityTab,
    dashboardState,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleViewAllClick = () => {
    history.push(`/surveys?completed`);
  };

  return (
    <Box className={classes.qualityRoot}>
      <Box className={classes.headerTab}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Quality
          </Typography>
        </Box>
        <Box className={classes.headerTabsContainer}>
          <TableTabs handleTabChange={() => {}}>
            <StyledTab
              disableRipple={true}
              label={<Box className={classes.tabItem}>Surveys</Box>}
              key={-1}
              value={-1}
            />
          </TableTabs>
        </Box>
        <Box>
          <Box className={classes.filterContainer}>
            <CalendarFilters />
          </Box>
          <ButtonBase
            color="secondary"
            onClick={handleViewAllClick}
            disableElevation
          >
            View All
          </ButtonBase>
        </Box>
      </Box>
      <Box className={classes.tableBody}>
        <Box className={classes.pageBodyWrapper}>
          <Box className={classes.root}>
            {qualityTabListLoading ? (
              <LoadingStateHorizontal
                isVisible
                style={classes.centerLoadingTab}
              />
            ) : !qualityTabList.length ? (
              <EmptyScreen
                icon={<QualityIcon />}
                type={3}
                actionText="Perform a Site Survey"
              />
            ) : (
              <Grid container className={classes.containerTabTable}>
                <Grid item md={12} className={classes.filtersApplied}>
                  {filterState.filters && <FiltersAppliedList />}
                </Grid>
                <Grid item md={10} xs={12}>
                  <Box className={classes.contentContainer}>
                    <Box className={classes.contentCounter}>
                      <Counter>{`${qualityTabList.length} / ${qualityTabCount}`}</Counter>
                    </Box>
                    <Box className={classes.rootContainer}>
                      <Box className={classes.tableContainer}>
                        <Box className={classes.sliderHeightTable}>
                          <Table
                            handleRowClick={handleRowClick}
                            newColumns={columns}
                            handleScrollClick={handleScrollClick}
                            idscrollwrapper="table-wrapper-quality-tab"
                            activeMobile={true}
                          ></Table>
                        </Box>
                      </Box>
                    </Box>
                    {loadingMore && (
                      <Box className={classes.loadingTable}>
                        <LinearProgress color="secondary" />
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item md={2} xs={12}>
                  <ChartPieTab
                    pct={parseInt(qualityTabAvg) ? parseInt(qualityTabAvg) : 0}
                    cx="50%"
                    cy={100}
                    colors={["#99d458", "#c6e8a0"]}
                    height={190}
                    width={"100%"}
                    innerRadius={50}
                    outerRadius={80}
                    startAngle={90}
                    endAngle={-270}
                  />
                  <Typography variant="h4" className={classes.GraphTitle}>
                    Avg. Score
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(QualityTab);
