import { createContext, useContext, useMemo, useState } from "react";

const TabIdContext = createContext();

export const TabIdProvider = ({ children }) => {
  const [tabId, setTabId] = useState(0);
  const value = useMemo(
    () => ({
      tabId,
      setTabId,
    }),
    [tabId, setTabId]
  );
  return (
    <TabIdContext.Provider value={value}>{children}</TabIdContext.Provider>
  );
};

export const useTabId = () => useContext(TabIdContext);
