import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import FileUpload from "../../components/common/FileUpload/fileUpload";
import FilesDisplay from "../../components/ui/FilesDisplay/filesDisplay";
import FilesDisplayUploaded from "../../components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import { saveQuoteFile } from "../../services/quoteService";

import { useQuoteState } from "../../contexts/quoteContext";

import useStyles from "./styles";

const QuoteFile = () => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [update, setUpdate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const stateContext = useQuoteState();

  const { quoteDetails, isLoading } = stateContext ? stateContext : null;

  useEffect(() => {
    if (!files.length) {
      setUpdate(0);
    }
  }, [files]);

  useEffect(() => {
    if (!isLoading) {
      setFilesDisplay(quoteDetails.files);
    }
  }, [quoteDetails, isLoading]);

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);
      const data = {
        files: files,
      };
      const fileResult = await saveQuoteFile(quoteDetails.id, data);
      setFilesDisplay([...filesDisplay, ...fileResult.data.data.files]);
      setFiles([]);
      setIsUploading(false);
    } catch (e) {
      console.log("Cannot save files", e);
    }
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = filesDisplay.filter((file) => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={200} />;
  }

  return (
    <Box className={classes.containerFile}>
      {!Boolean(files.length) && (
        <Typography variant="h4" className={classes.titleSection} gutterBottom>
          Documents
        </Typography>
      )}
      <Box className={classes.fileUploadContainer}>
        <FileUpload
          value={files}
          handleChange={setFiles}
          handleUpdate={setUpdate}
          filesDisplay={
            Boolean(update) && (
              <FilesDisplay files={files} handleRemove={handleRemove} />
            )
          }
        />
      </Box>
      {Boolean(update) && (
        <>
          <Box className={classes.containerUploadFile}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonUpload)}
              onClick={handleUploadFiles}
              disabled={isUploading}
            >
              Finish Upload
            </Button>
          </Box>
          {isUploading && <LinearProgress color="secondary" />}
        </>
      )}
      <Box className={classes.containerDisplayFiles}>
        <FilesDisplayUploaded
          files={filesDisplay}
          handleRemoveDisplay={handleRemoveDisplay}
        />
      </Box>
    </Box>
  );
};

export default QuoteFile;
