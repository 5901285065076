import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "capitalize",
    minWidth: 120,
    fontWeight: "bold",
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  dialogHeader: {
    marginTop: 10,
    marginBottom: 20,
  },
  iconHeader: {
    marginRight: 5,
    position: "relative",
    top: 3,
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitleBody: {
    fontSize: 18,
    fontWeight: "normal",
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerPlan: {
    minHeight: 200,
    marginTop: 10,
  },
  containerPlanList: {
    padding: 5,
    marginBottom: 3,
    border: "solid 1px #f8f8f8",
  },
  containerPlanItem: {
    padding: 0,
    margin: 0,
    "& > .MuiListItemText-multiline": {
      margin: 0,
    },
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
    "& .MuiFormControlLabel-label": {
      display: "block",
      width: "100%",
    },
    "& .Mui-checked + span": {
      textDecoration: "line-through",
    },
  },
}));

export default useStyles;
