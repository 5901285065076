import React from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import { useTableState } from "contexts/tableContext";
import { exportEvaluations } from "services/surveyEvaluationsService";

const EvaluationsBulkActions = (props) => {
  const classes = useStyles();
  const tableUi = useTableState();
  const { selected } = tableUi;

  const downloadResult = async () => {
    try {
      const result = await exportEvaluations({ record_ids: selected });
      window.open(result.data.data.file.presigned_url);
    } catch (e) {
      console.log("Cannot donwload file.");
    }
  };

  return (
    <Box className={classes.rootBulk}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className={classNames(classes.buttonBulk, classes.buttonPrimaryBulk)}
        onClick={downloadResult}
      >
        Download
      </Button>
    </Box>
  );
};

export default EvaluationsBulkActions;
