import React from "react";
import {
  getFrontlineDrafts,
  getFrontlineDraftsSearch,
  getFrontlineDraftsOffset,
} from "services/surveyFrontlineService";
import merge from "lodash/merge";

const defaultState = {
  draftsListLoading: true,
  draftsSelected: null,
  draftsList: [],
  draftsCount: [],
  openDelete: false,
  openResume: false,
};

const DraftsStateContext = React.createContext();
const DraftsDispatchContext = React.createContext();

function draftsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        draftsListLoading: action.draftsListLoading,
      };
    case "SET_DRAFTS_LIST":
      return {
        ...state,
        draftsList: action.draftsList,
      };
    case "SET_DRAFTS_COUNT":
      return {
        ...state,
        draftsCount: action.draftsCount,
      };
    case "SET_DRAFTS_SELECTED":
      return {
        ...state,
        draftsSelected: action.draftsSelected,
      };
    case "TOGGLE_OPEN_DELETE":
      return {
        ...state,
        openDelete: action.openDelete,
      };
    case "TOGGLE_OPEN_RESUME":
      return {
        ...state,
        openResume: action.openResume,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DraftsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(draftsReducer, mergedState);
  return (
    <DraftsStateContext.Provider value={state}>
      <DraftsDispatchContext.Provider value={dispatch}>
        {children}
      </DraftsDispatchContext.Provider>
    </DraftsStateContext.Provider>
  );
}

function useDraftsState() {
  const context = React.useContext(DraftsStateContext);
  if (context === undefined) {
    throw new Error("useDraftsState must be used within a DraftProvider");
  }
  return context;
}

function useDraftsDispatch() {
  const context = React.useContext(DraftsDispatchContext);
  if (context === undefined) {
    throw new Error("useDraftDispatch must be used within a DraftProvider");
  }
  return context;
}

async function getDraftsList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      draftsListLoading: true,
    });
    const result = await getFrontlineDrafts(filterData);
    dispatch({
      type: "SET_DRAFTS_LIST",
      draftsList: [...result.data.data.drafts],
    });
    dispatch({
      type: "SET_DRAFTS_COUNT",
      draftsCount: result.data.data.count,
    });
    dispatch({
      type: "SET_LOADING",
      draftsListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getDraftsDataSet(draftsList, dispatch, offset, filterData) {
  try {
    const result = await getFrontlineDraftsOffset(offset, filterData);
    const updateData = draftsList.concat(result.data.data.drafts);
    dispatch({
      type: "SET_DRAFTS_LIST",
      draftsList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchDraftsList(search, filterData) {
  try {
    const result = await getFrontlineDraftsSearch(search, filterData);
    return result.data.data.drafts;
  } catch (error) {
    console.log(error);
  }
}

export {
  DraftsProvider,
  useDraftsState,
  useDraftsDispatch,
  getDraftsList,
  getDraftsDataSet,
  searchDraftsList,
};
