import React, { useState } from "react";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import ViewDialog from "./dialog/viewDialog";
import SurveyContent from "pages/survey/surveyContent";

const SurveyViewPage = (props) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <SurveyFillProvider>
      <ViewDialog
        id={props.match.params.id}
        open={open}
        handleClose={handleClose}
      />
      <SurveyContent />
    </SurveyFillProvider>
  );
};

export default SurveyViewPage;
