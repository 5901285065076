import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import FormTitle from "components/ui/FormContent/formTitle";
import FormContent from "components/ui/FormContent/formBody";
import FormInput from "components/ui/FormContent/formInput";

import RequestActionNav from "./requestActionNav";
import StepNav from "./stepNav";
import Joi from "joi-browser";

import { useStateValue } from "components/util/state";
import { errorList } from "components/util/error";

import useStyles from "./styles";

const schema = Joi.object({
  first_name: Joi.string().required().label("First Name"),
  last_name: Joi.string().required().label("Last Name"),
  email: Joi.string().email().required().label("Email"),
  phone_number: Joi.string().required().label("Phone Number"),
});

const RequestStep1 = () => {
  const classes = useStyles();
  const [stepComplete, setStepComplete] = useState(false);
  const [formState, setFormState] = useState({});
  const [error, setError] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [{ step, completed }, dispatch] = useStateValue();

  useEffect(() => {
    if (completed) {
      setFormState({});
      setError([]);
      setHasError(false);
    }
  }, [completed]);

  useEffect(() => {
    const errorResult = errorList(schema, formState);
    if (hasError) {
      if (errorResult.length > 0) {
        setError(errorResult);
      } else {
        setError([]);
      }
    }
    if (errorResult.length === 0) {
      setStepComplete(true);
    }
  }, [formState, hasError]);

  const handleOnClick = () => {
    const errorResult = errorList(schema, formState);
    if (errorResult.length > 0) {
      setError(errorResult);
      setHasError(true);
      return false;
    }

    dispatch({
      type: "UPDATE_DATA",
      updateData: formState,
      newStep: 2,
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 0,
    });
  };

  const validateOnType = (event) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  if (step !== 1) return null;
  return (
    <Box className={classes.stepContainer}>
      <StepNav step={step} />
      <FormTitle>What is your contact info?</FormTitle>
      <FormContent>
        Add your contact info here so we can keep you updated on the status of
        your request.
      </FormContent>
      <Box className={classes.formContainer}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="first_name"
          label="First Name*"
          value={formState.first_name}
          validateOnType={validateOnType}
          error={error}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="last_name"
          label="Last Name*"
          value={formState.last_name}
          validateOnType={validateOnType}
          error={error}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="email"
          label="Email*"
          value={formState.email}
          validateOnType={validateOnType}
          error={error}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="phone_number"
          label="Phone Number*"
          value={formState.phone_number}
          validateOnType={validateOnType}
          error={error}
        />
      </Box>
      <RequestActionNav
        handleOnClickBack={handleOnClickBack}
        handleOnClick={handleOnClick}
        stepComplete={stepComplete}
      />
    </Box>
  );
};

export default RequestStep1;
