import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const AddContactDialogContext = React.createContext();
const initialState = {
  isOpenAddDialog: false,
  contactToEdit: null,
  isEditing: false,
  contactToDelete: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CONTACT_DIALOG":
      return {
        ...state,
        isOpenAddDialog: action.open,
      };
    case "TOGGLE_EDIT_CONTACT":
      return {
        ...state,
        contactToEdit: action.contact,
        isOpenAddDialog: action.open,
        isEditing: action.isEditing,
      };
    case "DELETE_CONTACT":
      return {
        ...state,
        contactToDelete: action.contact,
      };
    default:
      return state;
  }
};
const AddContactDialogProvider = ({ children }) => (
  <AddContactDialogContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </AddContactDialogContext.Provider>
);
const useAddContactModal = () => {
  const context = React.useContext(AddContactDialogContext);
  if (context === undefined) {
    throw new Error(
      "useAddContactModal must be used within a AddContactDialogProvider"
    );
  }
  return context;
};

export { AddContactDialogProvider, useAddContactModal };
