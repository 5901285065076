import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { getRequestDetails } from "services/requestSurfaceService";
import { logException } from "components/util/logUtil";
import Grid from "@material-ui/core/Grid";
import RequestTypeChip from "components/ui/Requests/RequestTypeChip";
import RequestSubmittedBy from "components/ui/Requests/RequestSubmittedBy";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TimelineIcon from "@material-ui/icons/Timeline";
import { convertDateFormatField } from "components/util/timeFormat";

import useStyles from "./styles";

const RequestDashboardSurfaceDialog = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [request, setRequest] = useState(null);
  const { isOpen, handleClose, id } = props;
  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getRequestDetails(id);
        const { request: requestData } = result.data.data;
        setRequest(requestData);
        setDetailData([
          { title: "Request Number", value: requestData.number, column: 1 },
          {
            title: "Location",
            value: requestData.job_description,
            column: 2,
          },
          { title: "Summary", value: requestData.summary, column: 1 },
          {
            title: "Area",
            value: requestData.nearest_location,
            column: 2,
          },
          {
            title: "Type",
            value: <RequestTypeChip type={requestData.type} />,
            column: 1,
          },
          {
            title: "Status",
            value: requestData.status_string,
            column: 2,
          },
          {
            title: "Submitted By",
            value: (
              <RequestSubmittedBy
                user_origin={requestData.user_origin}
                user_id={requestData.user_id}
                env="surface"
              />
            ),
            column: 1,
          },
          {
            title: "Requested Date",
            value: moment(requestData.due_date).format("MM/DD/YYYY"),
            column: 2,
          },
        ]);
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load request detail dashboard data");
      }
    };
    if (isOpen && id) {
      getData();
    } else if (!isOpen) {
      setIsLoading(true);
    }
  }, [isOpen, id]);

  const ActivityItem = (props) => (
    <ListItem>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(props.activity.created_at)}
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="max-width-dialog-title"
      maxWidth={"sm"}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Box className={classes.formContainer}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="h3"
                    className={classes.sectionHeader}
                    gutterBottom
                  >
                    Request Details
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerL}>
                  {detailData
                    .filter((d) => d.column === 1)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-1`}>
                          <Typography
                            key={`${index}-1a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-1b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerR}>
                  {detailData
                    .filter((d) => d.column === 2)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-2`}>
                          <Typography
                            key={`${index}-2a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-2b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.section}>
                  <Typography
                    variant="h3"
                    className={classes.sectionInterHeader2}
                  >
                    Activity
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className={classes.containerActivity}>
                    <List className={classes.containerActivityList}>
                      {request.activity.map((activity) => (
                        <ActivityItem key={activity.id} activity={activity} />
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => props.history.push(`/requests`)}
            >
              View
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default withRouter(RequestDashboardSurfaceDialog);
