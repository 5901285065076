import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { getContacts, getQuotePdf } from "services/quoteService";
import { useQuoteState, useQuoteDispatch } from "contexts/quoteContext";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";

import useStyles from "./styles";

const PrintQuoteDialog = (props) => {
  const classes = useStyles();
  const [contact, setContact] = useState(null);
  const dispatchQuote = useQuoteDispatch();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const { quoteDetails, openPrint } = useQuoteState();
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const loadContacts = async () => {
      setIsLoadingDialog(true);
      const result = await getContacts(quoteDetails.id);
      const resultForRender = result.data.data.contacts.map((item) => {
        return {
          value: item.id,
          type: item?.role_id ? 1 : 2,
          label: `${item.first_name} ${item.last_name}`,
          ...item,
        };
      });
      setContacts(resultForRender);
      setIsLoadingDialog(false);
    };
    if (!openPrint) {
      setContact(null);
    } else {
      loadContacts();
    }
  }, [openPrint]);

  const handleClose = () => {
    dispatchQuote({
      type: "TOGGLE_OPEN_PRINT",
      openPrint: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      const result = await getQuotePdf(
        quoteDetails.id,
        contact.value,
        contact.type
      );
      const file = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      dispatchQuote({
        type: "TOGGLE_OPEN_PRINT",
        openPrint: false,
      });
      setIsLoadingDialog(false);
    } catch (e) {
      console.log("Cannot print PDF.");
    }
  };

  const handleChangeContact = (event, value) => {
    setContact(value);
  };

  return (
    <Dialog
      open={openPrint}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {isLoadingDialog ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <PdfIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Print PDF
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              Please select a customer contact
            </Typography>
            <Box className={classes.formContainer}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={contacts}
                name="user"
                internal={false}
                handleChange={handleChangeContact}
                noLabel={true}
                value={contact}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClose}
              className={classes.buttonOutlined}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              disabled={contact ? false : true}
            >
              Print
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default PrintQuoteDialog;
