import React from "react";
import { TableProvider } from "contexts/tableContext";

const withTableContext = (BaseComponent) => (props) => (
  <TableProvider>
    <BaseComponent {...props} />
  </TableProvider>
);

export default withTableContext;
