import React from "react";

const PartnerIcon = () => (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2119 22.7366V20.2103H26.7372C27.095 20.2103 27.3947 20.0891 27.6362 19.8465C27.8787 19.6048 27.9999 19.3051 27.9999 18.9472C27.9999 18.5893 27.8787 18.2895 27.6362 18.0478C27.3947 17.8053 27.095 17.684 26.7372 17.684H24.2119V15.1577C24.2119 14.7998 24.0906 14.4996 23.8482 14.2571C23.6066 14.0154 23.3069 13.8945 22.9492 13.8945C22.5914 13.8945 22.2917 14.0154 22.0501 14.2571C21.8077 14.4996 21.6865 14.7998 21.6865 15.1577V17.684H19.1611C18.8034 17.684 18.5037 17.8053 18.2621 18.0478C18.0197 18.2895 17.8984 18.5893 17.8984 18.9472C17.8984 19.3051 18.0197 19.6048 18.2621 19.8465C18.5037 20.0891 18.8034 20.2103 19.1611 20.2103H21.6865V22.7366C21.6865 23.0945 21.8077 23.3943 22.0501 23.636C22.2917 23.8785 22.5914 23.9998 22.9492 23.9998C23.3069 23.9998 23.6066 23.8785 23.8482 23.636C24.0906 23.3943 24.2119 23.0945 24.2119 22.7366Z"
      fill="#78C1CE"
    />
    <rect x="2" y="6" width="3" height="6" fill="#D8E3EB" />
    <rect x="3" y="12" width="3" height="6" fill="#D8E3EB" />
    <path
      d="M2.74634 20.2105C2.38858 20.2105 2.0889 20.0893 1.84731 19.8467C1.60487 19.6051 1.48365 19.3053 1.48365 18.9474V12.6316H1.26268C0.862834 12.6316 0.53664 12.4787 0.284104 12.1731C0.031567 11.8682 -0.0526118 11.5158 0.0315671 11.1158L1.29425 4.8C1.35738 4.50526 1.5047 4.26316 1.73619 4.07368C1.96768 3.88421 2.23074 3.78947 2.52537 3.78947H19.3822C19.6768 3.78947 19.9399 3.88421 20.1714 4.07368C20.4029 4.26316 20.5502 4.50526 20.6133 4.8L21.876 11.1158C21.9602 11.5158 21.876 11.8682 21.6235 12.1731C21.3709 12.4787 21.0447 12.6316 20.6449 12.6316H20.4239V15.1579C20.4239 15.5158 20.3027 15.8156 20.0602 16.0573C19.8187 16.2998 19.519 16.4211 19.1612 16.4211C18.8035 16.4211 18.5038 16.2998 18.2622 16.0573C18.0198 15.8156 17.8985 15.5158 17.8985 15.1579V12.6316H12.8478V18.9474C12.8478 19.3053 12.727 19.6051 12.4854 19.8467C12.243 20.0893 11.9429 20.2105 11.5851 20.2105H2.74634ZM4.00902 17.6842H10.3224V12.6316H4.00902V17.6842ZM2.74634 2.52632C2.38858 2.52632 2.0889 2.40547 1.84731 2.16379C1.60487 1.92126 1.48365 1.62105 1.48365 1.26316C1.48365 0.905263 1.60487 0.605053 1.84731 0.362527C2.0889 0.120842 2.38858 0 2.74634 0H19.1612C19.519 0 19.8187 0.120842 20.0602 0.362527C20.3027 0.605053 20.4239 0.905263 20.4239 1.26316C20.4239 1.62105 20.3027 1.92126 20.0602 2.16379C19.8187 2.40547 19.519 2.52632 19.1612 2.52632H2.74634ZM2.80947 10.1053H19.0981L18.3405 6.31579H3.56708L2.80947 10.1053Z"
      fill="#17457A"
    />
  </svg>
);

export default PartnerIcon;
