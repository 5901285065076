import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    maxWidth: "100%",
    width: "100%",
    flexWrap: "noWrap"
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
    maxHeight: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(8)
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(7)
    }
  },
  toolbar: theme.mixins.toolbar
}));

export default useStyles;
