import http from "./httpService";
import { apiUrl } from "lib/config";

const apiEndpoint = apiUrl + "/subcontractors";

export function getSubcontractors() {
  return http.get(`${apiEndpoint}/`);
}

export function getSubcontractorMembers(id) {
  return http.get(`${apiEndpoint}/team/${id}`);
}
