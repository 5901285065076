import React from "react";
import AssignDialog from "./assignDialog";
import AssignMultipleDialog from "./assignMultipleDialog";
import DeleteDialog from "./deleteDialog";
import ScheduleDialog from "./scheduleDialog";
import ScheduleSingleDialog from "./scheduleSingleDialog";
import DueDateDialog from "./dueDateDialog";
import DueDateSingleDialog from "./dueDateSingleDialog";
import DoneDialog from "./doneDialog";
import DuplicateDialog from "./duplicateDialog";
import ArchiveDialog from "./archiveDialog";

const WorkticketsDialog = (props) => {
  return (
    <>
      <AssignDialog {...props} />
      <AssignMultipleDialog {...props} />
      <ScheduleDialog />
      <ScheduleSingleDialog />
      <DueDateDialog />
      <DueDateSingleDialog />
      <DoneDialog {...props} />
      <DuplicateDialog />
      <DeleteDialog />
      <ArchiveDialog />
    </>
  );
};
export default WorkticketsDialog;
