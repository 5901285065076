import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UploadPartnerProgress from "./uploadPartnerProgress";
import TablePartnerComponent from "./tablePartnerComponent";
import { useUploadFileState } from "contexts/uploadFileContext";
import * as classNames from "classnames";
import useStyles from "./styles";

export const TabPanelPartners = ({ data, partnerId, refreshData }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("all");
  const [dataTab, setDataTab] = useState([]);
  const { isUploadFile } = useUploadFileState();

  const handleChangeTab = (event, newTab) => {
    setActiveTab(newTab);
    setDataTab(data[newTab]);
  };

  useEffect(() => {
    setDataTab(data[activeTab]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const allCount = data["all"] ? data["all"].length : 0;
  const documentCount = data["pdf"] ? data["pdf"].length : 0;
  const imageCount = data["image"] ? data["image"].length : 0;
  const videoCount = data["video"] ? data["video"].length : 0;
  const linkCount = data["links"] ? data["links"].length : 0;

  const tabLabels = [
    { label: `All (${allCount})`, key: "all" },
    {
      label: `Images (${imageCount})`,
      key: "image",
    },
    {
      label: `Videos (${videoCount})`,
      key: "video",
    },
    {
      label: `PDF (${documentCount})`,
      key: "pdf",
    },
    {
      label: `Links (${linkCount})`,
      key: "links",
    },
  ];

  return (
    <Box>
      <Box className={classes.tabFilesContainer}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChangeTab}
        >
          {tabLabels.map((tab) => (
            <Tab
              className={classNames(
                {
                  [classes.activeTab]: activeTab === tab.key,
                },
                classes.customTabFiles
              )}
              key={tab.key}
              value={tab.key}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      {isUploadFile ? (
        <Box>
          <UploadPartnerProgress
            data={dataTab}
            partnerId={partnerId}
            activeTab={activeTab}
            refreshData={refreshData}
            style={{ maxWidth: "100%" }}
          />
        </Box>
      ) : (
        <Box>
          <TablePartnerComponent
            data={dataTab}
            partnerId={partnerId}
            style={{ maxWidth: "100%" }}
            refreshData={refreshData}
          />
        </Box>
      )}
    </Box>
  );
};
