import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import useStyles from "./styles";

const FieldContainerFilter = (props) => {
  const classes = useStyles();
  const { title, isLoading, withoutFormat, children } = props;

  return (
    <Grid container spacing={1} className={classes.filterGroup}>
      <Grid item xs={12} md={3}>
        <Typography variant="h4" className={classes.titleFilter} gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        className={withoutFormat ? "" : classes.autoContainerFilter}
      >
        {isLoading ? <LinearProgress color="secondary" /> : children}
      </Grid>
    </Grid>
  );
};

export default FieldContainerFilter;
