import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { useStateValue } from "components/util/state";

import useStyles from "./styles";

const RequestAddDialog = () => {
  const classes = useStyles();
  const [{ add }, dispatch] = useStateValue();

  const handleAdd = () => {
    dispatch({
      type: "NEW_REQUEST",
    });
  };

  const handleOnClick = () => {
    dispatch({
      type: "COMPLETED",
    });
  };

  if (!add) return null;
  return (
    <Dialog
      open={add}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperAdd}>
        <Typography variant="h6" gutterBottom className={classes.titleAdd}>
          Pro Tip
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.contentAdd}>
          Each request must be submitted separately. Select "Add Request" to
          include another.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.wrapperActionAdd}>
        <Button
          onClick={handleAdd}
          className={classes.buttonOutlined}
          variant="outlined"
          size="large"
          color="primary"
        >
          Add Request
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonBegin}
          onClick={handleOnClick}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestAddDialog;
