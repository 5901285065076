import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import * as classNames from "classnames";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionOpenPositions, hasPermission } from "lib/permissions";
import { downloadFile } from "services/openPositionsService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const OpenPositionsBulkActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const downloadFileFromUrl = (url) => {
    window.open(url);
  };

  return (
    <Box className={classes.root}>
      {hasPermission(permissionOpenPositions.UPDATE, permissions) ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.button, classes.buttonSecondary)}
          onClick={() => {
            props.setSelectedRowData(null);
            props.setStatusModal(true);
          }}
        >
          Change Status
        </Button>
      ) : null}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        className={classNames(classes.button, classes.buttonSecondary)}
        onClick={() => {
          downloadFile(props?.selected).then((res) => {
            downloadFileFromUrl(res?.data?.data?.file?.presigned_url);
          });
        }}
      >
        Download
      </Button>
    </Box>
  );
};

export default OpenPositionsBulkActions;
