import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import {
  useWorkticketState,
  useWorkticketDispatch,
} from "contexts/workticketContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const options = [
  {
    id: 1,
    label: "Assign",
    value: "ASSIGN",
    available: [0, 1, 80],
    portfolio: 1,
    permission: permissionWorkticket.EDIT,
  },
  {
    id: 2,
    label: "Change Due Date",
    value: "DUE_DATE",
    available: [0, 1],
    portfolio: 0,
    permission: permissionWorkticket.EDIT,
  },
  {
    id: 3,
    label: "Schedule",
    value: "SCHEDULE",
    available: [0, 1],
    portfolio: 1,
    permission: permissionWorkticket.DETAIL,
  },
  {
    id: 4,
    label: "Rate Service",
    value: "RATE",
    available: [0, 1, 2],
    portfolio: 1,
    permission: permissionWorkticket.RATE,
    otherPermission: permissionWorkticket.EDIT_RATING,
  },
];

const WorkticketTableActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const workticketAction = useWorkticketAction();
  const dispatchWorkticketAction = workticketAction[1];
  const dispatchWorktickets = useWorkticketDispatch();
  const { scheduleMode } = useWorkticketState();
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";
    switch (action) {
      case "ASSIGN":
        type = "TOGGLE_ASSIGN";
        break;
      case "DUE_DATE":
        type = "TOGGLE_DUEDATE_SINGLE";
        break;
      case "SCHEDULE":
        type = "TOGGLE_SCHEDULE_SINGLE";
        break;
      case "DUPLICATE":
        type = "TOGGLE_DUPLICATE";
        break;
      case "DELETE":
        type = "TOGGLE_DELETE";
        break;
      case "RATE":
        type = "TOGGLE_RATE_SINGLE";
        break;
      default:
        break;
    }
    dispatchWorkticketAction({
      type: type,
      open: true,
    });
    dispatchWorktickets({
      type: "SET_WORKTICKET_SELECTED",
      workticketSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClickSchedule = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_SCHEDULE_SINGLE",
      open: true,
    });
    dispatchWorktickets({
      type: "SET_WORKTICKET_SELECTED",
      workticketSelected: row,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (
    !hasPermission(permissionWorkticket.DETAIL, permissions) &&
    !hasPermission(permissionWorkticket.EDIT, permissions) &&
    !hasPermission(permissionWorkticket.CLONE, permissions) &&
    !hasPermission(permissionWorkticket.DELETE, permissions) &&
    !hasPermission(permissionWorkticket.RATE, permissions) &&
    !hasPermission(permissionWorkticket.EDIT_RATING, permissions)
  ) {
    return null;
  }

  const amountItems = options.reduce(
    (total, option) => {
      if (
        option.available.includes(parseInt(row.status)) &&
        (hasPermission(option.permission, permissions) ||
          (option.otherPermission
            ? hasPermission(option.otherPermission, permissions)
            : false))
      ) {
        total += 1;
      }
      return total;
    },
    [0]
  );

  if (amountItems === 0) {
    return null;
  }

  return !scheduleMode ? (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter(
            (option) =>
              option.portfolio ||
              (!option.portfolio &&
                ((row.instance_override && row.workticket_category === 3) ||
                  ![3, 7].includes(row.workticket_category)))
          )
          .map((option) => {
            return option.available.includes(parseInt(row.status)) &&
              (hasPermission(option.permission, permissions) ||
                (option.otherPermission
                  ? hasPermission(option.otherPermission, permissions)
                  : false)) ? (
              option.value !== "RATE" || !row.rated_score ? (
                <MenuItem
                  key={option.id}
                  onClick={handleSelect}
                  data={option.value}
                >
                  {option.label}
                </MenuItem>
              ) : hasPermission(
                  permissionWorkticket.EDIT_RATING,
                  permissions
                ) ? (
                <MenuItem
                  key={option.id}
                  onClick={handleSelect}
                  data={option.value}
                >
                  {option.label}
                </MenuItem>
              ) : null
            ) : null;
          })}
      </Menu>
    </div>
  ) : (
    <Button
      className={classes.buttonSchedule}
      variant="contained"
      color="secondary"
      onClick={handleClickSchedule}
    >
      Schedule
    </Button>
  );
};

export default WorkticketTableActions;
