import React from "react";
import {
  getFrontlineLocations,
  getFrontlineLocationsSearch,
  getFrontlineLocationsOffset,
} from "services/surveyFrontlineSurfaceService";
import merge from "lodash/merge";

const defaultState = {
  locationsListLoading: true,
  locationsSelected: null,
  locationsList: [],
  locationsCount: [],
  openLocationSurveys: false,
};

const LocationsStateContext = React.createContext();
const LocationsDispatchContext = React.createContext();

function locationsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        locationsListLoading: action.locationsListLoading,
      };
    case "SET_LOCATIONS_LIST":
      return {
        ...state,
        locationsList: action.locationsList,
      };
    case "SET_LOCATIONS_COUNT":
      return {
        ...state,
        locationsCount: action.locationsCount,
      };
    case "SET_LOCATIONS_SELECTED":
      return {
        ...state,
        locationsSelected: action.locationsSelected,
      };
    case "TOGGLE_OPEN_SURVEYS":
      return {
        ...state,
        openLocationSurveys: action.openLocationSurveys,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LocationsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(locationsReducer, mergedState);
  return (
    <LocationsStateContext.Provider value={state}>
      <LocationsDispatchContext.Provider value={dispatch}>
        {children}
      </LocationsDispatchContext.Provider>
    </LocationsStateContext.Provider>
  );
}

function useLocationsState() {
  const context = React.useContext(LocationsStateContext);
  if (context === undefined) {
    throw new Error("useLocationsState must be used within a LocationProvider");
  }
  return context;
}

function useLocationsDispatch() {
  const context = React.useContext(LocationsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}

async function getLocationsList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      locationsListLoading: true,
    });
    const result = await getFrontlineLocations(filterData);
    dispatch({
      type: "SET_LOCATIONS_LIST",
      locationsList: [...result.data.data.locations],
    });
    dispatch({
      type: "SET_LOCATIONS_COUNT",
      locationsCount: result.data.data.count,
    });
    dispatch({
      type: "SET_LOADING",
      locationsListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getLocationsDataSet(
  locationsList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getFrontlineLocationsOffset(offset, filterData);
    const updateData = locationsList.concat(result.data.data.locations);
    dispatch({
      type: "SET_LOCATIONS_LIST",
      locationsList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchLocationsList(search, filterData) {
  try {
    const result = await getFrontlineLocationsSearch(search, filterData);
    return result.data.data.locations;
  } catch (error) {
    console.log(error);
  }
}

export {
  LocationsProvider,
  useLocationsState,
  useLocationsDispatch,
  getLocationsList,
  getLocationsDataSet,
  searchLocationsList,
};
