import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useNoteDispatch } from "contexts/noteSurfaceContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
}));

const NotesListActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const dispatchNotes = useNoteDispatch();
  const { permissions } = globalUi;

  const handleClick = () => {
    dispatchNotes({
      type: "SET_NOTE_DETAILS_ACTION",
      noteDetailsAction: "Add",
    });
  };

  if (!permissions.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <>
        <ButtonBase
          variant="outlined"
          color="secondary"
          onClick={handleClick}
          id="add-note-btn"
        >
          Add Note
        </ButtonBase>
      </>
    </div>
  );
};

export default withRouter(NotesListActions);
