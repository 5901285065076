import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MoreVert";
import Skeleton from "@material-ui/lab/Skeleton";
import TimelineIcon from "@material-ui/icons/Timeline";
import ConfirmDialog from "./dialog/confirmDialog";
import MessageDialog from "./dialog/messageDialog";
import GlobalUiContext from "../../contexts/globalUiContext";
import { permissionAdmin, hasPermission } from "../../lib/permissions";

import {
  addQuoteComment,
  updateQuoteComment,
  deleteQuoteComment,
} from "services/quoteService";
import { useQuoteState } from "contexts/quoteContext";
import { convertDateFormatField } from "components/util/timeFormat";

import useStyles from "./styles";

const QuoteActivity = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const stateContext = useQuoteState();
  const [tab, setTab] = useState(0);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedComment, setSelectedComment] = React.useState(null);
  const [pastComment, setPastComment] = React.useState(null);
  const [editComment, setEditComment] = React.useState("");
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);

  const { quoteDetails, isLoading, activities } = stateContext
    ? stateContext
    : null;

  useEffect(() => {
    if (!isLoading) {
      setCommentList(quoteDetails.comments);
    }
  }, [isLoading, quoteDetails.comments]);

  useEffect(() => {
    if (!isLoading) {
      setActivityList(activities);
    }
  }, [isLoading, activities]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleAddComment = async () => {
    if (comment !== "") {
      const data = {
        body: comment,
      };
      try {
        const result = await addQuoteComment(quoteDetails.id, data);
        setCommentList(result.data.data.quote.comments);
        setComment("");
      } catch (e) {
        console.log("Cannot add comment");
      }
    }
  };

  const ActivityItem = (props) => (
    <ListItem>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(props.activity.created_at)}
      </ListItemSecondaryAction>
    </ListItem>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPastComment(selectedComment);
    const comment = commentList.find((c) => c.id === id);
    setSelectedComment(comment);
  };

  const markAsEdit = () => {
    if (pastComment) {
      pastComment.isEditable = false;
    }
    selectedComment.isEditable = true;
    setEditComment(selectedComment.body);
    setAnchorEl(null);
  };

  const handleUpdateComment = async () => {
    const data = {
      body: editComment,
    };
    try {
      await updateQuoteComment(quoteDetails.id, selectedComment.id, data);
      selectedComment.isEditable = false;
      selectedComment.body = editComment;
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      console.log("Cannot add comment");
    }
  };

  const deleteComment = async () => {
    try {
      await deleteQuoteComment(quoteDetails.id, selectedComment.id);
      const comments = commentList.filter((c) => c.id !== selectedComment.id);
      setOpenConfirm(false);
      setOpenMessage(true);
      setCommentList(comments);
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      console.log("Cannot add comment");
    }
  };

  const handleCancelEdit = () => {
    selectedComment.isEditable = false;
    setPastComment(null);
    setSelectedComment(null);
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyActivity}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        className={classes.activityTabRoot}
      >
        <Tab
          label={`Comments (${commentList.length})`}
          value={0}
          className={classes.activityTab}
        />
        <Tab
          label={`Activity (${activityList.length})`}
          value={1}
          className={classes.activityTab}
        />
      </Tabs>
      {tab === 0 && (
        <Box className={classes.containerComments}>
          <List className={classes.containerCommentsList}>
            {commentList.map((comment) => (
              <ListItem
                className={comment.isEditable ? classes.listItemComment : ""}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatarComment}>
                    {comment.user[0].first_name.charAt(0)}
                    {comment.user[0].last_name.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                {comment.isEditable ? (
                  <ListItemText
                    disableTypography={true}
                    className={classes.listText}
                    secondary={
                      <Box className={classes.containerCommentsForm}>
                        <Input
                          className={classes.inputNewComment}
                          disableUnderline={true}
                          rows={6}
                          multiline={true}
                          fullWidth={true}
                          autoFocus={true}
                          value={editComment}
                          onChange={(e) => setEditComment(e.target.value)}
                        />
                        <Box className={classes.boxActions}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            disableElevation
                            className={classNames(
                              classes.button,
                              classes.buttonOutlinedComment
                            )}
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            className={classNames(
                              classes.button,
                              classes.buttonAdd
                            )}
                            onClick={handleUpdateComment}
                          >
                            Update Comment
                          </Button>
                        </Box>
                      </Box>
                    }
                  />
                ) : (
                  <ListItemText
                    className={classes.listText}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.listName}
                          color="textPrimary"
                        >
                          {comment.user[0].first_name}{" "}
                          {comment.user[0].last_name}
                        </Typography>
                        {comment.body}
                      </React.Fragment>
                    }
                  />
                )}
                {!comment.isEditable && (
                  <ListItemSecondaryAction className={classes.listAction}>
                    {convertDateFormatField(comment.created_at)}
                    {hasPermission(
                      permissionAdmin.COMMENTS_MANAGE,
                      permissions
                    ) && (
                      <IconButton
                        onClick={(event) => {
                          handleClickItem(event, comment.id);
                        }}
                        className={classes.menuItemIconComment}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          <Box className={classes.containerCommentsForm}>
            <Input
              className={classes.inputNewComment}
              disableUnderline={true}
              rows={6}
              multiline={true}
              fullWidth={true}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Box className={classes.boxActions}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonAdd)}
                onClick={handleAddComment}
              >
                Add Comment
              </Button>
            </Box>
          </Box>
          <ConfirmDialog
            open={openConfirm}
            handleConfirm={deleteComment}
            handleClose={closeConfirm}
            message={"Are you sure you want to delete this comment?"}
            quoteId={quoteDetails.number}
          />
          <MessageDialog
            title={"Success!"}
            open={openMessage}
            handleClose={closeMessage}
            message={"Comment has been Deleted."}
          />
          <Menu
            id="quote-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              className: classes.menu,
            }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem
              onClick={() => {
                markAsEdit();
              }}
              className={classes.menuItem}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                openDelete();
              }}
              className={classes.menuItem}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
      {tab === 1 && (
        <Box className={classes.containerActivity}>
          <List className={classes.containerActivityList}>
            {activityList.map((activity) => (
              <ActivityItem key={activity.id} activity={activity} />
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default QuoteActivity;
