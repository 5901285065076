import moment from "moment";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./styles";

const FormInputDate = (props) => {
  const classes = useStyles();
  const [stateValue, setStateValue] = React.useState(moment());
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);
  const {
    name,
    label,
    value,
    handleBlur,
    blurType,
    gridSizes,
    error,
    internal,
    readonly,
  } = props;

  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  useEffect(() => {
    if (value) {
      setStateValue(moment(value, moment.defaultFormat));
    }
  }, [value]);

  const handleChange = (event, value) => {
    let date = moment(value).format("YYYY-MM-DD HH:mm:ss");
    const obj = {
      name: event,
      value: date,
    };
    setStateValue(date);
    if (handleBlur) {
      handleBlur(obj, blurType ? blurType : "KeyboardDatePicker");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <Grid item xs={gridSizeXs} md={gridSizeMd}>
          <div className={classes.inputDateWrapper}>
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {label}
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              disablePast={true}
              variant="inline"
              format="MM/DD/YYYY"
              margin="normal"
              id={name}
              name={name}
              value={stateValue}
              autoOk={true}
              onChange={handleChange.bind(this, name)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className={classes.inputDate}
              InputProps={{
                className: classes.inputDateField,
                disableUnderline: true,
              }}
              disabled={readonly ? readonly : false}
            />
            {errorInput && Boolean(errorInput.length) && (
              <FormHelperText error>{errorInput[0].message}</FormHelperText>
            )}
          </div>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FormInputDate;
