import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import CustomersTable from "./customersTable";
import Box from "@material-ui/core/Box";
import withTableCollapsibleFilterContext from "components/hoc/withTableCollapsibleFilterContext";
import { CustomersProvider } from "contexts/adminCustomersContext";
import useStyles from "./styles";

const CustomersList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Customers" link="/customers" />
      <Box className={classes.pageBodyWrapper}>
        <CustomersProvider>
          <CustomersTable />
        </CustomersProvider>
      </Box>
    </>
  );
};

export default withTableCollapsibleFilterContext(CustomersList);
