import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = apiUrl + "/budgets";

export function getBudgetDetails(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function uploadBudget(data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}`, setFormData(data));
}

export function getBudgetJobs(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/jobs?${filterString}`, {
    params: {},
  });
}

export function getBudgetJobsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/jobs?${filterString}search=${search}`, {});
}

export function getBudgetJobsTabOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/jobs?${filterString}&offset=${offset}`, {});
}

export function getBudgetCampus(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {
    params: {},
  });
}

export function getBudgetCampusTabOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}&offset=${offset}`, {});
}

export function getBudgetPortfolio(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/portfolio?${filterString}`, {
    params: {},
  });
}

export function getBudgetChildrenPortfolio(id) {
  return http.get(`${apiEndpoint}/portfolio/${id}`);
}

export function getBudgetPortfolioTabOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/portfolio?${filterString}&offset=${offset}`,
    {}
  );
}

export function getBudgetHistory(id) {
  return http.get(`${apiEndpoint}/history/${id}`);
}
