import React, { useContext, useState } from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TicketIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import {
  saveWorkticketBulkSchedule,
  getWorkticketScheduleCalendar,
} from "services/workticketService";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useWorkticketState,
  useWorkticketDispatch,
} from "contexts/workticketContext";
import GlobalUiContext from "contexts/globalUiContext";

import { numberToWord } from "components/util/stringFormat";
import {
  convertDateToMoment,
  convertDateTimeDBFormat,
  getDateFormatDatabase,
} from "components/util/timeFormat";

import useStyles from "./styles";

const ScheduleDialog = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [date, setDate] = useState(moment());
  const [timeFrom, setTimeFrom] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [timeTo, setTimeTo] = useState(moment());
  const [assignedTo, setAssignedTo] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const [workticketAction, dispatchWorkticketAction] = useWorkticketAction();
  const {
    scheduleMode,
    workticketScheduleCalendarRange,
  } = useWorkticketState();
  const dispatchWorktickets = useWorkticketDispatch();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openSchedule } = workticketAction;
  const { selected } = tableUi;

  const handleClose = () => {
    dispatchWorkticketAction({
      type: "TOGGLE_SCHEDULE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    const assignedUser = [];
    assignedTo.map((item) => {
      return assignedUser.push(item.id);
    });

    if (!assignedUser.length) {
      setError([
        {
          key: "users",
          message: "Please select at least one user to assign.",
        },
      ]);
      return;
    }

    const data = {
      start_date: convertDateTimeDBFormat(date, timeFrom),
      end_date: convertDateTimeDBFormat(dateEnd, timeTo),
      user_ids: assignedUser,
      workticket_ids: selected,
    };

    if (selected.length) {
      try {
        setIsLoadingDialog(true);
        const result = await saveWorkticketBulkSchedule(data);

        if (result) {
          // Mark updated fields
          dispatchTable({ type: "SET_UPDATED_ROWS", rowsUpdated: selected });
          // Clear selections on table
          dispatchTable({ type: "SET_SELECTED", selected: [] });

          // Update calendar if open
          if (scheduleMode) {
            const diffStart = moment(date).diff(
              workticketScheduleCalendarRange.startDate
            );
            const diffEnd = moment(dateEnd).diff(
              workticketScheduleCalendarRange.endDate
            );
            if (diffStart > 0 && diffEnd < 0) {
              const result = await getWorkticketScheduleCalendar(
                moment(workticketScheduleCalendarRange.startDate).format(
                  getDateFormatDatabase
                ),
                moment(workticketScheduleCalendarRange.endDate).format(
                  getDateFormatDatabase
                )
              );
              const scheduleListUpdate = [];
              const schedulesResult = result.data.data.schedules;
              schedulesResult.map((schedule) => {
                return scheduleListUpdate.push({
                  ...schedule,
                  start: convertDateToMoment(schedule.start),
                  end: convertDateToMoment(schedule.end),
                });
              });

              dispatchWorktickets({
                type: "SET_SCHEDULE_LIST",
                workticketSchedule: scheduleListUpdate,
              });
            }
          }

          handleClose();
          setIsLoadingDialog(false);
          setOpenSuccess(true);
        }
      } catch (e) {
        console.log("Cannot schedule worktickets.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    setAssignedTo(value);
    setError([]);
  };

  return (
    <>
      <Dialog
        open={openSchedule}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <TicketIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Worktickets
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                When do you want to schedule these{" "}
                {numberToWord(selected.length)} ({selected.length}) workticket
                {selected.length > 1 ? "s" : ""}?
              </Typography>
              <Box className={classes.formContainer}>
                <FormInputDateAdo
                  name="date"
                  label="Start Date"
                  value={date}
                  handleChange={setDate}
                  internal={true}
                />
                <FormInputTimeAdo
                  name="time_from"
                  label="Start Time"
                  value={timeFrom}
                  handleChange={setTimeFrom}
                />
                <FormInputDateAdo
                  name="date"
                  label="End Date"
                  value={dateEnd}
                  handleChange={setDateEnd}
                  internal={true}
                />
                <FormInputTimeAdo
                  name="time_to"
                  label="End Time"
                  value={timeTo}
                  handleChange={setTimeTo}
                />
                <FormSelectChipsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={users}
                  name="users"
                  label="Assigned To"
                  handleChange={handleChangeAssignedTo}
                  value={assignedTo}
                  multiple={true}
                  error={error}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Schedule
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These workticket${
          selected.length > 1 ? "s" : ""
        } have been scheduled.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ScheduleDialog;
