import React from "react";

const HandThumbUp = () => (
  <svg
    width="102"
    height="107"
    viewBox="0 0 102 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clipPath="url(#clip0_1723_9316)">
      <g id="OBJECTS">
        <g id="Group">
          <path
            id="Vector"
            d="M97.4682 40.3167H4.53187C2.25045 40.3167 0.401001 42.1661 0.401001 44.4475V102.869C0.401001 105.151 2.25045 107 4.53187 107H97.4682C99.7496 107 101.599 105.151 101.599 102.869V44.4475C101.599 42.1661 99.7496 40.3167 97.4682 40.3167Z"
            fill="#EDF5F8"
          />
          <path
            id="Vector_2"
            d="M97.4682 40.3167H4.53187C2.25095 40.3167 0.401001 42.1666 0.401001 44.4475V48.5052H101.599V44.4475C101.599 42.1666 99.7491 40.3167 97.4682 40.3167Z"
            fill="#4F98BC"
          />
          <g id="Group_2">
            <path
              id="Vector_3"
              d="M84.8114 46.0048C85.6197 46.0048 86.275 45.3495 86.275 44.5411C86.275 43.7327 85.6197 43.0774 84.8114 43.0774C84.003 43.0774 83.3477 43.7327 83.3477 44.5411C83.3477 45.3495 84.003 46.0048 84.8114 46.0048Z"
              stroke="#17457A"
              strokeWidth="0.406581"
              strokeLinejoin="round"
            />
            <path
              id="Vector_4"
              d="M90.2799 46.0048C91.0883 46.0048 91.7436 45.3495 91.7436 44.5411C91.7436 43.7327 91.0883 43.0774 90.2799 43.0774C89.4715 43.0774 88.8162 43.7327 88.8162 44.5411C88.8162 45.3495 89.4715 46.0048 90.2799 46.0048Z"
              stroke="#17457A"
              strokeWidth="0.406581"
              strokeLinejoin="round"
            />
            <path
              id="Vector_5"
              d="M95.7484 46.0048C96.5568 46.0048 97.2121 45.3495 97.2121 44.5411C97.2121 43.7327 96.5568 43.0774 95.7484 43.0774C94.94 43.0774 94.2847 43.7327 94.2847 44.5411C94.2847 45.3495 94.94 46.0048 95.7484 46.0048Z"
              stroke="#17457A"
              strokeWidth="0.406581"
              strokeLinejoin="round"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_6"
              d="M43.2146 60.7515C42.06 60.7515 40.9459 59.979 40.0596 59.2431L38.9821 58.3445C38.3153 57.7875 37.5225 57.4907 36.7093 57.4907H27.5125C26.679 57.4907 26 58.1656 26 59.0032V92.8836C26 94.0058 26.9067 94.9125 28.0288 94.9125H74.4645C75.5866 94.9125 76.4933 94.0058 76.4933 92.8836V62.7844C76.4933 61.6622 75.5866 60.7556 74.4645 60.7556H43.2146V60.7515Z"
              fill="#CAE0EB"
            />
            <path
              id="Vector_7"
              d="M72.7935 67.2527H59.6568C59.0713 67.2527 58.5956 66.777 58.5956 66.1915C58.5956 65.6061 59.0713 65.1304 59.6568 65.1304H72.7935C73.3789 65.1304 73.8546 65.6061 73.8546 66.1915C73.8546 66.777 73.3789 67.2527 72.7935 67.2527Z"
              stroke="#17457A"
              strokeWidth="0.406581"
              strokeMiterlimit="10"
            />
          </g>
        </g>
      </g>
      <g id="TEXTS">
        <g id="Group_4">
          <path
            id="Vector_8"
            d="M40.6735 81.4098H39.9742L37.0997 76.9943H37.0712C37.1078 77.5106 37.1281 77.9863 37.1281 78.4173V81.4057H36.563V76.1445H37.2542L40.1206 80.5397H40.149C40.145 80.4746 40.1328 80.2673 40.1165 79.9135C40.1002 79.5639 40.0921 79.3118 40.1002 79.1614V76.1445H40.6735V81.4057V81.4098Z"
            fill="#17457A"
          />
          <path
            id="Vector_9"
            d="M45.4306 79.4379C45.4306 80.0803 45.2679 80.5803 44.9467 80.9422C44.6215 81.3041 44.1742 81.483 43.605 81.483C43.2513 81.483 42.9382 81.4016 42.6658 81.2349C42.3934 81.0682 42.182 80.8324 42.0315 80.5234C41.8811 80.2144 41.8079 79.8526 41.8079 79.4379C41.8079 78.7955 41.9706 78.2954 42.2918 77.9376C42.613 77.5798 43.0602 77.4009 43.6294 77.4009C44.1986 77.4009 44.6174 77.5838 44.9427 77.9498C45.2679 78.3157 45.4306 78.8117 45.4306 79.4379ZM42.4259 79.4379C42.4259 79.942 42.5276 80.3242 42.7268 80.5885C42.9301 80.8528 43.2228 80.9829 43.6172 80.9829C44.0116 80.9829 44.3043 80.8528 44.5076 80.5885C44.7109 80.3242 44.8126 79.942 44.8126 79.4338C44.8126 78.9256 44.7109 78.5515 44.5076 78.2913C44.3043 78.0311 44.0075 77.901 43.6091 77.901C43.2106 77.901 42.922 78.0311 42.7227 78.2872C42.5235 78.5434 42.4259 78.9256 42.4259 79.4338V79.4379Z"
            fill="#17457A"
          />
          <path
            id="Vector_10"
            d="M52.6799 78.7305C52.6799 79.6006 52.4441 80.2633 51.9724 80.7227C51.5008 81.1822 50.8218 81.4098 49.9395 81.4098H48.4839V76.1487H50.094C50.9112 76.1487 51.5455 76.3764 52.0009 76.8277C52.4522 77.283 52.6799 77.9132 52.6799 78.7264V78.7305ZM52.0334 78.7508C52.0334 78.0637 51.8626 77.5473 51.517 77.2017C51.1715 76.8521 50.6632 76.6813 49.9842 76.6813H49.0938V80.8813H49.8379C50.5656 80.8813 51.1145 80.7024 51.4805 80.3446C51.8464 79.9868 52.0293 79.4542 52.0293 78.7508H52.0334Z"
            fill="#17457A"
          />
          <path
            id="Vector_11"
            d="M56.1887 81.4099L56.0707 80.8488H56.0423C55.8471 81.0968 55.6479 81.2635 55.4527 81.3489C55.2576 81.4383 55.0136 81.479 54.7209 81.479C54.3306 81.479 54.0256 81.3773 53.802 81.1781C53.5784 80.9748 53.4686 80.6902 53.4686 80.3202C53.4686 79.5233 54.107 79.1086 55.3796 79.0679L56.0504 79.0476V78.8037C56.0504 78.4947 55.9854 78.267 55.8512 78.1165C55.717 77.9702 55.5056 77.897 55.2129 77.897C54.8835 77.897 54.5135 77.9986 54.0988 78.1979L53.9159 77.7425C54.111 77.6368 54.3224 77.5555 54.5542 77.4945C54.786 77.4335 55.0177 77.405 55.2495 77.405C55.7211 77.405 56.0667 77.5107 56.2944 77.7181C56.5221 77.9254 56.6359 78.2629 56.6359 78.7223V81.4139H56.1927L56.1887 81.4099ZM54.8388 80.987C55.2088 80.987 55.5015 80.8854 55.713 80.6821C55.9244 80.4788 56.0301 80.1942 56.0301 79.8242V79.4664L55.4324 79.4908C54.9567 79.5071 54.6152 79.5802 54.4038 79.7103C54.1923 79.8405 54.0907 80.0437 54.0907 80.3202C54.0907 80.5357 54.1557 80.7024 54.2859 80.8122C54.416 80.926 54.5989 80.9829 54.8347 80.9829L54.8388 80.987Z"
            fill="#17457A"
          />
          <path
            id="Vector_12"
            d="M59.1324 80.991C59.2381 80.991 59.3397 80.9828 59.4373 80.9666C59.5349 80.9503 59.6121 80.9341 59.669 80.9178V81.3732C59.604 81.4057 59.5105 81.4301 59.3844 81.4504C59.2584 81.4707 59.1446 81.4829 59.0429 81.4829C58.2785 81.4829 57.9004 81.0804 57.9004 80.2795V77.9335H57.3353V77.6448L57.9004 77.3968L58.1525 76.5552H58.4981V77.47H59.6406V77.9335H58.4981V80.2551C58.4981 80.4909 58.555 80.6738 58.6689 80.804C58.7827 80.93 58.9372 80.995 59.1324 80.995V80.991Z"
            fill="#17457A"
          />
          <path
            id="Vector_13"
            d="M62.8851 81.4099L62.7672 80.8488H62.7387C62.5435 81.0968 62.3443 81.2635 62.1492 81.3489C61.954 81.4383 61.71 81.479 61.4173 81.479C61.027 81.479 60.7221 81.3773 60.4984 81.1781C60.2748 80.9748 60.165 80.6902 60.165 80.3202C60.165 79.5233 60.8034 79.1086 62.076 79.0679L62.7468 79.0476V78.8037C62.7468 78.4947 62.6818 78.267 62.5476 78.1165C62.4134 77.9702 62.202 77.897 61.9093 77.897C61.5799 77.897 61.21 77.9986 60.7952 78.1979L60.6123 77.7425C60.8074 77.6368 61.0189 77.5555 61.2506 77.4945C61.4824 77.4335 61.7141 77.405 61.9459 77.405C62.4175 77.405 62.7631 77.5107 62.9908 77.7181C63.2185 77.9254 63.3323 78.2629 63.3323 78.7223V81.4139H62.8891L62.8851 81.4099ZM61.5352 80.987C61.9052 80.987 62.1979 80.8854 62.4094 80.6821C62.6208 80.4788 62.7265 80.1942 62.7265 79.8242V79.4664L62.1288 79.4908C61.6531 79.5071 61.3116 79.5802 61.1002 79.7103C60.8888 79.8405 60.7871 80.0437 60.7871 80.3202C60.7871 80.5357 60.8522 80.7024 60.9823 80.8122C61.1124 80.926 61.2953 80.9829 61.5312 80.9829L61.5352 80.987Z"
            fill="#17457A"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1723_9316">
        <rect
          width="101.198"
          height="107"
          fill="white"
          transform="translate(0.40094)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HandThumbUp;
