import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RequiredIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  moveToQuestion,
} from "contexts/surveyFillContext";
import {
  fillQuestion,
  getQuestionsResume,
} from "services/surveyFrontlineService";
import useStyles from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { surveysLabelsLang } from "constants.js";

const SurveyContentModal = (props) => {
  const classes = useStyles();
  const {
    answers,
    selectedQuestions,
    correlative,
    lang,
    updateAnswers,
    isPreview,
    readonlySurvey,
    previewQuestions,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { anchorEl, handleClose } = props;
  const open = Boolean(anchorEl);

  useEffect(() => {
    const loadAnswers = async () => {
      setIsLoading(true);
      const resultResume = await getQuestionsResume(
        selectedQuestions[correlative].qa_survey_submit_id
      );
      const { questions: resumeQuestions } = resultResume.data.data;
      dispatchSurveyFill({
        type: "SET_ANSWERS",
        answers: resumeQuestions,
      });
      setIsLoading(false);
    };
    console.log(isPreview, readonlySurvey, updateAnswers);
    if (!isPreview && !readonlySurvey) {
      loadAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAnswers]);

  const goToQuestion = async (answer) => {
    if (!isPreview) {
      dispatchSurveyFill({
        type: "TOGGLE_ISLOADING",
        isLoading: true,
      });
      handleClose();
      if (!readonlySurvey) {
        const data = {
          current_question_id: answer.min_id,
        };
        await fillQuestion(selectedQuestions[correlative].id, data);
      }
      await moveToQuestion(
        dispatchSurveyFill,
        selectedQuestions[correlative].qa_survey_submit_id,
        answer.qa_question_id
      );
    } else {
      const selQuestion = previewQuestions.filter(
        (q) => parseInt(q.order) === parseInt(answer.order)
      );
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: selQuestion,
      });
      if (parseInt(selQuestion[0].type) === 1) {
        dispatchSurveyFill({
          type: "SET_SELECTED_TASKS",
          selectedTasks: selQuestion[0].tasks,
        });
      }
    }
  };

  return (
    <Popover
      id={1}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className={classes.filterTooltip}
    >
      {isLoading ? (
        <Paper className={classes.popperContainer}>
          <Skeleton
            animation="wave"
            variant="rect"
            height={80}
            style={{ marginBottom: 20 }}
          />
        </Paper>
      ) : (
        <Paper className={classes.popperContainer}>
          {answers.map((answer) => (
            <Grid
              container
              spacing={2}
              className={classes.containerQuestionContent}
              key={answer.qa_question_id}
            >
              <Grid
                item
                md={1}
                xs={1}
                className={classNames(classes.quesAdj, classes.modalCell)}
              >
                <Typography className={classes.quesModal}>
                  {answer.order}
                </Typography>
              </Grid>
              <Grid item md={1} xs={1} className={classes.modalCell}>
                {parseInt(answer.is_required) ? (
                  <Tooltip
                    title={surveysLabelsLang[lang].questionRequiredLabel}
                  >
                    <RequiredIcon className={classes.iconRequired} />
                  </Tooltip>
                ) : null}
              </Grid>
              <Grid item md={6} xs={8} className={classes.modalCell}>
                <Typography className={classes.quesNameModal}>
                  {lang === "Spanish" && answer.name_es?.length
                    ? answer.name_es
                    : answer.name}
                </Typography>
              </Grid>
              <Grid item md={2} className={classes.modalCell}>
                {parseInt(answer.sum_status) - parseInt(answer.count) > 0 ? (
                  <Box className={classes.answeredQuestion}>
                    <Typography className={classes.chipFontColorModal}>
                      {`${surveysLabelsLang[lang].answerLabel} (${
                        parseInt(answer.sum_status) - parseInt(answer.count)
                      })`}
                    </Typography>
                  </Box>
                ) : (
                  <Box className={classes.notAnsweredQuestion}>
                    <Typography className={classes.chipFontColorModalNot}>
                      {surveysLabelsLang[lang].answerNotLabel}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item md={2} className={classes.modalCell}>
                <Button
                  variant="outlined"
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  className={classNames(
                    classes.buttonModal,
                    classes.buttonPrimary
                  )}
                  onClick={() => {
                    goToQuestion(answer);
                  }}
                  size="small"
                >
                  {surveysLabelsLang[lang].questionsGoTo}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Paper>
      )}
    </Popover>
  );
};
export default SurveyContentModal;
