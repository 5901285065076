import React from "react";

const surveyQualityIcon = () => (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9291 19.0299L17.0935 13.3587C18.0217 11.9901 18.5644 10.3396 18.5644 8.56486C18.5644 3.84199 14.7225 0 10 0C5.27762 0 1.43518 3.84199 1.43518 8.56441C1.43518 10.3391 1.97791 11.9896 2.90613 13.3587L0.0705377 19.0299C-0.0330947 19.2376 -0.0219274 19.4842 0.100019 19.6817C0.222413 19.8791 0.437718 19.9997 0.669997 19.9997H3.92325L5.87529 22.6026C6.0026 22.7723 6.20137 22.8706 6.41132 22.8706C6.68916 22.8706 6.90849 22.7049 7.01078 22.5003L9.69941 17.1235C9.79903 17.127 9.89953 17.1293 10 17.1293C10.1005 17.1293 10.201 17.127 10.3007 17.1235L12.9893 22.5003C13.0916 22.7044 13.3105 22.8706 13.5888 22.8706C13.7983 22.8706 13.9975 22.7723 14.1248 22.6026L16.0768 19.9997H19.3301C19.5624 19.9997 19.7781 19.8795 19.9001 19.6817C20.022 19.4842 20.0332 19.2381 19.9291 19.0299ZM6.29697 20.9311L4.7943 18.9276C4.66788 18.7588 4.46911 18.6596 4.25827 18.6596H1.75412L3.83346 14.5014C5.01407 15.7271 6.55515 16.6031 8.28474 16.9559L6.29697 20.9311ZM2.77525 8.56441C2.77525 4.58082 6.01644 1.34007 10 1.34007C13.9836 1.34007 17.2244 4.58082 17.2244 8.56441C17.2244 12.548 13.9836 15.7887 10 15.7887C6.01644 15.7892 2.77525 12.548 2.77525 8.56441ZM15.7414 18.6596C15.5305 18.6596 15.3322 18.7588 15.2053 18.9276L13.7027 20.9311L11.7153 16.9564C13.4449 16.6031 14.986 15.7271 16.1666 14.5014L18.2455 18.6596H15.7414Z"
      fill="white"
    />
    <path
      d="M13.4199 9.67574L15.291 7.44229C15.4393 7.2654 15.4863 7.02463 15.4148 6.80531C15.3433 6.58553 15.1642 6.41847 14.9404 6.36219L12.1137 5.65508L10.5677 3.18532C10.4453 2.98967 10.2305 2.87085 9.99999 2.87085C9.7695 2.87085 9.55464 2.98967 9.4318 3.18532L7.88625 5.65508L5.06003 6.36219C4.83624 6.41802 4.65667 6.58553 4.5852 6.80486C4.51418 7.02463 4.56063 7.2654 4.70893 7.44229L6.58012 9.67574L6.37867 12.5824C6.36258 12.8129 6.46622 13.0349 6.65293 13.1707C6.93658 13.3766 7.22515 13.2792 7.29751 13.2502L9.99999 12.1611L12.7025 13.2506C12.9164 13.3368 13.1599 13.3069 13.3466 13.1711C13.5333 13.0353 13.637 12.8129 13.6213 12.5828L13.4199 9.67574ZM12.2192 9.02223C12.1079 9.15535 12.0521 9.32598 12.0646 9.49885L12.2102 11.6072L10.2501 10.817C10.033 10.7295 9.84231 10.7795 9.7494 10.817L7.78932 11.6072L7.93538 9.49885C7.94744 9.32598 7.89161 9.1549 7.78038 9.02223L6.42333 7.40253L8.4732 6.88973C8.6416 6.84774 8.78678 6.74232 8.87835 6.59536L9.99954 4.80368L11.1212 6.59536C11.2132 6.74232 11.3584 6.84774 11.5263 6.88973L13.5762 7.40253L12.2192 9.02223Z"
      fill="white"
    />
  </svg>
);

export default surveyQualityIcon;
