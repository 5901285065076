import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import eosLogo from "assets/icons/encompass-logo.png";
import Skeleton from "@material-ui/lab/Skeleton";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import InputLabel from "@material-ui/core/InputLabel";
import { useIndividualView } from "contexts/individualViewContext";
import { logException } from "components/util/logUtil";
import EditIcon from "@material-ui/icons/Edit";
import { getFilterJobs } from "components/util/filterUtil";
import useStyles from "./styles";
import Avatar from "@material-ui/core/Avatar";
import {
  updateProfilePicture,
  updateProfileInformation,
} from "services/teamService";
import { ringBellPositionOptions } from "constants.js";
import { reportsToList } from "services/userService";

const initialData = {
  name: "",
  profilePic: null,
  number: "",
  position: "",
  primary_job: null,
  note: "",
  reports_to: null,
  reports_to_user: "",
  associate_oid: null,
  job_title: "",
};

const IndividualViewInformation = (props) => {
  const classes = useStyles();
  const [jobs, setJobs] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [data, setData] = useState(initialData);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [stateContext] = useIndividualView();

  const { individual, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadData = async () => {
      try {
        const resultsUsers = await reportsToList(
          individual.id,
          individual.role.department
        );
        const usersRender = resultsUsers.data.data.users;
        const userDisplay = usersRender.filter(
          (item) => individual.reports_to === item.id
        )[0];
        setUsersList(usersRender);
        const resultForRender = await getFilterJobs();
        const jobsDisplay = resultForRender.filter(
          (item) => individual.primary_job === item.job_number
        )[0];
        setJobs(resultForRender);
        const selectedPosition = ringBellPositionOptions.find(
          (positionItem) =>
            positionItem.value === individual.role.tier_classification
        ).label;
        setData({
          profilePic: individual?.profile?.picture?.file_url,
          name: `${individual.first_name} ${individual.last_name}`,
          position: selectedPosition,
          number: individual.employee_number,
          primary_job: jobsDisplay,
          note: individual.profile.note,
          reports_to: userDisplay,
          reports_to_adp: individual.reports_to_adp,
          reports_to_user: individual.reports_to_user,
          associate_oid: individual.associate_oid,
          job_title: individual.job_title,
        });
        setIsLoadingView(false);
      } catch (e) {
        logException(e, "Cannot load user data");
      }
    };
    if (individual.id) {
      loadData();
    }
  }, [individual]);

  const handleBlur = async (event) => {
    if (data[event.name] !== event.value) {
      try {
        await updateProfileInformation(individual.id, {
          [event.name]: event.value,
        });
        data[event.name] = event.value;
        setData(data);
      } catch (e) {
        logException(e, "Cannot update Individual");
      }
    }
  };

  const handleChangeJob = async (event, value) => {
    if (value) {
      try {
        setData({ ...data, primary_job: value });
        await updateProfileInformation(individual.id, {
          primary_job: value.job_number,
        });
      } catch (e) {
        logException(e, "Cannot update Individual");
      }
    }
  };

  const handleReportsTo = async (event, value) => {
    if (value) {
      try {
        setData({ ...data, reports_to: value });
        await updateProfileInformation(individual.id, {
          reports_to: value.id,
        });
      } catch (e) {
        logException(e, "Cannot update Individual");
      }
    }
  };

  const handleChangeProfilePic = async (event) => {
    if (event.target.files[0]) {
      let profilePic = URL.createObjectURL(event.target.files[0]);
      setData({ ...data, profilePic });
      try {
        const pictureResult = await updateProfilePicture(individual.id, {
          files: [...event.target.files],
        });

        if (pictureResult.data.data.user.profile.picture) {
          profilePic = pictureResult.data.data.user.profile.picture.file_url;
        }
        setData({ ...data, profilePic });
      } catch (e) {
        logException(e, "Cannot upload profile picture");
      }
    }
  };

  if (isLoading || isLoadingView) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <>
      <Box className={classes.containerInformation}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="name"
          label="Employee Name *"
          value={data.name}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />
        <InputLabel className={classes.inputLabelP}>Profile Picture</InputLabel>
        <Box className={classes.infoProfile}>
          <input
            type="file"
            accept="image/*"
            className={classes.inputImage}
            id="icon-button-file-user"
            onChange={handleChangeProfilePic}
          />
          <label htmlFor="icon-button-file-user">
            <Avatar
              alt="Profile"
              src={data.profilePic ?? eosLogo}
              className={classes.imageProfile}
            />
            <Avatar className={classes.profileImageEdit}>
              <EditIcon className={classes.profileIconEdit} />
            </Avatar>
          </label>
        </Box>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="number"
          label="Employee Number *"
          value={data.number}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="position"
          label="Position *"
          value={data.job_title?.length ? data.job_title : data.position}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />
        <FormSelectJobsAuto
          gridSizes={[{ size: "md", val: 12 }]}
          options={jobs ?? []}
          name="primary_job"
          label="Primary Location"
          multiple={false}
          value={data.primary_job}
          handleChange={handleChangeJob}
        />
        {data?.reports_to_adp ? (
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="reports_to_user"
            label="Reports To *"
            value={data.reports_to_user}
            readonly={true}
            handleBlur={() => {}}
          />
        ) : (
          <FormSelectChipsAuto
            gridSizes={[{ size: "md", val: 12 }]}
            options={usersList ?? []}
            name="users"
            label="Reports To"
            handleChange={handleReportsTo}
            value={data.reports_to}
            readonly={data?.reports_to_adp ? true : false}
          />
        )}

        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="note"
          label="About"
          value={data?.note ?? ""}
          multiline={true}
          handleBlur={handleBlur}
          rows={3}
        />
        {data.associate_oid ? (
          <Alert severity="info" className={classes.infoAlert}>
            * Read only fields, please make edits in HCM software
          </Alert>
        ) : null}
      </Box>
    </>
  );
};

export default IndividualViewInformation;
