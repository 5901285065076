import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import React from "react";
import useStyles from "./styles";
export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();

  let response = selectedModalViewResponse?.data?.form_equipment
    ? selectedModalViewResponse?.data?.form_equipment
    : "";
  let items = response && response.items ? JSON.parse(response.items) : [];

  return (
    <Dialog open={true} fullWidth={true} value={response?.t} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                  height: "100%",
                }}
              >
                <Typography className={classes.modalTitle}>
                  Asset Transfer/Repair Request
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      First Name
                    </Typography>
                    <TextField
                      name="request_name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      From Account
                    </Typography>
                    <TextField
                      name="from_ac"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.job_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      To Account
                    </Typography>
                    <TextField
                      name="to_ac"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.to_job_id}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subTitle}>
                      Specific Location of Equipment:
                    </Typography>
                    <Grid container spacing={1}>
                      <TextField
                        name="location_of_equip"
                        className={classes.field}
                        InputProps={{
                          className: classes.input,
                          disableUnderline: true,
                        }}
                        fullWidth
                        multiline={true}
                        minRows={3}
                        value={response?.location ?? ""}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {items.map((v) => {
                  return (
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <Typography className={classes.subTitle}>
                            Items
                          </Typography>
                          <Typography className={classes.subSubTitle}>
                            Description
                          </Typography>
                          <TextField
                            name="description"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.description}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Serial No/Asset Tag Number
                          </Typography>
                          <TextField
                            name="serial_no"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.serial_number}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Make
                          </Typography>
                          <TextField
                            name="make"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.make}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography className={classes.subSubTitle}>
                            Model
                          </Typography>
                          <TextField
                            name="model"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.model}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <Typography className={classes.subSubTitle}>
                            Describe reason for request
                          </Typography>
                          <TextField
                            name="issue_of_problem"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v?.comment}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}></Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Typography className={classes.subTitle}>
                            Who will be transferring?
                          </Typography>
                          <Grid container spacing={1}>
                            <TextField
                              name="who_will_transfer_name"
                              className={classes.field}
                              InputProps={{
                                className: classes.input,
                                disableUnderline: true,
                              }}
                              fullWidth
                              value={response?.transfer_by}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      On-Site Contact
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Name
                    </Typography>
                    <TextField
                      name="onsite_name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.contact_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Phone
                    </Typography>
                    <TextField
                      name="onsite_phone"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.contact_phone}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
