import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GlobalUiContext from "contexts/globalUiContext";

import { useStateValue } from "components/util/state";

import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const RequestCompletedDialog = (props) => {
  const classes = useStyles();
  const [{ completed, isLoading }] = useStateValue();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);

  const handleAccept = () => {
    dispatchGlobalUi({
      type: "SET_REFRESH_DATA",
      refreshData: true,
    });

    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: false,
    });
  };

  if (!completed && globalUi.isDrawerOpen) return null;
  return (
    <Dialog
      open={completed}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {!isLoading && (
        <React.Fragment>
          <DialogContent className={classes.wrapperSuccess}>
            <IconButton
              aria-label="close"
              onClick={handleAccept}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
            <SuccessIcon className={classes.iconSuccess} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleSuccess}
            >
              Success!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentSuccess}
            >
              You are amazing today
            </Typography>
          </DialogContent>
          <DialogActions className={classes.wrapperActionSuccess}>
            <Button
              onClick={handleAccept}
              className={classes.buttonBegin}
              color="primary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
      {isLoading && (
        <DialogContent className={classes.wrapperSuccess}>
          <LoadingStateHorizontal isVisible />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default RequestCompletedDialog;
