import React, { useState, useEffect } from "react";
import moment from "moment";
import * as classNames from "classnames";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@material-ui/icons/Done";
import ShareIcon from "@material-ui/icons/Share";
import ListIcon from "@material-ui/icons/Reorder";
import { useSurveyView } from "contexts/surveyViewContext";
import { getCustomers } from "services/userService";
import {
  getSurveyShares,
  createSurveyShare,
  deleteSurveyShare,
} from "services/surveyService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const SurveyShare = (props) => {
  const classes = useStyles();
  const [loadingList, setLoadingList] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [shareCopy, setShareCopy] = useState(null);
  const [shareList, setShareList] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [stateContext] = useSurveyView();
  const [openCreate, setOpenCreate] = useState(false);
  const [openAccountList, setOpenAccountList] = useState(false);
  const [accountList, setAccountList] = useState(null);
  const { survey, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultCustomer = await getCustomers();
        const { customers } = resultCustomer.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        const allValue = { value: -1, label: "ALL" };
        setCustomers([allValue, ...resultForRender]);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers && props.open) {
      loadCustomer();
    }
  }, [customers, props.open]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingList(true);
        const result = await getSurveyShares(survey.id);
        setShareList(result.data.data.list);
        setLoadingList(false);
      } catch (e) {
        logException(e, "Cannot load timekeeping list");
      }
    };
    if (!isLoading && props.open) {
      loadData();
    }
  }, [isLoading, survey.id, props.open]);

  const handleCreateShare = async () => {
    try {
      let accounts = 0;
      let type = 0;

      if (selectedCustomers.length) {
        accounts = JSON.stringify(
          selectedCustomers.map((customer) => customer.value)
        );
        type = 2;
      }

      const data = {
        type,
        accounts,
        qa_survey_id: survey.id,
      };

      setLoadingCreate(true);
      const surveyShare = await createSurveyShare(data);
      setLoadingCreate(false);
      setShareLink(
        `${window.location.protocol}//${window.location.hostname}/survey/public/${surveyShare.data.data.surveyShare.token}`
      );
      setShareCopy(null);
      setLoadingList(true);
      const resultList = await getSurveyShares(survey.id);
      setShareList(resultList.data.data.list);
      setLoadingList(false);
    } catch (e) {
      logException(e, "Cannot create share link");
    }
  };

  const handleCopyShare = () => {
    if (!shareCopy) {
      navigator.clipboard.writeText(shareLink);
    }
    setShareCopy(true);
  };

  const handleCopyLink = ({ id, token }) => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.hostname}/survey/public/${token}`
    );
    setShareCopy(id);
    setTimeout(() => {
      setShareCopy(null);
    }, 1500);
  };

  const handleNewShare = () => {
    setShareLink(null);
    setOpenCreate(true);
    setLoadingCreate(false);
    setSelectedCustomers([]);
  };

  const handleChangeCustomers = (event, value) => {
    const findSelectedAllValue = selectedCustomers.find(
      (item) => item.value === -1
    );
    const findAllValue = value.find((item) => item.value === -1);
    if (findAllValue && !findSelectedAllValue) {
      setSelectedCustomers([findAllValue]);
    } else if (findAllValue && findSelectedAllValue) {
      const excludeAllValue = value.filter((item) => item.value !== -1);
      setSelectedCustomers(excludeAllValue ?? []);
    } else {
      setSelectedCustomers(value ?? []);
    }
  };

  const handleDelete = async (row) => {
    try {
      await deleteSurveyShare(row.id);
      const updatedList = shareList.filter((item) => item.id !== row.id);
      setShareList(updatedList);
    } catch (e) {
      logException(e, "Cannot delete share link");
    }
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleShowList = (row) => {
    let displayList = [];
    const selectedAccounts = JSON.parse(row.accounts);
    if (row.type === 2) {
      displayList = customers
        .filter((itemC) => selectedAccounts.includes(itemC.value))
        .map((itemK) => itemK.label);
    }
    setAccountList(displayList);
    setOpenAccountList(true);
  };

  const handleCloseAccountList = () => {
    setOpenAccountList(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <Grid container spacing={2} className={classes.dialogHeaderShare}>
            <Grid item sm={9}>
              <Typography
                variant="h3"
                className={classes.titleBody}
                gutterBottom
              >
                Share Survey
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={true}
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleNewShare}
              >
                Create New Link
              </Button>
            </Grid>
          </Grid>
          {!isLoading ? (
            <>
              <Box className={classes.containerDialog}>
                {loadingList ? (
                  <Box className={classes.loading}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                ) : (
                  <TableContainer component={Box}>
                    {shareList?.length ? (
                      <Table
                        className={classes.tableDialog}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableHead className={classes.tableHeadDialog}>
                          <TableRow>
                            <TableCell>Public Link</TableCell>
                            <TableCell>Linked to</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBodyDialog}>
                          {shareList.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                <IconButton
                                  aria-label="copy"
                                  onClick={() => handleCopyLink(row)}
                                  className={classes.wrapperIcon}
                                >
                                  {row.id === shareCopy ? (
                                    <DoneIcon className={classes.iconOptions} />
                                  ) : (
                                    <FileCopyIcon
                                      className={classes.iconOptions}
                                    />
                                  )}
                                </IconButton>
                                <span
                                  className={classes.tableLinkText}
                                >{`${window.location.protocol}//${window.location.hostname}/survey/public/${row.token}`}</span>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="list"
                                  onClick={() => handleShowList(row)}
                                  className={classes.wrapperIcon}
                                >
                                  <ListIcon className={classes.iconOptions} />
                                </IconButton>
                                {JSON.parse(row.accounts)?.length}{" "}
                                {row.type === 1 ? "Jobs" : "Customers"}
                              </TableCell>
                              <TableCell>
                                {moment(row.created_at).format("L")}
                              </TableCell>
                              <TableCell style={{ width: 50 }}>
                                <IconButton
                                  aria-label="close"
                                  onClick={() => handleDelete(row)}
                                  className={classes.wrapperIcon}
                                >
                                  <DeleteIcon className={classes.iconOptions} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography
                        variant="h4"
                        className={classes.loading}
                        gutterBottom
                      >
                        No share links have been created.
                      </Typography>
                    )}
                  </TableContainer>
                )}
              </Box>
            </>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}></DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseCreate}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          {!isLoading ? (
            <>
              <Grid container spacing={2} className={classes.dialogHeader}>
                <Grid item sm={12}>
                  <Box className={classes.formIconDialog}>
                    <ShareIcon className={classes.iconDialog} />
                  </Box>
                  {!shareLink ? (
                    <Typography
                      variant="h5"
                      className={classes.formSubtitleDialog}
                      gutterBottom
                    >
                      Share Survey
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              {shareLink ? (
                <Box className={classes.formWrapperDialog}>
                  <Typography
                    variant="h5"
                    className={classes.formSubtitleDialog}
                    gutterBottom
                  >
                    Get link here to share this survey!
                  </Typography>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="shareLink"
                    value={shareLink}
                    readonly={true}
                  />
                </Box>
              ) : (
                <Box className={classes.formWrapperDialog}>
                  {survey.type === 3 ? (
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={customers ?? []}
                      name="customer"
                      multiple={true}
                      handleChange={handleChangeCustomers}
                      label="Customers"
                      value={selectedCustomers}
                    />
                  ) : null}
                </Box>
              )}
            </>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}>
          {shareLink ? (
            <Button
              variant={shareCopy ? "outlined" : "contained"}
              color="primary"
              size="large"
              disableElevation
              className={classNames(
                classes.button,
                shareCopy ? classes.buttonOutlined : classes.buttonPrimary
              )}
              onClick={handleCopyShare}
            >
              {shareCopy ? "Copied!" : "Copy"}
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className={classNames(classes.button, classes.buttonOutlined)}
                onClick={handleCloseCreate}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                className={classNames(classes.button)}
                onClick={handleCreateShare}
                disabled={loadingCreate || !selectedCustomers.length}
              >
                Create Link
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openAccountList}
        onClose={handleCloseAccountList}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className={classes.formContentDialog}>
          <IconButton
            aria-label="close"
            onClick={handleCloseAccountList}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          {accountList?.map((account) => (
            <Typography
              variant="h5"
              className={classes.formItemListDialog}
              gutterBottom
            >
              {account}
            </Typography>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SurveyShare;
