import React, { useState, useEffect } from "react";
import auth from "services/authService";
import MainLayoutLogin from "components/common/MainLayoutLogin";
import Container from "@material-ui/core/Container";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { errorArray } from "components/util/error";
import useStyles from "./loginForm.styles";
import Joi from "joi-browser";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const schema = Joi.object({
  email: Joi.string().email().required().label("Email Address"),
});

const path = "/dashboard";

const ForgotPasswordForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({ email: "" });
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [hasPageError, setHasPageError] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    const dataInput = { ...data };
    dataInput[input.name] = input.value;
    setData(dataInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleForgotPassword(event);
    }
  };

  useEffect(() => {
    if (auth.getCurrentUser()) {
      window.location = path;
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (hasError) {
      const errorResult = errorArray(schema, data);
      if (errorResult && Object.keys(errorResult).length) {
        setErrors(errorResult);
        setHasError(true);
      } else {
        setErrors([]);
        setHasError(false);
      }
    }
  }, [data, hasError]);

  const handleForgotPassword = async (event) => {
    setHasPageError("");
    const errorResult = errorArray(schema, data);
    if (errorResult && Object.keys(errorResult).length) {
      setErrors(errorResult);
      setHasError(true);
      return false;
    }
    try {
      setIsWorking(true);
      await auth.passwordReset(data.email);
      setIsSent(true);
      setIsWorking(false);
    } catch (e) {
      console.log({ e });
    }
  };

  const handleGoBack = () => {
    const { history } = props;
    history.push("/");
  };

  if (isLoading) {
    return <LoadingStateHorizontal isVisible style={classes.centerLoading} />;
  }

  return (
    <MainLayoutLogin>
      <Container maxWidth="sm">
        <Grid container className={classes.root}>
          {!isSent ? (
            <CardContent className={classes.card}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Forgot your password?
              </Typography>
              {hasPageError && (
                <Alert severity="error" className={classes.alert}>
                  {hasPageError}
                </Alert>
              )}
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.subTitle}
              >
                It happens to all of us, input your email and we will get you
                taken care of.
              </Typography>
              <TextField
                name="email"
                className={classes.field}
                value={data.email}
                disabled={isWorking}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                }}
                fullWidth
                error={errors && errors["email"] ? true : false}
                helperText={errors && errors["email"] ? errors["email"] : ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="Input Email Address"
              />
              <CardActions className={classes.cardActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  disabled={isWorking}
                  className={classes.loginButton}
                  onClick={handleForgotPassword}
                  size="large"
                >
                  Send reset instructions
                </Button>
              </CardActions>
              {isWorking && (
                <Box className={classes.centerLoadingLogin}>
                  <LoadingStateHorizontal isVisible />
                </Box>
              )}
            </CardContent>
          ) : (
            <CardContent className={classes.card}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                Instructions sent!
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.subTitle}
              >
                Check your email <span>{data.email}</span>, and follow the
                instruction get you setup.
              </Typography>
              <CardActions className={classes.cardActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  disabled={isWorking}
                  className={classes.loginButton}
                  onClick={handleGoBack}
                  size="large"
                >
                  Go back to login
                </Button>
              </CardActions>
            </CardContent>
          )}
        </Grid>
      </Container>
    </MainLayoutLogin>
  );
};

export default ForgotPasswordForm;
