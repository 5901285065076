import React from "react";
import { ButtonBase } from "components/ui/core/button";
import AssetsIcon from "@material-ui/icons/Toys";
import EmptyScreen from "components/ui/core/emptyScreen";
import { useDashboardState } from "contexts/dashboardContext";
import AssetTable from "./AssetTable";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    //minWidth: "1109px"
  },
  workText: {
    flex: "1",
    marginLeft: "24px",
    fontWeight: "bolder",
    fontSize: "21px",
  },
  workTicketContainer: {
    flex: "5",
    minHeight: "30px",
    maxHeight: "35px",
    marginTop: "10px",
    display: "flex",
    borderBottom: "1px solid #C4C4C4",
  },
  assetTab: {
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "14px",
    cursor: "pointer",
  },
  flexContainer: { flex: "1", marginLeft: "20px" },
  flex1: { flex: "1" },
  loader: { display: "flex", marginTop: "80px" },
  centerLoadingTab: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
}));

const AssetComponent = ({
  history,
  selectedLocation,
  isSurfaceUser,
  setAssetModalDataVisibility,
}) => {
  const assetTableData = useDashboardState();
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container} id="mobile-tab-container">
        <Box className={classes.workText} id="mobile-tab">
          Assets
        </Box>
        <Box className={classes.workTicketContainer}>
          {["Asset List"].map((v, i) => {
            return (
              <Box
                className={classes.assetTab}
                key={i}
                style={{
                  borderBottom: "3px solid #16457A",
                  color: "#16457A",
                }}
              >
                {v}
              </Box>
            );
          })}
        </Box>
        <Box className={classes.flexContainer} id="view-all-container">
          <ButtonBase
            variant="contained"
            color="secondary"
            onClick={() => history.push("/assets")}
            disableElevation
          >
            View All
          </ButtonBase>
        </Box>
      </Box>
      <br />
      {assetTableData.assetListLoading ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoadingTab} />
      ) : !assetTableData?.sliderAssetList?.data?.assets.length ? (
        <EmptyScreen icon={<AssetsIcon />} type={3} actionText="Add An Asset" />
      ) : (
        <AssetTable
          history={history}
          assetTableData={assetTableData?.sliderAssetList?.data}
          selectedLocation={selectedLocation}
          isSurfaceUser={isSurfaceUser}
          setAssetModalDataVisibility={setAssetModalDataVisibility}
        />
      )}
    </Box>
  );
};

export default AssetComponent;
