import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import WOIcon from "@material-ui/icons/Build";
import GeneralIcon from "@material-ui/icons/LiveHelp";
import EmergencyIcon from "@material-ui/icons/NewReleases";

import useStyles from "./styles";

const RequestTypeChip = (props) => {
  const classes = useStyles();

  const { type } = props;

  if (!type) {
    return "Error type";
  }
  let classChip;
  let iconChip;
  let labelChip = "";
  switch (Number(type)) {
    case 1:
      classChip = classes.type1;
      iconChip = <WOIcon className={classes.icon} />;
      labelChip = "Work Order";
      break;
    case 2:
      classChip = classes.type2;
      iconChip = <GeneralIcon className={classes.icon} />;
      labelChip = "General";
      break;
    case 3:
      classChip = classes.type3;
      iconChip = <EmergencyIcon className={classes.icon} />;
      labelChip = "Emergency";
      break;
    default:
      break;
  }

  return (
    <Chip
      icon={iconChip}
      label={labelChip}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default RequestTypeChip;
