import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import { useLearnPageDispatch } from "contexts/learnPageContext";
import SchoolIcon from "@material-ui/icons/School";
import { validate, validateProperty } from "components/util/validationUtil";
import { createCategory, updateCategory } from "services/learnService";
import Grid from "@material-ui/core/Grid";
import { learnColorWheel } from "constants.js";
import {
  useLearnCategoriesState,
  useLearnCategoriesDispatch,
} from "contexts/learnCategoriesTableContext";

import useStyles from "./styles";

const schema = {
  name: Joi.string()
    .min(1)
    .required()
    .label("Name")
    .error(() => {
      return {
        message: "Name is required.",
      };
    }),
};

const initialData = {
  name: "",
  color: "#17457A",
};

const ManageCategoryDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [data, setData] = useState(initialData);
  const [updatedData, setUpdatedData] = useState({});
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [error, setError] = useState([]);
  const {
    openManageCategory,
    learnCategorySelected,
  } = useLearnCategoriesState();
  const dispatchLearnPage = useLearnPageDispatch();
  const dispatchLearnCategoryPage = useLearnCategoriesDispatch();

  useEffect(() => {
    if (openManageCategory) {
      setError([]);
      setUpdatedData({});
      if (learnCategorySelected) {
        setData({
          name: learnCategorySelected.name,
          color: learnCategorySelected.color,
        });
      } else {
        setData(initialData);
      }
    }
  }, [openManageCategory]);

  const handleClose = () => {
    dispatchLearnCategoryPage({
      type: "TOGGLE_MANAGE",
      openManageCategory: false,
    });
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);
        if (learnCategorySelected) {
          await updateCategory(learnCategorySelected.id, updatedData);
          setMessageSuccess("Category updated successfully!");
        } else {
          await createCategory(data);
          setMessageSuccess("Category created successfully!");
        }
        dispatchLearnPage({
          type: "TOGGLE_RELOAD_CATEGORIES",
          reloadCategories: true,
        });
        setIsLoadingDialog(false);
        handleClose();
        setOpenSuccess(true);
      }
    } catch (e) {
      console.log("Cannot update/create Category");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let updateData = {};
    switch (event.name) {
      case "name":
        newData = {
          ...data,
          name: event.value,
        };
        if (learnCategorySelected) {
          updateData = {
            ...updatedData,
            name: event.value,
          };
        }
        break;
      default:
        break;
    }
    validateProperty(event.name, event.value, schema, error, setError);
    setData(newData);
    if (learnCategorySelected) {
      setUpdatedData(updateData);
    }
  };

  const changeSelectedColor = (color) => {
    const newData = {
      ...data,
      color,
    };
    setData(newData);
    if (learnCategorySelected) {
      setUpdatedData({
        ...updatedData,
        color,
      });
    }
  };

  return (
    <>
      <Dialog
        open={openManageCategory}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog2}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.titleContainer}>
                <SchoolIcon className={classes.titleIcon} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  {`${learnCategorySelected ? "Update" : "New"} Category`}
                </Typography>
              </Box>
              <Box className={classes.formFieldsContainer}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="name"
                  label="Name"
                  error={error}
                  value={data.name}
                  handleBlur={handleBlur}
                />
                <Typography className={classes.titleSection} gutterBottom>
                  Color
                </Typography>
                <Box className={classes.colorsContainer}>
                  {learnColorWheel.map((color) => (
                    <Box
                      className={
                        color.value === data.color
                          ? classes.colorItemSelected
                          : classes.colorItem
                      }
                      style={{ backgroundColor: color.value }}
                      onClick={() => changeSelectedColor(color.value)}
                    ></Box>
                  ))}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              <ButtonBase color="secondary" onClick={handleConfirm}>
                {learnCategorySelected ? "Update" : "Create"}
              </ButtonBase>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={messageSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ManageCategoryDialog;
