import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import useStyles from "components/common/Filters/styles";
import { questionQATypes, questionQACategory } from "constants.js";

import { useFilterState, useFilterDispatch } from "contexts/filterContext";

const SurveysQuestionsListFilters = (props) => {
  const classes = useStyles();
  const [typesFilters, setTypesFilters] = useState([]);
  const [categoriesFilters, setCategoriesFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (anchorEl) {
      const selectedTypeFilter = filters.find(
        (filter) => filter.filter === "type"
      );
      setTypesFilters(selectedTypeFilter?.values ?? []);

      const selectedCategoryFilter = filters.find(
        (filter) => filter.filter === "category"
      );
      if (selectedCategoryFilter) {
        setCategoriesFilters(selectedCategoryFilter.values);
      } else {
        setCategoriesFilters([]);
      }
    }
  }, [anchorEl, filters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    if (typesFilters.length) {
      filterData.push({
        filter: "type",
        group: "type",
        values: [...typesFilters],
      });
    }

    if (categoriesFilters.length) {
      filterData.push({
        filter: "category",
        group: "category",
        values: [...categoriesFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    handleClose();
  };

  const handleChangeType = (event, value) => {
    setTypesFilters(value);
  };

  const handleChangeCategory = (event, value) => {
    setCategoriesFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={questionQATypes}
                name="type"
                handleChange={handleChangeType}
                multiple={true}
                value={typesFilters}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={questionQACategory}
                name="category"
                handleChange={handleChangeCategory}
                multiple={true}
                value={categoriesFilters}
              />
            </Grid>
          </Grid>
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default SurveysQuestionsListFilters;
