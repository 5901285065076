import React, { useContext, useMemo } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import { hasPermission, permissionQuality } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

import { useSurveyView } from "contexts/surveyViewContext";

const SurveyBreadcrum = () => {
  const [stateContext] = useSurveyView();
  const { survey, isLoading } = stateContext ? stateContext : null;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasManage = useMemo(() =>
    hasPermission(permissionQuality.MANAGE, permissions)
  );

  if (isLoading && survey !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <PageHeader pageTitle={survey.number ?? "New"}>
      <Link to={hasManage ? "/surveys/admin/surveys" : "/surveys"}>
        Surveys
      </Link>
    </PageHeader>
  );
};

export default SurveyBreadcrum;
