import React from "react";

const DeleteIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8252 6.45457L10.5315 14.0909M6.46853 14.0909L6.17483 6.45457M14.6326 3.73141C14.9227 3.77523 15.2119 3.82212 15.5 3.87203M14.6326 3.73141L13.7265 15.5101C13.65 16.5047 12.8206 17.2727 11.823 17.2727H5.17697C4.1794 17.2727 3.35001 16.5047 3.2735 15.5101L2.36745 3.73141M14.6326 3.73141C13.6598 3.58451 12.6758 3.47202 11.6818 3.39521M1.5 3.87203C1.78812 3.82212 2.07728 3.77523 2.36745 3.73141M2.36745 3.73141C3.34017 3.58451 4.32418 3.47202 5.31818 3.39521M11.6818 3.39521V2.61788C11.6818 1.61721 10.9091 0.781794 9.90898 0.7498C9.44116 0.734835 8.97146 0.727295 8.5 0.727295C8.02854 0.727295 7.55884 0.734835 7.09102 0.7498C6.09086 0.781794 5.31818 1.61721 5.31818 2.61788V3.39521M11.6818 3.39521C10.6319 3.31407 9.57076 3.27275 8.5 3.27275C7.42924 3.27275 6.36813 3.31407 5.31818 3.39521"
      stroke="#747474"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DeleteIcon;
