import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TicketIcon from "@material-ui/icons/Receipt";
import { DialogTitle } from "components/ui/core/dialogTitle";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { ButtonBase } from "components/ui/core/button";
import { logException } from "components/util/logUtil";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import {
  updateWorkticketFMStatus,
  updateWorkticketWGApprove,
  aproveWorkticketFM,
  acceptWorkticketSC,
  acceptWorkticketCO,
  approveWorkticketCO,
} from "services/workticketService";
import useStyles from "./styles";

const WorkticketAccept = (props) => {
  const { id, status, approval, category } = props;
  const { rows } = useTableState();
  const dispatchTable = useTableDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [approveValidation, setApproveValidation] = useState(null);
  const [openApprove, setOpenApprove] = useState(false);
  const classes = useStyles();

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      let errorApprove = [];
      const selRow = rows.find((row) => row.id === id);
      const wtNte = parseInt(selRow.nte) ?? 0;
      if (parseFloat(selRow.ap_total) <= 0 && parseInt(category) === 7) {
        errorApprove = [...errorApprove, "Missing payment amount (AP)"];
      }
      if (parseFloat(selRow.ar_total) <= 0 && wtNte > 0) {
        errorApprove = [...errorApprove, "Missing billing amount (AR)"];
      }
      if (!selRow.partner_assigned) {
        errorApprove = [...errorApprove, "Missing partner"];
      }

      setApproveValidation(errorApprove);
      setOpenApprove(true);
    } catch (e) {
      logException(e, "Cannot accept workorder");
    }

    setIsLoading(false);
  };

  const handleAccept = async () => {
    setIsLoading(true);
    if (parseInt(category) === 7) {
      // Service Channel Integration
      try {
        await acceptWorkticketSC(id);
        const selRow = rows.find((row) => row.id === id);
        selRow.status = 1;
        dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      } catch (e) {
        logException(e, "Cannot accept workorder");
      }
    }
    if (parseInt(category) === 8) {
      // FM Pilot Integration
      try {
        const data = {
          external_status: "VACCPT-ACCEPTED",
        };
        await updateWorkticketFMStatus(id, data);
        const selRow = rows.find((row) => row.id === id);
        selRow.status = 0;
        dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      } catch (e) {
        logException(e, "Cannot accept workorder");
      }
    }
    if (parseInt(category) === 9) {
      // Corrigo Integration
      try {
        await acceptWorkticketCO(id);
        const selRow = rows.find((row) => row.id === id);
        selRow.status = 0;
        dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      } catch (e) {
        logException(e, "Cannot accept workorder");
      }
    }
    setIsLoading(false);
  };

  const handleApproveSubmit = async () => {
    setIsLoadingStatus(true);
    try {
      await updateWorkticketWGApprove(id);
      const selRow = rows.find((row) => row.id === id);
      selRow.approval = 1;
      dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: { tab: 1, count: 1 },
      });
      handleApproveClose();
    } catch (e) {
      logException(e, "Cannot approve workorder");
    }
    setIsLoadingStatus(false);
  };

  const handleApproveSubmitFM = async () => {
    setIsLoadingStatus(true);
    try {
      await aproveWorkticketFM(id);
      const selRow = rows.find((row) => row.id === id);
      selRow.approval = 1;
      dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: { tab: 0, count: 1 },
      });
      handleApproveClose();
    } catch (e) {
      logException(e, "Cannot approve workorder");
    }
    setIsLoadingStatus(false);
  };

  const handleApproveCorrigo = async () => {
    setIsLoadingStatus(true);
    try {
      await approveWorkticketCO(id);
      const selRow = rows.find((row) => row.id === id);
      selRow.approval = 1;
      dispatchTable({ type: "UPDATE_ROWS", rows: rows });
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: { tab: 0, count: 1 },
      });
      handleApproveClose();
    } catch (e) {
      logException(e, "Cannot approve workorder");
    }
    setIsLoadingStatus(false);
  };

  const handleApproveClose = () => {
    setOpenApprove(false);
  };

  if (isLoading) {
    return <LinearProgress color="secondary" className={classes.root} />;
  }

  const typeAction =
    (parseInt(category) === 7 &&
      !approval &&
      [0, 6].includes(parseInt(status))) ||
    ([8, 9].includes(parseInt(category)) &&
      !approval &&
      parseInt(status) === 6);

  const approveValidationAction =
    ((parseInt(category) === 7 && parseInt(status) === 1) ||
      ([8, 9].includes(parseInt(category)) && parseInt(status) === 0)) &&
    approval;

  return (
    <>
      {typeAction ? (
        <ButtonBase
          color="secondary"
          onClick={handleAccept}
          className={classes.root}
        >
          Accept
        </ButtonBase>
      ) : (
        <ButtonBase
          color="primary"
          onClick={handleApprove}
          className={classes.root}
          disabled={approveValidationAction}
        >
          {approveValidationAction ? "Approved" : "Approve"}
        </ButtonBase>
      )}
      <Dialog
        open={openApprove}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingStatus ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              {!isLoadingStatus ? (
                <>
                  <DialogCloseIcon handleClose={handleApproveClose} />
                  <DialogTitle title="Workticket" icon={<TicketIcon />} />
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Please verify the partner, partner payment and billing are
                      correct. This will enable the workticket for work.
                    </Typography>
                  </Box>
                  {approveValidation?.map((item, index) => (
                    <Box
                      key={`ai-${index}`}
                      className={classes.wrapperItemValidation}
                    >
                      * {item}
                    </Box>
                  ))}
                </>
              ) : (
                <Box className={classes.containerDialogLoader}>
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                </Box>
              )}
            </DialogContent>
            {!isLoadingStatus ? (
              <DialogActions className={classes.wrapperDialogAction}>
                <Button
                  onClick={handleApproveClose}
                  className={classes.buttonOutlinedDialog}
                  variant="outlined"
                  size="large"
                  color="primary"
                  disabled={isLoadingStatus}
                >
                  Cancel
                </Button>
                <Button
                  onClick={
                    parseInt(category) === 7
                      ? handleApproveSubmit
                      : parseInt(category) === 8
                      ? handleApproveSubmitFM
                      : handleApproveCorrigo
                  }
                  className={classes.buttonDialog}
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={
                    isLoadingStatus ||
                    (approveValidation && Boolean(approveValidation.length))
                  }
                >
                  {Boolean(approveValidation?.length) ? "*" : ""}
                  Approve
                </Button>
              </DialogActions>
            ) : null}
          </>
        )}
      </Dialog>
    </>
  );
};

export default WorkticketAccept;
