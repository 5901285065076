import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import RingBellSurfaceTable from "./ringBellSurfaceTable";
import RingBellTable from "./ringBellTable";
import RingBellDialog from "./dialog/ringBellDialog";
import TeamSurveysDialog from "./dialog/teamSurveysDialog";
import SurveyContent from "pages/survey/surveyContent";
import AnnouncementDialog from "./dialog/announcementActionDialog";
import { RingBellSurfaceProvider } from "contexts/ringBellSurfaceContext";
import { RingBellProvider } from "contexts/ringBellContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const RingBellSurfaceList = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      <PageHeader pageTitle="Team" link="/team">
        <Link to="/team">Talent</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        {hasPermission(permissionSurface.MAP, permissions) ? (
          <RingBellSurfaceProvider>
            <RingBellSurfaceTable />
            <RingBellDialog />
          </RingBellSurfaceProvider>
        ) : (
          <RingBellProvider>
            <RingBellTable />
            <SurveyFillProvider>
              <TeamSurveysDialog />
              <SurveyContent />
            </SurveyFillProvider>
            <RingBellDialog />
            <AnnouncementDialog />
          </RingBellProvider>
        )}
      </Box>
    </>
  );
};

export default withTableFilterContext(RingBellSurfaceList);
