import http from "./httpService";
import { apiUrl } from "lib/config";
import { setFormData, getFilterString } from "components/util/serviceUtil";

const apiEndpoint = apiUrl + "/worknova";

export function uploadWorkorders(data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}`, setFormData(data));
}

export function getWorknovaList(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`);
}

export function getWorknovaWtList() {
  return http.get(`${apiEndpoint}/list`);
}

export function getWorknovaTabOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`);
}

export function getWorknovaSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`);
}

export function getWorknovaDetailList(worknova, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/detail/${worknova}?${filterString}`);
}

export function getWorknovaWorkticketDetail(woid) {
  return http.get(`${apiEndpoint}/workticket/detail/${woid}`);
}

export function getWorknovaDetailTabOffset(worknova, tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/detail/${worknova}?${filterString}filters[tab]=${tab}&offset=${offset}`
  );
}

export function getWorknovaDetailSearch(worknova, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/detail/${worknova}?${filterString}search=${search}`
  );
}

export function updateWorknovaDetailBulk(data) {
  return http.post(`${apiEndpoint}/update/bulk`, data);
}

export function updateWorknovaDetailStatusBulk(data) {
  return http.post(`${apiEndpoint}/update/bulkStatus`, data);
}

export function updateWorknovaWorkticketDetail(workticket_id, data) {
  return http.post(`${apiEndpoint}/update/workticket/${workticket_id}`, data);
}

export function deleteWorknovaBulk(data) {
  return http.post(`${apiEndpoint}/delete/bulk`, data);
}
