import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Skeleton from "@material-ui/lab/Skeleton";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import { getJobsCustomers } from "services/jobService";
import { getCustomers } from "services/userService";
import { useSurveyView } from "contexts/surveyViewContext";
import { logException } from "components/util/logUtil";
import { updateSurvey } from "services/surveyService";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import FilterJobsAdvance from "components/ui/Surveys/FilterJobsAdvance";
import useStyles from "./styles";
import SurveyShare from "./surveyShare";
import {
  getDepartments,
  getPositions,
} from "services/surveyEvaluationsService";

import {
  surveysQAStatuses,
  questionQACategory,
  signatureConfirmationOptions,
  ringBellPositionOptions,
  QASubTypes,
} from "constants.js";
import { getSurveyPreview } from "services/surveyService";
import { useSurveyFillDispatch } from "contexts/surveyFillContext";
import MessageDialog from "./dialog/messageDialog";
import { getTeamUsers } from "services/ringBellService";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionQuality } from "lib/permissions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const initialData = {
  name: "",
  number: "",
  description: "",
  customers: [],
  jobs: [],
  type: null,
  status: null,
  has_randomize: false,
  random_number: 0,
  sub_type: 0,
  signatureConfirmation: null,
  assignedOverrides: [],
  assignedDepartments: [],
  assignedPositions: [],
};

const SurveyInformation = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const classes = useStyles();
  const [customers, setCustomers] = useState(null);
  const [openShare, setOpenShare] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [data, setData] = useState(initialData);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [departments, setDepartments] = useState(null);
  const [positions, setPositions] = useState(null);
  const [stateContext, dispatchContext] = useSurveyView();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [isFailure, setIsFailure] = useState(false);
  const { survey, isLoading } = stateContext ?? null;
  const [exUsers, setExUsers] = useState([]);
  const dispatchSurveyFill = useSurveyFillDispatch();
  const isReadOnly = !hasPermission(permissionQuality.MANAGE, permissions);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultUser = await getCustomers();
        const { customers } = resultUser.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: parseInt(customer.id), label: customer.name };
        });
        setCustomers(resultForRender);
        const selectedCustomers = survey.customers.map((c) => {
          return c.id;
        });
        const customersDisplay = resultForRender.filter((item) =>
          selectedCustomers.includes(item.value)
        );

        const typeDisplay = questionQACategory.filter(
          (item) => item.value === survey.type
        );
        const subTypeDisplay = QASubTypes.find(
          (item) => item.value === survey.sub_type
        );
        const statusDisplay = surveysQAStatuses.filter(
          (item) => item.value === survey.status
        );
        if (survey.type === 1) {
          const selectedPositionsId = survey?.assigned_positions?.map(
            (item) => item.tier_classification
          );
          const selectedDeparmentsId = survey?.assigned_departments?.map(
            (item) => item.department
          );
          const selectedOverwriteUsersId = survey?.assigned_overrides?.map(
            (item) => item.user_id
          );
          const resultPositions = survey?.assigned_departments.length
            ? await getPositions(selectedDeparmentsId)
            : { data: { data: { positions: [] } } };
          const positionsIds = resultPositions.data.data.positions.map((p) => {
            return p.tier_classification;
          });
          const positionsToRender = ringBellPositionOptions.filter((p) =>
            positionsIds.includes(p.value)
          );
          const filterData = {};
          filterData.filters = [
            {
              filter: "department",
              group: "department",
              values: selectedDeparmentsId.map((item) => {
                return { label: "", value: item };
              }),
            },
            {
              filter: "tier_classification",
              group: "tier_classification",
              values: selectedPositionsId.map((item) => {
                return { label: "", value: item };
              }),
            },
          ];
          const result = await getTeamUsers(filterData);
          const usersToRender = result.data.data.list;
          setExUsers(usersToRender);
          setPositions(positionsToRender);
          setData({
            name: survey.name,
            description: survey.description,
            number: survey.number,
            customers: customersDisplay,
            jobs: [],
            type: typeDisplay[0],
            status: statusDisplay[0],
            has_randomize: survey.has_randomize,
            random_number: survey?.random_number,
            sub_type: subTypeDisplay,
            signatureConfirmation:
              signatureConfirmationOptions.find(
                (item) => item.value === survey.signature_confirmation
              ) ?? null,
            assignedOverrides: selectedOverwriteUsersId
              ? usersToRender.filter((userItem) =>
                  selectedOverwriteUsersId.includes(userItem.id)
                )
              : [],
            assignedDepartments: selectedDeparmentsId
              ? departments.filter((depItem) =>
                  selectedDeparmentsId.includes(depItem.value)
                )
              : [],
            assignedPositions: selectedPositionsId
              ? positionsToRender.filter((posItem) =>
                  selectedPositionsId.includes(posItem.value)
                )
              : [],
          });
        } else {
          const resultJob = await getJobsCustomers(selectedCustomers);
          const resultForRenderJob = resultJob.data.data.jobs.map((job) => {
            return {
              id: parseInt(job.id),
              job_description: job.job_description,
              job_number: job.job_number,
              customer_id: job.customer_id,
              industry: job.industry_id,
              city: job.city,
              state: job.state,
              zip: job.zip,
            };
          });
          const selectedJobs = survey.jobs.map((c) => {
            return c.id;
          });
          const jobsDisplay = resultForRenderJob.filter((item) =>
            selectedJobs.includes(item.id)
          );
          setJobs(resultForRenderJob);

          setData({
            name: survey.name,
            description: survey.description,
            number: survey.number,
            customers: customersDisplay,
            jobs: jobsDisplay,
            type: typeDisplay[0],
            status: statusDisplay[0],
            has_randomize: survey.has_randomize,
            random_number: survey?.random_number,
            sub_type: subTypeDisplay,
            signatureConfirmation:
              signatureConfirmationOptions.find(
                (item) => item.value === survey.signature_confirmation
              ) ?? null,
          });
        }
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (departments) {
      if (!customers && survey.id) {
        loadCustomer();
      } else if (survey.id) {
        const statusDisplay = surveysQAStatuses.filter(
          (item) => item.value === survey.status
        );
        setData({
          ...data,
          status: statusDisplay[0],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, JSON.stringify(survey), departments]);

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const resultForRender = await getDepartments();
        setDepartments(resultForRender.data.data.departments);
      } catch (e) {
        logException(e, "Cannot load departments data");
      }
    };
    if (!departments) {
      loadDepartments();
    }
  }, [departments, data]);

  const handleBlur = async (event) => {
    if (survey[event.name] !== event.value) {
      try {
        await updateSurvey(survey.id, { [event.name]: event.value });
        data[event.name] = event.value;
        setData(data);
      } catch (e) {
        logException(e, "Cannot update Survey");
      }
    }
  };

  const handleChangeSubType = async (event, value) => {
    if (value && data.sub_type !== value.value) {
      try {
        const dataUpdate = {
          sub_type: value.value,
        };
        survey.sub_type = value.value;
        data.sub_type = value;
        setData(data);
        dispatchContext({
          type: "SET_SURVEY",
          survey: survey,
        });
        await updateSurvey(survey.id, dataUpdate);
      } catch (e) {
        logException(e, "Cannot update Survey sub type");
      }
    }
  };

  const handleChangeStatus = async (event, value) => {
    if (value && data.status !== value.value) {
      try {
        const dataUpdate = {
          status: value.value,
        };
        survey.status = value.value;
        data.status = value;
        setData(data);
        dispatchContext({
          type: "SET_SURVEY",
          survey: survey,
        });
        await updateSurvey(survey.id, dataUpdate);
      } catch (e) {
        logException(e, "Cannot update Survey status");
      }
    }
  };

  const handleChangeJobs = async (event, value) => {
    if (value) {
      const assignedToId = [];
      const editAssignedToId = [];
      const removeAssigned = [];
      const addAssigned = [];

      data.jobs.map((item) => {
        return assignedToId.push(item.id);
      });
      value.map((item) => {
        return editAssignedToId.push(item.id);
      });

      data.jobs.map((item) => {
        if (!editAssignedToId.includes(item.id)) {
          removeAssigned.push(item.id);
        }
        return true;
      });

      value.map((item) => {
        if (!assignedToId.includes(item.id)) {
          addAssigned.push(item.id);
        }
        return true;
      });

      if (addAssigned.length > 0) {
        try {
          const dataUpdate = {
            add_job_ids: addAssigned,
          };
          await updateSurvey(survey.id, dataUpdate);
        } catch (e) {
          logException(e, "Cannot assign job");
        }
      }

      if (removeAssigned.length > 0) {
        try {
          const dataUpdate = {
            remove_job_ids: removeAssigned,
          };
          await updateSurvey(survey.id, dataUpdate);
        } catch (e) {
          logException(e, "Cannot unassign job");
        }
      }

      survey.jobs = value;
      data.jobs = value;
      setData(data);
      dispatchContext({
        type: "SET_SURVEY",
        survey: survey,
      });
    }
  };

  const handleChangeCustomers = async (event, value) => {
    if (value) {
      const assignedToId = [];
      const editAssignedToId = [];
      const removeAssigned = [];
      const addAssigned = [];

      data.customers.map((item) => {
        return assignedToId.push(item.value);
      });
      value.map((item) => {
        return editAssignedToId.push(item.value);
      });

      data.customers.map((item) => {
        if (!editAssignedToId.includes(item.value)) {
          removeAssigned.push(item.value);
        }
        return true;
      });

      value.map((item) => {
        if (!assignedToId.includes(item.value)) {
          addAssigned.push(item.value);
        }
        return true;
      });

      if (removeAssigned.length > 0) {
        try {
          const dataUpdate = {
            remove_customer_ids: removeAssigned,
          };
          const result = await updateSurvey(survey.id, dataUpdate);
          const { surveyResult } = result.data.data;
          const selectedJobs = surveyResult.jobs.map((c) => {
            return c.id;
          });
          const jobsDisplay = jobs.filter((item) =>
            selectedJobs.includes(item.id)
          );
          data.jobs = jobsDisplay;
          survey.jobs = jobsDisplay;
        } catch (e) {
          logException(e, "Cannot assign job");
        }
      }

      if (addAssigned.length > 0 || removeAssigned.length > 0) {
        const selectedCustomers = value.map((c) => {
          return c.value;
        });
        const resultJob = await getJobsCustomers(selectedCustomers);
        const resultForRenderJob = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            city: job.city,
            state: job.state,
            zip: job.zip,
          };
        });
        const selectedJobs = survey.jobs.map((c) => {
          return c.id;
        });
        const jobsDisplay = resultForRenderJob.filter((item) =>
          selectedJobs.includes(item.id)
        );
        setJobs(resultForRenderJob);
        survey.jobs = jobsDisplay;
        data.jobs = jobsDisplay;
      }
      survey.customers = value;
      data.customers = value;
      setData(data);
      dispatchContext({
        type: "SET_SURVEY",
        survey: survey,
      });
    }
  };

  const openPublishDialog = () => {
    dispatchContext({
      type: "TOGGLE_PUBLISH",
      openPublishDialog: true,
    });
  };

  const openShareDialog = () => {
    setOpenShare(true);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const changeHasRandomize = async (value) => {
    try {
      let updateData = null;
      if (value && !data?.random_number) {
        updateData = {
          has_randomize: value,
          random_number: 3,
        };
      } else {
        updateData = {
          has_randomize: value,
        };
      }
      await updateSurvey(survey.id, updateData);
      setData({ ...data, ...updateData });
    } catch (e) {
      logException(e, "Cannot update Survey");
    }
  };

  const handleChangeSignatureConfirmation = async (event, option) => {
    if (option) {
      await updateSurvey(survey.id, { signature_confirmation: option.value });
      const newData = {
        ...data,
        signatureConfirmation: option,
      };
      setData(newData);
    }
  };

  // const handleChangeRole = async (event, option) => {
  //   if (option) {
  //     const selectedIds = option.map((valueItem) => valueItem.value);
  //     const currentIds = data.assignedRoles.map((valueItem) => valueItem.value);
  //     const addIds = selectedIds.filter((item) => !currentIds.includes(item));
  //     const removeIds = currentIds.filter(
  //       (item) => !selectedIds.includes(item)
  //     );

  //     try {
  //       const newData = {
  //         ...data,
  //         assignedRoles: option,
  //       };
  //       setData(newData);
  //       await updateSurvey(survey.id, {
  //         add_role_ids: addIds,
  //         remove_role_ids: removeIds,
  //       });
  //     } catch (e) {
  //       logException(e, "Cannot update Survey Roles");
  //     }
  //   }
  // };

  const handleChangeOverrides = async (event, option) => {
    if (option) {
      const selectedIds = option.map((valueItem) => valueItem.id);
      const currentIds = data.assignedOverrides.map(
        (valueItem) => valueItem.id
      );
      const addIds = selectedIds.filter((item) => !currentIds.includes(item));
      const removeIds = currentIds.filter(
        (item) => !selectedIds.includes(item)
      );

      try {
        const newData = {
          ...data,
          assignedOverrides: option,
        };
        setData(newData);
        await updateSurvey(survey.id, {
          add_exclude_ids: addIds,
          remove_user_ids: removeIds,
        });
      } catch (e) {
        logException(e, "Cannot update Survey Exclude Users");
      }
    }
  };

  const getPreview = async () => {
    setIsLoadingPreview(true);
    const result = await getSurveyPreview(survey.id);
    const { survey: selSurvey } = result.data.data;
    if (selSurvey.questions.length) {
      dispatchSurveyFill({
        type: "SET_SURVEY_STATUS",
        surveyStatus: 1,
      });
      dispatchSurveyFill({
        type: "SET_IS_PREVIEW",
        isPreview: true,
      });
      dispatchSurveyFill({
        type: "SET_SELECTED_SURVEY",
        selectedSurvey: selSurvey,
      });
      dispatchSurveyFill({
        type: "SET_SIGNATURE",
        signature: null,
      });
      if (selSurvey.questions[0].type === 1) {
        dispatchSurveyFill({
          type: "SET_SELECTED_TASKS",
          selectedTasks: selSurvey.questions[0].tasks,
        });
      }
      dispatchSurveyFill({
        type: "SET_CORRELATIVE",
        correlative: 0,
      });
      const previewQuestions = selSurvey.questions.map((q) => {
        return {
          ...q,
          order: q.pivot.order,
          is_required: q.pivot.is_required,
        };
      });
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: [previewQuestions[0]],
      });
      dispatchSurveyFill({
        type: "SET_PREVIEW_QUESTIONS",
        previewQuestions: previewQuestions,
      });
      const resumeQuestions = selSurvey.questions.map((q) => {
        return {
          qa_question_id: q.id,
          name: q.name,
          name_es: q.name_es,
          order: q.pivot.order,
          is_required: q.pivot.is_required,
          min_id: q.id,
          sum_status: 0,
          count: 1,
        };
      });
      dispatchSurveyFill({
        type: "SET_ANSWERS",
        answers: resumeQuestions,
      });
      dispatchSurveyFill({
        type: "TOGGLE_OPEN_SHOW_SURVEY",
        openShowSurvey: true,
      });
    } else {
      setMessage("Please add questions to this survey so you can preview it.");
      setIsFailure(true);
      setTitle("Error!");
      setOpenSuccess(true);
    }
    setIsLoadingPreview(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangePositions = async (event, value) => {
    if (value?.length > 0) {
      const assignedToId = [];
      const editAssignedToId = [];
      const removeAssigned = [];
      const addAssigned = [];

      data.assignedPositions.map((item) => {
        return assignedToId.push(item.value);
      });
      value.map((item) => {
        return editAssignedToId.push(item.value);
      });

      data.assignedPositions.map((item) => {
        if (!editAssignedToId.includes(item.value)) {
          removeAssigned.push(item.value);
        }
        return true;
      });

      value.map((item) => {
        if (!assignedToId.includes(item.value)) {
          addAssigned.push(item.value);
        }
        return true;
      });

      if (addAssigned.length > 0 || removeAssigned.length > 0) {
        try {
          const dataUpdate = {
            remove_position_ids: removeAssigned,
            add_position_ids: addAssigned,
          };
          const result = await updateSurvey(survey.id, dataUpdate);
          const { survey: surveyResult } = result.data.data;
          const selectedPositionsId = surveyResult?.assigned_positions?.map(
            (item) => item.tier_classification
          );
          const filterData = {};
          filterData.filters = [
            {
              filter: "department",
              group: "department",
              values: data.assignedDepartments.map((item) => {
                return { label: "", value: item.value };
              }),
            },
            {
              filter: "tier_classification",
              group: "tier_classification",
              values: selectedPositionsId.map((item) => {
                return { label: "", value: item };
              }),
            },
          ];
          const resultUsers = await getTeamUsers(filterData);
          const usersToRender = resultUsers.data.data.list;
          setExUsers(usersToRender);
          const selectedOverwriteUsersId =
            surveyResult?.assigned_overrides?.map((item) => item.user_id);
          const newData = {
            ...data,
            assignedOverrides: selectedOverwriteUsersId
              ? usersToRender.filter((userItem) =>
                  selectedOverwriteUsersId.includes(userItem.id)
                )
              : [],

            assignedPositions: value,
          };
          setData(newData);
        } catch (e) {
          logException(e, "Cannot assign positions");
        }
      }
    }
  };

  const handleChangeDepartments = async (event, value) => {
    if (value?.length > 0) {
      const assignedToId = [];
      const editAssignedToId = [];
      const removeAssigned = [];
      const addAssigned = [];

      data.assignedDepartments.map((item) => {
        return assignedToId.push(item.value);
      });
      value.map((item) => {
        return editAssignedToId.push(item.value);
      });

      data.assignedDepartments.map((item) => {
        if (!editAssignedToId.includes(item.value)) {
          removeAssigned.push(item.value);
        }
        return true;
      });

      value.map((item) => {
        if (!assignedToId.includes(item.value)) {
          addAssigned.push(item.value);
        }
        return true;
      });

      if (addAssigned.length > 0 || removeAssigned.length > 0) {
        try {
          const dataUpdate = {
            remove_department_ids: removeAssigned,
            add_department_ids: addAssigned,
          };
          const result = await updateSurvey(survey.id, dataUpdate);
          const { survey: surveyResult } = result.data.data;
          const selectedDeparmentsId = surveyResult?.assigned_departments?.map(
            (item) => item.department
          );
          const resultPositions = surveyResult?.assigned_departments.length
            ? await getPositions(selectedDeparmentsId)
            : { data: { data: { positions: [] } } };
          const positionsIds = resultPositions.data.data.positions.map((p) => {
            return p.tier_classification;
          });
          const positionsToRender = ringBellPositionOptions.filter((p) =>
            positionsIds.includes(p.value)
          );
          const selectedPositionsId = surveyResult?.assigned_positions?.map(
            (item) => item.tier_classification
          );
          const filterData = {};
          filterData.filters = [
            {
              filter: "department",
              group: "department",
              values: selectedDeparmentsId.map((item) => {
                return { label: "", value: item };
              }),
            },
            {
              filter: "tier_classification",
              group: "tier_classification",
              values: selectedPositionsId.map((item) => {
                return { label: "", value: item };
              }),
            },
          ];
          const resultUsers = await getTeamUsers(filterData);
          const usersToRender = resultUsers.data.data.list;
          setExUsers(usersToRender);
          setPositions(positionsToRender);
          const selectedOverwriteUsersId =
            surveyResult?.assigned_overrides?.map((item) => item.user_id);
          const newData = {
            ...data,
            assignedOverrides: selectedOverwriteUsersId
              ? usersToRender.filter((userItem) =>
                  selectedOverwriteUsersId.includes(userItem.id)
                )
              : [],

            assignedPositions: selectedPositionsId
              ? positionsToRender.filter((posItem) =>
                  selectedPositionsId.includes(posItem.value)
                )
              : [],
            assignedDepartments: value,
          };
          setData(newData);
        } catch (e) {
          logException(e, "Cannot assign positions");
        }
      }
    }
  };

  const changeJobFilterList = (jobsIds) => {
    const jobList = jobs.filter((job) => jobsIds.includes(job.id));
    handleChangeJobs(null, jobList);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <>
      <SurveyShare open={openShare} handleClose={handleCloseShare} />
      <Box className={classes.containerInformation}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="number"
          label="Survey Number"
          value={data.number}
          styleOverride={{ cursor: "not-allowed" }}
          readonly={true}
        />

        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="name"
          label="Name"
          value={data.name}
          handleBlur={handleBlur}
          readonly={isReadOnly}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="description"
          label="Description"
          value={data.description}
          multiline={true}
          rows={4}
          handleBlur={handleBlur}
          readonly={isReadOnly}
        />
        {survey.type !== 1 ? (
          <>
            <FormSelectAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={customers ?? []}
              name="customer"
              multiple={true}
              handleChange={handleChangeCustomers}
              label="Customer/s"
              value={data.customers}
              readonly={isReadOnly}
            />
            {!isReadOnly ? (
              <FilterJobsAdvance
                jobs={jobs}
                jobsSelected={data.jobs}
                customersSelected={data.customers}
                changeJobFilterList={changeJobFilterList}
              />
            ) : null}
            <FormSelectJobsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={jobs ?? []}
              name="job"
              label="Job/s"
              multiple={true}
              value={data.jobs}
              handleChange={handleChangeJobs}
              readonly={isReadOnly}
            />
          </>
        ) : null}

        <FormSelectAuto
          gridSizes={[{ size: "md", val: 12 }]}
          options={questionQACategory}
          name="type"
          readonly={true}
          label="Type"
          value={data.type}
        />
        {survey.type === 2 ? (
          <FormSelectAuto
            gridSizes={[{ size: "md", val: 12 }]}
            handleChange={handleChangeSubType}
            options={QASubTypes}
            name="sub_type"
            label="Sub Type"
            value={data.sub_type}
          />
        ) : null}
        <FormSelectAuto
          gridSizes={[{ size: "md", val: 12 }]}
          options={surveysQAStatuses}
          name="status"
          handleChange={handleChangeStatus}
          label="Status"
          value={data.status}
          readonly={isReadOnly}
        />
        <Box className={classes.containerInput}>
          <InputLabel className={classes.inputLabel}>Express</InputLabel>
          <ButtonGroup
            variant="outlined"
            color="default"
            aria-label="primary button group"
            className={classes.groupOption2}
            fullWidth={true}
          >
            <Button
              className={data.has_randomize ? classes.groupOptionActive : null}
              onClick={() => changeHasRandomize(true)}
              disabled={isReadOnly}
            >
              Yes
            </Button>
            <Button
              className={!data.has_randomize ? classes.groupOptionActive : null}
              onClick={() => changeHasRandomize(false)}
              disabled={isReadOnly}
            >
              No
            </Button>
          </ButtonGroup>
        </Box>
        {data.has_randomize ? (
          <FormInput
            gridSizes={[{ size: "md", val: 6 }]}
            name="random_number"
            label="Maximum Number of Questions"
            value={data.random_number}
            handleBlur={handleBlur}
            readonly={isReadOnly}
          />
        ) : null}
        {data.type?.value === 1 ? (
          <Box className={classes.containerInput}>
            {departments?.length ? (
              <FormSelectAuto
                options={departments}
                name="departments"
                multiple={true}
                handleChange={handleChangeDepartments}
                label="Departments (Select Departments Survey is For)"
                value={data.assignedDepartments}
                readonly={isReadOnly}
              />
            ) : null}
            {departments?.length ? (
              <FormSelectAuto
                options={positions}
                name="positions"
                multiple={true}
                handleChange={handleChangePositions}
                label="Positions (Select Positions Survey is For)"
                value={data.assignedPositions}
                readonly={isReadOnly}
              />
            ) : null}
            <FormSelectChipsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={exUsers}
              name="users"
              label="Exceptions (User List)"
              handleChange={handleChangeOverrides}
              multiple={true}
              value={data.assignedOverrides}
              readonly={isReadOnly}
            />
          </Box>
        ) : null}
        {[1, 2].includes(data.type?.value) ? (
          <FormSelectAuto
            gridSizes={[{ size: "md", val: 12 }]}
            options={signatureConfirmationOptions}
            name="signatureConfirmation"
            handleChange={handleChangeSignatureConfirmation}
            label="Signature Confirmation"
            value={data?.signatureConfirmation}
            readonly={isReadOnly}
          />
        ) : null}
        <Box className={classes.containerInformationActionsSurvey}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6}>
              {isLoadingPreview ? (
                <Box className={classes.loadingPrevContainer}>
                  <LoadingStateHorizontal isVisible />
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonOutlined)}
                  onClick={getPreview}
                >
                  Preview Survey
                </Button>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6} className={classes.buttonWrapper}>
              {parseInt(survey.status) === 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonPrimary)}
                  onClick={openPublishDialog}
                  disabled={isReadOnly}
                >
                  Publish
                </Button>
              ) : null}
              {parseInt(survey.status) === 2 &&
              [3].includes(parseInt(survey.type)) ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classNames(classes.button, classes.buttonPrimary)}
                  onClick={openShareDialog}
                >
                  Share
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <MessageDialog
        open={openSuccess}
        title={title}
        message={message}
        handleClose={handleCloseSuccess}
        isFailure={isFailure}
      />
    </>
  );
};

export default SurveyInformation;
