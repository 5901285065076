import React from "react";
import Typography from "@material-ui/core/Typography";
import { formatNumber } from "components/util/stringFormat";
import useStyles from "./styles";

const colorLiquidity = (value) => {
  let color = "#747474";
  if (isNaN(value) && !value) {
    return color;
  }
  if (value > 95) {
    color = "#9CBB65";
  } else if (value <= 95 && value >= 85) {
    color = "#EFC945";
  } else {
    color = "#F07A8C";
  }
  return color;
};

const WorkticketLiquidityFieldKpi = (props) => {
  const classes = useStyles();
  const { value1, value2, value3, type } = props;

  if (isNaN(value1)) {
    return "0";
  }

  let value2Internal = 0;

  if (!isNaN(value2)) {
    value2Internal = value2;
  }

  let result = 0;

  if (value1 > 0) {
    result = (Number(value2Internal) / Number(value1)) * 100;
  }

  return (
    <>
      <nobr>
        <Typography
          variant="caption"
          className={classes.label}
          style={{ backgroundColor: colorLiquidity(result) }}
        >
          {parseFloat(result).toFixed(0)} %
        </Typography>

        {type === "dollars-empty" && value3 && !isNaN(value3) ? (
          <Typography variant="caption" className={classes.detail}>
            $ {formatNumber(parseFloat(value3).toFixed(0))}
          </Typography>
        ) : (
          ""
        )}
      </nobr>
    </>
  );
};

export default WorkticketLiquidityFieldKpi;
