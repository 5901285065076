import React from "react";
import { merge } from "lodash";

const FilterStateContext = React.createContext();
const FilterDispatchContext = React.createContext();

const defaultState = {
  filters: [], // Array of objects holding the CURRENTLY APPLIED filter state.
  tempFilters: [], // Array of objects holding SELECTED FILTERS that have not been applied yet.
  filterOptions: [], // Array of objects to hold the state of the OPTIONS array defined above as 'initialFilterOptions'
  initialFilterOptions: [], // Array of objects that remains unmodified at all times should the filters need to be 'reset' back to their initial values
  filtersUri: [],
  breakdown: null,
  fields: null,
  startDate: new Date(),
  endDate: new Date(),
  startDateActive: false,
  endDateActive: false,
  startDateFormatted: null,
  endDateFormatted: null,
  key: "selection",
  searchString: "",
  filterResults: [], // Array of objects holding the filter results.  When this has a length > 0, it is displayed in the table instead of the regular results.
  isLoadingFilters: true,
  isLoadingFiltersUri: true,
};

function filterReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoadingFilters: action.isLoadingFilters,
      };
    case "CLEAR_START_DATE": {
      return {
        ...state,
        startDate: new Date(),
        startDateActive: false,
      };
    }
    case "CLEAR_END_DATE": {
      return {
        ...state,
        endDate: new Date(),
        endDateActive: false,
      };
    }
    case "SET_FILTERS": {
      return {
        ...state,
        filters: state.tempFilters,
      };
    }
    case "SET_FILTERS_CORE": {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case "SET_FILTERS_URI": {
      return {
        ...state,
        filtersUri: action.filtersUri,
      };
    }
    case "SET_FILTERS_LOADING_URI": {
      return {
        ...state,
        isLoadingFiltersUri: action.isLoading,
      };
    }
    case "SET_BREAKDOWN": {
      return {
        ...state,
        breakdown: action.breakdown,
      };
    }
    case "SET_FIELDS": {
      return {
        ...state,
        fields: action.fields,
      };
    }
    case "SET_DATE":
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        startDateActive: action?.startDateActive ?? true,
        endDateActive: action?.endDateActive ?? true,
      };
    case "CLEAR_DATE_RANGE":
      return {
        ...state,
        startDate: new Date(),
        endDate: new Date(),
        startDateActive: false,
        endDateActive: false,
      };
    case "SET_SEARCH_STRING":
      return {
        ...state,
        searchString: action.searchString,
      };
    case "SET_FILTER_RESULTS":
      return {
        ...state,
        filterResults: action.filterResults,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FilterProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(filterReducer, mergedState);
  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
}

function useFilterState() {
  const context = React.useContext(FilterStateContext);
  if (context === undefined) {
    throw new Error("useFilterState must be used within a FilterProvider");
  }
  return context;
}

function useFilterDispatch() {
  const context = React.useContext(FilterDispatchContext);
  if (context === undefined) {
    throw new Error("useFilterDispatch must be used within a FilterProvider");
  }
  return context;
}

function getFilterData(useStateCall, defaultFilter = undefined) {
  try {
    const {
      filters,
      breakdown,
      fields,
      startDate,
      startDateActive,
      endDate,
      endDateActive,
    } = useStateCall;
    let filtersApply = filters;

    if (defaultFilter) {
      filtersApply = [...filters, defaultFilter];
    }

    const filterData = {
      filters: filtersApply,
      dateFilters: {
        startDate,
        startDateActive,
        endDate,
        endDateActive,
      },
      breakdown,
      fields,
    };
    return filterData;
  } catch (error) {
    console.log(error);
  }
}

export { FilterProvider, useFilterState, useFilterDispatch, getFilterData };
