import { useGetUserId } from "../context/UserIdContext";
import { useQuery } from "react-query";
import { getPartnerActivities } from "../../../services/partners/activitiesService";

export function useActivities() {
  const { userId } = useGetUserId();

  const { isLoading: isActivitiesLoading, data: activities } = useQuery(
    ["partnerActivities", userId],
    async () => {
      const { data } = await getPartnerActivities(userId);
      return data.data.activities;
    },
    {
      enabled: !!userId,
    }
  );
  return { isActivitiesLoading, activities };
}
