import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormInput from "../../../components/ui/FormContent/formInput";

import useStyles from "./styles";

const InitialData = {
  name: ""
};

const CategoryDialog = props => {
  const classes = useStyles();

  const { open, handleConfirm, handleClose, title, item } = props;

  const [data, setData] = useState(InitialData);

  useEffect(() => {
    if (item) {
      setData(item);
    } else {
      setData(InitialData);
    }
  }, [item]);

  const handleBlur = (event, type) => {
    let oldValue = data[event.name];
    if (event && oldValue !== event.value && event.value !== "") {
      const newData = {
        ...data
      };
      newData[event.name] = event.value;
      setData(newData);
    }
  };

  const handleAdd = () => {
    handleConfirm(data);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <>
          <DialogContent className={classes.wrapperDialog2}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose(false)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog2}
            >
              {title}
            </Typography>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="name"
              label="Name"
              internal={true}
              value={data.name}
              handleBlur={handleBlur}
            />
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction2}>
            <Button
              onClick={() => handleClose(false)}
              className={classes.buttonOutlined2}
              variant="outlined"
              size="large"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              className={classes.button2}
              color="primary"
              variant="contained"
              size="large"
            >
              {item ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};
export default CategoryDialog;
