import React from "react";
import { withRouter } from "react-router-dom";
import AssetsTable from "./assetsTable";

import AssetsInformation from "./assetsInformation";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useAssetState } from "contexts/assetContext";
import useStyles from "./styles";

const AssetsLayout = (props) => {
  const classes = useStyles();
  const { assetRowId } = useAssetState();

  return (
    <Box className={classes.rootLayout}>
      <Grid container className={classes.gridLayout}>
        <Grid
          item
          xs={12}
          sm={12}
          md={assetRowId ? 9 : 12}
          className={
            (classes.gridItemLayout,
            assetRowId ? classes.gridDividerLayout : null)
          }
        >
          <AssetsTable />
        </Grid>
        {assetRowId && (
          <Grid item xs={12} sm={12} md={3}>
            <AssetsInformation />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default withRouter(AssetsLayout);
