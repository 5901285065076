import React from "react";
import Box from "@material-ui/core/Box";
import QuestionTable from "./questionsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import QuestionsListDialog from "./dialog/questionsListDialog";
import { QuestionProvider } from "contexts/surveyQuestionContext";
import useStyles from "./styles";

const QuestionsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <QuestionProvider>
        <QuestionTable />
        <QuestionsListDialog />
      </QuestionProvider>
    </Box>
  );
};

export default withTableFilterContext(QuestionsList);
