import Joi from "joi-browser";

export const validate = (schema, analizeData, setError) => {
  const options = { abortEarly: false };
  const keyNames = Object.keys(schema);
  let data = {};
  for (let key of keyNames) {
    data[key] = analizeData[key];
  }
  const { error } = Joi.validate(data, schema, options);
  if (!error) {
    setError([]);
    return null;
  }
  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  if (Boolean(errors)) {
    const keyNames = Object.keys(errors);
    const errorArray = [];
    for (let key of keyNames) {
      errorArray.push({ key, message: errors[key] });
    }
    setError(errorArray);
  } else {
    setError([]);
  }
  return errors;
};

export const validateProperty = (
  name,
  value,
  schemaAnalized,
  error,
  setError
) => {
  const obj = { [name]: value };
  const schema = { [name]: schemaAnalized[name] };
  const { error: errors } = Joi.validate(obj, schema);
  let err = [];
  if (Boolean(errors)) {
    err = [...error, { key: name, message: errors.details[0].message }];
    setError(err);
  } else {
    err = error.filter((err) => err.key !== name);
    setError(err);
  }
  return { failed: Boolean(errors), error: err };
};

export const validateJoi = (schema, analizeData, setError) => {
  const options = { abortEarly: false };
  const keyNames = Object.keys(
    schema.describe().children ?? schema.describe().base.children
  );
  let data = {};
  for (let key of keyNames) {
    data[key] = analizeData[key];
  }
  const { error } = schema.validate(data, options);
  if (!error) {
    setError([]);
    return null;
  }
  console.log(error);
  console.log(data);
  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  if (Boolean(errors)) {
    const keyNames = Object.keys(errors);
    const errorArray = [];
    for (let key of keyNames) {
      errorArray.push({ key, message: errors[key] });
    }
    setError(errorArray);
  } else {
    setError([]);
  }
  return errors;
};

export const validatePropertyJoi = (
  name,
  value,
  schemaAnalized,
  error,
  setError
) => {
  const options = { abortEarly: false };
  let data = {};
  data[name] = value;
  const { error: errors } = schemaAnalized.validate(data, options);
  let err = [];
  if (Boolean(errors)) {
    err = [...error, { key: name, message: errors.details[0].message }];
    setError(err);
  } else {
    err = error.filter((err) => err.key !== name);
    setError(err);
  }
  return { failed: Boolean(errors), error: err };
};

export const validatePropertyJoi2 = (
  name,
  name2,
  value,
  schemaAnalized,
  error,
  setError
) => {
  const options = { abortEarly: false };
  let data = {};
  data[name] = value;
  data[name2] = value;
  const { error: errors } = schemaAnalized.validate(data, options);
  let err = [];
  let err2 = [];
  if (Boolean(errors)) {
    err = [...error, { key: name, message: errors.details[0].message }];
    setError(err);
  } else {
    err = error.filter((err) => err.key !== name);
    err2 = err.filter((err2) => err2.key !== name2);
    setError(err2);
  }
  return { failed: Boolean(errors), error: err };
};

export const validatePropertyJoi3 = (
  name,
  name2,
  value,
  value2,
  schemaAnalized,
  error,
  setError
) => {
  const options = { abortEarly: false };
  let data = {};
  data[name] = value;
  data[name2] = value2;
  const { error: errors } = schemaAnalized.validate(data, options);
  let err = [];
  let err2 = [];
  if (Boolean(errors)) {
    err = [...error, { key: name, message: errors.details[0].message }];
    setError(err);
  } else {
    err = error.filter((err) => err.key !== name);
    err2 = err.filter((err2) => err2.key !== name2);
    setError(err2);
  }
  return { failed: Boolean(errors), error: err };
};
