import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { deleteTile } from "services/learnService";
import {
  useLearnPageState,
  useLearnPageDispatch,
  getLearnPageList,
} from "contexts/learnPageContext";
import { useFilterState } from "contexts/filterContext";

import useStyles from "./styles";

const LearnPageDeleteDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const { tileSelected, openLearnDelete } = useLearnPageState();
  const dispatchLearnPage = useLearnPageDispatch();
  const { filters } = useFilterState();

  const handleClose = () => {
    dispatchLearnPage({
      type: "TOGGLE_DELETE",
      openLearnDelete: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      await deleteTile(tileSelected.id);
      const filterData = {
        filters: filters,
      };
      await getLearnPageList(dispatchLearnPage, filterData);
      setIsLoadingDialog(false);
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      console.log(e + "Cannot delete tile");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openLearnDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Learn
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to delete this tile?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`This tile has been deleted.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default LearnPageDeleteDialog;
