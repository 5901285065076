import React from "react";

const AddContactIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_454_3788)">
      <path
        d="M10.9747 1.66663H4.99967C4.08301 1.66663 3.33301 2.41663 3.33301 3.33329V16.6666C3.33301 17.5833 4.08301 18.3333 4.99967 18.3333H14.9997C15.9163 18.3333 16.6663 17.5833 16.6663 16.6666V7.35829C16.6663 6.91663 16.4913 6.49163 16.1747 6.18329L12.1497 2.15829C11.8413 1.84163 11.4163 1.66663 10.9747 1.66663ZM9.99967 8.33329C10.9163 8.33329 11.6663 9.08329 11.6663 9.99996C11.6663 10.9166 10.9163 11.6666 9.99967 11.6666C9.08301 11.6666 8.33301 10.9166 8.33301 9.99996C8.33301 9.08329 9.08301 8.33329 9.99967 8.33329ZM13.333 15H6.66634V14.525C6.66634 13.85 7.06634 13.25 7.68301 12.9833C8.39134 12.675 9.17467 12.5 9.99967 12.5C10.8247 12.5 11.608 12.675 12.3163 12.9833C12.933 13.25 13.333 13.85 13.333 14.525V15Z"
        fill="#4D4D4D"
      />
    </g>
    <defs>
      <clipPath id="clip0_454_3788">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AddContactIcon;
