import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import JobIcon from "@material-ui/icons/Work";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logException } from "components/util/logUtil";
import { getFilterJobs } from "components/util/filterUtil";
import {
  getUserAssignedLocations,
  requestLocationsChanges,
} from "services/teamService";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { validate, validateProperty } from "components/util/validationUtil";

import useStyles from "./styles";

const InitialData = {
  changedJobs: [],
};

const schemaNormal = {
  changedJobs: Joi.array()
    .required()
    .min(1)
    .label("Location")
    .error(() => {
      return {
        message: "Select at least one location.",
      };
    }),
};

const LocationsChangesDialog = (props) => {
  const classes = useStyles();
  const { adding, open, handleClose, userId } = props;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const schema = schemaNormal;
  const [error, setError] = useState([]);
  const [data, setData] = useState(InitialData);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        setIsLoadingJobs(true);
        let resultForRender = [];
        if (adding) {
          resultForRender = await getFilterJobs();
        } else {
          const result = await getUserAssignedLocations(userId);
          const { locations } = result.data.data;
          resultForRender = locations.map((job) => {
            return {
              id: job.id,
              job_description: job.job_description,
              job_number: job.job_number,
            };
          });
        }
        setJobs(resultForRender);
        setIsLoadingJobs(false);
      } catch (e) {
        logException(e, "Cannot load team data");
      }
    };
    if (open) {
      loadJobs();
    } else {
      setData(InitialData);
    }
  }, [open]);

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data, setError);
      const changedJobs = [];
      if (!Boolean(errors)) {
        let dataSent = {};
        setIsLoadingDialog(true);
        data.changedJobs.map((item) => {
          return changedJobs.push(item.id);
        });
        if (adding) {
          dataSent = {
            add_job_ids: changedJobs,
          };
        } else {
          dataSent = {
            remove_job_ids: changedJobs,
          };
        }
        await requestLocationsChanges(userId, dataSent);
        setOpenSuccess(true);
        handleClose();
        setIsLoadingDialog(false);
      }
    } catch (e) {
      console.log("Cannot request changed locations bell");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeJob = (event, value) => {
    const newData = {
      ...data,
      changedJobs: value ? value : [],
    };
    setData(newData);
    validateProperty("changedJobs", value, schema, error, setError);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainer}>
                <JobIcon className={classes.headerIcon} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  Locations
                </Typography>
              </Box>
              <Box className={classes.formContainer}>
                {!isLoadingJobs ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="changedJobs"
                    label={`Locations to ${adding ? "Add" : "Remove"}`}
                    handleChange={handleChangeJob}
                    multiple={true}
                    value={data.changedJobs}
                    error={error}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </Box>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Request
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Request has been sent!`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default LocationsChangesDialog;
