import React, { useState, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import WorkticketInvoiceWGDialog from "pages/workticketPage/workticketInvoiceWGDialog";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const WorkticketInvoiceWG = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  const externalData = workticket?.external?.data
    ? JSON.parse(workticket?.external?.data)
    : null;

  const handleOpenInvoice = () => {
    setIsOpenInvoice(true);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={50} />;
  }

  const activeInvoice = workticket?.external?.finance?.length;
  const activeAction =
    externalData &&
    externalData.Status.Primary === "COMPLETED" &&
    externalData.Status.Extended === "CONFIRMED" &&
    [2, 5].includes(workticket?.status);

  return hasPermission(permissionWorkticket.REQUESTS_INVOICE, permissions) ? (
    <>
      <Box className={classes.containerRating}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <ButtonBase
              color={"secondary"}
              variant={activeInvoice ? "contained" : "outlined"}
              fullWidth={true}
              onClick={handleOpenInvoice}
              className={classNames(classes.button)}
              disableElevation
              disabled={!activeAction}
            >
              {activeInvoice ? "View" : "Create"} Invoice
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      {isOpenInvoice ? (
        <WorkticketInvoiceWGDialog
          workticket={workticket}
          handleClose={() => setIsOpenInvoice(false)}
          open={isOpenInvoice}
        />
      ) : null}
    </>
  ) : null;
};

export default WorkticketInvoiceWG;
