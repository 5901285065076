import React from "react";
import Box from "@material-ui/core/Box";
// import InputLabel from "@material-ui/core/InputLabel";
// import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FormInformation = (props) => {
  const classes = useStyles();
  const { icon, text } = props;

  return (
    <Box className={classes.textInformationContainer}>
      {icon}
      <Typography className={classes.textInformation}>{text}</Typography>
    </Box>
  );
};

export default FormInformation;
