import React, { useState, useEffect, useCallback, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Timer from "components/common/Timer";
import ErrorDialog from "components/ui/dialog/errorDialog";
import LocationIcon from "@material-ui/icons/MyLocation";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import { convertDateToUnix } from "components/util/timeFormat";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  clockInWorkticketNonStrict,
  clockOutWorkticket,
  updateWorkticketStatus,
} from "services/workticketService";

import { getUserSchedules } from "services/userService";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketTimekeepingClock = (props) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [time, setTime] = useState(0);
  const [timeTravel, setTimeTravel] = useState(0);
  const [activeInterval, setActiveInterval] = useState(0);
  const [activeTravelInterval, setTravelActiveInterval] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [disabledTravel, setDisabledTravel] = useState(false);
  const [hasGeo, setHasGeo] = useState(false);
  const [positionLocation, setPositionLocation] = useState(null);
  const [currentClock, setCurrentClock] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const { role } = globalUi;

  const { workticket, isLoading } = stateContext ? stateContext : null;

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setHasGeo(true);
          setPositionLocation(position);
        }
      } else {
        setHasGeo(false);
      }
    };
    executeGeo();
  }, []);

  const clockStart = useCallback(
    async (entry) => {
      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
      setCurrentClock(entry);
      const start = convertDateToUnix(entry.clock_in);
      setDisabledTravel(true);
      const interval = setInterval(() => {
        setTime(Date.now() - start);
      }, 1000);
      setActiveInterval(interval);

      // Change status of workticket to In Progress if Not Started
      if (workticket.status === 0) {
        const data = {
          status: 1,
        };
        workticket.status = data.status;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketStatus(workticket.id, data);
      }
    },
    [workticket, dispatchContext, dispatchGlobalUi]
  );

  const clockStartTravel = useCallback(
    async (entry) => {
      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
      setCurrentClock(entry);
      const start = convertDateToUnix(entry.drive_in);
      setDisabled(true);
      const interval = setInterval(() => {
        setTimeTravel(Date.now() - start);
      }, 1000);
      setTravelActiveInterval(interval);

      // Change status of workticket to In Progress if Not Started
      if (workticket.status === 0) {
        try {
          const data = {
            status: 1,
          };
          workticket.status = data.status;
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: workticket,
          });
          await updateWorkticketStatus(workticket.id, data);
        } catch (e) {
          console.log("Cannot update workticket status");
        }
      }
    },
    [workticket, dispatchContext, dispatchGlobalUi]
  );

  const positionData = (drive) => {
    return {
      drive,
      latitude: positionLocation ? positionLocation.coords.latitude : null,
      longitude: positionLocation ? positionLocation.coords.longitude : null,
    };
  };

  useEffect(() => {
    // Clock in verification on this workticket
    const runningClocks = async () => {
      if (!isLoading) {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          const result = await getUserSchedules(user.id);
          const data = result.data.data;
          const entry = data.entries.find(
            (item) =>
              item.workticket.id === workticket.id &&
              !item.clock_hours &&
              item.user.id === user.id
          );

          if (entry && entry.clock_in && entry.user.id === user.id) {
            clockStart(entry);
          }
          if (entry && entry.drive_in && entry.user.id === user.id) {
            clockStartTravel(entry);
          }
        } catch (e) {
          logException(e, "No timekeeping");
        }
      }
    };
    runningClocks();
  }, [isLoading, workticket.id, clockStart, clockStartTravel]);

  useEffect(() => {
    return () => {
      clearInterval(activeInterval);
      clearInterval(activeTravelInterval);
    };
  }, [activeInterval, activeTravelInterval]);

  const handleStart = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserSchedules(user.id);

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }
    // const resultSchedule = await getWorkticketSchedule(workticket.id);

    // let scheduleId = 0;
    // if (!workticket.is_strict_scheduling) {
    //   validSchedule = true;
    // }
    // if (workticket.is_strict_scheduling && resultSchedule.data.data.schedules) {
    //   resultSchedule.data.data.schedules.map((schedule) => {
    //     const minutesStart = MinutesFromNowStandard(schedule.start_date);
    //     const minutesEnd = MinutesFromNowStandard(schedule.end_date);
    //     const userSchedule = schedule.users.find(
    //       (uSchedule) => uSchedule.id === user.id
    //     );
    //     if (minutesStart >= -7 && minutesEnd <= 0 && userSchedule) {
    //       validSchedule = true;
    //       scheduleId = schedule.id;
    //     }
    //     return true;
    //   });
    // }

    // if (!validSchedule) {
    //   setMsgError("User does not have a schedule.");
    //   setOpenError(true);
    //   return false;
    // }

    // if (workticket.is_strict_scheduling) {
    //   const data = positionData(0);
    //   const result = await clockInWorkticketStrict(
    //     workticket.id,
    //     scheduleId,
    //     data
    //   );
    //   const entry = result.data.data.entry;
    //   clockStart(entry);
    // } else {
    try {
      const data = positionData(0);
      const result = await clockInWorkticketNonStrict(workticket.id, data);
      console.log(result, result);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        clockStart(entry);
      }
    } catch (e) {
      setMsgError(e.response.data.message);
      setOpenError(true);
    }
  };
  //};

  const handleStartTravel = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserSchedules(user.id);

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }
    // const resultSchedule = await getWorkticketSchedule(workticket.id);
    // let validSchedule = false;
    // let scheduleId = 0;
    // if (!workticket.is_strict_scheduling) {
    //   validSchedule = true;
    // }
    // if (workticket.is_strict_scheduling && resultSchedule.data.data.schedules) {
    //   resultSchedule.data.data.schedules.map((schedule) => {
    //     const minutesStart = MinutesFromNowStandard(schedule.start_date);
    //     const minutesEnd = MinutesFromNowStandard(schedule.end_date);
    //     const userSchedule = schedule.users.find(
    //       (uSchedule) => (uSchedule.id = user.id)
    //     );
    //     if (minutesStart >= -7 && minutesEnd <= 0 && userSchedule) {
    //       validSchedule = true;
    //       scheduleId = schedule.id;
    //     }
    //     return true;
    //   });
    // }

    // if (!validSchedule) {
    //   setMsgError("User does not have a schedule.");
    //   setOpenError(true);
    //   return false;
    // }

    // if (workticket.is_strict_scheduling) {
    //   const data = positionData(1);
    //   const result = await clockInWorkticketStrict(
    //     workticket.id,
    //     scheduleId,
    //     data
    //   );
    //   const entry = result.data.data.entry;
    //   clockStartTravel(entry);
    // } else {
    try {
      const data = positionData(1);
      const result = await clockInWorkticketNonStrict(workticket.id, data);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        clockStartTravel(entry);
      }
    } catch (e) {
      setMsgError("Cannot clock In");
      setOpenError(true);
    }
  };
  //};

  const handleStop = async () => {
    try {
      if (!currentClock) {
        console.log("No clock is running");
        return;
      }
      clearInterval(activeInterval);
      setTime(0);
      setDisabledTravel(false);
      const data = positionData(0);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }
    } catch (e) {
      logException(e, "Cannot stop clock");
    }
  };

  const handleStopTravel = async () => {
    try {
      if (!currentClock) {
        console.log("No clock is running");
        return;
      }
      clearInterval(activeTravelInterval);
      setTimeTravel(0);
      setDisabled(false);
      const data = positionData(1);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }
    } catch (e) {
      logException(e, "Cannot stop travel clock");
    }
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  return (
    <>
      {hasGeo && <LocationIcon className={classes.locationClock} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Timer
            handleStart={handleStart}
            handleStop={handleStop}
            time={time}
            disabled={
              [2, 3, 4, 5].includes(workticket.status) ? true : disabled
            }
          />
        </Grid>
        {role.is_dispatch ? (
          <Grid item xs={12} sm={6}>
            <Timer
              handleStart={handleStartTravel}
              handleStop={handleStopTravel}
              time={timeTravel}
              travel={true}
              disabled={
                [2, 3, 4, 5].includes(workticket.status) ? true : disabledTravel
              }
            />
          </Grid>
        ) : null}
      </Grid>
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default WorkticketTimekeepingClock;
