import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = apiUrl + "/timekeeping";

export function getTimekeeping(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getTimekeepingSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getTimekeepingOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}&filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function timekeepingExport(data) {
  return http.post(`${apiEndpoint}/export-ids`, data);
}

export function timekeepingExportAll(data) {
  return http.post(`${apiEndpoint}/export-all`, data);
}

export function timekeepingExportHistory() {
  return http.get(`${apiEndpoint}/export-history`);
}
