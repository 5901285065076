import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import useStyles from "./styles";

const servicesStatusOptions = [
  { id: 0, text: "Stopped" },
  { id: 1, text: "Active" },
];

const ServicesStatusChip = (props) => {
  const classes = useStyles();
  const { status } = props;

  const statusSelected = servicesStatusOptions.find(
    (statusItem) => statusItem.id === Number(status)
  );

  if (!statusSelected) {
    return "Error status";
  }

  return (
    <Chip
      label={statusSelected.text}
      className={classNames(
        classes.chip,
        statusSelected.text === "Active"
          ? classes.chipActive
          : classes.chipStopped
      )}
    />
  );
};

export default ServicesStatusChip;
