import React, { useState, useEffect, useCallback, useContext } from "react";
import { getDistance } from "geolib";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import FormSelect from "components/ui/FormContent/formSelect";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import Timer from "components/common/Timer";
import ErrorDialog from "components/ui/dialog/errorDialog";
import LocationIcon from "@material-ui/icons/MyLocation";
import LocationOff from "@material-ui/icons/LocationOff";
import TicketIcon from "@material-ui/icons/Receipt";
import { toast } from "react-toastify";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import { convertDateToUnix } from "components/util/timeFormat";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  clockInWorkticketNonStrict,
  clockOutWorkticket,
  clockPreviewWorkticket,
  updateWorkticketStatus,
} from "services/workticketService";

import { workticketClockOutWG } from "constants.js";
import { getUserSchedulesAll, getUserJobSchedules } from "services/userService";
import { logException } from "components/util/logUtil";
import { DaysFromNowStandard } from "components/util/timeFormat";
import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorkticketTimekeepingClockWG = (props) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [time, setTime] = useState(0);
  const [activeInterval, setActiveInterval] = useState(0);
  const [externalStatus, setExternalStatus] = useState("");
  const [externalNote, setExternalNote] = useState("");
  const [statusUpdateType, setStatusUpdateType] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [hasGeo, setHasGeo] = useState(false);
  const [positionLocation, setPositionLocation] = useState(null);
  const [currentClock, setCurrentClock] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [openValidation, setOpenValidation] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [typeValidation, setTypeValidation] = useState(0);
  const [msgValidation, setMsgValidation] = useState(null);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);
  const [isLoadingClockOut, setIsLoadingClockOut] = useState(false);
  const [isClosingClockOut, setIsClosingClockOut] = useState(false);

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setHasGeo(true);
          setPositionLocation(position);
        }
      } else {
        setHasGeo(false);
      }
    };
    executeGeo();
  }, []);

  const clockStart = useCallback(
    async (entry) => {
      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
      setCurrentClock(entry);
      const start = convertDateToUnix(entry.clock_in);
      const interval = setInterval(() => {
        setTime(Date.now() - start);
      }, 1000);
      setActiveInterval(interval);

      // Change status of workticket to In Progress if Not Started
      if (workticket.status === 0) {
        const data = {
          status: 1,
        };
        workticket.status = data.status;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketStatus(workticket.id, data);
      }
    },
    [workticket, dispatchContext, dispatchGlobalUi]
  );

  const positionData = (drive, skipValFlag) => {
    return {
      drive,
      skipFlag: skipValFlag,
      latitude: positionLocation ? positionLocation.coords.latitude : null,
      longitude: positionLocation ? positionLocation.coords.longitude : null,
    };
  };

  useEffect(() => {
    // Clock in verification on this workticket
    const runningClocks = async () => {
      if (!isLoading) {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          const result = await getUserSchedulesAll(user.id);
          const data = result.data.data;
          const entry = data.entries.find(
            (item) =>
              item.workticket.id === workticket.id &&
              !item.clock_hours &&
              item.user.id === user.id
          );

          if (entry && entry.clock_in && entry.user.id === user.id) {
            clockStart(entry);
          } else {
            if (activeInterval) {
              clearInterval(activeInterval);
            }

            setTime(0);
            setCurrentClock(null);
          }
        } catch (e) {
          logException(e, "No timekeeping");
        }
      }
    };
    if (
      (globalUi.timekeeping && !currentClock) ||
      (!globalUi.timekeeping && currentClock) ||
      currentClock?.workticket?.id !== workticket.id
    ) {
      runningClocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, workticket.id, globalUi.timekeeping, clockStart]);

  useEffect(() => {
    return () => {
      clearInterval(activeInterval);
    };
  }, [activeInterval]);

  const handleStart = async (skipValFlag = 0) => {
    setDisabled(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserJobSchedules(
      user.id,
      workticket.job.id
    );

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }

    try {
      const data = positionData(0, skipValFlag);
      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. This will result in ticket not being verified as completed at site.",
        });
        setTypeValidation(1);
        setOpenValidation(true);
        const dataPreview = {
          latitude: null,
          longitude: null,
          method: "web",
          action: 1,
        };
        clockPreviewWorkticket(workticket.id, dataPreview);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 500 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(1);
          setOpenValidation(true);
          const dataPreview = {
            latitude: data.latitude,
            longitude: data.longitude,
            method: "web",
            action: 1,
          };
          clockPreviewWorkticket(workticket.id, dataPreview);
          return;
        }
      }
      const result = await clockInWorkticketNonStrict(workticket.id, data);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        clockStart(entry);
      }
      setDisabled(false);
      setIsLoadingValidation(false);
    } catch (e) {
      const daysDiff = DaysFromNowStandard(workticket.start_date);
      let errorMsg = e.response.data.message;
      if (daysDiff > 0) {
        errorMsg =
          "This ticket is overdue, and past the acceptable timeframe of completion. Please reach out to Encompass Support to update the status.";
      }
      setMsgError(errorMsg);
      setOpenError(true);
    }
  };

  const handleStop = async (skipValFlag = 0) => {
    try {
      if (!currentClock) {
        toast.warning("No clock is running.");
        return;
      }

      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
        status:
          externalStatus && externalStatus !== ""
            ? externalStatus
            : "Completed",
        resolution: externalNote,
      };

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. This will result in ticket not being verified as completed at site.",
        });
        setTypeValidation(2);
        setOpenValidation(true);
        const dataPreview = {
          latitude: null,
          longitude: null,
          method: "web",
          action: 2,
        };
        clockPreviewWorkticket(workticket.id, dataPreview);
        return;
      }
      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 500 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(2);
          setOpenValidation(true);
          const dataPreview = {
            latitude: data.latitude,
            longitude: data.longitude,
            method: "web",
            action: 2,
          };
          clockPreviewWorkticket(workticket.id, dataPreview);
          return;
        }
      }

      clearInterval(activeInterval);
      setTime(0);

      setDisabled(true);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }

      if (data.status === "Completed") {
        // Mark ticket as work completed
        workticket.status = 4;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
      }
      setDisabled(false);
      setIsLoadingValidation(false);
      setExternalStatus("");
      setExternalNote("");
    } catch (e) {
      logException(e, "Cannot stop clock");
    }
  };

  const handleValidationContinue = async () => {
    setIsLoadingValidation(true);
    if (typeValidation === 1) {
      // Clock In
      await handleStart(1);
    } else if (typeValidation === 2) {
      // Clock Out
      await handleStop(1);
    }
    setOpenValidation(false);
  };

  const handleValidationClose = () => {
    setOpenValidation(false);
    setIsLoadingValidation(false);
    setDisabled(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleChangeStatusWG = (event) => {
    setExternalStatus(event.value);
  };

  const handleChangeNoteWG = (event, value) => {
    setExternalNote(event.value);
  };

  const handleComplete = async () => {
    try {
      setIsLoadingClockOut(true);
      await handleStop();
      setOpenClockOut(false);
      setIsLoadingClockOut(false);
    } catch (e) {
      logException(e, "Cannot mark completed");
    }
  };

  const handleClockOutOpen = () => {
    setExternalStatus("");
    setExternalNote("");
    setOpenClockOut(true);
    setDisabled(true);
    setStatusUpdateType(1);
  };

  const handleClockOutClose = () => {
    setOpenClockOut(false);
    setExternalStatus("");
    setExternalNote("");
    setStatusUpdateType(null);
    setIsClosingClockOut(false);
    setDisabled(false);
  };

  const handleClockOutConfirmationClose = () => {
    setIsClosingClockOut(true);
  };

  const handleClockOutConfirmationRevert = () => {
    setIsClosingClockOut(false);
  };

  return (
    <>
      {hasGeo && <LocationIcon className={classes.locationClock} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Timer
            handleStart={() => handleStart()}
            handleStop={handleClockOutOpen}
            time={time}
            disabled={
              [2, 3, 4, 5].includes(workticket.status) ||
              workticket?.external?.approval === 0
                ? true
                : disabled
            }
          />
        </Grid>
      </Grid>
      <Dialog
        open={openValidation}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingValidation ? (
            <>
              <DialogCloseIcon handleClose={handleValidationClose} />
              <DialogTitle
                title={`Location ${msgValidation?.title}`}
                icon={<LocationOff fontSize="large" />}
              />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  {msgValidation?.content}
                </Typography>
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingValidation ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleValidationClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="secondary"
            >
              Go Back
            </Button>
            <Button
              onClick={handleValidationContinue}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Continue
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openClockOut}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingClockOut ? (
            <>
              {!isClosingClockOut ? (
                <DialogCloseIcon
                  handleClose={handleClockOutConfirmationClose}
                />
              ) : null}
              <DialogTitle
                title={`Workticket - ${workticket.number}`}
                icon={<TicketIcon />}
              />
              {!isClosingClockOut ? (
                <Box className={classes.wrapperDialogStatus}>
                  {statusUpdateType === 1 ? (
                    <>
                      <Box className={classes.wrapperDialogValidation}>
                        <Typography variant="body2">
                          Please update the status of the workticket
                        </Typography>
                      </Box>
                      <FormSelect
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="external_status_clock_out"
                        label="Select status"
                        options={workticketClockOutWG}
                        value={externalStatus}
                        handleBlur={handleChangeStatusWG}
                        changeValueControlled={true}
                      />
                    </>
                  ) : null}
                  {statusUpdateType === 2 ? (
                    <Box className={classes.wrapperDialogValidation}>
                      <Typography variant="body2">
                        Would you like to add a note, before marking the
                        workticket as completed?
                      </Typography>
                    </Box>
                  ) : null}

                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_note"
                    label="Note"
                    placeholder="Type note here"
                    value={externalNote}
                    multiline={true}
                    rows={4}
                    handleBlur={handleChangeNoteWG}
                  />
                </Box>
              ) : (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Are you sure you want to cancel, the status of the
                      workticket won't be updated?
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingClockOut && !isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleComplete}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingClockOut || (statusUpdateType === 1 && !externalStatus)
              }
            >
              Submit
            </Button>
          </DialogActions>
        ) : null}
        {isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClockOutConfirmationRevert}
              className={classes.buttonOutlinedDialog}
              color="secondary"
              variant="outlined"
              size="large"
            >
              No
            </Button>
            <Button
              onClick={handleClockOutClose}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Yes
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default WorkticketTimekeepingClockWG;
