import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Joi from "joi-browser";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logException } from "components/util/logUtil";
import FormInput from "components/ui/FormContent/formInput";
import { sendAnnouncement, getTeam } from "services/ringBellService";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useRingBellState,
  useRingBellDispatch,
} from "contexts/ringBellContext";
import { hasPermission, permissionTalent } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import { useFilterState, getFilterData } from "contexts/filterContext";

import useStyles from "./styles";

const schema = {
  assignedTo: Joi.array()
    .required()
    .min(1)
    .label("Team")
    .error(() => {
      return {
        message: "Select at least one team member.",
      };
    }),
  message: Joi.string()
    .min(1)
    .required()
    .label("Message")
    .error(() => {
      return {
        message: "Message is required.",
      };
    }),
};

const InitialData = {
  assignedTo: [],
  message: "",
};

const AnnouncementActionDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [error, setError] = useState([]);
  const [data, setData] = useState(InitialData);
  const [users, setUsers] = useState(null);
  const [usersNoPhone, setUsersNoPhone] = useState(null);
  const { openAnnouncement, ringBellUserId } = useRingBellState();
  const dispatchRingBell = useRingBellDispatch();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { selected } = tableUi;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const filterState = useFilterState();

  useEffect(() => {
    if (!ringBellUserId) {
      if (!openAnnouncement) {
        setData(InitialData);
      } else {
        let selectedUsers = [];
        if (selected.length > 0 && openAnnouncement) {
          selectedUsers = users.filter((item) => selected.includes(item.id));
        } else {
          selectedUsers = [];
        }
        const selectedUsersNoPhone = selectedUsers.filter(
          (item) => selected.includes(item.id) && !item.phone
        );
        const newData = {
          ...data,
          assignedTo: selectedUsers ?? [],
        };
        setData(newData);
        setUsersNoPhone(selectedUsersNoPhone);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAnnouncement, ringBellUserId]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setIsLoadingUsers(true);
        const filterData = getFilterData(filterState);
        if (hasPermission(permissionTalent.VIEW_DEPARTMENT, permissions)) {
          filterData.filters = filterData.filters = [
            ...filterData.filters,
            {
              filter: "skipPositionConditions",
              group: "skipPositionConditions",
              values: [{ label: "", value: 1 }],
            },
          ];
        }
        const result = await getTeam(filterData);
        setUsers(result.data.data.list);
        setIsLoadingUsers(false);
      } catch (e) {
        logException(e, "Cannot load team data");
      }
    };
    if (!ringBellUserId) {
      loadUsers();
    }
  }, [ringBellUserId, filterState]);

  const handleClose = () => {
    dispatchRingBell({
      type: "TOGGLE_ANNOUNCEMENT",
      openAnnouncement: false,
    });
  };

  const handleConfirm = async () => {
    try {
      const errors = validate(schema, data);
      if (!Boolean(errors)) {
        setIsLoadingDialog(true);
        const assignedToId = data.assignedTo.map((item) => {
          return item.id;
        });
        const dataSend = {
          user_ids: assignedToId,
          message: data.message,
        };
        await sendAnnouncement(dataSend);
        setOpenSuccess(true);
        dispatchTable({ type: "SET_SELECTED", selected: [] });
        handleClose();
        setIsLoadingDialog(false);
      }
    } catch (e) {
      logException(e, "Cannot send the announcement");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeAssignedTo = (event, value) => {
    const newData = {
      ...data,
      assignedTo: value ? value : [],
    };
    setData(newData);
    validateProperty("assignedTo", value, schema);
  };

  const validateProperty = (name, value, schemaAnalyzed) => {
    const obj = { [name]: value };
    const schema = { [name]: schemaAnalyzed[name] };
    const { error: errors } = Joi.validate(obj, schema);
    if (Boolean(errors)) {
      setError([...error, { key: name, message: errors.details[0].message }]);
    } else {
      setError(error.filter((err) => err.key !== name));
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};
    switch (event.name) {
      case "message":
        newData = {
          ...data,
          message: event.value,
        };
        validateProperty("message", value, schema);
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const validate = (schema, analyzeData) => {
    const options = { abortEarly: false };
    const keyNames = Object.keys(schema);
    let data = {};
    for (let key of keyNames) {
      data[key] = analyzeData[key];
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (Boolean(errors)) {
      const keyNames = Object.keys(errors);
      const errorArray = [];
      for (let key of keyNames) {
        errorArray.push({ key, message: errors[key] });
      }
      setError(errorArray);
    } else {
      setError([]);
    }
    return errors;
  };

  return (
    <>
      <Dialog
        open={openAnnouncement}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <MessageIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Message
              </Typography>
              <Box className={classes.formContainer}>
                {!isLoadingUsers ? (
                  <FormSelectChipsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={users}
                    name="assignedTo"
                    error={error}
                    label="Team Member(s)"
                    internal={true}
                    handleChange={handleChangeAssignedTo}
                    multiple={true}
                    value={data.assignedTo}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}

                {usersNoPhone && usersNoPhone.length ? (
                  <Alert severity="warning" className={classes.infoAlert}>
                    The following team members do not have a phone number:
                    {usersNoPhone.map((user, index) => {
                      return ` ${user.first_name} ${user.last_name}${
                        index + 1 !== usersNoPhone.length ? ", " : "."
                      }`;
                    })}
                  </Alert>
                ) : null}

                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="message"
                  label="Message"
                  error={error}
                  internal={false}
                  value={data.note}
                  handleBlur={handleBlur}
                  multiline={true}
                  rows={8}
                />
              </Box>
            </DialogContent>

            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Send
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`Announcement has been sent!`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default AnnouncementActionDialog;
