import React, { useMemo, useState, useContext, useEffect } from "react";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import Skeleton from "@material-ui/lab/Skeleton";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { useWorkticketView } from "contexts/workticketViewContext";
import { workticketBillingAP } from "services/workticketService";

const WorkticketBillingPartner = (props) => {
  const [listAP, setListAP] = useState([]);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;
  const { onlyPortfolio } = props;

  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );

  useEffect(() => {
    const loadAP = async () => {
      if (workticket?.id) {
        const resultWorkticketAP = await workticketBillingAP(workticket.id);
        setListAP(resultWorkticketAP.data.data.records);
      }
    };
    if (
      !onlyPortfolio &&
      hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions)
    ) {
      loadAP();
    }
  }, [workticket, permissions, onlyPortfolio]);

  if (!workticket.payment && !workticket.bill) {
    return null;
  }

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={10} />;
  }

  if (!permOnlyAP) {
    return null;
  }

  return (
    <Box>
      {workticket.payment ? (
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="workticket_payment"
          label="Payment"
          value={`$ ${Number(workticket.payment.total_amount).toFixed(2)}`}
          styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
          readonly={true}
        />
      ) : null}
      {listAP.map((item) => (
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="workticket_payment"
          label="Payment"
          value={`$ ${Number(item.total_amount).toFixed(2)}`}
          styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
          readonly={true}
        />
      ))}
    </Box>
  );
};

export default WorkticketBillingPartner;
