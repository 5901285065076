export const orderList = list => {
  return list.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }
    if (a.date < b.date) {
      return -1;
    }
    return 0;
  });
};

/**
 * Compares an array of numbers. If number is already in array,
 * it is removed.  If it is not in array, it is added.  New array is returned.
 */
export const compareArrayAddRemove = (arr, id) => {
  const arrIndex = arr.indexOf(id);
  let newArr = [];

  if (arrIndex === -1) {
    newArr = newArr.concat(arr, id);
  } else if (arrIndex === 0) {
    newArr = newArr.concat(arr.slice(1));
  } else if (arrIndex === arr.length - 1) {
    newArr = newArr.concat(arr.slice(0, -1));
  } else if (arrIndex > 0) {
    newArr = newArr.concat(arr.slice(0, arrIndex), arr.slice(arrIndex + 1));
  }
  return newArr;
};
