import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import UserBreadcrumb from "./userBreadcrumb";
import UserInformation from "./userInformation";
import UserSidebar from "./userSidebar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import PhotoIcon from "@material-ui/icons/Photo";
import { getUserDetail } from "services/superService";
import { useUserView } from "contexts/userViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

function useUsersItem(userId) {
  return useQuery(["users", userId], async () => {
    if (userId === "new") {
      return {};
    }
    const { data } = await getUserDetail(userId);
    return data;
  });
}

const UserLayout = (props) => {
  const classes = useStyles();
  const [valueBottomNavigation, setBottomNavigation] = useState("info");
  const [isMobile, setIsMobile] = useState(false);
  const context = useUserView();
  const dispatchContext = context[1];

  const { data, isLoading } = useUsersItem(props.id);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    window.addEventListener("resize", resize.bind(this));
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
  };

  useEffect(() => {
    const loadUser = async () => {
      try {
        dispatchContext({
          type: "SET_USER",
          user: props.id !== "new" ? data.data.user : "new",
        });
        dispatchContext({
          type: "SET_LOADING",
          isLoading: false,
        });
      } catch (e) {
        logException(e, "Cannot load user data");
      }
    };
    if (!isLoading) {
      loadUser();
    }
  }, [props.id, dispatchContext, data, isLoading]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {<UserBreadcrumb />}
      <Grid container className={classes.root}>
        {!isMobile || (isMobile && valueBottomNavigation === "info") ? (
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.containerBody}>
              <UserInformation {...props} />
            </Box>
          </Grid>
        ) : null}
        {!isMobile || (isMobile && valueBottomNavigation === "photo") ? (
          <Grid item xs={12} sm={12} md={4}>
            <Box className={classes.containerSidebar}>
              <UserSidebar />
            </Box>
          </Grid>
        ) : null}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="photo" icon={<PhotoIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default UserLayout;
