import React from "react";
import {
  getTilesList,
  getTilesListSearch,
  getTilesListOffset,
} from "services/learnService";
import { merge } from "lodash";

const defaultState = {
  learnPageLoading: true,
  tileSelected: null,
  learnPageList: [],
  learnPageCount: [],
  openTileManage: false,
  openTileManageCategory: false,
  reloadCategories: false,
  openLearnDelete: false,
  openViewVideo: false,
  viewURL: "",
};

const LearnPageStateContext = React.createContext();
const LearnPageDispatchContext = React.createContext();

function learnPageReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        learnPageLoading: action.learnPageLoading,
      };
    case "SET_LEARN_PAGE_LIST":
      return {
        ...state,
        learnPageList: action.learnPageList,
      };
    case "SET_LEARN_PAGE_COUNT":
      return {
        ...state,
        learnPageCount: action.learnPageCount,
      };
    case "SET_TILE_SELECTED":
      return {
        ...state,
        tileSelected: action.tileSelected,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openTileManage: action.openTileManage,
      };
    case "TOGGLE_MANAGE_CATEGORY":
      return {
        ...state,
        openTileManageCategory: action.openTileManageCategory,
      };
    case "TOGGLE_RELOAD_CATEGORIES":
      return {
        ...state,
        reloadCategories: action.reloadCategories,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openLearnDelete: action.openLearnDelete,
      };
    case "TOGGLE_VIEW_VIDEO":
      return {
        ...state,
        openViewVideo: action.openViewVideo,
      };
    case "SET_URL":
      return {
        ...state,
        viewURL: action.viewURL,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LearnPageProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(learnPageReducer, mergedState);
  return (
    <LearnPageStateContext.Provider value={state}>
      <LearnPageDispatchContext.Provider value={dispatch}>
        {children}
      </LearnPageDispatchContext.Provider>
    </LearnPageStateContext.Provider>
  );
}

function useLearnPageState() {
  const context = React.useContext(LearnPageStateContext);
  if (context === undefined) {
    throw new Error(
      "useLearnPageState must be used within a LearnPageProvider"
    );
  }
  return context;
}

function useLearnPageDispatch() {
  const context = React.useContext(LearnPageDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLearnPageDispatch must be used within a LearnPageProvider"
    );
  }
  return context;
}

async function getLearnPageList(dispatch, filterData, noLoading) {
  try {
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        learnPageLoading: true,
      });
    }
    const result = await getTilesList(filterData);
    dispatch({
      type: "SET_LEARN_PAGE_LIST",
      learnPageList: [...result.data.data.results],
    });
    dispatch({
      type: "SET_LEARN_PAGE_COUNT",
      learnPageCount: result.data.data.count,
    });
    if (!noLoading) {
      dispatch({
        type: "SET_LOADING",
        learnPageLoading: false,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function getLearnPageListDataSet(
  learnPageList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getTilesListOffset(offset, filterData);
    const updateData = learnPageList.concat(result.data.data.results);
    dispatch({
      type: "SET_LEARN_PAGE_LIST",
      learnPageList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchLearnPageList(search, filterData) {
  try {
    const result = await getTilesListSearch(search, filterData);
    return result.data.data.results;
  } catch (error) {
    console.log(error);
  }
}

export {
  LearnPageProvider,
  useLearnPageState,
  useLearnPageDispatch,
  getLearnPageListDataSet,
  getLearnPageList,
  searchLearnPageList,
};
