export function XIcon() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2 1.74541L10.8 11.3454"
        stroke="#979797"
        strokeLinecap="square"
      />
      <path
        d="M10.8 1.74541L1.2 11.3454"
        stroke="#979797"
        strokeLinecap="square"
      />
    </svg>
  );
}
