import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  paper: {
    padding: theme.spacing(3, 2),
    minHeight: 400,
  },
  title: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  stepContainer: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  stepText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    position: "absolute",
    right: 30,
    top: 5,
  },
  optionContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
  },
  optionPaper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 170,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 170,
    },
  },
  optionBox: {
    textAlign: "center",
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px",
  },
  optionBoxContent: {
    fontSize: 10,
  },
  buttonBegin: {
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4d90b2",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    color: "#4f98bc",
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  buttonNext: {
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4d90b2",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  formContainer: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 5,
  },
  wrapperSuccess: {
    textAlign: "center",
  },
  wrapperActionSuccess: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  titleSuccess: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentSuccess: {
    fontSize: 14,
  },
  iconSuccess: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 11,
    height: 11,
  },
  titleMultiRequest: {
    fontSize: 20,
    fontWeight: "bold",
    cursor: "pointer",
    marginBottom: 30,
    marginTop: 25,
    marginLeft: -25,
  },
  iconMultiRequest: {
    width: 38,
    height: 38,
    color: "#0b417e",
    position: "relative",
    top: 8,
  },
  wrapperAdd: {
    textAlign: "center",
  },
  wrapperActionAdd: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  titleAdd: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentAdd: {
    fontSize: 14,
  },
  itemRequest: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: -25,
  },
  iconItemRequest: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
    position: "relative",
    top: 8,
  },
  minLabel: {
    textAlign: "right",
    position: "relative",
    top: -20,
    height: 20,
  },
}));

export default useStyles;
