import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CalendarToolbar from "components/ui/core/calendarToolbar";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import {
  getDiffDateFormatMinutes,
  convertDateToCalendar,
  formatAppDateTZ,
} from "components/util/timeFormat";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationIcon from "@material-ui/icons/LocationOn";
import SummaryIcon from "@material-ui/icons/ShortText";
import DescriptionIcon from "@material-ui/icons/Subject";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SchedulingIcon from "@material-ui/icons/EventAvailable";

import Skeleton from "@material-ui/lab/Skeleton";
import {
  saveWorkticketSchedule,
  updateWorkticketSchedule,
  deleteWorkticketSchedule,
  assignUserSchedule,
  removeUserSchedule,
} from "services/workticketService";

import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const localizer = momentLocalizer(moment);

const WorkticketSchedule = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions, users } = globalUi;
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState([]);
  const [date, setDate] = useState(moment());
  const [timeStart, setTimeStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [timeEnd, setTimeEnd] = useState(moment());
  const [assignedTo, setAssignedTo] = useState([]);
  const [assignedToList, setAssignedToList] = useState([]);
  const [editAssignedTo, setEditAssignedTo] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduleSelected, setScheduleSelected] = useState([]);
  const [scheduleUpdate, setScheduleUpdate] = useState(false);
  const [scheduleHours, setScheduleHours] = useState(0);
  const [scheduleHoursCurrent, setScheduleHoursCurrent] = useState(0);
  const [scheduleHoursUpdate, setScheduleHoursUpdate] = useState(0);
  const [strictScheduling, setStrictScheduling] = useState(false);
  const [error, setError] = useState([]);
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      if (workticket.users) {
        const assignedUsers = workticket.users.map((user) => user.id);
        const assignedUsersList = users.filter((user) =>
          assignedUsers.includes(user.id)
        );
        setEditAssignedTo(assignedUsersList);
        setAssignedTo(assignedUsersList);
        setAssignedToList(assignedUsersList);
      }
    }
  }, [workticket, isLoading, users]);

  useEffect(() => {
    if (!isLoading) {
      const scheduleListUpdate = workticket.schedules.map((schedule) => {
        return {
          id: schedule.id,
          start: convertDateToCalendar(
            schedule.start_date,
            workticket?.job?.timezone
          ),
          end: convertDateToCalendar(
            schedule.end_date,
            workticket?.job?.timezone
          ),
          title: workticket.number,
          users: schedule.users,
          strictScheduling: Number(schedule.strict_scheduling),
        };
      });

      setScheduleList(scheduleListUpdate);
      setActiveSchedule(workticket.schedules);
    }
  }, [isLoading, workticket]);

  useEffect(() => {
    if (!isLoading) {
      const scheduleHours = activeSchedule
        ? activeSchedule.reduce(
            (hours, schedule) => (hours += parseFloat(schedule.hours)),
            0
          )
        : 0;

      setScheduleHours(scheduleHours);
    }
  }, [isLoading, activeSchedule]);

  useEffect(() => {
    const schedulingHours = getDiffDateFormatMinutes(
      date,
      timeStart,
      dateEnd,
      timeEnd
    );
    setScheduleHoursCurrent(schedulingHours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, dateEnd, timeStart, timeEnd]);

  useEffect(() => {
    if (!openDialog) {
      setDate(moment());
      setTimeStart(moment());
      setDateEnd(moment());
      setTimeEnd(moment());
      setScheduleHoursUpdate(0);
      setScheduleHoursCurrent(0);
      setAssignedTo([]);
      setStrictScheduling(false);
      if (workticket.users) {
        const assignedUsers = workticket.users.map((user) => user.id);
        setEditAssignedTo(
          users.filter((user) => assignedUsers.includes(user.id))
        );
        setAssignedTo(users.filter((user) => assignedUsers.includes(user.id)));
      } else {
        setEditAssignedTo([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleSelect = ({ start, end }) => {
    if (!hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions)) {
      return;
    }

    setDate(moment(start));
    setTimeStart(moment(start));
    setDateEnd(moment(end));
    setTimeEnd(moment(end));
    setScheduleHoursUpdate(0);
    setScheduleUpdate(false);
    setStrictScheduling(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const handleSaveSchedule = async () => {
    try {
      const assignedUser = [];
      assignedTo.map((item) => {
        return assignedUser.push(item.id);
      });

      if (!assignedUser.length) {
        setError([
          {
            key: "users",
            message: "Please select at least one user to assign.",
          },
        ]);
        return;
      }

      const tz = workticket?.job?.timezone;

      const startDate = `${formatAppDateTZ(
        `${date.format("YYYY-MM-DD")} ${timeStart.format("HH:mm:ss")}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      const endDate = `${formatAppDateTZ(
        `${dateEnd.format("YYYY-MM-DD")} ${timeEnd.format("HH:mm:ss")}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      const data = {
        start_date: startDate,
        end_date: endDate,
        strict_scheduling: strictScheduling ? 1 : 0,
        user_ids: assignedUser,
      };
      const result = await saveWorkticketSchedule(workticket.id, data);
      const scheduleListUpdate = result.data.data.workticket.schedules.map(
        (schedule) => {
          return {
            id: schedule.id,
            start: convertDateToCalendar(
              schedule.start_date,
              workticket?.job?.timezone
            ),
            end: convertDateToCalendar(
              schedule.end_date,
              workticket?.job?.timezone
            ),
            title: workticket.number,
            users: schedule.users,
            strictScheduling: Number(schedule.strict_scheduling),
          };
        }
      );
      setScheduleList(scheduleListUpdate);
      setActiveSchedule(result.data.data.workticket.schedules);
      setOpenDialog(false);
    } catch (e) {
      logException(e, "Cannot create schedule");
    }
  };

  const handleUpdateSubmitSchedule = async () => {
    try {
      const tz = workticket?.job?.timezone;

      const startDate = `${formatAppDateTZ(
        `${moment(date).format("YYYY-MM-DD")} ${moment(timeStart).format(
          "HH:mm:ss"
        )}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      const endDate = `${formatAppDateTZ(
        `${moment(dateEnd).format("YYYY-MM-DD")} ${moment(timeEnd).format(
          "HH:mm:ss"
        )}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      const data = {
        start_date: startDate,
        end_date: endDate,
        strict_scheduling: strictScheduling ? 1 : 0,
      };
      await updateWorkticketSchedule(workticket.id, scheduleSelected.id, data);

      // Remove update schedule
      const scheduleListUpdate = scheduleList.filter(
        (schedule) => schedule.id !== scheduleSelected.id
      );

      // Insert updated schedule
      scheduleListUpdate.push({
        id: scheduleSelected.id,
        start: convertDateToCalendar(
          data.start_date,
          workticket?.job?.timezone
        ),
        end: convertDateToCalendar(data.end_date, workticket?.job?.timezone),

        title: workticket.number,
        users: assignedTo.length ? assignedTo : editAssignedTo,
        strictScheduling: Number(strictScheduling),
      });

      setScheduleList(scheduleListUpdate);

      if (assignedTo.length) {
        // Track changes made to assignments
        const assignedToId = [];
        const editAssignedToId = [];
        const removeAssigned = [];
        const addAssigned = [];

        assignedTo.map((item) => {
          return assignedToId.push(item.id);
        });
        editAssignedTo.map((item) => {
          return editAssignedToId.push(item.id);
        });

        assignedTo.map((item) => {
          if (!editAssignedToId.includes(item.id)) {
            addAssigned.push(item.id);
          }
          return true;
        });

        editAssignedTo.map((item) => {
          if (!assignedToId.includes(item.id)) {
            removeAssigned.push(item.id);
          }
          return true;
        });

        addAssigned.forEach(async (user) => {
          await assignUserSchedule(workticket.id, scheduleSelected.id, {
            user_id: user,
          });
        });

        removeAssigned.forEach(async (user) => {
          await removeUserSchedule(workticket.id, scheduleSelected.id, user);
        });

        setEditAssignedTo(assignedTo);
      }

      setOpenDialog(false);
      setOpenViewDialog(false);
      setScheduleSelected([]);
    } catch (e) {
      logException(e, "Cannot update schedule");
    }
  };

  const handleSelectEvent = (event) => {
    setScheduleSelected(event);
    const schedulingHours = getDiffDateFormatMinutes(
      moment(event.start),
      moment(event.start),
      moment(event.end),
      moment(event.end)
    );
    setScheduleHoursUpdate(schedulingHours);
    setOpenViewDialog(true);
  };

  const handleScheduleAdd = () => {
    setScheduleUpdate(false);
    setOpenDialog(true);
  };

  const handleChangeAssignedTo = (event, value) => {
    setAssignedTo(value);
    setError([]);
  };

  const handleUpdateSchedule = async () => {
    if (!hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions)) {
      return;
    }
    setDate(scheduleSelected.start);
    setTimeStart(scheduleSelected.start);
    setDateEnd(scheduleSelected.end);
    setTimeEnd(scheduleSelected.end);
    setStrictScheduling(Boolean(scheduleSelected.strictScheduling));
    const usersList = users;
    const selectedUsers = [];
    scheduleSelected.users.map((user) => {
      return selectedUsers.push(user.id);
    });

    setEditAssignedTo(
      usersList.filter((item) => selectedUsers.includes(item.id))
    );
    setAssignedTo(usersList.filter((item) => selectedUsers.includes(item.id)));
    setScheduleUpdate(true);
    setOpenDialog(true);
  };

  const handleDeleteSchedule = async () => {
    if (!hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions)) {
      return;
    }
    try {
      await deleteWorkticketSchedule(workticket.id, scheduleSelected.id);
      const scheduleListUpdate = scheduleList.filter(
        (item) => item.id !== scheduleSelected.id
      );

      setScheduleList(scheduleListUpdate);
      setScheduleHours(scheduleHours - scheduleHoursUpdate);
      setOpenViewDialog(false);
      setScheduleSelected([]);
    } catch (e) {
      logException(e, "Cannot delete schedule");
    }
  };

  const handleStrictScheduleChange = (event) => {
    setStrictScheduling(event.target.checked);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={100}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
    );
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    return { className: classes.eventSchedule };
  };

  return (
    <Box className={classes.containerSchedule}>
      <Box className={classes.containerScheduleCalendar}>
        <Calendar
          localizer={localizer}
          events={scheduleList}
          selectable={true}
          scrollToTime={new Date()}
          defaultView={window.innerWidth <= 760 ? Views.DAY : Views.WEEK}
          onSelectSlot={handleSelect}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
          components={{
            toolbar: CalendarToolbar,
          }}
        />
      </Box>
      {hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions) && (
        <Box
          className={classNames(classes.boxActions, classes.boxActionsSchedule)}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonAdd)}
            onClick={handleScheduleAdd}
          >
            Schedule
          </Button>
        </Box>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          <>
            <Grid container spacing={2} className={classes.dialogHeader}>
              <Grid item md={12}>
                <Box className={classes.formIconDialog}>
                  <TicketIcon className={classes.iconDialog} />
                </Box>
                <Typography
                  variant="h4"
                  className={classes.formTitleDialog}
                  gutterBottom
                >
                  Workticket {workticket.number}
                </Typography>
                <Typography
                  variant="h5"
                  className={classes.formSubtitleDialog}
                  gutterBottom
                >
                  {!scheduleUpdate ? "Enter" : "Update"} schedule
                </Typography>
              </Grid>
            </Grid>

            <Box className={classes.formContainerDialog}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <FormInputDateAdo
                    name="date"
                    label="Start Date"
                    value={date}
                    handleChange={setDate}
                    internal={true}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormInputTimeAdo
                    name="time_start"
                    label="Start Time"
                    value={timeStart}
                    handleChange={setTimeStart}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormInputDateAdo
                    name="date_end"
                    label="End Date"
                    value={dateEnd}
                    handleChange={setDateEnd}
                    internal={true}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <FormInputTimeAdo
                    name="time_end"
                    label="End Time"
                    value={timeEnd}
                    handleChange={setTimeEnd}
                  />
                </Grid>
              </Grid>
              <FormSelectChipsAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={assignedToList}
                name="users"
                label="Assigned To"
                internal={true}
                handleChange={handleChangeAssignedTo}
                value={assignedTo}
                multiple={true}
                error={error}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={strictScheduling}
                    onChange={handleStrictScheduleChange}
                    value={true}
                    color="secondary"
                  />
                }
                label={`Only allow assignee to clock in during schedule hours`}
                className={classes.containerScheduleOptions}
              />
              {workticket.quote ? (
                <Box className={classes.formContainerInformation}>
                  <Typography variant="h4" gutterBottom>
                    [
                    {(
                      scheduleHours -
                      scheduleHoursUpdate +
                      scheduleHoursCurrent
                    ).toFixed(2)}{" "}
                    {workticket.quote.hours
                      ? `/ ${workticket.quote.hours}`
                      : ``}
                    ] Hours
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </>
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          {!scheduleUpdate ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, {
                [classes.buttonPrimary]: scheduleHoursCurrent > 0,
              })}
              onClick={handleSaveSchedule}
              disabled={scheduleHoursCurrent <= 0 ? true : false}
            >
              Enter
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonPrimary)}
              onClick={handleUpdateSubmitSchedule}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openViewDialog}
        onClose={handleCloseViewDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Box className={classes.scheduleViewOptions}>
            {hasPermission(
              permissionWorkticket.ASSIGN_SCHEDULE,
              permissions
            ) ? (
              <>
                <IconButton
                  aria-label="close"
                  className={classes.wrapperIcon}
                  onClick={handleDeleteSchedule}
                >
                  <DeleteIcon className={classes.iconOptions} />
                </IconButton>
                <IconButton
                  aria-label="close"
                  className={classes.wrapperIcon}
                  onClick={handleUpdateSchedule}
                >
                  <EditIcon className={classes.iconOptions} />
                </IconButton>
              </>
            ) : null}
            <IconButton
              aria-label="close"
              className={classes.wrapperIcon}
              onClick={handleCloseViewDialog}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>
          </Box>
          <>
            <Box className={classes.scheduleViewContainer}>
              <List component="div" aria-label="main schedule-view">
                <ListItem>
                  <ListItemIcon>
                    <span className={classes.scheduleBadge}></span>
                  </ListItemIcon>
                  <Box>
                    <Typography
                      variant="h3"
                      className={classes.scheduleViewTitle}
                      gutterBottom
                    >
                      {workticket.number}
                    </Typography>
                    <Typography
                      variant="h4"
                      className={classes.scheduleViewDateTitle}
                      gutterBottom
                    >
                      {moment(scheduleSelected.start).format("LLLL")} -{" "}
                      {moment(scheduleSelected.end).format("LT")}
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocationIcon className={classes.iconOptions} />
                  </ListItemIcon>
                  <ListItemText primary={workticket.job.job_description} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SummaryIcon className={classes.iconOptions} />
                  </ListItemIcon>
                  <ListItemText primary={workticket.subject} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DescriptionIcon className={classes.iconOptions} />
                  </ListItemIcon>
                  <ListItemText primary={workticket.description} />
                </ListItem>
                <ListItem button className={classes.usersWrapper}>
                  <ListItemIcon>
                    <PersonOutlineIcon className={classes.iconOptions} />
                  </ListItemIcon>
                  {scheduleSelected.users &&
                    scheduleSelected.users.map((user) => (
                      <Chip
                        className={classes.avatarOptions}
                        key={user.id}
                        size="small"
                        avatar={<Avatar>{user.first_name[0]}</Avatar>}
                        label={`${user.first_name} ${user.last_name}`}
                      />
                    ))}
                </ListItem>

                {scheduleSelected.strictScheduling ? (
                  <ListItem>
                    <ListItemIcon>
                      <SchedulingIcon className={classes.iconOptions} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Only allow assignee to clock in during schedule ${scheduleSelected.strictScheduling}`}
                    />
                  </ListItem>
                ) : null}
              </List>
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default WorkticketSchedule;
