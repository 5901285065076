import React from "react";
import { Route } from "react-router-dom";
import MainLayoutPublic from "../common/MainLayoutPublic";

const PublicRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return Component ? (
          <MainLayoutPublic>
            <Component {...props} />
          </MainLayoutPublic>
        ) : (
          render(props)
        );
      }}
    />
  );
};

export default PublicRoute;
