import React from "react";
import {
  getCustomersInformation,
  getCustomersInformationSearch,
} from "services/customerService";
import merge from "lodash/merge";

const defaultState = {
  customersListLoading: true,
  customersSelected: null,
  customersList: [],
  unassignedJobs: 0,
  openCreate: false,
  refreshList: false,
};

const CustomersStateContext = React.createContext();
const CustomersDispatchContext = React.createContext();

function customersReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        customersListLoading: action.customersListLoading,
      };
    case "SET_CUSTOMERS_LIST":
      return {
        ...state,
        customersList: action.customersList,
      };
    case "SET_CUSTOMERS_SELECTED":
      return {
        ...state,
        customersSelected: action.customersSelected,
      };
    case "SET_UNASSIGNED_JOBS":
      return {
        ...state,
        unassignedJobs: action.unassignedJobs,
      };
    case "SET_OPEN_CREATE":
      return {
        ...state,
        openCreate: action.openCreate,
      };
    case "SET_REFRESH":
      return {
        ...state,
        refreshList: action.refreshList,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CustomersProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(customersReducer, mergedState);
  return (
    <CustomersStateContext.Provider value={state}>
      <CustomersDispatchContext.Provider value={dispatch}>
        {children}
      </CustomersDispatchContext.Provider>
    </CustomersStateContext.Provider>
  );
}

function useCustomersState() {
  const context = React.useContext(CustomersStateContext);
  if (context === undefined) {
    throw new Error(
      "useCustomersState must be used within a CustomersProvider"
    );
  }
  return context;
}

function useCustomersDispatch() {
  const context = React.useContext(CustomersDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useCustomersDispatch must be used within a CustomersProvider"
    );
  }
  return context;
}

async function getCustomersList(dispatch, filterData, getParentJobs, isOpen) {
  try {
    dispatch({
      type: "SET_LOADING",
      customersListLoading: true,
    });
    const result = await getCustomersInformation(filterData);
    const { customers } = result.data.data;
    const listForRender = customers.map((c) => {
      return {
        ...c,
        hasExpander: c.has_children ? true : false,
        isOpen: isOpen ?? false,
        childRows: [],
        getChildren: (row, rows) => getParentJobs(row, rows),
        columnIndex: 0,
        _page: 0,
      };
    });
    dispatch({
      type: "SET_CUSTOMERS_LIST",
      customersList: listForRender,
    });
    dispatch({
      type: "SET_UNASSIGNED_JOBS",
      unassignedJobs: result.data.data.unassigned_job_count,
    });
    dispatch({
      type: "SET_LOADING",
      customersListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchCustomersList(search, filterData, getParentJobs) {
  try {
    const result = await getCustomersInformationSearch(search, filterData);
    const { customers } = result.data.data;
    const listForRender = customers.map((c) => {
      return {
        ...c,
        hasExpander: true,
        isOpen: false,
        childRows: [],
        getChildren: (row, rows) => getParentJobs(row, rows),
        columnIndex: 0,
        _page: 0,
      };
    });
    return listForRender;
  } catch (error) {
    console.log(error);
  }
}

export {
  CustomersProvider,
  useCustomersState,
  useCustomersDispatch,
  getCustomersList,
  searchCustomersList,
};
