import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import WeeklyScheduleModalViewOnly from "../../openPositions/weeklyScheduleModalViewOnly";
import useStyles from "./styles";

const OpenPositionsPreview = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const { preview, setPreviewModal, setPreview } = props;

  useEffect(() => {
    if (preview?.open_positions?.id) {
      setIsLoading(false);
    }
  }, [preview?.open_positions?.id]);

  return (
    <Dialog
      open={true}
      aria-labelledby="max-width-dialog-title"
      maxWidth={"sm"}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => {
                setPreview(null);
                setPreviewModal(false);
              }}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Box className={classes.modalContainer}>
              <Grid container className={classes.modalContainer}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h3" className={classes.sectionHeader}>
                    OPS - {preview?.open_positions?.id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container style={{ textAlign: "left" }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h6" className={classes.modalLabel}>
                      Job
                    </Typography>
                    <Typography className={classes.modalValue}>
                      {preview?.open_positions?.job?.job_description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h6" className={classes.modalLabel}>
                      Customer
                    </Typography>
                    <Typography className={classes.modalValue}>
                      {preview?.open_positions?.customer?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h6" className={classes.modalLabel}>
                      Position Title
                    </Typography>
                    <Typography className={classes.modalValue}>
                      {preview?.open_positions?.position_title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h6" className={classes.modalLabel}>
                      Position Type
                    </Typography>
                    <Typography className={classes.modalValue}>
                      {preview?.open_positions?.position_type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid>
                  <WeeklyScheduleModalViewOnly
                    scheduleData={
                      preview?.open_positions?.position_schedule &&
                      JSON.parse(preview?.open_positions?.position_schedule)
                        ? JSON.parse(preview?.open_positions?.position_schedule)
                        : null
                    }
                    viewOnly={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  className={classes.detailContainerR}
                ></Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              className={classes.buttonCap}
              color="primary"
              variant="contained"
              size="large"
              onClick={() =>
                props.history.push(
                  `/open-position/${preview?.open_positions?.id}`
                )
              }
            >
              View
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default withRouter(OpenPositionsPreview);
