import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SurveyBreadcrum from "./surveyBreadcrum";
import SurveyInformation from "./surveyInformation";
import SurveyTabs from "./surveyTabs";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import TabIcon from "@material-ui/icons/Storage";
import CollapseIcon from "@material-ui/icons/FirstPage";
import UnCollapsed from "@material-ui/icons/LastPage";
import { getSurveyDetails } from "services/surveyService";
import { useSurveyView } from "contexts/surveyViewContext";
import { logException } from "components/util/logUtil";
import SurveyContent from "pages/survey/surveyContent";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import useStyles from "./styles";

const SurveyLayout = (props) => {
  const classes = useStyles();
  const [valueBottomNavigation, setBottomNavigation] = useState("info");
  const [isMobile, setIsMobile] = useState(false);
  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const context = useSurveyView();
  const dispatchContext = context[1];

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setCollapseSidebar(false);
    }
    window.addEventListener("resize", resize.bind(this));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
    if (window.innerWidth <= 960 && collapseSidebar) {
      setCollapseSidebar(false);
    }
  };

  useEffect(() => {
    const loadSurvey = async () => {
      try {
        if (props.id !== "new") {
          const result = await getSurveyDetails(props.id);
          dispatchContext({
            type: "SET_SURVEY",
            survey: result.data.data.survey,
          });
          dispatchContext({
            type: "SET_LOADING",
            isLoading: false,
          });
        }
      } catch (e) {
        logException(e, "Cannot load Survey data");
      }
    };
    loadSurvey();
  }, [props.id, dispatchContext]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  const classSidebar = !isMobile
    ? collapseSidebar
      ? classes.collapseInfo
      : classes.unCollapseInfo
    : null;

  const classMainContent = !isMobile
    ? collapseSidebar
      ? classes.collapseSidebar
      : classes.unCollapseSidebar
    : null;

  return (
    <>
      <SurveyBreadcrum />
      <Grid container className={classes.root}>
        {(!isMobile || (isMobile && valueBottomNavigation === "info")) && (
          <Grid item xs={12} sm={12} md={3} className={classSidebar}>
            <Box className={classes.containerMain}>
              {!isMobile ? (
                !collapseSidebar ? (
                  <CollapseIcon
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(true)}
                  />
                ) : (
                  <UnCollapsed
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(false)}
                  />
                )
              ) : null}
              {!collapseSidebar ? (
                <SurveyFillProvider>
                  <SurveyInformation />
                  <SurveyContent />
                </SurveyFillProvider>
              ) : null}
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "tabs")) && (
          <Grid item xs={12} sm={12} md={9} className={classMainContent}>
            <SurveyTabs />
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="tabs" icon={<TabIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default SurveyLayout;
