import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import QuoteIcon from "@material-ui/icons/AttachMoney";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { updateQuotesBulk } from "../../../services/quoteService";
import { useQuoteAction } from "../../../contexts/quoteActionContext";
import {
  useTableState,
  useTableDispatch,
} from "../../../contexts/tableContext";
import {
  useQuoteDispatch,
  getQuoteTabList,
} from "../../../contexts/quoteTableContext";
import { numberToWord } from "../../../components/util/stringFormat";
import { useFilterState } from "../../../contexts/filterContext";

import { useProjectView } from "../../../contexts/projectViewContext";

import useStyles from "./styles";

const ApproveDialog = (props) => {
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [quoteAction, dispatchQuoteAction] = useQuoteAction();
  const dispatchQuotes = useQuoteDispatch();
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const { openApprove } = quoteAction;
  const { selected } = tableUi;
  const { filters, startDate, startDateActive, endDate, endDateActive } =
    useFilterState();

  const handleClose = () => {
    dispatchQuoteAction({
      type: "TOGGLE_APPROVE",
      open: false,
    });
  };

  const handleConfirm = async () => {
    if (selected.length) {
      setIsLoadingDialog(true);
      const approveStatus = 5;
      const data = {
        quotes_ids: selected,
        status: approveStatus,
      };
      try {
        const result = await updateQuotesBulk(data);
        if (result) {
          const filterData = {
            filters: filters,
            dateFilters: {
              startDate,
              startDateActive,
              endDate,
              endDateActive,
            },
          };
          // Done Status
          await getQuoteTabList(dispatchQuotes, project.id, filterData);

          dispatchTable({
            type: "SET_UPDATED_ROWS",
            rowsUpdated: selected,
          });
          setTimeout(() => {
            dispatchTable({
              type: "SET_UPDATED_ROWS",
              rowsUpdated: [],
            });
          }, 5000);

          handleClose();
          setIsLoadingDialog(false);
          setOpenSuccess(true);
        }
      } catch (e) {
        console.log("Cannot mark worktickets as verified.");
      }
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openApprove}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <QuoteIcon className={classes.iconDialog} />
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Quotes
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to mark these{" "}
                {numberToWord(selected.length)} ({selected.length}) quote
                {selected.length > 1 ? "s" : ""} as approve?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`These quote${
          selected.length > 1 ? "s" : ""
        } have been marked as approved.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default ApproveDialog;
