import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialogClose: {
    position: "absolute",
    right: 15,
  },
  wrapperDialog: {
    textAlign: "center",
    paddingTop: 24,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleWithIcon: {
    color: "#4D4D4D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 33,
  },
  titleDialog: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    paddingLeft: 10,
    marginLeft: 8,
  },
  selectContainer: {
    padding: 10,
  },
  labelText: {
    color: "#747474",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 8,
    paddingTop: 10,
  },
  textArea: {
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    border: "1px solid #d6dde1",
    borderRadius: 4,
  },
  formHelperText: {
    color: theme.palette.error.main,
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 0,
    paddingRight: 35,
    paddingBottom: 28,
  },
  buttonCancel: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    textTransform: "capitalize",
    marginRight: 10,
    color: "#4F98BC",
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  containerFeedbackButtonNoResults: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 2,
  },
  containerFeedbackButtonSearch: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: 2,
      marginRight: 2,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "left",
    },
  },
  feedbackButton: {
    width: 42,
    height: 42,
    border: "1px solid #4F98BC",
    borderRadius: 4,
  },
  zeroStateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
    padding: 3,
    paddingTop: 20,
    paddingBottom: 10,
  },
  frameIcon: {
    fontSize: 50,
    color: "#4a90e2",
  },
  textNoSearch: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 20,
  },
  textDetail: {
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 16,
  },
  textFeedback: {
    color: "#747474",
    fontSize: 14,
    fontWeight: 700,
    paddingTop: 5,
    paddingRight: 3,
  },
  containerSectionFeedbackButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
}));

export default useStyles;
