import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Counter from "components/common/TableList/counter";
import Snackbar from "@material-ui/core/Snackbar";
import { logException } from "components/util/logUtil";
import { getActivities, getActivitiesOffset } from "services/projectService";

import TimelineIcon from "@material-ui/icons/Timeline";
import { convertDateFormatField } from "components/util/timeFormat";

import { useProjectView } from "contexts/projectViewContext";

import useStyles from "./styles";

function useActivities(projectId) {
  return useQuery(["project-activities", { projectId }], async () => {
    const { data } = await getActivities(projectId);
    return data;
  });
}

const ProjectTabActivity = (props) => {
  const classes = useStyles();
  const [stateContext] = useProjectView();
  const { project, isLoading: isLoadingGeneral } = stateContext ?? null;
  const [activityList, setActivityList] = useState([]);
  const [activityListCount, setActivityListCount] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const { data, isLoading, isFetching, refetch } = useActivities(project.id);
  const isLoadingData = isLoading || isLoadingGeneral;

  useEffect(() => {
    if (data) {
      setActivityList(data?.data?.activities);
      setActivityListCount(data?.data?.filter_counts);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, [project.id]);

  const loadMoreActivities = async () => {
    if (activityListCount <= activityList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const newData = await getActivitiesOffset(
        project.id,
        activityList.length
      );
      const updateWorkticketList = [
        ...activityList,
        ...newData.data.data.activities,
      ];
      setActivityList(updateWorkticketList);
      setLoadingMore(false);
    } catch (e) {
      logException(e, "Cannot load more acitvities data");
    }
  };

  useEffect(() => {
    const trackScrolling = async (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      if (bottom) {
        if (activityListCount <= activityList.length || loadingMore) {
          return;
        }

        try {
          setLoadingMore(true);
          const newData = await getActivitiesOffset(
            project.id,
            activityList.length
          );
          const updateWorkticketList = [
            ...activityList,
            ...newData.data.data.activities,
          ];
          setActivityList(updateWorkticketList);
          setLoadingMore(false);
        } catch (e) {
          logException(e, "Cannot load more acitvities data");
        }
      }
    };
    document
      .getElementById("table-activity-wrapper")
      .addEventListener("scroll", trackScrolling);
    return () => {
      document
        .getElementById("table-activity-wrapper")
        .removeEventListener("scroll", trackScrolling);
    };
  }, [activityList.length, activityListCount, loadingMore]);

  const ActivityItem = (props) => (
    <ListItem className={classes.listContainer}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(props.activity.created_at)}
      </ListItemSecondaryAction>
    </ListItem>
  );

  if (isLoadingGeneral) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <Box>
      <Box className={classes.contentCounter}>
        {!isLoadingData ? (
          <Counter>{`${activityList?.length} / ${activityListCount}`}</Counter>
        ) : null}
      </Box>
      <Box
        id={"table-activity-wrapper"}
        className={classes.containerTabActivity}
      >
        <List className={classes.containerActivityList}>
          {activityList.map((activity) => (
            <ActivityItem key={activity.id} activity={activity} />
          ))}
        </List>
      </Box>
      {loadingMore ? (
        <Box className={classes.loadingTable}>
          <LinearProgress color="secondary" />
        </Box>
      ) : null}
      {isFetching && !isLoadingData ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={true}
          message="Loading..."
          className={classes.snackItem}
        />
      ) : null}
    </Box>
  );
};

export default ProjectTabActivity;
