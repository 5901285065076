import React from "react";
import { merge } from "lodash";

const defaultState = {
  quoteItemUsers: [],
  deletedQuoteItemUsers: [],
  changedQuoteItemUsers: [],
  currentItem: null,
};

const QuoteItemUserStateContext = React.createContext();
const QuoteItemUserDispatchContext = React.createContext();

function quoteItemUserReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_ITEM":
      return {
        ...state,
        currentItem: action.currentItem,
      };
    case "SET_QUOTE_ITEM_USERS":
      return {
        ...state,
        quoteItemUsers: action.quoteItemUsers,
      };
    case "SET_DELETED_QUOTE_ITEM_USERS":
      console.log(action);
      return {
        ...state,
        deletedQuoteItemUsers: action.deletedQuoteItemUsers,
      };
    case "SET_CHANGED_QUOTE_ITEM_USERS":
      console.log(action);
      return {
        ...state,
        changedQuoteItemUsers: action.changedQuoteItemUsers,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteItemUserProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(quoteItemUserReducer, mergedState);
  return (
    <QuoteItemUserStateContext.Provider value={state}>
      <QuoteItemUserDispatchContext.Provider value={dispatch}>
        {children}
      </QuoteItemUserDispatchContext.Provider>
    </QuoteItemUserStateContext.Provider>
  );
}

function useQuoteItemUserState() {
  const context = React.useContext(QuoteItemUserStateContext);
  if (context === undefined) {
    throw new Error(
      "useQuoteItemUserState must be used within a QuoteItemUserProvider"
    );
  }
  return context;
}

function useQuoteItemUserDispatch() {
  const context = React.useContext(QuoteItemUserDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useQuoteItemUserDispatch must be used within a QuoteItemUserProvider"
    );
  }
  return context;
}

export {
  QuoteItemUserProvider,
  useQuoteItemUserState,
  useQuoteItemUserDispatch,
};
