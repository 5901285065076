import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import FormInput from "../../components/ui/FormContent/formInput";
import FormSelectAuto from "../../components/ui/FormContent/formSelectAuto";
import MessageDialog from "../../components/ui/dialog/messageDialog";
import WorkIcon from "@material-ui/icons/Work";
import ExistingWorkIcon from "@material-ui/icons/Shop";
import BackIcon from "@material-ui/icons/ArrowBack";

import GlobalUiContext from "../../contexts/globalUiContext";
import { getProjectsUser } from "../../services/projectService";

import useStyles from "./styles";

const defaultQuick = [{ value: 0, label: "Default" }];

const QuoteCreateDrawer = (props) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [option, setOption] = useState(0);
  const [projects, setProjects] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [projectsOptExisting, setProjectsOptExisting] = useState([]);
  const [projectExistingSelected, setProjectExistingSelected] = React.useState(
    []
  );
  const { globalUi, dispatchGlobalUi } = React.useContext(GlobalUiContext);

  const { createObject } = globalUi;

  useEffect(() => {
    if (createObject) {
      setOption(2);
      setStep(2);
    }
  }, [createObject]);

  useEffect(() => {
    const loadProjects = async () => {
      const result = await getProjectsUser();
      const { projects } = result.data.data;
      setFirstLoad(true);
      setProjects(projects);
    };
    if (!projects.length && !firstLoad) {
      loadProjects();
    }
  }, [projects]);

  const handleClick = (optionInput) => {
    setOption(optionInput);

    if (optionInput === 1) {
      const filterProject = projects.filter((pro) => pro.type !== 0);
      const resultForRender = filterProject.map((project) => {
        return {
          value: project.id,
          label: `${project.number} ${project.title}`,
        };
      });
      setProjectsOptExisting([...resultForRender]);
    }
  };

  const handleNextClick = () => {
    if (option === 2) {
      dispatchGlobalUi({
        type: "SET_FLOW",
        createFlow: "quote",
      });
      dispatchGlobalUi({
        type: "SET_QUICKTICKET",
        quickTicket: null,
      });
      dispatchGlobalUi({
        type: "TOGGLE_SIDEDRAWER",
        isDrawerOpen: true,
        drawerContent: "project",
      });
    } else {
      setStep(option);
    }
  };

  const handleNewProjectClick = () => {
    const { history } = props;
    history.push(`/quote/new/${createObject.id}`);
    window.location.reload();
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleOnClickBack = () => {
    setStep(0);
  };

  const handleBeginProjectClick = () => {
    const { history } = props;
    history.push(`/quote/new/${projectExistingSelected[0].value}`);
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleChangeProjectExisting = (event, value) => {
    setProjectExistingSelected([value]);
  };

  const closeMessageDialog = () => {
    setOpenMessage(false);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Create New Quote
        </Typography>
        <Typography
          variant="h6"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Tell us more about the quote you're creating.
        </Typography>
      </div>
      {step === 0 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            Is this a Quote for a new or existing project?
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={4}>
              <Paper
                className={
                  option === 2 ? classes.optionPaperActive : classes.optionPaper
                }
                square={true}
                elevation={0}
              >
                <Box
                  className={classes.optionBox}
                  onClick={() => handleClick(2)}
                >
                  <Avatar
                    className={classNames(
                      classes.avatarBox,
                      classes.avatarType2
                    )}
                  >
                    <WorkIcon />
                  </Avatar>
                  <Typography
                    variant="body1"
                    className={classes.optionBoxTitle}
                    gutterBottom
                  >
                    New Project
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={
                  option === 1 ? classes.optionPaperActive : classes.optionPaper
                }
                square={true}
                elevation={0}
              >
                <Box
                  className={classes.optionBox}
                  onClick={() => handleClick(1)}
                >
                  <Avatar
                    className={classNames(
                      classes.avatarBox,
                      classes.avatarType3
                    )}
                  >
                    <ExistingWorkIcon />
                  </Avatar>
                  <Typography
                    variant="body1"
                    className={classes.optionBoxTitle}
                    gutterBottom
                  >
                    Existing Project
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleNextClick}
              className={classNames(classes.button)}
              disabled={!Boolean(option)}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            Please select a Project for your Quote
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={projectsOptExisting}
                name="project_existing"
                internal={true}
                handleChange={handleChangeProjectExisting}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <Button
              color="primary"
              className={classes.buttonBack}
              onClick={handleOnClickBack}
              startIcon={<BackIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleBeginProjectClick}
              className={classNames(classes.button)}
            >
              Begin
            </Button>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            Project for your Quote
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="project_name"
                label=""
                internal={true}
                value={createObject ? createObject.title : ""}
                styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
                readonly={true}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <Button
              color="primary"
              className={classes.buttonBack}
              onClick={handleOnClickBack}
              startIcon={<BackIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleNewProjectClick}
              className={classNames(classes.button)}
              disabled={!createObject ? true : false}
            >
              Begin
            </Button>
          </Box>
        </Box>
      )}
      <MessageDialog
        open={openMessage}
        title={"Success!"}
        message={"Your Project has been created, continue to create quote."}
        handleClose={closeMessageDialog}
      />
    </>
  );
};

export default withRouter(QuoteCreateDrawer);
