import React from "react";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";

import useStyles from "./styles";

const LoadingIndicator = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <LoadingStateHorizontal isVisible />
    </div>
  );
};

export default LoadingIndicator;
