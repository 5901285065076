import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBack";

import useStyles from "./styles";

const RequestActionNav = (props) => {
  const classes = useStyles();

  const { handleOnClick, handleOnClickBack, stepComplete } = props;

  return (
    <Box className={classes.actionBox}>
      <Button
        color="primary"
        className={classes.buttonBack}
        onClick={handleOnClickBack}
        startIcon={<BackIcon />}
      >
        Back
      </Button>
      <Button
        className={classes.buttonNext}
        onClick={handleOnClick}
        variant="contained"
        size="large"
        color="primary"
        disabled={stepComplete ? false : true}
      >
        Next
      </Button>
    </Box>
  );
};

export default RequestActionNav;
