import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useSurveyFillDispatch,
  surveyResume,
} from "contexts/surveyFillContext";
import {
  useEvaluationsState,
  useEvaluationsDispatch,
} from "contexts/evaluationsContext";
import { logException } from "components/util/logUtil";

const ViewDialog = (props) => {
  const { openView, evaluationsSelected } = useEvaluationsState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const dispatchEvaluations = useEvaluationsDispatch();

  useEffect(() => {
    const loadSurvey = async () => {
      try {
        await surveyResume(dispatchSurveyFill, evaluationsSelected.id, true);
        dispatchEvaluations({
          type: "TOGGLE_OPEN_VIEW",
          openView: false,
        });
      } catch (e) {
        logException(e, "Cannot view survey");
      }
    };
    if (openView) {
      loadSurvey();
    }
  }, [openView]);

  return (
    <Dialog
      open={openView}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <LoadingIndicator />
    </Dialog>
  );
};
export default ViewDialog;
