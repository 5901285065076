import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import TableTabs from "../../../components/common/Table/tableTabs";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { StyledTab } from "../../../components/common/Table/stylesTabs";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormInput from "../../../components/ui/FormContent/formInput";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import FormSelect from "../../../components/ui/FormContent/formSelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getCategories } from "../../../services/categoryService";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
import { serviceUnits, exceptionTypes } from "../../../constants";
import PriceInput from "../../../components/common/PriceInput/priceInput";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { calcPriceMargin } from "../../../lib/marginPrice";
import QuoteExceiptionsActions from "./quoteExceiptionsActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { getJobs } from "../../../services/jobService";
import { getCustomers } from "../../../services/userService";
import { getIndustries } from "../../../services/serviceService";
import TableRow from "@material-ui/core/TableRow";
import { get } from "lodash";
import * as classNames from "classnames";
import FormSelectJobsAuto from "../../../components/ui/FormContent/formSelectJobsAuto";

import useStyles from "./styles";

const InitialData = {
  productivity: 0,
  name: "",
  categories: [],
  exceptions: [],
  price: 0,
  priceExep: 0,
  labor: 0,
  supplies: 0,
  equipment: 0,
  other: 0,
  unit: "",
  exceptionType: "",
  low: false,
  lowPrice: 0,
  high: false,
  highPrice: 0,
  costType: false,
};

const InitialExcep = {
  id: null,
  type: "Industry",
  price: 0,
  values: [],
  priceType: false,
};

const ServiceDialog = (props) => {
  const classes = useStyles();

  const { open, handleConfirm, handleClose, title, item } = props;

  const [data, setData] = useState(InitialData);
  const [dataExcep, setDataExcep] = useState(InitialExcep);
  const [activeTab, setActiveTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoadingC, setIsloadingC] = useState(true);
  const [pUnit, setPUnit] = useState("h/h");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [tUnit, setTUnit] = useState("min/h");
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedExceptionId, setSelectedExceptionId] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);

  const viewItem = (row) => {
    const values =
      row.type === "Job"
        ? row.values.map((item) => {
            return {
              id: item.id,
              job_description: item.name,
              job_number: item.id,
            };
          })
        : row.values.map((item) => {
            return { value: item.id, label: item.name };
          });
    const newData = { ...row };
    newData.values = values;
    setDataExcep(newData);
    setSelectedExceptionId(row.id);
    setOpenCreate(true);
  };

  const columns = [
    {
      id: "type",
      label: "Exeption Type",
    },
    {
      id: "expecitons",
      label: "Exceptions",
      content: (row) =>
        row.values.map((item) => {
          return row.type === "Job"
            ? `${item.id} - ${item.name}, `
            : `${item.name}, `;
        }),
    },
    {
      id: "price",
      label: "Price",
      content: (row) => (row.priceType ? `${row.price} %` : `$ ${row.price}`),
    },
    {
      id: "priceType",
      label: "Price Type",
      content: (row) => (row.priceType ? "Margin" : "Fixed"),
    },
    {
      id: "totalPrice",
      label: "Total Price",
      content: (row) =>
        row.priceType
          ? `$ ${calcPriceMargin(parseFloat(row.price), totalPrice).toFixed(2)}`
          : `$ ${parseFloat(row.price).toFixed(2)}`,
    },
    {
      id: "actions",
      content: (row) => (
        <QuoteExceiptionsActions
          row={row}
          handleView={viewItem}
          handleDelete={handleDelete}
        />
      ),
      contentMobile: (row) => (
        <QuoteExceiptionsActions
          row={row}
          mobile={true}
          handleView={viewItem}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    const exceptions = data.exceptions.filter((m) => m.id !== id);
    const newData = {
      ...data,
    };
    newData["exceptions"] = exceptions;
    setData(newData);
  };

  const createKey = (item, column) => {
    return item.id + (column.id || column.key);
  };

  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return get(item, column.id);
  };

  useEffect(() => {
    const getCats = async () => {
      setIsloadingC(true);
      let resultForRenderCategory = [];
      if (categories.length <= 0) {
        const data = await getCategories();
        resultForRenderCategory = data.data.data.categories.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCategories(resultForRenderCategory);
      }
      if (jobs.length <= 0) {
        const resultJob = await getJobs();
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.job_number,
            job_description: job.job_description,
            job_number: job.job_number,
          };
        });
        setJobs(resultForRender);
      }
      if (customers.length <= 0) {
        const resultUser = await getCustomers();
        const { customers } = resultUser.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        setCustomers(resultForRender);
      }
      if (industries.length <= 0) {
        const resultIndustry = await getIndustries();
        const resultForRender = resultIndustry.data.data.industries.map(
          (item) => {
            return { value: item.industry_id, label: item.name };
          }
        );
        setIndustries(resultForRender);
      }
      if (item) {
        const category = JSON.parse(item.category);
        const confItem = JSON.parse(item.item);
        const cats = category.categories.map((item) => {
          return {
            value: item.id,
            label:
              resultForRenderCategory.length > 0
                ? resultForRenderCategory.filter(
                    (itemf) => itemf.value === item.id
                  )[0].label
                : categories.filter((itemf) => itemf.value === item.id)[0]
                    .label,
          };
        });
        const newData = {};
        newData["categories"] = cats;
        newData["name"] = item.name;
        newData["id"] = item.id;
        newData["price"] = confItem.price;
        newData["productivity"] = confItem.productivity;
        newData["unit"] = confItem.unit;
        newData["costType"] = confItem.costType;
        newData["equipment"] = confItem.costs.equipment;
        newData["labor"] = confItem.costs.labor;
        newData["other"] = confItem.costs.other;
        newData["supplies"] = confItem.costs.supplies;
        newData["low"] = confItem.intensity.low.low;
        newData["lowPrice"] = confItem.intensity.low.lowPrice;
        newData["high"] = confItem.intensity.high.high;
        newData["highPrice"] = confItem.intensity.high.highPrice;
        const un = serviceUnits.filter((it) => it.value === confItem.unit);
        let unit2 = un && un[0] ? un[0].symbol : "";
        setTUnit("min/" + unit2.toLowerCase().replace("s", ""));
        setPUnit(unit2 + "/h");
        const exception = JSON.parse(item.exception);
        newData["exceptions"] = exception.exceptions;
        setTotalPrice(parseFloat(item.price));
        setData(newData);
        setActiveTab(0);
      } else {
        setData(InitialData);
        setActiveTab(0);
        setPUnit("h/h");
        setTotalPrice(0);
        setTotalCost(0);
        setTUnit("min/h");
        setOpenCreate(false);
      }
      setIsloadingC(false);
    };
    getCats();
  }, [item]);

  const handleBlur = (event, type) => {
    let oldValue = data[event.name];
    if (event && oldValue !== event.value && event.value !== "") {
      const newData = {
        ...data,
      };
      if (event.name === "unit") {
        const un = serviceUnits.filter((item) => item.value === event.value);
        let unit2 = un[0].symbol;
        setTUnit("min/" + unit2.toLowerCase().replace("s", ""));
        setPUnit(unit2 + "/h");
      }
      if (
        ["unit", "labor", "supplies", "equipment", "other", "price"].includes(
          event.name
        )
      ) {
        if (event.name === "unit") {
          newData[event.name] = event.value;
        } else {
          newData[event.name] = parseFloat(event.value);
        }
        setData(newData);
        calculatePrice(newData);
      } else {
        newData[event.name] = event.value;
        setData(newData);
      }
    }
  };

  const handleBlurExcep = (event, type) => {
    let oldValue = data[event.name];
    if (event && oldValue !== event.value && event.value !== "") {
      const newData = {
        ...dataExcep,
      };
      newData[event.name] = event.value;
      if (event.name === "type") {
        newData["values"] = [];
      }
      setDataExcep(newData);
    }
  };

  const calculatePrice = (data) => {
    let totalPrice = 0,
      totalCost = 0;
    totalCost =
      parseFloat(data.labor) +
      parseFloat(data.supplies) +
      parseFloat(data.equipment) +
      parseFloat(data.other);
    if (data.costType) {
      totalPrice = calcPriceMargin(data.price, totalCost);
    } else {
      totalPrice = parseFloat(data.price);
    }
    setTotalCost(totalCost);
    setTotalPrice(totalPrice);
  };

  const handleAdd = (exeptions, isCalledFromExcep) => {
    const item = {
      unit: data.unit,
      productivity: data.productivity,
      costs: {
        labor: data.labor,
        supplies: data.supplies,
        equipment: data.equipment,
        other: data.other,
      },
      costType: data.costType,
      price: data.price,
      totalPrice: totalPrice,
      totalCost: totalCost,
      intensity: {
        low: {
          low: data.low,
          lowPrice: data.lowPrice,
        },
        high: {
          high: data.high,
          highPrice: data.highPrice,
        },
      },
    };
    const cats = data.categories.map((item) => {
      return {
        id: item.value,
      };
    });
    const sData = {
      name: data.name,
      item,
      category: { categories: cats },
      price: totalPrice,
      exception: { exceptions: exeptions ? exeptions : data.exceptions },
    };
    if (data.id) {
      sData.id = data.id;
    }
    setHasChanged(true);
    handleConfirm(sData, isCalledFromExcep);
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleChangeCategory = (event, value) => {
    const newData = {
      ...data,
    };
    newData["categories"] = value;
    setData(newData);
  };

  const handleChangeValue = (event, value) => {
    const newData = {
      ...dataExcep,
    };
    newData["values"] = value;
    setDataExcep(newData);
  };

  const handleChangeCostType = (event) => {
    const value = event.target.checked;
    const newData = {
      ...data,
    };
    newData["costType"] = value;
    setData(newData);
    calculatePrice(newData);
  };

  const handleChangeCostTypeExcep = (event) => {
    const value = event.target.checked;
    const newData = {
      ...dataExcep,
    };
    newData["priceType"] = value;
    setDataExcep(newData);
  };

  const clearExcepData = () => {
    const newData = {
      id: null,
      type: "Industry",
      price: 0,
      values: [],
      priceType: false,
    };
    setDataExcep(newData);
  };

  const handleChange = async (event) => {
    const checked = event.target.checked;
    const newData = {
      ...data,
    };
    newData[event.target.name] = checked;
    setData(newData);
  };

  const handleCloseCreate = () => {
    clearExcepData();
    setSelectedExceptionId(null);
    setOpenCreate(false);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleAddException = () => {
    let index = 0;
    if (data.exceptions.length > 0) index = data.exceptions.length - 1;
    if (!selectedExceptionId) {
      const exception = {
        id: data.exceptions[index] ? data.exceptions[index].id + 1 : 1,
        type: dataExcep.type,
        values: dataExcep.values.map((item) => {
          if (dataExcep.type === "Job")
            return { id: item.job_number, name: item.job_description };
          else return { id: item.value, name: item.label };
        }),
        priceType: dataExcep.priceType,
        price: parseFloat(dataExcep.price),
      };
      const newData = {
        ...data,
      };
      newData["exceptions"] = [...data.exceptions, exception];
      setData(newData);
      if (item) {
        handleAdd(newData["exceptions"], true);
      }
    } else {
      let excep;
      for (let i = 0; i < data.exceptions.length; i++) {
        if (data.exceptions[i].id === selectedExceptionId) {
          excep = data.exceptions[i];
          break;
        }
      }
      excep.type = dataExcep.type;
      excep.values = dataExcep.values.map((item) => {
        if (dataExcep.type === "Job")
          return { id: item.job_number, name: item.job_description };
        else return { id: item.value, name: item.label };
      });
      excep.priceType = dataExcep.priceType;
      excep.price = parseFloat(dataExcep.price);
      if (item) {
        handleAdd(null, true);
      }
    }
    clearExcepData();
    setOpenCreate(false);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <>
          <DialogContent className={classes.wrapperDialog2}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose(hasChanged)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog2}
            >
              {title}
            </Typography>
            {isLoadingC ? (
              <LoadingIndicator />
            ) : (
              <>
                <TableTabs handleTabChange={handleTabChange} value={activeTab}>
                  <StyledTab
                    disableRipple={true}
                    label={<Box className={classes.tabItem}>Service</Box>}
                    key={0}
                    value={0}
                  />
                  <StyledTab
                    disableRipple={true}
                    label={
                      <Box className={classes.tabItem}>Existing Exceptions</Box>
                    }
                    key={1}
                    value={1}
                  />
                </TableTabs>
                {activeTab === 0 && (
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="name"
                        label="Name"
                        internal={true}
                        value={data.name}
                        handleBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={categories}
                        name="categories"
                        value={data.categories}
                        label="Category"
                        multiple={true}
                        internal={true}
                        handleChange={handleChangeCategory}
                        inputContainer={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormSelect
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="unit"
                        label="Units"
                        options={serviceUnits}
                        value={data.unit}
                        internal={true}
                        handleBlur={handleBlur}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      {pUnit !== "h/h" && (
                        <>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="productivity"
                            label="Productivity"
                            internal={true}
                            value={data.productivity}
                            alignText="right"
                            handleBlur={handleBlur}
                            endAdornment={
                              <InputAdornment position="end">
                                {pUnit}
                              </InputAdornment>
                            }
                          />
                          <Typography className={classes.labelTip}>
                            {`Tip: ${
                              data.productivity && data.productivity > 0
                                ? (60 / data.productivity).toFixed(2)
                                : "0"
                            } ${tUnit !== "$" && tUnit}`}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.itemDialogTitle}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleDialog3}
                      >
                        Costs
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="labor"
                        label="Labor"
                        internal={true}
                        value={data.labor}
                        handleBlur={handleBlur}
                        alignText="right"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="supplies"
                        label="Supplies"
                        internal={true}
                        value={data.supplies}
                        handleBlur={handleBlur}
                        alignText="right"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="equipment"
                        label="Equipment"
                        internal={true}
                        value={data.equipment}
                        handleBlur={handleBlur}
                        alignText="right"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.itemDialog}
                    >
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="other"
                        label="Other"
                        internal={true}
                        value={data.other}
                        handleBlur={handleBlur}
                        alignText="right"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      className={classes.itemDialog2}
                    >
                      <PriceInput
                        label="Price"
                        name="price"
                        costType={data.costType}
                        handleChangeCostType={handleChangeCostType}
                        price={data.price}
                        handleBlur={handleBlur}
                        unit={"$"}
                        custom={true}
                      />
                      <Typography className={classes.labelTip}>
                        {`Total Price: $ ${totalPrice.toFixed(2)}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      className={classes.itemDialog}
                    >
                      <InputLabel
                        htmlFor="priceT"
                        className={classes.customInputInternalLabel}
                      >
                        Intensity
                      </InputLabel>
                      <FormControlLabel
                        value={data.low}
                        name={"low"}
                        checked={data.low ? true : false}
                        control={
                          <Checkbox
                            className={classes.tableCheckbox}
                            color="secondary"
                          />
                        }
                        label={"Low"}
                        labelPlacement="end"
                        className={classes.taskItem}
                        onChange={handleChange}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="lowPrice"
                        internal={true}
                        value={data.lowPrice}
                        readonly={!data.low ? true : false}
                        handleBlur={handleBlur}
                        withoutLabel={true}
                        alignText="right"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                      {data.low ? (
                        <Typography className={classes.labelTip}>
                          {`Low Price: $ ${(
                            totalPrice -
                            (totalPrice * data.lowPrice) / 100
                          ).toFixed(2)}`}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      className={classes.itemDialog}
                    >
                      <FormControlLabel
                        value={data.high}
                        name={"high"}
                        checked={data.high ? true : false}
                        control={
                          <Checkbox
                            className={classes.tableCheckbox}
                            color="secondary"
                          />
                        }
                        label={"High"}
                        labelPlacement="end"
                        className={classes.taskItem2}
                        onChange={handleChange}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="highPrice"
                        internal={true}
                        value={data.highPrice}
                        readonly={!data.high ? true : false}
                        handleBlur={handleBlur}
                        withoutLabel={true}
                        alignText="right"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                      {data.high ? (
                        <Typography className={classes.labelTip}>
                          {`High Price: $ ${(
                            totalPrice +
                            (totalPrice * data.highPrice) / 100
                          ).toFixed(2)}`}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                )}
                {activeTab === 1 && (
                  <Box>
                    <Box className={classes.rootRecurrence}>
                      <>
                        <Box className={classes.contentContainer}>
                          <Box className={classes.rootContainer}>
                            <Box className={classes.tableContainer}>
                              <Box className={classes.fullHeightTable}>
                                <Table
                                  stickyHeader
                                  className={classes.table}
                                  aria-labelledby="tableTitle"
                                  aria-label="enhanced table"
                                >
                                  {columns.map((headCell) => (
                                    <TableCell
                                      className={classNames(
                                        classes.headLabel,
                                        classes.tablecell
                                      )}
                                      key={headCell.id}
                                      style={
                                        headCell.style ? headCell.style : {}
                                      }
                                    >
                                      {headCell.label}
                                    </TableCell>
                                  ))}
                                  <TableBody>
                                    {data.exceptions &&
                                    data.exceptions.length > 0 ? (
                                      data.exceptions.map((item, index) => {
                                        return (
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={false}
                                            tabIndex={-1}
                                            key={item.id}
                                            className={classes.tablerow}
                                          >
                                            {columns.map((column) => (
                                              <TableCell
                                                className={classes.tablecell}
                                                key={createKey(item, column)}
                                                style={
                                                  column.style
                                                    ? column.style
                                                    : {}
                                                }
                                              >
                                                {renderCell(item, column)}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        );
                                      })
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={columns.length + 1}>
                                          No Records Found
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                                {openCreate && (
                                  <Grid
                                    container
                                    className={classes.itemContainer}
                                  >
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      className={classes.itemDialog}
                                    >
                                      <FormSelect
                                        gridSizes={[{ size: "md", val: 12 }]}
                                        name="type"
                                        label="Select Type"
                                        options={exceptionTypes}
                                        value={dataExcep.type}
                                        internal={true}
                                        handleBlur={handleBlurExcep}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      className={classes.itemDialog}
                                    >
                                      {dataExcep.type === "Job" ? (
                                        <FormSelectJobsAuto
                                          gridSizes={[{ size: "md", val: 12 }]}
                                          options={jobs}
                                          name="job_number"
                                          label="Job"
                                          internal={true}
                                          handleChange={handleChangeValue}
                                          multiple={true}
                                          value={dataExcep.values}
                                        />
                                      ) : (
                                        <FormSelectAuto
                                          gridSizes={[{ size: "md", val: 12 }]}
                                          options={
                                            dataExcep.type === "Industry"
                                              ? industries
                                              : customers
                                          }
                                          name="categories"
                                          value={dataExcep.values}
                                          label={
                                            dataExcep.type === "Industry"
                                              ? "Industry"
                                              : "Customer"
                                          }
                                          multiple={true}
                                          internal={true}
                                          handleChange={handleChangeValue}
                                          inputContainer={true}
                                        />
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      className={classes.itemDialog2}
                                    >
                                      <PriceInput
                                        label="Price"
                                        name="price"
                                        costType={dataExcep.priceType}
                                        handleChangeCostType={
                                          handleChangeCostTypeExcep
                                        }
                                        price={dataExcep.price}
                                        handleBlur={handleBlurExcep}
                                        unit={"$"}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          {activeTab === 0 && (
            <DialogActions className={classes.wrapperDialogAction2}>
              <Button
                onClick={() => handleClose(hasChanged)}
                className={classes.buttonOutlined2}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleAdd(null, false)}
                className={classes.button2}
                color="primary"
                variant="contained"
                size="large"
              >
                {item ? "Save" : "Add"}
              </Button>
            </DialogActions>
          )}
          {activeTab === 1 && openCreate && (
            <DialogActions className={classes.wrapperDialogAction2}>
              <Button
                onClick={handleCloseCreate}
                className={classes.buttonOutlined2}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddException}
                className={classes.button2}
                color="primary"
                variant="contained"
                size="large"
              >
                Save
              </Button>
            </DialogActions>
          )}
          {activeTab === 1 && !openCreate && (
            <DialogActions className={classes.wrapperDialogAction2}>
              <Button
                onClick={handleOpenCreate}
                className={classes.button2}
                color="primary"
                variant="contained"
                size="large"
              >
                Add New
              </Button>
            </DialogActions>
          )}
        </>
      </Dialog>
    </>
  );
};
export default ServiceDialog;
