import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Counter from "components/common/TableList/counter";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { questionQATypes } from "constants.js";
import Button from "@material-ui/core/Button";
import { useSurveyView } from "contexts/surveyViewContext";
import { ButtonBase } from "components/ui/core/button";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableDispatch } from "contexts/tableContext";
import {
  useSurveysQuestionsState,
  useSurveysQuestionsDispatch,
  getSurveysQuestionsList,
  searchSurveysQuestionsList,
  getSurveysQuestionsListDataSet,
} from "contexts/surveysQuestionsTableContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import {
  useQuestionState,
  useQuestionDispatch,
} from "contexts/surveyQuestionContext";
import { updateSurvey } from "services/surveyService";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const SurveysQuestionsListTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    surveysQuestionsLoading,
    surveysQuestionList,
    surveysQuestionsCount,
  } = useSurveysQuestionsState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchSurveysQuestions = useSurveysQuestionsDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContextSurvey] = useSurveyView();
  const { survey } = stateContextSurvey ?? null;
  const { assignedIds } = useQuestionState();
  const dispatchQuestions = useQuestionDispatch();

  const { refreshData } = globalUi;

  const filterQuestion = [
    {
      filter: "category",
      group: "category",
      values: [{ value: survey.type }],
    },
  ];

  const openManageQuestion = (row) => {
    dispatchSurveysQuestions({
      type: "SET_QUESTIONS_SELECTED",
      surveysQuestionSelected: row,
    });
    dispatchSurveysQuestions({
      type: "TOGGLE_MANAGE",
      openManageQuestion: true,
    });
  };

  const selectDeselectQuestion = async (question_id, type) => {
    dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns(question_id) });
    if (type === 0) {
      await updateSurvey(survey.id, { remove_question_id: question_id });
      const newAssignedIds = assignedIds.filter((idq) => idq !== question_id);
      dispatchQuestions({
        type: "SET_ASSIGNED_IDS",
        assignedIds: newAssignedIds,
      });
    } else {
      await updateSurvey(survey.id, { add_question_id: question_id });
      assignedIds.push(parseInt(question_id));
      dispatchQuestions({
        type: "SET_ASSIGNED_IDS",
        assignedIds: assignedIds,
      });
    }
    dispatchGlobalUi({
      type: "SET_REFRESH_DATA",
      refreshData: true,
    });
  };

  const getColumns = (selRow) => {
    return [
      {
        id: "id",
        label: "Question Number",
        style: { minWidth: 100, paddingLeft: 8 },
        content: (row) => (
          <Box className={classes.viewContainer}>
            <Typography variant="h6" className={classes.titleView}>
              {`QUES-${row.id}`}
            </Typography>
            <Button
              variant="outlined"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              className={classNames(
                classes.button2,
                classes.buttonPrimaryTable
              )}
              onClick={() => openManageQuestion(row)}
              size="small"
            >
              View
            </Button>
          </Box>
        ),
      },
      {
        id: "type",
        label: "Question Type",
        content: (row) =>
          questionQATypes.find((item) => item.value === row.type).label,
        style: { width: 200 },
      },
      {
        id: "name",
        label: "Question Name",
        style: { width: 800 },
      },
      {
        id: "updated_at",
        label: "Last Updated",
        format: "date",
        style: { width: 200 },
      },
      {
        id: "action",
        label: "",
        content: (row) =>
          row.id === selRow ? (
            <Box className={classes.centerLoadingSideBar}>
              <LoadingStateHorizontal isVisible />
            </Box>
          ) : assignedIds.includes(row.id) ? (
            <Button
              className={classes.followingBtn}
              onClick={() => selectDeselectQuestion(row.id, 0)}
            >
              Remove
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.followBtn}
              onClick={() => selectDeselectQuestion(row.id, 1)}
            >
              Add
            </Button>
          ),
      },
    ];
  };

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = filterQuestion;
      await getSurveysQuestionsList(dispatchSurveysQuestions, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchSurveysQuestions, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = filterQuestion;
      if (filterState.searchString === "") {
        await getSurveysQuestionsList(
          dispatchSurveysQuestions,
          filterData,
          true
        );
      } else {
        const filterData = getFilterData(filterState);
        filterData.filters = filterQuestion;
        const results = await searchSurveysQuestionsList(
          filterState.searchString,
          filterData
        );

        dispatchSurveysQuestions({
          type: "SET_QUESTIONS_LIST",
          surveysQuestionList: results,
        });

        dispatchSurveysQuestions({
          type: "SET_QUESTIONS_COUNT",
          surveysQuestionsCount: results.length,
        });
      }
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: surveysQuestionList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: getColumns(-1) });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, surveysQuestionList]);

  const handleScrollClick = useCallback(async () => {
    if (surveysQuestionsCount <= surveysQuestionList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = filterQuestion;
      await getSurveysQuestionsListDataSet(
        surveysQuestionList,
        dispatchSurveysQuestions,
        surveysQuestionList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: surveysQuestionList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    surveysQuestionList,
    surveysQuestionsCount,
    filterState,
    dispatchSurveysQuestions,
    dispatchTable,
    loadingMore,
  ]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = filterQuestion;
          const results = await searchSurveysQuestionsList(query, filterData);

          dispatchSurveysQuestions({
            type: "SET_QUESTIONS_LIST",
            surveysQuestionList: results,
          });

          dispatchSurveysQuestions({
            type: "SET_QUESTIONS_COUNT",
            surveysQuestionsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  console.log(assignedIds);

  return (
    <Box className={classes.root}>
      {surveysQuestionsLoading || loadingSearch ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>{`${surveysQuestionList.length} / ${surveysQuestionsCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={() => {}}
                    newColumns={getColumns(-1)}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        placeholderSearch={"Question Name"}
                        handleSearchClear={handleSearchClear}
                      >
                        <Box className={classes.listActionContainer}>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            onClick={() => openManageQuestion(null)}
                          >
                            New Question
                          </ButtonBase>
                        </Box>
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filterState.filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(SurveysQuestionsListTable);
