import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectCreateJobs = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterStateValue, setFilterStateValue] = useState([]);
  const [filterStateSelected, setFilterStateSelected] = useState([]);

  const { jobs, jobsSelected, changeJobFilterList } = props;

  useEffect(() => {
    const loadState = () => {
      const selectedList = jobsSelected.map((item) => item.id);
      const listState = jobs.map((job) => job.state);
      const uniqueState = Array.from(new Set(listState))
        .filter((item) => item !== "")
        .sort();
      const formatState = uniqueState.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setFilterState(formatState);
      setFilterStateValue([]);
      setFilterStateSelected(selectedList);
      setJobList(jobs);
    };

    if (open) {
      loadState();
    }
  }, [jobs, jobsSelected, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleApply = () => {
    changeJobFilterList(filterStateSelected);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectAllClick = () => {
    if (jobList.length === filterStateSelected.length) {
      setFilterStateSelected([]);
      return;
    }
    const jobsAll = jobList.map((job) => job.id);
    setFilterStateSelected([...jobsAll]);
  };

  const clickRow = (event, id) => {
    if (filterStateSelected.includes(id)) {
      filterStateSelected.splice(filterStateSelected.indexOf(id), 1);
    } else {
      filterStateSelected.push(id);
    }
    setFilterStateSelected([...filterStateSelected]);
  };

  const handleChangeState = (event, value) => {
    console.log("value", value);
    setFilterStateValue(value ?? []);
    if (!value.length) {
      setJobList(jobs);
      return;
    }
    const stateList = value.map((item) => item.value);
    const filterJobList = jobs.filter((job) => stateList.includes(job.state));
    setJobList(filterJobList);
  };

  return (
    <Box className={classes.wrapperJobFilter}>
      <Button
        variant="outlined"
        color={"secondary"}
        className={classes.btnJobFilter}
        startIcon={<FilterListIcon />}
        onClick={handleOpen}
        disabled={!jobs.length}
      >
        Filter
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBarWorkticketPreview}>
          <Toolbar>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={handleApply}
              className={classes.btnJobApply}
            >
              Apply
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" className={classes.appBarTitle}>
              {filterStateSelected.length} / {jobs.length} selected jobs for
              project
            </Typography>
          </Toolbar>
        </AppBar>
        <Box className={classes.filterJobContent}>
          {open ? (
            <Box>
              {filterState.length ? (
                <Box>
                  <Box className={classes.jobsTableCounter}>
                    <Typography
                      variant="h6"
                      className={classes.previewTableTitle}
                    >
                      {jobList.length} Jobs
                    </Typography>
                  </Box>
                  <Box className={classes.filterState}>
                    <FormSelectAuto
                      label="State"
                      options={filterState}
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="job_state"
                      handleChange={handleChangeState}
                      multiple={true}
                      value={filterStateValue}
                    />
                  </Box>
                </Box>
              ) : null}

              <TableContainer component={Box}>
                <Table className={classes.table} aria-label="table">
                  <TableHead className={classes.filterJobTableHeader}>
                    <TableRow>
                      <TableCell className={classes.filterJobTableCol}>
                        <Checkbox
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all jobs" }}
                          className={classes.tableCheckbox}
                          checked={filterStateSelected.length >= jobList.length}
                        />
                      </TableCell>
                      <TableCell>Job Number</TableCell>
                      <TableCell>Job Description</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Zip</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            inputProps={{ "aria-label": "select job" }}
                            className={classes.tableCheckbox}
                            checked={filterStateSelected.includes(row.id)}
                            onClick={(e) => clickRow(e, row.id)}
                          />
                        </TableCell>
                        <TableCell>{row.job_number}</TableCell>
                        <TableCell>{row.job_description}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.zip}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProjectCreateJobs;
