import React, { Fragment, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function CityFilter({
  classes,
  cityQuery,
  filterStateSelected,
  clickRow,
  filterStateValue,
  cityLoading,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [statesToFilter, setStatesToFilter] = useState(filterStateValue);

  const filteredCities = cityQuery?.data?.filter(
    (city) =>
      city.city_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (statesToFilter.length === 0 ||
        statesToFilter.some((state) => state.value === city.state_abbreviation))
  );

  function handleStateChange(event, value) {
    setStatesToFilter(value);
  }

  return (
    <Fragment>
      {cityLoading && <LoadingIndicator />}
      {!cityLoading && (
        <Fragment>
          <Box className={classes.servicedSearchContainerFilters}>
            <Typography variant={"h6"} className={classes.previewTableTitle}>
              {filteredCities.length} Cities
            </Typography>
            <Box className={classes.searchServicedFilterContainer}>
              <FormSelectAuto
                label="States Serviced"
                options={filterStateValue}
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                handleChange={handleStateChange}
                multiple={true}
                value={statesToFilter}
              />
              <TextField
                placeholder="Search City"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  disableunderline: "true",
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.textFieldSearchFilter}
              />
            </Box>
          </Box>

          <TableContainer component={Box}>
            <Table className={classes.table} aria-label="table">
              <TableHead className={classes.filterJobTableHeader}>
                <TableRow>
                  <TableCell className={classes.filterJobTableCol}></TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCities.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        inputProps={{ "aria-label": "select job" }}
                        className={classes.tableCheckbox}
                        checked={filterStateSelected.includes(row.id)}
                        onClick={(e) => clickRow(e, row.id, row.city_name)}
                      />
                    </TableCell>
                    <TableCell>{row.city_name}</TableCell>
                    <TableCell>{row.state_abbreviation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Fragment>
  );
}
