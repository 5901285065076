import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import * as classNames from "classnames";
import useStyles from "./styles";

const tabLabels = [
  {
    label: "Internal",
    key: "internal",
    permission: permissionWorkticket.COMMENTS_INTERNAL,
  },
  {
    label: "Partner",
    key: "partner",
    permission: permissionWorkticket.COMMENTS_PARTNER,
  },
  {
    label: "Customer",
    key: "customer",
    permission: permissionWorkticket.COMMENTS_CUSTOMER,
  },
];

const TabComment = ({ onChangeTab, activeTab }) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;

  const handleChange = (event, newTab) => {
    onChangeTab(newTab);
  };

  return (
    <Box className={classes.commentTabContainer}>
      {role?.category !== "subcontractor" && (
        <Tabs
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={handleChange}
        >
          {tabLabels.map(
            (tab, index) =>
              hasPermission(tab.permission, permissions) && (
                <Tab
                  className={classNames(
                    { [classes.rootTabs]: activeTab === tab.key },
                    classes.customTab
                  )}
                  key={tab.key}
                  value={tab.key}
                  label={tab.label}
                />
              )
          )}
        </Tabs>
      )}
    </Box>
  );
};
export default TabComment;
