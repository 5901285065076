import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { dateFormatField } from "components/util/timeFormat";
import VideoPlayer from "./videoPlayer";
import useStyles from "./styles";

const VideoLinkPreviewComponent = (props) => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    videoUrl,
    description,
    owner,
    createdAt,
    displayName,
    workTicketNumber,
  } = props;

  const playerRef = useRef(null);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.videoPreviewHeaderContainer}>
        <IconButton
          size="small"
          className={classes.closeIconButtonDialog}
          onClick={handleClose}
          style={{ color: "#fff", paddingLeft: 25 }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography variant="h6" className={classes.videoPreviewTitle}>
            {workTicketNumber}
          </Typography>
        </Box>
      </Box>

      <DialogContent>
        <Box className={classes.videoPreviewContainer}>
          <VideoPlayer
            url={videoUrl}
            playerRef={playerRef}
            isFullScreen={false}
            fullScreenPlayer={true}
            onVideoDurationChange={() => {}}
          />
          <Typography variant="h6" className={classes.imageNameStyle}>
            {displayName}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              className={classes.videoPreviewDescription}
            >
              {description}
            </Typography>
          )}
          <Typography variant="h6" className={classes.videoPreviewMetadata}>
            Uploaded By: {owner} | Uploaded: {dateFormatField(createdAt)}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoLinkPreviewComponent;
