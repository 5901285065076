import React, { useContext, lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";

import GlobalUiContext from "contexts/globalUiContext";
import { RequestProvider } from "contexts/requestContext";
import { RequestProvider as RequestSurfaceProvider } from "contexts/requestSurfaceContext";
import { RequestActionProvider } from "contexts/requestActionContext";
import { permissionSurface, hasPermission } from "lib/permissions";

import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const RequestsTable = lazy(() => import("./requestsTable"));
const RequestsSurfaceTable = lazy(() => import("./requestsSurfaceTable"));
const RequestsDialog = lazy(() => import("./dialog/requestsDialog"));
const RequestsSurfaceDialog = lazy(() =>
  import("./dialog/requestsSurfaceDialog")
);

const RequestsList = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      <PageHeader pageTitle="Requests" link="/requests" />
      <Box className={classes.pageBodyWrapper}>
        {hasPermission(permissionSurface.REQUEST, permissions) ? (
          <RequestSurfaceProvider>
            <RequestActionProvider>
              <Suspense
                fallback={
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                }
              >
                <RequestsSurfaceTable />
                <RequestsSurfaceDialog />
              </Suspense>
            </RequestActionProvider>
          </RequestSurfaceProvider>
        ) : (
          <RequestProvider>
            <RequestActionProvider>
              <Suspense
                fallback={
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                }
              >
                <RequestsTable />
                <RequestsDialog />
              </Suspense>
            </RequestActionProvider>
          </RequestProvider>
        )}
      </Box>
    </>
  );
};

export default withTableFilterContext(RequestsList);
