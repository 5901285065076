import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  newVersionQuestion,
  getQuestionForSubmit,
  getTasksForSubmit,
} from "services/surveyFrontlineService";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import { surveysLabelsLang } from "constants.js";

import useStyles from "./styles";

const AnswerAgainDialog = (props) => {
  const classes = useStyles();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const {
    openAnswerAgain,
    selectedQuestions,
    correlative,
    lang,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();

  const handleClose = () => {
    dispatchSurveyFill({
      type: "TOGGLE_OPEN_ANSWER_AGAIN",
      openAnswerAgain: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      await newVersionQuestion(selectedQuestions[correlative].id);
      const resultQuestion = await getQuestionForSubmit(
        selectedQuestions[correlative].qa_survey_submit_id,
        selectedQuestions[correlative].qa_question_id
      );
      const { questions: selectedQues } = resultQuestion.data.data;
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: selectedQues,
      });
      if (selectedQues[selectedQues.length - 1].type === 1) {
        const resultTasks = await getTasksForSubmit(
          selectedQues[selectedQues.length - 1].id
        );
        const { tasks: selectedTasks } = resultTasks.data.data;
        dispatchSurveyFill({
          type: "SET_SELECTED_TASKS",
          selectedTasks: selectedTasks,
        });
      }
      dispatchSurveyFill({
        type: "SET_CORRELATIVE",
        correlative: selectedQues.length - 1,
      });
      dispatchSurveyFill({
        type: "SET_SELECTED_FILES",
        selectedFiles: [],
      });
      dispatchSurveyFill({
        type: "SET_SELECTED_COMMENTS",
        selectedComments: [],
      });
      setIsLoadingDialog(false);
      handleClose();
      dispatchSurveyFill({
        type: "TOGGLE_NEW_ANSWER_INDICATOR",
        showNewAnswerIndicator: true,
      });
      setTimeout(function () {
        dispatchSurveyFill({
          type: "TOGGLE_NEW_ANSWER_INDICATOR",
          showNewAnswerIndicator: false,
        });
      }, 10000);
    } catch (e) {
      console.log("Cannot create new version of queston " + e);
    }
  };

  return (
    <Dialog
      open={openAnswerAgain}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {isLoadingDialog ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              {surveysLabelsLang[lang].answerAgainHeaderMsgLabel}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              {surveysLabelsLang[lang].answerAgainBodyMsgLabel}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleConfirm}
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              startIcon={<ReplayIcon className={classes.againIcon} />}
            >
              {surveysLabelsLang[lang].answerAgainLabel}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default AnswerAgainDialog;
