import React from "react";
import Box from "@material-ui/core/Box";
import ResultsTable from "./resultsTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import ResultsListDialog from "./dialog/resultsListDialog";
import { ResultsProvider } from "contexts/surveyResultsContext";
import { SurveyFillProvider } from "contexts/surveyFillContext";
import ViewDialog from "./dialog/viewDialog";
import SurveyContent from "pages/survey/surveyContent";
import useStyles from "./styles";

const ResultsList = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageBodyWrapper}>
      <ResultsProvider>
        <ResultsTable />
        <ResultsListDialog />
        <SurveyFillProvider>
          <ViewDialog />
          <SurveyContent />
        </SurveyFillProvider>
      </ResultsProvider>
    </Box>
  );
};

export default withTableFilterContext(ResultsList);
