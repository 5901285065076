import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";

const apiEndpoint = `${apiUrl}/qa/question`;

export function getQuestionsList(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}`, {});
}

export function getQuestionsListSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}search=${search}`, {});
}

export function getQuestionsListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}s?${filterString}offset=${offset}`, {});
}

export function createQuestion(data) {
  http.setFormEncode();
  return http.post(`${apiEndpoint}`, data);
}

export function updateQuestion(id, data) {
  http.setFormEncode();
  return http.put(`${apiEndpoint}s/${id}`, data);
}

export function deleteQuestion(id) {
  return http.delete(`${apiEndpoint}s/${id}`, {});
}

export function getQuestion(id) {
  return http.get(`${apiEndpoint}s/${id}`, {});
}
