import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { displayFieldBudget as displayField } from "components/util/stringFormat";
import { useBudgetView } from "contexts/budgetViewContext";

import useStyles from "./styles";

const headerColumns = ["Budget", "Category", "Date Range", "Value"];

const BudgetOverviewTable = (props) => {
  const classes = useStyles();
  const [stateContext] = useBudgetView();
  const { budget, isLoading } = stateContext ?? null;

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell className={classes.rowHeader} key={column}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {budget.budget_item.map((item) => (
              <TableRow tabIndex={-1} key={item.id}>
                <TableCell>{item.field}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.date_range}</TableCell>
                <TableCell align="right">
                  {displayField(item.measure, item.value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BudgetOverviewTable;
