import React from "react";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

const BudgetBreadcrumb = (props) => {
  const { type } = props;
  return (
    <PageHeader pageTitle={"Budget"}>
      <Link to={`/budgets${type === "3" ? `/portfolio` : ``}`}>Budgets</Link>
    </PageHeader>
  );
};

export default BudgetBreadcrumb;
