import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import DoneIcon from "@material-ui/icons/CheckCircle";
import { surveysSubmitQAStatuses } from "constants.js";
import { useSurveyFillState } from "contexts/surveyFillContext";

const SurveyStatusChip = (props) => {
  const classes = useStyles();
  const { lang } = useSurveyFillState();
  const { status } = props;

  const statusSelected = surveysSubmitQAStatuses.find(
    (statusItem) => statusItem.value === status
  );

  return (
    <Box
      className={
        status === 2 ? classes.radiusStatusChipDone : classes.radiusStatusChip
      }
    >
      {status === 2 ? (
        <DoneIcon className={classes.iconChip} />
      ) : (
        <PlayCircleFilledWhiteIcon className={classes.iconChip} />
      )}
      <Typography className={classes.chipFontColor}>
        {lang === "Spanish" ? statusSelected.labelES : statusSelected.label}
      </Typography>
    </Box>
  );
};
export default SurveyStatusChip;
