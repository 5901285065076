import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  useLearnPageState,
  useLearnPageDispatch,
} from "contexts/learnPageContext";

import useStyles from "./styles";

const ViewVideoDialog = (props) => {
  const classes = useStyles();
  const { openViewVideo, viewURL } = useLearnPageState();
  const dispatchLearnPage = useLearnPageDispatch();

  const handleClose = () => {
    dispatchLearnPage({
      type: "TOGGLE_VIEW_VIDEO",
      openViewVideo: false,
    });
  };

  return (
    <Dialog
      open={openViewVideo}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogContent className={classes.wrapperDialog2}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.wrapperDialogClose2} />
        </IconButton>
        <iframe
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          width="100%"
          height="100%"
          scrolling="auto"
          src={viewURL}
          title="Video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};
export default ViewVideoDialog;
