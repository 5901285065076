import React from "react";
import * as noteService from "../services/noteService";
import { merge } from "lodash";

const defaultState = {
  noteListLoading: true,
  noteSelected: null,
  noteList: [],
  noteCount: [],
  noteDetails: null,
  noteDetailsAction: null,
};

const NoteStateContext = React.createContext();
const NoteDispatchContext = React.createContext();

function noteReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        noteListLoading: action.noteListLoading,
      };
    case "SET_NOTE_LIST":
      return {
        ...state,
        noteList: action.noteList,
      };
    case "SET_NOTE_COUNT":
      return {
        ...state,
        noteCount: action.noteCount,
      };
    case "SET_NOTE_SELECTED":
      return {
        ...state,
        noteSelected: action.noteSelected,
      };
    case "SET_NOTE_DETAILS":
      return {
        ...state,
        noteDetails: action.noteDetails,
      };
    case "SET_NOTE_DETAILS_ACTION":
      return {
        ...state,
        noteDetailsAction: action.noteDetailsAction,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NoteProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(noteReducer, mergedState);
  return (
    <NoteStateContext.Provider value={state}>
      <NoteDispatchContext.Provider value={dispatch}>
        {children}
      </NoteDispatchContext.Provider>
    </NoteStateContext.Provider>
  );
}

function useNoteState() {
  const context = React.useContext(NoteStateContext);
  if (context === undefined) {
    throw new Error("useNoteState must be used within a NoteProvider");
  }
  return context;
}

function useNoteDispatch() {
  const context = React.useContext(NoteDispatchContext);
  if (context === undefined) {
    throw new Error("useNoteDispatch must be used within a NoteProvider");
  }
  return context;
}

async function getNoteList(dispatch, filterData, isDashboard, dashboardState) {
  try {
    dispatch({
      type: "SET_LOADING",
      noteListLoading: true,
    });
    const noteListResult = isDashboard
      ? await noteService.getDashboardNotes(filterData, dashboardState)
      : await noteService.getNotes(filterData);

    dispatch({
      type: "SET_NOTE_LIST",
      noteList: [...noteListResult.data.data.notes],
    });
    dispatch({
      type: "SET_NOTE_COUNT",
      noteCount: noteListResult.data.data.filter_count,
    });
    dispatch({
      type: "SET_LOADING",
      noteListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getNoteListDataSet(
  noteList,
  dispatch,
  offset,
  filterData,
  isDashboard,
  dashboardState
) {
  try {
    const result = isDashboard
      ? await noteService.getDashboardNotesOffset(
          offset,
          filterData,
          dashboardState
        )
      : await noteService.getNotesOffset(offset, filterData);
    const updateData = noteList.concat(result.data.data.notes);
    dispatch({
      type: "SET_NOTE_LIST",
      noteList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchNotes(search, filterData, isDashboard, dashboardState) {
  try {
    const noteListResult = isDashboard
      ? await noteService.getDashboardNotesSearch(
          search,
          filterData,
          dashboardState
        )
      : await noteService.getNotesSearch(search, filterData);
    return noteListResult.data.data.notes;
  } catch (error) {
    console.log(error);
  }
}

export {
  NoteProvider,
  useNoteState,
  useNoteDispatch,
  getNoteList,
  searchNotes,
  getNoteListDataSet,
};
