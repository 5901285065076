import React from "react";
import Chip from "@material-ui/core/Chip";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import PhotoIcon from "@material-ui/icons/Photo";
import { truncateString } from "components/util/stringFormat";
import { logException } from "components/util/logUtil";
import { deleteFile } from "services/fileService";
import useStyles from "./styles";

const imagesTypes = ["gif", "png", "jpg", "bmp", "webp"];

const FilesDisplayUploaded = (props) => {
  const classes = useStyles();
  const { files, handleRemoveDisplay, restrictRemove, controlled } = props;

  const handleClick = (file) => {
    window.open(file.file_url, "_blank");
  };

  const handleDelete = async (id) => {
    try {
      handleRemoveDisplay(id);
      if (!controlled) {
        await deleteFile(id);
      }
    } catch (e) {
      logException(e, "Cannot remove file");
    }
  };

  const getExtension = (fileName) => {
    return fileName.substr(fileName.lastIndexOf(".") + 1);
  };

  return files
    .filter(
      (itemF) =>
        !itemF.is_thumbnail &&
        ((itemF.pivot && !Number(itemF?.pivot.is_signature)) || !itemF.pivot)
    )
    .map((fileE) => {
      return (
        <Chip
          icon={
            imagesTypes.includes(getExtension(fileE.file_name)) ? (
              <PhotoIcon />
            ) : (
              <FileIcon />
            )
          }
          label={truncateString(fileE.file_name, 25)}
          className={classes.fileIcon}
          onDelete={restrictRemove ? undefined : () => handleDelete(fileE.id)}
          onClick={() => handleClick(fileE)}
          key={fileE.id}
        />
      );
    });
};

export default FilesDisplayUploaded;
