import React from "react";
import Typography from "@material-ui/core/Typography";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pieRoot: {
    position: "relative",
    paddingLeft: 50,
  },
  pieValue: {
    zIndex: 999,
    position: "absolute",
    top: 90,
    left: 75,
    fontSize: 24,
    color: "#9B9B9B",
    [theme.breakpoints.down("sm")]: {
      left: 115,
    },
  },
}));

const ChartPieTab = (props) => {
  const classes = useStyles();
  const {
    pct,
    width,
    cx,
    cy,
    height,
    colors,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
  } = props;

  const data = [
    { name: "Value", value: pct },
    { name: "Total", value: 100 - pct },
  ];

  return (
    <div className={classes.pieRoot}>
      <Typography variant="h1" className={classes.pieValue}>
        {parseInt(pct).toFixed(0) < 100 ? parseInt(pct).toFixed(0) : 100}%
      </Typography>
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey="value"
            startAngle={startAngle}
            endAngle={endAngle}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartPieTab;
