import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import { industryList } from "constants.js";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  wrapperJobFilter: {
    position: "relative",
  },
  btnJobFilter: {
    textTransform: "capitalize",
    position: "absolute",
    top: -2,
    right: 0,
  },
  btnJobApply: {
    textTransform: "capitalize",
    minWidth: 150,
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "right",
  },
  filterJobContent: {
    padding: theme.spacing(2),
  },
  filterState: {
    marginTop: 60,
    float: "right",
    display: "flex",
    "& > div": {
      marginBottom: 0,
      marginLeft: 4,
      width: 280,
    },
  },
  jobsTableCounter: {
    position: "absolute",
    top: 95,
    left: 20,
  },
}));

const FilterJobsAdvance = ({
  jobs,
  jobsSelected,
  customersSelected,
  changeJobFilterList,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [jobList, setJobList] = useState([]);

  const [filterStateSelected, setFilterStateSelected] = useState([]);
  const [filterCustomerValue, setFilterCustomerValue] = useState([]);
  const [filterIndustryValue, setFilterIndustryValue] = useState([]);

  useEffect(() => {
    const loadState = () => {
      const selectedList = jobsSelected.map((item) => item.id);
      setFilterStateSelected(selectedList);
      setFilterCustomerValue([]);
      setFilterIndustryValue([]);
      setJobList(jobs);
    };

    if (open) {
      loadState();
    }
  }, [jobs, jobsSelected, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleApply = () => {
    changeJobFilterList(filterStateSelected);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectAllClick = () => {
    if (jobList.length === filterStateSelected.length) {
      setFilterStateSelected([]);
      return;
    }
    const jobsAll = jobList.map((job) => job.id);
    setFilterStateSelected([...jobsAll]);
  };

  const clickRow = (event, id) => {
    if (filterStateSelected.includes(id)) {
      filterStateSelected.splice(filterStateSelected.indexOf(id), 1);
    } else {
      filterStateSelected.push(id);
    }
    setFilterStateSelected([...filterStateSelected]);
  };

  const handleChangeCustomer = (event, value) => {
    setFilterCustomerValue(value ?? []);
    const indList = filterIndustryValue.map((item) => item.value);
    if (!value.length) {
      setJobList(
        jobs.filter(
          (job) =>
            indList.length === 0 || indList.includes(Number(job.industry))
        )
      );
      return;
    }
    const customerList = value.map((item) => item.value);

    const filterJobList = jobs.filter(
      (job) =>
        customerList.includes(job.customer_id) &&
        (indList.length === 0 || indList.includes(Number(job.industry)))
    );
    setJobList(filterJobList);
  };

  const handleChangeIndustry = (event, value) => {
    setFilterIndustryValue(value ?? []);
    const customerList = filterCustomerValue.map((item) => item.value);
    if (!value.length) {
      setJobList(
        jobs.filter(
          (job) =>
            customerList.length === 0 || customerList.includes(job.customer_id)
        )
      );
      return;
    }
    const indList = value.map((item) => item.value);

    const filterJobList = jobs.filter(
      (job) =>
        indList.includes(Number(job.industry)) &&
        (customerList.length === 0 || customerList.includes(job.customer_id))
    );
    setJobList(filterJobList);
  };

  return (
    <Box className={classes.wrapperJobFilter}>
      <Button
        variant="outlined"
        color={"secondary"}
        className={classes.btnJobFilter}
        startIcon={<FilterListIcon />}
        onClick={handleOpen}
        disabled={!jobs.length}
      >
        Filter
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar color="secondary" className={classes.appBarWorkticketPreview}>
          <Toolbar>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={handleApply}
              className={classes.btnJobApply}
            >
              Apply
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" className={classes.appBarTitle}>
              {filterStateSelected.length} / {jobs.length} selected jobs for
              survey
            </Typography>
          </Toolbar>
        </AppBar>
        <Box className={classes.filterJobContent}>
          {open ? (
            <Box>
              <Box>
                <Box className={classes.jobsTableCounter}>
                  <Typography
                    variant="h6"
                    className={classes.previewTableTitle}
                  >
                    {jobList.length} Jobs
                  </Typography>
                </Box>
                <Box className={classes.filterState}>
                  <FormSelectAuto
                    options={customersSelected}
                    name="customers"
                    multiple={true}
                    handleChange={handleChangeCustomer}
                    label="Customers"
                    value={filterCustomerValue}
                  />
                  <FormSelectAuto
                    label="Industry"
                    options={industryList}
                    name="industry"
                    handleChange={handleChangeIndustry}
                    multiple={true}
                    value={filterIndustryValue}
                  />
                </Box>
              </Box>

              <TableContainer component={Box}>
                <Table className={classes.table} aria-label="table">
                  <TableHead className={classes.filterJobTableHeader}>
                    <TableRow>
                      <TableCell className={classes.filterJobTableCol}>
                        <Checkbox
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all jobs" }}
                          className={classes.tableCheckbox}
                          checked={filterStateSelected.length >= jobList.length}
                        />
                      </TableCell>
                      <TableCell>Job Number</TableCell>
                      <TableCell>Job Description</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Industry</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>State</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            inputProps={{ "aria-label": "select job" }}
                            className={classes.tableCheckbox}
                            checked={filterStateSelected.includes(row.id)}
                            onClick={(e) => clickRow(e, row.id)}
                          />
                        </TableCell>
                        <TableCell>{row.job_number}</TableCell>
                        <TableCell>{row.job_description}</TableCell>
                        <TableCell>
                          {customersSelected.find(
                            (customerItem) =>
                              customerItem.value === Number(row.customer_id)
                          )?.label ?? "-"}
                        </TableCell>
                        <TableCell>
                          {industryList.find(
                            (industryItem) =>
                              industryItem.value === Number(row.industry)
                          )?.label ?? "-"}
                        </TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.state}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}
        </Box>
      </Dialog>
    </Box>
  );
};

export default FilterJobsAdvance;
