import React from "react";
import { merge } from "lodash";

const defaultState = {
  pageHeaderTabs: [],
  currentTab: null,
};

const PageHeaderStateContext = React.createContext();
const PageHeaderDispatchContext = React.createContext();

function pageHeaderReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.currentTab,
      };
    case "SET_PAGE_TAB":
      return {
        ...state,
        pageHeaderTabs: action.pageHeaderTabs,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function PageHeaderProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(pageHeaderReducer, mergedState);
  return (
    <PageHeaderStateContext.Provider value={state}>
      <PageHeaderDispatchContext.Provider value={dispatch}>
        {children}
      </PageHeaderDispatchContext.Provider>
    </PageHeaderStateContext.Provider>
  );
}

function usePageHeaderState() {
  const context = React.useContext(PageHeaderStateContext);
  if (context === undefined) {
    throw new Error(
      "usePageHeaderState must be used within a PageHeaderProvider"
    );
  }
  return context;
}

function usePageHeaderDispatch() {
  const context = React.useContext(PageHeaderDispatchContext);
  if (context === undefined) {
    throw new Error(
      "usePageHeaderDispatch must be used within a PageHeaderProvider"
    );
  }
  return context;
}

export { PageHeaderProvider, usePageHeaderState, usePageHeaderDispatch };
