import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = apiUrl + "/goods";

export function getGoods(commodity) {
  return http.get(`${apiEndpoint}/`, {
    params: { commodity },
  });
}

export function getGoodsAdmin(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/admin?${filterString}`);
}
