import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import React from "react";
import useStyles from "./styles";

export default function DeleteDialog({ open, handleClose, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Schedule Updated
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              The Schedule has been updated.
            </Typography>
          </DialogContent>

          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              autoFocus
              onClick={() => setOpen(false)}
              className={classes.markFilledButton}
            >
              Close
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}
