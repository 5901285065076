import Integer from "lodash";
import { useState } from "react";
import { useMutation } from "react-query";
import { addUserLocations } from "../../../services/partners/usersService";
import { logException } from "../../../components/util/logUtil";

export function useLocation() {
  const [jobsValue, setJobsValue] = useState([]);
  const [customerJobData, setCustomerJobData] = useState({
    jobs: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleJobChange = async (
    value,
    userId,
    setSelectedLocations,
    selectedLocations
  ) => {
    setJobsValue(value ?? []);
    const jobs = value.map((item) => Integer.parseInt(item.value));
    setCustomerJobData({ ...customerJobData, jobs });
    setSelectedLocations({ ...selectedLocations, [userId]: jobs });
  };
  const handleSingleJobChange = async (value) => {
    setJobsValue(value ?? []);
    const jobs = value.map((item) => Integer.parseInt(item.value));
    setCustomerJobData({ ...customerJobData, jobs });
  };

  const assignUserLocationMutation = useMutation(addUserLocations, {
    onSuccess: () => {
      setIsSubmitting(false);
    },
    onError: (error) => {
      logException(error, "Failed to assign Location");
    },
  });
  return {
    handleJobChange,
    jobsValue,
    isSubmitting,
    assignUserLocationMutation,
    handleSingleJobChange,
  };
}
