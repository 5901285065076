import React from "react";
import PageHeader from "../../components/common/PageHeader/pageHeader";
import TimekeepingTable from "./timekeepingTable";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { TimekeepingProvider } from "contexts/timekeepingContext";

import useStyles from "./styles";

const TimekeepingList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        pageTitle="Timekeeping"
        link="/financial/report/timekeeping"
      />
      <Box className={classes.pageBodyWrapper}>
        <TimekeepingProvider>
          <TimekeepingTable />
        </TimekeepingProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(TimekeepingList);
