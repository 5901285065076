import React from "react";
import {
  getTriggersListT,
  getTriggersListTSearch,
  getTriggersListTOffset,
} from "services/triggersService";
import { merge } from "lodash";

const defaultState = {
  triggersLoading: true,
  triggersSelected: null,
  triggersList: [],
  triggersCount: [],
  openTriggersDelete: false,
  openManageTriggers: false,
};

const TriggersStateContext = React.createContext();
const TriggersDispatchContext = React.createContext();

function triggersReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        triggersLoading: action.triggersLoading,
      };
    case "SET_TRIGGERS_LIST":
      return {
        ...state,
        triggersList: action.triggersList,
      };
    case "SET_TRIGGERS_COUNT":
      return {
        ...state,
        triggersCount: action.triggersCount,
      };
    case "SET_TRIGGERS_SELECTED":
      return {
        ...state,
        triggersSelected: action.triggersSelected,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openTriggersDelete: action.openTriggersDelete,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageTriggers: action.openManageTriggers,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TriggersProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(triggersReducer, mergedState);
  return (
    <TriggersStateContext.Provider value={state}>
      <TriggersDispatchContext.Provider value={dispatch}>
        {children}
      </TriggersDispatchContext.Provider>
    </TriggersStateContext.Provider>
  );
}

function useTriggersState() {
  const context = React.useContext(TriggersStateContext);
  if (context === undefined) {
    throw new Error("useTriggersState must be used within a TriggersProvider");
  }
  return context;
}

function useTriggersDispatch() {
  const context = React.useContext(TriggersDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useTriggersDispatch must be used within a TriggersProvider"
    );
  }
  return context;
}

async function getTriggersList(dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      triggersLoading: true,
    });
    const result = await getTriggersListT(filterData);
    dispatch({
      type: "SET_TRIGGERS_LIST",
      triggersList: [...result.data.data.triggers],
    });
    dispatch({
      type: "SET_TRIGGERS_COUNT",
      triggersCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      triggersLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getTriggersListDataSet(
  triggersList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getTriggersListTOffset(offset, filterData);
    const updateData = triggersList.concat(result.data.data.triggers);
    console.log(updateData);
    dispatch({
      type: "SET_TRIGGERS_LIST",
      triggersList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchTriggersList(search, filterData) {
  try {
    const result = await getTriggersListTSearch(search, filterData);
    return result.data.data.triggers;
  } catch (error) {
    console.log(error);
  }
}

export {
  TriggersProvider,
  useTriggersState,
  useTriggersDispatch,
  getTriggersListDataSet,
  getTriggersList,
  searchTriggersList,
};
