import React, { useState } from "react";
import { ButtonBase } from "components/ui/core/button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Notes from "components/ui/Financial/Note";
import DescriptionIcon from "@material-ui/icons/Description";
import { getFinancialAPCheckDetailSub } from "services/financialService";
import { formatStringToNumber } from "components/util/stringFormat";
import { convertDateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: "invoice.check_number", label: "Check Number", width: 150 },
  { id: "check.date", label: "Check Date", width: 150 },
  { id: "check.amount", label: "Check Amount", width: 150 },
  { id: "correlative", label: "", width: 50 },
  { id: "invoice.invoice_number", label: "Work Number" },
  { id: "invoice.workticket.subject", label: "", width: 40 },
  { id: "invoice.workticket.due_date", label: "Work Due Date", width: 150 },
  {
    id: "invoice.workticket.sub_completed_date",
    label: "Completed Date",
    width: 150,
  },
  { id: "invoice.invoice_amount", label: "Amount", width: 150 },
  { id: "invoice.paid_amount", label: "Paid", width: 150 },
];

const CheckDetail = (props) => {
  const classes = useStyles();

  const [list, setList] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { check } = props;

  const handleOpenDetail = async () => {
    setOpenDetail(true);
    try {
      setIsLoading(true);
      const result = await getFinancialAPCheckDetailSub(check.id);
      setList(result.data.data.records);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot load check detail");
    }
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setList([]);
  };

  return (
    <>
      <ButtonBase color="secondary" size="small" onClick={handleOpenDetail}>
        Detail
      </ButtonBase>
      <Dialog
        fullScreen
        open={openDetail}
        onClose={handleCloseDetail}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDetail}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className={classes.wrapperDetail}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        className={classes.rowHeader}
                        style={{ width: column.width }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((row, index) => {
                    if (!row.invoice) return null;
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        {index === 0 ? (
                          <>
                            <TableCell>{row.invoice?.check_number}</TableCell>
                            <TableCell>
                              {convertDateFormatField(check.date)}
                            </TableCell>
                            <TableCell align="right">
                              {formatStringToNumber(check.amount)}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell colSpan="3"></TableCell>
                        )}
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.invoice?.invoice_number}</TableCell>
                        <TableCell>
                          {row.invoice?.workticket ? (
                            <Notes
                              notes={row.invoice?.workticket.subject}
                              icon={<DescriptionIcon />}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {row.invoice?.workticket
                            ? convertDateFormatField(
                                row.invoice.workticket.start_date
                              )
                            : null}
                        </TableCell>
                        <TableCell>
                          {row.invoice?.workticket
                            ? row.invoice.workticket.sub_completed_date
                              ? convertDateFormatField(
                                  row.invoice.workticket.sub_completed_date
                                )
                              : convertDateFormatField(
                                  row.invoice.workticket.start_date
                                )
                            : null}
                        </TableCell>
                        <TableCell align="right">
                          {formatStringToNumber(row.invoice?.invoice_amount)}
                        </TableCell>
                        <TableCell align="right">
                          {formatStringToNumber(row.invoice?.paid_amount)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default CheckDetail;
