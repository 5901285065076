import http from "./httpService";
import moment from "moment";
import { apiUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = apiUrl + "/quotes";
const apiEndpointSingle = apiUrl + "/quote";

export function getQuoteDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {
    params: {},
  });
}

export function quoteGenerator(data) {
  return http.post(`${apiUrl}/generator`, data);
}

export function deleteQuoteGenerator(id) {
  return http.delete(`${apiUrl}/generators/${id}`);
}

export function updateQuoteGenerator(id, data) {
  return http.put(`${apiUrl}/generators/${id}`, data);
}

export function initializeQuoteGenerator(data) {
  return http.post(`${apiUrl}/generators/initialize`, data);
}

export function specialQuoteGenerator(id, data) {
  return http.post(`${apiUrl}/generators/${id}/special-update`, data);
}

export function getQuotePlanning(id) {
  return http.get(`${apiEndpoint}/${id}/planningWT`, {
    params: {},
  });
}

export function createWTPlanning(data) {
  return http.post(`${apiEndpoint}/planningWT`, data);
}

export function deleteWTPlanning(id) {
  return http.delete(`${apiEndpoint}/planningWT/${id}`, {});
}

export function updateQuote(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function addQuoteItem(quoteId, data) {
  return http.post(`${apiEndpoint}/${quoteId}/add-quote-items`, data);
}

export function addQuoteItemPreview(quoteId, data) {
  return http.post(`${apiEndpoint}/${quoteId}/add-quote-items-preview`, data);
}

export function getQuotePrivew(quoteId) {
  return http.post(`${apiEndpoint}/${quoteId}/quote-items-preview`, {});
}

export function addFilesQuoteItem(quoteItemId, data) {
  return http.post(
    `${apiEndpoint}/${quoteItemId}/add-quote-items-files`,
    setFormData(data)
  );
}

export function addFilesQuoteItemDuplicate(quoteItemId, data) {
  return http.post(
    `${apiEndpoint}/${quoteItemId}/add-quote-items-files-duplicate`,
    data
  );
}

export function updateQuoteItem(quoteId, data) {
  return http.post(`${apiEndpoint}/${quoteId}/update-quote-items`, data);
}

export function validateUpdateQuoteItem(quoteId, data) {
  return http.post(
    `${apiEndpoint}/${quoteId}/validate-update-quote-items`,
    data
  );
}

export function deleteQuoteItem(quoteId, data) {
  return http.delete(`${apiEndpoint}/${quoteId}/delete-quote-items`, { data });
}

export function addQuoteComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comment`, data);
}

export function updateQuoteComment(id, idComment, data) {
  return http.put(`${apiEndpoint}/${id}/comments/${idComment}`, data);
}

export function deleteQuoteComment(id, idComment) {
  return http.delete(`${apiEndpoint}/${id}/comments/${idComment}`);
}

export function deleteQuote(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function getQuotes(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function saveQuoteFile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function getQuotesTab(tab, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}filters[tab]=${tab}`, {});
}

export function getQuotesTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function updateQuotesBulk(data) {
  return http.post(`${apiEndpoint}/update/bulk`, data);
}

export function changeQuoteProject(id, data) {
  return http.post(`${apiEndpoint}/${id}/changeProject`, data);
}

export function cloneQuote(id) {
  return http.post(`${apiEndpoint}/${id}/clone`, {});
}

export function sendQuote(id, data) {
  return http.post(`${apiEndpoint}/${id}/send`, data);
}

export function createQuote(data) {
  return http.post(`${apiEndpointSingle}`, data);
}

export function getQuotesSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getQuotePdf(id, contactId, contactType) {
  return http.get(`${apiEndpoint}/${id}/pdf/${contactId}/${contactType}`, {
    responseType: "blob",
  });
}

export function getContacts(id) {
  return http.get(`${apiEndpoint}/${id}/contacts`, {});
}

export function approveQuote(id) {
  return http.post(`${apiEndpoint}/${id}/approve`, {});
}

export function approveQuoteTicketLess(id) {
  return http.post(`${apiEndpoint}/${id}/create-ticketless`, {});
}

export function createWTQuote(id) {
  return http.post(`${apiEndpoint}/${id}/createWorktickets`, {});
}

export function updateQuoteItemPlanning(id, data) {
  return http.put(`${apiEndpoint}/${id}/planningWT`, data);
}

export function getQuoteItemsPlanning(id) {
  return http.get(`${apiEndpoint}/${id}/planningWT`, {
    params: {},
  });
}

export function filter(search, filters) {
  let queryString = "";

  if (search || (filters && filters.length > 0)) {
    if (search) {
      queryString += "search=" + search + "&";
    }

    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (filter.group !== "start_date" || filter.group !== "end_date") {
          //include the double array for non-date
          queryString += `filters[${filter.optionGroup}][]=${filter.option}&`;
        } else {
          queryString += `filters[${filter.optionGroup}]=${filter.option}&`;
        }
      });

      queryString = queryString.slice(0, -1);
    }
    console.log("query string: " + queryString);
    return http.get(`${apiEndpoint}/search?${queryString}`, {});
  } else {
    return http.get(`${apiEndpoint}`, {
      params: {},
    });
  }
}

const getFilterString = (filterData) => {
  let queryString = "";
  if (filterData) {
    if (filterData.dateFilters) {
      if (filterData.dateFilters.startDateActive) {
        queryString += `filters[start_date]=${moment(
          filterData.dateFilters.startDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }

      if (filterData.dateFilters.endDateActive) {
        queryString += `filters[end_date]=${moment(
          filterData.dateFilters.endDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }
    }
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          queryString += `filters[${element.filter}][]=${item.value}&`;
        });
      });
    }
    console.log("query String", queryString);
  }

  if (queryString === "") {
    queryString = "filters[]&";
  }
  return queryString;
};

export function createBillingItem(id, data) {
  return http.post(`${apiEndpoint}/${id}/billing-item`, data);
}

export function removeBillingItem(id, data) {
  return http.post(`${apiEndpoint}/${id}/billing-item-delete`, data);
}
export function changeBillingItem(id, data) {
  return http.post(`${apiEndpoint}/${id}/billing-item-update`, data);
}
