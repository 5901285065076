import React, { useEffect } from "react";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FormInputScale = (props) => {
  const classes = useStyles();
  const {
    name,
    name2,
    label,
    labelTo,
    value,
    value2,
    type,
    handleBlur,
    handleBlur2,
    blurType,
    error,
    gridSizes,
    internal,
    readonly,
    readonly2,
    styleOverride,
    validateOnType,
    sameLine,
    noMargin,
    alignText,
    withoutLabel,
    onChange,
    onChange2,
    custom,
    subLabel,
    minNumber,
    maxNumber,
  } = props;
  const [stateValue, setStateValue] = React.useState("");
  const [stateValue2, setStateValue2] = React.useState("");
  // const [gridSizeMd, setGridSizeMd] = useState(8);
  // const [gridSizeXs] = useState(12);
  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }
  let errorInput2 = undefined;
  if (error && error.length > 0) {
    errorInput2 = error.filter((item) => item.key === name2);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          // case "md":
          //   return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  useEffect(() => {
    if (value !== undefined) {
      setStateValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (value2 !== undefined) {
      setStateValue2(value2);
    }
  }, [value2]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (validateOnType) {
      validateOnType(event.target);
    }
  };

  const handleChange2 = (event) => {
    setStateValue2(event.target.value);
    if (validateOnType) {
      validateOnType(event.target);
    }
  };

  return (
    <div
      className={
        noMargin
          ? classes.noMargin
          : internal && sameLine
          ? custom
            ? classes.inputSameRowCustom
            : classes.inputSameRow
          : internal
          ? classes.inputInternalContainer
          : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        {!withoutLabel && (
          <Grid item xs={12} md={12} className={classes.labelContainer}>
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {label}
            </InputLabel>
            {subLabel ? (
              <InputLabel
                htmlFor={name}
                className={
                  internal
                    ? classes.inputInternalSubLabel
                    : classes.inputSubLabel
                }
              >
                {subLabel}
              </InputLabel>
            ) : (
              ""
            )}
          </Grid>
        )}
        <Grid item xs={5} md={3}>
          <Input
            name={name}
            id={name}
            fullWidth={true}
            disableUnderline={true}
            className={classNames(classes.inputField, {
              // [classes.disabled]: readonly
            })}
            classes={{
              focused: classes.inputFieldFocus,
              disabled: classes.inputFieldDisabled,
              input:
                alignText === "right"
                  ? classes.inputFieldRight
                  : alignText === "center"
                  ? classes.inputFieldCenter
                  : "",
            }}
            inputProps={{ autoComplete: "new-password" }}
            multiline={props.multiline ?? false}
            endAdornment={props.endAdornment}
            startAdornment={props.startAdornment}
            rows={props.rows ?? 1}
            value={stateValue}
            onChange={onChange ?? handleChange}
            style={styleOverride}
            onBlur={(event) =>
              handleBlur
                ? handleBlur(event.target, blurType ? blurType : "normal")
                : () => {}
            }
            disabled={readonly ? readonly : false}
            type={type ? type : "text"}
          />
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={2} md={2} className={classes.titleToScaleContainer}>
          <Typography variant="h6" className={classes.titleToScale}>
            {labelTo ?? "To"}
          </Typography>
        </Grid>
        <Grid item xs={5} md={3}>
          <Input
            name={name2}
            id={name2}
            fullWidth={true}
            disableUnderline={true}
            className={classNames(classes.inputField, {})}
            classes={{
              focused: classes.inputFieldFocus,
              disabled: classes.inputFieldDisabled,
              input:
                alignText === "right"
                  ? classes.inputFieldRight
                  : alignText === "center"
                  ? classes.inputFieldCenter
                  : "",
            }}
            inputProps={{
              autoComplete: "new-password",
              min: minNumber,
              max: maxNumber,
            }}
            multiline={props.multiline ?? false}
            endAdornment={props.endAdornment}
            startAdornment={props.startAdornment}
            rows={props.rows ?? 1}
            value={stateValue2}
            onChange={onChange2 ?? handleChange2}
            style={styleOverride}
            onBlur={(event) =>
              handleBlur2
                ? handleBlur2(event.target, blurType ? blurType : "normal")
                : () => {}
            }
            disabled={readonly2 ? readonly2 : false}
            type={"number"}
          />
          {errorInput2 && Boolean(errorInput2.length) && (
            <FormHelperText error>{errorInput2[0].message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormInputScale;
