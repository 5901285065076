import React, { lazy, Suspense } from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { DialogProvider } from "contexts/dialogContext";
import { DrawerProvider } from "contexts/detailsDrawerContext";
import { QuoteProvider } from "contexts/quoteSurfaceTableContext";
import { QuoteActionProvider } from "contexts/quoteActionContext";

import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuotesSurfaceTable = lazy(() => import("./quotesSurfaceTable"));
const QuotesDialog = lazy(() => import("./dialog/quotesDialog"));

const QuotesSurfaceList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Quotes" link="/quotes"></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <QuoteProvider>
          <DialogProvider>
            <DrawerProvider>
              <QuoteActionProvider>
                <Suspense
                  fallback={
                    <LoadingStateHorizontal
                      isVisible
                      style={classes.centerLoading}
                    />
                  }
                >
                  <QuotesSurfaceTable />
                  <QuotesDialog />
                </Suspense>
              </QuoteActionProvider>
            </DrawerProvider>
          </DialogProvider>
        </QuoteProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(QuotesSurfaceList);
