import React from "react";
import * as surveyService from "services/surveyService";
import merge from "lodash/merge";

const defaultState = {
  questionListLoading: true,
  questionSelected: null,
  questionList: [],
  questionCount: [],
  questionDetails: [],
  questionAction: null,
  questionRow: null,
  openQuestionDelete: false,
  openQuestionRequired: false,
  openManageQuestion: false,
  openQuestionAdd: false,
  assignedIds: [],
};

const QuestionStateContext = React.createContext();
const QuestionDispatchContext = React.createContext();

function questionReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        questionListLoading: action.questionListLoading,
      };
    case "SET_QUESTION_LIST":
      return {
        ...state,
        questionList: action.questionList,
      };
    case "SET_QUESTION_COUNT":
      return {
        ...state,
        questionCount: action.questionCount,
      };
    case "SET_QUESTION_SELECTED":
      return {
        ...state,
        questionSelected: action.questionSelected,
      };
    case "SET_QUESTION_DETAILS":
      return {
        ...state,
        questionDetails: action.questionDetails,
      };
    case "SET_QUESTION_ACTION":
      return {
        ...state,
        questionAction: action.questionAction,
      };
    case "SET_QUESTION_ROW":
      return {
        ...state,
        questionRow: action.questionRow,
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openQuestionDelete: action.openQuestionDelete,
      };
    case "TOGGLE_REQUIRED":
      return {
        ...state,
        openQuestionRequired: action.openQuestionRequired,
      };
    case "TOGGLE_MANAGE":
      return {
        ...state,
        openManageQuestion: action.openManageQuestion,
      };
    case "TOGGLE_ADD":
      return {
        ...state,
        openQuestionAdd: action.openQuestionAdd,
      };
    case "SET_ASSIGNED_IDS":
      return {
        ...state,
        assignedIds: action.assignedIds,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuestionProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(questionReducer, mergedState);
  return (
    <QuestionStateContext.Provider value={state}>
      <QuestionDispatchContext.Provider value={dispatch}>
        {children}
      </QuestionDispatchContext.Provider>
    </QuestionStateContext.Provider>
  );
}

function useQuestionState() {
  const context = React.useContext(QuestionStateContext);
  if (context === undefined) {
    throw new Error("useQuestionState must be used within a QuestionProvider");
  }
  return context;
}

function useQuestionDispatch() {
  const context = React.useContext(QuestionDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useQuestionDispatch must be used within a QuestionProvider"
    );
  }
  return context;
}

async function getQuestionList(survey_id, dispatch, filterData) {
  try {
    const questionListResult = await surveyService.getSurveyQuestions(
      survey_id,
      filterData
    );

    dispatch({
      type: "SET_QUESTION_LIST",
      questionList: questionListResult.data.data.list,
    });
    dispatch({
      type: "SET_QUESTION_COUNT",
      questionCount: questionListResult.data.data.list.length,
    });
    dispatch({
      type: "SET_LOADING",
      questionListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuestionListDataSet(
  survey_id,
  questionList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await surveyService.getSurveyQuestionsOffset(
      survey_id,
      offset,
      filterData
    );
    const updateData = questionList.concat(result.data.data.list);
    dispatch({
      type: "SET_QUESTION_LIST",
      questionList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchQuestions(survey_id, search, filterData) {
  try {
    const questionListResult = await surveyService.getSurveyQuestionsSearch(
      survey_id,
      search,
      filterData
    );
    return questionListResult.data.data.list;
  } catch (error) {
    console.log(error);
  }
}

export {
  QuestionProvider,
  useQuestionState,
  useQuestionDispatch,
  getQuestionList,
  searchQuestions,
  getQuestionListDataSet,
};
