import React, { useState } from "react";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import JobsListActions from "./jobsListActions";
import JobsTableActions from "./jobsTableActions";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { useProjectView } from "contexts/projectViewContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useJobsState, useJobsDispatch } from "contexts/projectJobsContext";
import { getPreventativeServices } from "services/projectService";
import { useFilterDispatch } from "contexts/filterContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import { useEffect } from "react";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "job_number",
    label: "Job Number",
    checkbox: {
      active: (row) => false,
    },
    style: { width: 150 },
  },
  {
    id: "job_description",
    label: "Job Name",
  },
  {
    id: "actions",
    content: (row) => <JobsTableActions row={row} />,
    contentMobile: (row) => <JobsTableActions row={row} mobile={true} />,
    style: { width: 55 },
  },
];

const JobsTable = (props) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const { jobListLoading, jobList, jobCount } = useJobsState();
  const [stateContextProject] = useProjectView();
  const dispatchJobs = useJobsDispatch();
  const dispatchTable = useTableDispatch();
  const { project } = stateContextProject ?? null;
  const { selected } = useTableState();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const resultService = await getPreventativeServices(project.id);
        dispatchJobs({
          type: "SET_JOBS_LIST",
          jobList: resultService.data.data.jobs,
        });
        dispatchJobs({
          type: "SET_JOBS_COUNT",
          jobCount: resultService.data.data.jobs.length ?? 0,
        });
        dispatchJobs({
          type: "SET_LOADING",
          jobListLoading: false,
        });
        setList(resultService.data.data.jobs);
      } catch (e) {
        logException(e, "Cannot load project preventative jobs");
      }
    };

    loadJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jobListLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: jobList,
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, jobList, jobListLoading]);

  const handleSearch = (ev, query) => {
    if (query && (ev.key === "Enter" || ev.type === "click")) {
      const queryNormalize = query.toLowerCase().replace(/^\s+/g, "");
      console.log("jobList", jobList);
      const resultSearch = jobList.filter(
        (job) =>
          job.job_number.toString().toLowerCase().startsWith(queryNormalize) ||
          job.job_description.toLowerCase().includes(queryNormalize)
      );
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: resultSearch,
      });
      dispatchFilters({
        type: "SET_SEARCH_STRING",
        searchString: query,
      });
      ev.preventDefault();
    }
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list,
    });
  };

  return (
    <Box className={classes.root}>
      {jobListLoading ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${jobCount} / ${jobCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table newColumns={columns} activeMobile={true}>
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected />
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          <JobsListActions />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default JobsTable;
