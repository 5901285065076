import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { quoteHelpOptions } from "constants.js";

const QuoteDescriptionHelp = props => {
  const classes = useStyles();
  const { quoteType } = props;

  return (
    <Box className={classes.containerTabs}>
      <List dense={true}>
        {quoteHelpOptions.map(option => {
          return (
            <ListItem>
              <ListItemIcon
                className={
                  option.required.includes(quoteType)
                    ? classes.checkListIconAvailable
                    : option.optional.includes(quoteType)
                    ? classes.checkListIconOptional
                    : classes.checkListIconNotAvailable
                }
              >
                {option.required.includes(quoteType) ? (
                  <Tooltip title="Required">
                    <CheckIcon />
                  </Tooltip>
                ) : option.optional.includes(quoteType) ? (
                  <Tooltip title="Optional">
                    <CallSplitIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Available">
                    <CloseIcon />
                  </Tooltip>
                )}
              </ListItemIcon>
              <ListItemText
                className={
                  option.required.includes(quoteType)
                    ? classes.checkListIconAvailableText
                    : option.optional.includes(quoteType)
                    ? classes.checkListIconOptionalText
                    : classes.checkListIconNotAvailableText
                }
                primary={option.text}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default QuoteDescriptionHelp;
