import React, { useState, useContext } from "react";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TimerIcon from "@material-ui/icons/Timer";
import { chatIconSolidBlue } from "./icons";
import IconButton from "@material-ui/core/IconButton";
import UpdateCommentComponent from "./updateComment";
import ConfirmationDialog from "./confirmationDialog";
import MessageDialog from "./dialog/messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { permissionAdmin, hasPermission } from "lib/permissions";
import { dateFormatField } from "components/util/timeFormat";
import { extractCommentTime } from "components/util/commentUtil";
import { logException } from "components/util/logUtil";
import { updateFileComment, deleteFileComment } from "services/fileService";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";

const commentOptions = [
  {
    id: 1,
    label: "Edit",
    value: "EDIT",
    icon: <EditIcon fontSize="inherit" />,
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    icon: <DeleteIcon fontSize="inherit" />,
  },
];

const CommentPartnerList = ({
  data,
  file,
  onTimeClick,
  refreshData,
  isLoadingComments,
  embed,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClick = (event, id) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (action) => {
    if (action === "EDIT") handleEdit();
    if (action === "DELETE") handleDeleteClick();
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedCommentId(null);
  };

  const handleEditComment = async (body) => {
    setIsLoadingData(true);
    setOpenMessage(true);

    try {
      const data = {
        body,
      };
      await updateFileComment(file.id, selectedCommentId, data);
      refreshData();
      setSelectedCommentId(null);
      setIsEditing(false);
      setIsLoadingData(false);
      setOpenMessageContent("Comment has been updated successfully.");
    } catch (error) {
      logException(error, "Error updating comment");
      setIsLoadingData(false);
    }
  };
  const handleDeleteClick = () => {
    setSelectedCommentId(selectedCommentId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingData(true);
    setOpenDeleteDialog(false);
    setOpenMessage(true);
    try {
      await deleteFileComment(file.id, selectedCommentId);
      refreshData();
      setOpenMessageContent("Comment has been deleted successfully.");
      setIsLoadingData(false);
    } catch (error) {
      logException(error, "Error deleting comment");
      setIsLoadingData(false);
    }
  };

  const closeMessage = () => {
    if (!isLoadingData) {
      setOpenMessage(false);
    }
  };

  if (!data || isLoadingComments) {
    return (
      <Box className={classes.loadingContainer}>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box className={classes.containerComments}>
      {data.length === 0 ? (
        <Box
          style={{
            height: embed ? "auto" : "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.circleComments}>
            <Box className={classes.circle}>
              <IconButton>{chatIconSolidBlue()}</IconButton>
            </Box>
          </Box>
          <Typography
            variant="body1"
            className={classes.titleNoCommentsStyle}
            style={{ paddingBottom: 10 }}
          >
            No Comments Yet
          </Typography>
          <Typography variant="body1" className={classes.detailNoCommentsStyle}>
            Do something awesome like
          </Typography>
          <Typography variant="body1" className={classes.detailNoCommentsStyle}>
            adding a comment.
          </Typography>
        </Box>
      ) : (
        data.map((comment, index) => (
          <Box
            key={comment.id || `comment-${index}`}
            className={classes.commentBoxStyles}
          >
            <Box className={classes.avatarContainer}>
              {comment.profile_url ? (
                <Avatar alt="Profile" src={comment.profile_url} />
              ) : (
                <Avatar className={classes.avatarComment}>
                  {comment.user_name
                    .split(" ")
                    .map((word) => word.charAt(0))
                    .join("")}
                </Avatar>
              )}
            </Box>
            <Box style={{ flexGrow: 1 }}>
              <Box className={classes.nameDateContainerStyles}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography className={classes.commentText}>
                    {comment.user_name}
                  </Typography>
                  <Box
                    onClick={() => {
                      onTimeClick(extractCommentTime(comment.video_metadata));
                    }}
                  >
                    {extractCommentTime(comment.video_metadata) && (
                      <Button
                        variant="outlined"
                        className={classes.roundedButtonClock}
                        startIcon={<TimerIcon style={{ fontSize: 12 }} />}
                      >
                        <Typography className={classes.commentClock}>
                          {extractCommentTime(comment.video_metadata)}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box className={classes.dateMenuStyles}>
                  <Typography className={classes.commentText}>
                    {dateFormatField(comment.created_at)}
                  </Typography>
                  <Box className={classes.dateMenuIconStyles}>
                    {hasPermission(
                      permissionAdmin.COMMENTS_MANAGE,
                      permissions
                    ) &&
                      user.id === parseInt(comment.user_id) && (
                        <Icon
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, comment.id)}
                        >
                          <MoreHorizIcon fontSize="small" />
                        </Icon>
                      )}
                  </Box>
                </Box>
              </Box>
              {isEditing && selectedCommentId === comment.id ? (
                <UpdateCommentComponent
                  comment={comment}
                  onUpdateComment={handleEditComment}
                  onCancel={handleCancelEdit}
                />
              ) : (
                <Input
                  type="text"
                  multiline
                  disableUnderline
                  className={classes.commentInputText}
                  value={comment.body}
                  readOnly
                />
              )}
            </Box>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={isMenuOpen && selectedCommentId === comment.id}
              onClose={handleClose}
            >
              {commentOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  data={option.value}
                  onClick={() => handleSelect(option.value)}
                >
                  <Box className={classes.menuIcon}>{option.icon}</Box>
                  <Typography variant="caption" className={classes.menuText}>
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ))
      )}
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title={"Comments"}
        content="Are you sure you want to remove the comment?"
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
        // isLoadingComments={isLoadingComments}
      />
    </Box>
  );
};

export default CommentPartnerList;
