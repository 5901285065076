import React from "react";
import SurveyLayout from "./surveyLayout";
import { SurveyViewProvider } from "contexts/surveyViewContext";
import SurveyAdminDialog from "./dialog/surveyAdminDialog";

const SurveyPage = (props) => {
  return (
    <SurveyViewProvider>
      <SurveyLayout id={props.match.params.id} />
      <SurveyAdminDialog />
    </SurveyViewProvider>
  );
};

export default SurveyPage;
