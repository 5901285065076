import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import FormInput from "../../ui/FormContent/formInput";
import InputLabel from "@material-ui/core/InputLabel";

const PriceInput = props => {
  const {
    costType,
    handleChangeCostType,
    price,
    isDisabled,
    unit,
    label,
    handleBlur,
    name,
    readonly,
    custom
  } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item md={7} xs={8} className={classes.switch}>
        <InputLabel
          htmlFor="priceT"
          className={
            custom
              ? classes.customInputInternalLabel
              : classes.inputInternalLabel
          }
        >
          {label}
        </InputLabel>
        <span className={classes.labelText}>Fixed</span>
        <FormControlLabel
          control={
            <Switch
              checked={costType}
              onChange={handleChangeCostType}
              disabled={readonly}
              color="primary"
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
                track: classes.track
              }}
            />
          }
          label="Margin"
        />
      </Grid>
      <Grid item md={5} xs={4} className={classes.input}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          className={custom ? classes.customField : classes.field}
          name={name}
          label=""
          value={price}
          internal={true}
          sameLine={true}
          custom={custom}
          alignText="right"
          readonly={isDisabled || readonly}
          handleBlur={handleBlur}
          endAdornment={
            costType || (unit && unit !== "$") ? (
              <InputAdornment position="end">
                {costType ? "%" : unit}
              </InputAdornment>
            ) : (
              ""
            )
          }
          startAdornment={
            !costType && !(unit && unit !== "$") ? (
              <InputAdornment position="start">{unit}</InputAdornment>
            ) : (
              ""
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default PriceInput;
