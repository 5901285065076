import React, { useState, useEffect, useCallback } from "react";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import ProjectTabBillingPreventiveFilters from "./projectTabBillingPreventiveFilters";
import BillingStatusChip from "components/ui/Billing/BillingStatusChip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import {
  getFinancialPayments,
  getFinancialPaymentsSearch,
  getFinancialPaymentsOffset,
  getFinancialBills,
  getFinancialBillsSearch,
  getFinancialBillsOffset,
} from "services/projectService";
import { useProjectView } from "contexts/projectViewContext";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import { useListState, useListDispatch } from "contexts/listContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columnsAP = [
  {
    id: "id",
    label: "ID",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "workticket.number",
    label: "Workticket",
  },
  {
    id: "workticket.subject",
    label: "WT Summary",
  },
  { id: "user_name", label: "Subcontractor" },
  {
    id: "status",
    label: "Status",
    content: (row) => <BillingStatusChip status={row.status} archived={0} />,
    disableSort: true,
  },

  { id: "workticket_start_date", label: "Due Date", format: "date" },
  {
    id: "net_paid",
    label: "Payment",
    format: "money",
  },
  {
    id: "net_total",
    label: "Total Amount",
    format: "money",
  },
];

const columnsAR = [
  {
    id: "id",
    label: "ID",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "workticket.number",
    label: "Workticket",
  },
  {
    id: "workticket.subject",
    label: "WT Summary",
  },
  {
    id: "status",
    label: "Status",
    content: (row) => <BillingStatusChip status={row.status} archived={0} />,
    disableSort: true,
  },
  { id: "workticket_start_date", label: "Due Date", format: "date" },
  {
    id: "net_billed",
    label: "Billed",
    format: "money",
  },
  {
    id: "net_total",
    label: "Total Amount",
    format: "money",
  },
];

const ProjectTabBillingPreventiveTable = (props) => {
  const classes = useStyles();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ? stateContextProject : null;
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [listAP, setListAP] = useState([]);
  const [listAR, setListAR] = useState([]);
  const [listAPCount, setListAPCount] = useState(0);
  const [listARCount, setListARCount] = useState(0);
  const { listLoading, list, count, dummyRefresh } = useListState();
  const { hasRowsClickable, rows } = useTableState();
  const {
    searchString,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
  } = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchList = useListDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatchList({
        type: "SET_LOADING",
        listLoading: true,
      });

      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };

      const resultPayments = await getFinancialPayments(project.id, filterData);
      const resultBills = await getFinancialBills(project.id, filterData);

      setListAP(resultPayments.data.data.payments);
      setListAR(resultBills.data.data.bills);
      dispatchList({
        type: "SET_LIST",
        list:
          activeTab === 0
            ? resultPayments.data.data.payments
            : resultBills.data.data.bills,
      });

      setListAPCount(resultPayments.data.data.count);
      setListARCount(resultBills.data.data.count);

      dispatchList({
        type: "SET_COUNT",
        count:
          activeTab === 0
            ? resultPayments.data.data.count
            : resultBills.data.data.count,
      });
      return dispatchList({
        type: "SET_LOADING",
        listLoading: false,
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchList,
    filters,
    startDate,
    startDateActive,
    endDate,
    endDateActive,
    dummyRefresh,
  ]);

  useEffect(() => {
    if (!listLoading) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: list,
      });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: activeTab === 0 ? columnsAP : columnsAR,
      });
      setTimeout(() => {
        setLoadingMore(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, list, listLoading]);

  const handleScrollClick = useCallback(async () => {
    if (searchString) {
      // If search activated not scroll
      return;
    }
    if (count <= list.length || loadingMore) {
      return;
    }
    try {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };
      setLoadingMore(true);

      if (activeTab === 0) {
        const response = await getFinancialPaymentsOffset(
          project.id,
          list.length,
          filterData
        );
        const updateData = listAP.concat(response.data.data.payments);
        setListAP(updateData);
        setListAPCount(updateData.length);
        dispatchList({
          type: "SET_LIST",
          list: updateData,
        });
      } else {
        const response = await getFinancialBillsOffset(
          project.id,
          list.length,
          filterData
        );
        const updateData = listAR.concat(response.data.data.bills);
        setListAR(updateData);
        setListARCount(updateData.length);
        dispatchList({
          type: "SET_LIST",
          list: updateData,
        });
      }
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, list.length, searchString, activeTab, loadingMore]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          const filterData = {
            filters: filters,
            dateFilters: {
              startDate,
              startDateActive,
              endDate,
              endDateActive,
            },
          };

          if (activeTab === 0) {
            const responseSearch = await getFinancialPaymentsSearch(
              project.id,
              query,
              filterData
            );
            dispatchTable({
              type: "UPDATE_ROWS",
              rows: responseSearch.data.data.payments,
            });
          } else {
            const responseSearch = await getFinancialBillsSearch(
              project.id,
              query,
              filterData
            );
            dispatchTable({
              type: "UPDATE_ROWS",
              rows: responseSearch.data.data.bills,
            });
          }
          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
        };
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: list,
    });
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchList({
      type: "SET_LIST",
      list: newTab === 0 ? listAP : listAR,
    });
    dispatchList({
      type: "SET_COUNT",
      count: newTab === 0 ? listAPCount : listARCount,
    });
  };

  return (
    <Box className={classes.rootTable}>
      {listLoading ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              {!searchString ? (
                <Chip
                  label={`${
                    list.length < count ? list.length : count
                  } / ${count}`}
                  color="secondary"
                  variant="outlined"
                />
              ) : (
                <Chip
                  label={`${rows.length}`}
                  color="secondary"
                  variant="outlined"
                />
              )}
            </Box>
            <TableTabs handleTabChange={handleTabChange} value={activeTab}>
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Account Payable</Box>}
                key={0}
                value={0}
              />

              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Account Receivable</Box>
                }
                key={1}
                value={1}
              />
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.contentContainer}>
                <Box className={classes.fullHeightTableProject}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={activeTab === 0 ? columnsAP : columnsAR}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        <CalendarFilters />
                        <ProjectTabBillingPreventiveFilters
                          projectId={project.id}
                        />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {filters && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProjectTabBillingPreventiveTable;
