import React from "react";
import navItemsDefault from "../components/common/SideNav/navItems";

const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

export const InitialState = {
  isNavBarOpen: false,
  navItemOpen: null,
  isDrawerOpen: false,
  drawerContent: "default",
  currentPage: "",
  navItems: navItemsDefault,
  timekeeping: null,
  createFlow: null,
  createObject: null,
  permissions: [],
  users: [],
  refreshData: false,
  role: {},
  quickTicket: null,
  isNavBarShow: true,
  filtersJobs: null,
  filtersZips: null,
  filtersCities: null,
  filtersCustomers: null,
  filtersProjects: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEDRAWER":
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
        drawerContent: action.drawerContent ?? "default",
      };
    case "TOGGLE_NAV":
      return {
        ...state,
        isNavBarOpen: !state.isNavBarOpen,
        navItemOpen: null,
      };
    case "UPDATE_SELECTED":
      return {
        ...state,
        selectedItem: action.index,
      };
    case "UPDATE_CURRENTPAGE":
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case "SET_TIMEKEEPING":
      return {
        ...state,
        timekeeping: action.timekeeping,
      };
    case "SET_FLOW":
      return {
        ...state,
        createFlow: action.createFlow,
      };
    case "SET_FLOW_OBJECT":
      return {
        ...state,
        createObject: action.createObject,
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.role,
      };
    case "SET_USERS":
      return {
        ...state,
        users: action.users,
      };
    case "SET_REFRESH_DATA":
      return {
        ...state,
        refreshData: action.refreshData,
      };
    case "CLICK_SUBNAV":
      if (action.navItemOpen === state.navItemOpen) {
        return {
          ...state,
          navItemOpen: null,
        };
      }
      return {
        ...state,
        navItemOpen: action.navItemOpen,
      };
    case "SET_QUICKTICKET":
      return {
        ...state,
        quickTicket: action.quickTicket,
      };
    case "TOGGLE_SHOW":
      return {
        ...state,
        isNavBarShow: !state.isNavBarShow,
      };
    case "SET_FILTERS_LOCATIONS":
      return {
        ...state,
        filtersJobs: action?.filtersJobs,
        filtersZips: action?.filtersZips,
        filtersCities: action?.filtersCities,
      };
    case "SET_FILTERS_CUSTOMERS":
      return {
        ...state,
        filtersCustomers: action.filtersCustomers,
      };
    case "SET_FILTERS_PROJECTS":
      return {
        ...state,
        filtersProjects: action.filtersProjects,
      };
    default:
      return InitialState;
  }
};

export default Context;
