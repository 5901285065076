import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { displayFieldBudget as displayField } from "components/util/stringFormat";
import { useBudgetView } from "contexts/budgetViewContext";
import { dateFormatTimeField } from "components/util/timeFormat";

import useStyles from "./styles";

const headerColumns = ["Budget", "Category", "Date Range", "Value"];
const headerLaborColumns = [
  "Number",
  "Position",
  "Category",
  "Days Week",
  "Start Shift",
  "End Shift",
  "Labor Hours",
  "Hour Rate",
  "Base Week",
  "Overhead Week",
  "Total Week",
];

const headerProjectColumns = [
  "Name",
  "Category",
  "Jobs per Year",
  "Hours per Job",
  "Hours Rate",
  "Labor Base Year",
  "Equipment Year",
  "Supplies Year",
  "Cost per Job",
  "General Liability",
  "Total",
];

const BudgetTable = (props) => {
  const classes = useStyles();
  const [stateContext] = useBudgetView();
  const { budget, isLoading } = stateContext ?? null;
  const { category } = props;

  if (isLoading) {
    return null;
  }

  const budgetList = budget.budget_item.filter(
    (item) => item.subcategory1 === category
  );

  const budgetLabor = budget.budget_labor;
  const budgetProject = budget.budget_project;

  const showLabor = category === "Labor";
  const showProjects = category === "Projects";

  return (
    <Box>
      <TableContainer
        className={
          showLabor || showProjects
            ? classes.tableContainerOne
            : classes.tableContainer
        }
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell className={classes.rowHeader} key={column}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetList.map((item) => (
              <TableRow tabIndex={-1} key={item.id}>
                <TableCell>{item.field}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.date_range}</TableCell>
                <TableCell align="right">
                  {displayField(item.measure, item.value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showLabor ? (
        <TableContainer className={classes.tableContainerTwo}>
          <Table stickyHeader aria-label="dense sticky table">
            <TableHead>
              <TableRow>
                {headerLaborColumns.map((column) => (
                  <TableCell className={classes.rowHeader} key={column}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetLabor
                .filter((item) => item.position_name)
                .map((item) => (
                  <TableRow tabIndex={-1} key={item.id}>
                    <TableCell>{item.count}</TableCell>
                    <TableCell>{item.position_name}</TableCell>
                    <TableCell>{item.subcategory1}</TableCell>
                    <TableCell>{item.days_week}</TableCell>
                    <TableCell>
                      {dateFormatTimeField(item.start_shift)}
                    </TableCell>
                    <TableCell>{dateFormatTimeField(item.end_shift)}</TableCell>
                    <TableCell>{item.total_hours}</TableCell>
                    <TableCell align="right">
                      {displayField("$", item.hour_rate)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.net_base_pay)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.total_overhead)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.total_cost_week)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {showProjects ? (
        <TableContainer className={classes.tableContainerTwo}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headerProjectColumns.map((column) => (
                  <TableCell className={classes.rowHeader} key={column}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetProject
                .filter((item) => item.project_name)
                .map((item) => (
                  <TableRow tabIndex={-1} key={item.id}>
                    <TableCell>{item.project_name}</TableCell>
                    <TableCell>{item.subcategory1}</TableCell>
                    <TableCell>{item.jobs_year}</TableCell>
                    <TableCell>{item.hours_job}</TableCell>
                    <TableCell align="right">
                      {displayField("$", item.hourly_rate)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.labor_base_year)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.equipment_cost)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.supplies_cost)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.job_cost)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.general_liability)}
                    </TableCell>
                    <TableCell align="right">
                      {displayField("$", item.total_cost)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
};

export default BudgetTable;
