import http from "./httpService";
import moment from "moment";
import { apiSurfaceUrl } from "lib/config";

const apiEndpoint = apiSurfaceUrl + "/quotes";

export function getQuoteDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {
    params: {},
  });
}

export function updateQuote(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function addQuoteComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comment`, data);
}

export function updateQuoteComment(id, idComment, data) {
  return http.put(`${apiEndpoint}/${id}/comments/${idComment}`, data);
}

export function deleteQuoteComment(id, idComment) {
  return http.delete(`${apiEndpoint}/${id}/comments/${idComment}`);
}

export function getQuotes(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getQuotesTab(tab, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}filters[tab]=${tab}`, {});
}

export function getQuotesTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`,
    {}
  );
}

export function updateQuotesBulk(data) {
  return http.post(`${apiEndpoint}/update/bulk`, data);
}

export function getQuotesSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getQuotePdf(id) {
  return http.get(`${apiEndpoint}/${id}/pdf`, {
    responseType: "blob",
  });
}

export function saveQuoteFollow(data) {
  return http.post(`${apiEndpoint}/follow`, data);
}

export function filter(search, filters) {
  let queryString = "";

  if (search || (filters && filters.length > 0)) {
    if (search) {
      queryString += "search=" + search + "&";
    }

    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (filter.group !== "start_date" || filter.group !== "end_date") {
          //include the double array for non-date
          queryString += `filters[${filter.optionGroup}][]=${filter.option}&`;
        } else {
          queryString += `filters[${filter.optionGroup}]=${filter.option}&`;
        }
      });

      queryString = queryString.slice(0, -1);
    }
    console.log("query string: " + queryString);
    return http.get(`${apiEndpoint}/search?${queryString}`, {});
  } else {
    return http.get(`${apiEndpoint}`, {
      params: {},
    });
  }
}

const getFilterString = (filterData) => {
  let queryString = "";
  if (filterData) {
    if (filterData.dateFilters) {
      if (filterData.dateFilters.startDateActive) {
        queryString += `filters[start_date]=${moment(
          filterData.dateFilters.startDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }

      if (filterData.dateFilters.endDateActive) {
        queryString += `filters[end_date]=${moment(
          filterData.dateFilters.endDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }
    }
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          queryString += `filters[${element.filter}][]=${item.value}&`;
        });
      });
    }
    console.log("query String", queryString);
  }

  if (queryString === "") {
    queryString = "filters[]&";
  }
  return queryString;
};
