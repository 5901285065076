import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AddCommentButton from "./addCommentButton";
import { timeFormatSeconds } from "../../util/timeFormat";
import { isValidFormatCommentTimestamp } from "components/util/stringFormat";
import * as classNames from "classnames";
import useStyles from "./styles";

const TimestampComment = ({
  file,
  workTicketId,
  activeTab,
  playerRef,
  videoDuration,
}) => {
  const classes = useStyles();
  const [timestamp, setTimestamp] = useState("00:00");
  const [commentStartTime, setCommentStartTime] = useState("");
  const [useTimeStampEnable, setUseTimeStampEnable] = useState(false);
  const [editTimestamp, setEditTimestamp] = useState(false);
  const [inputTimestamp, setInputTimestamp] = useState("");
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [invalidTime, setInvalidTime] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    const updateTimestamp = async () => {
      let timeToSet = "";
      if (comment) {
        if (useTimeStampEnable) {
          const currentTime = await playerRef.current.getCurrentTime();
          setCommentStartTime(currentTime);
          timeToSet = currentTime;
        } else {
          timeToSet = 0;
        }
        setTimestamp(timeFormatSeconds(timeToSet));
      }
    };

    updateTimestamp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useTimeStampEnable]);

  const handleUseTimestamp = async () => {
    setUseTimeStampEnable((prev) => !prev);
    if (editTimestamp) {
      setEditTimestamp(false);
      setInputTimestamp("00:00");
      setCommentStartTime("");
    }
    setInvalidTime(false);
  };

  const handleTimestampChange = (e) => {
    const value = e.target.value;
    const isValid = isValidFormatCommentTimestamp(value);
    setInvalidFormat(!isValid);

    if (!isValid) {
      setInputTimestamp(value);
      return;
    }

    if (editTimestamp) {
      setInputTimestamp(value);
    } else {
      setTimestamp(value);
    }

    if (videoDuration) {
      const [mm, ss] = value.split(":").map(Number);
      const enteredTimeInSeconds = mm * 60 + ss;

      if (enteredTimeInSeconds > videoDuration) {
        setInvalidTime(true);
      } else {
        setInvalidTime(false);
      }
    }
  };

  const handleEditTimestamp = () => {
    if (!useTimeStampEnable) {
      setInputTimestamp("");
      setEditTimestamp(true);
    }
  };

  const handleBlurTimestamp = () => {
    setEditTimestamp(true);
    const updatedStartTime = inputTimestamp || commentStartTime;
    if (isValidFormatCommentTimestamp(updatedStartTime)) {
      setCommentStartTime(updatedStartTime);
    }
  };

  const resetTimestampAndStartTime = () => {
    setTimestamp("00:00");
    setComment("");
    setCommentStartTime("");
    setUseTimeStampEnable(false);
    setEditTimestamp(false);
    setInvalidFormat(false);
    setInvalidTime(false);
  };

  const handleNewCommentChange = async (newComment) => {
    setComment(newComment);

    if (inputTimestamp === "" && newComment !== "") {
      setEditTimestamp(false);
    }

    if (!newComment || !playerRef?.current) {
      resetTimestampAndStartTime();
      return;
    }

    let timeToSet = "";
    if (useTimeStampEnable) {
      if (!commentStartTime) {
        const currentTime = await playerRef.current.getCurrentTime();
        setCommentStartTime(currentTime);
      }
      timeToSet = commentStartTime;
    }
    setTimestamp(timeFormatSeconds(timeToSet));
  };

  return (
    <Box className={classes.timestampContainer}>
      <FormLabel htmlFor="form-timestamp" className={classes.labelText}>
        Timestamp
      </FormLabel>

      <Box className={classes.commentInputContainer}>
        <FormControl className={classes.timestampInput}>
          <Input
            className={classNames({
              [classes.invalidTime]: invalidFormat || invalidTime,
            })}
            id="form-timestamp"
            type="text"
            disableUnderline
            placeholder="MM:SS"
            inputProps={{ maxLength: 5 }}
            readOnly={!editTimestamp}
            value={editTimestamp ? inputTimestamp : timestamp}
            onChange={handleTimestampChange}
            onBlur={handleBlurTimestamp}
          />
        </FormControl>

        <Box className={classes.iconButtonContainer}>
          <Fab
            className={classNames(classes.updateTimestamp, {
              [classes.borderColorBasedOnEditTimestamp]: editTimestamp,
              [classes.borderColorBasedOnEdit]: !editTimestamp,
            })}
            aria-label="edit"
            size="small"
            onClick={handleEditTimestamp}
          >
            <EditIcon />
          </Fab>
          <Button
            className={classNames(classes.useTimestampButton, {
              [classes.activeTimestampButton]: useTimeStampEnable,
              [classes.inactiveTimestampButton]: !useTimeStampEnable,
            })}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleUseTimestamp}
          >
            Use Timestamp
          </Button>
        </Box>
      </Box>
      {(invalidFormat || invalidTime) && (
        <Typography variant="caption">
          {invalidFormat
            ? "Invalid time format"
            : "The entered time exceeds the video duration"}
        </Typography>
      )}
      <Box width="100%">
        <AddCommentButton
          file={file}
          workTicketId={workTicketId}
          activeTab={activeTab}
          onCommentChange={handleNewCommentChange}
          commentStartTime={
            useTimeStampEnable ? timestamp : editTimestamp ? inputTimestamp : ""
          }
        />
      </Box>
    </Box>
  );
};

export default TimestampComment;
