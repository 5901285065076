import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "./messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useRecognitionState,
  useRecognitionDispatch,
  getRecognitionList,
} from "contexts/recognitionContext";
import { deleteRecognition } from "services/teamService";
import { useFilterState } from "contexts/filterContext";
import { useIndividualView } from "contexts/individualViewContext";

import useStyles from "./styles";

const RecognitionDeleteDialog = (props) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const { openRecognitionDelete, recognitionSelected } = useRecognitionState();
  const dispatchSurveysRecognition = useRecognitionDispatch();
  const { filters } = useFilterState();
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;

  const handleClose = () => {
    dispatchSurveysRecognition({
      type: "TOGGLE_DELETE",
      openRecognitionDelete: false,
    });
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      await deleteRecognition(recognitionSelected.detail_id);
      const filterData = {
        filters: [
          ...filters,
          {
            filter: "submittedFor",
            group: "Submitted For",
            values: [{ label: "", value: individual.id }],
          },
        ],
      };
      await getRecognitionList(dispatchSurveysRecognition, filterData);
      setIsLoadingDialog(false);
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      console.log("Cannot delete evaluation");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        open={openRecognitionDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.titleDialog}
              >
                Recognition
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Are you sure you want to delete this recognition?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <Button
                onClick={handleClose}
                className={classes.buttonOutlined}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
              >
                Okay
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={`This recognition has been deleted.`}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default RecognitionDeleteDialog;
