import React, { useEffect } from "react";
import MainLayoutLogin from "components/common/MainLayoutLogin";
import Container from "@material-ui/core/Container";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "pages/loginPage/loginForm.styles";
import { osName } from "react-device-detect";
import { Link } from "react-router-dom";

const DownloadMobileApp = () => {
  const classes = useStyles();

  useEffect(() => {
    if (document.visibilityState === "visible") {
      if (osName === "iOS") {
        window.location = "https://apps.apple.com/app/id6466451076";
      } else if (osName === "Android") {
        window.location =
          "https://play.google.com/store/apps/details?id=com.anonymous.encompassnative";
      }
    }
  }, []);

  return (
    <MainLayoutLogin>
      <Container maxWidth="sm">
        <Grid container className={classes.root}>
          <CardContent className={classes.card}>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Redirected to{" "}
              {osName === "iOS" ? "Apple App Store" : "Google Play Store"}
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.subTitle}
            >
              We've redirected you to the store to download our app, you can go
              back by clicking on the link below.
            </Typography>
            <Link className={classes.forgotPasswordLink} to="/dashboard">
              Go Back
            </Link>
            <CardActions className={classes.cardActions}></CardActions>
          </CardContent>
        </Grid>
      </Container>
    </MainLayoutLogin>
  );
};

export default DownloadMobileApp;
