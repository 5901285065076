import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    minHeight: 80,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  footer: {
    boxShadow: "0 0 8px 0 #c4c4c4",
    justifyContent: "space-between",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    borderTop: "1px solid #17457a",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  footerActionButtons: {
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      textAlign: "right",
      display: "flex",
    },
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonSecondaryDisabled: {
    backgroundColor: "#DFDFDF",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#DFDFDF",
    },
    boxShadow: "none",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 35,
    minHeight: 35,
    lineHeight: "100%",
  },
  textLeft: {
    textAlign: "left",
    marginTop: 3,
  },
  surveyMainHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  textRight: {
    textAlign: "right",
  },
  radiusChip: { borderRadius: 3, maxWidth: 200, marginLeft: 20 },
  chipFontColor: {
    color: "#ffffff",
    textAlign: "center",
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    lineHeight: "19px",
  },
  iconChip: {
    color: "#ffffff",
    width: 14,
    height: 14,
    marginLeft: 8,
  },
  quesTitle: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
      "& p": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
  },
  ques: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: 2,
    height: 35,
    width: 35,
    marginLeft: 8,
    textAlign: "center",
    lineHeight: "35px",
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
      marginLeft: 0,
      marginBottom: theme.spacing(1),
    },
  },
  quesModal: {
    backgroundColor: "#17457A",
    color: "#ffffff",
    borderRadius: 2,
    height: 30,
    width: 30,
    fontSize: 14,
    marginLeft: 6,
    textAlign: "center",
    paddingTop: 4.5,
  },
  quesText: {
    fontSize: 20,
  },

  surveyTitle: {
    fontWeight: "bold",
    fontSize: 24,
    paddingLeft: 35,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingLeft: 5,
      marginBottom: theme.spacing(1),
      width: "80%",
    },
  },
  surveyStatusWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  surveyPdfWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  quesCounterWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "right",
    },
  },
  quesCounter: {
    textAlign: "end",
    fontWeight: "bold",
  },
  quesOption: {
    fontSize: 18,
    paddingLeft: 60,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      fontSize: 16,
      paddingBottom: 5,
    },
  },
  alignQuesOption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  alignRating: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ratingBox: {
    background: "#FFFFFF",
    border: "1px solid #4F98BC",
    borderRadius: 3,
    width: 35,
  },
  ratingStar: {
    color: "#4F98BC",
    height: 35,
    width: 35,
  },
  ratingFont: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#3996C0",
    cursor: "pointer",
    justifyContent: "center",
  },
  ratingFontSelected: {
    backgroundColor: "#4F98BC",
    color: "#ffffff",
  },
  ratingFontSelectedActive: {
    animation: "$selectedNumberAnswer 1s",
  },
  ratingStarSelected: {
    color: "#4F98BC",
    height: 35,
    width: 35,
    cursor: "pointer",
  },
  ratingStarSelectedActive: {
    animation: "$selectedAnswer 1s",
  },
  ratingTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  ratingTextBelow: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "19px",
    color: "#4F98BC",
  },
  ratingTextBelowAdj: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "19px",
    color: "#4F98BC",
    paddingLeft: 12,
  },

  listMinHeight: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 0,
    paddingLeft: 0,
  },
  additionalFeedbackWrapper: {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 45,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  additionalFeedbackContent: {
    paddingLeft: 60,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      "& > div > div": {
        paddingBottom: "20px !important",
        paddingTop: "20px !important",
      },
    },
  },
  additionalFeedbackTitle: {
    fontSize: 20,
    color: "#22282D",
    marginBottom: 20,
  },
  docCommentsTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 15,
  },
  containerComments: {},
  containerCommentsList: {
    padding: 0,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  listItemComment: { backgroundColor: "#ececec" },
  listContainer: {
    paddingLeft: 0,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  inputEditComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    width: "98%",
  },
  boxActionsComment: {
    marginTop: 10,
    textAlign: "right",
    marginRight: 8,
  },
  buttonOutlinedComment: {
    marginRight: theme.spacing(1),
  },
  listText: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
      paddingRight: 0,
    },
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  listAction: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      position: "relative !important",
      textAlign: "right",
    },
  },
  menuItemIconComment: {
    textAlign: "right",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    margin: 0,
    padding: 0,
    justifyContent: "end",
  },
  inputNewAns: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    maxWidth: "calc(100% - 150px)",
    marginLeft: "68px",
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    borderRadius: 4,
    height: 175,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  boxActions: {
    marginTop: 10,
    textAlign: "right",
  },
  marginTop10: {
    marginTop: "10px",
  },
  marginHeader: {
    marginTop: 20,
  },
  headerTitleAdj: {
    marginTop: "7px",
  },
  modalBody: {
    paddingRight: 40,
    paddingLeft: 40,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  surveyHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      "& > svg": {
        left: -5,
        position: "relative",
      },
    },
  },
  surveyQuestionHeader: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      paddingTop: 10,
      borderTop: "1px solid #f8f8f8",
    },
  },
  listViewPadding: {
    paddingLeft: "60px",
  },
  quesContainer: {
    marginTop: 20,
  },
  divider: {
    marginLeft: 45,
    marginTop: 10,
  },
  iconClose: {
    width: 25,
    height: 25,
  },
  closeAdj: {
    marginTop: 15,
  },
  quesAdj: {
    maxWidth: 80,
  },
  adjRight: {
    marginRight: 30,
  },
  quesTextSub: {
    fontSize: 18,
    lineHeight: "25px",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 85,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 45,
      fontSize: 14,
    },
  },
  alphabetOptionsBox: {
    width: 36,
    height: 35,
    background: "#FFFFFF",
    border: "1px solid #4F98BC",
    borderRadius: 5,
    display: "inline-block",
  },
  alphabetOptionsBoxText: {
    display: "flex",
    justifyContent: "center",
    width: 15.43,
    height: 21.83,
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 32,
    alignItems: "center",
    textAlign: "center",
    color: "#3996C0",
    margin: "auto",
    marginTop: 3,
  },
  alphabetOptionText: {
    minHeight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 4,
    lineHeight: "110%",
    flex: 1,
    fontWeight: 600,
    fontSize: 18,
    color: "#4F98BC",
    marginLeft: 20,
  },
  alignMultipleQuesOption: {
    marginLeft: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  multipleOptionItemWrapper: {
    background: "#FFFFFF",
    border: "1px solid #4F98BC",
    borderRadius: 5,
    width: 278,
    display: "flex",
    paddingLeft: 26,
    minHeight: "55px",
    marginBottom: "15px",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  mcqSelected: {
    background: "#FFFFFF",
    color: "#ffffff",
    cursor: "pointer",
    display: "flex",
    padding: 5.5,
    fontSize: 18,
    textAlign: "center",
    alignItems: "center",
    fontWeight: "bold",
    backgroundColor: "#4F98BC",
    border: "1px solid #4F98BC",
    borderRadius: 5,
    width: 278,
    paddingLeft: 26,
    minHeight: 55,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  mcqSelectedText: {
    minHeight: 20,
    lineHeight: "110%",
    fontSize: 18,
    paddingRight: 4,
    fontWeight: 600,
    marginLeft: 20,
    color: "#ffffff",
    flex: 1,
    textAlign: "left",
  },
  radiusStatusChip: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 3,
    width: 134,
    marginLeft: 20,
    backgroundColor: "#78C1CE",
    paddingLeft: 5,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  radiusStatusChipDone: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 3,
    width: 134,
    marginLeft: 20,
    backgroundColor: "#9cbb65",
    paddingLeft: 5,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  rootAppBar: {
    backgroundColor: "#78C1CE !important",
    boxShadow: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  surveyResumeContentainer: {
    marginTop: 3,
    display: "flex",
    alignItems: "center",
  },
  answeredQuestion: {
    backgroundColor: "#9cbb65",
    borderRadius: 3,
  },
  iconRequired: {
    color: "#efc945",
  },
  iconRequiredMain: {
    color: "#efc945",
    marginRight: 8,
  },
  popperContainer: {
    minWidth: 800,
    paddingTop: 24,
    paddingBottom: 4,
    paddingLeft: 36,
    paddingRight: 36,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      overflowY: "scroll",
    },
  },
  containerQuestionContent: {
    marginBottom: 12,
  },
  quesNameModal: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: "#7A7E81",
  },
  buttonModal: {
    textTransform: "Capitalize",
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
    // minWidth: 120,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
    borderColor: "#4f98bc",
  },
  modalCell: {
    display: "flex",
    alignItems: "center",
  },
  notAnsweredQuestion: {
    borderColor: "#A8A8A8",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 3,
  },
  chipFontColorModal: {
    color: "#ffffff",
    textAlign: "center",
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    width: 110,
    fontSize: 12,
    fontWeight: "bold",
  },
  chipFontColorModalNot: {
    color: "#A8A8A8",
    textAlign: "center",
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    width: 110,
    fontSize: 12,
    fontWeight: "bold",
  },
  buttonReplay: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      lineHeight: "100%",
    },
  },
  headerPart: {
    display: "flex",
    alignItems: "center",
  },
  headerPartRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  answerText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  answerNavigation: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginRight: -10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  answerNavigationIcon: {
    cursor: "pointer",
  },
  navigationButton: {
    padding: 0,
  },
  navigationMenuButton: {
    padding: 0,
  },
  "@keyframes newIndicator": {
    "0%": {
      boxShadow: "0 0 0 0 #9ABC66",
    },
    "20%": {
      boxShadow: "0 0 0 5px transparent",
    },
    to: {
      boxShadow: "0 0 0 0 transparent",
    },
  },
  newAnswerIndicator: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    animation: "$newIndicator 3s infinite",
    background: "#9ABC66",
  },
  shortAnswerContainer: {
    paddingLeft: 85,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 45,
    },
  },
  buttonSignature: {
    textTransform: "capitalize",
    marginLeft: 50,
    fontSize: 14,
    fontWeight: "bold",
    borderRadius: 5,
    height: 50,
    top: 15,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      top: theme.spacing(2),
      lineHeight: "100%",
    },
  },
  buttonPrintPdf: {
    textTransform: "capitalize",
    marginLeft: 50,
    fontSize: 14,
    fontWeight: "bold",
    borderRadius: 5,
    height: 50,
    top: 15,
    position: "relative",
    "& svg": {
      marginRight: 8,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: "absolute",
      zIndex: 99999,
      height: 40,
    },
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10,
  },
  containerHeaderComments: {
    display: "flex",
  },
  docCommentsType: {
    "& > span": {
      fontSize: 14,
    },
  },
  menu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    fontSize: 16,
    padding: "5px 15px 5px 15px",
    minWidth: 120,
    fontWeight: "bold",
    background: "#ffffff",
  },
  menuItemLabel: {
    fontWeight: "bold",
  },
  againIcon: {
    transform: "scaleX(-1)",
  },
  surveySignatureWrapper: {
    flex: 1,
  },
  surveyLangWrapper: {
    flex: 1,
    textAlign: "right",
  },
  questionList: {
    marginLeft: 45,
    "& li": {
      borderTop: "1px solid #ececec",
    },
    "& li:last-child": {
      borderBottom: "1px solid #ececec",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  questionMultiList: {
    marginLeft: 20,
  },
  questionShortList: {
    "& li": {
      padding: 0,
    },
  },
  "@keyframes selectedAnswer": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0.5,
    },
    "40%": {
      opacity: 1,
    },
    "60%": {
      opacity: 0.5,
    },
    "80%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
  "@keyframes selectedNumberAnswer": {
    "0%": {
      opacity: 1,
    },
    "20%": {
      opacity: 0.5,
    },
    "40%": {
      opacity: 1,
    },
    "60%": {
      opacity: 0.5,
    },
    "80%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
}));
export default useStyles;
