import React, { useMemo, useState, useContext } from "react";
import { ButtonBase } from "components/ui/core/button";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import TicketIcon from "@material-ui/icons/Receipt";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  workticketBillOverride,
  workticketPaymentOverride,
} from "services/workticketService";
import { logException } from "components/util/logUtil";

import useStyles from "./styles";

const WorkticketBillingPortfolio = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;
  const [mode, setMode] = useState(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [amountUpdate, setAmountUpdate] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );
  const permAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AP, permissions),
    [permissions]
  );
  const permAR = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AR, permissions),
    [permissions]
  );

  const [tab, setTab] = useState(permAR ? 0 : 1);

  const handleBilling = (selectedMode) => {
    setMode(selectedMode);
    setIsDirty(false);
    setOpenUpdate(true);
    if (selectedMode === "Payment") {
      setAmountUpdate(Number(workticket.payment.total_amount));
      return;
    }

    if (selectedMode === "Billing") {
      setAmountUpdate(Number(workticket.bill.total_amount));
      return;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleChangeAmount = (event) => {
    setAmountUpdate(event.value);
    setIsDirty(true);
  };

  const handleUpdateBilling = async () => {
    try {
      setIsLoadingUpdate(true);
      if (mode === "Payment") {
        await workticketPaymentOverride(workticket.id, {
          payment_per_instance: amountUpdate,
        });
        workticket.payment.total_amount = Number(amountUpdate);
      }

      if (mode === "Billing") {
        await workticketBillOverride(workticket.id, {
          bill_per_instance: amountUpdate,
        });
        workticket.bill.total_amount = Number(amountUpdate);
      }
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setIsLoadingUpdate(false);
      setOpenUpdate(false);
    } catch (e) {
      logException(e, `Cannot update ${mode}`);
    }
  };

  if (!workticket.payment && !workticket.bill) {
    return null;
  }

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={20} />;
  }

  if (!permOnlyAP && !permAP && !permAR) {
    return null;
  }

  const readOnlyWorkticket =
    workticket?.recurrence_instance_id && !workticket?.instance_override;
  return (
    <Box>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        className={classes.billingTabRoot}
      >
        {permAR ? (
          <Tab
            label="Account Receivable"
            value={0}
            className={classes.billingTab}
          />
        ) : null}
        {permAP || permOnlyAP ? (
          <Tab
            label="Account Payable"
            value={1}
            className={classes.billingTab}
          />
        ) : null}
      </Tabs>
      {tab === 1 && (permAP || permOnlyAP) ? (
        <Box>
          {workticket.payment ? (
            <>
              {[0, 1].includes(workticket.status) &&
              workticket.payment.status === 4 &&
              !permOnlyAP ? (
                <Alert severity="error" className={classes.infoAlert}>
                  Contact support/financial to update amounts.
                </Alert>
              ) : null}

              <Grid container className={classes.rootBillingHeader}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Subcontractor
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Payment
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Total
                  </Typography>
                </Grid>
                {!readOnlyWorkticket ? <Grid item xs={1}></Grid> : null}
              </Grid>
              <Grid container className={classes.rootBillingBody}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    {workticket.payment.user_name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    $ {Number(workticket.payment.amount_paid).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    $ {Number(workticket.payment.total_amount).toFixed(2)}
                  </Typography>
                </Grid>
                {!readOnlyWorkticket &&
                Number(workticket.payment.amount_paid) === 0 &&
                [0, 1].includes(workticket.payment.status) &&
                !permOnlyAP ? (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="close"
                      className={classes.wrapperIcon}
                      onClick={() => handleBilling("Payment")}
                    >
                      <EditIcon className={classes.iconOptions} />
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            </>
          ) : (
            <Typography variant="subtitle2" className={classes.emptyBilling}>
              No Payment
            </Typography>
          )}
        </Box>
      ) : null}
      {tab === 0 && permAR ? (
        <Box>
          {workticket.bill ? (
            <>
              {[0, 1].includes(workticket.status) &&
              workticket.bill.status === 4 &&
              !readOnlyWorkticket ? (
                <Alert severity="error" className={classes.infoAlert}>
                  Contact support/financial to update amounts.
                </Alert>
              ) : null}
              <Grid container className={classes.rootBillingHeader}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Customer
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Billed
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Total
                  </Typography>
                  {!readOnlyWorkticket ? <Grid item xs={1}></Grid> : null}
                </Grid>
              </Grid>

              <Grid container className={classes.rootBillingBody}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    {workticket.bill.customer_name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    $ {Number(workticket.bill.amount_billed).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    $ {Number(workticket.bill.total_amount).toFixed(2)}
                  </Typography>
                </Grid>
                {!readOnlyWorkticket &&
                Number(workticket.bill.amount_billed) === 0 &&
                [0, 1].includes(workticket.bill.status) ? (
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="close"
                      className={classes.wrapperIcon}
                      onClick={() => handleBilling("Billing")}
                    >
                      <EditIcon className={classes.iconOptions} />
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            </>
          ) : (
            <Typography variant="subtitle2" className={classes.emptyBilling}>
              No Billing
            </Typography>
          )}
        </Box>
      ) : null}
      {permAP || permAR ? (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={openUpdate}
          onClose={handleCloseUpdate}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseUpdate}
              className={classes.wrapperClose}
            >
              <CloseIcon className={classes.iconClose} />
            </IconButton>

            <Box className={classes.formIconDialog}>
              <TicketIcon className={classes.iconDialog} />
            </Box>
            <Typography
              variant="h4"
              className={classes.formTitleDialog}
              gutterBottom
            >
              Workticket {mode}
            </Typography>
            {isLoadingUpdate ? (
              <LoadingIndicator />
            ) : (
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="amount"
                label={"Amount"}
                value={amountUpdate}
                handleBlur={handleChangeAmount}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.actionsBillingDialogWrapper}>
            <ButtonBase
              variant="outlined"
              color="secondary"
              onClick={handleCloseUpdate}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              color="secondary"
              onClick={handleUpdateBilling}
              disabled={!isDirty || isLoadingUpdate}
            >
              Update
            </ButtonBase>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default WorkticketBillingPortfolio;
