import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuestionDispatch } from "contexts/surveyQuestionContext";

const QuestionsTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchQuestions = useQuestionDispatch();
  const open = Boolean(anchorEl);
  const { row, disabled } = props;

  const options = [
    {
      id: 1,
      label: "View",
      value: "VIEW",
    },
    {
      id: 2,
      label: "Delete",
      value: "DELETE",
    },
    {
      id: 3,
      label: !parseInt(row.is_required) ? "Required" : "Not Required",
      value: "REQUIRED",
    },
  ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";
    switch (action) {
      case "DELETE":
        dispatchQuestions({
          type: "TOGGLE_DELETE",
          openQuestionDelete: true,
        });
        break;
      case "VIEW":
        dispatchQuestions({
          type: "TOGGLE_MANAGE",
          openManageQuestion: true,
        });
        break;
      case "REQUIRED":
        dispatchQuestions({
          type: "TOGGLE_REQUIRED",
          openQuestionRequired: true,
        });
        break;
      default:
        break;
    }

    dispatchQuestions({
      type: "SET_QUESTION_ROW",
      questionRow: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default QuestionsTableActions;
