import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import KpiTabPage from "./kpiTab/kpiTabPage";
import NotesTab from "./notesTab/notesTab";
import RingBellTabContainer from "./ringBellTab/ringBellTabContainer";
import QualityTabContainer from "./qualityTab/qualityTabContainer";
import WorkComponent from "./worktickets/WorkComponent";
import AssetComponent from "./assets/AssetComponent";
import { TableProvider } from "contexts/tableContext";
import GlobalUiContext from "contexts/globalUiContext";
import {
  Kpi,
  Team,
  Work,
  Quality,
  Asset,
  Note,
} from "components/common/Icons/navIconsV2";
import {
  hasPermission,
  permissionDashboard,
  permissionSurface,
  permissionLegacy,
  permissionWorkticket,
  permissionAsset,
  permissionTalent,
} from "lib/permissions";
import { useDashboardState } from "contexts/dashboardContext";
import { useEffect } from "react";

const tabItems = [
  {
    key: 1,
    label: "KPI",
    icon: (
      <Box className="mobile-svg">
        <Kpi />
      </Box>
    ),
  },
  {
    key: 2,
    label: "Talent",
    icon: (
      <Box className="mobile-svg">
        <Team />
      </Box>
    ),
  },
  {
    key: 3,
    label: "Work",
    icon: (
      <Box className="mobile-svg">
        <Work className="mobile-svg" />
      </Box>
    ),
  },
  {
    key: 4,
    label: "Quality",
    icon: (
      <Box className="mobile-svg">
        <Quality className="mobile-svg" />{" "}
      </Box>
    ),
  },
  {
    key: 5,
    label: "Assets",
    icon: (
      <Box className="mobile-svg">
        <Asset className="mobile-svg" />
      </Box>
    ),
  },
  {
    key: 6,
    label: "Notes",
    icon: (
      <Box className="mobile-svg">
        <Note className="mobile-svg" />
      </Box>
    ),
  },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ height: "100%" }}>
          <Box style={{ height: "100%" }}>{children}</Box>
        </Box>
      )}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index - 1}`,
    "aria-controls": `vertical-tabpanel-${index - 1}`,
  };
};

const useStyles = makeStyles((theme, isMobile) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    border: "1px solid #9B9B9B",
    borderRadius: 4,
    "& .MuiTab-root": {
      minWidth: "50px !important",
      padding: isMobile ? "11px" : "0px",
    },
    "& .MuiTab-root:focus": {
      backgroundColor: "white",
      color: "#16457a",
      borderRadius: "50%",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiTab-root:active": {
      backgroundColor: "white",
      color: "#16457a",
      borderRadius: "50%",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiTab-root:hover": {
      backgroundColor: "white",
      color: "#16457a",
      borderRadius: "50%",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
    },
  },
  indicator: {
    backgroundColor: "#ffffff",
  },
  flex: { display: "flex" },
  flexOne: { flex: "1" },
  notesContainer: {
    flex: "3",
    marginLeft: "24px",
    fontWeight: "bolder",
    fontSize: "21px",
  },
  viewAllBg: { backgroundColor: "#4F98BC" },
  tabContainer: { position: "relative" },
  actionContainer: {
    position: "fixed",
    left: 1150,
    marginTop: -30,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      marginTop: -30,
    },
  },
  tabTitle: {
    marginLeft: 24,
    fontWeight: "bolder",
    fontSize: 21,
  },
  tabSubTitle: {
    marginTop: 16,
    marginLeft: 24,
    fontWeight: "bolder",
    fontSize: 18,
    color: "#a7a7a7",
    borderBottom: "#f3f3f3 solid",
    marginRight: 90,
  },
}));

const selectedStyle = {
  marginBottom: 18,
  backgroundColor: "#ffffff",
  color: "#16457A",
  borderRadius: "50%",
  paddingTop: 2,
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
};

const tabCustomStyle = {};

const VerticalTabs = ({
  history,
  selectedLocation,
  isSurfaceUser,
  setAssetModalDataVisibility,
  setCurrentSelectedTab,
  isMobile,
  setClickEvent,
}) => {
  const classes = useStyles(isMobile);
  const { globalUi } = useContext(GlobalUiContext);
  const dashboardState = useDashboardState();
  const { permissions } = globalUi;
  const [tabPermission, setTabPermission] = useState([]);

  const iconMargin = {
    marginBottom: 18,
    opacity: 0.7,
    padding: isMobile ? "11px" : "0px",
  };

  const handleChange = (event, newValue) => {
    if (setClickEvent) {
      setClickEvent(event);
    }
    setCurrentSelectedTab(newValue);
  };

  useEffect(() => {
    const tabPermissionList = [];
    if (
      hasPermission(permissionDashboard.KPI, permissions) ||
      hasPermission(permissionDashboard.KPI_PARTNER, permissions) ||
      hasPermission(permissionSurface.KPI, permissions)
    ) {
      tabPermissionList.push(1);
    }

    if (
      hasPermission(permissionDashboard.PEOPLE, permissions) ||
      hasPermission(permissionSurface.PEOPLE, permissions)
    ) {
      tabPermissionList.push(2);
    }

    if (
      hasPermission(permissionWorkticket.MODULE, permissions) ||
      hasPermission(permissionSurface.WORKTICKET, permissions)
    ) {
      tabPermissionList.push(3);
    }

    if (
      hasPermission(permissionLegacy.QUALITY, permissions) ||
      hasPermission(permissionSurface.QUALITY, permissions) ||
      hasPermission(permissionTalent.EVALUATION_LIST_SUBMITTED, permissions) ||
      hasPermission(permissionTalent.EVALUATION_LIST_TIERED, permissions) ||
      hasPermission(permissionTalent.EVALUATION_LIST_TEAM, permissions)
    ) {
      tabPermissionList.push(4);
    }

    if (
      hasPermission(permissionAsset.MODULE, permissions) ||
      hasPermission(permissionSurface.ASSETS, permissions)
    ) {
      tabPermissionList.push(5);
    }

    if (
      hasPermission(permissionDashboard.NOTES, permissions) ||
      hasPermission(permissionSurface.NOTES, permissions)
    ) {
      tabPermissionList.push(6);
    }

    setTabPermission(tabPermissionList);
  }, [permissions]);

  return (
    <>
      <Box
        className={classes.root}
        id="root-main"
        style={{
          display: isMobile
            ? dashboardState.dasboardPropsData.closeButtonForMobile
              ? "flex"
              : "none"
            : "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={dashboardState.dasboardPropsData.currentSelectedTab}
          onChange={handleChange}
          style={tabCustomStyle}
          className={isMobile ? "hide-on-mobile-only" : "vertical-tabs"}
        >
          {tabItems
            .filter((item) => tabPermission.includes(item.key))
            .map((item, index) => {
              return (
                <Tab
                  value={item.key}
                  key={`tab-${index}`}
                  label={
                    <Tooltip title={item.label} placement="right">
                      <span>{item.icon}</span>
                    </Tooltip>
                  }
                  {...a11yProps(index)}
                  style={
                    dashboardState.dasboardPropsData.currentSelectedTab > 0 &&
                    dashboardState.dasboardPropsData.currentSelectedTab ===
                      item.key
                      ? { ...selectedStyle }
                      : { ...iconMargin }
                  }
                />
              );
            })}
        </Tabs>
        {tabPermission.includes(1) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[0].key}
            className="tabContent"
          >
            <KpiTabPage />
          </TabPanel>
        ) : null}
        {tabPermission.includes(2) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[1].key}
            className="tabContentTalent"
          >
            <RingBellTabContainer
              selectedLocation={selectedLocation}
              history={history}
            />
          </TabPanel>
        ) : null}

        {tabPermission.includes(3) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[2].key}
            className="tabContentWork"
          >
            <TableProvider>
              <WorkComponent
                history={history}
                selectedLocation={selectedLocation}
                isSurfaceUser={isSurfaceUser}
              />
            </TableProvider>
          </TabPanel>
        ) : null}

        {tabPermission.includes(4) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[3].key}
            className="tabContentQuality"
          >
            <QualityTabContainer
              selectedLocation={selectedLocation}
              history={history}
            />
          </TabPanel>
        ) : null}
        {tabPermission.includes(5) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[4].key}
            className="tabContentAsset"
          >
            <TableProvider>
              <AssetComponent
                history={history}
                selectedLocation={selectedLocation}
                isSurfaceUser={isSurfaceUser}
                setAssetModalDataVisibility={setAssetModalDataVisibility}
              />
            </TableProvider>
          </TabPanel>
        ) : null}
        {tabPermission.includes(6) ? (
          <TabPanel
            value={dashboardState.dasboardPropsData.currentSelectedTab}
            index={tabItems[5].key}
            className="tabContentNotes"
          >
            <NotesTab />
          </TabPanel>
        ) : null}
      </Box>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={dashboardState.dasboardPropsData.currentSelectedTab}
        onChange={handleChange}
        style={tabCustomStyle}
        className="vertical-tabs show-on-mobile-only align-middle-mobile"
      >
        {tabItems
          .filter((item) => tabPermission.includes(item.key))
          .map((item, index) => {
            return (
              <Tab
                value={item.key}
                key={`tab-mobile-${item.key}`}
                label={
                  <Tooltip title={item.label} placement="right">
                    <span>{item.icon}</span>
                  </Tooltip>
                }
                {...a11yProps(item.key)}
                style={
                  !dashboardState.dasboardPropsData.closeButtonForMobile
                    ? { ...iconMargin }
                    : dashboardState.dasboardPropsData.currentSelectedTab > 0 &&
                      dashboardState.dasboardPropsData.currentSelectedTab ===
                        item.key
                    ? { ...selectedStyle }
                    : { ...iconMargin }
                }
              />
            );
          })}
      </Tabs>
    </>
  );
};
export default VerticalTabs;
