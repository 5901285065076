import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import SurveysListTable from "./surveysListTable";
import SurveysListDialog from "./dialog/surveysListDialog";
import { SurveysProvider } from "contexts/surveysTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const SurveysList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Surveys" link="/surveys/admin/surveys" />
      <Box className={classes.pageBodyWrapper}>
        <SurveysProvider>
          <SurveysListTable />
          <SurveysListDialog />
        </SurveysProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(SurveysList);
