import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import RingBellTabTableActions from "./ringBellTabTableActions";
import PeopleIcon from "@material-ui/icons/People";
import EmptyScreen from "components/ui/core/emptyScreen";
import FieldName from "./fieldName";
import FieldLocation from "./fieldLocation";
import FieldAbout from "./fieldAbout";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import { ButtonBase } from "components/ui/core/button";
import SearchBar from "components/common/SearchBar/searchBar";
import Grid from "@material-ui/core/Grid";
import { ringBellPositionOptions } from "constants.js";
import RingBellManager from "./ringBellManager";
import Counter from "components/common/TableList/counter";
import { useDashboardState } from "contexts/dashboardContext";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useRingBellState,
  useRingBellDispatch,
  getRingBellList,
  getRingBellListDataSet,
  searchRingBellList,
} from "contexts/ringBellContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import OpenPositionsTable from "pages/openPositions/openPositionsTableTab";
import { OpenPositionsProvider } from "contexts/openPositionsContext";
import { hasPermission, permissionOpenPositions } from "lib/permissions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const RingBellTab = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const { ringBellListLoading, ringBellList, ringBellCount, ringBellManager } =
    useRingBellState();
  const { hasRowsClickable } = useTableState();
  const filterState = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const dispatchTable = useTableDispatch();
  const dispatchRingBell = useRingBellDispatch();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { selectedLocation } = props;
  const dashboardState = useDashboardState();
  const { refreshData, permissions } = globalUi;
  const [selected, setSelected] = useState(
    hasPermission(permissionOpenPositions.LIST_AND_VIEW, permissions) ? 1 : 2
  );
  const isSelectedLocation = Object.keys(selectedLocation).length
    ? true
    : false;

  const columns = [
    {
      id: "user",
      label: "Name",
      content: (row) => <FieldName field={row} />,
      style: { width: 250 },
    },
    {
      id: "position",
      label: "Position",
      content: (row) =>
        ringBellPositionOptions.find(
          (positionItem) => positionItem.value === row.tier_classification
        ).label,
      style: { width: 150 },
    },
    {
      id: "job_description",
      label: "Job Name",
      style: { width: 250 },
    },
  ];
  if (!isSelectedLocation || !ringBellManager) {
    columns.push({
      id: "original_hire_date",
      label: "Date of Hire",
      format: "date",
      style: { width: 50 },
    });
  }
  columns.push(
    {
      id: "about",
      label: "About",
      content: (row) => (
        <FieldAbout
          field={row.note?.length > 0 ? row.note : "-"}
          fieldLength={200}
        />
      ),
    },
    {
      id: "actions",
      label: "Recognize",
      content: (row) => <RingBellTabTableActions row={row} />,
      contentMobile: (row) => <RingBellTabTableActions row={row} />,
      style: { width: 58 },
    }
  );

  useEffect(() => {
    if (selected === 1) {
      return;
    }
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getRingBellList(dispatchRingBell, filterData, true, dashboardState);
    };
    if (filterState.searchString === "") {
      if (isSelectedLocation) {
        if (!filterState.filters.length) {
          // Map label and value with job data
          const filterData = [
            {
              filter: "job",
              group: "Job",
              values: [
                {
                  label: `${selectedLocation.job_number} - ${selectedLocation.job_description}`,
                  value: selectedLocation.id,
                  disableDelete: true,
                },
              ],
            },
          ];
          dispatchFilters({
            type: "SET_FILTERS_CORE",
            filters: filterData,
          });
        } else {
          fetchData();
        }
      } else {
        if (filterState.filters.length > 0) {
          dispatchFilters({
            type: "SET_FILTERS_CORE",
            filters: [],
          });
        } else {
          fetchData();
        }
      }
    }
  }, [dashboardState, dispatchRingBell, filterState, selected]);

  useEffect(() => {
    if (selected === 1) {
      return;
    }
    if (isSelectedLocation) {
      if (
        !filterState.filters.length ||
        filterState.filters[0].values[0].value !== selectedLocation.id
      ) {
        // Map label and value with job data
        const filterData = [
          {
            filter: "job",
            group: "Job",
            values: [
              {
                label: `${selectedLocation.job_number} - ${selectedLocation.job_description}`,
                value: selectedLocation.id,
                disableDelete: true,
              },
            ],
          },
        ];
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: filterData,
        });
      }
    } else {
      if (filterState.filters.length > 0) {
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: [],
        });
      }
    }
  }, [selectedLocation]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchRingBellList(
            query,
            filterData,
            true,
            dashboardState
          );

          dispatchRingBell({
            type: "SET_RINGBELL_LIST",
            ringBellList: results,
          });

          dispatchRingBell({
            type: "SET_RINGBELL_COUNT",
            ringBellCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  useEffect(() => {
    if (selected === 1) {
      return;
    }
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getRingBellList(dispatchRingBell, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  useEffect(() => {
    if (selected === 1) {
      return;
    }
    dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, ringBellList]);

  const handleScrollClick = useCallback(async () => {
    if (ringBellCount <= ringBellList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getRingBellListDataSet(
        ringBellList,
        dispatchRingBell,
        ringBellList.length,
        filterData,
        true,
        dashboardState
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    ringBellList,
    ringBellCount,
    filterState,
    dispatchRingBell,
    dashboardState,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  return (
    <Box className={classes.talentRoot}>
      <OpenPositionsProvider>
        <Box className={classes.headerTab}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Talent
            </Typography>
          </Box>
          <Box className={classes.talentTabContainer}>
            {["Open Positions", "Recognition"].map((v, i) => {
              if (
                !hasPermission(
                  permissionOpenPositions.LIST_AND_VIEW,
                  permissions
                ) &&
                i === 0
              ) {
                return null;
              }
              return (
                <Box
                  key={i}
                  className={classes.talentTab}
                  style={{
                    borderBottom: selected !== i + 1 ? "" : "3px solid #16457A",
                    color: selected !== i + 1 ? "#C4C4C4" : "#16457A",
                  }}
                  onClick={() => {
                    dispatchTable({ type: "UPDATE_ROWS", rows: [] });
                    dispatchTable({ type: "UPDATE_COLUMNS", columns: [] });
                    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
                    handleSearchClear();

                    setSelected(i + 1);
                  }}
                >
                  {v}
                </Box>
              );
            })}
          </Box>
          <Box>
            <Box
              className={classes.filterContainer}
              style={{ visibility: selected === 1 ? "hidden" : "visible" }}
            >
              <SearchBar
                handleSearch={handleSearch}
                handleSearchClear={handleSearchClear}
                placeholder={"Search"}
              />
            </Box>
            <ButtonBase
              color="secondary"
              onClick={() => {
                if (selected === 1) {
                  props.history.push("/open-position");
                } else {
                  props.history.push("/team");
                }
              }}
              disableElevation
            >
              View All
            </ButtonBase>
          </Box>
        </Box>
        {selected === 2 && (
          <Box className={classes.tableBody}>
            <Box className={classes.pageBodyWrapper}>
              <Box className={classes.root}>
                {ringBellListLoading ? (
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoadingTab}
                  />
                ) : !ringBellList.length && filterState.searchString === "" ? (
                  <EmptyScreen
                    icon={<PeopleIcon />}
                    type={3}
                    actionText="Perform a Site Survey"
                  />
                ) : (
                  <Grid container spacing={2}>
                    {isSelectedLocation && ringBellManager && (
                      <Grid item md={4}>
                        <RingBellManager manager={ringBellManager} />
                      </Grid>
                    )}
                    {!loadingSearch && (
                      <Grid
                        item
                        md={isSelectedLocation && ringBellManager ? 8 : 12}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.contentCounter}>
                            <Counter>{`${ringBellList.length} / ${ringBellCount}`}</Counter>
                          </Box>
                          <Box className={classes.rootContainer}>
                            <Box className={classes.tableContainer}>
                              <Box className={classes.sliderHeightTable}>
                                <Typography
                                  variant="h5"
                                  className={classes.tabSubTitle}
                                >
                                  Encompass Team
                                </Typography>
                                <Table
                                  handleRowClick={handleRowClick}
                                  newColumns={columns}
                                  handleScrollClick={handleScrollClick}
                                  idscrollwrapper="table-tab-wrapper-talent"
                                  activeMobile={true}
                                >
                                  {filterState.filters && (
                                    <FiltersAppliedList />
                                  )}
                                </Table>
                              </Box>
                            </Box>
                          </Box>
                          {loadingMore && (
                            <Box className={classes.loadingTable}>
                              <LinearProgress color="secondary" />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {selected === 1 ? (
          <Box>
            <OpenPositionsTable />
          </Box>
        ) : null}
      </OpenPositionsProvider>
    </Box>
  );
};

export default withRouter(RingBellTab);
