export const loadGeo = () => {
  if (!navigator.geolocation) {
    console.log("Geo NOT supported on your browser");
    return false;
  } else {
    console.log("Geo supported on your browser");
    return true;
  }
};

export const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position);
        },
        () => {
          console.log("Reject current location");
          resolve(null);
        }
      );
    }
  });
};
