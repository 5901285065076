import http from "./httpService";
import { apiUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";
import { getFilterString } from "components/util/filterUtil";
const apiEndpoint = `${apiUrl}/team`;

export function getDetailForUser(userId) {
  return http.get(`${apiEndpoint}/users/${userId}`, {});
}

export function updateProfilePicture(userId, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/${userId}/profile-picture`,
    setFormData(data)
  );
}

export function updateProfileInformation(userId, data) {
  return http.post(`${apiEndpoint}/${userId}`, data);
}

export function getRecognition(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/recognitions?${filterString}`);
}

export function getRecognitionSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/recognitions?${filterString}search=${search}`,
    {}
  );
}

export function getRecognitionOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/recognitions?${filterString}offset=${offset}`,
    {}
  );
}

export function deleteRecognition(id) {
  return http.delete(`${apiEndpoint}/recognitions/${id}`);
}

export function getUserLocations(userId, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/users/${userId}/jobs?${filterString}`);
}

export function getUserLocationsSearch(userId, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/users/${userId}/jobs?${filterString}search=${search}`,
    {}
  );
}

export function getUserLocationsOffset(userId, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/users/${userId}/jobs?${filterString}offset=${offset}`,
    {}
  );
}

export function getUserAssignedLocations(userId) {
  return http.get(`${apiEndpoint}/users/${userId}/jobs-assigned`);
}

export function requestLocationsChanges(userId, data) {
  return http.post(`${apiEndpoint}/users/${userId}/change-jobs`, data);
}

export function getOrgChart() {
  return http.get(`${apiEndpoint}/org-chart`);
}

export function getOrgChartOperations() {
  return http.get(`${apiEndpoint}/org-chart/full?type=operations`);
}

export function getOrgChartDetail(id) {
  return http.get(`${apiEndpoint}/org-chart/details/${id}`);
}

export function getOrgChartChildren(userId) {
  return http.get(`${apiEndpoint}/org-chart/children/${userId}`);
}
