import React, { useReducer } from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "../container";
import DataProvider from "../DataProvider";
import SideNav from "../SideNav";
import ProfileNav from "../ProfileNav/profileNav";
import SideDrawer from "../Drawer/sideDrawer";
import Speedial from "../speedDial";
import DownloadBadgeAppDialog from "../DownloadBadgeApp/dialog";
import theme from "theme";
import { reducer, InitialState, Provider } from "contexts/globalUiContext";
import { PageHeaderProvider } from "contexts/pageHeaderContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { isMobile } from "react-device-detect";
import useStyles from "./styles";
import BannerDownloadApp from "../DownloadBadgeApp/banner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const MainLayout = (props) => {
  const { children } = props;
  const classes = useStyles();
  const [globalUi, dispatchGlobalUi] = useReducer(reducer, InitialState);

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.mainContainer}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Provider value={{ globalUi, dispatchGlobalUi }}>
            <DataProvider>
              <SideNav />
              <ProfileNav />
              <PageHeaderProvider>
                <main className={classes.content}>{children}</main>
                <SideDrawer />
              </PageHeaderProvider>
              <Speedial />
            </DataProvider>
          </Provider>
        </QueryClientProvider>
        {isMobile && <DownloadBadgeAppDialog />}
        {isMobile && <BannerDownloadApp />}
      </Container>
    </ThemeProvider>
  );
};

export default MainLayout;
