import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
import * as classNames from "classnames";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import {
  getFilterCustomers,
  getFilterJobsCustomer,
} from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import GlobalUiContext from "contexts/globalUiContext";
import moment from "moment";
import {
  createOpenPosition,
  getPositionTitle,
} from "services/openPositionsService";
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/NavigateBefore";

const isnum = (val) => /^\d+$/.test(val);

const TalentRequest = ({
  setOpenWeeklySchedule,
  weeklyScheduleData,
  setOpenTalentRequest,
  setReload,
  tabUpdated,
  setTabUpdated,
  setWeeklyScheduleData,
  refresh,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(1);
  const [dueDate, setDueDate] = useState(moment());
  const [lastDate, setLastDate] = useState(moment());
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");
  const [selectedRequestType, handleRequestType] = useState("");
  const [jobTitleOptions, setJobTitleOptions] = useState("");
  const [jobTitleOptionsSelected, setJobTitleOptionsSelected] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });
  const [requestFor, setRequestFor] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [employeeReplacing, setEmployeeReplacing] = useState("");
  const requestedForOptions = [
    { label: "Team Member", value: 2 },
    { label: "Me", value: 1 },
  ];
  const requestTypeOptions = [
    { label: "New", value: "New" },
    { label: "Replacement", value: "Replacement" },
  ];
  const [selectedHourlyOrMonthly, handleSelectedHourlyOrMonthly] = useState("");
  const selectedHourlyOrMonthlyOptions = [
    { label: "Hourly", value: "Hourly" },
    { label: "Monthly", value: "Monthly" },
  ];
  const [reasonForTermination, setReasonForTermination] = useState("");
  const [explainationForTermination, setExplainationForTermination] =
    useState("");
  const [siteReq, setSiteReq] = useState("");
  const [positionRate, setPositionRate] = useState("");
  const [positionQuantity, setPositionQuantity] = useState(1);
  const [hasSchedule, setHasSchedule] = useState(false);

  const shouldNextBeDisabled = () => {
    if (currentTab === 1) {
      if (!requestFor) {
        return true;
      }
      if (!customerSelected) {
        return true;
      }
      if (customerSelected && !jobSelected) {
        return true;
      }
      if (!selectedRequestType) {
        return true;
      }
      return false;
    }
    if (currentTab === 2) {
      if (!employeeReplacing) {
        return true;
      }
      if (!lastDate) {
        return true;
      }
      if (!reasonForTermination) {
        return true;
      }
      if (!explainationForTermination) {
        return true;
      }
      return false;
    }
    if (currentTab === 3) {
      if (!dueDate) {
        return true;
      }
      if (!jobTitleOptionsSelected) {
        return true;
      }
      if (!positionRate) {
        return true;
      }
      if (!selectedHourlyOrMonthly) {
        return true;
      }

      if (!weeklyScheduleData) {
        return true;
      }

      if (!hasSchedule) {
        return true;
      }
      if (!siteReq) {
        return true;
      }
      if (positionQuantity < 0 || !positionQuantity) {
        return true;
      }
      return false;
    }
  };

  const handleChangeCustomer = (event, value) => {
    setJobSelected("");
    setCustomerSelected(value);
  };

  useEffect(() => {
    getPositionTitle().then((res) => {
      let transformed = [];
      const response = res?.data?.data?.open_positions_titles?.map((v) => {
        transformed.push({ label: v?.name, value: v?.number });
      });
      setJobTitleOptions(transformed);
    });
  }, []);

  useEffect(() => {
    let validSchedule = false;
    for (const keySchedule in weeklyScheduleData) {
      if (
        weeklyScheduleData[keySchedule].startTime &&
        weeklyScheduleData[keySchedule].endTime
      ) {
        validSchedule = true;
      }
    }
    setHasSchedule(validSchedule);
  }, [weeklyScheduleData]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultForRender = await getFilterCustomers();
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers.length) {
      loadCustomer();
    }
  }, [customers]);

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        if (customerSelected) {
          const resultForRender = await getFilterJobsCustomer(
            customerSelected?.value,
            true
          );
          setJobs(resultForRender);
        }
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };

    loadJob();
  }, [customerSelected?.value]);

  return (
    <Box>
      <Box className={classes.step}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenTalentRequest(false);
            setWeeklyScheduleData("");
          }}
          className={classes.closeIcon}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>

        <Typography className={classes.currentTab}>
          Step {currentTab}/3
        </Typography>
        <Typography className={classes.talentRequest}>
          Talent Request
        </Typography>
        <Typography className={classes.tellMore}>
          Tell us more about the position you need.
        </Typography>
      </Box>
      {currentTab === 1 && (
        <Box>
          <Box className={classes.padding23}>
            <Typography className={classes.talentRequestLabel}>
              Who is this Request For ?
            </Typography>
            <FormSelectAuto
              options={requestedForOptions}
              name="user"
              handleChange={(e, v) => setRequestFor(v)}
              value={requestFor}
            />
            {requestFor?.value === 2 && (
              <Typography className={classes.talentRequestLabel}>
                Team Member
              </Typography>
            )}
            {requestFor?.value === 2 && (
              <FormSelectAuto
                options={userOptions}
                name="user"
                handleChange={(e, v) => setTeamMember(v)}
                value={teamMember}
              />
            )}
            <Typography className={classes.talentRequestLabel}>
              Customer
            </Typography>

            {Boolean(customers.length) ? (
              <FormSelectAuto
                options={customers}
                name="customer"
                handleChange={handleChangeCustomer}
                value={customerSelected}
              />
            ) : (
              <LinearProgress color="secondary" />
            )}

            {customerSelected && (
              <Typography className={classes.talentRequestLabel}>
                Job Name
              </Typography>
            )}

            {customerSelected && (
              <span>
                {Boolean(jobs) ? (
                  <FormSelectJobsAuto
                    options={jobs}
                    name="job_number"
                    handleChange={(e, v) => setJobSelected(v)}
                    value={jobSelected}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )}
              </span>
            )}

            <Typography className={classes.talentRequestLabel}>
              Request Type
            </Typography>
            <FormSelectAuto
              options={requestTypeOptions}
              handleChange={(e, v) => handleRequestType(v)}
              value={selectedRequestType}
            />
          </Box>
        </Box>
      )}
      {currentTab === 2 && (
        <Box>
          <Box className={classes.padding23}>
            <Typography className={classes.talentRequestLabel}>
              Employee Replacing
            </Typography>
            <FormSelectAuto
              options={userOptions}
              name="user"
              handleChange={(e, v) => setEmployeeReplacing(v)}
              value={employeeReplacing}
            />
            <Typography className={classes.talentRequestLabel}>
              Last Day Working
            </Typography>
            <FormInputDateAdo
              name="date"
              value={lastDate}
              handleChange={setLastDate}
            />
            <Typography className={classes.talentRequestLabel}>
              Reason For Termination
            </Typography>
            <TextField
              name="email"
              className={classes.field}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
              }}
              fullWidth
              value={reasonForTermination}
              onChange={(e) => setReasonForTermination(e.target.value)}
            />
            <Typography className={classes.talentRequestLabel}>
              Explanation For Termination
            </Typography>
            <TextField
              className={classes.textArea}
              value={explainationForTermination}
              onChange={(e) => setExplainationForTermination(e.target.value)}
              placeholder=""
              multiline
              rows={4}
              maxRows={70}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>
      )}

      {currentTab === 3 && (
        <Box>
          <Box className={classes.padding23}>
            <Typography className={classes.talentRequestLabel}>
              Date Needed
            </Typography>
            <FormInputDateAdo
              name="date"
              //label="In Date"
              value={dueDate}
              handleChange={setDueDate}
            />
            <Typography className={classes.talentRequestLabel}>
              Position Title
            </Typography>
            {Boolean(jobTitleOptions.length) ? (
              <FormSelectAuto
                options={jobTitleOptions}
                // name="customer"
                handleChange={(e, v) => setJobTitleOptionsSelected(v)}
                value={jobTitleOptionsSelected}
              />
            ) : (
              <LinearProgress color="secondary" />
            )}
            <Typography className={classes.talentRequestLabel}>
              No of Positions needed
            </Typography>

            <TextField
              className={classes.textArea}
              value={positionQuantity}
              onChange={(e) => {
                return e.target.value === ""
                  ? setPositionQuantity("")
                  : e.target.value >= 1 && isnum(e.target.value)
                  ? setPositionQuantity(e.target.value.trim())
                  : "";
              }}
              placeholder=""
              InputProps={{
                className: classes.input,
                disableUnderline: true,
                pattern: "[0-9]*",
              }}
            />

            <Box className={classes.flex}>
              <Box className={classes.flex1marginRight5}>
                <Typography className={classes.talentRequestLabel}>
                  Position Rate
                </Typography>
                <TextField
                  name="rate"
                  value={positionRate}
                  onChange={(e) =>
                    (e.target.value >= 0 &&
                      Number(e.target.value) &&
                      Number(e.target.value) >= 0) ||
                    e.target.value.trim() === "" ||
                    e.target.value === "." ||
                    e.target.value === "0" ||
                    e.target.value === "0."
                      ? setPositionRate(e.target.value.trim())
                      : ""
                  }
                  min={0}
                  className={classes.posRate}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                    pattern: "[0-9]*",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Box>
              <Box className={classes.flex1Type}>
                <Typography
                  className={classNames(
                    classes.talentRequestLabel,
                    classes.trType
                  )}
                >
                  Type
                </Typography>
                <FormSelectAuto
                  options={selectedHourlyOrMonthlyOptions}
                  handleChange={(e, v) => handleSelectedHourlyOrMonthly(v)}
                  value={selectedHourlyOrMonthly}
                />
              </Box>
            </Box>
            <Box className={classes.flex}>
              <Box className={classes.flex1marginRight5}>
                <Typography className={classes.talentRequestLabel}>
                  Position Job Code
                </Typography>
                <TextField
                  name="email"
                  className={classes.field}
                  value={jobTitleOptionsSelected?.value}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box className={classes.flex1}>
                <Typography className={classes.talentRequestLabel}>
                  Schedule
                </Typography>
                <TextField
                  name="email"
                  className={classes.field}
                  onClick={() => setOpenWeeklySchedule(true)}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRangeIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  value={
                    weeklyScheduleData && hasSchedule ? "Updated" : "Please Set"
                  }
                />
              </Box>
            </Box>
            <Typography className={classes.talentRequestLabel}>
              Site Requirements
            </Typography>

            <TextField
              className={classes.textArea}
              value={siteReq}
              onChange={(e) => setSiteReq(e.target.value)}
              placeholder=""
              multiline
              rows={4}
              rowsMax={70}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>
      )}

      <Box className={classes.trContainer}>
        <Box className={classes.trSubContainer}>
          <Box className={classes.flex}>
            {currentTab === 1 ? (
              ""
            ) : (
              <Button
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  if (
                    currentTab === 3 &&
                    selectedRequestType?.value === "New"
                  ) {
                    setCurrentTab(currentTab - 2);
                  } else {
                    if (currentTab > 1) {
                      setCurrentTab(currentTab - 1);
                    }
                  }
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          {shouldNextBeDisabled() ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(
                classes.button,
                classes.buttonSecondary,
                classes.btnDisabled,
                classes.nxtAdj
              )}
              disabled={true}
            >
              {" "}
              {currentTab === 3 ? "Submit" : "Next"}
            </Button>
          ) : (
            <Button
              disabled={inProgress}
              className={classNames(
                classes.buttonBeginLink,
                classes.buttonBegin,
                classes.nxtAdj
              )}
              onClick={(e) => {
                if (currentTab < 3) {
                  if (selectedRequestType?.value === "New") {
                    setCurrentTab(currentTab + 2);
                  } else {
                    setCurrentTab(currentTab + 1);
                  }
                }
                if (currentTab === 3) {
                  if (shouldNextBeDisabled()) {
                    return true;
                  }
                  let formDataPayload = new FormData();
                  formDataPayload.append("requestor_type", requestFor?.value);
                  formDataPayload.append(
                    "requestor_user_id",
                    teamMember?.value
                  );
                  formDataPayload.append(
                    "customer_id",
                    customerSelected?.value
                  );
                  formDataPayload.append("job_id", jobSelected?.id);
                  formDataPayload.append(
                    "user_id",
                    employeeReplacing?.value ?? 0
                  );
                  formDataPayload.append(
                    "type",
                    selectedRequestType.value === "New" ? 1 : 2
                  );
                  formDataPayload.append(
                    "position_title",
                    jobTitleOptionsSelected?.label
                  );
                  formDataPayload.append("position_rate", positionRate);
                  formDataPayload.append(
                    "position_type",
                    selectedHourlyOrMonthly?.value
                  );
                  formDataPayload.append(
                    "position_job_code",
                    jobTitleOptionsSelected?.value
                  );
                  formDataPayload.append(
                    "position_schedule",
                    JSON.stringify(weeklyScheduleData)
                  );
                  formDataPayload.append("terminated_last_date", lastDate);
                  formDataPayload.append(
                    "terminated_reason",
                    reasonForTermination
                  );
                  formDataPayload.append(
                    "terminated_explanation",
                    explainationForTermination
                  );
                  formDataPayload.append("date_requested", dueDate);
                  formDataPayload.append("site_requirement", siteReq);
                  formDataPayload.append("position_quantity", positionQuantity);
                  if (inProgress) {
                    return;
                  }
                  setInProgress(true);
                  createOpenPosition(formDataPayload).then((res) => {
                    if (res.status === 201) {
                      setOpenTalentRequest(false);
                      setReload(!refresh);
                      setTabUpdated({
                        ...tabUpdated,
                        1: tabUpdated[2] + Number(positionQuantity),
                      });
                      setInProgress(false);
                    }
                  });
                }
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {currentTab === 3 ? "Save" : "Next"}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default TalentRequest;
