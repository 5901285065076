import { createContext, useState, useContext } from "react";

const AllRatingsDialogueContext = createContext();

export const AllRatingsModalProvider = ({ children }) => {
  const [openAllRatingsDialogue, setOpen] = useState(false);

  const handleDialogueOpen = () => {
    console.log("handleDialogueOpen");
    console.log(openAllRatingsDialogue);
    setOpen(true);
  };

  const handleDialogueClose = () => {
    setOpen(false);
  };

  return (
    <AllRatingsDialogueContext.Provider
      value={{
        openAllRatingsDialogue,
        handleDialogueOpen,
        handleDialogueClose,
      }}
    >
      {children}
    </AllRatingsDialogueContext.Provider>
  );
};

export const useAllRatingsModal = () => useContext(AllRatingsDialogueContext);
