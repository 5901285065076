import React from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useRingBellSurfaceDispatch } from "contexts/ringBellSurfaceContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const RingBellSurfaceBulkActions = (props) => {
  const classes = useStyles();
  const dispatchRingBell = useRingBellSurfaceDispatch();

  const handleOpenRingBell = () => {
    dispatchRingBell({
      type: "TOGGLE_RINGBELL",
      openRingBell: true,
    });
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classNames(classes.button, classes.buttonSecondary)}
        onClick={handleOpenRingBell}
      >
        Ring Bell
      </Button>
    </div>
  );
};

export default RingBellSurfaceBulkActions;
