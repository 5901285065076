import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Counter from "components/common/TableList/counter";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import NotesManage from "./notesManage";
import NoteTableActions from "./noteTableActions";
import NotesListActions from "./notesListActions";
import NoteFilters from "./noteFilters";
import FieldName from "./fieldName";
import FieldLocation from "./fieldLocation";
import FieldDescription from "./fieldDescription";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { useJobView } from "contexts/jobViewContext";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useNoteState,
  useNoteDispatch,
  getNoteList,
  searchNotes,
  getNoteListDataSet,
} from "contexts/noteContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";

const columns = [
  {
    id: "user",
    label: "Name",
    content: (row) => <FieldName field={row.user} />,
  },
  {
    id: "jobs",
    label: "Locations",
    content: (row) => <FieldLocation jobs={row.jobs} />,
  },
  { id: "created_at", label: "Date", format: "date" },
  {
    id: "type",
    label: "Type",
    content: (row) => (row.type === 1 ? "Team" : "Private"),
  },
  {
    id: "summary",
    label: "Note Summary",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "description",
    label: "Note Detail",
    content: (row) => (
      <FieldDescription field={row.description} fieldLength={80} />
    ),
  },
  {
    id: "actions",
    content: (row) => <NoteTableActions row={row} />,
    contentMobile: (row) => <NoteTableActions row={row} mobile={true} />,
    style: { width: 58 },
  },
];

const NotesTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { noteListLoading, noteList, noteCount } = useNoteState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchNotes = useNoteDispatch();
  const dispatchFilters = useFilterDispatch();
  const [stateContext] = useJobView();
  const { job } = stateContext ?? null;

  const { refreshData } = globalUi;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [...filterData.filters];
      filterData.filters.push({
        filter: "job",
        group: "Job",
        values: [{ label: "", value: job.id }],
      });
      await getNoteList(dispatchNotes, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchNotes, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [...filterData.filters];
      filterData.filters.push({
        filter: "job",
        group: "Job",
        values: [{ label: "", value: job.id }],
      });
      await getNoteList(dispatchNotes, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: noteList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, noteList]);

  const handleScrollClick = useCallback(async () => {
    if (noteCount <= noteList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = [...filterData.filters];
      filterData.filters.push({
        filter: "job",
        group: "Job",
        values: [{ label: "", value: job.id }],
      });
      await getNoteListDataSet(
        noteList,
        dispatchNotes,
        noteList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: noteList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    noteList,
    noteCount,
    filterState,
    dispatchNotes,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = [...filterData.filters];
          filterData.filters.push({
            filter: "job",
            group: "Job",
            values: [{ label: "", value: job.id }],
          });
          let results = await searchNotes(query, filterData);
          // Send result to search tab
          dispatchNotes({
            type: "SET_NOTE_LIST",
            noteList: results,
          });

          dispatchNotes({
            type: "SET_NOTE_COUNT",
            noteCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {noteListLoading || loadingSearch ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Counter>{`${noteList.length} / ${noteCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <div>
                      <EnhancedTableToolbar>
                        {selected.length > 0 ? (
                          <ToolbarSelected></ToolbarSelected>
                        ) : (
                          <ToolbarDefault
                            handleSearch={handleSearch}
                            placeholderSearch={"All Notes"}
                            handleSearchClear={handleSearchClear}
                          >
                            <NotesListActions />
                            <NoteFilters />
                          </ToolbarDefault>
                        )}
                      </EnhancedTableToolbar>
                    </div>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
            <NotesManage />
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(NotesTable);
