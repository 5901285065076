import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { getQuoteDetails } from "services/quoteService";
import { logException } from "components/util/logUtil";
import Grid from "@material-ui/core/Grid";
import QuoteStatusChip from "components/ui/Quotes/QuoteStatusChip";
import { formatNumber } from "components/util/stringFormat";
import { quoteTypeOptions } from "../../../constants";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TimelineIcon from "@material-ui/icons/Timeline";
import { convertDateFormatField } from "components/util/timeFormat";

import useStyles from "./styles";

const QuoteDashboardDialog = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [quote, setQuote] = useState(null);
  const { isOpen, handleClose, id } = props;
  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getQuoteDetails(id);
        const { quote: quoteData } = result.data.data;
        setQuote(quoteData);
        setDetailData([
          { title: "Quote Number", value: quoteData.number, column: 1 },
          {
            title: "Quote Owner",
            value: `${quoteData.user?.first_name} ${quoteData.user?.last_name}`,
            column: 2,
          },
          { title: "Customer", value: quoteData.customer?.name, column: 1 },
          {
            title: "Job Name",
            value: quoteData.job?.job_description,
            column: 2,
          },
          {
            title: "Status",
            value: (
              <QuoteStatusChip
                status={quoteData.status}
                archived={quoteData.archived}
                dueDate={quoteData.due_date}
                pendingPlanning={quoteData.pending_planning}
              />
            ),
            column: 1,
          },
          { title: "Summary", value: quoteData.subject, column: 2 },

          {
            title: "Project",
            value: quoteData.project?.title,
            column: 1,
          },
          {
            title: "Quote Type",
            value: quoteTypeOptions.find(
              (item) => item.value === quoteData.type
            )?.label,
            column: 2,
          },
        ]);
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load quote detail dashboard data");
      }
    };
    if (isOpen && id) {
      getData();
    } else if (!isOpen) {
      setIsLoading(true);
    }
  }, [isOpen, id]);

  const ActivityItem = (props) => (
    <ListItem className={classes.listContainer}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(props.activity.created_at)}
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="max-width-dialog-title"
      maxWidth={"sm"}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Box className={classes.formContainer}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h3" className={classes.sectionHeader}>
                    Quote Details
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerL}>
                  {detailData
                    .filter((d) => d.column === 1)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-1`}>
                          <Typography
                            key={`${index}-1a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-1b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerR}>
                  {detailData
                    .filter((d) => d.column === 2)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-2`}>
                          <Typography
                            key={`${index}-2a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-2b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
              </Grid>
              {quote.quote_items && quote.quote_items.length > 0 && (
                <Grid container className={classes.itemsContainer}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.section}
                  >
                    <Typography
                      variant="h3"
                      className={classes.sectionInterHeader}
                    >
                      Quote Line Items
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemHeader}
                      noWrap
                    >
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={2} lg={2}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemHeaderCenter}
                      noWrap
                    >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={2} lg={2}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemHeaderCenter}
                      noWrap
                    >
                      Hours
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={2} lg={2}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemHeaderCenter}
                      noWrap
                    >
                      Amount
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {quote.quote_items &&
                quote.quote_items.map((item, index) => {
                  return (
                    <Grid
                      container
                      className={classes.itemsContainerDetail}
                      key={item.id}
                    >
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemDetail}
                          noWrap
                        >
                          {item.item_subject}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        className={classes.alignRight}
                      >
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemDetail}
                          noWrap
                        >
                          {item.quantity
                            ? `${formatNumber(item.quantity)} ${
                                item.uom ? item.uom : ""
                              }`
                            : "-----"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        className={classes.alignRight}
                      >
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemDetail}
                          noWrap
                        >
                          {item.hours
                            ? formatNumber(parseFloat(item.hours).toFixed(2))
                            : "-----"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        lg={2}
                        className={classes.alignRight}
                      >
                        <Typography
                          variant="body2"
                          color="inherit"
                          className={classes.itemDetail}
                          noWrap
                        >
                          {item.total && item.item_type !== "task"
                            ? `$ ${formatNumber(
                                parseFloat(item.total).toFixed(2)
                              )}`
                            : "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              {quote.quote_items && quote.quote_items.length > 0 && (
                <Grid container className={classes.itemsContainerDetail}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummary}
                      noWrap
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={2} lg={2}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummary}
                      noWrap
                    ></Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummary}
                      noWrap
                    >
                      {formatNumber(parseFloat(quote.hours).toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    lg={2}
                    className={classes.alignRight}
                  >
                    <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.itemSummary}
                      noWrap
                    >
                      {`$ ${formatNumber(parseFloat(quote.total).toFixed(2))}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {(!quote.quote_items || quote.quote_items.length === 0) && (
                <Typography>No items added.</Typography>
              )}
              <Grid container className={classes.sectionContainer}>
                <Grid item xs={12} md={12} lg={12} className={classes.section}>
                  <Typography
                    variant="h3"
                    className={classes.sectionInterHeader2}
                  >
                    Activity
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className={classes.containerActivity}>
                    <List className={classes.containerActivityList}>
                      {quote.activity.map((activity) => (
                        <ActivityItem key={activity.id} activity={activity} />
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => props.history.push(`/quote/${quote.id}`)}
            >
              View
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default withRouter(QuoteDashboardDialog);
