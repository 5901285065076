import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = apiUrl + "/services";

export function getServices(category) {
  return http.get(`${apiEndpoint}/`, {
    params: { category },
  });
}

export function getServicesAdmin(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/admin?${filterString}`, {
    params: {},
  });
}

export function updateService(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function createService(data) {
  return http.post(`${apiEndpoint}`, data);
}

export function deleteService(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export function getIndustries() {
  return http.get(`${apiEndpoint}/industries`);
}
