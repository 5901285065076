import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: "1px solid #d4d4d4"
  },
  chip: {
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif'
  },
  icon: {
    fontSize: 14,
    color: "#fff"
  },
  chipSnoozed: {
    backgroundColor: "#f39a3e",
    color: "#fff"
  },
  chipDraft: {
    backgroundColor: "#c1c1c1",
    color: "#fff"
  },
  chipNotScheduled: {
    backgroundColor: "#78c1ce",
    color: "#fff"
  },
  chipNotStarted: {
    backgroundColor: "#78c1ce",
    color: "#fff"
  },
  chipInProgress: {
    backgroundColor: "#efc945",
    color: "#fff"
  },
  chipDone: {
    backgroundColor: "#9cbb65",
    color: "#fff"
  },
  chipCancelled: {
    backgroundColor: "#f07a8c",
    color: "#fff"
  },
  chipOverdue: {
    backgroundColor: "#f07a8c",
    color: "#fff"
  },
  chipArchived: {
    backgroundColor: "#4d4d4d",
    color: "#fff"
  },
  chipPlanning: {
    backgroundColor: "#0b417e",
    color: "#fff"
  }
}));

export default useStyles;
