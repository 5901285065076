import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import eosOneLogo from "../../../assets/eos-one-logo.svg";
import eosGroup from "../../../assets/eos-group.svg";
import iphoneX2 from "../../../assets/iPhoneX2.png";
import iphoneX3 from "../../../assets/iPhoneX3.png";
import iphoneX4 from "../../../assets/iPhoneX4.png";
import AppleIcon from "@material-ui/icons/Apple";
import AdbIcon from "@material-ui/icons/Adb";
import { osName } from "react-device-detect";
import useStyles from "./styles";
import { getUserPermissions } from "services/userService";

const DownloadBadgeAppDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const getRole = async () => {
      const resultPermissions = await getUserPermissions();
      const role = resultPermissions.data.data.role;
      setRole(role);
    };
    getRole();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const universalLinkUrl = `${window.location.protocol}//${window.location.hostname}/onboard/welcome-sign-in`;
    window.location = universalLinkUrl;
    setOpen(false);
  };

  const renderAndroidButton = () => {
    return (
      <Button
        className={classes.androidButton}
        variant="contained"
        color="primary"
        href="#"
        fullWidth
        startIcon={<AdbIcon />}
        onClick={handleDownload}
      >
        Encompass One for Android
      </Button>
    );
  };

  const renderiOSButton = () => {
    return (
      <Button
        className={classes.iOSButton}
        variant="contained"
        color="primary"
        href="#"
        fullWidth
        startIcon={<AppleIcon />}
        onClick={handleDownload}
      >
        Encompass One for iOS
      </Button>
    );
  };

  if (role?.category !== "subcontractor") {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <Box position="absolute" top={0} right={0} m={1} zIndex={9}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          onTouchStart={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Box className={classes.container}>
          <Box className={classes.logoContainer}>
            <img src={eosOneLogo} alt="eos-one" className={classes.eosLogo} />
            <img src={eosGroup} alt="eos-group" />
          </Box>

          <Typography variant="body1" className={classes.appMessage}>
            Use our mobile app <br /> for a better experience on the go!
          </Typography>

          <Box className={classes.imageContainer}>
            <img src={iphoneX3} alt="iphoneX3" />
            <img src={iphoneX4} alt="iphoneX3" />
            <img src={iphoneX2} alt="iphoneX2" />
          </Box>

          <Box className={classes.buttonContainer}>
            {osName === "iOS" && renderiOSButton()}
            {osName === "Android" && renderAndroidButton()}
            {osName !== "iOS" && osName !== "Android" && (
              <React.Fragment>
                {renderiOSButton()}
                {renderAndroidButton()}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadBadgeAppDialog;
