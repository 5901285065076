import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import eosLogo from "assets/icons/encompass-logo.png";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { ButtonBase } from "components/ui/core/button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import ContactIcon from "@material-ui/icons/PermContactCalendar";
import { logException } from "components/util/logUtil";
import { saveCustomerContact } from "services/customerService";
import { getJobsCustomer } from "services/jobService";
import { useJobView } from "contexts/jobViewContext";

const useStyles = makeStyles((theme) => ({
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  formContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  formContainerLogo: {
    paddingRight: 40,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  formContainerContent: {
    marginTop: 20,
  },
  formWrapperHeader: {
    marginBottom: 20,
    borderBottom: "1px solid #f3f3f3",
    width: "100%",
  },
  formContainerHeader: {
    marginTop: 20,
    marginBottom: 20,
    width: 320,
    float: "right",
  },
  formContainerHeaderReadonly: {
    marginTop: 20,
    marginBottom: 20,
    width: 200,
    float: "right",
  },
  chipJob: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerJobInput: {
    "& > div": {
      marginBottom: 10,
    },
    "& input": {
      fontSize: 14,
    },
  },
  containerError: {
    padding: 10,
    color: "#ffffff",
    backgroundColor: theme.palette.error.dark,
    textAlign: "center",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  containerLogo: {
    textAlign: "center",
    border: "1px solid #f3f3f3",
    padding: 20,
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  inputImage: {
    display: "none",
  },
  logoImage: {
    maxWidth: 240,
    maxHeight: 100,
    cursor: "pointer",
    objectFit: "cover",
  },
  logoImageEdit: {
    position: "absolute",
    top: 8,
    right: 8,
    cursor: "pointer",
    zIndex: 100,
  },
  logoIconEdit: {
    fontSize: 14,
  },
  iconDialog: {
    fontSize: 32,
  },
  containerNotification: {
    minHeight: 320,
  },
  checkboxNotification: {
    "& + .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
  },
  infoAlert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: 0,
    position: "relative",
    top: "-24px",
  },
  loadingContainer: {
    height: "100vh",
    "& >  div": {
      height: "85vh",
    },
  },
}));

const initNewData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  title: "",
  about: "",
  password: "",
  password_confirmation: "",
  notification_survey: false,
};

const TabOptions = [
  { id: 1, text: "Information", tab: "information", onTabing: true },
  { id: 2, text: "Notifications", tab: "notifications", onTabing: true },
];

const ContactsManage = (props) => {
  const classes = useStyles();
  const [mode, setMode] = useState(null);
  const [tab, setTab] = useState(1);
  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [jobsList, setJobsList] = useState([]);
  const [jobsData, setJobsData] = useState(null);
  const [type, setType] = useState(1);
  const [newData, setNewData] = useState(initNewData);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [stateContext, dispatchContext] = useJobView();
  const { job, openCreateContact } = stateContext ?? null;

  useEffect(() => {
    setMode("CREATE");
    setLogo(null);
    setIsLoading(false);
    setTab(1);
  }, [job]);

  useEffect(() => {
    if (!openCreateContact) {
      setNewData(initNewData);
      setJobsList([]);
    }
  }, [openCreateContact]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const result = await getJobsCustomer(job.customer_id);
        const resultForRender = result.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer ? job.customer.id : 0,
          };
        });
        setJobsData(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs");
      }
    };
    if (openCreateContact && !jobsData) {
      loadJobs();
    }
  }, [job.customer_id, openCreateContact, jobsData]);

  const handleCreateBlur = async (event) => {
    setError("");
    if (newData[event.name] !== event.value) {
      const data = {
        ...newData,
        [event.name]: event.value,
      };
      setNewData(data);
    }
  };

  const handleCreate = async () => {
    if (newData.first_name === "") {
      setError("Enter first name for the contact.");
      return;
    }
    if (newData.last_name === "") {
      setError("Enter last name for the contact.");
      return;
    }
    if (newData.email === "") {
      setError("Enter last name for the contact.");
      return;
    }

    if (!jobsList.length) {
      setError("Select at least one job to assign.");
      return;
    }

    try {
      setIsLoading(true);
      const dataSave = {
        ...newData,
        type: type,
        files: logoFile ? [...logoFile] : null,
        job_ids: jobsList.map((job) => job.id),
        notification_survey: newData.notification_survey ? 1 : 0,
      };
      await saveCustomerContact(job.customer_id, dataSave);
      dispatchContext({
        type: "SET_REFRESH_CONTACTS",
        refreshContactsOptions: true,
      });
      setMessageSuccess("Customer contact has been created.");
      setOpenSuccess(true);
      setIsLoading(false);
    } catch (e) {
      logException(e, "Cannot create contact");
    }
  };

  const handleChangeJob = (event, value) => {
    setError("");
    if (value) {
      jobsList.push(value);
      setJobsList([...jobsList]);
    }
  };

  const handleJobDelete = (id) => {
    const updateJobsList = jobsList.filter((job) => job.id !== id);
    setJobsList(updateJobsList);
    if (!updateJobsList.length) {
      setJobsData([]);
    }
  };

  const handleChangeLogo = async (event) => {
    console.log("Hello");
    if (event.target.files[0]) {
      setLogo(URL.createObjectURL(event.target.files[0]));
      setLogoFile(event.target.files);
    }
  };

  const handleTypeChange = (type) => {
    setError("");
    setType(type);
    const data = {
      ...initNewData,
    };
    setNewData(data);
    setLogo(null);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    handleClose();
  };

  const handleClose = () => {
    dispatchContext({
      type: "SET_OPEN_CREATE_CONTACT",
      openCreateContact: false,
    });
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const handleChangeNotificationSurvey = (e) => {
    const data = {
      ...newData,
      notification_survey: e.target.checked,
    };
    setNewData(data);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openCreateContact}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {isLoadingData ? (
          <Box className={classes.loadingContainer}>
            <LoadingIndicator />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogCloseIcon handleClose={handleClose} />
              <DialogTitle
                title={`${mode?.toLowerCase()} contact`}
                icon={<ContactIcon className={classes.iconDialog} />}
              />
              <Box className={classes.formWrapperHeader}>
                <Box className={classes.formContainerHeader}>
                  <ButtonGroup
                    variant="outlined"
                    color="default"
                    aria-label="primary button group"
                    className={classes.groupOption}
                    fullWidth={true}
                  >
                    <Button
                      key="type-1"
                      className={type === 1 ? classes.groupOptionActive : null}
                      onClick={() => handleTypeChange(1)}
                    >
                      Active User
                    </Button>
                    <Button
                      key="type-2"
                      className={type === 2 ? classes.groupOptionActive : null}
                      onClick={() => handleTypeChange(2)}
                    >
                      General
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              <StyledTabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                {TabOptions.filter((item) => item.onTabing).map((option) => {
                  return (
                    <StyledTab
                      disableRipple={true}
                      label={
                        <Box className={classes.tabItem}>{option.text}</Box>
                      }
                      value={option.id}
                      key={option.id}
                    />
                  );
                })}
              </StyledTabs>

              {type === 1 && tab === 1 ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.formContainerLogo}>
                      <Box className={classes.containerLogo}>
                        <input
                          type="file"
                          accept="image/*"
                          className={classes.inputImage}
                          id="icon-button-file"
                          onChange={handleChangeLogo}
                        />
                        <label htmlFor="icon-button-file">
                          <img
                            src={logo ?? eosLogo}
                            alt="logoImage"
                            className={classes.logoImage}
                          />

                          <Avatar className={classes.logoImageEdit}>
                            <EditIcon className={classes.logoIconEdit} />
                          </Avatar>
                        </label>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box className={classes.formContainerContent}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="first_name"
                            label="First Name"
                            value={newData.first_name}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="last_name"
                            label="Last Name"
                            value={newData.last_name}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="email"
                            label="Email"
                            value={newData.email}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="phone"
                            label="Phone"
                            value={newData.phone ?? ""}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                      </Grid>

                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="title"
                        label="Title"
                        value={newData.title}
                        handleBlur={handleCreateBlur}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="about"
                        label="About"
                        value={newData.about}
                        handleBlur={handleCreateBlur}
                        multiline={true}
                        rows={4}
                      />

                      <Box className={classes.containerJobInput}>
                        <FormSelectJobsAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={jobsData ?? []}
                          name="job_number"
                          label="Jobs"
                          multiple={false}
                          handleChange={handleChangeJob}
                        />
                      </Box>

                      <Box className={classes.containerSelectedElements}>
                        {jobsList.map((job) => (
                          <Chip
                            key={job.id}
                            size="small"
                            label={`${job.job_number} - ${job.job_description}`}
                            onDelete={() => handleJobDelete(job.id)}
                            className={classes.chipJob}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {type === 2 && tab === 1 ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.formContainerLogo}>
                      <Box className={classes.containerLogo}>
                        <input
                          type="file"
                          accept="image/*"
                          className={classes.inputImage}
                          id="icon-button-file"
                          onChange={handleChangeLogo}
                        />
                        <label htmlFor="icon-button-file">
                          <img
                            src={logo ?? eosLogo}
                            alt="logoImage"
                            className={classes.logoImage}
                          />

                          <Avatar className={classes.logoImageEdit}>
                            <EditIcon className={classes.logoIconEdit} />
                          </Avatar>
                        </label>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box className={classes.formContainerContent}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="first_name"
                            label="First Name"
                            value={newData.first_name}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="last_name"
                            label="Last Name"
                            value={newData.last_name}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="email"
                            label="Email"
                            value={newData.email}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="phone"
                            label="Phone"
                            value={newData.phone}
                            handleBlur={handleCreateBlur}
                          />
                        </Grid>
                      </Grid>

                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="title"
                        label="Title"
                        value={newData.title}
                        handleBlur={handleCreateBlur}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="about"
                        label="About"
                        value={newData.about}
                        handleBlur={handleCreateBlur}
                        multiline={true}
                        rows={4}
                      />

                      <Box className={classes.containerJobInput}>
                        <FormSelectJobsAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={jobsData ?? []}
                          name="job_number"
                          label="Jobs"
                          multiple={false}
                          handleChange={handleChangeJob}
                        />
                      </Box>

                      <Box className={classes.containerSelectedElements}>
                        {jobsList.map((job) => (
                          <Chip
                            key={job.id}
                            size="small"
                            label={`${job.job_number} - ${job.job_description}`}
                            onDelete={() => handleJobDelete(job.id)}
                            className={classes.chipJob}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {tab === 2 ? (
                <Box className={classes.containerNotification}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newData.notification_survey}
                        onChange={(e) => handleChangeNotificationSurvey(e)}
                        className={classes.checkboxNotification}
                      />
                    }
                    label={"Surveys"}
                  />
                </Box>
              ) : null}
            </DialogContent>
            {Boolean(error) ? (
              <Box className={classes.containerError}>{error}</Box>
            ) : null}

            <DialogActions className={classes.actionsDialogWrapper}>
              <ButtonBase
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </ButtonBase>

              <ButtonBase
                variant="contained"
                color="secondary"
                onClick={handleCreate}
                disabled={isLoading}
              >
                Create
              </ButtonBase>
            </DialogActions>
          </>
        )}
      </Dialog>

      <MessageDialog
        open={openSuccess}
        title={"Success!"}
        message={messageSuccess}
        handleClose={handleCloseSuccess}
      />
    </>
  );
};

export default ContactsManage;
