import React from "react";
import * as worknovaService from "services/worknovaService";
import { merge } from "lodash";
import { worknovaDetailTabOptions } from "../constants";

const defaultState = {
  worknovaDetailListLoading: true,
  worknovaDetailSelected: null,
  worknovaDetailList: [],
  worknovaDetailCount: [],
  worknovaDetailDetails: [],
  worknovaDetail: [],
  editMode: false,
  permissions: [],
};

const WorknovaDetailStateContext = React.createContext();
const WorknovaDetailDispatchContext = React.createContext();

function worknovaDetailReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        worknovaDetailListLoading: action.worknovaDetailListLoading,
      };
    case "SET_WorknovaDetail_LIST":
      return {
        ...state,
        worknovaDetailList: action.worknovaDetailList,
      };
    case "SET_WorknovaDetail_COUNT":
      return {
        ...state,
        worknovaDetailCount: action.worknovaDetailCount,
      };
    case "SET_WorknovaDetail_SELECTED":
      return {
        ...state,
        worknovaDetailSelected: action.worknovaDetailSelected,
      };
    case "SET_WorknovaDetail_DETAILS":
      return {
        ...state,
        worknovaDetailDetails: action.worknovaDetailDetails,
      };
    case "SET_Worknova_DETAILS":
      return {
        ...state,
        worknovaDetail: action.worknovaDetail,
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WorknovaDetailProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(
    worknovaDetailReducer,
    mergedState
  );
  return (
    <WorknovaDetailStateContext.Provider value={state}>
      <WorknovaDetailDispatchContext.Provider value={dispatch}>
        {children}
      </WorknovaDetailDispatchContext.Provider>
    </WorknovaDetailStateContext.Provider>
  );
}

function useWorknovaDetailState() {
  const context = React.useContext(WorknovaDetailStateContext);
  if (context === undefined) {
    throw new Error(
      "useWorknovaDetailState must be used within a WorknovaDetailProvider"
    );
  }
  return context;
}

function useWorknovaDetailDispatch() {
  const context = React.useContext(WorknovaDetailDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWorknovaDetailDispatch must be used within a WorknovaDetailProvider"
    );
  }
  return context;
}

async function getWorknovaDetailList(worknova, dispatch, filterData) {
  try {
    const worknovaDetailListResult = await worknovaService.getWorknovaDetailList(
      worknova,
      filterData
    );
    //const resultAll = await quoteService.getQuotesTab("all");
    const worknovaDetailList = worknovaDetailListResult.data.data.worknovaItems;
    const worknovaDetailCount =
      worknovaDetailListResult.data.data.filter_counts;
    dispatch({
      type: "SET_WorknovaDetail_LIST",
      worknovaDetailList: worknovaDetailList,
    });
    dispatch({
      type: "SET_WorknovaDetail_COUNT",
      worknovaDetailCount: worknovaDetailCount,
    });
    dispatch({
      type: "SET_LOADING",
      worknovaDetailListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorknovaDetailListDataSet(
  worknova,
  tab,
  worknovaDetailList,
  dispatch,
  offset,
  filterData
) {
  try {
    const findElement = worknovaDetailTabOptions.find(
      (item) => item.id === tab
    );
    const result = await worknovaService.getWorknovaDetailTabOffset(
      worknova,
      findElement.tab,
      offset,
      filterData
    );
    worknovaDetailList[tab] = worknovaDetailList[tab].concat(
      result.data.data.worknovaItems
    );
    dispatch({
      type: "SET_WorknovaDetail_LIST",
      worknovaDetailList: worknovaDetailList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchWorknovaDetail(worknova, search, filterData) {
  try {
    const worknovaDetailListResult = await worknovaService.getWorknovaDetailSearch(
      worknova,
      search,
      filterData
    );
    return worknovaDetailListResult.data.data.search;
  } catch (error) {
    console.log(error);
  }
}

export {
  WorknovaDetailProvider,
  useWorknovaDetailState,
  useWorknovaDetailDispatch,
  getWorknovaDetailList,
  getWorknovaDetailListDataSet,
  searchWorknovaDetail,
};
