import React from "react";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "./styles";

const FieldName = (props) => {
  const classes = useStyles();
  const { field } = props;

  if (!field) {
    return null;
  }

  return (
    <div className={classes.wrapperName}>
      <Avatar className={classes.avatar}>
        {field.first_name[0]}
        {field.last_name[0]}
      </Avatar>{" "}
      {field.first_name} {field.last_name}
    </div>
  );
};

export default FieldName;
