import React from "react";
import * as classNames from "classnames";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import WorkticketRatingComment from "./workticketRatingComment";
import { ratingWTOptions, ratingWTLabelDisplay } from "constants.js";

import useStyles from "./styles";

const WorkticketRatingDetail = (props) => {
  const classes = useStyles();
  const {
    ratedScore,
    fileUrl,
    firstName,
    lastName,
    ratedAt,
    ratedComment,
    manageEdit,
    canEdit,
  } = props;

  return (
    <Box className={classes.containerRatingBox}>
      <Typography
        variant="h4"
        className={classes.titleSectionBody}
        gutterBottom
      >
        Rating
      </Typography>
      <Box className={classes.containerRatingDetail}>
        {canEdit ? (
          <EditIcon className={classes.ratingEditIcon} onClick={manageEdit} />
        ) : null}
        <Box className={classes.ratingDetail}>
          <Box className={classes.ratingBox}>
            <Typography
              className={classNames(classes.ratingFont, {
                [classes.ratingFont1]: ratedScore === 1,
                [classes.ratingFont2]: ratedScore === 2,
                [classes.ratingFont3]: ratedScore === 3,
                [classes.ratingFont4]: ratedScore === 4,
                [classes.ratingFont5]: ratedScore === 5,
              })}
            >
              {ratedScore}
            </Typography>
          </Box>
          <Typography className={classes.ratingScore}>
            {ratedScore}/{ratingWTOptions.length}
          </Typography>
          <Typography className={classes.ratingScoreName}>
            {ratedScore > 3
              ? ratingWTLabelDisplay.right
              : ratedScore > 2
              ? ratingWTLabelDisplay.center
              : ratingWTLabelDisplay.left}
          </Typography>
        </Box>
        <Box className={classes.ratingCommentContainer}>
          <Box className={classes.ratingHeaderDetailContainer}>
            <Box className={classes.wrapperNameUser}>
              {fileUrl ? (
                <Avatar src={fileUrl} className={classes.avatar} />
              ) : (
                <Avatar className={classes.avatar}>
                  {firstName[0]?.charAt(0)}
                  {lastName[0]?.charAt(0)}
                </Avatar>
              )}
              <Typography
                className={classes.nameRatingContainer}
              >{`${firstName} ${lastName}`}</Typography>
            </Box>
            <Typography className={classes.ratingDetailDate}>
              {moment(ratedAt).format("MM/DD/YYYY")}
            </Typography>
          </Box>
          <WorkticketRatingComment comment={ratedComment ?? "No comments"} />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkticketRatingDetail;
