import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import moment from "moment";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDate from "components/ui/FormContent/formInputDate";
import { Typography } from "@material-ui/core";
import StepNav from "./stepNav";
import RequestActionNav from "./requestActionNav";
import Joi from "joi-browser";

import { useStateValue } from "components/util/state";
import { errorList } from "components/util/error";

import useStyles from "./styles";

const schema = Joi.object({
  summary: Joi.string().required().label("Summary"),
  description: Joi.string().allow("").optional().label("Description"),
  due_date: Joi.alternatives(Joi.string(), Joi.object())
    .optional()
    .label("Requested by"),
  due_notes: Joi.string().allow("").optional().label("Description"),
});

const initialFormState = {
  summary: "",
  description: "",
  due_date: moment().add(30, "days").format("YYYY-MM-DD HH:mm:ss"),
  due_notes: "",
};

const RequestStep2 = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialFormState);
  const [stepComplete, setStepComplete] = useState(false);
  const [error, setError] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [{ step, list, requests }, dispatch] = useStateValue();

  useEffect(() => {
    if (requests) {
      setFormState(initialFormState);
      setError([]);
      setHasError(false);
    }
  }, [requests]);

  useEffect(() => {
    const errorResult = errorList(schema, formState);
    if (hasError) {
      if (errorResult.length > 0) {
        setError(errorResult);
      } else {
        setError([]);
      }
    }
    if (errorResult.length === 0) {
      setStepComplete(true);
    }
  }, [formState, hasError]);

  const handleOnClick = () => {
    const errorResult = errorList(schema, formState);
    if (errorResult.length > 0) {
      setError(errorResult);
      setHasError(true);
      return false;
    }
    dispatch({
      type: "UPDATE_DATA",
      updateData: formState,
      newStep: 3,
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 1,
    });
  };

  const handleBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  const validateOnType = (event) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  if (step !== 2) return null;
  return (
    <>
      <div className={classes.headerContainer}>
        <StepNav step={step} list={list} />
        <Typography
          variant="h3"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Request Details
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Tell us more about the request.
        </Typography>
      </div>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.stepContainer}>
          <Box className={classes.formContainer}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="summary"
              label="Summary"
              value={formState.summary}
              validateOnType={validateOnType}
              error={error}
            />
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="description"
              label="Description"
              multiline={true}
              rows={3}
              value={formState.description}
              validateOnType={validateOnType}
              error={error}
            />
            <FormInputDate
              gridSizes={[{ size: "md", val: 12 }]}
              name="due_date"
              label="Requested By"
              value={formState.due_date}
              handleBlur={handleBlur}
              error={error}
            />
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="due_notes"
              label="Notes"
              multiline={true}
              rows={3}
              value={formState.due_notes}
              validateOnType={validateOnType}
              error={error}
            />
          </Box>
          <RequestActionNav
            handleOnClickBack={handleOnClickBack}
            handleOnClick={handleOnClick}
            stepComplete={stepComplete}
          />
        </div>
      </Container>
    </>
  );
};

export default RequestStep2;
