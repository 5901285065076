import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { useIndividualView } from "contexts/individualViewContext";
import EvalutionsList from "./evaluationsTab/evaluationsList";
import NoticesList from "./noticesTab/noticesList";
import RecognitionList from "./recognitionTab/recognitionList";
import UserLocationsList from "./userLocationsTab/userLocationsList";
import useStyles from "./styles";

const TabOptions = [
  { id: 1, text: "Evaluations", tab: "evalutions", onTabing: true },
  { id: 2, text: "Notices", tab: "notices", onTabing: true },
  { id: 3, text: "Recognition", tab: "recognition", onTabing: true },
  { id: 4, text: "Locations", tab: "locations", onTabing: true },
];

const IndividualViewTabs = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [stateContext] = useIndividualView();
  const { isLoading } = stateContext ?? null;

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <Box className={classes.containerTabs}>
      <StyledTabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        className={classes.rootTabs}
      >
        {TabOptions.filter((item) => item.onTabing).map((option) => {
          return (
            <StyledTab
              disableRipple={true}
              label={<Box className={classes.tabItem}>{option.text}</Box>}
              value={option.id}
              key={option.id}
            />
          );
        })}
      </StyledTabs>
      <Box className={classes.containerTabContent}>
        {tab === 1 ? <EvalutionsList /> : ""}
        {tab === 2 ? <NoticesList /> : ""}
        {tab === 3 ? <RecognitionList /> : ""}
        {tab === 4 ? <UserLocationsList /> : ""}
      </Box>
    </Box>
  );
};

export default IndividualViewTabs;
