import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import LockIcon from "@material-ui/icons/Lock";
import SendIcon from "@material-ui/icons/Send";
import DoneIcon from "@material-ui/icons/ThumbUp";
import CancelIcon from "@material-ui/icons/ThumbDown";
import UnlockIcon from "@material-ui/icons/LockOpen";
import ArchivedIcon from "@material-ui/icons/Archive";
import CheckIcon from "@material-ui/icons/CheckCircle";

import { quotesSurfaceStatusOptions } from "../../../../constants";
import useStyles from "./styles";

const WorkticketStatusChip = (props) => {
  const classes = useStyles();

  const { status, archived, pendingPlanning, unlocked } = props;

  const statusSelected = quotesSurfaceStatusOptions.find(
    (statusItem) => statusItem.id === status
  );

  if (!statusSelected) {
    return "Error status";
  }

  let classChip;
  let iconChip;
  if (archived) {
    classChip = classes.chipArchived;
    iconChip = <ArchivedIcon className={classes.icon} />;
  } else if (pendingPlanning && status === 5) {
    classChip = classes.chipPlanning;
    iconChip = <DoneIcon className={classes.icon} />;
  } else if (status === 7) {
    classChip = classes.chipDone;
    iconChip = <DoneIcon className={classes.icon} />;
  } else if (status === 30 && unlocked) {
    classChip = classes.chipDone;
    iconChip = <UnlockIcon className={classes.icon} />;
  } else {
    switch (statusSelected.text) {
      case "Draft":
        classChip = classes.chipDraft;
        iconChip = <PauseIcon className={classes.icon} />;
        break;
      case "Needs Action":
        classChip = classes.chipNotStarted;
        iconChip = <SendIcon className={classes.icon} />;
        break;
      case "Active":
        classChip = classes.chipNotStarted;
        iconChip = <CheckIcon className={classes.icon} />;
        break;
      case "Approved":
        classChip = classes.chipDone;
        iconChip = <DoneIcon className={classes.icon} />;
        break;
      case "Not Approved":
        classChip = classes.chipCancelled;
        iconChip = <CancelIcon className={classes.icon} />;
        break;
      case "Snoozed":
        classChip = classes.chipSnoozed;
        iconChip = <LockIcon className={classes.icon} />;
        break;
      case "Snoozed - UnLocked":
        classChip = classes.chipNotStarted;
        iconChip = <UnlockIcon className={classes.icon} />;
        break;
      case "Archived":
        classChip = classes.chipArchived;
        iconChip = <ArchivedIcon className={classes.icon} />;
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Chip
        icon={iconChip}
        label={
          archived
            ? "Archived"
            : pendingPlanning && status === 5
            ? `WT Planning`
            : status === 7
            ? `Approved`
            : status === 30 && unlocked
            ? `UnLocked`
            : `${statusSelected.text}`
        }
        className={classNames(classes.chip, classChip)}
      />
    </>
  );
};

export default WorkticketStatusChip;
