import React, { useContext, useEffect } from "react";
import {
  hasPermission,
  permissionDashboard,
  permissionFinancial,
  permissionProject,
  permissionQuote,
  permissionSurface,
  permissionWorkticket,
} from "lib/permissions";

import Dashboard from "pages/dashboard/dashboard";
import GlobalUiContext from "contexts/globalUiContext";
import { withRouter } from "react-router-dom";

const MainPage = (props) => {
  const { history } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  useEffect(() => {
    if (Boolean(permissions.length)) {
      if (
        hasPermission(permissionSurface.MAP, permissions) ||
        hasPermission(permissionDashboard.MAP, permissions)
      ) {
        if (!window.location.href.includes("?")) {
          history.push(`/dashboard`);
        }
      } else if (
        hasPermission(permissionSurface.KPI, permissions) ||
        hasPermission(permissionDashboard.KPI, permissions) ||
        hasPermission(permissionDashboard.KPI_PARTNER, permissions)
      ) {
        history.push(`/dashboard-kpi`);
      } else if (hasPermission(permissionProject.MODULE, permissions)) {
        history.push(`/projects`);
      } else if (hasPermission(permissionWorkticket.MODULE, permissions)) {
        history.push(`/worktickets`);
      } else if (hasPermission(permissionSurface.WORKTICKET, permissions)) {
        history.push(`/worktickets`);
      } else if (hasPermission(permissionQuote.MODULE, permissions)) {
        history.push(`/quotes`);
      } else if (hasPermission(permissionFinancial.MODULE, permissions)) {
        history.push(`/financial/report/account-payable`);
      }
    }
  }, [permissions, history]);
  return <Dashboard history={history} />;
};

export default withRouter(MainPage);
