import React from "react";
import eosLogo from "../../../assets/logo-eos.svg";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "../container";
import useStyles from "./styles";
import { useStateValue } from "components/util/state";

import theme from "theme";

const MainLayoutGuest = (props) => {
  const classes = useStyles();
  const [{ company }] = useStateValue();
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <header className={classes.headerWrapper}>
          <div className={classes.logoWrapper}>
            {company ? (
              <img
                src={company?.customer.logo?.file_url ?? eosLogo}
                alt="Encompass Onsite"
                className={classes.logo}
              />
            ) : null}
          </div>
        </header>
        <main className={classes.content}>{children}</main>
      </Container>
    </ThemeProvider>
  );
};

export default MainLayoutGuest;
