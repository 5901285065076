import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import * as classNames from "classnames";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import moment from "moment";
import useStyles from "./styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const isnum = (val) => /^\d+$/.test(val);
const WeeklyScheduleModal = ({
  setOpenWeeklySchedule,
  setWeeklyScheduleData,
  weeklyScheduleData,
  openWeeklySchedule,
  fullScreen,
}) => {
  const classes = useStyles();
  const [timeIn, setTimeIn] = useState(moment("09:00", "HH:mm"));
  const [overallTime, setOverAllTime] = useState(
    weeklyScheduleData
      ? weeklyScheduleData
      : {
          mon: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          tue: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          wed: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          thr: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          fri: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          sat: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
          sun: {
            startTime: null,
            endTime: null,
            lunchTime: "",
            lunchTimeHrs: "",
          },
        }
  );

  const calculateTotalLunchHours = () => {
    return (
      Number(overallTime?.mon?.lunchTime / 60) +
      Number(overallTime?.tue?.lunchTime / 60) +
      Number(overallTime?.wed?.lunchTime / 60) +
      Number(overallTime?.thr?.lunchTime / 60) +
      Number(overallTime?.fri?.lunchTime / 60) +
      Number(overallTime?.sat?.lunchTime / 60) +
      Number(overallTime?.sun?.lunchTime / 60) +
      Number(overallTime?.mon?.lunchTimeHrs) +
      Number(overallTime?.tue?.lunchTimeHrs) +
      Number(overallTime?.wed?.lunchTimeHrs) +
      Number(overallTime?.thr?.lunchTimeHrs) +
      Number(overallTime?.fri?.lunchTimeHrs) +
      Number(overallTime?.sat?.lunchTimeHrs) +
      Number(overallTime?.sun?.lunchTimeHrs)
    );
  };
  const calculateTotalShiftHours = () => {
    return (
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.mon?.endTime
            ? moment(overallTime?.mon.endTime, "HH:mm")?.diff(
                moment(overallTime?.mon?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.mon.endTime, "HH:mm")?.diff(
                  moment(overallTime?.mon?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.mon?.lunchTime +
                  overallTime?.mon?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.tue?.endTime
            ? moment(overallTime?.tue.endTime, "HH:mm")?.diff(
                moment(overallTime?.tue?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.tue.endTime, "HH:mm")?.diff(
                  moment(overallTime?.tue?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.tue?.lunchTime +
                  overallTime?.tue?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.wed?.endTime
            ? moment(overallTime?.wed.endTime, "HH:mm")?.diff(
                moment(overallTime?.wed?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.wed.endTime, "HH:mm")?.diff(
                  moment(overallTime?.wed?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.wed?.lunchTime +
                  overallTime?.wed?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.thr?.endTime
            ? moment(overallTime?.thr.endTime, "HH:mm")?.diff(
                moment(overallTime?.thr?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.thr.endTime, "HH:mm")?.diff(
                  moment(overallTime?.thr?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.thr?.lunchTime +
                  overallTime?.thr?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.fri?.endTime
            ? moment(overallTime?.fri.endTime, "HH:mm")?.diff(
                moment(overallTime?.fri?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.fri.endTime, "HH:mm")?.diff(
                  moment(overallTime?.fri?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.fri?.lunchTime +
                  overallTime?.fri?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.sat?.endTime
            ? moment(overallTime?.sat.endTime, "HH:mm")?.diff(
                moment(overallTime?.sat?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.sat.endTime, "HH:mm")?.diff(
                  moment(overallTime?.sat?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.sat?.lunchTime +
                  overallTime?.sat?.lunchTimeHrs * 60)
              : ""
            : null
        )
      ) +
      Number(
        nightShiftIfAnyCalculate(
          overallTime?.sun?.endTime
            ? moment(overallTime?.sun.endTime, "HH:mm")?.diff(
                moment(overallTime?.sun?.startTime, "HH:mm"),
                "minutes"
              )
              ? moment(overallTime?.sun.endTime, "HH:mm")?.diff(
                  moment(overallTime?.sun?.startTime, "HH:mm"),
                  "minutes"
                ) -
                (overallTime?.sun?.lunchTime +
                  overallTime?.sun?.lunchTimeHrs * 60)
              : ""
            : null
        )
      )
    );
  };

  const handleCopyToAll = () => {
    setOverAllTime({
      ...overallTime,
      tue: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
      wed: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
      thr: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
      fri: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
      sat: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
      sun: {
        startTime: overallTime?.mon?.startTime,
        endTime: overallTime?.mon?.endTime,
        lunchTime: overallTime?.mon?.lunchTime,
        lunchTimeHrs: overallTime?.mon?.lunchTimeHrs,
      },
    });
    calculateTotalShiftHours();
  };

  const nightShiftIfAnyCalculate = (val) => {
    if (val && Number(val) < 0) {
      return ((1440 + Number(val)) / 60).toFixed(2);
    }
    return val / 60 ? (val / 60).toFixed(2) : "";
  };

  return (
    <Dialog
      open={openWeeklySchedule}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={fullScreen}
      classes={{ paperFullWidth: classes.maxWidthDialog }}
    >
      <DialogContent>
        {" "}
        <Box className={classes.schedulerContainer}>
          <Box className={classes.descIcon}>
            <DescriptionIcon />
          </Box>
          <Typography className={classes.workSchedule}>
            Work Schedule
          </Typography>
          <Typography className={classes.enterExpected}>
            Enter Expected Weekly Work Schedule
          </Typography>
          <Box className={classes.marginTop10}></Box>
          <Grid item xs>
            <Grid container className={classes.startRow}>
              <Grid item xs={3}>
                <Typography className={classes.schedulterText}>
                  Start:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.schedulterText}>End:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.schedulterText}>
                  Hours:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.schedulterText}>
                  Lunch:
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.marginTop10}></Box>
            <Typography className={classes.label}>Mon</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.mon.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      mon: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.mon.endTime,
                        lunchTime: overallTime.mon.lunchTime,
                        lunchTimeHrs: overallTime.mon.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.mon.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      mon: {
                        startTime: overallTime.mon.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.mon.lunchTime,
                        lunchTimeHrs: overallTime.mon.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    moment(overallTime?.mon?.endTime, "HH:mm")?.diff(
                      moment(overallTime.mon.startTime, "HH:mm"),
                      "minutes"
                    )
                      ? moment(overallTime?.mon?.endTime, "HH:mm")?.diff(
                          moment(overallTime.mon.startTime, "HH:mm"),
                          "minutes"
                        ) -
                          (overallTime.mon.lunchTime +
                            overallTime.mon.lunchTimeHrs * 60)
                      : ""
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.mon?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        mon: {
                          ...overallTime.mon,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        mon: {
                          ...overallTime.mon,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.mon?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        mon: {
                          ...overallTime.mon,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        mon: {
                          ...overallTime.mon,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.copyToAllViewRowAdj} />
              <Grid item xs={3} className={classes.copyToAllViewRowAdj} />
              <Grid item xs={3} className={classes.copyToAllViewRowAdj} />
              <Grid item xs={3} className={classes.copyToAllViewRowAdj}>
                <Typography
                  className={classes.copyToAll}
                  onClick={handleCopyToAll}
                >
                  Copy to All
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.label}>Tue</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.tue.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      tue: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.tue.endTime,
                        lunchTime: overallTime.tue.lunchTime,
                        lunchTimeHrs: overallTime.tue.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.tue.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      tue: {
                        startTime: overallTime.tue.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.tue.lunchTime,
                        lunchTimeHrs: overallTime.tue.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    moment(overallTime?.tue?.endTime, "HH:mm")?.diff(
                      moment(overallTime.tue.startTime, "HH:mm"),
                      "minutes"
                    )
                      ? moment(overallTime?.tue?.endTime, "HH:mm")?.diff(
                          moment(overallTime.tue.startTime, "HH:mm"),
                          "minutes"
                        ) -
                          (overallTime.tue.lunchTime +
                            overallTime.tue.lunchTimeHrs * 60)
                      : ""
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.tue?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        tue: {
                          ...overallTime.tue,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        tue: {
                          ...overallTime.tue,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.tue?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        tue: {
                          ...overallTime.tue,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        tue: {
                          ...overallTime.tue,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>Wed</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.wed.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      wed: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.wed.endTime,
                        lunchTime: overallTime.wed.lunchTime,
                        lunchTimeHrs: overallTime.wed.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.wed.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      wed: {
                        startTime: overallTime.wed.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.wed.lunchTime,
                        lunchTimeHrs: overallTime.wed.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    moment(overallTime?.wed?.endTime, "HH:mm")?.diff(
                      moment(overallTime.wed.startTime, "HH:mm"),
                      "minutes"
                    )
                      ? moment(overallTime?.wed?.endTime, "HH:mm")?.diff(
                          moment(overallTime.wed.startTime, "HH:mm"),
                          "minutes"
                        ) -
                          (overallTime.wed.lunchTime +
                            overallTime.wed.lunchTimeHrs * 60)
                      : ""
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.wed?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        wed: {
                          ...overallTime.wed,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        wed: {
                          ...overallTime.wed,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.wed?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        wed: {
                          ...overallTime.wed,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        wed: {
                          ...overallTime.wed,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>Thr</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.thr.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      thr: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.thr.endTime,
                        lunchTime: overallTime.thr.lunchTime,
                        lunchTimeHrs: overallTime.thr.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.thr.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      thr: {
                        startTime: overallTime.thr.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.thr.lunchTime,
                        lunchTimeHrs: overallTime.thr.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    moment(overallTime?.thr?.endTime, "HH:mm")?.diff(
                      moment(overallTime.thr.startTime, "HH:mm"),
                      "minutes"
                    )
                      ? moment(overallTime?.thr?.endTime, "HH:mm")?.diff(
                          moment(overallTime.thr.startTime, "HH:mm"),
                          "minutes"
                        ) -
                          (overallTime.thr.lunchTime +
                            overallTime.thr.lunchTimeHrs * 60)
                      : ""
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.thr?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        thr: {
                          ...overallTime.thr,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        thr: {
                          ...overallTime.thr,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.thr?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        thr: {
                          ...overallTime.thr,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        thr: {
                          ...overallTime.thr,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>Fri</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.fri.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      fri: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.fri.endTime,
                        lunchTime: overallTime.fri.lunchTime,
                        lunchTimeHrs: overallTime.fri.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.fri.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      fri: {
                        startTime: overallTime.fri.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.fri.lunchTime,
                        lunchTimeHrs: overallTime.fri.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    moment(overallTime?.fri?.endTime, "HH:mm")?.diff(
                      moment(overallTime.fri.startTime, "HH:mm"),
                      "minutes"
                    )
                      ? moment(overallTime?.fri?.endTime, "HH:mm")?.diff(
                          moment(overallTime.fri.startTime, "HH:mm"),
                          "minutes"
                        ) -
                          (overallTime.fri.lunchTime +
                            overallTime.fri.lunchTimeHrs * 60)
                      : ""
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.fri?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        fri: {
                          ...overallTime.fri,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        fri: {
                          ...overallTime.fri,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.fri?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        fri: {
                          ...overallTime.fri,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        fri: {
                          ...overallTime.fri,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>Sat</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.sat.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      sat: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.sat.endTime,
                        lunchTime: overallTime.sat.lunchTime,
                        lunchTimeHrs: overallTime.sat.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.sat.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      sat: {
                        startTime: overallTime.sat.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.sat.lunchTime,
                        lunchTimeHrs: overallTime.sat.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    overallTime.sat.endTime
                      ? moment(overallTime?.sat?.endTime, "HH:mm")?.diff(
                          moment(overallTime.sat.startTime, "HH:mm"),
                          "minutes"
                        )
                        ? moment(overallTime?.sat?.endTime, "HH:mm")?.diff(
                            moment(overallTime.sat.startTime, "HH:mm"),
                            "minutes"
                          ) -
                          (overallTime.sat.lunchTime +
                            overallTime.sat.lunchTimeHrs * 60)
                        : ""
                      : null
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.sat?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sat: {
                          ...overallTime.sat,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sat: {
                          ...overallTime.sat,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.sat?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sat: {
                          ...overallTime.sat,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sat: {
                          ...overallTime.sat,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>Sun</Typography>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.sun.startTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      sun: {
                        startTime: e?.format("HH:mm"),
                        endTime: overallTime.sun.endTime,
                        lunchTime: overallTime.sun.lunchTime,
                        lunchTimeHrs: overallTime.sun.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <FormInputTimeAdo
                  name="date"
                  disableHelperText={true}
                  value={moment(overallTime.sun.endTime, "HH:mm")}
                  handleChange={(e, v) =>
                    setOverAllTime({
                      ...overallTime,
                      sun: {
                        startTime: overallTime.sun.startTime,
                        endTime: e?.format("HH:mm"),
                        lunchTime: overallTime.sun.lunchTime,
                        lunchTimeHrs: overallTime.sun.lunchTimeHrs,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={nightShiftIfAnyCalculate(
                    overallTime.sun.endTime
                      ? moment(overallTime?.sun?.endTime, "HH:mm")?.diff(
                          moment(overallTime.sun.startTime, "HH:mm"),
                          "minutes"
                        )
                        ? moment(overallTime?.sun?.endTime, "HH:mm")?.diff(
                            moment(overallTime.sun.startTime, "HH:mm"),
                            "minutes"
                          ) -
                          (overallTime.sun.lunchTime +
                            overallTime.sun.lunchTimeHrs * 60)
                        : ""
                      : null
                  )}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.sun?.lunchTimeHrs}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sun: {
                          ...overallTime.sun,
                          lunchTimeHrs: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 3
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sun: {
                          ...overallTime.sun,
                          lunchTimeHrs: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
                <span className={classes.seaparatorColon}>:</span>

                <TextField
                  name="email"
                  className={classes.timeSplit}
                  //   disabled={isWorking}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  value={overallTime?.sun?.lunchTime}
                  onChange={(e) => {
                    if (
                      e.target.value.trim() === "" ||
                      !isnum(e.target.value)
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sun: {
                          ...overallTime.sun,
                          lunchTime: "",
                        },
                      });
                      return;
                    }
                    if (
                      isnum(e.target.value) &&
                      Number(e.target.value) >= 0 &&
                      Number(e.target.value) <= 59
                    ) {
                      setOverAllTime({
                        ...overallTime,
                        sun: {
                          ...overallTime.sun,
                          lunchTime: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} className={classes.paddingRight5}></Grid>
              <Grid item xs={3} className={classes.paddingLeft5}>
                <Typography className={classes.schTotal}>Totals</Typography>
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  value={calculateTotalShiftHours()?.toFixed(2)}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                  fullWidth
                  onKeyPress={() => {}}
                />
              </Grid>
              <Grid item xs={3} className={classes.hoursLunch}>
                <TextField
                  name="email"
                  className={classes.field}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                    startAdornment: "   ",
                  }}
                  fullWidth
                  value={calculateTotalLunchHours()?.toFixed(2)}
                  onChange={(e) => {}}
                  onKeyPress={() => {}}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Box> */}
        <Grid container className={classes.modalPad}>
          <Grid item md={3}></Grid>
          <Grid item md={3} xs={12} className={classes.wrapperDialog}>
            <Button
              className={classes.cancelButton}
              onClick={() => setOpenWeeklySchedule(false)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item md={3} xs={12} className={classes.wrapperDialog}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => {
                setWeeklyScheduleData(overallTime);
                setOpenWeeklySchedule(false);
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
        {/* </Box> */}
      </DialogActions>
    </Dialog>
  );
};
export default WeeklyScheduleModal;
