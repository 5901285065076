import React, { Fragment, useEffect, useReducer, useState } from "react";
import Box from "@material-ui/core/Box";
import FormInput from "../../../components/ui/FormContent/formInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "../styles";
import FormSelect from "../../../components/ui/FormContent/formSelect";
import { useFilterDialog } from "../context/FilterDialogContext";
import ThreeDots from "../icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CityStateFilterPage } from "./CityStateFilterPage";

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "TOUCH_FIELD":
      return { ...state, [action.field]: true };
    default:
      return state;
  }
};

// Initial state for touched fields
const initialState = {
  variant_name: false,
  hourly_rate: false,
  fixed_rate: false,
  sqf_rate: false,
  states: false,
  cities: false,
  zip_codes: false,
};

export function TradeVariantForm({
  data,
  handleBlur,
  index,
  deleteVariant,
  setVariantHasError,
  failedSubmitVariant,
}) {
  const [touchedFields, dispatch] = useReducer(reducer, initialState);
  const [filterType, setFilterType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterCities, setFilterCities] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterZipCodes, setFilterZipCodes] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [zipCodesList, setZipCodesList] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { setIsEditingVariant, variantsLocations, setVariantsLocations } =
    useFilterDialog();

  // Mark unfilled fields as touched when failedSubmitVariant is true
  useEffect(() => {
    if (failedSubmitVariant) {
      const fieldsToCheck = [
        { field: "variant_name", value: data.variant_name },
        { field: "hourly_rate", value: data.hourly_rate },
        { field: "fixed_rate", value: data.fixed_rate },
        { field: "sqf_rate", value: data.sqf_rate },
        { field: "states", value: statesList.length },
        { field: "cities", value: citiesList.length },
        { field: "zip_codes", value: zipCodesList.length },
      ];

      fieldsToCheck.forEach(({ field, value }) => {
        if (!value) {
          dispatch({ type: "TOUCH_FIELD", field });
        }
      });
    }
  }, [failedSubmitVariant, data, statesList, citiesList, zipCodesList]);

  useEffect(() => {
    const array = [...variantsLocations];
    array[index] = {
      ...array[index],
      variant_name: data.variant_name,
      hourly_rate: data.hourly_rate,
      fixed_rate: data.fixed_rate,
      sqf_rate: data.sqf_rate,
      states: filterStates,
      cities: filterCities,
      zip_codes: filterZipCodes,
    };

    // Check if at least one of the rate fields is filled
    const hasAtLeastOneRate =
      data.fixed_rate || data.hourly_rate || data.sqf_rate;

    // Required fields
    const requiredFields = ["variant_name", "zip_codes", "cities", "states"];
    const hasError =
      !hasAtLeastOneRate ||
      requiredFields.some((field) => {
        return (
          array[index][field] === null ||
          array[index][field] === undefined ||
          array[index][field] === ""
        );
      });

    setVariantHasError(hasError);
    setVariantsLocations(array);
  }, [filterStates, filterCities, filterZipCodes, data]);

  const handleBlurField = (event, fieldName) => {
    dispatch({ type: "TOUCH_FIELD", field: fieldName });
    if (handleBlur) handleBlur(event, index);
  };

  return (
    <Fragment key={index}>
      <Box key={index} className={classes.variantBackground}>
        <Box
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={classes.threeDotVariantContainer}
        >
          <ThreeDots />
        </Box>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="variant_name"
          label="Variant Name *"
          value={data.variant_name}
          readOnly={false}
          error={
            touchedFields.variant_name &&
            (data.variant_name === null ||
              data.variant_name === undefined ||
              data.variant_name === "") && [
              { key: "variant_name", message: "This field is required" },
            ]
          }
          styleOverride={{ minWidth: "114px" }}
          handleBlur={(e) => handleBlurField(e, "variant_name")}
        />
        <Box className={classes.ratesFormContainer}>
          <FormInput
            gridSizes={[{ size: "md", val: 6 }]}
            name="hourly_rate"
            label="Hourly Rate *"
            type="number"
            value={data.hourly_rate}
            readOnly={false}
            error={
              touchedFields.hourly_rate &&
              !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
                {
                  key: "hourly_rate",
                  message: "At least one rate is required",
                },
              ]
            }
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            styleOverride={{ minWidth: "114px" }}
            handleBlur={(e) => handleBlurField(e, "hourly_rate")}
          />
          <FormInput
            gridSizes={[{ size: "md", val: 6 }]}
            name="fixed_rate"
            label="Fixed Rate *"
            value={data.fixed_rate}
            type="number"
            error={
              touchedFields.fixed_rate &&
              !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
                { key: "fixed_rate", message: "At least one rate is required" },
              ]
            }
            readOnly={false}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            styleOverride={{ minWidth: "114px" }}
            handleBlur={(e) => handleBlurField(e, "fixed_rate")}
          />
        </Box>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="sqf_rate"
          label="Sq Footage Rate *"
          type="number"
          value={data.sqf_rate}
          error={
            touchedFields.sqf_rate &&
            !(data.fixed_rate || data.hourly_rate || data.sqf_rate) && [
              { key: "sqf_rate", message: "At least one rate is required" },
            ]
          }
          readOnly={false}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          styleOverride={{ minWidth: "114px" }}
          handleBlur={(e) => handleBlurField(e, "sqf_rate")}
        />
        <Box
          onClick={() => {
            setIsEditingVariant(true);
            setOpen(true);
            setFilterType("states");
          }}
        >
          <FormSelect
            name="states"
            redirectIcon={true}
            readonly={true}
            label="States Serviced *"
            error={
              touchedFields.states &&
              (statesList.length === 0 || false) && [
                { key: "states", message: "This field is required" },
              ]
            }
            gridSizes={[{ size: "md", val: 12 }]}
            options={[
              {
                value: 1,
                label:
                  statesList.length > 0
                    ? statesList?.map((state) => state).join(", ")
                    : "States Serviced",
              },
              { value: 2, label: "Role 2" },
            ]}
            value={1}
            styleOverride={{ minWidth: "321px" }}
            handleBlur={(e) => handleBlurField(e, "states")}
          />
        </Box>
        <Box
          onClick={() => {
            setIsEditingVariant(true);
            setOpen(true);
            setFilterType("cities");
          }}
        >
          <FormSelect
            name="cities"
            label="Cities Serviced"
            redirectIcon={true}
            error={
              touchedFields.cities &&
              (citiesList.length === 0 || false) && [
                { key: "cities", message: "This field is required" },
              ]
            }
            readonly={true}
            gridSizes={[{ size: "md", val: 12 }]}
            options={[
              {
                value: 1,
                label:
                  citiesList.length > 0
                    ? citiesList?.map((city) => city).join(", ")
                    : "Cities Serviced",
              },
              { value: 2, label: "Role 2" },
            ]}
            value={1}
            styleOverride={{ minWidth: "321px" }}
            handleBlur={(e) => handleBlurField(e, "cities")}
          />
        </Box>
        <Box
          onClick={() => {
            setIsEditingVariant(true);
            setOpen(true);
            setFilterType("zip_codes");
          }}
        >
          <FormSelect
            name="zip_codes"
            label="Zip Codes Serviced"
            redirectIcon={true}
            readonly={true}
            error={
              touchedFields.zip_codes &&
              (zipCodesList.length === 0 || false) && [
                { key: "zip_codes", message: "This field is required" },
              ]
            }
            gridSizes={[{ size: "md", val: 12 }]}
            options={[
              {
                value: 1,
                label:
                  zipCodesList.length > 0
                    ? zipCodesList?.map((zipCode) => zipCode).join(", ")
                    : "Zip Codes Serviced",
              },
              { value: 2, label: "Role 2" },
            ]}
            value={1}
            styleOverride={{ minWidth: "321px" }}
            handleBlur={(e) => handleBlurField(e, "zip_codes")}
          />
        </Box>
        {open ? (
          <CityStateFilterPage
            filterCities={filterCities}
            setFilterCities={setFilterCities}
            filterStates={filterStates}
            setFilterStates={setFilterStates}
            filterZipCodes={filterZipCodes}
            setFilterZipCodes={setFilterZipCodes}
            setCitiesList={setCitiesList}
            setStatesList={setStatesList}
            setZipCodesList={setZipCodesList}
            statesList={statesList}
            citiesList={citiesList}
            zipCodesList={zipCodesList}
            setOpen={setOpen}
            filterType={filterType}
          />
        ) : null}
      </Box>
      <Menu
        className={classes.addRatingModalEndContainer}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            deleteVariant(index);
            setVariantHasError(false);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
