import React, { Fragment, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function ZipCodeFilter({
  classes,
  zipQuery: initialZipQuery, // Renaming the prop to initialZipQuery to avoid conflicts with the state variable
  filterStateSelected,
  clickRow,
  filterStateValue,
  filterCityValue,
  zipQueryLoading,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [statesToFilter, setStatesToFilter] = useState(filterStateValue);
  const [filterCitySelected, setFilterCitySelected] = useState(
    transformArrayOfCities(filterCityValue)
  );
  const [zipQuery, setZipQuery] = useState(initialZipQuery); // Initialize zipQuery as a state variable

  // Update zipQuery state if the initialZipQuery prop changes
  useEffect(() => {
    setZipQuery(initialZipQuery);
  }, [initialZipQuery]);

  function transformArrayOfCities(cities) {
    return cities.map((city, index) => ({
      label: city,
      value: index,
    }));
  }

  const filteredZips = zipQuery?.filter((city) => {
    const matchesZip = city.zip
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCity = filterCitySelected.some(
      (selectedCity) => selectedCity.label === city.city.city_name
    );
    const matchesState = statesToFilter.some(
      (selectedState) => selectedState.value === city.city.state_abbreviation
    );

    // If no city or state is selected, rely on the zip code match
    if (filterCitySelected.length === 0 && statesToFilter.length === 0) {
      return matchesZip;
    }

    // Otherwise, use the normal condition for filtering
    return matchesZip && (matchesCity || matchesState);
  });

  function handleStateChange(event, value) {
    setStatesToFilter(value);
  }

  function handleCityChange(event, value) {
    setFilterCitySelected(value);
  }

  return (
    <Fragment>
      {zipQueryLoading && <LoadingIndicator />}
      {!zipQueryLoading && (
        <Fragment>
          <Box className={classes.servicedSearchContainerFilters}>
            <Typography variant={"h6"} className={classes.previewTableTitle}>
              {filteredZips?.length} Zip Codes
            </Typography>

            <Box className={classes.searchServicedFilterContainer}>
              <FormSelectAuto
                label="Cities Serviced"
                options={transformArrayOfCities(filterCityValue)}
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                handleChange={handleCityChange}
                multiple={true}
                value={filterCitySelected}
              />
              <FormSelectAuto
                label="States Serviced"
                options={filterStateValue}
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                handleChange={handleStateChange}
                multiple={true}
                value={statesToFilter}
              />
              <TextField
                placeholder="Search Zip Code"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  disableunderline: "true",
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.textFieldSearchFilter}
              />
            </Box>
          </Box>
          <TableContainer component={Box}>
            <Table className={classes.table} aria-label="table">
              <TableHead className={classes.filterJobTableHeader}>
                <TableRow>
                  <TableCell className={classes.filterJobTableCol}></TableCell>
                  <TableCell>Zip Code</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredZips?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        inputProps={{ "aria-label": "select job" }}
                        className={classes.tableCheckbox}
                        checked={filterStateSelected.includes(row.id)}
                        onClick={(e) => clickRow(e, row.id, row.zip)}
                      />
                    </TableCell>
                    <TableCell>{row.zip}</TableCell>
                    <TableCell>{row.city.city_name}</TableCell>
                    <TableCell>{row.city.state_abbreviation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Fragment>
  );
}
