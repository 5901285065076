import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    textAlign: "center",
    fontSize: 14,
  },
}));

export default useStyles;
