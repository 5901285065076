import React from "react";
import * as classNames from "classnames";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { appUrl } from "lib/config";

import useStyles from "./styles";

const QualityTabTableActions = (props) => {
  const classes = useStyles();
  const { row } = props;

  const handleClick = (event) => {
    if (row.id.charAt(0) === "L") {
      window.open(
        `${appUrl}survey-reports/report-client-per-survey-detail/${row.id.substring(
          2,
          row.id.length
        )}`
      );
    } else {
      window.open(`surveys/completed/${row.id.substring(2, row.id.length)}`);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        className={classNames(classes.button, classes.buttonPrimary)}
        onClick={handleClick}
        size="small"
      >
        View
      </Button>
    </div>
  );
};

export default withRouter(QualityTabTableActions);
