import React, { useContext, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import SurveysFrontlineBreadcrum from "./surveysFrontlineBreadcrum";
import SurveysFrontlineTabs from "./surveysFrontlineTabs";
import SurveysFrontlineSurfaceTabs from "./surveysFrontlineSurfaceTabs";
import { permissionSurface, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "./styles";
import { useLocation } from "react-router-dom";

const SurveysFrontlineList = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  return (
    <>
      <SurveysFrontlineBreadcrum />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          {hasPermission(permissionSurface.QUALITY, permissions) ? (
            <SurveysFrontlineSurfaceTabs
              selectedTab={
                query.has("locations") ? 4 : query.has("completed") ? 3 : 1
              }
            />
          ) : (
            <SurveysFrontlineTabs
              selectedTab={
                query.has("locations") ? 4 : query.has("completed") ? 3 : 1
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SurveysFrontlineList;
