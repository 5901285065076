import axios from "axios";

import logger from "./logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurred.");
  }

  if (
    error.response &&
    error.response.status === 401 &&
    window.location.pathname !== "/"
  ) {
    // Token not accepted by backend, logout frontend app
    logger.log(error);
    window.location.href = "/logout";
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function setMultiPart() {
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
}

function setFormEncode() {
  axios.defaults.headers.common["Content-Type"] = "x-www-form-urlencoded";
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  setMultiPart,
  setFormEncode,
};

export default httpService;
