import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import { StyledTab } from "components/common/Table/stylesTabs";

import BudgetOverviewTable from "./budgetOverviewTable";
import BudgetTable from "./budgetTable";
import BudgetPortfolioTable from "./budgetPortfolioTable";
import BudgetHistoryTable from "./budgetHistoryTable";
import { useBudgetView } from "contexts/budgetViewContext";

import useStyles from "./styles";

const BudgetData = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState("Overview");
  const [stateContext] = useBudgetView();
  const { budget, isLoading } = stateContext ?? null;

  const { type } = props;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return null;
  }

  const categories = budget.budget_item.reduce((categoryItems, obj) => {
    if (!categoryItems.includes(obj.subcategory1)) {
      categoryItems.push(obj.subcategory1);
    }
    return categoryItems;
  }, []);

  categories.sort(function (value1, value2) {
    if (value1 === "Consolidated") {
      return -1;
    }

    if (value1 === "Labor" && value2 !== "Consolidated") {
      return -1;
    }

    if (
      value1 === "Projects" &&
      value2 !== "Labor" &&
      value2 !== "Consolidated"
    ) {
      return -1;
    }

    return 0;
  });

  return (
    <Box className={classes.rootData}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleTabChange}
          textColor="secondary"
          aria-label="budget tabs"
        >
          <StyledTab
            disableRipple={true}
            label={"Overview"}
            value={"Overview"}
          />
          {type === "3" ? (
            <StyledTab
              disableRipple={true}
              label={"Portfolio Stores"}
              value={"Portfolio"}
            />
          ) : null}
          {categories.map((category, index) => {
            return (
              <StyledTab
                disableRipple={true}
                label={category}
                value={category}
                key={index}
              />
            );
          })}
          <StyledTab disableRipple={true} label={"History"} value={"History"} />
        </Tabs>
      </AppBar>
      <Box className={classes.rootDataContent}>
        {value === "Overview" && <BudgetOverviewTable data={"Overview"} />}
        {value === "Portfolio" && <BudgetPortfolioTable data={"Portfolio"} />}
        {categories.map((category, index) => {
          return (
            value === category && (
              <BudgetTable key={index} category={category} />
            )
          );
        })}
        {value === "History" && <BudgetHistoryTable data={"History"} />}
      </Box>
    </Box>
  );
};

export default BudgetData;
