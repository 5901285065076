import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import useStyles from "components/common/Filters/styles";

import { useFilterDispatch } from "contexts/filterContext";

const typeOptions = [
  { value: 1, label: "Team" },
  { value: 2, label: "Private" },
];

const NoteFilters = (props) => {
  const classes = useStyles();
  const [typeFilters, setTypeFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchFilters = useFilterDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleApply = async () => {
    const filterData = [];
    if (typeFilters.length) {
      filterData.push({
        filter: "type",
        group: "Type",
        values: [...typeFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    handleClose();
  };

  const handleChangeType = (event, value) => {
    setTypeFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        id={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <Grid container spacing={1} className={classes.filterGroup}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h4"
                className={classes.titleFilter}
                gutterBottom
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} className={classes.autoContainerFilter}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={typeOptions}
                name="type"
                internal={true}
                handleChange={handleChangeType}
                multiple={true}
                value={typeFilters}
              />
            </Grid>
          </Grid>

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default NoteFilters;
