import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ThreeDots from "../../icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import EditIcon from "@material-ui/icons/Edit";
import { useAddContactModal } from "../../context/AddContactDialogContext";
import { useContacts } from "./useContacts";
import { useGetUserId } from "../../context/UserIdContext";
import DeleteContactDialogue from "./DeleteContactDialogue";
import { Grid, FormControl, FormLabel, TextField } from "@material-ui/core";

export function ContactList({ classes, contact, index }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteContactDialogueOpen, setIsDeleteContactDialogueOpen] =
    useState(false);
  const { userId } = useGetUserId();
  const [, dispatchContactAction] = useAddContactModal();
  const { deleteContact, isSubmitting, setIsSubmitting } = useContacts();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    deleteContact.mutate({
      partner_id: userId,
      data: {
        contact_id: contact.id,
      },
    });
    handleClose();
  };

  const handleEdit = () => {
    dispatchContactAction({
      type: "TOGGLE_EDIT_CONTACT",
      open: true,
      isEditing: true,
      contact: {
        first_name: contact.contact.first_name,
        last_name: contact.contact.last_name,
        phone_number: contact.contact.phone_number,
        email: contact.contact.email,
        id: contact.id,
      },
    });
    handleClose();
  };

  const menuOptions = [
    {
      label: "Edit",
      icon: <EditIcon fontSize="inherit" />,
      onClick: handleEdit,
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      onClick: () => {
        setIsDeleteContactDialogueOpen(true);
        handleClose();
      },
    },
  ];

  return (
    <Fragment>
      <Box>
        <Box className={classes.companyContactFields}>
          <Grid item md={12} style={{ minWidth: "148px" }}>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="first_name" className={classes.inputLabel}>
                Name
              </FormLabel>
              <TextField
                id="first_name"
                name="first_name"
                readOnly={true}
                value={
                  contact.contact.first_name + " " + contact.contact.last_name
                }
                inputProps={{
                  style: { cursor: "pointer" },
                }}
                InputProps={{ disableUnderline: true, readOnly: true }}
                className={classes.inputField}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} style={{ minWidth: "124px" }}>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="phone_number" className={classes.inputLabel}>
                Phone Number
              </FormLabel>
              <TextField
                id="phone_number"
                name="phone_number"
                type={"tel"}
                value={contact.contact.phone_number}
                style={{ cursor: "pointer" }}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  cursor: "none",
                }}
                inputProps={{
                  style: { cursor: "pointer" },
                }}
                className={classes.inputField}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} style={{ minWidth: "274px" }}>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="phone_number" className={classes.inputLabel}>
                Email
              </FormLabel>
              <TextField
                id="phone_number"
                name="email"
                type={"email"}
                readOnly={true}
                inputProps={{
                  style: { cursor: "pointer" },
                }}
                value={contact.contact.email}
                InputProps={{ disableUnderline: true, readOnly: true }}
                className={classes.inputField}
              />
            </FormControl>
          </Grid>
          <IconButton
            onClick={handleClick}
            disableRipple={true}
            className={classes.threeDotButtonContacts}
          >
            <ThreeDots />
          </IconButton>
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              disabled={isSubmitting}
              onClick={option.onClick}
            >
              <Box className={classes.menuIcon}>{option.icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <DeleteContactDialogue
        handleDeleteUser={handleDelete}
        isDeleteContactDialogueOpen={isDeleteContactDialogueOpen}
        setIsDeleteContactDialogueOpen={() =>
          setIsDeleteContactDialogueOpen(false)
        }
        handleDeleteUserDialogueClose={() =>
          setIsDeleteContactDialogueOpen(false)
        }
      />
    </Fragment>
  );
}
