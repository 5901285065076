import React from "react";
import NoticesDeleteDialog from "./noticesDeleteDialog";

const NoticesListDialog = (props) => {
  return (
    <>
      <NoticesDeleteDialog />
    </>
  );
};
export default NoticesListDialog;
