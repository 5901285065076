export function CommentsActive(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 5.67402C14.0894 5.86366 14.5 6.42613 14.5 7.07215V9.92919C14.5 10.6869 13.9351 11.3295 13.1798 11.3911C12.9539 11.4096 12.7273 11.4257 12.5 11.4395V13.5L10.5 11.5C9.5975 11.5 8.70373 11.4632 7.82015 11.3911C7.62128 11.3748 7.4356 11.3183 7.26996 11.23M13.5 5.67402C13.3991 5.64154 13.2929 5.62 13.1826 5.61085C12.2981 5.53744 11.4034 5.5 10.5 5.5C9.59655 5.5 8.70186 5.53744 7.81739 5.61085C7.06333 5.67343 6.5 6.31549 6.5 7.07214V9.92913C6.5 10.4873 6.80657 10.983 7.26996 11.23M13.5 5.67402V4.42487C13.5 3.34393 12.7322 2.4071 11.6602 2.26794C10.2986 2.09117 8.91002 2 7.50019 2C6.09022 2 4.70155 2.09119 3.33976 2.26799C2.26782 2.40716 1.5 3.34399 1.5 4.42492V8.57509C1.5 9.65603 2.26782 10.5929 3.33976 10.732C3.72441 10.782 4.11119 10.8251 4.5 10.8612V14L7.26996 11.23"
        stroke={props.fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
