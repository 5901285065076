import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { getWorkticketDetails } from "services/workticketSurfaceService";
import { logException } from "components/util/logUtil";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import useStyles from "./styles";

const WorkticketDashboardSurfaceDialog = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [workticket, setWorkticket] = useState(null);
  const { isOpen, handleClose, id } = props;
  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getWorkticketDetails(id);
        const { workticket: workticketData } = result.data.data;
        setWorkticket(workticketData);
        setDetailData([
          { title: "WT Number", value: workticketData.number, column: 1 },
          {
            title: "Location",
            value: workticketData.job?.job_description,
            column: 2,
          },
          { title: "WT Summary", value: workticketData.subject, column: 1 },
          {
            title: "Assigned To",
            value: workticketData?.users.map((option, index) => (
              <Chip
                disabled={false}
                key={index}
                data-tag-index={index}
                tabIndex={-1}
                avatar={<Avatar>{option.first_name[0]}</Avatar>}
                label={`${option.first_name} ${option.last_name}`}
                className={classes.chipAuto}
              />
            )),
            column: 2,
          },
          {
            title: "Status",
            value: (
              <WorkticketStatusChip
                status={workticketData.status}
                archived={workticketData.archived}
                dueDate={workticketData.start_date}
                type={workticketData.type}
              />
            ),
            column: 1,
          },
          {
            title: "WT Due Date",
            value: moment(workticketData.start_date).format("MM/DD/YYYY"),
            column: 2,
          },
        ]);
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load workticket detail dashboard data");
      }
    };
    if (isOpen && id) {
      getData();
    } else if (!isOpen) {
      setIsLoading(true);
    }
  }, [isOpen, id]);

  const CommentItem = (props) => (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={classes.avatarComment}>
          {props.comment.user[0].first_name.charAt(0)}
          {props.comment.user[0].last_name.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listText}
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.listName}
              color="textPrimary"
            >
              {props.comment.user[0].first_name}{" "}
              {props.comment.user[0].last_name}
            </Typography>
            {props.comment.body}
          </>
        }
      />
    </ListItem>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="max-width-dialog-title"
      maxWidth={"sm"}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <Box className={classes.formContainer}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h3" className={classes.sectionHeader}>
                    Workticket Details
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerL}>
                  {detailData
                    .filter((d) => d.column === 1)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-1`}>
                          <Typography
                            key={`${index}-1a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-1b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
                <Grid item md={6} xs={12} className={classes.detailContainerR}>
                  {detailData
                    .filter((d) => d.column === 2)
                    .map((item, index) => {
                      return (
                        <React.Fragment key={`${index}-2`}>
                          <Typography
                            key={`${index}-2a`}
                            variant="h6"
                            gutterBottom
                            className={classes.title}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            key={`${index}-2b`}
                            variant="h6"
                            gutterBottom
                            className={classes.detail}
                          >
                            {item.value}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid container className={classes.sectionContainer}>
                <Grid item xs={12} md={12} lg={12} className={classes.section}>
                  <Typography
                    variant="h3"
                    className={classes.sectionInterHeader2}
                  >
                    Comments
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className={classes.containerActivity}>
                    {workticket.comments.length > 0 ? (
                      <List className={classes.containerActivityList}>
                        {workticket.comments.map((comment) => (
                          <CommentItem key={comment.id} comment={comment} />
                        ))}
                      </List>
                    ) : (
                      <Typography>No comments.</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() =>
                props.history.push(`/workticket/surface/${workticket.id}`)
              }
            >
              View
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default withRouter(WorkticketDashboardSurfaceDialog);
