import React, { useRef, useState, useEffect } from "react";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import GoogleMapReact from "google-map-react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { mapKey } from "lib/config";
import useSupercluster from "use-supercluster";
import { getDetailsOfMapLocation } from "services/mapService";
import Avatar from "@material-ui/core/Avatar";
import {
  useDashboardState,
  setDashboardPropsData,
  useDashboardDispatch,
} from "contexts/dashboardContext";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const Marker = ({ children }) => children;
const createMapOptions = (maps) => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL,
    },
    fullscreenControl: false,
    //styles:
  };
};

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    "&::before": {
      color: theme.palette.common.white,
      border: "2px solid #4F97BB",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    border: "2px solid #4F97BB",
    minWidth: 320,
    minHeight: 150,
  },
  popper: {
    zIndex: "3",
  },
}));
const recordIconColor = { color: "#F39A3E" };
const mainContainer = { height: "100vh", width: "100%" };
const HoverMapComponent = ({ job, manager }) => {
  const hoverText = {
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "17px",
    color: "#4A4A4A",
  };
  const hoverTextBold = {
    fontWeight: "bolder",
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "17px",
    color: "#4A4A4A",
  };
  const main = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "17px",
    color: "#4A4A4A",
    marginBottom: "10px",
  };
  const circularDp = {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  };
  const flex3 = { flex: "3" };
  const flex1 = { flex: "1" };

  const flex = { display: "flex" };
  const container = { color: "black", borderRadius: "0" };
  return job ? (
    <Box style={container}>
      <Typography style={main}>{job?.job_description}</Typography>
      <Box style={flex}>
        <Box style={flex1}>
          {manager?.profile?.picture?.file_url ? (
            <img
              alt="manager"
              style={circularDp}
              src={manager?.profile?.picture?.file_url}
            />
          ) : (
            <Avatar>
              {manager?.first_name[0]}
              {manager?.last_name[0]}
            </Avatar>
          )}
        </Box>
        <Box style={flex3}>
          {manager ? (
            <>
              <Typography style={hoverTextBold}>
                {` ${manager?.first_name ?? ""}  ${manager?.last_name ?? ""}`}
              </Typography>
              <Typography style={hoverText}>
                {` ${manager?.job_title ?? ""}`}
              </Typography>
              <br />
              <Typography style={hoverText}>
                Mobile:{` ${manager?.phone ?? null}`}
              </Typography>
              <Typography style={hoverText}>
                Email:{` ${manager?.email ?? null}`}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box style={container}>
      <p>Loading...</p>
    </Box>
  );
};

const ReactGoogleMapComponent = ({
  locations,
  selectedLocation,
  selectedCustomer,
  onSelectChange,
  isSurfaceUser,
  isMobile,
}) => {
  const dashboardState = useDashboardState();
  const dispatch = useDashboardDispatch();
  const [lastTouchedJobId, setLastTouchedJobId] = useState(null);
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [mapZoom, setMapZoom] = useState(7);
  //35.26633108675557, lng: -83.15278648426514}
  //mobile lat: 28.762787804167985, lng: -82.08060941714724
  const [center, setCenter] = useState({
    lat:
      isMobile || window.screen.width <= 500 ? 28.762787804167985 : 30.850033,
    lng:
      isMobile || window.screen.width <= 500 ? -82.08060941714724 : -77.6500523,
  });
  const [pastZoom, setPastZoom] = useState(7);
  const [pastCenter, setPastCenter] = useState({
    lat:
      isMobile || window.screen.width <= 500 ? 28.762787804167985 : 30.850033,
    lng:
      isMobile || window.screen.width <= 500 ? -82.08060941714724 : -77.6500523,
  });
  const [cacheMarkerData, setCacheMarkerData] = useState({});

  const getHoverData = (jobId) => {
    getDetailsOfMapLocation(jobId, isSurfaceUser).then((res) => {
      setCacheMarkerData({ [jobId]: res, ...cacheMarkerData });
    });
  };
  const classes = useStylesTooltip();
  const [closePopup, setClosedPopup] = useState(false);
  useEffect(() => {
    if (
      dashboardState?.dasboardPropsData?.expanded ||
      dashboardState?.dasboardPropsData?.closeButtonForMobile
    ) {
      setClosedPopup(true);
    } else {
      if (
        !dashboardState?.dasboardPropsData?.expanded ||
        !dashboardState?.dasboardPropsData?.closeButtonForMobile
      )
        setClosedPopup(false);
    }
  }, [
    dashboardState?.dasboardPropsData?.expanded,
    dashboardState?.dasboardPropsData?.closeButtonForMobile,
  ]);
  useEffect(() => {
    if (selectedLocation?.latitude) {
      setPastCenter(center);
      setPastZoom(zoom);
      setTimeout(() => setMapZoom(18), 0);
    } else if (selectedCustomer?.id) {
      const customerLocations = locations.filter(
        (locationItem) => locationItem.customer_id === selectedCustomer.id
      );

      const findGeo = customerLocations.find(
        (cItem) => cItem.longitude && cItem.latitude
      );

      if (findGeo) {
        setCenter({
          lat: parseFloat(findGeo.latitude),
          lng: parseFloat(findGeo.longitude),
        });
      } else {
        setCenter(pastCenter);
      }
      setMapZoom(7);
    } else {
      setCenter(pastCenter);
      setMapZoom(7);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, selectedCustomer]);

  let jobs = [];
  if (locations && selectedCustomer?.id && !selectedLocation?.id) {
    jobs = locations.filter(
      (lItem) => lItem.customer_id === selectedCustomer?.id
    );
  } else if (locations && selectedLocation?.id) {
    jobs = locations.filter((lItem) => lItem.id === selectedLocation?.id);
  } else {
    jobs = locations;
  }

  let points = [];
  if (jobs) {
    points = jobs.map((job) => ({
      type: "Feature",
      properties: { cluster: false, jobId: job.id, city: job.city },
      originalObject: job,
      geometry: {
        type: "Point",
        coordinates: [parseFloat(job.longitude), parseFloat(job.latitude)],
      },
    }));
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 175, maxZoom: 10 },
  });

  return (
    <Box style={mainContainer} onClick={() => setLastTouchedJobId(null)}>
      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={{ key: mapKey }}
        center={
          selectedLocation?.latitude
            ? {
                lat: parseFloat(selectedLocation.latitude),
                lng: parseFloat(selectedLocation.longitude),
              }
            : center
        }
        zoom={mapZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds, center }) => {
          setCenter(center);
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
            bounds,
          ]);
          setDashboardPropsData(dispatch, {
            boundsChanged: new Date(),
            currentMapBounds: bounds,
          });
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <Box
                  className="cluster-marker"
                  style={{
                    width: `${10 + (pointCount / points.length) * 50}px`,
                    height: `${10 + (pointCount / points.length) * 50}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </Box>
              </Marker>
            );
          }
          return (
            <Marker
              key={`job-${cluster.properties.jobId}`}
              lat={latitude}
              lng={longitude}
            >
              <span
                onMouseOver={(e) => {
                  if (cacheMarkerData[cluster.properties.jobId]) {
                    setLastTouchedJobId(cluster.properties.jobId);
                    setClosedPopup(false);
                  } else {
                    getHoverData(cluster.properties.jobId);
                    setLastTouchedJobId(cluster.properties.jobId);
                  }
                  e.stopPropagation();
                }}
                onTouchStart={(e) => {
                  if (cacheMarkerData[cluster.properties.jobId]) {
                    setLastTouchedJobId(cluster.properties.jobId);
                    setClosedPopup(false);
                  } else {
                    getHoverData(cluster.properties.jobId);
                    setLastTouchedJobId(cluster.properties.jobId);
                  }
                  e.stopPropagation();
                }}
              >
                <Tooltip
                  disableFocusListener
                  title={HoverMapComponent(
                    cacheMarkerData[cluster.properties.jobId]?.data?.data
                      ? cacheMarkerData[cluster.properties.jobId]?.data?.data
                      : { job: "", manager: "" }
                  )}
                  open={
                    lastTouchedJobId === cluster.properties.jobId && !closePopup
                  }
                  classes={classes}
                  arrow
                  placement="top"
                >
                  <div>
                    <FiberManualRecordIcon
                      style={recordIconColor}
                      onClick={(e) => {
                        onSelectChange({
                          originalObject: cluster.originalObject,
                        });
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </Tooltip>
              </span>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </Box>
  );
};
export default ReactGoogleMapComponent;
