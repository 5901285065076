import { createTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

export const themeColors = {
  primary: {
    main: "#17457a",
    inverted: "#333333",
  },
  secondary: {
    main: "#4f97bb",
    inverted: "#FFFFFF",
  },
  success: green[600],
  text: {
    main: "#747474",
    inverted: "#FFFFFF",
  },
};

export default createTheme({
  palette: {
    primary: themeColors.primary,
    secondary: themeColors.secondary,
    text: {
      primary: themeColors.text.main,
      inverted: themeColors.text.inverted,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    useNextVariants: true,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  overrides: {
    MUIDataTable: {
      root: {
        backgroundColor: "red",
      },
    },
    MuiList: {
      root: {
        padding: 0,
      },
    },
    MuiPaper: {
      elevation0: {
        border: "1px solid #d4d4d4",
      },
    },
    MuiTableCell: {},
    MuiInput: {
      root: {
        backgroundColor: "#fff",
      },
    },
    MuiBadge: {
      root: {
        width: "100%",
      },
    },
    MuiFab: {
      sizeSmall: {
        background: themeColors.success,
      },
    },
    MuiTab: {
      root: {
        fontWeight: "bold",
        fontSize: "0.875rem",
        textTransform: "none",
        minWidth: "auto",
        padding: "2px 8px",
        marginRight: "60px",
        color: "#dfdfdf",
        "&:hover": {
          color: themeColors.text.main,
        },
      },
    },
    MuiTypography: {
      subtitle1: {
        padding: 0,
      },
      body1: {
        color: "inherit",
      },
      body2: {
        color: "inherit !important",
      },
      h1: {
        fontFamily: '"Lato", sans-serif',
        fontSize: "2.4rem",
        fontWeight: 600,
      },
      h2: {
        fontFamily: '"Lato", sans-serif',
        fontSize: "2rem",
        fontWeight: 600,
      },
      h3: {
        fontFamily: '"Lato", sans-serif',
        fontSize: "1.6rem",
        fontWeight: 600,
      },
      h4: {
        fontFamily: '"Lato", sans-serif',
        fontSize: "1.2rem",
        fontWeight: 600,
      },
      h5: {
        fontFamily: '"Lato", sans-serif',
        fontSize: "0.8rem",
        fontWeight: 600,
      },
    },
    MuiLinearProgress: {
      root: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    MuiDialog: {
      paperScrollBody: {
        height: 550,
        maxHeight: 550,
      },
    },
    MuiTablePagination: {
      caption: {
        "@media (max-width: 400px)": {
          fontSize: 9,
        },
        "@media (max-width: 320px)": {
          display: "none",
        },
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#ffffff",
        borderBottom: "1px solid #d4d4d4",
        boxShadow: "none",
      },
    },
    MuiSnackbar: {
      root: {
        color: "#ffffff",
      },
    },
    MuiAutocomplete: {
      popper: {
        "& .MuiAutocomplete-option": {
          fontSize: "13px !important",
        },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: "3px !important",
      },
    },
  },
});
