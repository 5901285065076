import React, { useContext, useReducer } from "react";

const BudgetViewContext = React.createContext();

const initialState = {
  budget: {},
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_BUDGET":
      return {
        ...state,
        budget: action.budget,
      };
    default:
      return state;
  }
};

const BudgetViewProvider = ({ children }) => (
  <BudgetViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </BudgetViewContext.Provider>
);

const useBudgetView = () => {
  const context = useContext(BudgetViewContext);
  if (context === undefined) {
    throw new Error("useBudgetView must be used within a BudgetViewProvider");
  }
  return context;
};

export { BudgetViewProvider, useBudgetView };
