import React from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import { useTableState } from "contexts/tableContext";
import { exportResult } from "services/surveyService";
import { useSurveyView } from "contexts/surveyViewContext";

const ResultsBulkActions = (props) => {
  const classes = useStyles();
  const tableUi = useTableState();
  const { selected } = tableUi;
  const [stateContextSurvey] = useSurveyView();
  const { survey } = stateContextSurvey ?? null;

  const downloadResult = async () => {
    try {
      const result = await exportResult(survey.id, { record_ids: selected });
      window.open(result.data.data.file.presigned_url);
    } catch (e) {
      console.log("Cannot donwload file.");
    }
  };

  return (
    <Box className={classes.rootBulk}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className={classNames(classes.buttonBulk, classes.buttonPrimaryBulk)}
        onClick={downloadResult}
      >
        Download
      </Button>
    </Box>
  );
};

export default ResultsBulkActions;
