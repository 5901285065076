import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1",
    background: "transparent",
    padding: 20,
    margin: 20
  }
}));

export default useStyles;
