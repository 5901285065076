import Bugsnag from "@bugsnag/js";

export const logException = (error, consoleMsg = null) => {
  if (process.env.REACT_APP_ENV === "production") {
    Bugsnag.notify(error);
  }
  if (consoleMsg) {
    console.log(consoleMsg);
    if (process.env.REACT_APP_ENV === "development") {
      console.log(error);
    }
  }
};
