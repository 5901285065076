import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    color: "#f1f5fd",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    fontSize: "10px",
    padding: "1px 6px",
    fontWeight: "700",
    borderColor: "#78c1ce",
    marginLeft: "10px",
    minWidth: 0,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#3996c0"
    }
  }
});

export default withStyles(styles)(props => (
  <Button variant="contained" {...props}>
    <Typography
      style={{
        color: "#f1f5fd",
        fontSize: "10px",
        fontWeight: "700",
        lineHeight: "normal"
      }}
    >
      Apply
    </Typography>
  </Button>
));
