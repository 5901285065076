import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useNoteDispatch } from "contexts/noteContext";

const options = [
  { id: 1, label: "View", value: "VIEW", user: false },
  { id: 2, label: "Edit", value: "EDIT", user: true },
  { id: 3, label: "Delete", value: "DELETE", user: true },
];

const NoteTableActions = (props) => {
  const { row, mobile } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchNotes = useNoteDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");
    if (action === "VIEW") {
      dispatchNotes({
        type: "SET_NOTE_DETAILS_ACTION",
        noteDetailsAction: "View",
      });
    }

    if (action === "EDIT") {
      dispatchNotes({
        type: "SET_NOTE_DETAILS_ACTION",
        noteDetailsAction: "Edit",
      });
    }

    if (action === "DELETE") {
      dispatchNotes({
        type: "SET_NOTE_DETAILS_ACTION",
        noteDetailsAction: "Delete",
      });
    }

    dispatchNotes({
      type: "SET_NOTE_DETAILS",
      noteDetails: row,
    });
    setAnchorEl(null);
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter((option) => !option.user || user.id === row?.user.id)
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default withRouter(NoteTableActions);
