import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import SurveysTabFilters from "./surveysTabFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import SurveysTabTableActions from "./surveysTabTableActions";
import SurveysTabTableSurfaceActions from "./surveysTabTableSurfaceActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { surveysQATypes } from "constants.js";
import { hasPermission, permissionSurface } from "lib/permissions";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableDispatch } from "contexts/tableContext";
import {
  useSurveysTabState,
  useSurveysTabDispatch,
  getSurveysTabList,
  getSurveysTabListDataSet,
  searchSurveysTabList,
} from "contexts/surveysTabContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const SurveysTabTable = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { surveysTabListLoading, surveysTabList, surveysTabCount } =
    useSurveysTabState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchSurveysTab = useSurveysTabDispatch();
  const dispatchFilters = useFilterDispatch();

  const columns = useMemo(() => [
    {
      id: "survey_name",
      label: "Survey Name",
      path: "survey_name",
    },
    {
      id: "type",
      label: "Survey Type",
      content: (row) =>
        surveysQATypes.find((typeItem) => typeItem.value === parseInt(row.type))
          .label,
    },
    {
      id: "last_survey_date",
      label: "Last Survey Date",
      format: "date",
      style: { width: 200 },
    },
    {
      id: "actions",
      content: (row) =>
        hasPermission(
          permissionSurface.SURVEYS_TAB_LIST_SURFACE,
          permissions
        ) ? (
          <SurveysTabTableSurfaceActions row={row} />
        ) : (
          <SurveysTabTableActions row={row} />
        ),
      contentMobile: (row) =>
        hasPermission(
          permissionSurface.SURVEYS_TAB_LIST_SURFACE,
          permissions
        ) ? (
          <SurveysTabTableSurfaceActions row={row} />
        ) : (
          <SurveysTabTableActions row={row} mobile={true} />
        ),

      style: { width: 125 },
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getSurveysTabList(dispatchSurveysTab, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchSurveysTab, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: surveysTabList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, surveysTabList]);

  const handleScrollClick = useCallback(async () => {
    if (surveysTabCount <= surveysTabList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getSurveysTabListDataSet(
        surveysTabList,
        dispatchSurveysTab,
        surveysTabList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: surveysTabList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    surveysTabList,
    surveysTabCount,
    filterState,
    dispatchSurveysTab,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchSurveysTabList(query, filterData);

          dispatchSurveysTab({
            type: "SET_SURVEYS_TAB_LIST",
            surveysTabList: results,
          });

          dispatchSurveysTab({
            type: "SET_SURVEYS_TAB_COUNT",
            surveysTabCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterSurveysTab: [],
    });
  };
  console.log(surveysTabListLoading);
  return (
    <Box className={classes.root}>
      {surveysTabListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${surveysTabList.length} / ${surveysTabCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                        placeholderSearch={"Search by survey"}
                      >
                        <CalendarFilters />
                        <SurveysTabFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(SurveysTabTable);
