import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import moment from "moment";
import React from "react";
import useStyles from "./styles";

export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();
  const response = selectedModalViewResponse?.data?.form_accident
    ? selectedModalViewResponse.data.form_accident
    : {
        job_id: "",

        user_id: "",
        accident_type: "",
        accident_date: "",
        corrective_action: "",
        correction_date: "",
        protective_equipment: "",
        incidents_past: "",
        corrective_action_past: "",
        repeat_occurrences: "",
      };
  let data = response?.data ? JSON.parse(response?.data) : {};
  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                }}
              >
                <Typography className={classes.modalTitle}>
                  Accident Follow-up Report
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Manager
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      First Name
                    </Typography>
                    <TextField
                      name="name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>Site</Typography>
                    <TextField
                      name="site"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.job?.job_description}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Employee(s) Involved
                    </Typography>
                    <TextField
                      name="employee_involved"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={
                        response?.user?.first_name +
                        " " +
                        response?.user?.last_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Accident
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Type of Accident
                    </Typography>
                    <TextField
                      name="type_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_type}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Date of Accident
                    </Typography>
                    <TextField
                      name="date_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.accident_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Corrective Action
                    </Typography>
                    <TextField
                      name="corrective_action"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.corrective_action}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Date of Correction
                    </Typography>
                    <TextField
                      name="date_of_occurance"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.correction_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Was Employee(s) wearing Personal Protective Equipment?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="was_employee_wearing_personal_equip"
                        name="was_employee_wearing_personal_equip"
                        value={
                          data?.protective_equipment
                            ? String(data.protective_equipment)
                            : ""
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="was_employee_wearing_personal_equip"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="No"
                          name="was_employee_wearing_personal_equip"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Has Employee(s) been involved in other incidents in the
                      past?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="has_employee_involved_accident_past"
                        name="has_employee_involved_accident_past"
                        value={
                          data?.ocurred_past ? String(data.ocurred_past) : ""
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="has_employee_involved_accident_past"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="No"
                          name="has_employee_involved_accident_past"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Corrective Action Given
                    </Typography>
                    <TextField
                      name="corrective_action_given"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={
                        data?.corrective_action_past
                          ? String(data.corrective_action_past)
                          : ""
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Has this type of incident occurred at this site in the
                      past?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="has_type_accident_past_in_this_site"
                        name="has_type_accident_past_in_this_site"
                        value={
                          data?.incidents_past
                            ? String(data.incidents_past)
                            : ""
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="has_type_accident_past_in_this_site"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="No"
                          name="has_type_accident_past_in_this_site"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      What is causing repeat occurrences?
                    </Typography>
                    <TextField
                      name="cause_repeat_occurance"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.repeat_occurrences}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
