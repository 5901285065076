import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import MessageDialog from "../../partnersList/dialog/messageDialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { PartnerIcon } from "components/common/Icons/navIconsV2";

import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { assignPartnerJobs } from "services/partners/locationsService";
import useStyles from "../styles";

export function AddLocationDialog(props) {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { open, handleClose, catalogJobs, catalogCustomers, refreshData } =
    props;

  useEffect(() => {
    if (catalogCustomers) {
      setCustomers(
        catalogCustomers.map((customer) => {
          return { value: customer.id, label: customer.name };
        })
      );
    }

    if (catalogJobs) {
      setJobs(
        catalogJobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
          };
        })
      );
    }
  }, [catalogCustomers, catalogJobs]);

  const onHandleSubmit = async () => {
    try {
      setIsLoading(true);
      await assignPartnerJobs(props.id, {
        customer_ids: selectedCustomers.map((customer) => customer.value),
        job_ids: selectedJobs.map((job) => job.id),
      });
      setIsLoading(false);
      setSelectedCustomers([]);
      setSelectedJobs([]);
      setIsSuccess(true);
      refreshData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeCustomer = (event, value) => {
    setSelectedCustomers(value);
  };

  const handleChangeJob = (event, value) => {
    setSelectedJobs(value);
  };

  const handleCloseSuccess = () => {
    setIsSuccess(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open && !isSuccess}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth={true}
      >
        {!isLoading ? (
          <>
            <DialogTitle
              className={classes.dialogTitleWrapper}
              id="form-dialog-title"
            >
              <Box className={classes.dialogTitleInnerWrapper}>
                <PartnerIcon />
                <Typography className={classes.dialogTitle}>
                  Add Location
                </Typography>
              </Box>
              <IconButton onClick={handleClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.addContactFormWrapper}>
              {selectedJobs.length === 0 && (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={customers}
                  label="Customer"
                  name="customer"
                  handleChange={handleChangeCustomer}
                  multiple={true}
                  value={selectedCustomers}
                />
              )}
              {selectedCustomers.length === 0 && (
                <FormSelectJobsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={jobs}
                  name="job_number"
                  label="Location"
                  handleChange={handleChangeJob}
                  multiple={true}
                  value={selectedJobs}
                />
              )}
              {selectedCustomers.length > 0 && (
                <Alert severity="info" className={classes.infoAlert}>
                  Adding the customer will add all locations associated with the
                  customer.
                </Alert>
              )}
            </DialogContent>
            <DialogActions className={classes.actionFormWrapper}>
              <Button
                className={classes.addContactCancelButton}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.secondAddContactButton}
                onClick={onHandleSubmit}
              >
                Add
              </Button>
            </DialogActions>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </Dialog>
      <MessageDialog
        open={isSuccess}
        title="Success!"
        message="The locations have been successfully added."
        handleClose={handleCloseSuccess}
      />
    </>
  );
}
