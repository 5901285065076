import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import DraftsFilters from "./draftsListFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import DraftsTableActions from "./draftsTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useDraftsState,
  useDraftsDispatch,
  getDraftsList,
  getDraftsDataSet,
  searchDraftsList,
} from "contexts/surveyDraftsContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "Id",
  },
  {
    id: "customer_name",
    label: "Customer",
    path: "customer_name",
  },
  {
    id: "job_number",
    label: "Job Number",
    path: "job_number",
  },
  {
    id: "job_description",
    label: "Job Name",
    path: "job_description",
  },
  {
    id: "survey_name",
    label: "Survey Name",
    path: "survey_name",
  },
  {
    id: "created_at",
    label: "Creation Date",
    path: "created_at",
    format: "date",
  },
  {
    id: "actions",
    content: (row) => <DraftsTableActions row={row} />,
    contentMobile: (row) => <DraftsTableActions row={row} mobile={true} />,
    style: { width: 30 },
  },
];

const DraftsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { draftsListLoading, draftsList, draftsCount } = useDraftsState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchDrafts = useDraftsDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getDraftsList(dispatchDrafts, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchDrafts, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: draftsList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, draftsList]);

  const handleScrollClick = useCallback(async () => {
    if (draftsCount <= draftsList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getDraftsDataSet(
        draftsList,
        dispatchDrafts,
        draftsList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: draftsList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [draftsList, draftsCount, filterState, dispatchDrafts, dispatchTable]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchDraftsList(query, filterData);

          dispatchDrafts({
            type: "SET_DRAFTS_LIST",
            draftsList: results,
          });

          dispatchDrafts({
            type: "SET_DRAFTS_COUNT",
            draftsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  return (
    <Box className={classes.root}>
      {draftsListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${draftsList.length} / ${draftsCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected></ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                          placeholderSearch={"Search by survey name"}
                        >
                          <CalendarFilters />
                          <DraftsFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(DraftsTable);
