import React, { useEffect, useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "components/common/Table/table";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import {
  getAllSliderWorkList,
  useDashboardDispatch,
} from "contexts/dashboardContext";
import { useTableDispatch } from "contexts/tableContext";
import { withRouter } from "react-router-dom";
import { followUnfollowWorkTicket } from "services/mapService";
import WorkticketDashboardSurfaceDialog from "../dialog/workticketDashboardSurfaceDialog";
import WorkticketDashboardDialog from "../dialog/workticketDashboardDialog";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import useStyles from "../styles";
import WorkticketAssigned from "components/ui/Worktickets/WorkticketAssigned";

const WorkTicketsTable = ({
  workTableData,
  selectedLocation,
  isSurfaceUser,
}) => {
  const dispatch = useDashboardDispatch();
  const classes = useStyles();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedWT, setSelectedWT] = useState(null);
  const columns = [
    {
      checkbox: false,
    },
    {
      id: "number",
      label: "Number",
      content: (row) => (
        <Box className={classes.rowLink} onClick={() => openDetail(row.id)}>
          {row.number}
        </Box>
      ),
    },
    {
      id: "user",
      label: "Name",
      content: (row) => row.subject,
    },
    {
      id: "jobs",
      label: "Locations",
      content: (row) => row.job.job_description,
    },
    {
      id: "assignedTo",
      label: "Assigned To",
      content: (row) => <WorkticketAssigned users={row.users} />,
    },
    { id: "start_date", label: "Due Date", format: "date" },
    {
      id: "status",
      label: "Status",
      content: (row) => (
        <WorkticketStatusChip
          status={row.status}
          type={row.type}
          dueDate={row.start_date}
        />
      ),
    },
    {
      id: "action",
      label: "",
      content: (row) =>
        Object.keys(row).indexOf("is_following") < 0 ? (
          ""
        ) : Number(row.is_following) === 1 ? (
          <Button
            className={classes.followingBtn}
            onClick={() => {
              followUnfollowWorkTicket(
                row.id,
                row.is_following,
                isSurfaceUser
              ).then((res) => {
                getAllSliderWorkList(dispatch, selectedLocation.job_number);
              });
            }}
          >
            Following
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.followBtn}
            onClick={() => {
              followUnfollowWorkTicket(
                row.id,
                row.is_following,
                isSurfaceUser
              ).then((res) => {
                getAllSliderWorkList(dispatch, selectedLocation.job_number);
              });
            }}
          >
            Follow
          </Button>
        ),
    },
  ];

  const dispatchTable = useTableDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const openDetail = (id) => {
    setIsOpenDialog(true);
    setSelectedWT(id);
  };

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: workTableData?.worktickets });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable]);

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.rootContainer}>
          <Box className={classes.tableContainer}>
            <Box className="sliderHeightTable">
              <Table newColumns={columns} activeMobile={true}></Table>
            </Box>
          </Box>
        </Box>
      </Box>
      {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
        <WorkticketDashboardSurfaceDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedWT}
        />
      ) : (
        <WorkticketDashboardDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedWT}
        />
      )}
    </Box>
  );
};

export default withRouter(WorkTicketsTable);
