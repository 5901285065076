import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useLearnPageDispatch } from "contexts/learnPageContext";
import Box from "@material-ui/core/Box";
import { CallMissedSharp } from "../../../node_modules/@material-ui/icons/index";
import useStyles from "./styles";

const options = [
  {
    id: 1,
    label: "View/Edit",
    value: "VIEW",
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
  },
];

const LearnPageTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchLearnPage = useLearnPageDispatch();
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { item } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    switch (action) {
      case "DELETE":
        dispatchLearnPage({
          type: "TOGGLE_DELETE",
          openLearnDelete: true,
        });
        break;
      case "VIEW":
        dispatchLearnPage({
          type: "TOGGLE_MANAGE",
          openTileManage: true,
        });
        break;
      default:
        break;
    }
    dispatchLearnPage({
      type: "SET_TILE_SELECTED",
      tileSelected: item,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.actionContainer}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LearnPageTableActions;
