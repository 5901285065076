import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import UserLocationsFilters from "./userLocationsFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { useIndividualView } from "contexts/individualViewContext";
import { ButtonBase } from "components/ui/core/button";
import LocationsChangesDialog from "./dialog/locationsChangesDialog";
import useStyles from "./styles";

import { useTableDispatch } from "contexts/tableContext";
import {
  useUserLocationsState,
  useUserLocationsDispatch,
  getUserLocationsList,
  getUserLocationsListDataSet,
  searchUserLocationsList,
} from "contexts/userLocationsContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "Job ID",
    path: "id",
  },
  {
    id: "job_number",
    label: "Job Number",
    path: "job_number",
  },
  {
    id: "job_description",
    label: "Job Name",
    path: "job_description",
  },
  {
    id: "note",
    label: "Site Number",
    path: "note",
  },
  {
    id: "site_number",
    label: "Site Number",
    path: "site_number",
  },
  {
    id: "type",
    label: "Type",
    content: (row) => (row.type ? "Child" : "Parent"),
  },
  {
    id: "city",
    label: "City",
    path: "city",
  },
  {
    id: "state",
    label: "State",
    path: "state",
  },
];

const UserLocationsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openChanges, setOpenChanges] = useState(false);
  const [adding, setAdding] = useState(false);
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;

  const { userLocationsListLoading, userLocationsList, userLocationsCount } =
    useUserLocationsState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchUserLocations = useUserLocationsDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getUserLocationsList(
        individual.id,
        dispatchUserLocations,
        filterData
      );
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchUserLocations, filterState]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: userLocationsList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
  }, [dispatchTable, userLocationsList]);

  const handleScrollClick = useCallback(async () => {
    if (userLocationsCount <= userLocationsList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      await getUserLocationsListDataSet(
        individual.id,
        userLocationsList,
        dispatchUserLocations,
        userLocationsList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: userLocationsList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    userLocationsList,
    userLocationsCount,
    filterState,
    dispatchUserLocations,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchUserLocationsList(
            individual.id,
            query,
            filterData
          );

          dispatchUserLocations({
            type: "SET_USER_LOCATIONS_LIST",
            userLocationsList: results,
          });

          dispatchUserLocations({
            type: "SET_USER_LOCATIONS_COUNT",
            userLocationsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterUserLocations: [],
    });
  };

  const handleCloseChanges = () => {
    setOpenChanges(false);
  };

  const openAddLocations = (event) => {
    setAdding(true);
    setOpenChanges(true);
  };

  const openRemoveLocations = (event) => {
    setAdding(false);
    setOpenChanges(true);
  };

  return (
    <Box className={classes.root}>
      {userLocationsListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <LocationsChangesDialog
            adding={adding}
            open={openChanges}
            handleClose={handleCloseChanges}
            userId={individual.id}
          />
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${userLocationsList.length} / ${userLocationsCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      <ToolbarDefault
                        handleSearch={handleSearch}
                        handleSearchClear={handleSearchClear}
                      >
                        <Box className={classes.listActionContainer}>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            onClick={openAddLocations}
                          >
                            Add Locations
                          </ButtonBase>
                          <ButtonBase
                            variant="outlined"
                            color="secondary"
                            onClick={openRemoveLocations}
                          >
                            Remove Locations
                          </ButtonBase>
                        </Box>
                        <UserLocationsFilters />
                      </ToolbarDefault>
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(UserLocationsTable);
