import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as classNames from "classnames";
import DateRangePickerComponent from "components/common/Filters/calendarFilters";
// import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import Table from "components/common/Table/table";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import Counter from "components/common/TableList/counter";
import {
  getFilterData,
  useFilterDispatch,
  useFilterState,
} from "contexts/filterContext";
import {
  useOpenPositionsDispatch,
  useOpenPositionsState,
} from "contexts/openPositionsContext";
import { useTableDispatch, useTableState } from "contexts/tableContext";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getOpenPositionById } from "services/openPositionsService";
import { getOpenPositionsList } from "services/openPositionsService";
import OpenPositionsPreview from "../dashboard/ringBellTab/openPositionsPreview";
import FieldLocation from "./fieldLocation";
import OpenPositionsChip from "./openPositionChip";
import OpenPositionsBulkActions from "./openPositionsBulkActions";
// import OpenPositionsFilters from "./openPositionsFilters";
import OpenPositionsTableActionsTab from "./openPositionsTableActionsTab";
import useStyles from "./styles";
import TalentRequest from "./talentRequest";
import WeeklyScheduleModal from "./weeklyScheduleModal";
import { useDashboardState } from "contexts/dashboardContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const mapToOffset = {
  0: "all",
  1: "opened",
  2: "candidate_identified",
  3: "interviewing",
  4: "offered",
  5: "onboarding",
  6: "filled",
  10: "archived",
};

const OpenPositionsSurfaceTable = (props) => {
  const columns = [
    {
      id: "id",
      label: "OPS No",
      content: (row) =>
        row?.id ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
            style={{ textDecoration: "underline" }}
            onClick={() => handlePreview(row.id)}
          >{`OPS - ${row?.id}`}</Typography>
        ) : null,
      style: { width: 250 },
    },
    {
      id: "requestor_user",
      label: "Requestor",
      content: (row) =>
        row?.first_name ? (
          <Typography
            variant="body2"
            aria-haspopup="true"
          >{`${row?.first_name}  ${row?.last_name}`}</Typography>
        ) : null,
      style: { width: 250 },
    },
    {
      id: "requestor_type",
      label: "Request Type",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {parseInt(row.type) === 1 ? "New" : "Replacement"}
        </Typography>
      ),
      style: { width: 150 },
    },

    {
      id: "job",
      label: "Job Name",
      content: (row) => (
        <FieldLocation job={{ job_description: row.job_description }} />
      ),
      style: { width: 250 },
    },
    {
      id: "date_requested",
      label: "Date Requested",
      format: "date",
      style: { width: 50 },
    },
    {
      id: "customer",
      label: "Customer",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.name}
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "position_title",
      label: "Position Title",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.position_title}
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "position_type",
      label: "Position Type",
      content: (row) => (
        <Typography variant="body2" aria-haspopup="true">
          {row?.position_type}
        </Typography>
      ),
      style: { width: 250 },
    },
    {
      id: "status",
      label: "Status",
      content: (row) => (
        <OpenPositionsChip status={row?.status} archived={row?.archived} />
      ),
    },
    {
      id: "actions",
      label: "",
      content: (row) => (
        <OpenPositionsTableActionsTab
          row={row}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ),
      contentMobile: (row) => (
        <OpenPositionsTableActionsTab
          row={row}
          setStatusModal={setStatusModal}
          setSelectedRowData={setSelectedRowData}
        />
      ),
      style: { width: 58 },
    },
  ];
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTalentRequest, setOpenTalentRequest] = useState(false);
  const [openWeeklySchedule, setOpenWeeklySchedule] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const { openPositionsList } = useOpenPositionsState();
  const [refresh, setRefresh] = useState(false);
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchOpenPositions = useOpenPositionsDispatch();
  const dispatchFilters = useFilterDispatch();
  const [openStatusModal, setStatusModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [weeklyScheduleData, setWeeklyScheduleData] = useState("");
  const [filteredRes, setFilteredRes] = useState("");
  const [tab, setTab] = useState(1);
  const [preview, setPreview] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  const dashboardState = useDashboardState();

  const query = new URLSearchParams(window?.location?.search);
  const search = query.get("job_number");
  const searchCustomerId = query.get("customer_id");

  const handlePreview = (id) => {
    setPreviewModal(true);
    getOpenPositionById(id).then((res) => {
      setPreview(res?.data?.data);
    });
  };

  const [tabUpdated, setTabUpdated] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
  });

  useEffect(() => {
    if (tab && openPositionsList?.data?.open_positions[tab]) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [...openPositionsList?.data?.open_positions[tab]],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    const filterData = getFilterData(filterState);
    setWeeklyScheduleData("");
    dispatchOpenPositions({
      type: "SET_OPEN_POSITIONS_LIST",
      openPositionsList: null,
    });

    const jobItem = dashboardState?.mapLocationList?.data?.jobs.find(
      (v) => v.job_number === Number(search)
    );

    let filterJobPresentAlready = filterData.filters.find(
      (v) => v.filter === "job"
    );

    if (search && !filterJobPresentAlready && jobItem) {
      filterData.filters.push({
        filter: "job",
        group: "Job",
        values: [
          { label: jobItem?.job_description, value: Number(jobItem.id) },
        ],
      });
      return;
    }

    if (searchCustomerId) {
      filterData.filters.push({
        filter: "customer",
        group: "Customer",
        values: [{ label: "Customer", value: Number(searchCustomerId) }],
      });
      return;
    }

    const { dasboardPropsData } = dashboardState;

    if (dasboardPropsData?.checked && dasboardPropsData.currentMapBounds) {
      filterData.filters.push({
        filter: "geo1",
        group: "Geo",
        values: [
          {
            label: "",
            value: `${dasboardPropsData.currentMapBounds.sw.lng},${dasboardPropsData.currentMapBounds.sw.lat}`,
          },
        ],
      });
      filterData.filters.push({
        filter: "geo2",
        group: "Geo",
        values: [
          {
            label: "",
            value: `${dasboardPropsData.currentMapBounds.ne.lng},${dasboardPropsData.currentMapBounds.ne.lat}`,
          },
        ],
      });
    }

    getOpenPositionsList(filterData).then((res) => {
      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: res?.data,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: res?.data?.data?.open_positions[tab],
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (
      (filterState.startDateActive && filterState.endDateActive) ||
      filterState.filters.length >= 0
    ) {
      const filterData = getFilterData(filterState);
      const openPositionsListCopy = openPositionsList;
      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: null,
      });
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }

      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      getOpenPositionsList(filterData, filterState.searchString).then((res) => {
        if (filterState.searchString && tab === 11) {
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: res?.data?.data?.open_positions,
          });

          dispatchOpenPositions({
            type: "SET_OPEN_POSITIONS_LIST",
            openPositionsList: openPositionsListCopy,
          });
          setFilteredRes(res?.data?.data);
          return;
        }
        dispatchOpenPositions({
          type: "SET_OPEN_POSITIONS_LIST",
          openPositionsList: res?.data,
        });
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: res?.data?.data?.open_positions[tab],
        });
        dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterState.filters.length,
    filterState.startDateActive,
    filterState.endDateActive,
    filterState,
  ]);

  useEffect(() => {
    if (openPositionsList?.data) {
      dispatchOpenPositions({
        type: "SET_CURRENT_TAB",
        currentSelectedTab: tab,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: openPositionsList?.data?.open_positions[tab],
      }); //openPositionsList
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchOpenPositions, tab]);

  const handleScrollClick = () => {
    if (
      openPositionsList?.data?.open_positions[tab]?.length >=
        openPositionsList?.data?.filter_counts[tab] ||
      loadingMore ||
      tab === 11
    ) {
      return;
    }
    let offsetLength = openPositionsList?.data?.open_positions[tab]?.length;
    const filterData = getFilterData(filterState);

    setLoadingMore(true);
    getOpenPositionsList(
      filterData,
      filterState.searchString,
      offsetLength,
      mapToOffset[tab]
    ).then((res) => {
      openPositionsList.data.open_positions[tab] = [
        ...openPositionsList.data.open_positions[tab],
        ...res.data.data.open_positions,
      ];

      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: { ...openPositionsList },
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [
          ...openPositionsList?.data?.open_positions[tab],
          ...res.data.data.open_positions,
        ],
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      setLoadingMore(false);
    });
  };

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        setTab(11);

        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });

        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    const filterData = getFilterData(filterState);
    setTab(1);
  };

  return (
    <Box className={classes.root}>
      {previewModal ? (
        <OpenPositionsPreview
          preview={preview}
          setPreview={setPreview}
          setPreviewModal={setPreviewModal}
        />
      ) : null}

      {openWeeklySchedule ? (
        <WeeklyScheduleModal
          setOpenWeeklySchedule={setOpenWeeklySchedule}
          setWeeklyScheduleData={setWeeklyScheduleData}
          weeklyScheduleData={weeklyScheduleData}
          openWeeklySchedule={openWeeklySchedule}
          fullScreen={fullScreen}
        />
      ) : null}

      {openTalentRequest && (
        <Drawer open={openTalentRequest}>
          <Box
            className={classNames(
              classes.trSidebar,
              classes.overflowScroll,
              classes.maxWidthSidebar
            )}
          >
            <TalentRequest
              setOpenTalentRequest={setOpenTalentRequest}
              setOpenWeeklySchedule={setOpenWeeklySchedule}
              weeklyScheduleData={weeklyScheduleData}
              setReload={setRefresh}
              setTabUpdated={setTabUpdated}
              tabUpdated={tabUpdated}
              setWeeklyScheduleData={setWeeklyScheduleData}
              refresh={refresh}
            />
          </Box>
        </Drawer>
      )}
      {!openPositionsList?.data ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoadingTab} />
      ) : (
        <Box className={classes.root}>
          <Box className={classes.contentContainer}>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.tabTableFullHeight}>
                  {openPositionsList?.data && (
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={columns}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                      hasSelect={false}
                    >
                      <EnhancedTableToolbar>
                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "block" : "none",
                          }}
                        >
                          <ToolbarSelected>
                            <OpenPositionsBulkActions
                              selected={selected}
                              setStatusModal={setStatusModal}
                              setSelectedRowData={setSelectedRowData}
                            />
                          </ToolbarSelected>
                        </Grid>

                        <Grid
                          container
                          className={classes.marginToolbar}
                          style={{
                            display: selected.length > 0 ? "none" : "flex",
                          }}
                        >
                          <Grid item md={6} xs={12}>
                            <ToolbarDefault
                              handleSearch={handleSearch}
                              placeholderSearch={"Search Candidates"}
                              handleSearchClear={handleSearchClear}
                            >
                              <Button
                                className={classNames(
                                  classes.button,
                                  classes.marginLeft10,
                                  classes.talReqButton
                                )}
                                onClick={() => setOpenTalentRequest(true)}
                              >
                                Talent Request
                              </Button>
                            </ToolbarDefault>
                          </Grid>

                          <Grid item md={2} xs={12} />
                          <Grid
                            item
                            md={4}
                            xs={12}
                            className={classes.filterTabAdj}
                          >
                            <DateRangePickerComponent />
                            <Box>
                              <span className={classes.contentCounter}>
                                {tab === 11 ? (
                                  <Counter>{`${
                                    filteredRes.open_positions?.length || 0
                                  } / ${
                                    filteredRes.filter_counts || 0
                                  }`}</Counter>
                                ) : (
                                  <Counter>{`${
                                    openPositionsList?.data?.open_positions[tab]
                                      ?.length || 0
                                  } / ${
                                    openPositionsList?.data?.filter_counts[
                                      tab
                                    ] || 0
                                  }`}</Counter>
                                )}
                              </span>
                            </Box>
                          </Grid>
                        </Grid>
                      </EnhancedTableToolbar>
                    </Table>
                  )}
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withRouter(OpenPositionsSurfaceTable);
