import React from "react";
import Box from "@material-ui/core/Box";
import RecognitionTable from "./recognitionTable";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { RecognitionProvider } from "contexts/recognitionContext";
import useStyles from "./styles";
import RecognitionListDialog from "./dialog/recognitionListDialog";
import { RingBellProvider } from "contexts/ringBellContext";
import RingBellActionDialog from "pages/ringBellList/dialog/ringBellActionDialog";

const RecognitionList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <RecognitionProvider>
          <RingBellProvider>
            <RecognitionTable />
            <RecognitionListDialog />
            <RingBellActionDialog />
          </RingBellProvider>
        </RecognitionProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(RecognitionList);
