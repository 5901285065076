import React, { useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import TicketIcon from "@material-ui/icons/Receipt";
import { ButtonBase } from "components/ui/core/button";
import WorkticketQuoteFMPilot from "pages/workticketPage/workticketQuoteFMPilot";
import WorkticketQuoteCorrigo from "pages/workticketPage/workticketQuoteCorrigo";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import useStyles from "./styles";

const WorkticketQuote = (props) => {
  const { row } = props;
  const { rows } = useTableState();
  const dispatchTable = useTableDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleQuote = () => {
    setOpen(true);
  };

  const handleUpdate = () => {
    const selRow = rows.find((r) => r.id === row.id);
    selRow.status = 0;
    dispatchTable({ type: "UPDATE_ROWS", rows: rows });
  };

  const handleOpenProposal = () => {
    const currentDomain = window.location.hostname;
    const baseUrl =
      currentDomain === "app.encompassonsite.com"
        ? "https://www.servicechannel.com"
        : "https://sb2.servicechannel.com";
    const url = `${baseUrl}/sc/Proposal/CreateProposal/FromWo?recid=${row.number}`;
    window.open(url, "_blank");
  };

  return hasPermission(permissionWorkticket.REQUESTS_QUOTE, permissions) ? (
    parseInt(row.workticket_category) === 7 ? (
      <>
        <ButtonBase
          variant="outlined"
          color="secondary"
          onClick={() => setOpenDialog(true)}
          className={classes.root}
          disabled={parseInt(row.status) !== 1}
        >
          Send Proposal
        </ButtonBase>
        {openDialog ? (
          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={openDialog}
            onClose={() => setOpenDialog(false)}
          >
            <DialogContent>
              <DialogCloseIcon handleClose={() => setOpenDialog(false)} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              <Box className={classes.wrapperDialog}>
                <Typography variant="body2">
                  Please submit your proposal in Service Channel Portal
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction}>
              <ButtonBase
                variant="contained"
                color="secondary"
                onClick={() => handleOpenProposal()}
              >
                Create Proposal
              </ButtonBase>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    ) : parseInt(row.workticket_category) === 8 ? (
      <>
        <ButtonBase
          variant="outlined"
          color="secondary"
          onClick={handleQuote}
          className={classes.root}
          disabled={![0, 6].includes(parseInt(row.status))}
        >
          Send Quote
        </ButtonBase>
        <WorkticketQuoteFMPilot
          workticketId={row.id}
          woNumber={row.number}
          woStatus={row.status_external}
          dne={row.nte}
          handleClose={() => setOpen(false)}
          open={open}
          workticketNumber={row.workticket_number}
          handleUpdate={handleUpdate}
        />
      </>
    ) : (
      <>
        <ButtonBase
          variant="outlined"
          color="secondary"
          onClick={handleQuote}
          className={classes.root}
          disabled={![0, 6].includes(parseInt(row.status))}
        >
          Send Quote
        </ButtonBase>
        <WorkticketQuoteCorrigo
          workticketId={row.id}
          woNumber={row.number}
          woStatus={row.status_external}
          dne={row.nte}
          handleClose={() => setOpen(false)}
          open={open}
          workticketNumber={row.workticket_number}
          handleUpdate={handleUpdate}
        />
      </>
    )
  ) : null;
};

export default WorkticketQuote;
