import React, { Fragment, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { dateFormatField } from "../../../components/util/timeFormat";
import { Rating } from "@material-ui/lab";
import Integer from "lodash";
import ThreeDots from "../icons/ThreeDots";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useRateDialog } from "./useRateDialog";

export function RatingTableBody({ row, index, originalClasses, partner_id }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    isLoading: submitIsLoading,
    setIsLoading,
    deleteRating,
    handleEditTrade,
  } = useRateDialog();
  return (
    <Fragment key={index}>
      <TableRow key={index}>
        <TableCell align="left">
          {row.user.first_name + " " + row.user.last_name}
        </TableCell>
        <TableCell align="left">{row.comment}</TableCell>
        <TableCell align="left">{dateFormatField(row.created_at)}</TableCell>
        <TableCell align="left">
          <Rating
            className={originalClasses.ratingRootStyling}
            name="simple-controlled"
            value={Integer.parseInt(row.rate)}
            disabled={true}
          />
        </TableCell>
        <TableCell onClick={handleClick} align="left">
          {Number(row.user_id) === Number(user.id) && <ThreeDots />}
        </TableCell>
      </TableRow>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleEditTrade(row);
            handleClose();
          }}
          disabled={submitIsLoading}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={submitIsLoading}
          onClick={() => {
            setIsLoading(true);
            const data = {
              rating_id: row.id,
            };
            deleteRating.mutate(data);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
