import React from "react";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircle";

import useStyles from "./styles";

const StepNav = (props) => {
  const classes = useStyles();
  const { step, list } = props;

  return (
    <React.Fragment>
      <Typography variant="subtitle2" gutterBottom className={classes.stepText}>
        Step {step}/4
      </Typography>
      {list &&
        Boolean(list.length) &&
        list.map((item, index) => {
          return (
            <Typography
              variant="h6"
              gutterBottom
              className={classes.itemRequest}
              key={index}
            >
              <AddIcon className={classes.iconItemRequest} fontSize="small" />{" "}
              REQ-{index + 1}
            </Typography>
          );
        })}
    </React.Fragment>
  );
};

export default StepNav;
