import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "30%",
    maxHeight: 80,
    marginRight: "2%",
    display: "inline-block",
  },
  fileIcon: {
    margin: 5,
  },
  cardRoot: {
    position: "relative",
  },
  wrapperSurveyRoot: {
    marginTop: 8,
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: 6,

    "& > div": {
      width: "24%",
      padding: 10,
      textAlign: "center",
      marginBottom: 4,
      [theme.breakpoints.down("sm")]: {
        width: "23%",
      },
    },
    "& img": {
      maxWidth: "100%",
    },
  },
  cardThumb: {
    height: 120,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f8f8f8",
    padding: 4,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 60,
    },
    "& > svg": {
      fontSize: 60,
      [theme.breakpoints.down("sm")]: {
        fontSize: 40,
      },
    },
  },
  cardLabel: {
    marginTop: 8,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 12,
  },
  wrapperRemove: {
    position: "absolute",
    top: 5,
    right: 5,
    "& button": {
      background: "#f8f8f8",
      cursor: "pointer",
    },
    "& button:hover": {
      background: "#cb3837",
      color: "#ffffff",
    },
    "& svg": {
      fontSize: 14,
    },
  },
}));

export default useStyles;
