import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  chipCat: {
    fontSize: 11,
    height: 25,
    marginRight: 2
  }
}));

export default useStyles;
