import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { surveysLabelAlpha } from "constants.js";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  questionFill,
} from "contexts/surveyFillContext";

const SurveyQuestionMultipleChoice = (props) => {
  const classes = useStyles();
  const [hovering, setHovering] = useState({ id: -1 });
  const {
    selectedQuestions,
    correlative,
    lang,
    updateAnswers,
    isPreview,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const response = selectedQuestions[correlative].response
    ? JSON.parse(selectedQuestions[correlative].response)
    : null;
  const options = response
    ? response.options
    : JSON.parse(selectedQuestions[correlative].options);
  const options_es = response
    ? response.options_es
    : JSON.parse(selectedQuestions[correlative].options_es);
  const selectedOption = response ? response.selected : null;

  const handleMouseOver = (selectedOption) => {
    setHovering(selectedOption);
  };

  const handleMouseLeave = () => {
    setHovering({ id: -1 });
  };

  const handleSaveOption = (option) => {
    if (!readonlySurvey) {
      const data = {
        response: { selected: option, options, options_es },
        status: 2,
        completed_using_es: lang !== "English",
      };
      if (!isPreview) {
        questionFill(
          dispatchSurveyFill,
          selectedQuestions[correlative].id,
          data,
          selectedQuestions[correlative].status === 1,
          !updateAnswers
        );
      }
      selectedQuestions[correlative].response = JSON.stringify(data.response);
      selectedQuestions[correlative].status = data.status;
      dispatchSurveyFill({
        type: "SET_SELECTED_QUESTIONS",
        selectedQuestions: selectedQuestions,
      });
    }
  };

  return (
    <Box>
      <List className={classes.questionMultiList}>
        <ListItem className={classes.listMinHeight}>
          <Grid container spacing={3}>
            <Grid container className={classes.alignMultipleQuesOption}>
              {options.map((option, i) => {
                return (
                  <Grid key={i} item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={
                        option.id === hovering.id ||
                        option.id === selectedOption?.id
                          ? classes.mcqSelected
                          : classes.multipleOptionItemWrapper
                      }
                      onMouseOver={() =>
                        !readonlySurvey ? handleMouseOver(option) : ""
                      }
                      onMouseLeave={() =>
                        !readonlySurvey ? handleMouseLeave(option) : ""
                      }
                      onClick={() => handleSaveOption(option)}
                    >
                      <span className={classes.alphabetOptionsBox}>
                        <span className={classes.alphabetOptionsBoxText}>
                          {surveysLabelAlpha[i]}
                        </span>
                      </span>
                      <Typography
                        className={
                          option.id === hovering.id ||
                          option.id === selectedOption?.id
                            ? classes.mcqSelectedText
                            : classes.alphabetOptionText
                        }
                      >
                        {lang === "Spanish" && options_es[i]?.name?.length > 0
                          ? options_es[i].name
                          : option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

export default SurveyQuestionMultipleChoice;
