import React from "react";
import Box from "@material-ui/core/Box";
import NotesTable from "./notesTable";
import { NoteProvider } from "contexts/noteContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const NotesList = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.pageBodyWrapper}>
      <NoteProvider>
        <NotesTable />
      </NoteProvider>
    </Box>
  );
};

export default withTableFilterContext(NotesList);
