export function DeleteBin(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.noBg !== false && (
        <rect
          x="0.132812"
          y="0.636719"
          width="24"
          height="24"
          rx="4"
          fill={props.noBg ? "#F8F8F8" : "#ECECEC"}
        />
      )}

      <path
        d="M13.8074 10.5L13.5766 16.5M10.3843 16.5L10.1535 10.5M16.7989 8.36038C17.0269 8.39481 17.2541 8.43165 17.4805 8.47086M16.7989 8.36038L16.087 17.615C16.0269 18.3965 15.3752 19 14.5914 19H9.36952C8.58571 19 7.93405 18.3965 7.87393 17.615L7.16204 8.36038M16.7989 8.36038C16.0346 8.24496 15.2615 8.15657 14.4805 8.09622M6.48047 8.47086C6.70685 8.43165 6.93405 8.39481 7.16204 8.36038M7.16204 8.36038C7.92632 8.24496 8.69946 8.15657 9.48047 8.09622M14.4805 8.09622V7.48546C14.4805 6.69922 13.8734 6.04282 13.0875 6.01768C12.7199 6.00592 12.3509 6 11.9805 6C11.61 6 11.241 6.00592 10.8734 6.01768C10.0876 6.04282 9.48047 6.69922 9.48047 7.48546V8.09622M14.4805 8.09622C13.6555 8.03247 12.8218 8 11.9805 8C11.1392 8 10.3054 8.03247 9.48047 8.09622"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
