import http from "./httpService";
import { apiUrl } from "../lib/config";
import { setFormData } from "components/util/serviceUtil";

export function getRequestCompany(subdomain) {
  return http.get(`${apiUrl}/request?subdomain=${subdomain}`);
}

export function saveOccupantRequest(data, customer_id) {
  http.setMultiPart();
  data.customer_id = customer_id;
  return http.post(`${apiUrl}/request`, setFormData(data));
}
