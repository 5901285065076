import React, { useState, useEffect } from "react";
import eosLogo from "../../assets/icons/encompass-logo.png";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import FormInput from "components/ui/FormContent/formInput";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import MessageDialog from "components/ui/dialog/messageDialog";
import { MinutesFromNowStandard } from "components/util/timeFormat";
import Button from "@material-ui/core/Button";
import PhotoIcon from "@material-ui/icons/Photo";
import Skeleton from "@material-ui/lab/Skeleton";
import { pick } from "lodash";

import {
  updateUserPassword,
  updateUserProfilePicture,
} from "services/superService";
import { getFileView } from "services/fileService";
import { useUserView } from "contexts/userViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const UserSidebar = () => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useUserView();
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({ password: "", password_confirmation: "" });
  const [error, setError] = useState("");
  const [profilePic, setProfilePic] = useState("");

  const { user, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadFileView = async () => {
      const resultFile = await getFileView(user.profile.picture.id);
      setProfilePic(resultFile.data.data.file.presigned_url);
    };
    if (user !== "new" && !isLoading && user?.profile?.picture) {
      if (MinutesFromNowStandard(user.profile?.picture.updated_at) < 5) {
        setProfilePic(user.profile.picture.presigned_url);
      } else {
        loadFileView();
      }
    }
  }, [user, isLoading]);

  const handleBlur = (event) => {
    try {
      data[event.name] = event.value;
      setData({ ...data });
      if (user === "new") {
        const password = pick(data, ["password", "password_confirmation"]);
        dispatchContext({
          type: "SET_PASSWORD",
          password: password,
        });
        if (data.password.length < 6) {
          setError("Password must be at least 5 characters long");
          setTimeout(() => {
            setError("");
          }, 2500);
          return;
        }
        if (
          data.password_confirmation !== "" &&
          data.password !== data.password_confirmation
        ) {
          setError("Password confirmation does not match");
          setTimeout(() => {
            setError("");
          }, 2500);
          return;
        }
      }
    } catch (e) {
      logException(e, "Cannot set new value to field");
    }
  };

  const handleChangePassword = async () => {
    setError("");
    if (data.password !== "") {
      if (data.password.length < 12) {
        setError("Password must be at least 12 characters long");
        setTimeout(() => {
          setError("");
        }, 2500);
        return;
      }
      if (data.password !== data.password_confirmation) {
        setError("Password confirmation does not match");
        setTimeout(() => {
          setError("");
        }, 2500);
        return;
      }

      try {
        await updateUserPassword(user.id, data);
        setData({ password: "", password_confirmation: "" });
        setOpenDialog(true);
      } catch (e) {
        logException(e, "Cannot change password.");
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeProfilePic = async (event) => {
    if (event.target.files[0]) {
      setProfilePic(URL.createObjectURL(event.target.files[0]));
      if (user !== "new") {
        try {
          const dataFile = { files: [...event.target.files] };
          await updateUserProfilePicture(user.id, dataFile);
        } catch (e) {
          console.log("Cannot upload profile picture", e);
        }
      } else {
        dispatchContext({
          type: "SET_PROFILE_PIC",
          profilePic: [...event.target.files],
        });
      }
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <Box className={classes.sidebarContainer}>
        <Box className={classes.sidebarContainerPhoto}>
          <Typography
            variant="h3"
            className={classes.titleContent}
            gutterBottom
          >
            <PhotoIcon /> Profile Picture
          </Typography>
          <Box className={classes.sidebarContainerProfilePhoto}>
            <input
              type="file"
              accept="image/*"
              className={classes.inputImage}
              id="icon-button-file"
              onChange={handleChangeProfilePic}
            />
            <label htmlFor="icon-button-file">
              <img
                src={profilePic ? profilePic : eosLogo}
                alt="profilePicture"
                className={classes.profileImage}
              />
              <Avatar className={classes.profileImageEdit}>
                <EditIcon className={classes.profileIconEdit} />
              </Avatar>
            </label>
          </Box>
        </Box>
        <Box className={classes.sidebarContainerPassword}>
          <Grid container spacing={4}>
            {user !== "new" && parseInt(user.validated) === 1 && (
              <Grid item md={12} xs={12}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="username"
                  label="Username"
                  value={user.email}
                  readonly={true}
                />
              </Grid>
            )}
            {Boolean(error) && (
              <Grid item md={12} xs={12}>
                <Box className={classes.passwordError}>{error}</Box>
              </Grid>
            )}
            {user !== "new" && parseInt(user.validated) === 1 && (
              <Grid item md={12} xs={12}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="password"
                  label="Password"
                  type="Password"
                  value={data.password}
                  handleBlur={handleBlur}
                />
              </Grid>
            )}
            {data.password !== "" &&
              user !== "new" &&
              parseInt(user.validated) === 1 && (
                <Grid item md={12} xs={12}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="password_confirmation"
                    label="Password Confirmation"
                    type="Password"
                    value={""}
                    handleBlur={handleBlur}
                  />
                </Grid>
              )}
          </Grid>
        </Box>
        {user !== "new" && parseInt(user.validated) === 1 && (
          <Box className={classes.boxActions}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonOutlined)}
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </Box>
        )}

        <MessageDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          title="Success"
          message={"Password has been changed."}
        />
      </Box>
    </>
  );
};

export default UserSidebar;
