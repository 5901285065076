import moment from "moment";
import React from "react";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import TimeIcon from "@material-ui/icons/AccessTime";

import useStyles from "./styles";

const FormInputTimeAdo = (props) => {
  const classes = useStyles();

  const {
    name,
    label,
    value,
    handleChange,
    disableHelperText,
    error,
    disabled,
    noMargin,
  } = props;

  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <Grid item xs={12} md={12}>
          {label ? (
            <InputLabel htmlFor={name} className={classes.inputLabelAdo}>
              {label}
            </InputLabel>
          ) : label === "" ? (
            <InputLabel htmlFor={name} className={classes.inputLabelAdoNoLabel}>
              {label}
            </InputLabel>
          ) : null}

          {disableHelperText ? (
            <KeyboardTimePicker
              helperText={
                errorInput && Boolean(errorInput.length)
                  ? errorInput[0].message
                  : ""
              }
              error={
                errorInput &&
                Boolean(errorInput.length) &&
                Boolean(errorInput[0].message)
              }
              margin="normal"
              id={name}
              value={value}
              onChange={(e) => handleChange(e)}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              className={classes.inputDate}
              InputProps={{
                className: noMargin
                  ? classes.inputDateFieldAdoNoMargin
                  : classes.inputDateFieldAdo,
                disableUnderline: true,
              }}
              InputAdornmentProps={{ className: classes.iconFormat }}
              fullWidth={true}
              minutesStep={5}
              keyboardIcon={<TimeIcon />}
              disabled={disabled}
            />
          ) : (
            <KeyboardTimePicker
              margin="normal"
              id={name}
              value={moment(value, moment.defaultFormat)}
              onChange={handleChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              className={classes.inputDate}
              InputProps={{
                className: classes.inputDateFieldAdo,
                disableUnderline: true,
              }}
              InputAdornmentProps={{ className: classes.iconFormat }}
              fullWidth={true}
              minutesStep={5}
              keyboardIcon={<TimeIcon />}
              disabled={disabled}
            />
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FormInputTimeAdo;
