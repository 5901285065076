import React, { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SurveyIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { logException } from "components/util/logUtil";
import {
  useSurveysTabState,
  useSurveysTabDispatch,
} from "contexts/surveysTabContext";
import { getSurveyLocations } from "services/surveyFrontlineService";
import { useSurveyFillDispatch, surveyStart } from "contexts/surveyFillContext";
import { hasPermission, permissionSurface } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

import useStyles from "./styles";

const SurveysTabLocationsDialog = (props) => {
  const classes = useStyles();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState(null);
  const [isExpress, setIsExpress] = useState(0);
  const { openLocations, surveysTabSelected } = useSurveysTabState();
  const dispatchSurveysTab = useSurveysTabDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const isSurface = hasPermission(
    permissionSurface.SURVEYS_TAB_LIST_SURFACE,
    permissions
  );

  const dispatchSurveyFill = useSurveyFillDispatch();

  useEffect(() => {
    const loadJobs = async () => {
      try {
        setIsLoadingDialog(true);
        const result = await getSurveyLocations(surveysTabSelected.id);
        const resultForRender = result.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
          };
        });
        setJobs(resultForRender);
        setJob(null);
        setIsLoadingDialog(false);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (surveysTabSelected?.id) {
      if (surveysTabSelected.type !== 3) {
        loadJobs();
      } else if (!surveysTabSelected.has_randomize) {
        handleConfirm();
      }
      setIsExpress(0);
    }
  }, [surveysTabSelected]);

  const handleClose = () => {
    dispatchSurveysTab({
      type: "TOGGLE_OPEN_LOCATIONS",
      openLocations: false,
    });
  };

  const handleChangeJob = async (event, value) => {
    if (value) {
      setJob(value);
    } else {
      setJob(null);
    }
  };

  const handleConfirm = async () => {
    try {
      setIsLoadingDialog(true);
      dispatchSurveyFill({
        type: "SET_CALLED_FROM",
        calledFrom: "surveysTab-surveys",
      });
      await surveyStart(
        dispatchSurveyFill,
        surveysTabSelected?.type === 3 ? -1 : job.id,
        surveysTabSelected.id,
        isExpress,
        surveysTabSelected.random_number
      );
      setIsLoadingDialog(false);
      handleClose();
    } catch (e) {
      console.log(`Cannot start survey: ${e}`);
    }
  };

  const changeExpress = (value) => {
    setIsExpress(value);
  };
  return (
    <>
      <Dialog
        open={openLocations}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <DialogContent className={classes.wrapperDialog}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.wrapperDialogClose}
              >
                <CloseIcon className={classes.iconDialogClose} />
              </IconButton>
              <Box className={classes.headerContainer}>
                <SurveyIcon className={classes.headerIcon} />
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleDialog}
                >
                  {surveysTabSelected?.type === 3
                    ? "Select Option"
                    : !isSurface
                    ? "Select Job"
                    : "Select Location"}
                </Typography>
              </Box>
              <Box className={classes.formContainer}>
                {surveysTabSelected?.type !== 3 ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs ?? []}
                    name="job_number"
                    label="Job"
                    handleChange={handleChangeJob}
                    value={job}
                  />
                ) : null}
                {surveysTabSelected?.has_randomize ? (
                  <Box className={classes.containerInput}>
                    <InputLabel className={classes.inputLabel}>
                      Express
                    </InputLabel>
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="primary button group"
                      className={classes.groupOption2}
                      fullWidth={true}
                    >
                      <Button
                        className={
                          isExpress === 1 ? classes.groupOptionActive : null
                        }
                        onClick={() => changeExpress(1)}
                      >
                        Yes
                      </Button>
                      <Button
                        className={
                          isExpress === 0 ? classes.groupOptionActive : null
                        }
                        onClick={() => changeExpress(0)}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </Box>
                ) : null}
              </Box>
            </DialogContent>
            <DialogActions className={classes.wrapperDialogAction2}>
              <Button
                onClick={handleConfirm}
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
                disabled={!Boolean(job) && surveysTabSelected?.type !== 3}
              >
                Start
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
export default SurveysTabLocationsDialog;
