import React from "react";
import { merge } from "lodash";

const defaultState = {
  isOpen: false,
  drawerContent: "",
  fromMainQuote: true,
};

const QuoteItemDrawerStateContext = React.createContext();
const QuoteItemDrawerDispatchContext = React.createContext();

function quoteItemDrawerReducer(state, action) {
  switch (action.type) {
    case "SET_IS_OPEN":
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case "SET_CONTENT":
      return {
        ...state,
        drawerContent: action.drawerContent,
      };
    case "SET_FROM_MAIN_QUOTE":
      return {
        ...state,
        fromMainQuote: action.fromMainQuote,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteItemDrawerProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(
    quoteItemDrawerReducer,
    mergedState
  );
  return (
    <QuoteItemDrawerStateContext.Provider value={state}>
      <QuoteItemDrawerDispatchContext.Provider value={dispatch}>
        {children}
      </QuoteItemDrawerDispatchContext.Provider>
    </QuoteItemDrawerStateContext.Provider>
  );
}

function useQuoteItemDrawerState() {
  const context = React.useContext(QuoteItemDrawerStateContext);
  if (context === undefined) {
    throw new Error(
      "useQuoteItemDrawerState must be used within a QuoteItemDrawerProvider"
    );
  }
  return context;
}

function useQuoteItemDrawerDispatch() {
  const context = React.useContext(QuoteItemDrawerDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useQuoteItemDrawerDispatch must be used within a QuoteItemDrawerProvider"
    );
  }
  return context;
}

export {
  QuoteItemDrawerProvider,
  useQuoteItemDrawerState,
  useQuoteItemDrawerDispatch,
};
