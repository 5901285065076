import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Typography from "@material-ui/core/Typography";
import { MiniChartBar } from "./icons/MiniChartBar";
import { logException } from "components/util/logUtil";
import { usePartnerState } from "contexts/partnerContext";
import { getPartnerProfileCompletion } from "services/partnerService";
import useStyles from "./styles";

const tabMapping = [
  { key: "contact_information", label: "General Information", index: 0 },
  {
    key: "geographical_information",
    label: "Geographical Information",
    index: 0,
  },
  { key: "trades", label: "Trades Offered", index: 1 },
  { key: "users", label: "User Management", index: 3 },
  { key: "questions", label: "Questions", index: 4 },
  { key: "compliance_files", label: "Compliance Files", index: 5 },
];

export default function LinearWithValueLabel({ onTabChange }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [missingParts, setMissingParts] = useState([]);
  const { partnerDetails } = usePartnerState();

  useEffect(() => {
    async function fetchProfileCompletion() {
      try {
        setIsLoading(true);
        const response = await getPartnerProfileCompletion(partnerDetails?.id);
        setProgress(response.data.data.completation_percentage);
        setMissingParts(response.data.data.missing_parts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        logException(error, "Error fetching profile completion");
      }
    }

    if (partnerDetails?.id) {
      fetchProfileCompletion();
    }
  }, [partnerDetails]);

  function getBarColorPrimary(value) {
    return value < 25
      ? classes.barColorPrimaryRed
      : value <= 90
      ? classes.barColorPrimaryYellow
      : classes.barColorPrimaryGreen;
  }

  const handleItemClick = (key) => {
    const tab = tabMapping.find((item) => item.key === key);
    if (tab) {
      onTabChange(null, tab.index);
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.zeroContainer}>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box className={classes.progressContainer}>
      {/* Progress title and icon */}
      <Box className={classes.profileCompletionTitleIconContainer}>
        <MiniChartBar className={classes.profileCompletionIcon} />
        <Typography className={classes.profileCompletionTitle}>
          Profile Completion Progress
        </Typography>
      </Box>

      {/* Progress bar and indicator */}
      <Box>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: getBarColorPrimary(progress),
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" className={classes.progressIndicator}>
              {`${Math.round(progress)}/100`}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Completion message */}
      <Typography className={classes.completeFieldsText}>
        {progress === 100 ? (
          <>
            <Typography variant="body1" className={classes.completeFieldsText}>
              You are almost there!
            </Typography>
            <Typography variant="body1" className={classes.completeFieldsText}>
              Complete these fields to reach 100%
            </Typography>
          </>
        ) : (
          "Complete these fields to reach 100%"
        )}
      </Typography>

      {/* List of missing parts */}
      <ul className={classes.profileList}>
        {missingParts.map((part) => {
          const tab = tabMapping.find((item) => item.key === part);
          return (
            <li
              key={part}
              onClick={() => handleItemClick(part)}
              className={classes.profileListItemText}
            >
              <Typography>{tab?.label}</Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
}
