import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomerBreadcrum from "./customerBreadcrum";
import CustomerInformation from "./customerInformation";
import NotesList from "./notesList/notesList";
import ActivityList from "./activityLIst/acitivityList";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import { getCustomerDetail } from "services/customerService";
import { useCustomerView } from "contexts/customerViewContext";
import { logException } from "components/util/logUtil";
import ContactsList from "./contactsList/contractsList";
import JobsList from "./adminJobsList/jobsList";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionCustomer, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const TabOptions = [
  { id: 0, text: "Activity", tab: "activity", permission: null },
  { id: 1, text: "General", tab: "general", permission: null },
  {
    id: 2,
    text: "Jobs",
    tab: "jobs",
    permission: permissionCustomer.VIEW_JOB,
  },
  {
    id: 3,
    text: "Contacts",
    tab: "contacts",
    permission: permissionCustomer.VIEW_CONTACT,
  },
  {
    id: 4,
    text: "Notes",
    tab: "notes",
    permission: permissionCustomer.NOTE_MANAGE,
  },
];

const CustomerLayout = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [tab, setTab] = useState(0);
  const context = useCustomerView();
  const [stateContext, dispatchContext] = context;
  const { customer, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        if (props.id !== "new") {
          const result = await getCustomerDetail(props.id);
          dispatchContext({
            type: "SET_CUSTOMER",
            customer: result.data.data.customer,
          });
          dispatchContext({
            type: "SET_FILES",
            files: result.data.data.customer?.files,
          });
        } else {
          dispatchContext({
            type: "SET_CUSTOMER",
            customer: "new",
          });
        }
        dispatchContext({
          type: "SET_LOADING",
          isLoading: false,
        });
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    loadWorkticket();
  }, [props.id, dispatchContext]);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <CustomerBreadcrum />
      {customer !== "new" ? (
        <StyledTabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          className={classes.rootTabs}
        >
          {TabOptions.filter(
            (item) =>
              !item.permission || hasPermission(item.permission, permissions)
          ).map((option) => {
            return (
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>{option.text}</Box>}
                value={option.id}
                key={option.id}
              />
            );
          })}
        </StyledTabs>
      ) : null}
      {tab === 0 && customer !== "new" ? <ActivityList /> : null}
      {tab === 1 || customer === "new" ? (
        <Box className={classes.root}>
          <Box className={classes.containerBody}>
            <CustomerInformation {...props} />
          </Box>
        </Box>
      ) : null}
      {tab === 2 && customer !== "new" ? <JobsList /> : null}
      {tab === 3 && customer !== "new" ? <ContactsList /> : null}
      {tab === 4 && customer !== "new" ? <NotesList /> : null}
    </>
  );
};

export default CustomerLayout;
