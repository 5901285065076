import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflowY: "hidden",
  },
  rootData: {
    maxWidth: "100%",
    margin: theme.spacing(2),
  },
  rootDataContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  rowHeader: {
    fontWeight: "bold",
    backgroundColor: "#ececec",
    borderRight: "1px solid #d4d4d4",
  },
  tableContainer: {
    height: "70vh",
  },
  tableContainerOne: {
    height: "30vh",
  },
  tableContainerTwo: {
    height: "40vh",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10,
  },
  containerMain: {
    backgroundColor: "#f8f8f8",
    height: "90vh",
    position: "relative",
    overflow: "hidden",
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    minWidth: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
    maxWidth: 440,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  contentTitle: {
    fontSize: 14,
  },
  containerOptions2: {
    marginTop: 30,
    marginBottom: 30,
  },
  inputLabel: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    marginBottom: 8,
  },
  groupOption2: {
    color: "#4f98bc",
    width: "100%",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  centerLoadingSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    marginTop: 30,
  },
}));

export default useStyles;
