import React from "react";
import moment from "moment";
import * as classNames from "classnames";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewButton from "components/common/Table/rowViewButton";
import IconButton from "@material-ui/core/IconButton";
import TableCollapsibleLoading from "./tableCollapsibleLoading";
import { timeFormat24toAMPM } from "components/util/timeFormat";
import { get } from "lodash";
import EmptyScreen from "components/ui/core/emptyScreen";
import useStyles from "./styles";

import {
  useTableCollapsibleState,
  useTableCollapsibleDispatch,
} from "contexts/tableCollapsibleContext";

const TableCollapsibleBody = (props) => {
  const classes = useStyles();
  const { localRows, level } = props;
  const dispatchTable = useTableCollapsibleDispatch();
  const { columns, rows, header, useEmpty, multipleOpen } =
    useTableCollapsibleState();

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const renderCell = (item, column) => {
    if (column.content && !column.format) return column.content(item);

    if (column.format === "date") {
      const dateField = get(item, column.id);
      return dateField && dateField !== "0000-00-00" ? (
        <Typography variant="body2" color="inherit" noWrap>
          {moment(dateField).format("MM/DD/YYYY")}
        </Typography>
      ) : (
        "-"
      );
    }

    if (column.format === "id") {
      return (
        <Box className={classes.viewActionWrap}>
          <Box>{get(item, column.id)}</Box>
          {Boolean(column.path) && (
            <Link
              className={classes.viewAction}
              to={{
                pathname: `/${
                  column.url
                    ? column.url
                    : column.path
                    ? column.path
                    : "project"
                }/${item.id}`,
              }}
            >
              <ViewButton />
            </Link>
          )}
        </Box>
      );
    }

    if (column.format === "action") {
      return (
        <Box className={classes.viewActionWrap}>
          <Box>{get(item, column.id)}</Box>
          <div className={classes.viewAction}>
            <ViewButton onClick={() => column.action(item)} />
          </div>
        </Box>
      );
    }

    if (column.format === "link") {
      return (
        <Link className={classes.linkFormat} to={column.path(item)}>
          {column.content ? column.content(item) : get(item, column.id)}
        </Link>
      );
    }

    if (column.format === "link_external") {
      return (
        <a
          className={classes.linkFormat}
          href={column.path(item)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {column.content ? column.content(item) : get(item, column.id)}
        </a>
      );
    }

    if (column.format === "time") {
      return (
        <Typography variant="body2" color="inherit" noWrap>
          {timeFormat24toAMPM(get(item, column.id))}
        </Typography>
      );
    }

    if (column.format === "main") {
      return (
        <Typography variant="body1" color="inherit" noWrap>
          {get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "main-bold") {
      return (
        <Typography
          variant="body1"
          color="inherit"
          noWrap
          className={classes.bold}
        >
          {get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "number") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {column.nDecimal
            ? parseFloat(get(item, column.id)).toFixed(column.nDecimal)
            : get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "percentage") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {`${formatNumber(parseFloat(get(item, column.id)).toFixed(2))} %`}
        </Typography>
      );
    }

    if (column.format === "money") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(2))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    if (column.format === "money-total") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(2))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.moneyTotal}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    if (column.format === "money_int") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(0))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    return get(item, column.id);
  };

  const handleExpander = async (row) => {
    if (row.isOpen) {
      row.isOpen = false;
      dispatchTable({ type: "UPDATE_ROWS", rows });
    } else {
      row.childRows = [
        {
          hasExpander: false,
          columnIndex: 0,
          _content: <TableCollapsibleLoading />,
        },
      ];
      if (
        (row?.singleOpen !== undefined && !row?.singleOpen) ||
        (multipleOpen && row?.singleOpen === undefined)
      ) {
        row.isOpen = true;
        dispatchTable({ type: "UPDATE_ROWS", rows });
        row.childRows = await row.getChildren(row, rows);
        dispatchTable({ type: "UPDATE_ROWS", rows });
      } else {
        rows.forEach((r) => {
          if (r.isOpen && r.columnIndex === row.columnIndex) {
            r.isOpen = false;
            r.childRows = [];
          }
        });
        row.isOpen = true;
        dispatchTable({ type: "UPDATE_ROWS", rows });
        row.childRows = await row.getChildren(row, rows);
        dispatchTable({ type: "UPDATE_ROWS", rows });
      }
    }
  };

  return (
    <>
      {localRows?.map((tableRow, tableRowIndex) => (
        <React.Fragment key={`f${tableRowIndex}`}>
          {tableRow._content ? (
            tableRow._content
          ) : (
            <TableRow
              key={tableRowIndex}
              style={tableRow.style ?? {}}
              className={classNames(classes.tablerow, `table-row-${level}`)}
            >
              {tableRow.hasExpander ? (
                <TableCell
                  style={
                    columns[tableRow.columnIndex][0].name === "_expander"
                      ? {
                          ...columns[tableRow.columnIndex][0].style,
                          paddingLeft: (level ?? 0) * 16,
                        }
                      : { paddingLeft: (level ?? 0) * 16 }
                  }
                  className={classes.tablecellExpander}
                >
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    className={classes.expanderButton}
                    onClick={() => handleExpander(tableRow)}
                  >
                    {tableRow.isOpen ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </IconButton>
                </TableCell>
              ) : !tableRow.notChilds ? (
                <TableCell
                  style={
                    columns[tableRow.columnIndex][0].name === "_expander"
                      ? {
                          ...columns[tableRow.columnIndex][0].style,
                          paddingLeft: (level ?? 0) * 16,
                        }
                      : { paddingLeft: (level ?? 0) * 16 }
                  }
                  className={classes.tablecellExpander}
                ></TableCell>
              ) : null}
              {columns[tableRow.columnIndex]
                .filter((c) => c.name !== "_expander")
                .map((column, colIndex) => (
                  <TableCell
                    className={classes.tablecell}
                    key={`r${tableRowIndex}c${colIndex}`}
                    style={column.style ?? {}}
                    colSpan={column.colSpan ?? 1}
                  >
                    {renderCell(tableRow, column)}
                  </TableCell>
                ))}
            </TableRow>
          )}
          {tableRow.isOpen ? (
            <TableCollapsibleBody
              localRows={tableRow.childRows}
              level={level + 1}
            />
          ) : null}
        </React.Fragment>
      ))}
      {!localRows.length ? (
        !useEmpty ? (
          <TableRow>
            <TableCell
              colSpan={header[0][0]?.colSpan ?? 1}
              style={{ textAlign: "right", position: "sticky", left: 0 }}
            >
              <Box>No Records Found</Box>
            </TableCell>
          </TableRow>
        ) : (
          <EmptyScreen
            detailText="There is nothing to show"
            detailText2="here."
            detailActionText="Please change period of time."
            type={3}
            icon={<SettingsIcon />}
          />
        )
      ) : null}
    </>
  );
};

export default TableCollapsibleBody;
