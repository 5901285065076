import React, { useContext, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { GeneralInformation } from "./GeneralInformation";
import { TradesOffering } from "./Trades/TradesOffering";
import LinearWithValueLabel from "./ProgressComponent";
import Metrics from "./Metrics";
import { Questions } from "./Questions/Questions";
import { UserManagement } from "./Users/UserManagement";
import { FilesPage } from "./Files/FilesPage";
import { LocationPage } from "./Locations/LocationPage";
import { AddQuestionModalProvider } from "./context/AddQuestionDialogContext";
import { UserDrawerProvider } from "./context/UserDrawerContext";
import { FilterDialogProvider } from "./context/FilterDialogContext";
import { FilterProvider } from "../../contexts/filterContext";
import { useTabId } from "./context/TabsContext";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionPartner } from "lib/permissions";
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      "@media (max-width: 768px)": {
        maxWidth: "100%",
      },
      width: "100%",
      backgroundColor: "#4F98BC",
    },
  },
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
    borderRight: "1px solid #e8e8e8",
    textAlign: "left",
    alignItems: "left",
    "&:hover": {
      color: "#4F98BC",
      opacity: 1,
    },
    "&$selected": {
      color: "#4F98BC",
      backgroundColor: "#EDF5F8",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4F98BC",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      fontWeight: 600,
    },
  },
  selected: {},
}))(Tab);

function TabPanel({ children, value, index, ...other }) {
  const classes = useStyles();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabsContainerRoot}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export default function CustomizedTabs({ id }) {
  const smallScreen = useMediaQuery("(max-width: 768px)");

  const classes = useStyles();
  const { tabId, setTabId } = useTabId();
  const [value, setValue] = useState(tabId);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabId(newValue);
  };

  const tabComponents = useMemo(
    () => [
      {
        label: "General Information",
        Component: GeneralInformation,
        permission: null,
      },
      {
        label: "Trades Offered",
        Component: () => (
          <FilterDialogProvider>
            <TradesOffering />
          </FilterDialogProvider>
        ),
        permission: null,
      },
      { label: "Metrics Summary", Component: Metrics, permission: null },
      {
        label: "User Management",
        Component: () => (
          <FilterProvider>
            <UserDrawerProvider>
              <UserManagement />
            </UserDrawerProvider>
          </FilterProvider>
        ),
        permission: null,
      },
      {
        label: "Questions",
        Component: () => (
          <AddQuestionModalProvider>
            <Questions />
          </AddQuestionModalProvider>
        ),
        permission: null,
      },
      { label: "Compliance Files", Component: FilesPage, permission: null },
      {
        label: "Location Management",
        Component: LocationPage,
        permission: permissionPartner.PARTNER_MANAGE,
      },
    ],
    []
  );

  return (
    <Box className={classes.tabsMainWrapper}>
      <Box className={classes.tabsWrapperRoot}>
        <Box className={classes.limitTabWrapperMobile}>
          <StyledTabs
            variant={smallScreen ? "scrollable" : "standard"}
            scrollButtons="auto"
            allowScrollButtonsMobile
            orientation={smallScreen ? "horizontal" : "vertical"}
            value={tabId ?? 0}
            onChange={handleChange}
            aria-label="styled tabs example"
            TabIndicatorProps={{ children: <span /> }}
          >
            {tabComponents
              .filter(
                (item) =>
                  item.permission === null ||
                  hasPermission(item.permission, permissions)
              )
              .map((tab, index) => (
                <StyledTab key={index} label={tab.label} />
              ))}
          </StyledTabs>
        </Box>
        <Box className={classes.progressContainer}>
          <LinearWithValueLabel onTabChange={handleChange} />
        </Box>
      </Box>
      {tabComponents
        .filter(
          (item) =>
            item.permission === null ||
            hasPermission(item.permission, permissions)
        )
        .map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            <tab.Component id={id} />
          </TabPanel>
        ))}
    </Box>
  );
}
