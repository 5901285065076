import http from "./httpService";
import { apiUrl } from "../lib/config";
import { setFormData } from "../components/util/serviceUtil";
const apiEndpoint = apiUrl + "/super";

export function getUsers() {
  return http.get(`${apiEndpoint}/users`);
}

export function getUsersJobs() {
  return http.get(`${apiEndpoint}/users/jobs`);
}

export function getUserDetail(id) {
  return http.get(`${apiEndpoint}/users/${id}`);
}

export function createUserDetail(data) {
  return http.post(`${apiEndpoint}/user`, data);
}

export function updateUserDetail(id, data) {
  return http.put(`${apiEndpoint}/users/${id}`, data);
}

export function updateUserPassword(id, data) {
  return http.post(`${apiEndpoint}/users/${id}/change-password`, data);
}

export function updateUserProfilePicture(id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/users/${id}/profile-picture`,
    setFormData(data)
  );
}

export function deleteUser(id) {
  return http.delete(`${apiEndpoint}/users/${id}`);
}

export function resendInvite(id) {
  return http.post(`${apiEndpoint}/users/${id}/resend-onboard`);
}

export function resetPassword(data) {
  return http.post(`${apiEndpoint}/users/reset-passwords`, data);
}

export function getRolesList() {
  return http.get(`${apiEndpoint}/roles`);
}

export function getPermissionsList() {
  return http.get(`${apiEndpoint}/permissions`);
}

export function getRoleDetail(id) {
  return http.get(`${apiEndpoint}/roles/${id}`);
}

export function updateRoleDetail(id, data) {
  return http.put(`${apiEndpoint}/roles/${id}`, data);
}

export function assignRolePermissions(role_id, data) {
  return http.post(`${apiEndpoint}/roles/${role_id}/assign-permissions`, data);
}

export function removeRolePermissions(role_id, data) {
  return http.post(`${apiEndpoint}/roles/${role_id}/remove-permissions`, data);
}

export function getJobs() {
  return http.get(`${apiEndpoint}/jobs`);
}

export function getJobDetail(id) {
  return http.get(`${apiEndpoint}/jobs/${id}`);
}

export function updateJobDetail(id, data) {
  return http.put(`${apiEndpoint}/jobs/${id}`, data);
}

export function updateJobPrimaryDirector(id, data) {
  return http.post(`${apiEndpoint}/jobs/${id}/set-director`, data);
}

export function updateJobPrimaryManager(id, data) {
  return http.post(`${apiEndpoint}/jobs/${id}/set-manager`, data);
}

export function removeJobPrimaryDirector(id) {
  return http.delete(`${apiEndpoint}/jobs/${id}/set-director`);
}

export function removeJobPrimaryManager(id) {
  return http.delete(`${apiEndpoint}/jobs/${id}/set-manager`);
}

export function saveJobFile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/jobs/${id}/upload-files`, setFormData(data));
}

export function saveJobDepartment(id, data) {
  return http.post(`${apiEndpoint}/jobs/${id}/department`, data);
}

export function updateJobDepartment(id, id_department, data) {
  return http.put(
    `${apiEndpoint}/jobs/${id}/departments/${id_department}`,
    data
  );
}

export function deleteJobDepartment(id, id_department) {
  return http.delete(`${apiEndpoint}/jobs/${id}/departments/${id_department}`);
}

export function saveJobZone(id, data) {
  return http.post(`${apiEndpoint}/jobs/${id}/zone`, data);
}

export function updateJobZone(id, id_zone, data) {
  return http.put(`${apiEndpoint}/jobs/${id}/zones/${id_zone}`, data);
}

export function deleteJobZone(id, id_zone) {
  return http.delete(`${apiEndpoint}/jobs/${id}/zones/${id_zone}`);
}

export function getCustomers() {
  return http.get(`${apiEndpoint}/customers`);
}

export function getStates() {
  return http.get(`${apiEndpoint}/states`);
}

export function getCustomerDetail(id) {
  return http.get(`${apiEndpoint}/customers/${id}`);
}

export function getCustomerOptions(id) {
  return http.get(`${apiEndpoint}/customers/${id}/edit`);
}

export function getCustomerJobs(id) {
  return http.get(`${apiEndpoint}/customers/jobs/${id}`);
}

export function assignCustomerJobs(id, data) {
  return http.post(`${apiEndpoint}/customers/${id}/assign-jobs`, data);
}

export function removeCustomerJobs(id, data) {
  return http.delete(`${apiEndpoint}/customers/${id}/assign-jobs`, {
    data: { ...data },
  });
}

export function getCustomerContact(id) {
  return http.get(`${apiEndpoint}/customers/${id}/contacts`);
}

export function createCustomerDetail(data) {
  return http.post(`${apiEndpoint}/customer`, data);
}

export function createCustomerContact(id_customer, data) {
  return http.post(`${apiEndpoint}/customers/${id_customer}/contact`, data);
}

export function deleteCustomerContact(id_customer, id_contact) {
  return http.delete(
    `${apiEndpoint}/customers/${id_customer}/contacts/${id_contact}`
  );
}

export function updateCustomerDetail(id, data) {
  return http.put(`${apiEndpoint}/customers/${id}`, data);
}

export function assignUserCustomer(data) {
  return http.post(`${apiEndpoint}/users/assign-customers`, data);
}

export function unassignUserCustomer(data) {
  return http.delete(`${apiEndpoint}/users/assign-customers`, {
    data: { ...data },
  });
}

export function saveCustomerFile(id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/customers/${id}/upload-files`,
    setFormData(data)
  );
}

export function assignUserJob(data) {
  return http.post(`${apiEndpoint}/users/assign-jobs`, data);
}

export function unassignUserJob(data) {
  return http.post(`${apiEndpoint}/users/remove-jobs`, data);
}

export function assignUserState(data) {
  return http.post(`${apiEndpoint}/users/assign-states`, data);
}

export function unassignUserState(data) {
  return http.post(`${apiEndpoint}/users/remove-states`, data);
}

export const getRoles = () => {
  return http.get(`${apiEndpoint}/roles/list`);
};

export const getLegacyRoles = () => {
  return http.get(`${apiEndpoint}/roles/list/legacy`);
};

export const addChildrenJob = (job, data) => {
  return http.post(`${apiEndpoint}/jobs/${job}/add-children`, data);
};

export const removeChildrenJob = (job, data) => {
  return http.post(`${apiEndpoint}/jobs/${job}/remove-children`, data);
};

export const getChildrenJob = (job) => {
  return http.get(`${apiEndpoint}/jobs/${job}/children`);
};
