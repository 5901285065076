import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import QuoteStatusChip from "../../components/ui/Quotes/QuoteSurfaceStatusChip";

import { useQuoteState } from "contexts/quoteSurfaceContext";

import useStyles from "./styles";

const QuoteSurfaceHeader = props => {
  const classes = useStyles();
  const stateContext = useQuoteState();
  const { quoteDetails, isLoading } = stateContext ? stateContext : null;

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <>
      <Typography variant="h3" className={classes.titleBody} gutterBottom>
        {quoteDetails.number}
        <Box className={classes.containerStatus}>
          <QuoteStatusChip status={quoteDetails.status} archived={0} />
        </Box>
      </Typography>
    </>
  );
};

export default QuoteSurfaceHeader;
