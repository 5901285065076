import React from "react";
import SurveyContent from "pages/survey/surveyContent";
import SurveyFrontlineSubmitPage from "./surveysFrontlineSubmitPage";
import { SurveyFillProvider } from "contexts/surveyFillContext";

const SurveysFrontlineSubmit = (props) => {
  return (
    <SurveyFillProvider>
      <SurveyFrontlineSubmitPage params={{ ...props.match.params }} />
      <SurveyContent />
    </SurveyFillProvider>
  );
};

export default SurveysFrontlineSubmit;
