import React from "react";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const FormTitle = props => {
  const classes = useStyles();
  return (
    <Typography variant="h4" gutterBottom className={classes.title}>
      {props.children}
    </Typography>
  );
};

export default FormTitle;
