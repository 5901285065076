import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import CompletedIcon from "@material-ui/icons/CheckCircle";
import MenuIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Recurrence from "components/common/Recurrence";
import Skeleton from "@material-ui/lab/Skeleton";
import RecurrenceIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import QuoteStatusChip from "components/ui/Quotes/QuoteStatusChip";
import ConfirmDialog from "./dialog/confirmDialog";
import { Link } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import * as serviceQuote from "services/quoteService";
import { withRouter } from "react-router-dom";

import {
  useQuoteState,
  useQuoteDispatch,
  updateQuote,
} from "contexts/quoteContext";
import { convertDateFormatField } from "components/util/timeFormat";
import { permissionQuote, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";
import MessageDialog from "./dialog/messageDialog";
import ErrorMessageDialog from "./dialog/errorMessageDialog";
import Tooltip from "@material-ui/core/Tooltip";

import useStyles from "./styles";

const QuoteHeader = (props) => {
  const classes = useStyles();
  const stateContext = useQuoteState();
  const { quoteDetails, isLoading, selectedQuote } = stateContext
    ? stateContext
    : null;
  const [openDate, setOpenDate] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { pendingPlanning, cloned } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const dispatch = useQuoteDispatch();
  const hasDueDate = hasPermission(permissionQuote.DUE_DATE, permissions);
  const hasRecurence = hasPermission(permissionQuote.RECURRENCE, permissions);
  const [openRecurrence, setOpenRecurrence] = useState(false);
  const [generator, setGenerator] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoadingDeleteGenerator, setIsLoadingDeleteGenerator] =
    useState(false);
  const [isLoadingChangeGenerator, setIsLoadingChangeGenerator] =
    useState(false);
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const hasApproval = hasPermission(permissionQuote.APPROVAL, permissions);
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();
  const [openMessage, setOpenMessage] = React.useState(false);
  const [openMessageError, setOpenMessageError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [openApprove, setOpenApprove] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [openFinalize, setOpenFinalize] = useState(false);
  const [isLoadingFinalize, setIsLoadingFinalize] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (quoteDetails.generator) setGenerator(quoteDetails.generator[0]);
      if (quoteDetails.type === 3) {
        const itemsSel = quoteDetails.quote_items
          .filter((item) => !["task", "good"].includes(item.item_type))
          .map((item) => {
            return {
              value: item.id,
              label: item.item_subject,
            };
          });
        setItems(itemsSel);
      } else {
        setItems([]);
      }
    }
  }, [isLoading, quoteDetails, selectedQuote]);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  const changeDate = (date) => {
    quoteDetails.due_date = moment(date, moment.defaultFormat).format(
      "YYYY-MM-DD"
    );
    dispatch({
      type: "SET_QUOTE_DETAILS",
      quoteDetails,
    });
    updateQuote(dispatch, selectedQuote, quoteDetails);
    setOpenDate(false);
  };

  const handleRecurrence = () => {
    setOpenRecurrence(!openRecurrence);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRecurrenceSubmit = async (id, data) => {
    setIsLoadingChangeGenerator(true);
    if (!id) {
      try {
        const resultGenerator = await serviceQuote.quoteGenerator({
          ...data,
          type: quoteDetails.type === 2 ? 1 : 2,
          source_ids:
            quoteDetails.type === 2 ? [selectedQuote] : data.quoteItems,
        });
        if (quoteDetails.type === 2)
          setGenerator(resultGenerator.data.data.generators[0]);
        else setGenerator(null);
        if (quoteDetails.status === 5 && quoteDetails.pending_planning === 0) {
          if (quoteDetails.type === 2) {
            await serviceQuote.initializeQuoteGenerator({
              generator_ids: [resultGenerator.data.data.generators[0].id],
            });
          } else {
            const generators = resultGenerator.data.data.generators.map(
              (item) => {
                return item.id;
              }
            );
            await serviceQuote.initializeQuoteGenerator({
              generator_ids: generators,
            });
          }
        }
      } catch (e) {
        console.log("Cannot create generator", e);
      }
    } else {
      await serviceQuote.updateQuoteGenerator(id, {
        ...data,
      });
    }
    setIsLoadingChangeGenerator(false);
    updateQuote(dispatch, selectedQuote, {}, true, true);
  };

  const handleGeneratorDelete = async () => {
    try {
      setIsLoadingDeleteGenerator(true);
      await serviceQuote.deleteQuoteGenerator(generator.id);
      setGenerator(null);
      updateQuote(dispatch, selectedQuote, {}, true, true);
      setIsLoadingDeleteGenerator(false);
      setOpenDelete(false);
    } catch (e) {
      console.log("Cannot delete generator");
    }
  };

  const openDeleteDialog = () => {
    setOpenDelete(true);
  };

  const handleSaveDraft = () => {
    const { history } = props;
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [
        ...pageHeaderTabs.filter(
          (page) => page.link !== window.location.pathname
        ),
      ],
    });
    history.push(`/quotes`);
  };

  const handleSaveProgress = () => {
    const { history } = props;
    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [
        ...pageHeaderTabs.filter(
          (page) => page.link !== window.location.pathname
        ),
      ],
    });
    history.push(`/quote/${quoteDetails.id}`);
  };

  const handleComplete = async () => {
    if (
      quoteDetails.quote_items.filter((item) =>
        ["service", "unit", "bulk"].includes(item.item_type)
      ).length > 0 ||
      quoteDetails.type === 1
    ) {
      if (
        ([2].includes(quoteDetails.type) &&
          quoteDetails.generator.length > 0) ||
        ([3].includes(quoteDetails.type) &&
          quoteDetails.quote_items.some((item) => item.generator.length > 0)) ||
        [0, 1, 5].includes(quoteDetails.type)
      ) {
        if (
          quoteDetails.quote_items.filter((item) =>
            ["service", "unit", "bulk", "good", "task"].includes(item.item_type)
          ).length === 0
        ) {
          setOpenMessageError(true);
          setMessage(
            "Please add a service, custom item or good item to complete this quote."
          );
        } else if (
          quoteDetails.quote_items.filter((item) =>
            ["service", "unit", "good", "bulk"].includes(item.item_type)
          ).length === 0 &&
          quoteDetails.quote_items.filter((item) =>
            ["task"].includes(item.item_type)
          ).length > 0 &&
          quoteDetails.type === 1
        ) {
          setOpenMessageError(true);
          setMessage(
            "Please add a service, custom item or good item to complete this quote."
          );
        } else {
          const data = {
            quotes_ids: [quoteDetails.id],
            status: 6,
          };
          try {
            await serviceQuote.updateQuotesBulk(data);
            quoteDetails["status"] = 6;
            dispatch({
              type: "SET_QUOTE_DETAILS",
              quoteDetails,
            });
            setOpenMessage(true);
            setMessage("Quote creation has been Completed!");
            if (cloned) {
              const { history } = props;
              dispatchPageHeader({
                type: "SET_PAGE_TAB",
                pageHeaderTabs: [
                  ...pageHeaderTabs.filter(
                    (page) => page.link !== window.location.pathname
                  ),
                ],
              });
              history.push(`/quote/${quoteDetails.id}`);
            }
          } catch (e) {
            console.log("Cannot complete quote", e);
          }
        }
      } else {
        setOpenMessageError(true);
        setMessage("Please set up a Recurrence for this Quote.");
      }
    } else {
      setOpenMessageError(true);
      setMessage("Please add a service or custom item to complete this quote.");
    }
  };

  const closeReject = () => {
    setOpenReject(false);
  };

  const handleOpenReject = () => {
    setOpenReject(true);
  };

  const closeSend = () => {
    setOpenSend(false);
  };

  const handleOpenSend = () => {
    setOpenSend(true);
  };

  const handleApprove = async () => {
    let failure = false;
    try {
      setOpenApprove(false);
      if (
        quoteDetails.quote_items.filter((item) =>
          ["service", "unit", "bulk"].includes(item.item_type)
        ).length > 0 ||
        quoteDetails.type === 1
      ) {
        if (
          ([2].includes(quoteDetails.type) &&
            quoteDetails.generator.length > 0) ||
          ([3].includes(quoteDetails.type) &&
            quoteDetails.quote_items.some(
              (item) => item.generator.length > 0
            )) ||
          [0, 1, 5].includes(quoteDetails.type)
        ) {
          if (
            quoteDetails.quote_items.filter((item) =>
              ["service", "unit", "good", "task", "bulk"].includes(
                item.item_type
              )
            ).length === 0
          ) {
            setOpenMessageError(true);
            setMessage(
              "Please add a service, custom item or good item to complete this quote."
            );
          } else if (
            quoteDetails.quote_items.filter((item) =>
              ["service", "unit", "good", "bulk"].includes(item.item_type)
            ).length === 0 &&
            quoteDetails.quote_items.filter((item) =>
              ["task"].includes(item.item_type)
            ).length > 0 &&
            quoteDetails.type === 1
          ) {
            setOpenMessageError(true);
            setMessage(
              "Please add a service, custom item or good item to complete this quote."
            );
          } else if (
            quoteDetails.quote_items.filter((item) =>
              ["service", "unit", "bulk"].includes(item.item_type)
            ).length === 0 &&
            quoteDetails.type === 1
          ) {
            await serviceQuote.approveQuoteTicketLess(quoteDetails.id);
          } else {
            const response = await serviceQuote.approveQuote(quoteDetails.id);
            if (response.data.message) {
              failure = true;
              setMessage(response.data.message);
              setOpenMessageError(true);
            } else {
              quoteDetails["pending_planning"] = 1;
            }
          }
          if (!failure) {
            quoteDetails["status"] = 5;
            dispatch({
              type: "SET_QUOTE_DETAILS",
              quoteDetails,
            });
            setMessage("Quote has been Approved!");
            setOpenMessage(true);
          }
        } else {
          setOpenMessageError(true);
          setMessage("Please set up a Recurrence for this Quote.");
        }
      } else {
        setOpenMessageError(true);
        setMessage(
          "Please add a service or custom item to complete this quote."
        );
      }
    } catch (e) {
      console.log("Cannot approve quote");
      console.log(e);
    }
  };

  const closeApprove = () => {
    setOpenApprove(false);
  };

  const handleReject = async () => {
    try {
      setOpenReject(false);
      const rejectStatus = 10;
      const data = {
        quotes_ids: [quoteDetails.id],
        status: rejectStatus,
      };
      await serviceQuote.updateQuotesBulk(data);
      quoteDetails["status"] = 10;
      dispatch({
        type: "SET_QUOTE_DETAILS",
        quoteDetails,
      });
      setOpenMessage(true);
      setMessage("Quote has been Rejected!");
    } catch (e) {
      console.log("Cannot reject quote");
      console.log(e);
    }
  };

  const handleMarkSend = async () => {
    try {
      setOpenSend(false);
      const sendStatus = 1;
      const data = {
        quotes_ids: [quoteDetails.id],
        status: sendStatus,
      };
      await serviceQuote.updateQuotesBulk(data);
      quoteDetails["status"] = 1;
      dispatch({
        type: "SET_QUOTE_DETAILS",
        quoteDetails,
      });
      setOpenMessage(true);
      setMessage("Quote has been Marked as Sent!");
    } catch (e) {
      console.log("Cannot mark as sent quote");
      console.log(e);
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleOpenFinalize = () => {
    setOpenFinalize(true);
  };

  const closeFinalize = () => {
    setOpenFinalize(false);
  };

  const handleFinalize = async () => {
    try {
      setIsLoadingFinalize(true);
      let result = [];
      result = await serviceQuote.createWTQuote(quoteDetails.id);
      quoteDetails["pending_planning"] = 0;
      dispatch({
        type: "SET_QUOTE_DETAILS",
        quoteDetails,
      });
      dispatch({
        type: "SET_WORKTICKETS",
        worktickets: result.data.data.quote.worktickets,
      });
      const { history } = props;
      dispatchPageHeader({
        type: "SET_PAGE_TAB",
        pageHeaderTabs: [
          ...pageHeaderTabs.filter(
            (page) => page.link !== window.location.pathname
          ),
        ],
      });
      history.push(`/quote/${quoteDetails.id}`);
    } catch (e) {
      setIsLoadingFinalize(false);
      console.log("Cannot create worktickets");
      console.log(e);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={openApprove}
        handleConfirm={handleApprove}
        handleClose={closeApprove}
        message={"Are you sure you want to approve this quote?"}
        quoteId={quoteDetails.number}
      />
      <ConfirmDialog
        open={openReject}
        handleConfirm={handleReject}
        handleClose={closeReject}
        message={"Are you sure you want to reject this quote?"}
        quoteId={quoteDetails.number}
      />
      <ConfirmDialog
        open={openSend}
        handleConfirm={handleMarkSend}
        handleClose={closeSend}
        message={"Are you sure you want to mark as sent this quote?"}
        quoteId={quoteDetails.number}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={message}
      />
      <ErrorMessageDialog
        title={""}
        open={openMessageError}
        handleClose={closeMessageError}
        message={message}
      />
      <Box className={classes.headerInformationContainer}>
        <Box className={classes.containerInformationStatusActions}>
          <Typography variant="h3" className={classes.titleBodyHeader}>
            {quoteDetails.number}
          </Typography>
          <Box className={classes.containerStatus}>
            <QuoteStatusChip
              status={quoteDetails.status}
              archived={0}
              completed_date={quoteDetails.completed_date}
            />
            <Chip
              label={`Due: ${convertDateFormatField(
                quoteDetails.due_date
                  ? quoteDetails.due_date
                  : quoteDetails.start_date
              )}`}
              className={classNames(classes.chipPrimary)}
            />
            {hasDueDate && (
              <>
                <IconButton
                  onClick={(event) => {
                    setOpenDate(true);
                  }}
                  className={classes.menuDueDateIcon}
                >
                  <MenuIcon />
                </IconButton>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    style={{ width: "1px", height: "1px" }}
                    disableToolbar
                    open={openDate}
                    onOpen={() => setOpenDate(true)}
                    onClose={() => setOpenDate(false)}
                    autoOk={true}
                    variant="inline"
                    format="MM/DD/YYYY"
                    margin="normal"
                    id="due_date"
                    value={moment(
                      quoteDetails.due_date
                        ? quoteDetails.due_date
                        : quoteDetails.start_date,
                      moment.defaultFormat
                    )}
                    onChange={changeDate}
                    onClick={() => {}}
                    fullWidth={true}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
            {quoteDetails.pending_planning === 1 && (
              <Chip
                label={`Pending WT Creation`}
                className={classNames(classes.chipPlanning)}
              />
            )}
          </Box>
        </Box>
        {[0].includes(quoteDetails.status) && hasEdit && (
          <Box className={classes.containerActions}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              className={classNames(
                classes.button,
                classes.buttonOutlined,
                classes.buttonMargin
              )}
              onClick={handleSaveDraft}
              disabled={quoteDetails.is_old_structure}
            >
              Save Draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disableElevation
              onClick={handleComplete}
              className={classNames(classes.button, classes.buttonAdd)}
              disabled={quoteDetails.is_old_structure}
            >
              Complete Quote
            </Button>
          </Box>
        )}
        {!pendingPlanning &&
          hasRecurence &&
          !quoteDetails.is_old_structure &&
          (quoteDetails.type === 2 ||
            (quoteDetails.type === 3 &&
              quoteDetails.quote_items.filter(
                (itemQ) => !["task", "good"].includes(itemQ.item_type)
              ).length > 0)) && (
            <Box className={classes.containerRecurrence}>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                disableElevation
                startIcon={<RecurrenceIcon />}
                className={classNames(
                  classes.button,
                  generator
                    ? classes.buttonOutlinedSuccess
                    : classes.buttonOutlined
                )}
                onClick={handleRecurrence}
              >
                {generator ? `Recurrence` : `Create Recurrence`}
              </Button>
              <ConfirmDialog
                open={isLoadingChangeGenerator}
                handleConfirm={() => {}}
                isLoading={isLoadingChangeGenerator}
                handleClose={() => {}}
                message={""}
                quoteId={quoteDetails.number}
              />
              <ConfirmDialog
                open={openDelete}
                handleConfirm={handleGeneratorDelete}
                isLoading={isLoadingDeleteGenerator}
                handleClose={handleCloseDelete}
                message={
                  "Deleting this will stop the auto generation of work tickets moving forward and existing tickets which have not been “started” yet are going to be deleted. Do you want to continue?"
                }
                quoteId={quoteDetails.number}
              />
              <Recurrence
                open={openRecurrence}
                mode={"workticket"}
                subTitle={quoteDetails.number}
                data={generator}
                items={items}
                quoteType={quoteDetails.type}
                handleClose={handleRecurrence}
                handleSubmit={handleRecurrenceSubmit}
                handleGeneratorDelete={openDeleteDialog}
                message={
                  "This will edit the recurrence for all associated work tickets that have not already been “started”. Do you want to continue?"
                }
                confirm={true}
              />
            </Box>
          )}
        {!props.pendingPlanning &&
          [1, 5, 6].includes(quoteDetails.status) &&
          hasEdit && (
            <Box className={classes.containerActions}>
              {quoteDetails.status === 5 &&
                quoteDetails.pending_planning === 1 && (
                  <Link
                    to={`/quote/${quoteDetails.id}/wtPlanning`}
                    className={classNames(
                      classes.buttonLink2,
                      classes.buttonPrimary2
                    )}
                    replace={true}
                  >
                    Create Worktickets
                  </Link>
                )}
              {[1, 6].includes(quoteDetails.status) && hasApproval && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  fullWidth={true}
                  className={classNames(
                    classes.button,
                    classes.buttonOutlined,
                    classes.buttonMargin
                  )}
                  onClick={handleOpenReject}
                >
                  Reject
                </Button>
              )}
              {[6].includes(quoteDetails.status) && hasApproval ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleOpenSend}
                  className={classNames(
                    classes.button,
                    classes.buttonOutlined,
                    classes.buttonMargin
                  )}
                  disabled={quoteDetails.is_old_structure}
                >
                  Send
                </Button>
              ) : null}

              {[1, 6].includes(quoteDetails.status) && hasApproval && (
                <>
                  {quoteDetails.project?.has_completed_worktickets ? (
                    <Tooltip title="You cannot approve a quote for a project where there is already a quote with completed worktickets">
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        disableElevation
                        disabled
                        fullWidth={true}
                        className={classNames(
                          classes.button,
                          classes.buttonAdd
                        )}
                        onClick={handleOpenApprove}
                        disabled={quoteDetails.is_old_structure}
                      >
                        Approve
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disableElevation
                      fullWidth={true}
                      className={classNames(classes.button, classes.buttonAdd)}
                      onClick={handleOpenApprove}
                      disabled={quoteDetails.is_old_structure}
                    >
                      Approve
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
        {props.pendingPlanning ? (
          <Box className={classes.containerActions}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classNames(
                classes.button,
                classes.buttonOutlined,
                classes.buttonMargin
              )}
              onClick={handleSaveProgress}
            >
              {quoteDetails.pending_planning === 1 ? "Save Progress" : "Close"}
            </Button>
            {quoteDetails.pending_planning === 1 ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonAdd2)}
                onClick={handleOpenFinalize}
                disabled={quoteDetails.is_old_structure}
              >
                Create Worktickets
              </Button>
            ) : (
              ""
            )}
            <ConfirmDialog
              open={openFinalize}
              handleConfirm={handleFinalize}
              isLoading={isLoadingFinalize}
              handleClose={closeFinalize}
              message={
                "Are you sure you want to create the worktickets for this quote?"
              }
              quoteId={quoteDetails.number}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default withRouter(QuoteHeader);
