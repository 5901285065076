import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { partnerStatus } from "constants.js";
import useStyles from "./styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { permissionPartner, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

const statusColors = {
  Onboarding: {
    background: "#EDF5F8",
    textColor: "#4F98BC",
  },
  Active: {
    background: "#E8F8CB",
    textColor: "#328914",
  },
  Potential: {
    background: "#FFEAD5",
    textColor: "#DB6F00",
  },
  Inactive: {
    background: "#F07A8C",
    textColor: "#FFF",
  },
};

const CustomArrowDropDownIcon = ({ onClick }) => (
  <ArrowDropDownIcon style={{ fontSize: 18 }} onClick={onClick} />
);

const inactiveStatusId = 4;

const PartnerStatus = ({ partnerSelected, onChange, readOnly = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const statusId = parseInt(partnerSelected?.status, 10);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const currentStatus = partnerStatus.find(
    (status) => status.value === statusId
  );
  const currentStatusLabel = currentStatus ? currentStatus.label : "";
  const currentStatusColor = statusColors[currentStatusLabel] || {};

  const hasInactivatePermission = hasPermission(
    permissionPartner.PARTNER_INACTIVATE,
    permissions
  );

  const hasOwnerPermission = hasPermission(
    permissionPartner.PARTNER_OWNER,
    permissions
  );

  const filteredStatusOptions = partnerStatus.filter((option) => {
    if (option.value === inactiveStatusId && !hasInactivatePermission) {
      return false;
    }
    return option.value !== statusId;
  });

  const handleChange = (event) => {
    const newStatusId = event.target.value;

    const updatedPartnerSelected = {
      ...partnerSelected,
      previousStatus: partnerSelected.status,
      status: newStatusId,
    };

    onChange(updatedPartnerSelected);
    setOpen(false);
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Select
      fullWidth
      disableUnderline
      value={statusId}
      open={open}
      disabled={readOnly}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
      IconComponent={() =>
        !hasOwnerPermission ? (
          <CustomArrowDropDownIcon
            onClick={handleIconClick}
            style={{
              color: currentStatusColor.textColor,
            }}
          />
        ) : null
      }
      style={{
        backgroundColor: currentStatusColor.background,
        color: currentStatusColor.textColor,
      }}
      className={classes.selectStatus}
      renderValue={(selected) => {
        const selectedStatus = partnerStatus.find(
          (status) => status.value === parseInt(selected, 10)
        );
        return selectedStatus ? (
          <Typography variant="caption">{selectedStatus.label}</Typography>
        ) : null;
      }}
    >
      {!hasOwnerPermission &&
        filteredStatusOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ fontSize: 14 }}
          >
            {option.label}
          </MenuItem>
        ))}
    </Select>
  );
};

PartnerStatus.propTypes = {
  onChange: PropTypes.func.isRequired,
  partnerSelected: PropTypes.object.isRequired,
};

export default PartnerStatus;
