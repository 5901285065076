import React, { lazy, Suspense, useContext } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import {
  permissionDashboard,
  permissionSurface,
  hasPermission,
} from "lib/permissions";

const KpiTab = lazy(() => import("./kpiTab"));
const KpiTabSurface = lazy(() => import("./kpiTabSurface"));

const KpiTabPage = (props) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <Suspense fallback={"Loading..."}>
      {hasPermission(permissionSurface.KPI, permissions) ? (
        <KpiTabSurface />
      ) : null}
      {hasPermission(permissionDashboard.KPI, permissions) ||
      hasPermission(permissionDashboard.KPI_PARTNER, permissions) ? (
        <KpiTab />
      ) : null}
    </Suspense>
  );
};

export default KpiTabPage;
