import React, { useState, useEffect } from "react";
import moment from "moment";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormTitle from "components/ui/FormContent/formTitle";
import FormContent from "components/ui/FormContent/formBody";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDate from "components/ui/FormContent/formInputDate";
import StepNav from "./stepNav";
import NextIcon from "@material-ui/icons/ArrowForward";
import BackIcon from "@material-ui/icons/ArrowBack";

import { useStateValue } from "components/util/state";

import useStyles from "./styles";

const initialFormState = {
  due_date: moment().add(30, "days").format("YYYY-MM-DD HH:mm:ss"),
  due_notes: "",
};

const RequestStep1 = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialFormState);
  const [{ completed, add, step, list }, dispatch] = useStateValue();

  useEffect(() => {
    if (completed || add) {
      setFormState(initialFormState);
    }
  }, [completed, add]);

  const handleOnClick = () => {
    dispatch({
      type: "UPDATE_DATA",
      updateData: formState,
      newStep: 4,
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 2,
    });
  };

  const handleBlur = (event, type) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  const validateOnType = (event) => {
    setFormState((formState) => {
      return { ...formState, [event.name]: event.value };
    });
  };

  if (step !== 3) return null;
  return (
    <Box className={classes.stepContainer}>
      <StepNav step={step} list={list} />
      <FormTitle>When do you need this done?</FormTitle>
      <FormContent>
        Need this completed by a certain date? No problem - just let us know
        when you need it done and why and we will do our best to accommodate
        your request.
      </FormContent>
      <Box className={classes.formContainer}>
        <FormInputDate
          gridSizes={[{ size: "md", val: 12 }]}
          name="due_date"
          label="Requested By"
          value={formState.due_date}
          handleBlur={handleBlur}
        />
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="due_notes"
          label="Notes"
          multiline={true}
          rows={5}
          value={formState.due_notes}
          validateOnType={validateOnType}
        />
      </Box>
      <Box className={classes.actionBox}>
        <Button
          color="primary"
          className={classes.buttonBack}
          onClick={handleOnClickBack}
          startIcon={<BackIcon />}
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.buttonNext}
          onClick={handleOnClick}
          endIcon={<NextIcon />}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default RequestStep1;
