import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useListDispatch } from "contexts/listContext";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionCustomer, hasPermission } from "lib/permissions";

const options = [
  {
    id: 1,
    label: "View",
    value: "VIEW",
    permission: permissionCustomer.VIEW_CONTACT,
  },
  {
    id: 2,
    label: "Edit",
    value: "EDIT",
    permission: permissionCustomer.CREATE_EDIT_CONTACT,
  },
  {
    id: 3,
    label: "Delete",
    value: "DELETE",
    permission: permissionCustomer.DELETE_CONTACT,
  },
];

const ContactsTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchList = useListDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    dispatchList({
      type: "SET_SELECTED",
      selected: row,
    });
    dispatchList({
      type: "SET_SELECTED_ACTION",
      selectedAction: action,
    });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter((option) => hasPermission(option.permission, permissions))
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default withRouter(ContactsTableActions);
