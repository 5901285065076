import React from "react";
import { getJobs } from "services/mapService";
import { getCustomersFilter } from "services/customerService";
import {
  getWorkListAllSlider,
  getAssetListAllSlider,
} from "services/mapService";
import { merge } from "lodash";

const defaultState = {
  mapLocationLoading: true,
  mapLocationList: [],
  mapCustomerList: [],
  dasboardPropsData: {
    checked: true,
    expanded: false,
    currentSelectedTab: 0,
    closeButtonForMobile: false,
  },
};

const DashboardStateContext = React.createContext();
const DashboardDispatchContext = React.createContext();

function DashboardReducer(state, action) {
  switch (action.type) {
    case "DASHBOARD_PROPS_DATA": {
      return {
        ...state,
        dasboardPropsData: {
          checked: true,
          expanded: false,
          currentSelectedTab: 0,
          closeButtonForMobile: false,
          ...state.dasboardPropsData,
          ...action.dasboardPropsData,
        },
      };
    }
    case "SET_DASHBOARD_LIST_LOADING":
      return {
        ...state,
        mapLocationLoading: action.dashboardListLoading,
      };
    case "SET_SLIDER_WORK_LIST_LOADING":
      return {
        ...state,
        sliderWorkListLoading: action.sliderWorkListLoading,
      };
    case "SET_SLIDER_ASSET_LIST_LOADING":
      return {
        ...state,
        assetListLoading: action.assetListLoading,
      };
    case "SET_DASHBOARD_LOCATION_LIST":
      return {
        ...state,
        mapLocationList: action.dashboardList?.data,
      };
    case "SET_DASHBOARD_CUSTOMER_LIST":
      return {
        ...state,
        mapCustomerList: action.customerList?.data,
      };
    case "SET_SLIDER_WORK_LIST":
      return {
        ...state,
        sliderWorkList: action.sliderWorkList,
      };
    case "SET_SLIDER_ASSET_LIST":
      return {
        ...state,
        sliderAssetList: {
          data: {
            assets: [...action.assetList.data.assets],
          },
        },
      };
    case "UPDATE_SLIDER_ASSET_LIST": {
      return {
        ...state,
        sliderAssetList: {
          data: {
            assets: [
              ...state.sliderAssetList.data.assets,
              ...action.assetList.data.assets,
            ],
          },
        },
      };
    }
    default: {
      return state;
    }
  }
}

function DashboardProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(DashboardReducer, mergedState);
  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  );
}

function useDashboardState() {
  const context = React.useContext(DashboardStateContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardState must be used within a DashboardProvider"
    );
  }
  return context;
}

function useDashboardDispatch() {
  const context = React.useContext(DashboardDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardDispatch must be used within a DashboardProvider"
    );
  }
  return context;
}

function setDashboardPropsData(dispatch, dasboardPropsDataNewKeyValueObject) {
  try {
    dispatch({
      type: "DASHBOARD_PROPS_DATA",
      dasboardPropsData: dasboardPropsDataNewKeyValueObject,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getDashboardLocationList(dispatch, isSurfaceUser) {
  try {
    const dashboardListResult = await getJobs();
    const dashboardFilterCustomer = await getCustomersFilter();
    dispatch({
      type: "SET_DASHBOARD_LOCATION_LIST",
      dashboardList: dashboardListResult,
    });
    dispatch({
      type: "SET_DASHBOARD_CUSTOMER_LIST",
      customerList: dashboardFilterCustomer,
    });
    dispatch({
      type: "SET_DASHBOARD_LIST_LOADING",
      dashboardListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getAllSliderWorkList(
  dispatch,
  jobId,
  isSurfaceUser,
  dashboardState
) {
  try {
    dispatch({
      type: "SET_SLIDER_WORK_LIST_LOADING",
      sliderWorkListLoading: true,
    });
    const sliderWorkListResult = await getWorkListAllSlider(
      jobId,
      isSurfaceUser,
      dashboardState
    );
    dispatch({
      type: "SET_SLIDER_WORK_LIST",
      sliderWorkList: sliderWorkListResult?.data,
    });
    dispatch({
      type: "SET_SLIDER_WORK_LIST_LOADING",
      sliderWorkListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getAllSliderAssetList(
  dispatch,
  jobId,
  isSurfaceUser,
  isUpdate,
  offset,
  dashboardState
) {
  try {
    if (!isUpdate) {
      dispatch({
        type: "SET_SLIDER_ASSET_LIST_LOADING",
        assetListLoading: true,
      });
    }
    return getAssetListAllSlider(
      jobId,
      isSurfaceUser,
      offset,
      dashboardState
    ).then((res) => {
      if (isUpdate) {
        dispatch({
          type: "UPDATE_SLIDER_ASSET_LIST",
          assetList: res?.data,
        });
      } else {
        dispatch({
          type: "SET_SLIDER_ASSET_LIST",
          assetList: res?.data,
        });
      }
      dispatch({
        type: "SET_SLIDER_ASSET_LIST_LOADING",
        assetListLoading: false,
      });
      return res;
    });
  } catch (error) {
    console.log(error);
  }
}

export {
  DashboardProvider,
  useDashboardState,
  useDashboardDispatch,
  getDashboardLocationList,
  getAllSliderWorkList,
  getAllSliderAssetList,
  setDashboardPropsData,
};
