import React, { useContext, useMemo, useState } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import Typography from "@material-ui/core/Typography";
import FormSelect from "components/ui/FormContent/formSelect";
import { logException } from "components/util/logUtil";
import { changePartner } from "services/workticketService";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import LinearProgress from "@material-ui/core/LinearProgress";

const WorkticketAssign = (props) => {
  const { id, state, user_id } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const { rows } = useTableState();
  const dispatchTable = useTableDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const partners = useMemo(() => {
    return users
      .filter(
        (user) =>
          user.tier_classification === "subcontractor" ||
          user?.role?.tier_classification === "subcontractor"
      )
      .filter(
        (user) =>
          (user.states && user.states.split(",").includes(state)) ||
          user.id === user_id
      )
      .map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      });
  }, [users, state, user_id]);

  if (!users) {
    return "Error users";
  }
  if (!users.length) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const handleChangePartnerAssignedTo = async (item) => {
    if (item) {
      setIsLoading(true);
      try {
        const resultChange = await changePartner(id, item.value);
        rows.find((row) => row.id === id).partner_assigned = item.value;
        if (resultChange) {
          dispatchTable({ type: "UPDATE_ROWS", rows: rows });
        }
      } catch (e) {
        logException(e, "Cannot change partner");
      }
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LinearProgress color="secondary" />
  ) : (
    <FormSelect
      name={`${id}_assigned`}
      gridSizes={[{ size: "md", val: 12 }]}
      withoutLabel
      options={partners}
      value={user_id}
      handleBlur={handleChangePartnerAssignedTo}
      changeValueControlled={true}
      noMargin
    />
  );
};

export default WorkticketAssign;
