import React, { useState, useEffect } from "react";
import {
  HomeDark,
  ProjectDark,
  QualityDark,
  TeamDark,
  FinancialDark,
  SettingDark,
  RequestDark,
  QuoteDark,
  WorkticketDark,
  AssetDark,
  PartnerIcon,
} from "components/common/Icons/navIconsV2";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import LegacyIcon from "@material-ui/icons/Storage";
import SurfaceIcon from "@material-ui/icons/RecentActors";
import BudgetIcon from "@material-ui/icons/Ballot";
import SchoolIcon from "@material-ui/icons/School";
import FormIcon from "@material-ui/icons/ListAlt";
import CustomerIcon from "@material-ui/icons/PermContactCalendar";
import { ButtonBase } from "components/ui/core/button";
import { useRoleView } from "contexts/roleViewContext";
import {
  updateRoleDetail,
  getPermissionsList,
  assignRolePermissions,
  removeRolePermissions,
} from "services/superService";
import { logException } from "components/util/logUtil";

import useStyles from "./styles";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const iniChecked = {
  dashboard: false,
  project: false,
  quote: false,
  workticket: false,
  asset: false,
  finance: false,
  admin: false,
  legacy: false,
  surface: false,
  request: false,
  budget: false,
  talent: false,
  form: false,
  customer: false,
  partner: false,
};

const RolePermissions = (props) => {
  const classes = useStyles();
  const [stateContext] = useRoleView();
  const [data, setData] = useState({ name: "" });
  const [tierClassifications, setTierClassification] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoadingPerms, setIsLoadingPerms] = useState(true);
  const [permissionsDashboard, setPermissionsDashboard] = useState([]);
  const [permissionsWorkticket, setPermissionsWorkticket] = useState([]);
  const [permissionsQuote, setPermissionsQuote] = useState([]);
  const [permissionsProject, setPermissionsProject] = useState([]);
  const [permissionsAsset, setPermissionsAsset] = useState([]);
  const [permissionsFinance, setPermissionsFinance] = useState([]);
  const [permissionsAdmin, setPermissionsAdmin] = useState([]);
  const [permissionsLegacy, setPermissionsLegacy] = useState([]);
  const [permissionsSurface, setPermissionsSurface] = useState([]);
  const [permissionsRequest, setPermissionsRequest] = useState([]);
  const [permissionsBudget, setPermissionsBudget] = useState([]);
  const [permissionsTalent, setPermissionsTalent] = useState([]);
  const [permissionsForm, setPermissionsForm] = useState([]);
  const [permissionsQuality, setPermissionsQuality] = useState([]);
  const [permissionsCustomer, setPermissionsCustomer] = useState([]);
  const [permissionsLearn, setPermissionsLearn] = useState([]);
  const [permissionsPartner, setPermissionsPartner] = useState([]);
  const [allChecked, setAllChecked] = useState(iniChecked);
  const [checkedDashboard, setCheckedDashboard] = useState([]);
  const [checkedProject, setCheckedProject] = useState([]);
  const [checkedQuote, setCheckedQuote] = useState([]);
  const [checkedWorkticket, setCheckedWorkticket] = useState([]);
  const [checkedAsset, setCheckedAsset] = useState([]);
  const [checkedFinance, setCheckedFinance] = useState([]);
  const [checkedAdmin, setCheckedAdmin] = useState([]);
  const [checkedLegacy, setCheckedLegacy] = useState([]);
  const [checkedSurface, setCheckedSurface] = useState([]);
  const [checkedRequest, setCheckedRequest] = useState([]);
  const [checkedBudget, setCheckedBudget] = useState([]);
  const [checkedTalent, setCheckedTalent] = useState([]);
  const [checkedForm, setCheckedForm] = useState([]);
  const [checkedQuality, setCheckedQuality] = useState([]);
  const [checkedCustomer, setCheckedCustomer] = useState([]);
  const [checkedLearn, setCheckedLearn] = useState([]);
  const [checkedPartner, setCheckedPartner] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { role, options, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      if (options && options.tier_classification) {
        setTierClassification(options.tier_classification);
      }
      if (options && options.departments) {
        setDepartments(options.departments);
      }
    }
  }, [options, isLoading]);

  useEffect(() => {
    // Load permissions
    const loadPermissions = async () => {
      const result = await getPermissionsList();
      const permissions = result.data.data.permissions;
      setPermissionsDashboard(
        permissions.filter((perm) => perm.module === "dashboard")
      );
      setPermissionsWorkticket(
        permissions.filter((perm) => perm.module === "worktickets")
      );
      setPermissionsQuote(
        permissions.filter((perm) => perm.module === "quotes")
      );
      setPermissionsProject(
        permissions.filter((perm) => perm.module === "projects")
      );
      setPermissionsAsset(
        permissions.filter((perm) => perm.module === "assets")
      );
      setPermissionsFinance(
        permissions.filter((perm) => perm.module === "finance")
      );
      setPermissionsAdmin(
        permissions.filter((perm) => perm.module === "admin")
      );
      setPermissionsLegacy(
        permissions.filter((perm) => perm.module === "legacy")
      );
      setPermissionsSurface(
        permissions.filter((perm) => perm.module === "surface")
      );
      setPermissionsRequest(
        permissions.filter((perm) => perm.module === "requests")
      );
      setPermissionsBudget(
        permissions.filter((perm) => perm.module === "budgets")
      );
      setPermissionsTalent(
        permissions.filter((perm) => perm.module === "talent")
      );
      setPermissionsForm(permissions.filter((perm) => perm.module === "forms"));
      setPermissionsQuality(
        permissions.filter((perm) => perm.module === "quality")
      );
      setPermissionsCustomer(
        permissions.filter((perm) => perm.module === "customers")
      );
      setPermissionsLearn(
        permissions.filter((perm) => perm.module === "learn")
      );
      setPermissionsPartner(
        permissions.filter((perm) => perm.module === "partners")
      );
      setIsLoadingPerms(false);
    };
    if (!isLoading) {
      if (role !== "new") {
        setData({ ...role });
        loadPermissions();
      }
    }
  }, [role, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      const selectedPerms = role.permissions;
      setCheckedDashboard(
        selectedPerms
          .filter((perm) => perm.module === "dashboard")
          .map((perm) => perm.id)
      );
      setCheckedProject(
        selectedPerms
          .filter((perm) => perm.module === "projects")
          .map((perm) => perm.id)
      );
      setCheckedQuote(
        selectedPerms
          .filter((perm) => perm.module === "quotes")
          .map((perm) => perm.id)
      );
      setCheckedWorkticket(
        selectedPerms
          .filter((perm) => perm.module === "worktickets")
          .map((perm) => perm.id)
      );
      setCheckedAsset(
        selectedPerms
          .filter((perm) => perm.module === "assets")
          .map((perm) => perm.id)
      );
      setCheckedFinance(
        selectedPerms
          .filter((perm) => perm.module === "finance")
          .map((perm) => perm.id)
      );
      setCheckedAdmin(
        selectedPerms
          .filter((perm) => perm.module === "admin")
          .map((perm) => perm.id)
      );

      setCheckedLegacy(
        selectedPerms
          .filter((perm) => perm.module === "legacy")
          .map((perm) => perm.id)
      );

      setCheckedSurface(
        selectedPerms
          .filter((perm) => perm.module === "surface")
          .map((perm) => perm.id)
      );

      setCheckedRequest(
        selectedPerms
          .filter((perm) => perm.module === "requests")
          .map((perm) => perm.id)
      );

      setCheckedBudget(
        selectedPerms
          .filter((perm) => perm.module === "budgets")
          .map((perm) => perm.id)
      );

      setCheckedTalent(
        selectedPerms
          .filter((perm) => perm.module === "talent")
          .map((perm) => perm.id)
      );

      setCheckedForm(
        selectedPerms
          .filter((perm) => perm.module === "forms")
          .map((perm) => perm.id)
      );

      setCheckedQuality(
        selectedPerms
          .filter((perm) => perm.module === "quality")
          .map((perm) => perm.id)
      );
      setCheckedCustomer(
        selectedPerms
          .filter((perm) => perm.module === "customers")
          .map((perm) => perm.id)
      );
      setCheckedLearn(
        selectedPerms
          .filter((perm) => perm.module === "learn")
          .map((perm) => perm.id)
      );
      setCheckedPartner(
        selectedPerms
          .filter((perm) => perm.module === "partners")
          .map((perm) => perm.id)
      );
    }
  }, [role, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      // Calculate groups per type
      const groupsDashboard = permissionsDashboard.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsProject = permissionsProject.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsQuote = permissionsQuote.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsWorkticket = permissionsWorkticket.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsAsset = permissionsAsset.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsFinance = permissionsFinance.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsAdmin = permissionsAdmin.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsLegacy = permissionsLegacy.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsSurface = permissionsSurface.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsRequest = permissionsRequest.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsBudget = permissionsBudget.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      const groupsTalent = permissionsTalent.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      const groupsForm = permissionsForm.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      const groupsQuality = permissionsQuality.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );
      const groupsCustomer = permissionsCustomer.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      const groupsLearn = permissionsLearn.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      const groupsPartner = permissionsPartner.reduce(
        (groups, { selection_group_id }) => {
          if (selection_group_id && !groups.includes(selection_group_id)) {
            groups.push(selection_group_id);
          }
          return groups;
        },
        []
      );

      setAllChecked({
        dashboard:
          checkedDashboard.length ===
          permissionsDashboard.filter((perm) => !perm.selection_group_id)
            .length +
            groupsDashboard.length,
        project:
          checkedProject.length ===
          permissionsProject.filter((perm) => !perm.selection_group_id).length +
            groupsProject.length,
        quote:
          checkedQuote.length ===
          permissionsQuote.filter((perm) => !perm.selection_group_id).length +
            groupsQuote.length,
        workticket:
          checkedWorkticket.length ===
          permissionsWorkticket.filter((perm) => !perm.selection_group_id)
            .length +
            groupsWorkticket.length,
        asset:
          checkedAsset.length ===
          permissionsAsset.filter((perm) => !perm.selection_group_id).length +
            groupsAsset.length,
        finance:
          checkedFinance.length ===
          permissionsFinance.filter((perm) => !perm.selection_group_id).length +
            groupsFinance.length,
        admin:
          checkedAdmin.length ===
          permissionsAdmin.filter((perm) => !perm.selection_group_id).length +
            groupsAdmin.length,
        legacy:
          checkedLegacy.length ===
          permissionsLegacy.filter((perm) => !perm.selection_group_id).length +
            groupsLegacy.length,
        surface:
          checkedSurface.length ===
          permissionsSurface.filter((perm) => !perm.selection_group_id).length +
            groupsSurface.length,
        request:
          checkedRequest.length ===
          permissionsRequest.filter((perm) => !perm.selection_group_id).length +
            groupsRequest.length,
        budget:
          checkedBudget.length ===
          permissionsBudget.filter((perm) => !perm.selection_group_id).length +
            groupsBudget.length,
        talent:
          checkedTalent.length ===
          permissionsTalent.filter((perm) => !perm.selection_group_id).length +
            groupsTalent.length,
        form:
          checkedForm.length ===
          permissionsForm.filter((perm) => !perm.selection_group_id).length +
            groupsForm.length,
        quality:
          checkedQuality.length ===
          permissionsQuality.filter((perm) => !perm.selection_group_id).length +
            groupsQuality.length,
        customer:
          checkedCustomer.length ===
          permissionsCustomer.filter((perm) => !perm.selection_group_id)
            .length +
            groupsCustomer.length,
        learn:
          checkedLearn.length ===
          permissionsLearn.filter((perm) => !perm.selection_group_id).length +
            groupsLearn.length,
        partner:
          checkedPartner.length ===
          permissionsPartner.filter((perm) => !perm.selection_group_id).length +
            groupsPartner.length,
      });
    }
  }, [
    isLoading,
    checkedProject.length,
    permissionsProject,
    checkedQuote.length,
    permissionsQuote,
    checkedWorkticket.length,
    permissionsWorkticket,
    checkedAsset.length,
    permissionsAsset,
    checkedFinance.length,
    permissionsFinance,
    checkedAdmin.length,
    permissionsAdmin,
    checkedLegacy.length,
    permissionsLegacy,
    checkedSurface.length,
    permissionsSurface,
    checkedRequest.length,
    permissionsRequest,
    checkedBudget.length,
    permissionsBudget,
    checkedTalent.length,
    permissionsTalent,
    checkedForm.length,
    permissionsForm,
    checkedCustomer.length,
    permissionsCustomer,
    checkedLearn.length,
    permissionsLearn,
    checkedPartner.length,
    permissionsPartner,
    checkedDashboard.length,
    permissionsDashboard,
    checkedQuality.length,
    permissionsQuality,
  ]);

  const updatePermissions = async (index, value) => {
    openAlert();
    if (index === -1) {
      await assignRolePermissions(role.id, { permission_ids: [value] });
    } else {
      await removeRolePermissions(role.id, { permission_ids: [value] });
    }
  };

  const updatePermissionsBulk = async (flag, list) => {
    openAlert();
    if (flag) {
      await assignRolePermissions(role.id, { permission_ids: list });
    } else {
      await removeRolePermissions(role.id, { permission_ids: list });
    }
  };

  const handleToggleDashboard = (value, group) => () => {
    const currentIndex = checkedDashboard.indexOf(value);
    const newChecked = [...checkedDashboard];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsDashboard
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedDashboard.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedDashboard.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, dashboard: false });
    }
    setCheckedDashboard(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleProject = (value, group) => () => {
    const currentIndex = checkedProject.indexOf(value);
    const newChecked = [...checkedProject];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsProject
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedProject.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedProject.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, project: false });
    }
    setCheckedProject(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleQuote = (value, group) => () => {
    const currentIndex = checkedQuote.indexOf(value);
    const newChecked = [...checkedQuote];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsQuote
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedQuote.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedQuote.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, quote: false });
    }
    setCheckedQuote(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleWorkticket = (value, group) => () => {
    const currentIndex = checkedWorkticket.indexOf(value);
    const newChecked = [...checkedWorkticket];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsWorkticket
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedWorkticket.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedWorkticket.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, workticket: false });
    }
    setCheckedWorkticket(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleAsset = (value, group) => () => {
    const currentIndex = checkedAsset.indexOf(value);
    const newChecked = [...checkedAsset];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsAsset
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedAsset.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedAsset.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, asset: false });
    }
    setCheckedAsset(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleFinance = (value, group) => () => {
    const currentIndex = checkedFinance.indexOf(value);
    const newChecked = [...checkedFinance];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsFinance
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedFinance.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedFinance.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, finance: false });
    }
    setCheckedFinance(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleAdmin = (value, group) => () => {
    const currentIndex = checkedAdmin.indexOf(value);
    const newChecked = [...checkedAdmin];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsAdmin
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedAdmin.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedAdmin.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, admin: false });
    }
    setCheckedAdmin(newChecked);
    updatePermissions(currentIndex, value);
  };

  // const handleToggleLegacy = (value, group) => () => {
  //   const currentIndex = checkedLegacy.indexOf(value);
  //   const newChecked = [...checkedLegacy];
  //   if (group && currentIndex === -1) {
  //     const permissionGroup = permissionsLegacy
  //       .filter(
  //         (perm) =>
  //           perm.selection_group_id === group &&
  //           checkedLegacy.includes(perm.id) &&
  //           perm.id !== value
  //       )
  //       .map((perm) => perm.id);

  //     if (permissionGroup.length) {
  //       permissionGroup.forEach((item) => {
  //         const permIndex = checkedLegacy.indexOf(item);
  //         newChecked.splice(permIndex, 1);
  //       });
  //       updatePermissionsBulk(false, permissionGroup);
  //     }
  //   }
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //     setAllChecked({ ...allChecked, legacy: false });
  //   }
  //   setCheckedLegacy(newChecked);
  //   updatePermissions(currentIndex, value);
  // };

  const handleToggleSurface = (value, group) => () => {
    const currentIndex = checkedSurface.indexOf(value);
    const newChecked = [...checkedSurface];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsSurface
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedSurface.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedSurface.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, surface: false });
    }
    setCheckedSurface(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleRequest = (value, group) => () => {
    const currentIndex = checkedRequest.indexOf(value);
    const newChecked = [...checkedRequest];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsRequest
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedRequest.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedRequest.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, request: false });
    }
    setCheckedRequest(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleBudget = (value, group) => () => {
    const currentIndex = checkedBudget.indexOf(value);
    const newChecked = [...checkedBudget];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsBudget
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedBudget.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedBudget.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, budget: false });
    }
    setCheckedBudget(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleTalent = (value, group) => () => {
    const currentIndex = checkedTalent.indexOf(value);
    const newChecked = [...checkedTalent];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsTalent
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedTalent.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedTalent.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, talent: false });
    }
    setCheckedTalent(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleForm = (value, group) => () => {
    const currentIndex = checkedForm.indexOf(value);
    const newChecked = [...checkedForm];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsForm
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedForm.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedForm.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, form: false });
    }
    setCheckedForm(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleQuality = (value, group) => () => {
    const currentIndex = checkedQuality.indexOf(value);
    const newChecked = [...checkedQuality];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsQuality
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedQuality.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedQuality.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, form: false });
    }
    setCheckedQuality(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleCustomer = (value, group) => () => {
    const currentIndex = checkedCustomer.indexOf(value);
    const newChecked = [...checkedCustomer];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsCustomer
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedCustomer.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedCustomer.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, quality: false });
    }
    setCheckedCustomer(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleToggleLearn = (value, group) => () => {
    const currentIndex = checkedLearn.indexOf(value);
    const newChecked = [...checkedLearn];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsLearn
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedLearn.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedLearn.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, learn: false });
    }
    setCheckedLearn(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleTogglePartner = (value, group) => () => {
    const currentIndex = checkedPartner.indexOf(value);
    const newChecked = [...checkedPartner];
    if (group && currentIndex === -1) {
      const permissionGroup = permissionsPartner
        .filter(
          (perm) =>
            perm.selection_group_id === group &&
            checkedPartner.includes(perm.id) &&
            perm.id !== value
        )
        .map((perm) => perm.id);

      if (permissionGroup.length) {
        permissionGroup.forEach((item) => {
          const permIndex = checkedPartner.indexOf(item);
          newChecked.splice(permIndex, 1);
        });
        updatePermissionsBulk(false, permissionGroup);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      setAllChecked({ ...allChecked, partner: false });
    }
    setCheckedPartner(newChecked);
    updatePermissions(currentIndex, value);
  };

  const handleChangeAll = (event) => {
    event.stopPropagation();
    setAllChecked({ ...allChecked, [event.target.name]: event.target.checked });
    const includeGroups = [];
    const includePermission = [];
    if (event.target.name === "dashboard") {
      if (event.target.checked) {
        const groups = permissionsDashboard.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsDashboard
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedDashboard.includes(p))
          );
          setCheckedDashboard(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedDashboard);
        setCheckedDashboard([]);
      }
    } else if (event.target.name === "project") {
      if (event.target.checked) {
        const groups = permissionsProject.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsProject
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedProject.includes(p))
          );
          setCheckedProject(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedProject);
        setCheckedProject([]);
      }
    } else if (event.target.name === "quote") {
      if (event.target.checked) {
        const groups = permissionsQuote.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsQuote
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedQuote.includes(p))
          );
          setCheckedQuote(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedQuote);
        setCheckedQuote([]);
      }
    } else if (event.target.name === "workticket") {
      if (event.target.checked) {
        const groups = permissionsWorkticket.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsWorkticket
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedWorkticket.includes(p))
          );
          setCheckedWorkticket(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedWorkticket);
        setCheckedWorkticket([]);
      }
    } else if (event.target.name === "asset") {
      if (event.target.checked) {
        const groups = permissionsAsset.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsAsset
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedAsset.includes(p))
          );
          setCheckedAsset(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedAsset);
        setCheckedAsset([]);
      }
    } else if (event.target.name === "finance") {
      if (event.target.checked) {
        const groups = permissionsFinance.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsFinance
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedFinance.includes(p))
          );
          setCheckedFinance(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedFinance);
        setCheckedFinance([]);
      }
    } else if (event.target.name === "admin") {
      if (event.target.checked) {
        const groups = permissionsAdmin.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsAdmin
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedAdmin.includes(p))
          );
          setCheckedAdmin(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedAdmin);
        setCheckedAdmin([]);
      }
    } else if (event.target.name === "legacy") {
      if (event.target.checked) {
        const groups = permissionsLegacy.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsLegacy
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedLegacy.includes(p))
          );
          setCheckedLegacy(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedLegacy);
        setCheckedLegacy([]);
      }
    } else if (event.target.name === "surface") {
      if (event.target.checked) {
        const groups = permissionsSurface.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsSurface
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedSurface.includes(p))
          );
          setCheckedSurface(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedSurface);
        setCheckedSurface([]);
      }
    } else if (event.target.name === "request") {
      if (event.target.checked) {
        const groups = permissionsRequest.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsRequest
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedRequest.includes(p))
          );
          setCheckedRequest(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedRequest);
        setCheckedRequest([]);
      }
    } else if (event.target.name === "budget") {
      if (event.target.checked) {
        const groups = permissionsBudget.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsBudget
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedBudget.includes(p))
          );
          setCheckedBudget(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedBudget);
        setCheckedBudget([]);
      }
    } else if (event.target.name === "talent") {
      if (event.target.checked) {
        const groups = permissionsTalent.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsTalent
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedTalent.includes(p))
          );
          setCheckedTalent(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedTalent);
        setCheckedTalent([]);
      }
    } else if (event.target.name === "form") {
      if (event.target.checked) {
        const groups = permissionsForm.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsForm
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedForm.includes(p))
          );
          setCheckedForm(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedForm);
        setCheckedForm([]);
      }
    } else if (event.target.name === "quality") {
      if (event.target.checked) {
        const groups = permissionsQuality.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsQuality
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedForm.includes(p))
          );
          setCheckedQuality(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedForm);
        setCheckedQuality([]);
      }
    } else if (event.target.name === "customer") {
      if (event.target.checked) {
        const groups = permissionsCustomer.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsCustomer
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedQuality.includes(p))
          );
          setCheckedCustomer(perms);
        }
      }
    } else if (event.target.name === "learn") {
      if (event.target.checked) {
        const groups = permissionsLearn.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsLearn
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedLearn.includes(p))
          );
          setCheckedLearn(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedLearn);
        setCheckedLearn([]);
      }
    } else if (event.target.name === "partner") {
      if (event.target.checked) {
        const groups = permissionsPartner.filter(
          (perm) => perm.selection_group_id
        );
        groups.forEach((item) => {
          if (!includeGroups.includes(item.selection_group_id)) {
            includeGroups.push(item.selection_group_id);
            includePermission.push(item.id);
          }
        });
        const perms = permissionsPartner
          .filter(
            (perm) =>
              !perm.selection_group_id || includePermission.includes(perm.id)
          )
          .map((perm) => perm.id);
        if (perms.length) {
          updatePermissionsBulk(
            event.target.checked,
            perms.filter((p) => !checkedPartner.includes(p))
          );
          setCheckedPartner(perms);
        }
      } else {
        updatePermissionsBulk(event.target.checked, checkedPartner);
        setCheckedPartner([]);
      }
    }
  };

  const handleCancel = () => {
    props.history.push(`/roles`);
  };

  const handleBlur = async (event) => {
    try {
      await updateRoleDetail(role.id, {
        [event.name]: event.value,
      });
      setData({ ...data, [event.name]: event.value });
      openAlert();
    } catch (e) {
      logException(e, "Cannot update role");
    }
  };

  const handleChangeTierClassification = async (event, item) => {
    try {
      await updateRoleDetail(role.id, {
        tier_classification: item ? item.value : "none",
      });
      setData({ ...data, tier_classification: item ? item.value : "none" });
      openAlert();
    } catch (e) {
      logException(e, "Cannot update role");
    }
  };

  const handleChangeDepartment = async (event, item) => {
    try {
      await updateRoleDetail(role.id, {
        department: item ? item.value : "none",
      });
      setData({ ...data, department: item ? item.value : "none" });
      openAlert();
    } catch (e) {
      logException(e, "Cannot update role");
    }
  };

  const openAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  if (isLoading || isLoadingPerms) {
    return <LoadingStateHorizontal isVisible style={classes.centerLoading} />;
  }

  return (
    <>
      <Box className={classes.rootForm}>
        {showAlert ? (
          <Alert severity="success" className={classes.alertSuccess}>
            Change has been saved.
          </Alert>
        ) : null}

        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="name"
              label="Name"
              value={data.name}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="category"
              label="Category"
              value={data.category}
              readonly={true}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormSelectAuto
              label={"Tier Classification"}
              gridSizes={[{ size: "md", val: 12 }]}
              options={tierClassifications}
              name="tier_classification"
              multiple={false}
              handleChange={handleChangeTierClassification}
              value={tierClassifications.find(
                (classification) =>
                  classification.value === data.tier_classification
              )}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormSelectAuto
              label={"Department"}
              gridSizes={[{ size: "md", val: 12 }]}
              options={departments}
              name="departments"
              multiple={false}
              handleChange={handleChangeDepartment}
              value={departments.find(
                (department) => department.value === data.department
              )}
            />
          </Grid>
          <Grid item md={1} xs={12}>
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="tier"
              label="Tier Level"
              value={data.tier}
              readonly={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Badge
                  badgeContent={checkedDashboard.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <HomeDark />
                  </Box>
                  Dashboard
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.dashboard}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"dashboard"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsDashboard.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleDashboard(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedDashboard.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Badge
                  badgeContent={checkedRequest.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <RequestDark />
                  </Box>
                  Requests
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.request}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"request"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsRequest.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleRequest(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedRequest.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Badge
                  badgeContent={checkedProject.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <ProjectDark />
                  </Box>
                  Projects
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.project}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"project"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsProject.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleProject(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedProject.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Badge
                  badgeContent={checkedQuote.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <QuoteDark />
                  </Box>
                  Quotes
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.quote}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"quote"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsQuote.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleQuote(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedQuote.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Badge
                  badgeContent={checkedWorkticket.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <WorkticketDark />
                  </Box>
                  Worktickets
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.workticket}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"workticket"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsWorkticket.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleWorkticket(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedWorkticket.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <Badge
                  badgeContent={checkedTalent.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <TeamDark />
                  </Box>
                  Talent
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.talent}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"talent"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsTalent.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleTalent(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedTalent.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7a-content"
                id="panel7a-header"
              >
                <Badge
                  badgeContent={checkedQuality.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <QualityDark />
                  </Box>
                  Surveys
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.quality}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"quality"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsQuality.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleQuality(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedQuality.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel15a-content"
                id="panel15a-header"
              >
                <Badge
                  badgeContent={checkedLearn.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <SchoolIcon className={classes.permissionIcon} />
                  Learn
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.learn}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"learn"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsLearn.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleLearn(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedLearn.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={5} xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8a-content"
                id="panel8a-header"
              >
                <Badge
                  badgeContent={checkedSurface.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <SurfaceIcon className={classes.permissionIcon} />
                  {`Surface`}
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.surface}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"surface"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsSurface.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleSurface(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedSurface.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel15a-content"
                id="panel15a-header"
              >
                <Badge
                  badgeContent={checkedPartner.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <PartnerIcon />
                  </Box>
                  Partners
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.partner}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"partner"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsPartner.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleTogglePartner(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedPartner.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9a-content"
                id="panel9a-header"
              >
                <Badge
                  badgeContent={checkedAsset.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <AssetDark />
                  </Box>
                  Assets
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.asset}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"asset"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsAsset.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleAsset(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedAsset.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10a-content"
                id="panel10a-header"
              >
                <Badge
                  badgeContent={checkedForm.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <FormIcon className={classes.permissionIcon} />
                  {`Forms`}
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.form}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"form"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsForm.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleForm(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedForm.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11a-content"
                id="panel11a-header"
              >
                <Badge
                  badgeContent={checkedBudget.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <BudgetIcon className={classes.permissionIcon} />
                  {`Budget`}
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.budget}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"budget"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsBudget.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleBudget(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedBudget.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel12a-content"
                id="panel12a-header"
              >
                <Badge
                  badgeContent={checkedFinance.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <FinancialDark />
                  </Box>
                  Finance
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.finance}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"finance"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsFinance.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleFinance(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedFinance.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel13a-content"
                id="panel13a-header"
              >
                <Badge
                  badgeContent={checkedCustomer.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <CustomerIcon className={classes.permissionIcon} />
                  {`Customers`}
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.customer}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"customer"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsCustomer.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleCustomer(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedCustomer.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Badge
                  badgeContent={checkedAdmin.length}
                  className={classes.badge}
                  color="secondary"
                >
                  <Box className={classes.permissionIconWrapper}>
                    <SettingDark />
                  </Box>
                  Administration
                </Badge>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.rootList}>
                  <ListItem key={0} role={undefined} dense button>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={allChecked.admin}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": 0 }}
                        onClick={handleChangeAll}
                        name={"admin"}
                      />
                    </ListItemIcon>
                    <ListItemText id={0} primary={`ALL`} />
                  </ListItem>
                  {permissionsAdmin.map((perm) => {
                    const labelId = `checkbox-list-label-${perm.id}`;
                    return (
                      <ListItem
                        key={perm.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggleAdmin(
                          perm.id,
                          perm.selection_group_id
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedAdmin.indexOf(perm.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${perm.display_name} ${
                            perm.selection_group_id ? `🔗` : ``
                          }`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={10} xs={12}>
            <Box className={classes.boxActions}>
              <ButtonBase
                variant="outlined"
                color="secondary"
                disableElevation
                onClick={handleCancel}
              >
                Close
              </ButtonBase>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RolePermissions;
