import React, { useState } from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Star from "@material-ui/icons/StarRounded";
import StarBorder from "@material-ui/icons/StarOutlineRounded";
import * as classNames from "classnames";
import useStyles from "./styles";

const StarRating = ({
  stars = 0,
  maxStars = 5,
  readOnly = true,
  showLabels = false,
  iconSize = 24,
  spacing = 2,
  onRate,
  isTableView = false,
}) => {
  const initialStars = Number(stars);
  const [value, setValue] = useState(initialStars);
  const [, setHover] = useState(-1);
  const classes = useStyles({ spacing });

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
    if (onRate) onRate(newValue);
  };

  const handleRatingHoverChange = (event, newHover) => {
    setHover(newHover);
  };

  return (
    <Box
      className={classNames(classes.rating, {
        [classes.ratingTable]: isTableView,
      })}
    >
      <Box className={classes.starsContainer}>
        <Rating
          name="rating"
          value={value}
          max={maxStars}
          readOnly={readOnly}
          onChange={handleRatingChange}
          onChangeActive={handleRatingHoverChange}
          icon={
            <Star
              style={{ fontSize: iconSize }}
              className={classes.starColor}
            />
          }
          emptyIcon={
            <StarBorder
              style={{ fontSize: iconSize }}
              className={classes.starColor}
            />
          }
        />
      </Box>
      {showLabels && (
        <Box className={classes.labels}>
          <Box className={classes.labelText}>Very Poor</Box>
          <Box className={classes.labelText}>Average</Box>
          <Box className={classes.labelText}>Excellent</Box>
        </Box>
      )}
    </Box>
  );
};

export default StarRating;
