import { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { NotepadIcon } from "../icons/NotepadIcon";
import Button from "@material-ui/core/Button";

export const NoTrades = ({ classes, toggleDrawer }) => (
  <Fragment>
    <Typography className={classes.generalInformationSubHeader}>
      Trades Offered & Pricing
    </Typography>
    <Box className={classes.bigNoContainer}>
      <Box className={classes.noTradesContainer}>
        <Box className={classes.notepadContainer}>
          <NotepadIcon />
          <Typography>No Trades Added Yet.</Typography>
        </Box>
        <Button
          onClick={toggleDrawer("right", true)}
          className={classes.addContactButton}
        >
          Add New Trade
        </Button>
      </Box>
    </Box>
  </Fragment>
);
