import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "components/common/PageHeader/pageHeader";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { FormsProvider } from "contexts/formsContext";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionForms } from "lib/permissions";
import FormsTabTable from "./formsTabTable";
import useStyles from "./styles";

const FormsTab = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      <PageHeader pageTitle="Forms" link="/forms?talent" />
      <Box className={classes.pageBodyWrapper}>
        {hasPermission(permissionForms.FORMS_MODULE, permissions) ? (
          <FormsProvider>
            <FormsTabTable />
          </FormsProvider>
        ) : null}
      </Box>
    </>
  );
};

export default withTableFilterContext(FormsTab);
