import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import useStyles from "../../partnersList/dialog/styles";
import MessageDialog from "../../partnersList/dialog/messageDialog";
import { useComments } from "./useComments";

export const CommentDialogue = () => {
  const classes = useStyles();
  const {
    isOpenAddDialog,
    isLoadingDialog,
    dispatchCloseDialog,
    isEditing,
    commentToEdit,
    comment,
    setComment,
    handleSubmitComment,
  } = useComments();

  return (
    <>
      <Dialog
        open={isOpenAddDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        {isLoadingDialog ? (
          <LoadingIndicator />
        ) : (
          <>
            <IconButton
              aria-label="close"
              onClick={dispatchCloseDialog}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <DialogTitle className={classes.wrapperDialog}>
              <Box className={classes.titleWithIcon}>
                <RateReviewIcon className={classes.rateIcon} />
                <Typography
                  className={classes.titleDialog}
                  variant="caption"
                  gutterBottom
                >
                  {isEditing ? "Edit Comment" : "Rate this Partner"}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                Please provide additional feedback in the text box to help us
                know more about them.
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box className={classes.selectContainer}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel
                    htmlFor="feedback"
                    className={classes.feedbackLabel}
                  >
                    Comment
                  </FormLabel>
                  <TextField
                    id="feedback"
                    size="small"
                    variant="standard"
                    multiline
                    minRows={5}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    className={classes.textArea}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions className={classes.actionItemButton}>
              <Button
                onClick={dispatchCloseDialog}
                className={classes.buttonCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmitComment}
                className={classes.buttonConfirm}
              >
                {isEditing ? "Update Comment" : "Rate Partner"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/*<MessageDialog*/}
      {/*  open={openSuccess}*/}
      {/*  handleClose={handleCloseSuccess}*/}
      {/*  title="Success"*/}
      {/*  message="Your rating and feedback for the partner have been successfully submitted."*/}
      {/*/>*/}
    </>
  );
};
