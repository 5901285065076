import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { PinIcon } from "./icons";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DescriptionIcon from "@material-ui/icons/Description";
import { filmIcon } from "./icons";
import FilePreviewDialog from "./filePreviewDialog";
import Thumbnail from "./thumbnail";
import { getThumbnailUrl } from "components/util/youtubeUtil";
import { mimeTypes } from "constants.js";
import classnames from "classnames";
import useStyles from "./styles";

const Gallery = ({ files, workTicketNumber, workTicketId }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // when there is only one pinned file
  useEffect(() => {
    const fetchThumbnail = async () => {
      if (
        files?.length > 0 &&
        [mimeTypes.LINK, mimeTypes.LINK_360].includes(
          parseInt(files[0].mime_type)
        )
      ) {
        const url = await getThumbnailUrl(files[0].file_url, true);
        setThumbnailUrl(url);
      }
    };
    fetchThumbnail();
  }, [files]);

  const renderPreviewThumbnail = () => {
    if (!files || files.length === 0) {
      return null;
    }

    const file = files[0];

    // Render thumbnail of a video Link
    if (
      [mimeTypes.LINK, mimeTypes.LINK_360].includes(parseInt(file.mime_type))
    ) {
      return (
        <Box className={classes.galleryItem}>
          <img
            className={classes.imagePreviewDefaultVideo}
            src={thumbnailUrl}
            alt={file.display_name}
            onClick={() => handleThumbnailClick(0)}
          />
          <Box
            className={classnames(
              classes.thumbnailIconContainer,
              classes.thumbnailIconSizeGallery,
              classes.backgroundRed
            )}
            onClick={() => handleThumbnailClick(0)}
          >
            <PlayArrowIcon />
          </Box>
          {file.is_pinned && (
            <Box className={classes.pinIconImg}>{PinIcon()}</Box>
          )}
        </Box>
      );
    }

    // Render thumbnail of a PDF file
    else if (parseInt(file.mime_type) === mimeTypes.PDF) {
      return (
        <Box
          className={classnames(classes.thumbnailGalleryPDF)}
          onClick={() => handleThumbnailClick(0)}
        >
          <Box
            className={classnames(
              classes.thumbnailIconSizeGallery,
              classes.backgroundOrange,
              classes.centerIconPdFGallery
            )}
          >
            <DescriptionIcon />
          </Box>
          {file.is_pinned && (
            <Box className={classes.pinIconImg}>{PinIcon()}</Box>
          )}
        </Box>
      );
    }

    // Render thumbnail of an image file
    else if (parseInt(file.mime_type) === mimeTypes.IMAGE) {
      return (
        <Box className={classes.galleryItem}>
          <img
            src={file.file_url}
            alt={file.display_name}
            className={classes.imagePreviewDialog}
            onClick={() => handleThumbnailClick(0)}
          />
          {file.is_pinned && (
            <Box className={classes.pinIconImg}>{PinIcon()}</Box>
          )}
        </Box>
      );
    }

    // Render thumbnail of a VIDEO
    else if (parseInt(file.mime_type) === mimeTypes.VIDEO) {
      return (
        <Box
          className={classnames(classes.thumbnailGalleryVideo)}
          onClick={() => handleThumbnailClick(0)}
        >
          <Box
            className={classnames(
              classes.thumbnailIconSizeGallery,
              classes.backgroundBlue
            )}
          >
            {filmIcon()}
          </Box>
          {file.is_pinned && (
            <Box className={classes.pinIconImg}>{PinIcon()}</Box>
          )}
        </Box>
      );
    }
  };

  const renderThumbnails = () => {
    if (!files || files.length <= 1) {
      return null;
    }
    const thumbnailFiles = files.slice(1);
    return (
      <Box className={classes.thumbnails}>
        {thumbnailFiles.map((file, index) => (
          <Thumbnail
            key={index}
            file={file}
            width={98}
            height={75}
            onClick={() => handleThumbnailClick(index + 1)}
            pinIcon={file.is_pinned && PinIcon()}
            openDialog={openDialog}
          />
        ))}
      </Box>
    );
  };
  const filePreviewDetails = () =>
    openDialog ? (
      <FilePreviewDialog
        open={openDialog}
        onClose={handleCloseDialog}
        classes={classes}
        files={files}
        workTicketNumber={workTicketNumber}
        workTicketId={workTicketId}
        currentImageIndex={selectedImageIndex}
      />
    ) : null;

  return (
    <Box className={classes.gallery}>
      {renderPreviewThumbnail()}
      {renderThumbnails()}
      {filePreviewDetails()}
    </Box>
  );
};

export default Gallery;
