import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import MessageDialog from "./dialog/messageDialog";
import { zipDownload } from "services/fileService";
import useStyles from "./styles";

const DownloadZipButton = ({
  selectedFiles,
  workTicketId,
  workTicketNumber,
}) => {
  const classes = useStyles();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleDownload = async () => {
    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      const postData = {
        workticket_id: workTicketId,
      };

      const response = await zipDownload(postData);
      const blob = new Blob([response.data], { type: "application/zip" });

      const downloadUrl = window.URL.createObjectURL(blob);
      downloadFile(downloadUrl, `${workTicketNumber}_files.zip`);

      setOpenMessageContent("The file has been successfully downloaded");
    } catch (error) {
      console.error("Error downloading file:", error);
      setOpenMessageContent("An error occurred while downloading the file");
    } finally {
      setIsLoadingData(false);
    }
  };

  const downloadFile = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Button
        className={classes.buttonDownload}
        variant="outlined"
        onClick={handleDownload}
        startIcon={<DownloadIcon fontSize="inherit" color="secondary" />}
      >
        <Typography className={classes.downloadButtonFont} variant="body1">
          Download All
        </Typography>
      </Button>

      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </>
  );
};

export default DownloadZipButton;
