import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { ButtonBase } from "components/ui/core/button";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import TicketIcon from "@material-ui/icons/Receipt";
import TipIcon from "@material-ui/icons/Error";
import BackIcon from "@material-ui/icons/ArrowBack";
import EmergencyIcon from "@material-ui/icons/NewReleases";
import { logException } from "components/util/logUtil";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import moment from "moment";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import RedirectDialog from "components/ui/dialog/redirectDialog";
import ErrorMessageDialog from "pages/quotePage/dialog/errorMessageDialog";
import Joi from "joi-browser";

import GlobalUiContext from "contexts/globalUiContext";
import { getProjectsUser } from "services/projectService";
import { hasPermission, permissionEmergencyTicket } from "lib/permissions";
import { getCampus, getLocations } from "services/campusService";
import { createWorkticketEmergency } from "services/workticketService";

import useStyles from "./styles";

const InitialData = {
  campus: null,
  campus_id: null,
  job: null,
  job_id: null,
  job_number: null,
  woid: "",
  job_zone_id: null,
  area: "",
  contact_info: "",
  summary: "",
  description: "",
  notes: "",
  building: "",
  start_date: moment().format("YYYY-MM-DD"),
};

const WorkticketCreate = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [step, setStep] = useState(0);
  const [option, setOption] = useState(0);
  const [useDefault] = useState(false);
  const [projects, setProjects] = useState(null);
  const [campuses, setCampuses] = useState(null);
  const [projectsOptQuick, setProjectsOptQuick] = useState([]);
  const [projectsOptExisting, setProjectsOptExisting] = useState([]);
  const [projectSelected, setProjectSelected] = useState([]);
  const [projectExistingSelected, setProjectExistingSelected] = useState([]);
  const [error, setError] = useState([]);
  const [data, setData] = useState(InitialData);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [jobs, setJobs] = useState([]);
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(0);
  const [msgRedirect, setMsgRedirect] = useState("");
  const [openRedirect, setOpenRedirect] = useState(false);
  const [linkRedirect, setLinkRedirect] = useState("");
  const [labelRedirect, setLabelRedirect] = useState("");
  const [hasCloseRedirect] = useState(false);
  const [schema, setSchema] = useState({});
  const [openMessageError, setOpenMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { createObject, permissions } = globalUi;

  useEffect(() => {
    if (!hasPermission(permissionEmergencyTicket.CREATE, permissions)) {
      dispatchGlobalUi({
        type: "SET_FLOW",
        createFlow: null,
      });
      dispatchGlobalUi({
        type: "SET_QUICKTICKET",
        quickTicket: null,
      });
      dispatchGlobalUi({
        type: "TOGGLE_SIDEDRAWER",
        isDrawerOpen: false,
        drawerContent: null,
      });
      history.push(`/workticket/new/0`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createObject) {
      setOption(2);
      setStep(2);
    }
  }, [createObject]);

  useEffect(() => {
    const loadProjects = async () => {
      const result = await getProjectsUser();
      const { projects } = result.data.data;
      setProjects(projects);
    };
    if (!projects) {
      loadProjects();
    }
  }, [projects]);

  useEffect(() => {
    const loadCampuses = async () => {
      try {
        const resultCampus = await getCampus();
        const { locations } = resultCampus.data.data;
        const resultForRender = locations.map((location) => {
          return { value: location, label: location };
        });
        setCampuses(resultForRender);
      } catch (e) {
        logException(e, "Cannot load campus data");
      }
    };
    if (
      !campuses &&
      hasPermission(permissionEmergencyTicket.CREATE, permissions)
    ) {
      loadCampuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campuses]);

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleCloseRedirect = () => {
    setOpenRedirect(false);
  };

  useEffect(() => {
    const loadLocations = async () => {
      try {
        setIsLoadingLocations(true);
        const resultBuildings = await getLocations(data.campus_id);
        const resultForRender = resultBuildings.data.data.buildings.map(
          (building) => {
            return {
              value: building.job_id,
              label: building.building,
              job_number: building.job.job_number,
            };
          }
        );
        setJobs(resultForRender);
        setIsLoadingLocations(false);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (data.campus_id) {
      loadLocations();
    }
  }, [data.campus_id]);

  const handleBlur = (event, value) => {
    let newData = {};
    switch (event.name) {
      case "woid":
        newData = {
          ...data,
          woid: event.value,
        };
        break;
      case "area":
        newData = {
          ...data,
          area: event.value,
        };
        break;
      case "contact_info":
        newData = {
          ...data,
          contact_info: event.value,
        };
        break;
      case "summary":
        newData = {
          ...data,
          summary: event.value,
        };
        validateProperty(event.name, event.value, schema);
        break;
      case "description":
        newData = {
          ...data,
          description: event.value,
        };
        break;
      case "notes":
        newData = {
          ...data,
          notes: event.value,
        };
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const handleClick = (optionInput) => {
    setOption(optionInput);
    if (optionInput === 1) {
      const filterProject = projects?.filter((pro) => pro.type === 0);
      const resultForRender = filterProject?.map((project) => {
        return {
          value: project.id,
          label: `${project.title}`,
        };
      });
      if (resultForRender) setProjectsOptQuick([...resultForRender]);
    }

    if (optionInput === 3) {
      const filterProject = projects.filter((pro) => pro.type === 0);
      const resultForRender = filterProject.map((project) => {
        return {
          value: project.id,
          label: `${project.title}`,
        };
      });
      setProjectsOptExisting([...resultForRender]);
    }
  };

  const handleNextClick = (stepValue) => {
    if (stepValue) {
      const errors = validate(schema, data);
      if (!Boolean(errors) || stepValue < step) {
        if (stepValue === 4) {
          setSchema({
            campus: Joi.object()
              .required()
              .label("Campus")
              .error(() => {
                return {
                  message: "Campus is required.",
                };
              }),
            job: Joi.object()
              .required()
              .label("Building")
              .error(() => {
                return {
                  message: "Building is required.",
                };
              }),
          });
        } else if (stepValue === 5) {
          setSchema({
            summary: Joi.string()
              .min(1)
              .required()
              .label("Summary")
              .error(() => {
                return {
                  message: "Summary is required.",
                };
              }),
          });
        }
        setStep(stepValue);
      }
    } else {
      if (option === 2) {
        dispatchGlobalUi({
          type: "SET_FLOW",
          createFlow: "workticket",
        });
        dispatchGlobalUi({
          type: "SET_QUICKTICKET",
          quickTicket: null,
        });
        dispatchGlobalUi({
          type: "TOGGLE_SIDEDRAWER",
          isDrawerOpen: true,
          drawerContent: "project",
        });
      } else {
        if (option === 1) {
          dispatchGlobalUi({
            type: "SET_FLOW",
            createFlow: null,
          });
          dispatchGlobalUi({
            type: "SET_QUICKTICKET",
            quickTicket: null,
          });
          dispatchGlobalUi({
            type: "TOGGLE_SIDEDRAWER",
            isDrawerOpen: false,
            drawerContent: null,
          });
          history.push(`/workticket/new/0`);
        } else {
          if (option === 4) {
            setSchema({
              campus: Joi.object()
                .required()
                .label("Campus")
                .error(() => {
                  return {
                    message: "Campus is required.",
                  };
                }),
              job: Joi.object()
                .required()
                .label("Building")
                .error(() => {
                  return {
                    message: "Building is required.",
                  };
                }),
            });
          }
          setStep(option);
        }
      }
    }
  };

  const handleOnClickBack = () => {
    setStep(0);
  };

  const handleBeginClick = () => {
    if (useDefault) {
      history.push(`/workticket/new/0`);
    } else {
      if (!projectSelected[0]) {
        const currentError = [];
        currentError.push({
          key: "project_quick",
          message: "Select a quick project",
        });
        setError([...currentError]);
        return false;
      }
      history.push(`/workticket/new/${projectSelected[0].value}`);
    }
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleNewProjectClick = () => {
    history.push(`/workticket/new/${createObject.id}`);
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleBeginProjectClick = () => {
    if (!projectExistingSelected[0]) {
      const currentError = [];
      currentError.push({
        key: "project_existing",
        message: "Select an existing project",
      });
      setError([...currentError]);
      return false;
    }
    history.push(`/workticket/new/${projectExistingSelected[0].value}`);
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const handleChangeProject = (event, value) => {
    setError([]);
    setProjectSelected([value]);
  };

  const handleChangeProjectExisting = (event, value) => {
    setError([]);
    setProjectExistingSelected([value]);
  };

  const validateProperty = (name, value, schemaAnalized) => {
    const obj = { [name]: value };
    const schema = { [name]: schemaAnalized[name] };
    const { error: errors } = Joi.validate(obj, schema);
    if (Boolean(errors)) {
      setError([...error, { key: name, message: errors.details[0].message }]);
    } else {
      setError(error.filter((err) => err.key !== name));
    }
  };

  const validate = (schema, analizeData) => {
    const options = { abortEarly: false };
    const keyNames = Object.keys(schema);
    let data = {};
    for (let key of keyNames) {
      data[key] = analizeData[key];
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (Boolean(errors)) {
      const keyNames = Object.keys(errors);
      const errorArray = [];
      for (let key of keyNames) {
        errorArray.push({ key, message: errors[key] });
      }
      setError(errorArray);
    } else {
      setError([]);
    }
    return errors;
  };

  const handleChangeCampus = (event, value) => {
    const newData = {
      ...data,
      campus: value,
      campus_id: value ? value.value : null,
      job: null,
      job_id: null,
      zone: null,
      job_zone_id: null,
    };
    setData(newData);
    validateProperty("campus", value, schema);
  };

  const handleChangeJob = (event, value) => {
    const newData = {
      ...data,
      building: value.label,
      job: value,
      job_id: value ? value.value : null,
      job_number: value ? value.job_number : null,
      zone: null,
      job_zone_id: null,
    };
    setData(newData);
    validateProperty("job", value, schema);
  };

  const handleChangeDueDate = async (event) => {
    if (data.start_date !== event.value) {
      const newData = {
        ...data,
        start_date: moment(event).format("YYYY-MM-DD"),
      };
      setData(newData);
    }
  };

  const handleCreate = async () => {
    const dataToCreate = {
      ...data,
      workticket_category: 4,
      subject: data.summary,
      item_subject: data.summary,
      is_strict_scheduling: 1,
      manual: 1,
      files: files,
      is_signature: 0,
      is_paper_ticket: 0,
      priority: 3,
    };
    try {
      setIsLoading(true);
      const result = await createWorkticketEmergency(dataToCreate);
      setMsgRedirect("Workticket has been created.");
      setLabelRedirect("Okay");
      setLinkRedirect(`/workticket/${result.data.data.workticket.id}`);
      setOpenRedirect(true);
      setIsLoading(false);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        setOpenMessageError(true);
        setMessage(e.response.data.message);
      }
      logException(e, "Cannot create workticket");
    }
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  return (
    <>
      <Box className={classes.headerContainer}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          {step < 4 ? "Create New Workticket" : "Urgent Ticket Details"}
        </Typography>
        <Typography
          variant="h6"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          {step < 4
            ? "Tell us more about the workticket you're creating."
            : "Tell us more about this quick ticket."}
        </Typography>
      </Box>
      {step === 0 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            What kind of workticket is this?
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={4}>
              <Paper
                className={
                  option === 1 ? classes.optionPaperActive : classes.optionPaper
                }
                square={true}
                elevation={0}
              >
                <Box
                  className={classes.optionBox}
                  onClick={() => handleClick(1)}
                >
                  <Avatar
                    className={classNames(
                      classes.avatarBox,
                      classes.avatarType1
                    )}
                  >
                    <TicketIcon />
                  </Avatar>
                  <Typography
                    variant="body1"
                    className={classes.optionBoxTitle}
                    gutterBottom
                  >
                    Quick Ticket
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            {hasPermission(permissionEmergencyTicket.CREATE, permissions) && (
              <Grid item xs={4}>
                <Paper
                  className={
                    option === 4
                      ? classes.optionPaperActive
                      : classes.optionPaper
                  }
                  square={true}
                  elevation={0}
                >
                  <Box
                    className={classes.optionBox}
                    onClick={() => handleClick(4)}
                  >
                    <Avatar
                      className={classNames(
                        classes.avatarBox,
                        classes.avatarType4
                      )}
                    >
                      <EmergencyIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      className={classes.optionBoxTitle}
                      gutterBottom
                    >
                      Emergency Ticket
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Box className={classes.tipOptionContainer}>
            <TipIcon className={classes.tipOptionIcon} />
            <Typography
              variant="body1"
              className={classes.tipOption}
              gutterBottom
            >
              TIP: To create a ticket with billing and payments, create a
              project first instead.
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              disabled={!Boolean(option)}
              onClick={() => handleNextClick()}
            >
              {option === 1 ? "Go to Ticket" : "Next"}
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box className={classes.optionWrapper}>
          <Box className={classes.optionContainer}>
            {/* <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.contentTitle}
            >
              Please select a Quick Project for your Ticket?
            </Typography>
            <ButtonGroup
              variant="outlined"
              color="default"
              aria-label="primary button group"
              className={classes.groupOptionDefault}
              fullWidth={true}
            >
              {switchOptions.map((opt) => (
                <Button
                  key={`default-${opt.value}`}
                  className={
                    useDefault === opt.value ? classes.groupOptionActive : null
                  }
                  onClick={() => handleUseDefault(opt.value)}
                >
                  {opt.label}
                </Button>
              ))}
            </ButtonGroup> */}
            {!useDefault && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom={true}
                      className={classes.contentTitle}
                    >
                      Please select a Quick Project
                    </Typography>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={projectsOptQuick}
                      name="project_quick"
                      internal={true}
                      handleChange={handleChangeProject}
                      value={projectSelected[0]}
                      error={error}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={handleOnClickBack}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase color="secondary" onClick={handleBeginClick}>
              Begin
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            Project for your Quick Ticket
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="project_name"
                label=""
                internal={true}
                value={createObject ? createObject.title : ""}
                styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
                readonly={true}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={handleOnClickBack}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase
              color="secondary"
              onClick={handleNewProjectClick}
              disabled={!createObject ? true : false}
            >
              Begin
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 3 && (
        <Box className={classes.optionWrapper}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.contentTitle}
          >
            Please select a Project for your Ticket?
          </Typography>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={projectsOptExisting}
                name="project_existing"
                internal={true}
                handleChange={handleChangeProjectExisting}
                error={error}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={handleOnClickBack}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase color="secondary" onClick={handleBeginProjectClick}>
              Begin
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 4 && (
        <Box className={classes.optionWrapper2}>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={campuses}
                name="campus"
                internal={false}
                error={error}
                handleChange={handleChangeCampus}
                label="Campus"
                value={data.campus}
              />
              {!isLoadingLocations ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={jobs}
                  name="job"
                  internal={false}
                  error={error}
                  handleChange={handleChangeJob}
                  label="Building"
                  value={data.job}
                />
              ) : (
                <LinearProgress color="secondary" />
              )}
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="woid"
                label="WOID"
                error={error}
                internal={false}
                value={data.woid}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="area"
                label="Area"
                error={error}
                internal={false}
                value={data.area}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="contact_info"
                label="Contact Info"
                error={error}
                internal={false}
                value={data.contact_info}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={handleOnClickBack}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase color="secondary" onClick={() => handleNextClick(5)}>
              Next
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 5 && (
        <Box className={classes.optionWrapper2}>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="summary"
                label="Summary"
                error={error}
                internal={false}
                value={data.summary}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="description"
                label="Description"
                error={error}
                internal={false}
                value={data.description}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="notes"
                label="Notes"
                error={error}
                internal={false}
                value={data.notes}
                multiline={true}
                rows={4}
                handleBlur={handleBlur}
              />
              <FormInputDateAdo
                name="start_date"
                label="Due Date"
                internal={true}
                value={data.start_date}
                handleChange={handleChangeDueDate}
              />
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={() => handleNextClick(4)}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase color="secondary" onClick={() => handleNextClick(6)}>
              Next
            </ButtonBase>
          </Box>
        </Box>
      )}
      {step === 6 && (
        <Box className={classes.optionWrapper2}>
          <Grid container className={classes.optionContainer} spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.containerFile}>
                {!Boolean(files.length) && (
                  <Typography
                    variant="h4"
                    className={classes.titleSectionBody}
                    gutterBottom
                  >
                    Documents
                  </Typography>
                )}
                <FileUpload
                  value={files}
                  handleChange={setFiles}
                  handleUpdate={setUpdate}
                  filesDisplay={
                    Boolean(update) && (
                      <FilesDisplay files={files} handleRemove={handleRemove} />
                    )
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.actionBox}>
            <ButtonBase
              color="secondary"
              startIcon={<BackIcon />}
              variant="text"
              onClick={() => handleNextClick(5)}
              className={classes.buttonBackDrawer}
            >
              Back
            </ButtonBase>
            <ButtonBase
              color="secondary"
              onClick={handleCreate}
              disabled={isLoading}
            >
              Submit
            </ButtonBase>
          </Box>
        </Box>
      )}
      <RedirectDialog
        open={openRedirect}
        hasClose={hasCloseRedirect}
        handleClose={handleCloseRedirect}
        title="Success"
        message={msgRedirect}
        redirectLabel={labelRedirect}
        redirect={linkRedirect}
      />
      <ErrorMessageDialog
        title={""}
        open={openMessageError}
        handleClose={closeMessageError}
        message={message}
      />
    </>
  );
};

export default withRouter(WorkticketCreate);
