import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  useQuestionState,
  useQuestionDispatch,
} from "contexts/surveyQuestionContext";
import SurveysQuestionsList from "./surveysQuestionsList/surveysQuestionsList";
import SurveyIcon from "assets/icons/surveyIcon";

import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddQuestionDialog = (props) => {
  const { openQuestionAdd } = useQuestionState();
  const dispatchSurveysQuestions = useQuestionDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatchSurveysQuestions({
      type: "TOGGLE_ADD",
      openQuestionAdd: false,
    });
  };

  return (
    <Dialog
      fullScreen
      open={openQuestionAdd}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        position="relative"
        classes={{
          root: classes.rootAppBar,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box className={classes.descriptionContainer}>
          <SurveyIcon className={classes.previewWTIcon} />
          <Typography variant="h6" className={classes.titleDialogSelect}>
            Select Questions
          </Typography>
        </Box>
        <Box className={classes.rootWrapper}>
          <SurveysQuestionsList />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AddQuestionDialog;
