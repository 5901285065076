import OfficeBuildingIcon from "../icons/OfficeBuilding";
import CalendarIcon from "../icons/CalendarIcon";
import ContactIcon from "../icons/ContactIcon";
import PhoneIcon from "../icons/PhoneIcon";
import EmailIcon from "../icons/EmailIcon";
import React, { Fragment, useEffect } from "react";
import useStyles from "../styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { usePartnerMainInfo } from "./usePartnerMainInfo";
import { dateFormatField } from "../../../components/util/timeFormat";
import { ProfileUpdate } from "./ProfileUpdate";
import PartnerStatus from "../../partnersList/partnerStatus";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import PartnersDialog from "../../partnersList/dialog/partnersDialogs";
import { usePartnerDispatch } from "contexts/partnerContext";

export function PartnerHeader({ id, mode }) {
  const classes = useStyles();
  const dispatchPartners = usePartnerDispatch();
  const {
    partnerMainData,
    isPartnerMainInfoLoading,
    handleStatusChange,
    refetch,
  } = usePartnerMainInfo();

  useEffect(() => {
    dispatchPartners({
      type: "SET_PARTNER_DETAILS",
      partnerDetails: partnerMainData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerMainData]);

  return (
    <Fragment>
      {isPartnerMainInfoLoading ? (
        <LoadingIndicator />
      ) : mode === "edit" ? (
        <ProfileUpdate id={id} mode={mode} />
      ) : (
        <Box className={classes.partnersWrapper}>
          <Box className={classes.headerBox}>
            <Box className={classes.externalHeaderWrapper}>
              <Box>
                <Box className={classes.officeBuildingIconHolder}>
                  <OfficeBuildingIcon />
                </Box>
              </Box>
              <Box className={classes.innerHeaderWrapper}>
                <Box className={classes.titleWrapper}>
                  <Box className={classes.companyTitle}>
                    {partnerMainData?.company_name}
                  </Box>
                  <Box>
                    {isPartnerMainInfoLoading && <LoadingIndicator />}
                    {!isPartnerMainInfoLoading && (
                      <PartnerStatus
                        partnerSelected={partnerMainData}
                        readOnly={true}
                        onChange={handleStatusChange}
                      />
                    )}
                  </Box>
                </Box>
                <Box className={classes.infoWrapper}>
                  <Box className={classes.individualInfoWrapper}>
                    <CalendarIcon />
                    <Box>
                      Active Since:{" "}
                      {dateFormatField(partnerMainData?.created_at)}
                    </Box>
                  </Box>
                  <Hidden smDown>
                    <Box className={classes.verticalSeparator}></Box>
                  </Hidden>
                  <Box className={classes.individualInfoWrapper}>
                    <ContactIcon />
                    <Box>Primary Contact: {partnerMainData?.contact_name}</Box>
                  </Box>
                  <Hidden smDown>
                    <Box className={classes.verticalSeparator}></Box>
                  </Hidden>
                  <Box className={classes.individualInfoWrapper}>
                    <PhoneIcon />
                    <Box>{partnerMainData?.phone_number}</Box>
                  </Box>
                  <Hidden smDown>
                    <Box className={classes.verticalSeparator}></Box>
                  </Hidden>
                  <Box className={classes.individualInfoWrapper}>
                    <EmailIcon />
                    <Box>{partnerMainData?.contact_email}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <PartnersDialog refetchData={refetch} />
    </Fragment>
  );
}
