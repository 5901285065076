import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import { XIcon } from "../icons/XIcon";
import Typography from "@material-ui/core/Typography";
import FormInput from "../../../components/ui/FormContent/formInput";
import FormSelect from "../../../components/ui/FormContent/formSelect";
import { InformationCircle } from "../icons/InformationCircle";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";

export function StepTwoComponent({
  classes,
  toggleDrawer,
  errors,
  data,
  handleBlur,
  jobs,
  handleJobChange,
  handleCustomerChange,
  jobsValue,
  customersValue,
  isJobsLoading,
  transformedRoles,
  transFormedCustomers,
  transformJobData,
  isViewOnly,
  resetData,
}) {
  return (
    <Fragment>
      <Box className={classes.drawerTitleContainer}>
        <Box
          onClick={() => {
            toggleDrawer("right", false, false, null);
            resetData();
          }}
          className={classes.drawerCloseIcon}
        >
          <XIcon className={classes.drawerCloseIcon} />
        </Box>
        <Box className={classes.userDrawerStepCount}>
          <Typography className={classes.userDrawerStepCountText}>
            Step 2 of 2
          </Typography>
        </Box>
        <Typography className={classes.drawerTitle}>
          Role Information
        </Typography>
        {isViewOnly === false && (
          <Typography className={classes.drawerInfoText}>
            Tell us more about the role of this user.
          </Typography>
        )}
      </Box>
      <Box className={classes.drawerFormContainer}>
        <FormInput
          gridSizes={[{ size: "md", val: 12 }]}
          name="employee_number"
          error={errors}
          label="Employee Number*"
          data={data.employee_number}
          value={data.employee_number}
          handleBlur={handleBlur}
          placeholder="Write Employee Number"
          readOnly={isViewOnly}
          type="number"
          styleOverride={{ minWidth: "114px" }}
        />
        <FormSelect
          name="role_id"
          redirectIcon={false}
          readonly={false}
          error={errors}
          placeholder={"Select a Role"}
          label="Role *"
          gridSizes={[{ size: "md", val: 12 }]}
          options={transformedRoles}
          readOnly={isViewOnly}
          value={data.role_id}
          styleOverride={{ minWidth: "321px" }}
          handleBlur={handleBlur}
        />
        {isViewOnly === false && (
          <Box className={classes.addUserInformationContainer}>
            <InformationCircle />
            <Typography>
              Including removing a customer will include remove all customer’s
              jobs.
            </Typography>
          </Box>
        )}
        {!isJobsLoading && (
          <FormSelectAuto
            label="Locations"
            options={jobs?.length ? transformJobData(jobs) : []}
            gridSizes={[{ size: "md", val: 12 }]}
            value={jobsValue}
            handleChange={handleJobChange}
            readOnly={isViewOnly}
            name="name"
            multiple={true}
          />
        )}
        <FormSelectAuto
          label="Customers"
          options={transFormedCustomers}
          gridSizes={[{ size: "md", val: 12 }]}
          value={customersValue}
          handleChange={handleCustomerChange}
          readOnly={isViewOnly}
          name="name"
          multiple={true}
        />
      </Box>
    </Fragment>
  );
}
