import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useStyles from "./styles";

const options = [
  { id: 1, label: "Delete Item", value: "DELETE" },
  { id: 2, label: "Copy to All", value: "COPY" },
];

const WorkticketTimekeepingManualActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { mobile, handleDeleteRow, handleCopyToAll } = props;

  const classes = useStyles();

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    if (action === "DELETE") {
      handleDeleteRow();
    } else {
      handleCopyToAll();
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.actionMenuContainer}>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.iconButtonAction}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default WorkticketTimekeepingManualActions;
