import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import QuestionIcon from "@material-ui/icons/Help";

import GlobalUiContext from "contexts/globalUiContext";
import { useListDispatch } from "contexts/listContext";
import {
  deleteUser,
  resetPassword,
  getUsers,
  resendInvite,
} from "services/superService";
import { permissionAdmin, hasPermission } from "lib/permissions";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const options = [
  {
    id: 1,
    label: "Edit",
    value: "EDIT",
    permission: permissionAdmin.USER_EDIT,
  },
  {
    id: 2,
    label: "Delete",
    value: "DELETE",
    permission: permissionAdmin.USER_DELETE,
  },
  {
    id: 3,
    label: "Resend Invite",
    value: "RESEND",
    permission: permissionAdmin.USER_EDIT,
  },
];

const UserTableActions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [openResend, setOpenResend] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);

  const dispatchList = useListDispatch();
  const open = Boolean(anchorEl);
  const { row, mobile } = props;

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = async (event) => {
    const action = event.currentTarget.getAttribute("data");
    const { history } = props;

    if (action === "EDIT") {
      history.push(`/user/${row.id}`);
    }
    if (action === "DELETE") {
      setOpenDelete(true);
    }
    if (action === "RESEND") {
      setOpenResend(true);
    }
    if (action === "PASSWORD_RESET") {
      try {
        const data = {
          user_ids: [row.id],
        };
        await resetPassword(data);
      } catch (e) {
        logException(e, "Cannot reset password for user");
      }
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseResend = () => {
    setOpenResend(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingDelete(true);
      await deleteUser(row.id);
      const result = await getUsers();
      dispatchList({
        type: "SET_LIST",
        list: result.data.data.users,
      });
      dispatchList({
        type: "SET_COUNT",
        count: result.data.data.users.length,
      });
      setOpenDelete(false);
      setIsLoadingDelete(false);
    } catch (e) {
      logException(e, "Cannot delete user");
    }
  };

  const handleConfirmResend = async () => {
    try {
      setIsLoadingResend(true);
      await resendInvite(row.id);
      const result = await getUsers();
      dispatchList({
        type: "SET_LIST",
        list: result.data.data.users,
      });
      dispatchList({
        type: "SET_COUNT",
        count: result.data.data.users.length,
      });
      setOpenResend(false);
      setIsLoadingResend(false);
    } catch (e) {
      logException(e, "Cannot Resend Invitation");
    }
  };

  return (
    <div>
      {!mobile ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Options
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options
          .filter(
            (option) =>
              hasPermission(option.permission, permissions) &&
              ((option.value !== "DELETE" && option.value !== "RESEND") ||
                (option.value === "DELETE" && !row.associate_oid) ||
                (option.value === "RESEND" && row.validated === "Pending"))
          )
          .map((option) => (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
      <Dialog
        open={openDelete}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseDelete(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <QuestionIcon className={classes.iconQuestionDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Delete User
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete the user?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDelete}
            className={classes.button}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={classes.button}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isLoadingDelete}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openResend}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={() => handleCloseResend(false)}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <QuestionIcon className={classes.iconQuestionDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Resend Onboarding Invitation
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to resend the onboarding invitation?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseResend}
            className={classes.button}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmResend}
            className={classes.button}
            color="secondary"
            variant="contained"
            size="large"
            disabled={isLoadingResend}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(UserTableActions);
