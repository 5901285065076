import React from "react";
import CustomerLayout from "./customerLayout";

import { CustomerViewProvider } from "contexts/customerViewContext";

const Customer = (props) => {
  return (
    <CustomerViewProvider>
      <CustomerLayout id={props.match.params.id} {...props} />
    </CustomerViewProvider>
  );
};

export default Customer;
