import React, { lazy, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useDashboardState } from "contexts/dashboardContext";
import useStyles from "./styles";

const NotesList = lazy(() => import("pages/notesList/notesList"));

const NotesTab = (props) => {
  const classes = useStyles();
  const dashboardState = useDashboardState();
  const { history } = props;

  return (
    <Box className={classes.notesRoot}>
      <Box className={classes.header}>
        <Box>
          <Typography variant="h4" gutterBottom className={classes.kpiTitle}>
            Notes
          </Typography>
        </Box>
        <Box>
          <Box className={classes.headerActions}>
            <ButtonBase
              color="secondary"
              onClick={() => history.push("/notes")}
              disableElevation
            >
              View All
            </ButtonBase>
          </Box>
        </Box>
      </Box>
      <Box className={classes.tableBody}>
        <Suspense fallback={"Loading..."}>
          <NotesList isDashboard={true} dashboardState={dashboardState} />
        </Suspense>
      </Box>
    </Box>
  );
};

export default withRouter(NotesTab);
