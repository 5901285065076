import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
  questionFill,
} from "contexts/surveyFillContext";
import { surveysLabelsLang } from "constants.js";

const SurveyQuestionShortAnswer = (props) => {
  const classes = useStyles();
  const {
    selectedQuestions,
    correlative,
    lang,
    updateAnswers,
    isPreview,
    readonlySurvey,
  } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const response = selectedQuestions[correlative].response
    ? JSON.parse(selectedQuestions[correlative].response)
    : null;
  const selectedAnswer = response ? response.answer : "";
  const handleBlur = (event) => {
    if (!readonlySurvey) {
      if (selectedAnswer !== event.value) {
        try {
          const data = {
            response: { answer: event.value },
            status: 2,
            completed_using_es: lang !== "English",
          };
          if (!isPreview) {
            questionFill(
              dispatchSurveyFill,
              selectedQuestions[correlative].id,
              data,
              selectedQuestions[correlative].status === 1,
              !updateAnswers
            );
          }
          selectedQuestions[correlative].response = JSON.stringify(
            data.response
          );
          selectedQuestions[correlative].status = data.status;
          dispatchSurveyFill({
            type: "SET_SELECTED_QUESTIONS",
            selectedQuestions: selectedQuestions,
          });
        } catch (e) {
          logException(e, "Cannot update Answer");
        }
      }
    }
  };

  return (
    <Box>
      <Typography className={classes.quesTextSub}>
        {lang === "Spanish" &&
        selectedQuestions[correlative].description_es?.length
          ? selectedQuestions[correlative].description_es
          : selectedQuestions[correlative].description}
      </Typography>

      <List className={classes.questionShortList}>
        <ListItem>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box className={classes.shortAnswerContainer}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="answer"
                  withoutLabel={true}
                  rows={8}
                  value={selectedAnswer}
                  multiline={true}
                  fullWidth={true}
                  readonly={readonlySurvey}
                  placeholder={
                    surveysLabelsLang[lang].shortAnswerPlaceHolderLabel
                  }
                  handleBlur={handleBlur}
                />
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
};

export default SurveyQuestionShortAnswer;
