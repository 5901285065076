import React, { useState, useEffect, useCallback, useContext } from "react";
import QuoteTableActions from "../quotesList/quotesTableActions";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import QuotesBulkActions from "components/ui/Quotes/quotesBulkActions";
import CalendarFilters from "components/common/Filters/calendarFilters";
import QuoteStatusChip from "components/ui/Quotes/QuoteStatusChip";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import WorkticketFinancialField from "components/ui/Worktickets/WorkticketFinancialField";
import { formatNumber } from "components/util/stringFormat";
import ProjectTabQuoteActions from "./projectTabQuoteActions";
import useStyles from "./styles";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useQuoteState,
  useQuoteDispatch,
  getQuoteTabList,
  getQuoteTabListDataSet,
  searchQuotesTab,
} from "contexts/quoteTableContext";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";

import { useProjectView } from "contexts/projectViewContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const QuotesTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const { quoteListLoading, quoteList, quoteCount } = useQuoteState();
  const { filters, startDate, startDateActive, endDate, endDateActive } =
    useFilterState();
  const [stateContextProject] = useProjectView();
  const { selected } = useTableState();
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatchTable = useTableDispatch();
  const dispatchQuotes = useQuoteDispatch();
  const dispatchFilters = useFilterDispatch();
  const { project } = stateContextProject ? stateContextProject : null;

  const columns = [
    {
      id: "number",
      label: "Number",
      format: "id",
      path: "quote",
      checkbox: {
        active: (row) => ![5].includes(row.status),
      },
    },
    {
      id: "subject",
      label: "Name",
    },
    { id: "due_date", label: "Due Date", format: "date" },
    {
      id: "total",
      label: "Revenue",
      style: { textAlign: "right", marginRight: 16 },
      content: (row) =>
        row.type === 1
          ? "N/A"
          : `$ ${formatNumber(parseFloat(row.total ?? 0).toFixed(2))}`,
    },
    {
      id: "total_cost",
      label: "Cost",
      content: (row) =>
        row.is_completed
          ? `$ ${formatNumber(
              (
                parseFloat(row.actual_ap_cost ?? 0) +
                parseFloat(row.actual_inhouse_cost ?? 0)
              ).toFixed(2)
            )}`
          : `$ ${formatNumber(parseFloat(row.total_cost ?? 0).toFixed(2))}`,
    },
    {
      id: "margin",
      label: "Margin($)",
      content: (row) =>
        row.type === 1 || row.is_old_structure === 1 ? (
          "N/A"
        ) : (
          <WorkticketFinancialField
            field={(row.is_completed
              ? parseFloat(row.total ?? 0) -
                parseFloat(row.actual_ap_cost ?? 0) -
                parseFloat(row.actual_inhouse_cost ?? 0)
              : parseFloat(row.total ?? 0) - parseFloat(row.total_cost ?? 0)
            ).toFixed(2)}
            type={1}
          />
        ),
      disableSort: true,
    },
    {
      id: "margin",
      label: "Margin(%)",
      content: (row) => {
        const margin = row.is_completed
          ? parseFloat(row.total) -
            parseFloat(row.actual_ap_cost ?? 0) -
            parseFloat(row.actual_inhouse_cost ?? 0)
          : parseFloat(row.total ?? 0) - parseFloat(row.total_cost ?? 0);
        let marginPercentage = -100;
        if (parseInt(row.total ?? 0) !== 0) {
          marginPercentage = (margin / parseFloat(row.total)) * 100;
        }
        return row.type === 1 || row.is_old_structure === 1 ? (
          "N/A"
        ) : (
          <WorkticketFinancialField
            field={
              marginPercentage === -100 ? "0" : marginPercentage.toFixed(2)
            }
            type={2}
          />
        );
      },
      disableSort: true,
    },
    {
      id: "status",
      label: "Status",
      content: (row) => (
        <QuoteStatusChip
          status={row.status}
          archived={row.archived}
          dueDate={row.due_date}
          pendingPlanning={row.pending_planning}
          completed_date={row.completed_date}
        />
      ),
      disableSort: true,
    },
  ];

  if (!project.has_completed_worktickets) {
    columns.push({
      id: "actions",
      content: (row) => <QuoteTableActions row={row} project={project} />,
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };
      await getQuoteTabList(dispatchQuotes, project.id, filterData);
    };
    fetchData();
  }, [
    project,
    dispatchQuotes,
    endDate,
    endDateActive,
    filters,
    startDate,
    startDateActive,
  ]);

  useEffect(() => {
    const quoteData = quoteList ? quoteList : [];
    dispatchTable({ type: "UPDATE_ROWS", rows: quoteData });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, quoteList]);

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = {
            filters: filters,
            dateFilters: {
              startDate,
              startDateActive,
              endDate,
              endDateActive,
            },
          };
          dispatchQuotes({
            type: "SET_LOADING",
            quoteListLoading: true,
          });
          await searchQuotesTab(dispatchQuotes, project.id, query, filterData);

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    const fetchData = async () => {
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };
      await getQuoteTabList(dispatchQuotes, project.id, filterData);
    };
    fetchData();
  };

  const handleScrollClick = useCallback(async () => {
    const quoteData = quoteList ? quoteList : [];

    if (quoteCount <= quoteData.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = {
        filters: filters,
        dateFilters: {
          startDate,
          startDateActive,
          endDate,
          endDateActive,
        },
      };
      await getQuoteTabListDataSet(
        quoteList,
        dispatchQuotes,
        project.id,
        quoteData.length,
        filterData
      );
      const quoteDataUpdate = quoteList ? quoteList : [];
      dispatchTable({ type: "UPDATE_ROWS", rows: quoteDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [dispatchTable, dispatchQuotes, quoteList, project, quoteCount]);

  const handleRowClick = () => {};

  return (
    <Box className={classes.rootTable}>
      {quoteListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${quoteList.length} / ${quoteCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.fullHeightTableProjectQuote}>
              <Table
                handleRowClick={handleRowClick}
                newColumns={columns}
                handleScrollClick={handleScrollClick}
                activeMobile={true}
              >
                <EnhancedTableToolbar>
                  {selected.length > 0 ? (
                    <ToolbarSelected>
                      <QuotesBulkActions
                        selected={selected.length}
                        project={project}
                      />
                    </ToolbarSelected>
                  ) : (
                    <ToolbarDefault
                      handleSearch={handleSearch}
                      handleSearchClear={handleSearchClear}
                    >
                      <ProjectTabQuoteActions />
                      <CalendarFilters />
                    </ToolbarDefault>
                  )}
                </EnhancedTableToolbar>
                {(filters || startDateActive || endDateActive) && (
                  <FiltersAppliedList />
                )}
              </Table>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuotesTable;
