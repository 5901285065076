import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AssetsCategoriesTable from "./assetsCategoriesTable";
import Box from "@material-ui/core/Box";

import { DrawerProvider } from "contexts/detailsDrawerContext";
import { ListProvider } from "contexts/listContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const AssetsCategoriesList = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Categories" link="/assets/categories"></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <ListProvider>
          <DrawerProvider>
            <AssetsCategoriesTable />
          </DrawerProvider>
        </ListProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AssetsCategoriesList);
