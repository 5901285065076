import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { useWorkticketView } from "contexts/workticketViewContext";
import { workticketBillingAP } from "services/workticketService";

import useStyles from "./styles";

const WorkticketBilling = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [tab, setTab] = useState(0);
  const [listAP, setListAP] = useState([]);
  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    const loadAP = async () => {
      if (workticket?.id) {
        const resultWorkticketAP = await workticketBillingAP(workticket.id);
        setListAP(resultWorkticketAP.data.data.records);
      }
    };
    if (
      hasPermission(permissionWorkticket.VIEW_AP, permissions) ||
      hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions)
    ) {
      loadAP();
    }
  }, [workticket, permissions]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={20} />;
  }

  if (
    !Boolean(listAP.length) ||
    (!hasPermission(permissionWorkticket.VIEW_AP, permissions) &&
      !hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions))
  ) {
    return null;
  }
  return (
    <Box>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        className={classes.billingTabRoot}
      >
        <Tab label="Account Payable" value={0} className={classes.billingTab} />
      </Tabs>
      <Box>
        <Grid container className={classes.rootBillingHeader}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" gutterBottom>
              Subcontractor
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" gutterBottom>
              Payment
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" gutterBottom>
              Total
            </Typography>
          </Grid>
        </Grid>
        {listAP.map((item) => (
          <Grid key={item.id} container className={classes.rootBillingBody}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                {item.subcontractor?.first_name} {item.subcontractor?.last_name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" gutterBottom>
                $ {Number(item.amount_paid).toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" gutterBottom>
                $ {Number(item.total_amount).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default WorkticketBilling;
