import http from "./httpService";
import { apiUrl } from "lib/config";

const apiEndpointLunaSearch = `${apiUrl}/ai`;
const apiEndpointSlack = `${apiUrl}/slack-llm`;

export function getAiSearch(userEmail, userId, search) {
  return http.get(
    `${apiEndpointLunaSearch}/partners-search?user_email=${userEmail}&user_id=${userId}&prompt=${search}`
  );
}

export function getReasonFeedback() {
  return http.get(`${apiEndpointSlack}/reason-feedback`);
}

export function storePartnerFeedback(data) {
  return http.post(`${apiEndpointSlack}/store-partners-feedback`, data);
}
