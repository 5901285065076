import React, { useEffect, useState, useContext } from "react";
import Box from "@material-ui/core/Box";
import Table from "components/common/Table/table";
import QuoteStatusChip from "components/ui/Quotes/QuoteStatusChip";
import { useTableDispatch } from "contexts/tableContext";
import { withRouter } from "react-router-dom";
import useStyles from "../styles";
import QuoteDashboardSurfaceDialog from "../dialog/quoteDashboardSurfaceDialog";
import QuoteDashboardDialog from "../dialog/quoteDashboardDialog";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import QuoteSurfaceStatusChip from "components/ui/Quotes/QuoteSurfaceStatusChip";
const QuotesTable = ({ workTableData, isSurfaceUser }) => {
  const columns = [
    {
      checkbox: false,
    },
    {
      id: "number",
      label: "Number",
      content: (row) => (
        <span className="underline" onClick={() => openDetail(row.id)}>
          {row.number}
        </span>
      ),
    },
    {
      id: "subject",
      label: "Name",
      content: (row) => row.subject,
    },
    {
      id: "location",
      label: "Location",
      content: (row) => row?.job?.job_description,
    },

    {
      id: "amount",
      label: "Amount",
      content: (row) => "$" + Number(row.total).toFixed(2),
    },
    {
      id: "sentBy",
      label: "Sent By",
      content: (row) =>
        row?.sender
          ? row?.sender?.first_name + " " + row?.sender?.last_name
          : "-",
    },
    { id: "created_at", label: "Sent On", format: "date" },
    { id: "due_date", label: "Due Date", format: "date" },
    {
      id: "status",
      label: "Status",
      content: (row) =>
        isSurfaceUser ? (
          <QuoteSurfaceStatusChip
            status={row.status}
            archived={row.archived}
            dueDate={row.due_date}
            pendingPlanning={row.pending_planning}
          />
        ) : (
          <QuoteStatusChip
            status={row.status}
            archived={row.archived}
            dueDate={row.due_date}
            pendingPlanning={row.pending_planning}
          />
        ),
    },
  ];
  const classes = useStyles();
  const dispatchTable = useTableDispatch();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const openDetail = (id) => {
    setIsOpenDialog(true);
    setSelectedQuote(id);
  };

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: workTableData?.quotes });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "SET_HAS_SELECT", hasSelect: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable]);

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.rootContainer}>
          <Box className={classes.tableContainer}>
            <Box className="sliderHeightTable">
              <Table newColumns={columns} activeMobile={true}></Table>
            </Box>
          </Box>
        </Box>
      </Box>
      {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
        <QuoteDashboardSurfaceDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedQuote}
        />
      ) : (
        <QuoteDashboardDialog
          isOpen={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          id={selectedQuote}
        />
      )}
    </Box>
  );
};

export default withRouter(QuotesTable);
