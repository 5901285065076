import React from "react";
import LocationsSurveysSurfaceDialog from "./locationsSurveysSurfaceDialog";
import SurveyContent from "pages/survey/surveyContent";

const LocationsListSurfaceDialog = (props) => {
  return (
    <>
      <LocationsSurveysSurfaceDialog />
      <SurveyContent />
    </>
  );
};
export default LocationsListSurfaceDialog;
