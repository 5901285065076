import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
  },
  detail: {
    fontSize: 16,
    marginBottom: 16,
  },
  detailContainerL: {
    paddingRight: 8,
  },
  detailContainerR: {
    paddingLeft: 4,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  formContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  itemsContainerDetail: {
    marginTop: theme.spacing(1),
  },
  itemHeader: {
    fontSize: 16,
    fontWeight: "bold",
  },
  itemHeaderCenter: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  itemDetail: {
    fontSize: 16,
  },
  alignRight: {
    textAlign: "right",
  },
  itemSummary: {
    fontSize: 16,
    fontWeight: "bold",
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 24,
  },
  sectionInterHeader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 24,
    marginTop: 18,
  },
  taskItemFieldset: {
    width: "100%",
  },
  taskItemContainer: {
    marginTop: "10px !important",
  },
  taskLabel: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 12,
    position: "relative",
  },
  taskItem: {
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
    "& .MuiFormControlLabel-label": {
      display: "block",
      width: "100%",
    },
    "& .Mui-checked + span": {
      textDecoration: "line-through",
    },
  },
  containerActivity: {
    maxHeight: 500,
    overflowY: "auto",
  },
  listText: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
      paddingRight: 0,
    },
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  listAction: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      position: "relative !important",
      textAlign: "right",
    },
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  subTitleContainer: {
    marginTop: theme.spacing(3),
  },
  chipAuto: {
    height: "25px !important",
    fontSize: "11px !important",
    margin: "3px !important",
    marginRight: "8px !important",
    "& .MuiChip-avatar": {
      marginLeft: "0 !important",
    },
  },
  tableNotCheckbox: {
    margin: 9,
    color: "#ececec",
  },
  section: {
    borderTop: "1px solid #f8f8f8",
  },
  sectionContainer: {
    marginTop: theme.spacing(3),
  },
  sectionInterHeader2: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 18,
    marginBottom: 8,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
}));

export default useStyles;
