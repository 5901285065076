import React from "react";
import merge from "lodash/merge";
import {
  getUserLocations,
  getUserLocationsSearch,
  getUserLocationsOffset,
} from "services/teamService";

const defaultState = {
  userLocationsListLoading: true,
  userLocationsSelected: null,
  userLocationsList: [],
  userLocationsCount: [],
};

const UserLocationsStateContext = React.createContext();
const UserLocationsDispatchContext = React.createContext();

function userLocationsReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        userLocationsListLoading: action.userLocationsListLoading,
      };
    case "SET_USER_LOCATIONS_LIST":
      return {
        ...state,
        userLocationsList: action.userLocationsList,
      };
    case "SET_USER_LOCATIONS_COUNT":
      return {
        ...state,
        userLocationsCount: action.userLocationsCount,
      };
    case "SET_USER_LOCATIONS_SELECTED":
      return {
        ...state,
        userLocationsSelected: action.userLocationsSelected,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserLocationsProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(userLocationsReducer, mergedState);
  return (
    <UserLocationsStateContext.Provider value={state}>
      <UserLocationsDispatchContext.Provider value={dispatch}>
        {children}
      </UserLocationsDispatchContext.Provider>
    </UserLocationsStateContext.Provider>
  );
}

function useUserLocationsState() {
  const context = React.useContext(UserLocationsStateContext);
  if (context === undefined) {
    throw new Error(
      "useUserLocationsState must be used within a LocationProvider"
    );
  }
  return context;
}

function useUserLocationsDispatch() {
  const context = React.useContext(UserLocationsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDispatch must be used within a LocationProvider"
    );
  }
  return context;
}
async function getUserLocationsList(userId, dispatch, filterData) {
  try {
    dispatch({
      type: "SET_LOADING",
      userLocationsListLoading: true,
    });
    const result = await getUserLocations(userId, filterData);
    dispatch({
      type: "SET_USER_LOCATIONS_LIST",
      userLocationsList: [...result.data.data.locations],
    });
    dispatch({
      type: "SET_USER_LOCATIONS_COUNT",
      userLocationsCount: result.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      userLocationsListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getUserLocationsListDataSet(
  userId,
  userLocationsList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await getUserLocationsOffset(userId, offset, filterData);
    const updateData = userLocationsList.concat(result.data.data.locations);
    dispatch({
      type: "SET_USER_LOCATIONS_LIST",
      userLocationsList: updateData,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchUserLocationsList(userId, search, filterData) {
  try {
    const result = await getUserLocationsSearch(userId, search, filterData);
    return result.data.data.locations;
  } catch (error) {
    console.log(error);
  }
}

export {
  UserLocationsProvider,
  useUserLocationsState,
  useUserLocationsDispatch,
  getUserLocationsList,
  getUserLocationsListDataSet,
  searchUserLocationsList,
};
