import React from "react";
import { merge } from "lodash";

const defaultState = {
  open: false,
  message: "",
  confirm: false,
  title: "",
  actionText: ""
};

const DialogStateContext = React.createContext();
const DialogDispatchContext = React.createContext();

function dialogReducer(state, action) {
  //console.log(action);
  switch (action.type) {
    case "SET_INITIAL_PROPS":
      return {
        ...state,
        open: action.props.open,
        message: action.props.message,
        title: action.props.title,
        confirm: action.props.confirm,
        actionText: action.props.actionText
      };
    case "OPEN":
      return {
        ...state,
        open: action.open
      };
    case "MESSAGE":
      return {
        ...state,
        message: action.message
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DialogProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(dialogReducer, mergedState);
  return (
    <DialogStateContext.Provider value={state}>
      <DialogDispatchContext.Provider value={dispatch}>
        {children}
      </DialogDispatchContext.Provider>
    </DialogStateContext.Provider>
  );
}

function useDialogState() {
  const context = React.useContext(DialogStateContext);
  if (context === undefined) {
    throw new Error("useDialogState must be used within a DialogProvider");
  }
  return context;
}

function useDialogDispatch() {
  const context = React.useContext(DialogDispatchContext);
  if (context === undefined) {
    throw new Error("useDialogDispatch must be used within a DialogProvider");
  }
  return context;
}

export { DialogProvider, useDialogState, useDialogDispatch };
