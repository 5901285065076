import React, { useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInput from "components/ui/FormContent/formInput";
import FormTotal from "components/ui/FormContent/formTotal";
import LinearProgress from "@material-ui/core/LinearProgress";
import Joi from "joi-browser";
import { logException } from "components/util/logUtil";
import { getCommodities } from "services/commodityService";
import { getGoods } from "services/goodService";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { ButtonBase } from "components/ui/core/button";
import { validate, validateProperty } from "components/util/validationUtil";
import {
  useQuoteState,
  useQuoteDispatch,
  addQuoteItems,
  updateQuoteItems,
} from "contexts/quoteContext";
import { calcPriceMargin } from "lib/marginPrice";
import MessageDialog from "./dialog/messageDialog";
import {
  useQuoteItemDrawerDispatch,
  useQuoteItemDrawerState,
} from "contexts/quoteItemDrawerContext";
import { billingOptions } from "constants.js";
import useStyles from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import { validateUpdateQuoteItem } from "services/quoteService";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionQuote, hasPermission } from "lib/permissions";

const initialCommodity = { label: "All", value: -1 };

const quoteItemStep0Schema = {
  workItem: Joi.object()
    .required()
    .label("Service")
    .error(() => {
      return {
        message: "Good is required.",
      };
    }),
  quantity: Joi.number().required().min(0).label("Quantity"),
  price: Joi.number().required().min(0).label("Price"),
  total: Joi.number().required().min(0).label("Total"),
  markup: Joi.number().required().min(0).label("Markup"),
  billing: Joi.object()
    .required()
    .label("Billing")
    .error(() => {
      return {
        message: "Billing Type is required.",
      };
    }),
};

const InitialData = {
  item_type: "good",
  quantity: 0,
  uom: "",
  price: 0,
  hours: 0,
  group: 1,
  total: 0,
  productivity: null,
  priceType: null,
  task_list: [],
  commodityId: -1,
  commodity: initialCommodity,
  workItemId: null,
  workItem: null,
  billing_type: null,
  billing: null,
  summary: "",
  description: "",
  total_cost: 0,
  markup: 0,
  files: [],
  filesDisplay: [],
};

const QuoteItemGood = (props) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [schema, setSchema] = useState(quoteItemStep0Schema);
  const [data, setData] = useState(InitialData);
  const [updatedData, setUpdatedData] = useState({});
  const [commodities, setCommodities] = useState(null);
  const [isLoadingCommodities, setIsloadingCommodities] = useState(true);
  const [isLoadingGoods, setIsloadingGoods] = useState(true);
  const [services, setServices] = useState(null);
  const [error, setError] = useState([]);
  const { quoteDetails, selectedQuoteItem } = useQuoteState();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isloadingMessage, setIsloadingMessage] = useState(false);
  const dispatchQuote = useQuoteDispatch();
  const dispatchQuoteItemDrawer = useQuoteItemDrawerDispatch();
  const { fromMainQuote } = useQuoteItemDrawerState();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const readOnlyGlobal =
    [20].includes(quoteDetails.status) ||
    quoteDetails.is_old_structure ||
    !hasEdit;

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    if (updatedData.total) {
      console.log(error);
      validate(schema, data, setError);
    }
  }, [updatedData?.total]);

  const getItemGoods = async (catId, checkInitialLoad) => {
    try {
      const data = await getGoods(catId);
      const resultForRender = data.data.data.goods.map((item) => {
        return {
          value: item.id,
          label: item.name,
          ...item,
        };
      });
      setServices(resultForRender);
      if (checkInitialLoad) {
        if (selectedQuoteItem) {
          const selectedGood = resultForRender.filter(
            (item) => item.value === selectedQuoteItem.workItemId
          );
          const selectedBilling = billingOptions.filter(
            (item) => item.value === selectedQuoteItem.billing_type
          );
          const it = JSON.parse(selectedGood[0].item);
          let price = parseFloat(it.price);
          let found = null;
          const exceptionsObject = JSON.parse(selectedGood[0].exception);
          const { exceptions } = exceptionsObject;
          if (exceptions.length > 0) {
            const exJobs = exceptions.filter((ex) => ex.type === "Job");

            for (let i = 0; i < exJobs.length; i++) {
              for (let j = 0; j < exJobs[i].values.length; j++) {
                if (exJobs[i].values[j].id === quoteDetails.job.job_number)
                  found = exJobs[i];
              }
            }

            if (!found) {
              const exMA = exceptions.filter((ex) => ex.type === "Customer");
              for (let i = 0; i < exMA.length; i++) {
                for (let j = 0; j < exMA[i].values.length; j++) {
                  if (exMA[i].values[j].id === quoteDetails.customer.id)
                    found = exMA[i];
                }
              }
            }

            if (!found) {
              const exIndustry = exceptions.filter(
                (ex) => ex.type === "Industry"
              );
              for (let i = 0; i < exIndustry.length; i++) {
                for (let j = 0; j < exIndustry[i].values.length; j++) {
                  if (
                    exIndustry[i].values[j].id ===
                    parseInt(quoteDetails.job.division)
                  )
                    found = exIndustry[i];
                }
              }
            }
          }
          if (found) {
            if (found.priceType) {
              price =
                Math.round(
                  calcPriceMargin(
                    parseFloat(found.price),
                    parseFloat(it.price)
                  ) * 100
                ) / 100;
            } else {
              price = Math.round(parseFloat(found.price) * 100) / 100;
            }
          }
          price = it.unit === "1000 ft²" ? price / 1000 : price;
          setData({
            item_type: selectedQuoteItem.item_type,
            quantity: parseFloat(selectedQuoteItem.quantity),
            uom: selectedQuoteItem.uom,
            price: parseFloat(selectedQuoteItem.price),
            markup: parseFloat(selectedQuoteItem.markup ?? 0),
            hours: parseFloat(selectedQuoteItem.hours),
            total: parseFloat(selectedQuoteItem.total),
            productivity: selectedQuoteItem.productivity,
            priceType: selectedQuoteItem.priceType,
            task_list: selectedQuoteItem.tasks,
            commodityId: -1,
            commodity: initialCommodity,
            workItemId: selectedQuoteItem.workItemId,
            workItem: selectedGood?.length === 1 ? selectedGood[0] : null,
            summary: selectedQuoteItem.summary,
            description: selectedQuoteItem.description,
            total_cost: parseFloat(selectedQuoteItem.total_cost),
            files: [],
            filesDisplay: selectedQuoteItem.files,
            billing_type: selectedQuoteItem.billing_type,
            billing:
              selectedBilling?.length === 1
                ? selectedBilling[0]
                : quoteDetails.is_old_structure
                ? billingOptions[1]
                : null,
          });
        }
      }
      setIsloadingGoods(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadCommodities = async () => {
      try {
        const dataCom = await getCommodities();
        const resultForRender = dataCom.data.data.commodities.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (quoteDetails.type === 1 || quoteDetails.is_old_structure) {
          const value = billingOptions[1];
          const newData = {
            ...data,
            billing: value,
            billing_type: value ? value.value : null,
          };
          setData(newData);
        }
        setCommodities([initialCommodity, ...resultForRender]);
        setIsloadingCommodities(false);
        getItemGoods(-1, true);
      } catch (e) {
        logException(e, "Cannot load categories data");
      }
    };
    if (!commodities) {
      loadCommodities();
    }
  }, [commodities]);

  const handleChangeCommodity = (event, value) => {
    const newData = {
      ...data,
      commodity: value,
      commodityId: value?.value,
      workItemId: null,
      workItem: null,
      uom: "",
      price: 0,
      hours: 0,
      total: 0,
    };
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData({
        ...updatedData,
        commodityId: value ? value.value : null,
      });
    }
    setIsloadingGoods(true);
    if (value) {
      getItemGoods(value.value);
    } else {
      getItemGoods(-1);
    }
  };

  const handleChangeWorkItem = (event, value) => {
    if (value) {
      const it = JSON.parse(value.item);
      let found = null;
      let price = parseFloat(value.price);
      const exceptionsObject = JSON.parse(value.exception);
      const { exceptions } = exceptionsObject;
      if (exceptions.length > 0) {
        const exJobs = exceptions.filter((ex) => ex.type === "Job");

        for (let i = 0; i < exJobs.length; i++) {
          for (let j = 0; j < exJobs[i].values.length; j++) {
            if (exJobs[i].values[j].id === quoteDetails.job.job_number)
              found = exJobs[i];
          }
        }

        if (!found) {
          const exMA = exceptions.filter((ex) => ex.type === "Customer");
          for (let i = 0; i < exMA.length; i++) {
            for (let j = 0; j < exMA[i].values.length; j++) {
              if (exMA[i].values[j].id === quoteDetails.customer.id)
                found = exMA[i];
            }
          }
        }

        if (!found) {
          const exIndustry = exceptions.filter((ex) => ex.type === "Industry");
          for (let i = 0; i < exIndustry.length; i++) {
            for (let j = 0; j < exIndustry[i].values.length; j++) {
              if (
                exIndustry[i].values[j].id ===
                parseInt(quoteDetails.job.division)
              )
                found = exIndustry[i];
            }
          }
        }
      }
      if (found) {
        if (found.priceType) {
          price =
            Math.round(
              calcPriceMargin(parseFloat(found.price), parseFloat(it.price)) *
                100
            ) / 100;
        } else {
          price = Math.round(parseFloat(found.price) * 100) / 100;
        }
      }

      const newData = {
        ...data,
        workItem: value,
        workItemId: value ? value.value : null,
        uom: it.UOM,
        price,
        priceType: "MEDIUM",
        total:
          quoteDetails.type !== 1
            ? Math.round(
                price *
                  parseFloat(data.quantity) *
                  (1 + parseFloat(data.markup) / 100) *
                  100
              ) / 100
            : 0,
        total_cost: Math.round(price * parseFloat(data.quantity) * 100) / 100,
      };
      setData(newData);
      if (selectedQuoteItem) {
        setUpdatedData({
          ...updatedData,
          workItemId: value ? value.value : null,
          item_subject: value?.label,
          uom: it.UOM,
          price,
          priceType: "MEDIUM",
          total:
            quoteDetails.type !== 1
              ? Math.round(
                  price *
                    parseFloat(data.quantity) *
                    (1 + parseFloat(data.markup) / 100) *
                    100
                ) / 100
              : 0,
          total_cost: Math.round(price * parseFloat(data.quantity) * 100) / 100,
        });
      }
    } else {
      const newData = {
        ...data,
        workItem: null,
        workItemId: null,
        uom: "",
        hours: 0,
        price: 0,
        total: 0,
      };
      setData(newData);
    }
    validateProperty("workItem", value, schema, error, setError);
  };

  const handleChangeBilling = (event, value) => {
    const newData = {
      ...data,
      billing: value,
      billing_type: value ? value.value : null,
    };
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData({
        ...updatedData,
        billing_type: value ? value.value : null,
      });
    }
    validateProperty("billing", value, schema, error, setError);
  };

  const handleBlur = (event, value) => {
    let newData = {};
    let updateData = {};
    let total = 0;
    let total_cost = 0;
    switch (event.name) {
      case "quantity":
        total =
          quoteDetails.type !== 1
            ? Math.round(
                parseFloat(data.price) *
                  parseFloat(event.value) *
                  (1 + parseFloat(data.markup) / 100) *
                  100
              ) / 100
            : 0;

        total_cost =
          Math.round(data.price * parseFloat(event.value) * 100) / 100;

        newData = {
          ...data,
          quantity: event.value,
          total,
          total_cost,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            quantity: event.value,
            total,
            total_cost,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "price":
        total =
          quoteDetails.type !== 1
            ? Math.round(
                parseFloat(data.quantity) *
                  parseFloat(event.value) *
                  (1 + parseFloat(data.markup) / 100) *
                  100
              ) / 100
            : 0;
        total_cost =
          Math.round(data.quantity * parseFloat(event.value) * 100) / 100;
        newData = {
          ...data,
          price: event.value,
          total,
          total_cost,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            price: event.value,
            total,
            total_cost,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "markup":
        total =
          quoteDetails.type !== 1
            ? Math.round(
                parseFloat(data.quantity) *
                  parseFloat(data.price) *
                  (1 + parseFloat(event.value) / 100) *
                  100
              ) / 100
            : 0;
        newData = {
          ...data,
          markup: event.value,
          total,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
            markup: event.value,
            total,
          };
        }
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      default:
        newData = {
          ...data,
        };
        if (selectedQuoteItem) {
          updateData = {
            ...updatedData,
          };
        }
        break;
    }
    setData(newData);
    if (selectedQuoteItem) {
      setUpdatedData(updateData);
    }
  };

  const handleComplete = async () => {
    const errors = validate(schema, data, setError);
    if (!Boolean(errors)) {
      let nologicErrors = true;
      if (quoteDetails.status === 5 && step === 0 && selectedQuoteItem) {
        const validationObject = {};
        if (
          updatedData.total &&
          updatedData.total !== selectedQuoteItem.total
        ) {
          validationObject.total = updatedData.total;
        }
        if (
          updatedData.billing_type &&
          updatedData.billing_type !== selectedQuoteItem.billing_type
        ) {
          validationObject.billing_type = updatedData.billing_type;
        }
        validationObject.id = selectedQuoteItem.id;
        const result = await validateUpdateQuoteItem(quoteDetails.id, {
          quote_item: validationObject,
        });
        if (result?.data?.data?.errors) {
          const { errors: valErrors } = result.data.data;
          const errorsToShow = [];
          Object.keys(valErrors).map((key, index) => {
            nologicErrors = false;
            errorsToShow.push({ key, message: valErrors[key] });
          });
          setError(errorsToShow);
        }
      }
      if (nologicErrors) {
        try {
          if (selectedQuoteItem) {
            const dataUpdate = {
              ...updatedData,
              id: selectedQuoteItem.id,
            };
            setOpenMessage(true);
            setIsloadingMessage(true);
            await updateQuoteItems(
              dispatchQuote,
              quoteDetails.id,
              dataUpdate,
              fromMainQuote
            );
            setIsloadingMessage(false);
            setMessage("This item has been updated.");
          } else {
            const dataInsert = {
              ...data,
              is_editable: true,
              is_deletable: true,
              item_subject: data.workItem.name,
              customer_id: quoteDetails.customer_id,
            };
            setOpenMessage(true);
            setIsloadingMessage(true);
            await addQuoteItems(
              dispatchQuote,
              quoteDetails.id,
              dataInsert,
              true
            );
            setIsloadingMessage(false);
            setMessage("This item has been added.");
          }
        } catch (e) {
          logException(e, "Cannot create quote service item");
        }
      }
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
    dispatchQuoteItemDrawer({
      type: "SET_IS_OPEN",
      isOpen: false,
    });
  };

  return (
    <>
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        isLoading={isloadingMessage}
        handleClose={closeMessage}
        message={message}
      />
      <Box className={classes.currentStepContainer}>
        <Typography className={classes.currentStep}></Typography>
      </Box>
      {step === 0 && (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              {`${selectedQuoteItem ? "Update" : "Add"} Supplies`}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Input details on the supplies that you need.
            </Typography>
          </Box>
          <Box className={classes.optionWrapperQuoteItem}>
            <Box className={classes.containerInformationCreate}>
              {isLoadingCommodities ? (
                <LinearProgress color="secondary" />
              ) : (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={commodities}
                  name="commodity"
                  internal={false}
                  error={error}
                  handleChange={handleChangeCommodity}
                  label="Commodity"
                  readonly={readOnlyGlobal}
                  value={data.commodity}
                />
              )}
              {isLoadingGoods ? (
                <LinearProgress color="secondary" />
              ) : (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={services}
                  name="workItem"
                  internal={false}
                  error={error}
                  handleChange={handleChangeWorkItem}
                  readonly={readOnlyGlobal}
                  label="Item"
                  value={data.workItem}
                />
              )}
              <FormInput
                gridSizes={[{ size: "md", val: 6 }]}
                name="quantity"
                label="Quantity"
                error={error}
                internal={false}
                value={data.quantity !== null ? data.quantity : ""}
                handleBlur={handleBlur}
                readonly={readOnlyGlobal}
                endText={data.uom}
              />
              {quoteDetails.type !== 1 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="price"
                  label="Price"
                  startAdornment="$"
                  alignText="right"
                  error={error}
                  readonly={readOnlyGlobal}
                  internal={false}
                  value={data.price !== null ? data.price : ""}
                  handleBlur={handleBlur}
                />
              ) : (
                ""
              )}
              {quoteDetails.type !== 1 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 6 }]}
                  name="markup"
                  label="Markup"
                  error={error}
                  internal={false}
                  value={data.markup !== null ? data.markup : ""}
                  handleBlur={handleBlur}
                  readonly={readOnlyGlobal}
                  endText={"%"}
                />
              ) : (
                ""
              )}
              <FormTotal
                name="total"
                error={error}
                label="Total Price"
                subLabel="(Quantity X Price + Markup)"
                value={`$ ${formatNumber(parseFloat(data.total).toFixed(2))}`}
                icon={
                  <MonetizationOnIcon
                    className={
                      quoteDetails.type === 1
                        ? classes.informationIconContract
                        : classes.informationIcon
                    }
                  />
                }
                text={
                  quoteDetails.type === 1
                    ? "This is a project with no billing"
                    : "What you are charging for the service"
                }
              />
              {quoteDetails.type !== 1 ? (
                quoteDetails.status === 5 && selectedQuoteItem ? (
                  <Tooltip title="This field can't be changed after quote has been approved.">
                    <Box>
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={billingOptions}
                        name="billing"
                        internal={false}
                        error={error}
                        readonly={true}
                        label="Billing Options"
                        value={data.billing}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={billingOptions}
                    name="billing"
                    internal={false}
                    error={error}
                    readonly={readOnlyGlobal}
                    handleChange={handleChangeBilling}
                    label="Billing Options"
                    value={data.billing}
                  />
                )
              ) : (
                ""
              )}
            </Box>
            <Box className={classes.actionBoxQuoteItem}>
              <ButtonBase
                color="secondary"
                onClick={handleComplete}
                disabled={readOnlyGlobal}
              >
                {selectedQuoteItem ? "Update Item" : "Add Item"}
              </ButtonBase>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default QuoteItemGood;
