import React, { useState, useEffect, useCallback, useContext } from "react";
import { withRouter } from "react-router-dom";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import EvaluationsFilters from "./evaluationsFilters";
import LinearProgress from "@material-ui/core/LinearProgress";
import EvaluationsTableActions from "./evaluationsTableActions";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import EvaluationsBulkActions from "./evaluationsBulkActions";
import { useIndividualView } from "contexts/individualViewContext";
import { ButtonBase } from "components/ui/core/button";
import useStyles from "./styles";
import { permissionTalent, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useEvaluationsState,
  useEvaluationsDispatch,
  getEvaluationsList,
  getEvaluationsListDataSet,
  searchEvaluationsList,
} from "contexts/evaluationsContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import {
  useSurveyFillState,
  useSurveyFillDispatch,
} from "contexts/surveyFillContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "submitted_for",
    label: "Submitted For",
    path: "submitted_for",
  },
  {
    id: "completed_date",
    label: "Date Submitted",
    path: "completed_date",
    format: "date",
  },
  {
    id: "submitted_by",
    label: "Submitted By",
    path: "submitted_by",
  },
  {
    id: "survey_name",
    label: "Survey",
    path: "survey_name",
  },
  {
    id: "score",
    label: "Score",
    content: (row) =>
      !row.total_score && parseInt(row.total_score) !== 0
        ? "-"
        : `${(parseFloat(row.total_score) * 100).toFixed(1)} %`,
  },
  {
    id: "actions",
    content: (row) => <EvaluationsTableActions row={row} />,
    contentMobile: (row) => <EvaluationsTableActions row={row} mobile={true} />,
    style: { width: 55 },
  },
];

const EvaluationsTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [stateContext] = useIndividualView();
  const { individual } = stateContext ?? null;
  const { evaluationsListLoading, evaluationsList, evaluationsCount } =
    useEvaluationsState();
  const { selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchEvaluations = useEvaluationsDispatch();
  const dispatchFilters = useFilterDispatch();
  const { updateListData } = useSurveyFillState();
  const dispatchSurveyFill = useSurveyFillDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
        {
          filter: "skipJobConditions",
          group: "skipJobConditions",
          values: [{ label: "", value: 1 }],
        },
        {
          filter: "status",
          group: "status",
          values: [{ label: "", value: 2 }],
        },
      ];
      await getEvaluationsList(dispatchEvaluations, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchEvaluations, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
        {
          filter: "skipJobConditions",
          group: "skipJobConditions",
          values: [{ label: "", value: 1 }],
        },
        {
          filter: "status",
          group: "status",
          values: [{ label: "", value: 2 }],
        },
      ];
      await getEvaluationsList(dispatchEvaluations, filterData);
      dispatchSurveyFill({
        type: "TOGGLE_UPDATE_LIST_DATA",
        updateListData: false,
      });
      dispatchSurveyFill({
        type: "SET_CALLED_FROM",
        calledFrom: null,
      });
    };
    if (updateListData) {
      fetchData();
    }
  }, [updateListData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: evaluationsList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, evaluationsList]);

  const handleScrollClick = useCallback(async () => {
    if (evaluationsCount <= evaluationsList.length) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      filterData.filters = [
        ...filterData.filters,
        {
          filter: "submittedFor",
          group: "Submitted For",
          values: [{ label: "", value: individual.id }],
        },
        {
          filter: "skipJobConditions",
          group: "skipJobConditions",
          values: [{ label: "", value: 1 }],
        },
        {
          filter: "status",
          group: "status",
          values: [{ label: "", value: 2 }],
        },
      ];
      await getEvaluationsListDataSet(
        evaluationsList,
        dispatchEvaluations,
        evaluationsList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: evaluationsList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    evaluationsList,
    evaluationsCount,
    filterState,
    dispatchEvaluations,
    dispatchTable,
  ]);

  const handleRowClick = (event, rowId) => {
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          filterData.filters = [
            ...filterData.filters,
            {
              filter: "submittedFor",
              group: "Submitted For",
              values: [{ label: "", value: individual.id }],
            },
            {
              filter: "skipJobConditions",
              group: "skipJobConditions",
              values: [{ label: "", value: 1 }],
            },
            {
              filter: "status",
              group: "status",
              values: [{ label: "", value: 2 }],
            },
          ];
          const results = await searchEvaluationsList(query, filterData);

          dispatchEvaluations({
            type: "SET_EVALUATIONS_LIST",
            evaluationsList: results,
          });

          dispatchEvaluations({
            type: "SET_EVALUATIONS_COUNT",
            evaluationsCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterEvaluations: [],
    });
  };

  const handleEvaluate = (event) => {
    dispatchEvaluations({
      type: "TOGGLE_OPENSURVEY",
      openSurveyDialog: true,
    });
  };

  return (
    <Box className={classes.root}>
      {evaluationsListLoading || loadingSearch ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentCounter}>
              <Chip
                label={`${evaluationsList.length} / ${evaluationsCount}`}
                color="secondary"
                variant="outlined"
              />
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <EvaluationsBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          {hasPermission(
                            permissionTalent.EVALUATE,
                            permissions
                          ) ? (
                            <Box className={classes.listActionContainer}>
                              <ButtonBase
                                variant="outlined"
                                color="secondary"
                                onClick={handleEvaluate}
                              >
                                Evaluate
                              </ButtonBase>
                            </Box>
                          ) : (
                            ""
                          )}
                          <CalendarFilters />
                          <EvaluationsFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(EvaluationsTable);
