import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import FieldContainerFilter from "components/common/Filters/fieldContainerFilter";
import useStyles from "components/common/Filters/styles";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import { useFilterDispatch, useFilterState } from "contexts/filterContext";
import GlobalUiContext from "contexts/globalUiContext";
import React, { useContext, useEffect, useState } from "react";

const FormsTabFilters = (props) => {
  const classes = useStyles();

  const [jobFilters, setJobFilters] = useState([]);
  const [typeFilters, setPositionFilters] = useState([]);

  const [userFilters, setManagerFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [includeChild] = useState(true);

  const [users, setManagers] = useState(null);

  const { filters } = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (anchorEl && !jobs.length) {
      loadJob();
    }
  }, [anchorEl, jobs]);

  useEffect(() => {
    const { users } = globalUi;
    const userOptions = [];
    users?.map((v) => {
      userOptions.push({
        label: v?.first_name + " " + v?.last_name,
        value: v?.id,
      });
    });
    setManagers(userOptions);
  }, [anchorEl]);

  useEffect(() => {
    if (anchorEl) {
      if (jobs.length) {
        const selectedJobFilter = filters.find(
          (filter) => filter.filter === "id"
        );
        if (selectedJobFilter) {
          const selectedValue = selectedJobFilter.values.map(
            (job) => job.value
          );
          const findJob = jobs.filter((job) => selectedValue.includes(job.id));
          setJobFilters(findJob);
        } else {
          setJobFilters([]);
        }
      }
      if (users) {
        const selectedManagersFilter = filters.find(
          (filter) => filter.filter === "user"
        );
        if (selectedManagersFilter) {
          setManagerFilters(selectedManagersFilter.values);
        } else {
          setManagerFilters([]);
        }
      }
    }
  }, [anchorEl, filters, jobs, users]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };
  const handleChangeType = (event, value) => {
    setPositionFilters(value);
  };

  const handleApply = async () => {
    const filterData = [];

    if (jobFilters.length) {
      const jobFilterConvert = jobFilters.map((job) => {
        return {
          label: `${job.job_number} - ${job.job_description}`,
          value: job.id,
          id: job.id,
          job_type: job?.job_type,
          parent_job_id: job?.parent_job_id,
        };
      });
      filterData.push({
        filter: "job",
        group: "Job",
        values: [...jobFilterConvert],
      });
    }

    if (userFilters.length) {
      filterData.push({
        filter: "user",
        group: "User",
        values: [...userFilters],
      });
    }

    if (typeFilters.length) {
      filterData.push({
        filter: "type",
        group: "Type",
        values: [...typeFilters],
      });
    }

    dispatchFilters({
      type: "SET_FILTERS_CORE",
      filters: filterData,
    });
    localStorage.setItem(
      `filter_${window.location.pathname.split("/")[1]}`,
      JSON.stringify(filterData)
    );
    handleClose();
  };

  const handleChangeJob = (event, value) => {
    if (!includeChild) {
      setJobFilters(value);
      return;
    }

    let mode = "add";
    if (value.length < jobFilters.length) {
      mode = "remove";
    }

    if (mode === "add") {
      const jobCurrent = jobFilters.map((job) => job.id);
      const jobNew = value.find((job) => !jobCurrent.includes(job.id));
      let children = [];
      if (jobNew && Number(jobNew?.job_type) === 1) {
        children = jobs.filter(
          (job) => Number(job?.parent_job_id) === Number(jobNew?.id)
        );
      }
      setJobFilters([...value, ...children]);
      return;
    }

    if (mode === "remove") {
      const jobCurrent = value.map((job) => job.id);
      const jobRemove = jobFilters.find((job) => !jobCurrent.includes(job.id));
      let valueNew = value;
      if (jobRemove && Number(jobRemove?.job_type) === 1) {
        valueNew = value.filter(
          (job) => Number(job?.parent_job_id) !== Number(jobRemove?.id)
        );
      }
      setJobFilters(valueNew);
      return;
    }
  };

  const handleChangeManager = (event, value) => {
    setManagerFilters(value);
  };

  return (
    <Box className={classes.filter}>
      <Button
        className={classes.tableFilterBtn}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter <ExpandMore />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.filterPopperContainer}>
          <FieldContainerFilter title="Job" isLoading={!Boolean(jobs.length)}>
            <FormSelectJobsAuto
              options={jobs}
              name="id"
              label="Job"
              handleChange={handleChangeJob}
              multiple={true}
              value={jobFilters}
            />
          </FieldContainerFilter>

          <FieldContainerFilter title="User" isLoading={!Boolean(users)}>
            <FormSelectAuto
              options={users}
              name="user"
              handleChange={handleChangeManager}
              multiple={true}
              value={userFilters}
            />
          </FieldContainerFilter>
          {props?.topTab === "forms?talent" ? (
            ""
          ) : (
            <FieldContainerFilter title="Type">
              <FormSelectAuto
                options={
                  props?.topTab === "forms?assets"
                    ? [
                        { label: "Transfer", value: 2 },
                        { label: "Repair", value: 1 },
                      ]
                    : [
                        { label: "First Report of Injury", value: 2 },
                        { label: "Investigation", value: 3 },
                        { label: "Follow up Request", value: 1 },
                      ]
                }
                gridSizes={[{ size: "md", val: 12 }]}
                name="tier_classification"
                internal={true}
                handleChange={handleChangeType}
                multiple={true}
                value={typeFilters}
              />
            </FieldContainerFilter>
          )}

          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default FormsTabFilters;
