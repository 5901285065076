import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { Box, IconButton, Slider } from "@material-ui/core";
import { PlayArrow, Pause, VolumeUp, VolumeOff } from "@material-ui/icons";
import useStyles from "./styles";

const VideoPlayer = ({
  url,
  playerRef,
  isFullScreen,
  initialSeekTime,
  onVideoDurationChange,
}) => {
  const classes = useStyles();
  const [playerHeight, setPlayerHeight] = useState();
  const [playerHeightView, setPlayerHeightView] = useState(null);
  const [duration, setDuration] = useState(0);
  const [played, setPlayed] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(0.5); // Default volume
  const [lastVolume, setLastVolume] = useState(volume); // Track last non-mute volume
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const progressBarRef = useRef();

  useEffect(() => {
    const element = document.getElementById("contentViewerId");
    const initialPlayerHeight = element.offsetHeight;
    setPlayerHeightView(initialPlayerHeight);
  }, []);

  useEffect(() => {
    const calculatePlayerHeight = (resizeW = false) => {
      if (isFullScreen) {
        return window.innerHeight;
      } else if (playerHeightView && !resizeW) {
        setPlayerHeight(playerHeightView);
        return playerHeightView;
      } else {
        const element = document.getElementById("contentViewerId");
        const initialPlayerHeight = element.offsetHeight;
        setPlayerHeight(initialPlayerHeight);
        return initialPlayerHeight;
      }
    };

    calculatePlayerHeight();

    const handleResize = () => {
      const newHeight = calculatePlayerHeight(true);
      setPlayerHeight(newHeight);
      setPlayerHeightView(newHeight);
      if (
        isFullScreen &&
        playerRef.current &&
        playerRef.current.internalPlayer
      ) {
        playerRef.current.internalPlayer.setSize("100%", newHeight);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, playerRef]);

  useEffect(() => {
    if (isEnded) {
      setControlsVisible(true);
    }
  }, [isEnded]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        togglePlayPause();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleReady = () => {
    if (initialSeekTime && playerRef.current) {
      playerRef.current.seekTo(initialSeekTime, "seconds");
    }
  };

  const handleVideoDuration = (duration) => {
    setDuration(duration);
    onVideoDurationChange(duration);
  };

  const handleError = (error) => {
    console.error("Error playing media:", error);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
    setCurrentTime(state.playedSeconds);
  };

  const handleSeek = (event) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const seekTo = ((event.clientX - rect.left) / rect.width) * duration;
    playerRef.current.seekTo(seekTo, "seconds");
    setIsEnded(false); // Reset the ended state when seeking
  };

  const handleEnded = () => {
    setIsEnded(true);
    setIsPlaying(false); // Ensure the video is paused when it ends
  };

  const handleClickOnScreen = () => {
    togglePlayPause();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
    setIsEnded(false); // Reset the ended state when toggling play/pause
  };

  const toggleVolumeSlider = () => {
    setShowVolumeSlider((prev) => !prev);
    if (volume === 0) {
      // Unmute and set to last non-mute volume
      setVolume(lastVolume);
    } else {
      // Mute and save current volume
      setLastVolume(volume);
      setVolume(0);
    }
  };

  const handleVolumeChange = (event, newValue) => {
    const newVolume = newValue / 100; // Normalize volume to a range of 0 to 1
    setVolume(newVolume);
    if (playerRef.current && playerRef.current.internalPlayer) {
      playerRef.current.internalPlayer.setVolume(newVolume);
      if (newVolume > 0) {
        setLastVolume(newVolume); // Update last non-mute volume
      }
    }
  };
  const commonPlayerStyles = {
    position: "relative",
  };

  const playerHeightValue = isFullScreen
    ? window.innerHeight - 40 // Header bar height
    : playerHeight;

  const handleMouseEnter = () => setControlsVisible(true);
  const handleMouseLeave = () => {
    if (!isEnded) {
      setControlsVisible(false);
    }
  };

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      // Exit full screen
      setIsPlaying(false); // Pause the video when exiting full screen
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <Box
      style={commonPlayerStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReactPlayer
        ref={playerRef}
        url={url}
        width={"100%"}
        height={playerHeightValue}
        playing={isPlaying}
        volume={volume}
        loop={true}
        onClick={handleClickOnScreen}
        onError={handleError}
        onReady={handleReady}
        onDuration={handleVideoDuration}
        onProgress={handleProgress}
        onEnded={handleEnded}
        style={{ background: "black" }}
      />
      <Box>
        {controlsVisible && (
          <Box className={classes.playerControlContainer}>
            <Box
              ref={progressBarRef}
              className={classes.playerProgressContainer}
              onClick={handleSeek}
            >
              <Box
                className={classes.playerProgressBar}
                style={{
                  width: `${played * 100}%`,
                }}
              />
            </Box>
            <Box className={classes.playerActionsContainer}>
              <IconButton
                size="small"
                className={classes.toggleActions}
                onClick={togglePlayPause}
              >
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
              <Box className={classes.volumeContainer}>
                <IconButton
                  size="small"
                  className={classes.toggleActions}
                  onClick={toggleVolumeSlider}
                >
                  {volume === 0 ? <VolumeOff /> : <VolumeUp />}
                </IconButton>

                {showVolumeSlider && (
                  <Box
                    className={classes.volumeSliderContainer}
                    onMouseLeave={() => setShowVolumeSlider(false)}
                  >
                    <Slider
                      aria-labelledby="continuous-slider"
                      className={classes.volumeSlider}
                      value={volume * 100}
                      onChange={handleVolumeChange}
                    />
                  </Box>
                )}
                <Box className={classes.time}>
                  {formatTime(currentTime)} / {formatTime(duration)}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VideoPlayer;
