import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import LearnPageTable from "./learnPageTable";
import LearnPageDialog from "./dialog/learnPageDialog";
import { LearnPageProvider } from "contexts/learnPageContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const LearnPage = (props) => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Learn" link="/learn" />
      <Box className={classes.pageBodyWrapper}>
        <LearnPageProvider>
          <LearnPageTable />
          <LearnPageDialog />
        </LearnPageProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(LearnPage);
