import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflowY: "scroll"
  },
  rootRecurrence: {
    flexGrow: 1,
    marginTop: 16
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10
  },
  containerMain: {
    backgroundColor: "#f8f8f8",
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%"
  },
  containerMainInformation: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 45
    }
  },
  containerInformation: {
    padding: theme.spacing(2),
    width: "100%",
    minHeight: "90%"
  },
  containerInformationActions: {
    backgroundColor: "#ececec",
    padding: 20,
    width: "100%",
    bottom: 0,
    position: "sticky",
    // paddingBottom: 1000,
    // marginBottom: -1000,
    overflow: "hidden",
    "& button": {
      padding: "7px 5px 7px 5px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingBottom: 10,
      marginBottom: 0
    }
  },
  containerInformationActionsPlanning: {
    backgroundColor: "transparent",
    padding: 0,
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    position: "relative",
    width: "100%",
    top: -20,
    right: 0,
    "& button": {
      padding: "7px 5px 7px 5px"
    }
  },
  containerBody: {
    padding: 20
  },
  containerSidebar: {
    backgroundColor: "#f8f8f8",
    padding: 20,
    paddingBottom: 80,
    height: "100%"
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold"
  },
  subTitleHeader: {
    fontSize: 14,
    fontWeight: "bold",
    margin: 0
  },
  subTitleHeaderDetail: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 0
  },
  subtitleBody: {
    fontSize: 18,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal"
  },
  containerSubtitle: {
    "& > span:last-child": {
      borderRight: "0 !important"
    }
  },
  containerSubtitleItem: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
    paddingRight: 5,
    borderRight: "1px solid #747474"
  },
  projectDetails: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "300px",
    background: "#fbfbfb",
    border: "solid 1px #f8f8f8",
    padding: "20px"
  },
  fullWidth: {
    width: "100%"
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    paddingTop: 90,
    alignItems: "stretch"
  },
  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "300px",
    display: "flex"
  },
  detailWrapper: {
    padding: "20px 20px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    overflowY: "scroll"
  },
  hasClose: {
    paddingRight: 50
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1
    }
  },
  detailTabbed: {
    borderTop: "1px solid #ececec",
    paddingTop: 20
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  mainHeader: {
    fontSize: "24px",
    fontWeight: "bold"
  },
  detailHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    display: "inline"
  },
  itemHeader: {
    fontSize: "14px",
    fontWeight: "bold"
  },
  itemHeaderCenter: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center"
  },
  itemHeaderRecurrence: {
    fontSize: "18px",
    fontWeight: "bold"
  },
  itemSummary: {
    fontSize: "14px",
    fontWeight: "bold"
  },
  itemDetail: {
    fontSize: "14px"
  },
  itemsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  itemsContainerDetail: {
    marginTop: theme.spacing(1)
  },
  buttonAdd: {
    textTransform: "Capitalize",
    backgroundColor: "#4f97bb"
  },
  buttonCancel: {
    textTransform: "Capitalize",
    backgroundColor: "rgb(242, 57, 85)",
    "&:hover": {
      backgroundColor: "rgba(242, 57, 85, 0.8)"
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  buttonDraft: {
    textTransform: "Capitalize",
    backgroundColor: "rgb(116, 116, 116)",
    "&:hover": {
      backgroundColor: "rgba(116, 116, 116, 0.8)"
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  buttonFinalize: {
    textTransform: "Capitalize",
    backgroundColor: "rgb(156, 187, 101)",
    "&:hover": {
      backgroundColor: "rgba(156, 187, 101)"
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50
    }
  },
  itemActions: {
    textAlign: "right"
  },
  menu: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuItem: {
    fontSize: "14px",
    paddingTop: "3px",
    paddingBottom: "3px",
    minWidth: "117px"
  },
  subMenuIcon: {
    position: "absolute",
    right: "0px"
  },
  menuItemIcon: {
    textAlign: "right",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    },
    margin: 0,
    padding: 0,
    justifyContent: "end"
  },
  menuItemIconComment: {
    textAlign: "right",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    },
    margin: 0,
    padding: 0,
    justifyContent: "end"
  },
  menuDueDateIcon: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    },
    margin: 0,
    padding: 0
  },
  itemDialog: {
    padding: theme.spacing(1),
    display: "inherit"
  },
  itemDialog2: {
    padding: theme.spacing(1)
  },
  subTitle: {
    display: "inline-block",
    fontSize: "14px",
    color: "#747474",
    marginTop: "34px",
    marginLeft: "7px",
    minWidth: "25px"
  },
  radioLabel: {
    paddingTop: "0px",
    paddingBottom: "0px",
    marginLeft: "0px",
    fontSize: 14,
    color: "#c4c4c4",
    fontWeight: "bold"
  },
  radioGreen: {
    marginLeft: theme.spacing(1),
    color: green[400],
    paddingTop: "0px",
    paddingBottom: "0px",
    "&$checked": {
      color: green[600]
    }
  },
  checked: {},
  radioYellow: {
    marginLeft: theme.spacing(1),
    color: yellow[400],
    paddingTop: "0px",
    paddingBottom: "0px",
    "&$checked": {
      color: yellow[600]
    }
  },
  radioRed: {
    marginLeft: theme.spacing(1),
    color: red[400],
    paddingTop: "0px",
    paddingBottom: "0px",
    "&$checked": {
      color: red[600]
    }
  },
  intensityGrid: {
    paddingLeft: "10px"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "0 8px 14px"
  },
  buttonLink: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    width: "100%",
    display: "block",
    borderRadius: 4,
    minWidth: 64,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
      backgroundColor: "rgba(23, 69, 122, 0.08)"
    }
  },
  addButtonSub: {
    margin: theme.spacing(1),
    marginTop: "28px",
    width: "35px",
    height: "35px",
    backgroundColor: "#747474"
  },
  addButtonPlanning: {
    marginLeft: theme.spacing(1),
    width: "35px",
    height: "35px",
    backgroundColor: "#639253"
  },
  lessButtonSub: {
    margin: 0,
    marginTop: "24px",
    width: "35px",
    height: "35px",
    backgroundColor: "#747474"
  },
  containerStatus: {
    display: "inline-block",
    top: "-3px",
    position: "relative",
    marginLeft: 10,
    "& > .MuiChip-root": {
      height: 21,
      borderRadius: 3,
      fontSize: 10,
      minWidth: 110,
      marginLeft: 5,
      fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif'
    }
  },
  containerStatusSub: {
    display: "inherit",
    textAlign: "center",
    position: "relative",
    top: "30px",
    marginLeft: "16px"
  },
  chipPrimary: {
    backgroundColor: "#78c1ce !important",
    color: "#fff !important"
  },
  chipPlanning: {
    backgroundColor: "#0b417e !important",
    color: "#fff !important"
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf"
  },
  listText: {
    paddingRight: 50
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20
  },
  listAction: {
    fontWeight: "bold"
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold"
    },
    "& > span": {
      fontSize: 14
    }
  },
  containerComments: {
    marginTop: 20
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important"
  },
  containerActivity: {
    marginTop: 20,
    maxHeight: 500,
    overflowY: "auto"
  },
  boxActions: {
    marginTop: 10,
    textAlign: "right"
  },
  containerActionButtons: {
    marginTop: 10,
    marginBottom: 30
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14
  },
  buttonPending: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    display: "inline",
    marginLeft: 16
  },
  buttonView: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    height: 20,
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiButton-label": {
      lineHeight: "100%",
      position: "relative"
    }
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff"
  },
  buttonOutlinedColor: {
    background: "#4f98bc",
    color: "#ffffff"
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b"
    }
  },
  groupOptionDisable: {
    backgroundColor: "#f8f8f8",
    color: "#747474; !important",
    cursor: "not-allowed !important",
    background: "transparent",
    borderColor: "#f8f8f8",
    "&:hover": {
      backgroundColor: "#f8f8f8"
    }
  },
  allItems: {
    display: "inline-block",
    fontSize: "14px",
    marginRight: theme.spacing(1)
  },
  containerBodyWTList: {
    marginTop: theme.spacing(4)
  },
  subContainer: {
    marginTop: theme.spacing(1)
  },
  titleSection: {
    fontSize: 18
  },
  titleSectionBody: {
    paddingLeft: theme.spacing(2),
    fontSize: 14
  },
  subCont: {
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: 32
  },
  plusButtonPlanning: {
    padding: 0,
    margin: 0,
    width: 24,
    height: 24,
    backgroundColor: "#4F98BC",
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#0B417E"
    }
  },
  errorButtonPlanningIcon: {
    fontSize: "20"
  },
  errorButtonPlanning: {
    margin: 0,
    marginLeft: theme.spacing(1),
    width: "22px",
    backgroundColor: "transparent",
    color: "#f07a8c",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  plusButtonPlanningIcon: {
    fontSize: "20px"
  },
  childItem: {
    marginLeft: "12px",
    fontSize: "18px",
    marginTop: "7px"
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal"
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal"
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
    minWidth: 440,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: 300
    }
  },
  contentTitle: {
    fontSize: 14
  },
  optionContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 40
  },
  optionPaper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
    }
  },
  optionPaperActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100
    }
  },
  optionBox: {
    textAlign: "center"
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px"
  },
  optionBoxContent: {
    fontSize: 10
  },
  avatarBox: {
    margin: "0 auto",
    marginBottom: 10
  },
  avatarType1: {
    backgroundColor: "#78c1ce",
    "& svg": {
      color: "#ffffff"
    }
  },
  avatarType2: {
    backgroundColor: "#efc945",
    "& svg": {
      color: "#ffffff"
    }
  },
  avatarType3: {
    backgroundColor: "#f39a3e",
    "& svg": {
      color: "#ffffff"
    }
  },
  actionBox: {
    textAlign: "right"
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc"
  },
  listItemComment: { backgroundColor: "#ececec" },
  buttonOutlinedComment: {
    marginRight: theme.spacing(1),
    color: "#4f98bc",
    background: "#ffffff"
  },
  buttonLink2: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: "center",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none !important",
      backgroundColor: "#17457a !important"
    }
  },
  buttonLinkOutlined: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: "center",
    textDecoration: "none"
  },
  buttonPrimary2: {
    background: "#4f98bc !important",
    color: "#ffffff"
  },
  alignRight: {
    textAlign: "right"
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10
  },
  containerUploadFile: {
    textAlign: "right"
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "#747474; !important",
    fontWeight: "bold !important",
    marginBottom: 5
  },
  linkLabel: {
    fontSize: "14px !important",
    color: "#4f97bb; !important"
  },
  linkTipo: {
    fontSize: "14px !important",
    color: "#4f97bb; !important",
    marginBottom: 10
  },
  linkIcon: {
    width: 18,
    height: 22,
    verticalAlign: "top"
  },
  checkListIconAvailable: {
    "& svg": {
      color: "#9cbb65 !important"
    }
  },
  checkListIconAvailableText: {
    "& span": {
      color: "#747474 !important"
    }
  },
  checkListIconNotAvailable: {
    "& svg": {
      color: "#f07a8c !important"
    }
  },
  checkListIconNotAvailableText: {
    "& span": {
      color: "#747474 !important",
      textDecoration: "line-through !important"
    }
  },
  checkListIconOptional: {
    "& svg": {
      color: "#0b417e !important"
    }
  },
  checkListIconOptionalText: {
    "& span": {
      color: "#747474 !important"
    }
  }
}));

export default useStyles;
