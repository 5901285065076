import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Joi from "joi-browser";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { ButtonBase } from "components/ui/core/button";
import SurveyQualityIcon from "assets/icons/surveyQualityIcon";
import SurveyCustomerSatisfactionIcon from "assets/icons/surveyCustomerSatisfactionIcon";
import SurveyInternalIcon from "assets/icons/surveyInternalIcon";
import { getCustomers } from "services/userService";
import { logException } from "components/util/logUtil";
import { validate, validateProperty } from "components/util/validationUtil";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import BackIcon from "@material-ui/icons/ArrowBack";
import { getJobsCustomers } from "services/jobService";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormInput from "components/ui/FormContent/formInput";
import FilterJobsAdvance from "components/ui/Surveys/FilterJobsAdvance";
import {
  getDepartments,
  getPositions,
} from "services/surveyEvaluationsService";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import WarningIcon from "@material-ui/icons/Error";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { createSurvey } from "services/surveyService";
import {
  signatureConfirmationOptions,
  ringBellPositionOptions,
  QASubTypes,
} from "constants.js";
import { getTeamUsers } from "services/ringBellService";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const InitialData = {
  type: 0,
  customers: [],
  jobs: [],
  name: "",
  description: "",
  signatureConfirmation: signatureConfirmationOptions[2],
  has_randomize: false,
  sub_type: null,
  random_number: 3,
  departments: [],
  positions: [],
  exceptions: [],
};

const surveyStep1Schema = {
  customers: Joi.array()
    .min(1)
    .label("Customers")
    .error(() => {
      return {
        message: "Choose at least one customer.",
      };
    }),
  jobs: Joi.array()
    .min(1)
    .label("Jobs")
    .error(() => {
      return {
        message: "Choose at least one job.",
      };
    }),
  name: Joi.string().min(3).required().label("Name"),
  description: Joi.string().min(3).required().label("Description"),
};

const surveyStep1SchemaNonOps = {
  name: Joi.string().min(3).required().label("Name"),
  description: Joi.string().min(3).required().label("Description"),
};

const surveyStep2Schema = {
  random_number: Joi.number().integer().min(1).label("Random Question Number"),
};

const surveyStep3Schema = {
  departments: Joi.array()
    .min(1)
    .label("Departments")
    .error(() => {
      return {
        message: "Choose at least one department.",
      };
    }),
  positions: Joi.array()
    .min(1)
    .label("Positions")
    .error(() => {
      return {
        message: "Choose at least one position.",
      };
    }),
};

const SideDrawer = (props) => {
  const classes = useStyles();
  const { isOpen, closeDrawer } = props;
  const [step, setStep] = useState(0);
  const [data, setData] = useState(InitialData);
  const [customers, setCustomers] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [exUsers, setExUsers] = useState([]);
  const [schema, setSchema] = useState({});
  const [error, setError] = useState([]);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingPositions, setIsLoadingPositions] = useState(false);
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);
  const [jobs, setJobs] = useState([]);

  const handleClick = (option) => {
    const newData = {
      ...data,
      type: option,
    };
    setData(newData);
  };

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultUser = await getCustomers();
        const { customers } = resultUser.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        setCustomers([{ value: -1, label: "All" }, ...resultForRender]);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers) {
      loadCustomer();
    }
  }, [customers]);

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const resultForRender = await getDepartments();
        setDepartments(resultForRender.data.data.departments);
      } catch (e) {
        logException(e, "Cannot load departments data");
      }
    };
    if (!departments.length) {
      loadDepartments();
    }
  }, [departments]);

  useEffect(() => {
    const loadPositions = async () => {
      try {
        setIsLoadingPositions(true);
        const departmentsIds = data.departments.map((d) => {
          return d.value;
        });
        const resultPositions = await getPositions(departmentsIds);
        const positionsIds = resultPositions.data.data.positions.map((p) => {
          return p.tier_classification;
        });
        const positionsToRender = ringBellPositionOptions.filter((p) =>
          positionsIds.includes(p.value)
        );
        setPositions(positionsToRender);
        if (data.positions.length > 0) {
          const posIds = data.positions.map((j) => {
            return j.value;
          });
          const posSelected = positionsToRender.filter((p) =>
            posIds.includes(p.value)
          );
          const newData = {
            ...data,
            positions: posSelected,
            customers: [],
            jobs: [],
          };
          setData(newData);
        } else {
          const newData = {
            ...data,
            customers: [],
            jobs: [],
          };
          setData(newData);
        }
        setIsLoadingPositions(false);
      } catch (e) {
        logException(e, "Cannot load positions data");
      }
    };
    if (data.departments?.length > 0) {
      loadPositions();
    } else {
      setPositions([]);
      const newData = {
        ...data,
        positions: [],
        customers: [],
        jobs: [],
      };
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.departments]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setIsLoadingUsers(true);
        const filterData = {};
        filterData.filters = [
          {
            filter: "department",
            group: "department",
            values: data.departments.map((item) => {
              return { label: "", value: item.value };
            }),
          },
          {
            filter: "tier_classification",
            group: "tier_classification",
            values: data.positions.map((item) => {
              return { label: "", value: item.value };
            }),
          },
        ];
        const result = await getTeamUsers(filterData);
        const usersToRender = result.data.data.list;
        setExUsers(usersToRender);
        if (data.exceptions.length > 0) {
          const exIds = data.exceptions.map((e) => {
            return e.id;
          });
          const exSelected = usersToRender.filter((u) => exIds.includes(u.id));
          const newData = {
            ...data,
            exceptions: exSelected,
          };
          setData(newData);
        }
        setIsLoadingUsers(false);
      } catch (e) {
        logException(e, "Cannot load users data");
      }
    };
    if (data.positions?.length > 0) {
      loadUsers();
    } else {
      setExUsers([]);
      const newData = {
        ...data,
        exceptions: [],
      };
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.positions]);

  useEffect(() => {
    const loadJob = async () => {
      try {
        setIsLoadingJob(true);
        const customerIds = data.customers.map((c) => {
          return c.value;
        });
        const resultJob = await getJobsCustomers(customerIds);
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            customer_id: job.customer_id,
            industry: job.division,
            city: job.city,
            state: job.state,
            zip: job.zip,
          };
        });
        setJobs([
          { id: -1, job_number: "-1", job_description: "All" },
          ...resultForRender,
        ]);
        if (data.jobs.length > 0) {
          const jobIds = data.jobs.map((j) => {
            return j.id;
          });
          const jobsSelected = resultForRender.filter((c) =>
            jobIds.includes(c.id)
          );
          const newData = {
            ...data,
            jobs: jobsSelected,
          };
          setData(newData);
        }
        setIsLoadingJob(false);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (data.customers?.length > 0) {
      loadJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customers]);

  const changeStep = async (n) => {
    if (n === 0) {
      setSchema({});
      setStep(n);
    } else {
      const errors = validate(schema, data, setError);
      if (!Boolean(errors) || n < step) {
        if (n === 1) {
          if (data.type > 1) {
            setSchema(surveyStep1Schema);
          } else {
            setSchema(surveyStep3Schema);
          }
          setStep(n);
        } else if (n === 2) {
          if (data.type > 1) {
            setSchema(surveyStep2Schema);
          } else {
            const depsIds = data.departments.map((d) => {
              return d.value;
            });
            if (depsIds.includes("operations")) {
              setSchema(surveyStep1Schema);
            } else {
              setSchema(surveyStep1SchemaNonOps);
            }
          }
          setStep(n);
        } else if (n === 3 && data.type > 1) {
          setIsLoadingSurvey(true);
          const { history } = props;
          let createData = {};
          if (data.jobs.length === 1 && data.jobs[0].id === -1) {
            if (data.customers.length === 1 && data.customers[0].value === -1) {
              createData = {
                name: data.name,
                description: data.description,
                signature_confirmation: data.signatureConfirmation.value,
                type: data.type,
                sub_type: data?.sub_type?.value ?? 0,
                has_randomize: data.has_randomize,
                random_number: data.has_randomize ? data.random_number : null,
                customer_ids: customers.map((c) => {
                  return c.value;
                }),
              };
            } else {
              createData = {
                name: data.name,
                description: data.description,
                signature_confirmation: data.signatureConfirmation.value,
                type: data.type,
                sub_type: data?.sub_type?.value ?? 0,
                has_randomize: data.has_randomize,
                random_number: data.has_randomize ? data.random_number : null,
                customer_ids: data.customers.map((c) => {
                  return c.value;
                }),
              };
            }
          } else {
            createData = {
              name: data.name,
              description: data.description,
              signature_confirmation: data.signatureConfirmation.value,
              type: data.type,
              sub_type: data?.sub_type?.value ?? 0,
              has_randomize: data.has_randomize,
              random_number: data.has_randomize ? data.random_number : null,
              job_ids: data.jobs.map((j) => {
                return j.id;
              }),
            };
          }
          const result = await createSurvey(createData);
          const { survey } = result.data.data;
          setIsLoadingSurvey(false);
          history.push(`/surveys/admin/survey/${survey.id}`);
        } else if (n === 3) {
          setSchema(surveyStep2Schema);
          setStep(n);
        } else if (n === 4) {
          setIsLoadingSurvey(true);
          const { history } = props;
          let createData = {};
          if (data.jobs.length === 1 && data.jobs[0].id === -1) {
            if (data.customers.length === 1 && data.customers[0].value === -1) {
              createData = {
                name: data.name,
                description: data.description,
                signature_confirmation: data.signatureConfirmation.value,
                type: data.type,
                sub_type: data?.sub_type?.value ?? 0,
                has_randomize: data.has_randomize,
                random_number: data.has_randomize ? data.random_number : null,
                customer_ids: customers.map((c) => {
                  return c.value;
                }),
                position_ids: data.positions.map((r) => {
                  return r.value;
                }),
                department_ids: data.departments.map((r) => {
                  return r.value;
                }),
                exclude_ids: data.exceptions.map((e) => {
                  return e.id;
                }),
              };
            } else {
              createData = {
                name: data.name,
                description: data.description,
                signature_confirmation: data.signatureConfirmation.value,
                type: data.type,
                sub_type: data?.sub_type?.value ?? 0,
                has_randomize: data.has_randomize,
                random_number: data.has_randomize ? data.random_number : null,
                customer_ids: data.customers.map((c) => {
                  return c.value;
                }),
                position_ids: data.positions.map((r) => {
                  return r.value;
                }),
                department_ids: data.departments.map((r) => {
                  return r.value;
                }),
                exclude_ids: data.exceptions.map((e) => {
                  return e.id;
                }),
              };
            }
          } else {
            createData = {
              name: data.name,
              description: data.description,
              signature_confirmation: data.signatureConfirmation.value,
              type: data.type,
              sub_type: data?.sub_type?.value ?? 0,
              has_randomize: data.has_randomize,
              random_number: data.has_randomize ? data.random_number : null,
              job_ids: data.jobs.map((j) => {
                return j.id;
              }),
              position_ids: data.positions.map((r) => {
                return r.value;
              }),
              department_ids: data.departments.map((r) => {
                return r.value;
              }),
              exclude_ids: data.exceptions.map((e) => {
                return e.id;
              }),
            };
          }
          const result = await createSurvey(createData);
          const { survey } = result.data.data;
          setIsLoadingSurvey(false);
          history.push(`/surveys/admin/survey/${survey.id}`);
        }
      }
    }
  };

  const handleChangeCustomer = (event, value) => {
    let newData = {};
    if (value.length > 0) {
      const customerIds = data.customers.map((c) => {
        return c.value;
      });
      let newValue = value;
      if (customerIds.includes(-1)) {
        newValue = value.filter((c) => c.value !== -1);
      } else if (value.length > 1) {
        const customerIdsValue = value.map((c) => {
          return c.value;
        });
        if (customerIdsValue.includes(-1)) {
          newValue = value.filter((c) => c.value === -1);
        }
      }
      if (value.length < data.customers.length) {
        const difference = data.customers.filter((c) => !value.includes(c));
        const newJobs = data.jobs.filter(
          (j) => j.customer_id !== difference[0].value
        );
        newData = {
          ...data,
          customers: newValue,
          jobs: newJobs,
        };
      } else {
        newData = {
          ...data,
          customers: newValue,
        };
      }
    } else {
      newData = { ...data, customers: value, jobs: [] };
    }
    setData(newData);
    validateProperty("customers", value, schema, error, setError);
  };

  const changeJob = (event, value) => {
    let newData = {};
    const jobsIds = data.jobs.map((j) => {
      return j.id;
    });
    if (jobsIds.includes(-1)) {
      newData = {
        ...data,
        jobs: value.filter((j) => j.id !== -1),
      };
    } else {
      if (value.length > 1) {
        const jobsIdsValue = value.map((j) => {
          return j.id;
        });
        if (jobsIdsValue.includes(-1)) {
          newData = {
            ...data,
            jobs: value.filter((j) => j.id === -1),
          };
        } else {
          newData = {
            ...data,
            jobs: value,
          };
        }
      } else {
        newData = {
          ...data,
          jobs: value,
        };
      }
    }

    setData(newData);
    validateProperty("jobs", value, schema, error, setError);
  };

  const handleChangeDepartment = (event, value) => {
    const newData = {
      ...data,
      departments: value,
    };
    setData(newData);
    validateProperty("departments", value, schema, error, setError);
  };

  const handleChangeSubType = (event, value) => {
    const newData = {
      ...data,
      sub_type: value,
    };
    setData(newData);
  };

  const handleChangePosition = (event, value) => {
    const newData = {
      ...data,
      positions: value,
    };
    setData(newData);
    validateProperty("positions", value, schema, error, setError);
  };

  const handleChangeExceptions = (event, value) => {
    const newData = {
      ...data,
      exceptions: value,
    };
    setData(newData);
  };

  const handleChangeSignatureConfirmation = (event, value) => {
    if (value) {
      const newData = {
        ...data,
        signatureConfirmation: value,
      };
      setData(newData);
    }
  };

  const handleBlur = (event, value) => {
    let newData = {};

    switch (event.name) {
      case "name":
        newData = {
          ...data,
          name: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "description":
        newData = {
          ...data,
          description: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      case "random_number":
        newData = {
          ...data,
          random_number: event.value,
        };
        validateProperty(event.name, event.value, schema, error, setError);
        break;
      default:
        newData = {
          ...data,
        };
        break;
    }
    setData(newData);
  };

  const changeHasRandomize = (option) => {
    let newData = {};
    if (option === 1) {
      newData = {
        ...data,
        has_randomize: option,
      };
    } else {
      newData = {
        ...data,
        has_randomize: option,
        random_number: 3,
      };
    }
    setData(newData);
  };

  const changeJobFilterList = (jobsIds) => {
    const jobList = jobs.filter((job) => jobsIds.includes(job.id));
    const newData = {
      ...data,
      jobs: jobList,
    };
    setData(newData);
  };

  const handleSelectConcierge = (e) => {
    const newData = {
      ...data,
      concierge: e.target.checked,
    };
    setData(newData);
  };

  return (
    <Drawer anchor="right" open={isOpen} className={classes.drawerWrapper}>
      <IconButton
        className={classes.buttonClose}
        aria-label="Close"
        onClick={() => closeDrawer()}
      >
        <CloseIcon className={classes.iconClose} />
      </IconButton>
      <Box className={classes.currentStepContainer}>
        <Typography className={classes.currentStep}>
          Step {step + 1}/{data.type > 1 ? 3 : 4}
        </Typography>
      </Box>
      {step === 0 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Create New Survey
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the survey you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.contentTitle}
            >
              What type of survey are you creating?
            </Typography>
            <Grid container className={classes.optionContainer} spacing={6}>
              <Grid item xs={4} className={classes.optionGrid}>
                <Paper
                  className={
                    data.type === 2
                      ? classes.optionPaperActive
                      : classes.optionPaper
                  }
                  square={true}
                  elevation={0}
                >
                  <Box
                    className={classes.optionBox}
                    onClick={() => handleClick(2)}
                  >
                    <Avatar
                      className={classNames(
                        classes.avatarBox,
                        classes.avatarType1
                      )}
                    >
                      <SurveyQualityIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      className={classes.optionBoxTitle}
                      gutterBottom
                    >
                      Quality
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={4} className={classes.optionGrid}>
                <Paper
                  className={
                    data.type === 3
                      ? classes.optionPaperActive2
                      : classes.optionPaper2
                  }
                  square={true}
                  elevation={0}
                >
                  <Box
                    className={classes.optionBox}
                    onClick={() => handleClick(3)}
                  >
                    <Avatar
                      className={classNames(
                        classes.avatarBox,
                        classes.avatarType2
                      )}
                    >
                      <SurveyCustomerSatisfactionIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      className={classes.optionBoxTitle}
                      gutterBottom
                    >
                      Customer Satisfaction
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={4} className={classes.optionGrid}>
                <Paper
                  className={
                    data.type === 1
                      ? classes.optionPaperActive
                      : classes.optionPaper
                  }
                  square={true}
                  elevation={0}
                >
                  <Box
                    className={classes.optionBox}
                    onClick={() => handleClick(1)}
                  >
                    <Avatar
                      className={classNames(
                        classes.avatarBox,
                        classes.avatarType3
                      )}
                    >
                      <SurveyInternalIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      className={classes.optionBoxTitle}
                      gutterBottom
                    >
                      Team Evaluation
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Box className={classes.actionBox}>
              <ButtonBase
                color="secondary"
                disabled={data.type < 1}
                onClick={() => changeStep(1)}
                disableElevation
              >
                Begin
              </ButtonBase>
            </Box>
          </Box>
        </>
      ) : (step === 1 && data.type > 1) || (step === 2 && data.type === 1) ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              General Information
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the survey you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              {schema !== surveyStep1SchemaNonOps ? (
                <>
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={customers}
                    name="customers"
                    multiple={true}
                    error={error}
                    handleChange={handleChangeCustomer}
                    label="Customers (Select One or Multiple)"
                    value={data.customers}
                  />
                  {!isLoadingJob ? (
                    <>
                      <FilterJobsAdvance
                        jobs={jobs.filter((job) => job.id !== -1)}
                        jobsSelected={data.jobs}
                        customersSelected={
                          data.customers[0]?.value === -1
                            ? customers
                            : data.customers
                        }
                        changeJobFilterList={changeJobFilterList}
                      />
                      <FormSelectJobsAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={jobs}
                        name="jobs"
                        multiple={true}
                        label="Job/s (Select One or Multiple)"
                        error={error}
                        value={data.jobs}
                        handleChange={changeJob}
                        readonly={data.customers.length === 0}
                      />
                    </>
                  ) : (
                    <LinearProgress color="secondary" />
                  )}
                </>
              ) : null}
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="name"
                label="Survey Name"
                error={error}
                internal={false}
                value={data.name}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="description"
                label="Survey Description"
                error={error}
                internal={false}
                value={data.description}
                handleBlur={handleBlur}
                multiline={true}
                rows={4}
              />
              {[1, 2].includes(data.type) ? (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={signatureConfirmationOptions}
                  name="signatureConfirmation"
                  error={error}
                  handleChange={handleChangeSignatureConfirmation}
                  label="Signature Confirmation"
                  value={data.signatureConfirmation}
                />
              ) : null}
              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(data.type === 1 ? 1 : 0)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={() => changeStep(data.type === 1 ? 3 : 2)}
                >
                  Next
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </>
      ) : (step === 2 && data.type > 1) || (step === 3 && data.type === 1) ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              General Information
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the survey you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <Box
                className={
                  data.has_randomize
                    ? classes.containerOptions3
                    : classes.containerOptions2
                }
              >
                {data.type === 2 ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    handleChange={handleChangeSubType}
                    options={QASubTypes.filter((item) => item.value !== 0)}
                    name="sub_type"
                    label="Sub Type"
                    value={data.sub_type}
                  />
                ) : null}
                <InputLabel className={classes.inputLabelP}>
                  This survey will have express option?
                </InputLabel>
                <ButtonGroup
                  variant="outlined"
                  color="default"
                  aria-label="primary button group"
                  className={classes.groupOption2}
                  fullWidth={true}
                >
                  <Button
                    className={
                      data.has_randomize ? classes.groupOptionActive : null
                    }
                    onClick={() => changeHasRandomize(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    className={
                      !data.has_randomize ? classes.groupOptionActive : null
                    }
                    onClick={() => changeHasRandomize(false)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
              {data.has_randomize ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 6 }]}
                  name="random_number"
                  label="Maximum Number of Questions"
                  error={error}
                  internal={false}
                  value={data.random_number}
                  handleBlur={handleBlur}
                />
              ) : null}

              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(data.type === 1 ? 2 : 1)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>

                {isLoadingSurvey ? (
                  <LoadingStateHorizontal
                    isVisible
                    style={classes.centerLoading}
                  />
                ) : (
                  <ButtonBase
                    color="secondary"
                    onClick={() => changeStep(data.type === 1 ? 4 : 3)}
                  >
                    Next
                  </ButtonBase>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : step === 1 && data.type === 1 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Assign to a group of team members
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about who this survey is for.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={departments}
                name="departments"
                multiple={true}
                error={error}
                handleChange={handleChangeDepartment}
                label="Departments (Select Departments Survey is For)"
                value={data.departments}
              />
              {isLoadingPositions ? (
                <LinearProgress color="secondary" />
              ) : (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={positions}
                  name="positions"
                  multiple={true}
                  error={error}
                  handleChange={handleChangePosition}
                  label="Positions (Select Positions Survey is For)"
                  value={data.positions}
                />
              )}
              {isLoadingUsers ? (
                <LinearProgress color="secondary" />
              ) : (
                <FormSelectChipsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={exUsers}
                  name="users"
                  label="Exceptions (User List)"
                  handleChange={handleChangeExceptions}
                  multiple={true}
                  value={data.exceptions}
                />
              )}
              <Box className={classes.textInformationContainer}>
                <WarningIcon className={classes.warningIcon} />
                <Typography className={classes.textInformation}>
                  Survey will not be available for users selected.
                </Typography>
              </Box>
              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  startIcon={<BackIcon />}
                  variant="text"
                  onClick={() => changeStep(0)}
                  className={classes.buttonBackDrawer}
                >
                  Back
                </ButtonBase>
                <ButtonBase color="secondary" onClick={() => changeStep(2)}>
                  Next
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </Drawer>
  );
};

export default withRouter(SideDrawer);
