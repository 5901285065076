import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function StateFilter({
  classes,
  stateQuery,
  filterStateSelected,
  clickRow,
  statesLoading,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredStates = stateQuery?.filter((city) =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Fragment>
      {statesLoading && <LoadingIndicator />}
      {!statesLoading && (
        <Fragment>
          <Box className={classes.servicedSearchContainerFilters}>
            <Typography variant={"h6"} className={classes.previewTableTitle}>
              {filteredStates.length} States
            </Typography>
            <TextField
              placeholder="Search State"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                disableunderline: "true",
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
            />
          </Box>

          <TableContainer component={Box}>
            <Table className={classes.table} aria-label="table">
              <TableHead className={classes.filterJobTableHeader}>
                <TableRow>
                  <TableCell className={classes.filterJobTableCol}></TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Abbreviation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStates?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        inputProps={{ "aria-label": "select job" }}
                        className={classes.tableCheckbox}
                        checked={filterStateSelected.includes(row.abbreviation)}
                        onClick={(e) => clickRow(e, row.abbreviation, row.name)}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.abbreviation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Fragment>
  );
}
