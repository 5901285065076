import moment from "moment";
import React from "react";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";

import useStyles from "./styles";

const FormInputDateAdo = (props) => {
  const classes = useStyles();

  const {
    name,
    label,
    value,
    minDate,
    minDateMessage,
    handleChange,
    disabled,
    internal,
    disableFuture,
    keyboardIcon,
    errorMessage,
    withoutLabel,
    noMargin,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <Grid item xs={12} md={12}>
          {!withoutLabel ? (
            <InputLabel
              htmlFor={name}
              className={
                internal ? classes.inputInternalLabel : classes.inputLabel
              }
            >
              {label}
            </InputLabel>
          ) : (
            ""
          )}
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            margin="normal"
            autoOk={true}
            id={name}
            value={value ? moment(value, moment.defaultFormat) : null}
            minDate={minDate ?? undefined}
            minDateMessage={minDateMessage ?? undefined}
            keyboardIcon={keyboardIcon ?? undefined}
            onChange={handleChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className={classes.inputDate}
            InputProps={{
              className: noMargin
                ? classes.inputDateFieldAdoNoMargin
                : classes.inputDateFieldAdo,
              disableUnderline: true,
            }}
            InputAdornmentProps={{ className: classes.iconFormat }}
            fullWidth={true}
            disabled={disabled ?? false}
            disableFuture={disableFuture ?? false}
            invalidDateMessage={errorMessage ?? "Invalid Date Format"}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FormInputDateAdo;
