import React, { Fragment, useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Rating } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { CommentsActivities } from "../Comments/CommentsActivites";
import { ArrowRight } from "../icons/ArrowRight";
import { useQuery } from "react-query";
import { getPartnerRatings } from "services/partners/ratingService";
import Integer from "lodash";
import { useAllRatingsModal } from "../context/AllRatingsDialogueContext";
import { AllRatingsModal } from "./AllRatingsModal";
import { RateDialog } from "./RateDialog";
import { useRateAction } from "../context/PartnerRateContext";
import { RatingsList } from "./RatingsList";
import { hasPermission, permissionPartner } from "lib/permissions";
import { CommentDialogueProvider } from "../context/CommentDialogueContext";
import { useGetUserId } from "../context/UserIdContext";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "../styles";

export function RatingMain() {
  const { userId } = useGetUserId();
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  // const containerRef = useRef(null);
  const [rateAction, dispatchRateAction] = useRateAction();

  const { data, isLoading } = useQuery(
    ["ratings", userId],
    async () => {
      const { data } = await getPartnerRatings(userId);
      return data.data;
    },
    {
      enabled: !!userId,
    }
  );

  const userRating = data?.partner_ratings;

  //only show first 5 ratings
  const otherRatings = data?.partner_ratings;
  const { openAllRatingsDialogue, handleDialogueOpen } = useAllRatingsModal();
  const NoReview = () => (
    <Box className={classes.ratingCommentsInnerWrapper}>
      <Box className={classes.ratingWrapper}>
        <Button
          onClick={() => {
            dispatchRateAction({
              type: "TOGGLE_RATE_PARTNER",
              open: true,
            });
          }}
          className={classes.ratePartnerButton}
        >
          Rate Partner
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : !hasPermission(permissionPartner.PARTNER_OWNER, permissions) ? (
        <Fragment>
          <Box className={classes.ratingCommentsMainWrapper}>
            {!isLoading && userRating?.length === 0 ? (
              <NoReview />
            ) : (
              <Box className={classes.ratingCommentsInnerWrapper}>
                <Box className={classes.topReviewContainer}>
                  <Box className={classes.ratingHeaderAndDownIcon}>
                    <Typography className={classes.topRatingText}>
                      Rating
                    </Typography>
                  </Box>
                  <Box className={classes.ratingStarsAndNumberContainer}>
                    <Typography className={classes.topRatingNumber}>
                      {data?.partner_avg_rating}
                    </Typography>
                    <Rating
                      className={classes.ratingStyle}
                      disabled={true}
                      name="simple-controlled"
                      value={Integer.parseInt(data?.partner_avg_rating)}
                    />
                  </Box>
                  <NoReview />
                  <Box
                    className={`${classes.ratingCollapsableContainer} ${
                      collapsed ? classes.ratingCollapsableContainerOpen : ""
                    }`}
                  >
                    {otherRatings?.length >= 1 &&
                      otherRatings
                        .slice(
                          0,
                          otherRatings?.length > 5 ? 5 : otherRatings.length
                        )
                        .map((rating, index) => (
                          <RatingsList
                            key={index}
                            index={index}
                            rating={rating}
                            classes={classes}
                          />
                        ))}
                  </Box>
                  {collapsed && <Box className={classes.dummyBoxRating}></Box>}
                  {data?.partner_ratings?.length >= 5 && (
                    <Box className={classes.totalReviewsContainer}>
                      <Typography className={classes.totalReviewsText}>
                        Total Reviews: {otherRatings?.length}
                      </Typography>
                      <Box
                        onClick={() => {
                          handleDialogueOpen();
                        }}
                        className={classes.viewAllContainer}
                      >
                        <Typography className={classes.totalReviewsText}>
                          View All
                        </Typography>
                        <ArrowRight fill={"#4F98BC"} />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <CommentDialogueProvider>
              <CommentsActivities />
            </CommentDialogueProvider>
          </Box>
          <RateDialog />
          {openAllRatingsDialogue ? (
            <AllRatingsModal
              averageRating={data?.partner_avg_rating}
              ratings={data?.partner_ratings}
              permissionRate={hasPermission(
                permissionPartner.PARTNER_MANAGE,
                permissions
              )}
            />
          ) : null}
        </Fragment>
      ) : (
        <Box className={classes.ratingCommentsMainWrapper}>
          <CommentDialogueProvider>
            <CommentsActivities />
          </CommentDialogueProvider>
        </Box>
      )}
    </Fragment>
  );
}
