import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "services/authService";
import MainLayout from "../common/MainLayout";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        return Component ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          render(props)
        );
      }}
    />
  );
};

export default ProtectedRoute;
