import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";
import { setFormData } from "components/util/serviceUtil";
import {
  getFilterString,
  filtersDashboardGeo,
} from "components/util/filterUtil";

const apiEndpoint = `${apiSurfaceUrl}/ringBell`;

export function getRingBellList(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/list?${filterString}`, {});
}

export function getRingBellListSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/list?${filterString}search=${search}`, {});
}

export function getRingBellListOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.post(`${apiEndpoint}/list?${filterString}offset=${offset}`, {});
}

export function getRingBellListDashboard(
  filterData = undefined,
  dashboardState
) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}/list`, payload);
}

export function getRingBellListSearchDashboard(
  search,
  filterData,
  dashboardState
) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}/list?search=${search}`, payload);
}

export function getRingBellListOffsetDashboard(
  offset,
  filterData,
  dashboardState
) {
  const payload = filtersDashboardGeo(filterData, dashboardState);
  return http.post(`${apiEndpoint}/list?offset=${offset}`, payload);
}

export function ringBell(data) {
  http.setFormEncode();
  return http.post(apiEndpoint, setFormData(data));
}

export function getTeam() {
  return http.get(`${apiEndpoint}/team`, {});
}
