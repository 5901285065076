import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialog2: {
    textAlign: "center",
    paddingBottom: 0,
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlined: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
  },
  checkbox: {
    color: "#ececec",
  },
  labelCheckbox: {
    "& > span.MuiTypography-root": {
      color: "#4d4d4d",
      fontSize: 12,
    },
  },
  infoAlert: {
    border: "none",
    marginBottom: theme.spacing(1),
    top: -15,
    position: "relative",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  titleIcon: {
    marginRight: 8,
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 0,
    paddingRight: 44,
    paddingBottom: 28,
  },
  actionItemButtonDrawer: {
    textTransform: "Capitalize",
    paddingTop: 16,
    paddingBottom: 16,
  },
  formFieldsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textAlign: "left",
  },
  formFieldsContainer2: {
    borderRight: "solid 1px #ECECEC",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textAlign: "left",
  },
  formToggleContainer: {
    textAlign: "right",
    minHeight: 34,
  },
  spanishToggle: {
    marginRight: 0,
    "& > span": {
      fontSize: 12,
    },
    "& > span.MuiButtonBase-root": {
      paddingRight: 4,
    },
  },
  spanishToggleCheck: {
    "& > span > svg": {
      borderRadius: 3,
      width: 16,
      height: 16,
    },
  },
  colorItem: {
    width: 32,
    height: 32,
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  colorItemSelected: {
    width: 32,
    height: 32,
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    outline: "2px solid #747474",
    cursor: "pointer",
  },
  colorsContainer: {
    display: "flex",
    flexFlow: "row wrap",
  },
  titleSection: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#747474",
  },
}));

export default useStyles;
