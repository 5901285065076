import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import AssetsLayout from "./assetsSurfaceLayout";
import Box from "@material-ui/core/Box";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import { AssetProvider } from "contexts/assetSurfaceContext";

import useStyles from "./styles";

const AssetsSurfaceList = props => {
  const classes = useStyles();

  return (
    <>
      <PageHeader pageTitle="Assets" link="/assets/surface"></PageHeader>
      <Box className={classes.pageBodyWrapper}>
        <AssetProvider>
          <AssetsLayout />
        </AssetProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(AssetsSurfaceList);
