import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Gallery from "components/common/File/gallery";
import { workticketFiles } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";

const filterAndSortFiles = (files) => {
  if (!files || files.length === 0) {
    return [];
  }

  return files.all.reduce((filteredFiles, currentFile) => {
    if (parseInt(currentFile.is_pinned) === 1) {
      if (currentFile.mime_type === "2") {
        filteredFiles.unshift(currentFile);
      } else {
        filteredFiles.push(currentFile);
      }
    }
    return filteredFiles;
  }, []);
};

export const WorkticketGallery = ({ workTicketId, workTicketNumber }) => {
  const [stateContext, dispatchContext] = useWorkticketView();
  const { workticketFiles: files, isLoading } = stateContext ?? null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await workticketFiles(workTicketId);
        dispatchContext({
          type: "SET_WORKTICKET_FILES",
          workticketFiles: response.data.data,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (workTicketId && workTicketId !== "new") {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  if (!filterAndSortFiles(files) || filterAndSortFiles(files).length === 0) {
    return null;
  }

  return (
    <Box mt={3}>
      <Gallery
        files={filterAndSortFiles(files)}
        workTicketNumber={workTicketNumber}
        workTicketId={workTicketId}
      />
    </Box>
  );
};
