import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import { ButtonBase } from "components/ui/core/button";
import { makeStyles } from "@material-ui/core/styles";
import { useListDispatch } from "contexts/listContext";
import ContactsManage from "./contactsManage";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionCustomer, hasPermission } from "lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    float: "left",
    "& button": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
}));

const ContactsListActions = (props) => {
  const classes = useStyles();
  const dispatchList = useListDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const handleClick = () => {
    dispatchList({
      type: "SET_SELECTED_ACTION",
      selectedAction: "CREATE",
    });
  };

  return (
    <Box className={classes.root}>
      {hasPermission(permissionCustomer.CREATE_EDIT_CONTACT, permissions) ? (
        <ButtonBase variant="outlined" color="secondary" onClick={handleClick}>
          Create Contact
        </ButtonBase>
      ) : null}
      <ContactsManage />
    </Box>
  );
};

export default ContactsListActions;
