export function UserCircle() {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9526 57.2913C62.6129 51.9574 66.1465 44.3913 66.1465 36C66.1465 19.8457 53.0508 6.75 36.8965 6.75C20.7422 6.75 7.64648 19.8457 7.64648 36C7.64648 44.3913 11.18 51.9574 16.8404 57.2913C22.0768 62.2258 29.1335 65.25 36.8965 65.25C44.6595 65.25 51.7161 62.2258 56.9526 57.2913ZM19.3319 53.4369C23.4553 48.2934 29.7913 45 36.8965 45C44.0017 45 50.3376 48.2934 54.4611 53.4369C49.9773 57.9534 43.7635 60.75 36.8965 60.75C30.0295 60.75 23.8157 57.9534 19.3319 53.4369ZM48.1465 27C48.1465 33.2132 43.1097 38.25 36.8965 38.25C30.6833 38.25 25.6465 33.2132 25.6465 27C25.6465 20.7868 30.6833 15.75 36.8965 15.75C43.1097 15.75 48.1465 20.7868 48.1465 27Z"
        fill="#D8D8D8"
      />
    </svg>
  );
}
