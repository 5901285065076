import React, { useState } from "react";
import eosLogo from "../../../assets/logo-eos-dark.svg";
import eosIcon from "../../../assets/icons/encompass-team.png";
// import progressLogo from "../../../assets/icons/continuous-progress.png";
// import improveLogo from "../../../assets/icons/improve-lives.png";
import fbLogo from "../../../assets/icons/encompass-facebook-light.png";
import twitterLogo from "../../../assets/icons/encompass-twitter-light.png";
import linkedinLogo from "../../../assets/icons/encompass-linkedin-light.png";

import { ThemeProvider } from "@material-ui/core/styles";
// import * as classnames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import useStyles from "./styles";

import theme from "theme";

const content = {
  1: {
    title: "Welcome to Encompass",
    content: "The platform that runs modern facility management teams",
    image: <img src={eosIcon} alt="Icon encompass" />,
  },
  // 2: {
  //   title: "Continuous Progress",
  //   content:
  //     "We strive to provide the best outcomes for our customers. We aren’t perfect but we always strive towards it",
  //   image: <img src={progressLogo} alt="Icon continuous progress" />,
  // },
  // 3: {
  //   title: "Improve Lives",
  //   content:
  //     "We set to improve the lives of our customers, our people, and our community",
  //   image: <img src={improveLogo} alt="Icon improve lives" />,
  // },
};

const MainLayoutLogin = (props) => {
  const { children } = props;
  const classes = useStyles();
  const [slider] = useState(1);
  // const [termsDialog, setTermsDialog] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const newSlider = slider === 3 ? 1 : slider + 1;
  //     setSlider(newSlider);
  //   }, 8000);

  //   return () => clearInterval(interval);
  // });

  // const handleSlider = (newSlider) => {
  //   setSlider(newSlider);
  // };

  // const handleTermsClose = () => {
  //   setTermsDialog(false);
  // };

  const linksContent = (
    <Box className={classes.linkWrapper}>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://help.encompassonsite.com/"
        className={classes.linkLabel}
      >
        Help Center
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.encompassonsite.com/terms"
        className={classes.linkLabel}
      >
        Terms and Conditions
      </a>
      <span className={classes.textLabel}>
        ©Encompass {new Date().getFullYear()}
      </span>
    </Box>
  );

  const socialContent = (
    <>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/EncompassOnsite"
      >
        <img src={fbLogo} alt="Facebook" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://twitter.com/encompassonsite"
      >
        <img src={twitterLogo} alt="Twitter" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/company/encompassonsite"
      >
        <img src={linkedinLogo} alt="Linkedin" />
      </a>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.mainContainer}>
        <Grid container spacing={0}>
          <Hidden smDown>
            <Grid item sm={4} md={5} lg={4}>
              <div className={classes.leftSection}>
                <div className={classes.logoWrapper}>
                  <img src={eosLogo} alt="Encompass" className={classes.logo} />
                </div>
                <div className={classes.sliderWrapper}>
                  <Typography
                    variant="h1"
                    gutterBottom
                    className={classes.sliderTitle}
                  >
                    {content[slider].title}
                  </Typography>
                  <Typography
                    variant="h2"
                    gutterBottom
                    className={classes.sliderSubTitle}
                  >
                    {content[slider].content} {content[slider].image}
                  </Typography>
                </div>
                {/* <div className={classes.tabWrapper}>
                  <Grid container spacing={1}>
                    {[1, 2, 3].map((item) => {
                      return (
                        <Grid item sm={4} key={item}>
                          <Box
                            className={classnames(classes.tabItem, {
                              [classes.tabItemActive]: slider === item,
                            })}
                            onClick={() => handleSlider(item)}
                          ></Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div> */}
                <div className={classes.socialWrapper}>{socialContent}</div>
                {linksContent}
              </div>
            </Grid>
          </Hidden>
          <Grid item sm={8} md={7} lg={8} xs={12}>
            <main className={classes.content}>{children}</main>
            <Hidden mdUp>
              <div className={classes.footerMobile}>
                <div className={classes.socialWrapperFooter}>
                  {socialContent}
                </div>
                {linksContent}
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default MainLayoutLogin;
