import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import LockIcon from "@material-ui/icons/Lock";
import SendIcon from "@material-ui/icons/Send";
import DoneIcon from "@material-ui/icons/ThumbUp";
import CompletedIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/ThumbDown";
import UnlockIcon from "@material-ui/icons/LockOpen";
import ArchivedIcon from "@material-ui/icons/Archive";
import CheckIcon from "@material-ui/icons/CheckCircle";

import { quotesStatusOptions } from "../../../../constants";
import useStyles from "./styles";

const WorkticketStatusChip = (props) => {
  const classes = useStyles();

  const { status, archived, pendingPlanning, completed_date } = props;

  const statusSelected = quotesStatusOptions.find(
    (statusItem) => statusItem.id === status
  );

  if (!statusSelected) {
    return "Error status";
  }

  let classChip;
  let iconChip;
  if (completed_date) {
    classChip = classes.chipPlanning;
    iconChip = <CompletedIcon className={classes.icon} />;
  } else if (archived) {
    classChip = classes.chipArchived;
    iconChip = <ArchivedIcon className={classes.icon} />;
  } else if (pendingPlanning && status === 5) {
    classChip = classes.chipPlanning;
    iconChip = <DoneIcon className={classes.icon} />;
  } else if (status === 7) {
    classChip = classes.chipDone;
    iconChip = <DoneIcon className={classes.icon} />;
  } else {
    switch (statusSelected.text) {
      case "Draft":
        classChip = classes.chipDraft;
        iconChip = <PauseIcon className={classes.icon} />;
        break;
      case "Sent":
        classChip = classes.chipNotStarted;
        iconChip = <SendIcon className={classes.icon} />;
        break;
      case "Active":
        classChip = classes.chipNotStarted;
        iconChip = <CheckIcon className={classes.icon} />;
        break;
      case "Approved":
        classChip = classes.chipDone;
        iconChip = <DoneIcon className={classes.icon} />;
        break;
      case "Not Approved":
        classChip = classes.chipCancelled;
        iconChip = <CancelIcon className={classes.icon} />;
        break;
      case "Snoozed":
        classChip = classes.chipSnoozed;
        iconChip = <LockIcon className={classes.icon} />;
        break;
      case "Snoozed - UnLocked":
        classChip = classes.chipNotStarted;
        iconChip = <UnlockIcon className={classes.icon} />;
        break;
      case "Archived":
        classChip = classes.chipArchived;
        iconChip = <ArchivedIcon className={classes.icon} />;
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Chip
        icon={iconChip}
        label={
          completed_date
            ? "Completed"
            : archived
            ? "Archived"
            : pendingPlanning && status === 5
            ? `WT Planning`
            : `${statusSelected.text}`
        }
        className={classNames(classes.chip, classChip)}
      />
    </>
  );
};

export default WorkticketStatusChip;
