import React from "react";
import Button from "@material-ui/core/Button";
import { useSurveysTabDispatch } from "contexts/surveysTabContext";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";

const SurveysTabTableSurfaceActions = (props) => {
  const classes = useStyles();
  const { row } = props;
  const dispatchSurveysTab = useSurveysTabDispatch();

  const handleClick = (event) => {
    dispatchSurveysTab({
      type: "SET_SURVEYS_TAB_SELECTED",
      surveysTabSelected: row,
    });
    dispatchSurveysTab({
      type: "TOGGLE_OPEN_LOCATIONS",
      openLocations: true,
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.buttonSurvey}
        onClick={handleClick}
        size="small"
      >
        Complete Survey
      </Button>
    </div>
  );
};

export default SurveysTabTableSurfaceActions;
