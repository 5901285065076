import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import {
  useDraftsState,
  useDraftsDispatch,
  getDraftsList,
} from "contexts/surveyDraftsContext";
import { useFilterState, getFilterData } from "contexts/filterContext";
import { deleteSurvey } from "services/surveyFrontlineService";
import { logException } from "components/util/logUtil";

import useStyles from "./styles";

const DeleteDialog = (props) => {
  const classes = useStyles();
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const { openDelete, draftsSelected } = useDraftsState();
  const dispatchDrafts = useDraftsDispatch();
  const filterState = useFilterState();

  const handleClose = () => {
    dispatchDrafts({
      type: "TOGGLE_OPEN_DELETE",
      openDelete: false,
    });
  };

  const handleDeleteSurvey = async () => {
    setIsLoadingDialog(true);
    try {
      await deleteSurvey(draftsSelected.id);
      const filterData = getFilterData(filterState);
      setIsLoadingDialog(false);
      dispatchDrafts({
        type: "TOGGLE_OPEN_DELETE",
        openDelete: false,
      });
      await getDraftsList(dispatchDrafts, filterData);
    } catch (e) {
      logException(e, "Cannot delete survey submit");
    }
  };

  return (
    <Dialog
      open={openDelete}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      {isLoadingDialog ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <AssignmentIcon className={classes.iconDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              Delete Draft
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.contentDialog}
            >
              Are you sure you want to delete this draft?
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actionsDialogWrapperSubmit}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              onClick={handleClose}
            >
              No
            </ButtonBase>
            <ButtonBase
              color="secondary"
              disableElevation={true}
              onClick={handleDeleteSurvey}
            >
              Yes
            </ButtonBase>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default DeleteDialog;
