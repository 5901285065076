import React, { useContext, useReducer } from "react";

const UserViewContext = React.createContext();

const initialState = {
  user: {},
  isLoading: true,
  profilePic: [],
  password: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user
      };
    case "SET_PROFILE_PIC":
      return {
        ...state,
        profilePic: action.profilePic
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.password
      };
    default:
      return state;
  }
};

const UserViewProvider = ({ children }) => (
  <UserViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </UserViewContext.Provider>
);

const useUserView = () => {
  const context = useContext(UserViewContext);
  if (context === undefined) {
    throw new Error("useUserView must be used within a UserViewProvider");
  }
  return context;
};

export { UserViewProvider, useUserView };
