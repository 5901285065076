import React, { useContext } from "react";
import ManageQuestionDialog from "./manageQuestionDialog";
import GlobalUiContext from "contexts/globalUiContext";
import ManageTaskDialog from "./manageTaskDialog";

const SurveysQuestionsListDialog = (props) => {
  const { globalUi } = useContext(GlobalUiContext);

  return (
    <>
      <ManageQuestionDialog />
      <ManageTaskDialog />
    </>
  );
};
export default SurveysQuestionsListDialog;
