import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import PageHeader from "components/common/PageHeader/pageHeader";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { OpenPositionsProvider } from "contexts/openPositionsContext";
import { hasPermission } from "lib/permissions";
import OpenPositionsTable from "./openPositionsTable";
import { permissionOpenPositions } from "lib/permissions";
import useStyles from "./styles";

const OpenPositions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  return (
    <>
      <PageHeader pageTitle="Open Positions" link="/open-position">
        <Link to="/open-position">Talent</Link>
      </PageHeader>
      <Box className={classes.pageBodyWrapper}>
        {hasPermission(permissionOpenPositions.LIST_AND_VIEW, permissions) ? (
          <OpenPositionsProvider>
            <OpenPositionsTable />
          </OpenPositionsProvider>
        ) : null}
      </Box>
    </>
  );
};

export default withTableFilterContext(OpenPositions);
