import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ProjectBreadcrum from "./projectBreadcrum";
import ProjectInformation from "./projectInformation";
import ProjectTabs from "./projectTabs";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import TabIcon from "@material-ui/icons/Storage";
import CollapseIcon from "@material-ui/icons/FirstPage";
import UnCollapsed from "@material-ui/icons/LastPage";
import { getProjectDetails } from "services/projectService";
import { useProjectView } from "contexts/projectViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const ProjectLayout = (props) => {
  const classes = useStyles();
  const [valueBottomNavigation, setBottomNavigation] = useState("info");
  const [isMobile, setIsMobile] = useState(false);
  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const context = useProjectView();
  const dispatchContext = context[1];

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      setCollapseSidebar(false);
    }
    window.addEventListener("resize", resize.bind(this));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
    if (window.innerWidth <= 960 && collapseSidebar) {
      setCollapseSidebar(false);
    }
  };

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        if (props.id !== "new") {
          const result = await getProjectDetails(props.id);
          dispatchContext({
            type: "SET_PROJECT",
            project: result.data.data.project,
          });
          dispatchContext({
            type: "SET_LOADING",
            isLoading: false,
          });
        }
      } catch (e) {
        logException(e, "Cannot load project data");
      }
    };
    loadWorkticket();
  }, [props.id, dispatchContext]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  const classSidebar = !isMobile
    ? collapseSidebar
      ? classes.collapseInfo
      : classes.unCollapseInfo
    : null;

  const classMainContent = !isMobile
    ? collapseSidebar
      ? classes.collapseSidebar
      : classes.unCollapseSidebar
    : null;

  return (
    <>
      <ProjectBreadcrum />
      <Grid container className={classes.root}>
        {(!isMobile || (isMobile && valueBottomNavigation === "info")) && (
          <Grid item xs={12} sm={12} md={3} className={classSidebar}>
            <Box className={classes.containerMain}>
              {!isMobile ? (
                !collapseSidebar ? (
                  <CollapseIcon
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(true)}
                  />
                ) : (
                  <UnCollapsed
                    className={classes.collapseIcon}
                    onClick={() => setCollapseSidebar(false)}
                  />
                )
              ) : null}
              {!collapseSidebar ? <ProjectInformation /> : null}
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "tabs")) && (
          <Grid item xs={12} sm={12} md={9} className={classMainContent}>
            <ProjectTabs />
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="tabs" icon={<TabIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default ProjectLayout;
