import React, { useState, useEffect } from "react";
import { saveOccupantRequest } from "services/requestNonService";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormContent from "components/ui/FormContent/formBody";
import FormTitle from "components/ui/FormContent/formTitle";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import Typography from "@material-ui/core/Typography";
import StepNav from "./stepNav";
import BackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/AddCircle";

import { useStateValue } from "components/util/state";

import useStyles from "./styles";

const RequestStep4 = (props) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [update, setUpdate] = useState(0);
  const [
    { add, completed, list, data, step, company },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    async function submitData() {
      list.forEach(async (item, index) => {
        try {
          await saveOccupantRequest(item, company.customer.id);
          if (list.length === index + 1) {
            dispatch({
              type: "LOADING",
              loading: false,
            });
          }
        } catch (e) {
          console.log("Error Request!");
        }
      });
    }
    if (company && completed) {
      submitData();
    }
  }, [completed, data, list, dispatch, company]);

  useEffect(() => {
    if (add) {
      setUpdate(0);
      setFiles([]);
    }
  }, [add, completed]);

  const handleOnClick = () => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    dispatch({
      type: "UPDATE_DATA",
      updateData: {
        files: files,
      },
    });
    dispatch({
      type: "COMPLETED",
    });
  };

  const handleOnClickBack = () => {
    dispatch({
      type: "CHANGE_STEP",
      newStep: 3,
    });
  };

  const handleAddRequest = () => {
    dispatch({
      type: "UPDATE_DATA",
      updateData: {
        files: files,
      },
      newStep: 4,
    });
    dispatch({
      type: "ADD_REQUEST",
      status: true,
    });
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
    setUpdate(newFileList.length);
  };

  if (step !== 4) return null;
  return (
    <Box className={classes.stepContainer}>
      <StepNav step={step} list={list} />
      <FormTitle>Add Photos</FormTitle>
      <FormContent>Upload photos of your request here (optional)</FormContent>
      <FileUpload
        value={files}
        handleChange={setFiles}
        handleUpdate={setUpdate}
        filesDisplay={
          Boolean(update) && (
            <FilesDisplay files={files} handleRemove={handleRemove} />
          )
        }
      />
      <Typography
        variant="h6"
        gutterBottom
        className={classes.titleMultiRequest}
        onClick={handleAddRequest}
      >
        <AddIcon className={classes.iconMultiRequest} /> Add Request
      </Typography>
      <Box className={classes.actionBox}>
        <Button
          color="primary"
          className={classes.buttonBack}
          onClick={handleOnClickBack}
          startIcon={<BackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonBegin}
          onClick={handleOnClick}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default RequestStep4;
