import React from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { useRingBellDispatch } from "contexts/ringBellContext";

import useStyles from "./styles";

const RingBellTabTableActionsSurface = (props) => {
  const classes = useStyles();
  const { row } = props;
  const dispatchRingBell = useRingBellDispatch();

  const handleClick = (event) => {
    dispatchRingBell({
      type: "SET_RINGBELL_SELECTED",
      ringBellSelected: row,
    });
    dispatchRingBell({
      type: "TOGGLE_RINGBELL_SINGLE",
      openRingBellSingle: true,
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        className={classNames(classes.button, classes.buttonPrimary)}
        onClick={handleClick}
        size="small"
      >
        Ring Bell
      </Button>
    </div>
  );
};

export default RingBellTabTableActionsSurface;
