import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as classNames from "classnames";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import React, { useEffect, useState, useContext } from "react";
import useStyles from "./styles";
import GlobalUiContext from "contexts/globalUiContext";
import BackIcon from "@material-ui/icons/NavigateBefore";

export default function AutoGrid({
  submitFormDataBasedOnCurrentTab,
  topTab,
  formSelected,
  closeAndRefreshSidebar,
  currentStep,
  setCurrentStep,
  totalSteps,
}) {
  const correctiveActionOptions = [
    { label: "No Fault", value: "No Fault" },
    { label: "Verbal Warning", value: "Verbal Warning" },
    { label: "Written Warning", value: "Written Warning" },
    { label: "Retraining", value: "Retraining" },
    { label: "Termination", value: "Termination" },
    { label: "Other", value: "Other" },
  ];
  const [teamMember, setTeamMember] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState("");

  const [correctiveActionGivenPast, setCorrectiveActionPast] = useState("");
  const [correctiveActionGiven, setCorrectiveAction] = useState("");
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const userOptions = [];
  let user = users?.map((v) => {
    userOptions.push({
      label: v?.first_name + " " + v?.last_name,
      value: v?.id,
    });
  });

  useEffect(() => {
    setJobs(null);
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (!jobs.length) {
      loadJob();
    }
  }, []);

  const classes = useStyles();
  const [form, setForm] = useState({});

  return (
    <Box className={classes.root}>
      <Grid
        container
        onChange={(e) => {
          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <Grid item xs></Grid>
        <Grid item xs={12} md={12}>
          <Box
            className={classes.Box}
            style={{
              overflow: "auto !important",
            }}
          >
            <span style={{ display: currentStep === 1 ? "block" : "none" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Job Name(*)
                  </Typography>

                  <span>
                    {Boolean(jobs) ? (
                      <FormSelectJobsAuto
                        options={jobs}
                        name="job_number"
                        handleChange={(e, v) => setJobSelected(v)}
                        value={jobSelected}
                        inputContainerProps={classes.inputContainerDateAdj}
                      />
                    ) : (
                      <LinearProgress color="secondary" />
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Employee Involved(*)
                  </Typography>
                  <FormSelectAuto
                    options={userOptions}
                    name="user"
                    handleChange={(e, v) => setTeamMember(v)}
                    value={teamMember}
                    inputContainerProps={classes.inputContainerDateAdj}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.inputContainer}>
                  <Typography className={classes.subSubTitle}>
                    Type of Accident(*)
                  </Typography>
                  <TextField
                    name="type_of_accident"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of Accident(*)
                  </Typography>
                  <FormInputDateAdo
                    name="date_of_accident"
                    value={form?.date_of_accident}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        date_of_accident: e,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subSubTitle}>
                    Corrective Action(*)
                  </Typography>
                  <FormSelectAuto
                    options={correctiveActionOptions}
                    name="user"
                    handleChange={(e, v) => setCorrectiveAction(v)}
                    value={correctiveActionGiven}
                    inputContainerProps={classes.inputContainer}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.inputContainerDateAdj}
                >
                  <Typography className={classes.subSubTitle}>
                    Date of Correction(*)
                  </Typography>

                  <FormInputDateAdo
                    name="date_of_correction"
                    value={form?.date_of_correction}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        date_of_correction: e,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </span>
            <span style={{ display: currentStep === 2 ? "block" : "none" }}>
              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    {" "}
                    Was Employee(s) wearing Personal Protective Equipment?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.was_employee_wearing_personal_equip === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_wearing_personal_equip"
                          value="yes"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_wearing_personal_equip: "yes",
                            })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.was_employee_wearing_personal_equip === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="was_employee_wearing_personal_equip"
                          value="no"
                          onClick={() =>
                            setForm({
                              ...form,
                              was_employee_wearing_personal_equip: "no",
                            })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Has Employee(s) been involved in other incidents in the
                    past?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.has_employee_involved_accident_past === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="has_employee_involved_accident_past"
                          value="yes"
                          onClick={() =>
                            setForm({
                              ...form,
                              has_employee_involved_accident_past: "yes",
                            })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.has_employee_involved_accident_past === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="has_employee_involved_accident_past"
                          value="no"
                          onClick={() =>
                            setForm({
                              ...form,
                              has_employee_involved_accident_past: "no",
                            })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className={classes.subSubTitle}>
                  Corrective Action Given(*)
                </Typography>
                <FormSelectAuto
                  options={correctiveActionOptions}
                  name="user"
                  handleChange={(e, v) => setCorrectiveActionPast(v)}
                  value={correctiveActionGivenPast}
                  inputContainerProps={classes.inputContainer}
                />
              </Grid>

              <Grid container className={classes.inputContainer}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    Has this type of incident occurred at this site in the past?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <ButtonGroup
                        variant="outlined"
                        color="default"
                        aria-label="primary button group"
                        className={classes.groupOption2}
                        fullWidth={true}
                      >
                        <Button
                          className={
                            form.has_type_accident_past_in_this_site === "yes"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="has_type_accident_past_in_this_site"
                          value="yes"
                          onClick={() =>
                            setForm({
                              ...form,
                              has_type_accident_past_in_this_site: "yes",
                            })
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          className={
                            form.has_type_accident_past_in_this_site === "no"
                              ? classes.groupOptionActive
                              : null
                          }
                          name="has_type_accident_past_in_this_site"
                          value="no"
                          onClick={() =>
                            setForm({
                              ...form,
                              has_type_accident_past_in_this_site: "no",
                            })
                          }
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.subTitle}>
                    What is causing repeat occurrences?(*)
                  </Typography>
                  <TextField
                    name="cause_repeat_occurance"
                    className={classes.field}
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </span>
            <Box className={classes.trContainer}>
              <Box className={classes.trSubContainer}>
                <Box className={classes.flex}>
                  {currentStep === 1 ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className={classes.buttonBack}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                      startIcon={<BackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classNames(
                      classes.button,
                      classes.buttonSecondary,
                      classes.btnDisabled
                    )}
                    disabled={
                      currentStep === 1
                        ? !form?.type_of_accident ||
                          !form?.date_of_accident ||
                          !correctiveActionGiven?.value ||
                          !form?.date_of_correction ||
                          !teamMember?.value
                        : currentStep === 2
                        ? !correctiveActionGivenPast?.value ||
                          !form?.cause_repeat_occurance
                        : false
                    }
                    onClick={() => {
                      if (currentStep === totalSteps) {
                        const templatePayload = {
                          job_id: jobSelected.id,

                          user_id: teamMember.value,
                          accident_type: form.type_of_accident,
                          accident_date: form.date_of_accident,
                          corrective_action: correctiveActionGiven.value,
                          correction_date: form.date_of_correction,
                          protective_equipment:
                            form.was_employee_wearing_personal_equip === "yes"
                              ? 1
                              : 0,
                          incidents_past:
                            form.has_employee_involved_accident_past === "yes"
                              ? 1
                              : 0,
                          corrective_action_past:
                            correctiveActionGivenPast.value,
                          ocurred_past:
                            form.has_type_accident_past_in_this_site === "yes"
                              ? 1
                              : 0,
                          repeat_occurrences: form.cause_repeat_occurance,
                        };
                        Object.keys(templatePayload).map((v) => {
                          if (!templatePayload[v]) {
                            templatePayload[v] = "";
                          }
                        });
                        submitFormDataBasedOnCurrentTab(
                          templatePayload,
                          topTab,
                          formSelected
                        ).then((res) => {
                          if (res.status === 201) {
                            closeAndRefreshSidebar();
                          }
                        });
                      }
                      if (currentStep < totalSteps) {
                        setCurrentStep(++currentStep);
                      }
                    }}
                  >
                    {" "}
                    {currentStep === totalSteps ? "Submit" : "Next"}
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}
