import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import RingBellTableActions from "./ringBellTableActions";
import RingBellFilters from "./ringBellFilters";
import RingBellBulkActions from "./ringBellBulkActions";
import FieldName from "./fieldName";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import useStyles from "./styles";
import { ringBellPositionOptions } from "constants.js";
import { StyledTabs } from "components/common/Table/stylesTabs";
import SideDrawer from "./drawer/sideDrawer";

import GlobalUiContext from "contexts/globalUiContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useRingBellState,
  useRingBellDispatch,
  getRingBellList,
  searchRingBellList,
  getRingBellListDataSet,
} from "contexts/ringBellContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import { useHistory } from "react-router-dom";
import {
  permissionOpenPositions,
  hasPermission,
  permissionTalent,
} from "lib/permissions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const RingBellSurfaceTable = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { permissions } = globalUi;
  const {
    ringBellListLoading,
    ringBellList,
    ringBellCount,
    openNoticeDrawer,
    ringBellSelected,
  } = useRingBellState();
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchRingBell = useRingBellDispatch();
  const dispatchFilters = useFilterDispatch();
  const history = useHistory();

  const { refreshData } = globalUi;

  const columns = [
    {
      id: "id",
      label: "ID",
      format: hasPermission(permissionTalent.INDIVIDUAL_VEW, permissions)
        ? "id"
        : null,
      path: hasPermission(permissionTalent.INDIVIDUAL_VEW, permissions)
        ? "team"
        : null,
    },
    {
      id: "user",
      label: "Name",
      content: (row) => <FieldName field={row} />,
      style: { width: 500 },
    },
    {
      id: "position",
      label: "Position",
      content: (row) =>
        row.job_title?.length
          ? row.job_title
          : ringBellPositionOptions.find(
              (positionItem) =>
                parseInt(positionItem.value) ===
                parseInt(row.tier_classification)
            )?.label,
      style: { width: 250 },
    },

    {
      id: "job_description",
      label: "Job Name",
      style: { width: 250 },
    },
    {
      id: "original_hire_date",
      label: "Date of Hire",
      format: "date",
      style: { width: 50 },
    },
    {
      id: "actions",
      label: "Actions",
      content: (row) => <RingBellTableActions row={row} />,
      contentMobile: (row) => <RingBellTableActions row={row} />,
      style: { width: 58 },
      disableSort: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      if (hasPermission(permissionTalent.VIEW_DEPARTMENT, permissions)) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "skipPositionConditions",
            group: "skipPositionConditions",
            values: [{ label: "", value: 1 }],
          },
        ];
      }
      await getRingBellList(dispatchRingBell, filterData);
    };
    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchRingBell, filterState]);

  useEffect(() => {
    const fetchData = async () => {
      const filterData = getFilterData(filterState);
      await getRingBellList(dispatchRingBell, filterData);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: false,
      });
    };
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  useEffect(() => {
    dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
  }, [dispatchTable, ringBellList]);

  const handleScrollClick = useCallback(async () => {
    if (ringBellCount <= ringBellList.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);
      if (hasPermission(permissionTalent.VIEW_DEPARTMENT, permissions)) {
        filterData.filters = [
          ...filterData.filters,
          {
            filter: "skipPositionConditions",
            group: "skipPositionConditions",
            values: [{ label: "", value: 1 }],
          },
        ];
      }
      await getRingBellListDataSet(
        ringBellList,
        dispatchRingBell,
        ringBellList.length,
        filterData
      );

      dispatchTable({ type: "UPDATE_ROWS", rows: ringBellList });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    ringBellList,
    ringBellCount,
    filterState,
    dispatchRingBell,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          if (hasPermission(permissionTalent.VIEW_DEPARTMENT, permissions)) {
            filterData.filters = [
              ...filterData.filters,
              {
                filter: "skipPositionConditions",
                group: "skipPositionConditions",
                values: [{ label: "", value: 1 }],
              },
            ];
          }
          let results = await searchRingBellList(query, filterData);

          dispatchRingBell({
            type: "SET_RINGBELL_LIST",
            ringBellList: results,
          });

          dispatchRingBell({
            type: "SET_RINGBELL_COUNT",
            ringBellCount: results.length,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };

  const handleCloseNotice = () => {
    dispatchRingBell({
      type: "TOGGLE_OPENNOTICE",
      openNoticeDrawer: false,
    });
  };

  return (
    <Box className={classes.root}>
      {ringBellListLoading || loadingSearch ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <SideDrawer
            isOpen={openNoticeDrawer}
            forUserId={ringBellSelected?.id}
            closeDrawer={handleCloseNotice}
          />
          <Box className={classes.contentContainer}>
            <TableTabs
              handleTabChange={(e, value) => {
                history.push(`/${value}`);
              }}
            >
              <StyledTabs
                value={1}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Team</Box>}
                  key={1}
                  value={1}
                />
                {hasPermission(permissionTalent.ORG_CHART, permissions) ? (
                  <StyledTab
                    disableRipple={true}
                    label={<Box className={classes.tabItem}>Org Chart</Box>}
                    key="org-chart"
                    value="team/org-chart"
                  />
                ) : null}
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Evaluations</Box>}
                  key={"evaluations"}
                  value={"evaluations"}
                />
                {hasPermission(
                  permissionOpenPositions.LIST_AND_VIEW,
                  permissions
                ) ? (
                  <StyledTab
                    disableRipple={true}
                    label={
                      <Box className={classes.tabItem}>Open Positions</Box>
                    }
                    key={"open-position"}
                    value={"open-position"}
                  />
                ) : null}
              </StyledTabs>
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>{`${ringBellList.length} / ${ringBellCount}`}</Counter>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                    activeMobile={true}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <RingBellBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          placeholderSearch={"All Team Members"}
                          handleSearchClear={handleSearchClear}
                        >
                          <RingBellFilters />
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {(filterState.filters ||
                      filterState.startDateActive ||
                      filterState.endDateActive) && <FiltersAppliedList />}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(RingBellSurfaceTable);
