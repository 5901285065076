import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import PageHeader from "components/common/PageHeader/pageHeader";

import { useUserView } from "contexts/userViewContext";

const UserBreadcrumb = () => {
  const [stateContext] = useUserView();
  const { user, isLoading } = stateContext ?? null;

  if (isLoading && user !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <PageHeader
      pageTitle={user.id ? `${user.first_name} ${user.last_name}` : "New"}
    >
      <Link to="/users">Users</Link>
    </PageHeader>
  );
};

export default UserBreadcrumb;
