import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSurveysTabDispatch } from "contexts/surveysTabContext";
import { useHistory } from "react-router-dom";

const SurveysTabTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const dispatchSurveysTab = useSurveysTabDispatch();
  const open = Boolean(anchorEl);
  const { row } = props;

  const options =
    parseInt(row.type) === 2
      ? [
          {
            id: 1,
            label: "Complete Survey",
            value: "COMPLETE",
          },
        ]
      : [
          {
            id: 1,
            label: "View",
            value: "VIEW",
          },
        ];

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    switch (action) {
      case "COMPLETE":
        dispatchSurveysTab({
          type: "TOGGLE_OPEN_LOCATIONS",
          openLocations: true,
        });
        break;
      case "VIEW":
        history.push(`/survey/${row.id}`);
        break;
      default:
        break;
    }
    dispatchSurveysTab({
      type: "SET_SURVEYS_TAB_SELECTED",
      surveysTabSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleSelect} data={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SurveysTabTableActions;
