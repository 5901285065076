import React, { useCallback, useState } from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import ImageResizer from "components/util/imageResizer";
import FileIcon from "@material-ui/icons/Description";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fillQuestion } from "services/surveyFrontlineService";
import {
  useSurveyFillDispatch,
  useSurveyFillState,
} from "contexts/surveyFillContext";
import useStyles from "./styles";

const imagesTypes = [
  "image/gif",
  "image/png",
  "image/jpeg",
  "image/bmp",
  "image/webp",
];

const FileUploadSurvey = (props) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const { questionId } = props;
  const dispatchSurveyFill = useSurveyFillDispatch();
  const { isPreview, readonlySurvey } = useSurveyFillState();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!isPreview && !readonlySurvey) {
        let filesToUpload = [];

        for (const file of acceptedFiles) {
          if (imagesTypes.includes(file.type)) {
            const resultRender = await ImageResizer(file);
            Object.assign(resultRender, {
              preview: URL.createObjectURL(resultRender),
            });
            filesToUpload = [...filesToUpload, resultRender];
          } else {
            filesToUpload = [...filesToUpload, file];
          }
        }

        try {
          setFiles(filesToUpload);
          const data = {
            files: filesToUpload,
          };
          const fileResult = await fillQuestion(questionId, data, true);
          dispatchSurveyFill({
            type: "SET_SELECTED_FILES",
            selectedFiles: fileResult.data.data.question.files,
          });
          setFiles([]);
        } catch (e) {
          console.log(e);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...props.settings,
    onDrop,
  });

  const thumbs = files.map((file) => (
    <Card className={classes.cardRoot} key={file.name}>
      <Box className={classes.cardThumb}>
        {file.preview ? <img src={file.preview} alt="Survey" /> : <FileIcon />}
      </Box>
      <Typography variant="body2" className={classes.cardLabel} gutterBottom>
        {file.name}
      </Typography>
      <LinearProgress className={classes.cardInProgress} />
    </Card>
  ));

  return (
    <Box>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box className={classes.dropZone}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleFileUpload}
            >
              Drop the files here
            </Typography>
          </Box>
        ) : (
          <>
            {files.length ? (
              <Box className={classes.dropZoneFiles}>
                <Box className={classes.fileThumbsDisplay}>{thumbs}</Box>
              </Box>
            ) : (
              <Box className={classes.dropZone}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.titleFileUpload}
                >
                  Drag documents here
                </Typography>

                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.linkFormat}
                >
                  or browse your computer
                </Typography>
              </Box>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default FileUploadSurvey;
