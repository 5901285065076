import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as classNames from "classnames";
import DateRangePickerComponent from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import Table from "components/common/Table/table";
import TableTabs from "components/common/Table/tableTabs";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import GlobalUiContext from "contexts/globalUiContext";
import {
  getFilterData,
  useFilterDispatch,
  useFilterState,
} from "contexts/filterContext";
import {
  useOpenPositionsDispatch,
  useOpenPositionsState,
} from "contexts/openPositionsContext";
import { useTableDispatch, useTableState } from "contexts/tableContext";
import { withRouter } from "react-router-dom";
import { updateOpenPositionById } from "services/openPositionsService";
import { getOpenPositionsList } from "services/openPositionsService";
import FieldLocation from "./fieldLocation";
import OpenPositionsChip from "./openPositionChip";
import OpenPositionsBulkActions from "./openPositionsBulkActions";
import OpenPositionsFilters from "./openPositionsFilters";
import OpenPositionsTableActions from "./openPositionsTableActions";
import useStyles from "./styles";
import TalentRequest from "./talentRequest";
import WeeklyScheduleModal from "./weeklyScheduleModal";
import Grid from "@material-ui/core/Grid";
import Counter from "components/common/TableList/counter";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import { permissionTalent, hasPermission } from "lib/permissions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const TabOptions = [
  { id: 0, text: "All", tab: "All", onTabing: true },
  { id: 1, text: "Opened", tab: "Opened", onTabing: true },
  { id: 5, text: "Onboarding", tab: "Onboarding", onTabing: true },
  { id: 7, text: "Closed", tab: "Closed", onTabing: true },
  { id: 6, text: "Filled", tab: "Filled", onTabing: true },
  { id: 10, text: "Archived", tab: "Archived", onTabing: true },
  { id: 11, text: "Search", tab: "Search", onTabing: true },
];

const colorMappingAnimation = {
  1: "#78C1CE",
  2: "#4F98BC",
  3: "#EFC945",
  4: "#F39A3E",
  5: "#9CBB65",
  6: "#03A550",
  7: "#CF7F7F",
};

const openPositionOptions = [
  {
    label: "Opened",
    value: 1,
  },
  {
    label: "Onboarding",
    value: 5,
  },
  {
    label: "Filled",
    value: 6,
  },
  {
    label: "Closed",
    value: 7,
  },
];

const mapToOffset = {
  0: "all",
  1: "opened",
  2: "candidate_identified",
  3: "interviewing",
  4: "offered",
  5: "onboarding",
  6: "filled",
  7: "closed",
  10: "archived",
};

const OpenPositionsSurfaceTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const moduleName = window.location.pathname.split("/")[1];
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTalentRequest, setOpenTalentRequest] = useState(false);
  const [openWeeklySchedule, setOpenWeeklySchedule] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const { openPositionsList } = useOpenPositionsState();
  const [refresh, setRefresh] = useState(false);
  const { hasRowsClickable, selected } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchOpenPositions = useOpenPositionsDispatch();
  const dispatchFilters = useFilterDispatch();
  const [openStatusModal, setStatusModal] = useState(false);
  const [openStatusModalSelected, setStatusModalSelected] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [weeklyScheduleData, setWeeklyScheduleData] = useState("");
  const [filteredRes, setFilteredRes] = useState("");
  const tabPersist = localStorage.getItem(`sub_tab_${moduleName}`)
    ? Number(localStorage.getItem(`sub_tab_${moduleName}`))
    : 0;
  const [tab, setTabState] = useState(tabPersist);
  const filterStateLoad = getFilterData(filterState);
  const filterPersisted = JSON.parse(
    localStorage.getItem(`filter_${moduleName}`)
  );
  const filterDatePersisted = JSON.parse(
    localStorage.getItem(`filter_date_${moduleName}`)
  );
  if (filterDatePersisted) {
    filterDatePersisted.startDate = new Date(filterDatePersisted?.startDate);
    filterDatePersisted.endDate = new Date(filterDatePersisted?.endDate);
  }

  const setTab = (value) => {
    setTabState(value);
    localStorage.setItem(`sub_tab_${moduleName}`, value);
  };

  const filterStatePersist =
    filterStateLoad?.length > 0 ? filterStateLoad : filterPersisted;

  const [tabUpdated, setTabUpdated] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });

  const columns = useMemo(
    () => [
      {
        id: "id",
        label: "OPS No",
        content: (row) =>
          row?.id ? (
            <Typography
              variant="body2"
              aria-haspopup="true"
            >{`OPS - ${row?.id}`}</Typography>
          ) : (
            "-"
          ),
        style: { width: 250 },
      },
      {
        id: "requestor_user",
        label: "Requestor",
        content: (row) =>
          row?.first_name ? (
            <Typography
              variant="body2"
              aria-haspopup="true"
            >{`${row?.first_name}  ${row?.last_name}`}</Typography>
          ) : (
            "-"
          ),
        style: { width: 250 },
        disableSort: true,
      },
      {
        id: "requestor_type",
        label: "Request Type",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            {parseInt(row.type) === 1 ? "New" : "Replacement"}
          </Typography>
        ),
        style: { width: 150 },
        disableSort: true,
      },

      {
        id: "job",
        label: "Job Name",
        content: (row) => (
          <FieldLocation job={{ job_description: row.job_description }} />
        ),
        style: { width: 250 },
        disableSort: true,
      },
      {
        id: "created_at",
        label: "Date Submitted",
        format: "date",
        style: { width: 50 },
      },
      {
        id: "date_requested",
        label: "Date Requested",
        format: "date",
        style: { width: 50 },
      },
      {
        id: "customer",
        label: "Customer",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            {row?.name}
          </Typography>
        ),
        style: { width: 250 },
        disableSort: true,
      },
      {
        id: "position_title",
        label: "Position Title",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            {row?.position_title}
          </Typography>
        ),
        style: { width: 250 },
        disableSort: true,
      },
      {
        id: "position_type",
        label: "Position Type",
        content: (row) => (
          <Typography variant="body2" aria-haspopup="true">
            {row?.position_type}
          </Typography>
        ),
        style: { width: 250 },
        disableSort: true,
      },
      {
        id: "date_filled",
        label: "Date Filled",
        format: "date",
        style: { width: 50 },
      },
      {
        id: "status",
        label: "Status",
        content: (row) => (
          <OpenPositionsChip status={row?.status} archived={row?.archived} />
        ),
        disableSort: true,
      },
      {
        id: "actions",
        label: "",
        content: (row) => (
          <OpenPositionsTableActions
            row={row}
            setStatusModal={setStatusModal}
            setSelectedRowData={setSelectedRowData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        ),
        contentMobile: (row) => (
          <OpenPositionsTableActions
            row={row}
            setStatusModal={setStatusModal}
            setSelectedRowData={setSelectedRowData}
          />
        ),
        style: { width: 58 },
        disableSort: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubTabChange = (event, newTab) => {
    setTab(newTab);
    dispatchTable({
      type: "UPDATE_ROWS",
      rows: null,
    });
    if (newTab !== 11) {
      if (filterState.searchString) {
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: "",
        });
        dispatchFilters({
          type: "SET_FILTER_RESULTS",
          filterResults: [],
        });
      }
    }
    setTimeout(() => setTabUpdated({ ...tabUpdated, [newTab]: 0 }), 3000);
  };
  useEffect(() => {
    if (tab && openPositionsList?.data?.open_positions[tab]) {
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [...openPositionsList?.data?.open_positions[tab]],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (filterStatePersist || filterDatePersisted) {
      if (filterStatePersist) {
        dispatchFilters({
          type: "SET_FILTERS_CORE",
          filters: filterStatePersist,
        });
      }
      if (filterDatePersisted) {
        dispatchFilters({
          type: "SET_DATE",
          ...filterDatePersisted,
        });
      }

      return;
    }
    const filterData = getFilterData(filterState);
    setWeeklyScheduleData("");
    dispatchOpenPositions({
      type: "SET_OPEN_POSITIONS_LIST",
      openPositionsList: null,
    });
    getOpenPositionsList(filterData).then((res) => {
      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: res?.data,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: res?.data?.data?.open_positions[tab],
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (
      (filterState.startDateActive && filterState.endDateActive) ||
      filterState.filters.length >= 0
    ) {
      const filterData = getFilterData(filterState);
      const openPositionsListCopy = openPositionsList;
      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: null,
      });
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }

      getOpenPositionsList(filterData, filterState.searchString).then((res) => {
        if (filterState.searchString && tab === 11) {
          dispatchTable({
            type: "UPDATE_ROWS",
            rows: res?.data?.data?.open_positions,
          });
          dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
          dispatchOpenPositions({
            type: "SET_OPEN_POSITIONS_LIST",
            openPositionsList: openPositionsListCopy,
          });
          setFilteredRes(res?.data?.data);
          return;
        }
        dispatchOpenPositions({
          type: "SET_OPEN_POSITIONS_LIST",
          openPositionsList: res?.data,
        });
        dispatchTable({
          type: "UPDATE_ROWS",
          rows: res?.data?.data?.open_positions[tab],
        });
        dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterState.filters.length,
    filterState.startDateActive,
    filterState.endDateActive,
    filterState,
  ]);

  useEffect(() => {
    if (openPositionsList?.data) {
      dispatchOpenPositions({
        type: "SET_CURRENT_TAB",
        currentSelectedTab: tab,
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: openPositionsList?.data?.open_positions[tab],
      }); //openPositionsList
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchOpenPositions, tab]);

  const handleScrollClick = () => {
    if (
      openPositionsList?.data?.open_positions[tab]?.length >=
        openPositionsList?.data?.filter_counts[tab] ||
      loadingMore ||
      tab === 11
    ) {
      return;
    }
    let offsetLength = openPositionsList?.data?.open_positions[tab]?.length;

    const filterData = getFilterData(filterState);

    setLoadingMore(true);
    getOpenPositionsList(
      filterData,
      filterState.searchString,
      offsetLength,
      mapToOffset[tab]
    ).then((res) => {
      openPositionsList.data.open_positions[tab] = [
        ...openPositionsList.data.open_positions[tab],
        ...res.data.data.open_positions,
      ];

      dispatchOpenPositions({
        type: "SET_OPEN_POSITIONS_LIST",
        openPositionsList: { ...openPositionsList },
      });
      dispatchTable({
        type: "UPDATE_ROWS",
        rows: [...openPositionsList?.data?.open_positions[tab]],
      });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
      setLoadingMore(false);
    });
  };

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      //ck on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        setTab(11);
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: query,
        });
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = async () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });

    setTab(0);
  };

  const handleTabChange = (event, value) => {
    const { history } = props;
    history.push(`/${value}`);
  };
  const IndicatorUpdateTab = ({ id }) => {
    return tabUpdated[id] ? (
      <DotIcon
        className={classes.newIndicator}
        style={{
          color: colorMappingAnimation[id],
          backgroundColor: colorMappingAnimation[id],
        }}
      />
    ) : null;
  };
  const LabelTab = ({ id, label }) => {
    return (
      <>
        {label}
        <span
          className={classes.tabUpdated}
          style={{ color: colorMappingAnimation[id] }}
        >
          {tabUpdated[id] ? `+${tabUpdated[id]}` : ""}
        </span>
      </>
    );
  };

  return (
    <Box className={classes.root}>
      {
        <Dialog
          open={openStatusModal}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={"sm"}
          fullScreen={fullScreen}
        >
          <Box className={classes.reqType}>
            <Typography className={classes.talentRequestLabel}>
              Status Type
            </Typography>
            <FormSelectAuto
              options={openPositionOptions}
              handleChange={(e, v) => {
                setStatusModalSelected(v);
              }}
              value={openStatusModalSelected}
            />
            <Box className={classes.flex}>
              <Button
                className={classes.markFilledButton}
                onClick={() => {
                  setStatusModal(false);
                  setStatusModalSelected("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classNames(
                  classes.button,
                  classes.buttonSecondary,
                  classes.marginLeft10
                )}
                onClick={() => {
                  if (selectedRowData) {
                    updateOpenPositionById(
                      { status: openStatusModalSelected.value },
                      selectedRowData?.id
                    ).then((res) => {
                      if (res.status === 201) {
                        dispatchTable({ type: "SET_SELECTED", selected: [] });
                        setStatusModalSelected("");
                        setRefresh(!refresh);
                        setTabUpdated({
                          ...tabUpdated,
                          [openStatusModalSelected.value]: ++tabUpdated[
                            openStatusModalSelected.value
                          ],
                        });
                      }
                    });
                  } else if (selected.length >= 1) {
                    selected.map((v) => {
                      updateOpenPositionById(
                        { status: openStatusModalSelected.value },
                        v
                      ).then((res) => {
                        dispatchTable({ type: "SET_SELECTED", selected: [] });
                        setStatusModalSelected("");
                        setTabUpdated({
                          ...tabUpdated,
                          [openStatusModalSelected.value]: ++tabUpdated[
                            openStatusModalSelected.value
                          ],
                        });
                      });
                    });
                    setStatusModal(false);
                    setRefresh(!refresh);
                    return;
                  }
                  setStatusModal(false);
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
      }
      {openWeeklySchedule ? (
        <WeeklyScheduleModal
          setOpenWeeklySchedule={setOpenWeeklySchedule}
          setWeeklyScheduleData={setWeeklyScheduleData}
          weeklyScheduleData={weeklyScheduleData}
          openWeeklySchedule={openWeeklySchedule}
          fullScreen={fullScreen}
        />
      ) : null}

      {openTalentRequest ? (
        <Drawer open={openTalentRequest}>
          <Box
            className={classNames(classes.trSidebar, classes.overflowScroll)}
          >
            <TalentRequest
              setOpenTalentRequest={setOpenTalentRequest}
              setOpenWeeklySchedule={setOpenWeeklySchedule}
              weeklyScheduleData={weeklyScheduleData}
              setReload={setRefresh}
              setTabUpdated={setTabUpdated}
              tabUpdated={tabUpdated}
              setWeeklyScheduleData={setWeeklyScheduleData}
              refresh={refresh}
            />
          </Box>
        </Drawer>
      ) : null}
      {!openPositionsList?.data ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <Box className={classes.root}>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleTabChange}>
              <StyledTabs
                value={1}
                indicatorColor="primary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                className={classes.rootTabs}
              >
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Team</Box>}
                  key={"team"}
                  value={"team"}
                />
                {hasPermission(permissionTalent.ORG_CHART, permissions) ? (
                  <StyledTab
                    disableRipple={true}
                    label={<Box className={classes.tabItem}>Org Chart</Box>}
                    key="org-chart"
                    value="team/org-chart"
                  />
                ) : null}
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Evaluations</Box>}
                  key={"evaluations"}
                  value={"evaluations"}
                />
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Open Positions</Box>}
                  key={1}
                  value={1}
                />
              </StyledTabs>
            </TableTabs>
            <Box className={classes.tableRoot}>
              <TableTabs handleTabChange={handleSubTabChange}>
                <StyledTabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs"
                  className={classes.rootTabs}
                >
                  {TabOptions.map((option) => {
                    if (option.id === 11 && filterState.searchString >= 3) {
                      return (
                        <StyledTab
                          disableRipple={true}
                          label={
                            <Box className={classes.tabItem}>
                              <IndicatorUpdateTab id={option.id} />
                              <LabelTab label={option.text} id={option.id} />
                            </Box>
                          }
                          value={option.id}
                          key={option.id}
                        />
                      );
                    } else {
                      if (option.id === 11 && filterState.searchString < 3) {
                        return null;
                      }
                      return (
                        <StyledTab
                          disableRipple={true}
                          label={
                            <Box className={classes.tabItem}>
                              <IndicatorUpdateTab id={option.id} />

                              <LabelTab label={option.text} id={option.id} />
                            </Box>
                          }
                          value={option.id}
                          key={option.id}
                        />
                      );
                    }
                  })}
                </StyledTabs>
              </TableTabs>

              <Box className={classes.contentCounterOld}>
                {tab === 11 ? (
                  <Counter>{`${filteredRes.open_positions?.length || 0} / ${
                    filteredRes.filter_counts || 0
                  }`}</Counter>
                ) : (
                  <Counter>{`${
                    openPositionsList?.data?.open_positions[tab]?.length || 0
                  } / ${
                    openPositionsList?.data?.filter_counts[tab] || 0
                  }`}</Counter>
                )}
              </Box>
            </Box>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  {openPositionsList?.data && (
                    <Table
                      handleRowClick={handleRowClick}
                      newColumns={columns}
                      handleScrollClick={handleScrollClick}
                      activeMobile={true}
                    >
                      <EnhancedTableToolbar>
                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "block" : "none",
                          }}
                        >
                          <ToolbarSelected>
                            <OpenPositionsBulkActions
                              selected={selected}
                              setStatusModal={setStatusModal}
                              setSelectedRowData={setSelectedRowData}
                            />
                          </ToolbarSelected>
                        </Grid>

                        <Grid
                          container
                          style={{
                            display: selected.length > 0 ? "none" : "flex",
                          }}
                        >
                          <Grid item md={6} xs={12}>
                            <ToolbarDefault
                              handleSearch={handleSearch}
                              placeholderSearch={"Search Candidates"}
                              handleSearchClear={handleSearchClear}
                            >
                              <Button
                                className={classNames(
                                  classes.button,
                                  classes.marginLeft10,
                                  classes.talReqButton
                                )}
                                onClick={() => setOpenTalentRequest(true)}
                              >
                                Talent Request
                              </Button>
                            </ToolbarDefault>
                          </Grid>

                          <Grid item md={4} xs={12} />
                          <Grid item md={2} xs={12}>
                            <DateRangePickerComponent />
                            <OpenPositionsFilters hideJobs={false} />
                          </Grid>
                        </Grid>
                      </EnhancedTableToolbar>
                      {(filterState.filters ||
                        filterState.startDateActive ||
                        filterState.endDateActive) && <FiltersAppliedList />}
                    </Table>
                  )}
                </Box>
              </Box>
            </Box>
            {loadingMore ? (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withRouter(OpenPositionsSurfaceTable);
