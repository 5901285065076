import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const ProjectActionContext = React.createContext();

const initialState = {
  openArchive: false,
  openArchiveSingle: false,
  openActive: false,
  openActiveSingle: false,
  openDelete: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_ARCHIVE":
      return {
        ...state,
        openArchive: action.open
      };
    case "TOGGLE_ARCHIVE_SINGLE":
      return {
        ...state,
        openArchiveSingle: action.open
      };
    case "TOGGLE_ACTIVE":
      return {
        ...state,
        openActive: action.open
      };
    case "TOGGLE_ACTIVE_SINGLE":
      return {
        ...state,
        openActiveSingle: action.open
      };
    case "TOGGLE_DELETE":
      return {
        ...state,
        openDelete: action.open
      };
    default:
      return initialState;
  }
};

const ProjectActionProvider = ({ children }) => (
  <ProjectActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </ProjectActionContext.Provider>
);

function useProjectAction() {
  const context = React.useContext(ProjectActionContext);
  if (context === undefined) {
    throw new Error(
      "useProjectAction must be used within a ProjectActionProvider"
    );
  }
  return context;
}

export { ProjectActionProvider, useProjectAction };
