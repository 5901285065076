import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: 41,
  },
});

const DashboardHeader = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <PageHeader pageTitle="Dashboard" link="/dashboard" />
    </Box>
  );
};

export default DashboardHeader;
