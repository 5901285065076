import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";

const apiEndpoint = apiSurfaceUrl + "/assets";
const apiEndpointSingular = apiSurfaceUrl + "/asset";

export function getAssets(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function getAssetsAll(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiSurfaceUrl}/assetsFilter?${filterString}`, {});
}

export function getAssetsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`, {});
}

export function getAssetsOffset(offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}offset=${offset}`, {});
}

export function getAssetDetails(id) {
  return http.get(`${apiEndpoint}/${id}`, {});
}

export function getAssetCategories() {
  return http.get(`${apiEndpointSingular}/categories`, {});
}

export function getAssetJobs(customer_id = undefined) {
  return http.get(
    `${apiEndpointSingular}${customer_id ? `?customer_id=${customer_id}` : ``}`,
    {}
  );
}

export function getAssetMaintenanceUpcoming(assetId = 0) {
  return http.get(
    `${apiEndpoint}${assetId ? `/${assetId}` : ``}/maintenance/upcoming`
  );
}

export function getAssetMaintenanceHistory(assetId = 0) {
  return http.get(
    `${apiEndpoint}${assetId ? `/${assetId}` : ``}/maintenance/history`
  );
}

export function getAssetMaintenancePlan(assetId = 0) {
  return http.get(`${apiEndpoint}/${assetId}/maintenance/plan`, {});
}

export function getAssetProjectMaintenanceUpcoming(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/upcoming`
  );
}

export function getAssetProjectMaintenanceHistory(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/history`
  );
}

export function getAssetProjectMaintenancePlan(assetId = 0, projectId = 0) {
  return http.get(
    `${apiEndpoint}/${assetId}/projects/${projectId}/maintenance/plan`
  );
}
