import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./styles";

const FormInputBillingItem = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    type,
    handleBlur,
    error,
    gridSizes,
    internal,
    sameLine,
    noMargin,
    withoutLabel,
    custom,
    endText,
    alignText,
    onChange,
    validateOnType,
    readonly,
  } = props;
  const [stateValue, setStateValue] = React.useState("");
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);
  let errorInput = undefined;
  if (error && error.length > 0) {
    errorInput = error.filter((item) => item.key === name);
  }

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  useEffect(() => {
    if (value !== undefined) {
      setStateValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (validateOnType) {
      validateOnType(event.target);
    }
  };

  return (
    <div
      className={
        noMargin
          ? classes.noMargin
          : internal && sameLine
          ? custom
            ? classes.inputSameRowCustom
            : classes.inputSameRow
          : internal
          ? classes.inputInternalContainer
          : classes.inputContainer
      }
    >
      <Grid container spacing={1}>
        {!withoutLabel && (
          <Grid item xs={12} md={12} className={classes.labelContainer}>
            <InputLabel htmlFor={name} className={classes.inputLabelBilling}>
              {label}
            </InputLabel>
          </Grid>
        )}
        <Grid item xs={gridSizeXs} md={gridSizeMd}>
          <Input
            name={name}
            id={name}
            fullWidth={true}
            disableUnderline={true}
            className={
              withoutLabel
                ? classes.inputFieldBillingNoLabel
                : classes.inputFieldBilling
            }
            classes={{
              focused: classes.inputFieldFocus,
              disabled: classes.inputFieldDisabled,
              input:
                alignText === "right"
                  ? classes.inputFieldRight
                  : alignText === "center"
                  ? classes.inputFieldCenter
                  : "",
            }}
            startAdornment={props.startAdornment}
            disabled={readonly ? readonly : false}
            value={stateValue}
            onBlur={(event) =>
              handleBlur ? handleBlur(event.target, "normal") : () => {}
            }
            type={type ? type : "text"}
            onChange={onChange ? onChange : handleChange}
          />
          {errorInput && Boolean(errorInput.length) && (
            <FormHelperText error>{errorInput[0].message}</FormHelperText>
          )}
        </Grid>
        {gridSizeMd < 12 ? (
          <Grid item md={12 - gridSizeMd} className={classes.endTextContainer}>
            {endText}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

export default FormInputBillingItem;
