import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { ButtonBase } from "components/ui/core/button";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FormInputDate from "components/ui/FormContent/formInputDate";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getFilterJobs } from "components/util/filterUtil";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

import { uploadBudget } from "services/budgetService";
import GlobalUiContext from "contexts/globalUiContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const budgetTypes = [
  { value: 1, label: "Campus/Building" },
  { value: 3, label: "Portfolio" },
];

const BudgetUpload = (props) => {
  const classes = useStyles();
  const [type, setType] = useState(null);
  const [files, setFiles] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [update, setUpdate] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const { dispatchGlobalUi } = useContext(GlobalUiContext);

  useEffect(() => {
    const loadJob = async () => {
      try {
        const resultForRender = await getFilterJobs();
        setJobs(resultForRender);
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (type && (type.value === 1 || type.value === 2) && !jobs.length) {
      loadJob();
    }
  }, [type, jobs]);

  const handleChangeType = (event, value) => {
    setType(value);
  };

  const closeMessageDialog = () => {
    dispatchGlobalUi({
      type: "SET_REFRESH_DATA",
      refreshData: true,
    });

    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: false,
    });
    setOpenMessage(false);
  };

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
    setUpdate(!update);
  };

  const handleChangeJob = (event, value) => {
    setJobSelected(value);
  };

  const handleChangeEffective = (e) => {
    setEffectiveDate(new Date(e.value));
  };

  const handleSubmit = async () => {
    try {
      const data = {
        job_id: jobSelected?.id,
        type: type?.value,
        effective_date: effectiveDate.toISOString(),
        files: files,
      };
      setIsLoading(true);
      await uploadBudget(data);
      setOpenMessage(true);
      setIsLoading(false);
      setIsCompleted(true);
    } catch (e) {
      logException(e, "Cannot upload budget");
    }
  };

  const activeSubmit = type?.value > 0 && files.length > 0;

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          Budget
        </Typography>
        <Typography
          variant="h6"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          Upload budget file matching the template format.
        </Typography>
      </div>
      {isLoading ? (
        <Box className={classes.centerLoadingSideBar}>
          <LoadingStateHorizontal isVisible />
        </Box>
      ) : (
        <Box className={classes.optionWrapper}>
          {!isCompleted ? (
            <>
              <Box>
                <FileUpload
                  title={"File"}
                  value={files}
                  handleChange={setFiles}
                  handleUpdate={setUpdate}
                  filesDisplay={
                    Boolean(update) && (
                      <FilesDisplay files={files} handleRemove={handleRemove} />
                    )
                  }
                  settings={{
                    accept: [
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ],
                  }}
                />
              </Box>
              <Box className={classes.containerOptions2}>
                <Grid container spacing={4}>
                  <Grid item md={6} sm={12}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={budgetTypes}
                      name="budget_type"
                      label="Type of budget"
                      handleChange={handleChangeType}
                      value={type}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <FormInputDate
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="effective_date"
                      label="Effective Date"
                      value={effectiveDate}
                      handleBlur={(e) => handleChangeEffective(e)}
                    />
                  </Grid>
                </Grid>
                {type &&
                  (type.value === 1 || type.value === 2) &&
                  (Boolean(jobs.length) ? (
                    <FormSelectJobsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={jobs}
                      name="job_number"
                      label="Budget for Job"
                      handleChange={handleChangeJob}
                      multiple={false}
                      value={jobSelected}
                    />
                  ) : (
                    <LinearProgress color="secondary" />
                  ))}
              </Box>
              <Box className={classes.actionBox}>
                <ButtonBase
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={!activeSubmit}
                >
                  Submit
                </ButtonBase>
              </Box>
            </>
          ) : null}
        </Box>
      )}
      <MessageDialog
        open={openMessage}
        title={"Success!"}
        message={"Budget has been uploaded."}
        handleClose={closeMessageDialog}
      />
    </>
  );
};

export default withRouter(BudgetUpload);
