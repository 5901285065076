import React from "react";
import Box from "@material-ui/core/Box";
import LearnCategoriesListTable from "./learnCategoriesListTable";
import LearnCategoriesListDialog from "./dialog/learnCategoriesListDialog";
import { LearnCategoriesProvider } from "contexts/learnCategoriesTableContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import useStyles from "./styles";

const LearnCategoriesListList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <LearnCategoriesProvider>
          <LearnCategoriesListTable />
          <LearnCategoriesListDialog />
        </LearnCategoriesProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(LearnCategoriesListList);
