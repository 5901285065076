import React from "react";
import { merge } from "lodash";

const TableStateContext = React.createContext();
const TableDispatchContext = React.createContext();

const defaultState = {
  tableLoading: false,
  initialCallType: "one", // Options: (all, one).  This sets the type of data being handled from the initial call. "one" expects data on a tab-by-tab basis, vs "all" which expects all tab data in the initial call.
  rows: [], // Initial empty array for table data. Once
  columns: [], // Initial empty array of table column headings
  orderBy: "", // Initial column to order by (controlled by clicking table headings). Default is order by ID desc.
  order: "asc", // Initial direction order of orderBy
  hasSelect: true, // Include select checkboxes in first column
  selected: [], // Initial empty array of selected items (populated by select checkboxes above)
  hasSearch: true, // Include search box
  hasFilters: true, // Include filter controls
  currentTab: -1, // Currently selected tab (defaults to id of 1)
  tabUpdated: null, // ID of tab that has changed data (e.g. after moving status of request/wt/quote)
  rowsUpdated: [], // Initial empty array of IDs to show which rows have been updated
  hideOption: null, // Specify a field to show or hide the row
  errorOption: null, // Specify a field to show an error on the row
  tableKpi: null, // style for KPI tables
  header: [], // Initial empty array of table column headings for multiple talble
  isMultipleHeader: false, // Initial empty array of table column headings for multiple talble
};

function tableReducer(state, action) {
  // console.log(action);
  switch (action.type) {
    case "SET_TABLE_LOADING":
      return {
        ...state,
        tableLoading: action.tableLoading,
      };
    case "UPDATE_ROWS":
      return {
        ...state,
        rows: action.rows,
        rowsFiltered: action.rows,
      };
    case "UPDATE_COLUMNS":
      return {
        ...state,
        columns: action.columns,
      };
    case "SET_ORDER":
      return {
        ...state,
        order: action.order,
      };
    case "SET_ORDERBY":
      return {
        ...state,
        orderBy: action.orderBy,
      };
    case "SET_SELECTED":
      return {
        ...state,
        selected: action.selected,
      };
    case "SET_SEARCH":
      return {
        ...state,
        hasSearch: action.hasSearch,
      };
    case "SET_CURRENT_TAB":
      return {
        ...state,
        currentTab: action.currentTab,
        selected: [],
      };
    case "SET_TAB_UPDATED":
      return {
        ...state,
        tabUpdated: action.tabUpdated,
      };
    case "SET_UPDATED_ROWS":
      return {
        ...state,
        rowsUpdated: action.rowsUpdated,
      };

    case "SET_HAS_SELECT":
      return {
        ...state,
        hasSelect: action.hasSelect,
      };
    case "SET_HIDE_OPTION":
      return {
        ...state,
        hideOption: action.hideOption,
      };
    case "SET_ERROR_OPTION":
      return {
        ...state,
        errorOption: action.errorOption,
      };
    case "SET_TABLEKPI_OPTION":
      return {
        ...state,
        tableKpi: action.tableKpi,
      };
    case "UPDATE_HEADER":
      return {
        ...state,
        rows: state.rows,
        header: action.header,
      };
    case "SET_MULTIPLE_HEADER":
      return {
        ...state,
        isMultipleHeader: action.isMultipleHeader,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TableProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);

  const [state, dispatch] = React.useReducer(tableReducer, mergedState);
  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        {children}
      </TableDispatchContext.Provider>
    </TableStateContext.Provider>
  );
}

function useTableState() {
  const context = React.useContext(TableStateContext);
  if (context === undefined) {
    throw new Error("useTableState must be used within a TableProvider");
  }
  return context;
}

function useTableDispatch() {
  const context = React.useContext(TableDispatchContext);
  if (context === undefined) {
    throw new Error("useTableDispatch must be used within a TableProvider");
  }
  return context;
}

export { TableProvider, useTableState, useTableDispatch };
