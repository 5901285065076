import React from "react";
import signatureLogo from "../../../assets/icons/signature-icon.png";

export const SignatureIcon = (props) => {
  return (
    <img
      src={signatureLogo}
      alt="Signature"
      style={{ height: 22, marginRight: 8 }}
    />
  );
};
