import http from "./httpService";
import { apiUrl } from "../lib/config";
import { getFilterString } from "../components/util/filterUtil";

const apiEndpoint = apiUrl + "/goods/commodities";

// http.setJwt(tokenKey);

export function getCommodities() {
  return http.get(`${apiEndpoint}/`, {
    params: {}
  });
}

export function getCommoditiesAdmin(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/admin?${filterString}`, {
    params: {}
  });
}
